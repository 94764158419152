import React from "react";
import _ from "lodash";
import axios, { AxiosResponse } from 'axios'
import { CmsInterface } from "../../components/Global/interfaces"
import { ContributorInterface } from "../../features/Preferences/Contributors/interfaces"
import { recordServiceV2Headers } from "../Record/utils/generateHeaders"
import { CoreTablePagination } from "../../core/Table/types";

export async function getContributors(cmsData: CmsInterface, pagination: CoreTablePagination | boolean, searchTerm: string = ""): Promise<AxiosResponse> {
    const { 
        apiUrls = {},
        user = {}
    } = cmsData;

    const { recordServiceV2Contributors = "" } = apiUrls;

    let urlString = `q=${searchTerm}`

    if (pagination) {
        urlString = searchTerm 
            ? `per_page=${pagination.pageSize}&page=${pagination.currentPage}&q=${searchTerm}`
            : `per_page=${pagination.pageSize}&page=${pagination.currentPage}`
    }

    return axios.get(`${recordServiceV2Contributors}?${urlString}`, recordServiceV2Headers(user) )
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return {
                error: error,
                ...error
            }
        });
}

export async function getContributor(cmsData: CmsInterface, id: string): Promise<AxiosResponse> {
    const { 
        apiUrls = {},
        user = {}
    } = cmsData;

    const { recordServiceV2Contributors = "" } = apiUrls;

    return axios.get(`${recordServiceV2Contributors}/${id}`, recordServiceV2Headers(user))
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return {
                error: error,
                ...error
            }
        });
}

export async function updateContributor(cmsData: CmsInterface, id: string, contributor: ContributorInterface): Promise<AxiosResponse> {
    const { 
        apiUrls = {},
        user = {}
    } = cmsData;

    const { recordServiceV2Contributors = "" } = apiUrls;

    return axios.put(`${recordServiceV2Contributors}/${id}`, contributor, recordServiceV2Headers(user))
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return {
                error: error,
                ...error
            }
        });
}

export async function createContributor(cmsData: CmsInterface, contributor: ContributorInterface): Promise<AxiosResponse> {
    const { 
        apiUrls = {},
        user = {}
    } = cmsData;

    const { recordServiceV2Contributors = "" } = apiUrls;

    return axios.post(recordServiceV2Contributors, contributor, recordServiceV2Headers(user))
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return {
                error: error,
                ...error
            }
        });
}

export async function deleteContributor(cmsData: CmsInterface, id: string | number): Promise<any> {
    const { 
        apiUrls = {},
        user = {}
    } = cmsData;

    const { recordServiceV2Contributors = "" } = apiUrls;

    return axios.delete(`${recordServiceV2Contributors}/${id}`, recordServiceV2Headers(user))
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return {
                error: error,
                ...error
            }
        });
}