import React from "react";
import { extendObservable, makeObservable } from 'mobx'
import { RootStore } from './rootStore'
import { permissionsStoreInitialState } from "./state"
import { globalStateInterface } from "../../../../../components/Global/interfaces"
import { userHasPermission } from "../../../../../services/PermissionService"; 
import { contributorsOption } from "../../../../UserRoleManagement/utils/sectionOptions/applicationSettings"
import { accessTypeStrictCreate, accessTypeStrictUpdate, accessTypeStrictDelete } from "../../../../UserRoleManagement/utils/accessOptions";

export class PermissionsStore {
    rootStore: RootStore
    userHasCreatePermission: boolean
    userHasUpdatePermission: boolean
    userHasDeletePermission: boolean

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {

        })

        extendObservable(this, permissionsStoreInitialState);
    }

    reset(): void {
        Object.keys(permissionsStoreInitialState).forEach(
            (key) => (this[key] = permissionsStoreInitialState[key]),
        );
    }

    initialize(globalState: globalStateInterface): void {
        this.handleSetUserHasDeletePermission(globalState)
        this.handleSetUserHasCreatePermission(globalState)
        this.handleSetUserHasUpdatePermission(globalState)
    }

    setUserHasDeletePermission(userHasDeletePermission: boolean): void {
        this.userHasDeletePermission = userHasDeletePermission
    }

    setUserHasUpdatePermission(userHasUpdatePermission: boolean): void {
        this.userHasUpdatePermission = userHasUpdatePermission
    }

    setUserHasCreatePermission(userHasCreatePermission: boolean): void {
        this.userHasCreatePermission = userHasCreatePermission
    }

    handleSetUserHasDeletePermission(globalState: globalStateInterface): void {
        const deletePermission = userHasPermission({
            cmsData: this.rootStore.cmsData,
            userPermissions: globalState.userPermissions,
            pathField: contributorsOption.path,
            access: accessTypeStrictDelete
        });

        this.setUserHasDeletePermission(deletePermission)
    }

    handleSetUserHasCreatePermission(globalState: globalStateInterface): void {
        const createPermission = userHasPermission({
            cmsData: this.rootStore.cmsData,
            userPermissions: globalState.userPermissions,
            pathField: contributorsOption.path,
            access: accessTypeStrictCreate
        });

        this.setUserHasCreatePermission(createPermission)
    }

    handleSetUserHasUpdatePermission(globalState: globalStateInterface): void {
        const updatePermission = userHasPermission({
            cmsData: this.rootStore.cmsData,
            userPermissions: globalState.userPermissions,
            pathField: contributorsOption.path,
            access: accessTypeStrictUpdate
        });

        this.setUserHasUpdatePermission(updatePermission)
    }
}