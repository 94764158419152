import React from "react";
import { CoreFormStack } from "../../../components/layout/CoreForm.stack";
import { snakeCase } from "lodash";
import { CoreSelectMultipleMenu } from "../../../components/inputs/Select/components/CoreSelectMultipleMenu";
import { CoreFormLabel } from "../../../components/styled/CoreForm.label"
import Input from "../../interfaces/Input.interface";
import { TooltipInfo } from "../../../../Tooltip/TooltipInfo";
import { observer } from "mobx-react";
import { useStores } from '../../store/rootStore'

export const MultipleSelect = observer(({ field }: Input) => {
    const { listsStore, formStore } = useStores()

    const Tooltip = () => {
        if (field.validation?.rangeLimit?.enabled) {
            return <TooltipInfo title={<div>Selection Limits <br />Minimum: {field.validation?.rangeLimit.min} <br />Maximum: {field.validation?.rangeLimit.max}</div>} />
        }

        return <></>
    }

    let customStyles = {
        menuButton: {
            width: "100%;",
            height: "2.5rem;",
            backgroundColor: "#FFF",
            marginTop: "0 !important",
            color: "rgba(47, 47, 47, 0.87)",
            borderWidth: "0.0625rem",
            textTransform: "none",
            whiteSpace: "nowrap",
            "&:hover": {
                backgroundColor: "#FFF",
                border: "0.0625rem solid rgba(40, 108, 210, 0.75)",
            },
            "& .MuiTypography-root": {
                lineHeight: "1",
                color: "rgba(47, 47, 47, 0.57)",
                fontSize: "1rem",
                fontWeight: "400"
            },
            "& .MuiChip-root": {
                margin: 0,
                backgroundColor: "rgba(40, 108, 210, 0.2)",
                color: "rgba(0, 0, 0, 1)",
                padding: "0.75rem 0.5rem",
                "& .MuiChip-label": {
                    fontSize: "0.75rem",
                },
            },
            "& .MuiMenu-list": {
                paddingTop: 0,
            },
            "& .MuiButton-endIcon": {
                "& .MuiSvgIcon-root": {
                    fontSize: "1.5rem"
                }
            }
        }
    }

    if (field.validation?.readOnly?.enabled || formStore.disabled) {
        customStyles.menuButton = {
            ...customStyles.menuButton,
            border: "none !important",
            padding: "0.5rem 0",
            height: "auto",
            "& .MuiButton-endIcon": {
                display: "none",
            },
            "& .Mui-disabled": {
                border: "none"
            }
        }
    }

    return (
        <CoreFormStack>
            <CoreFormLabel 
                text={field.label}
                isRequired={field.validation?.required?.enabled}
                tooltip={<Tooltip />}
                customStyles={{
                    color: "rgba(47, 47, 47, 0.87) !important",
                }}
            />
            <CoreSelectMultipleMenu 
                path={field.key}
                disabled={field.validation?.readOnly?.enabled || formStore.disabled}
                noneSelected={true}
                name={''}
                options={listsStore.lists[field.type.select.listKey]?.options || []}
                optionsFetched={listsStore.lists[field.type.select.listKey]?.isFetched}
                hasSearch={true}
                isSingleSelect={false}
                key={'random_key_' + snakeCase(field.label)}
                anchorProps={{
                    anchorOrigin: {
                        horizontal: "center",
                        vertical: "bottom",
                    },
                    transformOrigin : {
                        horizontal: "center",
                        vertical: "top",
                    }
                }}
                customStyles={customStyles}
            />
        </CoreFormStack>
    );
});
