import Config from "../../../components/Clients/Config/index";
import _ from "lodash";

const FilterRecordCategoryTypesList = (props) => {
    const { category, list } = props;

    const getConfigAvailableList = (category) => {
        let result = {
            availableCategoryTypes: [],
            disabledCategoryTypes: [],
        };

        let config = Config.getCreateRecordConfig();
        if (
            config &&
            typeof config === "object" &&
            config.hasOwnProperty("availableCategoryTypes")
        ) {
            if (
                config.availableCategoryTypes[category] &&
                Array.isArray(config.availableCategoryTypes[category])
            ) {
                result.availableCategoryTypes =
                    config.availableCategoryTypes[category];
            }
        }

        if (
            config &&
            typeof config === "object" &&
            config.hasOwnProperty("disabledCategoryTypes")
        ) {
            if (
                config.disabledCategoryTypes[category] &&
                Array.isArray(config.disabledCategoryTypes[category])
            ) {
                result.disabledCategoryTypes =
                    config.disabledCategoryTypes[category];
            }
        }

        return result;
    };

    let configsTypes = getConfigAvailableList(category);
    let result = list;

    if (!_.isEmpty(configsTypes.availableCategoryTypes)) {
        result = result.filter((type) =>
            configsTypes.availableCategoryTypes.includes(type.value),
        );
    }

    if (!_.isEmpty(configsTypes.disabledCategoryTypes)) {
        result = result.filter(
            (type) => !configsTypes.disabledCategoryTypes.includes(type.value),
        );
    }

    return result;
};

export default FilterRecordCategoryTypesList;
