import React, { Component } from "react";
import Notification from "../Notification";

export default class FlashMessage extends Component {
    /**
     * FlashMessage
     *
     * @param object props
     * @return false
     */
    constructor(props) {
        super(props);

        /**
         * @var object
         */
        this.state = {
            display: false,
        };

        /**
         * @var false|string
         */
        this.title = false;

        /**
         * @var false|string
         */
        this.description = false;

        /**
         * @var string
         */
        this.intercationStatus = "alert";

        /**
         * @var false|string
         */
        this.okText = false;

        /**
         * @var false|string
         */
        this.image = false;

        /**
         * @var bool
         */
        this.centralised = false;
    }

    /**
     * Handles on mount events
     *
     * @return false
     */
    componentDidMount() {
        this.setValues();
    }

    /**
     * Sets class values
     *
     * @return false
     */
    setValues() {
        if (typeof window !== "undefined") {
            if (
                document.body.contains(document.querySelector("#flashMessage"))
            ) {
                let element = document.getElementById("flashMessage");

                if (element) {
                    if (element.getAttribute("data-title")) {
                        this.title = element.getAttribute("data-title");
                    }

                    if (element.getAttribute("data-description")) {
                        this.description =
                            element.getAttribute("data-description");
                    }

                    if (element.getAttribute("data-intercation-status")) {
                        this.intercationStatus = element.getAttribute(
                            "data-intercation-status",
                        );
                    }

                    if (element.getAttribute("data-image")) {
                        this.image = element.getAttribute("data-image");
                    }

                    if (element.getAttribute("data-centralised")) {
                        this.centralised = true;
                    }
                }

                if (this.title && this.description) {
                    this.setState({
                        display: true,
                    });
                }
            }
        }
    }

    /**
     * Handles notification component callback
     *
     * @return flase
     */
    handleNotificationClick() {
        this.setState({
            display: false,
        });
    }

    /**
     * Renders DOM elements
     *
     * @return Notification
     */
    render() {
        if (this.state.display) {
            return (
                <Notification
                    title={this.title}
                    description={this.description}
                    intercationStatus={this.intercationStatus}
                    okText={this.okText || undefined}
                    image={this.image || undefined}
                    centralised={this.centralised || undefined}
                    onClick={this.handleNotificationClick.bind(this)}
                    display={true}
                />
            );
        }

        return false;
    }
}
