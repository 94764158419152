import React from 'react'

interface IconInterface {
    color?: string,
    className?: string
}

export const DeleteIconDynamicWhite = ({ color, className }: IconInterface) => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            x="0px" 
            y="0px"
            className={className || 'delete-icon-white'}
            viewBox="0 0 500 500" style={{ enableBackground: 'new 0 0 500 500' }}>
            
            <path fill={color || "white"} d="M375,340l-35,35l-90-90l-90,90l-35-35l90-90l-90-90l35-35l90,90l90-90l35,35l-90,90L375,340z M250,0
                C112.5,0,0,112.5,0,250c0,137.5,112.5,250,250,250c137.5,0,250-112.5,250-250C500,112.5,387.5,0,250,0z"/>
        </svg>
    )
}