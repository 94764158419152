import { SET_NEW_ASSET, CONFIRM_ASSET_CREATE } from "./index";

export const setNewAsset = (data) => {
    return {
        type: SET_NEW_ASSET,
        state: { newAsset: data },
    };
};

export const confirmAssetCreate = () => {
    return {
        type: CONFIRM_ASSET_CREATE,
        state: {},
    };
};
