import React, { useEffect, useState } from 'react'
import ClientManagedScripts from "../../ClientManagedOptions/scripts"
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconComponent from "./IconComponent";
import { CmsInterface } from "../../Global/interfaces"
import { useStore } from "../../Global/store/store";
import { getDefaultWrittenLanguageCodeFromCache } from "../../../models/ClientManaged/languagesModel";

interface ScriptSelectProps {
    cmsData: CmsInterface
    options: { scripts: any[] }
    setScriptValue: (value: string) => void
    scriptValue: string
    scriptOptionsListKey: string
    wrapperStyles?: object
    disabled?: boolean
}

type LanguageObject = {
    text: string
    value: string
}

const ScriptSelect: React.FC<ScriptSelectProps> = (props) => {
    const {
        cmsData = {
            clientFeatures: {},
            clientDataStructure: {}
        },
        options,
        setScriptValue,
        scriptValue,
        scriptOptionsListKey,
        wrapperStyles,
        disabled = false
    } = props

    const {
        clientFeatures = {},
        clientDataStructure = {}
    } = cmsData

    const fallBackScript = getDefaultWrittenLanguageCodeFromCache()

    const [state] = useStore() || [{}];

    const scriptOptions: LanguageObject[] = ClientManagedScripts({
        options,
        fallback: state.fallBackWrittenLanguages,
        clientFeatures,
        listKey: scriptOptionsListKey,
    })

    const [selectedOption, setSelectedOption] = useState<LanguageObject>({
        text: "",
        value: ""
    });

    useEffect(() => {
        let targetScriptIndex: number = scriptOptions.findIndex(
            (option) =>
                option.value === scriptValue
        )

        if (targetScriptIndex !== -1) {
            setSelectedOption({
                text: scriptOptions[targetScriptIndex].text,
                value: scriptOptions[targetScriptIndex].value
            }) 
        } else {
            targetScriptIndex = scriptOptions.findIndex(
                (option) =>
                    option.value === fallBackScript
            )

            if (targetScriptIndex !== -1) {
                setSelectedOption({
                    text: scriptOptions[targetScriptIndex].text,
                    value: scriptOptions[targetScriptIndex].value
                })
            }
        }
    }, [scriptValue]);

    const MenuProps: object = {
        PaperProps: {
            style: {
                background: "#E9E6EB",
                borderRadius: "0.375rem",
                border: "0.063rem solid #B8B8B8",
                marginTop: "0.4375rem",
                padding: "0 0.5rem",
                maxHeight: "23.75rem"
            }
        }
    }

    const handleSelect = (event: SelectChangeEvent) => {
        const selectedScriptIndex: number = scriptOptions.findIndex(
            (option) =>
                option.value === event.target.value
        )

        if (selectedScriptIndex !== -1) {
            setScriptValue(event.target.value)
            setSelectedOption(scriptOptions[selectedScriptIndex])
        }
    }

    return (
        <React.Fragment>
            <div
                className="meta-select-mui"
                style={wrapperStyles}>

                <Select
                    disabled={disabled}
                    value={selectedOption.value}
                    renderValue={() =>
                        <div className="meta-select-row">
                            <div className="meta-select-tag">
                                <span>{selectedOption.value}</span>
                            </div>
                            <div className="meta-select-value">
                                {selectedOption.text}
                            </div>
                        </div>
                    }
                    onChange={(event: SelectChangeEvent) => handleSelect(event)}
                    IconComponent={IconComponent}
                    MenuProps={MenuProps}
                    style={wrapperStyles}
                    className="meta-select-with-tag">

                    {scriptOptions.map((option) => {
                        return (
                            <MenuItem
                                key={option.value}
                                value={option.value}
                                className="meta-select-options--item">

                                <div className="value">
                                    <div className="text">
                                        {option.text}
                                    </div>
                                    <div className="meta-select-tag">
                                        <span>
                                            {option.value}
                                        </span>
                                    </div>
                                </div>
                            </MenuItem>
                        )
                    })}
                </Select>
            </div>
        </React.Fragment>
    )
}

export default ScriptSelect