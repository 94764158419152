const smallTransparentBubble = {
    fontSize: "0.78125rem",
    padding: "0.11875rem 0.25rem",
    borderRadius: "0.3rem",
    border: "0.065rem solid rgb(134, 134, 134)",
    color: "rgba(110, 110, 110, 0.87)",
    lineHeight: 1,
};

export default smallTransparentBubble;
