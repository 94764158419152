import React from "react"
import { ListsStore } from "./ListsStore"
import { FormStore } from "./FormStore"

export class RootStore {
    rootStore: RootStore
    listsStore: ListsStore
    formStore: FormStore

    constructor() {
        this.rootStore = this
        this.listsStore = new ListsStore(this)
        this.formStore = new FormStore(this)
    }
}

const StoresContext = React.createContext(new RootStore());
export const useStores = () => React.useContext(StoresContext);