import React, { Button } from "@mui/material";
import { observer } from "mobx-react";
import { PropsWithChildren } from "react";
import { Components } from "../../../../core/QA/Components";
import { generateTestId } from "../config/CreateDataTestId";
import { useBackgroundProcessingStore } from "../store/useBackgroundProcessingStore";
import { ProcessIdType } from "../types";

export const ClearButton = observer(
    ({
        processId,
        fullWidth,
        children,
    }: ProcessIdType & { fullWidth?: boolean } & PropsWithChildren) => {
        const store = useBackgroundProcessingStore();
        return (
            <Button
                variant="clear-errors"
                data-testid={generateTestId(Components.button, "clear-errors")}
                fullWidth={fullWidth}
                onClick={() => {
                    store.clearErrorsForProcess(processId);
                }}
                sx={{
                    color: "white",
                }}
            >
                {children || "Clear Issues"}
            </Button>
        );
    },
);
