import React from "react";
import "./styles/_styles.scss";

export default class TrafficLight extends React.Component {
    render() {
        const { style, status } = this.props;

        const color = this.getStatusColor(status);
        const statusStyle = color
            ? {
                  display: "block",
                  background: color,
              }
            : {
                  display: "none",
              };

        return (
            <div
                style={{
                    ...style,
                    ...statusStyle,
                }}
                className={`meta-traffic-light`}
            ></div>
        );
    }

    getStatusColor(status) {
        const { statusColors = {} } = this.props;
        return statusColors[status];
    }
}
