import React from "react";
import { SectionInterface } from "./interface";
import Scheduling from "../../../PlatformScheduling";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";

const SectionScheduling = (props: SectionInterface) => {
    const { data = {}, handleFormChange = () => {} } = props;

    const {
        platform_scheduling = [],
        scheduling_conditions = {},
        category = null,
    } = data;

    return (
        <Scheduling
            {...getSectionCommonProps({
                ...props,
                field: "platform_scheduling",
            })}
            platformScheduling={platform_scheduling}
            handleConditionsFormChange={(conditions: any) =>
                handleFormChange("scheduling_conditions", conditions)
            }
            schedulingConditions={scheduling_conditions}
            category={category}
        />
    );
};

export default SectionScheduling;
