import _ from "lodash";
import { TableCreditRow } from "../types/CastCrew.type";

/**
 * computes the rankings for every group and returns the updated rows
 */
export const recreateRankings = (rows: TableCreditRow[]) => {
    const newRows: TableCreditRow[] = [];
    const groups = _.groupBy(rows, "role");
    const roles = Object.keys(groups);
    roles.forEach((role) => {
        const sorted = groups[role].map((row) => {
            return {
                ...row,
                ranking:
                    _.indexOf(
                        groups[role].map((el) => el.id),
                        row.id,
                    ) + 1,
            };
        });
        newRows.push(...sorted);
    });

    return newRows;
};
