import { CreditListCoreInterface, CreditListInterface, EmptyObject } from "../../../credits.interface";

export default function (
    field: string,
    value: string,
    creditLists: CreditListInterface[],
    currentList: CreditListInterface | EmptyObject
) {
    let valid: boolean = true
    let message: string = ""

    const inputValue = value.replace(/\s/g, "").toLowerCase()

    const filteredLists: CreditListInterface[] =
        creditLists?.filter((creditList: CreditListInterface) => {
            return creditList.meta_id !== currentList?.meta_id && !creditList.deleted
        })

    const targetListIndex = filteredLists.findIndex(
        (creditList) =>
            creditList[field as keyof CreditListCoreInterface]?.replace(/\s/g, "").toLowerCase() === inputValue
    )

    if (targetListIndex !== -1) {
        valid = false
        message = "Please enter a different name, the one entered already exists."
    } else if (inputValue === "") {
        valid = false
        message = `Credit List ${field} is required.`
    } else if (inputValue.length < 3) {
        valid = false
        message = `Credit List ${field} should be at least 3 characters`
    }

    return {
        [field]: {
            valid,
            message
        }
    }
}