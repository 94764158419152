import React from "react";
import Component from "./index";
import ClientManagedComponent from "../ClientManagedOptions/Component";

const ClientManagedBubbleList = (props) => {
    return (
        <ClientManagedComponent
            {...props}
            dataKey="datalist"
            onSearchKey="handleChange"
            Component={Component}
        />
    );
};

export default ClientManagedBubbleList;
