import { isString } from "lodash"

export function getTrimedString(string: string): string {
    return isString(string) 
        ? string.trim()
        : "";
}

export function getCharactersOnly(string: string): string {
    return isString(string) 
        ? string.replaceAll(" ", "")
        : "";
}