import styled from "@emotion/styled";
import { Box, Menu } from "@mui/material";

interface Props {
    imgsrc?: string;
}

export const StyledAvatar = styled(Box)<Props>(({ imgsrc = '' }) => ({
    padding: '1.25rem',
    ".avatar__container": {
        position: 'relative',
        "#fileInput": {
            display: 'none',
        },
    },
    ".avatar__image": {
        backgroundColor: '#F5F5F5',
        width: '9.375rem',
        height: '9.375rem',
        overflow: 'hidden',
        borderRadius: '50%',
        boxShadow: '0 0.25rem 0.625rem 0 rgba(79, 94, 116, 0.25)',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        "img": {
            objectFit: imgsrc ? 'cover' : 'unset',
            width: '100%',
            height: '100%'
        }
    },
    ".avatar__edit-button": {
        position: 'absolute',
        bottom: '0',
        right: '1.25rem',
        width: '1.5rem',
        height: '1.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#1976d2',
        borderRadius: '50%',
        cursor: 'pointer',
        transition: 'background 0.2s ease',
        ".MuiSvgIcon-root": {
            color: '#fff',
            fontSize: '1rem'
        },
        "&:hover": {
            background: '#1565c0'
        }
    }
}));

export const StyleMenu = styled(Menu)(() => ({
    "> .MuiPaper-root": {
        boxShadow: "0.125rem 0.1875rem 0.3125rem rgba(79, 94, 116, 0.15)",
        border: "0.0625rem solid #E6E5E5",
        padding: "0.3125rem",
        overflow: "scroll",
        minWidth: "11.875rem",
        "& .MuiList-root": {
            padding: 0,
            "& .MuiMenuItem-root": {
                borderRadius: "0.1875rem",
                color: "rgba(47, 47, 47, 0.87)",
                cursor: "pointer",
                height: "2.25rem",
                fontSize: "0.875rem",
                margin: 0,
                padding: "0.375rem 0.5rem",
                "&.Mui-disabled": {
                    opacity: "0.37",
                },
                "&:hover": {
                    backgroundColor: "rgba(40, 108, 210, 0.10)",
                },
                "& .MuiSvgIcon-root": {
                    color: "#838383",
                    margin: "0 0.3125rem 0 0"
                },
            }
        },
    }
}));