import React, { useState, useEffect } from "react";
import "./styles/_verification_status.scss";
import Switch from "../../../Switch";
import Settings from "../../../Settings";
import _ from "lodash";

export default function VerificationStatus(props) {
    const {
        name,
        item = {},
        disabled = true,
        handleChange = () => {},
        overRide = false,
        forceUnverified = false,
    } = props;

    const {
        verified: isVerified = false,
        selectedStatus = null,
        created_at = {},
        updated_at = {},
        updated_by = "",
        updated_by_name = "",
    } = item;

    const toggleVerify = (verify) => {
        const { checked = false } = verify;

        setVerified(checked);
        handleChange(checked);
    };

    const [verified, setVerified] = useState(selectedStatus || isVerified);

    useEffect(() => {
        if (forceUnverified && !disabled) {
            toggleVerify({
                checked: false,
            });
        }
    }, [forceUnverified]);

    return (
        <React.Fragment>
            <div className="meta-validation-status">
                {disabled && verified && (
                    <span className="meta-validation-status--image">
                        <img
                            src={`${Settings.images.path}/svg/verified.svg`}
                            title={`${updated_by_name} Updated On ${updated_at.date}`}
                        />
                    </span>
                )}
                {!disabled &&
                    !forceUnverified &&
                    (overRide || (!overRide && !isVerified)) && (
                        <div className="meta-validation-status--switch">
                            <Switch
                                name={name}
                                handleChange={(verify) => toggleVerify(verify)}
                                checked={verified}
                            />

                            <span className="meta-validation-status--text">
                                {verified ? "Verified" : "Verify"}
                            </span>
                        </div>
                    )}
            </div>
        </React.Fragment>
    );
}
