import React from "react";
import _ from "lodash";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import ReactTooltip from "react-tooltip";
import Settings from "../Settings";
import SendRequest from "../SendRequest";
import "react-circular-progressbar/dist/styles.css";
import "./styles/_styles.scss";
import $ from "jquery";
import { GetFromLocalStorage, RemoveItemFromLocalStorage } from "../Storage/localStorage";
//import { taskProgressId } from './index'
import { setBackgroundProcess } from "../../store/actions/backgroundProcess";
const taskProgressId = "taskProgressId";

export default class ProgressIndicator extends React.Component {
    constructor(props) {
        super(props);

        /**
         * @var int
         */
        this.defaultPercentage = 0;

        /**
         * @var int
         */
        this.completedPercentage = 100;

        /**
         * @var string
         */
        this.defaultText = "";

        /**
         * @var bool
         */
        this.fetchingProcess = false;

        /**
         * @var string
         */
        this.completedStatus = "completed";

        /**
         * @var string
         */
        this.pendingStatus = "pending";

        /**
         * @var string
         */
        this.inProgressStatus = "in progress";

        /**
         * @var string
         */
        this.errorStatus = "error";

        /**
         * @var object|bool
         */
        this.timer = false;

        /**
         * @var object|bool
         */
        this.displayTimer = false;

        /**
         * @var bool
         */
        this.processComplete = false;

        /**
         * @var int
         */
        this.hideComponentTimer = 5000;

        this.defaultActiveText = "Bulk episode create underway";

        /**
         * @var object
         */
        this.state = {
            percentage: this.defaultPercentage,
            text: this.defaultText,
        };

        this.dispatch = this.props.dispatch;
    }

    componentDidMount() {
        this.setStroageId();
        this.fetchProcess();
        this.repositionTickIcon();
    }

    componentDidUpdate(prevProps) {
        const currentBackgroundProcess = this.props.backgroundProcess || {};
        const previousBackgroundProcess = prevProps.backgroundProcess || {};

        if (currentBackgroundProcess.id !== previousBackgroundProcess.id) {
            this.displayComponent();
        }
    }

    setStroageId() {
        this.storageId = GetFromLocalStorage(taskProgressId);

        if (this.storageId) {
            this.dispatch(setBackgroundProcess({
                    id: this.storageId,
                    text: this.defaultActiveText,
                }));
        }
    }

    removeStorageId() {
        RemoveItemFromLocalStorage(taskProgressId);
        this.storageId = null;
    }

    displayComponent() {
        this.fetchingProcess = false;
        this.processComplete = false;

        clearTimeout(this.displayTimer);
        $(".meta-process-indicator").fadeIn(750, () => {
            this.fetchProcess();
        });
    }

    hideComponent() {
        clearTimeout(this.displayTimer);
        this.fetchingProcess = false;
        this.processComplete = false;

        $(".meta-process-indicator").fadeOut(300, () => {
            this.setState({
                percentage: this.defaultPercentage,
            });
        });
    }

    setResponseData(response = {}) {
        const { progress, target, status, message } = response;

        const { backgroundProcess = {}, handleMessageResponse } = this.props;

        // Notification message
        if (message && typeof handleMessageResponse == "function") {
            handleMessageResponse(message);
        }

        // error
        if (status === this.errorStatus) {
            this.handleError(response);

            return;
        }

        // Complete
        if (status === this.completedStatus) {
            this.fetchingProcess = false;
            this.processComplete = true;

            this.removeStorageId();

            this.setState({
                percentage: this.completedPercentage,
                text: this.defaultText,
            });

            clearTimeout(this.timer);
            this.displayTimer = setTimeout(
                this.hideComponent.bind(this),
                this.hideComponentTimer,
            );

            return;
        }

        // Pending
        if (
            this.fetchingProcess &&
            (status === this.pendingStatus ||
                status === this.inProgressStatus) &&
            Number.isInteger(progress) &&
            Number.isInteger(target)
        ) {
            const percentage = (progress / target) * 100;

            this.setState(
                {
                    percentage,
                    text: backgroundProcess.text,
                },
                () => {
                    clearTimeout(this.timer);
                    this.timer = setTimeout(this.fetchProcess.bind(this), 500);
                },
            );
        }
    }

    handleError(error = {}) {
        const { message } = error;

        const { handleMessageResponse } = this.props;

        this.fetchingProcess = false;
        this.processComplete = false;

        // Notification message
        if (message && typeof handleMessageResponse == "function") {
            handleMessageResponse(message);
        }

        if (message) {
            console.log(message);
        }

        this.setState({
                percentage: this.defaultPercentage,
                text: this.defaultText,
            }, () => {
                clearTimeout(this.timer);
                this.displayTimer = setTimeout(
                    this.hideComponent.bind(this),
                    this.hideComponentTimer,
                );
            },
        );
    }

    fetchProcess() {
        const { backgroundProcess, progressGetUrl } = this.props;

        let storageId = this.storageId;
        if (backgroundProcess && backgroundProcess.id) {
            storageId = backgroundProcess.id;
        }
        
        if (storageId && process.env.NODE_ENV !== "test") {
            try {
                this.fetchingProcess = true;

                const options = {
                    url: progressGetUrl.replace("{taskProgressId}", storageId),
                    method: "GET",
                };

                SendRequest(
                    options,
                    async (response) => {
                        if (typeof response == "object") {
                            if (response.status == 200 && response.data) {
                                this.setResponseData(response.data);

                                return;
                            }
                        }

                        this.handleError(data);
                    },
                    (e) => {
                        this.handleError(e);
                    },
                );
            } catch (e) {
                this.handleError();
            }
        }
    }

    repositionTickIcon() {
        if (document) {
            const processIndicator = document.querySelector(
                ".meta-process-indicator",
            );

            if (processIndicator) {
                const text = processIndicator.querySelector("text");

                if (text) {
                    text.setAttribute("y", "53");
                }
            }
        }
    }

    render() {
        const { percentage, text } = this.state;

        const { styles, strokeWidth } = this.props;

        return (
            <div
                className={`meta-process-indicator 
                    ${
                        this.processComplete
                            ? "meta-process-indicator--complete"
                            : ""
                    }
                `}
            >
                <ReactTooltip
                    delayShow={0}
                    effect={Settings.tooltip.effect}
                    place="right"
                />

                <div
                    style={styles}
                    data-tip={text}
                    data-type="success"
                    place="right"
                >
                    <CircularProgressbarWithChildren
                        percentage={percentage}
                        value={percentage}
                        strokeWidth={strokeWidth || 9}
                        styles={{
                            trail: {
                                stroke: "#545454",
                            },
                            path: {
                                stroke: "white",
                            },
                            text: {
                                fill: "white",
                                fontSize: "35px",
                            },
                        }}
                    >
                        {this.processComplete && (
                            <img
                                src={`${Settings.images.path}/svg/tick-single-white.svg`}
                                className="completed"
                            />
                        )}
                    </CircularProgressbarWithChildren>
                </div>
            </div>
        );
    }
}
