import React, { useContext } from "react";
import { SectionInterface } from "./interface";
import LocaleSelect from "../../views/LocaleSelect";
import _ from "lodash";
import { SET_LANGUAGE_ISO } from "../../store/reducer";
import { AppContext } from "../../store/context";

const SectionLocalisations = (props: SectionInterface) => {
    const { cmsData = {}, data = {}, options = {} } = props;

    const { clientFeatures = {} } = cmsData;

    const { 
        titles = [], 
        synopses = [] ,
        original_title_locale
    } = data;

    const { state, dispatch } = useContext(AppContext);

    const { languageIso } = state;

    let titlesLocales = titles.map((item) => item.locale) || [];
    let synopsesLocales = synopses.map((item) => item.locale) || [];
    const concattedLocales = _.concat(titlesLocales, synopsesLocales);

    return (
        <LocaleSelect
            options={options}
            value={original_title_locale || languageIso}
            clientFeatures={clientFeatures}
            handleLanguageChange={(languageIso: string) =>
                dispatch({ type: SET_LANGUAGE_ISO, languageIso })
            }
            checkedKeys={_.uniq(concattedLocales)}
            cmsData={cmsData}
        />
    );
};

export default SectionLocalisations;
