import deepcopy from 'deepcopy'
import _ from 'lodash'

const enrich = data => {
    let result = deepcopy(data)

    if (!_.isEmpty(result.name) && typeof result.name === 'string') {
        const nameParts = result.name.split(/(\s+)/).filter(string => string.trim().length > 0)

        if (_.isEmpty(data.first_name) && nameParts[0]) {
            result.first_name = nameParts[0]
        }

        if (nameParts.length == 2 && _.isEmpty(data.last_name)) {
            result.last_name = nameParts[1]
        }

        if (nameParts.length > 2) {
            if (_.isEmpty(data.middle_names)) {
                result.middle_names = nameParts[1]
            }

            if (_.isEmpty(data.last_name)) {
                result.last_name = nameParts[2]
            }
        }
    }

    return result
}

const EnrichContributorByName = contributor => {
    let result = enrich(contributor)

    if (_.has(contributor, 'script_names') && Array.isArray(contributor.script_names)) {
        result.script_names = contributor.script_names.map(item => enrich(item))
    }

    return result
}

export default EnrichContributorByName