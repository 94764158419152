export default function GetDefaultNewRowValues(props) {
    const { options = {} } = props;

    const { asset_management } = options;

    const system = asset_management.find(
        (system) =>
            system &&
            system.system_name &&
            system.system_name.value === "Sony Media Backbone",
    );

    const { asset_types = {}, system_name } = system || {};

    let result = {};

    const assetManagementSystems = system_name ? [system_name] : [];
    const masterAssetTypes = asset_types.Masters || [];

    if (
        Array.isArray(assetManagementSystems) &&
        assetManagementSystems.length == 1
    ) {
        result.system_type = "Asset Management System";
        result.system_name = assetManagementSystems[0].value;
    }

    if (Array.isArray(masterAssetTypes) && masterAssetTypes.length == 1) {
        result.asset_type = masterAssetTypes[0].value;
    }

    if (typeof process.env == "object") {
        let system_environment = "Development";

        if (process.env.APP_ENV == "Live") {
            system_environment = "Production";
        }

        result.system_environment = system_environment;
    }

    return result;
}
