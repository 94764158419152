import { GridRowParams } from "@mui/x-data-grid-pro";
import { TableCreditRow } from "../types/CastCrew.type";

export function handleEdit(
    params: GridRowParams<any>,
    rows: TableCreditRow[],
    onEdit: (data: TableCreditRow) => void,
) {
    const { id, row } = params;
    onEdit(row);

    /*
    const foundRow = rows?.find((row) => row?.id === id);
    if (foundRow) {
        onEdit(foundRow);
    }*/
}
