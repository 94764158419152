import React, { Fragment } from "react";
import Switch from "../../Switch";
import _ from "lodash";

const RenderCertificationsToggle = (props) => {
    const {
        asset = {},
        handleAssetRatingSelect = () => {},
        handleCreateRatingSelect = () => {},
        checkedAssetsRatings = [],
    } = props;

    const { certifications = {} } = asset;

    return (
        <Fragment>
            <div className="gracenote-advisories-asset-ratings">
                {!_.isEmpty(certifications) &&
                    certifications.map((cert, index) => {
                        const isChecked =
                            checkedAssetsRatings.find((item) => {
                                return (
                                    item.meta_asset_id === asset.meta_id &&
                                    item.country_code === cert.country_code &&
                                    item.system === cert.system
                                );
                            }) || false;

                        return (
                            <div className="gracenote-copyto--row" key={index}>
                                <div className="asset-row-title">
                                    {cert.rating}
                                </div>
                                <div className="asset-row-line"></div>
                                <Switch
                                    name={cert.rating}
                                    checked={isChecked}
                                    handleChange={() =>
                                        handleAssetRatingSelect({ cert, asset })
                                    }
                                    sliderClass="react-slider--orange"
                                />
                            </div>
                        );
                    })}
                {_.isEmpty(certifications) && (
                    <div className="gracenote-copyto--row" key="create_new">
                        <div className="asset-row-title">Add New Rating</div>
                        <div className="asset-row-line"></div>
                        <Switch
                            name="create_new_rating"
                            checked={false}
                            handleChange={() => handleCreateRatingSelect(asset)}
                            sliderClass="react-slider--orange"
                        />
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default RenderCertificationsToggle;
