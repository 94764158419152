import React from "react";
import styles from "./styles";
import "./styles/_styles.scss";
import Settings from "../Settings";

export const variantSmallLeft = "content-loading-small-left";

export default class ContentLoading extends React.Component {
    /**
     * ContentLoading
     *
     * @param object props
     * @return false
     */
    constructor(props) {
        super(props);
    }

    /**
     * Renders DOM elements
     *
     * @return DOM elements
     */
    render() {
        const {
            variant = "",
            loadingWhite,
            loadingGrey,
            loadingDotsWhite,
            loadingDotsGrey,
        } = this.props;

        let image = Settings.images.loadingBlack;

        if (loadingWhite) {
            image = Settings.images.loadingWhite;
        }

        if (loadingGrey) {
            image = Settings.images.loadingGrey;
        }

        if (loadingDotsWhite) {
            image = Settings.images.loadingDotsWhite;
        }

        if (loadingDotsGrey) {
            image = Settings.images.loadingDotsGrey;
        }

        return (
            <div
                style={Object.assign(
                    {},
                    styles(this.props).container,
                    this.props.styles && this.props.styles,
                )}
                className={`meta-content-loading ${variant}`}
            >
                <img src={image} alt="loading" style={styles(this.props).img} />
            </div>
        );
    }
}
