import moment from "moment";

export default function GetMonthYearFromDate(date) {
    let result = {};

    const momentDate = moment(date);

    if (momentDate) {
        result = {
            month: momentDate.format("MMMM"),
            year: momentDate.format("YYYY"),
        };
    }

    return result;
}
