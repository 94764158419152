import React from "react";
import _ from "lodash";
import styles from "../styles";
import BubbleList from "../../BubbleList";
import ClientManagedBubbleList from "../../BubbleList/ClientManagedBubbleList";
import Select from "../../Select";
import update from "react-addons-update";
import isIndexInArray from "../../Helpers/isIndexInArray";
import Index from "./Index";
import deepcopy from "deepcopy";
import { diff } from "deep-object-diff";
import AutocompleteInput from "../../AutocompleteInput";
import ClientManagedDescriptors, {
    IsClientManagedDescriptors,
} from "../../ClientManagedOptions/descriptors";
import {
    CLIENT_MANAGED_DESCRIPTORS,
    DESCRIPTORS_GENRES,
    DESCRIPTORS_SUB_GENRES,
    DESCRIPTORS_MOODS,
    DESCRIPTORS_KEYWORDS,
} from "../../ClientManagedOptions/options";
import { filterGenresViaCategory } from "../..//Descriptors/model.tsx";

export default class Descriptors extends React.Component {
    constructor(props) {
        super(props);

        const data = this.props.data;

        let sectionOptions = [];
        if (Array.isArray(this.props.sectionOptions)) {
            sectionOptions = this.props.sectionOptions;
        }

        /**
         * @var object
         */
        this.copy = this.props.copy || {};

        /**
         * @var object
         */
        this.styles = styles(this.props);

        /**
         * @var object
         */
        this.state = {
            data,
            sectionOptions,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        const nextPropsData = nextProps.data || {};
        const thisPropsData = this.props.data || {};
        const difference = diff(nextPropsData, thisPropsData);

        if (!_.isEmpty(difference)) {
            return true;
        }

        if (!_.isEqual(nextProps.validationKeys, this.props.validationKeys)) {
            return true;
        }

        if (!_.isEqual(nextProps.disabled, this.props.disabled)) {
            return true;
        }

        if (!_.isEqual(nextProps.options, this.props.options)) {
            return true;
        }

        if (!_.isEqual(nextProps.sectionOptions, this.props.sectionOptions)) {
            return true;
        }

        if (!_.isEqual(nextState, this.state)) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps, prevState) {
        // sectionOptions
        if (!_.isEqual(this.props.sectionOptions, prevProps.sectionOptions)) {
            if (Array.isArray(this.props.sectionOptions)) {
                this.setStateValue("sectionOptions", this.props.sectionOptions);
            }
        }

        // data
        if (!_.isEqual(this.props.data, this.state.data)) {
            this.setStateValue("data", this.props.data);
        }
    }

    setStateValue(key, value) {
        if (!_.isEqual(this.state[key], value)) {
            this.setState({
                [key]: value,
            });
        }
    }

    setData(data) {
        if (!_.isEqual(this.state.data, data)) {
            this.setState({
                data,
            });
        }
    }

    getOptions(key, options) {
        let result = [];

        if (typeof options == "object") {
            result = options[key] || [];
        }

        if (this.props.category == "Game") {
            const gameKey = `games_${key}`;
            result = options[gameKey] || [];
        }

        return result;
    }

    handleChange(name, value) {
        const data = this.state.data;
        let newValue = [];

        if (Array.isArray(value)) {
            newValue = value.map((item) => {
                if (item.text !== undefined) {
                    return item;
                }

                return {
                    text: item.name,
                    value: item.value,
                };
            });
        }

        if (typeof value === "string") {
            newValue = [
                {
                    text: value,
                    value: value,
                },
            ];
        }

        let stateData = deepcopy(data || {});
        let result = { ...stateData };

        if (typeof name == "string") {
            result = update(result, {
                [name]: {
                    $set: newValue,
                },
            });

            if (typeof this.props.handleFormChange == "function") {
                this.props.handleFormChange(result);
            }

            const newData = update(this.state.data, { $set: result });

            this.setData(newData);
        }
    }

    renderNoneAdded() {
        return false;
    }

    renderThreeWayOptions() {
        const data = deepcopy(this.state.data || {});
        const options = this.props.options || {};
        const record_types = options.record_types || [];
        const record_sub_types = options.games_subtypes || [];
        const user_objectives = options.user_objectives || [];
        const validationKeys = this.props.validationKeys || [];

        const getTypes = () => {
            let result = [];

            record_types.map((item) => {
                if (item.category == this.props.category) {
                    result.push({
                        value: item.value,
                        text: item.type,
                    });
                }
            });

            return result;
        };
        return (
            <div style={this.styles.flexMarginContainer}>
                {isIndexInArray(this.props.sectionOptions, "RecordType") && (
                    <Select
                        name="type"
                        id="type"
                        value={
                            _.isArray(data.type)
                                ? _.last(data.type).text
                                : data.type || ""
                        }
                        clear={true}
                        label={this.copy.recordType || "Record Type"}
                        disabledText={this.copy.selectType || "Select Type"}
                        noneSelectedText={
                            this.copy.noneSelected || "None Selected"
                        }
                        readOnly={this.props.disabled}
                        disabled={this.props.disabled}
                        data={getTypes()}
                        wrapperStyles={this.styles.flexMarginItem}
                        handleChange={(item) => this.handleChange("type", item)}
                    />
                )}

                {isIndexInArray(this.props.sectionOptions, "RecordSubType") && (
                    <Select
                        name="sub_type"
                        id="sub_type"
                        value={
                            _.isArray(data.sub_type)
                                ? _.last(data.sub_type).text
                                : data.sub_type || ""
                        }
                        clear={true}
                        label={this.copy.recordSubType || "Record Sub-Type"}
                        disabledText={this.copy.selectType || "Select Type"}
                        noneSelectedText={
                            this.copy.noneSelected || "None Selected"
                        }
                        readOnly={this.props.disabled}
                        disabled={this.props.disabled}
                        data={record_sub_types}
                        wrapperStyles={this.styles.flexMarginItem}
                        handleChange={(item) =>
                            this.handleChange("sub_type", item)
                        }
                    />
                )}

                {isIndexInArray(this.props.sectionOptions, "UserObjective") && (
                    <Select
                        name="user_objective"
                        id="user_objective"
                        value={
                            _.isArray(data.user_objective)
                                ? _.last(data.user_objective).text
                                : data.user_objective || ""
                        }
                        clear={true}
                        label={this.copy.userObjective || "User Objective"}
                        disabledText={this.copy.selectType || "Select Type"}
                        noneSelectedText={
                            this.copy.noneSelected || "None Selected"
                        }
                        readOnly={this.props.disabled}
                        disabled={this.props.disabled}
                        data={user_objectives}
                        wrapperStyles={this.styles.flexMarginItem}
                        handleChange={(item) =>
                            this.handleChange("user_objective", item)
                        }
                    />
                )}
            </div>
        );
    }

    renderBooleanProductPlacement({ data }) {
        const CapitalizeFirstLetter = (string) =>
            string.charAt(0).toUpperCase() + string.slice(1);

        return (
            <div style={this.styles.marginItem}>
                <AutocompleteInput
                    label={"Product Placement"}
                    className="meta-product-placement-boolean"
                    datalistId={"product-placement-list"}
                    placeholder={"Start Typing Product Placement"}
                    value={
                        data.product_placement === ""
                            ? data.product_placement
                            : CapitalizeFirstLetter(
                                  Boolean(data.product_placement).toString(),
                              )
                    }
                    staticDatalist={[
                        { value: false, text: "False" },
                        { value: true, text: "True" },
                    ]}
                    openOnClick={true}
                    single={true}
                    recordBasic={true}
                    keepSelected={true}
                    bubbleOnSelect={true}
                    readOnly={this.props.disabled}
                    disabled={this.props.disabled}
                    callBackOnDelete={(item) => {
                        this.handleChange("product_placement", "");
                        this.setState({ deletedProductPlacement: true });
                    }}
                    handleListSelection={(item) => {
                        this.handleChange("product_placement", item.value);
                        this.setState({ deletedProductPlacement: false });
                    }}
                    filterStaticDataList={true}
                    createNew={false}
                />
            </div>
        );
    }

    renderBubbleListProductPlacement({ data }) {
        const { validationKeys = [], disabled, options = {} } = this.props;

        const product_placements = this.getOptions(
            "product_placements",
            options,
        );

        return (
            <div style={this.styles.marginItem}>
                <BubbleList
                    label="Product Placement"
                    name="productPlacement"
                    id="meta-product-placement"
                    className="meta-product-placement"
                    inputId="product-placement"
                    background="#88D8D9"
                    color="#175E56"
                    emptyPlaceholder="Start Typing Product Placement"
                    data={data.product_placement || []}
                    handleFormChange={(item) =>
                        this.handleChange("product_placement", item)
                    }
                    datalistId="product-placement-list"
                    single={true}
                    datalist={product_placements}
                    error={isIndexInArray(validationKeys, "product_placement")}
                    noMatchText={"No match for {value}. Create New?"}
                    showAddNewButton={false}
                    deleteWhite={true}
                    readOnly={disabled}
                    ranking={true}
                    dataListUseEnterButton={true}
                    filterStaticDataList={true}
                />
            </div>
        );
    }

    renderFullWidthOptions() {
        const {
            clientFeatures = {},
            managed_lists = [],
            category,
            apiUrls = {},
        } = this.props;

        const isClientManaged = IsClientManagedDescriptors(clientFeatures);
        const data = deepcopy(this.state.data || {});
        const options = this.props.options || {};
        const genres = this.getOptions("genres", options);
        const sub_genres = this.getOptions("sub_genres", options);
        const moods = this.getOptions("moods", options);
        const keywords = this.getOptions("keywords", options);

        const clientManagedKeywordsList = ClientManagedDescriptors({
            options,
            fallback: keywords,
            clientFeatures,
            managed_lists,
            itemKey: "keywords",
        });

        const clientManagedGenresList = ClientManagedDescriptors({
            options,
            fallback: genres,
            clientFeatures,
            managed_lists,
            itemKey: "genres",
            category,
        });

        const clientManagedSubGenresList = ClientManagedDescriptors({
            options,
            fallback: sub_genres,
            clientFeatures,
            managed_lists,
            itemKey: "sub_genres",
        });

        const validationKeys = this.props.validationKeys || [];

        const displayKeywords =
            isIndexInArray(this.props.sectionOptions, "Keywords") ||
            managed_lists.find((item) => item.type == "keywords");

        const { productPlacement = {} } = clientFeatures;

        const commonProps = {
            showAddNewButton: false,
            deleteWhite: true,
            readOnly: this.props.disabled,
            ranking: true,
            dataListUseEnterButton: true,
            filterStaticDataList: true,
            clientManagedType: CLIENT_MANAGED_DESCRIPTORS,
            clientFeatures,
            apiUrls,
            searchOnChange: true,
        };

        let BubbleListComponent = BubbleList;
        if (isClientManaged) {
            BubbleListComponent = ClientManagedBubbleList;
        }

        return (
            <React.Fragment>
                {isIndexInArray(this.props.sectionOptions, "Genres") && (
                    <div style={this.styles.marginItem}>
                        <BubbleListComponent
                            label={this.copy.genres || "Genres"}
                            name="genres"
                            id="meta-genres"
                            inputId="genres"
                            background="#E7E7E7"
                            color="#333333"
                            emptyPlaceholder={
                                this.copy.startTypingGenres ||
                                "Start Typing Genres"
                            }
                            data={data.genres || []}
                            handleFormChange={(item) =>
                                this.handleChange("genres", item)
                            }
                            datalistId="genres_list"
                            datalist={clientManagedGenresList}
                            error={isIndexInArray(validationKeys, "genres")}
                            showAddNewButton={false}
                            type={DESCRIPTORS_GENRES}
                            transformListHook={(genres) =>
                                filterGenresViaCategory(genres, category)
                            }
                            {...commonProps}
                        />
                    </div>
                )}

                {isIndexInArray(this.props.sectionOptions, "SubGenres") && (
                    <div style={this.styles.marginItem}>
                        <BubbleListComponent
                            label={this.copy.subGenres || "Sub-Genres"}
                            name="sub_genres"
                            id="meta-sub-genres"
                            inputId="sub_genres"
                            background="#E1E1E1"
                            color="#383838"
                            emptyPlaceholder={
                                this.copy.startTypingSubGenres ||
                                "Start Typing Sub-Genres"
                            }
                            data={data.sub_genres || []}
                            handleFormChange={(item) =>
                                this.handleChange("sub_genres", item)
                            }
                            datalistId="sub_genres_list"
                            datalist={clientManagedSubGenresList}
                            error={isIndexInArray(validationKeys, "sub_genres")}
                            type={DESCRIPTORS_SUB_GENRES}
                            {...commonProps}
                        />
                    </div>
                )}

                {isIndexInArray(this.props.sectionOptions, "Moods") && (
                    <div style={this.styles.marginItem}>
                        <BubbleListComponent
                            label={this.copy.moods || "Moods"}
                            name="moods"
                            inputId="moods"
                            id="meta-moods"
                            background="#D0B7FF"
                            color="#5A4582"
                            emptyPlaceholder={
                                this.copy.startTypingMoods ||
                                "Start Typing Moods"
                            }
                            data={data.moods || []}
                            handleFormChange={(item) =>
                                this.handleChange("moods", item)
                            }
                            datalistId="moods_list"
                            datalist={moods}
                            error={isIndexInArray(validationKeys, "moods")}
                            type={DESCRIPTORS_MOODS}
                            {...commonProps}
                        />
                    </div>
                )}

                {displayKeywords && (
                    <div style={this.styles.marginItem}>
                        <BubbleListComponent
                            label={this.copy.moods || "Keywords"}
                            name="keywords"
                            id="meta-keywords"
                            inputId="keywords"
                            background="#9BF4AF"
                            color="#2D6969"
                            emptyPlaceholder={
                                this.copy.startTypingKeywords ||
                                "Start Typing Keywords"
                            }
                            data={data.keywords || []}
                            handleFormChange={(item) =>
                                this.handleChange("keywords", item)
                            }
                            datalistId="keywords_list"
                            datalist={clientManagedKeywordsList}
                            error={isIndexInArray(validationKeys, "keywords")}
                            noMatchText={
                                clientFeatures?.noMatchTextCreateNew
                                    ? "No match for {value}. Create New?"
                                    : false
                            }
                            type={DESCRIPTORS_KEYWORDS}
                            {...commonProps}
                        />
                    </div>
                )}

                {isIndexInArray(
                    this.props.sectionOptions,
                    "productPlacement",
                ) && (
                    <div style={this.styles.marginItem}>
                        {!productPlacement.boolean &&
                            this.renderBubbleListProductPlacement({ data })}
                        {productPlacement.boolean &&
                            this.renderBooleanProductPlacement({ data })}
                    </div>
                )}
            </React.Fragment>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.renderThreeWayOptions()}
                {this.renderFullWidthOptions()}
            </React.Fragment>
        );
    }
}
