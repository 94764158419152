import React from "react";
import moment from "moment";

const RenderDate = (props) => {
    const { item = {}, format = "Do MMMM YYYY" } = props;

    const { date } = item;

    const momentDate = moment(date).format(format);

    return (
        <span className="meta-numbered-record-table--date margin-left">
            {momentDate}
        </span>
    );
};

export default RenderDate;
