import DateLock from "../views/DateLock";
import DisplayName from "../views/DisplayName";
import ActiveInactiveTag from "../views/ActiveInactiveTag";
import LicencePreview from "../views/LicencePreview";
import CustomSelect from "../../../components/CustomSelect/SelectWithMenuIcon";

export interface HeaderComponentInterface {
    [key: string]: any;
}

export const itemDateLock = "DateLock";
export const itemDisplayName = "DisplayName";
export const itemCustomSelect = "CustomSelect";
export const itemActiveInactiveTag = "ActiveInactiveTag";
export const itemLicencePreview = "LicencePreview";

export const headerComponents: HeaderComponentInterface = {
    [itemDateLock]: DateLock,
    [itemDisplayName]: DisplayName,
    [itemCustomSelect]: CustomSelect,
    [itemActiveInactiveTag]: ActiveInactiveTag,
    [itemLicencePreview]: LicencePreview,
};
