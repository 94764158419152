import React from "react";
import _ from "lodash";
import SendRequest from "../../components/SendRequest";
import { CmsInterface } from "../../components/Global/interfaces"
import { SendRequestResponseInterface } from "../../components/SendRequest/interface"

export async function getRoles(cmsData: CmsInterface): Promise<SendRequestResponseInterface> {
    const { apiUrls = {} } = cmsData;

    const { userManagementRoles = "" } = apiUrls;

    const options = {
        method: "GET",
        url: userManagementRoles,
        testingData: process.TESTING_DATA_FETCH_ROLES || [],
        corsHeaders: true,
    };

    const response = await SendRequest(options)
    
    return response
}

export async function createRole(cmsData: CmsInterface, body: any): Promise<SendRequestResponseInterface> {
    const { apiUrls = {} } = cmsData;

    const { userManagementRoles = "" } = apiUrls;

    const options = {
        method: "POST",
        url: userManagementRoles,
        body,
        corsHeaders: true,
    };

    const response = await SendRequest(options)
    
    return response
}

export async function updateRole(cmsData: CmsInterface, id: number, body: any): Promise<SendRequestResponseInterface> {
    const { apiUrls = {} } = cmsData;

    const { userManagementRoles = "" } = apiUrls;

    const options = {
        method: "PUT",
        url: `${userManagementRoles}/${id}`,
        body,
        corsHeaders: true,
    };

    const response = await SendRequest(options)
    
    return response
}

export async function deleteRole(cmsData: CmsInterface, id: number): Promise<SendRequestResponseInterface> {
    const { apiUrls = {} } = cmsData;

    const { userManagementRoles = "" } = apiUrls;

    const options = {
        method: "DELETE",
        url: `${userManagementRoles}/${id}`,
        corsHeaders: true,
    };

    const response = await SendRequest(options)
    
    return response
}