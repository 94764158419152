import React, { Fragment } from "react";
import Boilerplate from "./Boilerplate";
import { useStore } from "../../components/Global/store/store";
import { Helmet } from "react-helmet";

const BoilerplatePage = (props) => {
    const [state] = useStore();

    const { active = "" } = props;

    const { cmsData = {} } = state;

    const { pageMetaData = {} } = cmsData;

    const activeMetaData = pageMetaData[active] || {};

    return (
        <Fragment>
            <Helmet>
                {activeMetaData && activeMetaData.title && (
                    <title>{activeMetaData.title}</title>
                )}
            </Helmet>

            <Boilerplate cmsData={cmsData} {...props} />
        </Fragment>
    );
};

export default BoilerplatePage;
