import React from "react";
import { Stack } from "@mui/material";

export const NoRowsOverlay = ({ message }: { message: string }) => {
    return (
        <Stack
            textAlign="center"
            height="100%"
            alignItems="center"
            justifyContent="center"
            sx={{ color: "rgba(149, 149, 149, 0.37)" }}
        >
            {message || "No Data"}
        </Stack>
    );
};
