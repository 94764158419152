import Prefixer from "inline-style-prefixer";

export default function styles(props = {}) {
    let styles = {
        title: {
            color: props.color || "#333",
            fontSize: props.fontSize || "1.4375rem",
            fontWeight: props.fontWeight || "500",
            margin: props.margin || "0 0 0.5rem 0",
            padding: props.padding || "0",
            display: props.display || "block",
            textAlign: props.textAlign || "left",
            textTransform: props.textTransform || "initial",
            textDecoration: props.underline ? "underline" : "none",
            letterSpacing: props.letterSpacing || "normal",
            lineHeight: props.lineHeight || "1.39",
        },
        sufix: {
            color: props.sufixColor || "#585857",
        },
    };

    let prefixer = new Prefixer();
    styles = prefixer.prefix(styles);

    return styles;
}
