const HandleToggleSearchBoxFilters = (props) => {
    const { state = {} } = props;

    const { displaySearchBoxFilters, set = () => {} } = state;

    set({
        displaySearchBoxFilters: !displaySearchBoxFilters,
    });
};

export default HandleToggleSearchBoxFilters;
