/* eslint-disable no-confusing-arrow */
import { isEmpty } from "lodash";
import { z } from "zod";
import { CORE_FORM_STRING_DEFAULT_VALUE } from "../../../../../../core/Form/constants";
import {
    ArrayOperation,
    arrayOperation,
} from "../../../../../../core/utils/arrayOperation";
import { displayTitleOptions } from "../../../../containers/record/standalone/fields/DisplayTitle";
import { Categories } from "../../../../containers/record/tv-series/types/types";
import { recordCollectionVS } from "../../../../containers/record/types";
import { AllFieldsVT } from "../../../types";
import { C100, C1024, C250, C255, C12, C5 } from "./constants";

const genreVS = z.string().array().max(C5, `Genres has a limit of ${C5}`);

function parentShowValidation(val: AllFieldsVT, ctx: z.RefinementCtx) {
    if (
        (val?.category === Categories.Episode ||
            val?.category === Categories.ModularEpisode ||
            val?.category === Categories.Storyline ||
            val?.category === Categories.Season) &&
        isEmpty(val?.parentShow?.title)
    ) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["parentShow.title"],
            message: `Parent Show Title is mandatory!`,
        });
    }
}

export const sequenceVS = z.object({
    id: z.coerce.string(),
    value1: z.number().nullish(),
    value2: z.coerce.string().nullish(),
    value3: z.number().nullish(),
    childCategory: z.string().nullish(), // TODO: this should be removed in the future
});

const metaIdVS = z.coerce
    .string()
    .nullish()
    .transform((value) =>
        isEmpty(value) || value === "null"
            ? CORE_FORM_STRING_DEFAULT_VALUE
            : value,
    );

export const SEQUENCES_ERROR_MESSAGE = `Please enter a unique number; this one is already in use.`;
export const sequenceArrayVS = z.object({
    sequences: z
        .array(sequenceVS)
        .nullish()
        .superRefine((sequences, ctx) => {
            const hasDuplicates = arrayOperation({
                array: sequences,
                key: "value1",
                operation: ArrayOperation.HAS_DUPLICATES,
            });

            if (hasDuplicates) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ["sequences"],
                    message: SEQUENCES_ERROR_MESSAGE,
                    fatal: true,
                });
            }
        }),
});

export type SequenceShape = Required<z.infer<typeof sequenceVS>>;
const releaseYearVS = z.coerce
    .number()
    .nullish()
    .superRefine((val, ctx) => {
        const releaseYear = val;
        // If Release Year is not empty it should have 4 digits
        if (
            Number(releaseYear) !== 0 &&
            (Number(releaseYear) < 1000 || Number(releaseYear) > 9999)
        ) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `Release Year must be 4 digits`,
            });
        }
    });
/**
 * @description
 * This is the validation schema for all fields in the form.
 *
 * @note
 * ! even if zod provides a way to merge schemas (id for example which is used in many places)
 * ! because of a BUG in zod, using merge will slow down the IDE a lot making development very hard
 * ! so leave duplicate in the below schema.
 */
export const allFieldsVS = z
    .object({
        id: z.coerce.string(),
        metaId: metaIdVS,
        category: z.coerce.string().nonempty("Please provide Category"),
        title: z.coerce.string().nonempty("Please provide Record Title"),
        releaseYear: releaseYearVS,
        brand: z.coerce.string().nullish(),
        provenance: z.coerce.string().nullish(),
        displayTitle: z.coerce.string().nullish(),
        originalLanguage: z.coerce.string().nullish(),
        countryOfOrigin: z.coerce.string().nullish(),
        collection: z
            .object({
                category: z.string().nonempty("Please select a category"),
                title: z.string().nonempty("Please enter a title"),
                metaId: z.string().nullish(),
            })
            .nullish(),

        // Additional fields
        titles: z
            .object({
                aka: z.string().array().nullish(),
                fka: z.string().array().nullish(),
                short: z.coerce.string().nullish(),
                sort: z.coerce.string().nullish(),
                alternative: z.coerce.string().nullish(),
                release: z.coerce.string().nullish(),
                display: z.coerce.string().nullish(),
            })
            .nullish(),
        descriptions: z
            .object({
                genre: z.string().array().nullish(),
                subGenre: z.string().array(),

                description100: z.coerce
                    .string()
                    // .max(C100, `Maximum ${C100} characters reached`)
                    .nullish(),
                description250: z.coerce
                    .string()
                    // .max(C250, `Maximum ${C250} characters reached`)
                    .nullish(),
                longDescription1024: z.coerce
                    .string()
                    // .max(C1024, `Maximum ${C1024} characters reached`)
                    .nullish(),
            })
            .nullish(),
        ids: z
            .object({
                rms: z.coerce.string().nullish(),
                legacySap: z.coerce.string().nullish(),
                s4_mpm_id: z.coerce.string().nullish(),
                change_record_id: z.coerce.string().nullish(),
            })
            .nullish(),
        other: z
            .object({
                channelOfOrigin: z.coerce.string().nullish(),
            })
            .nullish(),

        // Child category
        childCategory: z.string().nullish(),

        // inheritance
        episodeInheritance: z.array(z.string().nullish()).nullish(),
        seasonInheritance: z.array(z.string().nullish()).nullish(),
        seasonEpisodeInheritance: z.array(z.string().nullish()).nullish(),

        seasonNo: z.coerce.number().nullish(),
        episodeNo: z.coerce.number().nullish(),
        originalProductionNo: z.coerce.string().nullish(),
        numberOfEpisodes: z.coerce.number().nullish(),

        // Parent Show
        parentShow: z
            .object({
                id: z.coerce.string(),
                metaId: metaIdVS,
                title: z.coerce.string().nullish(),
            })
            .nullish(),

        // Parent Season
        parentSeason: z
            .object({
                id: z.coerce.string(),
                metaId: metaIdVS,
                title: z.coerce.string().nullish(),
            })
            .nullish(),

        // Parent Modular Episode
        parentModularEpisode: z
            .object({
                id: z.coerce.string(),
                metaId: metaIdVS,
                title: z.coerce.string().nullish(),
            })
            .nullish(),
    })
    .merge(sequenceArrayVS);

export const episodeVS = allFieldsVS.superRefine((val, ctx) => {
    parentShowValidation(val, ctx);

    if (
        (val?.category === Categories.Episode ||
            val?.category === Categories.ModularEpisode ||
            val?.category === Categories.Storyline) &&
        isEmpty(val?.parentSeason?.title)
    ) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["parentSeason.title"],
            message: `Parent Season Title is mandatory!`,
        });
    }
});

export const seasonVS = allFieldsVS.superRefine((val, ctx) => {
    parentShowValidation(val, ctx);
});

type RequiredFields = Required<
    Pick<z.infer<typeof allFieldsVS>, "id" | "title" | "category">
>;

export type AllFields = z.infer<typeof allFieldsVS> & RequiredFields;
const primaryFieldsVS = allFieldsVS.pick({
    id: true,
    metaId: true,
    category: true,
    title: true,
    releaseYear: true,
    brand: true,
    provenance: true,
    displayTitle: true,
    originalLanguage: true,
    countryOfOrigin: true,
    collection: true,
});

export type PrimaryFields = z.infer<typeof primaryFieldsVS> & RequiredFields;

const requiredDescriptionsVS = z.object({
    descriptions: z
        .object({
            genre: genreVS,
            description100: z.coerce
                .string()
                .max(C100, `Maximum ${C100} characters reached`)
                .nullish(),
            description250: z.coerce
                .string()
                .max(C250, `Maximum ${C250} characters reached`)
                .nullish(),
            longDescription1024: z.coerce
                .string()
                .max(C1024, `Maximum ${C1024} characters reached`)
                .nullish(),
        })
        .nullish(),
});

export const requiredFieldsVS = z
    .object({
        id: z.coerce.string(),
        metaId: metaIdVS,
        category: z.coerce.string().nonempty("Please provide Category"),
        title: z.coerce
            .string()
            .max(C255, `Maximum ${C255} characters reached`)
            .nonempty("Please provide Record Title"),
        releaseYear: releaseYearVS,
        brand: z.coerce.string().nonempty("Please provide Brand"),
        provenance: z.coerce.string().nonempty("Please provide Provenance"),
        displayTitle: z.coerce
            .string()
            .nonempty("Please provide Display Title"),
        originalLanguage: z.coerce
            .string()
            .nonempty("Please provide Original Language"),
        countryOfOrigin: z.coerce
            .string()
            .nonempty("Please provide Country of Origin"),
        originalProductionNo: z.coerce
            .string()
            .max(C12, `Maximum ${C12} characters reached`)
            .nullish(),
        titles: z
            .object({
                short: z.coerce
                    .string()
                    .max(C255, `Maximum ${C255} characters reached`)
                    .nullish(),
                sort: z.coerce
                    .string()
                    .max(C255, `Maximum ${C255} characters reached`)
                    .nullish(),
                alternative: z.coerce
                    .string()
                    .max(C255, `Maximum ${C255} characters reached`)
                    .nullish(),
            })
            .nullish(),
    })
    .merge(requiredDescriptionsVS)
    .merge(recordCollectionVS)
    .superRefine((val, ctx) => {
        const category = val?.category;
        // Release Year is mandatory for Feature
        if (
            category === "Feature" &&
            (val?.releaseYear == null || val?.releaseYear <= 0)
        ) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ["releaseYear"],
                message: `Release year is mandatory!`,
            });
        }

        // Original Production No is mandatory for Episode, Storyline, Modular Episode
        if (
            isEmpty(val?.originalProductionNo) &&
            (category === Categories.Episode ||
                category === Categories.Storyline ||
                category === Categories.ModularEpisode)
        ) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ["originalProductionNo"],
                message: `Original Production No is mandatory!`,
            });
        }

        // Collection is mandatory for Feature, Special, Extras, Short and Show
        if (
            (category === "Feature" || // TODO: use Category Tags instead of hardcoded values
                category === "Short" ||
                category === "Extras" ||
                category === "Special" ||
                category === Categories.Show) &&
            isEmpty(val?.collection)
        ) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ["collection"],
                message: `Collection is mandatory!`,
            });
        }

        // If user selects a display title, then the corresponding title is mandatory
        if (
            val?.displayTitle === displayTitleOptions[1]?.value &&
            isEmpty(val?.titles?.short)
        ) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ["titles.short"],
                message: `Short Title is mandatory because display title was set to ${val?.displayTitle}!`,
            });
        }

        if (
            val?.displayTitle === displayTitleOptions[2]?.value &&
            isEmpty(val?.titles?.sort)
        ) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ["titles.sort"],
                message: `Sort Title is mandatory because display title was set to ${val?.displayTitle}!`,
            });
        }

        if (
            val?.displayTitle === displayTitleOptions[3]?.value &&
            isEmpty(val?.titles?.alternative)
        ) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ["titles.alternative"],
                message: `Alternative Title is mandatory because display title was set to ${val?.displayTitle}!`,
            });
        }
    });

const additionalFieldsVS = allFieldsVS.pick({
    titles: true,
    descriptions: true,
    ids: true,
    other: true,
});
export type AdditionalFields = z.infer<typeof additionalFieldsVS>;

export const standaloneVS = primaryFieldsVS.merge(additionalFieldsVS);

export type StandaloneFields = z.infer<typeof standaloneVS> & RequiredFields;

export type TvShowFields = Omit<
    AllFields,
    | "parentShow"
    | "parentSeason"
    | "parentModularEpisode"
    | "seasonNo"
    | "episodeNo"
    | "originalProductionNo"
    | "numberOfEpisodes"
>;

export type SeasonFields = Omit<
    z.infer<typeof allFieldsVS>,
    | "parentSeason"
    | "parentModularEpisode"
    | "episodeNo"
    | "originalProductionNo"
>;
export type StorylineFields = AllFields;

export type EpisodeFields = AllFields;
