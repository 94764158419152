import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import { CoreTableCell } from "../../components/CoreTableCell";
import { CoreTableGridColDef } from "../../types";

const RenderCell = (params: GridRenderCellParams<string>) => {
    const elem = useMemo(
        () => (
            <CoreTableCell enableCopyToClipboard>{params?.value || ''}</CoreTableCell>
        ),
        [params?.value],
    );
    return elem;
};

export const CharacterNameCol: CoreTableGridColDef = {
    headerName: "Character",
    field: "characterName",
    flex: 1,
    maxWidth: 300,
    minWidth: 250,
    enableClipboardCopy: true,
    renderCell: (params: GridRenderCellParams<string>) => {
        return <RenderCell {...params} />;
    }
};
