import deepcopy from "deepcopy";
import _ from 'lodash';
import { envTest } from "../../../components/Environment";
import { ValidationResultInterface } from "../../SelectV2/interfaces";
import { ConfigOptionsInterface, FieldConfigInterface } from "../interfaces";
import transformBuilderPayload from "./utils/transformBuilderPayload/transformBuilderPayload";
import validateLength from "./utils/validateLength/validateLength";
import { ValidateRegionalPropsInterface } from "./interfaces";
import { userHasPermission } from "../../../services/PermissionService";
import { titlesSection } from "../../../features/UserRoleManagement/utils/sectionOptions/recordSections";
import { accessTypeUpdate, accessTypeView } from "../../../features/UserRoleManagement/utils/accessOptions";

const validateDisplayTitle = (props) => {
    const {
        status,
        titlesData = [],
        response,
    } = props;

    const displayTitle = titlesData.find(
        (item) => item.display && !item.deleted && item.title,
    );

    if (_.isEmpty(displayTitle)) {
        response.result = false;

        response.notification = {
            title: "Please check display title",
            description: "Display title is required",
            status,
        };

        response.validationKeys = response.validationKeys.concat([
            `title_title_display`,
        ]);
    }
};

export const validateTitles = (props: ValidateRegionalPropsInterface): ValidationResultInterface => {
    const {
        result,
        options,
    } = props;

    const {
        data,
        status,
        clientDataStructure = {},
        builderConfig,
        userPermissions,
        cmsData,
    } = options;

    const {
        titles: titlesData,
    } = data;

    const hasAccess = (accessType) => {
        return userHasPermission({
            cmsData,
            userPermissions,
            pathField: titlesSection.path,
            access: accessType,
            prefix: data.category,
        })
    }

    const response: ValidationResultInterface = deepcopy(result);

    if(
        (!hasAccess(accessTypeView) || !hasAccess(accessTypeUpdate))
        && !envTest
    ) {
        return response
    }

    let fieldsConfig: FieldConfigInterface[] = clientDataStructure.titles;

    if (!_.isEmpty(builderConfig)) {
        fieldsConfig = transformBuilderPayload(builderConfig.titles, 'title');
    }

    const configOptions: ConfigOptionsInterface = {
        data: titlesData,
        fieldType: 'title',
        fieldsConfig,
    };

    try {
        validateDisplayTitle({ titlesData, status, response });
        validateLength({ response, options, configOptions });
    } catch (error) {
        console.log(error);
    }

    return response;
};
