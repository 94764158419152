import SupportedLanguages from "./google-translate-supported-languages";
import GetLanguagesFromRegions from "./GetLanguagesFromRegions";
import _ from "lodash";

export default function GetMetaGoogleTranslateLanguages(props) {
    const { languages, top_written_languages = [] } = props;

    let result = [];

    const topWrittenLanguages = GetLanguagesFromRegions({
        languages: top_written_languages,
    });

    if (!_.isEmpty(languages) && !_.isEmpty(SupportedLanguages)) {
        result = languages.filter((item) =>
            SupportedLanguages.find((sp) => {
                let languageCode = "";

                if (typeof item.iso_639_1 == "string") {
                    languageCode = item.iso_639_1.toLowerCase();
                }

                if (
                    sp.locale == languageCode &&
                    topWrittenLanguages.find((twl) => item.value == twl.value)
                ) {
                    return true;
                }
            }),
        );
    }

    return result;
}
