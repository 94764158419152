import { SearchType, SearchTypes } from "../types/searchType";

export const searchTypesConfig: Record<SearchTypes, SearchType> = {
    records: {
        name: "discovery",
        label: "Discovery",
        recordLabel: "Record",
        recordsLabel: "Records",
        searchPlaceholder: "Search",
        newRecordLabel: "Create New Record",
        location: "records-search",
    },
    collections: {
        name: "collections",
        label: "Collections",
        recordLabel: "Collection",
        recordsLabel: "Collections",
        searchPlaceholder: "Search by Collection Title or ID",
        newRecordLabel: "Create New Collection",
        location: "collections",
    },
    localisations: {
        name: "localisations",
        label: "Localisations",
        recordLabel: "Localisation",
        recordsLabel: "Localisations",
        searchPlaceholder: "Search by Localisation Title or ID",
        newRecordLabel: "Create New Localisation",
        location: "localisations",
    },
    reports: {
        name: "reports",
        label: "Reports",
        recordLabel: "Report",
        recordsLabel: "Reports",
        searchPlaceholder: "Search by Report Title or ID",
        newRecordLabel: "Create New Report",
        location: "reporting",
    },
};
