import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/material/styles";

export const StyledPagination = styled(Pagination)(() => ({
    "ul.MuiPagination-ul > li > button": {
        backgroundColor: "transparent",
        color: "rgba(47, 47, 47, 0.87)",
        border: "none",
        padding: "0.188rem 0.625rem",
        fontSize: "0.875rem",
        fontWeight: 400,
        height: "1.5rem",
        minWidth: "1.75rem",
    },

    "ul.MuiPagination-ul > li": {
        "& .Mui-selected ": {
            backgroundColor: "rgba(40, 108, 210, 1)",
            color: "white",
            "&:hover": {
                backgroundColor: "rgba(40, 108, 210, 1)",
                color: "white",
            },
        },
        "&:hover": {
            button: {
                backgroundColor: "rgba(40, 108, 210, 0.1)",
                color: "rgba(47, 47, 47, 0.87)",
            },
        },
    },
}));
