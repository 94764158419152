import _ from "lodash";
import clientManagedController from "./clientManagedController";
import {
    StringPriorityTransformer,
    PRIORITY_KEYS_NAME_OR_TEXT,
} from "../Transformers/StringPriorityTransformer";

export function isClientManagedRightsLicensors(props) {
    const { clientFeatures = {} } = props;

    const { managedLists = [] } = clientFeatures;

    return managedLists.includes("rights_licensors");
}

const RightsLicensorsOptions = (props) => {
    const { options = {} } = props;

    let result = clientManagedController({
        clientManagedOptions: options.client_managed_rights_licensors || [],
        fallback: options.licensors || [],
        isClientManaged: isClientManagedRightsLicensors(props),
    });

    return result.map((item) =>
        StringPriorityTransformer({
            item,
            priority: PRIORITY_KEYS_NAME_OR_TEXT,
        }),
    );
};

export default RightsLicensorsOptions;
