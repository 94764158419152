import React from "react"
import { Tooltip, TooltipProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CoreTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip 
        {...props} 
        classes={{ popper: className }} 
        componentsProps={{
            tooltip: {
                sx: {
                    bgcolor: "#3B4462",
                    color: "#FFFFFF",
                    "& .MuiTooltip-arrow": {
                        color: "#3B4462",
                    },
                },
            },
        }}
    />
))(({ theme }) => ({
   
}));

CoreTooltip.defaultProps = {
    arrow: true,
    placement: "top",
};
