import React, { Fragment } from "react";
import "./styles/_styles.scss";
import _ from "lodash";
import Label from "./Label";
import CopyTo from "./CopyTo";
import NoneAddedLabel from "./NoneAddedLabel";

const GracenoteCertifications = (props) => {
    const { data, disabled, handleCopyToClick = () => {} } = props;

    const itemStyles = {
        marginBottom: "1.5rem",
    };

    const RenderRow = (item) => (
        <div className="certification-row">
            <div className="rating">{item.rating || "-"}</div>
            <span className="description text">{item.description || "-"}</span>
            <span className="body text">&bull; {item.rating_body || "-"}</span>

            {!disabled && (
                <CopyTo
                    margin="0 0 0 auto"
                    handleClick={() => handleCopyToClick(item)}
                />
            )}
        </div>
    );

    return (
        <div style={itemStyles}>
            <Label text="Certifications" styles={{ marginBottom: "1.5rem" }} />

            {_.isEmpty(data) && <NoneAddedLabel />}

            {!_.isEmpty(data) && (
                <div className="row-wrapper">
                    {data.map((item, index) => (
                        <RenderRow {...item} key={index} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default GracenoteCertifications;
