import {
    CONNECT,
    REPORT,
    LOCALISATION,
    TITLE,
    FRANCHISE,
} from "./CollectionType";
import CategoryTags from "../../../components/CategoryTags";

const GetPageTypeByCategory = (record: any): string => {
    const category = record.category || record.category_name;
    if (!category) {
        return "";
    }

    let pageType: string;
    switch (category) {
        case CategoryTags.Collection.short:
            pageType = TITLE;
            break;
        case CategoryTags.ConnectCollection.short:
            pageType = CONNECT;
            break;
        case CategoryTags.Report.short:
            pageType = REPORT;
            break;
        case CategoryTags.LocalisationOrder.short:
            pageType = LOCALISATION;
            break;
        case CategoryTags.FranchiseCollection.short:
            pageType = FRANCHISE;
            break;
        default:
            pageType = "";
    }

    return pageType;
};

export default GetPageTypeByCategory;
