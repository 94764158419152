import React from "react";
import Context from "./Context";
import DefaultState from "./DefaultState";
import HandleChange from "../../pages/src/HandleChange";
import HandleDisplayTitleValueChange from "../../pages/src/HandleDisplayTitleValueChange";
import HandleDisplayLanguageIsoCodeChange from "../../pages/src/HandleDisplayLanguageIsoCodeChange";
import HandleReleaseDisplayTitleChange from "../../pages/src/HandleReleaseDisplayTitleChange";
import HandleEditRecordClick from "../../pages/src/HandleEditRecordClick";
import HandleRegionalTitleSwitchChange from "../../pages/src/HandleRegionalTitleSwitchChange";
import HandleDeleteCollectionTitles from "../../pages/src/Collection/HandleDeleteCollectionTitles";
import HandleCollectionTitleCategoryChange from "../../pages/src/Collection/HandleCollectionTitleCategoryChange";
import HandleCollectionTitleQueryChange from "../../pages/src/Collection/HandleCollectionTitleQueryChange";
import HandleCollectionTitleQueryListSelection from "../../pages/src/Collection/HandleCollectionTitleQueryListSelection";
import HandleCollectionTitleSelectAll from "../../pages/src/Collection/HandleCollectionTitleSelectAll";
import HandleCollectionTitleSelection from "../../pages/src/Collection/HandleCollectionTitleSelection";
import HandleCollectionTitlesAdd from "../../pages/src/Collection/HandleCollectionTitlesAdd";
import HandleCollectionTitleSelectAllShowSpecials from "../../pages/src/Collection/HandleCollectionTitleSelectAllShowSpecials";
import HandleCollectionTitleSelectionShowSpecial from "../../pages/src/Collection/HandleCollectionTitleSelectionShowSpecial";
import HandleNewCollection from "../../pages/src/Collection/HandleNewCollection";
import HandleCollectionTitleSeasonSelect from "../../pages/src/Collection/HandleCollectionTitleSeasonSelect";
import HandleSearchFiltersChange from "../../pages/src/HandleSearchFiltersChange";
import HandleToggleSearchBoxFilters from "../../pages/src/HandleToggleSearchBoxFilters";
import HandleSetCollectionTitle from "../../pages/src/Collection/HandleSetCollectionTitle";
import HandleClearUrlSearch from "../../pages/src/HandleClearUrlSearch";
import HandleDeleteNewRecord from "../../pages/src/HandleDeleteNewRecord";
import HandleRecordSave from "../../pages/src/HandleRecordSave";
import HandleCreateNewRecordRequest from "../../pages/src/HandleCreateNewRecordRequest";
import HandleUpdateRecordRequest from "../../pages/src/HandleUpdateRecordRequest";
import HandleRecentActivityCategorySearch from "../../pages/src/HandleRecentActivityCategorySearch";
import HandleCollectionSearch from "../../pages/src/Collection/HandleCollectionSearch";
import HandleActiveRecordSet from "../../pages/src/HandleActiveRecordSet";
import HandleRecordMetaDataRequest from "../../pages/src/HandleRecordMetaDataRequest";
import HandleSetHover from "../../pages/src/HandleSetHover";
import HandleSetCollectionTitleKey from "../../pages/src/Collection/HandleSetCollectionTitleKey";
import HandleSelectedTitlesChange from "../../pages/src/Collection/HandleSelectedTitlesChange";
import HandleFetchCMSDataRequest from "../../pages/src/HandleFetchCMSDataRequest";
import HandleFetchOptionsRequst from "../../pages/src/HandleFetchOptionsRequst";
import HandleRecordDelete from "../../pages/src/HandleRecordDelete";
import HandleRecordReset from "../../pages/src/HandleRecordReset";
import Localisation from "../../pages/src/Collection/Localisation";
import GetRecordData from "../../pages/src/GetRecordData";
import HandleRecordDuplication from "../../pages/src/HandleRecordDuplication";
import UrlParser from "../../components/UrlParser";
import HandleFetchLinkagesRequest from "../../pages/src/HandleFetchLinkagesRequest";
import CategoryTags from "../../components/CategoryTags";
import { isActiveIoTSubscription } from "../../components/IoT";
import HandleRestoreRecordClick from "../../pages/src/Collection/HandleRestoreRecordClick";
import HandleRestoreRecordConfirm from "../../pages/src/Collection/HandleRestoreRecordConfirm";
import ApplySearchFilters from "../../pages/src/ApplySearchFilters";
import HandleSelectCollectionCategory from "../../pages/src/Collection/HandleSelectCollectionCategory";

export default class ContextState extends React.Component {
    constructor(props) {
        super(props);

        const {
            location,
            category,
            newRecordText,
            categoriesList = [],
            type,
            pageSecondaryType
        } = this.getOptions();

        this.state = {
            ...DefaultState,
            categoriesList,
            set: this.set.bind(this),
            handleSetCollectionTitle: (collectionTitle) =>
                HandleSetCollectionTitle({
                    collectionTitle,
                    state: this.state,
                }),
            handleChange: (key, data) =>
                HandleChange({
                    key,
                    data,
                    state: this.state,
                }),
            handleFormChange: (key, data) =>
                HandleChange({
                    key,
                    data,
                    state: this.state,
                }),
            handleStatusChange: (key, data, callback) =>
                HandleChange({
                    key,
                    data,
                    callback,
                    state: this.state,
                }),
            handleOnBlur: () => {},
            handleDisplayTitleValueChange: (title, value) =>
                HandleDisplayTitleValueChange({
                    title,
                    value,
                    state: this.state,
                }),
            handleDisplayLanguageIsoCodeChange: (locale) =>
                HandleDisplayLanguageIsoCodeChange({
                    locale,
                    state: this.state,
                }),
            handleReleaseDisplayTitleChange: (releaseTitle) =>
                HandleReleaseDisplayTitleChange({
                    releaseTitle,
                    state: this.state,
                }),
            handleEditRecordClick: (value) =>
                HandleEditRecordClick({
                    value,
                    state: this.state,
                }),
            handleRecordDelete: () =>
                HandleRecordDelete({
                    state: this.state,
                }),
            handleRecordSave: (options) =>
                HandleRecordSave({
                    options,
                    state: this.state,
                }),
            handleCreateNewRecordRequest: () =>
                HandleCreateNewRecordRequest({
                    state: this.state,
                    category,
                }),
            handleUpdateRecordRequest: (options) =>
                HandleUpdateRecordRequest({
                    ...options,
                    state: this.state,
                    category,
                }),
            handleSelectCollectionCategory: (options) =>
                HandleSelectCollectionCategory({
                    ...options,
                    state: this.state,
                }),
            handleRegionalTitleSwitchChange: (
                checked,
                title,
                key,
                section,
                subSection,
            ) =>
                HandleRegionalTitleSwitchChange({
                    checked,
                    title,
                    key,
                    section,
                    subSection,
                    state: this.state,
                }),
            handleDeleteCollectionTitles: (metaIds) =>
                HandleDeleteCollectionTitles({
                    metaIds,
                    state: this.state,
                    handleChange: HandleChange,
                }),
            handleCollectionTitleCategoryChange: (category) =>
                HandleCollectionTitleCategoryChange({
                    state: this.state,
                    category,
                }),
            handleCollectionTitleQueryChange: (options) =>
                HandleCollectionTitleQueryChange({
                    options,
                    state: this.state,
                }),
            handleCollectionTitleQueryListSelection: (querySelection) =>
                HandleCollectionTitleQueryListSelection({
                    querySelection,
                    state: this.state,
                }),
            handleCollectionTitleSelectionShowSpecial: (item) =>
                HandleCollectionTitleSelectionShowSpecial({
                    item,
                    state: this.state,
                }),
            handleCollectionTitleSelection: (item) =>
                HandleCollectionTitleSelection({
                    item,
                    state: this.state,
                }),
            handleCollectionTitlesAdd: () =>
                HandleCollectionTitlesAdd({
                    state: this.state,
                }),
            handleCollectionTitleSelectAll: () =>
                HandleCollectionTitleSelectAll({
                    state: this.state,
                }),
            handleCollectionTitleSelectAllShowSpecials: () =>
                HandleCollectionTitleSelectAllShowSpecials({
                    state: this.state,
                }),
            handleNewCollection: (selectedCategory = null) =>
                HandleNewCollection({
                    state: this.state,
                    selectedCategory: selectedCategory,
                }),
            handleCollectionTitleSeasonSelect: (selectedSeason) =>
                HandleCollectionTitleSeasonSelect({
                    selectedSeason,
                    state: this.state,
                }),
            toggleSearchBoxFilters: () =>
                HandleToggleSearchBoxFilters({
                    state: this.state,
                }),
            handleSearchFiltersChange: (item) =>
                HandleSearchFiltersChange({
                    item,
                    state: this.state,
                }),
            applySearchFilters: (item) =>
                ApplySearchFilters({
                    item,
                    state: this.state,
                }),
            handleClearUrlSearch: () =>
                HandleClearUrlSearch({
                    location,
                    state: this.state,
                }),
            handleDeleteNewRecord: () =>
                HandleDeleteNewRecord({
                    state: this.state,
                    category,
                }),
            handleCollectionSearch: (searchQuery) =>
                HandleCollectionSearch({
                    searchQuery,
                    state: this.state,
                    category: this.getCategoryQuery(),
                }),
            handleActiveRecordSet: (selectedRecord, keepSelected, callBack) =>
                HandleActiveRecordSet({
                    selectedRecord,
                    keepSelected,
                    callBack,
                    state: this.state,
                }),
            handleRecordMetaDataRequest: (callBack) =>
                HandleRecordMetaDataRequest({
                    callBack,
                    state: this.state,
                }),
            handleRecentActivitySearch: (category) =>
                HandleRecentActivityCategorySearch({
                    state: this.state,
                    category: this.getCategoryQuery(),
                }),
            handleSelectedTitlesChange: (selectedTitles) =>
                HandleSelectedTitlesChange({
                    selectedTitles,
                    state: this.state,
                }),
            setHoverRecord: (hoverRecord) =>
                HandleSetHover({
                    hoverRecord,
                    state: this.state,
                }),
            setCollectionTitleKey: (key, value) =>
                HandleSetCollectionTitleKey({
                    key,
                    value,
                    state: this.state,
                }),
            handleFetchOptionsRequst: () =>
                HandleFetchOptionsRequst({
                    state: this.state,
                }),
            handleResetRecordAndSearch: () => {
                HandleRecordReset(this.state),
                    this.state.handleRecentActivitySearch(category);
            },
            getRecordData: () =>
                GetRecordData({
                    state: this.state,
                }),
            handleRecordDuplication: (data) =>
                HandleRecordDuplication({
                    data,
                    state: this.state,
                }),
            handleFetchLinkagesRequest: (options) =>
                HandleFetchLinkagesRequest({
                    ...options,
                    state: this.state,
                }),
            handleLinkSearch: (selected, url) =>
                this.handleLinkSearch({
                    selected,
                    url,
                    state: this.state,
                }),
            handleRestoreRecord: (meta_id) =>
                HandleRestoreRecordClick({
                    meta_id,
                    state: this.state,
                }),
            handleRestoreRecordConfirm: (meta_id) =>
                HandleRestoreRecordConfirm({
                    meta_id,
                    state: this.state,
                }),
            handleRecordUpdateAfterIoT: () => this.handleRecordUpdateAfterIoT(),
            newRecordText,
            type,
            pageSecondaryType,
            ...Localisation.call(this),
            contextOptions: this.props.options || {},
            category,
        };
    }

    async componentDidMount() {
        HandleFetchCMSDataRequest({
            state: this.state,
            callBack: () => {
                this.state.handleFetchOptionsRequst();
                this.checkUrl(this.getCategoryQuery());
            },
        });
    }

    getCategoryQuery() {
        const { category, categoriesList = [] } = this.getOptions();

        return Array.isArray(categoriesList) && categoriesList.length > 0
            ? categoriesList.join(",")
            : category;
    }

    getOptions() {
        const { options = {} } = this.props;

        return options;
    }

    set(object, callback = () => {}) {
        this.setState(object, callback);
    }

    checkUrl(category) {
        if (process.env.NODE_ENV !== "test") {
            const urlComponents = this.setUrlComponents();

            if (!urlComponents.selected) {
                this.state.handleRecentActivitySearch(category);
            }

            if (urlComponents.selected) {
                HandleCollectionSearch({
                    searchQuery: urlComponents.selected,
                    state: this.state,
                    category,
                });
            }
        }
    }

    setUrlComponents() {
        if (typeof window == "object") {
            if (typeof window.location == "object") {
                const urlComponents = UrlParser(window.location);

                this.setState({
                    urlComponents,
                });

                return urlComponents;
            }
        }
    }

    handleLinkSearch(props) {
        const { url, selected } = props;

        if (typeof url.params == "string" && window && window.location) {
            const win = window.open(
                `/discovery/${selected}${url.params}`,
                "_blank",
            );
            return win.focus();
        }
    }

    handleRecordUpdateAfterIoT() {
        const {
            activeRecord = {},
            IoTConnection = {},
            recordChanges,
            handleRecordMetaDataRequest,
            handleFetchLinkagesRequest,
            recordData = {},
        } = this.state;

        const { category } = activeRecord;

        const active = isActiveIoTSubscription({
            IoTConnection,
            item: activeRecord,
        });

        if (!_.isEmpty(activeRecord) && _.isEmpty(recordChanges) && active) {
            switch (category) {
                case CategoryTags.Collection.short:
                    handleFetchLinkagesRequest({
                        meta_id: activeRecord.meta_id,
                        handleSuccess: (data) => {
                            let updatedState = { ...recordData };
                            updatedState.linkage = data;

                            this.setState({
                                recordData: updatedState,
                                IoTConnection: {},
                            });
                        },
                    });
                    break;

                default:
                    handleRecordMetaDataRequest();
            }

            return;
        }

        this.setState({
            IoTConnection: {},
        });
    }

    render() {
        return (
            <Context.Provider value={this.state}>
                {this.props.children}
            </Context.Provider>
        );
    }
}
