import React, { useState, useEffect } from "react";
import Input from "../../../../../Input/basic";
import Select from "../../../../../Select";
import {
    ASSET_CODES_CONFIG,
    ASSET_PLATFORMS_CONFIG,
    ASSET_TYPES_CONFIG,
} from "../../../configuration";
import { useStore } from "../../../state/context";
import _ from "lodash";
import { setNewAsset } from "../../../state/actions/assetCreate";
import AssetPlatformInterface from "../interfaces/AssetPlatformInterface";
import AssetTypeInterface from "../interfaces/AssetTypeInterface";
import "./styles/_styles.scss";
import AssetCodeInterface from "../interfaces/AssetCodeInterface";

type Props = {
    isRoot: boolean;
};

type FormData = {
    destination_platform: string;
    type: string;
    asset_name: string;
    asset_code: string;
    [propKey: string]: string;
};

const AssetCreateForm: React.FC<Props> = (props) => {
    const { isRoot = true } = props;

    const [store, dispatch] = useStore();

    const { configuration, permissionEdit } = store;

    const [formData, setFormData] = useState<FormData>({
        destination_platform: null,
        type: null,
        asset_name: null,
        asset_code: null,
    });

    useEffect(() => {
        let updatedState: FormData = {...formData}

        const platforms = GetPlatformList();
        const codes = GetCodesList();

        if (platforms[0]) {
            updatedState.destination_platform = platforms[0].value
        }

        if (codes[0]) {
            updatedState.asset_code = codes[0].value
        }

        setFormData(updatedState)
        dispatch(setNewAsset(updatedState))
    }, []);

    useEffect(() => {
        if (formData.destination_platform) {
            let updatedState: FormData = {...formData}

            const types = GetTypesList(updatedState.destination_platform)

            if (types[0]) {
                updatedState.type = types[0].value
            }

            setFormData(updatedState)
            dispatch(setNewAsset(updatedState))
        }
    }, [formData.destination_platform]);

    const handleChange = (key: string, value: string): void => {
        let data: FormData = Object.assign({}, { ...formData });
        data[key] = value;

        setFormData(data);
        dispatch(setNewAsset(data));
    };

    const GetPlatformList = (): Array<{ text: string; value: string }> => {
        const platformConfigs: Array<AssetPlatformInterface> =
            configuration[ASSET_PLATFORMS_CONFIG] || [];

        return platformConfigs
            .filter((item) => !item.archived)
            .map((item) => {
                return { text: item.displayName, value: item.key };
            });
    };

    const GetTypesList = (destination_platform: string): Array<{ text: string; value: string }> => {
        const typeConfigs: Array<AssetTypeInterface> =
            configuration[ASSET_TYPES_CONFIG] || [];

        return typeConfigs
            .filter((item) => !item.archived)
            .filter(
                (item) =>
                    item.destinationPlatform ==
                    destination_platform,
            )
            .filter((item) =>
                isRoot
                    ? _.get(item, "options.canBeRoot.enabled", false)
                    : true,
            )
            .map((item) => {
                return { text: item.displayName, value: item.code };
            });
    };

    const GetCodesList = (): Array<{ text: string; value: string }> => {
        const codeConfigs: Array<AssetCodeInterface> =
            configuration[ASSET_CODES_CONFIG] || [];

        if (_.isEmpty(codeConfigs)) {
            return [{ text: "MVP", value: "mvp" }]
        }

        return codeConfigs
            .filter((item) => !item.archived)
            .map((item) => {
                return { text: item.displayName, value: item.key };
            });
    };

    const IsAvailableField = (fieldName: string): boolean => {
        return !!configuration[ASSET_TYPES_CONFIG].find((item) => {
            if (item.code == formData.type) {
                return !!item.sections.find(
                    (section) =>
                        !section.archived &&
                        !!section.fields.find(
                            (field) => field.key == fieldName,
                        ),
                );
            }
        });
    };

    return (
        <div className="asset-create-form">
            <div className="form-field-wrapper">
                <Select
                    label="Destination Platform"
                    name="destination_platform"
                    id="destination-platform"
                    value={formData.destination_platform}
                    handleChange={(value) =>
                        handleChange("destination_platform", value)
                    }
                    data={GetPlatformList()}
                    required={true}
                    showNullOption={false}
                />
            </div>

            <div className="form-field-wrapper">
                <Select
                    label="Asset Type"
                    name="type"
                    id="asset-type"
                    value={formData.type}
                    handleChange={(value) => handleChange("type", value)}
                    data={GetTypesList(formData.destination_platform)}
                    required={true}
                    showNullOption={false}
                />
            </div>

            <div className="form-field-wrapper">
                <Select
                    label="Asset Code"
                    name="asset_code"
                    id="asset-code"
                    value={formData.asset_code}
                    handleChange={(value) => handleChange("asset_code", value)}
                    data={GetCodesList()}
                    showNullOption={false}
                />
            </div>

            <div className="form-field-wrapper">
                <Input
                    placeholder="Enter Asset Name"
                    label="Asset Name"
                    color="#000000"
                    disabled={!permissionEdit}
                    value={formData.asset_name}
                    handleChange={(value) => handleChange("asset_name", value)}
                    styles={{
                        boxShadow: "rgb(207, 206, 206) 0px 0.0625rem 0px 0px",
                    }}
                />
            </div>

            {IsAvailableField("ds_id") && (
                <div className="form-field-wrapper">
                    <Input
                        placeholder="Enter DSID"
                        label="DSID"
                        color="#000000"
                        handleChange={(value) => handleChange("ds_id", value)}
                        styles={{
                            boxShadow:
                                "rgb(207, 206, 206) 0px 0.0625rem 0px 0px",
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default AssetCreateForm;
