import deepcopy from 'deepcopy'
import { Credit, NewCredit } from '../../interfaces/Credit.interface'

const sortByRanking = (list: Credit[]): Credit[] => {
    let rankedList = deepcopy(list)

    if (Array.isArray(rankedList)) {
        const existingCredits: Credit[] = rankedList.filter((item: Credit) => item.ranking)
        const newCredits: NewCredit[] = rankedList.filter((item: Credit) => !item.ranking)

        rankedList = existingCredits.sort((creditA: Credit, creditB: Credit) => {
            return creditA.ranking - creditB.ranking
        })

        rankedList.push(...newCredits)
    }

    return rankedList
}

export default sortByRanking