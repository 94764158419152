import Attribute from "../Attribute";
import colors from "./colors";
import components from "./components";
import fonts from "./fonts";
import { GetFromLocalStorage } from "../Storage/localStorage";

let clientCMSData = {};
if (window && window.localStorage) {
    clientCMSData = GetFromLocalStorage("meta-client-cms-data") || {};
}

const { clientFeatures = {} } = clientCMSData;

const { buttonV2 = false } = clientFeatures;

let games = true;
let videos = true;
let assets = true;

if (
    !Attribute("#discovery", "data-feature-videos") ||
    Attribute("#discovery", "data-feature-videos") == "false"
) {
    videos = false;
}

if (
    !Attribute("#discovery", "data-feature-assets") ||
    Attribute("#discovery", "data-feature-assets") == "false"
) {
    assets = false;
}

if (process.env.NODE_ENV == "test") {
    games = true;
    assets = true;
}

let videoAssets = "/assets";
if (process.env.ASSETS_PATH) {
    videoAssets = process.env.ASSETS_PATH;
}

if (process.env.VIDEO_PATH_TESTING) {
    videoAssets = process.env.VIDEO_PATH_TESTING;
}

const defaultLoader = `${Attribute("body", "data-images") || ""}/svg/${
    buttonV2 ? "loading-dots-white" : "loading-black"
}.svg`;

const settings = {
    images: {
        path: Attribute("body", "data-images") || "",
        loadingBlack: defaultLoader,
        loadingWhite: `${
            Attribute("body", "data-images") || ""
        }/svg/loading-white.svg`,
        loadingDotsWhite: `${
            Attribute("body", "data-images") || ""
        }/svg/loading-dots-white.svg`,
        loadingDotsGrey: `${
            Attribute("body", "data-images") || ""
        }/svg/loading-dots-grey.svg`,
        loadingGrey: `${
            Attribute("body", "data-images") || ""
        }/svg/loading-grey.svg`,
        placeholder: `${
            Attribute("body", "data-images") || ""
        }/svg/placeholder-dark.svg`,
        placeholderDots: `${
            Attribute("body", "data-images") || ""
        }/svg/dots-grey-horizontal.svg`,
        posterIcon: `${
            Attribute("body", "data-images") || ""
        }/svg/star-light-grey.svg`,
        eidrLinkImage: `${
            Attribute("body", "data-images") || ""
        }/svg/eidr-link-grey.svg`,
        linkWhite: `${
            Attribute("body", "data-images") || ""
        }/png/link-white.png`,
        checkWhiteSmall: `${
            Attribute("body", "data-images") || ""
        }/png/check-white-small.png`,
        checkGreen: `${
            Attribute("body", "data-images") || ""
        }/png/check-green.png`,
        linkSmallGrey: `${
            Attribute("body", "data-images") || ""
        }/svg/link-small-grey.svg`,
        linkSmallDarkGrey: `${
            Attribute("body", "data-images") || ""
        }/svg/link-small-dark-grey.svg`,
        externalLinkSmall: `${
            Attribute("body", "data-images") || ""
        }/svg/external-link-small.svg`,
        posterPlaceholder: `${
            Attribute("body", "data-images") || ""
        }/svg/poster-placeholder.svg`,
    },
    recordImages: {
        path: Attribute("body", "data-record-images"),
    },
    recordVideos: {
        playerLicenseKey: Attribute("#discovery", "data-player-key"),
    },
    assets: {
        path: Attribute("body", "data-assets"),
    },
    tooltip: {
        delay: 1000,
        effect: "solid",
    },
    feature: {
        games,
        videos,
        assets,
    },
    bulkRecord: {
        limit: 200,
    },
    urls: {
        eidrGetUrl: Attribute("#discovery", "data-eidr-get-url"),
        eidrRegisterUrl: Attribute("#discovery", "data-eidr-register-url"),
        eidrRequestUrl: Attribute("#discovery", "data-eidr-request-url"),
        eidrStatusUrl: Attribute("#discovery", "data-eidr-status-url"),
    },
    videoAssets,
    container: {
        width: "75rem",
        maxWidth: "calc(100vw - 28.125rem)",
        minWidth: "75rem",
    },
    colors,
    components,
    fonts,
    svg: `${Attribute("body", "data-images")}/svg`,
};

export default settings;
