import React from 'react'
import { makeObservable, action, extendObservable } from 'mobx'
import update from "react-addons-update";
import { isEmpty, uniq } from "lodash"
import { RootStore } from './rootStore'
import { creditsStoreInitialState } from "./state"
import { CreditInterface, CreditRowInterface, CreditLocalisedData } from './interfaces';
import transformCreditLocalisedData from "../utils/transformCreditLocalisedData"
import { creditsByRecord } from "../test-react/testData"
import { apiCreditFields, apiCreditUpdateFields, apiCreditCreateFields } from "../config/credit.config"
import { envTest } from "../../../../components/Environment"
import { getRecordCredits, getCreditByLocale } from "../../../../services/Record/CreditsService"

export class CreditsStore {
    rootStore: RootStore;
    credits: CreditInterface[];
    originalCredits: CreditInterface[];
    fetching: boolean;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            initialize: action,
            setCredits: action,
            setOriginalCredits: action,
            setFetching: action,

            handleAddCredit: action,
            handleAddCreditsFromResponse: action,
            handleFetchRecordCredits: action,
            handleTransformCreditsForApi: action,
            handleFetchCreditData: action
        });

        extendObservable(this, creditsStoreInitialState);
    }

    initialize() {
        this.reset()
        this.setFetching(true)
    }

    reset() {
        Object.keys(creditsStoreInitialState).forEach(
            (key) => (this[key] = creditsStoreInitialState[key]),
        );
    }

    getCredits(): CreditInterface[] {
        return this.credits?.filter((credit: CreditInterface) => !credit.deleted)
    }

    getCreditsFromListOfCredits(credits: CreditInterface[]): CreditInterface[] {
        return credits?.filter((credit: CreditInterface) => !credit.deleted)
    }

    getCreditIndex(credit: CreditInterface): number {
        return credit?.id
            ? this.credits.findIndex((c: CreditInterface) => c.id === credit?.id)
            : this.credits.findIndex((c: CreditInterface) => c.role_id === credit?.role_id && c.contributor_id === credit?.contributor_id)
    }

    getCreditCharacterNames(credit: CreditInterface): string[] {
        return credit?.localised_data?.map((item: CreditLocalisedData) => {
            return item?.character_names.map((characterName) => characterName.name)
        })?.flat()
    }

    getCreditByRoleIdAndContributorId(credits: CreditInterface[], credit: CreditInterface): CreditInterface {
        return credits
            ?.filter((c: CreditInterface) => !c.deleted)
            ?.find((c: CreditInterface) => c.role_id === credit.role_id && c.contributor_id === credit.contributor_id)
    }

    getCreditsByRoleIdAndContributorId(credits: CreditInterface[], credit: CreditInterface): CreditInterface[] {
        return credits
            ?.filter((c: CreditInterface) => !c.deleted)
            ?.filter((c: CreditInterface) => c.role_id === credit.role_id && c.contributor_id === credit.contributor_id)
    }

    getCreditByCreditId(credits: CreditInterface[], credit: CreditInterface): CreditInterface {
        return !credit.id
            ? null
            : credits?.find((c: CreditInterface) => c.id === credit.id)
    }

    getCredit(credit: CreditInterface): CreditInterface {
        return this.getCreditByCreditId(this.credits, credit) || this.getCreditByRoleIdAndContributorId(this.credits, credit)
    }

    getTransformedTableCredit(tableCredit: CreditRowInterface): CreditInterface {
        return {
            id: tableCredit.creditID,
            role_id: tableCredit.roleID,
            contributor_id: tableCredit.contributorID
        }
    }

    getCreditLocales(): string[] {
        const locales = this.credits?.flatMap((credit: CreditInterface) =>
            credit?.localised_data?.map((ld: CreditLocalisedData) => ld?.locale) || []
        ) || [];

        return uniq(locales)
    }

    getCreditLocalisedDataByLocale(credit: CreditInterface, locale: string): CreditLocalisedData {
        return credit?.localised_data?.find((ld: CreditLocalisedData) => ld?.locale === locale)
    }

    setCredits(credits: CreditInterface[]): void {
        this.credits = credits
    }

    setOriginalCredits(originalCredits: CreditInterface[]): void {
        this.originalCredits = originalCredits
    }

    setFetching(fetching: boolean): void {
        this.fetching = fetching
    }

    isCreditInActiveCreditList(credit: CreditInterface): boolean {
        const creditListCredits: CreditInterface[] = this.rootStore.creditListsStore.getActiveCreditListCredits()

        if (this.getCreditByCreditId(creditListCredits, credit)) {
            return true
        }

        const creditByRoleIdAndContributorId: CreditInterface = this.getCreditByRoleIdAndContributorId(creditListCredits, credit)

        return creditByRoleIdAndContributorId
            ? true 
            : false
    }

    handleAddCredit(credit: CreditInterface): void {
        const credits: CreditInterface[] = [...this.credits, credit]

        this.setCredits(credits)
    }

    handleAddCreditsFromResponse(credits: CreditInterface[]): void {
        credits?.forEach((credit: CreditInterface) => {
            const validationMessage = this.rootStore.creditFormValidationStore.handleExistingCreditsValidation({
                credit
            })

            if (isEmpty(validationMessage)) {
                this.setCredits(
                    [...this.credits, credit]
                )
            }
        })
    }

    handleUpdateCredit(transformedCredit: CreditInterface, credit: CreditInterface): void {
        const index: number = this.getCreditIndex(credit)
 
        if (index !== -1) {
            const updatedState = update(this.credits, {
                [index]: {
                    $set: {
                        ...this.credits[index],
                        ...this.rootStore.handleTransformItem(apiCreditFields, transformedCredit)
                    }
                }
            })

            this.setCredits(updatedState)
        }
    }

    async handleFetchRecordCredits() {
        return envTest 
            ? this.handleFetchCreditsTest() 
            : this.handleFetchCreditsApi()
    }

    async handleFetchCreditData() {
        return envTest 
            ? this.handleFetchCreditDataTest() 
            : this.handleFetchCreditDataApi()
    }

    handleFetchCreditDataTest(): void {
        //
    }

    async handleFetchCreditDataApi() {
        for await (const credit of this.credits) {
            const availableCharacterNames: boolean = credit.locale == this.rootStore.optionsStore.languageCode && !isEmpty(credit.character_names)

            if (availableCharacterNames) {
                const creditWithLocalisedData: CreditInterface = transformCreditLocalisedData(credit, credit, this.rootStore.optionsStore.languageCode)
                this.handleUpdateCredit(creditWithLocalisedData, credit)
            }

            if (!availableCharacterNames && credit.id && !this.getCreditLocalisedDataByLocale(credit, this.rootStore.optionsStore.languageCode)) {
                const response = await getCreditByLocale(this.rootStore.globalState?.cmsData, credit.id, this.rootStore.optionsStore.languageCode)

                if (response.status === 200) {
                    const creditWithLocalisedData: CreditInterface = transformCreditLocalisedData(credit, response.data || {}, this.rootStore.optionsStore.languageCode)
                    this.handleUpdateCredit(creditWithLocalisedData, credit)
                } else {
                    this.rootStore.handleDispatchError(`Failed to fetch credit ${credit?.id || ''}`)
                }
            }
        }
    }

    handleFetchCreditsTest(): void {
        const credits: CreditInterface[] = creditsByRecord
        this.setCredits(credits)
    }

    async handleFetchCreditsApi() {
        // new record
        if (!this.rootStore.meta_id) {
            return 
        }

        const response = await getRecordCredits(this.rootStore.globalState?.cmsData, this.rootStore.meta_id)

        if (response.status === 200) {
            const credits: CreditInterface[] = this.rootStore.handleTransformItems(apiCreditFields, response.data || [])

            this.setCredits(credits)
            this.setOriginalCredits(credits)
        } else {
            this.rootStore.handleDispatchError("Failed to fetch credits, no credits found")
        }
    }

    handleDeleteCredit(tableCredit: CreditRowInterface): void {
        const credit: CreditInterface = this.rootStore.creditsStore.getCredit(
            this.rootStore.creditsStore.getTransformedTableCredit(tableCredit)
        )

        if (!credit) {
            return this.rootStore.handleDispatchError("Failed to delete credit. Credit not found.")
        }

        const index: number = this.getCreditIndex(credit)

        let updatedCredits: CreditInterface[] = [...this.credits]

        if (!credit.id) {
            // Hard delete
            updatedCredits = update(this.credits, {
                $splice: [[index, 1]]
            })
        } else {
            // Soft delete
            updatedCredits = update(this.credits, {
                [index]: {
                    deleted: {
                        $set: true
                    }
                }
            })

            delete updatedCredits[index]?.ranking
        }

        // this.handleUpdateCredit(updatedCredit, credit)
        this.setCredits(updatedCredits)
        this.rootStore.creditListsStore.handleDeleteCreditFromCreditLists(credit)
    }

    handleTransformCreditsForApi(credits: CreditInterface[]): CreditInterface[] {
        const items: CreditInterface[] = [...credits]

        return items.map((credit: CreditInterface) =>
            credit.id 
                ? this.rootStore.handleTransformItem(apiCreditUpdateFields, credit)
                : this.rootStore.handleTransformItem(apiCreditCreateFields, credit)
        );
    }
}