import React from "react";
import Component from "./index";

const TrippleCenterColor = (props) => {
    const {
        leftText,
        rightText,
        centerText,
        styles = {},
        customCenterTagStyles = {},
        color = "#838383",
        handleOnClick,
    } = props;

    return (
        <Component
            leftText={leftText}
            rightText={rightText}
            centerText={centerText}
            styles={styles}
            leftTagStyles={{
                background: "transparent",
                border: `0.065rem solid ${color}`,
                color,
                lineHeight: 1,
            }}
            centerTagStyles={{
                background: color,
                color: "white",
                lineHeight: 1,
                borderStyle: "solid",
                borderColor: color,
                borderWidth: `0.065rem`,
                borderLeft: 0,
                ...customCenterTagStyles,
            }}
            rightTagStyles={{
                borderStyle: "solid",
                borderColor: color,
                borderWidth: `0.065rem`,
                borderLeft: 0,
                background: "transparent",
                color,
                lineHeight: 1,
            }}
            handleOnClick={handleOnClick}
        />
    );
};

export default TrippleCenterColor;
