import GetLighterColor from "./GetLighterColor";

const GetRoleColor = props => {
    const {
        roleName = '',
        roleOptions = [],
        ranking = null
    } = props

    let defaultResult = '#ce758e'
    const optionsBackground = roleOptions.find(item => item.name == roleName)

    if(roleName == 'Director') {
        defaultResult = '#ba81cb'
    }

    if(roleName == 'Editor') {
        defaultResult = '#9b8dd5'
    }

    if(roleName == 'Executive Producer') {
        defaultResult = '#6994c9'
    }

    if(roleName == 'Music') {
        defaultResult = '#6dcff6'
    }

    if(roleName == 'Producer') {
        defaultResult = '#84f7c5'
    }

    if(roleName == 'Screenplay') {
        defaultResult = '#7dec80'
    }

    if(roleName == 'Writer') {
        defaultResult = '#bfe54a'
    }

    if(roleName == 'Cinematography') {
        defaultResult = '#EB984E'
    }

    if(roleName == 'Narrator') {
        defaultResult = '#EA502F'
    }

    if(roleName == 'Voice Over') {
        defaultResult = '#A135FB'
    }

    if(roleName == 'Voice') {
        defaultResult = '#9d1010'
    }

    if(roleName == 'Performer') {
        defaultResult = '#DEBC6C'
    }

    let result = defaultResult
    if(optionsBackground && typeof optionsBackground.colour_code == 'string' && optionsBackground.colour_code) {
        result = optionsBackground.colour_code
    }

    if (ranking && !!(ranking%2) ) {
        result = GetLighterColor(result)
    }

    return result
}

export default GetRoleColor