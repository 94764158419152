import AssetInterface from "../components/asset/interfaces/AssetInterface";
import deepcopy from "deepcopy";

const HandleUpdateAssetName = (
    asset: AssetInterface,
    name: string,
): AssetInterface => {
    let updatedAsset = deepcopy(asset);
    updatedAsset.asset_name = name;
    return updatedAsset;
};

export default HandleUpdateAssetName;
