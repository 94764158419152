import React, { useMemo } from "react";
import SectionContentWrapper from "../../../Boilerplates/SectionContentWrapper"
import ExpandableRow from "../../../ExpandableRow/v2";
import Header from "./views/Header.view"
import { levelOne, levelTwo, levelThree, expandableProps, labelColumnStyles } from "./constables"
import DoubleColumnContent from "../../../Boilerplates/DoubleColumnContent/DoubleColumnContent";
import "../../styles/_multi_level_expandable.scss"
import { 
    MultiLevelExpandableInterface, 
    ChildRowInterface, 
    ItemDataInterface, 
    FieldsInterface 
} from "./interfaces"

const ChildRow = ({ item, level, index, expandAll }: ChildRowInterface) => {
    return (
        <>
            <div className="multi-level-expandable--body-border"></div>

            <ExpandableRow
                header={
                    <Header 
                        {...item}
                        level={level}
                        expandAll={expandAll}
                    />
                }
                body={
                    <>
                        <div className="multi-level-expandable--body-border"></div>
                        <div className="multi-level-expandable--body">
                            <DoubleColumnContent 
                                fields={item.fields}
                                handleChange={() => {}}
                                labelColumnStyles={labelColumnStyles}
                            />
                        </div>
                    </>
                }
                bodyStyles={{ padding: 0, borderRadius: "0.375rem" }}
                borderRadius={true}
                className="record-builder--multi-level-expandable"
                headerClassName="record-builder--multi-level-expandable-header"
                defaultOpen={false}
                arrowIconType="large"
                key={`multi-level-expandable-${index}`}
            />
        </>
    )
}

const Fields = ({ fields }: FieldsInterface) => {
    return (
        <>
            <div className="multi-level-expandable--body-border"></div>
            <div className="multi-level-expandable--body">
                <DoubleColumnContent 
                    fields={fields}
                    handleChange={() => {}}
                    labelColumnStyles={labelColumnStyles}
                />
            </div>
        </>
    )
}

const MultiLevelExpandable = (props: MultiLevelExpandableInterface) => {
    const {
        className = '',
        data = [],
        expandAll
    } = props

    return (
        <div className="multi-level-expandable">
            {data.map((item: ItemDataInterface, index: number) => {
                return (
                    <SectionContentWrapper className={`multi-level-expandable--section ${className}`}>
                        <ExpandableRow
                            header={
                                <Header 
                                    {...item}
                                    level={levelOne}
                                    expandAll={expandAll}
                                />
                            }
                            body={
                                <>
                                    <Fields 
                                        fields={item.fields}
                                    />

                                    {item.children?.map((levelTwoChild: ItemDataInterface, levelTwoIndex: number) => {
                                        return (
                                            <>
                                                <div className="multi-level-expandable--body-border"></div>

                                                <ExpandableRow
                                                    header={
                                                        <Header 
                                                            {...levelTwoChild}
                                                            level={levelTwo}
                                                            expandAll={expandAll}
                                                        />
                                                    }
                                                    body={
                                                        <>
                                                            <Fields 
                                                                fields={levelTwoChild.fields}
                                                            />

                                                            {levelTwoChild.children?.map((levelThreeChild: ItemDataInterface, levelThreeIndex: number) => {
                                                                return (
                                                                    <ChildRow 
                                                                        item={levelThreeChild}
                                                                        index={levelThreeIndex}
                                                                        level={levelThree}
                                                                        expandAll={expandAll}
                                                                    />
                                                                )
                                                            })}
                                                        </>
                                                    }
                                                    key={`multi-level-expandable-${levelTwoIndex}`}
                                                    {...expandableProps}
                                                />
                                            </>
                                        )
                                    })}
                                </>
                            }
                            key={`multi-level-expandable-${index}`}
                            {...expandableProps}
                        />
                    </SectionContentWrapper>
                )
            })}
        </div>
    )
}

export default MultiLevelExpandable;
