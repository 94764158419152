const GetDisplayTitle = (props) => {
    const { titles = [] } = props;

    let result = "";

    if (Array.isArray(titles)) {
        const displayTitle = titles.find(
            (item) => item.display && item.title && !item.deleted,
        );

        if (displayTitle) {
            result = displayTitle;
        }
    }

    return result;
};

export default GetDisplayTitle;
