import { GridRenderCellParams } from "@mui/x-data-grid";
import { CoreTableGridColDef } from "../../types";
import {
    AwardsCustomChip,
    AwardsNominatedChip,
    AwardsShortlistedChip,
    AwardsWinnerChip,
} from "../AwardsTableChips";
import Settings from "../../../../components/Settings";

export const ResultsCol: CoreTableGridColDef = {
    headerName: "Results",
    field: "results",
    flex: 0.5,
    renderCell: (params: GridRenderCellParams<string>) => {
        const { value } = params;
        // FIXME: sanitize strings
        switch (value) {
            case "Winner": {
                return (
                    <AwardsWinnerChip
                        label={value}
                        icon={
                            <img
                                src={`${Settings.images.path}/svg/winner.svg`}
                                alt="Winner"
                            />
                        }
                    />
                );
            }
            case "Nomination":
            case "Nominated": {
                return (
                    <AwardsNominatedChip
                        label={value}
                        icon={
                            <img
                                src={`${Settings.images.path}/svg/nominated.svg`}
                                alt="Nominated"
                            />
                        }
                    />
                );
            }
            case "Shortlist":
            case "Shortlisted": {
                return (
                    <AwardsShortlistedChip
                        label={value}
                        icon={
                            <img
                                src={`${Settings.images.path}/svg/shortlisted.svg`}
                                alt="Shortlisted"
                            />
                        }
                    />
                );
            }
            case "Custom Award": {
                return <AwardsCustomChip label={value} />;
            }

            default: {
                return null;
            }
        }
    },
};
