import React, { useState } from "react";
import Body from "./body";
import Header from "./header";
import _ from "lodash";
import ExpandableRow from "../../../../ExpandableRow/v2";
import AssetInterface from "./interfaces/AssetInterface";
import SubDubInterface from "./interfaces/SubDubInterface";

type AssetRowProps = {
    asset: AssetInterface | SubDubInterface;
    hierarchyLevel?: number;
    isLastChild: boolean;
};

const AssetRow: React.FC = (props: AssetRowProps) => {
    const { asset, hierarchyLevel = 0, isLastChild = true } = props;

    const [expandAll, setExpandAll] = useState(false);

    return (
        <div className="asset-row">
            <ExpandableRow
                header={
                    <Header
                        asset={asset}
                        hierarchyLevel={hierarchyLevel}
                        isLastChild={isLastChild}
                        expandAll={expandAll}
                        setExpandAll={setExpandAll}
                    />
                }
                body={
                    <Body
                        asset={asset}
                        hierarchyLevel={hierarchyLevel}
                        isLastChild={isLastChild}
                        expandAll={expandAll}
                    />
                }
                bodyStyles={{ padding: 0, borderRadius: "0.375rem" }}
                borderRadius={true}
                className="asset-builder-expandale-asset"
                headerClassName="asset-builder-expandale-asset-header"
                defaultOpen={false}
                forceOpen={expandAll}
                arrowIconType="large"
            />
        </div>
    );
};

export default AssetRow;
