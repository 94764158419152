import CategoryTagsClientFeatures from "./ClientFeatures";

const Categories = (props) => {
    const { cmsData = {}, options = {}, target = "createRecord" } = props;

    const { clientFeatures = {}, clientDataStructure = {} } = cmsData;

    const { categories = [] } = options;

    let result = [];
    
    let categoryTags = CategoryTagsClientFeatures({clientFeatures, clientDataStructure})

    const IsOptionCategory = (category) => {
        return categories.find((cat) => cat.value == category);
    };

    Object.keys(categoryTags).forEach((key) => {
        if (key == categoryTags.Game?.short && !clientFeatures.games) {
            return;
        }

        if (categoryTags[key][target]) {
            result.push({
                text: categoryTags[key].long,
                value: categoryTags[key].short,
            });
        }
    });

    if (process.env.NODE_ENV === "test") {
        return result;
    }

    return result.filter((cat) => IsOptionCategory(cat.value));
};

export default Categories;
