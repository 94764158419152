import { styled } from "@mui/material/styles"

export const StyledExpandableTags = styled('div')({
    display: "inline-flex",
    width: "3rem",
    '& .MuiPaper-root': {
        borderRadius: 6,
        minWidth: 180,
        boxShadow: 'rgb(255, 255, 255) 0 0 0 0, rgba(0, 0, 0, 0.05) 0 0 0 1px, rgba(0, 0, 0, 0.1) 0 0.625rem 0.9375rem -0.1875, rgba(0, 0, 0, 0.05) 0 0.25rem 0.375rem -0.125rem',
        '& .MuiMenu-list': {
        	padding: '0.25rem 0',
        },
        '& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
			    fontSize: 18,
			},
        },
    },
})