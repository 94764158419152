import Categories from "./index";

const CollectionCategories = [
    Categories.Collection.short,
    Categories.ConnectCollection.short,
    Categories.Report.short,
    Categories.LocalisationOrder.short,
    Categories.FranchiseCollection.short,
];

export function isCollection(category) {
    return CollectionCategories.includes(category);
}

export default CollectionCategories;
