import Prefixer from "inline-style-prefixer";

export default function styles(props = {}) {
    let styles = {
        paragraph: {
            fontSize: props.fontSize || "1.05rem",
            fontWeight: props.fontWeight || "300",
            color: props.color || "#333333",
            lineHeight: props.lineHeight || 1.6,
            textAlign: props.textAlign || "left",
            width: props.width || "100%",
            maxWidth: props.maxWidth || "100%",
            margin: props.margin || "0 0 0.5rem 0",
            padding: props.padding || 0,
            textTransform: props.textTransform || "none",
            letterSpacing: props.letterSpacing || "initial",
            display: "block",
        },
        label: {
            fontSize: "0.9375rem",
            fontWeight: 500,
            lineHeight: 1,
            color: "#7a7a7a",
            marginBottom: "0.46875rem",
            display: "block",
        },
    };

    let prefixer = new Prefixer();
    styles = prefixer.prefix(styles);

    return styles;
}
