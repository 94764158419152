import React from 'react'
import { observer } from "mobx-react";
import { isEmpty } from 'lodash';
import { useForm } from "react-hook-form";
import ButtonBarContainer from "../../../../../containers/ButtonBarContainer"
import Button from "../../../../../components/Button"
import CustomSelect from "../../../../../components/CustomSelect/SelectWithMenuIcon";
import {
    CoreForm,
    CoreFormProvider,
    CoreFormThemeProvider,
} from "../../../../../core/Form/components/CoreForm";
import { useStores } from "../../store/rootStore";
import { CreditListInterface } from "../../store/interfaces";
import { LocaleCoreForm } from "../../../../../components/RecordBody/views/LocaleCoreForm"
import SelectV2 from "../../../../../components/Select/SelectV2";
import { Box } from '@mui/material';
import trimString from '../../utils/trimString';

const ActiveCreditListsBar = observer(() => {
    const { 
        optionsStore,
        permissionsStore,
        creditListsStore,
        activeCreditsStore,
        rootStore
    } = useStores();

    const form = useForm({
        defaultValues: {},
        mode: "onChange",
    });

    return (
        <>
            <CoreFormThemeProvider>
                <CoreFormProvider {...form}>
                    <CoreForm>
                        <ButtonBarContainer 
                            leftChildren={
                                <>
                                    <Box className="credit-list-select no-margin">
                                        <SelectV2
                                            disabledProp={rootStore.disabled}
                                            dataList={creditListsStore.getFilteredCreditLists()}
                                            defaultValue={creditListsStore.selectedCreditList?.code}
                                            forceValue={creditListsStore.selectedCreditList?.code}
                                            setSelectedValue={(id: string) => rootStore.handleCreditListChange(id)}
                                            handleAddNew={() => rootStore.setShowCreditListCreateForm(true)}
                                            handleEdit={(creditList: CreditListInterface) => rootStore.handleCreditListEdit(creditList)}
                                            handleDelete={(creditList: CreditListInterface) => creditListsStore.handleDeleteCreditList(creditList)}
                                            renderValue={() => !creditListsStore.fetching 
                                                ? `${trimString(creditListsStore.selectedCreditList?.name || '', 20)} • ${creditListsStore.selectedCreditList?.code}` 
                                                : 'Fetching Credit Lists...'
                                            }
                                            actions={permissionsStore.userHasUpdatePermission || permissionsStore.userHasDeletePermission}
                                            enableDelete={!rootStore.disabled && permissionsStore.userHasDeletePermission}
                                            enableUpdate={!rootStore.disabled && permissionsStore.userHasUpdatePermission}
                                            addNew={!rootStore.disabled && permissionsStore.userHasCreatePermission}
                                            addNewText="Add New Credit List..."
                                            wrapperStyles={{
                                                margin: "0",
                                                minWidth: "17.24rem"
                                            }}
                                            dataTestId="credit-list-select"
                                            loading={creditListsStore.fetching}
                                            showLoadingIcon={true}
                                        />
                                    </Box>

                                    <Box className="locale-select">
                                        <LocaleCoreForm
                                            options={optionsStore.languageOptions}
                                            onChange={(languageCode: string) => rootStore.handleLocaleChange(languageCode)}
                                            value={optionsStore.languageCode}
                                            key={`locale-${optionsStore.languageCode}`}
                                        />
                                    </Box>

                                    <CustomSelect 
                                        disabled={false} 
                                        actionsList={[
                                            {
                                                text: 'Delete Credits',
                                                onClick: () => activeCreditsStore.handleDeleteCreditsInBulk(),
                                                disabled: rootStore.disabled 
                                                    || !permissionsStore.userHasDeletePermission 
                                                    || isEmpty(activeCreditsStore.selected)
                                            },
                                            {
                                                text: 'Move to Inactive',
                                                onClick: () => activeCreditsStore.handleMoveCreditsToInactiveInBulk(),
                                                disabled: rootStore.disabled 
                                                    || !permissionsStore.userHasUpdatePermission
                                                    || isEmpty(activeCreditsStore.selected)
                                            }
                                        ]} 
                                    />
                                </>
                            }
                            rightChildren={
                                <>                    
                                    <Button
                                        value="New Credit"
                                        type="button"
                                        id="add-new-contributor--button"
                                        className="add-new-contributor--button"
                                        disabled={rootStore.disabled || !permissionsStore.userHasCreatePermission}
                                        disabledBackground="#E4E4E4"
                                        disabledColor="#ACACAC"
                                        recordAction={true}
                                        onClick={() => rootStore.setShowCreditCreateForm(true)}
                                        styles={{
                                            margin: 0
                                        }}
                                    />
                                </>
                            }
                        />
                    </CoreForm>
                </CoreFormProvider>
            </CoreFormThemeProvider>
        </>
    )
})

export default ActiveCreditListsBar