export type NameComponents = {
    name: string
    first_name: string
    last_name: string
    middle_name: string
}

export default function(name: string): NameComponents | Error {

    const nameComponents: NameComponents = {
        name: name,
        first_name: "",
        last_name: "",
        middle_name: ""
    };

    const words = name.trim().split(/\s+/);

    if (!words[0].length) return new Error(
        "Credits parseName function Error: Name must not be empty or only whitespace", 
        { 
            cause: nameComponents
        }
    );

    const numWords = words.length;
    if (numWords === 1) {
        nameComponents.first_name = words[0];
    } else if (numWords === 2) {
        nameComponents.first_name = words[0];
        nameComponents.last_name = words[1];
    } else {
        nameComponents.first_name = words[0];
        nameComponents.last_name = words[numWords - 1];
        nameComponents.middle_name = words.slice(1, numWords - 1).join(" ");
    }

    return nameComponents;
}