import { styled } from "@mui/material/styles"

interface StyledFormProps {
    columnSpacing?: string
    rowSpacing?: string
}

export const StyledForm = styled('div')<StyledFormProps>((props) => {
    return {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        marginTop: "1.5rem",

        ".form-item": {
            marginTop: props.rowSpacing ? props.rowSpacing : 0,
            width: "100%",

            "&.half": {
                width: `calc(50% - ${props.columnSpacing || "1rem" })`,
            },
            "&.fourth": {
                width: `calc(25% - ${props.columnSpacing || "1rem" })`,
            },
            "&.mt-1": {
                marginTop: "1rem"
            }
        }        
    }
})