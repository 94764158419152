import React from "react";
import Main from "./src/Main";
import { AgeGuidanceProvider } from "./store/context";
import EntryPropertiesInterface from "./interfaces/EntryProperties.interface";
import TransformRegionalDataIncome from "./src/transformers/TransformRegionalDataIncome";

const AgeGuidance: React.FC<EntryPropertiesInterface> = (
    props,
): JSX.Element => {
    return (
        <AgeGuidanceProvider
            {...props}
            age_guidance={TransformRegionalDataIncome(props.age_guidance || [])}
        >
            <Main />
        </AgeGuidanceProvider>
    );
};

export default AgeGuidance;
