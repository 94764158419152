import { ListItem } from "../../../Boilerplates/DoubleColumnContent/ContentField.interface"
import { BooleanDisplayProperty } from '../../../../models/RecordBuilder/Field.interface'

type Props = {
    value?: boolean,
    booleanDisplay?: BooleanDisplayProperty,
}

const mapBooleanDisplayProperty = ({ value = false,  booleanDisplay }: Props): {
    list: ListItem[];
    value: ListItem;
} => {

    const { trueLabel = "True", falseLabel = "False" } = booleanDisplay;

    let list: ListItem[] = [
        { text: trueLabel, value: 1 },  
        { text: falseLabel, value: 0 },
    ];

    let valueObject: ListItem = { text: falseLabel, value: 0 };

    if (typeof value === "boolean") {
        valueObject.value = Number(value);
    }

    valueObject.text = valueObject.value ? trueLabel : falseLabel;

    return {
        list,
        value: valueObject,
    };
}

export default mapBooleanDisplayProperty
