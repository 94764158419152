import React from 'react'
import { makeObservable, action, extendObservable } from 'mobx'
import { isEmpty, uniq } from "lodash"
import { RootStore } from './rootStore'
import { optionsStoreInitialState } from "./state"
import { envTest } from "../../../../components/Environment"
import { getDefaultWrittenLanguageCodeFromCache } from "../../../../models/ClientManaged/languagesModel"
import { CoreSelectOptionType } from "../../../../core/Form/components/inputs/Select/types/CoreSelectOptionType";
import fetchClientManagedOptions from "../../../../components/ClientManagedOptions/fetchClientManagedOptions";
import { CAST_AND_CREW } from "../../../../components/ClientManagedOptions/options";
import { languagesTransformer, rightAdornmentTransformer } from "../../../../models/ClientManaged/languagesModel"
import { LanguageInterface } from "../../../../models/ClientManaged/languagesInterface"
import { castAndCrewLanguages } from "../test-react/testData"
import { APIRoleOptionInterface } from "../../v3/credits.interface";
import { roleNameActor } from "../utils/constants"
import { getRoles } from "../../../../services/Record/CastAndCrew/getRoles";
import { getRecordLanguages } from "../../../../services/Record/CreditsService";

export class OptionsStore {
    rootStore: RootStore;
    languageCode: string;
    languageOptions: CoreSelectOptionType[];
    roles: APIRoleOptionInterface[];
    creditLocales: string[]

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            initialize: action,
            setLanguageCode: action,
            setLanguageOptions: action,
            setRoles: action,
            setCreditLocales: action,

            getLanguageOptions: action,
            handleSetDefaultLanguageCode: action,
            handleSetRoles: action,
            handleFetchCreditLocales: action,
            handleSetLanguageOptionsWithLocales: action,
        });

        extendObservable(this, optionsStoreInitialState);
    }

    async initialize() {
        this.handleSetDefaultLanguageCode()

        await this.handleFetchCreditLocales()

        envTest 
            ? this.handleSetLanguageOptionsTest()
            : this.handleSetLanguageOptions()
    }

    getActorRoleId(): string {
        const id: number = this.roles?.find(role => role.name === roleNameActor)?.id

        return id?.toString() || ''
    }

    getLanguageOptions(): any[] {
        return []
    }

    setLanguageCode(languageCode: string): void {
        this.languageCode = languageCode
    }

    setLanguageOptions(languageOptions: CoreSelectOptionType[]): void {
        this.languageOptions = languageOptions
    }

    setRoles(roles: APIRoleOptionInterface[]): void {
        this.roles = roles
    }

    setCreditLocales(creditLocales: string[]): void {
        this.creditLocales = creditLocales
    }

    async handleSetRoles() {
        return await getRoles(this.rootStore.globalState?.cmsData).then((res: any) => this.setRoles(res.data))
    }

    handleSetDefaultLanguageCode(): void {
        this.setLanguageCode(
            getDefaultWrittenLanguageCodeFromCache()
        )
    }

    handleSetLanguageOptionsTest(): void {
        this.setLanguageOptions(
            languagesTransformer(castAndCrewLanguages)
        )
    }

    async handleFetchCreditLocales() {
        return envTest
            ? this.handleFetchCreditLocalesTest()
            : this.handleFetchCreditLocalesApi()
    }

    handleFetchCreditLocalesTest() {
        this.setCreditLocales([
            'ENG',
            'JPN'
        ])
    }

    async handleFetchCreditLocalesApi() {
        const response = await getRecordLanguages(this.rootStore.globalState?.cmsData, this.rootStore.meta_id)

        if (response.status == 200 && !isEmpty(response.data)) {
            this.setCreditLocales(response.data)
        }

        return response
    }

    handleSetLanguageOptions(): void {
        fetchClientManagedOptions({
            clientManagedType: CAST_AND_CREW,
            apiUrls: this.rootStore.globalState?.cmsData?.apiUrls || {},
            clientFeatures: this.rootStore.globalState?.cmsData?.clientFeatures || {},
            testingData: castAndCrewLanguages,
            onResponse: (items: LanguageInterface[]) => {
                this.setLanguageOptions(
                    languagesTransformer(items, this.creditLocales)
                )
            },
            onError: () => {},
        });
    }

    handleSetLanguageOptionsWithLocales(): void {
        const locales: string[] = [
            ...this.creditLocales,
            ...this.rootStore.creditsStore.getCreditLocales()
        ]

        this.setLanguageOptions(
            rightAdornmentTransformer(this.languageOptions, uniq(locales))
        )
    }
}
