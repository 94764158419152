import FieldInterface, { InputType } from '../interfaces/Field.interface'
import { TextInput, TextInputType } from './inputs/Text.input';
import { MultipleSelect } from './inputs/MultipleSelect.input'
import { Select } from './inputs/Select.input'
import { BooleanRadio } from './inputs/BooleanRadio.input';
import { BooleanToggle } from './inputs/BooleanToggle.input';
import { BooleanCheckbox } from './inputs/BooleanCheckbox.input';
import { MultipleSelectChips } from './inputs/MultipleSelectChips.input';
import { Duration } from './inputs/Duration.input';
import { DatePicker } from "./inputs/DatePicker.input"
import { PhoneNumber } from "./inputs/PhoneNumber.input"
import { Autocomplete } from './inputs/Autocomplete.input'
import { AutocompleteContributors } from './inputs/special/AutocompleteContributors.input'
import { Skeleton } from "@mui/material";
import { CoreFormLabel } from "../../components/styled/CoreForm.label";
import { DateTimePicker } from './inputs/DateTimePicker.input'
import { TimePicker } from './inputs/TimePicker.input';
import { ColorPalette } from './inputs/ColorPalette.input';

type Props = {
    field: FieldInterface
}

const CoreDynamicInput = ({ field }: Props) => {
    if (field.loading) {
        return (
            <div className="dynamic-form--loading">
                <CoreFormLabel  
                    text={field.label}
                    isRequired={field.validation?.required?.enabled}
                />

                <Skeleton height="2.5rem" />
            </div>
        )
    }

    switch(field.type.input) {
        case InputType.BooleanRadio:
            return (
                <BooleanRadio field={field} />
            )
        case InputType.BooleanToggle:
            return (
                <BooleanToggle field={field} />
            )
        case InputType.BooleanCheckbox:
            return (
                <BooleanCheckbox field={field} />
            )
        case InputType.Select:
            return (
                <Select field={field} />
            )
        case InputType.SelectFramed:
            return (
                <Select field={field} framed={true} />
            )
        case InputType.SelectMultiple:
            return (
                <MultipleSelect field={field} />
            )
        case InputType.SelectMultipleChips:
            return (
                <MultipleSelectChips field={field} />
            )
        case InputType.Duration:
            return (
                <Duration field={field} />
            )
        case InputType.Autocomplete:
            return (
                <Autocomplete field={field} />
            )
        case InputType.PhoneNumber:
            return (
                <PhoneNumber field={field} />
            )
        case InputType.ColorPalette:
            return (
                <ColorPalette field={field} />
            )
        case InputType.Time:
            return (
                <TimePicker field={field} />
            )
        case InputType.Date:
        case InputType.Year:
            return (
                <DatePicker field={field} />
            )

        case InputType.DateTime:
            return (
                <DateTimePicker field={field} />
            )
        case InputType.Text:
        case InputType.TextArea:
        case InputType.Alphanumeric:
            return (
                <TextInput field={field} />
            )
        case InputType.TextFramed:
            return (
                <TextInput field={field} framed={true} />
            )

        case InputType.Numeric:
            return (
                <TextInput field={field} type={TextInputType.Number} />
            )
        
        // Special Components
        case InputType.AutocompleteContributors:
            return (
                <AutocompleteContributors field={field} />
            )

        default:
            return (
                <TextInput field={field} />
            )
    }
}

export default CoreDynamicInput