import React from "react";
import Tag from "../../Tag";
import CategoryTags from "../../CategoryTags";

const RenderCategoryTag = (category) => {
    const categoryTag = CategoryTags[category.replace(/\s/g, "")];

    if (categoryTag) {
        return (
            <Tag
                text={categoryTag.long}
                background={categoryTag.color}
                className="meta-numbered-record-table--tags"
            />
        );
    }

    return false;
};

export default RenderCategoryTag;
