import Clients from "../../Clients";

import {
    headerTimeFormat as defaultTimeFormat,
    activeConfig as defaultActiveConfig,
    inactiveConfig as defaultInactiveConfig,
    scheduleInformationSectionConfig as defaultScheduleInformationSectionConfig,
    scheduleWindowSectionConfig as defaultScheduleWindowSectionConfig,
    HeaderTags as defaultHeaderTags,
} from './defaultConfig';

import {
    bbcScheduleInformationSectionConfig,
    bbcScheduleWindowSectionConfig
} from './bbc-config'

let headerTimeFormat = defaultTimeFormat;
let activeConfig = defaultActiveConfig;
let inactiveConfig = defaultInactiveConfig;
let HeaderTags = defaultHeaderTags;
let scheduleInformationSectionConfig = defaultScheduleInformationSectionConfig;
let scheduleWindowSectionConfig = defaultScheduleWindowSectionConfig;

if (Clients.isBBCStudios()) {
    scheduleInformationSectionConfig = bbcScheduleInformationSectionConfig;
    scheduleWindowSectionConfig = bbcScheduleWindowSectionConfig;
}

export {
    headerTimeFormat,
    activeConfig,
    inactiveConfig,
    HeaderTags,
    scheduleInformationSectionConfig,
    scheduleWindowSectionConfig
}

