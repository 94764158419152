import Prefixer from "inline-style-prefixer";

export default function styles(props = {}) {
    let styles = {
        container: {
            default: {
                width: "100%",
                minHeight: "100%",
                minHeight: "100vh",
                background: props.background || "#C3C3C3",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                padding: "6rem 2rem",
                position: "relative",
            },
            metaconnect: {
                background: "#2b2b2b",
            },
        },
        versionContainer: {
            position: "absolute",
            bottom: "1rem",
            left: "1rem",
        },
        trademark: {
            default: {
                display: "block",
                fontSize: "1.5625rem",
                color: "#4D5656",
                fontWeight: 700,
            },
            metaconnect: {
                color: "white",
            },
        },
        version: {
            default: {
                display: "block",
                textTransform: "capitalize",
                fontSize: "1.175rem",
                color: "#626567",
            },
            metaconnect: {
                color: "#848484",
            },
        },
        content: {
            default: {
                width: "100%",
                maxWidth: props.width || "23.46875rem",
                background: props.contentBackground || "#292929",
                borderRadius: "0.5rem",
            },
            metaconnect: {
                background: "#1b1b1b",
            },
        },
    };

    let prefixer = new Prefixer();
    styles = prefixer.prefix(styles);

    return styles;
}
