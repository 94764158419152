import _ from "lodash";

const ConvertExtraInfoToString = (value: any): string => {
    if (!value) {
        return "";
    }
    if (Array.isArray(value)) {
        let filteredValue = value.filter(item => !item.deleted)
        return _.compact(_.map(filteredValue, "name")).join(", ");
    }
    if (typeof value === "object" && !value.deleted) {
        return value.name || value.text;
    }
    return String(value);
};
export default ConvertExtraInfoToString;
