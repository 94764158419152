import { useLanguageByCode } from "../hooks/useLanguageByCode";
import { RenderInfo } from "../../../core/Table/components/RenderInfo";

export const RenderOriginalLanguage = (params) => {
    return (
        <RenderInfo
            rightChipValue={params?.value}
            useData={useLanguageByCode}
            label="Language"
        />
    );
};
