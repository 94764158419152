import React, { Fragment } from "react";
import "./styles/_styles.scss";
import _ from "lodash";
import Label from "./Label";
import CopyTo from "./CopyTo";
import NoneAddedLabel from "./NoneAddedLabel";
import KeywordLabel from "../KeywordLabel";

const GracenoteVersions = (props) => {
    const { data, disabled, handleCopyToClick = () => {} } = props;

    const itemStyles = {
        marginBottom: "1.5rem",
    };

    const RenderRow = (item) => (
        <div className="versions-row">
            <KeywordLabel
                title="TMS ID"
                background="#d7d7d7"
                color="#3c3c3c"
                weighting={item.from_tms_id}
                weightingBackground="#B0B0B0"
                weightColor="#2F2F2F"
            />
            <span className="title text">{item.title || "-"}</span>

            {!disabled && (
                <CopyTo
                    margin="0 0 0 auto"
                    handleClick={() => handleCopyToClick(item)}
                    color="red"
                />
            )}
        </div>
    );

    return (
        <div style={itemStyles}>
            <Label text="Other Versions" styles={{ marginBottom: "1.5rem" }} />

            {_.isEmpty(data) && <NoneAddedLabel />}

            {!_.isEmpty(data) && (
                <div className="row-wrapper">
                    {data.map((item, index) => (
                        <RenderRow {...item} key={index} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default GracenoteVersions;
