import React from "react";
import "./styles/_styles.scss";
import ContentLoading from "../ContentLoading";
import deepcopy from "deepcopy";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

const SortableTable = (props) => {
    const {
        items = [],
        styles = {},
        itemStyles = {},
        inactive = false,
        disabled,
        handleSort,
        loading,
        borderRadiusTop = false,
        borderRadiusBottom = false,
        indexStartFrom = false,
        scrollableContainer,
        useCommonBorderRadius = true,
        helperClass = "sortableTableItem",
        lockAxis = "y"
    } = props;

    let sortableOptions = {
        axis: "xy",
        lockAxis: lockAxis,
        lockToContainerEdges: true,
        lockOffset: "0%",
        helperClass,
        distance: 10,
        hideSortableGhost: true,
        useWindowAsScrollContainer: true,
    };

    const SortableList = SortableContainer(() => {
        return (
            <div
                className={`meta-sortable-table 
				${borderRadiusTop ? "border-radius-top" : ""} 
				${borderRadiusBottom ? "border-radius-bottom" : ""}
				${useCommonBorderRadius ? "border-radius" : ""}
			`}
                style={styles}
            >
                {items.map((item, index) => {
                    const sortableItem = {
                        ...item,
                        index,
                    };

                    return (
                        <SortableItem
                            item={sortableItem}
                            key={`item-${index}`}
                            disabled={disabled}
                            index={index}
                        />
                    );
                })}

                {loading && (
                    <div className="meta-sortable-table--loading">
                        <ContentLoading
                            loadingDotsGrey={true}
                            margin="0"
                            styles={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        />
                    </div>
                )}
            </div>
        );
    });

    const SortableItem = SortableElement(({ item }) => {
        let itemStylesCopied = deepcopy(itemStyles);
        if (indexStartFrom && Number.isInteger(indexStartFrom)) {
            if ((indexStartFrom + item.index) % 2 == 0) {
                itemStylesCopied.background = "#f5f5f5";
            }
        }
        return (
            <div
                className="meta-sortable-table--item noselect"
                style={itemStylesCopied}
            >
                {item}
            </div>
        );
    });

    return (
        <SortableList
            onSortEnd={(data) => (!inactive ? handleSort(data) : null)}
            {...sortableOptions}
        />
    );
};

export default SortableTable;
