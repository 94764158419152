import _ from "lodash";
import { CLIENT_MANAGED_RECORD_STATUSES } from "../../ClientManagedOptions/options";
import { GetFromLocalStorage } from "../../Storage/localStorage";

const BubleListSearchDataList = (props) => {
    const { searchDataList = [], clientFeatures = {} } = props;

    if (!Array.isArray(searchDataList)) {
        return [];
    }

    const { recordStatusFlag } = clientFeatures;
    let recordStatuses = [];

    if (recordStatusFlag) {
        const localRecordStatuses = GetFromLocalStorage(
            CLIENT_MANAGED_RECORD_STATUSES,
        );

        if (localRecordStatuses) {
            const parsedRecordStatuses = JSON.parse(localRecordStatuses);
            recordStatuses = parsedRecordStatuses.filter(
                (item) => item.flagged,
            );
        }
    }

    return searchDataList.map((item) => {
        const {
            original_title,
            metaId,
            first_release_year,
            origination = {},
            status = "",
            duplication = {},
        } = item;

        const { producers = [] } = origination;

        const flagedStatus =
            recordStatuses.find((rs) => rs.value == status) || {};

        const text = original_title;
        let result = {
            ...item,
            text,
            value: metaId,
        };

        if (Number.isInteger(first_release_year)) {
            result.text = `${text} • ${first_release_year}`;
        }

        if (!_.isEmpty(producers) && producers[0] && producers[0].text) {
            result.text = `${result.text}`;
            result.postfix = ` • ${producers[0].text}`;
        }

        if (recordStatusFlag) {
            if (status && !_.isEmpty(flagedStatus)) {
                result.postfixBold = ` • ${flagedStatus.text}`;
            }

            if (duplication && !_.isEmpty(duplication.duplicated_from)) {
                result.postfixBold = ` • Duplicate`;
            }
        }

        return result;
    });
};

export default BubleListSearchDataList;
