import Prefixer from "inline-style-prefixer";

export default function styles(props = {}) {
    let styles = {
        container: {
            display: "inline-block",
            width: props.width || "50%",
            display: "flex",
            alignItems: "center",
        },
        image: {
            width: "0.75rem",
            margin: "0 0.1875rem 0 0",
        },
    };

    let prefixer = new Prefixer();
    styles = prefixer.prefix(styles);

    return styles;
}
