interface ValidationInterface {
    description: string;
    type: string;
    paths: Array<string>;
}

export const validationTypeInclude: string = "include";

export const policiesValidation: ValidationInterface[] = [
    {
        description: "Discovery & catalog permission must be set",
        type: validationTypeInclude,
        paths: ["catalogs", "record"],
    },
];
