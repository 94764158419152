import _ from "lodash";
import GetStamp from "../../components/SendRequest/Stamp";
import SendRequest from "../../components/SendRequest";
import UrlParser from "../../components/UrlParser";
import HandleActiveRecordSet from "./HandleActiveRecordSet";

const HandleRecentActivityCategorySearch = (props) => {
    const { state = {}, category = "" } = props;

    const { set, searchQuery, cmsData = {} } = state;

    const { apiUrls = {} } = cmsData;

    const stamp = GetStamp();
    const urlCategory = category.toLowerCase();

    let url = `${apiUrls.searchWithCategory}?category=${urlCategory}&with_deleted=1`;
    if (searchQuery) {
        url = `${apiUrls.searchWithCategory}?category=${urlCategory}&s=${searchQuery}&with_deleted=1`;
    }

    const options = {
        url,
        method: "GET",
        body: false,
        updateState: true,
        stateOptions: "searchData",
        stamp,
    };

    set({
        fetchingSearchData: true,
    });

    if (
        !searchQuery &&
        typeof history == "object" &&
        typeof window == "object"
    ) {
        history.pushState({}, "", window.location.href.split("?")[0]);
    }

    const HandleError = (error) => {
        if (error) {
            console.log(error);
        }

        set({
            fetchingSearchData: false,
            notification: {
                title: "Technical error",
                description: "Failed to fetch recent activity",
                status: "alert",
                onClick: () => {
                    set({
                        notification: {},
                    });
                },
            },
        });
    };

    try {
        SendRequest(
            options,
            (response) => {
                const { data, status, message } = response;

                if (status == 200 && stamp == response.stamp) {
                    set({
                        searchData: data || [],
                        fetchingSearchData: false,
                    });

                    const urlComponents = UrlParser(window.location);
                    if (urlComponents && urlComponents.selected) {
                        HandleActiveRecordSet({
                            selectedRecord: data.find(
                                (item) =>
                                    item.meta_id == urlComponents.selected,
                            ),
                            state,
                        });
                    }

                    return;
                }

                if (status !== 200) HandleError(message);
            },
            (error) => {
                HandleError(error);
            },
        );
    } catch (e) {
        HandleError(e);
    }
};

export default HandleRecentActivityCategorySearch;
