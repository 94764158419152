import { SET_CONFIGURATION } from "./index";
import AssetTypeInterface from "../../components/asset/interfaces/AssetTypeInterface";
import AssetPlatformInterface from "../../components/asset/interfaces/AssetPlatformInterface";

export default (
    configuration: Array<AssetTypeInterface | AssetPlatformInterface>,
    type: string,
) => {
    return {
        type: SET_CONFIGURATION,
        state: { [type]: configuration },
    };
};
