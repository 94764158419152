import React from "react";
import "./styles/_copyattributes.scss";
import Button from "../Button";

const CopyAttributesSubmitLanguageBar = (props) => {
    const {
        disabled,
        copy = {},
        submitted,
        approved,
        localisationApproval,
        localisationApproveAndSubmit,
        handleCopyAttributesClick = () => {},
        handleSubmitLanguageClick = () => {},
        handleLanguageApproval = () => {},
    } = props;

    const RenderSubmitButton = (props) => {
        const { styles = {} } = props;

        if (submitted) {
            return <a style={styles}>{copy.submitted || "Submitted"}</a>;
        }

        return (
            <a
                id="localisation-language-manager-submit-language"
                onClick={
                    !disabled ? handleSubmitLanguageClick.bind(this) : () => {}
                }
                style={styles}
            >
                {copy.submitLanguage || "Submit Language"}
            </a>
        );
    };

    const RenderApproveButton = () => {
        if (approved) {
            return <a>{copy.approved || "Approved"}</a>;
        }

        return (
            <a
                id="localisation-language-manager-approve-language"
                disabled={disabled}
                onClick={
                    !disabled ? handleLanguageApproval.bind(this) : () => {}
                }
            >
                {copy.approveLanguage || "Approve Language"}
            </a>
        );
    };

    const RenderSubmitApprove = () => {
        if (localisationApproveAndSubmit) {
            return (
                <React.Fragment>
                    <RenderApproveButton />
                    <RenderSubmitButton styles={{ marginLeft: 0 }} />
                </React.Fragment>
            );
        }

        if (localisationApproval) {
            return <RenderApproveButton />;
        } else {
            return <RenderSubmitButton />;
        }
    };

    return (
        <div
            className="localisation-language-manager-copy-atrributes-bar"
            data-disabled={disabled ? "true" : "false"}
        >
            <Button
                value={copy.copyAttributes || "Copy Attributes"}
                id="localisation-language-manager-copy-attributes"
                type="button"
                disabled={disabled}
                margin="0"
                padding="1.125rem 2.21875rem"
                background="#1c9065"
                hoverBackground="#1c9065"
                disabledBackground="#c8c8c8"
                disabledColor="rgba(255, 255, 255, 0.5)"
                fontSize="1.0625rem"
                color="white"
                borderRadius="0"
                transition="none"
                boxShadow="none"
                onClick={
                    !disabled ? handleCopyAttributesClick.bind(this) : () => {}
                }
            />

            <RenderSubmitApprove />
        </div>
    );
};

export default CopyAttributesSubmitLanguageBar;
