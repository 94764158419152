import React from "react";
import Settings from "../../Settings";
import AssetTrafficLight from "../../AssetTrafficLight";
import QuickView from "../../QuickView";
import RenderIdDoubleTag from "./RenderIdDoubleTag";
import ActionButton from "../../CustomSelect/ActionButton";

const ExpandableRowHeader = (props) => {
    const {
        data = [],
        media_id,
        handleCreateNewAlternateMaster = () => {},
        handlePublishRetry = () => {},
        handleDeletePlaceholder = () => {},
        handleViewErrorMessage = () => {},
        disabled,
        pendingStatus,
        preSavedStatus,
        errorStatus,
        placeholderCreated,
        placeholderError,
        placeholderCreatedStatus,
        handleSetQuickView,
        dataStructure = {},
    } = props;

    const { name: media_id_name } = dataStructure.media_id || {};

    let status = pendingStatus || preSavedStatus;
    if (placeholderError) status = errorStatus;
    if (placeholderCreated) status = placeholderCreatedStatus;

    let actionOptions = [];
    let actionDisabled = true;

    if (status == errorStatus) {
        actionDisabled = false;
        actionOptions = [
            {
                text: "Retry Publish to Asset Management System",
                onClick: () => handlePublishRetry(),
            },
            {
                text: "Delete Attempted Placeholder",
                onClick: () => handleDeletePlaceholder(),
            },
            {
                text: "Review Error Message",
                onClick: () => handleViewErrorMessage(),
            },
        ];
    }

    if (status == placeholderCreatedStatus) {
        actionDisabled = false;
        actionOptions = [
            {
                text: "Create Alternate Language Master Container",
                onClick: () => handleCreateNewAlternateMaster(),
            },
        ];
    }

    if (disabled) {
        actionOptions = [];
        actionDisabled = true;
    }

    return (
        <div className="meta-asset-header">
            <img
                className="header-icon"
                src={`${Settings.images.path}/svg/assets-container-grey.svg`}
            />

            <span className="title">Title Asset Container</span>

            <div className="right-content">
                <QuickView
                    onChange={(value) => handleSetQuickView(value)}
                    speed={disabled ? 2.5 : 2.5}
                />

                <AssetTrafficLight
                    status={status}
                    square={true}
                    style={{
                        height: Settings.components.actionButton.container
                            .height,
                        width: Settings.components.actionButton.container
                            .height,
                    }}
                />

                <RenderIdDoubleTag
                    textLeft={media_id_name}
                    textRight={media_id || "-"}
                />

                <ActionButton disabled={actionDisabled} items={actionOptions} />
            </div>
        </div>
    );
};

export default ExpandableRowHeader;
