import React, { Component } from "react";
import _ from "lodash";
import styles from "./styles";
import "./styles/_styles.scss";
import Index from "./index";

export default class BasicCounter extends Index {
    componentDidMount() {
        this.isComponentMounted = true;
        this.setCounter();
        this.setCounterOverLimit();

        if (this.props.setFocusOnMount) {
            this.handleFocus.bind(this, true);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!_.isEqual(this.props.value, nextProps.value)) {
            return true;
        }

        if (!_.isEqual(this.props.disabled, nextProps.disabled)) {
            return true;
        }

        if (!_.isEqual(this.props.display, nextProps.display)) {
            return true;
        }

        if (!_.isEqual(this.props.counter, nextProps.counter)) {
            return true;
        }

        if (!_.isEqual(this.props.className, nextProps.className)) {
            return true;
        }

        if (!_.isEqual(this.props.error, nextProps.error)) {
            return true;
        }

        if (!_.isEqual(this.props.disabledPrefix, nextProps.disabledPrefix)) {
            return true;
        }

        if (!_.isEqual(this.props.required, nextProps.required)) {
            return true;
        }

        if (!_.isEqual(this.state, nextState)) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.value !== this.props.value) {
            this.setState(
                {
                    value: this.props.value,
                },
                () => {
                    this.setCounter();
                    this.setCounterOverLimit();
                },
            );
        }
    }

    handleChange(event) {
        if (event) {
            if (event.target) {
                let value = event.target.value;
                let counter = this.state.counter;

                if (this.props.counter) {
                    counter = value.length;
                }

                // Validate number
                if (this.props.type == "number") {
                    if (this.props.max !== undefined) {
                        if (value > this.props.max) {
                            value = this.props.max;
                        }
                    }

                    if (this.props.min !== undefined) {
                        if (value < this.props.min) {
                            value = this.props.min;
                        }
                    }
                }

                this.setState(
                    {
                        value,
                        counter,
                    },
                    () => {
                        this.setCounterOverLimit();
                        this.handleChangeCallback();
                    },
                );
            }
        }
    }

    render() {
        const recordBasicOptions = this.props.recordBasic
            ? this.getRecordBasicProps()
            : {};
        const optionsProps = {
            ...this.props,
            ...recordBasicOptions,
        };
        const { disabledPrefix, disabled, fontSize } = this.props;

        let options = this.getOptions(optionsProps) || {};
        const classStyles = styles(optionsProps, this.state.value);
        const displayPrefix = disabledPrefix && disabled;

        if (displayPrefix) {
            options.placeholder = undefined;
        }

        return (
            <div
                style={classStyles.container}
                ref={(node) => {
                    this.node = node;
                }}
            >
                {this.renderLabel()}

                <div style={classStyles.prefixContainer}>
                    {disabled &&
                        this.renderDisabledInput(options, {
                            ...this.props,
                            disabledPrefix,
                        })}

                    {!disabled && (
                        <input
                            {...options}
                            className={`placeholder-light-grey ${
                                this.props.className || ""
                            } ${
                                displayPrefix
                                    ? "meta-original-title-prefix"
                                    : ""
                            }`}
                            ref={(ref) => (this.inputRef = ref)}
                        />
                    )}
                </div>
            </div>
        );
    }
}
