import React from "react";
import { SectionInterface } from "./interface";
import Properties from "../../views/Properties";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";

const SectionProperties = (props: SectionInterface) => {
    const { data = {} } = props;

    return (
        <Properties
            {...getSectionCommonProps({ ...props, field: "properties" })}
            name="properties"
            className="meta-record-properties"
            title="Properties"
            data={data.properties || {}}
        />
    );
};

export default SectionProperties;
