import { useState } from "react";

export const usePopperState = () => {
    const [open, setOpen] = useState(false);
    const closePopper = () => setOpen(false);
    const openPopper = () => setOpen(true);

    return {
        open,
        closePopper,
        openPopper,
    };
};
