import { CoreInputText } from "../../../../../../core/Form/components/inputs/CoreInput.text";
import { GridXS12 } from "../../tv-series/layout/grid";
import { AllFieldsVT } from "../../../../config/types";

export const ParentModularEpisodeDisabled = () => {
    return (
        <GridXS12>
            <CoreInputText<AllFieldsVT>
                path="parentModularEpisode.title"
                labelProps={{
                    text: "Parent Modular Episode",
                    isRequired: true,
                    isDisabled: true,
                }}
                disabled
            />
        </GridXS12>
    );
};
