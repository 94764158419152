import { isNull } from 'lodash'

const GetScript = (data, scriptCode) => {
    let result = "";

    if (data && typeof data == "object" && Array.isArray(data["scripts"])) {
        const script = data.scripts?.find((item) => item.code == scriptCode);

        if (script) {
            return script.value || script.name;
        }
    }

    return result;
};

const getItemFromState = (props) => {
    const { item = {}, index, activeRow = {}, editRowIndex } = props;

    let state = item;
    if (index === editRowIndex) {
        state = activeRow;
    }

    return state;
};

export function ScriptedValuesTransformer(props) {
    const {
        item = {},
        index,
        activeRow = {},
        editRowIndex,
        isScript,
        script,
    } = props;

    let state = getItemFromState({
        item,
        index,
        activeRow,
        editRowIndex,
    });

    const {
        award_body = {},
        result = {},
        year,
        category = {},
        order,
    } = item;

    const contributor = isNull(item?.contributor) ? {} : item.contributor

    if (isScript) {
        return {
            award_body: GetScript(award_body, script),
            result: GetScript(result, script),
            year,
            category: GetScript(category, script),
            contributor: GetScript(contributor, script),
            order,
        };
    }

    return {
        award_body: award_body.value,
        result: result.value,
        year,
        category: category.value,
        contributor: contributor?.value,
        order,
    };
}

export function GetEnglishValues(props) {
    const { item = {}, index, activeRow = {}, editRowIndex } = props;

    let state = getItemFromState({
        item,
        index,
        activeRow,
        editRowIndex,
    });

    const {
        award_body = {},
        result = {},
        year,
        category = {},
        order,
    } = item;

    const contributor = isNull(item?.contributor) ? {} : item.contributor

    return {
        award_body: award_body.value,
        result: result.value,
        year,
        category: category.value,
        contributor: contributor?.value,
        order,
    };
}
