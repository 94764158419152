import { isClientManagedLanguages } from "./languages";
import { isClientManagedCountries } from "./countries";
import { isClientManagedRightsLicensors } from "./rights_licensors";
import { isClientManagedRightsPlatforms } from "./rights_platforms";
import { isClientManagedProductionQuotas } from "./productionQuotas";
import { isClientManagedProvenance } from "./provenance";
import { isClientManagedCastAndCrew } from "./cast_and_crew";
import { isClientManagedRightsVersions } from "./rights_versions";
import { isClientManagedReleaseDateTypes } from "./release_date_types";
import { isClientManagedTerritories } from "./territories";
import { isClientManagedSupplier } from "./supplier";
import { isClientManagedField } from "./isClientManagedField";

import {
    LANGUAGE_KEY_PRIMARY_LANGUAGE,
    ADDITIONAL_SPOKEN_LANGUAGES,
    CLIENT_MANAGED_COUNTRIES,
    CLIENT_MANAGED_RIGHTS_LICENSORS,
    CLIENT_MANAGED_RIGHTS_PLATFORMS,
    CLIENT_MANAGED_PRODUCTION_QUOTAS,
    CLIENT_MANAGED_PROVENANCES,
    CLIENT_MANAGED_CAST_AND_CREW,
    SUBTITLES,
    DUBBING_TRACKS,
    AWARDS,
    CAST_AND_CREW,
    TITLES_AND_SYNOPSES,
    CLIENT_MANAGED_RIGHTS_VERSIONS,
    CLIENT_MANAGED_RECORD_STATUSES,
    CLIENT_MANAGED_RELEASE_DATE_TYPES,
    CLIENT_MANAGED_TERRITORIES,
    CLIENT_MANAGED_SUPPLIERS,
    CLIENT_MANAGED_CONTENT_TYPES,
    CLIENT_MANAGED_ENHANCED_FIRSTLOOK_SERIES,
    CLIENT_MANAGED_PURCHASE_TYPES,
    CLIENT_MANAGED_BRANDING_TYPE_NAMES,
    CLIENT_MANAGED_TARGET_DEMOS,
    CLIENT_MANAGED_VIEWERSHIPS,
    CLIENT_MANAGED_MEDIAN_AGES,
    CLIENT_MANAGED_SERIES_NETWORKS,
    CLIENT_MANAGED_RIGHTS_HIATUS_TYPES,
    CLIENT_MANAGED_PROGRAM_DIRECTORS,
    CLIENT_MANAGED_PROGRAM_STATUSES,
    CLIENT_MANAGED_SP_REVIEW_NAMES,
    CLIENT_MANAGED_SP_REVIEW_CAUSES,
    CLIENT_MANAGED_PROGRAM_USAGES,
    CLIENT_MANAGED_PRIMARY_PROGRAM_USAGES,
    CLIENT_MANAGED_PROGRAM_SUBTYPES,
    CLIENT_MANAGED_PLATFORMS,
    CLIENT_MANAGED_TITLES_AND_SYNOPSES_STATUS
} from "./options";

export default function isClientManaged(props) {
    const { clientManagedType = "", clientFeatures } = props;

    let result = false;

    switch (clientManagedType) {
        case LANGUAGE_KEY_PRIMARY_LANGUAGE:
            result = isClientManagedLanguages({
                clientFeatures,
                languageKey: LANGUAGE_KEY_PRIMARY_LANGUAGE,
            });
            break;

        case ADDITIONAL_SPOKEN_LANGUAGES:
            result = isClientManagedLanguages({
                clientFeatures,
                languageKey: ADDITIONAL_SPOKEN_LANGUAGES,
            });
            break;

        case CLIENT_MANAGED_COUNTRIES:
            result = isClientManagedCountries({
                clientFeatures,
            });
            break;

        case CLIENT_MANAGED_RIGHTS_LICENSORS:
            result = isClientManagedRightsLicensors({
                clientFeatures,
            });
            break;

        case CLIENT_MANAGED_RIGHTS_PLATFORMS:
            result = isClientManagedRightsPlatforms({
                clientFeatures,
            });
            break;

        case CLIENT_MANAGED_PRODUCTION_QUOTAS:
            result = isClientManagedProductionQuotas({
                clientFeatures,
            });
            break;

        case CLIENT_MANAGED_PROVENANCES:
            result = isClientManagedProvenance({
                clientFeatures,
            });
            break;

        case SUBTITLES:
            result = isClientManagedLanguages({
                clientFeatures,
                languageKey: SUBTITLES,
            });
            break;

        case DUBBING_TRACKS:
            result = isClientManagedLanguages({
                clientFeatures,
                languageKey: DUBBING_TRACKS,
            });
            break;

        case AWARDS:
            result = isClientManagedLanguages({
                clientFeatures,
                languageKey: AWARDS,
            });
            break;

        case TITLES_AND_SYNOPSES:
            result = isClientManagedLanguages({
                clientFeatures,
                languageKey: TITLES_AND_SYNOPSES,
            });
            break;

        case CAST_AND_CREW:
            result = isClientManagedLanguages({
                clientFeatures,
                languageKey: CAST_AND_CREW,
            });
            break;

        case CLIENT_MANAGED_CAST_AND_CREW:
            result = isClientManagedCastAndCrew({
                clientFeatures,
            });
            break;

        case CLIENT_MANAGED_RIGHTS_VERSIONS:
            result = isClientManagedRightsVersions({
                clientFeatures,
            });
            break;

        case CLIENT_MANAGED_RELEASE_DATE_TYPES:
            result = isClientManagedReleaseDateTypes({
                clientFeatures,
            });
            break;

        case CLIENT_MANAGED_RECORD_STATUSES:
            result = isClientManagedField({
                clientFeatures,
                field: "record_statuses",
            });
            break;

        case CLIENT_MANAGED_TERRITORIES:
            result = isClientManagedTerritories({
                clientFeatures,
            });
            break;

        case CLIENT_MANAGED_SUPPLIERS:
            result = isClientManagedSupplier({
                clientFeatures,
            });
            break;

        case CLIENT_MANAGED_SERIES_NETWORKS:
            result = isClientManagedField({
                clientFeatures,
                field: "series_networks",
            });
            break;

        case CLIENT_MANAGED_CONTENT_TYPES:
            result = isClientManagedField({
                clientFeatures,
                field: "content_types",
            });
            break;

        case CLIENT_MANAGED_PURCHASE_TYPES:
            result = isClientManagedField({
                clientFeatures,
                field: "purchase_types",
            });
            break;

        case CLIENT_MANAGED_ENHANCED_FIRSTLOOK_SERIES:
            result = isClientManagedField({
                clientFeatures,
                field: "enhanced_firstlook_series",
            });
            break;

        case CLIENT_MANAGED_BRANDING_TYPE_NAMES:
            result = isClientManagedField({
                clientFeatures,
                field: "branding_type_names",
            });
            break;

        case CLIENT_MANAGED_TARGET_DEMOS:
            result = isClientManagedField({
                clientFeatures,
                field: "target_demos",
            });
            break;

        case CLIENT_MANAGED_VIEWERSHIPS:
            result = isClientManagedField({
                clientFeatures,
                field: "viewerships",
            });
            break;

        case CLIENT_MANAGED_MEDIAN_AGES:
            result = isClientManagedField({
                clientFeatures,
                field: "median_ages",
            });
            break;

        case CLIENT_MANAGED_VIEWERSHIPS:
            result = isClientManagedField({
                clientFeatures,
                field: "viewerships",
            });
            break;

        case CLIENT_MANAGED_SP_REVIEW_NAMES:
            result = isClientManagedField({
                clientFeatures,
                field: "sp_review_names",
            });
            break;

        case CLIENT_MANAGED_SP_REVIEW_CAUSES:
            result = isClientManagedField({
                clientFeatures,
                field: "sp_review_causes",
            });
            break;

        case CLIENT_MANAGED_RIGHTS_HIATUS_TYPES:
            result = isClientManagedField({
                clientFeatures,
                field: "rights_hiatus_types",
            });
            break;

        case CLIENT_MANAGED_PROGRAM_DIRECTORS:
            result = isClientManagedField({
                clientFeatures,
                field: "program_directors",
            });
            break;

        case CLIENT_MANAGED_PROGRAM_STATUSES:
            result = isClientManagedField({
                clientFeatures,
                field: "program_statuses",
            });
            break;

        case CLIENT_MANAGED_PROGRAM_USAGES:
            result = isClientManagedField({
                clientFeatures,
                field: "program_usages",
            });
            break;

        case CLIENT_MANAGED_PRIMARY_PROGRAM_USAGES:
            result = isClientManagedField({
                clientFeatures,
                field: "primary_program_usages",
            });
            break;

        case CLIENT_MANAGED_PROGRAM_SUBTYPES:
            result = isClientManagedField({
                clientFeatures,
                field: "program_subtypes",
            });
            break;

        case CLIENT_MANAGED_PLATFORMS:
            result = isClientManagedField({
                clientFeatures,
                field: "platforms",
            });
            break;
        case CLIENT_MANAGED_TITLES_AND_SYNOPSES_STATUS:
            result = isClientManagedField({
                clientFeatures,
                field: "titles_and_synopses_status",
            });
            break;
    }

    return result;
}
