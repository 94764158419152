import { GetFromLocalStorage } from "../Storage/localStorage";
import { CLIENT_MANAGED_TERRITORIES } from "./options";
import clientManagedController from "./clientManagedController";

export function isClientManagedTerritories(props) {
    const { clientFeatures = {} } = props;

    const { managedLists = [] } = clientFeatures;

    if (managedLists.includes("territories")) {
        return true;
    }

    return false;
}

export function formatClientManagedTerritories(client_managed_territories) {
    if (!Array.isArray(client_managed_territories)) return [];

    return client_managed_territories.map((row) => {
        return { text: row.name, value: row.iso_code };
    });
}

export default function ClientManagedTerritories(props) {
    const { options = {}, fallback = [], clientFeatures = {} } = props;

    const clientManaged = isClientManagedTerritories({ clientFeatures });
    const local = GetFromLocalStorage(CLIENT_MANAGED_TERRITORIES);

    let territories = clientManagedController({
        clientManagedOptions: options.client_managed_territories || [],
        fallback,
        localStorage: JSON.parse(local),
        isClientManaged: clientManaged,
    });

    if (clientManaged) {
        return formatClientManagedTerritories(territories);
    }

    return territories;
}
