const data = {
    channel: "HBO",
    network_type: "Cable • National",
    series_id: "22078_HBO",
    daypart: "Primetime",
    reactions: 40185,
    reaction_rate: "37.70%",
    unique_reaction_authors: 23095,
    avarage_followers: 1478,
    keywords: {
        funny: 13285,
        crazy: 10935,
        weird: 7291,
        happy: 4921,
        enjoy: 3501,
        love: 2892,
        lucky: 2140,
        hate: 1907,
        badass: 1365,
        angry: 1014,
        afraid: 804,
        brutal: 89,
        cried: 0,
    },
};

export default data;
