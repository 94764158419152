import _ from "lodash";
import { CountriesInterface } from "../model";

export default function transformCountriesToInterface(
    countries: CountriesInterface[],
): CountriesInterface[] | [] {
    if (Array.isArray(countries)) {
        return countries.map((country: CountriesInterface) => {
            let result = {
                text: (country.text as string) || (country.name as string),
                value: country.iso_code || country.value,
            };

            if (country.deleted) {
                result.deleted = true;
            }

            return result;
        });
    }

    return [];
}
