import React, { Component } from "react";
import styles from "./styles";
import isIndexInArray from "../Helpers/isIndexInArray";
import _ from "lodash";
import Label from "../Input/label";

export default class Select extends Component {
    constructor(props) {
        super(props);

        /**
         * @var object
         */
        this.state = {
            value: this.props.value || "",
        };

        this.styles = styles(this.props);
    }

    componentDidMount() {
        this.setSingleOnLoad();
    }

    setSingleOnLoad() {
        const data = this.props.data || [];

        if (
            this.props.setSingleOnLoad &&
            Array.isArray(data) &&
            data.length == 1
        ) {
            const item = data[0];
            const value = item.value || item;

            this.setValue(value);

            // Callback
            if (typeof this.props.handleChange == "function") {
                this.props.handleChange(value);
            }
        }
    }

    setValue(value) {
        if (value !== this.state.value) {
            this.setState({
                value,
            });
        }
    }

    getOptionValues(option) {
        let value = option;
        let text = option;
        let disabled = false;

        if (typeof option == "object") {
            value = "";
            text = "";

            if (option.value !== undefined && option.text !== undefined) {
                value = option.value;
                text = option.text;
            }

            if (option.value !== undefined && option.text == undefined) {
                value = option.value;
                text = option.value;
            }

            if (option.value == undefined && option.text !== undefined) {
                value = option.text;
                text = option.text;
            }

            if (option.disabled) {
                disabled = true;
            }
        }

        const result = {
            value,
            text,
            disabled,
        };

        return result;
    }

    checkValue() {
        let result = "disabled_value";

        if (Array.isArray(this.props.data)) {
            this.props.data.map((item) => {
                if (
                    typeof this.props.value == "object" &&
                    this.props.value &&
                    typeof item == "object"
                ) {
                    if (item.value == this.props.value.value) {
                        result = this.props.value.value;
                    }

                    if (item.text == this.props.value.text) {
                        result = this.props.value.text;
                    }
                }

                if (
                    typeof this.props.value == "string" ||
                    Number.isInteger(this.props.value)
                ) {
                    if (_.isObject(item)) {
                        if (item.text == this.props.value) {
                            result = this.props.value;
                        }

                        if (item.value == this.props.value) {
                            result = this.props.value;
                        }
                    }

                    if (_.isEqual(item, this.props.value)) {
                        result = this.props.value;
                    }
                }
            });
        }

        this.setValue(result);
    }

    handleChange(e) {
        const { allowChange = true, showNullOption = true } = this.props;

        if (e) {
            if (e.target) {
                if (this.state.value !== e.target.value && allowChange) {
                    let value = e.target.value;

                    if (value == "None" && showNullOption) {
                        value = null;
                    }

                    this.setValue(value);

                    // Callback
                    if (typeof this.props.handleChangeTextValue == "function") {
                        let text = "";
                        const el = e.target;

                        if (
                            el.options !== undefined &&
                            el.selectedIndex !== undefined
                        ) {
                            if (
                                typeof el.options[el.selectedIndex] == "object"
                            ) {
                                text = el.options[el.selectedIndex].text;
                            }
                        }

                        const result = {
                            value,
                            text,
                        };

                        this.props.handleChangeTextValue(result);
                    }

                    // Callback
                    if (typeof this.props.handleChange == "function") {
                        this.props.handleChange(value);
                    }
                }
            }
        }
    }

    renderOptions() {
        const { data, loading, filterArray, selected, checkedKeys } =
            this.props;

        if (Array.isArray(data)) {
            let options = data;

            // Filter data
            if (Array.isArray(filterArray)) {
                options = data.filter((item) => {
                    if (
                        typeof item == "object" &&
                        !isIndexInArray(filterArray, item.value)
                    )
                        return item;
                    if (
                        typeof item == "string" &&
                        !isIndexInArray(filterArray, item)
                    )
                        return item;
                });
            }

            return (
                <React.Fragment>
                    {options.map((item, index) => {
                        let value = item;
                        let text = item;
                        let disabled = false;
                        let itemSelected = false;

                        if (typeof item == "object") {
                            value = "";
                            text = "";

                            if (
                                item.value !== undefined &&
                                item.text !== undefined
                            ) {
                                value = item.value;
                                text = item.text;
                            }

                            if (
                                item.value !== undefined &&
                                item.text == undefined
                            ) {
                                value = item.value;
                                text = item.value;
                            }

                            if (
                                item.value == undefined &&
                                item.text !== undefined
                            ) {
                                value = item.text;
                                text = item.text;
                            }

                            if (item.disabled) {
                                disabled = true;
                            }
                        }

                        let checkedKey = false;

                        if (checkedKeys) {
                            if (
                                !_.isEmpty(checkedKeys) &&
                                isIndexInArray(checkedKeys, value)
                            ) {
                                checkedKey = true;
                            }
                        }

                        if (value == selected) {
                            itemSelected = true;
                        }

                        const optionProps = {
                            key: index,
                            value,
                            disabled,
                            style: this.styles.li,
                            defaultValue: itemSelected,
                        };

                        return this.renderOptionText({
                            checkedKeys,
                            checkedKey,
                            text,
                            optionProps,
                        });
                    })}
                </React.Fragment>
            );
        }
    }

    renderOptionText(props) {
        const { checkedKeys, checkedKey, text, optionProps } = props;

        if (checkedKeys && !checkedKey) {
            return <option {...optionProps}>&#9702; {text}</option>;
        }

        if (checkedKeys && checkedKey) {
            return <option {...optionProps}>&#8226; {text}</option>;
        }

        return <option {...optionProps}>{text}</option>;
    }

    renderContent() {
        const {
            data = [],
            value,
            autoWidth,
            width_tmp_option_id = "width_tmp_option",
            width_tmp_select_id = "width_tmp_select",
            showNullOption = true,
        } = this.props;

        let selectedItem = {};
        if (autoWidth && Array.isArray(data)) {
            selectedItem =
                data.find((item) => item.value == value || item == value) || {};
        }

        const noneSelectedText =
            typeof this.props.noneSelectedText == "string"
                ? this.props.noneSelectedText
                : "None Selected";

        return (
            <React.Fragment>
                {this.props.label && (
                    <Label
                        name={this.props.name}
                        label={this.props.label}
                        labelStyles={this.props.labelStyles}
                        required={this.props.required}
                    />
                )}

                <div
                    style={Object.assign(
                        {},
                        this.styles.selectContainer,
                        this.props.readOnly &&
                            this.styles.selectContainerReadOnly,
                    )}
                >
                    <select
                        name={this.props.name}
                        id={this.props.id}
                        className={this.props.className}
                        style={Object.assign(
                            {},
                            this.styles.select,
                            this.props.border && this.styles.border,
                            this.props.readOnly && this.styles.readOnly,
                            this.props.readOnly &&
                                this.props.readOnlyPadding &&
                                this.styles.readOnlyPadding,
                            this.props.readOnly &&
                                this.props.readOnlyColor &&
                                this.styles.readOnlyColor,
                            this.props.noMargin && this.styles.noMargin,
                            this.props.readOnly &&
                                this.props.readOnlyPaddingCustom &&
                                this.props.readOnlyPaddingCustom,
                            !this.props.readOnly && this.styles.background,
                            !this.props.readOnly &&
                                data &&
                                data.length == 1 &&
                                this.styles.selectSingle,
                            this.props.backgroundIconLeft &&
                                this.styles.doubleBackground,
                            !this.state.value &&
                                this.props.clear &&
                                this.props.readOnly &&
                                this.styles.null,
                            this.props.loading && this.styles.loading,
                            this.props.loading &&
                                this.props.loadingWhite &&
                                this.styles.loadingWhite,
                            this.props.error && this.styles.error,
                            this.props.customPadding &&
                                this.styles.customPadding,
                            this.props.selectStyles && this.props.selectStyles,
                            this.props.small && this.styles.small,
                            this.props.theme && this.styles[this.props.theme],
                        )}
                        value={this.state.value}
                        onChange={this.handleChange.bind(this)}
                        disabled={
                            this.props.readOnly || this.props.disabled || false
                        }
                    >
                        {this.props.disabledText && !this.props.readOnly && (
                            <option value="disabled_value" disabled={true}>
                                {this.props.disabledText}
                            </option>
                        )}

                        {this.props.readOnly && this.props.noneSelectedText && (
                            <option value="disabled_value" disabled={true}>
                                {noneSelectedText}
                            </option>
                        )}

                        {this.renderOptions()}

                        {(!_.isEmpty(this.props.value) ||
                            Number.isInteger(this.props.value)) &&
                            showNullOption && (
                                <option value={null}>None</option>
                            )}
                    </select>
                </div>
            </React.Fragment>
        );
    }

    render() {
        return (
            <div
                style={Object.assign(
                    {},
                    this.styles.wrapper,
                    this.props.wrapperStyles && this.props.wrapperStyles,
                )}
            >
                {this.renderContent()}
            </div>
        );
    }
}
