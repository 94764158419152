import React from "react";
import _ from "lodash";
import deepcopy from "deepcopy";
import { diff } from "deep-object-diff";
import update from "react-addons-update";
import Index from "./Index";
import styles from "../styles";
import RegionalItem from "./RegionalItem";

export default class AdditionalInformation extends Index {
    constructor(props) {
        super(props);

        this.styles = styles(this.props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const nextPropsData = nextProps.data || {};
        const thisPropsData = this.props.data || {};
        const difference = diff(nextPropsData, thisPropsData);

        if (!_.isEmpty(difference)) {
            return true;
        }

        if (!_.isEqual(nextProps.disabled, this.props.disabled)) {
            return true;
        }

        if (!_.isEqual(nextState, this.state)) {
            return true;
        }

        if (!_.isEqual(nextProps.languageIso, this.props.languageIso)) {
            return true;
        }

        return false;
    }

    getLocaleData(sectionData) {
        const { languageIso } = this.props;

        return sectionData.find((item) => item.locale === languageIso) || {};
    }

    getSectionData(section) {
        const data = { ...this.props.data };
        return data[section] || [];
    }

    checkIfWithData() {
        return (
            !_.isEmpty(this.props.data) &&
            Object.keys(this.props.data).filter(
                (key) => !_.isEmpty(this.props.data[key]),
            ).length > 0
        );
    }

    handleChange(section, options) {
        const { languageIso } = this.props;

        const { type, value, key } = options;

        const sectionData = this.getSectionData(section);
        const localeIndex = _.findIndex(
            sectionData,
            (item) => item.locale === languageIso && item.type === type,
        );

        let updatedSectionData = deepcopy(sectionData);

        if (localeIndex === -1) {
            const item = {
                type,
                locale: languageIso,
                status: "approved",
                source: "open",
                [key]: value,
                display: !this.checkIfWithData(),
            };

            // create
            updatedSectionData = update(sectionData, {
                $push: [item],
            });
        } else if (_.isEmpty(value) && key !== "comment") {
            // delete

            const item = deepcopy(sectionData[localeIndex]);
            // Mark the item for deletion
            item.deleted = true;
            // Delete the actual value so we don't have inconsistent UX
            item[key] = "";

            updatedSectionData = update(sectionData, {
                [localeIndex]: {
                    $set: item,
                },
            });
        } else {
            // update
            const item = deepcopy(sectionData[localeIndex]);
            item[key] = value;

            updatedSectionData = update(sectionData, {
                [localeIndex]: {
                    $set: item,
                },
            });
        }

        let updatedState = { [section]: updatedSectionData };
        
        if (!_.isEmpty(this.props.data)) {
            updatedState = update(this.props.data, {
                [section]: {
                    $set: updatedSectionData,
                },
            });
        }

        if (typeof this.props.handleFormChange === "function") {
            this.props.handleFormChange(updatedState);
        }
    }

    // eslint-disable-next-line class-methods-use-this
    renderNoneAdded() {
        return true;
    }

    renderContent() {
        const {
            validationKeys = [],
            disabled,
            handleOnBlur = () => {},
            dataStructure = [],
            data = [],
            handleRegionalTitleSwitchChange,
            category,
        } = this.props;

        const dataStructureFiltered = dataStructure.filter(
            (item) =>
                !(
                    Array.isArray(item.categories) &&
                    !item.categories.includes(category)
                ),
        );

        return (
            <>
                {dataStructureFiltered.map((item) => {
                    const {
                        label,
                        max,
                        placeholder,
                        name,
                        section,
                        in_header: inHeader = true,
                        textArea = false
                    } = item;

                    const sectionData = data[section] || [];
                    const localData = this.getLocaleData(sectionData);

                    const switchOptions = inHeader
                        ? {
                              displayText: "Display in Record Header",
                              displayRelease: false,
                              disableOnNotEmpty: false,
                          }
                        : {
                              displayRelease: false,
                              disableOnNotEmpty: false,
                              hideDisplay: true,
                          };

                    return (
                        <div
                            style={this.styles.marginItem}
                            className="additional-information--regional-item"
                            key={`regional-item-${name}`}
                        >
                            <RegionalItem
                                inputKey={name}
                                classname="meta-record-disclaimer"
                                commentWidth="50%"
                                label={label}
                                placeholder={
                                    disabled ? "None Entered" : placeholder
                                }
                                name={`${name}_text`}
                                data={localData}
                                disabled={disabled}
                                maxCharacter={max}
                                counter
                                validationKeys={validationKeys}
                                type={name}
                                displaySwitches={false}
                                handleOnBlur={handleOnBlur.bind(this, name)}
                                handleChange={this.handleChange.bind(
                                    this,
                                    section,
                                )}
                                textArea={textArea}
                                displayStatusSelect={false}
                                switchOptions={switchOptions}
                                handleRegionalTitleSwitchChange={(
                                    checked,
                                    item,
                                ) =>
                                    handleRegionalTitleSwitchChange(
                                        checked,
                                        item,
                                        "display",
                                        "additional_information",
                                        section,
                                    )
                                }
                            />
                        </div>
                    );
                })}
            </>
        );
    }
}
