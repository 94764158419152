import { first } from "lodash"
import { ContributorInterface, ContributorImageInterface, ContributorLocalisedMetadataInterface } from "../../features/Preferences/Contributors/interfaces"

export interface ContributorLocalisedMetadataProps {
    byLocale: ContributorLocalisedMetadataInterface | null,
    first: ContributorLocalisedMetadataInterface
}

export function getAvatarImage(contributor: ContributorInterface): ContributorImageInterface | null {
    return contributor?.images?.find((image: ContributorImageInterface) => image.avatar) || null
}

export function getContributorName(contributor: ContributorInterface): string {
    const localised_metadata: ContributorLocalisedMetadataInterface = contributor?.localised_metadata.find((item: ContributorLocalisedMetadataInterface) => item.locale === "ENG") || contributor?.localised_metadata[0]
    
    return localised_metadata?.full_name || ''
}

export function getContributorLocalisedMetadataByLocale(contributor: ContributorInterface, locale: string): ContributorLocalisedMetadataInterface | null {
    return contributor?.localised_metadata?.find((item: ContributorLocalisedMetadataInterface) => item?.locale == locale)
}

export function getFirstContributorLocalisedMetadata(contributor: ContributorInterface): ContributorLocalisedMetadataInterface {
    return first(contributor?.localised_metadata)
}

export function getContributorLocalisedMetadata(contributor: ContributorInterface, locale: string): ContributorLocalisedMetadataProps {
    return {
        byLocale: getContributorLocalisedMetadataByLocale(contributor, locale),
        first: getFirstContributorLocalisedMetadata(contributor)
    }
}