import React from "react";
import Context from "./Context";
import DefaultState from "./DefaultState";
import HandleFetchCMSDataRequest from "../../pages/src/HandleFetchCMSDataRequest";
import HandleFetchOptionsRequst from "../../pages/src/HandleFetchOptionsRequst";
import HandleUserGroupSearchRequest from "../../pages/src/LineupManager/HandleUserGroupSearchRequest";
import HandleLineupManagerRequest from "../../pages/src/LineupManager/HandleLineupManagerRequest";
import HandleLineupPeriodChange from "../../pages/src/LineupManager/HandleLineupPeriodChange";
import HandleLineupPeriodNextPreviousEvent from "../../pages/src/LineupManager/HandleLineupPeriodNextPreviousEvent";
import HandleSwimlaneUpdateRequest from "../../pages/src/LineupManager/HandleSwimlaneUpdateRequest";
import HandleSwimlaneDelete from "../../pages/src/LineupManager/HandleSwimlaneDelete";
import HandleLineupManagerUpdate from "../../pages/src/LineupManager/HandleLineupManagerUpdate";
import HandleSwimlaneCreateRequest from "../../pages/src/LineupManager/HandleSwimlaneCreateRequest";
import HandleFetchCatalogAvailability from "../../pages/src/LineupManager/HandleFetchCatalogAvailability";
import HandleUpdateCatalogAvailability from "../../pages/src/LineupManager/HandleUpdateCatalogAvailability";
import HandleFetchCyclesRequest from "../../pages/src/LineupManager/HandleFetchCyclesRequest";
import HandleFetchCyclesV2Request from "../../pages/src/LineupManager/HandleFetchCyclesV2Request";
import HandleRecordDataSet from "../../pages/src/LineupManager/HandleRecordDataSet";
import HandleNewUsergroup from "../../pages/src/LineupManager/HandleNewUsergroup";
import HanldeRecordCancel from "../../pages/src/LineupManager/HanldeRecordCancel";
import HandleDeleteUserGroup from "../../pages/src/LineupManager/HandleDeleteUserGroup";
import HandleNewUsergroupSave from "../../pages/src/LineupManager/HandleNewUsergroupSaveRequest";
import HandleNewRecordChange from "../../pages/src/LineupManager/HandleNewRecordChange";
import HandleUserGroupUpdate from "../../pages/src/LineupManager/HandleUserGroupUpdate";
import HandleImageChange from "../../pages/src/LineupManager/HandleImageChange";
import HanldeImageDelete from "../../pages/src/LineupManager/HanldeImageDelete";
import HandleEditRecordClick from "../../pages/src/HandleEditRecordClick";
import HandleActiveRecordSet from "../../pages/src/HandleActiveRecordSet";
import HandleFetchAllPlatformAdmins from "../../pages/src/HandleFetchAllPlatformAdmins";
import HandleSetHover from "../../pages/src/HandleSetHover";

export default class ContextState extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...DefaultState,
            set: this.set.bind(this),
            handleEditRecordClick: (value) =>
                HandleEditRecordClick({
                    value,
                    state: this.state,
                }),
            handleActiveRecordSet: (selectedRecord) =>
                HandleActiveRecordSet({
                    selectedRecord,
                    state: this.state,
                }),
            setHoverRecord: (hoverRecord) =>
                HandleSetHover({
                    hoverRecord,
                    state: this.state,
                }),
            handleUserGroupSearch: (searchQuery) =>
                HandleUserGroupSearchRequest({
                    state: this.state,
                    searchQuery,
                }),
            handleRecordMetaDataRequest: () =>
                HandleRecordDataSet({
                    state: this.state,
                }),
            handleLineupManagerRequest: (options) =>
                HandleLineupManagerRequest({
                    state: this.state,
                    options,
                }),
            handleFetchOptionsRequst: () =>
                HandleFetchOptionsRequst({
                    state: this.state,
                }),
            handleLineupPeriodNextPreviousEvent: (options) =>
                HandleLineupPeriodNextPreviousEvent({
                    options,
                    state: this.state,
                }),
            handleLineupPeriodChange: (options) =>
                HandleLineupPeriodChange({
                    options,
                    state: this.state,
                }),
            handleSwimlaneUpdate: (options) =>
                HandleSwimlaneUpdateRequest({
                    options,
                    state: this.state,
                }),
            handleSwimlaneDelete: (options) =>
                HandleSwimlaneDelete({
                    options,
                    state: this.state,
                }),
            handleLineupManagerUpdate: (options) =>
                HandleLineupManagerUpdate({
                    options,
                    state: this.state,
                }),
            handleFetchAllPlatformAdmins: (options) =>
                HandleFetchAllPlatformAdmins({
                    options,
                    state: this.state,
                }),
            handleSwimlaneCreateRequest: (options) =>
                HandleSwimlaneCreateRequest({
                    options,
                    state: this.state,
                }),
            handleFetchCatalogAvailability: (options) =>
                HandleFetchCatalogAvailability({
                    options,
                    state: this.state,
                }),
            handleUpdateCatalogAvailability: (options) =>
                HandleUpdateCatalogAvailability({
                    options,
                    state: this.state,
                }),
            handleFetchCycles: (options) =>
                HandleFetchCyclesRequest({
                    options,
                    state: this.state,
                }),
            handleFetchCyclesV2: (options) =>
                HandleFetchCyclesV2Request({
                    options,
                    state: this.state,
                }),
            handleCyclesYearChange: (type) =>
                HandleCyclesYearChange({
                    type,
                    state: this.state,
                }),
            handleNewUsergroup: () =>
                HandleNewUsergroup({
                    state: this.state,
                }),
            handleCancel: () =>
                HanldeRecordCancel({
                    state: this.state,
                }),
            handleDeleteUserGroup: () =>
                HandleDeleteUserGroup({
                    state: this.state,
                }),
            handleNewUsergroupSave: () =>
                HandleNewUsergroupSave({
                    state: this.state,
                }),
            handleNewRecordChange: (key, value) =>
                HandleNewRecordChange({
                    key,
                    value,
                    state: this.state,
                }),
            handleUserGroupUpdate: (key, value) =>
                HandleUserGroupUpdate({
                    key,
                    value,
                    state: this.state,
                }),
            handleImageChange: (event) =>
                HandleImageChange({
                    event,
                    state: this.state,
                }),
            handleImageDelete: () =>
                HanldeImageDelete({
                    state: this.state,
                }),
        };
    }

    set(object, callback = () => {}) {
        this.setState(object, callback);
    }

    render() {
        return (
            <Context.Provider value={this.state}>
                {this.props.children}
            </Context.Provider>
        );
    }
}
