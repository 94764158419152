import _ from "lodash";
import { ContributorInterface, ContributorLocalisedMetadataInterface } from "../../../../Preferences/Contributors/interfaces";
import { CreditContributorPairInterface, CreditInterface, CreditRowInterface, CreditLocalisedData } from "../../store/interfaces";
import { APIRoleOptionInterface } from "../../../v3/credits.interface";
import { getCreditLocalisedData, CreditLocalisedDataProps } from "../../../../../models/Record/v2/credits";
import { getContributorLocalisedMetadata, ContributorLocalisedMetadataProps } from "../../../../../models/Contributor/model";

function getGreyedOutValue(value: string): string {
    return `<span style="color: rgba(188, 188, 188, 0.87);">${value}</span>`
}

function getCharacterName(localisedData: CreditLocalisedDataProps): string {
    const dataToUse: CreditLocalisedData = localisedData.byLocale || localisedData.first
    const characterName: string = dataToUse?.character_names
        ?.filter((cn) => !cn.deleted)
        .map((cn) => cn.name).join(", ")

    if (!characterName) {
        return null
    }

    if (!localisedData.byLocale) {
        return getGreyedOutValue(characterName)
    }

    return characterName
}

function getContributorName(localisedData: ContributorLocalisedMetadataProps): string {
    const dataToUse: ContributorLocalisedMetadataInterface = localisedData.byLocale || localisedData.first
    const fullName: string = dataToUse?.full_name

    if (!localisedData.byLocale) {
        return getGreyedOutValue(fullName)
    }

    return fullName
}

export const generateTableRows = (credits: CreditInterface[], contributors: ContributorInterface[], roles: APIRoleOptionInterface[], locale: string): CreditRowInterface[] => {
    const creditContributorPairs: CreditContributorPairInterface[] = []

    credits.forEach((credit: CreditInterface) => {
        const contributor: ContributorInterface = contributors.find((contributor: ContributorInterface) => contributor.id === credit.contributor_id);

        if (contributor) {
            creditContributorPairs.push({
                credit: credit,
                contributor: contributor
            })
        }
    })

    if (!_.isEmpty(creditContributorPairs) && !_.isEmpty(roles)) {
        return creditContributorPairs.map((pair: CreditContributorPairInterface) => {
            const contributorLocalisedMetadata = getContributorLocalisedMetadata(pair.contributor, locale)
            const creditLocalisedData = getCreditLocalisedData(pair.credit, locale)

            return {
                id: `${pair.credit.id || pair.credit.role_id}-${pair.contributor.id}`,
                creditID: pair.credit.id,
                contributorID: pair.contributor.id,
                contributorName: getContributorName(contributorLocalisedMetadata),
                role: roles?.find(role => role.id == pair.credit.role_id)?.name || pair.credit?.role_id,
                roleID: pair.credit.role_id,
                characterName: getCharacterName(creditLocalisedData),
                locale: pair.credit.locale,
                notes: pair.credit.notes,
                imdbID: pair.contributor?.ids?.imdb_id || null,
                ranking: pair.credit.ranking
            }
        })
    }

    return []
};