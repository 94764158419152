import moment from "moment";
import { auditTooltipDateFormat } from "./AuditTooltipConfig";

const GenerateAuditMessage = (auditRow) => {
    let user = auditRow.user_name || "";
    let time = moment(auditRow.updated_at || "").format(auditTooltipDateFormat);
    return user + " updated on " + time;
};

export default GenerateAuditMessage;
