const HandleDeleteNewRecord = (props) => {
    const { state = {}, copy = {}, category } = props;

    const { set = () => {}, handleRecentActivitySearch = () => {} } = state;

    set({
        notification: {
            title: copy.deleteNewRecord || "Delete new record",
            description:
                copy.deleteRecordDescription
                || `Are you sure you want to delete new record from the ${process.env.APP_NAME} platform?`,
            status: "error",
            okText: copy.cancelOkText || "Delete",
            onClick: () => {
                set({
                    notification: {},
                });
            },
            confirm: () => {
                set({
                    notification: {},
                    editRecord: false,
                    validationKeys: [],
                    newRecord: {},
                    originalNewRecord: {},
                    unsavedChanges: false,
                    recordData: {},
                    collectionTitle: {},
                    search: "",
                });

                handleRecentActivitySearch(category);
            },
        },
    });
};

export default HandleDeleteNewRecord;
