import { ContributorNameCol } from "../../../../core/Table/cast-crew/columns/ContributorName.column";
import { RoleCol } from "../../../../core/Table/cast-crew/columns/Role.column";
import { CharacterNameCol } from "../../../../core/Table/cast-crew/columns/CharacterName.column";
import { ContributorIDCol } from "../../../../core/Table/cast-crew/columns/ContributorID.column";
import { ImdbIDCol } from "../../../../core/Table/cast-crew/columns/ImdbID.column";
import { CreditIDCol } from "../../../../core/Table/cast-crew/columns/CreditID.column";
import { CoreTableGridColDef } from "../../../../core/Table/types";

export const defaultCreditsColumns: CoreTableGridColDef[] = [
    ContributorNameCol,
    RoleCol,
    CharacterNameCol,
    ContributorIDCol,
    ImdbIDCol,
    CreditIDCol,
];