import React from 'react'
import { MaterialDatePickerInterface } from './index'
import "./_styles.scss"

const Label = (props: MaterialDatePickerInterface) => {
    const {
        label,
        labelLegacy
    } = props

    return (
        <>
            {label &&
                <span className="datepicker-label">
                    {label}
                </span>
            }

            {labelLegacy &&
                <span className="datepicker-label-legacy">
                    {labelLegacy}
                </span>
            }
        </>
    )
}

export default Label