import React, { Fragment } from "react";
import Titles from "./Titles";
import DoesTitleReadOnly from "../../RecordHeader/src/DoesTitleReadOnly";
import FullScreenLoadingSpinner from "../../FullScreenLoadingSpinner";
import Notification from "../../Notification";

export default class TitlesV2 extends Titles {
    renderContent() {
        const { fetchingTranslations, notification } = this.state;

        const {
            clientDataStructure = {},
            languageIso,
            newRecordEdit,
            supply_chain = {},
            disabled = false,
        } = this.props;

        const { digital_readiness_status = "draft" } = supply_chain;

        const { titles = [] } = clientDataStructure;

        const marginTop = { marginTop: "1.5rem" };

        return (
            <Fragment>
                {this.renderUpdatedAt()}

                <div style={this.styles.flexMarginContainer}>
                    {Array.isArray(titles) &&
                        titles.map((item, index) => (
                            <div
                                style={Object.assign(
                                    {},
                                    this.styles.flexMarginItemDouble,
                                    marginTop,
                                )}
                                key={index}
                            >
                                {this.renderTitle({
                                    label: item.title,
                                    placeholder: disabled
                                        ? "None Entered"
                                        : item.placeholder,
                                    key: item.type,
                                    data: this.getTitle(item.type),
                                    maxCharacter: item.length,
                                    counter: true,
                                    readOnly:
                                        DoesTitleReadOnly({
                                            title: item,
                                            languageIso: languageIso || "",
                                            digital_readiness_status,
                                        }) && !newRecordEdit,
                                })}
                            </div>
                        ))}
                </div>

                <Notification {...notification} />

                {fetchingTranslations && <FullScreenLoadingSpinner />}
            </Fragment>
        );
    }
}
