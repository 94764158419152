export default function SeasonName(data = {}) {
    let result = "";

    if (typeof data == "object") {
        const { meta_id, original_title, season_number, season_numbering } =
            data;

        if (meta_id) {
            result = meta_id;
        }

        if (Number.isInteger(season_number)) {
            result = "Season " + season_number;
        }

        if (
            season_numbering &&
            Number.isInteger(season_numbering.season_number)
        ) {
            result = "Season " + season_numbering.season_number;
        }

        if (original_title) {
            result = original_title;
        }
    }

    return result;
}
