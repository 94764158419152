const keywords = [
    {
        keyword: "Halo",
        timestamp: "00:01",
        confidence: 51.66,
    },
    {
        keyword: "Video Gaming",
        timestamp: "00:01",
        confidence: 51.05,
    },
    {
        keyword: "Electronics",
        timestamp: "00:01",
        confidence: 54.49,
    },
    {
        keyword: "Machine",
        timestamp: "00:02",
        confidence: 50.51,
    },
    {
        keyword: "Team Sport",
        timestamp: "00:02",
        confidence: 50.52,
    },
    {
        keyword: "Screen",
        timestamp: "00:02",
        confidence: 59.3,
    },
    {
        keyword: "Outdoors",
        timestamp: "00:02",
        confidence: 53.96,
    },
    {
        keyword: "Athletes",
        timestamp: "00:02",
        confidence: 56.49,
    },
    {
        keyword: "Shack",
        timestamp: "00:02",
        confidence: 55.26,
    },
    {
        keyword: "Countryside",
        timestamp: "00:02",
        confidence: 57.98,
    },
    {
        keyword: "Monitor",
        timestamp: "00:02",
        confidence: 51.52,
    },
    {
        keyword: "Quake",
        timestamp: "00:03",
        confidence: 63.6,
    },
    {
        keyword: "Call of Duty",
        timestamp: "00:03",
        confidence: 53.45,
    },
    {
        keyword: "World of Warcraft",
        timestamp: "00:05",
        confidence: 51.22,
    },
    {
        keyword: "Grand Theft Auto",
        timestamp: "00:06",
        confidence: 55.94,
    },
    {
        keyword: "Military Uniform",
        timestamp: "00:07",
        confidence: 54.51,
    },
    {
        keyword: "Advertisement",
        timestamp: "00:13",
        confidence: 55.08,
    },
    {
        keyword: "Jungle",
        timestamp: "00:14",
        confidence: 88.25,
    },
    {
        keyword: "Nature",
        timestamp: "00:15",
        confidence: 75.36,
    },
    {
        keyword: "Vegitation",
        timestamp: "00:16",
        confidence: 75.44,
    },
    {
        keyword: "Helicopter",
        timestamp: "00:23",
        confidence: 62.05,
    },
    {
        keyword: "Final Fantasy",
        timestamp: "00:26",
        confidence: 51.04,
    },
    {
        keyword: "Sun Hat",
        timestamp: "00:36",
        confidence: 67.4,
    },
    {
        keyword: "Trees",
        timestamp: "00:36",
        confidence: 90.26,
    },
    {
        keyword: "Vegitation",
        timestamp: "00:36",
        confidence: 84.23,
    },
    {
        keyword: "Outdoors",
        timestamp: "00:37",
        confidence: 91.14,
    },
    {
        keyword: "Woodland",
        timestamp: "00:38",
        confidence: 53.98,
    },
    {
        keyword: "Weapon",
        timestamp: "00:41",
        confidence: 61.16,
    },
    {
        keyword: "Bomb",
        timestamp: "00:41",
        confidence: 66.45,
    },
    {
        keyword: "Restaurant",
        timestamp: "00:46",
        confidence: 61.64,
    },
    {
        keyword: "Furniture",
        timestamp: "00:47",
        confidence: 52.53,
    },
    {
        keyword: "Disk",
        timestamp: "00:54",
        confidence: 56.18,
    },
    {
        keyword: "Book",
        timestamp: "00:54",
        confidence: 53.59,
    },
    {
        keyword: "Poster",
        timestamp: "00:57",
        confidence: 63.92,
    },
    {
        keyword: "Phone",
        timestamp: "00:57",
        confidence: 51.38,
    },
];

export default keywords;
