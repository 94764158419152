import update from "react-addons-update";
import _ from "lodash";
import deepcopy from "deepcopy";
import HandleRecordValidation from "./HandleRecordValidation";
import DeepObjectDifference from "../../components/Helpers/DeepObjectDifference";
import UpdateDataTransformer from "../../components/Transformers/UpdateDataTransformer";
import GetDisplayTitle from "./GetDisplayTitle";

const HandleChange = (props) => {
    const { key, data, callback = () => {}, state = {} } = props;

    const {
        recordData,
        newRecord,
        set,
        options = {},
        originalRecord = {},
        unsavedChanges,
        cmsData,
        recordBuilder = {}
    } = state;

    const SetOriginalTitle = (updatedState) => {
        const original_title = GetDisplayTitle({
            titles: updatedState.titles || [],
        });

        return update(updatedState, {
            original_title: {
                $set: original_title.title || "",
            },
        });
    };

    // update record data
    if (_.isEmpty(newRecord)) {
        let updatedState = update(recordData, {
            [key]: {
                $set: data,
            },
        });

        // temp original title fix
        if (key == "titles") {
            updatedState = SetOriginalTitle(updatedState);
        }

        const validated = HandleRecordValidation({
            options,
            data: updatedState,
            state,
            cmsData,
            recordBuilder
        });

        const sectionDifference = DeepObjectDifference(
            updatedState,
            originalRecord,
            cmsData
        );

        const recordChanges = UpdateDataTransformer(
            sectionDifference,
            originalRecord,
        );

        let updatedRecordChanges = deepcopy(recordChanges);
        let updatedUnsavedChanges = unsavedChanges;

        if (!_.isEmpty(recordChanges)) {
            updatedUnsavedChanges = true;
            updatedRecordChanges = recordChanges;
        }

        set(
            {
                validated,
                recordData: updatedState,
                recordChanges: updatedRecordChanges,
                unsavedChanges: updatedUnsavedChanges,
            },
            callback,
        );
    }

    // update new record data
    if (!_.isEmpty(newRecord)) {
        let updatedState = update(newRecord, {
            [key]: {
                $set: data,
            },
        });

        // temp original title fix
        if (key == "titles") {
            updatedState = SetOriginalTitle(updatedState);
        }

        const validated = HandleRecordValidation({
            options,
            data: updatedState,
            state,
            cmsData,
            recordBuilder
        });

        set(
            {
                validated,
                newRecord: updatedState,
                unsavedChanges: true,
            },
            callback,
        );
    }
};

export default HandleChange;
