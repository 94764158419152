import { ContributorInterface, ContributorsMeasurementInterface } from "../interfaces"
import { CastAndCrewInterface } from "../../../../models/ClientManaged/castAndCrewInterface"
import { LanguageInterface } from "../../../../models/ClientManaged/languagesInterface"
import { GetContributorSectionConfigsResponseInterface } from '../../../../services/ApiClients/RecordBuilder/graphql/interfaces/contributorSectionsInterface'
import { globalStateInterface } from "../../../../components/Global/interfaces"

const cmsData: any = {
    clientDataStructure: {
        api_date_format: "YYYY-MM-DD",
    }
}

export const castAndCrewOptions: CastAndCrewInterface[] = [
    {
        id: 1,
        order: 1,
        text: "Actor",
        value: 1,
        type: "Cast"
    },
    {
        id: 2,
        order: 2,
        text: "Producer",
        value: 2,
        type: "Crew"
    }
]

export const languageOptions: LanguageInterface[] = [
    {
        id: 1,
        name: 'English Latin',
        meta_code: 'ENG',
        value: 'ENG',
        text: 'English Latin',
    },
    {
        id: 2,
        name: 'Japanese',
        meta_code: 'JPN',
        value: 'JPN',
        text: 'Japanese',
    }
]

export const contributorUpdateData = {
    id: "CON00001",
    localised_metadata: [
        {
            locale: "FRA",
            full_name: "Full Name",
            title: "Mr"
        },
        {
            full_name: "James"
        }
    ],
    ids: {
        imdb_id: "new_id",
        hand_id: "hand_id",
        isni_id: "",
        origin_id: ""
    },
    contact: {
        job_title: "job title",
        phone_number: {
            country_code: "us",
            number: "443321",
            extension: ""
        },
        address: "",
        email: "",
        website_url: "",
        linkedin_url: ""
    },
    metadata: {
        date_of_birth: "2023-12-12",
        tags: [
            {
                value: "tag",
                name: "tag"
            }
        ]
    }
}

export const contributorsData: ContributorInterface[] = [
    {
        "id": "contributor_id_123",
        "images": [
            {
                "avatar": true,
                "source": "image source",
                "type": "image type",
                "url": "https://i.pravatar.cc/150?img=2"
            }
        ],
        "ids": {
            "imdb_id": "nm01234567",
            "origin_id": "origin_id_123",
            "isni_id": "isni_id_123",
            "hand_id": "hand_id"
        },
        "metadata": {
            "date_of_birth": "1989-01-01",
            "tags": [
                "tag 1"
            ],
        },
        "contact": {
            "job_title": "job title",
            "phone_number": {
                "country_code": "us",
                "number": "123456",
                "extension": "1234"
            },
            "address": "address",
            "email": "a@a.a",
            "website_url": "example.com",
            "linkedin_url": "linkined.com/in/xxxxxx",
        },
        "sources":{
            "IMDb": {}
        }, 
        "measurement": [
            {
                "source": "imdb",
                "rating": "155"
            },
            {
                "source": "origin",
                "rating": "76"
            }
        ],
        "localised_metadata": [
            {
                "locale": "ENG", // mandatory
                "full_name": "James May", // mandatory
                "source": "data source",
                "first_name": "first name",
                "middle_name": "middle name",
                "last_name": "last name",
                "title": "title",
                "alternative_name": "alternative name",
                "alternative_name_2": "alternative name 2",
                "suffix": "suffix",
                "biography": "biography"
            },
            {
                "locale": "JPN",
                "full_name": "James May JPN",
                "source": "data source",
                "first_name": "first name",
                "middle_name": "middle name",
                "last_name": "last name",
                "title": "title",
                "alternative_name": "alternative name",
                "alternative_name_2": "alternative name 2",
                "suffix": "suffix",
                "biography": "biography"
            }
        ],
        "roles": [
            "Actor",
            "Producer",
            "Soundtrack"
        ],
        "types": [
            "Cast"
        ],
        "imdbID": "nm0562112",
    },
    {
        "id": "contributor_id_124",
        "localised_metadata": [
            {
                "locale": "ENG", 
                "full_name": "Richard Hammond"
            }
        ],
        "roles": [
            "Additional Crew",
            "Music Artist",
            "Composer"
        ],
        "types": [
            "Cast"
        ],
        "imdbID": "nm0359204"
    },
    {
        "id": "contributor_id_125",
        "localised_metadata": [
            {
                "locale": "ENG", 
                "full_name": "Jeremy Clarkson"
            }
        ],
        "roles": [
            "Director",
            "Writer",
            "Marketing",
            "Sales"
        ],
        "types": [
            "Cast"
        ],
        "imdbID": "nm0562112",
    }
]

export const recordBuilderResponse: GetContributorSectionConfigsResponseInterface = {
    "data": {
        "getContributorSectionConfigs": [
            {
                "id": "1",
                "code": "localised_metadata",
                "displayName": "Regional",
                "order": 1,
                "archived": false,
                "options": {
                    "dataKey": "locale",
                    "viewKey": "",
                    "defaultValues": [
                        {
                            "key": "biography_status",
                            "value": "None"
                        }
                    ]
                },
                "fields": [
                    {
                        "key": "locale",
                        "displayName": "Language",
                        "order": 1,
                        "dataType": "String",
                        "options": {
                            "checkedKeys": {
                                "enabled": true,
                            },
                            "editable": {
                                "enabled": true,
                                "inputType": "Select",
                                "maxLength": "",
                                "optionsManagedList": {
                                    "enabled": true,
                                    "key": "cast_and_crew",
                                    "dynamic": false
                                }
                            },
                            "mandatory": {
                                "enabled": true,
                            },
                            "validation": {
                                "required": {
                                    "enabled": true,
                                }
                            }
                        }
                    },
                    {
                        "key": "full_name",
                        "displayName": "Full Name",
                        "order": 2,
                        "dataType": "String",
                        "options": {
                            "editable": {
                                "enabled": true,
                            },
                            "mandatory": {
                                "enabled": true,
                            },
                            "validation": {
                                "required": {
                                    "enabled": true,
                                }
                            }
                        }
                    },
                    {
                        "key": "title",
                        "displayName": "Title",
                        "order": 3,
                        "dataType": "String",
                        "options": {
                            "editable": {
                                "enabled": true,
                                "inputType": "Select",
                                "maxLength": "",
                                "optionsManagedList": {
                                    "enabled": true,
                                    "key": "title",
                                    "dynamic": true
                                }
                            }
                        }
                    },
                    {
                        "key": "first_name",
                        "displayName": "First Name",
                        "order": 4,
                        "dataType": "String"
                    },
                    {
                        "key": "middle_name",
                        "displayName": "Middle Name",
                        "order": 5,
                        "dataType": "String"
                    },
                    {
                        "key": "last_name",
                        "displayName": "Last Name",
                        "order": 6,
                        "dataType": "String"
                    },
                    {
                        "key": "alternative_name",
                        "displayName": "Alternative Name",
                        "order": 7,
                        "dataType": "String"
                    },
                    {
                        "key": "alternative_name_2",
                        "displayName": "Alternative Name 2",
                        "order": 8,
                        "dataType": "String"
                    },
                    {
                        "key": "suffix",
                        "displayName": "Suffix",
                        "order": 9,
                        "dataType": "String"
                    },
                    {
                        "key": "biography",
                        "displayName": "Biography",
                        "order": 10,
                        "dataType": "String"
                    },
                    {
                        "key": "biography_status",
                        "displayName": "Biography Status",
                        "order": 11,
                        "dataType": "String",
                        "options": {
                            "editable": {
                                "enabled": true,
                                "inputType": "Select",
                                "optionsManagedList": {
                                    "enabled": true,
                                    "key": "biography_status",
                                    "dynamic": true
                                }
                            }
                        }
                    }
                ]
            },
            {
                "id": "2",
                "code": "ids",
                "displayName": "IDs",
                "order": 2,
                "archived": false,
                "options": {
                    "viewKey": ""
                },
                "fields": [
                    {
                        "key": "imdb_id",
                        "displayName": "IMDb ID",
                        "order": 1,
                        "dataType": "String"
                    },
                    {
                        "key": "isni_id",
                        "displayName": "ISNI ID",
                        "order": 2,
                        "dataType": "String"
                    },
                    {
                        "key": "hand_id",
                        "displayName": "HAND ID",
                        "order": 3,
                        "dataType": "String"
                    },
                    {
                        "key": "origin_id",
                        "displayName": "Origin Person ID",
                        "order": 4,
                        "dataType": "String"
                    },
                    {
                        "key": "external_id",
                        "displayName": "External ID",
                        "order": 5,
                        "dataType": "String"
                    }
                ]
            },
            {
                "id": "3",
                "code": "filmography",
                "displayName": "Filmography",
                "order": 3,
                "archived": false,
                "options": {
                    "viewKey": "filmographyTable"
                },
                "fields": []
            },
            {
                "id": "4",
                "code": "contact",
                "displayName": "Contact Details",
                "order": 4,
                "archived": false,
                "options": {
                    "viewKey": ""
                },
                "fields": [
                    {
                        "key": "job_title",
                        "displayName": "Job Title",
                        "order": 1,
                        "dataType": "String"
                    },
                    {
                        "key": "phone_number",
                        "displayName": "Phone Number",
                        "order": 2,
                        "dataType": "String"
                    },
                    {
                        "key": "extension",
                        "displayName": "Phone Extension",
                        "order": 3,
                        "dataType": "String"
                    },
                    {
                        "key": "address",
                        "displayName": "Contact Address",
                        "order": 4,
                        "dataType": "String"
                    },
                    {
                        "key": "email",
                        "displayName": "Email Address",
                        "order": 5,
                        "dataType": "String",
                        "options": {
                            "validation": {
                                "email": {
                                    "enabled": true,
                                }
                            }
                        }
                    },
                    {
                        "key": "website_url",
                        "displayName": "Website URL",
                        "order": 6,
                        "dataType": "String",
                        "options": {
                            "validation": {
                                "url": {
                                    "enabled": true,
                                }
                            }
                        }
                    },
                    {
                        "key": "linkedin_url",
                        "displayName": "LinkedIn Profile URL",
                        "order": 7,
                        "dataType": "String",
                        "options": {
                            "validation": {
                                "url": {
                                    "enabled": true,
                                }
                            }
                        }
                    }
                ]
            },
            {
                "id": "5",
                "code": "metadata",
                "displayName": "Additional Information",
                "order": 5,
                "archived": false,
                "options": {
                    "viewKey": ""
                },
                "fields": [
                    {
                        "key": "date_of_birth",
                        "displayName": "Date of Birth",
                        "order": 1,
                        "dataType": "String",
                        "options": {
                            "editable": {
                                "enabled": true,
                                "inputType": "Date"
                            }
                        }
                    },
                    {
                        "key": "tags",
                        "displayName": "Tags",
                        "order": 2,
                        "dataType": "Array",
                        "options": {
                            "editable": {
                                "enabled": true,
                                "inputType": "SelectMultiple",
                                "maxLength": ""
                            }
                        }
                    }
                ]
            },
            {
                "id": "6",
                "code": "measurement",
                "displayName": "Measurement",
                "order": 6,
                "archived": false,
                "options": {
                    "viewKey": "measurement"
                },
                "fields": []
            }
        ]
    }
}

export const superAdminGlobalState: globalStateInterface = {
    cmsData,
    userPermissions: [
        {
            platformManagement: {
                _p: 15,
                tab: {
                    contributors: {
                        _p: 15
                    }
                }
            }
        }
    ]
}

export const deleteOnlyGlobalState: globalStateInterface = {
    cmsData,
    userPermissions: [
        {
            platformManagement: {
                _p: 2,
                tab: {
                    contributors: {
                        _p: 2
                    }
                }
            }
        }
    ]
}

export const updateOnlyGlobalState: globalStateInterface = {
    cmsData,
    userPermissions: [
        {
            platformManagement: {
                _p: 4,
                tab: {
                    contributors: {
                        _p: 4
                    }
                }
            }
        }
    ]
}

export const viewOnlyGlobalState: globalStateInterface = {
    cmsData,
    userPermissions: [
        {
            platformManagement: {
                _p: 1,
                tab: {
                    contributors: {
                        _p: 1
                    }
                }
            }
        }
    ]
}

export const measurementData: ContributorsMeasurementInterface[] = [
    {
        logo: 'imdb-yellow-black.svg',
        title: 'IMDb STARmeter',
        rating: 155,
        ratingIcon: 'trend-down-red.svg',
    },
    {
        logo: 'origin.svg',
        title: 'Origin Power Rating',
        rating: 76,
        ratingIcon: 'star.svg'
    },
    {
        logo: 'imdb-yellow-black.svg',
        title: 'IMDb STARmeter',
        rating: 155,
        ratingIcon: 'trend-down-red.svg',
    },
    {
        logo: 'origin.svg',
        title: 'Origin Power Rating',
        rating: 76,
        ratingIcon: 'star.svg'
    }
]