import _ from "lodash";
import { CountriesInterface } from "../model";
import getCountriesFromAllSources from "./getCountriesFromAllSources";
import { getCountryByCode, getCountryCode } from "../model";

interface getFilteredCountriesFromAllSources {
    options: any;
    countries: CountriesInterface[];
}

export default function getFilteredCountriesFromAllSources({
    options,
    countries,
}: getFilteredCountriesFromAllSources): CountriesInterface[] | [] {
    const countriesFromAllSources = getCountriesFromAllSources(options);

    return countries
        .filter(
            (country: CountriesInterface) =>
                !_.isEmpty(
                    getCountryByCode(
                        countriesFromAllSources,
                        getCountryCode(country),
                    ),
                ),
        )
        .map((country: CountriesInterface) => {
            const countryModel = getCountryByCode(
                countriesFromAllSources,
                getCountryCode(country),
            );

            if (_.isString(country)) {
                return countryModel;
            }

            return {
                ...country,
                ...getCountryByCode(
                    countriesFromAllSources,
                    getCountryCode(country),
                ),
            };
        });
}
