import Categories from "./index";
import isIndexInArray from "../Helpers/isIndexInArray";

const EpisodeCategories = [
    Categories.Episode.short,
    Categories.Storyline.short,
    Categories.ConstructedEpisode.short,
];

export const EpisodeFilteredCategories = [
    Categories.Episode.short,
    Categories.ConstructedEpisode.short,
];

export function isEpisode(category) {
    return isIndexInArray(EpisodeCategories, category);
}

export default EpisodeCategories;
