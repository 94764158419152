import React from "react";
import { debounce, initial } from "lodash"
import { extendObservable, makeObservable, action } from 'mobx'
import { RootStore } from './rootStore'
import { integrationsStoreInitialState } from "./state"
import { ContributorInterface, ContributorImageInterface } from "../../interfaces"
import { getCelebrityById } from "../../../../../services/Origin"
import config from "../config";
import { measurementSouceOrigin } from "../config"

export class IntegrationsStore {
    rootStore: RootStore
    ratings: any
    fetchingKeys: any
    originId: string

    debouncedFetchOriginCelebrity = debounce(this.handleFetchOriginCelebrity, 1000);

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            setRating: action,
            setOriginId: action,
            setFetchingKey: action
        })

        extendObservable(this, integrationsStoreInitialState);
    }

    initialize(contributor: ContributorInterface | null) {
        if (contributor?.ids?.origin_id) {
            this.setOriginId(contributor?.ids?.origin_id)
            this.debouncedFetchOriginCelebrity(contributor?.ids?.origin_id)
        }
    }

    reset(): void {
        Object.keys(integrationsStoreInitialState).forEach(
            (key) => (this[key] = integrationsStoreInitialState[key]),
        );
    }

    setOriginId(originId: string): void {
        this.originId = originId
    }

    setRatings(ratings: any): void {
        this.ratings = ratings
    }

    setRating(key: string, value: number | null): void {
        this.ratings = {
            ...this.ratings,
            [key]: value
        }
    }

    setFetchingKey(key: string, value: boolean): void {
        this.fetchingKeys = {
            ...this.fetchingKeys,
            [key]: value
        }
    }

    getPersonImageByType(person: any, type: string): any | null {
        return person?.Images?.find((image: any) => image.ImageType === type)
    }

    getHeadShotImage(image: any): ContributorImageInterface {
        const apiUrls: any = this.rootStore.cmsData.apiUrls || {}
        const url: string = `${apiUrls.ivaCloudfront}/images/iva-img/${image.FilePath}/source.jpg?Width=150` 

        return {
            avatar: true,
            source: measurementSouceOrigin,
            type: image.ImageType || '',
            url: url,
        }
    }

    handleContributorChange(): void {
        const contributor: ContributorInterface | null = this.rootStore.getActiveContributorStore().contributor
        const origin_id: string = contributor?.ids?.origin_id || ''

        // fetch Origin
        if (origin_id !== this.originId) {
            this.setRatings({})
            this.setOriginId(origin_id)

            if (this.rootStore.getActiveContributorStore().contributor?.images) {
                this.rootStore.getActiveContributorStore().handleSetImages([])
            }
            
            this.debouncedFetchOriginCelebrity(contributor?.ids?.origin_id)
            return
        }
    }

    async handleFetchOriginCelebrity(id: string) {
        if (!this.ratings[config.iva.ratingKey] && id) {
            this.setFetchingKey(config.iva.ratingKey, true)
        }

        const person: any = await getCelebrityById(this.rootStore.cmsData, id)

        this.setFetchingKey(config.iva.ratingKey, false)

        if (person[config.iva.ratingKey]) {
            this.setRating(config.iva.ratingKey, person[config.iva.ratingKey])
        }

        if (Array.isArray(person.Images)) {
            const headshot = this.getPersonImageByType(person, config.iva.imagesTypes.headshot)

            if (headshot) {
                const headshotImage: ContributorImageInterface = this.getHeadShotImage(headshot)
                this.rootStore.getActiveContributorStore()?.handleSetOriginImage(headshotImage)
            }
        }
    }
}