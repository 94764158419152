import React, { useState } from "react";
import _ from "lodash";
import Select from "../../Select";
import Settings from "../../Settings";
import DoubleGridList from "../../CheckboxTag/DoubleGridList";
import TitleWithImage from "../../TitleWithImage";
import Title from "../../Title";
import RadioButtonOptions from "../../RadioButtonOptions";
import FadeIn from "../../TransitionGroup/FadeIn";
import AssetCompliances from "./AssetCompliances";

const AssetInformation = (props) => {
    const {
        data = {},
        master = {},
        options,
        handleUpdate = () => {},
        update,
        alternateMaster,
        clientFeatures = {},
        dataStructure = {},
    } = props;

    const {
        system_name,
        asset_type,
        compliances = [],
        register_performance_eidr,
        video,
        audio,
    } = master;

    const { asset_management = [], tmc_languages = [] } = options;

    const system = asset_management.find(
        (system) =>
            system &&
            system.system_name &&
            (system.system_name.value === system_name ||
                system.system_name.value === dataStructure.system_name),
    );

    const { asset_types = {} } = system || {};

    const { managedLists = [] } = clientFeatures;

    const clientManaged = managedLists.includes("asset_compliances");

    const assetCompliances = AssetCompliances({
        options,
        clientFeatures,
        dataStructure,
    });

    const assetTypeOptions = {
        popular: assetCompliances.filter((item) => item.popular),
        all: clientManaged ? _.groupBy(assetCompliances, "group") : asset_types,
    };

    const [popular, setPopular] = useState(true);
    const assetManagementSystems =
        system && system.system_name ? [system.system_name] : [];
    const masterAssetTypes = asset_types.Masters || [];
    const selectedDisabled = Array.isArray(data.compliances)
        ? data.compliances.map((item) => item.asset_type)
        : [];

    const handleChange = (key, value) => {
        let updatedState = { ...master };
        updatedState[key] = value;
        handleUpdate(updatedState);
    };

    const setCompliances = (compliance) => {
        let updatedState = [...compliances];

        if (_.includes(compliances, compliance)) {
            updatedState = updatedState.filter((item) => item !== compliance);
        } else {
            updatedState.push(compliance);
        }

        handleChange("compliances", updatedState);
    };

    const setCompliancesWithUpdate = (compliance) => {
        let updatedState = [...compliances];

        // remove
        if (
            updatedState.find(
                (item) => item.asset_type == compliance || item == compliance,
            )
        ) {
            updatedState = updatedState.filter((item) => item !== compliance);
        } else {
            // add
            updatedState.push(compliance);
        }

        handleChange("compliances", updatedState);
    };

    const handleEidrSelection = (key) => {
        let value = !register_performance_eidr;
        handleChange("register_performance_eidr", value);
    };

    const margin = "0 0 1.25rem 0";
    const titleFontSize = "1.05rem";
    const transformedLanguages = tmc_languages.map((item) => {
        return {
            text: item.name,
            value: item.tmc_code,
        };
    });

    return (
        <div className="asset-information">
            <div style={{ padding: "1.25rem 1.25rem 0 1.25rem" }}>
                <Title title="Asset Information" fontSize={titleFontSize} />

                <div style={{ margin: "1.5rem 0 1.25rem 0", display: "flex" }}>
                    <Select
                        name="system_name"
                        id="system_name"
                        value={system_name}
                        clear={true}
                        label="Destination Platform"
                        disabledText="Select Platform"
                        noneSelectedText="None Selected"
                        data={assetManagementSystems}
                        wrapperStyles={{ width: "50%", marginRight: "0.3rem" }}
                        handleChange={(value) =>
                            handleChange("system_name", value)
                        }
                        showNullOption={false}
                    />

                    <Select
                        name="asset_type"
                        id="asset_type"
                        value={asset_type}
                        clear={true}
                        label="Asset Type"
                        data={masterAssetTypes}
                        wrapperStyles={{ width: "50%", marginLeft: "0.3rem" }}
                        handleChange={(value) =>
                            handleChange("asset_type", value)
                        }
                        showNullOption={false}
                    />
                </div>

                {alternateMaster && (
                    <div style={{ margin: "0 0 1.25rem 0", display: "flex" }}>
                        <Select
                            name="video"
                            id="video"
                            value={video}
                            clear={true}
                            label="Video Language"
                            disabledText="Select Language"
                            data={transformedLanguages}
                            wrapperStyles={{
                                width: "50%",
                                marginRight: "0.3rem",
                            }}
                            handleChange={(value) =>
                                handleChange("video", value)
                            }
                            showNullOption={false}
                        />

                        <Select
                            name="audio"
                            id="audio"
                            value={audio}
                            clear={true}
                            label="Video Audio"
                            disabledText="Select Language"
                            data={transformedLanguages}
                            wrapperStyles={{
                                width: "50%",
                                marginLeft: "0.3rem",
                            }}
                            handleChange={(value) =>
                                handleChange("audio", value)
                            }
                            showNullOption={false}
                        />
                    </div>
                )}

                {!alternateMaster && (
                    <FadeIn key={popular}>
                        <React.Fragment>
                            {popular && (
                                <DoubleGridList
                                    label="Popular Compliances"
                                    items={assetTypeOptions.popular}
                                    styles={{ margin }}
                                    selected={compliances}
                                    selectedDisabled={selectedDisabled}
                                    handleSelection={(value) =>
                                        !update
                                            ? setCompliances(value)
                                            : setCompliancesWithUpdate(value)
                                    }
                                    rightLabelText="View All"
                                    handleRightTextClick={() =>
                                        setPopular(false)
                                    }
                                />
                            )}

                            {!popular &&
                                Object.keys(assetTypeOptions.all).map(
                                    (key, index) => {
                                        if (key !== "Masters") {
                                            return (
                                                <DoubleGridList
                                                    label={key}
                                                    items={
                                                        assetTypeOptions.all[
                                                            key
                                                        ]
                                                    }
                                                    styles={{ margin }}
                                                    selected={compliances}
                                                    handleSelection={(value) =>
                                                        !update
                                                            ? setCompliances(
                                                                  value,
                                                              )
                                                            : setCompliancesWithUpdate(
                                                                  value,
                                                              )
                                                    }
                                                    key={index}
                                                    selectedDisabled={
                                                        selectedDisabled
                                                    }
                                                />
                                            );
                                        }
                                    },
                                )}
                        </React.Fragment>
                    </FadeIn>
                )}
            </div>

            <div
                style={{
                    padding: "0 1.25rem 1.25rem 1.25rem",
                    borderTop: "solid 0.065rem #d1d1d1",
                }}
            >
                <TitleWithImage
                    title="EIDR Registration"
                    fontSize={titleFontSize}
                    image={
                        <img
                            src={`${Settings.images.path}/svg/eidr-blue.svg`}
                            style={{ width: "4.21875rem" }}
                        />
                    }
                    styles={{
                        margin: "1.0625rem 0 1rem 0",
                    }}
                />

                <RadioButtonOptions
                    data={[
                        {
                            text: "Register New Performance EIDR for All Assets?",
                            value: "register_performance_eidr",
                        },
                    ]}
                    selected={
                        register_performance_eidr
                            ? ["register_performance_eidr"]
                            : []
                    }
                    handleSelection={handleEidrSelection.bind(this)}
                />
            </div>
        </div>
    );
};

export default AssetInformation;
