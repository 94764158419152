import React from "react";
import IPlatformScheduling from "./PlatformSchedulingInterface";
import ExpandableRow from "../../../ExpandableRow/v2";
import CubeShape from "../../../CubeShape";
import CustomSelect from "../../../CustomSelect";
import MenuIconBoilerPlate from "../../../Boilerplates/MenuIconBoilerplate";
import Settings from "../../../Settings";
import MultipleTag from "../../../Tag/multiple";
import IsActiveWindow from "../helpers/IsActiveWindow";
import moment from "moment";
import { headerTimeFormat, activeConfig, inactiveConfig } from "../config";
import _ from "lodash"
import DoubleColumnContentExpandable from "../../../Boilerplates/DoubleColumnContent/DoubleColumnContentExpandable";
import { useStore as useGlobalStore } from "../../../Global/store/store";
import { SCHEDULING_PLATFORMS } from '../../../../models/RecordBuilder'
import BuilderField from '../../../../models/RecordBuilder/Field.interface'
import mapField from '../../../RecordBuilder/map/doubleColumnContent/mapField'
import IsActive from '../../../RecordBuilder/IsActive'
import {
    scheduleInformationSectionConfig,
    scheduleWindowSectionConfig,
} from "../config";

type Props = {
    item: IPlatformScheduling;
};

const ScheduleRow: React.FC<Props> = ({ item }) => {
    const [globalStore] = useGlobalStore()

    const {
        cmsData = {},
        recordBuilder = {},
    } = globalStore || {}

    const RenderActions = () => {
        return (
            <div className="actions">
                <div className="action">
                    <CustomSelect
                        disabled={true}
                        chevronImage={false}
                        background={"none"}
                        items={[]}
                        customImageRight={<MenuIconBoilerPlate />}
                    />
                </div>

                <div className="expand-all action" onClick={() => {}}>
                    <img
                        className="menu-arrow-icon"
                        src={`${Settings.images.path}/svg/arrows-expand-all.svg`}
                    />
                </div>
            </div>
        );
    };

    const RenderHeader = () => {
        return (
            <div className="schedule-row-header">
                <div className="first-half">
                    <CubeShape
                        color={IsActiveWindow(item) ? "#2EBD8E" : "#E3646D"}
                    />
                    <span className="title">
                        <span>{item.platform}</span> {!_.isEmpty(item.territory) ? `• ${item.territory}` : " "} •{" "}
                        {moment(item.put_up_date).format(headerTimeFormat)} •{" "}
                        {moment(item.take_down_date).format(_.get(cmsData, 'data_structure.date_format', 'M/D/YYYY'))}
                    </span>
                </div>

                <div className="second-half">
                    <div className="tags">
                        <MultipleTag
                            tags={[
                                IsActiveWindow(item)
                                    ? activeConfig
                                    : inactiveConfig,
                            ]}
                        />
                    </div>
                    <RenderActions />
                </div>
            </div>
        );
    };

    const RenderSection = ({ item }) => {
        if (IsActive({ page: SCHEDULING_PLATFORMS, clientFeatures: cmsData.clientFeatures || {}})) {
            return <RenderSectionBuilder item={item} />
        }

        return <RenderSectionTemplate item={item}/>
    }

    const RenderSectionTemplate = ({ item }: { item: IPlatformScheduling }) => {
        return (
            <div className="schedule-row-body">
                <DoubleColumnContentExpandable
                    headerText={"Schedule Information"}
                    fields={scheduleInformationSectionConfig(item)}
                    rowBodyStyles={{ padding: 0, borderRadius: "0.375rem" }}
                />
                <DoubleColumnContentExpandable
                    headerText={"Schedule Window"}
                    fields={scheduleWindowSectionConfig(item)}
                    rowBodyStyles={{ padding: 0, borderRadius: "0.375rem" }}
                />
            </div>
        );
    }

    const RenderSectionBuilder = ({ item }: { item: IPlatformScheduling }) => {
        const platformConfig = recordBuilder[SCHEDULING_PLATFORMS].find(
            (config: BuilderField) => config.key == item.platform_type && item.scheduling_system == config.system
        )
        const sections = platformConfig?.sections || []

        return (
            <div className="schedule-row-body">
                {sections.map(section => {
                    return (
                        <DoubleColumnContentExpandable
                            headerText={"Schedule Information"}
                            fields={section.fields.map(initialField => {
                                return mapField({
                                    initialField,
                                    value: item[initialField.key],
                                    dateFormat: _.get(cmsData, 'data_structure.date_format', 'M/D/YYYY')
                                })
                            })}
                            rowBodyStyles={{ padding: 0, borderRadius: "0.375rem" }}
                            handleChange={() => {}}
                        /> 
                    )
                })}
            </div>
        );
    }

    return (
        <div className="platform-scheduling-item">
            <ExpandableRow
                header={<RenderHeader />}
                body={<RenderSection item={item} />}
                bodyStyles={{ padding: 0, borderRadius: "0.375rem" }}
                borderRadius={true}
                className="platform-scheduling-expandale-row"
                headerClassName="platform-scheduling-expandale-row-header"
                defaultOpen={false}
                arrowIconType="large"
            />
        </div>
    );
};

export default ScheduleRow;
