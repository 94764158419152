import { Component, Fragment } from "react";
import * as React from "react";
import KeywordLabel from "../../KeywordLabel";
import _ from "lodash";
import AuditTooltip from "../../AuditTooltip";
import DigitalReadinessValidationMessages from "../../DigitalReadiness/ValidationMessages";
import GetValidationMessages from "../../DigitalReadiness/getValidationMessages";
import FadeIn from "../../TransitionGroup/FadeIn";

class DigitalReadinessStatus extends Component {
    constructor(props) {
        super(props);

        const { data = {} } = props;

        let { digital_readiness_status } = data;

        if (!digital_readiness_status || _.isEmpty(digital_readiness_status))
            digital_readiness_status = "draft";

        this.state = {
            status: digital_readiness_status,
            colors: this.setColors(digital_readiness_status),
            validationMessages: [],
            validationStatus: "",
        };
    }

    handleSelect(status) {
        const {
            handleFormChange = () => {},
            data = {},
            recordData = {},
            clientDataStructure = {},
        } = this.props;

        let { supply_chain = {} } = data;

        const validationMessages = GetValidationMessages({
            status,
            recordData,
            clientDataStructure,
        });

        if (!_.isEmpty(validationMessages)) {
            return this.setState({
                validationMessages,
                validationStatus: status,
            });
        } else {
            supply_chain.digital_readiness_status = status;

            handleFormChange(supply_chain);

            this.setState({
                status: status,
                colors: this.setColors(status),
                validationMessages: [],
                validationStatus: "",
            });
        }
    }

    setColors(status) {
        const { options = {} } = this.props;

        const { digital_readiness_statuses = [] } = options;

        let statusesColorMap = {
            draft: "rgb(229, 140, 0)",
            ready: "#2a8618",
            disabled: "rgb(241, 93, 93)",
        };

        let colors = {};
        digital_readiness_statuses.map((item) => {
            if (
                status &&
                status.toLowerCase() == item.toLowerCase() &&
                statusesColorMap[status]
            ) {
                colors[item] = statusesColorMap[status];
            } else {
                colors[item] = "#f1f1f1";
            }
        });
        return colors;
    }

    render() {
        const {
            options = {},
            disabled,
            clientFeatures = {},
            audits = [],
        } = this.props;

        const { 
            digital_readiness_statuses = [] 
        } = options;

        let {
            status = "",
            colors,
            validationMessages = [],
            validationStatus = "",
        } = this.state;

        return (
            <Fragment>
                <div className="meta-assets-supply-chain-assignment">
                    <AuditTooltip
                        clientFeatures={clientFeatures}
                        audits={audits}
                        dataSection="digital_readiness"
                        fieldName="status"
                    >
                        <h2
                            style={{
                                color: "rgb(0, 0, 0)",
                                fontSize: "1.3rem",
                            }}
                        >
                            Supply Chain Assignment
                        </h2>

                        <span
                            className="meta-placeholder-text"
                            style={{
                                color: "#707070",
                                fontSize: "1.05rem",
                                marginTop: "0.625rem",
                                display: "block",
                            }}
                        >
                            Digital Readiness Status
                        </span>
                        {digital_readiness_statuses.map((listStatus, index) => {
                            let color =
                                status.toLowerCase() ===
                                listStatus.toLowerCase()
                                    ? "white"
                                    : "#787878";
                            let statusTitle =
                                listStatus.charAt(0).toUpperCase() +
                                listStatus.slice(1);
                            let background = colors[listStatus] || "#f1f1f1";
                            let deleteWhite = true;
                            let deleteRed = false;

                            if (validationStatus == listStatus) {
                                background = "#FAD4DA";
                                color = "#D6465E";
                                deleteWhite = false;
                                deleteRed = true;
                            }

                            return (
                                <KeywordLabel
                                    title={statusTitle}
                                    deleteRight={true}
                                    disabled={disabled}
                                    delete={true}
                                    styles={{
                                        background,
                                        color,
                                    }}
                                    className="meta-assets-supply-chain-digital-readiness-status"
                                    margin="0.6875rem 0.625rem 0 0"
                                    width={"10rem"}
                                    deleteWhite={deleteWhite}
                                    deleteRed={deleteRed}
                                    onClick={() =>
                                        !disabled
                                            ? this.handleSelect(listStatus)
                                            : {}
                                    }
                                    key={index}
                                />
                            );
                        })}
                    </AuditTooltip>
                </div>

                <FadeIn>
                    {!_.isEmpty(validationMessages) && (
                        <DigitalReadinessValidationMessages
                            items={validationMessages}
                            styles={{ marginTop: "0.8125rem" }}
                        />
                    )}
                </FadeIn>
            </Fragment>
        );
    }
}

export default DigitalReadinessStatus;
