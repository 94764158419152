import SendRequest from "../../SendRequest";

const DownloadFileWithFetch = (props) => {
    const {
        url,
        method,
        filename,
        body,
        handleSuccess = () => {},
        handleError = () => {},
    } = props;

    const options = {
        url,
        method,
        responseType: "blob",
        body,
    };

    const erroHandle = (error) => {
        console.log("Failed to download file");
        console.log(error);
        handleError(error);
    };

    try {
        SendRequest(
            options,
            (response) => {
                if (window && document) {
                    const windowUrl = window.URL.createObjectURL(response);
                    let a = document.createElement("a");

                    a.href = windowUrl;
                    a.download = filename;
                    document.body.appendChild(a);

                    a.click();
                    a.remove();

                    return handleSuccess();
                }
            },
            (error) => {
                erroHandle(error);
            },
        );
    } catch (e) {
        erroHandle(e);
    }
};

export default DownloadFileWithFetch;
