/* eslint-disable no-nested-ternary */
import React from "react";
import { isEmpty, isNaN } from "lodash";
import { CoreSelectOptionType } from "../../../../../../core/Form/components/inputs/Select/types/CoreSelectOptionType";
import {
    CORE_FORM_ARRAY_DEFAULT_VALUE,
    CORE_FORM_NUMBER_DEFAULT_VALUE,
    CORE_FORM_STRING_DEFAULT_VALUE,
} from "../../../../../../core/Form/constants";
import { TvShowVT } from "../../../types";
import { C100, C1024, C250 } from "../validation/constants";

// TODO: these were hardcoded in the original code because of no time, but they should be dynamic
export const fieldOptions: CoreSelectOptionType[] = [
    {
        label: "Release Year",
        overrideLabel: <span>Release Year</span>,
        value: "releaseYear",
    },
    {
        label: "Brand",
        value: "brand",
        overrideLabel: <span>Brand</span>,
    },
    {
        label: "Provenance",
        overrideLabel: <span>Provenance</span>,
        value: "provenance",
    },
    {
        label: "Display Title",
        overrideLabel: <span>Display Title</span>,
        value: "displayTitle",
    },
    {
        label: "Primary Language",
        overrideLabel: <span>Primary Language</span>,
        value: "originalLanguage",
    },
    {
        label: "Country of Origin",
        overrideLabel: <span>Country of Origin</span>,
        value: "countryOfOrigin",
    },
    {
        label: "Also Known As (AKA)",
        overrideLabel: <span>Also Known As (AKA)</span>,
        value: "titles.aka",
    },
    {
        label: "Formerly Known As (FKA)",
        overrideLabel: <span>Formerly Known As (FKA)</span>,
        value: "titles.fka",
    },
    {
        label: "Short Title",
        overrideLabel: <span>Short Title</span>,
        value: "titles.short",
    },
    {
        label: "Sort Title",
        overrideLabel: <span>Sort Title</span>,
        value: "titles.sort",
    },
    {
        label: "Alternative Title",
        overrideLabel: <span>Alternative Title</span>,
        value: "titles.alternative",
    },
    {
        label: "Release Title",
        overrideLabel: <span>Release Title</span>,
        value: "titles.release",
    },
    {
        label: "Genres",
        overrideLabel: <span>Genres</span>,
        value: "descriptions.genre",
    },
    {
        label: "Sub Genres",
        overrideLabel: <span>Sub Genres</span>,
        value: "descriptions.subGenre",
    },
    {
        label: `Super Short Description (${C100})`,
        overrideLabel: <span>Super Short Description ({C100})</span>,
        value: "descriptions.description100",
    },
    {
        label: `Description (${C250})`,
        overrideLabel: <span>Description ({C250})</span>,
        value: "descriptions.description250",
    },
    {
        label: `Long Description (${C1024})`,
        overrideLabel: <span>Long Description ({C1024})</span>,
        value: "descriptions.longDescription1024",
    },
    {
        label: "RMS ID",
        overrideLabel: <span>RMS ID</span>,
        value: "ids.rms",
    },
    {
        label: "Legacy SAP ID (SAP-V ID)",
        overrideLabel: <span>Legacy SAP ID (SAP-V ID)</span>,
        value: "ids.legacySap",
    },
    {
        label: "S4 MPM ID",
        overrideLabel: <span>S4 MPM ID</span>,
        value: "ids.s4_mpm_id",
    },
    {
        label: "Change Record ID",
        overrideLabel: <span>Change Record ID</span>,
        value: "ids.change_record_id",
    },
    {
        label: "Channel of Origin",
        overrideLabel: <span>Channel of Origin</span>,
        value: "other.channelOfOrigin",
    },

    // {
    //     label: "Genres",
    //     overrideLabel: <span>Genres</span>,
    //     value: "descriptions.genre",
    // },
]; // .concat(additionalFields);

export const inheritFields = (
    tvShow: TvShowVT,
    defaultValues?: TvShowVT = {},
): TvShowVT => {
    const episodeInheritance = tvShow?.episodeInheritance || [];
    return {
        ...tvShow,
        ...defaultValues,
        brand: episodeInheritance?.includes("brand")
            ? tvShow?.brand
            : !isEmpty(defaultValues?.brand)
            ? defaultValues.brand
            : CORE_FORM_STRING_DEFAULT_VALUE,

        releaseYear: episodeInheritance?.includes("releaseYear")
            ? tvShow?.releaseYear
            : !isNaN(Number(defaultValues?.releaseYear)) &&
              !isEmpty(defaultValues?.releaseYear)
            ? defaultValues.releaseYear
            : CORE_FORM_NUMBER_DEFAULT_VALUE,

        provenance: episodeInheritance?.includes("provenance")
            ? tvShow?.provenance
            : !isEmpty(defaultValues?.provenance)
            ? defaultValues.provenance
            : CORE_FORM_STRING_DEFAULT_VALUE,

        displayTitle: episodeInheritance?.includes("displayTitle")
            ? tvShow?.displayTitle
            : !isEmpty(defaultValues?.displayTitle)
            ? defaultValues.displayTitle
            : CORE_FORM_STRING_DEFAULT_VALUE,

        originalLanguage: episodeInheritance?.includes("originalLanguage")
            ? tvShow?.originalLanguage
            : !isEmpty(defaultValues?.originalLanguage)
            ? defaultValues.originalLanguage
            : CORE_FORM_STRING_DEFAULT_VALUE,

        countryOfOrigin: episodeInheritance?.includes("countryOfOrigin")
            ? tvShow?.countryOfOrigin
            : !isEmpty(defaultValues?.countryOfOrigin)
            ? defaultValues.countryOfOrigin
            : CORE_FORM_STRING_DEFAULT_VALUE,

        titles: {
            aka: episodeInheritance?.includes("titles.aka")
                ? tvShow?.titles?.aka
                : !isEmpty(defaultValues?.titles?.aka)
                ? defaultValues.titles.aka
                : CORE_FORM_ARRAY_DEFAULT_VALUE,

            fka: episodeInheritance?.includes("titles.fka")
                ? tvShow?.titles?.fka
                : !isEmpty(defaultValues?.titles?.fka)
                ? defaultValues.titles.fka
                : CORE_FORM_ARRAY_DEFAULT_VALUE,

            short: episodeInheritance?.includes("titles.short")
                ? tvShow?.titles?.short
                : !isEmpty(defaultValues?.titles?.short)
                ? defaultValues.titles.short
                : CORE_FORM_STRING_DEFAULT_VALUE,

            sort: episodeInheritance?.includes("titles.sort")
                ? tvShow?.titles?.sort
                : !isEmpty(defaultValues?.titles?.sort)
                ? defaultValues.titles.sort
                : CORE_FORM_STRING_DEFAULT_VALUE,

            alternative: episodeInheritance?.includes("titles.alternative")
                ? tvShow?.titles?.alternative
                : !isEmpty(defaultValues?.titles?.alternative)
                ? defaultValues.titles.alternative
                : CORE_FORM_STRING_DEFAULT_VALUE,

            release: episodeInheritance?.includes("titles.release")
                ? tvShow?.titles?.release
                : !isEmpty(defaultValues?.titles?.release)
                ? defaultValues.titles.release
                : CORE_FORM_STRING_DEFAULT_VALUE,
        },

        descriptions: {
            genre: episodeInheritance?.includes("descriptions.genre")
                ? tvShow?.descriptions?.genre
                : !isEmpty(defaultValues?.descriptions?.genre)
                ? defaultValues.descriptions.genre
                : CORE_FORM_ARRAY_DEFAULT_VALUE,

            subGenre: episodeInheritance?.includes("descriptions.subGenre")
                ? tvShow?.descriptions?.subGenre
                : !isEmpty(defaultValues?.descriptions?.subGenre)
                ? defaultValues.descriptions.subGenre
                : CORE_FORM_ARRAY_DEFAULT_VALUE,

            description100: episodeInheritance?.includes(
                "descriptions.description100",
            )
                ? tvShow?.descriptions?.description100
                : !isEmpty(defaultValues?.descriptions?.description100)
                ? defaultValues.descriptions.description100
                : CORE_FORM_STRING_DEFAULT_VALUE,

            description250: episodeInheritance?.includes(
                "descriptions.description250",
            )
                ? tvShow?.descriptions?.description250
                : !isEmpty(defaultValues?.descriptions?.description250)
                ? defaultValues.descriptions.description250
                : CORE_FORM_STRING_DEFAULT_VALUE,

            longDescription1024: episodeInheritance?.includes(
                "descriptions.longDescription1024",
            )
                ? tvShow?.descriptions?.longDescription1024
                : !isEmpty(defaultValues?.descriptions?.longDescription1024)
                ? defaultValues.descriptions.longDescription1024
                : CORE_FORM_STRING_DEFAULT_VALUE,
        },

        ids: {
            rms: episodeInheritance?.includes("ids.rms")
                ? tvShow?.ids?.rms
                : !isEmpty(defaultValues?.ids?.rms)
                ? defaultValues.ids.rms
                : CORE_FORM_STRING_DEFAULT_VALUE,

            legacySap: episodeInheritance?.includes("ids.legacySap")
                ? tvShow?.ids?.legacySap
                : !isEmpty(defaultValues?.ids?.legacySap)
                ? defaultValues.ids.legacySap
                : CORE_FORM_STRING_DEFAULT_VALUE,

            s4_mpm_id: episodeInheritance?.includes("ids.s4_mpm_id")
                ? tvShow?.ids?.s4_mpm_id
                : !isEmpty(defaultValues?.ids?.s4_mpm_id)
                ? defaultValues.ids.s4_mpm_id
                : CORE_FORM_STRING_DEFAULT_VALUE,

            change_record_id: episodeInheritance?.includes(
                "ids.change_record_id",
            )
                ? tvShow?.ids?.change_record_id
                : !isEmpty(defaultValues?.ids?.change_record_id)
                ? defaultValues.ids.change_record_id
                : CORE_FORM_STRING_DEFAULT_VALUE,
        },

        other: {
            channelOfOrigin: episodeInheritance?.includes(
                "other.channelOfOrigin",
            )
                ? tvShow?.other?.channelOfOrigin
                : !isEmpty(defaultValues?.other?.channelOfOrigin)
                ? defaultValues.other.channelOfOrigin
                : CORE_FORM_STRING_DEFAULT_VALUE,
        },
    };
};
