import Prefixer from "inline-style-prefixer";

export default function styles(props = {}) {
    let styles = {
        label: {
            display: "block",
            marginBottom: "0.5625rem",
        },
        container: {
            display: "flex",
            display: "flex",
            alignItems: "center",
        },
    };

    let prefixer = new Prefixer();
    styles = prefixer.prefix(styles);

    return styles;
}
