import React, { useEffect, useState } from "react";
import { useStore } from "../state/context";
import { useStore as useGlobalStore } from "../../Global/store/store";
import DoubleColumnContent from "../../Boilerplates/DoubleColumnContent/DoubleColumnContent";
import {
    setState,
    setConditionsConfiguration,
    setConditionsData,
} from "../state/reducer";
import mapConditionsBuilderToBoilerplateConfiguration from "./helpers/MapConditionsBuilderToBoilerplateConfiguration";
import TestConfigurationPayload from "../test/ConditionsConfigurationPayload";
import ContentFieldInterface from "../../Boilerplates/DoubleColumnContent/ContentField.interface";
import _ from "lodash";
import FadeMaterial from "../../TransitionGroup/FadeMaterial";
import FadeWithSkeleton from "../../SkeletonLoader/FadeWithSkeleton";
import { SCHEDULING } from "../../../models/RecordBuilder";
import BuilderError from "../../RecordBuilder/FetchError";
import { envTest } from "../../Environment"

const SchedulingConditions = (): JSX.Element => {
    const [store, dispatch] = useStore() || [[]];
    const [globalStore] = useGlobalStore() || [[]];

    const {
        schedulingConditions = {},
        disabled,
        loadingBuilderConfig = false,
        conditionsConfiguration = [],
        recordCategory,
    } = store;

    const {
        cmsData = {},
        recordBuilder = {},
        recordBuilderError = false,
    } = globalStore || {};

    const { clientDataStructure = {}, apiUrls = {} } = cmsData;

    const [loadConfigError, setLoadConfigError] = useState<boolean>(
        !!recordBuilderError,
    );

    const [conditions, setConditions] = useState<any[]>(schedulingConditions);

    useEffect(() => {
        if (envTest) {
            dispatch(setConditionsConfiguration(TestConfigurationPayload));
            dispatch(setState({ loadingBuilderConfig: false }));
            return;
        }

        dispatch(
            setConditionsConfiguration(
                recordBuilder[SCHEDULING],
            ),
        );
    }, []);

    const fieldsConfigurationList: ContentFieldInterface[] =
        mapConditionsBuilderToBoilerplateConfiguration({
            conditions,
            recordCategory,
            conditionsConfiguration,
            dateFormat: clientDataStructure.date_format,
        });

    const handleChange = (key: string, value: any) => {
        const fieldConfiguration: ContentFieldInterface | undefined =
            fieldsConfigurationList.find((field: any) => field.key == key);

        if (fieldConfiguration) {
            let conditionsEdited: any = Object.assign({}, conditions);
            if (fieldConfiguration.type === "boolean") {
                conditionsEdited[key] = Boolean(value.value) || false;
            } else {
                conditionsEdited[key] = value;
            }

            dispatch(setConditionsData(conditionsEdited));
            setConditions(conditionsEdited);
        }
    };

    if (_.isEmpty(fieldsConfigurationList) && !envTest) {
        return <></>
    }

    return (
        <React.Fragment>
            <div className="scheduling-conditions">
                <FadeWithSkeleton loading={loadingBuilderConfig} />

                <FadeMaterial loading={!loadingBuilderConfig}>
                    <div>
                        {loadConfigError && <BuilderError />}
                        {!loadConfigError && (
                            <div className="conditions-wrapper-first">
                                <div className="conditions-wrapper-second">
                                    <span className="block-title">
                                        Scheduling Conditions
                                    </span>
                                    <DoubleColumnContent
                                        fields={fieldsConfigurationList}
                                        disabled={disabled}
                                        handleChange={(
                                            key: string,
                                            value: any,
                                        ) => handleChange(key, value)}
                                        apiUrls={apiUrls}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </FadeMaterial>
            </div>
        </React.Fragment>
    );
};

export default SchedulingConditions;
