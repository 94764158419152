import _ from "lodash";
import SendRequest from "../../../components/SendRequest";
import GetApiUrls from "../GetApiUrls";
import GetRecordData from "../../../components/GetRecordData";
import HandleUserGroupUpdateRequest from "./HandleUserGroupUpdateRequest";

const HandleImageChange = (props) => {
    const { state = {}, event } = props;

    const { set, newRecord = {}, recordData = {} } = state;

    const record = GetRecordData(state);

    const { meta_id, meta_id_temp } = record;

    const { userGroupsUploadLogo = "" } = GetApiUrls(state);

    if (event && event.target) {
        const target = event.target.files[0];

        let metaId = meta_id || meta_id_temp;
        let formData = new FormData();

        formData.append("image", target);

        set({
            uploadingUserGroupImage: true,
        });

        const options = {
            url: userGroupsUploadLogo.replace("%meta_id%", metaId),
            method: "POST",
            basic: true,
            noJson: true,
            formData,
        };

        const handleError = (error) => {
            if (error) console.log(error);

            set({
                uploadingUserGroupImage: false,
                notification: {
                    title: "Technical error",
                    description: "Failed to upload user group logo",
                    status: "alert",
                    onClick: () =>
                        set({
                            notification: {},
                        }),
                },
            });
        };

        SendRequest(
            options,
            (response) => {
                const { data, status, message } = response;

                if (status == 200) {
                    let filename = data.filename;
                    let original_filename = data.original_filename;

                    if (process.env.NODE_ENV == "test") {
                        filename = "filename";
                        original_filename = "original_filename";
                    }

                    set({
                        uploadingUserGroupImage: false,
                    });

                    if (!_.isEmpty(newRecord)) {
                        let updatedState = { ...newRecord };
                        updatedState.logo = filename;
                        updatedState.original_filename = original_filename;

                        set({
                            newRecord: updatedState,
                        });
                    }

                    if (!_.isEmpty(recordData)) {
                        let updatedState = { ...recordData };
                        updatedState.logo = filename;
                        updatedState.original_filename = original_filename;

                        HandleUserGroupUpdateRequest({
                            state,
                            userGroup: updatedState,
                        });
                    }

                    return;
                }

                handleError(message);
            },
            (e) => {
                handleError(e);
            },
        );
    }
};

export default HandleImageChange;
