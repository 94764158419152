import DefaultRoles from "../DefaultRoles";

export default function Roles() {
    const Role_DataManager = "data-manager";
    const Role_SensitiveViewer = "sensitive-viewer";
    const Role_CollectionsManager = "collections-manager";
    const Role_FinanceManager = "finance-manager";

    const {
        Role_SuperAdmin,
        Role_PlatformAdmin,
        Role_Admin,
        Role_Viewer,
        Role_LocalisationManager,
        Role_LanguageManager,
        Role_Okta_User,
        Role_RegionalEditor,
    } = DefaultRoles();

    return {
        Role_SuperAdmin,
        Role_PlatformAdmin,
        Role_Admin,
        Role_Viewer,
        Role_LocalisationManager,
        Role_LanguageManager,
        Role_Okta_User,
        Role_RegionalEditor,
        Role_DataManager,
        Role_SensitiveViewer,
        Role_CollectionsManager,
        Role_FinanceManager,
    };
}
