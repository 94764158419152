import React from "react";
import RecordImageContainer from "../../../components/RecordImageContainer";
import BorderRadius from "../../../components/BorderRadius";
import AuditTooltip from "../../../components/AuditTooltip";
import styles from "../../../components/RecordBody/styles";
import { useStore } from "../../../components/Global/store/store";
import { GridViewInterface } from "../interfaces"

const GridView = (props: GridViewInterface) => {
    const [globalState] = useStore() || [{}];
    const classStyles = styles()

    const {
        data = [],
        disabled,
        audits,
        meta_id,
        clientManagedLanguages,
        languages,
        image_types,
        userPermissions,
        handleEdit = () => {},
        handleDelete = () => {}
    } = props

    const {
        cmsData = {}
    } = globalState

    const {
        clientFeatures = {}
    } = cmsData

    return (
        <>
            {data.map((item, index) => {
                if (!item.deleted) {
                    return (
                        <div
                            key={index}
                            className="meta-record-image-container"
                            style={
                                classStyles.images.recordImageContainer
                            }
                        >
                            <BorderRadius
                                styles={{
                                    height: "100%",
                                    minHeight: "37.0938rem",
                                }}
                            >
                                <AuditTooltip
                                    audits={audits}
                                    metaId={item.meta_id}
                                    clientFeatures={clientFeatures}
                                    dataSection={"images"}
                                >
                                    <RecordImageContainer
                                        data={item}
                                        meta_id={meta_id}
                                        options={{
                                            languages: clientManagedLanguages
                                                ? clientManagedLanguages
                                                : languages,
                                            image_types,
                                        }}
                                        disabled={disabled}
                                        handleEdit={() => handleEdit(index)}
                                        handleDelete={() => handleDelete(index)}
                                        cmsData={cmsData}
                                        userPermissions={userPermissions}
                                    />
                                </AuditTooltip>
                            </BorderRadius>
                        </div>
                    );
                }
            })}
        </>
    );
}

export default GridView