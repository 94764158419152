import { Stack } from "@mui/material";
import { StackProps } from "@mui/system";
import React from "react";

export type CoreFormStackProps = Omit<StackProps, "spacing"> & {
    children: React.ReactNode;
    customStyles?: {
        [key: string]: string
    }
};
export const CoreFormStack = ({
    children,
    direction = "column",
    customStyles = {}
}: CoreFormStackProps) => {
    return (
        <Stack sx={customStyles} spacing={direction === "row" ? 2 : 4.5} direction={direction}>
            {children}
        </Stack>
    );
};
