import { CoreRadioStack } from "../../../../../../core/Form/components/inputs/Radio/CoreRadioStack";
import { TvShowVT } from "../../../../config/types";
import { tvSeriesCategoryOptions } from "../../tv-series/smart/CategoriesForm";
import { Categories } from "../../tv-series/types/types";

export const categoryOptions = tvSeriesCategoryOptions;

type CategoryTvSeriesProps = {
    variant?: "episode-level";
};
export const CategoryTvSeries = ({ variant }: CategoryTvSeriesProps) => {
    let options = categoryOptions;

    if (variant === "episode-level") {
        options = options.filter(
            (option) =>
                option.value === Categories.Episode ||
                option.value === Categories.Storyline ||
                option.value === Categories.ModularEpisode,
        );
    }
    return (
        <CoreRadioStack<TvShowVT>
            options={options}
            path="category"
            labelProps={{
                text: "Record Category",
                isRequired: true,
            }}
        />
    );
};
