/* eslint-disable no-restricted-syntax */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-shadow */
import { isEmpty, uniq } from "lodash";
import { action, extendObservable, makeObservable } from "mobx";
import { RootStore } from "../../store/rootStore";
import TaskManagerStoreInterface from "../../store/TaskManagerStoreInterface";
import {
    BackgroundProcessingErrorType,
    BackgroundProcessingRecordStatuses,
    BackgroundProcessRecordType,
    BackgroundProcessStatuses,
    BackgroundProcessType,
    ProcessIdType,
    WidgetState,
} from "../types";

export type BackgroundProcessingConfigType = {
    processingText: string;
};

export type InitialStateType = {
    processes: BackgroundProcessType[];
    config: BackgroundProcessingConfigType;
    processIdDetails: BackgroundProcessType["processId"] | null; // used to open the details dialog
};

const initialState: InitialStateType = {
    processes: [],
    config: {
        processingText: "",
    },
    processIdDetails: null,
};

class BackgroundProcessingStore implements TaskManagerStoreInterface {
    storeName = BackgroundProcessingStore.name;
    rootStore: RootStore;
    processes: InitialStateType["processes"] = [];
    config: InitialStateType["config"] = {
        processingText: "Processing...",
    };
    processIdDetails: InitialStateType["processIdDetails"] = null;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            initialize: action,
            setConfig: action,
            setProcesses: action,
            updateProcess: action,
            removeProcess: action,
            setProcessIdDetails: action,
            clearErrorsForRecord: action,
            clearErrorsForProcess: action,
            addProcess: action,
        });

        extendObservable(this, initialState);
    }

    async initialize(...args: any[]) {
        if (this.rootStore.storesInit.backgroundProcessing) {
            throw new Error(
                `Task Manager backgroundProcessing Store has already been initialized.`,
            );
        }

        this.rootStore.finishLoading("backgroundProcessing");
    }

    // Actions - these are the only way to modify the state
    setConfig(config: BackgroundProcessingConfigType) {
        this.config = config;
    }

    setProcesses(processes: BackgroundProcessType[]) {
        this.processes = processes;
    }

    addProcess(process: BackgroundProcessType) {
        // if processId exists in the list, no need to update it
        if (this.getProcessById(process.processId)) {
            return;
        }

        // if process has STARTED status, we need to add it first in the list
        if (process.status === BackgroundProcessStatuses.STARTED) {
            this.processes.unshift(process);
            return;
        }
        this.processes.push(process);
    }

    updateProcess(process: BackgroundProcessType) {
        const existingProcess = this.getProcessById(process.processId);
        if (!existingProcess) {
            this.addProcess(process);
            return;
        }

        // find the index of the process
        const index = this.processes.findIndex(
            (item) => item.processId === process.processId,
        );

        // update the process
        this.processes[index] = process;
    }

    removeProcess(processId: ProcessIdType["processId"]) {
        this.processes = this.processes.filter(
            (process) => process.processId !== processId,
        );
    }

    // used to open the details dialog for a process
    setProcessIdDetails(processId: ProcessIdType["processId"]) {
        this.processIdDetails = processId;
    }

    clearErrorsForRecord(record: BackgroundProcessRecordType) {
        record.error = [];
    }

    clearErrorsForProcess(processId: ProcessIdType["processId"]) {
        const records = this.getProcessRecords(processId);
        for (const record of records) {
            this.clearErrorsForRecord(record);
        }

        if (this.getProcessErrorsCount(processId) === 0) {
            this.processIdDetails = null;
        }
    }

    // Non-actions - these are computed values, not state modifiers

    getProcesses() {
        return this.processes;
    }

    getProcessesCount() {
        return this.processes.length;
    }

    getProcessById(processId: ProcessIdType["processId"]) {
        return this.processes.find(
            (process) => process.processId === processId,
        );
    }

    getTicketIdForProcess(processId: ProcessIdType["processId"]) {
        const ticketId =
            this.getProcessById(processId)?.payload?.ticketId || null;

        return ticketId;
    }

    getProcessRecords(processId: ProcessIdType["processId"]) {
        const records = this.getProcessById(processId).payload?.records || [];
        return records;
    }

    getProcessRecordsCount(processId: ProcessIdType["processId"]) {
        return this.getProcessRecords(processId).length;
    }

    getProcessErrors(processId: ProcessIdType["processId"]) {
        const records = this.getProcessRecords(processId);
        const errors = records
            .filter((record) => !isEmpty(record?.error))
            .map((record) => record.error);

        return errors || [];
    }

    hasProcessFailed(processId: ProcessIdType["processId"]) {
        return (
            this.getProcessById(processId)?.status ===
            BackgroundProcessStatuses.FAILED
        );
    }

    getProcessErrorsCount(processId: ProcessIdType["processId"]) {
        return this.getProcessErrors(processId).length;
    }

    hasProcessErrors(processId: ProcessIdType["processId"]) {
        return this.getProcessErrorsCount(processId) > 0;
    }

    hasProcessFinished(processId: ProcessIdType["processId"]) {
        return (
            this.getProcessById(processId)?.status ===
            BackgroundProcessStatuses.FINISHED
        );
    }

    getRecordsCountForProcess(processId: ProcessIdType["processId"]) {
        return this.getProcessRecords(processId).length;
    }

    getPendingRecordsCountForProcess(processId: ProcessIdType["processId"]) {
        const records = this.getProcessRecords(processId);
        return records.filter(
            (item) =>
                item?.status === BackgroundProcessingRecordStatuses.PENDING,
        ).length;
    }

    getProcessedRecordsCountForProcess(processId: ProcessIdType["processId"]) {
        return (
            this.getRecordsCountForProcess(processId) -
            this.getPendingRecordsCountForProcess(processId)
        );
    }

    getProcessedProcessesCount() {
        return this.processes.filter(
            (process) =>
                process.status === BackgroundProcessStatuses.FINISHED ||
                process.status === BackgroundProcessStatuses.FAILED,
        ).length;
    }

    getFailedProcessesCount() {
        let counter = 0;
        for (const process of this.processes) {
            counter += this.getProcessErrorsCount(process.processId);
        }

        return counter;
        // return this.processes.filter(
        //     (process) => process.status === BackgroundProcessStatuses.FAILED,
        // ).length;
    }

    getWidgetState() {
        if (this.getProcessesCount() === 0) return WidgetState.HIDDEN;

        // all processes are processed (finished or failed)
        if (this.getProcessedProcessesCount() === this.getProcessesCount()) {
            if (this.getFailedProcessesCount() > 0) {
                return WidgetState.ERROR;
            }
            return WidgetState.SUCCESS;
        }

        return WidgetState.LOADING;
    }

    getFlattenErrors(error: BackgroundProcessingErrorType) {
        return uniq(
            [].concat(error?.data).concat(error?.message).filter(Boolean),
        );
    }

    getStartedProcessesCount() {
        return this.processes.filter(
            (process) => process.status === BackgroundProcessStatuses.STARTED,
        ).length;
    }

    getRecordTitleForError(
        processId: ProcessIdType["processId"],
        error: BackgroundProcessingErrorType,
    ) {
        const record = this.getProcessRecords(processId).find(
            (record) => JSON.stringify(record.error) === JSON.stringify(error),
        );
        return record?.title || "";
    }

    // Events - these will send messages to the websocket server
    listProcesses() {
        this.rootStore.webSocketService.listProcesses();
    }

    killProcess(processId: ProcessIdType["processId"]) {
        this.rootStore.webSocketService.killProcess(processId);
        this.removeProcess(processId);
    }

    getProcessDetails(processId: ProcessIdType["processId"]) {
        this.rootStore.webSocketService.getProcessDetails(processId);
    }
}

export default BackgroundProcessingStore;
