import React from "react";
import _ from "lodash";
import "./styles/_doubleRightArrowTag.scss";

type Props = {
    firstText?: string | number;
    secondText?: string | number;
};

type HalfType = "first" | "second";

const DoubleRightArrowTag: React.FC<Props> = (props) => {
    const { firstText, secondText } = props;

    const GetText = (type: HalfType): string | number => {
        if (type === "first" && firstText) {
            return firstText;
        }

        if (type === "second" && secondText) {
            return secondText;
        }

        return "-";
    };

    const GetColor = (
        type: HalfType,
        area: "color" | "background" = "background",
    ): string => {
        if (type === "first" && firstText) {
            return area == "background" ? "#FFA5A3" : "#711E1C";
        }

        if (type === "second" && secondText) {
            return area == "background" ? "#F56F65" : "#481212";
        }

        return area == "background" ? "#D2D2D2" : "#481212";
    };

    return (
        <div className="double-right-arrow-tag">
            <div
                className="first-tag tag"
                style={{
                    background: GetColor("first"),
                    color: GetColor("first", "color"),
                }}
            >
                {GetText("first")}
            </div>
            <div
                className="triangle"
                style={{
                    borderColor: `transparent transparent transparent ${GetColor(
                        "first",
                    )}`,
                }}
            ></div>
            <div
                className="second-tag tag"
                style={{
                    background: GetColor("second"),
                    color: GetColor("second", "color"),
                }}
            >
                {GetText("second")}
            </div>
        </div>
    );
};

export default DoubleRightArrowTag;
