import { Box, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { CoreTooltip } from "../../Tooltip/CoreTooltip";
import { useBodyFontSize } from "../../utils/useBodyFontSize";
import { CoreCellText } from "./core-cell-v2/CoreCellText";
import { CoreTableCellRenderMultipleChips } from "./CoreTableCellRenderMultipleChips";

const StyledStack = styled(Stack)(({ theme }) => ({}));
StyledStack.defaultProps = {
    direction: "row",
    spacing: 1,
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
};
type RenderInfoProps = {
    rightChipValue?: string;
    useData: (value?: string) => any; // Adjust the type accordingly
};
export const RenderInfo = ({
    rightChipValue: value,
    useData,
}: RenderInfoProps) => {
    const { isNormalFontSize } = useBodyFontSize();
    const data = useData(value);
    const MAX_LENGTH = isNormalFontSize ? 19 : 22; // 22 for fontSize 80%

    let label = data?.label;
    let tooltipEnabled = false;

    if (label?.length > MAX_LENGTH) {
        label = label?.substring(0, MAX_LENGTH) + "...";
        tooltipEnabled = true;
    }

    const labelComponent = (
        <Box>
            <CoreCellText>{label}</CoreCellText>
        </Box>
    );

    return (
        <StyledStack>
            {label && (
                <Box>
                    {tooltipEnabled ? (
                        <CoreTooltip title={data?.label}>
                            {labelComponent}
                        </CoreTooltip>
                    ) : (
                        labelComponent
                    )}
                </Box>
            )}
            <Box>
                <CoreTableCellRenderMultipleChips value={value} />
            </Box>
        </StyledStack>
    );
};
