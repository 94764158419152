import CircleIcon from "@mui/icons-material/Circle";

export enum CoreDialogTabIconColorEnum {
    Red = "Red",
    Green = "Green",
    Orange = "Orange",
    Gray = "Gray",
}
export const CoreDialogCircleIcon = ({
    variant,
}: {
    variant: CoreDialogTabIconColorEnum;
}) => {
    let color: string = variant;

    switch (variant) {
        case CoreDialogTabIconColorEnum.Red: {
            color = "rgba(236, 74, 74, 1)";
            break;
        }
        case CoreDialogTabIconColorEnum.Green: {
            color = "rgba(12, 174, 121, 1)";
            break;
        }
        case CoreDialogTabIconColorEnum.Orange: {
            color = "rgba(249, 152, 7, 1)";
            break;
        }
        case CoreDialogTabIconColorEnum.Gray: {
            color = "rgba(156, 156, 156, 1)";
            break;
        }

        default: {
            color = "rgba(156, 156, 156, 1)";
            break;
        }
    }
    return (
        <CircleIcon
            sx={{
                marginLeft: "0.5rem",
                width: "0.75rem",
                height: "0.75rem",
                color,
            }}
        />
    );
};
