import { OptionInterface } from "../../interfaces"
import { defaultSectionOptions } from "./"
import Localisations from "../../../../components/RecordBody/src/sections/Localisations";

export const localisationsOption: OptionInterface = {
    name: "localisations",
    path: "localisations",
    children: [],
};

export const localisationTabOption: OptionInterface = {
    name: "localisations",
    path: "tab.localisations",
    children: [],
    Component: Localisations,
    ...defaultSectionOptions,
};