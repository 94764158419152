import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Stack } from "@mui/material";
import { CoreTableCell } from "../../components/CoreTableCell";
import { CoreTableGridColDef } from "../../types";
import { CoreTooltip } from "../../../Tooltip/CoreTooltip";
import Settings from "../../../../components/Settings";

export const ImageNameCol: CoreTableGridColDef = {
    headerName: "Image Name",
    field: "name",
    flex: 2,
    renderCell: (params: GridRenderCellParams<string>) => {
        const { row } = params;

        return (
            <Stack direction="row" spacing={1}>
                <CoreTableCell>{row.name || ""}</CoreTableCell>

                {row.poster === 1 && (
                    <CoreTooltip title="Poster Image">
                        <img
                            alt={`Poster for ${row.name}`}
                            src={`${Settings.images.path}/svg/star.svg`}
                            className="star-icon"
                        />
                    </CoreTooltip>
                )}
            </Stack>
        );
    },
};
