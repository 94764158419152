import _ from "lodash";
import SendRequest from "../../components/SendRequest";
import deepcopy from "deepcopy";

const HandleRecordDeleteRequest = (props) => {
    const { state = {}, meta_id, copy = {} } = props;

    const {
        set,
        cmsData = {},
        notification,
        handleResetRecordAndSearch,
    } = state;

    const { apiUrls = {} } = cmsData;

    let notificationState = deepcopy(notification);
    notificationState.loading = true;

    set({
        notification: notificationState,
    });

    const options = {
        url: apiUrls.metaDataUrl.replace("{meta_id}", meta_id),
        method: "DELETE",
    };

    const handleError = (error) => {
        set({
            notification: {
                title: copy.technicalError || "Technical Error",
                description:
                    copy.recordCouldNotBeDeleted ||
                    "Record could not be deleted",
                status: "alert",
                onClick: () => {
                    set({
                        notification: {},
                    });
                },
            },
        });

        if (error) {
            console.log(error);
        }
    };

    const successNotification = {
        title: copy.recordDeleted || "Record Deleted",
        description:
            copy.recordDeletedDescription ||
            "Record has been successfully deleted",
        status: "success",
        okText: false,
        confirm: false,
        disabled: false,
    };

    SendRequest(
        options,
        (response) => {
            const { status, data, message } = response;

            if (status == 200) {
                set(
                    {
                        notification: successNotification,
                    },
                    () => {
                        handleResetRecordAndSearch();
                    },
                );

                return;
            }

            // On Error
            handleError(message);
        },
        (e) => {
            handleError(e);
        },
    );
};

export default HandleRecordDeleteRequest;
