import React from "react";
import RecordTitle from "../../RecordTitle";

const RenderTitle = (item, styles = {}) => {
    return (
        <span
            className="meta-numbered-record-table--original-title"
            style={styles}
        >
            {RecordTitle(item)}
        </span>
    );
};

export default RenderTitle;
