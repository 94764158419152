import RegionalAgeGuidancePayloadItemInterface from "../../interfaces/RegionalAgeGuidancePayloadItem.interface";
import RegionalAgeGuidanceRowInterface from "../../interfaces/RegionalAgeGuidanceRow.interface";

const TransformRegionalDataOutgoing = (
    data: Array<RegionalAgeGuidanceRowInterface>,
): Array<RegionalAgeGuidancePayloadItemInterface> => {
    let rows: RegionalAgeGuidancePayloadItemInterface[] = [];
    data.forEach((item: RegionalAgeGuidanceRowInterface) => {
        item.territories.forEach((territory) => {
            let payloadItem: RegionalAgeGuidancePayloadItemInterface = {
                territory,
                maximum_age: item.maximum_age,
                minimum_age: item.minimum_age,
            };

            if (item.deleted) {
                payloadItem.deleted = true;
            }

            rows.push(payloadItem);
        });
    });

    return rows;
};

export default TransformRegionalDataOutgoing;
