import _ from "lodash";
import messages from "../../../config/messages"

const HandleCollectionSearch = (props) => {
    const { searchQuery = "", state = {}, copy = {}, category } = props;

    const { set, unsavedChanges, handleRecentActivitySearch } = state;

    const search = () => {
        set(
            {
                searchQuery,
                activeRecord: false,
                unsavedChanges: false,
                hoverRecord: false,
                recordData: {},
                notification: {},
            },
            () => {
                handleRecentActivitySearch(category);
            },
        );
    };

    if (unsavedChanges) {
        set({
            notification: {
                title: copy.unsavedChanges || "Unsaved Changes",
                description:
                    copy.unsavedChangesDescription ||
                    messages.recordUnsavedChanges,
                okText: copy.cancelOkText || "Leave this Page",
                status: "error",
                onClick: () => {
                    set({
                        notification: {},
                    });
                },
                confirm: () => {
                    search();
                },
            },
        });

        return;
    }

    search();
};

export default HandleCollectionSearch;
