import { StandaloneTicket } from "../../containers/ticket/shared/validationSchema";
import { TicketTypeUpdate } from "../../types/constants";
import getTicketType from "../../utils/getTicketType";
import RecordCreatePermissionPaths from "./RecordCreatePermissionPaths";
import RecordUpdatePermissionPaths from "./RecordUpdatePermissionPaths";

class TicketTypePathHandler {
    private recordCreateHandler: RecordCreatePermissionPaths;
    private recordUpdateHandler: RecordUpdatePermissionPaths;

    constructor() {
        this.recordCreateHandler = new RecordCreatePermissionPaths();
        this.recordUpdateHandler = new RecordUpdatePermissionPaths();
    }

    getHandler(ticket: StandaloneTicket) {
        const ticketType = getTicketType(ticket);

        if (ticketType === TicketTypeUpdate) {
            return this.recordUpdateHandler;
        }

        return this.recordCreateHandler;
    }
}

export default TicketTypePathHandler;
