import SendRequest from "../../../components/SendRequest";
import GetApiUrls from "../GetApiUrls";

const HandleFetchCatalogAvailabilityRequest = (props) => {
    const { state, method, body, handleError, handleSuccess } = props;

    const { activeRecord = {} } = state;

    const { meta_id } = activeRecord;

    const { catalogAvailability = "" } = GetApiUrls(state);

    const options = {
        url: catalogAvailability.replace("{meta_id}", meta_id),
        method,
        body,
    };

    try {
        SendRequest(
            options,
            (response) => {
                const { data = {}, message, status } = response;

                if (status == 200) {
                    handleSuccess(data);
                    return;
                }

                handleError(message);
            },
            (error) => {
                handleError(error);
            },
        );
    } catch (e) {
        handleError(e);
    }
};

export default HandleFetchCatalogAvailabilityRequest;
