import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const CoreFormErrorText = styled(Typography)(({ theme }) => ({
    color: "rgba(214, 70, 94, 0.75)", // TODO: get it from theme
    fontWeight: 400,
    fontSize: "0.75rem",
    lineHeight: "145%",
    marginTop: "0.25rem !important",
}));

export type CoreFormErrorProps = {
    message: string | undefined;
    path: string;
};
export const CoreFormFieldError = ({ message, path }: CoreFormErrorProps) => {
    return (
        <CoreFormErrorText data-testid={`field-error-${path}`}>
            {message}
        </CoreFormErrorText>
    );
};
