import { Box } from "@mui/material";
import { isEmpty } from "lodash";
import { observer } from "mobx-react";
import { useFormContext } from "react-hook-form";
import { CoreFormLabel } from "../../../../../../core/Form/components/styled/CoreForm.label";
import { AllFieldsVT } from "../../../../config/types";
import { ExistingItemsSkeleton } from "../components/ExistingItemsSkeleton";
import { GreenChip } from "../components/GreenChip";
import { useFetchLinkage } from "../hooks/useFetchLinkage";
import { GridXS12 } from "../layout/grid";
import { Categories } from "../types/types";

export const ExistingLinkedItems = observer(
    ({ category: categoryProp }: { category?: AllFieldsVT["category"] }) => {
        const form = useFormContext<AllFieldsVT>();
        let category = form.getValues("category");
        if (!isEmpty(categoryProp)) {
            category = categoryProp;
        }
        const parentShowMetaId = form.getValues("parentShow.metaId");
        const parentSeasonMetaId = form.getValues("parentSeason.metaId");
        const metaId =
            category === Categories.Season
                ? parentShowMetaId
                : parentSeasonMetaId;
        const { linkedItems, loading } = useFetchLinkage({ category, metaId });

        if (loading) {
            return (
                <Box
                    sx={{
                        padding: "0.5rem 0 0 0.5rem",
                    }}
                >
                    <ExistingItemsSkeleton />
                </Box>
            );
        }

        if (
            !isEmpty(linkedItems) &&
            (category === Categories.Episode ||
                category === Categories.Storyline)
        ) {
            return (
                <GridXS12>
                    <Box sx={{ marginBottom: "0.5rem" }}>
                        <CoreFormLabel text="Existing Episodes" />
                        {linkedItems
                            ?.slice() // Create a shallow copy of the array to avoid mutating the original array
                            .sort(
                                (episodeA, episodeB) =>
                                    episodeA.episodeNo - episodeB.episodeNo,
                            )
                            .map((episode) => (
                                <GreenChip
                                    key={`episode-${episode.episodeNo}`}
                                    datatest-id={`existing-episodes-${episode.episodeNo}`}
                                    label={`Episode: ${episode.episodeNo} • ${
                                        episode?.originalProductionNo || ""
                                    }`}
                                />
                            ))}
                    </Box>
                </GridXS12>
            );
        }

        if (!isEmpty(linkedItems)) {
            return (
                <GridXS12>
                    <Box sx={{ marginBottom: "0.5rem" }}>
                        <CoreFormLabel text="Existing Seasons" />
                        {linkedItems
                            ?.slice() // Create a shallow copy of the array to avoid mutating the original array
                            .sort(
                                (seasonA, seasonB) =>
                                    seasonA.seasonNo - seasonB.seasonNo,
                            )
                            ?.map((season) => (
                                <GreenChip
                                    label={`Season: ${season.seasonNo} • ${
                                        season?.numberOfEpisodes || 0
                                    } ${
                                        season?.numberOfEpisodes !== 1
                                            ? "Episodes"
                                            : "Episode"
                                    } `}
                                />
                            ))}
                    </Box>
                </GridXS12>
            );
        }

        return null;
    },
);
