import React from "react";
import "./styles/_styles.scss";
import _ from "lodash";
import KeywordLabel from "../KeywordLabel";

const NoneAddedLabel = (props) => {
    return (
        <KeywordLabel
            title="None Added"
            background="transparent"
            color="rgb(197, 197, 197)"
            margin="0.6875rem 0.625rem 0 0"
            border="0.065rem solid rgb(197, 197, 197)"
            cursor="default"
        />
    );
};

export default NoneAddedLabel;
