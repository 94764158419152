import { CreditInterface, CreditListInterface } from "../../credits.interface";
import setListScopedRanking from "./helpers/setListScopedRanking";

export default function setCreditsRanking(
    credits: CreditInterface[],
    creditLists: CreditListInterface[]
): CreditInterface[] {

    creditLists.forEach(
        (creditList: CreditListInterface) =>
            setListScopedRanking(credits, creditList)
    )

    return credits
}