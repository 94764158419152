import { Alert, Chip } from "@mui/material";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { CoreSelect } from "../../../../../core/Form/components/inputs/Select/components/CoreSelect";
import { CoreSelectOptionType } from "../../../../../core/Form/components/inputs/Select/types/CoreSelectOptionType";
import { CoreFormPath } from "../../../../../core/Form/types";
import { EpisodeVT, SeasonVT } from "../../../config/types";
import { useStores } from "../../../store/rootStore";
import { ParentShowDisabled } from "../standalone/fields/ParentShowDisabled";
import { createParentSeason } from "./EpisodeForm.container";
import { useFetchLinkage } from "./hooks/useFetchLinkage";
import { useFetchRecordByMetaId } from "./hooks/useFetchRecordByMetaId";
import { GridXS12 } from "./layout/grid";
import { ParentShow } from "./ParentShow";
import store from "./store/store";
import { Categories } from "./types/types";

function getCoreSelectOptions(
    seasons: SeasonVT[] = [],
): CoreSelectOptionType[] {
    return seasons
        .slice() // Create a shallow copy of the array to avoid mutating the original array
        .sort((seasonA, seasonB) => seasonA.seasonNo - seasonB.seasonNo)
        .map((season) => ({
            value: season?.title,
            label: season?.title,
            rightAdornment: (
                <Chip
                    label={`${season?.numberOfEpisodes} Episodes`}
                    sx={{
                        marginTop: 0,
                        "&:only-child": {
                            marginRight: 0,
                        },
                        "& .MuiChip-label": {
                            fontSize: "0.75rem",
                        },
                    }}
                />
            ),
        }));
}

export const ParentShowParentSeasonContainer = () => {
    const form = useFormContext<EpisodeVT>();
    const rootStore = useStores();
    const pathParentShowMetaId: CoreFormPath<EpisodeVT>["path"] =
        "parentShow.metaId";
    const pathParentSeasonTitle: CoreFormPath<EpisodeVT>["path"] =
        "parentSeason.title";
    const parentShowMetaId = form.watch(pathParentShowMetaId);
    const { linkedItems: seasons, loading } = useFetchLinkage({
        category: Categories.Season,
        metaId: parentShowMetaId,
    });
    const [parentSeasonPreview, setParentSeasonPreview] =
        useState<EpisodeVT["parentSeason"]>(null);
    const { record: remoteSeason, loading: loadingSeasonDetails } =
        useFetchRecordByMetaId(parentSeasonPreview?.metaId);

    const options: CoreSelectOptionType[] = getCoreSelectOptions(seasons);

    useEffect(() => {
        if (loadingSeasonDetails) {
            store.setLoading(true);
        } else {
            store.setLoading(false);
        }
    }, [loadingSeasonDetails]);

    useEffect(() => {
        const existingSeason = store.findByMetaId(
            form?.getValues("category"),
            form?.getValues("metaId"),
        );

        let parentSeason = null;
        if (existingSeason) {
            parentSeason = existingSeason;
        } else {
            parentSeason = createParentSeason(
                seasons?.find((season) => {
                    return (
                        season?.title === form?.getValues(pathParentSeasonTitle)
                    );
                }),
            );
            console.log(
                "ParentShowParentSeasonContainer parentSeason",
                parentSeason,
            );
        }

        setParentSeasonPreview(parentSeason);

        form?.setValue("parentSeason", parentSeason);
    }, [form.watch(pathParentSeasonTitle)]);

    useEffect(() => {
        if (!isEmpty(remoteSeason?.meta_id)) {
            const seasonAdapted =
                rootStore.taskManagerAdapters.adaptRecordToTvShow(remoteSeason);
            store.setParentSeason(seasonAdapted);
        }
    }, [remoteSeason?.meta_id]);

    if (isEmpty(parentShowMetaId) && !loading) {
        return <ParentShow />;
    }

    if (!isEmpty(parentShowMetaId) && isEmpty(seasons) && !loading) {
        return (
            <GridXS12>
                <Alert severity="warning">
                    No season found for this TV show. Add a season and episode
                    after that.
                </Alert>
            </GridXS12>
        );
    }

    return (
        <>
            <ParentShowDisabled />
            <GridXS12>
                <CoreSelect<EpisodeVT["parentSeason"]["title"]>
                    config={{
                        path: pathParentSeasonTitle,
                        labelProps: {
                            text: "Parent Season",
                            isRequired: false,
                            // isDisabled: disabled,
                        },
                        options,
                        enableSearchbox: true,
                        multiple: false,
                    }}
                />
            </GridXS12>
        </>
    );
};
