import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import {
    CoreForm,
    CoreFormProvider,
    CoreFormThemeProvider,
} from "../../../core/Form/components/CoreForm";
import { CoreSelect } from "../../../core/Form/components/inputs/Select/components/CoreSelect";
import { CoreFormStack } from "../../../core/Form/components/layout/CoreForm.stack";
import { CoreSelectOptionType } from "../../../core/Form/components/inputs/Select/types/CoreSelectOptionType";
import { useEffect } from "react";

export type LocaleCoreFormProps = {
    options: CoreSelectOptionType[];
    onChange: (locale: string) => void;
    value: string;
};

export const LocaleCoreForm = ({
    options,
    onChange,
    value,
}: LocaleCoreFormProps) => {
    const form = useForm({
        defaultValues: {
            locale: value,
        },
        mode: "onSubmit",
    });

    const sortedOptions = useMemo(() => options.sort((a, b) => a.label.localeCompare(b.label)), [options])

    useEffect(() => {
        if (value !== form?.getValues("locale")) {
            onChange(form?.getValues("locale"));
        }
    }, [form?.watch("locale")]);

    return (
        <CoreFormThemeProvider>
            <CoreFormProvider {...form}>
                <CoreForm>
                    <CoreFormStack direction="row">
                        <CoreSelect
                            config={{
                                path: "locale",
                                options: sortedOptions,
                                enableSearchbox: true,
                                multiple: false,
                                enableScrollToSelected: true,
                                testId: 'locale-select'
                            }}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        boxShadow:
                                            "0.125rem 0.1875rem 0.3125rem rgba(79, 94, 116, 0.15)",
                                        border: "0.0625rem solid rgba(77, 77, 77, 0.2)",
                                        padding: "0.3125rem",
                                        overflow: "hidden",
                                    },
                                },
                                MenuListProps: {
                                    sx: {
                                        maxHeight: 315,
                                        paddingY: 0,
                                        "& .MuiPaper-root + div": {
                                            overflow: "scroll",
                                            maxHeight: "17.5rem",
                                        },
                                    },
                                },
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                sx: { transform: "translateY(0.3125rem)" },
                            }}
                            customStyles={{
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderColor:
                                        "rgba(77, 77, 77, 0.2)",
                                },
                                transform: "translateY(-0.3125rem)",

                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor:
                                        "rgb(40, 108, 210, .75)",
                                },
                            }}
                        />
                    </CoreFormStack>
                </CoreForm>
            </CoreFormProvider>
        </CoreFormThemeProvider>
    );
};
