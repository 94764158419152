import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledTextField = styled(TextField)(({ theme }) => ({
    "&.core-text-root .MuiInputBase-input.Mui-disabled": {
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    "& textarea.MuiInputBase-inputMultiline": {
        padding: "0 !important",
    },
    "& .MuiAutocomplete-inputRoot": {
        padding: "0 !important",
    },
}));
StyledTextField.defaultProps = {
    fullWidth: true,
    size: "small",
};
