import Prefixer from "inline-style-prefixer";
import Settings from "../../Settings";

export default function styles(props = {}) {
    let styles = {
        container: {
            width: "100%",
            background: "white",
            padding: "0 1.375rem 1.375rem 1.375rem",
        },
        flexMarginContainer: {
            display: "flex",
            flexWrap: "wrap",
            margin: "0 0 0 -3.25rem",
            width: "calc(100% + 3.25rem)",
        },
        flexMarginContainerNarrow: {
            display: "flex",
            flexWrap: "wrap",
            margin: "0 0 0 -1.125rem",
            width: "calc(100% + 1.125rem)",
        },
        flexMarginItem: {
            width: "calc(33.33% - 3.25rem)",
            marginTop: "1.5rem",
            marginLeft: "3.25rem",
        },
        flexMarginItemQuoter: {
            width: "calc(25% - 3.25rem)",
            marginTop: "1.5rem",
            marginLeft: "3.25rem",
        },
        flexMarginItemQuoterNarrow: {
            width: "calc(25% - 1.125rem)",
            marginTop: "1.5rem",
            marginLeft: "1.125rem",
        },
        flexMarginItemDouble: {
            width: "calc(50% - 3.25rem)",
            marginTop: "1.78125rem",
            marginLeft: "3.25rem",
        },
        marginItem: {
            marginTop: "1.5rem",
            width: "100%",
        },
        marginTopSmall: {
            marginTop: "0.8125rem",
            width: "100%",
        },
        marginItemXSmall: {
            marginTop: "0.625rem",
            width: "100%",
        },
        marginItemOneThird: {
            width: "calc(33.33% - 3.25rem)",
            marginTop: "1.5rem",
            marginLeft: "3.25rem",
        },
        marginItemTwoThirds: {
            width: "calc(66.66% - 3.25rem)",
            marginTop: "1.5rem",
            marginLeft: "3.25rem",
        },
        menu: {
            container: {
                width: "100%",
                position: "relative",
                display: "flex",
                justifyContent: "center",
            },
            line: {
                width: "100%",
                height: "0.065rem",
                background: "#E9E9E9",
                position: "absolute",
                zIndex: 0,
                top: "50%",
                left: 0,
            },
            ul: {
                padding: "0.125rem",
                margin: 0,
                listStyle: "none",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1,
                position: "relative",
                background: "#E9E9E9",
                borderRadius: "0.25rem",
            },
            li: {
                padding: "0.3125rem",
                color: "#AAAAAA",
                lineHeight: 1,
                fontSize: "0.75rem",
                cursor: "pointer",
                background: "#E9E9E9",
                transition: "0.3s",
                borderRadius: "0.25rem",
                margin: "0 0.25rem 0 0",
                textTransform: "capitalize",
            },
            liFirst: {},
            liLast: {
                margin: "0",
            },
            liHover: {
                color: "#6D6C6C",
                background: "white",
                boxShadow: "0px 0px 4px #D4D4D4",
            },
            liActive: {
                color: "#535353",
                background: "white",
                boxShadow: "0px 0px 4px #D4D4D4",
            },
            liError: {
                color: "#ff0000",
            },
            liDisabled: {
                color: "lightgrey",
            },
        },
        section: {
            displayNone: {
                display: "none",
            },
            displaySection: {
                display: 'block'
            },
            rightContent: {
                marginLeft: "auto",
                display: "flex",
                alignItems: "center",
            },
            dropdown: {
                marginLeft: "auto",
            },
            title: {
                color: "#000000",
                fontSize: "1.3rem",
                padding: 0,
                margin: 0,
            },
            subTitle: {
                padding: 0,
                margin: 0,
                color: "rgba(110, 110, 110, 0.87)",
                fontSize: "0.90625rem",
                fontWeight: 400,
            },
            default: {
                paddingTop: "1.375rem",
                paddingBottom: "1.375rem",
                borderBottom: "0.065rem solid #e5e5e5",
            },
            noBorder: {
                borderBottom: 0,
            },
            paddingTop: {
                paddingTop: "1.375rem",
            },
            first: {
                paddingTop: "1.375rem",
            },
            last: {
                paddingBottom: 0,
                borderBottom: 0,
            },
            joint: {
                paddingBottom: 0,
                borderBottom: 0,
            },
            flex: {
                display: "flex",
            },
            marginBottom: {
                margin: "0 0 1rem 0",
            },
            marginTop: {
                margin: "1rem 0 0",
            },
            content: {
                paddingTop: "1.03125rem",
            },
            keywords: {
                margin: "0.6875rem 0.625rem 0 0",
            },
            classification: {
                display: "flex",
                alignItems: "center",
                gap: "1rem",
            },
            inputGroup: {
                position: "relative",
            },
        },
        editIcon: {
            width: "0.8rem",
            marginLeft: "0.5rem",
            cursor: "pointer",
        },
        rating: {
            fontSize: "1.3rem",
            color: "rgba(39, 39, 39, 0.87)",
            padding: "1.125rem 0.6875rem 1.125rem 1.28125rem",
        },
        certificationType: {
            fontSize: "0.90625rem",
            fontWeight: 500,
            color: "rgba(78, 78, 78, 0.87)",
        },
        certificateBody: {
            fontSize: "0.90625rem",
            color: " rgba(78, 78, 78, 0.87)",
            fontWeight: 300,
        },
        images: {
            container: {
                display: "flex",
                flexWrap: "wrap",
                margin: "-0.875rem 0 0 -0.875rem",
            },
            recordImageContainer: {
                width: "calc(25% - 0.875rem)",
                minWidth: "15.09375rem",
                margin: "0.875rem 0 0 0.875rem",
                display: "flex",
                flexDirection: "column",
            },
            inline: {
                display: "flex",
                alignItems: "center",
            },
            inlineBottom: {
                display: "flex",
                alignItems: "flex-end",
            },
            title: {
                fontSize: "1.05rem",
                fontWeight: 500,
                lineHeight: 1.49,
                color: "#000000",
                marginRight: "1rem",
            },
            recordPosterContainer: {
                marginLeft: "auto",
                display: "flex",
                alignItems: "center",
            },
            recordPoster: {
                fontSize: "0.78125rem",
                color: "rgba(0, 0, 0, 0.87)",
                marginRight: "0.65625rem",
            },
        },
        langIso: {
            padding: "0.5625rem 1.8125rem",
            backgroundImage: `url('${Settings.images.path}/svg/globe-grey.svg'), url('${Settings.images.path}/svg/chevron-bottom-grey.svg')`,
            backgroundPosition:
                "left 0.65625rem top 51.5%, right 0.65625rem top 51.5%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "0.8125rem",
        },
        editImage: {
            width: "1.4375rem",
            marginLeft: "1rem",
            cursor: "pointer",
        },
        bubbleList: {
            marginTop: "0.6875rem",
        },
        footerContainer: {
            width: "100%",
            background: "white",
            padding: "2.90625rem 0 1.34375rem 0",
            display: "flex",
            alignItems: "flex-start",
        },
        footerLogo: {
            width: "3.03125rem",
        },
        footerRightContent: {
            marginLeft: "auto",
            paddingLeft: "1rem",
            textAlign: "right",
        },
        footerRightContentInline: {
            display: "flex",
            alignItems: "center",
        },
        footerMetaId: {
            fontSize: "0.90625rem",
            fontWeight: 300,
            color: "rgba(78, 78, 78, 0.87)",
            lineHeight: 1.95,
        },
        idLogo: {
            height: "1.5rem",
            marginBottom: "0.625rem",
            maxWidth: "9.625rem",
        },
    };

    let prefixer = new Prefixer();
    styles = prefixer.prefix(styles);

    return styles;
}
