import { debounce, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useStores } from "../../../../store/rootStore";
import { adaptToAutocompleteRecord } from "../adapters/adaptToAutocompleteRecord";
import { AutocompleteRecord } from "../components/AutocompleteRecordCard";
import { Categories } from "../types/types";

export const useFetchCategorySuggestions = (
    value: string,
    category: string = Categories.Show,
) => {
    const rootStore = useStores();
    const [suggestions, setSuggestions] = useState<AutocompleteRecord[]>([]);

    const DELAY = 500;
    useEffect(() => {
        const fetchSuggestions = async () => {
            // don't need to use try catch because rootStore.recordServiceApi.search already handles it
            const response = await rootStore.recordServiceApi.search(
                value,
                category,
            );
            const suggestionsAux: AutocompleteRecord[] =
                response.data.active_records.map(adaptToAutocompleteRecord);

            setSuggestions(suggestionsAux);
        };

        const debouncedFetchSuggestions = debounce(fetchSuggestions, DELAY);

        if (!isEmpty(value)) {
            debouncedFetchSuggestions();
        }

        return () => {
            debouncedFetchSuggestions.cancel(); // Cancel any pending debounced invocation
        };
    }, [value, category]);

    return {
        suggestions,
    };
};
