export const SET_BREAKPOINT = "SET_BREAKPOINT";
export const SET_UNSAVED_CHANGES = "SET_UNSAVED_CHANGES";
export const SET_NEW_RECORD = "SET_NEW_RECORD";
export const SET_ORIGINAL_NEW_RECORD = "SET_ORIGINAL_NEW_RECORD";
export const SET_ACTIVE_RECORD = "SET_ACTIVE_RECORD";
export const SET_SEARCH_DATA = "SET_SEARCH_DATA";
export const SET_RECORD_DATA = "SET_RECORD_DATA";
export const SET_SAVING_RECORD = "SET_SAVING_RECORD";
export const SET_ORIGINAL_RECORD = "SET_ORIGINAL_RECORD";
export const SET_RECORD_CHANGES = "SET_RECORD_CHANGES";
export const SET_NEW_RECORD_EDIT = "SET_NEW_RECORD_EDIT";
export const SET_HOVER_RECORD = "SET_HOVER_RECORD";
export const SET_BADRESPONSE = "SET_BADRESPONSE";
export const SET_ERROR = "SET_ERROR";
export const SET_URL_COMPONENTS = "SET_URL_COMPONENTS";
export const SET_BACKGROUND_PROCESS = "SET_BACKGROUND_PROCESS";
export const SET_EIDR_SEARCH = "SET_EIDR_SEARCH";
export const SET_INHERITANCE_FILTERS = "SET_INHERITANCE_FILTERS";
export const SET_INHERITANCE_DATA = "SET_INHERITANCE_DATA";
export const SET_CONVERTING_DATA = "SET_CONVERTING_DATA";
export const SET_RECORD_CHANGES_UUID = "SET_RECORD_CHANGES_UUID";
export const SET_INITIAL_STATE = "SET_INITIAL_STATE";
export const SET_RECORD_CHANGES_V2 = "SET_RECORD_CHANGES_V2";
