import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import { CoreTableCell } from "../../components/CoreTableCell";
import { CoreTableGridColDef } from "../../types";
import externalLinks from "../../../../config/external_links"

const RenderCell = (params: GridRenderCellParams<string>) => {
    const elem = useMemo(
        () => (
            <CoreTableCell
                enableCopyToClipboard
                linkProps={{
                    href: externalLinks.imdb_url_name.replace("{id}", params?.value),
                    target: "_blank",
                }}
                yellowChip
            >
                {params?.value}
            </CoreTableCell>
        ),
        [params?.value],
    );
    return elem;
};

export const ImdbIDCol: CoreTableGridColDef = {
    headerName: "Contributor IMDb",
    field: "imdbID",
    flex: 1,
    maxWidth: 160,
    minWidth: 160,
    renderCell: (params: GridRenderCellParams<string>) => {
        return <RenderCell {...params} />;
    }
};
