import React, { Component } from "react";
import styles from "./styles";

export default class Tag extends Component {
    handleOnclick() {
        const { href, handleOnClick } = this.props;

        if (typeof handleOnClick == "function") {
            return handleOnClick();
        }

        if (href) {
            window.open(href, "_blank");
        }
    }

    render() {
        const { 
            text, 
            active, 
            proposed, 
            disabled, 
            background, 
            href, 
            icon, 
            iconStyles = {} 
        } = this.props;

        const classStyles = styles(this.props);
        const propsStyles = this.props.styles;

        if (text || icon) {
            return (
                <span
                    style={Object.assign(
                        {},
                        classStyles.default,
                        active && classStyles.active,
                        proposed && classStyles.proposed,
                        disabled && classStyles.disabled,
                        background && classStyles.background,
                        href && classStyles.href,
                        icon && classStyles.iconContainer,
                        propsStyles && propsStyles,
                    )}
                    className={this.props.className || undefined}
                    onClick={this.handleOnclick.bind(this)}
                >
                    {icon &&
                        <img 
                            src={icon} 
                            style={{
                                ...classStyles.icon,
                                ...iconStyles
                            }} 
                        />
                    }

                    {text}
                </span>
            );
        }

        return false;
    }
}
