import React from "react";
import PropTypes from "prop-types";
import styles from "../styles";
import $ from "jquery";
import { ReactSVG } from "react-svg";
import _ from "lodash";

export default class Button extends React.Component {
    constructor(props) {
        super(props);

        /**
         * @var object
         */
        this.state = {
            hover: false,
            loading: this.props.loading || false,
            leftIcon: {},
            rightIcon: {},
        };

        /**
         * @var string
         */
        this.tag = "button";

        /**
         * @var object|bool
         */
        this.image = false;

        /**
         * @var bool
         */
        this.componentIsMounted = false;

        this.renderIcon = process.env.NODE_ENV !== "test";
    }

    componentWillMount() {
        this.componentIsMounted = true;

        this.setTagFromProps();
        this.loadImage();
        this.loadIcon("leftIcon", this.props.leftIcon);
        this.loadIcon("rightIcon", this.props.rightIcon);
        this.setStyles();
    }

    componentDidMount() {
        this.setLeftIconColor();
    }

    componentWillUpdate(nextProps) {
        this.setLoading(nextProps.loading);

        if (nextProps.leftIcon !== this.props.leftIcon) {
            this.loadIcon("leftIcon", nextProps.leftIcon);
        }
    }

    componentWillUnmount() {
        this.componentIsMounted = false;
    }

    setStyles() {
        this.styles = styles(this.props);
    }

    setLeftIconColor(props = {}) {
        const { hover } = props;

        const { leftIconColors = {}, leftIconClassName } = this.props;

        const item = $(`.${leftIconClassName}`);

        if (
            item &&
            leftIconColors &&
            leftIconColors.default &&
            leftIconColors.hover
        ) {
            $(`.${leftIconClassName}`).css(
                {
                    fill: hover ? leftIconColors.hover : leftIconColors.default,
                },
                100,
            );
        }
    }

    getHoverColor(props = {}) {
        const { hover } = props;

        const { leftIconColors = {} } = this.props;

        return hover ? leftIconColors.hover : leftIconColors.default;
    }

    setTagFromProps() {
        if (this.props.tag) {
            this.tag = this.props.tag;
        }
    }

    setLoading(loading) {
        if (loading === true || loading === false) {
            if (this.state.loading !== loading) {
                this.setState({
                    loading,
                });
            }
        }
    }

    setImage(image) {
        if (image) {
            this.image = image;
        }
    }

    loadIcon(stateKey, icon) {
        if (icon) {
            let image = new Image();

            if (image) {
                image.src = icon;

                image.onload = () => {
                    if (this.componentIsMounted) {
                        this.setState({
                            [stateKey]: image,
                        });
                    }
                };
            }
        }
    }

    loadImage() {
        if (this.props.loadingSrc) {
            let image = new Image();
            image.src = this.props.loadingSrc;

            image.onload = () => {
                this.setImage(image);
            };
        }
    }

    handleHover(hover) {
        if (!this.props.disabled || this.props.disabled == undefined) {
            this.setState({
                hover,
                hoverColor: this.getHoverColor({
                    hover,
                }),
            });

            if (typeof this.props.handleHover == "function") {
                this.props.handleHover(hover);
            }
        }
    }

    handleOnClick(e) {
        if (this.props.disabled) {
            e.preventDefault();
            return;
        }

        if (typeof this.props.onClick === "function") {
            e.preventDefault();
            this.props.onClick(e);
        }
    }

    getStyles() {
        const {
            red,
            grey,
            amber,
            blue,
            orange,
            increaseOnHover,
            disabled,
            disabledOpacity,
            themeOverride,
            customButtonTheme,
            primary,
            secondary,
            warning,
            buttonEdit,
            buttonDelete,
            buttonCancel,
            actionButtonDelete,
            actionButtonPrimary,
            enrichmentButton,
            fluid,
            buttonText,
            buttonWelcome,
            small,
            recordAction,
            imagesButtonPrimary,
            imagesButtonDelete,
            marginLeftTop,
            buttonSection,
            tableControl,
            error,
            margin
        } = this.props;

        const theme = process.THEME || {};

        let buttonTheme = {};
        if (!themeOverride) {
            buttonTheme = customButtonTheme || theme.button || {};
        }

        let disabledStyles = {
            ...this.styles.disabled,
        };

        let defaultStyles = {
            ...this.styles.default,
        };

        let hoverStyles = {
            ...this.styles.hover,
        };

        if (buttonTheme.default) {
            defaultStyles = {
                ...defaultStyles,
                ...buttonTheme.default,
            };
        }

        if (buttonTheme.defaultHover) {
            hoverStyles = {
                ...hoverStyles,
                ...buttonTheme.defaultHover,
            };
        }

        if (buttonTheme.disabled) {
            disabledStyles = {
                ...disabledStyles,
                ...buttonTheme.disabled,
            };
        }

        return Object.assign(
            {},
            defaultStyles,
            red && this.styles.red,
            grey && this.styles.grey,
            amber && this.styles.amber,
            blue && this.styles.blue,
            orange && this.styles.orange,
            enrichmentButton && this.styles.enrichmentButton,
            fluid && this.styles.fluid,
            primary && this.styles.primary,
            secondary && this.styles.secondary,
            warning && this.styles.warning,
            buttonEdit && this.styles.buttonEdit,
            buttonDelete && this.styles.buttonDelete,
            buttonCancel && this.styles.buttonCancel,
            actionButtonDelete && this.styles.actionButtonDelete,
            actionButtonPrimary && this.styles.actionButtonPrimary,
            buttonText && this.styles.buttonText,
            buttonWelcome && this.styles.buttonWelcome,
            small && this.styles.small,
            recordAction && this.styles.recordAction,
            marginLeftTop && this.styles.marginLeftTop,
            tableControl && this.styles.tableControl,
            imagesButtonPrimary && this.styles.imagesButtonPrimary,
            imagesButtonDelete && this.styles.imagesButtonDelete,
            buttonSection && this.styles.buttonSection,
            error && this.styles.error,
            this.state.hover && hoverStyles,
            this.state.hover && increaseOnHover && this.styles.increaseOnHover,
            this.state.hover && red && this.styles.hoverRed,
            this.state.hover && grey && this.styles.hoverGrey,
            this.state.hover && amber && this.styles.hoverAmber,
            this.state.hover && blue && this.styles.hoverBlue,
            this.state.hover && orange && this.styles.hoverOrange,
            this.state.hover &&
                enrichmentButton &&
                this.styles.hoverEnrichmentButton,
            this.state.hover && fluid && this.styles.hoverFluid,
            this.state.hover && primary && this.styles.hoverPrimary,
            this.state.hover && secondary && this.styles.hoverSecondary,
            this.state.hover && warning && this.styles.hoverWarning,
            this.state.hover && buttonEdit && this.styles.hoverButtonEdit,
            this.state.hover && buttonDelete && this.styles.hoverButtonDelete,
            this.state.hover && buttonCancel && this.styles.hoverButtonCancel,
            this.state.hover &&
                actionButtonDelete &&
                this.styles.hoverActionButtonDelete,
            this.state.hover &&
                actionButtonPrimary &&
                this.styles.hoverActionButtonPrimary,
            this.state.hover && buttonText && this.styles.hoverButtonText,
            this.state.hover && small && this.styles.hoverSmall,
            this.state.hover && recordAction && this.styles.hoverRecordAction,
            this.state.hover && tableControl && this.styles.hoverTableControl,
            this.state.hover &&
                imagesButtonPrimary &&
                this.styles.hoverImagesButtonPrimary,
            this.state.hover &&
                imagesButtonDelete &&
                this.styles.hoverImagesButtonDelete,
            this.state.hover && buttonSection && this.styles.hoverButtonSection,
            this.state.hover && error && this.styles.hoverError,
            disabled && disabledStyles,
            disabled && disabledOpacity && this.styles.disabledOpacity,
            this.props.styles && this.props.styles,
        );
    }

    renderContent() {
        const { hover } = this.state;

        const {
            leftIconAlt,
            leftIconStyles = {},
            leftIconClassName,
            leftIconHover,
            leftIcon,
        } = this.props;

        return (
            <div style={this.styles.content}>
                {leftIcon && (
                    <img
                        src={hover && leftIconHover ? leftIconHover : leftIcon}
                        className={leftIconClassName}
                        alt={leftIconAlt}
                        style={Object.assign(
                            {},
                            this.styles.leftIcon,
                            leftIconStyles && leftIconStyles,
                        )}
                    />
                )}

                <span
                    style={Object.assign(
                        {},
                        this.styles.text,
                        this.state.loading &&
                            this.props.loadingSrc &&
                            this.styles.textTransparent,
                    )}
                >
                    {this.props.value}
                </span>

                {this.state.loading && this.props.loadingSrc && (
                    <img
                        src={this.image.src || this.props.loadingSrc}
                        alt="loading"
                        style={this.styles.loading}
                    />
                )}

                {this.props.rightIcon && this.renderIcon && (
                    <img
                        src={this.state.rightIcon.src}
                        alt={this.props.rightIconAlt}
                        className={this.props.rightIconClassName || ""}
                        style={Object.assign(
                            {},
                            this.styles.rightIcon,
                            this.props.rightIconStyles &&
                                this.props.rightIconStyles,
                        )}
                    />
                )}
            </div>
        );
    }

    render() {
        const { value, href, type, id, className } = this.props;

        const theme = process.THEME || {};
        const buttonTheme = theme.button || {};

        let disabledStyles = {
            ...this.styles.disabled,
        };

        let defaultStyles = {
            ...this.styles.default,
        };

        let hoverStyles = {
            ...this.styles.hover,
        };

        if (buttonTheme.default) {
            defaultStyles = {
                ...defaultStyles,
                ...buttonTheme.default,
            };
        }

        if (buttonTheme.defaultHover) {
            hoverStyles = {
                ...hoverStyles,
                ...buttonTheme.defaultHover,
            };
        }

        if (buttonTheme.disabled) {
            disabledStyles = {
                ...disabledStyles,
                ...buttonTheme.disabled,
            };
        }

        if (value) {
            return (
                <this.tag
                    onMouseEnter={this.handleHover.bind(this, true)}
                    onMouseLeave={this.handleHover.bind(this, false)}
                    onClick={this.handleOnClick.bind(this)}
                    href={href || undefined}
                    type={type || undefined}
                    id={id}
                    data-testid={id}
                    className={className || undefined}
                    style={this.getStyles()}
                    disabled={this.props.disabled}
                >
                    {this.renderContent()}
                </this.tag>
            );
        }

        return false;
    }
}

Button.propTypes = {
    tag: PropTypes.oneOf(["button", "a"]),
    type: PropTypes.oneOf(["submit", "button"]),
    href: PropTypes.string,
    className: PropTypes.string,
    id: PropTypes.string,
};
