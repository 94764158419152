export const AssetPlatformConfigurations = [
    {
        id: "1",
        displayName: "Vidispine",
        key: "vidispine",
        order: 1,
        archived: false,
    },
    {
        id: "2",
        displayName: "Not Vidispine",
        key: "notvidi",
        order: 2,
        archived: false,
    },
];

export const AssetCodeConfigurations = [
    {
        id: "1",
        displayName: "MVP",
        key: "mvp",
        order: 1,
        archived: false,
    },
];

export const AssetTypeConfigurations = [
    {
        id: "1",
        code: "rendition",
        destinationPlatform: "vidispine",
        displayName: "VDSPN",
        order: 1,
        archived: false,
        options: {
            alternativeKeys: [],
            canBeRoot: {
                enabled: true,
                __typename: "BasicFeatureFlag",
            },
            childAssetTypes: [],
            __typename: "AssetTypeOptions",
        },
        sections: [
            {
                key: "asset_information",
                displayName: "Asset Information",
                order: 1,
                fields: [
                    {
                        key: "meta_id",
                        displayName: "Fabric Asset ID",
                        order: 1,
                        dataType: "String",
                        options: {
                            editable: {
                                inputType: "String",
                                enabled: true,
                                __typename: "BasicFeatureFlag",
                            },
                            unique: {
                                enabled: true,
                                __typename: "BasicFeatureFlag",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                __typename: "DefaultOption",
                            },
                            __typename: "AssetFieldOptions",
                        },
                        __typename: "AssetField",
                    },
                    {
                        key: "source_type",
                        displayName: "Source Type",
                        order: 1,
                        dataType: "String",
                        options: {
                            editable: {
                                enabled: true,
                                __typename: "BasicFeatureFlag",
                            },
                            unique: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                __typename: "DefaultOption",
                            },
                            __typename: "AssetFieldOptions",
                        },
                        __typename: "AssetField",
                    },
                    {
                        key: "system_name",
                        displayName: "System Name",
                        order: 2,
                        dataType: "String",
                        options: {
                            editable: {
                                enabled: true,
                                __typename: "BasicFeatureFlag",
                            },
                            unique: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                __typename: "DefaultOption",
                            },
                            __typename: "AssetFieldOptions",
                        },
                        __typename: "AssetField",
                    },
                    {
                        key: "system_type",
                        displayName: "System Type",
                        order: 3,
                        dataType: "String",
                        options: {
                            editable: {
                                enabled: true,
                                __typename: "BasicFeatureFlag",
                            },
                            unique: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                __typename: "DefaultOption",
                            },
                            __typename: "AssetFieldOptions",
                        },
                        __typename: "AssetField",
                    },
                ],
                __typename: "AssetSection",
            },
            {
                key: "asset_payload",
                displayName: "Asset Payload",
                order: 1,
                fields: [
                    {
                        key: "vidispine_id",
                        displayName: "Vidispine ID",
                        order: 3,
                        dataType: "String",
                        options: {
                            editable: {
                                enabled: true,
                                __typename: "BasicFeatureFlag",
                            },
                            unique: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                __typename: "DefaultOption",
                            },
                            __typename: "AssetFieldOptions",
                        },
                        __typename: "AssetField",
                    },
                ],
                __typename: "AssetSection",
            },
            {
                key: "subtitle_tracks",
                order: 1,
                fields: [],
                displayName: "Subtitle",
                archived: false,
                options: {
                    hidden: { enabled: false },
                    dataPath: { path: "subtitle_tracks" },
                },
                sections: [
                    {
                        key: "subtitle_metadata",
                        order: 1,
                        archived: false,
                        fields: [
                            {
                                key: "filename",
                                order: 1,
                                options: {
                                    hidden: { enabled: false },
                                    unique: { enabled: false },
                                    default: { enabled: false },
                                    editable: { enabled: false },
                                },
                                dataType: "String",
                                displayName: "Filename",
                            },
                            {
                                key: "format",
                                order: 2,
                                options: {
                                    hidden: { enabled: false },
                                    unique: { enabled: false },
                                    default: { enabled: false },
                                    editable: { enabled: false },
                                },
                                dataType: "String",
                                displayName: "Format",
                            },
                            {
                                key: "language",
                                order: 3,
                                options: {
                                    hidden: { enabled: false },
                                    unique: { enabled: false },
                                    default: { enabled: false },
                                    editable: { enabled: false },
                                },
                                dataType: "String",
                                displayName: "Language",
                            },
                            {
                                key: "standart",
                                order: 4,
                                options: {
                                    hidden: { enabled: false },
                                    unique: { enabled: false },
                                    default: { enabled: false },
                                    editable: { enabled: false },
                                },
                                dataType: "String",
                                displayName: "Standart",
                            },
                            {
                                key: "status",
                                order: 5,
                                options: {
                                    hidden: { enabled: false },
                                    unique: { enabled: false },
                                    default: { enabled: false },
                                    editable: { enabled: false },
                                },
                                dataType: "String",
                                displayName: "Status",
                            },
                        ],
                        displayName: "Subtitle Metadata",
                    },
                ],
            },
            {
                key: "audio_tracks",
                order: 1,
                fields: [],
                displayName: "Dub",
                archived: false,
                options: {
                    hidden: { enabled: false },
                    dataPath: { path: "alternate_language_audio_tracks" },
                },
                sections: [
                    {
                        key: "audio_metadata",
                        order: 1,
                        archived: false,
                        fields: [
                            {
                                key: "filename",
                                order: 1,
                                options: {
                                    hidden: { enabled: false },
                                    unique: { enabled: false },
                                    default: { enabled: false },
                                    editable: { enabled: false },
                                },
                                dataType: "String",
                                displayName: "Filename",
                            },
                            {
                                key: "format",
                                order: 2,
                                options: {
                                    hidden: { enabled: false },
                                    unique: { enabled: false },
                                    default: { enabled: false },
                                    editable: { enabled: false },
                                },
                                dataType: "String",
                                displayName: "Format",
                            },
                            {
                                key: "language",
                                order: 3,
                                options: {
                                    hidden: { enabled: false },
                                    unique: { enabled: false },
                                    default: { enabled: false },
                                    editable: { enabled: false },
                                },
                                dataType: "String",
                                displayName: "Language",
                            },
                            {
                                key: "status",
                                order: 5,
                                options: {
                                    hidden: { enabled: false },
                                    unique: { enabled: false },
                                    default: { enabled: false },
                                    editable: { enabled: false },
                                },
                                dataType: "String",
                                displayName: "Status",
                            },
                            {
                                key: "bit_depth",
                                order: 1,
                                options: {
                                    hidden: { enabled: false },
                                    unique: { enabled: false },
                                    default: { enabled: false },
                                    editable: { enabled: false },
                                },
                                dataType: "String",
                                displayName: "Bit Depth",
                            },
                            {
                                key: "sample_rate",
                                order: 1,
                                options: {
                                    hidden: { enabled: false },
                                    unique: { enabled: false },
                                    default: { enabled: false },
                                    editable: { enabled: false },
                                },
                                dataType: "String",
                                displayName: "Sample Rate",
                            },
                        ],
                        displayName: "Audio Metadata",
                    },
                ],
            },
        ],
    },
    {
        id: "2",
        code: "edit",
        destinationPlatform: "notvidi",
        displayName: "NTVD",
        order: 2,
        archived: false,
        options: {
            alternativeKeys: [],
            canBeRoot: {
                enabled: true,
                __typename: "BasicFeatureFlag",
            },
            childAssetTypes: [],
            __typename: "AssetTypeOptions",
        },
        sections: [
            {
                key: "asset_information",
                displayName: "Asset Information",
                order: 1,
                fields: [
                    {
                        key: "meta_id",
                        displayName: "Fabric Asset ID",
                        order: 1,
                        dataType: "String",
                        options: {
                            editable: {
                                inputType: "String",
                                enabled: true,
                                __typename: "BasicFeatureFlag",
                            },
                            unique: {
                                enabled: true,
                                __typename: "BasicFeatureFlag",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                __typename: "DefaultOption",
                            },
                            __typename: "AssetFieldOptions",
                        },
                        __typename: "AssetField",
                    },
                    {
                        key: "source_type",
                        displayName: "Source Type",
                        order: 1,
                        dataType: "String",
                        options: {
                            editable: {
                                enabled: true,
                                __typename: "BasicFeatureFlag",
                            },
                            unique: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                __typename: "DefaultOption",
                            },
                            __typename: "AssetFieldOptions",
                        },
                        __typename: "AssetField",
                    },
                    {
                        key: "system_name",
                        displayName: "System Name",
                        order: 2,
                        dataType: "String",
                        options: {
                            editable: {
                                enabled: true,
                                __typename: "BasicFeatureFlag",
                            },
                            unique: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                __typename: "DefaultOption",
                            },
                            __typename: "AssetFieldOptions",
                        },
                        __typename: "AssetField",
                    },
                    {
                        key: "system_type",
                        displayName: "System Type",
                        order: 3,
                        dataType: "String",
                        options: {
                            editable: {
                                enabled: true,
                                __typename: "BasicFeatureFlag",
                            },
                            unique: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                __typename: "DefaultOption",
                            },
                            __typename: "AssetFieldOptions",
                        },
                        __typename: "AssetField",
                    },
                ],
                __typename: "AssetSection",
            },
            {
                key: "asset_payload",
                displayName: "Asset Payload",
                order: 1,
                fields: [
                    {
                        key: "vidispine_id",
                        displayName: "Vidispine ID",
                        order: 3,
                        dataType: "String",
                        options: {
                            editable: {
                                enabled: true,
                                __typename: "BasicFeatureFlag",
                            },
                            unique: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                __typename: "DefaultOption",
                            },
                            __typename: "AssetFieldOptions",
                        },
                        __typename: "AssetField",
                    },
                ],
                __typename: "AssetSection",
            },
            {
                key: "subtitle_tracks",
                order: 1,
                fields: [],
                displayName: "Subtitle",
                archived: false,
                options: {
                    hidden: { enabled: false },
                    dataPath: { path: "subtitle_tracks" },
                },
                sections: [
                    {
                        key: "subtitle_metadata",
                        order: 1,
                        archived: false,
                        fields: [
                            {
                                key: "filename",
                                order: 1,
                                options: {
                                    hidden: { enabled: false },
                                    unique: { enabled: false },
                                    default: { enabled: false },
                                    editable: { enabled: false },
                                },
                                dataType: "String",
                                displayName: "Filename",
                            },
                            {
                                key: "format",
                                order: 2,
                                options: {
                                    hidden: { enabled: false },
                                    unique: { enabled: false },
                                    default: { enabled: false },
                                    editable: { enabled: false },
                                },
                                dataType: "String",
                                displayName: "Format",
                            },
                            {
                                key: "language",
                                order: 3,
                                options: {
                                    hidden: { enabled: false },
                                    unique: { enabled: false },
                                    default: { enabled: false },
                                    editable: { enabled: false },
                                },
                                dataType: "String",
                                displayName: "Language",
                            },
                            {
                                key: "standart",
                                order: 4,
                                options: {
                                    hidden: { enabled: false },
                                    unique: { enabled: false },
                                    default: { enabled: false },
                                    editable: { enabled: false },
                                },
                                dataType: "String",
                                displayName: "Standart",
                            },
                            {
                                key: "status",
                                order: 5,
                                options: {
                                    hidden: { enabled: false },
                                    unique: { enabled: false },
                                    default: { enabled: false },
                                    editable: { enabled: false },
                                },
                                dataType: "String",
                                displayName: "Status",
                            },
                        ],
                        displayName: "Subtitle Metadata",
                    },
                ],
            },
            {
                key: "audio_tracks",
                order: 1,
                fields: [],
                displayName: "Dub",
                archived: false,
                options: {
                    hidden: { enabled: false },
                    dataPath: { path: "alternate_language_audio_tracks" },
                },
                sections: [
                    {
                        key: "audio_metadata",
                        order: 1,
                        archived: false,
                        fields: [
                            {
                                key: "filename",
                                order: 1,
                                options: {
                                    hidden: { enabled: false },
                                    unique: { enabled: false },
                                    default: { enabled: false },
                                    editable: { enabled: false },
                                },
                                dataType: "String",
                                displayName: "Filename",
                            },
                            {
                                key: "format",
                                order: 2,
                                options: {
                                    hidden: { enabled: false },
                                    unique: { enabled: false },
                                    default: { enabled: false },
                                    editable: { enabled: false },
                                },
                                dataType: "String",
                                displayName: "Format",
                            },
                            {
                                key: "language",
                                order: 3,
                                options: {
                                    hidden: { enabled: false },
                                    unique: { enabled: false },
                                    default: { enabled: false },
                                    editable: { enabled: false },
                                },
                                dataType: "String",
                                displayName: "Language",
                            },
                            {
                                key: "status",
                                order: 5,
                                options: {
                                    hidden: { enabled: false },
                                    unique: { enabled: false },
                                    default: { enabled: false },
                                    editable: { enabled: false },
                                },
                                dataType: "String",
                                displayName: "Status",
                            },
                            {
                                key: "bit_depth",
                                order: 1,
                                options: {
                                    hidden: { enabled: false },
                                    unique: { enabled: false },
                                    default: { enabled: false },
                                    editable: { enabled: false },
                                },
                                dataType: "String",
                                displayName: "Bit Depth",
                            },
                            {
                                key: "sample_rate",
                                order: 1,
                                options: {
                                    hidden: { enabled: false },
                                    unique: { enabled: false },
                                    default: { enabled: false },
                                    editable: { enabled: false },
                                },
                                dataType: "String",
                                displayName: "Sample Rate",
                            },
                        ],
                        displayName: "Audio Metadata",
                    },
                ],
            },
        ],
    },
];
