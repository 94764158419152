import React from "react";
import _ from "lodash";
import Index from "./Index";
import "../styles/_collectionTitles.scss";
import CategoryTags from "../../CategoryTags";
import AddTitleToCollection from "../../AddTitleToCollection";
import Notification from "../../Notification";
import DownloadFileWithFetch from "../../File/DownloadFileWithFetch";
import DoubleGridList from "../../CheckboxTag/DoubleGridList";
import deepcopy from "deepcopy";
import { arrayMove } from "react-sortable-hoc";
import isIndexInArray from "../../Helpers/isIndexInArray";
import { isActiveIoTSubscription } from "../../IoT";
import MaterialTable from "../../Table/Material/Grid";
import RenderTitle from "../../RecordTable/src/RenderTitle";
import RenderCategoryTag from "../../RecordTable/src/RenderCategoryTag";
import RenderShowTags from "../../RecordTable/src/RenderShowTags";
import RenderMetaId from "../../RecordTable/src/RenderMetaId";
import Button from "../../Button";
import { GRID_REORDER_COL_DEF } from "@mui/x-data-grid-pro";
import { StyledTableHeaderColumn } from "../../Table/Material/styles";
import { v4 as uuidv4 } from "uuid";
import FadeWithSkeleton from "../../SkeletonLoader/FadeWithSkeleton";
import RenderType from "../../RecordTable/src/RenderType";
import RenderEpisodeNumbertag from "../../RecordTable/src/RenderEpisodeNumbertag";
import RenderSeasonTags from "../../RecordTable/src/RenderSeasonTags";

export default class CollectionTitles extends Index {
    constructor(props) {
        super(props);

        /**
         * @var object
         */
        this.copy = this.props.copy || {};

        this.state = {
            notification: {},
            fetchingCollections: false,
            selectedRecords: [],
        };
    }

    componentDidMount() {
        this.fetchCollections();
    }

    componentDidUpdate(prevProps) {
        if (
            !_.isEqual(prevProps.data, this.props.data) &&
            _.isEmpty(this.props.data) &&
            !this.state.fetchingCollections
        ) {
            this.fetchCollections();
        }
    }

    getData() {
        const { data = [] } = this.props;

        return data;
    }

    getActiveData() {
        if (_.isEmpty(this.getData())) {
            return [];
        }

        return this.getData()
            .filter((item) => !item.deleted)
            .sort((a, b) => {
                return a.order - b.order;
            })
            .map((record, index) => {
                record.category = record.category || record.category_name;
                if (!record.uuid) {
                    record.uuid = uuidv4();
                }
                return record;
            });
    }

    getClientFeatures() {
        const { cmsData = {} } = this.props;

        return cmsData.clientFeatures || {};
    }

    getApiUrls() {
        const { cmsData = {} } = this.props;

        return cmsData.apiUrls || {};
    }

    getNotificationWithErrorMessage(error) {
        const { notification = {} } = this.state;

        let updatedState = deepcopy(notification);
        updatedState.errorMessage = error;

        return updatedState;
    }

    checkIoTConnection() {
        const { state } = this.props;

        const { activeRecord, IoTConnection = {} } = state;

        return isActiveIoTSubscription({
            IoTConnection,
            item: activeRecord,
        });
    }

    fetchCollections() {
        const { state } = this.props;

        const {
            recordData = {},
            handleFetchLinkagesRequest = () => {},
            set,
        } = state;

        const { meta_id } = recordData;

        if (this.checkIoTConnection()) {
            return;
        }

        if (meta_id && process.env.NODE_ENV != "test") {
            if (!this.checkIoTConnection()) {
                this.setState({ fetchingCollections: true });
            }

            handleFetchLinkagesRequest({
                meta_id,
                handleError: () =>
                    this.setState({ fetchingCollections: false }),
                handleSuccess: (data) => {
                    this.setState({
                        fetchingCollections: false,
                    });

                    let updatedState = { ...recordData };
                    updatedState.linkage = data;

                    set({
                        recordData: updatedState,
                    });
                },
            });
        }
    }

    handleAddNewTitle() {
        const { disabled, state = {} } = this.props;

        const { handleSetCollectionTitle, handleCollectionTitlesAdd } = state;

        if (!disabled) {
            this.setState({
                notification: {
                    title: this.copy.addToCollection || "Add to Collection",
                    intercationStatus: "info",
                    background: "white",
                    closeOnWrapperClick: false,
                    disabled: true,
                    okText: this.copy.addPlusClose || "Add + Close",
                    addTitle: true,
                    confirm: () => this.handleConfirm(),
                    onClick: () => {
                        this.setState(
                            {
                                notification: {},
                            },
                            () => {
                                handleSetCollectionTitle({});
                            },
                        );
                    },
                    customMiddleButton: {
                        text: "Add + Reload Window",
                        disabled: disabled,
                        onClick: () => {
                            handleCollectionTitlesAdd();
                            handleSetCollectionTitle({});
                        },
                    },
                },
            });
        }
    }

    handleConfirm() {
        const { state = {} } = this.props;

        const { handleCollectionTitlesAdd, handleSetCollectionTitle } = state;

        handleCollectionTitlesAdd();
        handleSetCollectionTitle({});

        this.setState({
            notification: {},
        });
    }

    shouldNotificationBeDisabled() {
        const { state = {} } = this.props;

        const { collectionTitle = {} } = state;

        const {
            category,
            selectedSeason,
            selectedTitle,
            selectedTitles,
            selectedEpisodes,
            selectedShowSpecials,
        } = collectionTitle;

        let result = true; // Should be disabled

        const MultipleTitlesValidation = () => {
            let result = true;

            if (!_.isEmpty(category) && !_.isEmpty(selectedTitles)) {
                result = false;
            }

            return result;
        };

        const SingleTitleValidation = () => {
            let result = true;

            if (
                !_.isEmpty(category) &&
                !_.isEmpty(selectedTitle) &&
                !_.isEmpty(selectedSeason)
            ) {
                result = false;
            }

            return result;
        };

        const SingleTitleEpisodeValidation = () => {
            let result = true;

            if (
                !_.isEmpty(category) &&
                !_.isEmpty(selectedTitle) &&
                !_.isEmpty(selectedSeason) &&
                !_.isEmpty(selectedEpisodes)
            ) {
                result = false;
            }

            return result;
        };

        const SingleTitleSpecialValidation = () => {
            let result = true;

            if (!_.isEmpty(category) && !_.isEmpty(selectedTitle)) {
                if (
                    !_.isEmpty(selectedShowSpecials) ||
                    !_.isEmpty(selectedEpisodes)
                )
                    result = false;
            }

            return result;
        };

        switch (category) {
            case CategoryTags.Season.short:
                result = SingleTitleValidation();
                break;

            case CategoryTags.Episode.short:
                result = SingleTitleEpisodeValidation();
                break;

            case CategoryTags.ConstructedEpisode.short:
                result = SingleTitleEpisodeValidation();
                break;

            case CategoryTags.Storyline.short:
                result = SingleTitleEpisodeValidation();
                break;

            case CategoryTags.Special.short:
                if (!_.isEmpty(selectedTitles)) {
                    result = MultipleTitlesValidation();
                } else {
                    result = SingleTitleSpecialValidation();
                }

                break;

            default:
                result = MultipleTitlesValidation();
        }

        return result;
    }

    handleExportDownload() {
        const { selectedExportId = [], notification } = this.state;

        const { options = [] } = notification;

        let option = {};
        Object.keys(options).forEach((key) => {
            let result = options[key].find(
                (item) => item.value == selectedExportId,
            );

            if (result) {
                option = result;
                return;
            }
        });

        if (!option) {
            this.setState({
                notification: this.getNotificationWithErrorMessage(
                    "There was a problem finding the file to download. Please try again",
                ),
            });

            return;
        }

        this.setState(
            {
                downloading: true,
            },
            () => {
                this.handleStartDownload(option);
            },
        );
    }

    handleStartDownload(option) {
        const { url, method, value } = option;

        DownloadFileWithFetch({
            url,
            method,
            filename: value,
            handleSuccess: () => {
                this.setState({
                    downloading: false,
                    selectedExportId: [],
                });
            },
            handleError: () => {
                this.setState({
                    notification: this.getNotificationWithErrorMessage(
                        "There was a problem finding the file to download. Please try again",
                    ),
                    downloading: false,
                });
            },
        });
    }

    handleSorting(item) {
        const { oldIndex, newIndex } = item;

        const { state = {}, data = [] } = this.props;

        const { handleChange } = state;

        let updatedState = deepcopy(
            arrayMove(this.getActiveData(), oldIndex, newIndex),
        );
        let order = 0;

        updatedState = updatedState.map((item) => {
            return {
                ...item,
                order: order++,
            };
        });

        handleChange("linkage", updatedState);
    }

    handleRecordSelect(indexes) {
        this.setState({
            selectedRecords: this.getActiveData().filter((record, index) =>
                indexes.includes(record.uuid),
            ),
        });
    }

    handleDeleteSelectedRecords() {
        const { state = {} } = this.props;

        const { handleDeleteCollectionTitles = () => {} } = state;

        const { selectedRecords } = this.state;

        handleDeleteCollectionTitles(
            selectedRecords.map((record) => record.meta_id),
        );

        return;
    }

    renderAddTitleToCollection() {
        const { state = {} } = this.props;

        const {
            handleCollectionTitleCategoryChange,
            handleCollectionTitleQueryChange,
            handleCollectionTitleQueryListSelection,
            handleCollectionTitleSeasonSelect,
            handleCollectionTitleSelectAll,
            handleCollectionTitleSelection,
            handleCollectionTitleSelectAllShowSpecials,
            handleCollectionTitleSelectionShowSpecial,
            handleSelectedTitlesChange,
        } = state;

        return (
            <AddTitleToCollection
                state={state}
                handleCategoryChange={handleCollectionTitleCategoryChange}
                handleQueryChange={handleCollectionTitleQueryChange}
                handleQueryListSelection={
                    handleCollectionTitleQueryListSelection
                }
                handleCollectionTitleSeasonSelect={
                    handleCollectionTitleSeasonSelect
                }
                handleCollectionTitleSelectAll={handleCollectionTitleSelectAll}
                handleCollectionTitleSelection={handleCollectionTitleSelection}
                handleCollectionTitleSelectAllShowSpecials={
                    handleCollectionTitleSelectAllShowSpecials
                }
                handleCollectionTitleSelectionShowSpecial={
                    handleCollectionTitleSelectionShowSpecial
                }
                handleSelectedTitlesChange={handleSelectedTitlesChange}
            />
        );
    }

    renderButtonsBar() {
        const {
            disabled,
            meta_id,
            canViewFinancials,
            state = {},
            data = [],
            canCreateNew = true,
        } = this.props;

        const { recordData = {} } = state;

        const { financials, exports = {} } = this.getClientFeatures();

        const {
            allCollectionsExport,
            exportButtonMetadata,
            exportButtonFinancials,
        } = exports;

        const {
            financialsCSVExport = "",
            metadataCSVExport = "",
            metadataTSVExport = "",
        } = this.getApiUrls();

        const allowedCategories = [
            CategoryTags.Report.short,
            CategoryTags.Collection.short,
            CategoryTags.ConnectCollection.short,
        ];

        let categoryAllowedExport = true;
        if (!allCollectionsExport) {
            if (!isIndexInArray(allowedCategories, recordData.category)) {
                categoryAllowedExport = false;
            }
        }

        let options = {};

        if (exportButtonMetadata) {
            options.metadata = [];

            options.metadata.push({
                text: "Export Metadata - CSV",
                url: metadataCSVExport.replace("{collection_meta_id}", meta_id),
                method: "GET",
                value: "metadata-export.csv",
            });

            options.metadata.push({
                text: "Export Metadata - TSV",
                url: metadataTSVExport.replace("{collection_meta_id}", meta_id),
                method: "GET",
                value: "metadata-export.tsv",
            });
        }

        if (financials && exportButtonFinancials && canViewFinancials) {
            options.financial = [];
            options.financial.push({
                text: "Export Financial Data - CSV",
                url: financialsCSVExport.replace(
                    "{collection_meta_id}",
                    meta_id,
                ),
                method: "GET",
                value: "financial-data-export.csv",
            });
        }

        return (
            <div className="buttons-bar">
                <div className="button left-pipe">
                    <Button
                        id="meta-add-new-collection-title"
                        value="Add Record"
                        margin="0"
                        blue={true}
                        disabled={disabled || !canCreateNew}
                        className="add-new"
                        onClick={this.handleAddNewTitle.bind(this)}
                        disabledBackground={"#E4E4E4"}
                    />
                </div>
                <div className="button">
                    <Button
                        value="Delete"
                        margin="0"
                        red={true}
                        disabled={disabled}
                        className="add-new"
                        onClick={() => this.handleDeleteSelectedRecords()}
                        disabledBackground={"#E4E4E4"}
                    />
                </div>
                <div className="button">
                    <Button
                        value="Export Data"
                        margin="0"
                        blue={true}
                        disabled={
                            !categoryAllowedExport ||
                            !(disabled && !_.isEmpty(options))
                        }
                        className="add-new"
                        onClick={() => {
                            this.setState({
                                notification: {
                                    title: "Export data",
                                    intercationStatus: "info",
                                    background: "white",
                                    closeOnWrapperClick: false,
                                    disabled: true,
                                    okText: "Download",
                                    exportData: true,
                                    options,
                                    confirm: () => this.handleExportDownload(),
                                    onClick: () => {
                                        this.setState({
                                            notification: {},
                                            selectedExportId: [],
                                            loading: false,
                                        });
                                    },
                                },
                            });
                        }}
                        disabledBackground={"#E4E4E4"}
                    />
                </div>
            </div>
        );
    }

    renderExportDataNotification(props) {
        const { options = [] } = props;

        const { metadata = [], financial = [] } = options;

        const { selectedExportId = [] } = this.state;

        const handleSelection = (item) => {
            const value = selectedExportId == item ? [] : item;

            this.setState({
                selectedExportId: value,
            });
        };

        return (
            <div>
                {!_.isEmpty(metadata) && (
                    <DoubleGridList
                        label="Metadata"
                        items={metadata}
                        styles={{
                            margin: "1.78125rem 0 0",
                        }}
                        selected={selectedExportId}
                        handleSelection={(item) => handleSelection(item)}
                    />
                )}

                {!_.isEmpty(financial) && (
                    <DoubleGridList
                        label="Financials"
                        items={financial}
                        styles={{
                            margin: "1.78125rem 0 0",
                        }}
                        selected={selectedExportId}
                        handleSelection={(item) => handleSelection(item)}
                    />
                )}
            </div>
        );
    }

    renderCollections() {
        const { disabled, handleLinkSearch } = this.props;

        let columns = [
            {
                field: "original_title",
                editable: false,
                renderCell: (params) =>
                    RenderTitle(params.row, {
                        fontWeight: "400",
                        padding: "0",
                    }),
                renderHeader: () => (
                    <StyledTableHeaderColumn>
                        Record Name
                    </StyledTableHeaderColumn>
                ),
                flex: 1,
                disableColumnMenu: true,
                disableColumnFilter: true,
                disableColumnResize: true,
                hideSortIcons: true,
            },
            {
                field: "type",
                editable: false,
                renderCell: (params) => {
                    return RenderType(
                        params.row.type || params.row.record_type?.name,
                    );
                },
                renderHeader: () => (
                    <StyledTableHeaderColumn>
                        Record Type
                    </StyledTableHeaderColumn>
                ),
                width: 185,
                disableColumnMenu: true,
                disableColumnFilter: true,
                disableColumnResize: true,
                hideSortIcons: true,
            },
            {
                field: "category",
                renderHeader: () => (
                    <StyledTableHeaderColumn>
                        Record Category
                    </StyledTableHeaderColumn>
                ),
                editable: false,
                renderCell: (params) => (
                    <>
                        {RenderCategoryTag(params.value)}
                        {RenderEpisodeNumbertag(params.row.episode_number)}
                        {RenderSeasonTags(params.row)}
                        {RenderShowTags(params.row.show_title)}
                    </>
                ),
                flex: 1,
                disableColumnMenu: true,
                hideSortIcons: true,
            },
            {
                field: "meta_id",
                renderHeader: () => (
                    <StyledTableHeaderColumn>Fabric ID</StyledTableHeaderColumn>
                ),
                editable: false,
                renderCell: (params) =>
                    RenderMetaId(params.value, handleLinkSearch, {
                        fontSize: "0.875rem",
                        marginLeft: "0",
                    }),
                width: 125,
                disableColumnMenu: true,
                hideSortIcons: true,
            },
            {
                ...GRID_REORDER_COL_DEF,
                width: 66,
                hide: disabled,
            },
        ];

        return (
            <div style={this.styles.marginItem}>
                <MaterialTable
                    placeholder="None Added"
                    rows={this.getActiveData()}
                    columns={columns}
                    checkboxSelection={!disabled}
                    handleCheckboxSelect={(indexes) =>
                        this.handleRecordSelect(indexes)
                    }
                    rowReordering={!disabled}
                    handleReordering={(oldIndex, newIndex) =>
                        this.handleSorting({ newIndex, oldIndex })
                    }
                    rowKey={"uuid"}
                />
            </div>
        );
    }

    renderNotification() {
        const {
            notification = {},
            downloading = false,
            selectedExportId,
        } = this.state;

        if (!_.isEmpty(notification)) {
            const { exportData, addTitle, options } = notification;

            let notificationProps = { ...notification };

            if (exportData) {
                notificationProps.html = this.renderExportDataNotification({
                    options,
                });

                notificationProps.disabled = _.isEmpty(selectedExportId);
                notificationProps.loading = downloading;
            }

            if (addTitle) {
                notificationProps.html = this.renderAddTitleToCollection();
                notificationProps.disabled =
                    this.shouldNotificationBeDisabled();
                notificationProps.customMiddleButton.disabled =
                    this.shouldNotificationBeDisabled();
            }

            return <Notification {...notificationProps} />;
        }
    }

    renderNoneAdded() {
        return false;
    }

    renderContent() {
        const { fetchingCollections } = this.state;

        const { title = "" } = this.props;

        return (
            <React.Fragment>
                <FadeWithSkeleton loading={fetchingCollections} />

                <h2 className="section-title">{title}</h2>

                {this.renderButtonsBar()}
                {this.renderCollections()}
                {this.renderNotification()}
            </React.Fragment>
        );
    }
}
