import React, { Component } from "react";
import "./styles/_styles.scss";
import _ from "lodash";
import CircleInput from "../CircleInput";
import Notification from "../Notification";
import deepcopy from "deepcopy";
import CheckIfDataExists from "./handlers/CheckIfDataExists";
import Clients from "../Clients/Config/index";
import ClientManagedDescriptors from "../ClientManagedOptions/descriptors";
import { GetFromLocalStorage } from "../Storage/localStorage";
export default class GracenoteCopyAttributesToMeta extends Component {
    constructor(props) {
        super(props);

        this.state = {
            notification: {},
        };

        this.copyTitle = "Gracenote Attribute Copy";
    }

    componentDidUpdate(prevProps) {
        if (
            !_.isEqual(this.props.item, prevProps.item) &&
            _.isEmpty(this.state.notification) &&
            this.props.item
        ) {
            this.setNotification();
        }
    }

    getFieldStructure() {
        const { item = {}, clientDataStructure = {} } = this.props;

        const { structureKey } = item;

        return this.enrichTitleByLang(clientDataStructure[structureKey]);
    }

    enrichTitleByLang(dataStructure) {
        const { item = {}, data } = this.props;

        const { language = {} } = item;

        const { setToTitleName = false } = language;

        let result = dataStructure;

        if (
            !_.isEmpty(result) &&
            Array.isArray(dataStructure) &&
            setToTitleName &&
            typeof data === "object" &&
            data.locale &&
            typeof data.locale === "string"
        ) {
            result = result.map((item) => {
                let itemCopy = deepcopy(item);
                if (itemCopy.title) {
                    itemCopy.title =
                        itemCopy.title + ` (${data.locale.toUpperCase()})`;
                }
                return itemCopy;
            });
        }
        return result;
    }

    setNotification() {
        const { item = {}, data = [] } = this.props;

        const {
            options,
            label,
            checkDestinationTarget,
            checkFieldForConflict = true,
            checkIfValueExists = true,
            disabledDefaultCopyMode = false,
            chooseSingleSelectValue = false,
        } = item;

        const fieldStructure = this.getFieldStructure();
        const recordData = this.getMetaData();
        const emptyRecordData = Number.isInteger(recordData)
            ? !recordData
            : _.isEmpty(recordData);

        const commonProps = {
            emptyRecordData,
            label,
            fieldStructure,
            options,
        };

        if (disabledDefaultCopyMode) {
            return;
        }

        if (!_.isEmpty(data) && checkDestinationTarget) {
            return this.checkTargetDestination(commonProps);
        }

        const dataAvailableToSelect =
            !_.isEmpty(data) && Array.isArray(data) && data.length > 1;

        if (dataAvailableToSelect && chooseSingleSelectValue) {
            return this.handleValueSelect(commonProps);
        }

        if (!emptyRecordData && !_.isEmpty(options) && checkFieldForConflict) {
            return this.checkIfFieldHasAttributes(commonProps);
        }

        if (
            emptyRecordData &&
            _.isEmpty(options) &&
            _.isEmpty(fieldStructure)
        ) {
            return this.handleCopy();
        }

        if (!emptyRecordData && _.isEmpty(options) && checkIfValueExists) {
            return this.checkIfValueExists(commonProps);
        }

        this.handleCopy();
    }

    checkIfValueExists(props) {
        const { emptyRecordData, options, label, handleCopy, push_to_section } =
            props;

        if (!emptyRecordData && _.isEmpty(options) && !push_to_section) {
            return this.setState({
                notification: {
                    title: this.copyTitle,
                    description: `The ${label} field already has a value assigned in the live record for this title. Continuing this copy will overwrite this value, please decide.`,
                    status: "error",
                    okText: "Overwrite",
                    confirm: () =>
                        handleCopy ? handleCopy() : this.handleCopy(),
                },
            });
        }
    }

    checkTargetDestination(props) {
        const { fieldStructure } = props;

        const FieldChoiceHtml = () => this.fieldChoiceHtml(fieldStructure);

        return this.setState({
            notification: {
                title: this.copyTitle,
                description:
                    "Please choose the destination target field within thelive record for this title.",
                status: "alert",
                disabled: true,
                html: <FieldChoiceHtml />,
                confirm: () => {
                    const { option } = this.state;

                    const { item = {}, data = {} } = this.props;

                    const { locale } = data;

                    const metadata = this.getMetaData();

                    if (Array.isArray(metadata)) {
                        const emptyRecordData = _.isEmpty(
                            metadata.find(
                                (i) => i.type == option && i.locale == locale,
                            ),
                        );

                        const commonProps = {
                            ...props,
                            metadata,
                            emptyRecordData,
                            fieldStructure,
                        };

                        if (
                            !this.checkIfValueExists({
                                ...props,
                                emptyRecordData,
                                handleCopy: () =>
                                    this.copySingleItem(commonProps),
                            })
                        ) {
                            this.handleSingleItemCopy(commonProps);
                        }
                    }
                },
            },
        });
    }

    handleValueSelect(props) {
        const { fieldStructure } = props;

        const { data = [] } = this.props;

        const ValueChoiceHtml = () => this.valueChoiceHtml(data);

        return this.setState({
            notification: {
                title: this.copyTitle,
                description:
                    "Please choose the value within the live record for this title.",
                status: "alert",
                disabled: true,
                html: <ValueChoiceHtml />,
                confirm: () => {
                    const metadata = this.getMetaData();

                    if (Array.isArray(metadata)) {
                        const commonProps = {
                            ...props,
                            metadata,
                            emptyRecordData: true,
                            fieldStructure,
                        };

                        if (
                            !this.checkIfValueExists({
                                ...props,
                                handleCopy: () => this.handleCopy(),
                            })
                        ) {
                            this.handleSingleItemCopy(commonProps);
                        }
                    }
                },
            },
        });
    }

    handleSingleItemCopy(props) {
        const { emptyRecordData, options, fieldStructure } = props;

        if (
            emptyRecordData &&
            _.isEmpty(options) &&
            !_.isEmpty(fieldStructure)
        ) {
            this.copySingleItem(props);
        }
    }

    copySingleItem(props) {
        const { metadata, emptyRecordData } = props;

        const { data, item } = this.props;

        const { option } = this.state;

        const { locale } = data;

        const { attribute, setApiSourceFlag = false } = item;

        let updatedState = deepcopy(metadata);

        const AssignApiFlag = (item) => {
            let result = item;
            if (!_.isEmpty(item) && typeof item === "object") {
                result.api_name = "gracenote";
                result.source = "api";
            }
            return result;
        };

        if (!emptyRecordData) {
            updatedState = metadata.map((item) => {
                if (
                    item.locale == locale &&
                    item.type == option &&
                    data[attribute]
                ) {
                    if (setApiSourceFlag) {
                        item = AssignApiFlag(item);
                    }
                    return {
                        ...item,
                        [attribute]: data[attribute],
                    };
                }

                return item;
            });
        } else {
            let item = {
                type: option,
                locale,
                [attribute]: data[attribute],
            };
            if (setApiSourceFlag) {
                item = AssignApiFlag(item);
            }
            updatedState.push(item);
        }

        this.handleChangeCallBack(updatedState);
    }

    checkIfFieldHasAttributes(props) {
        const { label } = props;

        const Html = () => this.getHtml();

        return this.setState({
            notification: {
                title: this.copyTitle,
                description: `The ${label} field already has attributes assigned in the live record for this title. Please choose the desired outcome.`,
                status: "alert",
                disabled: true,
                html: <Html />,
                confirm: () => this.handleCopy(),
            },
        });
    }

    fieldChoiceHtml(fieldStructure) {
        if (!Array.isArray(fieldStructure)) {
            return false;
        }

        return this.renderOptions(
            fieldStructure.map((item) => {
                return {
                    title: item.title,
                    stateKey: item.type,
                };
            }),
        );
    }

    valueChoiceHtml(data) {
        if (!Array.isArray(data)) {
            return false;
        }

        let items = data.map((item) => {
            return {
                title: item.text || item.value,
                stateKey: item.text || item.value,
            };
        });

        return this.renderOptions(this.validateList(items));
    }

    validateList(data) {
        const {
            item,
            options = {},
            clientFeatures = {},
            clientDataStructure,
        } = this.props;

        const { validation = {} } = item;

        let result = data;

        const {
            rule = false,
            options_key = false,
            list_key = false,
            client_managed_list = false,
        } = validation;

        const HandleChecking = (props) => {
            const { data = [], metaOptionsList = [] } = props;

            let result = [];
            if (Array.isArray(metaOptionsList)) {
                data.map((item) => {
                    let inListItem = metaOptionsList.find(
                        (listItem) => listItem[list_key] === item.title,
                    );
                    if (inListItem) {
                        result.push(item);
                    } else {
                        let disabledItem = deepcopy(item);
                        disabledItem.disabled = true;
                        disabledItem.title =
                            item.title +
                            " (Invalid " +
                            Clients.getClient() +
                            " Genre)";
                        result.push(disabledItem);
                    }
                });
            }

            return result;
        };

        if (
            !_.isEmpty(validation) &&
            rule &&
            rule === "in_list" &&
            options_key &&
            list_key
        ) {
            if (client_managed_list) {
                const metaOptionsList = ClientManagedDescriptors({
                    options,
                    fallback: [],
                    clientFeatures,
                    itemKey: options_key,
                    managed_lists: clientDataStructure.managed_lists || [],
                });

                return HandleChecking({ data, metaOptionsList });
            } else {
                const metaOptionsList = options[options_key];
                return HandleChecking({ data, metaOptionsList });
            }
        }

        return result;
    }

    getMetaData() {
        const { item = {}, metadata = {} } = this.props;

        const { copy_to, structureKey } = item;

        return _.get(metadata, copy_to || structureKey);
    }

    handleCopy() {
        const { item = {}, metadata = {}, data } = this.props;

        const {
            parent_attribute,
            attribute_name,
            options,
            push_to_section,
            copy_to,
            chooseSingleSelectValue = false,
            checkIfDataExists = {},
            apiType = false,
        } = item;

        const { fields = [] } = checkIfDataExists;

        const { option } = this.state;

        const recordData = this.getMetaData();

        let result = false;
        let parentData = deepcopy(metadata[parent_attribute]);

        if (option == "mergeAttributes") {
            if (Array.isArray(recordData) && Array.isArray(data)) {
                const concatData = _.concat(recordData, data);

                parentData[attribute_name] = concatData;
                result = parentData;
            }
        }

        if (
            parentData &&
            attribute_name &&
            (option == "overwriteAndReplace" || !option || _.isEmpty(options))
        ) {
            parentData[attribute_name] = data;
            if (chooseSingleSelectValue && option) {
                parentData[attribute_name] = [this.getValueDefaultListObject()];
            }
            result = parentData;
        }

        if (push_to_section && copy_to && Array.isArray(recordData)) {
            result = this.handleOverwrite({
                fields,
                recordData,
                data,
            });
        }

        if (apiType) {
            const storeKey =
                _.isObject(data) && _.has(data, "storeKey")
                    ? data.storeKey
                    : "";
            let storageData = GetFromLocalStorage(storeKey);
            if (_.has(data, "handleTransformation")) {
                storageData = data.handleTransformation({
                    recordData,
                    data: storageData,
                });
            }
            result = this.handleOverwrite({
                fields,
                recordData,
                data: storageData,
            });
        }

        let checkDataRules = CheckIfDataExists({ data, item });
        if (
            checkDataRules.hasOwnProperty("allowCopy") &&
            !checkDataRules.allowCopy
        ) {
            result = checkDataRules;
        }

        if (result) {
            this.handleChangeCallBack(result);
        }
    }

    handleOverwrite(props) {
        const { fields = [], recordData, data } = props;

        let result = false;

        if (_.isEmpty(fields)) {
            result = _.concat(recordData, data);
        } else {
            const index = recordData.findIndex((record) => {
                return (
                    fields.filter((field) => {
                        return (
                            Object.keys(record).includes(field) &&
                            !_.isEmpty(data[field]) &&
                            record[field] == data[field]
                        );
                    }).length == fields.length
                );
            });

            if (index < 0) {
                result = _.concat(recordData, data);
            } else {
                let copyData = recordData;
                copyData[index] = data;
                result = copyData;
            }
        }

        return result;
    }

    handleChangeCallBack(result) {
        const { item = {}, handleChange = {} } = this.props;

        let empty = _.isEmpty(result);

        if (
            typeof result == "object" &&
            Object.getOwnPropertyNames(result).length > 0
        ) {
            empty = false;
        }

        if (
            !empty &&
            result.hasOwnProperty("allowCopy") &&
            !result.allowCopy &&
            result.hasOwnProperty("message")
        ) {
            this.setState({
                notification: {
                    title: this.copyTitle,
                    description: result.message,
                    status: "error",
                },
            });
        } else if (!empty) {
            handleChange({
                item,
                result,
            });

            this.setState({
                notification: {
                    title: this.copyTitle,
                    description:
                        "The selected attributes have been copied across into the corresponding values in the main title record.",
                    status: "success",
                },
            });
        } else {
            this.setState({
                notification: {
                    title: this.copyTitle,
                    description:
                        "There was a problem merging in data to Meta. Please try again.",
                    status: "error",
                },
            });
        }
    }

    updateNotification(stateKey, value) {
        const { notification = {} } = this.state;

        this.setState(
            {
                option: value ? stateKey : "",
            },
            () => {
                const { option } = this.state;

                const { item, data } = this.props;

                const { chooseSingleSelectValue = false } = item;

                const fieldStructure = this.getFieldStructure();

                const Html = () => this.getHtml();
                const FieldChoiceHtml = () =>
                    this.fieldChoiceHtml(fieldStructure);
                const ValueChoiceHtml = () => this.valueChoiceHtml(data);

                let viewToUse = <Html />;

                if (!_.isEmpty(fieldStructure)) {
                    viewToUse = <FieldChoiceHtml />;
                }

                if (chooseSingleSelectValue) {
                    viewToUse = <ValueChoiceHtml />;
                }

                let updatedState = { ...notification };
                updatedState.html = viewToUse;
                updatedState.disabled = _.isEmpty(option);

                this.setState({
                    notification: updatedState,
                });
            },
        );
    }

    getValueDefaultListObject() {
        const { option } = this.state;

        return {
            text: option,
            value: option,
            ranking: 1,
        };
    }

    getHtml() {
        const { item = {} } = this.props;

        const { options = [] } = item;

        const defaultOptions = [
            {
                title: "Merge attributes in destination value.",
                stateKey: "mergeAttributes",
            },
            {
                title: "Overwrite & replace attributes in destination value.",
                stateKey: "overwriteAndReplace",
            },
        ];

        const selectedOptions = defaultOptions.filter((item) =>
            options.includes(item.stateKey),
        );

        return this.renderOptions(selectedOptions);
    }

    renderOptions(options) {
        const { option } = this.state;

        const commonProps = {
            background: "#e2e2e2",
            width: "1.3rem",
        };

        return (
            <div className="gracenote-copyto">
                {options.map((item, index) => {
                    const { stateKey } = item;

                    let optionCommonProps = deepcopy(commonProps);
                    let onclick = () =>
                        this.updateNotification(
                            stateKey,
                            option == stateKey ? false : stateKey,
                        );
                    let titleColor = "rgba(0, 0, 0, 0.87)";
                    if (item.disabled) {
                        optionCommonProps.disabled = item.disabled;
                        onclick = () => {};
                        titleColor = "rgb(172 172 172)";
                    }

                    return (
                        <div
                            className="gracenote-copyto--row"
                            onClick={() => onclick()}
                            key={index}
                        >
                            <CircleInput
                                innerBackground={
                                    option == stateKey
                                        ? "#787878"
                                        : optionCommonProps.background
                                }
                                onClick={() => onclick()}
                                {...optionCommonProps}
                            />

                            <span style={{ color: titleColor }}>
                                {item.title}
                            </span>
                        </div>
                    );
                })}
            </div>
        );
    }

    render() {
        const { notification = {} } = this.state;

        const { handleCancel = () => {} } = this.props;

        return (
            <Notification
                title={notification.title}
                html={notification.html}
                description={notification.description}
                okText={notification.okText || "OK"}
                confirmOnEnter={false}
                closeOnWrapperClick={false}
                disabled={notification.disabled}
                intercationStatus={notification.status}
                background="white"
                confirm={notification.confirm}
                onClick={() => {
                    this.setState(
                        {
                            notification: {},
                            option: "",
                        },
                        () => {
                            handleCancel();
                        },
                    );
                }}
            />
        );
    }
}
