import ContentFieldInterface from "../Boilerplates/DoubleColumnContent/ContentField.interface";

export const ORIGINAL_AGE_GUIDANCE: string = "original";
export const REGIONAL_AGE_GUIDANCE: string = "regional";

export type AgeGuidanceConfig = Omit<ContentFieldInterface, "value">;

let configuration: any = {};
configuration[ORIGINAL_AGE_GUIDANCE] = [
    {
        name: "Minimum Age",
        key: "minimum_age",
        type: "string",
        editable: true,
        required: true,
    },
    {
        name: "Maximum Age",
        key: "maximum_age",
        type: "string",
        editable: true,
        required: true,
    },
];

configuration[REGIONAL_AGE_GUIDANCE] = [
    {
        name: "Minimum Age",
        key: "minimum_age",
        type: "string",
        editable: true,
        required: false,
        defaultValue: null,
    },
    {
        name: "Maximum Age",
        key: "maximum_age",
        type: "string",
        editable: true,
        required: false,
        defaultValue: null,
    },
    {
        name: "Territories",
        key: "territories",
        type: "selectmultiple",
        list: [],
        editable: true,
        multiple: true,
        required: true,
        defaultValue: [],
    },
];

export default configuration;
