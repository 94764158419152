import {
    MetaIdResolver,
    FieldNameResolver,
    FieldValueResolver,
    DataRowIdResolver,
} from "../resolvers/Resolver";
import ResolverConditions from "../resolvers/resolversConditions";

const GetAuditLog = (props) => {
    const { audits = [] } = props;

    let activeRow = {};

    audits.map((row) => {
        let resolved = false;
        const resolverProps = {
            ...props,
            auditRow: row,
            conditions: ResolverConditions({ ...props, auditRow: row }),
        };

        resolved = MetaIdResolver(resolverProps) || resolved;
        resolved = FieldNameResolver(resolverProps) || resolved;
        resolved = FieldValueResolver(resolverProps) || resolved;
        resolved = DataRowIdResolver(resolverProps) || resolved;

        activeRow = resolved ? row : activeRow;
    });

    return activeRow;
};

export default GetAuditLog;
