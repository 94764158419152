import Prefixer from "inline-style-prefixer";

export default function styles(props = {}) {
    const paddingTop = props.paddingTop || "0.3125rem";
    const paddingBottom = props.paddingBottom || "0.3125rem";
    const color = props.color || "white";
    const padding = "0.3125rem .5rem";

    let styles = {
        container: {
            display: "inline-block",
            borderRadius: "0.25rem",
            background: props.background || "#6a6a6a",
            color,
            border: props.border || "none",
            cursor: props.cursor || "pointer",
            fontSize: "0.9375rem",
            margin: props.margin || 0,
            userSelect: "none",
        },
        content: {
            display: "flex",
            alignItems: "center",
        },
        title: {
            padding: `${paddingTop} 0.5rem ${paddingBottom}`,
            display: "flex",
            alignItems: "center",
            width: "100%",
        },
        postfix: {
            marginLeft: "0.53125rem",
            color: "rgba(255, 255, 255, 0.5)",
        },
        disabled: {
            cursor: "default",
        },
        titleWeightingPadding: {
            //padding: `${paddingTop} 0.5rem ${paddingBottom} 0.9375rem`,
            padding,
            width: "auto",
        },
        titleWithIconPadding: {
            //padding: `${paddingTop} 0.4375rem ${paddingBottom} 0.9375rem`,
            padding,
        },
        timestamp: {
            //padding: `${paddingTop} 0.5rem ${paddingBottom} 0.5rem`,
            padding,
            background: props.timestampBackground || "#949494",
            display: "inline-block",
            color: props.timestampColor || color,
        },
        imdb: {
            //padding: `${paddingTop} 0.5rem ${paddingBottom} 0.5rem`,
            padding,
            background: props.imdbBackground || "#949494",
            display: "flex",
            alignItems: "center",
        },
        imbdbLink: {
            display: "flex",
            alignItems: "center",
            color: "#634B0F",
        },
        imdbImage: {
            width: "2.3rem",
        },
        weighting: {
            //padding: `${paddingTop} 0.9375rem ${paddingBottom} 0.5rem`,
            padding,
            background: props.weightingBackground || "#949494",
            color: props.weightColor || color,
            display: "inline-block",
            borderTopRightRadius: "0.25rem",
            borderBottomRightRadius: "0.25rem",
            display: "flex",
            alignItems: "center",
        },
        copy: {
            display: "flex",
            alignItems: "center",
            textTransform: props.textTransform || "none",
            fontWeight: props.fontWeight || 400,
        },
        delete: {
            width: "1.071875rem",
            marginLeft: "0.4375rem",
            background: props.color,
        },
    };

    let prefixer = new Prefixer();
    styles = prefixer.prefix(styles);

    return styles;
}
