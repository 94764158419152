import React, { useMemo } from "react";
import Input from "../../interfaces/Input.interface";
import { useStores } from '../../store/rootStore'
import { CoreFormLabel } from "../../../components/styled/CoreForm.label";
import { TooltipInfo } from "../../../../Tooltip/TooltipInfo";
import { CoreAutocomplete } from "../../../components/inputs/CoreAutocomplete";
import { observer } from "mobx-react";
import RgbColourDarkner from "../utils/RgbColourDarkner";

export const Autocomplete = observer(({ field, valueKey = 'value' }: Input & { valueKey?: string }) => {
    const { listsStore, formStore } = useStores();

    const arrayPath: string = field.key;
    const inputPath: string = `text_${field.key}`;
    const listOptions = listsStore.lists[field.type.select.listKey]?.options;

    const suggestions = useMemo(() => {
        return listOptions 
            ? listOptions.map(option => ({ ...option, [inputPath]: option.text }))
            : [];
    },[listOptions])

    const Tooltip = () => {
        if (field.validation?.rangeLimit?.enabled) {
            return <TooltipInfo title={<div>Selection Limits <br />Minimum: {field.validation?.rangeLimit?.min} <br />Maximum: {field.validation?.rangeLimit?.max}</div>} />
        }

        return <></>
    }

    const chipColor = field.type.select?.color || 'rgba(0, 0, 0, 0.08)'
    const chipTextColor = field.type.select?.color ? RgbColourDarkner(field.type.select?.color, -65) : 'rgb(73, 73, 73)'

    return (
        <>
            <CoreFormLabel 
                text={field.label}
                isRequired={field.validation?.required?.enabled}
                tooltip={<Tooltip />}
                customStyles={{
                    color: "rgba(47, 47, 47, 0.87) !important",
                    margin: 0
                }}
            />

            <CoreAutocomplete
                labelProps={{
                    isDisabled: true
                }}
                placeholder={`Start typing ${field.label}...`}
                arrayPath={arrayPath}
                inputPath={inputPath}
                disabled={field.validation?.readOnly?.enabled || formStore.disabled}
                suggestions={suggestions}
                chipsLabel={inputPath}
                valueKey={valueKey}
                isFixedList={!field.type?.select?.growing}
                chipArrayStyles={{
                    "& .MuiChip-root": {
                        backgroundColor: `${chipColor} !important`,
                    },
                    "& .MuiChip-root span.MuiChip-label": {
                        color: field.type.select?.textColor || chipTextColor
                    },
                    "& .MuiChip-root .MuiSvgIcon-root": {
                        color: field.type.select?.textColor || chipTextColor
                    }
                }}
            />
        </>
    );
})
