import React, { useEffect } from 'react'
import { observer } from "mobx-react";
import { useStore as globalStore } from "../../../../../../components/Global/store/store"
import { useStores } from "../../../store/rootStore";
import Notification from "../../../../../../components/Notification";
import Settings from "../../../../../../components/Settings";
import MaterialDatePicker from "../../../../../../components/Input/MaterialDatePicker"
import AutocompleteInput from "../../../../../../components/AutocompleteInput"
import Input from "../../../../../../components/Input/basic"
import ScriptSelect from "../../../../../../components/Select/ScriptSelect";
import { CreditInterface, CreditScriptedNameInterface } from '../../../credits.interface';
import { CAST_AND_CREW } from "../../../../../../components/ClientManagedOptions/options";
import {
    getNameScriptValue,
    setNameScriptValue
} from "../../../services/FormService"
import { getContributorCustomIdStatus } from '../../../../../../models/Record/contributors';

const createOrUpdateForm: React.FC = observer(() => {
    const [globalState] = globalStore() || {}
    const rootStore = useStores()
    const { creditsStore } = rootStore || {}
    const scriptValue = rootStore.scriptValue
    const defaultScriptValue = rootStore.defaultScriptValue
    const isOnDefaultScript = scriptValue === defaultScriptValue
    let activeCredit: CreditInterface = creditsStore.activeCredit as CreditInterface

    const {
        options = {}
    } = globalState

    const customIdEnabled: boolean = getContributorCustomIdStatus(
        rootStore.getCmsData().clientDataStructure?.constributorCustomFields || [],
        rootStore.getCmsData().clientFeatures?.contributorCustomFields || false
    )

    const {
        isDisabled,
        isNewContributor,
        contributorValidationIsOn
    } = creditsStore.getContributorFormProperties

    const validation = creditsStore.getContributorFormValidation
        
    useEffect(() => {
        creditsStore.assignParsedNametoCredit()
    }, [])

    const handleFormElements = (field: string, value: string): void => {

        let scriptedValues: CreditScriptedNameInterface[] = [...activeCredit.script_names]

        switch (field) {
            case "date_of_birth":
            case "imdb_id":
            case "isni_id":
            case "hand_id":
            case "iva_person_id":
            case "external_id":
            case "title":
                activeCredit[field] = value
                break;

            case "first_name":
            case "middle_names":
            case "last_name":
            case "alternative_name":
            case "second_alternative_name":
                if (isOnDefaultScript) {
                    activeCredit[field] = value
                } 

                activeCredit = {
                        ...activeCredit,
                        script_names: setNameScriptValue(scriptedValues, field, scriptValue, value)
                }

                if (field === "first_name") {
                    creditsStore.runFormValidation(
                        "contributor",
                        field as string,
                        (creditsStore.activeCredit as CreditInterface)[field as keyof CreditInterface]
                    )
                }
                break;
        }

        creditsStore.setActiveCredit(activeCredit)
        creditsStore.setContributorFormProperty("isDisabled", activeCredit.first_name === "")
    }

    const inputStyleProps = {
        dateInputProps: {
            margin: "1.5rem 0 0",
            recordBasic: true
        },
        textInputProps: {
            margin: "0 0.75rem 0 0",
            recordBasic: true,
            noDelay: true,
        },
        baseInputProps: {
            margin: "1.5rem 0 0",
            recordBasic: true
        }
    }

    const renderFormElements = (): JSX.Element => {
        return (
            <div style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between"
            }}>

                <MaterialDatePicker
                    labelLegacy='Date of Birth'
                    defaultValue={activeCredit.date_of_birth}
                    handleChange={(value: string) => handleFormElements('date_of_birth', value)}
                    styles={{
                        width: Settings.components.credits.item.double.width,
                        ...inputStyleProps.dateInputProps
                    }}
                    className="datepicker-full-width datepicker-small"
                />

                <div style={{
                    marginTop: "1.5rem",
                    width: 'calc(50% - 0.328125rem)'
                }}>
                    <Input
                        label="IMDB ID"
                        placeholder="Enter IMDB ID"
                        value={activeCredit.imdb_id}
                        handleChange={(value: string) => handleFormElements('imdb_id', value)}
                        {...inputStyleProps.textInputProps}
                    />
                </div>

                <div style={{
                    marginTop: "1.5rem",
                    width: 'calc(50% - 0.328125rem)'
                }}>
                    <Input
                        label="ISNI ID"
                        placeholder="Enter ISNI ID"
                        className="contributor-isni-id"
                        value={activeCredit.isni_id}
                        handleChange={(value: string) => handleFormElements('isni_id', value)}
                        {...inputStyleProps.textInputProps}
                    />
                </div>

                <div style={{
                    marginTop: "1.5rem",
                    width: 'calc(50% - 0.328125rem)'
                }}>
                    <Input
                        label="HAND ID"
                        placeholder="Enter HAND ID"
                        className="contributor-hand-id"
                        value={activeCredit.hand_id}
                        handleChange={(value: string) => handleFormElements('hand_id', value)}
                        {...inputStyleProps.textInputProps}
                    />
                </div>

                <div style={{
                    marginTop: "1.5rem",
                    width: 'calc(50% - 0.328125rem)'
                }}>
                    <Input
                        label="IVA Person ID"
                        placeholder="Enter IVA Person ID"
                        className="contributor-iva-person-id"
                        value={activeCredit.iva_person_id}
                        handleChange={(value: string) => handleFormElements('iva_person_id', value)}
                        {...inputStyleProps.textInputProps}
                    />
                </div>

                {customIdEnabled &&
                    <div style={{
                        marginTop: "1.5rem",
                        width: 'calc(50% - 0.328125rem)'
                    }}>
                        <Input
                            label="External ID"
                            placeholder="Enter External ID"
                            className="contributor-external-id"
                            value={activeCredit.external_id}
                            handleChange={(value: string) => handleFormElements('external_id', value)}
                            {...inputStyleProps.textInputProps}
                        />
                    </div>
                }

                <div style={{
                    margin: "1.5rem 0 0 0",
                    width: "100%"
                }}>
                    <ScriptSelect
                        cmsData={rootStore.getCmsData()}
                        options={options}
                        scriptValue={rootStore.scriptValue}
                        scriptOptionsListKey={CAST_AND_CREW}
                        setScriptValue={(value) => {
                            rootStore.setScriptValue(value);
                            rootStore.creditsStore.refreshTableData();
                        }}
                        wrapperStyles={{
                            padding: "0.125rem 0",
                            minWidth: "20rem"
                        }}
                        disabled={creditsStore.getContributorFormScriptStatus}
                    />

                    {creditsStore.getContributorFormScriptStatus &&
                        <div className="error-message">
                            Please populate the Default Language first
                        </div>
                    }
                </div>

                <div style={Settings.components.credits.item.double}>
                    <AutocompleteInput
                        label="Title"
                        placeholder="Select Title"
                        className="contributor-title"
                        value={activeCredit.title}
                        handleListSelection={(value: string) => handleFormElements("title", value)}
                        openOnClick={true}
                        keepSelected={true}
                        staticDatalist={options.person_titles || []}
                        {...inputStyleProps.baseInputProps}
                        styles={{ marginTop: '1.5rem' }}
                    />
                </div>


                <div style={{ ...Settings.components.credits.item.double }}>
                    <Input
                        label="First Name"
                        placeholder="Enter First Name"
                        id="create-new-contributor--first-name"
                        className="contributor-first-name"
                        value={
                            getNameScriptValue({
                                field: "first_name",
                                credit: activeCredit,
                                script: scriptValue,
                            })}
                        handleChange={(value: string) => handleFormElements("first_name", value)}
                        noDelay={true}
                        autoFocus={""}
                        enterOnFocusOut={true}
                        onFocus={() => { }}
                        {...inputStyleProps.baseInputProps}
                    />

                    {(contributorValidationIsOn && !validation.first_name.valid) &&
                        <div className="error-message">
                            {validation.first_name.message}
                        </div>
                    }
                </div>

                <Input
                    label="Middle Name"
                    placeholder="Enter Middle Name"
                    className="contributor-middle-name"
                    value={
                        getNameScriptValue({
                            field: "middle_names",
                            credit: activeCredit,
                            script: scriptValue
                        })}
                    handleChange={(value: string) => handleFormElements("middle_names", value)}
                    noDelay={true}
                    {...inputStyleProps.baseInputProps}
                    {...Settings.components.credits.item.double}
                />

                <div style={{ ...Settings.components.credits.item.double }}>
                    <Input
                        label="Last Name"
                        placeholder="Enter Last Name"
                        className="contributor-last-name"
                        value={
                            getNameScriptValue({
                                field: "last_name",
                                credit: activeCredit,
                                script: scriptValue
                            })}
                        handleChange={(value: string) => handleFormElements("last_name", value)}
                        noDelay={true}
                        {...inputStyleProps.baseInputProps}
                    />

                    {/*(contributorValidationIsOn && !validation.last_name.valid) &&
                        <div className="error-message">
                            {validation.last_name.message}
                        </div>
                    */}
                </div>

                <Input
                    label="Alternative Name"
                    placeholder="Enter Alternative Name"
                    className="contributor-alternative-name"
                    value={
                        getNameScriptValue({
                            field: "alternative_name",
                            credit: activeCredit,
                            script: scriptValue
                        })}
                    handleChange={(value: string) => handleFormElements("alternative_name", value)}
                    noDelay={true}
                    {...inputStyleProps.baseInputProps}
                    {...Settings.components.credits.item.double}
                />

                <Input
                    label="Alternative Name 2"
                    placeholder="Enter Alternative Name 2"
                    className="contributor-alternative-name"
                    value={
                        getNameScriptValue({
                            field: "second_alternative_name",
                            credit: activeCredit,
                            script: scriptValue
                        })}
                    handleChange={(value: string) => handleFormElements("second_alternative_name", value)}
                    noDelay={true}
                    {...inputStyleProps.baseInputProps}
                    {...Settings.components.credits.item.double}
                />
            </div>
        )
    }

    return (
        <React.Fragment>
            <Notification
                intercationStatus={"alert"}
                background={"white"}
                icon={`${Settings.images.path}/svg/alert.svg}`}
                disabled={isDisabled}
                title={isNewContributor ? "Add New Contributor" : "Edit Contributor"}
                okText={isNewContributor ? "Create" : "ok"}
                html={renderFormElements()}
                confirm={() => creditsStore.handleOnSubmitContributorForm()}
                onClick={() => creditsStore.handleOnCancelContributorForm()} // cancel action
            />
        </React.Fragment>
    )
})

export default createOrUpdateForm