import { CreditInterface, CreditListInterface } from "../../credits.interface";

export default function assignCreditList(
    credit: CreditInterface,
    creditList: CreditListInterface
): CreditInterface {

    if (credit.hasOwnProperty('credit_lists')) {
        credit.credit_lists.push({
            name: creditList?.name,
            code: creditList?.code,
            meta_id: creditList?.meta_id,
            ranking: 999
        })
    } else {
        credit.credit_lists = [
            {
                name: creditList?.name,
                code: creditList?.code,
                meta_id: creditList?.meta_id || '',
                ranking: 999
            }
        ];
    }

    return credit;
}