import * as React from 'react';
import Radio, { radioClasses } from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

type Props = {
    label: string,
    id: string,
    value: boolean,
    handleChange: Function
    disabled: boolean
    trueLabel?: string
    falseLabel?: string
}

const RadioBooleanInput = (props: Props) => {
    const {
        label,
        id,
        disabled,
        handleChange,
        value = false,
        trueLabel = "Yes",
        falseLabel = "No",
    } = props

    let radioSx: any = {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
        },
        paddingLeft: 0,
    }

    let controlLabelSx: any = {
        '.MuiTypography-root': {
            fontSize: "0.875rem",
            color: "rgba(47, 47, 47, 0.87)",
        },
        '&.Mui-disabled': {
            '& .MuiTypography-root': {
                color: "rgba(47, 47, 47, 0.87)",
            }
        },
        flex: "1 1 0",
        width: 0,
        marginLeft: 0,
        marginRight: 0,
    }

    let choosenControlLabelSx = {
        ...controlLabelSx
    }

    let choosenRadioSx: any = {
        ...radioSx,
        '&.Mui-disabled': {
            color: "#286CD2",
        }
    }

    if (!disabled) {
        controlLabelSx = {
            ...controlLabelSx,
            border: '1px solid rgba(77, 77, 77, 0.3)',
            borderRadius: '0.25rem',
            '&:hover': {
                border: '1px solid rgba(40, 108, 210, 0.75)',
                background: "rgba(242, 246, 254, 0.5)",
            },
        }

        radioSx = {
            ...radioSx,
            paddingLeft: '0.5rem',
        }

        choosenControlLabelSx = {
            ...controlLabelSx,
            border: "1px solid #286CD2",
            background: "#F2F6FE",
            '&:hover': {},
        }

        choosenRadioSx = {
            ...choosenRadioSx,
            ...radioSx,
        }
    }

    return (
        <FormControl sx={{ width: "100%" }}>
            <label>{label}</label>
            <div style={{ margin: "0.71875rem 0 0" }}>
                <RadioGroup
                    row
                    aria-labelledby={id}
                    name="row-radio-buttons-group"
                    sx={{ columnGap: '10px' }}
                    onChange={e => {
                        handleChange(e.target.value === 'true')
                    }}
                    value={value}
                >
                    <FormControlLabel 
                        disabled={disabled} 
                        value={true} 
                        control={<Radio sx={value ? choosenRadioSx : radioSx} />} 
                        label={trueLabel} 
                        sx={value ? choosenControlLabelSx : controlLabelSx}/>
                    
                    <FormControlLabel 
                        disabled={disabled} 
                        value={false} 
                        control={<Radio sx={!value ? choosenRadioSx :radioSx}/>} 
                        label={falseLabel} 
                        sx={!value ? choosenControlLabelSx : controlLabelSx}/>
                </RadioGroup>    
            </div>
        </FormControl>
    );
}


export default RadioBooleanInput