import isIndexInArray from "../../../components/Helpers/isIndexInArray";
import _ from "lodash";
import update from "react-addons-update";

const handleUpdate = (props) => {
    let { key, value, updatedState, updateRoot, isScript, script } = props;

    if (updateRoot) {
        updatedState[key] = value;
        return updatedState;
    }

    if (!isScript) {
        if (typeof value == "string") {
            updatedState[key].value = value;
        }

        if (value && typeof value == "object") {
            updatedState[key] = {
                value: value.name || value.text || value.value,
                scripts: value.scripts || value.script_names || [],
            };
        }
    }

    if (isScript) {
        if (!Array.isArray(updatedState[key].scripts)) {
            updatedState[key].scripts = [];
        }

        const scriptIndex = _.findIndex(
            updatedState[key].scripts,
            function (item) {
                return item.code == script;
            },
        );

        // update
        if (scriptIndex !== -1) {
            updatedState = update(updatedState, {
                [key]: {
                    scripts: {
                        [scriptIndex]: {
                            value: {
                                $set: value,
                            },
                        },
                    },
                },
            });
        } else {
            updatedState = update(updatedState, {
                [key]: {
                    scripts: {
                        $push: [
                            {
                                code: script,
                                value: value,
                            },
                        ],
                    },
                },
            });
        }
    }

    return updatedState;
};

const HandleChange = (props) => {
    const { value, key, activeRow, isScript, script } = props;

    const rootKeys = ["year"];

    const updateRoot = isIndexInArray(rootKeys, key);
    let updatedState = { ...activeRow };

    updatedState = handleUpdate({
        key,
        value,
        updatedState,
        updateRoot,
        isScript,
        script,
    });

    return updatedState;
};

export default HandleChange;
