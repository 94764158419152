import Boilerplate from "./Boilerplate"
import CoreDynamicForm from '../../../core/Form/dynamic'
import CoreFormFieldInterface from '../../../core/Form/dynamic/interfaces/Field.interface'

type Props = {
    disabled: boolean
    handleFormChange: Function
    category: string
    data: any
    coreDynamicFormFields: CoreFormFieldInterface[]
}

const FabricStudioNumbering = (props: Props) => {
    const {
        disabled,
        data,
        handleFormChange,
        coreDynamicFormFields,
    } = props

    const handleChange = (numbering) => {
        handleFormChange(numbering);
    }

    return (
        <div className="numbering-builder">
            <Boilerplate
                title={"Fabric Studio Numbering"}
                className={"meta-record-fabric-studio-numbering"}
                displayTitle={true}
            >
                <CoreDynamicForm
                    fields={coreDynamicFormFields} 
                    data={data}
                    originalData={data}
                    disabled={disabled}
                    onChange={(numbering) => handleChange(numbering)}
                    stackStyles={{ paddingTop: "0.4rem" }}
                    handleErrors={(errors) => console.log("errors", errors)}
                />
            </Boilerplate>
        </div>
    )
}

export default FabricStudioNumbering