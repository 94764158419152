import React, { Component } from "react";
import styles from "./styles";

export default class Title extends Component {
    /**
     * Title
     *
     * @param object props
     * @return false
     */
    constructor(props) {
        super(props);

        /**
         * @var object
         */
        this.state = {
            tag: this.props.tag || "h1",
        };
    }

    /**
     * Renders html dom elements
     *
     * @return DOM elements
     */
    render() {
        if (this.props.title) {
            return (
                <this.state.tag
                    style={styles(this.props).title}
                    className={this.props.className || ""}
                    data-testid={this.props.testId || 'title'}
                >
                    {this.props.title}

                    {this.props.sufix && (
                        <span
                            style={Object.assign(
                                {},
                                styles(this.props).sufix,
                                this.props.sufixStyles || {},
                            )}
                        >
                            {this.props.sufix}
                        </span>
                    )}
                </this.state.tag>
            );
        }

        return false;
    }
}
