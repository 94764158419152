"use strict";

//const awsIot = require('aws-iot-device-sdk');
import SendRequest from "../SendRequest";
let client;

const isActiveIoTSubscription = (props) => {
    const { IoTConnection = {}, item = {}, index } = props;

    let result = false;

    if (
        IoTConnection.original_title == item.original_title &&
        !IoTConnection.meta_id &&
        index === 0
    ) {
        result = true;
    }

    if (IoTConnection.meta_id == item.meta_id) {
        result = true;
    }

    return result;
};

const buildTopic = (props) => {
    const { client = "", env = "", meta_id = "", topic = "" } = props;

    return `${client}_${env}_${meta_id}_${topic}`;
};

const getSession = async () => {
    let promise = new Promise((resolve, reject) => {
        const options = {
            url: process.env.IOT_ENDPOINT,
            method: "GET",
            overrideHeaders: {
                "x-api-key": process.env.IOT_API_KEY,
            },
        };

        try {
            SendRequest(
                options,
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                },
            );
        } catch (e) {
            reject(e);
        }
    });

    let result = await promise;
    return result;
};

const connect = async (props) => {
    const {
        topic,
        iotEndpoint,
        region,
        accessKey,
        secretKey,
        sessionToken,
        onMessage = () => {},
    } = props;

    return true;

    let promise = new Promise((resolve, reject) => {
        client = awsIot.device({
            region: region,
            protocol: "wss",
            accessKeyId: accessKey,
            secretKey: secretKey,
            sessionToken: sessionToken,
            port: 443,
            host: iotEndpoint,
        });

        client.on("connect", () => {
            client.subscribe(topic);
            console.log("connected to topic " + topic);
            resolve(client);
        });

        client.on("message", (message) => {
            console.log(message);
            onMessage(message);
        });

        client.on("error", (error) => {
            console.log(error);
            reject(error);
        });

        client.on("reconnect", () => {
            console.log("reconnect");
        });

        client.on("offline", () => {
            console.log("offline");
        });

        client.on("close", () => {
            console.log("close");
        });
    });

    let result = await promise;
    return result;
};

const send = (props) => {
    const { iotTopic, message } = props;

    client.publish(iotTopic, message);
};

export { isActiveIoTSubscription, connect, send, getSession, buildTopic };
