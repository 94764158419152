import React from "react";
import RightTransparentLeftColor from "../../DoubleTags/RightTransparentLeftColor";
import ButtonGroup from "../../Button/group";

const FormatOptions = (props) => {
    const {
        data = [],
        selectedFormat = {},
        styles = {},
        handleFormatChange = () => {},
    } = props;

    const { bucket = "-", identifier = "-", format } = selectedFormat;

    const style = {
        display: "flex",
        alignItems: "center",
        ...styles,
    };

    const formats = data.map((item) => {
        return {
            text: item.format,
            active: item.format == format,
            onClick: () => handleFormatChange(item.format),
        };
    });

    return (
        <div style={style}>
            <RightTransparentLeftColor
                leftText={bucket}
                rightText={identifier}
                color="#626262"
                textColor="white"
            />

            <ButtonGroup
                action={true}
                items={formats}
                styles={{
                    marginLeft: "auto",
                }}
            />
        </div>
    );
};

export default FormatOptions;
