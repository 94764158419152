import { SET_ACTIVE_RECORD } from "../actions/action_types";
import { defaultActiveRecord } from "../initialState";

export default function (
    state = { activeRecord: defaultActiveRecord },
    action,
) {
    switch (action.type) {
        case SET_ACTIVE_RECORD:
            return Object.assign({}, state, {
                activeRecord: action.activeRecord,
            });
    }

    return state;
}
