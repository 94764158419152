import { CreditListInterface } from "../store/interfaces"

export const originalCreditListCode: string = "ORG"

export const apiCreditListFields: string[] = [
    'id',
    'code',
    'name',
    'meta_id',
    'deleted'
]

export const apiCreditListCreateFields: string[] = [
    'code',
    'name',
    'credits',
    'meta_id',
    'deleted'
]

export const apiCreditListUpdateFields: string[] = [
    'id',
    'name',
    'credits',
    'meta_id',
    'deleted'
]

export const defaultCreditLists: CreditListInterface[] = [
    {
        code: 'ORG',
        name: 'Original Credit List'
    }
]