import React, { Component } from "react";
import _ from "lodash";
import deepcopy from "deepcopy";
import styles from "./styles";
import Input from "../Input/basic";
import Index from "./index";
import update from "react-addons-update";
import AuditTooltip from "../AuditTooltip";

export default class Links extends Index {
    constructor(props) {
        super(props);

        /**
         * @var object
         */
        this.state = {
            data: [],
            editRow: false,
            hoverRow: false,
            newRow: true,
            activeRow: {
                name: false,
                url: false,
            },
            notification: {},
            datalist: [],
        };

        /**
         * @var object
         */
        this.defaultActiveRowData = {
            name: false,
            url: false,
        };

        /**
         * @var object
         */
        this.styles = styles(this.props);

        /**
         * @var object
         */
        this.copy = this.props.copy || {};

        /**
         * @var string
         */
        this.saveButtonClass = "meta-links-save-icon";
    }

    checkEmptyDataKeys() {
        let result = true;

        if (Array.isArray(this.state.data)) {
            this.state.data.map((item) => {
                if (item.name == false || item.url == false) {
                    result = false;
                }
            });
        }

        return result;
    }

    getRowByIndex(rowIndex) {
        const { data = [] } = this.state;

        return data.find((value, key) => key === rowIndex);
    }

    isActiveRowValidated() {
        let result = false;

        if (this.state.activeRow.name && this.state.activeRow.url) {
            result = true;
        }

        return result;
    }

    handleChangeCallBack() {
        const { data = [] } = this.state;

        if (typeof this.props.handleChange == "function") {
            let updatedData = deepcopy(data);
            if (Array.isArray(updatedData) && !_.isEmpty(updatedData)) {
                updatedData = updatedData.map((link) => {
                    if (!link.locale) link.locale = "ENG";
                    return link;
                });
            }
            this.props.handleChange(updatedData);
        }
    }

    handleChange(key, index, value) {
        if (key) {
            let activeRow = deepcopy(this.getRowByIndex(index));
            activeRow[key] = value;
            const updatedState = update(this.state.data, {
                [index]: { $set: activeRow },
            });
            this.setData(updatedState);
            this.setActiveRow(activeRow);
            this.setNewRow(true);
            this.handleStateSave();
        }
    }

    renderDataRow(item, index, count) {
        if (typeof item == "object") {
            let disabled = this.props.disabled || false;
            let oddIndex = count % 2;
            let placeholderName = "-";
            let placeholderUrl = "-";
            let color = "rgba(55, 117, 211, 0.87)";

            if (!disabled) {
                disabled = false;
                placeholderName = this.copy.enterName || "Enter Name";
                placeholderUrl = this.copy.enterUrl || "Enter Url";
                color = false;
            }

            return (
                <div
                    style={Object.assign(
                        {},
                        this.styles.tableContainer,
                        disabled && this.styles.tableContainerDisabled,
                    )}
                    key={index}
                    onMouseEnter={this.handleRowHover.bind(this, index)}
                    onMouseLeave={this.handleRowHover.bind(this, false)}
                >
                    <div style={this.styles.tableContent}>
                        <table
                            style={Object.assign(
                                {},
                                this.styles.table,
                                disabled && this.styles.tableDisabled,
                            )}
                            cellPadding="0"
                            cellSpacing="0"
                        >
                            <tbody style={this.styles.tbody}>
                                <tr
                                    style={Object.assign(
                                        {},
                                        this.styles.trow,
                                        oddIndex == 1 && this.styles.trEven,
                                    )}
                                >
                                    <td
                                        style={Object.assign(
                                            {},
                                            this.styles.tdExtraPadding,
                                            { width: "20rem" },
                                        )}
                                    >
                                        <AuditTooltip
                                            audits={this.props.audits || []}
                                            dataSection="links"
                                            dataRowId={item.id}
                                            clientFeatures={
                                                this.props.clientFeatures || {}
                                            }
                                        >
                                            {disabled && (
                                                <Input
                                                    className="meta-link-name"
                                                    value={item.name}
                                                    disabled={true}
                                                    borderHiddenPadding={true}
                                                    recordBasic={true}
                                                    margin="0"
                                                    fontWeight={700}
                                                    color="rgba(101, 101, 101, 0.87)"
                                                />
                                            )}

                                            {!disabled && (
                                                <Input
                                                    placeholder={
                                                        placeholderName
                                                    }
                                                    className="meta-link-name"
                                                    defaultValue={item.name}
                                                    disabled={disabled}
                                                    margin="0"
                                                    recordBasic={true}
                                                    handleChange={(value) =>
                                                        this.handleChange(
                                                            "name",
                                                            index,
                                                            value,
                                                        )
                                                    }
                                                />
                                            )}
                                        </AuditTooltip>
                                    </td>

                                    <td style={this.styles.tdExtraPadding}>
                                        <AuditTooltip
                                            audits={this.props.audits || []}
                                            dataSection="links"
                                            dataRowId={item.id}
                                            clientFeatures={
                                                this.props.clientFeatures || {}
                                            }
                                        >
                                            {disabled && (
                                                <Input
                                                    className="meta-link-url"
                                                    value={item.url}
                                                    disabled={true}
                                                    recordBasic={true}
                                                    borderHiddenPadding={true}
                                                    margin="0"
                                                    fontWeight={500}
                                                    color="rgba(55, 117, 211, 0.87)"
                                                    letterSpacing="0.025rem"
                                                    cursor="pointer"
                                                    href={item.url}
                                                />
                                            )}

                                            {!disabled && (
                                                <Input
                                                    placeholder={placeholderUrl}
                                                    className="meta-link-url"
                                                    defaultValue={item.url}
                                                    disabled={disabled}
                                                    margin="0"
                                                    color={color}
                                                    focusColor={color}
                                                    recordBasic={true}
                                                    handleChange={(value) =>
                                                        this.handleChange(
                                                            "url",
                                                            index,
                                                            value,
                                                        )
                                                    }
                                                />
                                            )}
                                        </AuditTooltip>
                                    </td>

                                    {!disabled && (
                                        <td
                                            style={Object.assign(
                                                {},
                                                this.styles.tdExtraPadding,
                                                this.styles.tdAlign,
                                                { width: "5rem" },
                                            )}
                                        >
                                            <div
                                                style={
                                                    this.styles.buttonContainer
                                                }
                                            >
                                                {this.renderDeleteIcon(
                                                    item,
                                                    index,
                                                    "meta-links-delete-icon",
                                                )}
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        }

        return false;
    }
}
