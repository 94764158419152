import React, { useState, useEffect, Fragment } from "react";
import SwitchFilters from "../SwitchFilters";
import _ from "lodash";
import RecordMetaDataRequest from "./helpers/RecordMetaDataRequest";
import GetFieldValue from "./helpers/GetFieldValue";

const DataInheritance = (props) => {
    const {
        sourceRecord = {},
        fallback = () => {},
        handleChange = () => {},
        configuration = {},
        cmsData = {},
        handleChangeSourceRecord = () => {},
    } = props;

    const {
        clientFeatures = {}
    } = cmsData

    const { inheritFields = [] } = configuration;

    const [filters, setFilters] = useState([]);

    const HandleFiltersFetch = (recordMetaData) => {
        handleChangeSourceRecord(recordMetaData);

        setFilters(
            inheritFields
            .filter(item => {
                if (item.featureToggle && !clientFeatures[item.featureToggle]) {
                    return false
                }

                return true
            })
            .map((item) => {
                let sourceRecordFieldData = GetFieldValue({
                    fieldSettings: item,
                    sourceRecord: recordMetaData,
                });

                return {
                    checked: false,
                    disabled:
                        _.isEmpty(sourceRecordFieldData) &&
                        isNaN(parseFloat(sourceRecordFieldData)),
                    name: item.title,
                };
            }),
        );
    };

    useEffect(() => {
        if (_.isEmpty(filters)) {
            RecordMetaDataRequest({
                cmsData,
                meta_id: sourceRecord.meta_id,
                handleSuccess: (data) => HandleFiltersFetch(data),
            });
        }
    }, []);

    if (configuration) {
        const CheckFilters = (filtersHandled) => {
            if (Array.isArray(filtersHandled) && filtersHandled.length > 0) {
                handleChange(filtersHandled[0].filter((item) => item.checked));
            }
        };

        return (
            <Fragment>
                <SwitchFilters
                    data={[filters]}
                    sliderClass="react-slider--orange"
                    style={{
                        margin: "1.6875rem auto 0.5625rem auto",
                        width: "17.375rem",
                    }}
                    handleChange={(data) => CheckFilters(data)}
                />
            </Fragment>
        );
    }

    return fallback();
};

export default DataInheritance;
