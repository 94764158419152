const GetScriptedValues = props => {
    const {
        item = {},
        disabled,
        isScript,
        script,
        nameFieldExposed
    } = props

    const {
        script_names = [],
        script_character_names = []
    } = item

    let result = {
        name: '',
        namePlaceholder: '-',
        character_name: '',
        characterPlaceholder: '-'
    }

    const enterName = 'Enter Name'
    const characterName = !disabled ? 'Enter Character Name' : ""

    result.namePlaceholder = enterName
    result.characterPlaceholder = characterName

    const getName = item => {
        let result = ""

        if(item.name) {
            result = item.name
        }

        if (item.first_name && item.last_name && !nameFieldExposed) {
            if (item.middle_names) {
                result = `${item.first_name} ${item.middle_names || ""} ${item.last_name}`;
            } else {
                result = `${item.first_name} ${item.last_name}`;
            }
        }

        return result.trim()
    }

    if(item) {
        if(item.name) {
            result.name = getName(item)
        }

        if(item.character_name) {
            result.character_name = item.character_name
        }
    }
    
    // Use scripts
    if(isScript) {
        let scriptedName = {}
        let scripteCharacterdName = {}

        if(script_names && Array.isArray(script_names)) {
            scriptedName = script_names.find(item => item.code.toLowerCase() == script.toLowerCase()) || {}
        }

        if(script_character_names && Array.isArray(script_character_names)) {
            scripteCharacterdName = script_character_names.find(item => item.code.toLowerCase() == script.toLowerCase()) || {}
        }

        result = {
            name: getName(scriptedName),
            namePlaceholder: item.name || enterName,
            character_name: scripteCharacterdName.character_name || '',
            characterPlaceholder: item.character_name || characterName
        }
    }

    return result
}

export default GetScriptedValues