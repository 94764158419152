import {
    TaxonomyOptionsInterface,
    TaxonomyOptionsTransformedInterface,
    PartialTaxonomyOptionsTransformedInterface
} from "./taxonomyInterface";
import { GlobalTaxonomyInterface, TaxonomyInterface } from "../Record/taxonomy";
import { TaxonomyStructure } from "../../features/Taxonomies/taxonomy.interface";
import _ from "lodash";

export function extractDataFromTypes(
    data: GlobalTaxonomyInterface | {},
    structure: TaxonomyStructure | {},
): TaxonomyInterface[] {
    let result: TaxonomyInterface[] = [];

    if (!_.isEmpty(data) && structure.globalKey) {
        if (data[structure.globalKey] && structure.types) {
            structure.types.forEach((type) => {
                if (data[structure.globalKey][type.name]) {
                    result = [
                        ...result,
                        ...data[structure.globalKey][type.name],
                    ];
                }
            });
        }
    }

    return result;
}

function mergeOptions(results: TaxonomyOptionsTransformedInterface[]): PartialTaxonomyOptionsTransformedInterface[] {
    const newResults: PartialTaxonomyOptionsTransformedInterface[] = [];

    results.forEach((item) => {
        if (!newResults.some((rs) => rs.text === item.text)) {
            const items = results.filter((result) => result.text === item.text);

            if (!_.isEmpty(items)) {
                const optionsResult = items.reduce((acc, result) => {
                    Object.entries(result.options).forEach(([key, value]) => {
                        acc[key] = acc[key] ? [...acc[key], ...value] : value;
                    });

                    return acc;
                }, {});

                newResults.push({
                    text: item.text,
                    options: optionsResult,
                });
            } else {
                newResults.push(item);
            }
        }
    });

    return newResults;
}

export function getTransformedOptions(
    options: TaxonomyOptionsInterface[],
    data: TaxonomyInterface | {},
    structure: TaxonomyStructure | {},
): PartialTaxonomyOptionsTransformedInterface[] {
    if (!Array.isArray(options)) {
        return [];
    }

    let results: TaxonomyOptionsTransformedInterface[] = [];
    const concatedData: TaxonomyInterface[] = extractDataFromTypes(
        data,
        structure,
    );

    options.forEach((parent) => {
        parent.children?.forEach((subParent) => {
            subParent.children?.forEach((category) => {
                const options: any = category.children
                    ?.filter(
                        (subCategory) =>
                            !concatedData.find(
                                (item) => item.id == subCategory.id,
                            ),
                    )
                    .map((subCategory) => {
                        return {
                            text: subCategory.value,
                            value: subCategory.id,
                            parent: parent.value,
                            subParent: subParent.value,
                        };
                    });

                if (!_.isEmpty(options)) {
                    results.push({
                        parent: parent.value,
                        subParent: subParent.value,
                        text: category.value,
                        options: {
                            [category.value]: options,
                        },
                    });
                }
            });
        });
    });

    const mergedOptions = mergeOptions(results)

    return mergedOptions
}
