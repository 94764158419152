import _ from "lodash";
import DefaultClientPermissions from "../DefaultClientPermissions";

export default function ClientPermissions(roles) {
    const {
        Role_SuperAdmin,
        Role_PlatformAdmin,
        Role_Admin,
        Role_Viewer,
        Role_Viewer_No_Rights,
        Role_MediaAssetEditor,
    } = roles;

    let permissions = DefaultClientPermissions(roles);

    permissions.reporting.looker = [
        Role_SuperAdmin,
        Role_PlatformAdmin,
        Role_Admin,
        Role_Viewer,
    ];

    permissions.discovery.assets.deleteCompliance = [
        Role_PlatformAdmin,
        Role_SuperAdmin,
    ];

    // additions

    permissions.dataManager.view = _.concat(permissions.dataManager.view, [
        Role_PlatformAdmin,
    ]);

    permissions.dataManager.view = _.concat(permissions.dataManager.view, [
        Role_PlatformAdmin,
    ]);

    permissions.dataManager.edit = _.concat(permissions.dataManager.edit, [
        Role_PlatformAdmin,
    ]);

    permissions.discovery.view = _.concat(permissions.discovery.view, [
        Role_MediaAssetEditor,
    ]);

    permissions.discovery.edit = _.concat(permissions.discovery.edit, [
        Role_MediaAssetEditor,
    ]);
    permissions.userAccount = _.concat(permissions.userAccount, [
        Role_MediaAssetEditor,
    ]);
    permissions.discovery.images.edit = _.concat(
        permissions.discovery.images.edit,
        [Role_MediaAssetEditor],
    );

    permissions.discovery.assets.edit = _.concat(
        permissions.discovery.assets.edit,
        [Role_MediaAssetEditor],
    );

    permissions.discovery.videos.edit = _.concat(
        permissions.discovery.videos.edit,
        [Role_MediaAssetEditor],
    );

    permissions.discovery.linkages.edit = _.concat(
        permissions.discovery.linkages.edit,
        [Role_MediaAssetEditor],
    );

    permissions.discovery.inheritData = _.concat(
        permissions.discovery.inheritData,
        [Role_MediaAssetEditor, Role_Admin],
    );

    permissions.verifiedFields = _.concat(permissions.verifiedFields, [
        Role_PlatformAdmin,
        Role_Admin,
    ]);

    permissions.discovery.rights.view =
        permissions.discovery.rights.view.filter(
            (role) => role !== Role_Viewer_No_Rights,
        );
    permissions.discovery.cast_and_crew.editContributor = [
        Role_SuperAdmin,
        Role_PlatformAdmin,
    ];

    return permissions;
}
