import { ComponentsType } from "../../../../core/QA/Components";
import { CoreCreateDataTestId } from "../../../../core/QA/CoreCreateDataTestIdType";
import { Namespace } from "./Namespace";

export class CreateDataTestId extends CoreCreateDataTestId {
    constructor() {
        super(Namespace);
    }
}

const createDataTestId = new CreateDataTestId();
export const generateTestId: (
    componentType: ComponentsType,
    details: string,
) => string = createDataTestId.generateTestId.bind(createDataTestId);
