import React, { useEffect, useState } from "react";
import _ from "lodash";
import Notification from "../../Notification";
import ContentLoading from "../../ContentLoading";
import TitleBarWithPager from "../../TitleBarWithPager";
import Cycles from "../../Cycles";
import { disabledSection } from "../config";
import FadeWithSkeleton from "../../SkeletonLoader/FadeWithSkeleton";

const CyclesView = (props) => {
    const [data, setData] = useState({});
    const [notification, setNotification] = useState({});
    const [fetchingData, setFetchingData] = useState(true);

    const { copy = {}, state = {}, disabled } = props;

    const { handleFetchCycles = () => {}, cyclesYear, set } = state;

    const FetchCyles = () => {
        setFetchingData(true);

        handleFetchCycles({
            handleError: () => {
                setFetchingData(false);
                setNotification({
                    title: copy.technicalError || "Techincal Error",
                    description:
                        "Failed to fetch cycles, please refresh to retry",
                });
            },
            handleSuccess: (data) => {
                setData(data);
                setFetchingData(false);
            },
        });
    };

    useEffect(() => {
        FetchCyles();
    }, []);

    const handleCyclesYearChange = (type) => {
        let updatedState = cyclesYear;

        if (type == "next") {
            updatedState = parseInt(cyclesYear) + 1;
        }

        if (type == "previous") {
            updatedState = parseInt(cyclesYear) - 1;
        }

        set(
            {
                cyclesYear: updatedState,
            },
            () => {
                FetchCyles();
            },
        );
    };

    return (
        <div
            className={`meta-home-screen-cycles ${
                disabled ? disabledSection : ""
            }`}
        >
            <FadeWithSkeleton loading={fetchingData} />

            <div className="meta-record-body--title-content">
                <h2>Cycles</h2>
            </div>

            <TitleBarWithPager
                disabled={fetchingData}
                title={cyclesYear}
                onPreviousClick={() => handleCyclesYearChange("previous")}
                onNextClick={() => handleCyclesYearChange("next")}
                styles={{
                    marginTop: "1.625rem",
                }}
            />

            {!fetchingData && (
                <Cycles
                    data={data}
                    styles={{
                        marginTop: "1.625rem",
                    }}
                />
            )}

            <Notification
                title={notification.title}
                description={notification.description}
                intercationStatus="alert"
                onClick={setNotification.bind(this, {})}
                okText={copy.ok || "Ok"}
            />
        </div>
    );
};

export default CyclesView;
