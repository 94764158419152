import { useFormContext } from "react-hook-form";
import { CoreSelect } from "../../../../../../core/Form/components/inputs/Select/components/CoreSelect";
import { CoreFormPath } from "../../../../../../core/Form/types";
import { C5 } from "../../../../config/clients/viacom/validation/constants";
import { AdditionalVT } from "../../../../config/types";
import { useGenresOptions } from "../../../../hooks/useGenresOptions";

export const Genres = () => {
    const genresOptions = useGenresOptions();
    const form = useFormContext<AdditionalVT>();
    const path: CoreFormPath<AdditionalVT>["path"] = "descriptions.genre";

    let forcedErrorMessage = "";
    if (form.watch(path)?.length > C5) {
        forcedErrorMessage = `Genres has a limit of ${C5}`;
    }

    return (
        <CoreSelect<AdditionalVT>
            config={{
                path,
                labelProps: {
                    text: "Genres",
                },
                options: genresOptions,
                multiple: true,
                enableSearchbox: true,
                showChipArray: true,
                forcedErrorMessage,
            }}
        />
    );
};
