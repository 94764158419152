import React from "react";
import { SectionInterface } from "./interface";
import Descriptors from "../../views/Descriptors";
import DescriptorsV2 from "../../views/DescriptorsV2";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";
import Boilerplate from "../../views/Boilerplate";
import Taxonomies from "../../../../features/Taxonomies";

const SectionDescriptors = (props: SectionInterface) => {
    const {
        handleFormChange = () => {},
        cmsData = {},
        data = {},
        disabled = true,
        recordChangesUuid,
    } = props;

    const {
        clientDataStructure = {},
        clientFeatures = {},
        apiUrls = {},
    } = cmsData;

    const descriptorsProps = {
        ...getSectionCommonProps({ ...props, field: "descriptors" }),
        name: "descriptors",
        className: "meta-record-descriptors",
        title: "Descriptors",
        data: data.descriptors || {},
        originationData: data.origination || {},
        category: data.category,
        clientDataStructure,
        managed_lists: clientDataStructure.managed_lists || [],
        clientFeatures: clientFeatures,
        recordData: data,
        apiUrls,
    };

    return (
        <Boilerplate title="Descriptors" className="meta-record-descriptors">
            <>
                {clientFeatures.taxonomies && (
                    <Taxonomies
                        data={data?.taxonomies}
                        disabled={disabled}
                        handleFormChange={(data: any) =>
                            handleFormChange("taxonomies", data)
                        }
                        key={recordChangesUuid}
                    />
                )}

                {!_.isEmpty(clientDataStructure.descriptors) && (
                    <DescriptorsV2
                        {...descriptorsProps}
                        triviaData={data.trivia || []}
                        handleTriviaFormChange={(data: any) =>
                            handleFormChange("trivia", data)
                        }
                    />
                )}

                {_.isEmpty(clientDataStructure.descriptors) && (
                    <Descriptors {...descriptorsProps} />
                )}
            </>
        </Boilerplate>
    );
};

export default SectionDescriptors;
