import React, { Fragment } from "react";
import _ from "lodash";
import styles from "../styles";
import Index from "./Index";
import ContentLoading from "../../ContentLoading";
import Settings from "../../Settings";
import FormatOptions from "../../ContentDeliveryPreview/FormatOptions";
import LanguageOptions from "../../ContentDeliveryPreview/LanguageOptions";
import ContentDeliveryPreviewTesting from "../../Testing/ContentDeliveryPreview";
import ContentDeliveryTransformer from "../../ContentDeliveryPreview/Transformer";
import VideoJsPlayer from "../../VideoPlayer/VideoJs";
import SendRequest from "../../SendRequest";

export default class ContentDeliveryPreviewClass extends Index {
    constructor(props) {
        super(props);

        /**
         * @var object
         */
        this.styles = styles(this.props);

        /**
         * @var object
         */
        this.state = {
            data: [],
            loading: true,
            error: false,
            selectedFormat: {},
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!_.isEqual(nextState, this.state)) {
            return true;
        }

        return false;
    }

    handleError(message) {
        console.log(message);

        this.setState({
            error:
                typeof message == "string"
                    ? message
                    : "Technical error whilst fetching Content Delivery. Please try again.",
            loading: false,
        });
    }

    fetchData() {
        const { data = {}, cmsData = {} } = this.props;

        const { apiUrls = {} } = cmsData;

        const { contentDelivery = "" } = apiUrls;

        const { meta_id } = data;

        const options = {
            url: contentDelivery.replace("{meta_id}", meta_id),
            method: "GET",
        };

        SendRequest(
            options,
            (response) => {
                const { status, data = [], message } = response;

                if (status == 200) {
                    return this.setData(data);
                }

                this.handleError(message);
            },
            (error) => {
                this.handleError(error);
            },
        );
    }

    setData(responseData) {
        const { clientFeatures = {} } = this.props;

        const { content_delivery_preview_url } = clientFeatures;

        const transformedData = ContentDeliveryTransformer({
            content_delivery_preview_url,
            data: responseData,
        });

        this.setState({
            data: transformedData.data,
            loading: false,
            error: false,
            selectedFormat: transformedData.selectedFormat,
        });
    }

    handleFormatChange(format) {
        const { data = [] } = this.state;

        const changedFormat = data.find((item) => item.format == format);

        if (!_.isEmpty(changedFormat)) {
            this.setState({
                selectedFormat: changedFormat,
            });
        }
    }

    renderContent() {
        const { data, loading, error, selectedFormat = {} } = this.state;

        const commonProps = {
            selectedFormat,
            data,
            handleFormatChange: this.handleFormatChange.bind(this),
        };

        const { source = {}, subtitles = [], poster } = selectedFormat;

        const { path, type } = source;

        const videoJsOptions = {
            //autoplay: true,
            controls: true,
            width: "640px",
            height: "264px",
            poster,
            crossOrigin: "anonymous",
            controlsList: "nodownload",
            sources: [
                {
                    src: path,
                    type: type,
                },
            ],
            fluid: true,
            aspectRatio: "16:9",
            fill: true,
            subtitles,
        };

        return (
            <div style={this.styles.marginItem}>
                {loading && (
                    <ContentLoading
                        loadingDotsGrey={true}
                        styles={{ textAlign: "left" }}
                    />
                )}

                {_.isEmpty(data) && !loading && !error && (
                    <span style={Settings.components.noneEnteredPlaceholder}>
                        No Delivery Content Available for the Title.
                    </span>
                )}

                {_.isEmpty(data) && !loading && typeof error == "string" && (
                    <span style={Settings.components.noneEnteredPlaceholder}>
                        {error}
                    </span>
                )}

                {!_.isEmpty(data) && (
                    <Fragment>
                        <FormatOptions {...commonProps} />

                        <VideoJsPlayer {...videoJsOptions} />

                        <LanguageOptions {...commonProps} />
                    </Fragment>
                )}
            </div>
        );
    }
}
