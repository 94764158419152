import _ from "lodash";
import Attribute from "../../components/Attribute";
import ClientRoles from "./ClientRoles";
import ClientPermissions from "./ClientPermissions";

const roles = ClientRoles();

const {
    Role_SuperAdmin,
    Role_PlatformAdmin,
    Role_Admin,
    Role_Viewer,
    Role_Viewer_No_Rights,
    Role_LocalisationManager,
    Role_LanguageManager,
    Role_Okta_User,
    Role_RegionalEditor,
    Role_DataManager,
    Role_SensitiveViewer,
    Role_CollectionsManager,
    Role_FinanceManager,
    Role_MediaAssetEditor,
} = roles;

const permissions = ClientPermissions(roles);

let userRole = null;

class AuthService {
    init(domElement) {
        if (Boolean(Attribute(domElement, "data-user-is-super-admin"))) {
            userRole = Role_SuperAdmin;
        } else if (
            Boolean(Attribute(domElement, "data-user-is-platform-admin"))
        ) {
            userRole = Role_PlatformAdmin;
        } else if (Boolean(Attribute(domElement, "data-user-is-admin"))) {
            userRole = Role_Admin;
        } else if (
            Boolean(Attribute(domElement, "data-user-is-localisation-manager"))
        ) {
            userRole = Role_LocalisationManager;
        } else if (
            Boolean(Attribute(domElement, "data-user-is-language-manager"))
        ) {
            userRole = Role_LanguageManager;
        } else if (Boolean(Attribute(domElement, "data-user-is-okta-user"))) {
            userRole = Role_Okta_User;
        } else if (
            Boolean(Attribute(domElement, "data-user-is-regional-editor"))
        ) {
            userRole = Role_RegionalEditor;
        } else if (
            Boolean(Attribute(domElement, "data-user-is-data-manager"))
        ) {
            userRole = Role_DataManager;
        } else if (
            Boolean(Attribute(domElement, "data-user-is-sensitive-user"))
        ) {
            userRole = Role_SensitiveViewer;
        } else if (
            Boolean(Attribute(domElement, "data-user-is-collection-manager"))
        ) {
            userRole = Role_CollectionsManager;
        } else if (
            Boolean(Attribute(domElement, "data-user-is-finance-manager"))
        ) {
            userRole = Role_FinanceManager;
        } else if (
            Boolean(Attribute(domElement, "data-user-is-viewer-no-rights"))
        ) {
            userRole = Role_Viewer_No_Rights;
        } else if (
            Boolean(Attribute(domElement, "data-user-is-media-asset-editor"))
        ) {
            userRole = Role_MediaAssetEditor;
        } else {
            userRole = Role_Viewer;
        }
    }

    hasPermission(service) {
        let permission = _.get(permissions, service);
        if (typeof permission === "object") {
            permission = Object.values(permission);
        }
        return Array.isArray(permission)
            ? userRole && permission.indexOf(userRole) >= 0
            : false;
    }

    isUserAdmin() {
        if (userRole == Role_Viewer) {
            return false;
        }

        if (userRole == Role_Viewer_No_Rights) {
            return false;
        }

        if (userRole == Role_Okta_User) {
            return false;
        }

        return true;
    }

    isUserSuperAdmin() {
        return userRole == Role_SuperAdmin ? true : false;
    }

    setTestingRoleSuperAdmin() {
        userRole = Role_SuperAdmin;
    }

    setTestingRoleViwer() {
        userRole = Role_Viewer;
    }

    setTestingRoleViewerNoRights() {
        userRole = Role_Viewer_No_Rights;
    }

    setTestingRoleMediaAssetEditor() {
        userRole = Role_MediaAssetEditor;
    }

    isMetaUser(user = {}) {
        return user.userIsMeta;
    }

    setTestingRoleDefualt() {
        userRole = null;
    }

    getPermissions() {
        return permissions;
    }
}

const instance = new AuthService();

export { instance as default };
