import { CreditInterface, CreditListInterface } from "../../credits.interface";
import { matchCreditsOnContributorCreate } from "./index";

export default function attachSingleCreditToList(
    newCredit: CreditInterface,
    credits: CreditInterface[],
    activeCreditList: CreditListInterface
): CreditInterface[] {

    if (newCredit.new) {
        const contributorID = `CON${Math.floor(Math.random() * 100000000) + 1}`
        const creditID = `CRE${Math.floor(Math.random() * 100000000) + 1}`

        newCredit.contributor_meta_id = contributorID
        newCredit.credit_meta_id = creditID
    }

    let updatedCredit = matchCreditsOnContributorCreate(credits, newCredit)

    return [
        ...credits,
        {
            ...updatedCredit,
            credit_lists: [
                {
                    name: activeCreditList.name,
                    code: activeCreditList.code,
                    ranking: 999
                }
            ]
        }]
}