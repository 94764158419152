export default function EpisodeTitle(data = {}) {
    let result = "";

    if (typeof data == "object") {
        const {
            meta_id,
            original_title,
            titles = [],
            episode_numbering = {},
            storyline_numbering = {},
            episode_number,
        } = data;

        if (meta_id) {
            result = meta_id;
        }

        if (episode_numbering && Number.isInteger(episode_numbering.position)) {
            result = "Episode " + episode_numbering.position;
        }

        if (episode_number) {
            result = "Episode " + episode_number;
        }

        if (
            storyline_numbering &&
            Number.isInteger(storyline_numbering.position)
        ) {
            result = "Storyline " + storyline_numbering.position;
        }

        if (original_title && original_title !== meta_id) {
            result = original_title;
        }

        if (Array.isArray(titles)) {
            const displayTitle = titles.find(
                (title) => title.display && title.title,
            );

            if (displayTitle && displayTitle.title) {
                result = displayTitle.title;
            }
        }
    }

    return result;
}
