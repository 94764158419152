export default function GetLocaleNameFromOptions(locale, options = {}) {
    const { top_written_languages } = options;

    let result = locale;

    if (typeof top_written_languages == "object") {
        let languages = [];

        for (var k in top_written_languages) {
            if (top_written_languages.hasOwnProperty(k)) {
                languages.push(...top_written_languages[k]);
            }
        }

        const language = languages.find((item) => item.value == locale);

        if (typeof language == "object" && language && language.text) {
            result = language.text;
        }
    }

    return result;
}
