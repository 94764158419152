import React, { useRef, useReducer, Fragment, useState } from "react";
import AddNewRowButton from "../AddNewRowButton";
import Notification from "../Notification";
import GetActiveData from "./src/GetActiveData";
import Section from "./src/Section";
import MetaClients from "../Clients";
import AssetMandatoryAttrForm from "../AssetMandatoryAttrForm";
import OriginMasterCreateVersion from "./src/OriginMasterCreateVersion";
import CreateNewForm from "./src/CreateNewForm";
import EditIdsForm from "./src/EditIdsForm";
import AssetInformationCertificatesAdvisory from "./src/AssetInformationCertificatesAdvisory";
import AssetInformationCertificatesAdvisorySingle from "./src/AssetInformationCertificatesAdvisorySingle";
import EditDurationForm from "./src/EditDurationForm";
import AssetCompliances from "./src/AssetCompliances";
import _ from "lodash";
import FilterAssetTypesBySystem from "./src/FilterAssetTypesBySystem";
import { Player } from "video-react";

let notificationBodyRef;

const Wrapper = (props) => {
    const {
        data,
        dataStructure,
        disabled,
        clientFeatures = {},
        handleChange,
        canCreateNew = true,
    } = props;

    const { assets } = clientFeatures;

    const viewOnly = !!(assets && assets.viewOnly);

    let disabledAddNewButton = viewOnly || disabled;
    if (assets && typeof assets === "object" && "disabled" in assets)
        disabledAddNewButton = assets.disabled;

    const parentNotificationRef = useRef();
    notificationBodyRef = useRef();
    const [notificationState, dispatchNotification] = useReducer(
        notificationReducer,
        {},
    );
    const notification = notificationState || {};

    const actualDataStructure = getActualDataStructure(dataStructure);
    const groupedData = MetaClients.isClientWarnerMedia()
        ? groupDataBySystemForWarnerMedia(data, actualDataStructure)
        : groupDataBySystem(data, actualDataStructure);

    const handleGroupChange = (index, data) => {
        const newData = groupedData
            .filter((_, i) => i !== index)
            .flatMap((systemData) => systemData.data)
            .concat(data);

        if (typeof handleChange === "function") {
            handleChange(newData);
        }
    };

    const activeGroupedData = groupedData
        .map((data) => GetActiveData(data.data))
        .filter((data) => data.length > 0);

    const handleAddNewRow = () => {
        if (canAddNew()) {
            dispatchCreateNewForm(props, dispatchNotification);
        } else {
            handleFailedAddNewValidation();
        }
    };

    const canAddNew = () => {
        const { invalidKeys = [] } = props;
        return !MetaClients.isClientWarnerMedia() || invalidKeys.length === 0;
    };

    const handleFailedAddNewValidation = () => {
        const { invalidKeys = [] } = props;
        if (invalidKeys.includes("eidr_status")) {
            dispatchNotification({
                state: "potential duplication alert",
                props,
                dispatchNotification,
            });
        } else {
            dispatchNotification({
                state: "manadatory attributes form",
                props,
                dispatchNotification,
            });
        }
    };

    return (
        <Fragment>
            {groupedData.map((systemData, index) => (
                <>
                    {systemData.data.some(asset => !asset.deleted) && 
                        <Section
                            key={`AssetSection_${index}`}
                            {...props}
                            {...systemData}
                            disabled={viewOnly || disabled}
                            handleChange={(data) => handleGroupChange(index, data)}
                            dispatchNotification={dispatchNotification}
                        />
                    }
                </>
            ))}

            {!disabled &&
                activeGroupedData.length < actualDataStructure.length && (
                    <AddNewRowButton
                        onClick={handleAddNewRow}
                        disabled={!canCreateNew || disabledAddNewButton}
                        data={data}
                    />
                )}

            <Notification
                title={notification.title}
                description={notification.description}
                intercationStatus={notification.status}
                html={notification.html}
                confirm={notification.confirm}
                background="white"
                disabled={notification.disabled}
                onClick={
                    notification.onClick
                        ? notification.onClick
                        : () => this.handleNotificationCancel.bind(this)
                }
                okText={notification.okText}
                contentNoPadding={notification.contentNoPadding}
                loading={notification.loading}
                errorMessage={notification.errorMessage}
                keyPressEnterDisabled={true}
                closeOnWrapperClick={notification.closeOnWrapperClick || false}
                confirmOnEnter={false}
                maxWidth={notification.maxWidth}
                ref={parentNotificationRef}
                wrapperStyle={notification.wrapperStyle || {}}
            />
        </Fragment>
    );
};

function groupDataBySystemForWarnerMedia(data, dataStructure) {
    let defaultIndex = dataStructure.findIndex((item) => !!item.default);
    if (defaultIndex < 0) {
        defaultIndex = 0;
    }

    const systems = dataStructure.map(
        ({ system_type, system_name }) => `${system_type}|${system_name}`,
    );

    const groupedData = data.reduce((accum, item) => {
        const { system_type = "", system_name = "" } = item;

        let system = `${system_type}|${system_name}`;
        if (!systems.includes(system)) {
            system = systems[defaultIndex];
        }

        if (!Array.isArray(accum[system])) {
            accum[system] = [];
        }

        accum[system].push(item);

        return accum;
    }, {});

    return systems
        .map((system, index) => {
            const data = groupedData[system];
            if (!data) {
                return null;
            }

            return {
                data,
                dataStructure: dataStructure[index],
            };
        })
        .filter((item) => !!item);
}

function groupDataBySystem(data, dataStructure) {
    let defaultIndex = dataStructure.findIndex((item) => !!item.default);
    if (defaultIndex < 0) {
        defaultIndex = 0;
    }

    const systems = dataStructure.map(
        ({ system_option }) => system_option?.value,
    );

    const groupedData = data.reduce((accum, item) => {
        const { destination_platform = "" } = item;

        let system = destination_platform;
        if (!systems.includes(system)) {
            system = systems[defaultIndex];
        }

        if (!Array.isArray(accum[system])) {
            accum[system] = [];
        }

        accum[system].push(item);

        return accum;
    }, {});

    return systems
        .map((system, index) => {
            const data = groupedData[system];
            if (!data) {
                return null;
            }

            return {
                data,
                dataStructure: dataStructure[index],
            };
        })
        .filter((item) => !!item);
}

function getActualDataStructure(dataStructure) {
    return Array.isArray(dataStructure) ? dataStructure : [dataStructure];
}

function dispatchCreateNewForm(props, dispatchNotification) {
    dispatchNotification({
        state: "create new asset",
        props: props,
        dispatchNotification,
    });
}

const notificationFunctions = {
    potentialDuplicationAlert: (props, dispatchNotification) => {
        const html = (
            <div>
                <p>{`Assets cannot be created for potential duplicated record.`}</p>
            </div>
        );

        return {
            title: `Asset Validation`,
            description: false,
            html,
            okText: "Ok",
            disabled: false,
            background: false,
            contentNoPadding: false,
            titlePadding: false,
            status: "alert",
            confirm: () => dispatchNotification(),
            onClick: () => dispatchNotification(),
        };
    },
    manadatoryAttributesForm: (props, dispatchNotification) => {
        const {
            attributes = {},
            options = {},
            copy = {},
            validationData: data,
            clientFeatures = {},
        } = props;

        const { assets } = clientFeatures;

        let requiredMetadata = false;
        if (Array.isArray(assets.requiredMetadata)) {
            requiredMetadata = assets.requiredMetadata;
        }

        const formProps = {
            attributes,
            options,
            copy,
            data,
            requiredMetadata,
            onSubmitComplete: (data) => {
                const { handleMandatoryData } = props;

                if (typeof handleMandatoryData == "function") {
                    handleMandatoryData(data);
                }

                dispatchCreateNewForm(props, dispatchNotification);
            },
            onError: (error) => {
                let errorMessage = null;
                if (error && error.message) {
                    errorMessage = error.message;
                }
                dispatchNotification({
                    notification: {
                        title: "Mandatory Attributes for Create Assets Error",
                        description:
                            errorMessage ||
                            error ||
                            "Technical error. Please try again.",
                    },
                });
            },
        };

        return {
            html: (
                <AssetMandatoryAttrForm
                    {...formProps}
                    ref={notificationBodyRef}
                />
            ),
            okText: "Continue",
            status: "alert",
            background: "white",
            closeOnWrapperClick: false,
            confirmOnEnter: true,
            onClick: () => dispatchNotification(),
            confirm: () => {
                if (notificationBodyRef.current) {
                    notificationBodyRef.current.submit();
                }
            },
        };
    },
    createNewForm: (props, dispatchNotification) => {
        const { data, handleChange } = props;

        return {
            title: "Asset Information",
            status: "info",
            html: (
                <OriginMasterCreateVersion
                    {...props}
                    onChange={(isValid) =>
                        dispatchNotification({
                            state: `set disabled`,
                            disabled: !isValid,
                        })
                    }
                    onSubmitComplete={(newItem) => {
                        if (typeof handleChange === "function") {
                            handleChange(data.concat([newItem]));
                        }

                        dispatchNotification();
                    }}
                    ref={notificationBodyRef}
                />
            ),
            onClick: () => dispatchNotification(),
            confirm: () => {
                if (notificationBodyRef.current) {
                    notificationBodyRef.current.submit();
                }
            },
            okText: "Next",
            disabled: true,
        };
    },
    createNewFormForWarnerMedia: (props, dispatchNotification) => {
        const {
            data,
            clientFeatures,
            dataStructure,
            options,
            handleChange,
            filterOnSystemName = true,
        } = props;

        const { asset_management = [] } = options;

        const newAssetManagement = asset_management
            .filter(({ system_name }) =>
                filterOnSystemName
                    ? !data.some(
                          (asset) => asset.system_name === system_name.value && !asset.deleted,
                      )
                    : true,
            )
            .map((system) => {
                const systemName = system.system_name.value;
                const originalAssetTypes = system.asset_types || [];
                const masterAssetTypes = system.asset_types.Masters || [];
                const currentStructure = dataStructure.find(
                    (structure) => structure.system_name === systemName,
                );
                system.asset_types = _.groupBy(
                    AssetCompliances({
                        options,
                        clientFeatures,
                        dataStructure: currentStructure,
                    }),
                    "group",
                );
                system.asset_types = FilterAssetTypesBySystem({
                    asset_types: system.asset_types,
                    system_name: systemName,
                });
                if (systemName === "Sony Media Backbone")
                    system.asset_types.Masters = masterAssetTypes;
                if (systemName === "Metadata Only")
                    system.asset_types = originalAssetTypes;

                return system;
            });

        if (newAssetManagement.length === 0) {
            return;
        }

        const newOptions = {
            ...options,
            asset_management: newAssetManagement,
        };

        return {
            status: "info",
            html: (
                <CreateNewForm
                    data={data}
                    dataStructure={dataStructure}
                    clientFeatures={clientFeatures}
                    options={newOptions}
                    onChange={(data, canSubmit) => {
                        notificationBodyRef.current = { formData: data };
                        dispatchNotification({
                            state: `set disabled`,
                            disabled: !canSubmit,
                        });
                    }}
                />
            ),
            maxWidth: "42rem",
            contentNoPadding: true,
            disabled: true,
            onClick: () => dispatchNotification(),
            confirm: () => {
                const newItem = notificationBodyRef.current.formData;
                if (typeof handleChange === "function") {
                    handleChange(data.concat(newItem));
                }
                dispatchNotification();
            },
        };
    },
    editIdsForm: (props, dispatchNotification) => {
        const { asset, handleChange } = props;

        return {
            okText: "Ok",
            title: "Asset Information",
            status: "alert",
            html: (
                <EditIdsForm
                    {...props}
                    onChange={(data, canSubmit) => {
                        notificationBodyRef.current = { formData: data };
                        dispatchNotification({
                            state: `set disabled`,
                            disabled: !canSubmit,
                        });
                    }}
                />
            ),
            disabled: true,
            onClick: () => dispatchNotification(),
            confirm: () => {
                const newItem = notificationBodyRef.current.formData;
                if (typeof handleChange === "function") {
                    handleChange(asset, newItem);
                }
                dispatchNotification();
            },
        };
    },
    editCertificationsForm: (props, dispatchNotification) => {
        const {
            asset,
            handleChange,
            options,
            dataStructure,
            clientFeatures,
            cmsData = {},
            territory,
        } = props;

        const AssetCertificatesHtml = (props) => {
            const {
                certifications = [],
                compliance = {},
                options = {},
                dataStructure = {},
                clientFeatures = {},
                handleUpdate = () => {},
                cmsData = {},
                territory,
            } = props;

            const { singleAdvisoryGroup = false } = dataStructure;

            return (
                <div>
                    {!singleAdvisoryGroup && (
                        <AssetInformationCertificatesAdvisory
                            certifications={certifications}
                            compliance={compliance}
                            options={options}
                            clientFeatures={clientFeatures}
                            handleUpdate={(data) => handleUpdate(data)}
                            cmsData={cmsData}
                            territory={territory}
                        />
                    )}

                    {singleAdvisoryGroup && (
                        <AssetInformationCertificatesAdvisorySingle
                            certifications={certifications}
                            compliance={compliance}
                            options={options}
                            clientFeatures={clientFeatures}
                            handleUpdate={(data) => handleUpdate(data)}
                        />
                    )}
                </div>
            );
        };

        return {
            okText: "Ok",
            title: "Asset Information",
            status: "alert",
            html: (
                <AssetCertificatesHtml
                    compliance={asset}
                    certifications={asset.certifications}
                    options={options}
                    dataStructure={dataStructure}
                    clientFeatures={clientFeatures}
                    handleUpdate={(data) => {
                        notificationBodyRef.current = { formData: data };
                        dispatchNotification({
                            state: `set disabled`,
                            disabled: false,
                        });
                    }}
                    cmsData={cmsData}
                    territory={territory}
                />
            ),
            disabled: true,
            onClick: () => dispatchNotification(),
            confirm: () => {
                const newItem = notificationBodyRef.current.formData;
                if (typeof handleChange === "function") {
                    handleChange(asset, newItem);
                }
                dispatchNotification();
            },
        };
    },
    editDurationForm: (props, dispatchNotification) => {
        const { asset, handleChange } = props;

        return {
            okText: "Ok",
            title: "Asset Information",
            status: "alert",
            html: (
                <EditDurationForm
                    {...props}
                    onChange={(data, canSubmit) => {
                        notificationBodyRef.current = { formData: data };
                        dispatchNotification({
                            state: `set disabled`,
                            disabled: canSubmit,
                        });
                    }}
                />
            ),
            disabled: true,
            onClick: () => dispatchNotification(),
            confirm: () => {
                const newItem = notificationBodyRef.current.formData;
                if (typeof handleChange === "function") {
                    handleChange(asset, newItem);
                }
                dispatchNotification();
            },
        };
    },
    deleteMetaDataOnlyAsset: (props, dispatchNotification) => {
        const { asset, handleChange } = props;

        return {
            okText: "Ok",
            title: "Delete Asset",
            status: "alert",
            html: (
                <React.Fragment>
                    <div>
                        <p>Are you sure you want to delete this asset?</p>
                    </div>
                </React.Fragment>
            ),
            onClick: () => dispatchNotification(),
            confirm: () => {
                if (typeof handleChange === "function") {
                    handleChange(asset);
                }
                dispatchNotification();
            },
        };
    },
    playVideo: (props, dispatchNotification) => {
        const { media_url = {} } = props;

        const RenderVideoPlayer = (props) => {
            const { mediaUrl } = props;

            return <Player playsInline poster="" src={mediaUrl} />;
        };

        return {
            title: false,
            html: <RenderVideoPlayer mediaUrl={media_url} />,
            disabled: false,
            onClick: () => dispatchNotification(),
            contentNoPadding: true,
            closeOnWrapperClick: true,
            titlePadding: false,
            status: false,
            wrapperStyle: { maxWidth: "70%" },
        };
    },
};

function notificationReducer(prevState, action = {}) {
    const { state, props, dispatchNotification } = action;

    switch (state) {
        case "potential duplication alert":
            return notificationFunctions.potentialDuplicationAlert(
                props,
                dispatchNotification,
            );
        case "play video":
            return notificationFunctions.playVideo(props, dispatchNotification);
        case "manadatory attributes form":
            return notificationFunctions.manadatoryAttributesForm(
                props,
                dispatchNotification,
            );
        case "create new asset":
            if (MetaClients.isClientWarnerMedia()) {
                return notificationFunctions.createNewFormForWarnerMedia(
                    props,
                    dispatchNotification,
                );
            } else {
                return notificationFunctions.createNewForm(
                    props,
                    dispatchNotification,
                );
            }
        case "edit asset ids":
            return notificationFunctions.editIdsForm(
                props,
                dispatchNotification,
            );
        case "edit asset certifications":
            return notificationFunctions.editCertificationsForm(
                props,
                dispatchNotification,
            );
        case "edit asset duration":
            return notificationFunctions.editDurationForm(
                props,
                dispatchNotification,
            );
        case "delete metadata only asset":
            return notificationFunctions.deleteMetaDataOnlyAsset(
                props,
                dispatchNotification,
            );
        case "set disabled":
            return {
                ...prevState,
                disabled: !!action.disabled,
            };
        default:
            return action.notification || {};
    }
}

export default Wrapper;
