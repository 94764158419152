import React, { Fragment } from "react";
import ExpandableRow from "../../ExpandableRow";
import RenderValue from "./RenderValue";
import RightsColumn from "./RightsColumn";

export const ExpandableRowBody = (props) => {
    const { data = [], dataStructure = [], isOpen = false } = props;

    const RenderExpandableContent = (props) => {
        const { fields = [], title = "" } = props;

        return (
            <ExpandableRow
                header={title}
                defaultOpen={true}
                arrowIconColorType="medium"
                className="rights-v3-row-header"
                body={<RenderContent fields={fields} isOpen={isOpen} />}
            />
        );
    };

    const RenderContent = (props) => {
        const { fields = [], columnClass = "", isOpen = false } = props;

        return (
            <div className={`rights-v3-container ${columnClass}`}>
                {fields.map((field) => (
                    <RightsColumn
                        data={data}
                        field={field}
                        RenderValue={RenderValue}
                        isOpen={isOpen}
                    />
                ))}
            </div>
        );
    };

    return (
        <div className="body">
            {dataStructure.length &&
                dataStructure.map((item, key) => (
                    <Fragment key={key}>
                        {item.type === "grouped" ? (
                            <RenderExpandableContent
                                fields={item.fields}
                                title={item.caption}
                            />
                        ) : (
                            <RenderContent
                                fields={item.fields}
                                columnClass="rights-info"
                                isOpen={isOpen}
                            />
                        )}
                    </Fragment>
                ))}
        </div>
    );
};
