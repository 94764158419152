import map from './map'
import DataTransformer from './transformers/transformIncomeData'
import { RecordBuilderApolloClient } from './graphql/ApolloClient'
import BuilderField from '../../../models/RecordBuilder/Field.interface'
import transformOutgoingData, { handleItemUpdate as transformOutgoingUpdateItem } from './transformers/transformOutgoingData'
import _ from 'lodash'

type UpdateMutationsProps = {
    entity: string, 
    data: BuilderField[],
    token: string,
    onResponse: Function
}

export const getAllQuery = (token) => {
    return RecordBuilderApolloClient.query({
        query: map.all.queryAll,
        context: {
            headers: { token }
        }
    })
    .then((response) => {
        let transformedData = {}
        Object.keys(response.data).map(key => {
            transformedData[key] = DataTransformer(response.data[key])
        })

        return transformedData
    })
}

export const updateMultipleMutation = async ({
    entity, 
    data,
    token,
    onResponse = () => {}
}: UpdateMutationsProps) => {
    let gotResponse = []
    let gotError = []

    const pushResponse = (response) => {
        let value = response.data && Object.values(response.data)[0]
        if (value) gotResponse.push(value)
    }

    
    const transformedData: BuilderField[] = transformOutgoingData(data);
    const splitPayloads: BuilderField[][] = _.chunk(transformedData, 15);

    for (const payloads of splitPayloads) {
        await Promise.all(
            payloads.map((item) => {
                if (item.id) {
                    return RecordBuilderApolloClient.mutate({
                        mutation: map[entity].updateMutation,
                        variables: {
                            id: item.id,
                            input: transformOutgoingUpdateItem(item, entity),
                        },
                        context: {
                            headers: { token }
                        }
                    }).then((response) => {
                        pushResponse(response);
                        return Promise.resolve();
                    }).catch((error) => {
                        gotError.push(error);
                        return Promise.resolve();
                    });
                } else {
                    return RecordBuilderApolloClient.mutate({
                        mutation: map[entity].createMutation,
                        variables: {
                            input: item,
                        },
                        context: {
                            headers: { token }
                        }
                    }).then((response) => {
                        pushResponse(response);
                        return Promise.resolve();
                    }).catch((error) => {
                        gotError.push(error);
                        return Promise.resolve();
                    });
                }
            })
        );
    }
    
    let cycles = 0
    let t = setInterval(() => {
        cycles+=1
        if ((gotResponse.length + gotError.length) == data.length) {
            clearInterval(t)
            onResponse({
                data: DataTransformer(gotResponse),
                errors: gotError
            })
        }
    }, 200); // every 0.2 sec

    if (cycles >= 30) { //after 6 sec return response
        clearInterval(t)
        onResponse({
            data: DataTransformer(gotResponse),
            errors: gotError
        })
    }
}