import React, { ReactChildren, ReactChild } from "react";
import "./styles/_styles.scss";

interface WhiteBoxInterface {
    children: ReactChildren | ReactChild;
}

const WhiteBox = ({ children }: WhiteBoxInterface) => {
    return <div className="white-box">{children}</div>;
};

export default WhiteBox;
