import { CmsInterface } from "../../../components/Global/interfaces";
import { TaskManagerFabricOptions } from "../types/interfaces";
import { useStore as useGlobalStore } from "../../../components/Global/store/store";

export default (): [CmsInterface, TaskManagerFabricOptions, any] => {
    const [globalState] = useGlobalStore();

    const {
        cmsData = {
            apiUrls: {},
            clientDataStructure: {},
            clientFeatures: {},
            user: {
                api_token: "",
                userIsSuperAdmin: false,
                userId: 0,
            },
        },
        options = {},
        userPermissions = {},
    }: {
        cmsData: CmsInterface;
        options: TaskManagerFabricOptions;
        userPermissions: any;
    } = globalState;

    return [cmsData, options, userPermissions];
};
