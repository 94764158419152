import React from 'react'
import { makeObservable, action, extendObservable } from 'mobx'
import { RootStore } from './rootStore'
import { bulkActionsStoreInitialState } from "./state"

export class BulkActionsStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            initialize: action
        });

        extendObservable(this, bulkActionsStoreInitialState);
    }

    initialize() {
        this.reset()
    }

    reset() {
        Object.keys(bulkActionsStoreInitialState).forEach(
            (key) => (this[key] = bulkActionsStoreInitialState[key]),
        );
    }
}
