import { extendObservable, makeObservable, action } from "mobx";
import _ from "lodash";
import { CmsInterface } from "../../../../components/Global/interfaces";
import mapSections from "../src/mapSections";

interface RightsStoreInitialStateInterface {
    data: Array<any>;
    cmsData: CmsInterface | {};
    configs: Array<any>;
    pageType: string;
    loading: boolean;
}

interface InitializeInterface {
    data: Array<any>;
    handleFormChange: (data: []) => void;
    cmsData: CmsInterface;
    configs: Array<any>;
}

const rightsStoreInitialState: RightsStoreInitialStateInterface = {
    data: [],
    cmsData: {},
    configs: [],
    pageType: "getRightsConfigs",
    loading: true,
};

export class RightsStore {
    data;
    handleFormChange;
    cmsData;
    configs;
    pageType;
    loading;

    constructor() {
        makeObservable(this, {
            initialize: action,
            setCmsData: action,
            setData: action,
            setConfigs: action,
            setLoading: action,
            reset: action,
            handleChange: action,
            handleTransformConfig: action,
            handleDelete: action,
        });

        extendObservable(this, rightsStoreInitialState);
    }

    reset() {
        Object.keys(rightsStoreInitialState).forEach(
            (key) => (this[key] = rightsStoreInitialState[key]),
        );
    }

    initialize({
        data,
        handleFormChange,
        cmsData,
        configs,
    }: InitializeInterface): void {
        this.reset();
        this.setCmsData(cmsData);
        this.setData(data);
        this.handleTransformConfig(configs);

        this.handleFormChange = handleFormChange;
    }

    setCmsData(cmsData: CmsInterface | {}): void {
        this.cmsData = cmsData;
    }

    setData(data: Array<any>): void {
        this.data = data;
    }

    setConfigs(configs: Array<any>): void {
        this.configs = configs;
    }

    setLoading(loading: boolean): void {
        this.loading = loading;
    }

    setConfigsFromResponse(response: any): void {
        const { clientDataStructure = {} } = this.cmsData;

        const { dateFormat = "" } = clientDataStructure;

        if (_.isEmpty(response)) {
            return this.setConfigs([])
        }

        const configs: any = response?.map((rightsDomain: any) => {
            return {
                displayName: rightsDomain.displayName,
                key: rightsDomain.key,
                sections: mapSections({
                    dateFormat,
                    domain: rightsDomain,
                }),
            };
        });

        this.setConfigs(configs);
    }

    handleChange(field: string, value: any): void {
        this.handleFormChange(field, value);
    }

    handleDelete(item: any): void {
        console.log(item);
    }

    handleTransformConfig(configs: Array<any>): void {
        if (!_.isEmpty(this.configs)) {
            return this.setLoading(false);
        }

        this.setConfigsFromResponse(configs);
        this.setLoading(false);
    }
}
