import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { useStore as globalStore } from "../../../../../../components/Global/store/store";
import { useStores } from "../../../store/rootStore";
import { defaultLanguage, optionURL } from "../../../config";
import { assignCreditRole } from "../../../services/CreditService";
import Notification from "../../../../../../components/Notification";
import Settings from "../../../../../../components/Settings";
import InputDataList from "../../../../../../components/Input/datalist";
import Input from "../../../../../../components/Input/basic";
import {
    CreditInterface,
    APIRoleOptionInterface,
    Contributor,
} from "../../../credits.interface";
import {
    getCharacterNameScriptValue,
    setCharaceterNameScripts,
} from "../../../services/FormService";

import {
    handleContributorSearch,
    selectContributor,
    removeSelectedContributor,
} from "../../../utils/helpers/CreditFormHelpers";
import { isEmpty, deepCopy } from "../../../utils/helpers";
import { RoleCoreSelect } from "../../components/RoleCoreSelect";
import { ScriptCoreSelect } from "../../../../../../components/Select/ScriptSelect/ScriptCoreSelect";
import { CAST_AND_CREW } from "../../../../../../components/ClientManagedOptions/options";
const CreateOrUpdateForm: React.FC = observer(() => {
    const [globalState] = globalStore() || {};
    const rootStore = useStores();
    const { 
        creditsStore, 
        creditListsStore 
    } = rootStore || {};

    const script = rootStore.getScriptValue;
    const defaultScriptValue = rootStore.getDefaultScriptValue;

    const { 
        isDisabled, 
        isNewCredit, 
        searchIsActive, 
        creditValidationIsOn 
    } = creditsStore.getCreditFormProperties;

    const validation = creditsStore.getCreditFormValidation;
    const creditRoleCheck = creditsStore.getCreditRoleCheck;

    const { 
        cmsData = {}, 
        options = {} 
    } = globalState;

    const { 
        apiUrls = {} 
    } = cmsData;

    const [contributors, setContributors] = useState([]);
    const [isScriptSelected, setIsScriptSelected] = useState<boolean>(false);
    const [characterNameIsShown, setCharacterNameIsShown] = useState<boolean>(false);
    const [focusOn, setFocusOn] = useState<boolean | string>(false);

    useEffect(() => {
        if (isNewCredit) {
            rootStore.setScriptValue(defaultScriptValue);
            creditsStore.refreshTableData();
        }
    }, [isNewCredit]);

    useEffect(() => {
        const shouldShowCharacterName =
            rootStore.roles?.find(
                (role: APIRoleOptionInterface) =>
                    (creditsStore.activeCredit as CreditInterface)?.role ===
                    role.id,
            )?.character_name === 1;

        setCharacterNameIsShown(
            shouldShowCharacterName
        );
    
    }, [creditsStore.activeCredit]);

    useEffect(() => {
        setIsScriptSelected(script !== defaultLanguage.value);
        if (focusOn) return setFocusOn("name");
    }, [script]);

    const selectRole = (value: APIRoleOptionInterface): void => {
        creditsStore.setActiveCredit(
            assignCreditRole(
                creditsStore.activeCredit as CreditInterface,
                value,
            ),
        );

        setCharacterNameIsShown(value?.character_name !== 0);

        characterNameIsShown ? setFocusOn("character") : setFocusOn(false);
    };

    const setCharacterName = (value: string): void => {
        let creditCopy = deepCopy(creditsStore.activeCredit);

        creditCopy = setCharaceterNameScripts({
                credit: creditsStore.activeCredit as CreditInterface,
                value,
                script,
            });

        if (!isScriptSelected) {
            creditCopy = {
                ...creditCopy,
                character_name: value,
            };
        }

        creditsStore.setActiveCredit(creditCopy);
    };

    const handleFormElements = (
        field: string,
        value: string | APIRoleOptionInterface,
    ) => {
        creditsStore.setCreditFormProperty("creditValidationIsOn", true);
        switch (field) {
            case "contributor":
                handleContributorSearch({
                    apiUrls,
                    optionUrl: optionURL,
                    searchTerm: value as string,
                    onUpdateList: (data: any) => setContributors(data),
                });

                resetRoleField();
                creditsStore.runFormValidation(
                    "credit",
                    field,
                    value as string,
                );
                break;

            case "role":
                selectRole(value as APIRoleOptionInterface);

                creditsStore.runFormValidation(
                    "credit",
                    field,
                    value as string,
                );
                creditsStore.runRoleUniquenessValidation();
                break;

            case "character_name":
                setCharacterName(value as string);
                creditsStore.runRoleUniquenessValidation();
                break;
        }

        if (isNewCredit) {
            creditsStore.setActiveCredit({
                ...creditsStore.activeCredit,
                new: true,
            });
        } else {
            creditsStore.setActiveCredit({
                ...creditsStore.activeCredit,
                new: false,
            });
        }

        creditsStore.setCreditFormProperty(
            "isDisabled",
            !creditsStore.getCreditRoleCheck.valid,
        );
    };

    const roleFormRef = useRef(null);

    function resetRoleField() {
        if (roleFormRef && roleFormRef.current) {
            roleFormRef.current.resetForm();
        }
        setCharacterNameIsShown(false);

        selectRole(null);
    }

    const chracterNameScriptValue = getCharacterNameScriptValue({
        credit: creditsStore.activeCredit as CreditInterface,
        script,
    });

    const renderFormElements = (): JSX.Element => {
        return (
            <div className="update-create-credit-form">
                <div>
                    <div className="credit-contributor">
                        {!searchIsActive && (
                            <div className="contributor-wrapper">
                                <span className="contributor-name">
                                    {(
                                        creditsStore.activeCredit as CreditInterface
                                    )?.name || ""}
                                </span>

                                <span className="contributor-id">
                                    {(
                                        creditsStore.activeCredit as CreditInterface
                                    )?.contributor_meta_id || ""}
                                </span>

                                {isNewCredit && (
                                    <img
                                        src={`${Settings.images.path}/svg/clear-white.svg`}
                                        onClick={() =>
                                            removeSelectedContributor({
                                                setFocusOn: (value) =>
                                                    setFocusOn(value),
                                                setSearch: (value) =>
                                                    creditsStore.setCreditFormProperty(
                                                        "searchIsActive",
                                                        value,
                                                    ),
                                                setActiveCredit: (value) =>
                                                    creditsStore.setActiveCredit(
                                                        value,
                                                    ),
                                                resetRole: () =>
                                                    resetRoleField(),
                                            })
                                        }
                                        alt="clear-icon"
                                        className="clear-icon"
                                    />
                                )}
                            </div>
                        )}

                        {searchIsActive && (
                            <InputDataList
                                disabled={false}
                                placeholder="Enter Name"
                                value={""}
                                keepValue={false}
                                single={true}
                                datalist={contributors || []}
                                recordBasic={true}
                                useEnterButton={false}
                                dataListUseEnterButton={false}
                                autoFocus={false}
                                handleChange={(value: string) =>
                                    handleFormElements("contributor", value)
                                }
                                handleNoMatchText={(value: string) => {
                                    creditsStore.setActiveCredit({
                                        name: value,
                                    });
                                    creditsStore.setCreditFormStatus({
                                        isActive: false,
                                        isDisabled: true,
                                        searchIsActive: false,
                                        isNewCredit: true,
                                    });

                                    creditsStore.setContributorFormStatus({
                                        isActive: true,
                                        isDisabled: true,
                                        isNewContributor: true,
                                        contributorValidationIsOn: true,
                                    });
                                }}
                                handleListSelection={(
                                    contributor: Contributor,
                                ) => {
                                    selectContributor({
                                        value: contributor,
                                        defaultScript: rootStore.getDefaultScriptValue,
                                        creditList:
                                            creditListsStore.getActiveCreditList,
                                        setActiveCredit: (credit) =>
                                            creditsStore.setActiveCredit(
                                                credit,
                                            ),
                                        setSearch: (value) =>
                                            creditsStore.setCreditFormProperty(
                                                "searchIsActive",
                                                value,
                                            ),
                                        setFocusOn: (value) =>
                                            setFocusOn(value),
                                    });
                                }}
                                noMatchText={
                                    "No match for {value}. Create New?"
                                }
                                padding="0.4375rem 0"
                                margin="0"
                                boxShadow="none"
                                noBorder={true}
                                borderHiddenPadding={true}
                                className="meta-credits-name"
                                styles={{
                                    boxShadow: "none",
                                    padding: "0.25rem 0",
                                }}
                            />
                        )}
                    </div>

                    {creditValidationIsOn && !validation.contributor.valid && (
                        <div className="error-message">
                            {validation.contributor.message}
                        </div>
                    )}
                </div>

                <div className="credit-script">
                    <ScriptCoreSelect
                        options={options}
                        onChange={(value: string) => {
                            rootStore.setScriptValue(value);
                            rootStore.creditsStore.refreshTableData();
                        }}
                        value={rootStore.scriptValue}
                        listKey={CAST_AND_CREW}
                    />
                </div>

                <div className="credit-role">
                    <RoleCoreSelect
                        options={rootStore.roles}
                        value={
                            (
                                creditsStore.activeCredit as CreditInterface
                            )?.role?.toString() || ""
                        }
                        onChange={(role: APIRoleOptionInterface) =>
                            handleFormElements("role", role)
                        }
                        formRef={roleFormRef}
                    />

                    {creditValidationIsOn && !validation.role.valid && (
                        <div className="error-message">
                            {validation.role.message}
                        </div>
                    )}
                </div>

                {characterNameIsShown &&
                    <div className="credit-character-name">
                        <Input
                            label="Character Name"
                            inputDelay={600}
                            placeholder={
                                chracterNameScriptValue.placeHolder
                            }
                            value={
                                chracterNameScriptValue.characterName || ""
                            }
                            handleChange={(value: string) =>
                                handleFormElements("character_name", value)
                            }
                            margin="1rem 0 0"
                            recordBasic={true}
                            autoFocus={focusOn === "character"}
                        />
                    </div>
                }
            </div>
        );
    };

    const handleCancel = (): void => {
        creditsStore.handleOnCancelCreditForm();
        resetRoleField();
    };

    return (
        <React.Fragment>
            <Notification
                intercationStatus="alert"
                background={"white"}
                icon={`${Settings.images.path}/svg/alert.svg}`}
                disabled={isDisabled}
                title={isNewCredit ? "Add New Credit" : "Edit Credit"}
                okText={isNewCredit ? "Create" : "ok"}
                html={renderFormElements()}
                confirm={() => creditsStore.handleOnSubmitCreditForm()}
                onClick={handleCancel}
                errorMessage={creditRoleCheck.message}
            />
        </React.Fragment>
    );
});

export default CreateOrUpdateForm;
