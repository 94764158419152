import React from 'react'
import { observer } from "mobx-react";
import { RootStore, useStores } from "../../store/rootStore";
import Boilerplate from "../../../../../components/RecordBody/views/Boilerplate";
import { CastCrewTable } from "../../../../../core/Table/cast-crew/CastCrew.table"
import InActiveTableActionBar from "./InActiveTableActionBar";
import { DataTablePayload, CreditListInterface } from "../../credits.interface";


const InactiveCreditsContainer: React.FC = observer(() => {
    const rootStore = useStores() || {}
    const { creditsStore, creditListsStore } = rootStore as RootStore

    const activeCreditList: CreditListInterface = creditListsStore.getActiveCreditList
    const tableRows: DataTablePayload[] = creditsStore.inActiveTableCredits
    const tableFooterdata: string = `${activeCreditList.name} • ${activeCreditList.code} • Credit List ID: ${activeCreditList.meta_id}`

    const titleStyles = {
        flexFlow: 'column',
        paddingBottom: '0.625rem'
    }

    return (
        <React.Fragment>
            <div id="inactive-credits">
                <Boilerplate
                    title="Inactive Credits"
                    titleRightContent={<InActiveTableActionBar />}
                    titleStyles={titleStyles}
                    noBorder={true}
                    className="meta-record-credits-v3">

                    <CastCrewTable
                        variant="inactive"
                        credits={tableRows}
                        footerData={tableFooterdata}
                        onSelect={(data) => creditsStore.setSelectedCredits(data, "inactive")}
                        onAdd={(data) => {
                            creditsStore.setSelectedCredits([data], "inactive")
                            creditsStore.addCreditsToCreditList()
                        }}
                        onCreditEdit={() => { }}
                        onReorder={() => { }}
                        onRowsChanged={() => { }}
                    />
                </Boilerplate>
            </div>
        </React.Fragment>
    )
})

export default InactiveCreditsContainer

