import { styled } from "@mui/material/styles"
import Button from '@mui/material/Button';

export const StyledChipCounter = styled(Button)({
	background: "#DCDCDC",
	color: "#626262",
	fontSize: "0.75rem",
	fontWeight: "normal",
	lineHeight: "normal",
	marginLeft: "0.5rem",
	minWidth: "unset",
	padding: "0.25rem 0.3125rem",

	"&:hover": {
		background: "#c4c0c0",
	}
})