import React from 'react'
import { SectionInterface } from './interface'
import CollectionLocalisations from "../../views/CollectionLocalisations"
import _ from 'lodash'
import { getSectionCommonProps } from '../../actions'
import Context from '../../../../context/Collections/Context'

const SectionLocalisations = (props: SectionInterface) => {
    const { cmsData = {}, data = {}, state = {} } = props;

    const { clientDataStructure = {}, clientFeatures = {} } = cmsData;

	return (
		<Context.Consumer>
			{state => 
				<CollectionLocalisations
					{...getSectionCommonProps({...props, field: 'localisations'})}
					name="collection-localisations"
					className="meta-record-collection-localisations"
					title="Localisations Orders"
					data={data.localisations || []}
					linkage={data.linkage || []}
					meta_id={data.meta_id}
					lastSection={true}
					state={state}
					clientFeatures={clientFeatures}
					clientDataStructure={clientDataStructure}
				/>
			}
		</Context.Consumer>
	)
}

export default SectionLocalisations;
