import React from "react";
import _ from "lodash";
import Index from "./Index";
import Tag from "../../Tag";
import RegionalSupplyChain from "../../RegionalSupplyChain";
import Environment from "../../Environment";
import Button from "../../Button";
import styles from "../styles";
import "../styles/_regional_supply_chain.scss";
import deepcopy from "deepcopy";
import Settings from "../../Settings";
import isIndexInArray from "../../Helpers/isIndexInArray";
import NoneEntered from "../../KeywordLabel/noneEntered";

export default class EsbRegionalSupplyChain extends Index {
    constructor(props) {
        super(props);

        /**
         * @var object
         */
        this.copy = this.props.copy || {};

        /**
         * @var object
         */
        this.styles = styles(this.props);

        let data = [];
        if (Array.isArray(this.props.data)) {
            data = this.props.data;
        }

        /**
         * @var object
         */
        this.state = {
            data,
        };
    }

    getInvalidKeys() {
        const { validationData } = this.props;

        let result = [];

        if (typeof validationData == "object") {
            const { eidr_status } = validationData;

            if (eidr_status && eidr_status.status === "duplicate") {
                result.push("eidr_status");
            }
        }

        return result;
    }

    handleFormChange(data) {
        const { handleFormChange } = this.props;

        this.setState({
            data,
        });

        if (typeof handleFormChange == "function") {
            handleFormChange(data);
        }
    }

    renderContent() {
        const data = deepcopy(this.state.data || []);
        const fontSize = "0.78125rem";
        const marginBottom = "0.96875rem";
        const invalidKeys = this.getInvalidKeys();

        const { disabled, esb_status, handleRecordUpdateEsb, updatingEsb } =
            this.props;

        let tags = ["workflowTriggers", "latamESB", "environment"];

        if (Array.isArray(this.props.tags)) tags = this.props.tags;

        if (disabled && _.isEmpty(data)) {
            return;
        }

        return (
            <div style={this.styles.marginItem}>
                <div
                    style={{
                        marginBottom,
                    }}
                    className="container-flex"
                >
                    {isIndexInArray(tags, "workflowTriggers") && (
                        <Tag
                            text={
                                this.copy.workflowTriggers ||
                                "Workflow Triggers"
                            }
                            background="#626262"
                            styles={{
                                marginRight: "0.4375rem",
                                fontSize,
                            }}
                        />
                    )}

                    {isIndexInArray(tags, "latamESB") && (
                        <Tag
                            text={this.copy.latamEsb || "LATAM ESB"}
                            background="#888888"
                            styles={{
                                marginRight: "0.4375rem",
                                fontSize,
                            }}
                        />
                    )}

                    {isIndexInArray(tags, "environment") && (
                        <Tag
                            text={Environment}
                            background={
                                Environment == "Development Environment"
                                    ? "#e58c00"
                                    : "#1c654d"
                            }
                            styles={{
                                fontSize,
                            }}
                        />
                    )}

                    {!_.isEmpty(esb_status) && disabled && (
                        <Button
                            value="Republish"
                            type="button"
                            margin="0 0 0 auto"
                            fontSize="0.78125rem"
                            padding="0.3125rem"
                            fontWeight={500}
                            letterSpacing="-0.01875rem"
                            onClick={handleRecordUpdateEsb}
                            loading={updatingEsb}
                            loadingSrc={Settings.images.loadingDotsGrey}
                            loadingIconWidth="0.8rem"
                        />
                    )}
                </div>

                <RegionalSupplyChain
                    {...this.props}
                    data={data}
                    invalidKeys={invalidKeys}
                    handleFormChange={this.handleFormChange.bind(this)}
                />
            </div>
        );
    }
}
