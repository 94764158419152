import React from "react";
import { get } from "lodash"
import { FormControlLabel, Checkbox, Typography } from "@mui/material";
import { Controller, FieldValues, useFormContext, FieldError } from "react-hook-form";
import { FieldPath } from "../../../types";
import { CoreFormFieldError } from "../../styled/CoreFormFieldError";

export type CoreCheckboxProps<T extends FieldValues> = {
    path: FieldPath<T>;
    label: string;
    labelMessage?: string;
    disabled?: boolean;
};

export function CoreCheckbox<T extends FieldValues>({
    path,
    label,
    labelMessage,
    disabled = false
}: CoreCheckboxProps<T>) {
    const formMethods = useFormContext<T>();
    const errorMessage: string | null = get(formMethods.formState.errors, path)?.message

    return (
        <>
            <Controller
                name={path}
                render={({ field }) => {
                    return (
                        <>
                            <FormControlLabel 
                                control={<Checkbox 
                                    checked={field.value}
                                    {...field}
                                    disabled={disabled}
                                    data-testid={`checkbox-${path}`}
                                />} 
                                label={label} 
                                disableTypography={true}
                                sx={{
                                    color: 'rgba(47, 47, 47, 0.87)',
                                    fontSize: '0.85rem',
                                    fontWeight: '500 !important'
                                }}
                            />

                            {labelMessage &&
                                <Typography 
                                    variant="body1" 
                                    component="p"
                                    sx={{
                                        marginTop: '-0.5rem',
                                        paddingLeft: '1.95rem',
                                        color: 'rgba(47, 47, 47, 0.57)',
                                        fontSize: '0.875rem'
                                    }}>

                                    {labelMessage}
                                </Typography>
                            }
                        </>
                    )
                }}
            />
            
            {errorMessage && (
                <CoreFormFieldError 
                    path={path} 
                    message={errorMessage} 
                />
            )}

        </>
    );
}
