import React from "react";
import Input from "./basic";
import InputMask from "react-input-mask";

export default class maskInput extends Input {
    constructor(props) {
        super(props);
    }

    renderInput(options) {
        const {
            onChange = () => {},
            onFocus = () => {},
            onBlur = () => {},
        } = options;

        const { maskChar = false, mask = "99:99" } = this.props;

        return (
            <InputMask
                mask={mask}
                maskChar={maskChar}
                value={options.value || ""}
                onChange={(value) => onChange(value)}
                onFocus={(value) => onFocus(value)}
                onBlur={(value) => onBlur(value)}
            >
                {(inputProps) => {
                    if (options.onChange) delete options.onChange;
                    if (options.onFocus) delete options.onFocus;
                    if (options.onBlur) delete options.onBlur;

                    return (
                        <input
                            {...options}
                            {...inputProps}
                            className={`placeholder-light-grey ${
                                this.props.className || ""
                            }`}
                            ref={(ref) => (this.inputRef = ref)}
                        />
                    );
                }}
            </InputMask>
        );
    }
}
