import _ from "lodash";

const HandleLineupPeriodChange = (props) => {
    const { state = {}, options } = props;

    const { lineupPeriod, handleSuccess } = options;

    const { set } = state;

    set(
        {
            lineupPeriod,
        },
        () => {
            handleSuccess();
        },
    );
};

export default HandleLineupPeriodChange;
