import CategoryTags from "../../../CategoryTags";

const disabledExceptShow = [];
Object.keys(CategoryTags).map((categoryKey) => {
    if (CategoryTags[categoryKey].short !== CategoryTags.Show.short) {
        disabledExceptShow.push(CategoryTags[categoryKey].short);
    }
});

const config = {
    supply_chain: {
        fields: [
            {
                key: "show_code",
                disabledForCategories: disabledExceptShow,
            },
        ],
    },
};

export default config;
