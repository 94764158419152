import React, { Component } from "react";
import deepcopy from "deepcopy";
import _ from "lodash";
import update from "react-addons-update";
import LocalisationLanguageDetailsForm from "./LocalisationLanguageDetailsForm";
import TitleBarWithPager from "../TitleBarWithPager";
import TrafficLightMenuSelector from "../TrafficLightMenuSelector";
import TrafficLightTransformer from "./TrafficLightTransformer";
import CopyAttributesSubmitLanguageBar from "./CopyAttributesSubmitLanguageBar";
import CopyAttributesLanguages from "./CopyAttributesLanguages";
import SelectedLanguageIndex from "./src/SelectedLanguageIndex";
import $ from "jquery";
import "./styles/_styles.scss";
import AuthService from "../../services/AuthService";
import ClientManagedLanguages from "../ClientManagedOptions/languages";

export default class LocalisationLanguageManager extends Component {
    constructor(props) {
        super(props);

        const { initLocale } = props;

        this.state = {
            data: props.data || {},
            currentLang: initLocale
                ? this.getLanguages().find((lang) => lang.value == initLocale)
                : this.getFirstLanguage(),
            copyAttributesWindowDisplay: false,
            selectedLanguagesToCopy: this.getDefaultLanguagesToCopy(),
            selectAll: false,
        };

        this.changeLanguage = this.changeLanguage.bind(this);
        this.changeToPreviousLanguage =
            this.changeToPreviousLanguage.bind(this);
        this.changeToNextLanguage = this.changeToNextLanguage.bind(this);
        this.updateLanguage = this.updateLanguage.bind(this);
    }

    componentDidUpdate() {
        const { copyAttributesWindowDisplay } = this.state;

        const speed = 250;
        const topContent = ".meta-localisation-language-manager--top-content";
        const languages =
            ".localisation-language-manager-copy-atrributes-languages";

        if (copyAttributesWindowDisplay) {
            $(topContent)
                .css("display", "flex")
                .fadeIn(speed, () => {
                    $(languages).slideDown(speed);
                });
        }

        if (!copyAttributesWindowDisplay) {
            $(languages).slideUp(speed, () => {
                $(topContent).fadeOut(speed);
            });
        }
    }

    getDefaultLanguagesToCopy() {
        const { data = {} } = this.props;

        const { languages = [] } = data;

        let result = [];

        if (Array.isArray(languages) && !_.isEmpty(languages)) {
            result = languages.map((item) => {
                return {
                    text: item.language,
                    value: item.locale,
                };
            });
        }

        return result;
    }

    getFirstLanguage() {
        const { data = {} } = this.props;

        const { languages = [] } = data;

        const optionsLanguages = this.getLanguages();

        let result = {};

        if (Array.isArray(optionsLanguages)) {
            result = optionsLanguages[0];
        }

        if (!_.isEmpty(languages)) {
            result = optionsLanguages.find(
                (item) => item.value == languages[0].locale,
            );
        }

        return result;
    }

    getLanguages() {
        const {
            state: {
                options: {
                    localisations: { languages = [] },
                },
            },
        } = this.props;

        const { clientFeatures } = this.props;

        const result = ClientManagedLanguages({
            options: this.props.options,
            spoken: true,
            fallback: languages,
            clientFeatures,
            languageKey: "localisation_orders",
        });

        return result;
    }

    getLanguageData(inLang) {
        const {
            data: { languages = [] },
        } = this.state;

        return (
            languages.find((lang) => lang.locale == inLang.value) || {
                locale: inLang.value,
                language: inLang.text,
            }
        );
    }

    submit() {
        return this.state.data;
    }

    changeLanguage(locale) {
        const lang = this.getLanguages().find((lang) => lang.value == locale);
        this.setState({ currentLang: lang });
    }

    changeToPreviousLanguage() {
        const languages = this.getLanguages();
        const index = languages.indexOf(this.state.currentLang);
        if (index < 0) {
            return;
        }

        let newIndex = index - 1;
        if (newIndex < 0) {
            newIndex = languages.length + newIndex;
        }
        this.changeLanguage(languages[newIndex].value);
    }

    changeToNextLanguage() {
        const languages = this.getLanguages();
        const index = languages.indexOf(this.state.currentLang);
        if (index < 0) {
            return;
        }

        let newIndex = index + 1;
        if (newIndex >= languages.length) {
            newIndex = newIndex - languages.length;
        }
        this.changeLanguage(languages[newIndex].value);
    }

    updateLanguage(props = {}) {
        const langData = props.newData;
        const newLanguages = deepcopy(this.state.data.languages);
        const langIndex = newLanguages.findIndex(
            (lang) => lang.locale == langData.locale,
        );
        if (langIndex >= 0) {
            newLanguages.splice(langIndex, 1, langData);
        } else {
            newLanguages.push({
                preferred_location: null,
                first_use_date: null,
                type: [],
                compliance: null,
                subtitles: false,
                dubbing: false,
                voicecover: false,
                accessibility: false,
                translations: false,
                studio: null,
                audio_description: false,
                sign_language: false,
                hard_of_hearing: false,
                all_screen_graphics_in_vision: false,
                episode_title_in_vision: false,
                original_credits_in_vision: false,
                end_dub_card_in_vision: false,
                screen_graphics_voiced: false,
                series_title_voiced: false,
                episode_title_voiced: false,
                original_credits_voiced: false,
                opening_songs_dubbed: false,
                all_songs_dubbed: false,
                songs_by_case: false,
                scripts: null,
                screeners: null,
                approver_email: null,
                user_notification: null,
                status: "incomplete",
                language_notes: null,
                ...langData,
            });
        }

        const newData = deepcopy(this.state.data);
        newData.languages = newLanguages;
        this.setState({ data: newData });

        const { onFormChange, validateLanguages = () => {} } = this.props;

        if (typeof onFormChange == "function") {
            onFormChange({
                isValid: validateLanguages(newData),
                newData,
                key: props.key,
                langIndex,
            });
        }
    }

    handleLanguageSelect(selectedLanguage = {}) {
        const { selectedLanguagesToCopy = [] } = this.state;

        const { value } = selectedLanguage;

        const inArray = selectedLanguagesToCopy.find(
            (item) => item.value == value,
        );
        let updatedState = deepcopy(selectedLanguagesToCopy);

        // add
        if (!inArray) {
            updatedState = update(selectedLanguagesToCopy, {
                $push: [selectedLanguage],
            });
        }

        // remove
        if (inArray) {
            const index = _.findIndex(
                selectedLanguagesToCopy,
                (item) => item.value == value,
            );
            updatedState = update(selectedLanguagesToCopy, {
                $splice: [[index, 1]],
            });
        }

        this.setState({
            selectedLanguagesToCopy: updatedState,
        });
    }

    handleCopyAttributes() {
        const {
            selectedLanguagesToCopy = [],
            data = {},
            currentLang = {},
        } = this.state;

        const { languages = [] } = data;

        const { onFormChange, validateLanguages = () => {} } = this.props;

        const currentLanguageAttributes = deepcopy(
            languages.find((language) => language.locale == currentLang.value),
        );
        let updatedState = deepcopy(data);

        if (currentLanguageAttributes) {
            selectedLanguagesToCopy.forEach((selectedLanguage) => {
                const languageObject = {
                    ...currentLanguageAttributes,
                    locale: selectedLanguage.value,
                    language: selectedLanguage.text,
                };

                const index = _.findIndex(
                    languages,
                    (item) => item.locale == selectedLanguage.value,
                );

                // update
                if (index !== -1) {
                    updatedState = update(updatedState, {
                        languages: {
                            [index]: {
                                $set: languageObject,
                            },
                        },
                    });
                }

                // create
                if (index == -1) {
                    updatedState = update(updatedState, {
                        languages: {
                            $push: [languageObject],
                        },
                    });
                }
            });

            this.setState({
                data: updatedState,
                copyAttributesWindowDisplay: false,
                selectedLanguagesToCopy: this.getDefaultLanguagesToCopy(),
                selectAll: false,
            });

            if (typeof onFormChange == "function") {
                onFormChange(validateLanguages(updatedState));
            }
        }
    }

    handleSelectAll() {
        const { selectAll } = this.state;

        const languages = this.getLanguages();

        this.setState({
            selectedLanguagesToCopy: selectAll ? [] : languages,
            selectAll: !selectAll,
        });
    }

    handleLanguageStatusChange(status) {
        const { data = {}, currentLang = {} } = this.state;

        const { languages = [] } = data;

        const { validateLanguages, onFormChange } = this.props;

        const index = _.findIndex(
            languages,
            (item) => item.locale == currentLang.value,
        );

        if (index !== -1) {
            const updatedData = update(data, {
                languages: {
                    [index]: {
                        status: {
                            $set: status,
                        },
                    },
                },
            });

            this.setState({
                data: updatedData,
            });

            if (typeof onFormChange == "function") {
                onFormChange(validateLanguages(updatedData));
            }
        }
    }

    renderHeader() {
        const { data: { localisation_request_title, po_number } = {} } =
            this.state;

        return (
            <div className="meta-localisation-header">
                <h3 className="title">Localisation Order Language Manager</h3>
                <div className="order-title">{localisation_request_title}</div>
                <div className="po-number">{po_number}</div>
            </div>
        );
    }

    render() {
        const {
            data = {},
            currentLang = {},
            selectedLanguagesToCopy,
            selectAll,
        } = this.state;

        const { languages = [] } = data;

        const { text: language, value: locale, tmc_code } = currentLang;

        const {
            statusColors,
            state: { localisationApproval },
            validateLanguage,
            clientFeatures,
            cmsData,
            meta_id,
        } = this.props;

        const locales = this.getLanguages();

        const selecteLanguageIndex = SelectedLanguageIndex({
            locales,
            currentLang,
        });

        const currentDataLanguage =
            languages.find(
                (language) => language.locale == currentLang.value,
            ) || {};
        const isCurrentLangValid = validateLanguage(currentDataLanguage);

        const trafficLightTransformer = TrafficLightTransformer({
            locales,
            data,
            statusColors,
        });

        return (
            <div className="meta-localisation-language-manager">
                <div className="meta-localisation-language-manager--top-content">
                    <CopyAttributesLanguages
                        locales={locales}
                        selectAll={selectAll}
                        selectedLanguagesToCopy={selectedLanguagesToCopy}
                        handleCancelClick={() =>
                            this.setState({
                                copyAttributesWindowDisplay: false,
                                selectedLanguagesToCopy:
                                    this.getDefaultLanguagesToCopy(),
                                selectAll: false,
                            })
                        }
                        handleSelection={(selectedLanguage) =>
                            this.handleLanguageSelect(selectedLanguage)
                        }
                        handleCopyAttributes={this.handleCopyAttributes.bind(
                            this,
                        )}
                        handleSelectAll={this.handleSelectAll.bind(this)}
                    />
                </div>

                <div className="meta-localisation-language-manager--bottom-content">
                    {this.renderHeader()}

                    <TrafficLightMenuSelector
                        options={trafficLightTransformer}
                        selectedOption={selecteLanguageIndex}
                        handleClick={(index) =>
                            this.setState({
                                currentLang: this.getLanguages()[index],
                            })
                        }
                        styles={{
                            marginBottom: "0.90625rem",
                        }}
                    />

                    <TitleBarWithPager
                        title={language}
                        tag={tmc_code || locale}
                        onPreviousClick={this.changeToPreviousLanguage}
                        onNextClick={this.changeToNextLanguage}
                    />

                    <LocalisationLanguageDetailsForm
                        props={this.props}
                        cmsData={cmsData}
                        state={this.props.state}
                        data={this.getLanguageData(currentLang)}
                        onUpdate={this.updateLanguage}
                        clientFeatures={clientFeatures}
                        meta_id={meta_id}
                    />

                    <CopyAttributesSubmitLanguageBar
                        disabled={!isCurrentLangValid}
                        submitted={currentDataLanguage.status == "submitted"}
                        approved={currentDataLanguage.status == "approved"}
                        handleCopyAttributesClick={() =>
                            this.setState({
                                copyAttributesWindowDisplay: true,
                            })
                        }
                        handleSubmitLanguageClick={this.handleLanguageStatusChange.bind(
                            this,
                            "submitted",
                        )}
                        handleLanguageApproval={this.handleLanguageStatusChange.bind(
                            this,
                            "approved",
                        )}
                        localisationApproval={
                            AuthService.hasPermission(
                                "localisations.approve",
                            ) || localisationApproval
                        }
                        localisationApproveAndSubmit={
                            AuthService.hasPermission(
                                "localisations.approveAndSubmit",
                            ) || localisationApproval
                        }
                    />
                </div>
            </div>
        );
    }
}
