import MenuItem from "@mui/material/MenuItem";
import { observer } from "mobx-react";
import * as React from "react";
import { StyledChipCounter } from "./styles/StyledChipCounter";
import { StyledExpandableTags } from "./styles/StyledExpandableTags";
import { StyledMenu } from "./styles/StyledMenu";

export type ExpandableTagsProps = {
    dataList: String[];
    disabled: Boolean;
    text: String;
    awardsStore: any;
};

const ExpandableTags = observer(
    ({
        dataList,
        text = "",
        disabled = true,
        awardsStore = () => {},
    }: ExpandableTagsProps) => {
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(
            null,
        );

        const open = Boolean(anchorEl);

        const handleClick = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleContributorClick = () => {
            if (!disabled) {
                // awardsStore.setAwardEditContributorFormIsActive(true)  - turn this on to use the contributor edit form
                setAnchorEl(null);
            }
        };

        const ITEM_HEIGHT = 32;

        return (
            <StyledExpandableTags>
                <StyledChipCounter
                    aria-haspopup="true"
                    variant="contained"
                    disableElevation
                    onClick={handleClick}
                >
                    {text}
                </StyledChipCounter>

                <StyledMenu
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 5.5,
                        },
                    }}
                    transformOrigin={{
                        horizontal: "right",
                        vertical: "top",
                    }}
                    anchorOrigin={{
                        horizontal: "right",
                        vertical: "bottom",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    {dataList.map((value, index) => {
                        return (
                            <MenuItem
                                key={index}
                                onClick={handleContributorClick}
                                disableRipple
                                sx={{
                                    "&:hover": {
                                        cursor: `${
                                            disabled ? "unset" : "pointer"
                                        }`,
                                        textDecoration: `${
                                            disabled ? "none" : "underline"
                                        }`,
                                    },
                                }}
                            >
                                {value}
                            </MenuItem>
                        );
                    })}
                </StyledMenu>
            </StyledExpandableTags>
        );
    },
);

export default ExpandableTags;
