import DefaultClientPermissions from "../DefaultClientPermissions";
import _ from "lodash";

export default function ClientPermissions(roles) {
    const { Role_PlatformAdmin, Role_RecordAdmin, Role_SuperAdmin } = roles;

    let permissions = DefaultClientPermissions(roles);

    //overrides
    permissions.reporting.looker = [
        Role_PlatformAdmin,
        Role_RecordAdmin,
        Role_SuperAdmin,
    ];

    // additions
    permissions.dataManager.view = _.concat(permissions.dataManager.view, [
        Role_PlatformAdmin,
    ]);

    permissions.dataManager.edit = _.concat(permissions.dataManager.edit, [
        Role_PlatformAdmin,
    ]);

    permissions.discovery.metadata.origination.release_type.populate = [
        Role_PlatformAdmin,
        Role_SuperAdmin,
    ];

    permissions.eidr = [];

    return permissions;
}
