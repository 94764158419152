import React from "react";
import { observer } from "mobx-react";
import _ from "lodash";
import "./_styles.scss";
import InitializeContainer from "./views/Initialize.container"
import EmptyState from "./views/EmptyState.view"
import AncillariesView from "./views/Ancillaries.view"
import Boilerplate from "../../components/RecordBody/views/Boilerplate";
import { InitializePropsInterface } from "./interfaces"

const Ancillaries = observer((props: InitializePropsInterface) => {
    return (
        <InitializeContainer {...props}>
            <Boilerplate
                title="Ancillaries"
                className="fabric-ancillaries"
                lastSection={true}>

                <EmptyState />
                <AncillariesView />
                
            </Boilerplate>
        </InitializeContainer>
    );
});

export default Ancillaries;
