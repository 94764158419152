/* eslint-disable @typescript-eslint/no-unused-vars */
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useMemo } from "react";
import { CoreTableGridColDef } from "../../types";
import { RenderContributorNameCell } from "./RenderContributorNameCell";

const RenderCell = (params: GridRenderCellParams<string>) => {   

    const elem = useMemo(
        () => (
            <RenderContributorNameCell
                row={params?.row}
                value={params?.value}
            />
        ),
        [params?.value, params?.row?.ranking],
    );
    return elem;
};


export const ContributorNameCol: CoreTableGridColDef = {
    headerName: "Contributor",
    field: "contributorName",
    // width: 166,
    flex: 1,
    minWidth: 290, // 290,
    renderCell: (params: GridRenderCellParams<string>) => {
        return <RenderCell {...params} />;
    },
    // renderCell: (params: GridRenderCellParams<string>) => {
    //     const { value, row } = params;
    //     return <RenderContributorNameCell row={row} value={value} />;
    // },
};
