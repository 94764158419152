import { Chip } from "@mui/material";
import { styled } from "@mui/material/styles";

export type AwardsTableChipsProps = {
    value: string | undefined;
    variant:
        | "awards-winner"
        | "awards-nominated"
        | "awards-shortlisted"
        | "awards-custom";
};

// TODO: convert px to rem
// TODO: extract custom styles to a styled BaseChip componnet
export const AwardsWinnerChip = styled(Chip)(() => ({
    background: "linear-gradient(268.42deg, #FAE6AF 0.89%, #E8BD70 99.78%)",
    color: "#41351C",
}));

export const AwardsNominatedChip = styled(Chip)(() => ({
    display: "flex",
    background: "linear-gradient(90deg, #A6A6A6 0%, #DEDEDE 77.08%)",
    color: "#41351C",
}));

export const AwardsShortlistedChip = styled(Chip)(() => ({
    background: "linear-gradient(90deg, #E19133 0%, #FFB368 73.96%)",
    color: "#41351C",
}));

export const AwardsCustomChip = styled(Chip)(() => ({
    background: "#C4D8F6",
    color: "#1E3E6F",
}));

export const BlueChip = styled(Chip)(() => ({
    background: "rgba(40, 108, 210, 0.10)",
    color: "#144794",
}));

export const GreyChip = styled(Chip)(() => ({
    background: "#DEDEDE",
    color: "#494949",
}));

export const YellowChip = styled(Chip)(() => ({
    background: "rgba(249, 152, 7, 0.20)",
    color: "#773401",
}));
