import { accessTypeCreate, accessTypeDelete, accessTypeUpdate } from "../sectionOptions"
import { OptionInterface } from "../../interfaces"
import { defaultSectionOptions } from "./index"

export const platformManagementOption: OptionInterface = {
    name: "Platform Management",
    value: "platformManagement",
    path: "platformManagement",
    children: [],
};

export const userRegistrationsAndPermissionsOption: OptionInterface = {
    name: "user registrations and permissions",
    value: "userRegistrationsAndPermissions",
    path: "tab.userRegistrationsAndPermissions",
    children: [],
};

export const dataSetsAndListsOption: OptionInterface = {
    name: "data sets and lists",
    value: "dataSetsAndLists",
    path: "tab.dataSetsAndLists",
    children: [],
};

export const dataManagerOption: OptionInterface = {
    name: "data sets",
    value: "dataManager",
    path: "tab.dataManager",
    children: [],
};

export const catalogsOption: OptionInterface = {
    name: "catalogs",
    value: "catalogs",
    path: "tab.catalogs",
    children: [],
};

export const imageUploaderOption: OptionInterface = {
    name: "Image Uploader",
    value: "imageUploader",
    path: "tab.imageUploader",
    disabledAccessTypes: [accessTypeCreate, accessTypeUpdate, accessTypeDelete],
};

export const dataIngestOption: OptionInterface = {
    name: "data ingest",
    value: "dataIngest",
    path: "tab.dataIngest",
    children: [],
    ...defaultSectionOptions,
};

export const contributorsOption: OptionInterface = {
    name: "Contributors",
    value: "contributors",
    path: "tab.contributors",
    children: [],
};