import _ from "lodash";

const GetFieldValue = (props) => {
    const { fieldSettings = {}, sourceRecord = {} } = props;

    const { src = [] } = fieldSettings;

    let sourceRecordFieldData = null;
    for (let i = 0; i <= src.length; i++) {
        sourceRecordFieldData = _.get(sourceRecord, src[i]);
        if (
            sourceRecordFieldData &&
            (!_.isEmpty(sourceRecordFieldData) ||
                !isNaN(parseFloat(sourceRecordFieldData)))
        )
            break;
    }

    return sourceRecordFieldData;
};

export default GetFieldValue;
