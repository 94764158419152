import _ from "lodash";
import GetRecordData from "./GetRecordData";
import DeleteRecordValidation from "./Validation/DeleteRecordValidation";
import GetDisplayTitle from "./GetDisplayTitle";
import HandleRecordDeleteRequest from "./HandleRecordDeleteRequest";

const HandleRecordDelete = (props) => {
    const { state = {}, copy = {} } = props;

    const { set } = state;

    const record = GetRecordData({
        state,
    });

    const { titles = [], meta_id, belong_to_lineup = false } = record;

    const validationNotification = DeleteRecordValidation({
        record,
    });

    if (!_.isEmpty(validationNotification)) {
        set({
            notification: {
                ...validationNotification,
                status: "alert",
            },
        });

        return;
    }

    const displayTitle = GetDisplayTitle({
        titles,
    });

    let description =
        copy.deleteRecordDescription ||
        "Are you sure you want to delete this record?";
    if (displayTitle) {
        description = `Are you sure you want to delete ${
            displayTitle.title || "this record"
        }?`;
        if (belong_to_lineup)
            description += `<div>Warning - This collection is being used in a lineup</div>`;
    }

    set({
        notification: {
            title: copy.deleteRecord || "Delete Record",
            description: description,
            status: "alert",
            confirm: HandleRecordDeleteRequest.bind(this, {
                meta_id,
                state,
            }),
        },
    });
};

export default HandleRecordDelete;
