import React from "react";
import DoubleTags from "../../DoubleTags";
import moment from "moment";
import Clients from "../../Clients";
import _ from "lodash";

export default class CreatedByTags extends React.Component {
    constructor(props) {
        super(props);

        this.setData();
    }

    setData() {
        const {
            created_at,
            created_by,
            created_by_name,
            copy = {},
            styles = {},
            leftText,
            color = "#9a9a9a",
            fontSize,
            newRecord,
        } = this.props;

        this.created_at = created_at;
        this.created_by = created_by;
        this.created_by_name = created_by_name;
        this.copy = copy;
        this.styles = styles;
        this.color = color;
        this.fontSize = fontSize;

        // formatedDate
        let formatedDate = moment(created_at).format("YYYY.MM.DD • HH:mm");

        if (Clients.isClientEpix() && !_.isEmpty(created_at)) {
            formatedDate = moment(created_at).format("M/D/YYYY HH:mm");
        }

        if (formatedDate) {
            this.created_at = formatedDate;
        }

        // created_by
        if (!created_by) {
            this.created_by_name = copy.api || "API";

            if (created_by_name) {
                this.created_by_name = `${
                    copy.api || "API"
                } • ${created_by_name}`;
            }

            if (newRecord) {
                this.created_by_name = "";
            }
        }

        //leftText
        this.leftText = copy.created || "Created";

        if (leftText) {
            this.leftText = leftText;
        }

        if (newRecord) {
            this.leftText = "";
        }
    }

    render() {
        const {
            created_at,
            created_by_name,
            fontSize,
            styles,
            leftText,
            color,
        } = this;

        return (
            <DoubleTags
                leftText={leftText}
                centerText={created_at}
                rightText={created_by_name}
                leftTagStyles={{
                    background: color,
                    color: "white",
                    borderColor: color,
                }}
                centerTextTransparent={true}
                rightTextTransparent={true}
                styles={styles}
                fontSize={fontSize}
            />
        );
    }
}
