import React from "react";
import SectionContentBoilerplate from "../../../Boilerplates/SectionContentWrapper";
import { useStore } from "../../state/context";
import ScheduleContainer from "./ScheduleContainer";
import "../styles/_styles.scss";
import _ from "lodash";
import { HeaderTags } from "../config";
import { useStore as useGlobalStore } from "../../../Global/store/store";
import { SCHEDULING_SYSTEMS } from '../../../../models/RecordBuilder'
import BuilderField from '../../../../models/RecordBuilder/Field.interface'
import BuilderError from "../../../RecordBuilder/FetchError";

const PlatformSchedulingBuilder: React.FC = () => {
    const [store] = useStore()
    const [globalStore] = useGlobalStore()

    const { platformScheduling = [], loadingBuilderConfig = true } = store

    const groupsBySchedulingSystem: any[] = _.groupBy(
        platformScheduling,
        "scheduling_system",
    )

    const {
        recordBuilder,
        recordBuilderError = false,
    } = globalStore || {}
    
    const systemConfig = 
        (system: string): BuilderField | undefined => recordBuilder[SCHEDULING_SYSTEMS]?.find(item => item.key == system)

    return(
        <div className="platform-scheduling-builder">
            {recordBuilderError && <BuilderError />}

            {!recordBuilderError && !_.isEmpty(platformScheduling) &&
                Object.keys(groupsBySchedulingSystem).map(
                    (schedulingSystem: string, index: number) => {
                        return (
                            <SectionContentBoilerplate
                                header={HeaderTags(systemConfig(schedulingSystem)?.displayName || "System")}
                                containers={[
                                    <ScheduleContainer
                                        data={groupsBySchedulingSystem[schedulingSystem]}
                                    />,
                                ]}
                                key={index}
                            />
                        );
                    },
                )
            }        
        </div>
    )
}

export default PlatformSchedulingBuilder