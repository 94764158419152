import React from "react";
import "./styles/_no_preview.scss"

interface NoPreviewAvailableInterface {
    extension: string
}

const NoPreviewAvailable = ({ extension }: NoPreviewAvailableInterface) => {
    return (
        <div className="no-preview-available">
            <svg width="32" height="43" viewBox="0 0 33 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M27.9329 9.84959C26.8391 9.84853 25.7905 9.41345 25.0173 8.63987C24.2441 7.8663 23.8095 6.81749 23.809 5.72376V0H5.38181C3.95447 0 2.58558 0.566998 1.5763 1.57628C0.567023 2.58556 0 3.95445 0 5.38178V37.8244C0 39.2518 0.567023 40.6206 1.5763 41.6299C2.58558 42.6392 3.95447 43.2062 5.38181 43.2062H27.5009C28.9282 43.2062 30.2971 42.6392 31.3064 41.6299C32.3156 40.6206 32.8827 39.2518 32.8827 37.8244V9.84959H27.9329Z" fill="#727272"/>
                <path d="M10.0095 26.1609C10.0095 26.1609 8.70758 23.395 10.3935 20.6951C10.3935 20.6951 10.8915 22.1471 11.3015 22.0531C11.3015 22.0531 10.7795 19.9712 11.1635 19.3552C11.1635 19.3552 12.8274 16.9693 14.6633 15.6794C14.6633 15.6794 14.7233 17.0973 15.1553 17.2133C15.1553 17.2133 15.0873 16.3373 15.6973 15.0134C15.6973 15.0134 18.6572 12.7335 23.597 12.9415C23.597 12.9415 21.647 15.9613 20.5511 17.9972C19.9717 19.0966 19.3348 20.1647 18.6432 21.1971C18.6432 21.1971 17.1912 22.0451 16.2433 21.9651C16.2433 21.9651 17.0432 22.5931 17.9112 22.2831C17.9112 22.2831 15.7013 26.2829 11.4135 26.7869C11.4135 26.7869 10.6135 28.4328 10.4535 29.8748C10.4535 29.8748 9.65357 30.3587 9.27759 30.2747C9.27759 30.2747 11.3515 22.3091 18.4212 16.5373C18.4312 16.5373 14.3554 17.6153 10.0095 26.1609Z" fill="#4D4D4D"/>
                <path d="M32.8827 9.84959H27.9329C26.8391 9.84853 25.7905 9.41345 25.0173 8.63987C24.2441 7.8663 23.8095 6.81749 23.809 5.72376V0L32.8827 9.84959Z" fill="#8B8B8B"/>
            </svg>
            
            <div className="extension">
                .{extension}
            </div>

            <span>No Preview Available</span>
        </div>
    )
}

export default NoPreviewAvailable