export const CLIENT_MANAGED_DESCRIPTORS = "client_managed_descriptors";
export const CLIENT_MANAGED_LANGUAGES = "client_managed_languages";
export const CLIENT_MANAGED_COUNTRIES = "client_managed_countries";
export const CLIENT_MANAGED_ASSET_COMPLIANCES =
    "client_managed_asset_compliances";
export const CLIENT_MANAGED_ASSET_CERTIFICATIONS =
    "client_managed_certifications";
export const CLIENT_MANAGED_RIGHTS_LICENSORS =
    "client_managed_rights_licensors";
export const CLIENT_MANAGED_RIGHTS_PLATFORMS =
    "client_managed_rights_platforms";
export const CLIENT_MANAGED_CAST_AND_CREW = "client_managed_cast_and_crew";
export const CLIENT_MANAGED_PRODUCTION_QUOTAS =
    "client_managed_production_quotas";
export const CLIENT_MANAGED_PROVENANCES = "client_managed_provenances";
export const CLIENT_MANAGED_RIGHTS_VERSIONS = "client_managed_rights_versions";
export const CLIENT_MANAGED_RECORD_STATUSES = "client_managed_record_statuses";
export const CLIENT_MANAGED_RELEASE_DATE_TYPES =
    "client_managed_release_date_types";
export const CLIENT_MANAGED_TERRITORIES = "client_managed_territories";
export const CLIENT_MANAGED_SUPPLIERS = "client_managed_suppliers";
export const CLIENT_MANAGED_LIBRARY_PREFIX = "client_managed_library_prefix";
export const CLIENT_MANAGED_CONTENT_TYPES = "client_managed_content_types";
export const CLIENT_MANAGED_PURCHASE_TYPES = "client_managed_purchase_types";
export const CLIENT_MANAGED_ENHANCED_FIRSTLOOK_SERIES =
    "client_managed_enhanced_firstlook_series";
export const CLIENT_MANAGED_BRANDING_TYPE_NAMES =
    "client_managed_branding_type_names";
export const CLIENT_MANAGED_TARGET_DEMOS = "client_managed_target_demos";
export const CLIENT_MANAGED_VIEWERSHIPS = "client_managed_viewerships";
export const CLIENT_MANAGED_MEDIAN_AGES = "client_managed_median_ages";
export const CLIENT_MANAGED_SERIES_NETWORKS = "client_managed_series_networks";
export const CLIENT_MANAGED_SP_REVIEW_NAMES = "client_managed_sp_review_names";
export const CLIENT_MANAGED_SP_REVIEW_CAUSES =
    "client_managed_sp_review_causes";
export const CLIENT_MANAGED_RIGHTS_HIATUS_TYPES =
    "client_managed_rights_hiatus_types";
export const CLIENT_MANAGED_PROGRAM_DIRECTORS =
    "client_managed_program_directors";
export const CLIENT_MANAGED_PROGRAM_STATUSES =
    "client_managed_program_statuses";
export const CLIENT_MANAGED_PROGRAM_USAGES = "client_managed_program_usages";
export const CLIENT_MANAGED_PRIMARY_PROGRAM_USAGES =
    "client_managed_primary_program_usages";
export const CLIENT_MANAGED_TAXONOMY = "client_managed_taxonomy";
export const CLIENT_MANAGED_PROGRAM_SUBTYPES =
    "client_managed_program_subtypes";
export const CLIENT_MANAGED_PROGRAM_BRANDING =
    "client_managed_program_branding";
export const CLIENT_MANAGED_RECORD_TYPES = "client_managed_record_types";
export const CLIENT_MANAGED_RECORD_TYPES_WITH_CATEGORIES = "client_managed_record_types_with_categories";
export const CLIENT_MANAGED_CHANNEL_ORIGIN = "client_managed_channel_origin";
export const CLIENT_MANAGED_ACTION_TYPE = "client_managed_action_type";
export const CLIENT_MANAGED_RELEASE_TYPES = "client_managed_release_types";

export const ASSET_COMPLIANCES = "asset_compliances";
export const ASSET_CERTIFICATIONS = "asset_certifications";
export const LANGUAGE_KEY_PRIMARY_LANGUAGE = "primary_language";
export const SUBTITLES = "subtitles";
export const DUBBING_TRACKS = "dubbing_tracks";
export const ADDITIONAL_SPOKEN_LANGUAGES = "additional_spoken_languages";
export const IMAGES_LANGUAGES = "images";
export const CAST_AND_CREW = "cast_and_crew";
export const AWARDS = "awards";
export const TITLES_AND_SYNOPSES = "titles_and_synopses";
export const RIGHTS_PLATFORMS = "rights_platforms";
export const RIGHTS_PLATFORMS_FULL = "rights_platforms_full";
export const TITLES_AND_SYNOPSES_STATUS = "regional_item_statuses";

export const DESCRIPTORS_GENRES = "genres";
export const DESCRIPTORS_SUB_GENRES = "sub_genres";
export const DESCRIPTORS_MOODS = "moods";
export const DESCRIPTORS_KEYWORDS = "keywords";
export const CLIENT_MANAGED_VERSIONS = "client_managed_versions";

export const CLIENT_MANAGED_APPROVAL_GROUPS = "client_managed_approval_groups";
export const CLIENT_MANAGED_APPROVAL_STATUSES = "client_managed_approval_statuses";
export const CLIENT_MANAGED_APPROVAL_JUSTIFICATIONS = "client_managed_approval_justifications";

export const CLIENT_MANAGED_EPISODE_TYPE = "client_managed_episode_type";
export const CLIENT_MANAGED_AMC_DESCRIPTION_PREFIXES = "client_managed_amc_description_prefixes";
export const CLIENT_MANAGED_PREMIER_PLATFORM = "client_managed_premier_platform";
export const CLIENT_MANAGED_AMC_PREMIER_PLATFORM = "client_managed_amc_premier_platform";
export const CLIENT_MANAGED_PRODUCTION_STUDIOS = "client_managed_production_studios";
export const CLIENT_MANAGED_PLATFORMS = "client_managed_platforms";
export const CLIENT_MANAGED_TITLES_AND_SYNOPSES_STATUS = "client_managed_titles_and_synopses_status"