import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "./store/rootStore";
import RootContainer from "./view/containers/RootContainer";
import ActiveCreditsContainer from "./view/containers/ActiveCredits.container";
import InactiveCreditsContainer from "./view/containers/InactiveCredits.container";
import { CreditsV3PropsInterface } from "./credits.interface";
import { originalCreditList } from "./config";
import { isEmpty } from "./utils/helpers";
import "./styles/_styles.scss";

const CreditsV3: React.FC<CreditsV3PropsInterface> = observer((props) => {
    const {
        disabled,
        script,
        defaultScript,
        creditLists,
        credits,
        handleFormChange = () => {},
        cmsData = { clientFeatures: {}, clientDataStructure: {} },
    } = props;

    const rootStore = useStores();
    const { creditListsStore, creditsStore } = rootStore || {};

    useEffect(() => {
        rootStore.initialize({
            handleFormChange,
            scriptValue: script,
            defaultScriptValue: defaultScript,
            tableSortActive: false,
            cmsData: cmsData,
        });

        creditsStore.initialize({ credits });

        creditListsStore.initialize({
            creditLists: !isEmpty(creditLists)
                ? creditLists
                : [originalCreditList],
        });
    }, []);

    useEffect(() => {
        rootStore.setEditStatus(disabled);
        creditsStore.refreshTableData();
    }, [disabled]);

    return (
        <React.Fragment>
            <div className="credits-v3">
                <RootContainer>
                    <ActiveCreditsContainer />
                    <div
                        style={{
                            width: "100%",
                            height: "0.0625rem",
                            backgroundColor: "#E6E6E6",
                            margin: ".825rem 0 0 0",
                        }}
                    ></div>
                    <InactiveCreditsContainer />
                </RootContainer>
            </div>
        </React.Fragment>
    );
});

export default CreditsV3;
