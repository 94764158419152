import { searchTypesConfig } from "../../features/RecordsSearch/config/searchTypes";

export const DEFAULT_PER_PAGE = 50;
export const DEFAULT_TARGETS = "original_title,meta_id";
export const DEFAULT_META_ID_TARGET = "meta_id";

export const RECORD_SERVICE_V2_BASE_URL = process.env.RECORD_SERVICE_V2_URL;

export const RECORD_SERVICE_V2_ENDPOINTS = {
    RECORDS: "/records",
};

export const ALL_TARGETS = {
    key: "all_targets",
    text: "All Targets",
    children: [],
};

export const API_ERROR_SEARCH_TARGETS_REQUIRED =
    "At least one search target must be provided";
export const API_ERROR_UNEXPECTED = "An unexpected error has occured";
export const API_ERROR_UNAUTHORIZED = "Unauthorized";
export const API_ERROR_BAD_REQUEST = "Invalid request";
export const API_ERROR_REQUEST_ABORTED = "Request was aborted";

export const getUrl = (url: string, endpoint: string) => `${url}${endpoint}`;

export const getSearchUrl = (searchTerm: string): string =>
    `/${searchTypesConfig.records.location}?searchTerm=${searchTerm}&selected=${searchTerm}&selectedTargets=${DEFAULT_META_ID_TARGET}`
