import { CircularProgress, Grid } from "@mui/material";

// TODO: add types
const LoadingTaskManagerScreen = ({ color, backgroundColor }) => {
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            width="100vw"
            sx={{
                color,
                backgroundColor,
                margin: "-24px",
            }}
        >
            <Grid item>
                <CircularProgress size="5rem" sx={{ color: "#373737" }} />
            </Grid>
        </Grid>
    );
};
LoadingTaskManagerScreen.defaultProps = {
    color: "rgba(149, 149, 149, 1)",
    backgroundColor: "#545454",
};

export default LoadingTaskManagerScreen;
