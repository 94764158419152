import React from "react";
import _ from "lodash";
import ActionButton from "../../../CustomSelect/ActionButton";
import TagSimple from "../../../Tag/simple";
import TagsTrack from "../../../TagsTrack";
import Settings from "../../../Settings";
import AssetStateColorMap from "./AssetStateColorMap";
import FileHoverSection from "./FileHoverSection";

const RenderAudioAndSubtitleLeftContent = (props) => {
    const { item = {}, background } = props;

    const {
        language,
        language_code,
        order_placed_on,
        bgColor = "",
        status = null,
        placeholder = "",
    } = item;

    let text = language || language_code;
    if (language && language_code) text = language + " • " + language_code;

    let statusColors =
        !_.isEmpty(status) &&
        AssetStateColorMap.subtitleStatuses.hasOwnProperty(status.toLowerCase())
            ? AssetStateColorMap.subtitleStatuses[status.toLowerCase()]
            : AssetStateColorMap.subtitleStatuses.empty;

    return (
        <div className="compliance-track-left-content">
            <TagSimple
                text={text}
                className="compliance-audio-subtitle-language"
                background={
                    bgColor ? bgColor : order_placed_on ? "#d2d2d2" : background
                }
                color="#787777"
            />
            {/*Dont render if placeholder null*/}
            {placeholder !== null && (
                <TagSimple
                    text={(placeholder
                        ? placeholder
                        : status
                        ? status
                        : "empty"
                    ).toUpperCase()}
                    className="compliance-audio-subtitle-delivery-order-status"
                    background={statusColors.background}
                    color={statusColors.text}
                    styles={{ marginRight: "0.3125rem" }}
                />
            )}

            {order_placed_on && (
                <TagSimple
                    text={`Placeholder • ${order_placed_on}`}
                    className="compliance-audio-subtitle-language-placeholder"
                    background="#bcbcbc"
                    color="#7e7d7d"
                />
            )}
        </div>
    );
};

const RenderAudioRightContent = (props) => {
    const { item = {}, options } = props;

    const { order_placed_on, format, bit_depth, sample_rate } = item;

    const background = "#d2d2d2";
    const disabledBackground = "#bcbcbc";

    const tagProps = {
        styles: { marginRight: "0.3125rem" },
        color: "#787777",
    };

    return (
        <div className={"compliance-track-right-content"}>
            <TagSimple
                text={!_.isEmpty(format) ? format : "-"}
                className="compliance-audio-format"
                background={format ? background : disabledBackground}
                {...tagProps}
            />

            <TagSimple
                text={!_.isEmpty(bit_depth) ? bit_depth : "-"}
                className="compliance-audio-bit-depth"
                background={bit_depth ? background : disabledBackground}
                {...tagProps}
            />

            <TagSimple
                text={!_.isEmpty(sample_rate) ? sample_rate : "-"}
                className="compliance-audio-sample-rate"
                background={sample_rate ? background : disabledBackground}
                {...tagProps}
            />
        </div>
    );
};

const RenderSubtitleRightContent = (props) => {
    const { item = {}, options } = props;

    const { format, standard, order_placed_on } = item;

    const background = "#d2d2d2";
    const disabledBackground = "#bcbcbc";

    const tagProps = {
        styles: { marginRight: "0.3125rem" },
        color: "#787777",
    };

    return (
        <div className={"compliance-track-right-content"}>
            <TagSimple
                text={!_.isEmpty(format) ? format : "-"}
                className="compliance-subtitle-format"
                background={format ? background : disabledBackground}
                {...tagProps}
            />

            <TagSimple
                text={!_.isEmpty(standard) ? standard : "-"}
                className="compliance-subtitle-standard"
                background={standard ? background : disabledBackground}
                {...tagProps}
            />
        </div>
    );
};

const RenderAudioAndSubtitleActionButton = (props) => {
    const { options, styles = {} } = props;

    return (
        <ActionButton
            platformImage={true}
            label={false}
            disabled={_.isEmpty(options)}
            items={options}
            styles={{
                marginTop: "0.3125rem",
                height: "1.465rem",
                ...styles,
            }}
        />
    );
};

const AudioVideoTracks = (props) => {
    const { compliance = {}, send_to_ai = {}, clientFeatures = {} } = props;

    const { alternate_language_audio_tracks = [], subtitle_tracks = [] } =
        compliance;

    const { subs = [], dubs = [] } = send_to_ai;

    const leftStyles = { marginRight: 0, flex: "auto", maxWidth: "100%" };

    return (
        <div className="double-inline audo-tracks">
            <div>
                <span style={Settings.components.titleValue.title}>
                    Alternate Language Audio Tracks
                </span>

                {_.isEmpty(alternate_language_audio_tracks) && (
                    <TagsTrack
                        leftContent={
                            <TagSimple
                                text="No alternate language audio tracks present"
                                className="compliance-alternate-audio-tracks"
                                background="#bcbcbc"
                                color="#787777"
                            />
                        }
                        leftContentStyles={{
                            maxWidth: "100%",
                        }}
                    />
                )}

                {!_.isEmpty(alternate_language_audio_tracks) &&
                    Array.isArray(alternate_language_audio_tracks) &&
                    alternate_language_audio_tracks.map((item, index) => {
                        return (
                            <FileHoverSection
                                clientFeatures={clientFeatures}
                                item={item}
                            >
                                <TagsTrack
                                    key={index}
                                    leftContent={
                                        <div
                                            className={
                                                "compliance-track-composed"
                                            }
                                        >
                                            <RenderAudioAndSubtitleLeftContent
                                                item={item}
                                                background={`${
                                                    item.bgColor || "#7efbfc"
                                                }`}
                                            />
                                            <RenderAudioRightContent
                                                item={item}
                                                options={dubs}
                                            />
                                        </div>
                                    }
                                    contentStyles={{ padding: "0 0.3125rem" }}
                                    rightContent={
                                        <RenderAudioAndSubtitleActionButton
                                            options={dubs}
                                            styles={{ marginTop: "0" }}
                                        />
                                    }
                                    rightContentStyles={{
                                        alignItems: "center",
                                    }}
                                    leftContentStyles={leftStyles}
                                    styles={{
                                        marginTop:
                                            index !== 0 ? "0.3125rem" : 0,
                                    }}
                                    className="compliance-altarnate-audio-track"
                                />
                            </FileHoverSection>
                        );
                    })}
            </div>

            <div>
                <span style={Settings.components.titleValue.title}>
                    Subtitle Tracks
                </span>

                {_.isEmpty(subtitle_tracks) && (
                    <TagsTrack
                        leftContent={
                            <TagSimple
                                text="No subtitle tracks present"
                                className="compliance-subtitle-tracks"
                                background="#bcbcbc"
                                color="#787777"
                            />
                        }
                        leftContentStyles={{
                            maxWidth: "100%",
                        }}
                    />
                )}

                {!_.isEmpty(subtitle_tracks) &&
                    Array.isArray(subtitle_tracks) &&
                    subtitle_tracks.map((item, index) => {
                        return (
                            <FileHoverSection
                                clientFeatures={clientFeatures}
                                item={item}
                            >
                                <TagsTrack
                                    key={index}
                                    leftContent={
                                        <div
                                            className={
                                                "compliance-track-composed"
                                            }
                                        >
                                            <RenderAudioAndSubtitleLeftContent
                                                item={item}
                                                background="#fbf666"
                                            />
                                            <RenderSubtitleRightContent
                                                item={item}
                                                options={subs}
                                            />
                                        </div>
                                    }
                                    rightContent={
                                        <RenderAudioAndSubtitleActionButton
                                            options={subs}
                                            styles={{ marginTop: "0" }}
                                        />
                                    }
                                    styles={{
                                        marginTop:
                                            index !== 0 ? "0.3125rem" : 0,
                                    }}
                                    maxWidth={true}
                                    contentStyles={{ padding: "0 0.3125rem" }}
                                    rightContentStyles={{
                                        alignItems: "center",
                                    }}
                                    leftContentStyles={leftStyles}
                                    className="compliance-subtitle"
                                />
                            </FileHoverSection>
                        );
                    })}
            </div>
        </div>
    );
};

export default AudioVideoTracks;
