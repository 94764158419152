import { CmsInterface } from "../../../components/Global/interfaces";
import {
    BulkImportConfigInterface,
    StaticHeaderInterface,
} from "../../NuvoFileUploader/nuvo.interface";
import { getViacomFieldsConfig } from "../config/fields.config";
import { Categories } from "../containers/record/tv-series/types/types";

export default (
    cmsData: CmsInterface,
): {
    standalone: BulkImportConfigInterface;
    tvShows: BulkImportConfigInterface;
} => {
    const { clientDataStructure = {} } = cmsData;
    const { bulkImportConfig = {} } = clientDataStructure;
    const { taskManagerConfig = {} } = bulkImportConfig;
    const { standalone = {}, tvShows = {} } = taskManagerConfig;

    return {
        standalone,
        tvShows,
    };
};

export const getBulkImportConfig2 = (): {
    standalone: BulkImportConfigInterface;
    tvShows: BulkImportConfigInterface;
} => {
    const fieldsConfig = getViacomFieldsConfig();

    const commonStaticHeaders: StaticHeaderInterface[] = [
        fieldsConfig.metaId.nuvoConfig,
        fieldsConfig.releaseYear.nuvoConfig,
        fieldsConfig.provenance.nuvoConfig,
        fieldsConfig.brand.nuvoConfig,
        fieldsConfig.countryOfOrigin.nuvoConfig,
        fieldsConfig.originalLanguage.nuvoConfig,
        fieldsConfig.displayTitle.nuvoConfig,
        // Collection fields are not editable via the form,
        // so they are added separately here
        {
            label: "Collection Title",
            key: "collection_title",
            columnType: "string",
        },
        {
            label: "Collection Meta ID",
            key: "collection_meta_id",
            columnType: "string",
        },
        {
            label: "Collection Category",
            key: "collection_category",
            columnType: "category",
            dropdownOptions: [
                {
                    label: "Title Collection",
                    value: "Collection",
                    type: "string",
                },
                {
                    label: "Franchise Collection",
                    value: "Franchise Collection",
                    type: "string",
                },
            ],
        },
    ];

    const commonAdditionalStaticHeaders = [
        ...Object.keys(fieldsConfig.ids)
            .map((key) => fieldsConfig.ids[key].nuvoConfig)
            .filter((config) => config && !config.global),
        ...Object.keys(fieldsConfig.descriptions)
            .map((key) => fieldsConfig.descriptions[key].nuvoConfig)
            .filter((config) => config && !config.global),
        ...Object.keys(fieldsConfig.titles)
            .map((key) => fieldsConfig.titles[key].nuvoConfig)
            .filter((config) => config && !config.global),
        ...Object.keys(fieldsConfig.other)
            .map((key) => fieldsConfig.other[key].nuvoConfig)
            .filter((config) => config && !config.global),
    ];

    const standalone: BulkImportConfigInterface = {
        headers: {
            static: [
                fieldsConfig.standaloneTitle.nuvoConfig,
                fieldsConfig.categories.standaloneCategory.nuvoConfig,
                ...commonStaticHeaders,
                ...commonAdditionalStaticHeaders,
            ],

            dynamic: {
                origination: {
                    enabled: true,
                    dataStructureEnabled: true,
                },
                record: {
                    enabled: true,
                },
                descriptors: {
                    enabled: true,
                    fields: ["genres", "sub_genres"],
                    list: "client_managed_descriptors",
                },
            },
        },
        mandatoryFields: [
            { category: "default", required_with: ["original_title"] },
        ],
    };

    const tvShowsNuvoFields: StaticHeaderInterface[] = [
        fieldsConfig.seasonNo.nuvoConfig,
        fieldsConfig.episodeNo.nuvoConfig,
        fieldsConfig.originalProductionNo.nuvoConfig,
        {
            label: "Parent Show Title",
            key: "manualLink_parent_show_title",
            columnType: "string",
        },
        {
            label: "Parent Show Meta ID",
            key: "manualLink_parent_show_meta_id",
            columnType: "string",
        },
        {
            label: "Parent Season Number",
            key: "manualLink_parent_season_number",
            columnType: "int",
        },
        {
            label: "Parent Season Meta ID",
            key: "manualLink_parent_season_meta_id",
            columnType: "string",
        },
        {
            label: "Parent Episode Number",
            key: "manualLink_parent_episode_number",
            columnType: "int",
        },
    ];
    const tvShows: BulkImportConfigInterface = {
        headers: {
            static: [
                fieldsConfig.tvShowTitle.nuvoConfig,
                fieldsConfig.categories.tvShowCategory.nuvoConfig,
                ...commonStaticHeaders,
                ...tvShowsNuvoFields,
                ...commonAdditionalStaticHeaders,
            ],
            dynamic: {
                origination: {
                    enabled: true,
                    dataStructureEnabled: true,
                },
                record: {
                    enabled: true,
                },
                descriptors: {
                    enabled: true,
                    fields: ["genres", "sub_genres"],
                    list: "client_managed_descriptors",
                },
            },
        },
        mandatoryFields: [
            { category: "default", required_with: ["original_title"] },
            {
                category: Categories.Season,
                required_with_conditionals: [
                    {
                        conditions: [
                            {
                                and: [
                                    {
                                        operator: "or",
                                        tests: [
                                            {
                                                column: "manualLink_parent_show_meta_id",
                                                test: "not_empty",
                                            },
                                            {
                                                column: "manualLink_parent_show_title",
                                                test: "not_empty",
                                            },
                                        ],
                                    },
                                    {
                                        operator: "and",
                                        tests: [
                                            {
                                                column: "original_title",
                                                test: "not_empty",
                                            },
                                            {
                                                column: "season_number",
                                                test: "not_empty",
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                category: Categories.Episode,
                required_with_conditionals: [
                    {
                        conditions: [
                            {
                                and: [
                                    {
                                        operator: "or",
                                        tests: [
                                            {
                                                column: "manualLink_parent_show_meta_id",
                                                test: "not_empty",
                                            },
                                            {
                                                column: "manualLink_parent_show_title",
                                                test: "not_empty",
                                            },
                                        ],
                                    },
                                    {
                                        operator: "or",
                                        tests: [
                                            {
                                                column: "manualLink_parent_season_meta_id",
                                                test: "not_empty",
                                            },
                                            {
                                                column: "manualLink_parent_season_number",
                                                test: "not_empty",
                                            },
                                        ],
                                    },
                                    {
                                        operator: "and",
                                        tests: [
                                            {
                                                column: "original_title",
                                                test: "not_empty",
                                            },
                                            {
                                                column: "episode_number",
                                                test: "not_empty",
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                category: Categories.Storyline,
                required_with_conditionals: [
                    {
                        conditions: [
                            {
                                and: [
                                    {
                                        operator: "or",
                                        tests: [
                                            {
                                                column: "manualLink_parent_show_meta_id",
                                                test: "not_empty",
                                            },
                                            {
                                                column: "manualLink_parent_show_title",
                                                test: "not_empty",
                                            },
                                        ],
                                    },
                                    {
                                        operator: "or",
                                        tests: [
                                            {
                                                column: "manualLink_parent_season_meta_id",
                                                test: "not_empty",
                                            },
                                            {
                                                column: "manualLink_parent_season_number",
                                                test: "not_empty",
                                            },
                                        ],
                                    },
                                    {
                                        operator: "and",
                                        tests: [
                                            {
                                                column: "original_title",
                                                test: "not_empty",
                                            },
                                            {
                                                column: "episode_number",
                                                test: "not_empty",
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                category: Categories.ModularEpisode,
                required_with_conditionals: [
                    {
                        conditions: [
                            {
                                and: [
                                    {
                                        operator: "or",
                                        tests: [
                                            {
                                                column: "manualLink_parent_show_meta_id",
                                                test: "not_empty",
                                            },
                                            {
                                                column: "manualLink_parent_show_title",
                                                test: "not_empty",
                                            },
                                        ],
                                    },
                                    {
                                        operator: "or",
                                        tests: [
                                            {
                                                column: "manualLink_parent_season_meta_id",
                                                test: "not_empty",
                                            },
                                            {
                                                column: "manualLink_parent_season_number",
                                                test: "not_empty",
                                            },
                                        ],
                                    },
                                    {
                                        operator: "and",
                                        tests: [
                                            {
                                                column: "original_title",
                                                test: "not_empty",
                                            },
                                            {
                                                column: "episode_number",
                                                test: "not_empty",
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    };

    return {
        standalone,
        tvShows,
    };
};
