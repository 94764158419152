import ValidateCountryOfOrigin from "./ValidateCountryOfOrigin";
import ValidateGenres from "./ValidateGenres";
import ValidateSubGenres from "./ValidateSubGenres";
import ValidateProperties from "./ValidateProperties";
import ValidateEidr from "./ValidateEidr";
import ValidateAssets from "./ValidateAssets";
import { validateTitles } from "../../models/Validation/regional/validateTitles";
import { validateSynopses } from "../../models/Validation/regional/validateSynopses";
import ValidateOriginationBase from "./builder/OriginationBase";
import ValidateCustomFields from "./builder/CustomFields";
import ValidateNumbering from "./builder/AlternativeNumbering"
import ValidateOrigination from "./ValidateOrigination";

export default function General(options = {}) {
    let result = {
        result: true,
        notification: {},
        validationKeys: [],
    };

    // Regional
    result = validateTitles({ result, options });
    result = validateSynopses({ result, options });

    // Country of Origin
    result = ValidateCountryOfOrigin(result, options);

    // Genrres
    result = ValidateGenres(result, options);

    // Sub Genrres
    result = ValidateSubGenres(result, options);

    // EIDR
    result = ValidateEidr(result, options);

    // Assets
    result = ValidateAssets(result, options);

    // Properties
    result = ValidateProperties(result, options);

    //Origination
    result = ValidateOrigination({ result, options });
    result = ValidateOriginationBase( result, options );
    result = ValidateCustomFields(result, options)
    result = ValidateNumbering(result, options);

    return result;
}
