import React from "react"
import Input from "../../interfaces/Input.interface";
import { CoreInputSwitch } from "../../../components/inputs/Switch/CoreInput.switch";
import { observer } from "mobx-react";
import { useStores } from '../../store/rootStore'

export const BooleanToggle = observer(({ field }: Input) => {
    const { formStore } = useStores()

    return (
        <div data-testid={"fabric-dynamic-form-boolean-toggle-input"}>
            <CoreInputSwitch
                path={field.key}
                label={field.label}
                labelColor="rgba(47, 47, 47, 0.87)"
                listItemStyles={{
                    paddingTop: "0;",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    ".MuiSwitch-root": {
                        marginLeft: "0"
                    },
                    ".MuiListItemText-root": {
                        marginBottom: "0.625rem"
                    },
                    ".MuiTypography-root": {
                        color: "rgba(47, 47, 47, 0.87);",
                        fontWeight: "500;",
                    },
                    ".Mui-disabled.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                        opacity: "0.37 !important",
                    },
                    ".Mui-checked.Mui-disabled": {
                        color: "white !important;"
                    }
                }}
                disabled={field.validation?.readOnly?.enabled || formStore.disabled}
                stackProps={{
                    direction: "column",
                    alignItems: "flex-start"
                }}
            />
        </div>
    )
})