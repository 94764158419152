const FetchConfig = (props) => {
    const {
        clientDataStructure = {},
        targetCategory = "",
        sourceCategory = {},
    } = props;

    const { inheritance = [] } = clientDataStructure;

    const recordInheritanceSettings = inheritance.find((item) => {
        const { targetCategory: targetConfigCategory, inheritFromCategory } =
            item;

        return (
            targetConfigCategory === targetCategory &&
            inheritFromCategory === sourceCategory
        );
    });

    return recordInheritanceSettings || {};
};

export default FetchConfig;
