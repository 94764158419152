import { Paper } from "@mui/material";
import React from "react";

export type CoreFormPaperProps = {
    minWidth?: string; // should be a rem value
    maxWidth?: string; // should be a rem value
    children: React.ReactNode;
};
export const CoreFormPaper = ({
    minWidth,
    maxWidth,
    children,
}: CoreFormPaperProps) => {
    return (
        <Paper
            variant="outlined"
            sx={{
                minWidth,
                maxWidth,
            }}
        >
            {children}
        </Paper>
    );
};
CoreFormPaper.defaultProps = {
    minWidth: "20rem",
    maxWidth: "30.125rem",
};
