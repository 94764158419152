import _ from "lodash";
import CategoryTags from "../../../components/CategoryTags";

const MultipleTitleSearch = (category) => {
    let result = true;

    switch (category) {
        case CategoryTags.Season.short:
            result = false;
            break;

        case CategoryTags.Episode.short:
            result = false;
            break;

        case CategoryTags.ConstructedEpisode.short:
            result = false;
            break;

        case CategoryTags.Storyline.short:
            result = false;
            break;
    }

    return result;
};

export default MultipleTitleSearch;
