import React from 'react'
import { observer } from "mobx-react";
import { useForm } from "react-hook-form";
import { isEmpty } from "lodash"
import { useStores } from "../../store/rootStore";
import ButtonBarContainer from "../../../../../containers/ButtonBarContainer"
import Button from "../../../../../components/Button"
import {
    CoreForm,
    CoreFormProvider,
    CoreFormThemeProvider,
} from "../../../../../core/Form/components/CoreForm";

const InactiveCreditListsBar = observer(() => {
    const { 
        inactiveCreditsStore,
    } = useStores();

    const form = useForm({
        defaultValues: {},
        mode: "onChange",
    });

    return (
        <>
            <CoreFormThemeProvider>
                <CoreFormProvider {...form}>
                    <CoreForm>
                        <ButtonBarContainer 
                            rightChildren={
                                <>    
                                    <Button
                                        value="Delete"
                                        onClick={() => inactiveCreditsStore.handleDeleteCreditsInBulk()}
                                        type="button"
                                        buttonSection={true}
                                        buttonDelete={true}
                                        disabled={isEmpty(inactiveCreditsStore.selected)}
                                    />     

                                    <span className="divider" />

                                    <Button
                                        value="Add to Credit List"
                                        onClick={() => inactiveCreditsStore.handleAddCreditsInBulkToCreditList()}
                                        type="button"
                                        buttonSection={true}
                                        disabledBackground="#E4E4E4"
                                        disabledColor="#ACACAC"
                                        disabled={isEmpty(inactiveCreditsStore.selected)}
                                    />
                                </>
                            }
                        />
                    </CoreForm>
                </CoreFormProvider>
            </CoreFormThemeProvider>
        </>
    )
})

export default InactiveCreditListsBar