import React from "react";
import Settings from "../Settings";
import "./styles/_styles.scss";

const TagsTrack = (props) => {
    const {
        title = "",
        styles = {},
        contentStyles = {},
        leftContentStyles = {},
        rightContentStyles = {},
        className,
        leftContent,
        rightContent,
        maxWidth,
    } = props;

    return (
        <div
            className={`meta-tag-track ${className ? className : ""}`}
            style={styles}
        >
            {title && (
                <span style={Settings.components.titleValue.title}>
                    {title}
                </span>
            )}

            <div className="track-content" style={contentStyles}>
                <div
                    style={{
                        maxWidth: maxWidth ? "50%" : "none",
                        ...leftContentStyles,
                    }}
                >
                    {leftContent}
                </div>

                <div style={rightContentStyles}>{rightContent}</div>
            </div>
        </div>
    );
};

export default TagsTrack;
