import React, { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { CoreDialog } from "../../../../core/Dialog/CoreDialog";
import { coreDialogProps, classPrefix } from "./utils/constants"

interface Props {
    handleClose: Function
}

const RecordBuilderError = observer(({ handleClose }: Props) => {
    const [open, setOpen] = useState<boolean>(true)

    return (
        <CoreDialog
            {...coreDialogProps}
            open={open}
            setOpen={(open) => setOpen(open)}
            title="Record Builder Error"
            buttons={{
                submit: {
                    handleOnSubmit: () => {
                        setOpen(false)
                        handleClose(false)
                    },
                    label: "Close",
                }
            }}
            data-testid={`${classPrefix}-rb-error`}
        >
            <Stack 
                direction="column" 
                spacing={4}>

                <Typography
                    sx={{
                        fontSize: "1rem",
                        letterSpacing: "unset",
                        lineHeight: "1.4",
                        color: "rgba(47, 47, 47, 0.87)",
                    }}
                >
                    There was an issue with fetching Record Builder config, please try again.
                </Typography>
            </Stack>
        </CoreDialog>
    )
})

export default RecordBuilderError