import React, { useEffect } from "react";
import "./_styles.scss";
import { useStore as globalStore } from "../../../components/Global/store/store";
import Boilerplate from "../../../components/RecordBody/views/Boilerplate";
import { useStores } from "./store/rootStore";
import Tag from "../../../components/Tag/v2";
import TagContainer from "../../../containers/TagContainer";
import RightsBuilderContainer from "../../../containers/BuilderExpandable";
import {
    itemDateLock,
    itemDisplayName,
    itemCustomSelect,
    itemActiveInactiveTag,
    itemLicencePreview,
} from "../../../containers/BuilderExpandable/containers/headerComponents";
import { observer } from "mobx-react";
import _ from "lodash";
import { paginationSize } from "./config";
import ContentLoading, {
    variantSmallLeft,
} from "../../../components/ContentLoading";
import FadeIn from "../../../components/TransitionGroup/FadeIn";
import PlaceholderText from "../../../components/PlaceholderText";
import { RIGHTS } from "../../../models/RecordBuilder";
import BuilderError from "../../../components/RecordBuilder/FetchError";
import { envTest } from "../../../components/Environment";
import testConfigResponse from "./test/config";

interface RightsPropsInterface {
    data: any;
    disabled: boolean;
    handleFormChange: (item: any) => void;
}

const Rights = observer((props: RightsPropsInterface) => {
    const { rightsStore } = useStores() || {};

    const [globalState] = globalStore() || [{}];

    const { disabled, handleFormChange = () => {} } = props;

    const { cmsData = {}, recordBuilder = {} } = globalState;

    useEffect(() => {
        rightsStore.initialize({
            handleFormChange,
            cmsData,
            data: props.data || [],
            configs: envTest 
                ? testConfigResponse 
                : recordBuilder[RIGHTS],
        });
    }, []);

    return (
        <Boilerplate
            title="Rights"
            className="meta-record-rights"
            lastSection={true}
        >
            <FadeIn>
                <div>
                    {rightsStore.loading && (
                        <ContentLoading
                            loadingDotsGrey={true}
                            variant={variantSmallLeft}
                        />
                    )}

                    {!rightsStore.loading && (
                        <>
                            {_.isEmpty(rightsStore.configs) && (
                                <BuilderError />
                            )}

                            {rightsStore.configs.map(
                                (config: any, index: number) => {
                                    const data: any =
                                        rightsStore?.data?.filter(
                                            (item: any) =>
                                                item.system == config.key,
                                        ) || [];

                                    return (
                                        <div key={index}>
                                            <TagContainer>
                                                <Tag
                                                    text="Rights Management System"
                                                    color="#626262"
                                                    background="#CECECE"
                                                    uppercase={true}
                                                />

                                                <Tag
                                                    text={config.displayName}
                                                    color="#878787"
                                                    background="#EDECED"
                                                    uppercase={true}
                                                />

                                                <Tag
                                                    text={
                                                        process.env.APP_ENV ||
                                                        "local"
                                                    }
                                                    color="#1C654D"
                                                    background="#99E4CC"
                                                    uppercase={true}
                                                />
                                            </TagContainer>

                                            {!_.isEmpty(data) && (
                                                <RightsBuilderContainer
                                                    disabled={disabled}
                                                    handleChange={rightsStore.handleChange.bind(
                                                        this,
                                                    )}
                                                    components={[
                                                        itemDateLock,
                                                        itemDisplayName,
                                                        itemCustomSelect,
                                                        itemActiveInactiveTag,
                                                        itemLicencePreview,
                                                    ]}
                                                    className="rights-builder"
                                                    headerClassName="rights-builder---header"
                                                    sections={config.sections}
                                                    data={data}
                                                    itemProps={{
                                                        actionsList: [
                                                            {
                                                                onClick: (
                                                                    item: any,
                                                                ) =>
                                                                    rightsStore.handleDelete(
                                                                        item,
                                                                    ),
                                                                text: "Delete",
                                                            },
                                                        ],
                                                    }}
                                                    pagination={true}
                                                    paginationSize={
                                                        paginationSize
                                                    }
                                                />
                                            )}

                                            {_.isEmpty(data) && disabled && (
                                                <PlaceholderText
                                                    text="None Added"
                                                    styles={{
                                                        marginTop: "1rem",
                                                    }}
                                                />
                                            )}
                                        </div>
                                    );
                                },
                            )}
                        </>
                    )}
                </div>
            </FadeIn>
        </Boilerplate>
    );
});

export default Rights;
