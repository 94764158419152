import React from "react";
import { GridRowParams } from "@mui/x-data-grid";
import { CoreTable } from "../../../../../core/Table/CoreTable";
import { observer } from "mobx-react";
import { useStores } from "../../store/rootStore";
import { CreditRowInterface } from "../../store/interfaces";
import { GridRowOrderChangeParams } from "@mui/x-data-grid-pro";
import { classPrefix } from "../../utils/constants";
import { defaultCreditsColumns } from "../../config/creditsTable.config";
import { ReorderCol } from "../../../../../core/Table/components/Reorder.column";
import { RenderActionsCol } from "../../../../../core/Table/components/RenderActions.column";
import { CheckboxCol } from "../../../../../core/Table/components/Checkbox.column";
import { CoreTableGridColDef } from "../../../../../core/Table/types";
import ActiveCreditListsBar from "./ActiveCreditListsBar.view"

const ActiveCreditsTable = observer(() => {
    const { 
        rootStore,
        activeCreditsStore,
        permissionsStore,
    } = useStores();

    const editModeActiveCreditsColumns: CoreTableGridColDef[] = [
        CheckboxCol,
        ...defaultCreditsColumns,
        ReorderCol,
        ...RenderActionsCol({
            edit: {
                disabled: !permissionsStore.userHasUpdatePermission,
                handleOnClick: (params: GridRowParams) => {
                    activeCreditsStore.setSelectedId(params?.row?.creditID)
                    rootStore.setShowCreditUpdateForm(true)
                }
            },
            delete: {
                disabled: !permissionsStore.userHasDeletePermission,
                handleOnClick: (params: GridRowParams) => activeCreditsStore.handleDeleteCredit(params),
            },
        })
    ]
    
    return (
        <>
            <ActiveCreditListsBar />

            <div className={`${classPrefix}-active-credits-table`} data-testid={`${classPrefix}-active-credits-table`}>
                <CoreTable<CreditRowInterface> 
                    sortingOrder={["asc", "desc"]}
                    columns={rootStore.disabled ? defaultCreditsColumns : editModeActiveCreditsColumns}
                    config={activeCreditsStore.tableConfig}
                    rows={activeCreditsStore.activeTableCredits}
                    onSelectionModelChange={(ids: string[]) => activeCreditsStore.setSelected(ids)}
                    hideFooterRowCount
                    hideFooterSelectedRowCount
                    onRowOrderChange={(params: GridRowOrderChangeParams) => {
                        const { oldIndex, targetIndex } = params;

                        const movedData = {
                            oldIndex,
                            newIndex: targetIndex,
                        };

                        activeCreditsStore.handleOrderCredits(movedData);
                    }}
                    loading={rootStore.isFetchingCredits}
                    onSortModelChange={(params) => {

                    }}    
                />
            </div>
        </>
    )
})

export default ActiveCreditsTable;