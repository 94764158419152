import React, { useState, useEffect, Fragment } from "react";
import _ from "lodash";
import settings from "../../../Settings";
import { CopyToClipboard } from "react-copy-to-clipboard";
import TransitionGroupFadeIn from "../../../TransitionGroup/FadeIn";

const FileHoverSection = (props) => {
    const { children, clientFeatures = {}, item = {} } = props;

    const { filename = "" } = item;

    const { assetsAudioVideoFileCopy = false } = clientFeatures;

    const [activeContainerHover, setActiveContainerHover] = useState(false);
    const [activeFilenameHover, setActiveFilenameHover] = useState(false);
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (copied) {
            setTimeout(() => setCopied(false), 2000);
        }
    }, [copied]);

    const isAvailableHover = !!(
        assetsAudioVideoFileCopy && !_.isEmpty(filename)
    );

    return (
        <div className={"assets-file-hover"}>
            {copied && (
                <TransitionGroupFadeIn>
                    <div className={"file-copied-label"}>
                        <img src={settings.images.checkWhiteSmall} />
                        Copied to Clipboard
                    </div>
                </TransitionGroupFadeIn>
            )}

            <div
                className={"transparent-hover-block"}
                style={{ display: !activeContainerHover ? "block" : "none" }}
                onMouseEnter={() => {
                    setActiveContainerHover(isAvailableHover);
                }}
            ></div>

            {activeContainerHover && (
                <div
                    className={"file-container"}
                    onMouseLeave={() => setActiveContainerHover(false)}
                >
                    <span
                        onMouseEnter={() => setActiveFilenameHover(true)}
                        onMouseLeave={() => setActiveFilenameHover(false)}
                        className={"filename-text"}
                    >
                        <CopyToClipboard
                            onCopy={() => setCopied(true)}
                            text={filename}
                        >
                            <span
                                style={{
                                    borderColor: activeFilenameHover
                                        ? "white"
                                        : "transparent",
                                }}
                            >
                                File&nbsp;Name:&nbsp;{filename}
                            </span>
                        </CopyToClipboard>
                        {activeFilenameHover && (
                            <img src={settings.images.linkWhite} />
                        )}
                    </span>
                </div>
            )}

            {children}
        </div>
    );
};

export default FileHoverSection;
