import React, { useRef, useState } from "react";
import { SUBTITLE_SECTION, AUDIO_SECTION } from "../section/AdditionalSection";
import Settings from "../../../../../Settings";
import CustomSelect from "../../../../../CustomSelect";
import { useStore } from "../../../state/context";
import setNotification from "../../../state/actions/notification";
import AssetUpdateForm from "../forms/updateForm";
import AssetInterface from "../interfaces/AssetInterface";
import {
    confirmAssetEdit,
    setEditAsset,
} from "../../../state/actions/assetEdit";
import EditDurationForm from "../../../../src/EditDurationForm";
import { confirmAssetDelete } from "../../../state/actions/assetDelete";
import AssetInformationCertificatesAdvisory from "../../../../src/AssetInformationCertificatesAdvisory";
import MenuIconBoilerPlate from "../../../../../Boilerplates/MenuIconBoilerplate";
import deepcopy from "deepcopy";
import AssetTypeInterface from "../interfaces/AssetTypeInterface";
import _ from "lodash";
import GetAssetTypeConfiguration from "../../../helpers/GetAssetTypeConfig";
import { Player } from "video-react";
import { featureUserRoleManagement } from "../../../../../../services/PermissionService";

type Props = {
    asset: AssetInterface;
    expandAll: boolean;
    setExpandAll: any;
};

type Action = {
    text: string;
    onClick: Function;
};

const Actions: React.FC<Props> = (props) => {
    const [store, dispatch] = useStore();

    const {
        disabled,
        editAsset,
        assets,
        cmsData,
        notification,
        configuration,
        options,
        permissionEdit,
        permissionDelete
    } = store;

    const { clientFeatures = {} } = cmsData;

    const { asset, setExpandAll = () => {}, expandAll = false } = props;

    const { type, media_url } = asset;

    const { assets: assetsFeatures = {} } = clientFeatures;

    const { denyEdit = false } = assetsFeatures;

    const editAssetRef = useRef();
    editAssetRef.current = editAsset;

    const assetTypeConfig: AssetTypeInterface | undefined =
        GetAssetTypeConfiguration(configuration, asset);
    const iconOption = _.get(assetTypeConfig, "options.icon") || {};

    const { enabled = false, code = "" } = iconOption;

    const playButton = _.isEmpty(media_url) 
        ? "play-button" 
        : "play-button-active"

    const mediaIconSrc = (enabled && !_.isEmpty(code)) 
        ? code 
        : playButton

    const notificationRef = useRef();
    notificationRef.current = notification;

    const [canSubmitDuration, setCanSubmitDuration] = useState<boolean>(false);

    const canSubmitDurationRef = useRef();
    canSubmitDurationRef.current = canSubmitDuration;

    const ConfirmDuration = (): void => {
        if (canSubmitDurationRef.current) {
            dispatch(confirmAssetEdit(editAssetRef.current, assets));
            dispatch(setNotification({}));
            return;
        }

        let notificationObject = deepcopy(notificationRef.current);
        notificationObject.errorMessage =
            "Version Duration Is Invalid. Format Should Be: 99:59:59:29!";
        dispatch(setNotification(notificationObject));
    };

    const PlayMedia = (): void => {
        if (!_.isEmpty(media_url)) {
            dispatch(
                setNotification({
                    html: <Player playsInline poster="" src={media_url} />,
                    disabled: false,
                    onClick: () => dispatch(setNotification({})),
                    contentNoPadding: true,
                    closeOnWrapperClick: true,
                    titlePadding: false,
                    status: false,
                    intercationStatus: false,
                    wrapperStyle: { maxWidth: "70%" },
                }),
            );
        }
    }

    const actionsList = {
        updateAction: {
            onClick: () =>
                dispatch(
                    setNotification({
                        html: <AssetUpdateForm asset={asset} />,
                        title: "Asset Information",
                        confirm: () => {
                            dispatch(
                                confirmAssetEdit(editAssetRef.current, assets),
                            );
                        },
                    }),
                ),
            text: "Update IDs & Technical Metadata",
        },
        editCertsAction: {
            onClick: () =>
                dispatch(
                    setNotification({
                        html: (
                            <AssetInformationCertificatesAdvisory
                                certifications={asset.certifications || []}
                                compliance={asset}
                                options={options}
                                clientFeatures={clientFeatures}
                                cmsData={cmsData}
                                handleUpdate={(certs) => {
                                    let assetCopy = Object.assign(
                                        {},
                                        { ...asset },
                                    );
                                    assetCopy.certifications =
                                        certs.certifications;
                                    dispatch(setEditAsset(assetCopy));
                                }}
                            />
                        ),
                        title: "Edit Certifications & Advisory",
                        confirm: () => {
                            dispatch(
                                confirmAssetEdit(editAssetRef.current, assets),
                            );
                        },
                    }),
                ),
            text: "Edit Certifications & Advisory",
        },
        editDurationAction: {
            onClick: () =>
                dispatch(
                    setNotification({
                        html: (
                            <EditDurationForm
                                asset={asset}
                                onChange={(data, isError) => {
                                    setCanSubmitDuration(!isError);
                                    if (!isError) {
                                        dispatch(
                                            setEditAsset(
                                                Object.assign({}, asset, {
                                                    duration: data,
                                                }),
                                            ),
                                        );
                                    }
                                }}
                            />
                        ),
                        title: "Duration",
                        confirm: () => ConfirmDuration(),
                    }),
                ),
            text: "Edit Duration",
        },
        deleteAction: {
            onClick: () =>
                dispatch(
                    setNotification({
                        html: (
                            <p className="asset-delete">
                                Are you sure you want to delete this asset?
                            </p>
                        ),
                        title: "Delete Asset",
                        confirm: () => {
                            dispatch(confirmAssetDelete(asset, assets));
                        },
                    }),
                ),
            text: "Delete Asset",
        },
    };

    let editActions: Array<Action> = [];

    if (!denyEdit) {
        editActions.push(actionsList.updateAction);
    }

    editActions.push(actionsList.editCertsAction);

    if (!denyEdit) {
        editActions.push(actionsList.editDurationAction);
    }

    editActions.push(actionsList.deleteAction);

    if (featureUserRoleManagement({ cmsData })) {
        editActions = []

        if (permissionEdit) {
            editActions.push(actionsList.updateAction);
            editActions.push(actionsList.editCertsAction);
            editActions.push(actionsList.editDurationAction);
        }

        if (permissionDelete) {
            editActions.push(actionsList.deleteAction);
        }
    }

    const isAdditionalSectionRow = !!(
        type == AUDIO_SECTION || type == SUBTITLE_SECTION
    );

    return (
        <div className="header-actions">
            <div className="menu action">
                <CustomSelect
                    disabled={isAdditionalSectionRow || disabled}
                    chevronImage={false}
                    background={"none"}
                    items={isAdditionalSectionRow ? [] : editActions}
                    customImageRight={<MenuIconBoilerPlate />}
                />
            </div>

            {type == AUDIO_SECTION && (
                <div className="play-button action" onClick={() => {}}>
                    <img
                        className="menu-dubs-icon"
                        src={`${Settings.images.path}/svg/dubs-icon.svg`}
                    />
                </div>
            )}

            {type == SUBTITLE_SECTION && (
                <div className="play-button action" onClick={() => {}}>
                    <img
                        className="menu-subs-icon"
                        src={`${Settings.images.path}/svg/subs-icon.svg`}
                    />
                </div>
            )}

            {type != AUDIO_SECTION && type != SUBTITLE_SECTION && (
                <div 
                    className={`play-button action ${!_.isEmpty(media_url) ? 'media-button-active' : ''}`}
                    onClick={(e) => {
                        e.stopPropagation();
                        PlayMedia();
                    }}>
                    <img
                        className="menu-play-icon"
                        src={`${Settings.images.path}/svg/${mediaIconSrc}.svg`}
                    />
                </div>
            )}

            <div
                className="expand-all action"
                onClick={(e) => {
                    e.stopPropagation();
                    setExpandAll(!expandAll);
                }}
            >
                <img
                    className="menu-arrow-icon"
                    src={`${Settings.images.path}/svg/${
                        expandAll ? "arrows-expand-all" : "arrows-collapse-all"
                    }.svg`}
                />
            </div>
        </div>
    );
};

export default Actions;
