import CurrentMonthYear from "../../components/Helpers/CurrentMonthYear";
import CurrentYear from "../../components/Helpers/CurrentYear";

const LineupManagerDefaultstate = {
    searchData: [],
    searchQuery: "",
    fetchingSearchData: false,
    editRecord: false,
    notification: {},
    recordData: {},
    originalRecord: {},
    options: {},
    cmsData: {},
    menuActive: "lineups",
    lineupPeriod: CurrentMonthYear(),
    cyclesYear: CurrentYear(),
};

export default LineupManagerDefaultstate;
