/* eslint-disable no-unused-vars */
import { GridColDef } from "@mui/x-data-grid-pro";
import React from "react";
import { DeepPartial, ValidationMode } from "react-hook-form";
import { StaticHeaderInterface } from "../../NuvoFileUploader/nuvo.interface";
import {
    AdditionalFields,
    AllFields,
    EpisodeFields,
    PrimaryFields,
    SeasonFields,
    SequenceShape,
    StandaloneFields,
    StorylineFields,
    TvShowFields,
} from "./clients/viacom/validation/allFieldsVS";
import { NuvoRecord } from "../containers/record/tv-series/types/nuvo.types";

// eslint-disable-next-line no-shadow
export enum CoreFormSectionVariants {
    None = "none",
    Accordion = "accordion",
}
export type CoreFormSection = {
    label: string;
    variant: CoreFormSectionVariants;
    rightAdornment?: string | React.ReactNode;
};

export type FieldObject<TData> = {
    path: keyof TData;
    component: React.ReactNode | null;
    tab: string | null;
    section: CoreFormSection;
    xs: 6 | 12;
    gridColDef: GridColDef<TData>;
    nuvoConfig?: StaticHeaderInterface & {
        serdes: {
            type: "basic" | "array" | "custom";
            dataType?: "string" | "number";
            deserializeFn?: (nuvoRecord: NuvoRecord) => unknown;
            serializeFn?: (data: unknown) => unknown;
            separator?: string; // Used only for array serdes
            unflatten?: boolean;
        };
        global?: boolean;
    };
};

type PathOf<T> = {
    [K in keyof T]: T[K] extends object ? K | `${K}.${PathOf<T[K]>}` : K;
}[keyof T];

export type FieldsConfig<T extends object> = {
    [key in PathOf<T>]: FieldObject<T>;
};

export type CoreFormTMConfig<TDataVT extends object> = {
    formTitle: string;
    fieldsConfig: FieldsConfig<TDataVT>;
    dialogSubmitLabel?: string;
    dialogCancelLabel?: string;
    defaultValues?: DeepPartial<TDataVT>;
    validationSchema: unknown; // TValidationSchema;
    onSubmit: (data: TDataVT) => void;
    validationMode?: keyof ValidationMode;
    adaptToCoreTable: (record: TDataVT) => TDataVT; // result is the same type as TData with the argument, but the notation is different: "titles.aka" instead of {titles: {aka: string}}
};

export type PrimaryVT = PrimaryFields;
export type AdditionalVT = AdditionalFields;
export type FieldsVT = PrimaryVT & AdditionalVT;
export type TvShowVT = TvShowFields;
export type SeasonVT = SeasonFields;
export type EpisodeVT = EpisodeFields;
export type ModularEpisodeVT = EpisodeFields;
export type StorylineVT = EpisodeFields;
export type ModularStorylineVT = StorylineFields;
export type AllFieldsVT = AllFields;
export type StandaloneVT = StandaloneFields;
export type SequenceVT = SequenceShape;
