const bbcLondonEV = [
    {
        meta_id: "AS00012564",
        asset_type: "Original Master",
        system_type: "Asset Management System",
        system_name: "Sony Media Backbone",
        system_environment: "Development",
        compliance_code: "ORIG",
        status: "placeholder created",
        eidr: "10.5240/63B8-F4DD-D11B-B584-3E2D-S",
        duration: "00.01.42.04",
        created_at: "03/14/2019 10:12:00",
        emea_id: "FE_883726133",
        latam_id: "002136544A/3883",
        apac_id: "MOV136544A/3883001",
        filename: "Philomena_Theatrical-Edit_EN.mov",
        resolution: "1920x1080",
        fps: "25",
        audio_format: "stereo",
        image_height: "1080",
        color_encoding: "4:4:4",
        jpeg_200_profile: "2k IMF Single ML4 SL2 400 Mbit/s",
        colorimetry: "P3 D65 / SMPTE ST 2084 (PQ)",
        pixel_bit_depth: "12-bit",
        mdcv_metadata: "SMPTE ST-2086",
        dynamic_metadata_for_color_volume_transformation: "Dolby VisionTM",
        sample_rate: "48 KHz",
        video_language: "ENG",
        audio_language: "ENG",
        asset_advisory: [],
        meta_users: ["sl@turner.com"],
        email_addresses: [
            "patrick.mccarthy@turner.com",
            "robert.cranfield@turner.com",
        ],
        alternate_language_audio_tracks: [
            {
                filename: "Philomena_Theatrical-Edit_Alt-Audio_French-FRE.mxf",
                format: "STEREO",
                bit_depth: "24-bit",
                sample_rate: "48 KHz",
                language: "French",
                language_code: "FRE",
                order_placed_on: "24.04.2020",
            },
        ],
        subtitle_tracks: [
            {
                filename:
                    "Philomena_Theatrical-Edit_English-Subtitles-EN-SDH.xml",
                format: "SDH",
                standard: "WebVTT",
                language: "French",
                language_code: "FRE",
                order_placed_on: "24.04.2020",
            },
        ],
        compliances: [
            {
                meta_id: "AS00012565",
                asset_type: "Normalised",
                system_type: "Asset Management System",
                system_name: "London EV",
                system_environment: "Development",
                compliance_code: "NORM",
                status: "placeholder created",
                emea_id: "FE_883726133",
                latam_id: "002136544A/3883",
                apac_id: "MOV136544A/3883001",
                eidr: "some-eidr",
                duration: "00.01.42.04",
                created_at: "03/14/2019 10:12:00",
                resolution: "1920x1080",
                fps: "25",
                audio_format: "stereo",
                image_height: "1080",
                color_encoding: "4:4:4",
                jpeg_200_profile: "2k IMF Single ML4 SL2 400 Mbit/s",
                colorimetry: "P3 D65 / SMPTE ST 2084 (PQ)",
                pixel_bit_depth: "12-bit",
                mdcv_metadata: "SMPTE ST-2086",
                dynamic_metadata_for_color_volume_transformation:
                    "Dolby VisionTM",
                sample_rate: "48 KHz",
                bit_depth: "24-bit",
                language: "French",
                language_code: "FRE",
                meta_users: ["sl@turner.com"],
                email_addresses: [
                    "patrick.mccarthy@turner.com",
                    "robert.cranfield@turner.com",
                ],
                certifications: [
                    {
                        country_code: "FRA",
                        rating: "17+",
                        system: "HBO",
                        advisory: [
                            "Strong Language",
                            "Violence & Gore",
                            "Drug References",
                            "Distrubing Content",
                        ],
                    },
                ],
                alternate_language_audio_tracks: [
                    {
                        filename:
                            "Philomena_Theatrical-Edit_Alt-Audio_French-FRE.mxf",
                        format: "STEREO",
                        bit_depth: "24-bit",
                        sample_rate: "48 KHz",
                        language: "French",
                        language_code: "FRE",
                        order_placed_on: "24.04.2020",
                    },
                ],
                subtitle_tracks: [
                    {
                        filename:
                            "Philomena_Theatrical-Edit_English-Subtitles-EN-SDH.xml",
                        format: "SDH",
                        standard: "WebVTT",
                        language: "French",
                        language_code: "FRE",
                        order_placed_on: "24.04.2020",
                    },
                ],
                territory: "South Pole",
            },
        ],
        territory: "North Pole",
    },
];

export default bbcLondonEV;

export const clientManagedRegionalCertifications = {
    Poland: {
        "Munich Internal": {
            ratings: [
                "Intern TAP",
                "Intern TAP 0",
                "Intern TAP 6",
                "Intern HAP",
                "Intern SPÄ",
                "Intern NAP",
            ],
            region_code: "POL",
            system_code: "MUNC",
        },
        "Ofcom Rating System": {
            ratings: ["U", "PG", "12A", "12", "15", "18", "R18"],
            advisories: ["Adult Nature"],
            schedule_restrictions: ["Post 7 hours"],
            region_code: "POL",
            system_code: "OFCOM",
        },
    }
}