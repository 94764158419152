import React, { useMemo } from "react";
import _ from "lodash";
import moment from "moment";
import "../styles/_doubleColumnContentStyles.scss";
import Input from "../../Input/basic";
import SplitColumns from "./SplitColumns";
import AutocompleteMultipleOneLineInput from "./AutocompleteMultipleOneLineInput";
import ContentFieldInterface from "./ContentField.interface";
import DatePickerInput from "../../Input/datepicker";
import LinkTooltip from "../../LinkTooltip";
import ClientManagedAutoCompleteMultipleOneLineInput from "./ClientManagedAutoCompleteMultipleOneLineInput";

type Props = {
    fields: Array<ContentFieldInterface>;
    disabled?: boolean;
    handleChange?: (key: string, value: any) => void;
    apiUrls?: {};
    values?: any;
    labelColumnStyles?: any
};

interface RenderItemsInterface {
    fields: Array<ContentFieldInterface>;
}

const INPUT_TYPES = ["text", "textarea", "string", "number", "duration"];
const SELECT_TYPES = ["array", "select", "selectmultiple", "boolean"];
const defaultPlaceholder: string = "-";

const DoubleColumnContent: React.FC<Props> = (props): JSX.Element => {
    const {
        fields,
        disabled = true,
        handleChange = () => {},
        apiUrls = {},
        values = {},
        labelColumnStyles = {}
    } = props;

    const doubledContenthalfs = useMemo(() => SplitColumns(fields), [fields]);

    const inputProps = {
        disabled,
        styles: {
            padding: "0.25rem",
            backgroundColor: "#F4F4F4",
            display: "flex",
            minHeight: "1.25rem",
            borderRadius: "0.188rem",
            color: "#4F4F4F",
            fontSize: "0.75rem",
            boxShadow: "none",
            borderFocus: "1px solid rgba(40, 108, 210, 0.45)",
        },
    };

    const GetInputValue = (field: ContentFieldInterface) => {
        const { value, type = "" } = field;

        if (!value && type.toLowerCase() !== "boolean") {
            return "";
        }

        switch (type.toLowerCase()) {
            case "number":
                return value;

            case "string":
            case "text":
            case "textarea":
            case "duration":
                return typeof value === "string" || typeof value == "number"
                    ? value
                    : "";

            case "datetime":
            case "date":
                const format = field.dateFormat || "Do MMMM YYYY";
                return moment(value).format(format);
        }

        return "-";
    };

    const RenderTextInput: React.FC<{ field: ContentFieldInterface }> = ({
        field
    }): JSX.Element => {

        if (disabled && field.viewType == 'link') {
            return (
                <div className="field-data">
                    <LinkTooltip link={field.value} />
                </div>
            )
        }

        return (
            <Input
                value={GetInputValue(field)}
                handleChange={(value: string) =>
                    handleChange(field.key, value)
                }
                {...inputProps}
                className={`field-data ${field.key}-input`}
                disabled={!field.editable || disabled}
            />
        )
    }

    const RenderSelect: React.FC<{ field: ContentFieldInterface }> = ({
        field,
    }): JSX.Element => {
        let Component = AutocompleteMultipleOneLineInput;

        const isClientManaged = field.optionsManagedList?.enabled;

        if (isClientManaged) {
            Component = ClientManagedAutoCompleteMultipleOneLineInput;
        }

        return (
            <Component
                id={"double-column-content-select-input"}
                dataList={field.list || []}
                placeholder={!_.isEmpty(field.value) ? '' : `Enter ${field.name}`}
                disabled={!field.editable || disabled}
                multiple={field.type == 'selectmultiple'}
                value={field.value}
                handleChange={(value: string | Array<any>) =>
                    handleChange(field.key, value)
                }
                clientManagedType={field.optionsManagedList?.key || ""}
                isClientManaged={isClientManaged}
                isDynamicList={field.optionsManagedList?.dynamic}
                apiUrls={apiUrls}
                limitTags={3}
                className={`field-data ${field.key}-select`}
            />
        );
    };

    const RenderDateTimePicker: React.FC<{ field: ContentFieldInterface }> = ({
        field,
    }): JSX.Element => {
        return (
            <DatePickerInput
                {...inputProps}
                className={`field-data ${field.key}-datepicker`}
                id={"id-" + field.key}
                dataToggle={"toggle-" + field.key}
                placeholder={field.editable && `Select ${field.name}`}
                value={GetInputValue(field)}
                handleChange={(value: string) => handleChange(field.key, value)}
                format={field.dateFormat || "mm-dd-yyyy"}
                inputPadding={"0.25rem"}
                background={"rgb(244, 244, 244)"}
                borderBackground={"none"}
                borderBackgroundFocus={"none"}
                color={"rgb(79, 79, 79)"}
                fontSize={"0.75rem"}
                disabled={!field.editable || disabled}
            />
        );
    };

    const RenderLabelsContainer = ({ fields }) => {
        return (
            <div 
                className="labels-column"
                style={labelColumnStyles}>
                {
                    fields.map((field: ContentFieldInterface) => {
                        return (
                            <span className={`field-position field-label field-value-${field.key}`} key={field.name}>
                                {field.name}
                            </span>
                        )
                    })
                }
            </div>
        )
    }

    const RenderInputsContainer = ({ fields }) => {
        return (
            <div className="inputs-column">
                {
                    fields.map((field: ContentFieldInterface) => {
                        const fieldWithValue: any = {
                            ...field,
                            value: field.value || values[field.key],
                        };

                        return (
                            <div
                                className={`field-position field-value field-value-${field.key}`}
                                key={`${field.type}-${field.name}`}>

                                {(!fieldWithValue.value && disabled) && 
                                    <div className="field-data">
                                        <>
                                            {GetInputValue(fieldWithValue) || defaultPlaceholder}
                                        </>
                                    </div>
                                }

                                {(fieldWithValue.value || !disabled) && (
                                    <>
                                        {INPUT_TYPES.includes(field.type) && 
                                            <RenderTextInput field={fieldWithValue} />
                                        }

                                        {SELECT_TYPES.includes(field.type) && (
                                            <RenderSelect field={fieldWithValue} />
                                        )}

                                        {(field.type == "datetime" || field.type == "date") && (
                                            <RenderDateTimePicker field={fieldWithValue} />
                                        )}
                                    </>
                                )}
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const RenderItems = ( { fields } : RenderItemsInterface) => {
        return (
            <div className="double-column-content-column-container">
                <RenderLabelsContainer fields={fields} />
                <RenderInputsContainer fields={fields} />
            </div>
        );
    };

    return (
        <div className="double-column-content-container">
            <div className="first-column">
                <RenderItems fields={doubledContenthalfs.first} />
            </div>

            <div className="second-column">
                <RenderItems fields={doubledContenthalfs.second} />
            </div>
        </div>
    );
};

export default DoubleColumnContent;
