import { CoreSelectOptionType } from "../types/CoreSelectOptionType";
import renderChip from "./renderChip";

interface SelectItem {
    value: string
    text?: string
    name?: string
}

export default function (
    data: SelectItem[],
    checkedKeys: string[],
): CoreSelectOptionType[]  
{
    return data.map((item) => {
        const checked = checkedKeys.some(
            (checkedKey) => checkedKey === item.value
        );

        return {
            value: item.value,
            label: item.text || item.name,
            rightAdornment: renderChip(item.value, checked),
        };
    })
}