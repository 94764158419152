import React, { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const InternetConnectionMonitor = () => {
    const [snackOpen, setSnackOpen] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackOpen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    useEffect(() => {
        window.addEventListener("online", () => setSnackOpen(false));
        window.addEventListener("offline", () => setSnackOpen(true));
    }, []);

    return (
        <Snackbar
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            open={snackOpen}
            onClose={handleClose}
            message="Your internet connection may be unstable"
            action={action}
        />
    );
};

export default InternetConnectionMonitor;
