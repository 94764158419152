import Prefixer from "inline-style-prefixer";

export default function styles(props = {}) {
    let styles = {
        container: {
            width: props.containerWidth || "100%",
            textAlign: props.textAlign || "center",
            display: "flex",
        },
        img: {
            display: "inline-block",
            margin: props.margin || "auto",
            width: props.width || "2rem",
        },
    };

    let prefixer = new Prefixer();
    styles = prefixer.prefix(styles);

    return styles;
}
