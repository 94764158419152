import _ from "lodash";
import AssetInterface from "../components/asset/interfaces/AssetInterface";
import AssetTypeInterface, { AssetTypeSectionFieldInterface, AssetTypeSectionInterface } from "../components/asset/interfaces/AssetTypeInterface";
import GetAssetTypeConfiguration from "./GetAssetTypeConfig";

const GetEditableFields = (
    configuration,
    asset: AssetInterface
): Array<AssetTypeSectionFieldInterface> => {
    const typeConfig: AssetTypeInterface | undefined =
        GetAssetTypeConfiguration(configuration, asset);

    if (!typeConfig) {
        return [];
    }

    return typeConfig.sections
        .reduce(
            (
                fields: Array<AssetTypeSectionFieldInterface>,
                section: AssetTypeSectionInterface,
            ) => {
                return fields.concat(section.fields || []);
            },
            [],
        )
        .filter(
            (field) =>
                !_.get(field, "options.hidden.enabled", false) &&
                _.get(field, "options.editable.enabled", true),
        );
};

export default GetEditableFields