import deepcopy from 'deepcopy'
import GetContributorFullName from '../../src/GetContributorFullName'
import _ from "lodash"

const AssignContributorScripts = ({ 
    contributor: contributorOrigin = {}, 
    field, 
    value, 
    script,
    nameFieldExposed = false
}) => {
    let contributor = deepcopy(contributorOrigin)
    const scriptNames = contributor.script_names || []

    const targetScriptIndex = scriptNames.findIndex(
        (scriptName) => 
            scriptName.code.toLowerCase() === script.toLowerCase()
    )

    if (targetScriptIndex !== -1) {
        scriptNames[targetScriptIndex][field] = value

        if (!nameFieldExposed) {
            scriptNames[targetScriptIndex].name = 
            !_.isEmpty(scriptNames[targetScriptIndex]?.first_name) ?
                GetContributorFullName({ 
                    contributor: scriptNames[targetScriptIndex] 
                })
                :
                null 
        }
        
        return {
            ...contributor,
            script_names: scriptNames
        }
    } 

    scriptNames.push({ code: script, [field]: value })
    return {
        ...contributor,
        script_names: scriptNames
    }
}

export default AssignContributorScripts