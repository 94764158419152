import { transformIncomeCategories } from "./transformCategories"
import _ from 'lodash'
import deepcopy from 'deepcopy'

type OneEntityConfigurationType = {
    categories?: string[]
    [key: string]: any
}

const DataTransformer = (configuration: OneEntityConfigurationType[]): OneEntityConfigurationType[] => {
    return configuration.map((item: OneEntityConfigurationType) => {
        let updatedEntity = deepcopy(item)

        if (Array.isArray(updatedEntity.categories)) {
            updatedEntity.categories = transformIncomeCategories(updatedEntity.categories || [])
        }
        
        return updatedEntity
    })
}

export default DataTransformer