import { SET_INHERITANCE_DATA } from "../actions/action_types";
import { defaultInheritanceData } from "../initialState";

export default function (
    state = { inheritanceData: defaultInheritanceData },
    action,
) {
    switch (action.type) {
        case SET_INHERITANCE_DATA:
            return Object.assign({}, state, {
                inheritanceData: action.inheritanceData,
            });
    }

    return state;
}
