import { OptionInterface } from "../../interfaces"

export const userRolesAndPoliciesOption: OptionInterface = {
    name: "User Role & Policies",
    value: "userRolesAndPolicies",
    path: "userRolesAndPolicies",
    children: [],
};

export const userRoleManagementOption: OptionInterface = {
    name: "user role management",
    value: "role",
    path: "tab.role",
    children: [],
};

export const userPolicyManagementOption: OptionInterface = {
    name: "user policy management",
    value: "policy",
    path: "tab.policy",
    children: [],
};