import moment from "moment";
import _ from "lodash";
import isIndexInArray from "../Helpers/isIndexInArray";

export function MonthYearDatesWithOptions(props) {
    const { limitToDate } = props;

    return MonthYearDates([], 12, limitToDate);
}

export function MonthYearDates(
    dates = [],
    numberOfMonths = 12,
    limitToDate = false,
) {
    let result = {};
    let number = -12;

    if (process.env.NODE_ENV == "test") {
        dates = [...dates, moment().format("YYYY-MM")];
    }

    while (number <= numberOfMonths) {
        let monthMoment = moment().add(number, "months");

        const year = monthMoment.format("YYYY");
        const value = monthMoment.format("YYYY-MM");

        if (isIndexInArray(dates, value) || _.isEmpty(dates)) {
            if (!result[year]) {
                result[year] = [];
            }

            result[year].push({
                text: monthMoment.format("MMMM"),
                value,
                tag: year,
                unix: monthMoment.unix(),
            });
        }

        // Finish the loop early
        if (limitToDate && value == limitToDate) {
            return result;
        }

        number++;
    }

    return result;
}

export default MonthYearDates;
