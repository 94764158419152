import React from "react";
import TitleValue from "../../TitleValue";
import UpdateDeleteActionButtons from "../../UpdateDeleteActionButtons";
import moment from "moment";
import NumberFormat from "react-number-format";

const ExpandableRowBody = (props) => {
    const { item = {}, handleDelete, handleEdit, disabled } = props;

    const {
        user_group,
        created_by,
        buy_rate,
        total_buy_rate,
        total_margin,
        last_edited_by,
        onboarding_date,
        expected_buy_runs,
        sell_rate,
        total_sell_rate,
        expected_sell_runs,
        margin,
        last_edited_on,
        notes,
        offboarding_date,
        licensor,
    } = item;
    const RenderFormattedNumber = (props) => {
        return (
            <NumberFormat
                value={props.value}
                displayType={"text"}
                thousandSeparator={true}
                prefix={props.prefix ? "$" : ""}
                decimalScale={2}
            />
        );
    };

    return (
        <div className="body">
            <div className="row">
                <TitleValue
                    title="Connect User Group ID"
                    value={user_group}
                    className="user_group"
                />

                <TitleValue
                    title="Created by User"
                    value={created_by}
                    className="created_by"
                />

                <TitleValue
                    title="Buy Rate"
                    value={
                        <RenderFormattedNumber value={buy_rate} prefix={true} />
                    }
                    className="buy_rate"
                />

                <TitleValue
                    title="Total Buy Rate"
                    value={
                        <RenderFormattedNumber
                            value={total_buy_rate}
                            prefix={true}
                        />
                    }
                    className="total_buy_rate"
                />

                <TitleValue
                    title="Expected Buy Runs"
                    value={expected_buy_runs}
                    className="expected_buy_runs"
                />

                <TitleValue
                    title="Total Margin"
                    value={
                        <RenderFormattedNumber
                            value={total_margin}
                            prefix={true}
                        />
                    }
                    className="total_margin"
                />

                <TitleValue
                    title="Last Edited by"
                    value={last_edited_by}
                    className="last_edited_by"
                />
            </div>

            <div className="row">
                <TitleValue
                    title="First Aired Date"
                    value={moment(onboarding_date).format("Do MMMM YYYY")}
                    className="onboarding_date"
                />

                <TitleValue
                    title="Sell Rate"
                    value={
                        <RenderFormattedNumber
                            value={sell_rate}
                            prefix={true}
                        />
                    }
                    className="sell_rate"
                />

                <TitleValue
                    title="Total Sell Rate"
                    value={
                        <RenderFormattedNumber
                            value={total_sell_rate}
                            prefix={true}
                        />
                    }
                    className="total_sell_rate"
                />

                <TitleValue
                    title="Expected Sell Runs"
                    value={expected_sell_runs}
                    className="expected_sell_runs"
                />

                <TitleValue
                    title="Margin %"
                    value={`${margin.toFixed(2)}%`}
                    className="margin"
                />

                <TitleValue
                    title="Last Edited On"
                    value={moment(last_edited_on).format("DD.MM.YYYY")}
                    className="last_edited_on"
                />

                <TitleValue
                    title="Off-boarding Date"
                    value={moment(offboarding_date).format("DD.MM.YYYY")}
                    className="offboarding_date"
                />
            </div>

            <div className="row">
                <TitleValue
                    title="Licensor"
                    value={licensor}
                    className="licensor"
                />
                <div className="notes">
                    <TitleValue title="Notes" value={notes} className="notes" />
                </div>

                {!disabled && (
                    <UpdateDeleteActionButtons
                        onClickDelete={handleDelete}
                        onClickEdit={handleEdit}
                        update={false}
                        style={{
                            justifyContent: "flex-end",
                            marginLeft: "auto",
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default ExpandableRowBody;
