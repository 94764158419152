import deepcopy from "deepcopy";
import _ from 'lodash';
import { envTest } from "../../../components/Environment";
import { ValidationResultInterface } from "../../SelectV2/interfaces";
import { ConfigOptionsInterface, FieldConfigInterface } from "../interfaces";
import transformBuilderPayload from "../regional/utils/transformBuilderPayload/transformBuilderPayload";
import validateLength from "./utils/validateLength";
import { userHasPermission } from "../../../services/PermissionService";
import { idsOption } from "../../../features/UserRoleManagement/utils/sectionOptions/recordSections";
import { accessTypeUpdate, accessTypeView } from "../../../features/UserRoleManagement/utils/accessOptions";
import { ValidationResult } from "../interfaces";
import { CLIENT_IDS, SUPPLY_CHAIN_IDS, ALTERNATE_IDS } from "../../RecordBuilder";
import validatePresence from "./utils/validatePresence";
import filterRecordBuilderConfigurationByCategory from "../../../components/RecordBuilder/map/filterCategory";

type Props = {
    options: any;
    result: ValidationResult;
}

export const validateIdentifiers = ({ result, options }: Props): ValidationResultInterface => {
    const {
        data,
        builderConfig,
        userPermissions,
        cmsData,
    } = options;

    const {
        ids = {},
    } = data;

    const hasAccess = (accessType) => {
        return userHasPermission({
            cmsData,
            userPermissions,
            pathField: idsOption.path,
            access: accessType,
            prefix: data.category,
        })
    }

    const response: ValidationResultInterface = deepcopy(result);

    if(
        (!hasAccess(accessTypeView) || !hasAccess(accessTypeUpdate))
        && !envTest
    ) {
        return response
    }

    let fieldsConfig: FieldConfigInterface[] = [];

    if (!_.isEmpty(builderConfig)) {
        const configurations: FieldConfigInterface[] = builderConfig[ALTERNATE_IDS]
            .concat(builderConfig[CLIENT_IDS])
            .concat(builderConfig[SUPPLY_CHAIN_IDS])
            .filter((item: FieldConfigInterface) => !item.archived)

        fieldsConfig = filterRecordBuilderConfigurationByCategory({ category: data.category, models: configurations });
        fieldsConfig = transformBuilderPayload(fieldsConfig, 'id');
    }

    const configOptions: ConfigOptionsInterface = {
        data: Object.keys(ids || {}).map((id: string) => {
            return { id: ids[id], type: id}
        }),
        fieldType: 'id',
        fieldsConfig,
    };

    try {
        validateLength({ response, options, configOptions });
        validatePresence({ response, options, configOptions });
    } catch (error) {
        console.log(error);
    }

    return response;
};
