import FieldInterface, { DataType } from '../interfaces/Field.interface'
import { get, isEmpty, isNumber, isObject, isString, snakeCase } from "lodash"
import { CoreSelectOptionType } from "../../components/inputs/Select/types/CoreSelectOptionType"
import validString from '../utils/validString'

type Option = { [key: string]: any } | string

export const transformOutgoingOption = ({ field, option, list }: {
    field: FieldInterface, 
    option: string | { text: string, value: string },
    list: CoreSelectOptionType[]
}) => {
    const {
        option: optionPayload,
        growing,
    } = field.type.select

    const {
        dataType,
        textField = 'text',
        valueField = 'value',
    } = optionPayload || {}

    if (
        isEmpty(list) 
        && !isEmpty(field.type.select?.list) 
        && Array.isArray(field.type.select?.list)
    ) {
        list = field.type.select?.list
    }

    //transform Growing lists
    if (growing) {
        const textFieldData = isObject(option) ? option.text : option
        const valueFieldData = snakeCase(isObject(option) ? option.value : option)

        if (dataType == DataType.String || field.type.dataType === DataType.String) {
            return validString(textFieldData, null)
        }

        return {
            [textField]: textFieldData,
            [valueField]: valueFieldData
        }
    }

    let optionValue = option.value ?? option

    // Transform empty array when data type is string
    if (dataType == DataType.String || field.type.dataType === DataType.String) {
        return validString(optionValue, null)
    }

    const listOption = list.find((listOption: CoreSelectOptionType) => listOption.value == optionValue)

    if (listOption) {
        return {
            [textField]: listOption.text,
            [valueField]: listOption.value
        }
    }

    return null
}


export const transformOutgoingOptions = ({ field, options, list, prevOptions }: {
    field: FieldInterface
    options: string[]
    prevOptions: Option[]
    list: CoreSelectOptionType[]
}): Option[] => {

    let selection: Option[] = options
        .map((option: string) => transformOutgoingOption({ field, option, list }))
        .filter((option: any) => !isEmpty(option))

    return deleteOptions({
        options: selection,
        prevOptions,
        field
    })
}

export const optionValue = ({ field, option, useList = false }: {
    field: FieldInterface
    option: Option
    useList?: boolean
}): string | null => {
    let valueKey = get(field, 'type.select.option.valueField', 'value')

    if (useList) {
        valueKey = get(field, 'type.select.listValueField', 'value')
    }

    if (isString(option) || isNumber(option)) {
        return option.toString()
    }

    if (typeof option === 'object') {
        return (option[valueKey])?.toString()
    }

    return null
}

export const listOptionText = ({ field, option }: {
    field: FieldInterface
    option: Option
}): string | null => {
    const textKey = get(field, 'type.select.listTextField', 'text')

    if (isNumber(option)) {
        return option.toString(option)
    }

    if (isString(option) || isNumber(option)) {
        return option.toString()
    }

    if (typeof option === 'object') {
        return (option[textKey])?.toString()
    }

    return null
}

export const optionsValues = ({ field, options }: {
    field: FieldInterface, 
    options: Option[],
}): string[] => {
    if (isEmpty(options)) {
        return []
    }

    return options
        .map((option: any) => optionValue({ field, option }))
        .filter(option => !isEmpty(option))
}

const deleteOptions = ({ field, options, prevOptions }: {
    field: FieldInterface, 
    options: Option[],
    prevOptions: Option[],
}) => {
    const prevOptionsStringValues = optionsValues({ field, options: prevOptions })
    const optionsStringValues = optionsValues({ field, options })
    const optionType = get(field, 'type.select.option.dataType')
    const valueKey = get(field, 'type.select.option.valueField', 'value')

    // we expect select input returns array of values without deleted options, this is how delete works for Array of strings
    if (optionType == DataType.String || field.type.dataType === DataType.String) {
        return options
    }

    let deletedPrevOptions: Option[] = prevOptionsStringValues
        .filter((prevOption: string) => !optionsStringValues.includes(prevOption))
        .map((prevOption: string) => ({ deleted: true, [valueKey]: prevOption }))
    
    return options.concat(deletedPrevOptions)
}