import React, { Component } from "react";
import styles from "./styles";
import Settings from "../Settings";
import Button from "../Button";
import AddNewRowButton from "../AddNewRowButton";
import Notification from "../Notification";
import _ from "lodash";
import update from "react-addons-update";
import $ from "jquery";
import NoneEntered from "../KeywordLabel/noneEntered";

export default class Certifications extends Component {
    constructor(props) {
        super(props);

        /**
         * @var object
         */
        this.state = {
            data: [],
            editRow: false,
            hoverRow: false,
            newRow: true,
            activeRow: {},
            notification: {},
            movedItemIndex: false,
        };

        /**
         * @var object
         */
        this.defaultActiveRowData = {};

        /**
         * @var object
         */
        this.styles = styles(this.props);

        /**
         * @var object
         */
        this.copy = this.props.copy || {};

        /**
         * @var bool
         */
        this._isMounted = false;

        /**
         * @var bool
         */
        this.keyPressEnterDisabled = false;

        /**
         * @var string
         */
        this.rowSpacing = "0.625rem";
    }

    componentDidMount() {
        this.setDataFromProps();
        this._isMounted = true;
    }

    componentDidUpdate(prevProps, prevState) {
        // Edit record
        if (!_.isEqual(this.props.data, prevProps.data)) {
            this.setDataFromProps();
        }

        // Animate row sort
        if (this.state.movedItemIndex !== prevState.movedItemIndex) {
            $(".react-row-updated").fadeTo(100, 0.3, () => {
                $(".react-row-updated").fadeTo(400, 1);
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setDataFromProps() {
        let result = [];

        if (Array.isArray(this.props.data)) {
            result = this.props.data;
        }

        this.setData(result);
    }

    setActiveRowDefault() {
        this.setActiveRow(this.defaultActiveRowData);
    }

    setStateKey(key, value) {
        if (!_.isEqual(this.state[key], value)) {
            this.setState({
                [key]: value,
            });
        }
    }

    setMovedItemIndex(movedItemIndex) {
        if (this.state.movedItemIndex !== movedItemIndex) {
            this.setState({
                movedItemIndex,
            });
        }
    }

    setData(data) {
        if (!_.isEqual(this.state.data, data)) {
            this.setState({
                data,
            });
        }
    }

    setNotification(notification) {
        if (!_.isEqual(this.state.notification, notification)) {
            this.setState({
                notification,
            });
        }
    }

    setEditRow(editRow) {
        if (this.state.editRow !== editRow) {
            this.setState({
                editRow,
            });
        }
    }

    setHoverRow(hoverRow) {
        if (this.state.hoverRow !== hoverRow) {
            this.setState({
                hoverRow,
            });
        }
    }

    setNewRow(newRow) {
        if (this.state.newRow !== newRow) {
            this.setState({
                newRow,
            });
        }
    }

    setActiveRow(activeRow) {
        if (!_.isEqual(this.state.activeRow, activeRow)) {
            this.setState({
                activeRow,
            });
        }
    }

    handleChangeCallBack() {
        if (typeof this.props.handleChange == "function") {
            this.props.handleChange(this.state.data);
        }
    }

    handleNewRowNotification() {
        const notification = this.props.notification;

        if (typeof notification == "object") {
            const status = notification.status;
            let disabled = false;
            let confirm = this.handleNewRow.bind(this);

            if (status == "alert") {
                confirm = () => {};
                disabled = true;
            }

            this.setNotification({
                status,
                confirm,
                disabled,
                title: notification.title,
                description: notification.description,
                okText: this.copy.okText || "Ok",
            });
        }
    }

    handleNewRow() {
        if (
            this.state.newRow &&
            this.state.editRow === false &&
            this.checkEmptyDataKeys() &&
            Array.isArray(this.state.data)
        ) {
            this.setActiveRowDefault();
            const data = update(this.state.data, {
                $push: [this.defaultActiveRowData],
            });

            this.setData(data);
            this.setEditRow(data.length - 1);
            this.setNewRow(false);
        }
    }

    handleRowHover(index) {
        if (!this.props.disabled) {
            this.setHoverRow(index);
        }
    }

    handleRowEditClick(item, index) {
        if (
            !this.props.disabled &&
            this.state.editRow === false &&
            this.checkEmptyDataKeys()
        ) {
            if (item) {
                this.setActiveRow({ ...item });
            }

            this.setEditRow(index);
        }
    }

    handleRowDelete(item, index) {
        this.setNotification({
            title: this.copy.deleteTitle || "Delete Row",
            description:
                this.copy.deleteDescription ||
                "Are you sure you would like to delete this row?",
            status: "alert",
            okText: this.copy.okText || "Delete",
            confirm: this.deleteRow.bind(this, item, index),
        });
    }

    handleStateSave() {
        if (this.isActiveRowValidated()) {
            this.setEditRow(false);
            this.setActiveRowDefault();
            this.handleChangeCallBack();
        }
    }

    handleNotificationCancel() {
        this.setNotification({});
    }

    deleteRow(item, index) {
        const updatedItem = {
            ...item,
            deleted: true,
        };

        const updatedState = update(this.state.data, {
            [index]: {
                $set: updatedItem,
            },
        });

        this.setState(
            {
                data: updatedState,
            },
            () => {
                this.setActiveRowDefault();
                this.handleChangeCallBack();
                this.setEditRow(false);
                this.setNewRow(true);

                if (typeof this.updateRanks == "function") {
                    this.updateRanks();
                }
            },
        );

        this.setNotification({});
    }

    getDisabledStateForAddNewButton() {
        let disabled = true;

        if (this.state.editRow === false) {
            disabled = false;
        }

        return disabled;
    }

    renderAddNewButton() {
        const {
            canCreateNew = true,
            disabled,
            basic,
            notification,
        } = this.props;

        if (!disabled && !basic) {
            const disabled = this.getDisabledStateForAddNewButton();

            return (
                <AddNewRowButton
                    onClick={
                        !_.isEmpty(notification)
                            ? this.handleNewRowNotification.bind(this)
                            : this.handleNewRow.bind(this)
                    }
                    disabled={!canCreateNew || disabled}
                    data={this.state.data}
                />
            );
        }

        return false;
    }

    renderButton(disabled) {
        return (
            <div style={this.styles.newRowContainer}>
                <Button
                    value={this.copy.addNew || "Add new"}
                    type="button"
                    className={`meta-add-row ${this.addNewButtonClass || ""}`}
                    margin="0"
                    padding="0.34375rem"
                    background="#8f8f8f"
                    hoverBackground="#9a9a9a"
                    fontSize="0.78125rem"
                    color="white"
                    onClick={
                        !_.isEmpty(this.props.notification)
                            ? this.handleNewRowNotification.bind(this)
                            : this.handleNewRow.bind(this)
                    }
                    disabled={disabled}
                    disabledOpacity={true}
                    leftIcon={`${Settings.images.path}/svg/new-record-white.svg`}
                    leftIconStyles={{
                        width: "0.75rem",
                        marginRight: "0.1875rem",
                    }}
                />
            </div>
        );
    }

    renderData() {
        if (Array.isArray(this.state.data)) {
            if (this.state.data.length > 0) {
                let count = 1;

                return (
                    <React.Fragment>
                        {this.state.data.map((item, index) => {
                            if (!item.deleted) {
                                count++;
                                return this.renderDataRow(item, index, count);
                            }
                        })}
                    </React.Fragment>
                );
            }
        }

        return false;
    }

    renderDeleteIcon(item, index, className) {
        return (
            <img
                src={`${Settings.images.path}/svg/clear-light-grey.svg`}
                alt="delete"
                className={`meta-delete-row ${className} ${
                    this.props.deletedIconClass || ""
                }`}
                style={this.styles.tableInlineIcon}
                onClick={this.handleRowDelete.bind(this, item, index)}
            />
        );
    }

    // Only used linkages
    renderDeleteIconLinkage(item, index, className) {
        return (
            <img
                src={`${Settings.images.path}/svg/clear-light-grey.svg`}
                alt="delete"
                className={`meta-delete-row ${className} ${
                    this.props.deletedIconClass || ""
                }`}
                onClick={this.handleRowDelete.bind(this, item, index)}
            />
        );
    }

    renderEditIcon(item, index, className) {
        return (
            <img
                src={`${Settings.images.path}/svg/edit-white.svg`}
                alt="edit"
                className={`meta-edit-row ${className}`}
                style={this.styles.tableInlineIcon}
                onClick={this.handleRowEditClick.bind(this, item, index)}
            />
        );
    }

    renderEditIconGrey(item, index, className) {
        return (
            <img
                src={`${Settings.images.path}/svg/edit-grey.svg`}
                alt="edit"
                className={`meta-edit-row ${className}`}
                style={this.styles.tableIcon}
                onClick={this.handleRowEditClick.bind(this, item, index)}
            />
        );
    }

    renderEditIconGreyLinkage(item, index) {
        return (
            <img
                src={`${Settings.images.path}/svg/edit-grey.svg`}
                alt="edit"
                className="meta-edit-row"
                style={Object.assign({}, this.styles.tableIcon, { margin: 0 })}
                onClick={this.handleRowEditLinkageClick.bind(this, item, index)}
            />
        );
    }

    renderSaveIcon(id = "") {
        return (
            <img
                src={`${Settings.images.path}/svg/tick-light-grey.svg`}
                alt="save"
                className={`meta-save-row ${this.saveButtonClass || ""}`}
                id={id}
                style={Object.assign(
                    {},
                    this.styles.tableInlineIcon,
                    this.styles.tableIconMargin,
                    !this.isActiveRowValidated() && this.styles.iconDisabled,
                )}
                onClick={this.handleStateSave.bind(this)}
            />
        );
    }

    renderPlusIcon() {
        if (!this.props.disabled) {
            if (this.state.data.length > 0) {
                return (
                    <img
                        src={`${Settings.images.path}/svg/new-record-light-grey.svg`}
                        style={Object.assign(
                            {},
                            this.styles.plusSign,
                            this.state.editRow !== false &&
                                this.styles.iconDisabled,
                        )}
                        className="react-fade-in meta-add-row"
                        onClick={this.handleNewRow.bind(this)}
                    />
                );
            }
        }

        return false;
    }

    render() {
        return (
            <React.Fragment>
                <div
                    className={`${this.props.className} dynamic-table`}
                    id={this.props.id}
                >
                    {this.props.addNewButtonOnTop && this.renderAddNewButton()}

                    {this.renderData()}

                    {!this.props.addNewButtonOnTop && this.renderAddNewButton()}
                </div>

                <Notification
                    title={this.state.notification.title || false}
                    description={this.state.notification.description || false}
                    intercationStatus={this.state.notification.status || false}
                    html={this.state.notification.html || false}
                    confirm={this.state.notification.confirm || false}
                    background={this.state.notification.background || false}
                    disabled={this.state.notification.disabled || false}
                    onClick={this.handleNotificationCancel.bind(this)}
                    okText={this.state.notification.okText || undefined}
                    datalist={this.state.notification.datalist || undefined}
                    interactionButtonClassName={this.interactionButtonClassName}
                    keyPressEnterDisabled={this.keyPressEnterDisabled}
                    className={this.notificationClass}
                    titlePadding={this.state.notification.titlePadding}
                    contentNoPadding={this.state.notification.contentNoPadding}
                />
            </React.Fragment>
        );
    }
}
