import React from "react";
import "./styles/_styles.scss";

export default class AssetTrafficLight extends React.Component {
    render() {
        const { style, status, square, height, text } = this.props;

        const classStatus = () => {
            let result = "";

            if (typeof status == "string") {
                result = status.replace(" ", "-");
            }

            return result;
        };

        return (
            <div
                style={style}
                className={`
					meta-asset-traffic-light 
					${classStatus()} 
					${square ? "square" : ""}
				`}
            >
                {text && <span>{text}</span>}
            </div>
        );
    }
}
