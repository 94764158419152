import _ from "lodash";

export default function ObjectArrayToKeyArrayTransformer(props) {
    const { value = [], key = "text" } = props;

    let newVal = [];

    if (Array.isArray(value)) {
        newVal = value
            .map((item) => {
                let result = null;
                if (_.isObject(item)) {
                    result = item[key] || null;
                } else if (_.isString(item)) {
                    result = item;
                }
                return result;
            })
            .filter((item) => {
                return !_.isEmpty(item);
            });
    }

    return [...new Set(newVal)];
}
