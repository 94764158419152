import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledContributorListItem = styled(Box)(({ theme }) => ({
    "& .MuiListItem-root": {
        borderRadius: '0.375rem',
        border: '0.0625rem solid #E5E5E5',
        background: '#FFF',
        boxShadow: '0 0.25rem 0.625rem 0 rgba(74, 90, 113, 0.10)',
        padding: "0.625rem",
        "& .MuiListItemAvatar-root": {
            minWidth: 'unset',
            "& .MuiAvatar-root": {
                width: '3rem',
                height: '3rem',
                margin: '0 0.625rem 0 0'
            },
        },
        "& .MuiListItemText-root": {
            margin: 0,
            "& .MuiListItemText-primary": {
                fontSize: '1.125rem',
                lineHeight: '1.3125rem',
                margin: '0 0 0.3125rem 0',
            },
            "& .MuiListItemText-secondary": {
                lineHeight: "1.25rem",
            },
        },
    }
}));
