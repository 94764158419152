import React from "react";
import "./styles/_styles.scss";
import Settings from "../Settings";
import $ from "jquery";

export default class ExpandableRow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: props.defaultOpen || false,
        };
    }

    render() {
        const {
            header,
            body,
            className,
            id,
            bodyStyles = {},
            styles = {},
            noHeaderBackground,
            borderRadius,
            defaultOpen,
            arrowIconColorType = "light", //light, medium, dark,
            bodyOpen = () => {},
        } = this.props;

        const GetArrowIconSrc = (type) => {
            let src = "";
            switch (type) {
                case "medium":
                    src = "/svg/chevron-bottom-medium-grey.svg";
                    break;
                case "dark":
                    src = "/svg/chevron-bottom-grey.svg";
                    break;
                case "light":
                    src = "/svg/chevron-bottom-light-grey.svg";
                    break;
                default:
                    src = "/svg/chevron-bottom-light-grey.svg";
            }

            return src;
        };

        const { open } = this.state;

        const handleOnClick = (e) => {
            e.stopPropagation();
            animate($(e.currentTarget));
            bodyOpen();
        };

        const animate = (element) => {
            // slide body down
            const body = element.children()[1];

            this.setState(
                {
                    open: !open,
                },
                () => {
                    $(body).slideToggle(200);
                },
            );
        };

        let headerStyles = {
            ...this.props.headerStyles,
        };

        if (borderRadius) {
            headerStyles = {
                ...this.props.headerStyles,
            };
        }

        return (
            <div
                className={`meta-expandable-row ${className} ${
                    defaultOpen ? "default-open" : ""
                } ${open ? "open" : ""} ${
                    noHeaderBackground ? "no-background-selector" : ""
                } ${borderRadius ? "border-radius" : ""}`}
                id={id}
                style={styles}
                onClick={handleOnClick.bind(this)}
            >
                <div
                    className={
                        "meta-expandable-row--header " +
                        (this.props.headerClassName || "")
                    }
                    style={headerStyles}
                >
                    <img
                        className="meta-expandable-row--icon"
                        src={`${Settings.images.path}${GetArrowIconSrc(
                            arrowIconColorType,
                        )}`}
                    />

                    <div className="meta-expandable-row--header--content">
                        {header}
                    </div>
                </div>

                <div
                    className="meta-expandable-row--body"
                    style={bodyStyles}
                    onClick={(e) => e.stopPropagation()}
                >
                    {body}
                </div>
            </div>
        );
    }
}
