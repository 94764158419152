import _ from "lodash";
import UnsavedChanges from "./Notifications/UnsavedChanges";
import CurrentMonthYear from "../../components/Helpers/CurrentMonthYear";
import { isActiveIoTSubscription } from "../../components/IoT";
import GetPageTypeByCategory from "../../pages/Collections/src/GetPageTypeByCategory";

const HandleActiveRecordSet = (props) => {
    const {
        selectedRecord = {},
        state = {},
        keepSelected,
        callBack = () => {},
    } = props;

    const {
        set,
        unsavedChanges,
        activeRecord = {},
        handleRecordMetaDataRequest = () => {},
        IoTConnection = {},
    } = state;

    const IoTMatch = isActiveIoTSubscription({
        IoTConnection,
        item: selectedRecord,
    });

    const deSelection =
        activeRecord.meta_id == selectedRecord.meta_id && !keepSelected;
    let updatedActiveRecord = IoTMatch ? IoTConnection : selectedRecord;

    if (deSelection) {
        updatedActiveRecord = {};
    }

    const setActiveRecord = () => {
        let updatedState = {
            activeRecord: updatedActiveRecord,
            editRecord: false,
            originalRecord: {},
            unsavedChanges: false,
            collectionTitle: {},
            lineupPeriod: CurrentMonthYear(),
            recordData: {},
        };

        if (!keepSelected) {
            updatedState.recordData = {};
        }

        if (IoTMatch && !deSelection) {
            //updatedState.fetchingMetaData = false
            updatedState.recordData = IoTConnection;
            updatedState.originalRecord = IoTConnection;
        }

        const pageType = GetPageTypeByCategory(updatedActiveRecord);
        if (!deSelection && pageType) {
            updatedState.type = pageType;
        }

        set(updatedState, () => {
            // fetch
            if (!_.isEmpty(updatedActiveRecord) && !IoTMatch) {
                handleRecordMetaDataRequest(callBack);
            }
        });
    };

    if (unsavedChanges) {
        set({
            notification: {
                ...UnsavedChanges({}),
                onClick: () =>
                    set({
                        notification: {},
                    }),
                confirm: () => {
                    set({
                        notification: {},
                    });

                    setActiveRecord();
                },
            },
        });

        return;
    }

    setActiveRecord();
};

export default HandleActiveRecordSet;
