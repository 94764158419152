import { CoreSelectOptionType } from "../../../core/Form/components/inputs/Select/types/CoreSelectOptionType";
import { useStores } from "../store/rootStore";

export function useProvenanceOptions(): CoreSelectOptionType[] {
    const stores = useStores();

    return stores?.clientManaged?.provenances?.map((p) => ({
        label: p,
        value: p,
    }));
}
