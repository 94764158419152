import { RecordInterface } from "../../models/Record/model";
import { FetchResultInterface, ParentSeriesInterface } from "./interfaces";

export const transformParentSeriesList = (
    data: FetchResultInterface,
): ParentSeriesInterface[] => {
    if (!data || !Array.isArray(data.active_records)) {
        return [];
    }

    return data.active_records
        .filter((item: RecordInterface) => item.origination.is_parent)
        .map((item: RecordInterface) => {
            return {
                meta_id: item.meta_id,
                text: item.original_title,
            };
        });
};
