import {
    policyTypeDiscovery,
    policyTypeCollections,
    policyTypeLocalisations,
    policyTypeExports,
    policyTypeUserGroups,
    policyTypeSwimlanes,
    crudKey,
    policyTypeFranchiseCollections,
    accessTypeStrictCreate,
    accessTypeStrictDelete,
    accessTypeStrictUpdate,
} from "../../features/UserRoleManagement/utils/accessOptions";

import {
    getDisoveryAccess,
    getCollectionsAccess,
    getFranchiseCollectionsAccess,
    getLocalisationsAccess,
    getExportsAccess,
    getLineupsUserGroupsAccess,
    getSwimlanesAccess,
} from "../../features/UserRoleManagement/utils/accessStructure";

import { RecordInterface } from "../../models/Record/model";
import _ from "lodash";
import { SectionInterface } from "./src/sections/interface";
import { PolicyInterface } from "../../features/UserRoleManagement/interfaces";
import { OptionInterface } from "../../features/UserRoleManagement/interfaces";
import findPath from "../Helpers/findPath";
import { CmsInterface, globalStateInterface } from "../Global/interfaces";
import { isUserSuperAdmin, userHasPermission } from "../../services/PermissionService";

interface SectionPermissionsInterface {
    state: globalStateInterface;
    type: string;
    access: Array<OptionInterface>;
}

function getSectionOptionsByPermissions({
    state,
    type,
    access,
}: SectionPermissionsInterface): Array<OptionInterface> {
    const cmsData: CmsInterface = state.cmsData || {};
    const userPermissions: Array<any> = state.userPermissions || [];

    if (isUserSuperAdmin(cmsData)) {
        return access;
    }

    let result: Array<OptionInterface> = [];
    let permissions =
        userPermissions.find(
            (item: PolicyInterface) =>
                _.get(item, `${type}`) || findPath(item, `${type}`),
        ) || {};
    let path: string = findPath(permissions, `${type}`);
    let tabs: Array<OptionInterface> =
        _.get(permissions, `${type}.tab`) ||
        _.get(permissions, `${path}.${type}.tab`) ||
        {};

    access.forEach((item: OptionInterface) => {
        const name: string = item.value || item.name || "";
        const tabItem: OptionInterface = tabs[name] || {};

        if (tabItem[crudKey] > 0) {
            result.push(item);
        }
    });

    return result;
}

export function getCategoriesFromPermissions(props: any): Array<any> {
    const data: RecordInterface = props.data || {};
    const policyType: string = props.policyType;
    const state: globalStateInterface = props.state;

    const { category } = data;

    let result: Array<OptionInterface> = [];

    switch (policyType) {
        case policyTypeDiscovery:
            result = getSectionOptionsByPermissions({
                state,
                access: getDisoveryAccess(category) || [],
                type: `record.category.${category}`,
            });

            break;

        case policyTypeCollections:
            result = getSectionOptionsByPermissions({
                state,
                type: policyTypeCollections,
                access: getCollectionsAccess() || [],
            });
            break;

        case policyTypeLocalisations:
            result = getSectionOptionsByPermissions({
                state,
                type: policyTypeLocalisations,
                access: getLocalisationsAccess() || [],
            });

            break;

        case policyTypeExports:
            result = getSectionOptionsByPermissions({
                state,
                type: policyTypeExports,
                access: getExportsAccess() || [],
            });

            break;

        case policyTypeSwimlanes:
            result = getSectionOptionsByPermissions({
                state,
                type: policyTypeSwimlanes,
                access: getSwimlanesAccess() || [],
            });

            break;

        case policyTypeUserGroups:
            result = getSectionOptionsByPermissions({
                state,
                type: policyTypeUserGroups,
                access: getLineupsUserGroupsAccess() || [],
            });

            break;

        case policyTypeFranchiseCollections:
            result = getSectionOptionsByPermissions({
                state,
                type: policyTypeFranchiseCollections,
                access: getFranchiseCollectionsAccess() || [],
            });

            break;
    }

    return result;
}

export function displaySectionFields(sectionOptions: any, item: string) {
    if (sectionOptions == false) {
        return true;
    }

    return sectionOptions.includes(item);
}

export function getSectionCommonProps(props: SectionInterface): any {
    const state: globalStateInterface = props.state || {};
    const cmsData: CmsInterface = state.cmsData || {};
    const userPermissions: Array<any> = state.userPermissions || [];
    const pathField: string = props.pathField;

    let result = {
        ...props,
        sectionOptions: false,
        disabled: props.disabled
            ? true
            : !userHasPermission({
                  cmsData,
                  userPermissions,
                  pathField,
                  access: accessTypeStrictUpdate
              }),
        canCreateNew: userHasPermission({
            cmsData,
            userPermissions,
            pathField,
            access: accessTypeStrictCreate,
        }),
        canDelete: userHasPermission({
            cmsData,
            userPermissions,
            pathField,
            access: accessTypeStrictDelete,
        }),
    };

    if (props.attributes) {
        result.year = props.attributes.year;
    }

    if (props.handleFormChange) {
        result.handleFormChange = (data: any) =>
            props.handleFormChange(props.field, data);
    }

    if (props.handleRegionalTitleSwitchChange) {
        result.handleRegionalTitleSwitchChange =
            props.handleRegionalTitleSwitchChange.bind(this);
    }

    if (props.handleLinkSearch) {
        result.handleLinkSearch = props.handleLinkSearch.bind(this);
    }

    if (props.handleUserGroupUpdate) {
        result.handleChange = props.handleUserGroupUpdate.bind(this);
    }

    return result;
}
