import React from 'react';
import { InputAdornment, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { PhoneNumberUtil } from "google-libphonenumber";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import {
    defaultCountries,
    FlagImage,
    parseCountry,
    usePhoneInput,
} from "react-international-phone";
import { CoreFormType } from "../../types";
import { CoreFormLabel, CoreFormLabelProps } from "../styled/CoreForm.label";
import { StyledTextField } from "../styled/CoreFormText.field";
import { CoreSelect } from "./Select/components/CoreSelect";
import { CoreSelectOptionType } from "./Select/types/CoreSelectOptionType";

const phoneUtil = PhoneNumberUtil.getInstance();
/**
 * Example of how to validate a phone number
 * It could be used in zod schema validation if needed in the future so don't remove it
 */
const isPhoneValid = (phone: string) => {
    try {
        const number = phoneUtil.parseAndKeepRawInput(phone);
        return phoneUtil.isValidNumber(number);
    } catch (error) {
        return false;
    }
};

export const getCountryDialCode = (phone: string) => {
    try {
        const number = phoneUtil.parseAndKeepRawInput(phone);
        return number.getCountryCode();
    } catch (error) {
        return "";
    }
};

export type CoreInputPhoneNumberType = {
    phone_number: {
        country_code: string;
        number: string;
    };
    date: string;
};

const countryOptions =
    defaultCountries?.map((c) => {
        const countryAux = parseCountry(c);
        const option: CoreSelectOptionType = {
            label: countryAux.name,
            value: countryAux.iso2,
            leftAdornment: (
                <FlagImage
                    iso2={countryAux.iso2}
                    style={{
                        width: "1.4rem",
                        height: "1.4rem",
                    }}
                />
            ),
            rightAdornment: `+${countryAux.dialCode}`,
        };
        return option;
    }) || [];

const StyledAdornment = styled(InputAdornment)(({ theme }) => ({
    marginRight: "0.125rem",
}));

export function CoreInputPhoneNumber<T>({
    phoneNumberPath = "phone_number",
    countryCodePath = "phone_number.country_code",
    phoneNumberNumberPath = "phone_number.number",
    labelProps,
    disabled,
    ...muiPhoneNumberProps
}: {
    phoneNumberPath: CoreFormType<T>;
    countryCodePath: CoreFormType<T>;
    phoneNumberNumberPath: CoreFormType<T>;
    disabled? :boolean
} & { labelProps?: CoreFormLabelProps }) {
    const form = useFormContext<T>();

    const countryCodeValue = form.getValues(countryCodePath);

    const {
        phone,
        handlePhoneValueChange,
        inputRef,
        country,
        setCountry,
        inputValue, // it's the 'phone' value but formatted (masked such as +1 (123) 312-3313)
    } = usePhoneInput({
        defaultCountry: countryCodeValue,
        value: form.getValues(phoneNumberNumberPath),
        countries: defaultCountries,
    });

    // Watch every change on the country code and set the value into usePhoneInput state
    useEffect(() => {
        setCountry(countryCodeValue);
    }, [form.watch(countryCodePath)]);

    /**
     * Watch every change on the phone number and set the value on the form state
     */
    useEffect(() => {
        form.setValue(phoneNumberPath, {
            country_code: country?.iso2,
            number: phone,
        });
    }, [country]);

    return (
        <Stack width="100%">
            {labelProps && <CoreFormLabel {...labelProps} />}
            <StyledTextField
                {...muiPhoneNumberProps}
                variant="outlined"
                color="primary"
                placeholder="Enter Phone number..."
                type="tel"
                inputRef={inputRef}
                size="small"
                value={inputValue}
                disabled={disabled}
                onChange={(e) => {
                    handlePhoneValueChange(e);
                    form.setValue(phoneNumberNumberPath, e.target.value);
                }}
                InputProps={{
                    startAdornment: (
                        <StyledAdornment position="start">
                            <CoreSelect<CoreInputPhoneNumberType>
                                {...muiPhoneNumberProps}
                                disabled={disabled}
                                config={{
                                    path: countryCodePath,
                                    enableSearchbox: true,
                                    options: countryOptions,
                                    showOnlyLeftAdornment: true,
                                }}
                            />
                        </StyledAdornment>
                    ),
                }}
                sx={{
                    "& .MuiInputBase-adornedStart": {
                        paddingLeft: 0,
                    },
                }}
            />
        </Stack>
    );
}

CoreInputPhoneNumber.defaultProps = {
    labelProps: null,
};
