import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStore as globalStore } from "../../../components/Global/store/store";
import { useStores } from "../store/rootStore";
import { InitializeContainerInterface } from "../interfaces"

const InitializeContainer = observer((props: InitializeContainerInterface) => {
    const { rootStore, ancillariesStore } = useStores() || {};
    const [globalState] = globalStore() || [{}]

    const { 
        disabled, 
        data,
        handleFormChange = () => {} ,
        children
    } = props;

    const { 
        cmsData = {},
        userPermissions = {} 
    } = globalState;

    useEffect(() => {
        rootStore.initialize({
            cmsData,
            userPermissions,
            disabled
        });

        ancillariesStore.initialize({
            data,
            handleFormChange
        });
    }, []);

    return (
        <>
            {children}
        </>
    )
});

export default InitializeContainer;
