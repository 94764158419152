import React from "react";
import "./_styles.scss";
import _ from "lodash";

interface DisplayNameInterface {
    values: any;
}

const DisplayName = ({ values }: DisplayNameInterface) => {
    const { platform = [], language = [], exclusivity = {} } = values;

    const firstPlatform = _.first(platform);
    const firstLanguage = _.first(language);

    let nameArray: Array<string> = [];
    if (firstPlatform?.text) nameArray.push(firstPlatform.text);
    if (firstLanguage?.text) nameArray.push(firstLanguage.text);
    const name: string = nameArray.join(" • ");

    return (
        <div className="display-name">
            {name}{" "}
            {exclusivity.text && (
                <>
                    • <span className="postfix">{exclusivity.text}</span>
                </>
            )}
        </div>
    );
};

export default DisplayName;
