import React from "react";
import _ from "lodash";
import AssetInterface from "../interfaces/AssetInterface";
import SubDubInterface from "../interfaces/SubDubInterface";
import { AssetTypeSectionInterface } from "../interfaces/AssetTypeInterface";
import DoubleColumnContentExpandable from "../../../../../Boilerplates/DoubleColumnContent/DoubleColumnContentExpandable";
import FieldInterface from '../../../../../../models/RecordBuilder/Field.interface'
// import MapEditableOptionBoolean from "../../../../../Boilerplates/DoubleColumnContent/mapping/MapEditableOptionBoolean";
import ContentFieldInterface from "../../../../../Boilerplates/DoubleColumnContent/ContentField.interface";
import mapField from "../../../../../RecordBuilder/map/doubleColumnContent/mapField";

type SectionProps = {
    asset: AssetInterface | SubDubInterface;
    configuration: AssetTypeSectionInterface;
    expandAll: boolean;
    category: string;
};

const Section: React.FC = (props: SectionProps) => {
    const { asset = {}, configuration, category, expandAll } = props;

    const { displayName, fields = [] } = configuration;

    let configurationFields: ContentFieldInterface[] = [];

    fields
        .filter((field) => !_.get(field, "options.hidden.enabled", false))
        .filter((field) => {
            const hiddenForCategories = _.get(
                field,
                "options.hiddenForCategories",
            );
            return !(
                Array.isArray(hiddenForCategories) &&
                hiddenForCategories.includes(category)
            );
        })
        .forEach((field: FieldInterface) => {
            let fieldConfig = mapField({
                initialField: field,
                value: asset[field.key] || null,
            })
            configurationFields.push(fieldConfig);
        })

    return (
        <div className="asset-section">
            <DoubleColumnContentExpandable
                headerText={displayName}
                fields={configurationFields}
                rowBodyStyles={{ padding: 0, borderRadius: "0.375rem" }}
                handleChange={() => {}}
            />
        </div>
    );
};

export default Section;
