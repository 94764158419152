import React from "react";
import { SectionInterface } from "./interface";
import Links from "../../views/Links";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";

const SectionLinks = (props: SectionInterface) => {
    const { cmsData = {}, data = {}, index } = props;

    const { clientFeatures = {} } = cmsData;

    return (
        <Links
            {...getSectionCommonProps({ ...props, field: "links" })}
            key={index}
            name="links"
            className="meta-record-links"
            title="Links"
            data={data.links}
            multilingual={false}
            clientFeatures={clientFeatures}
        />
    );
};

export default SectionLinks;
