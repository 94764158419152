import React, { Component } from "react";
import Settings from "../Settings";
import _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";

const styles = (theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    formControl: {
        width: "100%",
    },
    disabled: {
        border: 0,
        background: "none",
    },
});

class SimpleSelect extends React.Component {
    constructor(props) {
        super(props);

        /**
         * @var object
         */
        this.state = {
            value: this.props.value || "",
        };
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.value, this.props.value)) {
            this.setValue(this.props.value || "");
        }
    }

    setValue(value) {
        if (value !== this.state.value) {
            this.setState({
                value,
            });
        }
    }

    getSelectProps() {
        const input = () => {
            return (
                <Input
                    name={this.props.name}
                    id={this.props.name}
                    readOnly={this.props.disabled}
                />
            );
        };

        const inputMulti = () => {
            return <Input id="select-multiple-checkbox" />;
        };

        let result = {
            value: this.state.value,
            onChange: this.handleChange.bind(this),
            disabled: this.props.readOnly || false,
            disabled: this.props.disabled || false,
            inputProps: {
                name: this.props.name,
                id: this.props.id,
            },
            input: input(),
        };

        if (this.props.multiple) {
            result.multiple = true;
            result.input = inputMulti();
            result.renderValue = (selected) => selected.join(", ");
        }

        return result;
    }

    handleChange(e) {
        if (e) {
            if (e.target) {
                if (!_.isEqual(this.state.value, e.target.value)) {
                    this.setState({
                        value: e.target.value,
                    });

                    // Callback
                    if (typeof this.props.handleChange == "function") {
                        this.props.handleChange(e.target.value);
                    }
                }
            }
        }
    }

    render() {
        const { classes } = this.props;
        const selectProps = this.getSelectProps() || {};

        return (
            <div className={classes.root}>
                <FormControl
                    className={classes.formControl}
                    disabled={this.props.disabled}
                    classes={{
                        root: this.props.disabled ? "meta-select-disabled" : "",
                    }}
                >
                    {this.props.label && !this.props.disabled && (
                        <InputLabel htmlFor={this.props.id}>
                            {this.props.label}
                        </InputLabel>
                    )}

                    <Select {...selectProps} className={classes.formControl}>
                        {this.props.disabledText && !this.props.disabled && (
                            <MenuItem value={"disabled_value"} disabled>
                                {this.props.disabledText}
                            </MenuItem>
                        )}

                        {this.props.disabled && this.props.noneSelectedText && (
                            <MenuItem value={"disabled_value"} disabled>
                                {this.props.noneSelectedText}
                            </MenuItem>
                        )}

                        {this.props.data.map((item, index) => {
                            let value = item;
                            let text = item;

                            if (typeof item == "object") {
                                value = "";
                                text = "";

                                if (
                                    item.value !== undefined &&
                                    item.text !== undefined
                                ) {
                                    value = item.value;
                                    text = item.text;
                                }

                                if (
                                    item.value !== undefined &&
                                    item.text == undefined
                                ) {
                                    value = item.value;
                                    text = item.value;
                                }

                                if (
                                    item.value == undefined &&
                                    item.text !== undefined
                                ) {
                                    value = item.text;
                                    text = item.text;
                                }
                            }

                            if (this.props.multiple) {
                                return (
                                    <MenuItem key={value} value={text}>
                                        <Checkbox
                                            checked={
                                                this.state.value.indexOf(
                                                    value,
                                                ) > -1
                                            }
                                            color="primary"
                                        />

                                        <ListItemText primary={text} />
                                    </MenuItem>
                                );
                            }

                            if (!this.props.multiple) {
                                return (
                                    <MenuItem key={index} value={value}>
                                        {text}
                                    </MenuItem>
                                );
                            }
                        })}
                    </Select>
                </FormControl>
            </div>
        );
    }
}

export default withStyles(styles)(SimpleSelect);
