import React from "react";
import { SectionInterface } from "./interface";
import Financials from "../../views/Financials";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";

const SectionFinancials = (props: SectionInterface) => {
    const { data = {}, pageType = [] } = props;

    return (
        <Financials
            {...getSectionCommonProps({ ...props, field: "financials" })}
            name="financials"
            className="meta-record-financials"
            title="Financials"
            data={data.financials || []}
            rights={data.rights || []}
            noBorder={true}
            lastSection={true}
            isLicensorMandatory={!["connect", "report"].includes(pageType)}
        />
    );
};

export default SectionFinancials;
