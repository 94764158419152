import React from "react";
import ActionButton from "../ActionButton";

const ButtonGroup = (props) => {
    const {
        items = [],
        wrapperBackground = "#e2e2e2",
        activeBackground = "#8f8f8f",
        background = "#e2e2e2",
        color = "#949393",
        activeColor = "white",
        styles = {},
        action = true,
    } = props;

    const style = {
        display: "flex",
        background: wrapperBackground,
        borderRadius: "0.2rem",
        ...styles,
    };

    const buttonProps = {
        margin: "0.125rem",
        boxShadow: "none",
    };

    return (
        <div style={style}>
            {items.map((item, index) => (
                <ActionButton
                    key={index}
                    text={item.text}
                    {...buttonProps}
                    background={item.active ? activeBackground : background}
                    hoverBackground={activeBackground}
                    color={item.active ? activeColor : color}
                    hoverColor={activeColor}
                    onClick={() => item.onClick()}
                />
            ))}
        </div>
    );
};

export default ButtonGroup;
