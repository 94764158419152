import React, { useEffect, useState } from "react";
import _ from "lodash";
import Notification from "../../Notification";
import ContentLoading from "../../ContentLoading";
import SelectWithTag from "../../SelectWithTag";
import CategoryTags from "../../CategoryTags";
import CyclesV2 from "../../Cycles/v2";
import { MonthYearDates } from "../../Helpers/MonthYearDates";
import CurrentMonthYear from "../../Helpers/CurrentMonthYear";
import moment from "moment";
import isIndexInArray from "../../Helpers/isIndexInArray";
import MarginsV2 from "../../Financials/src/MarginsV2";
import Settings from "../../Settings";
import AddNewButtonWithLineMultiple from "../../AddNewButtonWithLine/Multiple";
import DownloadFileWithFetch from "../../File/DownloadFileWithFetch";
import { disabledSection } from "../config";
import FadeWithSkeleton from "../../SkeletonLoader/FadeWithSkeleton";

const CyclesV2View = (props) => {
    const [data, setData] = useState([]);
    const [notification, setNotification] = useState({});
    const [fetchingData, setFetchingData] = useState(true);
    const [period, setPeriod] = useState(CurrentMonthYear());
    const [dates, setDates] = useState(MonthYearDates());
    const [transformedData, setTransformedData] = useState([]);
    const [monthStats, setMonthStats] = useState({});
    const [downloading, setDownloading] = useState(false);

    const { copy = {}, state = {}, disabled } = props;

    const { handleFetchCyclesV2 = () => {}, set } = state;

    const FetchCyles = (period) => {
        setFetchingData(true);

        handleFetchCyclesV2({
            period,
            handleError: () => {
                setFetchingData(false);
                setNotification({
                    title: copy.technicalError || "Techincal Error",
                    description:
                        "Failed to fetch cycles, please refresh to retry",
                });

                setTransformedData([]);
                setMonthStats({});
            },
            handleSuccess: (response) => {
                const data = response.data || [];

                setData(data || []);
                setFetchingData(false);

                const transformedData = data.map((item) => {
                    const margins = MarginsV2(item);

                    return {
                        ...item,
                        ...margins,
                    };
                });

                const categories = [
                    CategoryTags.Feature.short,
                    CategoryTags.Show.short,
                    CategoryTags.Episode.short,
                    CategoryTags.ConnectCollection.short,
                ];

                const countedItems = transformedData.filter((item) =>
                    isIndexInArray(categories, item.category),
                );

                let totalSellRate = 0;
                let totalBuyRate = 0;
                countedItems.map((item) => {
                    if (item.hasOwnProperty("total_sell_rate"))
                        totalSellRate += parseFloat(item.total_sell_rate);
                    if (item.hasOwnProperty("total_buy_rate"))
                        totalBuyRate += parseFloat(item.total_buy_rate);
                });
                let overallMargin = 0;
                if (totalSellRate !== 0) {
                    overallMargin =
                        ((totalSellRate - totalBuyRate) / totalSellRate) * 100;
                }

                const monthStats = {
                    month: moment(period).format("MMMM"),
                    total_sell_rate: parseFloat(totalSellRate.toFixed(2)),
                    total_buy_rate: parseFloat(totalBuyRate.toFixed(2)),
                    overall_margin: parseFloat(overallMargin.toFixed(2)),
                    features: transformedData.filter(
                        (item) => item.category == CategoryTags.Feature.short,
                    ),
                    shows: transformedData.filter(
                        (item) => item.category == CategoryTags.Show.short,
                    ),
                    episodes: transformedData.filter(
                        (item) => item.category == CategoryTags.Episode.short,
                    ),
                };

                setTransformedData(transformedData);
                setMonthStats(monthStats);
            },
        });
    };

    useEffect(() => {
        FetchCyles(period);
    }, []);

    const handlePeriodChange = (value) => {
        setPeriod(value);
        FetchCyles(value);
    };

    const HandleStartDownload = (item) => {
        setDownloading(true);
        DownloadFileWithFetch({
            ...item,
            handleSuccess: () => {
                setDownloading(false);
            },
            handleError: () => {
                setDownloading(false);
            },
        });
    };

    const GetExportApiUrl = () => {
        let result = "";
        const { state = {} } = props;

        const { cmsData = {}, activeRecord } = state;

        const { meta_id, name } = activeRecord;

        const { apiUrls = {} } = cmsData;

        const { lineupFinancialsCSVExport = "" } = apiUrls;

        if (!_.isEmpty(lineupFinancialsCSVExport)) {
            result =
                lineupFinancialsCSVExport
                    .replace("{usergroup_meta_id}", meta_id)
                    .replace("{period}", period) +
                "/full?user_group_name=" +
                name;
        }

        return result;
    };

    const { activeRecord = {} } = state;

    const filename = `${
        activeRecord.name || "record"
    }-${period}-LineupFinancialData.csv`;

    return (
        <div
            className={`meta-home-screen-cycles ${
                disabled ? disabledSection : ""
            }`}
        >
            <FadeWithSkeleton loading={fetchingData} />

            <div className="meta-record-body--title-content">
                <h2>Cycles</h2>
                <div className="container-flex container-align-center container-margin-left-auto">
                    <AddNewButtonWithLineMultiple
                        buttons={[
                            {
                                id: "meta-csv-lineup-export-button",
                                title: "Export Usergroup Financial Data",
                                loading: downloading,
                                onClick: () =>
                                    HandleStartDownload({
                                        url: GetExportApiUrl(),
                                        method: "GET",
                                        filename,
                                    }),
                            },
                        ]}
                        leftIcon={
                            Settings.images.path + "/svg/down-arrow-white.svg"
                        }
                        className="meta-cyclesV2-financials-export-btn"
                    />
                    <SelectWithTag
                        id="month_year_date"
                        value={period}
                        clear={true}
                        data={dates}
                        group={true}
                        displayTagOnDisabled={true}
                        disabled={fetchingData}
                        loading={fetchingData}
                        width="14.15625rem"
                        noMargin={true}
                        wrapperStyles={{
                            margin: "0 0 0 0.6875rem",
                        }}
                        handleChange={(value) => handlePeriodChange(value)}
                    />
                </div>
            </div>

            {!fetchingData && (
                <CyclesV2
                    data={transformedData}
                    monthStats={monthStats}
                    styles={{
                        marginTop: "1.625rem",
                    }}
                />
            )}

            <Notification
                title={notification.title}
                description={notification.description}
                intercationStatus="alert"
                onClick={setNotification.bind(this, {})}
                okText={copy.ok || "Ok"}
            />
        </div>
    );
};

export default CyclesV2View;
