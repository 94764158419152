import { gql } from "@apollo/client";
import { FieldMapInterface } from "../../interfaces";

const properties = `
    id
    displayName
    key
    type
    archived
    order
    dataType
    categories
    options {
        editable {
            enabled
            inputType
            minLength
            maxLength
            booleanDisplay {
                type
                trueLabel
                falseLabel
            }
            optionsManagedList {
                enabled
                key
                textField
                valueField
                minimumSelectLimit
                maximumSelectLimit
                dynamic
            }
        }
        unique {
            enabled
        }
        default {
            enabled
            value
        }
    }
`;

export const schemaGetAll = `
    getSchedulingConfigs {
        ${properties}
    }
`

export const queryGetAll = gql`
    query {
        ${schemaGetAll}
    }
`;

const map: FieldMapInterface = {
    queryAll: queryGetAll,
    dataPath: "getSchedulingConfigs",
}

export default map
