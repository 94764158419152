import _ from "lodash";

const HandleSetCollectionTitleKey = (props) => {
    const { key, value, state = {} } = props;

    const { set, collectionTitle = {} } = state;

    if (typeof collectionTitle == "object" && key) {
        let collectionTitleState = collectionTitle;
        collectionTitleState[key] = value;

        set({
            collectionTitle: collectionTitleState,
        });
    }
};

export default HandleSetCollectionTitleKey;
