import React from "react";
import _ from "lodash";

const RenderValue = ({ data = {}, field = {} }) => {
    const { displayType = "" } = field;
    
    if (
        field.component_type == "date_time" &&
        !_.isEmpty(field.date_format) &&
        !_.isEmpty(data[field.key])
    ) {
        return data[field.key]
    }

    if (displayType === "tag") {
        if (!_.isEmpty(data[field.key])) {
            if (typeof data[field.key] === "object") {
                return (
                    <React.Fragment>
                        {Object.keys(data[field.key]).map((keyName) => (
                            <span>{data[field.key][keyName]}</span>
                        ))}
                    </React.Fragment>
                );
            }

            return <span>{data[field.key]}</span>;
        }

        return "None";
    }

    return data[field.key];
};

export default RenderValue;
