import { observer } from "mobx-react";
import { CoreSelect } from "../../../../../../core/Form/components/inputs/Select/components/CoreSelect";
import { PrimaryVT } from "../../../../config/types";
import { useProvenanceOptions } from "../../../../hooks/useProvenanceOptions";
import { HideFormConditionally } from "./HideFormConditionally";

export const Provenance = observer(() => {
    const provenanceOptions = useProvenanceOptions();
    return (
        <HideFormConditionally>
            <CoreSelect<PrimaryVT>
                config={{
                    path: "provenance",
                    labelProps: {
                        text: "Provenance",
                        isRequired: true,
                    },
                    options: provenanceOptions,
                    enableSearchbox: true,
                    multiple: false,
                }}
            />
        </HideFormConditionally>
    );
});
