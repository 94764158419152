import { CmsInterface } from "../../components/Global/interfaces";

export function getCustomCategories(cmsData: CmsInterface): string[] {
    let customCategories = cmsData.clientDataStructure?.customCategories;

    if (cmsData.clientFeatures?.disabledCustomCategories) {
        customCategories = customCategories?.filter(
            (category: string) => !cmsData.clientFeatures.disabledCustomCategories.includes(category)
        );
    }

    return customCategories || [];
}
