import React from "react";
import Tag from "../../Tag";

const RenderEpisodeNumbertag = (episode_number) => {
    if (episode_number) {
        return (
            <Tag
                text={episode_number}
                className="meta-numbered-record-table--tags"
                background="transparent"
                color="#9a9a9a"
                styles={{
                    padding: "0.1369rem 0.3rem",
                    border: "0.0625rem solid #9a9a9a",
                }}
            />
        );
    }

    return false;
};

export default RenderEpisodeNumbertag;
