import _ from "lodash";
import AssetCompliances from "../../ClientManagedOptions/asset_compliances";

function generateNewAsset(system, assets, extra, params = {}) {
    const { system_type, system_name = {}, default_status } = system;

    const { options = {}, dataStructure = {}, clientFeatures = {} } = params;

    if (system_name.value == "Sony Media Backbone") {
        return generateNewAssetForTmc(system, assets, extra);
    }

    const newAssetTypes = AssetCompliances({
        options,
        clientFeatures,
        dataStructure,
    });

    return assets.map((asset) => {
        const assetType =
            newAssetTypes.find((type) => type.value === asset) || {};

        return {
            system_type,
            system_name: system_name.value,
            system_enviromnent: getSystemEnvironment(),
            asset_type: assetType.value,
            compliance_code: assetType.code,
            status: default_status || "pre-saved",
            ...extra,
        };
    });
}

function generateNewAssetForTmc(system, assets, extra) {
    const {
        system_type,
        system_name = {},
        asset_types = {},
        default_status,
    } = system;

    const commonAttr = {
        system_type,
        system_name: system_name.value,
        system_enviromnent: getSystemEnvironment(),
        status: default_status || "pre-saved",
        ...extra,
    };

    const masterAssetType =
        Array.isArray(asset_types.Masters) && asset_types.Masters.length == 1
            ? asset_types.Masters[0]
            : {};

    const orig = {
        ...commonAttr,
        asset_type: "Original Master",
        compliance_code: masterAssetType.code || "ORIG",
        compliances: [
            {
                ...commonAttr,
                asset_type: "Normalised",
                compliance_code: "NORM",
            },
        ],
    };

    const assetTypes = Object.entries(asset_types)
        .filter(([key]) => key !== "Masters")
        .flatMap(([_, values]) => values);

    const compliances = assets
        .map((asset) => {
            const assetType = assetTypes.find((type) => type.value === asset);
            return assetType
                ? {
                      ...commonAttr,
                      asset_type: assetType.value,
                      compliance_code: assetType.code,
                  }
                : null;
        })
        .filter((asset) => asset);

    orig.compliances.push(...compliances);

    return [orig];
}

export function getSystemEnvironment() {
    const env = process.env.APP_ENV || null;
    switch (env) {
        case "QA":
            return "Staging";
        case "Live":
            return "Production";
        default:
            return "Development";
    }
}

export default generateNewAsset;
