import { SET_RECORD_CHANGES_V2 } from "../actions/action_types";
import { defaultRecordChangesV2 } from "../initialState";

export default function (
    state = { recordChangesV2: defaultRecordChangesV2 },
    action,
) {
    switch (action.type) {
        case SET_RECORD_CHANGES_V2:
            return Object.assign({}, state, {
                recordChangesV2: action.recordChangesV2,
            });
    }

    return state;
}
