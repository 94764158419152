import Prefixer from "inline-style-prefixer";
import Settings from "../../Settings";

export default function styles(props = {}) {
    let defaultColor = "rgba(0, 0, 0, 0.87)";
    if (props.blue) defaultColor = "white";

    let color = props.color || defaultColor;

    const defaultValues = {
        fontSize: props.fontSize || "1.05rem",
        padding: props.padding || "0.90625rem",
        margin: props.margin || "1.5rem 0 0",
        width: props.width || "auto",
        maxWidth: props.maxWidth || "none",
        minWidth: props.minWidth || "auto",
        background: props.background || Settings.colors.green.light,
        color,
        boxShadow:
            props.boxShadow ||
            "0 0 0.0625rem 0 rgba(0, 0, 0, 0.12), 0px 0.0625rem 0.0625rem 0 rgba(0, 0, 0, 0.24)",
        display: props.display || "block",
        letterSpacing: props.letterSpacing || "0.03125rem",
        fontWeight: props.fontWeight || 500,
        lineHeight: props.lineHeight || 1,
        textTransform: props.textTransform || "uppercase",
        transition: props.transition || "0.35s",
        border: props.border || "0",
        textAlign: props.textAlign || "center",
        borderRadius: props.borderRadius || "0.2rem",
        appearance: "none",
        outline: "none",
        cursor: "pointer",
        visibility: props.visibility || "visible",
    };

    let styles = {
        default: defaultValues,
        primary: {
            background: Settings.colors.green.light,
            margin: props.margin || "0.65625rem 0 0 0",
            padding: props.padding || "0.59375rem 1.96875rem",
        },
        secondary: {
            background: Settings.colors.grey.darker,
            margin: "0.65625rem 0 0 0",
            padding: "0.59375rem 1.96875rem",
        },
        warning: {
            background: Settings.colors.amber.default,
            margin: "0.65625rem 0 0 0",
            padding: "0.59375rem 1.96875rem",
        },
        buttonEdit: {
            background: Settings.colors.grey.darker,
            margin: props.margin || "0.65625rem 0 0 0",
            padding: props.padding || "0.59375rem 1.96875rem",
        },
        buttonDelete: {
            background: Settings.colors.red.medium,
            color: "rgba(255, 255, 255, 0.87)",
            margin: "0.65625rem 0 0 0",
            padding: "0.59375rem 1.96875rem",
        },
        buttonCancel: {
            background: Settings.colors.red.medium,
            color: "rgba(255, 255, 255, 0.87)",
            margin: "0.65625rem 0 0 0",
            padding: "0.59375rem 1.96875rem",
        },
        actionButtonDelete: {
            background: Settings.colors.grey.lighter5,
            padding: "0.3125rem",
            margin: "0 0 0 0.3125rem",
        },
        actionButtonPrimary: {
            background: Settings.colors.grey.lighter5,
            padding: "0.3125rem",
            margin: "0 0 0 0.3125rem",
        },
        enrichmentButton: {
            background: Settings.colors.enrichment.background,
            color: Settings.colors.enrichment.color,
            margin: "0.65625rem 0 0 0",
            padding: "0.59375rem 1.96875rem",
        },
        fluid: {
            margin: "0",
            width: "100%",
        },
        buttonText: {
            background: "transparent",
            boxShadow: "none",
            color: "white",
            display: "inline-block",
            margin: "0",
        },
        buttonWelcome: {
            fontSize: "1.05rem",
            fontWeight: 500,
            textTransform: "uppercase",
        },
        red: {
            background: Settings.colors.red.medium,
            color: "rgba(255, 255, 255, 0.87)",
        },
        grey: {
            background: Settings.colors.grey.darker,
        },
        amber: {
            background: Settings.colors.amber.default,
        },
        blue: {
            background: Settings.colors.blue.medium,
        },
        orange: {
            background: Settings.colors.orange.medium,
            color: "white",
        },
        hover: {
            background: props.hoverBackground || Settings.colors.green.lighter,
            color: props.hoverColor || color,
            border: props.hoverBorder || defaultValues.border,
        },
        hoverPrimary: {
            background: Settings.colors.green.lighter,
        },
        hoverSecondary: {
            background: Settings.colors.grey.lighter2,
        },
        hoverWarning: {
            background: Settings.colors.hover.amber,
        },
        hoverButtonEdit: {
            background: Settings.colors.grey.lighter2,
        },
        hoverButtonDelete: {
            background: Settings.colors.red.medium,
            color: defaultColor,
        },
        hoverButtonCancel: {
            background: Settings.colors.red.medium,
            color: defaultColor,
        },
        hoverEnrichmentButton: {
            background: Settings.colors.enrichment.backgroundHover,
            color: Settings.colors.enrichment.color,
        },
        hoverActionButtonDelete: {
            background: Settings.colors.red.default,
            color: "white",
        },
        hoverActionButtonPrimary: {
            background: Settings.colors.grey.lighter2,
            color: "white",
        },
        hoverButtonText: {
            background: "transparent",
            boxShadow: "none",
            color: "white",
            display: "inline-block",
            margin: "0",
        },
        hoverRed: {
            background: Settings.colors.hover.red,
        },
        hoverGrey: {
            background: Settings.colors.grey.lighter2,
        },
        hoverAmber: {
            background: Settings.colors.hover.amber,
        },
        hoverBlue: {
            background: Settings.colors.blue.dark,
        },
        hoverOrange: {
            background: Settings.colors.orange.dark,
            color: "white",
        },
        disabled: {
            background:
                props.disabledBackground || Settings.colors.grey.lighter3,
            color: props.disabledColor || "rgba(0, 0, 0, 0.87)",
        },
        increaseOnHover: {
            transform: "scale(1.01)",
        },
        disabledOpacity: {
            background: props.background || Settings.colors.green.light,
            color: props.color || "rgba(0, 0, 0, 0.87)",
            opacity: 0.4,
        },
        content: {
            position: "relative",
            display: "flex",
            alignItems: "center",
            textAlign: props.textAlign || "center",
            width: "100%",
        },
        text: {
            width: "100%",
            display: "block",
            textAlign: props.textAlign || "center",
            fontWeight: props.fontWeight || 500,
        },
        textTransparent: {
            opacity: 0,
            position: "relative",
            textAlign: props.textAlign || "center",
        },
        loading: {
            maxHeight: props.fontSize || "1.05rem",
            display: "block",
            margin: "auto",
            position: "absolute",
            left: "50%",
            WebkitTransform: "translateX(-50%)",
            transform: "translateX(-50%)",
            width: props.loadingIconWidth || "auto",
        },
        leftIcon: {
            width: "1.09375rem",
            marginRight: "0.40625rem",
        },
        rightIcon: {
            width: "1.09375rem",
            marginLeft: "0.40625rem",
        },
        marginLeftTop: {
            margin: "0.65625rem 0 0 0.65625rem",
        },
    };

    let prefixer = new Prefixer();
    styles = prefixer.prefix(styles);

    return styles;
}
