import IPlatformScheduling from "./platforms/PlatformSchedulingInterface";

export const bbcScheduleInformationSectionConfig = (item: IPlatformScheduling) => [
    {
        name: "Name",
        value: item.name,
        type: "string",
    },
    {
        name: "EPV Display Name",
        value: item.evp_display_name,
        type: "string",
    },
    {
        name: "Asset External Id",
        value: item.asset_external_id,
        type: "string",
    },
    {
        name: "Brand Id",
        value: item.brand_id,
        type: "string",
    },
    {
        name: "Series/Collection Id",
        value: item.series_collection_id,
        type: "string",
    },
    {
        name: "Series/Collection External Id",
        value: item.series_collection_external_id,
        type: "string",
    },
    {
        name: "ID *",
        value: item.bbc_id,
        type: "string",
    },
    {
        name: "Scheduling Id",
        value: item.schedule_id,
        type: "string",
    },
    {
        name: "Scheduling Tags",
        value: item.scheduling_tags,
        type: "tag",
    },
];

export const bbcScheduleWindowSectionConfig = (item: IPlatformScheduling) => [
    {
        name: "Type",
        value: item.type,
        type: "string",
    },
    {
        name: "Asset Name",
        value: item.asset_name,
        type: "string",
    },
    {
        name: "Asset Type",
        value: item.asset_type,
        type: "string",
    },
    {
        name: "Asset Description",
        value: item.asset_description,
        type: "string"
    },
    {
        name: "Asset Runtime",
        value: item.asset_runtime,
        type: "string"
    },
    {
        name: "Platform",
        value: item.platform,
        type: "string",
    },
    {
        name: "Put Up Date",
        value: item.put_up_date,
        type: "datetime",
        dateFormat: "Do MMMM YYYY, HH:mm",
    },
    {
        name: "Take Down Date",
        value: item.take_down_date,
        type: "datetime",
        dateFormat: "Do MMMM YYYY, HH:mm",
    },
    {
        name: "Schedule Entry: Offer Type",
        value: item.schedule_entry_offer_type,
        type: "string"
    },
    {
        name: "Schedule Entry: Scheduled For The Drop",
        value: item.schedule_entry_for_the_drop,
        type: "string",
    },
];
