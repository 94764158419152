import React, { Fragment } from "react";
import moment from "moment";
import RenderValue from "./RenderValue";

export const ExpandableRowHeader = (props) => {
    const { data = [], dataStructure = {}, icon = {}, edit = false } = props;

    const headerValues = dataStructure.map((item, key) => {
        return {
            label: item.title,
            stateKey: key,
            placeholder: item.placeholder,
            value: RenderValue({ data, field: item }),
            disabled: !edit,
            key: item.key,
        };
    });

    return (
        <div className="title">
            <div className="left-content">
                {headerValues.map((header, index) => (
                    <Fragment>
                        <span className={header.key}>{header.value}</span>
                        {index === headerValues.length - 1 ? (
                            ""
                        ) : (
                            <span className="bullet">
                                {header.value && ` • `}
                            </span>
                        )}
                    </Fragment>
                ))}
            </div>
            <div className="right-content">{icon}</div>
        </div>
    );
};
