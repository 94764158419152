import React from "react";
import "./_styles.scss";

type Props = {
    color: string;
};

const CubeShape: React.FC<Props> = ({ color }) => {
    return <div className="figure-cube" style={{ background: color }}></div>;
};

export default CubeShape;
