import actionButton from "./actionButton";
import actionButtonLarge from "./actionButtonLarge";
import titleValue from "./titleValue";
import label from "./label";
import secondaryLabel from "./secondaryLabel";
import input from "./input";
import regional from "./regional";
import recordCategoryTag from "./recordCategoryTag";
import noneEnteredPlaceholder from "./noneEnteredPlaceholder";
import table from "./table";
import smallTransparentBubble from "./smallTransparentBubble";
import credits from "./credits";

const components = {
    actionButton,
    actionButtonLarge,
    titleValue,
    label,
    secondaryLabel,
    input,
    recordCategoryTag,
    noneEnteredPlaceholder,
    table,
    regional,
    smallTransparentBubble,
    credits,
};

export default components;
