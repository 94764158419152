import HandleDeleteUserGroupRequest from "./HandleDeleteUserGroupRequest";
import HandleUserGroupSearchRequest from "./HandleUserGroupSearchRequest";

const HandleDeleteUserGroup = (props) => {
    const { state = {} } = props;

    const { set, recordData = {} } = state;

    const { name = "this user group", meta_id } = recordData;

    const notificationProps = {
        title: "Delete user group",
        description: `Are you sure you want to delete ${name}?`,
        okText: "Delete",
        status: "alert",
    };

    set({
        notification: {
            ...notificationProps,
            confirm: () => {
                set({
                    notification: {
                        ...notificationProps,
                        loading: true,
                    },
                });

                HandleDeleteUserGroupRequest({
                    state,
                    meta_id,
                    handleSuccess: () => {
                        set(
                            {
                                notification: {},
                                recordData: {},
                                activeRecord: {},
                                editRecord: false,
                            },
                            () => {
                                HandleUserGroupSearchRequest({
                                    state,
                                });
                            },
                        );
                    },
                    handleError: () => {
                        set({
                            notification: {
                                title: "Technical Error",
                                description:
                                    "Failed to delete user group, please try again.",
                                okText: "OK",
                                status: "alert",
                                confirm: () => {
                                    set({
                                        notification: {},
                                    });
                                },
                            },
                        });
                    },
                });
            },
        },
    });
};

export default HandleDeleteUserGroup;
