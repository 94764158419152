import { Stack, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { observer } from "mobx-react";
import React from "react";
import { CoreAccordion } from "../../../../core/Accordion/CoreAccordion";
import { CoreDialog } from "../../../../core/Dialog/CoreDialog";
import { Components } from "../../../../core/QA/Components";
import { generateTestId } from "../config/CreateDataTestId";
import { useBackgroundProcessingStore } from "../store/useBackgroundProcessingStore";
import { BackgroundProcessingErrorType, ProcessIdType } from "../types";
import { ClearButton } from "./Clear.button";

interface ErrorDetailsAccordionProps {
    error: BackgroundProcessingErrorType; // Replace 'any' with the actual type of error if known
    processId: string; // Replace 'string' with the actual type of processId if known
}

const ErrorDetailsAccordion: React.FC<ErrorDetailsAccordionProps> = observer(
    ({ error, processId }) => {
        const store = useBackgroundProcessingStore();
        const errorRows = store.getFlattenErrors(error);

        return (
            <Stack direction="column" spacing={1}>
                {errorRows.map((err) => {
                    return (
                        <Stack key={err} direction="row" spacing={1}>
                            <Typography
                                data-testid={generateTestId(
                                    Components.text,
                                    "error-status",
                                )}
                                sx={{
                                    color: (theme) =>
                                        theme.palette.secondary.main,
                                    fontWeight: 700,
                                }}
                            >
                                {error?.status}
                            </Typography>
                            <Typography
                                data-testid={generateTestId(
                                    Components.text,
                                    "error-message",
                                )}
                                sx={{
                                    color: (theme) =>
                                        theme.palette.secondary.main,
                                }}
                            >
                                {err}
                            </Typography>
                        </Stack>
                    );
                })}
                <ClearButton processId={processId} fullWidth />
            </Stack>
        );
    },
);

type ErrorItemProps = {
    index: number;
    error: BackgroundProcessingErrorType;
} & ProcessIdType;
const ErrorItem = observer(({ index, error, processId }: ErrorItemProps) => {
    const store = useBackgroundProcessingStore();
    const recordTitle = store.getRecordTitleForError(processId, error);

    return (
        <CoreAccordion
            data-testid={generateTestId(Components.accordion, "error-item")}
            items={[
                {
                    title: recordTitle,
                    content: (
                        <ErrorDetailsAccordion
                            error={error}
                            processId={processId}
                        />
                    ),
                },
            ]}
        />
    );
});

const ErrorItemList = observer(({ processId }: ProcessIdType) => {
    const store = useBackgroundProcessingStore();

    return (
        <Stack direction="column" spacing={2.75}>
            {store.getProcessErrors(processId)?.map((error, index) => (
                <ErrorItem
                    key={JSON.stringify(error)}
                    processId={processId}
                    error={error}
                    index={index}
                />
            ))}
        </Stack>
    );
});

export const Details = observer(({ processId }: ProcessIdType) => {
    const store = useBackgroundProcessingStore();

    const closeDialog = () => {
        store.setProcessIdDetails(null);
    };

    return (
        <CoreDialog
            data-testid={generateTestId(Components.dialog, "details")}
            open={!isEmpty(processId)}
            setOpen={closeDialog}
            subTitle="This dialog highlights issues encountered during record creation that prevented certain records from being added to the catalog. Below, you'll find specific errors detailing the issues."
            buttons={{
                submit: {
                    handleOnSubmit: () => {
                        store.clearErrorsForProcess(processId);
                        closeDialog();
                    },
                    label: "Clear Issues",
                },
                cancel: {
                    label: "Cancel",
                },
            }}
            title="Background Processing Errors"
            dialogMaxWidth="37.5rem"
            dialogContentWidth="57.5rem"
        >
            <ErrorItemList processId={processId} />
        </CoreDialog>
    );
});
