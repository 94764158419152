import React from "react";
import AgeGuidanceInterface from "../interfaces/RegionalAgeGuidanceRow.interface";
import AgeGuidanceRow from "./AgeGuidanceRow";

type Props = {
    data: Array<AgeGuidanceInterface>;
    type: string;
};

const Section: React.FC<Props> = (props): JSX.Element => {
    const { data = [], type } = props;

    return (
        <div className="age-guidance-section">
            <div className="item-block">
                {data.map((item: AgeGuidanceInterface, index: number) => {
                    return (
                        <AgeGuidanceRow
                            index={index}
                            ageGuidance={item}
                            type={type}
                            key={index}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default Section;
