import { MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CoreFormMenuItem = styled(MenuItem)(({ theme }) => ({
    '&.Mui-selected': {
        backgroundColor: 'rgb(40, 108, 210)',
        borderRadius: '0.21875rem',
        color: 'white',
        justifyContent: 'center',
    },
    '&.Mui-selected:hover': {
        backgroundColor: 'rgb(40, 108, 210)',
    }
}));