import React from "react";
import "./_styles.scss";
import ActiveLicenceCheck from "../../../../components/ActiveLicenceCheck";

interface ActiveInactiveTagInterface {
    values: any;
}

const ActiveInactiveTag = ({ values }: ActiveInactiveTagInterface) => {
    const statusActive: boolean = ActiveLicenceCheck(
        values.license_start,
        values.license_end,
    );

    return (
        <div
            className={`active-inactive-tag ${
                statusActive ? "active" : "inactive"
            }`}
        >
            {statusActive ? "Active" : "Inactive"}
        </div>
    );
};

export default ActiveInactiveTag;
