import React, { useState, useEffect } from "react";
import _ from "lodash";
import Select from "../../Select";
import Settings from "../../Settings";
import DoubleGridList from "../../CheckboxTag/DoubleGridList";
import TitleWithImage from "../../TitleWithImage";
import Title from "../../Title";
import RadioButtonOptions from "../../RadioButtonOptions";
import FadeIn from "../../TransitionGroup/FadeIn";
import generateNewAsset from "./GenerateNewAsset";
import AssetCompliances, {
    isClientManagedAssetCompliances,
} from "../../ClientManagedOptions/asset_compliances";

const CreateNewForm = (props) => {
    const {
        data = [],
        options,
        onChange = () => {},
        dataStructure,
        clientFeatures,
    } = props;

    const { asset_management = [] } = options;

    const clientManaged = isClientManagedAssetCompliances({ clientFeatures });
    const clientManagedCompliances = AssetCompliances({ options });

    const dataStructureSystemNames = dataStructure.map(
        (structure) => structure.system_name,
    );
    const systemNames = asset_management
        .map((system) => system.system_name)
        .filter(({ value }) => dataStructureSystemNames.includes(value));

    const [selectedSystemName, setSelectedSystemName] = useState(
        systemNames[0].value,
    );
    const currentSystem = asset_management.find(
        (system) => system.system_name.value === selectedSystemName,
    );

    let assetOptions = currentSystem.asset_types;
    if (_.isObject(assetOptions)) {
        assetOptions = Object.values(assetOptions).flat(1);
    }

    if (clientManaged && !_.isEmpty(clientManagedCompliances)) {
        assetOptions = clientManagedCompliances.filter(
            (item) => item.systems.includes(selectedSystemName)
        );
    }

    let dummyAssetTypes,
        selectedDisabled = [];
    if (selectedSystemName === "Sony Media Backbone") {
        dummyAssetTypes = currentSystem.asset_types.Masters;
    } else {
        dummyAssetTypes = [
            { text: selectedSystemName, value: selectedSystemName },
        ];
        selectedDisabled = data
            .filter((asset) => asset.system_name === selectedSystemName)
            .map((asset) => asset.asset_type);
    }

    const [popularOnly, setPopularOnly] = useState(true);

    // popular
    if (popularOnly && !clientManaged) {
        assetOptions = assetOptions.filter((item) => item.popular);
    }

    if (popularOnly && clientManaged) {
        assetOptions = assetOptions.filter((item) => item.popular);
    }

    const [selectedAssets, setSelectedAssets] = useState([]);
    const [registerPerformanceEidr, setRegisterPerformanceEidr] =
        useState(false);

    useEffect(() => {
        const validToSubmit =
            selectedSystemName === "Sony Media Backbone" ||
            selectedAssets.length > 0;

        const newAsset = validToSubmit
            ? generateNewAsset(
                  currentSystem,
                  selectedAssets,
                  {
                      register_performance_eidr: registerPerformanceEidr,
                  },
                  {
                      options,
                      dataStructure,
                      clientFeatures,
                  },
              )
            : null;

        onChange(newAsset, validToSubmit);
    }, [selectedSystemName, selectedAssets, registerPerformanceEidr]);

    const handleSystemNameSelect = (newValue) => {
        setSelectedSystemName(newValue);
        setPopularOnly(true);
        setSelectedAssets([]);
        setRegisterPerformanceEidr(false);
    };

    const handleAssetSelect = (newAsset) => {
        const newSelectedAssets = [...selectedAssets];

        const index = newSelectedAssets.indexOf(newAsset);
        if (index >= 0) {
            newSelectedAssets.splice(index, 1);
        } else {
            newSelectedAssets.push(newAsset);
        }

        setSelectedAssets(newSelectedAssets);
    };

    const handleEidrSelection = (key) => {
        const newValue = !registerPerformanceEidr;
        setRegisterPerformanceEidr(newValue);
    };

    const margin = "0 0 1.25rem 0";
    const titleFontSize = "1.05rem";

    return (
        <div className="asset-information">
            <div style={{ padding: "1.25rem 1.25rem 0 1.25rem" }}>
                <Title title="Asset Information" fontSize={titleFontSize} />

                <div style={{ margin: "1.5rem 0 1.25rem 0", display: "flex" }}>
                    <Select
                        name="system_name"
                        id="system_name"
                        value={selectedSystemName}
                        clear={true}
                        label="Destination Platform"
                        disabledText="Select Platform"
                        noneSelectedText="None Selected"
                        data={systemNames}
                        wrapperStyles={{ width: "50%", marginRight: "0.3rem" }}
                        handleChange={handleSystemNameSelect}
                        showNullOption={false}
                    />

                    <Select
                        name="asset_type"
                        id="asset_type"
                        value={dummyAssetTypes[0].value}
                        clear={true}
                        label="Asset Type"
                        data={dummyAssetTypes}
                        wrapperStyles={{ width: "50%", marginLeft: "0.3rem" }}
                        showNullOption={false}
                    />
                </div>

                <FadeIn key={popularOnly}>
                    <React.Fragment>
                        {popularOnly && Array.isArray(assetOptions) && (
                            <DoubleGridList
                                label="Popular"
                                items={assetOptions}
                                styles={{ margin }}
                                selected={selectedAssets}
                                selectedDisabled={selectedDisabled}
                                handleSelection={handleAssetSelect}
                                rightLabelText="View All"
                                handleRightTextClick={() =>
                                    setPopularOnly(false)
                                }
                            />
                        )}
                        {!popularOnly && Array.isArray(assetOptions) && (
                            <DoubleGridList
                                label="All"
                                items={assetOptions}
                                styles={{ margin }}
                                selected={selectedAssets}
                                selectedDisabled={selectedDisabled}
                                handleSelection={handleAssetSelect}
                            />
                        )}
                        {!popularOnly &&
                            !Array.isArray(assetOptions) &&
                            Object.entries(assetOptions).map(
                                ([key, items], index) => {
                                    if (key !== "Masters") {
                                        return (
                                            <DoubleGridList
                                                key={index}
                                                label={key}
                                                items={items}
                                                styles={{ margin }}
                                                selected={selectedAssets}
                                                selectedDisabled={
                                                    selectedDisabled
                                                }
                                                handleSelection={
                                                    handleAssetSelect
                                                }
                                            />
                                        );
                                    }
                                },
                            )}
                    </React.Fragment>
                </FadeIn>
            </div>

            <div
                style={{
                    padding: "0 1.25rem 1.25rem 1.25rem",
                    borderTop: "solid 0.065rem #d1d1d1",
                }}
            >
                <TitleWithImage
                    title="EIDR Registration"
                    fontSize={titleFontSize}
                    image={
                        <img
                            src={`${Settings.images.path}/svg/eidr-blue.svg`}
                            style={{ width: "4.21875rem" }}
                        />
                    }
                    styles={{
                        margin: "1.0625rem 0 1rem 0",
                    }}
                />

                <RadioButtonOptions
                    data={[
                        {
                            text: "Register New Performance EIDR for All Assets?",
                            value: "register_performance_eidr",
                        },
                    ]}
                    selected={
                        registerPerformanceEidr
                            ? ["register_performance_eidr"]
                            : []
                    }
                    handleSelection={handleEidrSelection}
                />
            </div>
        </div>
    );
};

export default CreateNewForm;
