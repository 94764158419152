import React from "react";
import _ from "lodash";
import TitleValue from "../../../TitleValue";

const Advisories = (props) => {
    const { compliance = {}, options = {}, dataStructure = {} } = props;

    const { basic_info_options = {} } = dataStructure;

    const { countries = [] } = options;

    const { certifications = [] } = compliance;

    const { width = "100%" } = basic_info_options;

    const assetAdvisories = certifications
        .filter((item) => !item.deleted)
        .map((certificate) => {
            const { country_code, rating, system } = certificate;

            const advisory =
                !_.isEmpty(certificate.advisory) &&
                _.isArray(certificate.advisory)
                    ? certificate.advisory
                    : [];

            const advisoryText = advisory.join(", ");

            const scheduleRestrictions = (!_.isEmpty(certificate.schedule_restrictions) && Array.isArray(certificate.schedule_restrictions))
                ? certificate.schedule_restrictions
                : [];

            const scheduleRestrictionsText = scheduleRestrictions?.join(", ") || ""

            const country =
                countries.find((item) => item.value == country_code) || {};
            const countryTitle = country.text || country_code;

            let fullText = `${countryTitle} • ${system} • ${rating}`;

            if (!_.isEmpty(advisory)) {
                fullText += ` • ${advisoryText}`;
            }

            if (!_.isEmpty(scheduleRestrictions)) {
                fullText += ` • ${scheduleRestrictionsText}`;
            }

            return fullText;
        });

    const marginBottom = "1.15625rem";
    const dataNotAvailable = "Data not available.";

    return (
        <TitleValue
            title="Asset Advisory Content Descriptions"
            values={assetAdvisories}
            style={{ marginBottom, width }}
            emptyPlaceholder={dataNotAvailable}
            className="compliance--asset-advisory"
        />
    );
};

export default Advisories;
