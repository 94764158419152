import React from "react";
import Tag from "../index";
import "./_styles.scss";

interface TagV2Interface {
    text: string;
    color: string;
    background: string;
    uppercase?: boolean;
}

const TagV2 = (props: TagV2Interface) => {
    return (
        <Tag
            {...props}
            className={`fabric-tag-v2 ${props.uppercase ? "uppercase" : ""}`}
        />
    );
};

export default TagV2;
