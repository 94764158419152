import _ from "lodash";
import { z } from "zod";
import { ValidationLayer } from "../../../../../../../models/RecordBuilder/Field.interface"

export const keyValidation = z.object({
    key: z.string().optional(),
});
export const optionsValidation = z.object({ options: z.string().array() });
export const featureIntegrationValidation = z.object({ featureIntegration: z.string().array() });
export const categoriesValidation = z.object({
    categories: z
        .string()
        .array()
        .refine((val) => val?.length > 0, {
            message: "Please select at least 1 Record Category",
        }),
});

export const limitsValidation = z.object({
    limits: z
        .object({
            enabled: z.boolean().optional(),
            value: z.coerce.number().optional(),
            minValue: z.coerce.number().optional(),
            validation: z.nativeEnum(ValidationLayer).optional().default(ValidationLayer.None),
        })
        .superRefine(({ enabled, value, minValue }, ctx) => {
            if (enabled && !value && value < 2) {
                ctx.addIssue({
                    message: "Character Limit must be greater than 2",
                    code: z.ZodIssueCode.custom,
                    path: ["value"],
                });
            }

            if (enabled && Number(value) < Number(minValue)) {
                ctx.addIssue({
                    message: `Minimum Character Length can't be greater than the maximum`,
                    code: z.ZodIssueCode.custom,
                    path: ["minValue"],
                });
            }
        }),
});

export const smallRangeLimitsValidation = z.object({
    limits: z
        .object({
            enabled: z.boolean().optional(),
            value: z.coerce.number().optional(),
            minValue: z.coerce.number().optional(),
            validation: z.nativeEnum(ValidationLayer).optional().default(ValidationLayer.None),
        })
        .superRefine(({ enabled, value }, ctx) => {
            if (enabled && !value && value < 2) {
                ctx.addIssue({
                    message: "Character Limit must be greater than 2",
                    code: z.ZodIssueCode.custom,
                    path: ["value"],
                });
            }
        })
        .superRefine(({ enabled, value }, ctx) => {
            if (enabled && value > 180) {
                ctx.addIssue({
                    message: "Character Limit must be greater than 2",
                    code: z.ZodIssueCode.custom,
                    path: ["value"],
                });
            }
        }),
});

export const selectionsIntervalValidation = z.object({
    selectionsInterval: z.object({
        enabled: z.boolean().optional(),
        minimum: z.number().positive().optional(),
        maximum: z.number().positive().optional(),
        validation: z.nativeEnum(ValidationLayer).optional().default(ValidationLayer.None),
    })
    .superRefine(({ enabled, minimum, maximum }, ctx) => {
        if (enabled) {
            if (!minimum) {
                ctx.addIssue({
                    message: "Please provide a minimum number",
                    code: z.ZodIssueCode.custom,
                    path: ["minimum"],
                });
            }

            if (!maximum) {
                ctx.addIssue({
                    message: "Please provide a maximum number",
                    code: z.ZodIssueCode.custom,
                    path: ["maximum"],
                });
            }
        }
    }),
});

export const requiredValidation = z.object({
    required: z.object({
        enabled: z.boolean().optional(),
        validation: z.nativeEnum(ValidationLayer).optional().default(ValidationLayer.All),
    }),
});

export const readOnlyValidation = z.object({
    readOnly: z.object({
        enabled: z.boolean().optional(),
        validation: z.nativeEnum(ValidationLayer).optional().default(ValidationLayer.None),
    }),
});

export const includeInSNSValidation = z.object({
    includeInSNS: z.object({
        enabled: z.boolean().optional(),
    }),
});

export const uniqueValidation = z.object({
    unique: z.object({
        enabled: z.boolean().optional(),
        validation: z.nativeEnum(ValidationLayer).optional().default(ValidationLayer.None),
        withinCatalog: z.string().optional().default(''),
    }),
});

export const commentsValidation = z.object({
    comments: z.object({
        enabled: z.boolean().optional(),
        default: z
            .object({
                enabled: z.boolean().optional(),
                value: z.string().optional(),
            })
            .superRefine(({ enabled, value }, ctx) => {
                if (enabled && _.isEmpty(value)) {
                    ctx.addIssue({
                        message: "Please provide Default Comment",
                        code: z.ZodIssueCode.custom,
                        path: ["value"],
                    });
                }
            }),
    }),
});

export const titlesDescriptionsBase = z
    .object({})
    .merge(keyValidation)
    .merge(optionsValidation)
    .merge(categoriesValidation)
    .merge(limitsValidation)
    .merge(selectionsIntervalValidation)
    .merge(requiredValidation)
    .merge(readOnlyValidation)
    .merge(uniqueValidation)
    .merge(featureIntegrationValidation)
    .merge(commentsValidation);
