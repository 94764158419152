import React, { useEffect, useState } from "react";
import _ from "lodash";
import deepcopy from "deepcopy";
import Select from "../../Select";
import Notification from "../../Notification";
import ContentLoading from "../../ContentLoading";
import { disabledSection } from "../config";
import FadeWithSkeleton from "../../SkeletonLoader/FadeWithSkeleton";

const CatalogAvailability = (props) => {
    const [data, setData] = useState({});
    const [notification, setNotification] = useState({});
    const [fetchingData, setFetchingData] = useState(true);
    const [locked, setLocked] = useState(false);
    const [updating, setUpdating] = useState(false);

    const { copy = {}, disabled, state = {} } = props;

    const {
        options = {},
        handleFetchCatalogAvailability = () => {},
        handleUpdateCatalogAvailability = () => {},
    } = state;

    const { movie, tv_show } = data;

    const { catalog_availability = {} } = options;

    const { movies = [], tv_shows = [] } = catalog_availability;

    const isDisabled = disabled || locked;

    useEffect(() => {
        handleFetchCatalogAvailability({
            handleError: () => {
                setFetchingData(false);
                setLocked(true);
                setNotification({
                    title: copy.technicalError || "Techincal Error",
                    description:
                        "Failed to fetch catalog availability, please refresh to retry",
                });
            },
            handleSuccess: (data) => {
                setData(data);
                setFetchingData(false);
            },
        });
    }, []);

    const handleChange = (key, value) => {
        let updatedState = deepcopy(data) || {};
        updatedState[key] = value;

        setUpdating(key);

        const body = {
            movie: updatedState.movie || null,
            tv_show: updatedState.tv_show || null,
        };

        handleUpdateCatalogAvailability({
            body,
            handleError: () => {
                setUpdating(false);
                setNotification({
                    title: copy.technicalError || "Techincal Error",
                    description:
                        "Failed to update catalog availability, please try again",
                });
            },
            handleSuccess: (data) => {
                setData(data);
                setUpdating(false);
            },
        });
    };

    return (
        <div
            className={`meta-catalog-availiability ${
                disabled ? disabledSection : ""
            }`}
        >
            <FadeWithSkeleton loading={fetchingData} />

            {!fetchingData && (
                <>
                    <div className="meta-record-body--title-content">
                        <h2>
                            {copy.metaConnectCatalogAvailability ||
                                "Fabric Connect Catalog Availability"}
                        </h2>
                    </div>

                    <div className="container-wrap-margin">
                        <div className="container-item-one-third">
                            <Select
                                id="catalog-availability-movie"
                                value={movie}
                                clear={true}
                                label={
                                    copy.movieCatalogAvailability ||
                                    "Movie Catalog Availability"
                                }
                                noneSelectedText={
                                    copy.noneSelected || "None Selected"
                                }
                                disabledText={
                                    copy.selectCatalogAvailability ||
                                    "Select Catalog Availability"
                                }
                                readOnly={isDisabled}
                                disabled={isDisabled || updating}
                                data={movies}
                                loading={updating == "movie"}
                                handleChange={(item) =>
                                    handleChange("movie", item)
                                }
                            />
                        </div>

                        <div className="container-item-one-third">
                            <Select
                                id="catalog-availability-tv-show"
                                value={tv_show}
                                clear={true}
                                label={
                                    copy.tvShowCatalogAvailability ||
                                    "TV Show Catalog Availability"
                                }
                                noneSelectedText={
                                    copy.noneSelected || "None Selected"
                                }
                                disabledText={
                                    copy.selectCatalogAvailability ||
                                    "Select Catalog Availability"
                                }
                                readOnly={isDisabled}
                                disabled={isDisabled || updating}
                                data={tv_shows}
                                loading={updating == "tv_show"}
                                handleChange={(item) =>
                                    handleChange("tv_show", item)
                                }
                            />
                        </div>
                    </div>
                </>
            )}

            <Notification
                title={notification.title}
                description={notification.description}
                intercationStatus="alert"
                onClick={setNotification.bind(this, {})}
                okText={copy.ok || "Ok"}
            />
        </div>
    );
};

export default CatalogAvailability;
