import { RootStoreInitialStateInterface, AncillariesStoreInitialStateInterface } from "../interfaces"

export const rootStoreInitialState: RootStoreInitialStateInterface = {
    cmsData: {},
    disabled: true,
    userHasCreatePermission: false,
    userHasUpdatePermission: false,
    userHasDeletePermission: false,
}

export const ancillariesStoreInitialState: AncillariesStoreInitialStateInterface = {
    data: [],
    multiLevelViewData: []
};