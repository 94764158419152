import { DataStructureFieldConfigInterface, ValidationResult, ValidationUtilProps } from "../../interfaces";

const validatePresence = ({
    response = {},
    options = {},
    configOptions,
}: ValidationUtilProps): ValidationResult | {} => {
    const {
        data = [],
        fieldType = '',
        fieldsConfig = [],
    } = configOptions;

    const {
        status,
        description,
    } = options;

    const filteredData: any = data.filter((item: any) => !item.deleted);

    if (Array.isArray(fieldsConfig)) {
        fieldsConfig.map((fieldConfig) => {
            const row: DataStructureFieldConfigInterface = filteredData.find((item: DataStructureFieldConfigInterface) => item.type === fieldConfig.type) || {};
            
            if (fieldConfig.required && !row[fieldType]) {
                response.result = false;
                response.notification = {
                    title: `ID - ${fieldConfig.id} cannot be empty.`,
                    description,
                    status,
                };
        
                response.validationKeys = response.validationKeys.concat([fieldConfig.type]);
            }
        });
    }

    return response;
};

export default validatePresence;
