import _ from "lodash";
import clientManagedController from "./clientManagedController";

export function isClientManagedProductionQuotas(props) {
    const { clientFeatures = {} } = props;

    const { managedLists = [] } = clientFeatures;

    return managedLists.includes("production_quotas");
}

const ProductionQuotasOptions = (props) => {
    const { options = {} } = props;

    let result = clientManagedController({
        clientManagedOptions: options.client_managed_production_quotas || [],
        fallback: options.production_quotas || [],
        isClientManaged: isClientManagedProductionQuotas(props),
    });

    return result.map((item) => item.text);
};

export default ProductionQuotasOptions;
