import { gql } from "@apollo/client";
import { FieldMapInterface } from "../../interfaces";
import validationOption from "./options/validation";
import iconOption from "./options/icon";
import permissions from "./options/permissions";
import messages from "./options/messages";
import taskManager from "./options/taskManager";

const coreIdsFields = `
    id
    displayName
    key
    type
    categories
    archived
    order
    options {
        ${validationOption}
        ${iconOption}
        ${messages}
        validateType {
            enabled
        }
        characterType {
            enabled
        }
        hidden {
            enabled
        }
        url {
            enabled
            value
        }
        includeInSNS {
            enabled
        }
        ${taskManager}
    }
`;

const properties = `
    ${coreIdsFields}
`;

export const schemaGetAll = `
    getIdConfigs {
        label
        ${properties}
        ${permissions}
    }
`;

export const queryGetAll = gql`
    query {
        ${schemaGetAll}
    }
`;

export const queryGetOne = gql`
    query {
        getIdConfig(id: $id) {
            ${properties}
        }
    }
`;

export const mutationCreate = gql`
    mutation ($input: CreateIdConfigInput!) {
        createIdConfig(input: $input) {
            ${properties}
        }
    }
`;

export const mutationUpdate = gql`
    mutation ($id: ID!, $input: UpdateIdConfigInput!) {
        updateIdConfig(id: $id, input: $input) {
            ${properties}
        }
    }
`;

const map: FieldMapInterface = {
    createMutation: mutationCreate,
    updateMutation: mutationUpdate,
    queryAll: queryGetAll,
    dataPath: "getIdConfigs",
};

export default map;
