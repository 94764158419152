import React from "react";
import { SectionInterface } from "./interface";
import Origination from "../../views/Origination";
import OriginationV2 from "../../views/OriginationV2";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";
import OriginationBase from "../../views/OriginationBase";
import { ORIGINATION } from "../../../../models/RecordBuilder";
import IsActiveRecordBuilder from "../../../RecordBuilder/IsActive";

const SectionOrigination = (props: SectionInterface) => {
    const { cmsData = {}, data: recordData = {} } = props;

    const {
        clientDataStructure = {},
        clientFeatures = {},
        apiUrls = {},
    } = cmsData;

    const data = recordData.origination || {}
    const handleFormChange = (editData) => {
        if (props.handleFormChange) {
            props.handleFormChange("origination", {...data, ...editData})
        }
    }

    const originationProps = {
        ...getSectionCommonProps({ ...props, field: "origination" }),
        data,
        clientDataStructure,
        clientFeatures,
        apiUrls,
        handleFormChange,
        name: "origination",
        className: "meta-record-origination",
        title: "Origination",
        category: recordData.category,
        dataStructure: clientDataStructure.origination || [],
        canPopulateReleaseType: true,
    };

    if (IsActiveRecordBuilder({ page: ORIGINATION, clientFeatures })) {
        return <OriginationBase {...originationProps} />
    }

    if (!_.isEmpty(clientDataStructure.origination)) {
        return <OriginationV2 {...originationProps} />;
    }

    return <Origination {...originationProps} />;
};

export default SectionOrigination;
