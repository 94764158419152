import { observer } from "mobx-react";
import ObservableTextInputWithChip from "../../../../components/ObservableTextInputWithChip";
import { C12 } from "../../../../config/clients/viacom/validation/constants";
import { AllFieldsVT } from "../../../../config/types";

export const OriginalProductionNo = observer(() => {
    return (
        <ObservableTextInputWithChip<AllFieldsVT>
            path="originalProductionNo"
            placeholder="Enter original production number..."
            labelProps={{
                text: "Original Production No.",
            }}
            maxLength={C12}
        />
    );
});
