import * as React from "react";
import "./styles/_styles.scss";
import { DataGridPro, GridSelectionModel } from "@mui/x-data-grid-pro";
import { GridSxCommon } from "./styles";
import NoRowsOverlay from "../src/NoRowsOverlay";

type GridTableProps = {
    rows: Array<any>;
    columns: Array<any>;
    rowKey: string;
    id?: string;
    checkboxSelection?: boolean;
    handleCheckboxSelect?: (data: Array<any>) => void;
    rowReordering?: boolean;
    handleReordering?: (oldIndex: number, newIndex: number) => void;
    hideFooter?: boolean;
    disableColumnReorder?: boolean;
    disableSelectionOnClick?: boolean;
    sx?: any;
};

const GridTable = (props: GridTableProps) => {
    const {
        rows,
        columns,
        rowKey,
        id = "",
        rowReordering = false,
        checkboxSelection = false,
        handleCheckboxSelect = () => {},
        handleReordering = () => {},
        hideFooter = true,
        disableColumnReorder = false,
        sx = {},
        disableSelectionOnClick = true,
    } = props;

    return (
        <div
            className={"material-table"}
            style={{ height: "auto", overflow: "auto" }}
        >
            <DataGridPro
                checkboxSelection={checkboxSelection}
                rows={rows}
                columns={columns}
                rowReordering={rowReordering}
                onRowOrderChange={(params: any) =>
                    handleReordering(params.oldIndex, params.targetIndex)
                }
                getRowId={(row) => row[rowKey]}
                hideFooter={hideFooter}
                disableColumnReorder={disableColumnReorder}
                sx={Object.assign({}, { ...GridSxCommon }, { ...sx })}
                disableSelectionOnClick={disableSelectionOnClick}
                rowHeight={36}
                headerHeight={36}
                onSelectionModelChange={(data: GridSelectionModel) =>
                    handleCheckboxSelect(data)
                }
                components={{
                    NoRowsOverlay: () => NoRowsOverlay(),
                    NoResultsOverlay: () => NoRowsOverlay(),
                }}
                autoHeight={true}
            />
        </div>
    );
};

export default GridTable;
