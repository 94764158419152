import React from "react";
import { useStore } from "../state/context";
import "./styles/_styles.scss";
import _ from "lodash";
import SchedulingConditions from "./SchedulingConditions";
import FadeWithSkeleton from "../../SkeletonLoader/FadeWithSkeleton";
import PlatformScheduling from "./platforms";

const Scheduling: React.FC = () => {
    const [store] = useStore() || [{}];

    const { loadingBuilderConfig = true } = store;

    return (
        <div className="scheduling-container">
            <FadeWithSkeleton loading={loadingBuilderConfig} />
            <SchedulingConditions />
            <PlatformScheduling />
        </div>
    );
};

export default Scheduling;
