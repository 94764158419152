import clientManagedAssetCompliances from "../../ClientManagedOptions/asset_compliances";

export default function AssetCompliances(props) {
    const { options = {}, clientFeatures = {}, dataStructure = {} } = props;

    const { asset_management = [] } = options;

    const { system_name } = dataStructure;

    const system = asset_management.find(
        (system) =>
            system &&
            system.system_name &&
            system.system_name.value === system_name,
    );

    const { asset_types } = system || {};

    const { managedLists = [] } = clientFeatures;

    let defaultResult = [];

    if (Array.isArray(asset_types)) {
        asset_types.map((type) =>
            defaultResult.push({
                ...type,
            }),
        );
    } else if (typeof asset_types == "object" && asset_types) {
        Object.keys(asset_types).forEach((key) => {
            if (key !== "Masters") {
                defaultResult.push(
                    ...asset_types[key].map((type) => ({
                        ...type,
                        group: key,
                    })),
                );
            }
        });
    }

    defaultResult = defaultResult.map((item) => {
        return {
            ...item,
            text: item.code + " • " + item.text,
        };
    });

    const transformedClientManagedTypes = clientManagedAssetCompliances({
        options,
    });

    return managedLists.includes("asset_compliances")
        ? transformedClientManagedTypes
        : defaultResult;
}
