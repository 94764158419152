const colors = {
    amber: {
        default: "#ffbf00",
    },
    red: {
        light: "rgb(255, 56, 56)",
        default: "#ff0000",
        dark: "rgb(222, 7, 7)",
        medium: "#e8375c"
    },
    grey: {
        lightest: "#f5f5f5",
        lighter: "#9F9F9F",
        lighter2: "#949494",
        lighter3: "#828282",
        lighter4: "#8B8B8B",
        lighter5: "#6c6c6c",
        light: "#dbdbdb",
        dark: "#a8a8a8",
        dark2: "#B5B5B5",
        darker: "#a5a5a5",
        medium: '#c4c4c4'
    },
    green: {
        light: "#7ef6dd",
        lighter: "#0fe2b5",
        dark: "#287e4c",
        salad: "#b1e3c6",
        meta: "#72f4d7",
    },
    blue: {
        whiteMix: 'rgb(105, 136, 236)',
        lighter: "#286cd2",
        medium: "#377ae8",
        dark: "#326cc9",
        darker: "#195CBF",
    },
    orange: {
        medium: "#e2781d",
        dark: "#cb6c1a",
    },
    imdb: {
        background: "#e6b91e",
        backgroundHover: "#dcb428",
        color: "rgba(1, 1, 1, 0.87)",
    },
    rottenTomatoes: {
        background: "#a21f1f",
    },
    canvs: {
        background: "#343f61",
    },
    commonSense: {
        background: "#008f20",
    },
    origin: {
        background: "#464646",
    },
    eidr: {
        background: "#576aaf",
    },
    gracenote: {
        background: "#00aeef",
    },
    enrichment: {
        background: "#353535",
        backgroundHover: "#303030",
        color: "rgba(215, 215, 215, 0.87)",
    },
    error: {
        default: "#D6485F",
    },
    hover: {
        amber: "#e6ac00",
        red: "#d03152",
        error: "#c03e53"
    },
};

export default colors;
