export default {
    recordServiceV2Headers: {
        headers: {
            'x-api-key': process.env.RECORD_SERVICE_V2_X_API_KEY
        }
    },
    ivaHeaders: {
        headers: {
            'subscription-key': process.env.IVA_API_KEY,
            'accept': 'application/json',
            'format': 'json'
        },
        urlParams: `subscription-key=${process.env.IVA_API_KEY}&accept=application/json&format=json`
    }
}