import { FabricUserType } from "../types/interfaces";
import { fetchApi } from "../utils/fetchApi";
import { log } from "../utils/log";
import {
    API_ERROR_UNEXPECTED,
    TaskManagerApiReturnType,
    USEndpoints,
} from "./api.config";
import { USBaseUrl, getUrl } from "./api.config";

type ApiResponse<T> = {
    data: Array<T>;
};

class UserServiceApi {
    userToken: string = "";

    xApiKey: string = "";

    constructor(userToken?: string, xApiKey?: string) {
        this.userToken = userToken;
        this.xApiKey = xApiKey || process.env.RECORD_SERVICE_URL_X_API_KEY;
    }

    async getUsers({
        type,
    }: {
        type: string;
    }): Promise<TaskManagerApiReturnType<FabricUserType[]>> {
        log("[API.UserService] Obtaining users from API using type", type);

        try {
            const url = getUrl(USBaseUrl, USEndpoints.USERS);
            const filters = {
                type,
            };
            const response = await fetchApi<ApiResponse<FabricUserType>>(url, {
                method: "GET",
                params: filters,
                headers: {
                    "Content-Type": "application/json",
                    token: this.userToken,
                    "x-api-key": this.xApiKey,
                },
            });

            if (!response.data) {
                throw new Error("UserService did not respond with valid data");
            }

            return response.data;
        } catch (err) {
            log("[API.UserService] Failed call to get", err);
            return {
                message: API_ERROR_UNEXPECTED,
                data: [],
                isError: true,
            };
        }
    }
}

export default UserServiceApi;
