import { extendObservable, makeObservable, action } from "mobx";
import _ from "lodash";
import { AncillariesInterface } from "../../../models/Record/ancillaries"
import { AncillariesInitializeInterface } from "../interfaces"
import { ancillariesStoreInitialState } from "./initialState"
import multiLevelViewTransformer from "../utils/multiLevelViewTransformer"

export class AncillariesStore {
    handleFormChange;
    data;
    multiLevelViewData;

    constructor() {
        makeObservable(this, {
            initialize: action,
        });

        extendObservable(this, ancillariesStoreInitialState);
    }

    reset() {
        Object.keys(ancillariesStoreInitialState).forEach(
            (key) => (this[key] = ancillariesStoreInitialState[key]),
        );
    }

    initialize({
        handleFormChange,
        data
    }: AncillariesInitializeInterface): void {
        this.handleFormChange = handleFormChange;

        this.setData(data)
        this.setMultiLevelViewData()
    }

    setData(data: AncillariesInterface[]): void {
        this.data = data
    }

    setMultiLevelViewData(): void {
        this.multiLevelViewData = multiLevelViewTransformer(this.data)
    }
}
