import React from "react";
import _ from "lodash";
import styles from "../styles";
import Boilerplate from "./Boilerplate";
import DynamicTable from "../../DynamicTable/linkageGeneral";
import Index from "./Index";
import update from "react-addons-update";
import deepcopy from "deepcopy";
import { diff } from "deep-object-diff";

export default class Games extends Index {
    constructor(props) {
        super(props);

        let data = [];

        if (Array.isArray(this.props.data)) {
            data = this.props.data;
        }

        /**
         * @var object
         */
        this.styles = styles(this.props);

        /**
         * @var object
         */
        this.state = {
            data,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        const nextPropsData = nextProps.data || [];
        const thisPropsData = this.props.data || [];
        const difference = diff(nextPropsData, thisPropsData);

        if (!_.isEmpty(difference)) {
            return true;
        }

        if (!_.isEqual(nextProps.disabled, this.props.disabled)) {
            return true;
        }

        if (!_.isEqual(nextState, this.state)) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.data, prevProps.data)) {
            let data = deepcopy(this.props.data || []);

            if (Array.isArray(this.props.data)) {
                data = deepcopy(this.props.data || []);
            }

            this.setStateValue("data", data);
        }
    }

    handleChange(data) {
        if (Array.isArray(data)) {
            const updatedData = update(this.state.data, { $set: data });

            if (typeof this.props.handleFormChange == "function") {
                this.props.handleFormChange(updatedData);
            }

            this.setStateValue("data", updatedData);
        }
    }

    renderContent() {
        const data = deepcopy(this.state.data || []);
        const copy = this.props.copy || {};
        const linkTitle = copy.selectGame || "Select a game";

        if (this.props.disabled && _.isEmpty(data)) {
            return false;
        }

        return (
            <div style={this.styles.marginItem}>
                <DynamicTable
                    {...this.props}
                    classPrefix="meta-games"
                    id="meta-games-table"
                    deletedIconClass="meta-games-delete-icon"
                    data={data}
                    links={true}
                    linkTitle={linkTitle}
                    handleChange={this.handleChange.bind(this)}
                    handleLinkSearch={this.props.handleLinkSearch.bind(this)}
                />
            </div>
        );
    }
}
