import { StandaloneTicket } from "../../containers/ticket/shared/validationSchema";
import {
    StatusApproved,
    StatusAwaitingApproval,
    StatusCancelled,
    StatusChangeInProgress,
    StatusDraft,
    StatusRejected,
    StatusSubmissionInProgress,
    StatusSubmitted,
    StatusSubmissionRejected,
    TicketTypeStandalone,
    TicketTypeTVShow,
} from "../../types/constants";
import { TaskManagerPermissionsSchema } from "../../types/interfaces";
import PermissionPathsInterface from "./PermissionPathsInterface";

class RecordCreatePermissionPaths implements PermissionPathsInterface {
    private statusesToPermissions: {
        [
            k: string
        ]: keyof TaskManagerPermissionsSchema["ticketTypes"]["recordRequest"]["statuses"];
    } = {
        [StatusDraft.label]: "draft",
        [StatusAwaitingApproval.label]: "awaitingApproval",
        [StatusRejected.label]: "rejected",
        [StatusApproved.label]: "approved",
        [StatusChangeInProgress.label]: "changeInProgress",
        [StatusCancelled.label]: "cancelled",
        [StatusSubmissionInProgress.label]: "submissionInProgress",
        [StatusSubmitted.label]: "submitted",
        [StatusSubmissionRejected.label]: "submissionRejected",
    };

    private recordCreateSubtypePermissionLabels: {
        [
            k: string
        ]: keyof TaskManagerPermissionsSchema["ticketTypes"]["recordRequest"]["type"];
    } = {
        [TicketTypeStandalone.label]: "standalone",
        [TicketTypeTVShow.label]: "tvShow",
    };

    getApprovalPaths(ticket: StandaloneTicket) {
        const subtype =
            this.recordCreateSubtypePermissionLabels[ticket.type.label];

        return {
            own: "ticketTypes.recordRequest.approvals.own." + subtype,
            other: "ticketTypes.recordRequest.approvals.other." + subtype,
        };
    }

    getCRUDPath(ticket: StandaloneTicket) {
        const subtype =
            this.recordCreateSubtypePermissionLabels[ticket.type.label];

        return "ticketTypes.recordRequest.type." + subtype;
    }

    getTicketCommentPath() {
        return "ticketTypes.recordRequest.comments";
    }

    getStatusesPaths() {
        return [
            StatusDraft,
            StatusAwaitingApproval,
            StatusApproved,
            StatusRejected,
            StatusChangeInProgress,
            StatusCancelled,
            StatusSubmissionInProgress,
            StatusSubmitted,
            StatusSubmissionRejected,
        ].map((status) => {
            const prefix = "ticketTypes.recordRequest.statuses";
            const suffix = this.statusesToPermissions[status.label];

            return { status: status, path: `${prefix}.${suffix}` };
        });
    }
}

export default RecordCreatePermissionPaths;
