import _ from "lodash";
import clientManagedController from "./clientManagedController";

export default function RightsPlatformsOptions(props) {
    const { options = {}, platformType } = props;

    const { client_managed_rights_platforms = [], rights_platforms = [] } =
        options;

    let result = clientManagedController({
        clientManagedOptions: client_managed_rights_platforms,
        fallback: rights_platforms,
        isClientManaged: isClientManagedRightsPlatforms(props),
    });

    if (isClientManagedRightsPlatforms(props)) {
        return transformClientManagedRightsPlatforms({
            client_managed_rights_platforms: result,
            platformType,
        });
    } else {
        return {
            types: result.map((item) => item.type),
            platforms: result
                .filter((item) => item.type == platformType)
                .map((item) => item.platform),
        };
    }
}

export function isClientManagedRightsPlatforms(props) {
    const { clientFeatures = {} } = props;

    const { managedLists = [] } = clientFeatures;

    return managedLists.includes("rights_platforms");
}

export function transformClientManagedRightsPlatforms(props) {
    const { client_managed_rights_platforms = [], platformType } = props;

    const platformTypes =
        client_managed_rights_platforms.find(
            (item) => item.name == platformType,
        ) || {};

    return {
        types: client_managed_rights_platforms.map(
            (item) => item.name || item.type,
        ),
        platforms: platformTypes.platforms || [],
    };
}
