import { SET_SEARCH_DATA } from "../actions/action_types";
import { defaultSearchData } from "../initialState";

export default function (state = { searchData: defaultSearchData }, action) {
    switch (action.type) {
        case SET_SEARCH_DATA:
            return Object.assign({}, state, {
                searchData: action.searchData,
            });
    }

    return state;
}
