import _ from "lodash";
import moment from "moment";
import {
    ReleaseDate,
    USTheatricalRelease,
} from "../types/USTheatricalRelease/USTheatricalRelease";

function USTheatricalReleaseDataHandler(date: string): USTheatricalRelease {
    const us_theatrical_release_date: ReleaseDate = {
        country: "USA",
        date,
        type: "Theatrical",
    };

    return {
        us_theatrical_release_date_transformed:
            moment(date).format("Do MMMM YYYY"),
        us_theatrical_release_date,
    };
}

export default USTheatricalReleaseDataHandler;
