import React, { Fragment } from "react";
import "./styles/_styles.scss";
import _ from "lodash";
import Label from "./Label";
import CopyTo from "./CopyTo";
import NoneAddedLabel from "./NoneAddedLabel";
import { envTest } from "../Environment";
import { RenderedContributor } from "./types/Contributor/RenderedContributor";
import { ContributorItemProps } from "./types/Contributor/ContributorItemProps";

function ContributorItem(props: ContributorItemProps): JSX.Element {
    const {
        data = [],
        disabled,
        handleCopyToClick = () => {},
        readonly = false,
    } = props;

    function RenderRow(item: RenderedContributor): JSX.Element {
        return (
            <div className="contributor-row">
                <div className="role">{item.role || "-"}</div>
                <span className="name text">{item.contributor || "-"}</span>
                <span className="character text">
                    {" "}
                    {!_.isEmpty(item.character_names) &&
                        " • " + item.character_names.join(" , ")}
                </span>
                {(envTest || (!disabled && item.isValid && !readonly)) && (
                    <CopyTo
                        margin="0 0 0 auto"
                        handleClick={() => handleCopyToClick(item)}
                    />
                )}
            </div>
        );
    }

    return (
        <div style={{ marginBottom: "1.5rem" }}>
            <Label text="Contributors" styles={{ marginBottom: "1.5rem" }} />

            {_.isEmpty(data) && <NoneAddedLabel />}

            {!_.isEmpty(data) && (
                <div className="row-wrapper">
                    {data.map((item, index) => (
                        <RenderRow {...item} key={index} />
                    ))}
                </div>
            )}
        </div>
    );
}

export default ContributorItem;
