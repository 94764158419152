import { LANGUAGE_KEY_PRIMARY_LANGUAGE } from "../../../components/ClientManagedOptions/options";
import useGlobalData from "./useGlobalData";

export function useLanguages() {
    const [_, options] = useGlobalData();
    const languages = [];

    for (const [, v] of Object.entries(
        options.client_managed_languages?.all_spoken_languages ?? {},
    )) {
        // Exclude languages that are not primary and not spoken

        if (
            !v.spoken ||
            !v.spoken_locations?.includes(LANGUAGE_KEY_PRIMARY_LANGUAGE)
        ) {
            continue;
        }

        const code = v.meta_code?.toUpperCase();
        languages.push({
            label: v.name,
            value: code,
        });
    }

    return languages;
}
