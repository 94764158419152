import FetchCategoryConfig from "./FetchCategoryConfig";

const FetchIncludingList = (props) => {
    const { fallback = [] } = props;

    const categoryConfigs = FetchCategoryConfig(props);
    return categoryConfigs.includingTypes &&
        Array.isArray(categoryConfigs.includingTypes)
        ? categoryConfigs.includingTypes
        : fallback;
};

export default FetchIncludingList;
