/* eslint-disable jsx-a11y/alt-text */
import { isEmpty, isNaN } from "lodash";
import React from "react";
import { useFormContext } from "react-hook-form";
import { CoreAutocomplete } from "../../../../../../core/Autocomplete/CoreAutocomplete";
import { CoreFormLabelProps } from "../../../../../../core/Form/components/styled/CoreForm.label";
import { AllFieldsVT } from "../../../../config/types";
import { AutocompleteRecord } from "../components/AutocompleteRecordCard";
import { useFetchCategorySuggestions } from "../hooks/useFetchTvShowsSuggestions";
import { AutocompleteGroupComponent } from "../../../../../../core/Autocomplete/AutocompleteGroupComponent";
import { AutocompleteOptionItem } from "../../../../../../core/Autocomplete/AutocompleteOptionItem";

export default AutocompleteOptionItem;

export type AutocompleteRecordsProps = {
    labelProps: CoreFormLabelProps;
    headerText: string;
    disabled?: boolean;
    disableEmptyCard?: boolean;
    path: AllFieldsVT["title"] | "parentShow.title" | "parentSeason.title";
    /**
     * Will be called when the user clicks on a suggestion record card
     */
    onClick?: (option: AutocompleteRecord) => void;
    category: string;
    notFoundMessage?: string;
    maxLength?: number;
};

export const AutocompleteRecords = ({
    headerText,
    onClick,
    disableEmptyCard,
    maxLength,
    category,
    ...restProps
}: AutocompleteRecordsProps) => {
    const [open, setOpen] = React.useState(false);

    const hideSuggestions = () => setOpen(false);
    const showSuggestions = () => setOpen(true);

    const enabledClick = typeof onClick === "function";

    const formMethods = useFormContext();
    const value = formMethods.watch(restProps?.path);
    const { suggestions } = useFetchCategorySuggestions(value, category);

    const isEmptyMaxLength = Number(maxLength) <= 0 || isNaN(Number(maxLength));
    // const errorMessage = get(formMethods.formState.errors, path)?.message;

    return (
        <CoreAutocomplete
            open={open}
            isEmptyMaxLength={isEmptyMaxLength}
            freeSolo
            options={suggestions}
            isEmptySuggestionsArray={isEmpty(suggestions)}
            onBlur={() => hideSuggestions()}
            onFocus={() => showSuggestions()}
            groupBy={() => headerText}
            placeholder="Start typing the record title..."
            renderGroup={(params) => {
                return (
                    <AutocompleteGroupComponent
                        params={params}
                        headerText={headerText}
                        disableEmptyCard={disableEmptyCard}
                        hideSuggestions={hideSuggestions}
                    />
                );
            }}
            renderOption={(props, option: AutocompleteRecord) => {
                return (
                    <AutocompleteOptionItem
                        option={option}
                        enabledClick={enabledClick}
                        disableEmptyCard={disableEmptyCard}
                        hideSuggestions={hideSuggestions}
                        onClick={onClick}
                    />
                );
            }}
            {...restProps}
        />
    );
};

AutocompleteRecords.defaultProps = {
    disabled: false,
    onClick: null,
    disableEmptyCard: false,
};
