const GetMetaIdTemp = () => {
    let string =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
    string = `TEMP_${string}`;

    return string;
};

const MetaIdTemp = GetMetaIdTemp();

export default MetaIdTemp;
