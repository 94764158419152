import React from 'react'
import { makeObservable, action, extendObservable } from 'mobx'
import { isEmpty } from "lodash"
import { RootStore } from '../rootStore'
import { creditFormUpdateStoreInitialState } from "../state"
import { CreditFormStore } from "./creditFormStore"
import { configurationUpdate, fieldCharacterNameLanguage, fieldRole, apiUpdateFields } from "../../config/creditForm.config"
import { CreditInterface, CreditLocalisedData, CreditCharacterName } from '../interfaces';
import { getCreditByLocale } from "../../../../../services/Record/CreditsService"

export class CreditFormUpdateStore extends CreditFormStore {
    constructor(rootStore: RootStore) {
        super(rootStore)

        makeObservable(this, {
            handleSetConfiguration: action,
            handleSubmit: action,
            handleSetCredit: action
        });

        extendObservable(this, creditFormUpdateStoreInitialState);
    }

    initialize() {
        this.reset()

        this.handleSetDefaultLocale()
        this.handleSetConfiguration()
        this.handleSetCoreFormFields()

        this.handleSetCredit()
    }
    
    handleSetConfiguration(): void {
        this.setConfiguration(configurationUpdate)
    }

    handleSetCredit(): void {
        const id: string = this.rootStore.activeCreditsStore.selectedId
        const credit: CreditInterface = this.rootStore.activeCreditsStore.activeCredits.find(credit => credit.id === id) || {}
        const actorRoleId = this.rootStore.optionsStore.getActorRoleId()
        const characterNames: CreditCharacterName[] = this.getCharacterNamesFromCredit(credit)

        let result: CreditInterface = {
            ...credit,
            character_name: characterNames || []
        }

        if (credit?.[fieldRole.key] == actorRoleId) {
            result[fieldCharacterNameLanguage.key] = this.defaultLocale
        }

        this.setCredit(result)
        this.setCreditOriginal(result)
    }

    async handleLocaleChange() {
        this.setFetching(true)

        if (isEmpty(this.getExistingLocalisedData())) {
            const response = await getCreditByLocale(this.rootStore.globalState?.cmsData, this.credit.id, this.credit.locale || this.defaultLocale)

            if (response.status === 200) {
                const credit: CreditInterface = response.data || {}

                if (!isEmpty(credit.character_names)) {
                    this.handleUpdateCreditWithNewLocales({
                        ...this.credit,
                        character_name: credit.character_names.map((cn: any) => {
                            return {
                                name: cn.name,
                                value: cn.name
                            }
                        })
                    })
                }
            } else {
                this.rootStore.handleDispatchError(`Failed to fetch credit localised data for ${this.credit?.id || ''}`)
            }
        } else {
            this.handleUpdateCreditWithNewLocales({
                ...this.credit,
                character_name: this.getCharacterNamesFromCredit(this.credit) || []
            })
        }

        this.setFetching(false)
    }

    handleUpdateCreditWithNewLocales(credit: CreditInterface): void {
        this.setCredit(credit)
        this.setCreditOriginal(credit)
        this.handleSetCoreFormFields()
        this.setChangeId()
    }

    handleSubmit(): void {
        this.rootStore.creditFormValidationStore.handleValidationUpdate(this.configuration, this.credit, this.creditOriginal)

        if (isEmpty(this.rootStore.creditFormValidationStore.errorAlerts)) {
            const transformedCredit = this.rootStore.handleTransformItem(apiUpdateFields, this.credit)

            this.rootStore.creditsStore.handleUpdateCredit(transformedCredit, this.credit)
            this.rootStore.activeCreditsStore.handleSetCredits()
            this.rootStore.optionsStore.handleSetLanguageOptionsWithLocales()
            this.rootStore.handleChange()
            
            this.rootStore.setShowCreditUpdateForm(false)
        }
    }
}
