export const MetaIdResolver = (props) => {
    const { conditions = {} } = props;

    return conditions.isMetaIdCheck;
};

export const FieldNameResolver = (props) => {
    const { conditions = {}, value = false } = props;

    return conditions.isDataSection && conditions.fieldIn && !value;
};

export const DataRowIdResolver = (props) => {
    const { auditRow = {}, dataRowId = null, conditions = {} } = props;

    const { auditable_id = null } = auditRow;

    return conditions.isDataSection && dataRowId && auditable_id == dataRowId;
};

export const FieldValueResolver = (props) => {
    const {
        value = false,
        fieldName = "",
        auditRow = {},
        conditions = {},
    } = props;

    const { new_values = {} } = auditRow;

    const searchInObject = () => {
        return conditions.fieldIn && new_values[fieldName] == value;
    };

    const searchInArray = () => {
        return Array.isArray(new_values)
            ? new_values.find(
                  (newRow) =>
                      typeof newRow === "object" &&
                      Object.keys(newRow).includes(fieldName) &&
                      newRow[fieldName] == value,
              )
            : false;
    };

    return conditions.isDataSection
        ? searchInObject() || searchInArray()
        : false;
};
