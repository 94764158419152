import React, { useEffect, useState } from "react"
import { isEmpty } from "lodash"
import { useStore } from "../../Global/store/store";
import Select from "../../Select"
import { GetFromLocalStorage, SetLocalStorage } from "../../Storage/localStorage";
import { CLIENT_MANAGED_RECORD_TYPES, CLIENT_MANAGED_RECORD_TYPES_WITH_CATEGORIES } from "../../ClientManagedOptions/options";
import HanldeFetchDataListRequest from "../../../services/ApiClients/CML/HanldeFetchDataListRequest";
import { isClientManagedRecordTypes } from "../../ClientManagedOptions/record_types";

interface RecordTypeSelectInterface {
    category: string,
    value: string,
    wrapperStyles?: any,
    fallBackData?: Array<any>,
    name?: string,
    id?: string,
    label?: string | false,
    disabled?: boolean,
    error?: boolean,
    handleChange: (item: string) => void
}

const RecordTypeSelect = (props: RecordTypeSelectInterface): JSX.Element => {
    const {
        category = "",
        value,
        fallBackData = [],
        wrapperStyles = {},
        name = "selectType",
        id = "selectType",
        label = false,
        disabled,
        error,
        handleChange = () => {}
    } = props

    const [state] = useStore() || [{}]

    const {
        cmsData = {}
    } = state

    const {
        apiUrls = {},
        clientFeatures = {}
    } = cmsData

    const [data, setData] = useState<any[]>([])
    const isClientManaged: boolean = isClientManagedRecordTypes({ clientFeatures })

    useEffect(() => {
        if (!isClientManaged) {
            setData(fallBackData)
        } else {
            let recordTypes: any = GetFromLocalStorage(CLIENT_MANAGED_RECORD_TYPES_WITH_CATEGORIES)

            if (recordTypes) {
                setData(JSON.parse(recordTypes))
            } else {
                HanldeFetchDataListRequest({
                    apiUrls,
                    list: CLIENT_MANAGED_RECORD_TYPES,
                    onError: (error) => {
                        //
                    },
                    onSuccess: (list: Array<any>) => {
                        setData(list)

                        SetLocalStorage({
                            name: CLIENT_MANAGED_RECORD_TYPES_WITH_CATEGORIES,
                            value: JSON.stringify(list)
                        })
                    },
                })
            }
        }
    }, [])

    let categoryTypes = isClientManaged ? [] : fallBackData
    if (isClientManaged && category && !isEmpty(data)) {
        categoryTypes = data.filter((item: any) => item?.categories.includes(category))
    }

    return (
        <Select
            label={label}
            name={name}
            id={id}
            value={value}
            readOnly={disabled}
            disabled={disabled}
            error={error}
            handleChange={(item: string) => handleChange(item)}
            disabledText="Select Type"
            noneSelectedText="None Selected"
            wrapperStyles={wrapperStyles}
            key={`category-type-${value}`}
            data={categoryTypes.map((item: any) => {
                return {
                    text: item.name,
                    value: item.value
                }
            })}
        />
    );
};

export default RecordTypeSelect;
