import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

export const TooltipInBox = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        // backgroundColor: "transparent",
        borderRadius: "4px;",
        border: "1px solid rgba(77, 77, 77, 0.10);",
        background: "#FFF;",
        boxShadow: "2px 3px 5px 0px rgba(79, 94, 116, 0.15);",
        padding: "5px;",
        
        '& .tooltip-text': {
            padding: "0.5rem",
            fontSize: "1rem",
            display: "block",
            color: "rgba(47, 47, 47, 0.87)",
        }
    },
}));
