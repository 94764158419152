import { SET_RECORD_OPTIONS } from "../actions/recordOptions";
import { defaultRecordOptions } from "../initialState";

export default function (
    state = { recordOptions: defaultRecordOptions },
    action,
) {
    switch (action.type) {
        case SET_RECORD_OPTIONS:
            return Object.assign({}, state, {
                recordOptions: action.recordOptions,
            });
    }

    return state;
}
