import React from "react";
import CustomSelect from "./index";
import Settings from "../Settings";

const CustomSelectActionButton = (props) => {
    const {
        label = "Actions",
        disabled,
        styles = {},
        disabledImageWhite,
    } = props;

    let background = props.background || "#626262";
    let color = props.color || "white";
    let labelStyles = {
        ...Settings.components.actionButton.text,
        ...(props.labelStyles || {}),
    };
    let image = "options-white";

    if (disabled) {
        background = props.disabledBackground || "#737373";
        color = "#a5a5a5";

        if (!disabledImageWhite) {
            image = "options-light-grey";
        }

        labelStyles = {
            ...Settings.components.actionButton.text,
            ...Settings.components.actionButton.textDisabled,
            ...(props.labelStyles || {}),
        };
    }

    let selectStyles = {
        ...Settings.components.actionButton.container,
        ...styles,
    };

    const RenderImage = () => {
        return (
            <img
                src={`${Settings.images.path}/svg/${image}.svg`}
                style={{
                    width: "0.2rem",
                    marginLeft: "0.4375rem",
                }}
            />
        );
    };

    return (
        <CustomSelect
            label={label}
            background={background}
            color={color}
            chevronImage={false}
            opacity={false}
            styles={selectStyles}
            labelStyles={labelStyles}
            customImageRight={<RenderImage />}
            {...props}
        />
    );
};

export default CustomSelectActionButton;
