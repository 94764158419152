import { z } from "zod";
import FieldInterface, { InputType } from '../../interfaces/Field.interface'
import { ValidationLayer } from "../../../../../models/RecordBuilder/Field.interface";

export const TextInputRule = (field: FieldInterface) => {
    let string = z.string()
        .nullish()

    if (field.type.dataType === 'Number') {
        string = z.string()
            .regex(new RegExp('^[0-9]*$'), `${field.label} must be a number`)
            .nullish()
    }

    if (field.validation?.characterLimit?.enabled && field.validation?.characterLimit?.validation != ValidationLayer.None) {
        string = z.string()
            .max(field.validation?.characterLimit?.limit, 'Character limit exceeded')
            .nullish()
    }

    if (field.validation?.rangeLimit?.enabled && field.validation?.rangeLimit?.min && field.validation?.rangeLimit?.max) {
        const message: string = field.validation?.rangeLimit?.min == field.validation?.rangeLimit?.max
            ? `${field.label} must be ${field.validation?.rangeLimit?.min} characters`
            : `${field.label} must be between ${field.validation?.rangeLimit?.min} and ${field.validation?.rangeLimit?.max} characters`

        string = z.string()
            .min(field.validation?.rangeLimit?.min, message)
            .max(field.validation?.rangeLimit?.max, message)
            .nullish()
    }

    if (field.validation?.email?.enabled) {
        string = z.union([
            z.string().email(`${field.label} is not a valid email address`), 
            z.literal(""),
            z.literal(null)
        ]).optional()
    }

    if (field.validation?.url?.enabled) {
        string = z.union([
            z.string().regex(new RegExp(
                '(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})'), 
                `${field.label} is not a valid url`
            ).nullish(),
            z.literal("")
        ]).optional()
    }

    if (field.validation?.required?.enabled) {
        string = z.string()
            .min(1, `Please enter ${field.label}`)
            .nullish()
    }

    return z.object({
        [field.key]: string,
    })
}