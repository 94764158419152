import React, { useContext } from "react";
import "./css/label.scss";
import "./css/styles.scss";
import AuditContext from "./context/Context";
import _ from "lodash";
import GetAuditLog from "./src/GetAuditLog";
import GenerateAuditMessage from "./src/GenerateAuditMessage";

const LabelAudit = (props) => {
    const { labelKey = null, styles = {} } = props;

    const context = useContext(AuditContext);

    const { audits = [] } = context.state;

    const defaultView = (
        <div
            className={"audit-label-tooltip"}
            style={Object.assign({}, styles)}
        >
            <div className="audit-message-block non-log">
                No update logs available
            </div>
        </div>
    );

    if (!labelKey || !_.has(context.state, labelKey)) {
        return defaultView;
    }

    let auditLog = GetAuditLog({ ...context.state[labelKey], audits });

    if (_.isEmpty(auditLog)) {
        return defaultView;
    }

    return (
        <div
            className={"audit-label-tooltip"}
            style={Object.assign({}, styles)}
        >
            <div className="audit-message-block active">
                {GenerateAuditMessage(auditLog)}
            </div>
        </div>
    );
};

export default LabelAudit;
