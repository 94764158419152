import React, { Fragment, useEffect } from "react";
import { useStore } from "../Global/store/store";
import {
    setOptions,
    setCMSData,
    setUserPermissions,
    setPermissionsError,
    setRoles,
    setRolesTransformed,
    setAccessCatalogs,
    setAccessCatalogsError,
    setClientManagedOptions,
    setFallBackWrittenLanguages,
    setDefaultLanguageCode,
    setRecordBuilder,
    setRecordBuilderError,
    setClientManagedLists,
} from "../Global/store/reducer";
import CMSService from "../../services/CMSService";
import HandleFetchOptionsRequst from "../../pages/src/HandleFetchOptionsRequst";
import HandleClientManagedOptionsVersionsRequest from "./src/HandleClientManagedOptionsVersionsRequest";
import PageLoading from "../PageLoading";
import TestingOptions from "../Testing/Options";
import TurnerCMSData from "../Testing/TurnerCMSData";
import {
    CMS_DATA,
    CONNECT_BRANDING,
    OPTIONS,
    CLIENT_MANAGED_OPTIONS_VERSIONS,
    USER_ROLES,
    USER_PERMISSIONS,
    ACCESS_CATALOGS,
    ACCESS_POLICY_CATALOGS,
    CLIENT_MANAGED_OPTIONS_LANGUAGES,
    RECORD_BUILDER,
    CLIENT_MANAGED_LISTS,
} from "./Options";
import { manageVersionsInLocalStorage } from "../ClientManagedOptions/clientManagedController";
import { getUserPermissions } from "../../models/User/model";
import getRolesTransformed from "../../features/UserRoleManagement/views/Roles/src/getRolesTransformed";
import _ from "lodash";
import { CLIENT_MANAGED_LANGUAGES } from "../ClientManagedOptions/options"
import OptionsSearchApi from "../Options/OptionsSearchApi";
import { getPolicyCatalogs, getCatalogs } from "../../services/CatalogService"
import { getPermissions } from "../../services/PermissionService"
import { getRoles } from "../../services/RoleService"
import { envTest } from "../Environment";
import { getFallBackWrittenLanguagesFromOptions } from "../../models/ClientManaged/languagesModel"
import { getAllQuery as RecordBuilderGetAllQuery} from "../../services/ApiClients/RecordBuilder";
import MapRecordBuilderConfiguration from "./src/MapRecordBuilderConfiguration";
import Builder from '../../config/builder'
import { IsEnabled } from "../RecordBuilder/IsActive";
import { getAllLists as  getClientManagedLists} from "../../services/CMLService";

const SetGlobalOptions = (props) => {
    const {
        options = [],
        testing,
        testingData = {},
        testingCmsData = {},
        testingOptionsData = {},
        permissionsTestingData = {},
        userRolesTestingData = {},
        accessCatalogsTestingData = {},
        recordBuilderTestingData = {},
    } = props;

    const [state] = useStore();
    const [, dispatch] = useStore();

    const { cmsData = {} } = state;

    useEffect(() => {
        if (testing || !_.isEmpty(testingCmsData)) {
            dispatch(
                setCMSData({
                    ...TurnerCMSData,
                    ...testingCmsData,
                }),
            );

            dispatch(setOptions(TestingOptions));
            dispatch(setAccessCatalogs(accessCatalogsTestingData));
            dispatch(setRecordBuilder(recordBuilderTestingData))
            return;
        }

        if (options.includes(CMS_DATA)) {
            CMSService({
                testingData: !_.isEmpty(testingData)
                    ? testingData
                    : { clientFeatures: {} },
                handleResponseData: async (cmsData) => {
                    const {
                        clientBranding = {},
                        userGroup = {},
                        clientFeatures = {},
                        apiUrls = {},
                        clientDataStructure = {}
                    } = cmsData;

                    const {
                        getClientManagedLanguages = ""
                    } = apiUrls;

                    const { product } = userGroup;

                    if (!_.isEmpty(clientBranding)) {
                        let defaultBranding = clientBranding.default || {};
                        let productBranding = clientBranding[product] || {};

                        if (
                            options.includes(CONNECT_BRANDING) &&
                            clientBranding.Connect
                        ) {
                            defaultBranding =
                                clientBranding.Connect.default || {};
                        }

                        let branding = {
                            ...defaultBranding,
                            ...productBranding,
                        };

                        if (process) {
                            process.THEME = branding;
                        }

                        cmsData = {
                            ...cmsData,
                            clientBranding: branding,
                        };
                    }

                    dispatch(setCMSData(cmsData));

                    const state = {
                        cmsData:
                            cmsData.data ||
                            (!_.isEmpty(cmsData) && cmsData) ||
                            {},
                    };

                    if (options.includes(OPTIONS)) {
                        HandleFetchOptionsRequst({
                            testingData: testingOptionsData,
                            state,
                            onSuccess: (options) => {
                                dispatch(setOptions(options))
                                
                                // sets default languages
                                dispatch(
                                    setFallBackWrittenLanguages(
                                        getFallBackWrittenLanguagesFromOptions(options)
                                    )
                                )
                            }
                        });
                    }

                    if (options.includes(CLIENT_MANAGED_LISTS)) {
                        getClientManagedLists(cmsData).then((lists) => {
                            dispatch(
                                setClientManagedLists(lists)
                            );
                        }).catch((error) => {
                            dispatch(
                                setClientManagedLists([])
                            );
                        })
                    }

                    if (options.includes(CLIENT_MANAGED_OPTIONS_VERSIONS)) {
                        HandleClientManagedOptionsVersionsRequest({
                            state,
                            onSuccess: (versions) => {
                                manageVersionsInLocalStorage(versions);
                            },
                            testingData: {
                                versions: {},
                            },
                        });
                    }

                    if (options.includes(CLIENT_MANAGED_OPTIONS_LANGUAGES)) {
                        OptionsSearchApi({
                            storeKey: CLIENT_MANAGED_LANGUAGES,
                            url: getClientManagedLanguages,
                            updateList: items => dispatch(
                                setClientManagedOptions({
                                    [CLIENT_MANAGED_LANGUAGES]: items
                                })
                            )
                        });
                    }

                    if (
                        options.includes(RECORD_BUILDER) 
                        && IsEnabled(clientFeatures)
                    ) {
                        RecordBuilderGetAllQuery(cmsData?.user?.user_token).then((response) => {
                            dispatch(
                                setRecordBuilder(MapRecordBuilderConfiguration(response))
                            );
                        }).catch((error) => {
                            dispatch(
                                setRecordBuilderError(error || true),
                            );
                        })
                    }

                    if (
                        options.includes(ACCESS_CATALOGS) &&
                        clientFeatures.userRoleManagement
                    ) {
                        let accessCatalogs = await getCatalogs(cmsData)
                        dispatch(setAccessCatalogs(accessCatalogs.data));
                    }

                    if (
                        options.includes(USER_ROLES) &&
                        clientFeatures.userRoleManagement
                    ) {
                        let roles = await getRoles(cmsData)

                        const userPermissions = getUserPermissions({
                            roles: roles.data,
                            cmsData,
                        });

                        const rolesTransformed = getRolesTransformed(roles.data);

                        dispatch(setUserPermissions(userPermissions));
                        dispatch(setRoles(roles.data));
                        dispatch(setRolesTransformed(rolesTransformed));
                    }

                    if (
                        options.includes(USER_PERMISSIONS) &&
                        clientFeatures.userRoleManagement
                    ) {
                        let permissions = {}

                        if (envTest) {
                            permissions = permissionsTestingData
                        } else {
                            permissions = await getPermissions(cmsData)
                            permissions = permissions.data
                        }

                        dispatch(
                            setUserPermissions([permissions])
                        );

                        if (options.includes(ACCESS_POLICY_CATALOGS)) {
                            let accessCatalogs = await getPolicyCatalogs(cmsData, permissions)

                            dispatch(
                                setAccessCatalogs(accessCatalogs)
                            );
                        }
                    }
                },
            });
        }
    }, []);

    const getChildrenWithProps = () => {
        return React.Children.map(props.children, (child) => {
            if (React.isValidElement(child)) {
                return React.cloneElement(child, { cmsData });
            }

            return child;
        });
    };

    const childrenwithProps = getChildrenWithProps();

    return (
        <Fragment>
            {_.isEmpty(cmsData) ? <PageLoading /> : childrenwithProps}
        </Fragment>
    );
};

export default SetGlobalOptions;
