import AssetInterface from "../components/asset/interfaces/AssetInterface";
import { v4 as uuidv4 } from "uuid";

const EnrichAssetWithUUID: Function = (
    assets: Array<AssetInterface>,
): Array<AssetInterface> => {
    return assets.map((asset) => {
        if (!asset.uuid) {
            asset.uuid = uuidv4();
        }

        if (asset.child_assets) {
            asset.child_assets = EnrichAssetWithUUID(asset.child_assets);
        }
        return asset;
    });
};

export default EnrichAssetWithUUID;
