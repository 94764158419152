import React from 'react'
import { observer } from "mobx-react"
import { useStores } from "../../store/rootStore";
import Notification from '../../../../../components/Notification'

interface Props {

}

const DeleteAwardForm: React.FC<Props> = observer((props:Props) => {
    const rootStore = useStores()
    const { awardsStore } = rootStore || {}

    return (
        <Notification
            title="Delete Award"
            description="Are you sure you want to delete this Award?"
            intercationStatus="error"
            confirm={() => {
                awardsStore.handleDeleteItems(awardsStore.selectedRows)
                awardsStore.setSelectedRows([])
            }}
            okText="Delete"
            cancelClick={() => awardsStore.setAwardDeleteFormIsActive(false)}
        />
    )
})

export default DeleteAwardForm