import { Chip, ChipProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { CoreFormSection, CoreFormSectionVariants } from "../../types";

export const TAB_LABELS = {
    Primary: "Primary Fields",
    Additional: "Additional Fields",
};
export const SECTION_LABELS = {
    Default: "Default",
    Titles: "Titles",
    Additional_Titles: "Additional Titles",
    Descriptions: "Descriptions",
    IDs: "IDs",
    Other: "Other",
};
const GreenChip = styled(Chip)(({ theme }) => ({
    color: "#0B4331",
    background: "#9DE9D0",
}));
type RightAdornmentChipProps = Pick<ChipProps, "label">;
const RightAdornmentChip = ({ label }: RightAdornmentChipProps) => (
    <GreenChip
        label={label}
        sx={{
            cursor: "pointer",
        }}
    />
);
export const sections = {
    [SECTION_LABELS.Default]: {
        label: SECTION_LABELS.Default,
        variant: CoreFormSectionVariants.None,
    } as CoreFormSection,
    [SECTION_LABELS.Titles]: {
        label: SECTION_LABELS.Titles,
        variant: CoreFormSectionVariants.Accordion,
        // rightAdornment: (
        //     <RightAdornmentChip label={`${SECTION_LABELS.Titles} section`} />
        // ),
    } as CoreFormSection,
    [SECTION_LABELS.Additional_Titles]: {
        label: SECTION_LABELS.Additional_Titles,
        variant: CoreFormSectionVariants.Accordion,
        // rightAdornment: (
        //     <RightAdornmentChip
        //         label={`${SECTION_LABELS.Additional_Titles} section`}
        //     />
        // ),
    } as CoreFormSection,
    [SECTION_LABELS.Descriptions]: {
        label: SECTION_LABELS.Descriptions,
        variant: CoreFormSectionVariants.Accordion,
    } as CoreFormSection,
    [SECTION_LABELS.IDs]: {
        label: SECTION_LABELS.IDs,
        variant: CoreFormSectionVariants.Accordion,
    } as CoreFormSection,
    [SECTION_LABELS.Other]: {
        label: SECTION_LABELS.Other,
        variant: CoreFormSectionVariants.Accordion,
    } as CoreFormSection,
} as const;
