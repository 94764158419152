import _ from "lodash";

export default function RenameObjectKey(obj, key, newKey) {
    if (Array.isArray(obj)) {
        obj.forEach((item) => {
            let object = item;
            let result = [];

            if (object.hasOwnProperty(key)) {
                object[newKey] = _.clone(object[key], true);

                delete object[key];
                result.push(object);
            }

            return result;
        });
    }

    return obj;
}
