import React from "react";
import Settings from "../Settings";
import Button from "../Button";
import "./styles/_styles.scss";

const TitleBarWithPager = ({
    title = "",
    tag = "",
    statusColor,
    styles = {},
    disabled,
    onPreviousClick = () => {},
    onNextClick = () => {},
}) => {
    return (
        <div className="meta-title-bar-with-pager" style={styles}>
            <div className="left">
                <Button
                    id="meta-title-bar-with-pager--previous"
                    value="Previous"
                    type="button"
                    margin="0"
                    padding="0.1875rem 0.3125rem"
                    background="#8f8f8f"
                    hoverBackground="#9a9a9a"
                    fontSize="0.78125rem"
                    color="white"
                    disabledColor="white"
                    display="inline-block"
                    lineHeight="1"
                    onClick={!disabled ? onPreviousClick : () => {}}
                    disabled={disabled}
                    leftIcon={`${Settings.images.path}/svg/chevron-bottom-white.svg`}
                    leftIconStyles={{
                        height: "0.6875rem",
                        width: "auto",
                        marginRight: "0",
                        paddingTop: "0.1875rem",
                        transform: "rotate(90deg)",
                    }}
                />
            </div>
            <div className="middle">
                {statusColor && (
                    <div
                        className="status"
                        style={{ background: statusColor }}
                    ></div>
                )}
                {tag && (
                    <div className="tag">
                        <span>{tag}</span>
                    </div>
                )}
                <div className="title">
                    <span>{title}</span>
                </div>
            </div>
            <div className="right">
                <Button
                    id="meta-title-bar-with-pager--next"
                    value="Next"
                    type="button"
                    margin="0"
                    padding="0.1875rem 0.3125rem"
                    background="#8f8f8f"
                    hoverBackground="#9a9a9a"
                    fontSize="0.78125rem"
                    color="white"
                    disabledColor="white"
                    display="inline-block"
                    lineHeight="1"
                    onClick={!disabled ? onNextClick : () => {}}
                    disabled={disabled}
                    rightIcon={`${Settings.images.path}/svg/chevron-bottom-white.svg`}
                    rightIconStyles={{
                        height: "0.6875rem",
                        width: "auto",
                        marginLeft: "0",
                        paddingTop: "0.1875rem",
                        transform: "rotate(-90deg)",
                    }}
                />
            </div>
        </div>
    );
};

export default TitleBarWithPager;
