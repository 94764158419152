import { action, extendObservable, makeObservable, reaction } from "mobx";
import React from "react";
import fetchClientManagedOptions from "../../../components/ClientManagedOptions/fetchClientManagedOptions";
import { CLIENT_MANAGED_DESCRIPTORS } from "../../../components/ClientManagedOptions/options";
import { CmsInterface } from "../../../components/Global/interfaces";
import { setSnackbar } from "../../../components/Global/store/reducer";
import WebSocketService from "../background-processing/services/WebSocketService";
import BackgroundProcessingStore from "../background-processing/store/backgroundProcessingStore";
import TaskManagerAdapters from "../helpers/TaskManagerAdapters";
import { API_WARNING_CANCELLED } from "../services/api.config";
import RecordServiceApi from "../services/RecordServiceApi";
import TaskManagerApi from "../services/TaskManagerApi";
import TaskManagerPermissionsService from "../services/TaskManagerPermissionsService";
import UserServiceApi from "../services/UserServiceApi";
import getUserToken from "../utils/getUserToken";
import TaskManagerStoreInterface from "./TaskManagerStoreInterface";
import TaskManagerValidatorStore from "./TaskManagerValidatorStore";
import TicketAttachmentsStore from "./ticketAttachmentsStore";
import TicketCommentsStore from "./ticketCommentsStore";
import TicketDetailsStore from "./ticketDetailsStore";
import TicketHistoryStore from "./ticketHistoryStore";
import TicketsStore from "./ticketsStore";

const USER_TOKEN = getUserToken() as string;

export type InitialStateType = {
    snackbars: string[];
    searchTerm: string;
    clientManaged: {
        provenances: string[];
        descriptors: {
            genres: {
                label: string;
                value: string;
            }[];
            subGenres: {
                label: string;
                value: string;
            }[];
        };
    };
    userId: number;
    recordsProcessing: boolean;
    storesInit: {
        root: boolean;
        tickets: boolean;
        ticketDetails: boolean;
        validator: boolean;
        ticketComments: boolean;
        ticketHistory: boolean;
        backgroundProcessing: boolean;
    };
    shouldRenderUnauthorized: boolean;
};

export const initialState: InitialStateType = {
    snackbars: [],
    searchTerm: "",
    clientManaged: {
        provenances: [],
        descriptors: {
            genres: [],
            subGenres: [],
        },
    },
    userId: 0,
    recordsProcessing: false,
    storesInit: {
        root: false,
        tickets: false,
        ticketDetails: false,
        validator: false,
        ticketComments: false,
        ticketHistory: false,
        backgroundProcessing: false,
    },
    shouldRenderUnauthorized: false,
};

export class RootStore implements TaskManagerStoreInterface {
    ticketsStore: TicketsStore;

    searchTerm: InitialStateType["searchTerm"];

    ticketDetailsStore: TicketDetailsStore;

    validatorStore: TaskManagerValidatorStore;

    readonly taskManagerAdapters: TaskManagerAdapters;

    readonly webSocketService: WebSocketService;

    readonly taskManagerApi: TaskManagerApi;

    readonly recordServiceApi: RecordServiceApi;

    readonly userServiceApi: UserServiceApi;

    snackbars: InitialStateType["snackbars"];

    clientManaged: InitialStateType["clientManaged"];

    permissionsService: TaskManagerPermissionsService;

    userId: number;

    recordsProcessing: InitialStateType["recordsProcessing"];

    storesInit: InitialStateType["storesInit"];

    ticketCommentsStore: TicketCommentsStore;

    ticketHistoryStore: TicketHistoryStore;

    ticketAttachmentsStore: TicketAttachmentsStore;

    backgroundProcessingStore: BackgroundProcessingStore;

    dispatch: any;

    shouldRenderUnauthorized: InitialStateType["shouldRenderUnauthorized"];

    constructor(
        taskManagerApi: TaskManagerApi,
        recordServiceApi: RecordServiceApi,
        userServiceApi: UserServiceApi,
        taskManagerAdapters: TaskManagerAdapters,
    ) {
        this.ticketsStore = new TicketsStore(this);
        this.ticketDetailsStore = new TicketDetailsStore(this);
        this.validatorStore = new TaskManagerValidatorStore(this);
        this.ticketCommentsStore = new TicketCommentsStore(this);
        this.ticketHistoryStore = new TicketHistoryStore(this);
        this.ticketAttachmentsStore = new TicketAttachmentsStore(this);
        this.backgroundProcessingStore = new BackgroundProcessingStore(this);
        this.taskManagerApi = taskManagerApi;
        this.recordServiceApi = recordServiceApi;
        this.userServiceApi = userServiceApi;
        this.taskManagerAdapters = taskManagerAdapters;
        this.webSocketService = new WebSocketService(USER_TOKEN, this);

        makeObservable(this, {
            initialize: action,
            pushSnackbar: action,
            setSearchTerm: action,
            handleApiError: action,
            setRecordsProcessing: action,
            finishLoading: action,
        });

        extendObservable(this, initialState);
    }

    async initialize(
        cmsData: CmsInterface,
        userPermissions: any,
        dispatch: any,
    ) {
        if (this.storesInit["root"]) {
            throw new Error(
                `Task Manager root Store has already been initialized.`,
            );
        }

        const { apiUrls, clientFeatures, user } = cmsData;

        // Fetch client managed fields
        const clientManagedFields: Promise<void>[] = ["provenances"].map(
            (header) =>
                new Promise((resolve, reject) => {
                    try {
                        fetchClientManagedOptions({
                            clientManagedType: `client_managed_${header}`,
                            apiUrls,
                            clientFeatures,
                            onResponse: (response) => {
                                this.clientManaged[header] = response;
                                resolve();
                            },
                        });
                    } catch (error) {
                        reject(error);
                    }
                }),
        );

        try {
            await Promise.allSettled(clientManagedFields);
        } catch (err) {
            console.error(err);
        }

        // Fetch genres and sub-genres
        try {
            await Promise.allSettled([
                new Promise((resolve, reject) => {
                    try {
                        fetchClientManagedOptions({
                            clientManagedType: CLIENT_MANAGED_DESCRIPTORS,
                            apiUrls,
                            searchAll: true,
                            clientFeatures,
                            onResponse: (response) => {
                                const genres = response?.filter(
                                    (d) => d.types === "genres",
                                );
                                this.clientManaged.descriptors.genres = genres;

                                const subGenres = response?.filter(
                                    (d) => d.types === "sub_genres",
                                );
                                this.clientManaged.descriptors.subGenres =
                                    subGenres;

                                resolve();
                            },
                        });
                    } catch (error) {
                        reject(error);
                    }
                }),
            ]);
        } catch (err) {
            console.error(err);
        }

        this.permissionsService = new TaskManagerPermissionsService(
            cmsData,
            userPermissions,
        );

        // Our own user's ID
        this.userId = user.userId;

        // Catch if userId ever becomes null
        reaction(
            () => this.userId,
            (userId) => {
                if (userId === null) {
                    console.error("User ID is null");
                    this.renderUnauthorized();
                }
            },
        );

        this.dispatch = dispatch;

        this.finishLoading("root");
    }

    getSearchTerm() {
        return this.searchTerm;
    }

    pushSnackbar(message: string, autoHideDuration?: number, action?: any) {
        if (typeof this.dispatch === "function") {
            this.dispatch(setSnackbar({ message, autoHideDuration, action }));
        }
    }

    setSearchTerm(newTerm: string) {
        this.searchTerm = newTerm;
    }

    handleApiError(response) {
        this.pushSnackbar(response.message);
    }

    handleRequestCancelled() {
        this.pushSnackbar(API_WARNING_CANCELLED);
    }

    isErrorRecoverable(response) {
        return response.message === API_WARNING_CANCELLED;
    }

    setRecordsProcessing(newValue: boolean) {
        this.recordsProcessing = newValue;
    }

    finishLoading(key: keyof InitialStateType["storesInit"]) {
        console.log(`Task Manager ${key} Store has finished loading`);

        this.storesInit[key] = true;
    }

    renderUnauthorized() {
        this.shouldRenderUnauthorized = true;
    }

    /**
     * Checks if all Task Manager stores have initialized OK
     * @returns True if all stores initialized OK
     */
    storesInitialized() {
        if (!this.storesInit.root) {
            return false;
        }

        if (!this.storesInit.tickets) {
            return false;
        }

        if (!this.storesInit.ticketDetails) {
            return false;
        }

        if (!this.storesInit.validator) {
            return false;
        }

        if (!this.storesInit.ticketComments) {
            return false;
        }

        if (!this.storesInit.ticketHistory) {
            return false;
        }

        if (!this.storesInit.backgroundProcessing) {
            return false;
        }

        return true;
    }
}

const StoresContext = React.createContext(
    new RootStore(
        new TaskManagerApi(USER_TOKEN),
        new RecordServiceApi(USER_TOKEN),
        new UserServiceApi(USER_TOKEN),
        new TaskManagerAdapters(),
    ),
);
export const useStores = () => React.useContext(StoresContext);
