const TurnerCMSData = {
    clientData: {
        clientName: "Warner Media",
    },
    clientDataStructure: {
        classification: [
            {
                label: "Minimum Age",
                key: "minimum_age",
                component: "number",
                placeholder: "Enter Minimum Age",
            },
            {
                label: "Maximum Age",
                key: "maximum_age",
                component: "number",
                placeholder: "Enter Maximum Age",
            },
        ],
    },
    clientFeatures: {
        games: true,
        assets: {
            tmcAssetValidation: true,
        },
        synopsis60: true,
        turner_emea_id: true,
        turner_emea_game_id: true,
        turner_apac_id: true,
        turner_apac_game_id: true,
        turner_latam_id: true,
        turner_latam_game_id: true,
        game_international_id: true,
        turner_scarlett_id: true,
        title19: true,
        title35: true,
        title40: true,
        title50: true,
        synopsis400: true,
        synopsis640: true,
        filmstruck320: true,
        sequential: true,
        brand: true,
        synopses: ["60", "400", "640", "filmstruck320"],
        'creditsVersion':'v3',
    },
    apiUrls: {
        logout: "logout",
    },
    user: {
        api_token: "token",
    },
};

export default TurnerCMSData;
