import React, { Component } from "react";
import _ from "lodash";
import "./styles/_styles.scss";
import Settings from "../Settings";
import TextScrolling from "../TextScrolling";

const LabelDynamic = (props) => {
    const { styles = {}, items = [], handleDelete, handleEdit } = props;

    if (_.isEmpty(items)) {
        return false;
    }

    return (
        <div className="meta-keyword-dynamic" style={styles}>
            {items.map((item, index) => {
                return (
                    <div
                        key={index}
                        style={item.styles || {}}
                        className="meta-keyword-dynamic--item"
                    >
                        <TextScrolling>
                            <div className="meta-keyword-dynamic--text">
                                {item.title}
                            </div>
                        </TextScrolling>
                    </div>
                );
            })}

            <div className="buttons">
                {handleEdit && (
                    <img
                        src={`${Settings.images.path}/svg/pencil-white.svg`}
                        className="edit"
                        onClick={() => handleEdit()}
                    />
                )}

                {handleDelete && (
                    <img
                        src={`${Settings.images.path}/svg/clear-white.svg`}
                        className="delete"
                        onClick={() => handleDelete()}
                    />
                )}
            </div>
        </div>
    );
};

export default LabelDynamic;
