import _ from "lodash";
import deepcopy from "deepcopy";

const GetRecordData = (props) => {
    const { state = {} } = props;

    const { hoverRecord, activeRecord, recordData, newRecord } = state;

    let result = {};

    if (!_.isEmpty(hoverRecord)) {
        result = deepcopy(hoverRecord);
    }

    if (!_.isEmpty(activeRecord)) {
        result = deepcopy(activeRecord);
    }

    if (!_.isEmpty(activeRecord) && !_.isEmpty(recordData)) {
        result = deepcopy(recordData);
    }

    if (!_.isEmpty(newRecord)) {
        result = deepcopy(newRecord);
    }

    return result;
};

export default GetRecordData;
