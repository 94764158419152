import React from 'react'
import { isEmpty } from "lodash"
import { RootStore } from '../rootStore'
import { makeObservable, action, extendObservable } from 'mobx'
import { updateStoreInitialState } from "../state"
import { CreditListStore } from "./creditListStore"
import { configurationUpdate } from "../../config/creditListForm.config"

export class CreditListUpdateStore extends CreditListStore {
    constructor(rootStore: RootStore) {
        super(rootStore)

        makeObservable(this, {
            handleSetConfiguration: action,
            handleSubmit: action
        });

        extendObservable(this, updateStoreInitialState);
    }

    initialize() {
        this.handleSetConfiguration()
        this.handleSetCoreFormFields()
    }

    handleSetConfiguration(): void {
        this.setConfiguration(configurationUpdate)
    }

    handleSubmit(): void {
        this.rootStore.creditListValidationStore.handleValidation(this.configuration, this.creditList, this.creditListOriginal)

        if (isEmpty(this.rootStore.creditListValidationStore.errorAlerts) && isEmpty(this.rootStore.creditListValidationStore.errors)) {
            this.rootStore.creditListsStore.handleUpdateCreditList(this.creditList)
            this.rootStore.creditListsStore.setSelectedCreditList(this.creditList)
            this.rootStore.setShowCreditListUpdateForm(false)
        }
    }
}
