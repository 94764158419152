import React from "react"
import { isEmpty, get } from "lodash"
import Boilerplate from "./Boilerplate"
import { useStore as useGlobalStore } from '../../Global/store/store'
import CoreDynamicForm from '../../../core/Form/dynamic'
import { RECORD_BUILDER } from '../../Global/Options'
import { ORIGINATION } from "../../../models/RecordBuilder"
import BuilderError from "../../RecordBuilder/FetchError";
import PlaceholderText from "../../PlaceholderText"
import CoreFormFieldInterface from '../../../core/Form/dynamic/interfaces/Field.interface'
import CoreDynamicFormFieldsMapper from '../../RecordBuilder/map/dynamicCoreForm/coreDynamicForm'
import OriginationField, { Section } from "../../../models/RecordBuilder/OriginationField.interface"

type Props = {
    disabled: boolean
    handleFormChange: Function
    category: string
    data: any
    validationKeys: []
}

const OriginationBase = (props: Props) => {
    const {
        disabled,
        category,
        data,
        validationKeys,
        handleFormChange,
    } = props

    const [globalState] = useGlobalStore() || [{}];

    const handleChange = (origination) => {
        handleFormChange(origination);
    }

    let recordBuilderData = globalState[RECORD_BUILDER]
    let recordBuilderError = globalState.recordBuilderError
    
    let configuration = get(recordBuilderData, ORIGINATION, []).filter((config: OriginationField) => config.section == Section.Origination)

    let coreFormFields: CoreFormFieldInterface[] = CoreDynamicFormFieldsMapper({ disabled, category, configuration })
    const isEmptyConfiguration = isEmpty(coreFormFields) && !recordBuilderError

    return (
        <div className="origination-builder">
            <Boilerplate
                title={"Origination"}
                className={"meta-record-origination-builder"}
                displayTitle={true}
            >
                {recordBuilderError && <BuilderError />}

                {isEmptyConfiguration && <PlaceholderText useMargin={true} />}

                <CoreDynamicForm 
                    fields={coreFormFields} 
                    data={data} 
                    onChange={(origination) => handleChange(origination)}
                    disabled={disabled}
                    originalData={data}
                    triggerEnabled={true}
                />
            </Boilerplate>
            
        </div>
    )
}

export default OriginationBase