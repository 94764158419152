import React, { Component } from "react";
import _ from "lodash";
import KeywordLabel from "../KeywordLabel";
import update from "react-addons-update";
import Index from "./index";
import isIndexInArray from "../Helpers/isIndexInArray";

export default class BubbleListSimpleOnEnter extends Index {
    addEntryToDataState(value) {
        const { data } = this.state;

        const updatedState = update(data, { $push: [value] });

        this.setState(
            {
                data: updatedState,
            },
            () => {
                this.handleFormChange(updatedState);
            },
        );
    }

    filterDatalist(data, datalist) {
        let result = super.filterDatalist(data, datalist);

        if (Array.isArray(data) && Array.isArray(result)) {
            result = result.filter(
                (datalistItem) => !isIndexInArray(data, datalistItem),
            );
        }

        return result;
    }

    handleDelete(index) {
        const { data } = this.state;

        const { fullyDelete = true } = this.props;

        let updatedState = data;

        if (fullyDelete) {
            updatedState = update(data, {
                $splice: [[index, 1]],
            });
        } else {
            const updatedItem = {
                ...(data[index] || {}),
                deleted: true,
            };

            updatedState = update(data, {
                [index]: {
                    $set: updatedItem,
                },
            });
        }

        this.setState(
            {
                data: updatedState,
            },
            () => {
                this.handleFormChange(updatedState);
            },
        );
    }

    renderData(styles = {}) {
        const { titleKey, datalist } = this.props;

        return (
            <React.Fragment>
                {this.state.data.map((item, index) => {
                    if (item.deleted) {
                        return false;
                    }

                    let itemTitle =
                        item[titleKey] || item.text || item.name || item;

                    if (typeof itemTitle !== "string") {
                        const datalistItem = datalist.find(
                            (obj) => obj.value === item.value,
                        );

                        itemTitle = datalistItem ? datalistItem.name : "";
                    }

                    return (
                        <KeywordLabel
                            title={itemTitle}
                            weighting={false}
                            background={this.props.background || "#dedede"}
                            color={this.props.color || "#494949"}
                            margin={
                                styles.margin || "0.71875rem 0.71875rem 0 0"
                            }
                            paddingTop={styles.paddingTop}
                            paddingBottom={styles.paddingBottom}
                            delete={true}
                            deleteWhite={this.props.deleteWhite || false}
                            disabled={this.props.readOnly}
                            handleDelete={this.handleDelete.bind(this, index)}
                            key={index}
                        />
                    );
                })}
            </React.Fragment>
        );
    }
}
