import React, { useState } from "react";
import "./styles/_styles.scss";
import SelectStyles from "../Select/styles";
import _ from "lodash";
import AdaptListItem from "./src/AdaptListItem";
import StringUnderlineSearch from "../Helpers/StringUnderlineSearch";
import {
    StringPriorityTransformer,
    PRIORITY_KEYS_TEXT_OR_ORIGINAL_TITLE,
} from "../Transformers/StringPriorityTransformer";
import Settings from "../Settings";

const DatalistItems = (props) => {
    const {
        value = "",
        datalist = [],
        filterStaticDataList,
        onSelection = () => {},
        onSelectCreateNew = () => {},
        createNew,
        datalistMultiDimensional = false,
        datalistId,
        limitResults,
        datalistMultiDimensionalUseSingle = false,
        multiDimensionalSelectionPreset = {},
        valueExists,
        noMatchText = null,
    } = props;

    const dimensionalListTopStep = "2.825";

    const [multiDimensionalItems, setMultiDimensionalItems] = useState({});
    const [activeIndex, setactiveIndex] = useState(null);
    const [isCalledMultiDimensionalPreset, setIsCalledMultiDimensionalPreset] =
        useState(false);

    let itemIndex = activeIndex
        ? _.findIndex(datalist, (item) => item.value == activeIndex)
        : false;

    const getItemKey = (item) => {
        let result = "";

        const keys = StringPriorityTransformer({
            item,
            priority: PRIORITY_KEYS_TEXT_OR_ORIGINAL_TITLE,
        });

        if (typeof item == "object" && keys) {
            result = keys;
        }

        if (typeof item == "string") {
            result = item;
        }

        return result;
    };

    const RenderItem = (props) => {
        const {
            className,
            onClick = () => {},
            item,
            options,
            itemValue,
            postfix,
            styles = {},
            postfixBold,
            dataTestId,
            enableUnderlineSearch = false,
        } = props;

        if (!item) {
            return false;
        }

        const handleHover = () => {
            if (datalistMultiDimensional && _.isEmpty(multiDimensionalItems)) {
                setactiveIndex(itemValue);
            }
        };

        let liStyles = styles;

        if (datalistMultiDimensional) {
            if (itemValue === activeIndex) {
                if (
                    !(datalistMultiDimensionalUseSingle && _.isEmpty(options))
                ) {
                    liStyles = SelectStyles().backgroundArrowRight;
                    liStyles.backgroundColor = "#d7d7d7";
                }
            }
        }

        const handleOnClick = (e) => {
            if (datalistMultiDimensional) {
                if (datalistMultiDimensionalUseSingle && _.isEmpty(options)) {
                    onClick();
                    return;
                }

                e.stopPropagation();
                setMultiDimensionalItems(options);
                setactiveIndex(itemValue);
                return;
            }

            onClick();
        };

        if (
            !_.isEmpty(multiDimensionalSelectionPreset) &&
            datalistMultiDimensional &&
            multiDimensionalSelectionPreset.parent &&
            !isCalledMultiDimensionalPreset
        ) {
            let itemData = datalist.find(
                (item) => item.value === multiDimensionalSelectionPreset.parent,
            );
            setMultiDimensionalItems(
                itemData && itemData.options ? itemData.options : {},
            );
            setactiveIndex(multiDimensionalSelectionPreset.parent);
            setIsCalledMultiDimensionalPreset(true);
        }

        let underlineItem = item;

        if (enableUnderlineSearch) {
            underlineItem = StringUnderlineSearch(item, value);
        }

        return (
            <li
                className={`${className} meta-datalist-item`}
                onClick={handleOnClick.bind(this)}
                onMouseEnter={() => handleHover()}
                style={liStyles}
            >
                <span
                    className="underline-list-item"
                    dangerouslySetInnerHTML={{ __html: underlineItem }}
                    data-testid={dataTestId}
                ></span>

                {postfix && (
                    <span className="meta-datalist-item--postfix">
                        {postfix}
                    </span>
                )}
                {postfixBold && (
                    <span className="meta-datalist-item--postfix-bold">
                        {postfixBold}
                    </span>
                )}
            </li>
        );
    };

    const RenderItems = (props) => {
        const { items } = props;

        if (!_.isEmpty(items) && Array.isArray(items)) {
            return (
                <React.Fragment>
                    {items.map((item, index) => {
                        return (
                            <RenderItem
                                item={getItemKey(item)}
                                postfix={item.postfix}
                                postfixBold={item.postfixBold}
                                itemValue={item.value || item}
                                index={index}
                                onClick={() => onSelection(item)}
                                key={index}
                                options={item.options || {}}
                                enableUnderlineSearch={true}
                                {...props}
                            />
                        );
                    })}
                </React.Fragment>
            );
        }

        return false;
    };

    let filteredDatalist = datalist;
    if (Array.isArray(datalist) && filterStaticDataList && value) {
        filteredDatalist = datalist.filter((item) => {
            let valueString = value;
            if (typeof value == "object" && value) {
                valueString = value.text;
            }

            if (typeof valueString == "string" && valueString) {
                if (typeof item == "object" && item) {
                    if (typeof item.text == "string") {
                        if (
                            item.text
                                .toLowerCase()
                                .includes(valueString.toLowerCase())
                        ) {
                            return item;
                        }
                    }

                    if (typeof item.value == "string") {
                        if (
                            item.value
                                .toLowerCase()
                                .includes(valueString.toLowerCase())
                        ) {
                            return item;
                        }
                    }
                }

                if (typeof item == "string") {
                    if (
                        item.toLowerCase().includes(valueString.toLowerCase())
                    ) {
                        return item;
                    }
                }
            }
        });
    }

    if (Number.isInteger(limitResults)) {
        filteredDatalist = _.take(filteredDatalist, limitResults);
    }

    let display = props.display;
    if (_.isEmpty(datalist) && _.isEmpty(filteredDatalist) && !createNew) {
        display = false;
    }

    const empty =
        _.isEmpty(multiDimensionalItems) &&
        _.isEmpty(filteredDatalist) &&
        !createNew;

    if (display && (!_.isEmpty(filteredDatalist) || value)) {
        let showCreateNew = false;
        if (createNew && value && Array.isArray(filteredDatalist)) {
            let isInList = filteredDatalist.find((item) => {
                return (
                    AdaptListItem(item).text.trim().toLowerCase() ===
                    value.trim().toLowerCase()
                );
            });
            if (_.isEmpty(isInList)) showCreateNew = true;
        }

        let multidimensionalTopStyle = "-0.06125rem";
        if (itemIndex && itemIndex != -1 && Number.isInteger(itemIndex)) {
            multidimensionalTopStyle =
                dimensionalListTopStep * itemIndex + "rem";
        }

        return (
            <div className="meta-datalist-items--container">
                <ul
                    className="meta-datalist-items meta-datalist"
                    id={datalistId}
                >
                    {showCreateNew && (
                        <div>
                            {valueExists && (
                                <RenderItem
                                    item={`"${value}" Already Exists!`}
                                    className="meta-datalist-items--list"
                                    onClick={() => {}}
                                />
                            )}

                            {!valueExists && (
                                <RenderItem
                                    item={
                                        noMatchText
                                            ? noMatchText.replace(
                                                  "{value}",
                                                  `<u>${value}</u>`,
                                              )
                                            : `No match for <u>"${value}"</u>. Create New?`
                                    }
                                    classNames="meta-datalist-items--list"
                                    onClick={() => onSelectCreateNew(value)}
                                    dataTestId="datalist-create-new"
                                />
                            )}

                            {!_.isEmpty(filteredDatalist) && (
                                <RenderItem
                                    item="Suggestions"
                                    className="meta-datalist-items--list"
                                    onClick={() => {}}
                                    styles={{
                                        color: "rgba(110, 110, 110, 0.87)",
                                    }}
                                />
                            )}
                        </div>
                    )}

                    {!_.isEmpty(filteredDatalist) && (
                        <RenderItems
                            className="meta-datalist-items--list"
                            items={filteredDatalist}
                            identifier="list"
                        />
                    )}

                    {!_.isEmpty(multiDimensionalItems) && (
                        <ul
                            className="meta-datalist-items--multidimensional meta-datalist"
                            style={{ top: multidimensionalTopStyle }}
                        >
                            {Object.keys(multiDimensionalItems).map(
                                (key, parentIndex) => {
                                    return (
                                        <React.Fragment key={parentIndex}>
                                            <li className="groupLabel">
                                                {key}
                                            </li>

                                            {multiDimensionalItems[key].map(
                                                (item, index) => {
                                                    return (
                                                        <li
                                                            key={index}
                                                            onClick={() => {
                                                                onSelection({
                                                                    value: item,
                                                                    key,
                                                                    parent: activeIndex,
                                                                });
                                                                setMultiDimensionalItems(
                                                                    {},
                                                                );
                                                            }}
                                                        >
                                                            {typeof item ===
                                                            "object"
                                                                ? item.text
                                                                : item}
                                                        </li>
                                                    );
                                                },
                                            )}
                                        </React.Fragment>
                                    );
                                },
                            )}
                        </ul>
                    )}

                    {empty && (
                        <RenderItem
                            item={`No match found. Please refine your search.`}
                            className="meta-datalist-items--list"
                        />
                    )}
                </ul>
            </div>
        );
    }

    return false;
};

export default DatalistItems;
