import deepcopy from "deepcopy";
import _ from "lodash";
import {
    DataStructureItem,
    DataItem,
    ValidationResult,
    ValidationProps
} from "../../models/Validation/interfaces";
import isActiveRecordBuilder from '../RecordBuilder/IsActive'
import { ORIGINATION } from "../../models/RecordBuilder"

export default function ValidateOrigination({
    result,
    options,
}: ValidationProps): ValidationResult {
    let response = deepcopy(result);

    const {
        data = {}, 
        status, 
        clientDataStructure = {}, 
        clientFeatures = {}
    } = options;

    if (isActiveRecordBuilder({ page: ORIGINATION,  clientFeatures})) {
        return response
    }

    const { origination: originationData = [] } = data;

    const { origination = [], origination_dynamic_inputs = {} } =
        clientDataStructure;

    const { three_way_options = [], full_width_options = [] } =
        origination_dynamic_inputs;

    const originationFields = origination.concat(
        three_way_options,
        full_width_options,
    );

    if (!_.isEmpty(originationFields)) {
        originationFields.forEach((item: DataStructureItem) => {
            const fieldKey: string = item.key;
            let limit: Number | boolean = false;

            if (Number.isInteger(item.limit)) {
                limit = item.limit;
            }

            if (
                Array.isArray(originationData[fieldKey]) &&
                Number.isInteger(limit)
            ) {
                const originationField: DataItem[] = originationData[
                    fieldKey
                ].filter((item: DataItem) => !item.deleted);

                if (originationField.length > limit) {
                    response.result = false;
                    response.notification = {
                        title: `Please check ${item.name || fieldKey}`,
                        description: `Maximum ${limit} ${
                            item.name || fieldKey
                        } is allowed per record`,
                        status,
                    };

                    response.validationKeys = (
                        result.validationKeys as DataStructureItem[]
                    ).concat([item]);
                }
            }

            if (
                originationData.number_of_episodes_in_season &&
                originationData.number_of_episodes_in_season !== "" &&
                !Number.isInteger(
                    Number(originationData.number_of_episodes_in_season),
                )
            ) {
                response.result = false;
                response.notification = {
                    title: `Please check Number of episodes in Season field`,
                    description: `Field requires Integer value`,
                    status,
                };

                response.validationKeys = result.validationKeys.concat([
                    "number_of_episodes_in_season",
                ]);
            }
        });
    }

    return response;
}
