import clientManagedController from "./clientManagedController";
import {
    StringPriorityTransformer,
    PRIORITY_KEYS_NAME_OR_TEXT,
} from "../Transformers/StringPriorityTransformer";

export function isClientManagedSupplier(props) {
    const { clientFeatures = {} } = props;

    const { managedLists = [] } = clientFeatures;

    return managedLists.includes("suppliers");
}

const SupplierOptions = (props) => {
    const { options = {} } = props;

    let result = clientManagedController({
        clientManagedOptions: options.client_managed_suppliers || [],
        fallback: options.suppliers || [],
        isClientManaged: isClientManagedSupplier(props),
    });

    return result.map((item) =>
        StringPriorityTransformer({
            item,
            priority: PRIORITY_KEYS_NAME_OR_TEXT,
        }),
    );
};

export function SupplierOptionsTransformer(client_managed_suppliers) {
    let suppliers = client_managed_suppliers;

    if (!Array.isArray(suppliers)) {
        suppliers = JSON.parse(client_managed_suppliers);
    }

    return suppliers.map((item) => {
        return {
            text: item.text,
            value: item.value,
        };
    });
}

export default SupplierOptions;
