import React from "react";
import { useFormContext } from "react-hook-form";
import {
    CoreInputText,
    CoreInputTextProps,
} from "../../../core/Form/components/inputs/CoreInput.text";

type Props<T> = CoreInputTextProps<T> & {
    maxLength: number;
};

function ObservableTextInputWithChip<T>({
    maxLength,
    ...inputProps
}: Props<T>): JSX.Element {
    const formMethods = useFormContext<T>();
    const value = formMethods.watch(inputProps.path);

    return (
        <CoreInputText<T>
            {...inputProps}
            labelProps={{
                ...inputProps.labelProps,
                ...{
                    characterCounter: {
                        inputTextMaxLength: maxLength,
                        inputTextLength: String(value).length,
                    },
                },
            }}
        />
    );
}

export default ObservableTextInputWithChip;
