import React, { useMemo, useEffect } from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateValidationError } from '@mui/x-date-pickers/models';
import { useStore } from "../../Global/store/store";
import Label from './Label'
import dayjs from 'dayjs';
import moment from 'moment'
import "./_styles.scss"

export interface MaterialDatePickerInterface {
    label?: string,
    labelLegacy?: string,
    defaultValue: string,
    disabled?: boolean,
    readOnly?: boolean,
    format?: string,
    className?: string,
    id?: string,
    required?: boolean,
    minDate?: string,
    maxDate?: string,
    styles?: any,
    placeholder?: string,
    time_format?: string,
    error?: boolean,
    path?: string,
    handleChange: Function
}

const yearFormat: string = "YYYY"

export const MaterialDatePicker = (props: MaterialDatePickerInterface) => {
    const [store] = useStore() || [{}]

    const {
        defaultValue,
        disabled,
        readOnly,
        id = "",
        className = "",
        required,
        minDate,
        maxDate,
        styles = {},
        handleChange = () => {},
        placeholder,
        time_format,
        inputStyles = {}
    } = props

    let format = time_format 
        ? `${store?.cmsData?.clientDataStructure?.date_format} ${time_format}`
        : store?.cmsData?.clientDataStructure?.date_format

    let datepickerProps: any = {
        value: null
    }
    if (defaultValue) {
        datepickerProps.value = dayjs(defaultValue)
        datepickerProps.defaultValue = dayjs(defaultValue)
    }
    if (minDate) datepickerProps.minDate = dayjs(minDate)
    if (maxDate) datepickerProps.maxDate = dayjs(maxDate)

    return (
        <div 
            style={styles}
            className={`fabric-datepicker-material ${!disabled && 'active'} ${className || ''} ${required ? "fabric-datepicker-material-required" : ""}`}>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Label {...props}/>

                <DesktopDatePicker 
                    {...datepickerProps}
                    format={format}
                    disabled={readOnly || disabled}
                    id={id}
                    data-testid={`core-select-${props?.path}`}
                    slotProps={{ 
                        field: { 
                            shouldRespectLeadingZeros: true 
                        },
                        textField: { 
                            placeholder: placeholder || format 
                        }
                    }}
                    onChange={(value: any) => handleDatePickerChange(value, format, handleChange)}
                    sx={inputStyles}
                />
            </LocalizationProvider>
        </div>
    )
}

export const MaterialYearPicker = (props: MaterialDatePickerInterface) => {
    const {
        defaultValue,
        disabled,
        readOnly,
        className = "",
        required,
        styles = {},
        labelLegacy,
        label,
        handleChange = () => {},
        inputStyles = {}
    } = props

    const [error, setError] = React.useState<DateValidationError | null>(null);

    let datepickerProps: any = {}
    if (defaultValue) datepickerProps.defaultValue = dayjs().year(parseInt(defaultValue))

    const errorMessage = useMemo(() => {
        const validationMessage: string = `${labelLegacy || label} must be a valid year`

        if (props.error) {
            return validationMessage
        }

        switch (error) {
            case 'invalidDate': {
                return validationMessage
            }

            default: {
                return '';
            }
        }
    }, [error, props.error]);

    return (
        <div 
            style={styles}
            className={`fabric-datepicker-material 
                ${!disabled && 'active'} 
                ${className || ''} 
                ${props.error && 'error'}
                ${required ? "fabric-datepicker-material-required" : ""}`}>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Label {...props}/>

                <DatePicker 
                    {...datepickerProps}
                    format={yearFormat}
                    disabled={readOnly || disabled}
                    onError={(newError) => setError(newError)}
                    slotProps={{ 
                        field: { 
                            shouldRespectLeadingZeros: false 
                        },
                        textField: {
                            helperText: errorMessage,
                        },
                    }}
                    onChange={(value: any) => handleYearPickerChange(value, handleChange)}
                    views={['year']}
                    sx={inputStyles}
                />
            </LocalizationProvider>
        </div>
    )
}

function handleDatePickerChange(value: Date|string, format: string, handleChange: Function): void {
    const dateString: Date | string = value ? moment(value.toDate()).toDate() : ""
    const date: string = moment(dateString).format(format)

    if (moment(value).isValid()) {
        handleChange(date)
    }
}

function handleYearPickerChange(value: Date|string, handleChange: Function): void {
    const dateString: Date | string = value ? moment(value.toDate()).toDate() : ""
    const date: string = moment(dateString).format(yearFormat)

    if (moment(date).isValid()) {
        handleChange(date)
    }

    if (!value) {
        handleChange(null)
    }
}

export default MaterialDatePicker