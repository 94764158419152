import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React from "react";
import CharacterCounterChip from "../inputs/CoreCharacterCounter.chip";

// TODO: implement theming
const Text = styled("label")(({ theme }) => ({
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: "1.3125rem",
    color: "rgba(47, 47, 47, 0.87)",
    margin: "0 0 0.3125rem 0",
}));

Text.defaultProps = {
    margin: "dense",
};

const DisabledText = styled(Text)(({ theme }) => ({
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: "1.3125rem",
    color: "rgba(47, 47, 47, 0.37)",
}));

const RedText = styled("span")(({ theme }) => ({
    color: "rgba(214, 70, 94, 1)",
    margin: "0 0 0 0.3125rem",
}));

export type CoreFormLabelProps = {
    text?: string;
    isRequired?: boolean;
    isDisabled?: boolean;
    tooltip?: JSX.Element | null;
    customStyles?: React.CSSProperties;
    characterCounter?: {
        inputTextMaxLength: number;
        inputTextLength: number;
    };
    containerStyles?: React.CSSProperties;
};
export const CoreFormLabel = ({
    text,
    isRequired = false,
    isDisabled,
    tooltip,
    customStyles,
    characterCounter,
    containerStyles
}: CoreFormLabelProps) => {
    return (
        <Stack direction="row" sx={containerStyles}>
            {!isDisabled ? (
                <Text sx={customStyles}>
                    {text}
                    {isRequired && <RedText>*</RedText>}
                </Text>
            ) : (
                <DisabledText>{text}</DisabledText>
            )}

            <Box marginTop={-1}>{tooltip}</Box>
            {characterCounter && (
                <CharacterCounterChip
                    maxValue={characterCounter.inputTextMaxLength}
                    value={characterCounter.inputTextLength}
                />
            )}
        </Stack>
    );
};

CoreFormLabel.defaultProps = {
    text: "",
    isRequired: false,
    isDisabled: false,
    tooltip: null,
};
