import React from 'react'
import Input from "../../interfaces/Input.interface";
import { CoreCheckbox } from "../../../components/inputs/Checkbox/CoreCheckbox";
import { observer } from "mobx-react";
import { useStores } from '../../store/rootStore'

export const BooleanCheckbox = observer(({ field }: Input) => {
    const { formStore } = useStores()

    return (
        <div data-testid={"fabric-dynamic-form-boolean-checkbox-input"}>
            <CoreCheckbox
                path={field.key}
                label={field.label}
                labelMessage={field.labelMessage}
                disabled={field.validation?.readOnly?.enabled || formStore.disabled}
            />
        </div>
    )
})