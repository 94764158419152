import Prefixer from "inline-style-prefixer";

export default function styles(props = {}) {
    const minHeight = props.height || "16rem";

    let height = minHeight;
    let imageMaxHeight = props.imageMaxPercentage || "95%";

    if (props.responsive) {
        height = "100%";
        imageMaxHeight = `calc(${minHeight} - 1rem)`;
    }

    let styles = {
        image: {
            maxWidth: props.imageMaxPercentage || "95%",
            maxHeight: imageMaxHeight,
            border: "solid 0.03125rem #030303",
            boxShadow: "0 0 0.4375rem 0 rgba(0, 0, 0, 0.85)",
            display: "block",
            margin: "auto",
            position: "relative",
            zIndex: 0,
            borderRadius: ".3125rem",
        },
        imageBasicStyles: {
            border: 0,
            boxShadow: 0,
        },
        imageCover: {
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: 1,
        },
        loadingImage: {
            display: "block",
            maxWidth: "2rem",
            margin: "auto",
        },
        imageInteractiveStyles: {
            cursor: "pointer",
        },
        placeholder: {
            width: "100%",
            display: "flex",
            alignItems: "center",
            height,
            minHeight,
            background: "#4d4d4d",
            boxShadow: "inset 0 0 21.5px 0 rgba(0, 0, 0, 0.86)",
            position: "relative",
            borderRadius: ".625rem",
        },
        placeholderImage: {
            display: "block",
            margin: "auto",
            maxWidth: props.placeholderImageWith || "5.15625rem",
        },
        posterIcon: {
            width: "1.25rem",
            top: "0.3125rem",
            right: "0.3125rem",
            position: "absolute",
            zIndex: 0,
        },
        imageFullStyles: {
            objectFit: "cover",
            objectPosition: "center bottom",
            maxHeight: "7.5rem",
            width: "100%",
            maxWidth: "100%",
            boxShadow: "none",
            border: "0",
        }
    };

    let prefixer = new Prefixer();
    styles = prefixer.prefix(styles);

    return styles;
}
