import React from "react";
import axios from 'axios'
import { CmsInterface } from "../../components/Global/interfaces"
import requestHeaders from "../../config/requestHeaders"

export async function getCelebrityById(cmsData: CmsInterface, id: string): Promise<number|null> {
    const { apiUrls = {} } = cmsData;

    const { ivaApiCelebritySearch = "" } = apiUrls;

    const response = await axios.get(`${ivaApiCelebritySearch}?${requestHeaders.ivaHeaders.urlParams}&Ids=Person/${id}&includes=Images`, {})
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error
        });

    return response?.data?.Hits[0]?.Source || {}
}