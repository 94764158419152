import { 
    RootStoreInterface, 
    ContributorStoreInterface,
    ContributorUpdateStoreInterface,
    OptionsStoreInterface,
    NavigationStoreInterface,
    PermissionsStoreInterface,
    IntegrationsStoreInterface,
    ValidationStoreInterface
} from "../interfaces"

export const rootStoreInitialState: RootStoreInterface = {
    cmsData: {}
};

export const optionsStoreInitialState: OptionsStoreInterface = {
    roleTypes: [],
    contributorTypes: [],
    defaultLanguageCode: '',
    bulkActionOptions: [],
    recordBuilderError: false,
    recordBuilderConfig: [],
    activeSectionConfig: {},
    activeSectionDefaultValues: {},
    viewKey: '',
    fetching: true
}

export const contributorStoreInitialState: ContributorStoreInterface = {
    contributorOriginal: {},
    contributor: {},
    contributorSectionData: {},
    deleteDialogActive: false,
    errorDialogActive: false,
    errorDialogConfig: {
        title: '',
        description: ''
    },
    dataKey: null,
    open: false,
    working: false,
    userChanges: {},
    ratings: {},
    showValidationErrors: false
};

export const contributorUpdateStoreInitialState: ContributorUpdateStoreInterface = {
    ...contributorStoreInitialState,
    fetching: false
};

export const validationStoreInitialState: ValidationStoreInterface = {
    validationMessages: [],
    fieldValidationMessages: [],
    errorSections: [],
    fieldErrorSections: []
};

export const navigationStoreInitialState: NavigationStoreInterface = {
    formMenu: [],
    activeMenuIndex: 0
}

export const permissionsStoreInitialState: PermissionsStoreInterface = {
    userHasDeletePermission: false,
    userHasUpdatePermission: false,
    userHasCreatePermission: false,
}

export const integrationsStoreInitialState: IntegrationsStoreInterface = {
    ratings: {},
    fetchingKeys: {},
    originId: ''
}