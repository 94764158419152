import dayjs from 'dayjs';
import _ from "lodash";

interface RightsData {
    deal_name:  string
    deal_status: string 
    deal_sub_type: string 
    deal_type:  string
    license_end: string
    license_start: string 
    licensor: string
    linear_rights: string[]
    no_linear_rights: string[]
    stations_ids: string[]
    temp_download_enabled: string
    window_id: string
    window_name: string
}

export const sortRights = (data: RightsData[]): RightsData[] => {
    const today = dayjs()

    const activeRights: RightsData[] = _.orderBy(
        data.filter(right => dayjs(today).isBetween(dayjs(right.license_start), dayjs(right.license_end))
        ),
        [(right) => dayjs(right.license_start)],
        "asc",
    )

    const futureRights: RightsData[] = _.orderBy(
        data.filter(right => dayjs(dayjs(right.license_start)).isAfter(today)
        ),
        [(right) => dayjs(right.license_start)],
        "asc",
    )

    const expiredRights: RightsData[] = _.orderBy(
        data.filter(right => dayjs(dayjs(right.license_end)).isBefore(today)
        ),
        [(right) => dayjs(right.license_end)],
        "asc",
    )

    return activeRights.concat(futureRights).concat(expiredRights)
}