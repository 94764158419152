import Prefixer from "inline-style-prefixer";

export default function styles(props = {}) {
    let styles = {
        container: {
            width: "100%",
        },
        label: {
            display: "block",
            fontSize: "0.90625rem",
            fontWeight: 500,
            color: "rgba(110, 110, 110, 0.87)",
        },
        inlineContent: {
            width: "100%",
        },
    };

    let prefixer = new Prefixer();
    styles = prefixer.prefix(styles);

    return styles;
}
