import React from "react";
import Button from "../Button";
import downloadFile from "../File/downloadFile"

type Props = {
    href: string;
    text: string;
    fileName: string
};

const DownloadButtonOnHover: React.FC = ({ href, text, fileName }: Props) => {
    return (
        <Button
            value={text}
            type="button"
            imagesButtonPrimary
            className="meta-download-image-container"
            onClick={(event: React.SyntheticEvent) => downloadFile(event, href, fileName)}
        />
    )
};

export default DownloadButtonOnHover;
