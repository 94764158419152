import React, { useState } from "react";
import _ from "lodash";
import "./styles/_styles.scss";
import SortableTable from "../../../components/SortableTable";
import RenderRows from "../utils/RenderRows"
import HandleSort from "../utils/HandleSort";
import HandleNewRow from "../utils/HandleNewRow";
import HandleChange from "../utils/HandleChange";
import HandleDeleteRow from "../utils/HandleDeleteRow";
import AddNewRowButton from "../../../components/AddNewRowButton";
import Notification from "../../../components/Notification"

const Awards = (props) => {
    const {
        data = [],
        year,
        handleChange = () => {},
        script,
        defaultScriptCode,
        cmsData,
        allowCreateNew,
        canCreateNew = true,
    } = props;

    const [items, setItems] = useState(data);
    const [activeRow, setActiveRow] = useState({});
    const [editRowIndex, setEditRowIndex] = useState(false);
    const [notification, setNotification] = useState({});
    const disabled = props.disabled;
    const borderRadius = !_.isEmpty(items);
    const isScript = defaultScriptCode !== script;

    const changeProps = {
        setItems,
        activeRow,
        items,
        setEditRowIndex,
        setActiveRow,
        handleChange,
        editRowIndex,
    };

    const rows = RenderRows({
        items,
        editRowIndex,
        year,
        setItems,
        activeRow,
        setEditRowIndex,
        isScript,
        script,
        cmsData,
        createNew: allowCreateNew,
        disabled: props.disabled,
        handleChange: (props) => {
            const { value, key, index } = props;

            let currentItem = {};
            let updatedItems = items.map((item, itemIndex) => {
                if (itemIndex === index) {
                    item = HandleChange({
                        value,
                        key,
                        activeRow: item,
                        isScript,
                        script,
                    });
                    currentItem = item;
                }
                return item;
            });

            if (
                !_.isEmpty(currentItem) &&
                currentItem.result &&
                !_.isEmpty(currentItem.result.value) &&
                currentItem.award_body &&
                currentItem.award_body.value &&
                !_.isEmpty(currentItem.award_body.value) &&
                currentItem.year &&
                currentItem.category &&
                !_.isEmpty(currentItem.category.value)
            ) {
                handleChange(updatedItems);
                setEditRowIndex(false);
            }

            setItems(updatedItems);
        },
        handleDeleteRow: (props) =>
            setNotification({
                title: "Delete row",
                description: "Are you sure you want to delete this row?",
                confirm: () => {
                    HandleDeleteRow({
                        index: props.index,
                        ...changeProps,
                    });

                    setNotification({});
                },
            }),
    });

    return (
        <div>
            <SortableTable
                items={rows}
                disabled={disabled}
                styles={{
                    border: 0,
                    overflow: "initial",
                }}
                itemStyles={{
                    padding: "0.96875rem 0.59375rem",
                }}
                handleSort={(movedData) => {
                    if (!Number.isInteger(editRowIndex)) {
                        HandleSort({
                            movedData,
                            ...changeProps,
                        });
                    }
                }}
                borderRadiusTop={borderRadius}
            />

            {!disabled && (
                <AddNewRowButton
                    disabled={!canCreateNew || Number.isInteger(editRowIndex)}
                    onClick={() =>
                        HandleNewRow({
                            items,
                            setActiveRow,
                            setItems,
                            setEditRowIndex,
                        })
                    }
                    data={items}
                />
            )}

            <Notification
                title={notification.title}
                description={notification.description}
                intercationStatus="alert"
                onClick={() => setNotification({})}
                confirm={notification.confirm}
            />
        </div>
    );
};

export default Awards;
