import SendRequest from "../SendRequest";

const GoogleTranslateApi = (props) => {
    const {
        locale,
        text,
        handleError = () => {},
        handleSuccess = () => {},
    } = props;

    const options = {
        url: process.env.GOOGLE_TRANSLATE_API_URL,
        method: "POST",
        body: {
            text,
            locale,
        },
        overrideHeaders: {
            "x-api-key": process.env.GOOGLE_TRANSLATE_API_X_API_KEY,
        },
    };

    SendRequest(
        options,
        (response) => {
            const { statusCode } = response;

            if (statusCode == 200) {
                return handleSuccess(response.text);
            }

            // On Error
            handleError();
        },
        (e) => {
            handleError(e);
            console.log(e);
        },
    );
};

export default GoogleTranslateApi;
