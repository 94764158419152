import React, { useState, useMemo } from "react";
import _ from "lodash";
import TreeElement from "./TreeElement";
import { useStore } from "../../../state/context";
import { confirmAssetEdit } from "../../../state/actions/assetEdit";
import { ASSET_TYPES_CONFIG } from "../../../configuration";
import HeaderTag from "./Tag";
import AssetInterface from "../interfaces/AssetInterface";
import SubDubInterface from "../interfaces/SubDubInterface";
import Actions from "./Actions";
import Input from "../../../../../Input/basic";
import GetSectionConfiguration, {
    IsAdditionalSection,
} from "../section/AdditionalSection";
import HandleUpdateAssetName from "../../../helpers/HandleUpdateAssetName";
import Tooltip from "@mui/material/Tooltip";
import $ from "jquery";
import formatMultipleString from "../../../../../Helpers/formatMultipleString";

type HeaderProps = {
    asset: AssetInterface | SubDubInterface;
    hierarchyLevel: number;
    isLastChild: boolean;
    expandAll: boolean;
    setExpandAll: any;
};

const Header = (props: HeaderProps) => {
    const {
        asset,
        hierarchyLevel = 0,
        isLastChild = true,
        expandAll = false,
        setExpandAll = () => {},
    } = props;

    const [store, dispatch] = useStore();

    const [assetNameFocus, setAssetNameFocus] = useState<boolean>(false);
    const [showAssetNameTooltip, setShowAssetNameTooltip] =
        useState<boolean>(false);

    const {
        type,
        parentType,
        certifications = [],
        child_assets = [],
        subtitle_tracks = [],
        alternate_language_audio_tracks = [],
        asset_code,
        asset_name,
        filename,
        uuid,
    } = asset;

    const filteredCertifications = useMemo(() => certifications?.filter((cert) => !cert?.deleted), [certifications]);

    const { configuration, disabled = true, assets = [] } = store;

    const assetTypeConfig =
        configuration[ASSET_TYPES_CONFIG].find((item) => item.code == type) ||
        {};

    let assetTypeLabel = assetTypeConfig.displayName || "TYPE";

    if (IsAdditionalSection(type)) {
        const typeAdditionalConfig =
            configuration[ASSET_TYPES_CONFIG].find(
                (item) => item.code == parentType,
            ) || {};
        const sectionConfig = GetSectionConfiguration({
            sectionKey: type,
            typeConfiguration: typeAdditionalConfig,
        });
        if (sectionConfig) {
            assetTypeLabel = sectionConfig.displayName;
        }
    }

    const assetHasChildRows =
        !_.isEmpty(child_assets) ||
        !_.isEmpty(subtitle_tracks) ||
        !_.isEmpty(alternate_language_audio_tracks);

    const handleShowAssetNameTooltip = (input: boolean = false): void => {
        const htmlEl = input
            ? $(`#asset-name-input-wrapper-${uuid} input`)
            : $(`#asset-name-${uuid}`);
        let html =
            "<span id='tmpsmp' style='font-size: 14px'>" +
            (input ? htmlEl.val() : htmlEl.text()) +
            "</span>";
        $("body").append(html);

        setShowAssetNameTooltip(htmlEl.width() < $("#tmpsmp").width());
        $("#tmpsmp").remove();
    };

    return (
        <div className="asset-header">
            <TreeElement
                hierarchyLevel={hierarchyLevel}
                isChildExists={assetHasChildRows}
                isLastChild={isLastChild}
                asset={asset}
            />

            <div className="header-tags">
                {!IsAdditionalSection(type) && (
                    <Tooltip
                        title={asset_name}
                        open={showAssetNameTooltip}
                        placement="bottom-end"
                        arrow
                        PopperProps={{
                            disablePortal: true,
                            keepMounted: true,
                        }}
                        classes={{
                            popper: "asset-name-popper",
                        }}
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    bgcolor: "#3B4462",
                                    color: "common.white",
                                    "& .MuiTooltip-arrow": {
                                        color: "#3B4462",
                                    },
                                },
                            },
                        }}
                    >
                        <div data-tip={asset_name} className="tooltip-wrapper">
                            {disabled && (
                                <span
                                    id={`asset-name-${uuid}`}
                                    className="header-asset-name"
                                    onMouseEnter={() =>
                                        handleShowAssetNameTooltip()
                                    }
                                    onMouseLeave={() =>
                                        setShowAssetNameTooltip(false)
                                    }
                                >
                                    { !_.isEmpty(asset_name) ? asset_name : 'No Asset Name' }
                                </span>
                            )}

                            {!disabled && (
                                <div
                                    id={`asset-name-input-wrapper-${uuid}`}
                                    className={`asset-name-input-wrapper ${
                                        assetNameFocus
                                            ? `asset-name-input-wrapper-focus`
                                            : ``
                                    }`}
                                    onMouseEnter={() =>
                                        handleShowAssetNameTooltip(true)
                                    }
                                    onMouseLeave={() =>
                                        setShowAssetNameTooltip(false)
                                    }
                                >
                                    <Input
                                        value={asset_name}
                                        placeholder={`Enter Asset Name`}
                                        color={"#636363"}
                                        fontSize={"0.875rem"}
                                        focusColor={"#636363"}
                                        inputPadding={
                                            "0.313rem 0.313rem 0.313rem 0.5rem"
                                        }
                                        borderBackgroundFocus={"transparent"}
                                        textOverflow={"ellipsis"}
                                        handleChange={(value: string) => {
                                            dispatch(
                                                confirmAssetEdit(
                                                    HandleUpdateAssetName(
                                                        asset,
                                                        value,
                                                    ),
                                                    assets,
                                                ),
                                            );
                                        }}
                                        onClick={(e) => e.stopPropagation()}
                                        onFocus={(focus: boolean) =>
                                            setAssetNameFocus(focus)
                                        }
                                        borderBackground={"transparent"}
                                    />
                                </div>
                            )}
                        </div>
                    </Tooltip>
                )}

                {IsAdditionalSection(type) && filename && filename}

                <div className="right-tags">
                    {!_.isEmpty(filteredCertifications) &&
                        <HeaderTag
                            type="double"
                            text={formatMultipleString(filteredCertifications, 'Certification')}
                            secondText={filteredCertifications.length}
                        />
                    }

                    {asset_code && (
                        <HeaderTag text={asset_code.toUpperCase().replace('_', ' ')} />
                    )}

                    <HeaderTag text={assetTypeLabel.toUpperCase()} />
                </div>
            </div>

            <Actions
                asset={asset}
                expandAll={expandAll}
                setExpandAll={setExpandAll}
            />
        </div>
    );
};

export default Header;
