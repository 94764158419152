import React, { useEffect, useState } from "react";
import BubbleList from "../../BubbleList";
import GetCategoryObject from "../../CategoryTags/GetCategoryObject";
import "../styles/_akas.scss";
import { getDefaultWrittenLanguageCodeFromCache } from "../../../models/ClientManaged/languagesModel";

export const Akas = ({
    category = "",
    disabled = true,
    handleFormChange = () => {},
    locale = getDefaultWrittenLanguageCodeFromCache(),
    akas = [],
    clientFeatures = {},
}) => {
    const [data, setData] = useState([]);
    const [activeColor, setActiveColor] = useState("#ed145b");
    const [activeTextColor, setActiveTextColor] = useState("#ffffff");
    const [tempData, setTempData] = useState([]);

    const {
        akas: akasEnabled = false,
        fkas: fakasEnabled = false
    } = clientFeatures;

    const getFilteredData = (currentLocale) => {
        return akas?.filter((item) => item.locale === currentLocale);
    };

    useEffect(() => {
        setData(akas);
        setTempData(getFilteredData(locale));

        if (category) {
            setActiveColor(
                GetCategoryObject(category).tagBackground || "#ed145b",
            );

            setActiveTextColor(
                GetCategoryObject(category).tagColor || "#ffffff",
            );
        }
    }, [locale, akas]);

    const handleDelete = (itemToRemove, type) => {
        const updatedState = [...data];
        if (itemToRemove.id) {
            const index = updatedState.findIndex((item) => item.id === itemToRemove.id);
            if (index !== -1) {
                updatedState[index].deleted = true;
            }
        } else {
            const index = updatedState.findIndex(
                (item) =>
                    item.aka === itemToRemove.value &&
                    item.type === type &&
                    item.locale === locale,
            );
            if (index !== -1) {
                updatedState.splice(index, 1);
            }
        }

        setData(updatedState);
        setTempData(getFilteredData(locale));
        handleFormChange(updatedState);
    };

    const handleEnter = (value, type) => {
        const updatedState = [...data];

        updatedState.push({
            aka: value,
            locale,
            type,
        });

        setData(updatedState);
        setTempData(getFilteredData(locale));
        handleFormChange(updatedState);
    };

    const RenderAka = ({
        type = "aka",
        longLabel = "Also Known As • Akas",
        noBorder = false,
    }) => {
        const itemData = tempData
            .filter((item) => item.type === type && !item.deleted)
            .map((item) => {
                return {
                    id: item.id,
                    text: item.aka,
                    value: item.aka,
                };
            });

        const [isTyping, setIsTyping] = useState(false);

        return (
            <div
                className={`meta-akas${noBorder ? "" : " border"}${
                    isTyping ? " active" : ""
                }`}
            >
                <BubbleList
                    name="akas"
                    id="akas"
                    inputId="akas_inputId"
                    longLabel={longLabel}
                    padding="0.4375rem 0"
                    margin="0"
                    label={`${type.toUpperCase()}S`}
                    value=""
                    emptyPlaceholder={`Start Typing ${type.toUpperCase()} Title`}
                    data={itemData}
                    handleDelete={(item) => handleDelete(item, type)}
                    color={activeTextColor}
                    background={activeColor}
                    useEnterButtonOnSelect
                    readOnly={disabled}
                    noDelay
                    handleChange={(value) => setIsTyping(!!value)}
                    handleChangeText={(value) => handleEnter(value, type)}
                />

                {!disabled && isTyping && (
                    <p className="meta-akas--supporting-text">
                        Press enter once finished typing to add the{" "}
                        {type.toUpperCase()} Title
                    </p>
                )}
            </div>
        );
    };

    return (
        <div className="meta-akas--wrapper">
            {akasEnabled &&
                <RenderAka
                    noBorder={fakasEnabled}
                />
            }
            {fakasEnabled &&
                <RenderAka
                    type="fka"
                    longLabel="Formerly Known As • FKA"
                />
            }
        </div>
    );
};

export default Akas;
