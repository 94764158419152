import React, { Fragment } from "react";
import TagsTrack from "../../TagsTrack";
import DoubleTagSimple from "../../Tag/simpleDouble";
import TagSimple from "../../Tag/simple";

const LanguageOptions = (props) => {
    const { selectedFormat = {}, styles = {} } = props;

    const {
        fps,
        resolution = "-",
        alternate_languages = [],
        subtitles = [],
        primary_language,
    } = selectedFormat;

    const getLanguageName = (language) => {
        const { name, code } = language;

        let result = "-";
        if (code) result = code;
        if (name) result = name;

        return result;
    };

    const RenderTagSimple = (props) => (
        <TagSimple
            background={props.background}
            color={props.color || "#959595"}
            text={props.text}
            styles={{
                marginLeft: "0.3125rem",
            }}
        />
    );

    const RenderTitleTag = (props) => (
        <RenderTagSimple background="#d2d2d2" text={props.text} />
    );

    const RenderNoneTag = (props) => (
        <RenderTagSimple
            background="rgb(188, 188, 188)"
            text="None"
            color="rgb(121, 121, 121)"
        />
    );

    const RenderTagTrackLeft = () => (
        <Fragment>
            <DoubleTagSimple
                leftBackground="#626262"
                rightBackground="#188651"
                color="white"
                leftText={resolution}
                rightText={`${fps}FPS`}
            />
        </Fragment>
    );

    const RenderLanguageTags = (props) => {
        const { languages = [], background } = props;

        if (_.isEmpty(languages)) {
            return <RenderNoneTag />;
        }

        return (
            <Fragment>
                {languages.map((language, index) => (
                    <RenderTagSimple
                        background={background}
                        text={getLanguageName(language)}
                        key={index}
                    />
                ))}
            </Fragment>
        );
    };

    const RenderTagTrackRight = () => (
        <Fragment>
            <RenderTitleTag text="Primary Language" />
            <RenderTagSimple
                background="#8dd5fe"
                text={getLanguageName(primary_language)}
            />

            <RenderTitleTag text="Alternate Languages" />
            <RenderLanguageTags
                languages={alternate_languages}
                background="#7efbfc"
            />

            <RenderTitleTag text="Subtitles" />
            <RenderLanguageTags languages={subtitles} background="#fbf666" />
        </Fragment>
    );

    return (
        <TagsTrack
            leftContent={<RenderTagTrackLeft />}
            rightContent={<RenderTagTrackRight />}
            background="#a9a9a9"
            contentStyles={{
                height: "auto",
                padding: "0.3125rem",
            }}
            leftContentStyles={{
                alignItems: "flex-start",
            }}
            rightContentStyles={{
                justifyContent: "flex-end",
            }}
        />
    );
};

export default LanguageOptions;
