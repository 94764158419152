import { TaskManagerTab, TaskManagerTicketTypeSlug } from "./interfaces";
import { TicketPriority, TicketStatus, TicketType } from "./interfaces.shared";

export const TabAllTickets: TaskManagerTab = Object.freeze({
    label: "Tickets",
});

export const TabMyTickets: TaskManagerTab = Object.freeze({
    label: "My Tickets",
});

export const TabArchivedTickets: TaskManagerTab = Object.freeze({
    label: "Archive",
});

export const MESSAGE_TICKET_DELETED = "Ticket(s) have been deleted.";
export const MESSAGE_TICKET_PRIORITY_UPDATED = "Ticket(s) priority changed.";
export const MESSAGE_TICKET_STATUS_UPDATED = "Ticket(s) status changed.";
export const MESSAGE_TICKET_ASSIGNED = "Ticket(s) have been assigned.";

// =============================================
// !These cannot be changed by the user!
// =============================================

//Ticket Statuses
export const StatusDraft: TicketStatus = {
    label: "Draft in Progress",
    value: 1,
};

export const StatusAwaitingApproval: TicketStatus = {
    label: "Awaiting Approval",
    value: 6,
};

export const StatusApproved: TicketStatus = {
    label: "Approved",
    value: 4,
};

export const StatusRejected: TicketStatus = {
    label: "Rejected",
    value: 5,
};

export const StatusChangeInProgress: TicketStatus = {
    label: "Change in Progress",
    value: 7,
};

export const StatusCancelled: TicketStatus = {
    label: "Cancelled",
    value: 8,
};

export const StatusSubmissionInProgress: TicketStatus = {
    label: "Submission in Progress",
    value: 9,
};

export const StatusSubmitted: TicketStatus = {
    label: "Submitted",
    value: 10,
};

export const StatusSubmissionRejected: TicketStatus = {
    label: "Submission Rejected",
    value: 11,
};

export const TicketTypeStandalone: TicketType = {
    label: "Record Request - Standalone",
    value: 1,
};

export const TicketTypeTVShow: TicketType = {
    label: "Record Request - TV Show",
    value: 2,
};

export const TicketTypeUpdate: TicketType = {
    label: "Record Update Request",
    value: 3,
};

export const SupportedTicketTypes = [
    TicketTypeStandalone,
    TicketTypeTVShow,
    TicketTypeUpdate,
];

export const TicketTypeLabels = [
    TicketTypeStandalone.label,
    TicketTypeTVShow.label,
    TicketTypeUpdate.label,
];

export const TicketTypeSlugs: {
    [k: string]: TaskManagerTicketTypeSlug;
} = {
    [TicketTypeStandalone.label]: "standalone",
    [TicketTypeTVShow.label]: "tvShow",
    [TicketTypeUpdate.label]: "update",
};

export const TaskManagerRoutes = {
    TICKET_LIST: "/task-manager",
    NEW_STANDALONE: `/task-manager/new/${
        TicketTypeSlugs[TicketTypeStandalone.label]
    }`,
    NEW_TV_SHOW: `/task-manager/new/${TicketTypeSlugs[TicketTypeTVShow.label]}`,
    NEW_UPDATE: `/task-manager/new/${TicketTypeSlugs[TicketTypeUpdate.label]}`,
    TICKET_DETAILS: `/task-manager/ticket/:ticketId`,
};

export const PriorityHigh: TicketPriority = {
    label: "High",
    value: 4,
};
export const PriorityMedium: TicketPriority = {
    label: "Medium",
    value: 3,
};
export const PriorityLow: TicketPriority = {
    label: "Low",
    value: 2,
};
export const PriorityNoPriority: TicketPriority = {
    label: "No Priority",
    value: 1,
};

export const RED_COLOR = "#E92751";

export enum AccountStatus {
    VERIFIED = "verified",
    DISABLED = "disabled",
    DENIED = "denied",
    PENDING = "pending",
}
