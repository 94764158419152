import React from "react";
import CategoryTags from "../../CategoryTags";
import RenderNoEpisodesText from "./RenderNoEpisodesText";
import CircleInput from "../../CircleInput";
import _ from "lodash";

const RenderSelectAll = (props) => {
    const {
        handleSelectAllClick,
        selectedAll,
        category,
        copy = {},
        selectAllLabel,
        episodes,
        parent,
    } = props;

    let text = "Select Episodes";

    switch (category) {
        case CategoryTags.Storyline.short:
            text = "Select Storylines";
            break;

        case CategoryTags.Storyline.short:
            text = "Select Storylines";
            break;

        case CategoryTags.Special.short:
            text = "Select Specials";
            break;
    }

    if (selectAllLabel) {
        text = selectAllLabel;
    }

    return (
        <React.Fragment>
            <div className="meta-collection-titles--select-episodes">
                <span>{copy.selectEpisodes || `${text}`}</span>

                {!_.isEmpty(episodes) && (
                    <div>
                        <span>{copy.selectAll || "Select All"}</span>

                        <CircleInput
                            className="meta-collection-titles--placeholder meta-collection-titles--select-all"
                            background="#dfdfdf"
                            innerBackground={
                                selectedAll ? "#787878" : "#dfdfdf"
                            }
                            width="1.34375rem"
                            borderWidth="0.3rem"
                            onClick={handleSelectAllClick.bind(this)}
                        />
                    </div>
                )}
            </div>

            {_.isEmpty(episodes) && (
                <RenderNoEpisodesText category={category} parent={parent} />
            )}
        </React.Fragment>
    );
};

export default RenderSelectAll;
