import React, { useReducer, useContext, useEffect } from "react";
import reducer from "./reducer";
import { useStore as useGlobalStore } from "../../../Global/store/store";
import AssetsStateInterface from "./state.interface";
import { ASSET_CODES_CONFIG, ASSET_PLATFORMS_CONFIG, ASSET_TYPES_CONFIG } from "../configuration";
import AssetInterface from "../components/asset/interfaces/AssetInterface";
import setState from "./actions/state";
import EnrichAssetWithUUID from "../helpers/EnrichAssetWithUUID";
import { CmsInterface } from "../../../Global/interfaces";
import { userHasPermission } from "../../../../services/PermissionService";
import { accessTypeStrictDelete, accessTypeUpdate, accessTypeCreate } from "../../../../features/UserRoleManagement/utils/accessOptions";
import { relatedAssetsSection } from "../../../../features/UserRoleManagement/utils/sectionOptions/recordSections"; 
import _ from 'lodash'
import { ASSET_TYPES, ASSET_DESTINATION_PLATFORMS, ASSET_CODES } from '../../../../models/RecordBuilder'
import { envTest } from "../../../Environment";

type ProviderProps = {
    children: JSX.Element;
    assets: Array<AssetInterface>;
    handleFormChange: Function;
    disabled: boolean;
    category: string;
};

export const AssetBuilderContext = React.createContext();

export const useStore = () => useContext(AssetBuilderContext);

export const AssetBuilderContextProvider: React.FC<ProviderProps> = ({
    children,
    assets,
    handleFormChange,
    disabled,
    category,
}) => {
    let cmsData: CmsInterface = {
        clientDataStructure: {},
        apiUrls: {},
        user: {},
        clientFeatures: { 
            assets: {}
        },
    };

    let options = undefined;

    const [globalStore] = useGlobalStore() || [{}];

    const { recordBuilder = {}, recordBuilderError = false } = globalStore || {};

    if (envTest) {
        cmsData = globalStore.cmsData || {};
        options = globalStore.options || {};
    }

    if (!_.isEmpty(globalStore.cmsData)) {
        cmsData = globalStore.cmsData
    }

    const permissionCreate = userHasPermission({
        cmsData,
        userPermissions: globalStore.userPermissions,
        pathField: relatedAssetsSection.path,
        access: accessTypeCreate,
        prefix: category
    });

    const permissionEdit = userHasPermission({
        cmsData,
        userPermissions: globalStore.userPermissions,
        pathField: relatedAssetsSection.path,
        access: accessTypeUpdate,
        prefix: category
    });

    const permissionDelete = userHasPermission({
        cmsData,
        userPermissions: globalStore.userPermissions,
        pathField: relatedAssetsSection.path,
        access: accessTypeStrictDelete,
        prefix: category
    });

    const defaultState: AssetsStateInterface = {
        assets: EnrichAssetWithUUID(assets),
        handleFormChange,
        disabled,
        category,
        loadingBuilderConfig: true,
        notification: {},
        configuration: {
            [ASSET_PLATFORMS_CONFIG]: [],
            [ASSET_CODES_CONFIG]: [],
            [ASSET_TYPES_CONFIG]: [],
            isConfigurationInited: false,
        },
        cmsData: cmsData || {},
        options: options || {},
        permissionCreate,
        permissionEdit,
        permissionDelete,
		recordBuilderError,
        assetPlatformsBuilderConfig:
            recordBuilder[ASSET_DESTINATION_PLATFORMS] || [],
        assetCodesBuilderConfig:
            recordBuilder[ASSET_CODES] || [],
        assetTypesBuilderConfig:
            recordBuilder[ASSET_TYPES] || [],
    };

    const [state, dispatch] = useReducer(reducer, defaultState);

    useEffect(() => {
        dispatch(setState({ disabled, assets }));
    }, [assets, disabled]);

    useEffect(() => {
        if (state.assetPlatformsBuilderConfig && state.loadingBuilderConfig) {
            dispatch(setState({ loadingBuilderConfig: false }));
        } 
    }, []);

    return (
        <AssetBuilderContext.Provider value={[state, dispatch]}>
            {children}
        </AssetBuilderContext.Provider>
    );
};
