import _ from "lodash";
import React, { Component } from "react";
import Tag from "../Tag";
import RightTransparentLeftColor from "../DoubleTags/RightTransparentLeftColor";
import "./styles/_styles.scss";

export default class StatusTags extends Component {
    constructor(props) {
        super(props);
    }

    renderStatus(data, text, copy) {
        let value = "Empty";
        let active = false;
        let background = "rgb(85, 124, 111)";

        if (!_.isEmpty(data) && data.status) {
            const status = this.props?.statusOptions?.find(
                (option) => option.value === data.status,
            );

            if (status) {
                value = status.text;
            } else {
                value = data.status;
            }
        }

        if (data.status == "approved" || text) {
            value = copy.approved || "Approved";
            active = true;
        }

        if (data.status == "proposed") {
            value = copy.proposed || "Proposed";
            active = false;
            background = "#d2793e";
        }

        return (
            <Tag
                className="meta-tag meta-tag-status"
                text={value}
                active={active}
                disabled={false}
                background={background}
                styles={Object.assign(
                    {},
                    {
                        margin: "0 0.3125rem 0 0",
                        display: "inline-block",
                    },
                )}
            />
        );
    }

    isSourceApi(source) {
        let result = false;
        if (source && source !== "open") result = true;
        return result;
    }

    renderSource(data, copy) {
        let text = copy.open || "Open";
        let active = false;
        let disabled = true;
        let background = undefined;

        const isApi = this.isSourceApi(data.source);

        if (isApi) {
            text = "Api";
        }

        if (isApi && data.api_name) {
            return (
                <RightTransparentLeftColor
                    leftText={text}
                    rightText={data.api_name}
                    color="#818181"
                    leftColor="white"
                />
            );
        }

        return (
            <Tag
                className="meta-tag meta-tag-source"
                text={text}
                active={active}
                disabled={disabled}
                background={background}
                styles={Object.assign(
                    {},
                    {
                        display: "inline-block",
                        border: "0.065rem solid " + background,
                    },
                    typeof data.apiName == "string" &&
                        isApi && {
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                        },
                )}
            />
        );
    }

    render() {
        const data = this.props.data || {};
        const copy = this.props.copy || {};

        return (
            <div
                className="meta-status-tags"
                style={Object.assign(
                    {},
                    this.props.hidden && { visibility: "hidden" },
                    this.props.style && this.props.style,
                )}
            >
                {this.renderStatus(data, this.props.text, copy)}
                {this.renderSource(data, copy)}
            </div>
        );
    }
}
