import React, { useEffect, useState } from "react";
import { CoreSelectOptionType } from "../types/CoreSelectOptionType";

export const useAutocomplete = (
    options: CoreSelectOptionType[],
    inputSearchRef: HTMLInputElement | null,
    filterFn?: (value: string) => (itemData: CoreSelectOptionType) => boolean,
) => {
    const [searchedValue, setSearchedValue] = useState("");
    const [suggestions, setSuggestions] =
        useState<CoreSelectOptionType[]>(options); // TODO: check if options is empty
    const [selectedSuggestion, setSelectedSuggestion] = useState("");
    const [activeSuggestion, setActiveSuggestion] = useState(0);

    useEffect(() => {
        if (inputSearchRef) {
            inputSearchRef.focus();
        }
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.value !== "") {
            let filteringFn = filterFn;

            if (filteringFn === undefined) {
                filteringFn = (value) => (itemData) => {
                    const name = itemData.label.toLowerCase();

                    const words = value.split(" ");
                    const bool = words.every((el) => name.includes(el));

                    return value && bool;
                    // return value && name.includes(value);
                };
            }

            if (typeof filteringFn === "function") {
                const filteredSuggestions = options?.filter(
                    filteringFn(event.target.value.toLowerCase()),
                );

                setSearchedValue(event.target.value);
                setSuggestions(filteredSuggestions);
            }
        } else {
            setSearchedValue("");
            setSuggestions(options);
            setSelectedSuggestion("");
            setActiveSuggestion(0);
        }
    };

    const handleKeyDown = (
        event: React.KeyboardEvent<HTMLInputElement>,
    ): void => {
        if (
            event.key === "ArrowDown" &&
            activeSuggestion < suggestions.length
        ) {
            setActiveSuggestion(activeSuggestion + 1);
        } else if (event.key === "ArrowUp" && activeSuggestion > 1) {
            setActiveSuggestion(activeSuggestion - 1);
        } else if (event.key === "Enter") {
            setSearchedValue(suggestions[activeSuggestion - 1].label);
            setSelectedSuggestion(suggestions[activeSuggestion - 1].label);
            setSuggestions([]);
            setActiveSuggestion(0);
        }
    };

    const handleClick = (value: string) => {
        setSearchedValue("");
        setSuggestions(options);
        setActiveSuggestion(0);
        setSelectedSuggestion(value);
        // do something else
    };

    const handleReset = () => {
        setSearchedValue("");
        setSuggestions(options);
    };

    return {
        searchedValue,
        suggestions,
        selectedSuggestion,
        activeSuggestion,
        handleChange,
        handleKeyDown,
        handleClick,
        handleReset,
        options,
    };
};
