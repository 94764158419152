import React from "react";
import NumberFormat from "react-number-format";
import RightTransparentLeftColor from "../../DoubleTags/RightTransparentLeftColor";

const RenderDoubleTagAmount = (props) => {
    const { text, rightText, styles = {}, className = "" } = props;

    const RenderFormattedNumber = (props) => {
        if (!Number(rightText)) {
            return "-";
        }

        return (
            <div className={`meta-lineup--finance-month--tag--${className}`}>
                <NumberFormat
                    value={props.value}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={props.prefix ? "$" : ""}
                    decimalScale={2}
                />
            </div>
        );
    };

    return (
        <RightTransparentLeftColor
            leftText={text}
            rightText={
                <RenderFormattedNumber value={rightText} prefix={true} />
            }
            styles={styles}
        />
    );
};

export default RenderDoubleTagAmount;
