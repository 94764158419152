import {
    CreditInterface,
    CreditListInterface,
    Contributor
} from "../../credits.interface";
import OptionsSearchApi from "../../../../../components/Options/OptionsSearchApi"
import { assignCreditNames, assignCreditList, composeCredit } from "../../services/CreditService";
import { getCharacterNameScriptValue } from "../../services/FormService"

interface HandleContributorSearchProps {
    onUpdateList: (data: any) => void;
    searchTerm: string;
    optionUrl: string;
    apiUrls: any
}

export function handleContributorSearch(props: HandleContributorSearchProps): void {
    const {
        searchTerm,
        apiUrls,
        optionUrl,
        onUpdateList
    } = props

    // @TODO update this method to merge new contributor to this search array
    OptionsSearchApi({
        apiUrls,
        optionUrl,
        searchTerm,
        updateList: (data: any) => onUpdateList(data)
    })
}

interface SelectContributorProps {
    value: Contributor;
    defaultScript: string;
    creditList: CreditListInterface;
    setActiveCredit: (credit: CreditInterface) => void;
    setSearch: (value: boolean) => void;
    setFocusOn: (value: string) => void;
}

export function selectContributor(props: SelectContributorProps): void {
    const {
        value,
        defaultScript,
        creditList,
        setActiveCredit,
        setSearch,
        setFocusOn
    } = props

    let credit: CreditInterface = composeCredit(creditList, value, defaultScript)
    setActiveCredit(credit)
    setSearch(false)
    setFocusOn('role')
}

interface addNewContributorProps {
    value: string | CreditInterface;
    defaultScript: string;
    activeCredit: CreditInterface;
    creditList: CreditListInterface;
    setActiveCredit: (credit: CreditInterface) => void;
}

export function addNewContributor(props: addNewContributorProps): void {
    const {
        value,
        defaultScript,
        activeCredit,
        creditList,
        setActiveCredit
    } = props
    
    let credit: CreditInterface = assignCreditNames(activeCredit, value as Contributor)
    credit = assignCreditList(credit, creditList)
    credit.language = defaultScript
    setActiveCredit(credit)
}

interface RemoveSelectedContributorProps {
    setFocusOn: (value: string) => void;
    setSearch: (value: boolean) => void;
    setActiveCredit: (credit: {}) => void;
    resetRole: () => void
}

export function removeSelectedContributor(props: RemoveSelectedContributorProps): void {
    const {
        setFocusOn,
        setSearch,
        setActiveCredit,
        resetRole
    } = props

    setFocusOn("name")
    setSearch(true)
    setActiveCredit({})
    resetRole()
}

interface chracterNameScriptValueProps {
    credit: CreditInterface
    script: string
}

export const chracterNameScriptValue = (props: chracterNameScriptValueProps) => {
    const {
        credit,
        script
    } = props

    return getCharacterNameScriptValue({ credit, script })
}