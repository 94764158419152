import React from "react";
import _ from "lodash";
import Index from "./Index";
import styles from "../styles";
import Select from "../../Select";
import BubbleList from "../../BubbleList";
import Input from "../../Input/basic";
import TextArea from "../../Input/textarea";
import isIndexInArray from "../../Helpers/isIndexInArray";
import getYears from "../../Options/years";
import update from "react-addons-update";
import deepcopy from "deepcopy";
import { diff } from "deep-object-diff";
import { displaySectionFields } from "../actions";

export default class Properties extends Index {
    constructor(props) {
        super(props);

        const data = this.getDataFromProps(this.props.data);

        let sectionOptions = [];
        if (Array.isArray(this.props.sectionOptions)) {
            sectionOptions = this.props.sectionOptions;
        }

        /**
         * @var object
         */
        this.copy = this.props.copy || {};

        /**
         * @var object
         */
        this.styles = styles(this.props);

        /**
         * @var object
         */
        this.state = {
            data,
            sectionOptions,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        const nextPropsData = nextProps.data || [];
        const thisPropsData = this.props.data || [];
        const difference = diff(nextPropsData, thisPropsData);

        if (!_.isEmpty(difference)) {
            return true;
        }

        if (!_.isEqual(nextProps.disabled, this.props.disabled)) {
            return true;
        }

        if (!_.isEqual(nextProps.sectionOptions, this.props.sectionOptions)) {
            return true;
        }

        if (!_.isEqual(nextProps.validationKeys, this.props.validationKeys)) {
            return true;
        }

        if (!_.isEqual(nextProps.options, this.props.options)) {
            return true;
        }

        if (!_.isEqual(nextState, this.state)) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps, prevState) {
        // sectionOptions
        if (!_.isEqual(this.props.sectionOptions, prevProps.sectionOptions)) {
            if (Array.isArray(this.props.sectionOptions)) {
                this.setStateValue("sectionOptions", this.props.sectionOptions);
            }
        }

        // data
        if (!_.isEqual(this.props.data, prevProps.data)) {
            const data = this.getDataFromProps(this.props.data);
            this.setStateValue("data", this.props.data);
        }
    }

    getDataFromProps(data = {}) {
        let result = {};

        if (typeof data == "object") {
            if (data.regional_owner) {
                result.regional_owner = data.regional_owner;
            }

            if (data.format) {
                result.format = data.format;
            }

            if (data.control_mechanism) {
                result.control_mechanism = data.control_mechanism;
            }

            if (data.first_release_year) {
                result.first_release_year = data.first_release_year;
            }

            if (data.loading_menu_audio) {
                result.loading_menu_audio = data.loading_menu_audio;
            }

            if (data.orientation) {
                result.orientation = data.orientation;
            }

            if (data.horizontal_resolution) {
                result.horizontal_resolution = data.horizontal_resolution;
            }

            if (data.vertical_resolution) {
                result.vertical_resolution = data.vertical_resolution;
            }

            if (data.build_size) {
                result.build_size = data.build_size;
            }

            if (data.device) {
                result.device = data.device;
            }

            if (data.play_notes) {
                result.play_notes = data.play_notes;
            }
        }

        return result;
    }

    handleChange(name, value) {
        const data = this.getDataFromProps(this.state.data);
        let stateData = deepcopy(data || {});
        let result = { ...stateData };

        if (typeof name == "string") {
            result[name] = value;

            if (typeof this.props.handleFormChange == "function") {
                this.props.handleFormChange(result);
            }

            const newData = update(this.state.data, { $set: result });

            this.setStateValue("data", result);
        }
    }

    renderNoneAdded() {
        return false;
    }

    renderSelects() {
        const { sectionOptions, data } = this.state;
        const noneSelectedText = this.copy.noneSelected || "None Selected";
        const options = this.props.options || {};
        const regional_owners = options.regional_owners || [];
        const formats = options.formats || [];
        const control_mechanisms = options.control_mechanisms || [];
        const loading_menu_audios = options.loading_menu_audio || [];
        const orientations = options.orientations || [];
        const devices = options.devices || [];

        return (
            <div style={this.styles.flexMarginContainer}>
                {displaySectionFields(sectionOptions, "RegionalOwner") && (
                    <Select
                        name="RegionalOwner"
                        id="RegionalOwner"
                        value={data.regional_owner}
                        clear={true}
                        label={this.copy.releaseYear || "Regional Owner"}
                        disabledText={this.copy.selectOwner || "Select Owner"}
                        noneSelectedText={noneSelectedText}
                        readOnly={this.props.disabled}
                        disabled={this.props.disabled}
                        data={regional_owners}
                        wrapperStyles={this.styles.flexMarginItem}
                        error={isIndexInArray(
                            this.props.validationKeys,
                            "regional_owner",
                        )}
                        handleChange={(item) =>
                            this.handleChange("regional_owner", item)
                        }
                    />
                )}

                {displaySectionFields(sectionOptions, "Format") && (
                    <Select
                        name="Format"
                        id="Format"
                        value={data.format}
                        clear={true}
                        label={this.copy.format || "Format"}
                        disabledText={this.copy.selectFormat || "Select Format"}
                        noneSelectedText={noneSelectedText}
                        readOnly={this.props.disabled}
                        disabled={this.props.disabled}
                        data={formats}
                        wrapperStyles={this.styles.flexMarginItem}
                        error={isIndexInArray(
                            this.props.validationKeys,
                            "format",
                        )}
                        handleChange={(item) =>
                            this.handleChange("format", item)
                        }
                    />
                )}

                {displaySectionFields(sectionOptions, "ControlMechanism") && (
                    <Select
                        name="ControlMechanism"
                        id="ControlMechanism"
                        value={data.control_mechanism}
                        clear={true}
                        label={
                            this.copy.controlMechanism || "Control Mechanism"
                        }
                        disabledText={
                            this.copy.selectMechanism || "Select Mechanism"
                        }
                        noneSelectedText={noneSelectedText}
                        readOnly={this.props.disabled}
                        disabled={this.props.disabled}
                        data={control_mechanisms}
                        wrapperStyles={this.styles.flexMarginItem}
                        error={isIndexInArray(
                            this.props.validationKeys,
                            "control_mechanism",
                        )}
                        handleChange={(item) =>
                            this.handleChange("control_mechanism", item)
                        }
                    />
                )}

                {displaySectionFields(sectionOptions, "FirstReleaseYear") && (
                    <Select
                        name="FirstReleaseYear"
                        id="FirstReleaseYear"
                        value={data.first_release_year}
                        clear={true}
                        label={
                            this.copy.firstReleaseYear || "First Release Year"
                        }
                        disabledText={this.copy.selectYear || "Select Year"}
                        noneSelectedText={noneSelectedText}
                        readOnly={this.props.disabled}
                        disabled={this.props.disabled}
                        data={getYears(this.props.year)}
                        wrapperStyles={this.styles.flexMarginItem}
                        error={isIndexInArray(
                            this.props.validationKeys,
                            "first_release_year",
                        )}
                        handleChange={(item) =>
                            this.handleChange("first_release_year", item)
                        }
                    />
                )}

                {displaySectionFields(sectionOptions, "LoadingMenuAudio") && (
                    <Select
                        name="LoadingMenuAudio"
                        id="LoadingMenuAudio"
                        value={data.loading_menu_audio}
                        clear={true}
                        label={
                            this.copy.loadingMenuAudio || "Loading / Menu Audio"
                        }
                        disabledText={this.copy.selectAudio || "Select Audio"}
                        noneSelectedText={noneSelectedText}
                        readOnly={this.props.disabled}
                        disabled={this.props.disabled}
                        data={loading_menu_audios}
                        wrapperStyles={this.styles.flexMarginItem}
                        error={isIndexInArray(
                            this.props.validationKeys,
                            "loading_menu_audio",
                        )}
                        handleChange={(item) =>
                            this.handleChange("loading_menu_audio", item)
                        }
                    />
                )}

                {displaySectionFields(sectionOptions, "Orientation") && (
                    <Select
                        name="Orientation"
                        id="Orientation"
                        value={data.orientation}
                        clear={true}
                        label={this.copy.orientation || "Orientation"}
                        disabledText={
                            this.copy.selectOrientation || "Select Orientation"
                        }
                        noneSelectedText={noneSelectedText}
                        readOnly={this.props.disabled}
                        disabled={this.props.disabled}
                        data={orientations}
                        wrapperStyles={this.styles.flexMarginItem}
                        error={isIndexInArray(
                            this.props.validationKeys,
                            "orientation",
                        )}
                        handleChange={(item) =>
                            this.handleChange("orientation", item)
                        }
                    />
                )}
            </div>
        );
    }

    renderInputs() {
        const { sectionOptions, data } = this.state;

        return (
            <div style={this.styles.flexMarginContainer}>
                {displaySectionFields(
                    sectionOptions,
                    "HorizontalResolution",
                ) && (
                    <div style={this.styles.flexMarginItem}>
                        <Input
                            label={
                                this.copy.horizontalResolution ||
                                "Horizonal Resolution (Pixels)"
                            }
                            placeholder={this.copy.pixels || "Pixels"}
                            id="HorizontalResolution"
                            name="HorizontalResolution"
                            value={data.horizontal_resolution}
                            disabled={this.props.disabled}
                            margin="0"
                            width="100%"
                            recordBasic={true}
                            handleChange={(item) =>
                                this.handleChange("horizontal_resolution", item)
                            }
                        />
                    </div>
                )}

                {displaySectionFields(sectionOptions, "VerticalResolution") && (
                    <div style={this.styles.flexMarginItem}>
                        <Input
                            label={
                                this.copy.verticalResolution ||
                                "Vertical Resolution (Pixels)"
                            }
                            placeholder={this.copy.pixels || "Pixels"}
                            id="VerticalResolution"
                            name="VerticalResolution"
                            value={data.vertical_resolution}
                            disabled={this.props.disabled}
                            margin="0"
                            width="100%"
                            recordBasic={true}
                            handleChange={(item) =>
                                this.handleChange("vertical_resolution", item)
                            }
                        />
                    </div>
                )}

                {displaySectionFields(sectionOptions, "BuildSize") && (
                    <div style={this.styles.flexMarginItem}>
                        <Input
                            type="number"
                            label={this.copy.buildSize || "Build Size (Mb)"}
                            placeholder={this.copy.megabytes || "Megabytes"}
                            id="BuildSize"
                            name="BuildSize"
                            value={data.build_size}
                            disabled={this.props.disabled}
                            margin="0"
                            width="100%"
                            recordBasic={true}
                            handleChange={(item) =>
                                this.handleChange("build_size", item)
                            }
                        />
                    </div>
                )}
            </div>
        );
    }

    renderPlayNotesWithDevices() {
        const { sectionOptions, data } = this.state;
        const options = this.props.options || {};
        const devices = options.devices || [];
        const noneSelectedText = this.copy.noneSelected || "None Selected";

        if (
            displaySectionFields(sectionOptions, "PlayNotes") ||
            displaySectionFields(sectionOptions, "Devices")
        ) {
            return (
                <div style={this.styles.flexMarginContainer}>
                    {displaySectionFields(sectionOptions, "Devices") && (
                        <Select
                            name="Device"
                            id="Device"
                            value={data.device}
                            clear={true}
                            label={this.copy.devices || "Devices"}
                            disabledText={
                                this.copy.selectDevices || "Select Devices"
                            }
                            noneSelectedText={noneSelectedText}
                            readOnly={this.props.disabled}
                            disabled={this.props.disabled}
                            data={devices}
                            wrapperStyles={this.styles.marginItemOneThird}
                            handleChange={(item) =>
                                this.handleChange("device", item)
                            }
                        />
                    )}

                    {displaySectionFields(sectionOptions, "PlayNotes") && (
                        <div style={this.styles.marginItemTwoThirds}>
                            <TextArea
                                label={this.copy.playNotes || "Play Notes"}
                                placeholder={this.copy.notes || "Notes"}
                                id="PlayNotes"
                                name="PlayNotes"
                                value={data.play_notes}
                                disabled={this.props.disabled}
                                maxCharacter={180}
                                counter={true}
                                textArea={true}
                                recordBasic={true}
                                borderHiddenPadding={true}
                                error={isIndexInArray(
                                    this.props.validationKeys,
                                    "PlayNotes",
                                )}
                                handleChange={(item) =>
                                    this.handleChange("play_notes", item)
                                }
                            />
                        </div>
                    )}
                </div>
            );
        }

        return false;
    }

    renderContent() {
        return (
            <React.Fragment>
                {this.renderSelects()}
                {this.renderInputs()}
                {this.renderPlayNotesWithDevices()}
            </React.Fragment>
        );
    }
}
