import DefaultClientPermissions from "../DefaultClientPermissions";
import _ from "lodash";

export default function ClientPermissions(roles) {
    const {
        Role_RegionalEditor,
        Role_PlatformAdmin,
        Role_SuperAdmin,
        Role_Admin,
    } = roles;

    let permissions = DefaultClientPermissions(roles);

    permissions.dataManager.view = _.concat(permissions.dataManager.view, [
        Role_PlatformAdmin,
    ]);

    permissions.dataManager.edit = _.concat(permissions.dataManager.edit, [
        Role_PlatformAdmin,
    ]);

    return permissions;
}
