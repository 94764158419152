interface Props {
    item: {
        source: string;
        type: string;
        api_name: string;
    };
    synopsisTransformer: {
        source: string;
        api_name: string;
        type: any[];
    }[];
}

export const transformType = (props: Props): string => {
    const { item, synopsisTransformer } = props;

    const { source = "", type = "", api_name = "" } = item;

    let validSynopsis = synopsisTransformer.find(
        (t) => t.source === source && t.api_name === api_name,
    );

    if (!validSynopsis) {
        return type;
    }

    const validType = validSynopsis.type.find((t) => t.text === type);

    return validType ? validType.value : type;
};
