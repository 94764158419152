import _ from "lodash";
import clientManagedController from "./clientManagedController";
import {
    StringPriorityTransformer,
    PRIORITY_KEYS_NAME_OR_TEXT,
} from "../Transformers/StringPriorityTransformer";

export function isClientManagedReleaseDateTypes(props) {
    const { clientFeatures = {} } = props;

    const { managedLists = [] } = clientFeatures;

    return managedLists.includes("release_date_types");
}

const ReleaseDateTypesOptions = (props) => {
    const { options = {} } = props;

    let result = clientManagedController({
        clientManagedOptions: [],
        fallback: _.get(options, "release_dates_types", []),
        isClientManaged: isClientManagedReleaseDateTypes(props),
    });

    return result.map((item) =>
        StringPriorityTransformer({
            item,
            priority: PRIORITY_KEYS_NAME_OR_TEXT,
        }),
    );
};

export default ReleaseDateTypesOptions;
