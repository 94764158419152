import GetApiUrls from "./GetApiUrls";
import SendRequest from "../../components/SendRequest";
import HandleRequestError from "./HandleRequestError";

const HandleFetchLinkagesRequest = (props) => {
    const {
        state = {},
        meta_id,
        handleSuccess = () => {},
        handleError = () => {},
    } = props;

    const { getRecordLinkage = "" } = GetApiUrls(state);

    const options = {
        url: getRecordLinkage.replace("{meta_id}", meta_id),
        method: "GET",
    };

    const errorHandle = (error) => {
        HandleRequestError({
            state,
            error,
            description: "Failed to fetch record meta data",
        });

        handleError();
    };

    try {
        SendRequest(
            options,
            (response) => {
                const { data, message } = response;

                if (data) {
                    handleSuccess(data);

                    return;
                }

                errorHandle(message);
            },
            (error) => {
                errorHandle(error);
            },
        );
    } catch (e) {
        errorHandle(e);
    }
};

export default HandleFetchLinkagesRequest;
