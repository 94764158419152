import React, { useEffect, useState } from "react";
import AgeGuidanceInterface from "../interfaces/RegionalAgeGuidanceRow.interface";
import ExpandableRow from "../../ExpandableRow/v2";
import DoubleColumnContent from "../../Boilerplates/DoubleColumnContent/DoubleColumnContent";
import ContentFieldInterface from "../../Boilerplates/DoubleColumnContent/ContentField.interface";
import { useStore } from "../store/context";
import DoubleRightArrowTag from "../../Tag/DoubleRightArrow";
import Actions from "./Actions";
import { editRegionalRow, editClassification } from "../store/reducer";
import Configuration, {
    AgeGuidanceConfig,
    ORIGINAL_AGE_GUIDANCE,
    REGIONAL_AGE_GUIDANCE,
} from "../configuration";
import ClientManagedTerritories from "../../ClientManagedOptions/territories";
import TransformTerritoriesList from "./transformers/TransformTerritoriesList";
import EditRegionalRatingHelper from "./helpers/EditRegionalRatingHelper";
import _ from "lodash";
import deepcopy from "deepcopy";

type Props = {
    index: number;
    ageGuidance: AgeGuidanceInterface;
    type: string;
};

const AgeGuidanceRow: React.FC<Props> = (props): JSX.Element => {
    const [store, dispatch] = useStore();

    const { disabled, options, cmsData, age_guidance } = store;

    const { ageGuidance: rowData, index, type } = props;

    const [rating, setRating] = useState(rowData);

    useEffect(() => {
        setRating(rowData);
    }, [rowData]);

    const { clientFeatures } = cmsData;

    const handleRatingChange = (field: string, value: any): void => {
        let ratingEdited = deepcopy(rating);
        let isReadyToSave = true;

        ratingEdited[field] = value;

        const fieldConfig = Configuration[type].find(
            (fieldConfig: AgeGuidanceConfig) => fieldConfig.key == field,
        );

        if (_.isEmpty(value)) {
            ratingEdited[field] = fieldConfig.defaultValue;
        }

        if (fieldConfig && fieldConfig.type == "selectmultiple") {
            ratingEdited[field] = value.map((item: any) => item.text);
        }

        Configuration[type].forEach((fieldConfig: AgeGuidanceConfig) => {
            if (fieldConfig.required && !ratingEdited[fieldConfig.key]) {
                isReadyToSave = false;
            }

            if (
                fieldConfig.required &&
                fieldConfig.type == "selectmultiple" &&
                _.isEmpty(ratingEdited[fieldConfig.key])
            ) {
                isReadyToSave = false;
            }
        });

        setRating(ratingEdited);

        if (isReadyToSave) {
            type == REGIONAL_AGE_GUIDANCE
                ? dispatch(
                      editRegionalRow(
                          EditRegionalRatingHelper(
                              ratingEdited,
                              rowData,
                              age_guidance,
                          ),
                      ),
                  )
                : dispatch(editClassification(ratingEdited));
        }
    };

    const fields: Array<ContentFieldInterface> = Configuration[type].map(
        (fieldConfig: AgeGuidanceConfig) => {
            fieldConfig.value = _.get(rating, fieldConfig.key, null);

            if (
                _.has(fieldConfig, "list") &&
                fieldConfig.key == "territories"
            ) {
                fieldConfig.list = ClientManagedTerritories({
                    options,
                    clientFeatures,
                    fallback: [],
                });

                fieldConfig.list = TransformTerritoriesList(
                    fieldConfig.list,
                    age_guidance,
                    rating,
                );
            }

            return fieldConfig;
        },
    );

    const RenderHeader = () => {
        return (
            <div className="age-guidance-row-header">
                <div className="first-half">
                    <DoubleRightArrowTag
                        firstText={rowData.minimum_age}
                        secondText={rowData.maximum_age}
                    />
                    <span>
                        {rating.territories && rating.territories.join(", ")}
                    </span>
                </div>
                <div className="second-half">
                    <Actions index={index} ageGuidance={rowData} type={type} />
                </div>
            </div>
        );
    };

    return (
        <div className="age-guidance-row">
            <ExpandableRow
                header={<RenderHeader />}
                body={
                    <DoubleColumnContent
                        fields={fields}
                        disabled={disabled}
                        handleChange={(field: string, value: any) => {
                            handleRatingChange(field, value);
                        }}
                    />
                }
                borderRadius={true}
                className="age-guidance-expandale-row"
                headerClassName="age-guidance-expandale-row-header"
                defaultOpen={false}
                arrowIconType="large"
            />
        </div>
    );
};

export default AgeGuidanceRow;
