import { LanguagesInterface, LanguageInterface } from "./languagesInterface"
import _ from 'lodash'
import {
    CLIENT_MANAGED_LANGUAGES
} from "../../components/ClientManagedOptions/options";
import { GetFromLocalStorage } from "../../components/Storage/localStorage";
import { CoreSelectOptionType } from "../../core/Form/components/inputs/Select/types/CoreSelectOptionType";
import RenderChip from "../../core/Form/components/inputs/Select/utils/renderChip";

const defaultlanguageCode: string = "ENG"

export function getLanguageCode(language: LanguageInterface | {}): string {
    return language?.meta_code || ''
}

export function getDefaultLanguage(languages: LanguageInterface[]): LanguageInterface | {} {
    if (_.isEmpty(languages)) {
        return {}
    }

    return _.find(languages, function(it) { return it.default === true }) || {}
}

export function getDefaultWrittenLanguageCodeFromCache(): string {
    const localString: string = GetFromLocalStorage(CLIENT_MANAGED_LANGUAGES);
    const localLanguages: LanguagesInterface = JSON.parse(localString) || {
        all_spoken_languages: [],
        all_written_languages: []
    }

    if (_.isEmpty(localLanguages.all_written_languages)) {
        return defaultlanguageCode
    }

    const allWrittenLanguages = localLanguages.all_written_languages || []
    const defaultLanguage = getDefaultLanguage(allWrittenLanguages)

    return getLanguageCode(defaultLanguage) || defaultlanguageCode
}

export function getFallBackWrittenLanguagesFromOptions(options: any): LanguageInterface[] {
    let result = []

    if (!_.isEmpty(options.all_written_languages) && _.isObject(options.all_written_languages)) {
        Object
            .keys(options.all_written_languages)
            .forEach(key => {

            result = _.concat(result, options.all_written_languages[key])
        })
    }

    return result
}

export function languagesTransformer(items: LanguageInterface[], keys: string[] = null): CoreSelectOptionType[] {
    return items?.map((item: LanguageInterface) => {
        const value: string = item?.value?.toString()

        let result = {
            label: item.text,
            value,
        }

        if (Array.isArray(keys)) {
            result.rightAdornment = RenderChip(value, keys?.includes(value))
        }

        return result
    })
}

export function rightAdornmentTransformer(items: CoreSelectOptionType[], keys: string[] = null): CoreSelectOptionType[] {
    return items?.map((item: CoreSelectOptionType) => {
        return {
            ...item,
            rightAdornment: RenderChip(item.value, keys?.includes(item.value))
        }
    })
}