import React from "react";
import ReactTooltip from "react-tooltip";
import styles from "./styles";
import Settings from "../Settings";

export default class Switch extends React.Component {
    constructor(props) {
        super(props);

        /**
         * @var object
         */
        this.state = {
            checked: this.props.checked || false,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.checked !== prevProps.checked) {
            this.setState({
                checked: this.props.checked,
            });
        }
    }

    handleChange(e) {
        if (e) {
            if (e.target) {
                const { amber, tooltip, handleChange } = this.props;

                let checked = e.target.checked;
                let name = e.target.name;

                let data = {
                    name,
                    checked,
                };

                if (amber) {
                    data.amber = true;
                }

                if (tooltip) {
                    data.tooltip = tooltip;
                }

                this.setState({
                    checked,
                });

                if (typeof handleChange == "function") {
                    handleChange(data);
                }
            }
        }
    }

    render() {
        const {
            name,
            sliderClass,
            disabled,
            disabledNoneOpacity,
            amber,
            tooltip,
            inputClassName,
            handleOnClick = () => {},
        } = this.props;

        if (name) {
            let className = "react--switch";

            if (disabled) {
                className = "react--switch react--switch-disabled";

                if (disabledNoneOpacity) {
                    className = "react--switch";
                }
            }

            return (
                <label
                    style={styles(this.props).checkbox}
                    className={className}
                >
                    {typeof tooltip == "string" && (
                        <ReactTooltip
                            delayShow={0}
                            effect={Settings.tooltip.effect}
                            place="right"
                        />
                    )}

                    <input
                        type="checkbox"
                        name={name}
                        className={`react--checkbox-switch ${inputClassName}`}
                        checked={this.state.checked || false}
                        onChange={this.handleChange.bind(this)}
                        disabled={disabled}
                    />

                    <span
                        className={`
                            react--slider 
                            ${sliderClass}
                            ${amber ? "react-slider--amber" : ""}
                        `}
                        data-tip={tooltip || ""}
                        data-type="success"
                        onClick={() => handleOnClick()}
                    ></span>
                </label>
            );
        }

        return false;
    }
}
