import React from 'react'
import { makeObservable, action, extendObservable } from 'mobx'
import { isEmpty } from "lodash"
import { RootStore } from '../rootStore'
import { createStoreInitialState } from "../state"
import { CreditListStore } from "./creditListStore"
import { configurationCreate } from "../../config/creditListForm.config"
import { CreditListInterface } from "../interfaces"

export class CreditListCreateStore extends CreditListStore {
    constructor(rootStore: RootStore) {
        super(rootStore)

        makeObservable(this, {
            handleSetConfiguration: action,
            handleSubmit: action
        });

        extendObservable(this, createStoreInitialState);
    }

    initialize() {
        this.reset()
        this.handleSetConfiguration()
        this.handleSetCoreFormFields()
    }

    handleSetConfiguration(): void {
        const updatedList = this.rootStore.creditListsStore.getFilteredCreditLists()?.map((creditList: CreditListInterface) => ({
            value: creditList.code,
            label: creditList.name
        }));

        const updatedFields = configurationCreate.fields.map((field: any) => {
            if (field.key !== "clone_from") return field;

            const { options: { editable: { optionsManagedList } } } = field;

            return {
                ...field,
                options: {
                    ...field.options,
                    editable: {
                        ...field.options.editable,
                        optionsManagedList: {
                            ...optionsManagedList,
                            list: updatedList
                        }
                    }
                }
            }
        });

        this.setConfiguration({
            ...configurationCreate, 
            fields: updatedFields 
        })
    }

    handleSubmit(): void {
        this.rootStore.creditListValidationStore.handleValidation(this.configuration, this.creditList, this.creditListOriginal)

        if (isEmpty(this.rootStore.creditListValidationStore.errorAlerts) && isEmpty(this.rootStore.creditListValidationStore.errors)) {
            const credits: CreditListInterface[] = this.rootStore.creditListsStore.creditLists?.find((creditList: CreditListInterface) => creditList.code === this.creditList.clone_from)?.credits || []

            const newCreditList = {
                ...this.creditList,
                credits
            }

            this.rootStore.creditListsStore.handleAddCreditList(newCreditList)
            this.rootStore.creditListsStore.setSelectedCreditList(newCreditList)
            
            this.rootStore.setShowCreditListCreateForm(false)
        }
    }
}
