import { SET_INITIAL_STATE } from "../actions/action_types";
import initialState from "../initialState";

export default function (state = {}, action) {
    switch (action.type) {
        case SET_INITIAL_STATE:
            return Object.assign({}, state, initialState);
    }

    return state;
}
