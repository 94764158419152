import React from "react";
import OriginationClass from "./Origination";
import { isCollection } from "../../CategoryTags/CollectionCategories";
import _ from "lodash";

export default class OriginationV2 extends OriginationClass {
    isInDataStructure(key) {
        const { dataStructure = [] } = this.props;

        return dataStructure.find((item) => item.key == key);
    }

    shouldRenderRecordType() {
        return (
            this.isInDataStructure("record_type") &&
            this.showField("record_type")
        );
    }

    shouldRenderCountryOfOrigin() {
        return (
            this.isInDataStructure("country_of_origin") &&
            this.showField("country_of_origin")
        );
    }

    shouldRenderPrimaryLanguage() {
        return (
            this.isInDataStructure("primary_language") &&
            this.showField("primary_language")
        );
    }

    shouldRenderFirstReleaseYear() {
        return (
            this.isInDataStructure("release_year") &&
            this.showField("release_year")
        );
    }

    shouldRenderReleaseDuration() {
        return (
            this.isInDataStructure("release_duration") &&
            this.showField("release_duration")
        );
    }

    shouldRenderSequential() {
        return (
            this.isInDataStructure("sequential") && this.showField("sequential")
        );
    }

    shouldRenderBrand() {
        return this.isInDataStructure("brand") && this.showField("brand");
    }

    shouldRenderAdditionalLanguages() {
        return (
            this.isInDataStructure("additional_languages") &&
            this.showField("additional_languages")
        );
    }

    shouldRenderProducers() {
        return (
            this.isInDataStructure("producers") && this.showField("producers")
        );
    }

    shouldRenderDistributor() {
        return (
            this.isInDataStructure("distributor") &&
            this.showField("distributor")
        );
    }

    shouldRenderFirstReleaseDate() {
        return (
            this.isInDataStructure("first_release_date") &&
            this.showField("first_release_date")
        );
    }

    shouldRenderLastReleaseYear() {
        return (
            this.isInDataStructure("last_release_year") &&
            this.showField("last_release_year")
        );
    }

    shouldRenderProductionYear() {
        return (
            this.isInDataStructure("production_year") &&
            this.showField("production_year")
        );
    }

    shouldRenderAdditionalCountriesOfOrigin() {
        return (
            this.isInDataStructure("additional_countries_of_origin") &&
            this.showField("additional_countries_of_origin")
        );
    }

    shouldRenderReleaseType() {
        return (
            this.isInDataStructure("release_type") &&
            this.showField("release_type")
        );
    }

    shouldRenderProductionCountry() {
        return (
            this.isInDataStructure("production_country") &&
            this.showField("production_country")
        );
    }

    shouldRenderOriginalAspectRatio() {
        return (
            this.isInDataStructure("original_aspect_ratio") &&
            this.showField("original_aspect_ratio")
        );
    }

    shouldRenderProductionStartYear() {
        return (
            this.isInDataStructure("production_start_year") &&
            this.showField("production_start_year")
        );
    }

    shouldRenderParentSeries() {
        return (
            this.isInDataStructure("parent_series") &&
            this.showField("parent_series")
        );
    }

    shouldRenderProvenance() {
        return (
            this.isInDataStructure("provenance") && this.showField("provenance")
        );
    }

    shouldRenderBrandHub() {
        return (
            this.isInDataStructure("brand_hub") && this.showField("brand_hub")
        );
    }

    shouldRenderProductionQuotas() {
        return (
            this.isInDataStructure("production_quotas") &&
            this.showField("production_quotas")
        );
    }

    showField(field) {
        let result = true;
        const dataStructureItem = this.isInDataStructure(field) || {};

        const { category = false } = this.props;

        const { categories = [], excludedCategories = [] } = dataStructureItem;

        if (category) {
            if (isCollection(category)) {
                return dataStructureItem.collection;
            }

            if (
                !_.isEmpty(excludedCategories) &&
                excludedCategories.includes(category)
            ) {
                result = false;
            }

            if (!_.isEmpty(categories) && !categories.includes(category)) {
                result = false;
            }
        }

        return result;
    }
}
