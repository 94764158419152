import CategoryTags from "../../../components/CategoryTags";

import {
    additionalInformationSection,
    airingOrderOption,
    akasSection,
    assetsOption,
    awardsOptions,
    canvasAIOption,
    creditsOption,
    catalogAvailabilityOption,
    certificationsSection,
    channelsOption,
    charactersOption,
    classificationSection,
    collectionTitlesSection,
    cyclesOption,
    descriptionsSection,
    descriptorsSection,
    episodeNumberingSection,
    financialsOption,
    gamesOption,
    gracenoteOption,
    idsOption,
    imagesOption,
    lineupsOption,
    linkageOption,
    linksSection,
    measurementsOption,
    metadataOption,
    originationCustomFieldsSection,
    originationSection,
    programmingOption,
    propertiesSection,
    ratingsOption,
    ratingsSection,
    regionalOptions,
    regionalSupplyChainSection,
    rekognitionOption,
    releaseDatesSection,
    rightsOption,
    runningOrderOption,
    schedulesOption,
    seasonNumberingSection,
    shortNumberingSection,
    showsOption,
    storylineNumberingSection,
    titlesSection,
    videosOption,
    ancillariesOption,
    digitalReadinessStatusSection,
    alternativeNumberingSection,
} from "./sectionOptions/recordSections";

import { reportingOption } from "./sectionOptions/reporting";

import { localisationTabOption } from "./sectionOptions/localisations";

import { groupSettingsOption } from "./sectionOptions/userGroups";

import {
    userRegistrationsAndPermissionsOption,
    imageUploaderOption,
    dataIngestOption,
    dataManagerOption,
    catalogsOption,
    contributorsOption,
} from "./sectionOptions/applicationSettings";

import { recordBuilderOption } from "./sectionOptions/recordBuilder";

import {
    securityLevelOption,
    recordStatusOption,
    graceNoteSyndicationOption,
    catalogOption,
} from "./sectionOptions/recordProperties";

import {
    userPolicyManagementOption,
    userRoleManagementOption,
} from "./sectionOptions/userRolesAndPolicies";

import {
    createRecordsRequestStatusApprovedOption,
    createRecordsRequestStatusAwatingApprovalOption,
    createRecordsRequestStatusCancelledOption,
    createRecordsRequestStatusChangeInProgressOption,
    createRecordsRequestStatusDraftOption,
    createRecordsRequestStatusRejectedOption,
    createRecordsRequestStatusSubmissionInProgressOption,
    createRecordsRequestStatusSubmittedOption,
    createRecordsRequestStatusSubmissionRejectedOption,
    taskManagerArchiveOptions,
    taskManagerMyTicketsOptions,
    taskManagerTicketsOptions,
    otherApprovalsStandaloneOption,
    otherApprovalsTvShowOption,
    otherApprovalsUpdateOption,
    ownApprovalsStandaloneOption,
    ownApprovalsTvShowOption,
    ownApprovalsUpdateOption,
    updateRecordsRequestStatusApprovedOption,
    updateRecordsRequestStatusAwaitingApprovalOption,
    updateRecordsRequestStatusCancelledOption,
    updateRecordsRequestStatusChangeInProgressOption,
    updateRecordsRequestStatusDraftOption,
    updateRecordsRequestStatusRejectedOption,
    updateRecordsRequestStatusSubmissionInProgressOption,
    updateRecordsRequestStatusSubmittedOption,
    updateRecordsRequestStatusSubmissionRejectedOption,
    ticketTypeStandaloneOption,
    ticketTypeTvShowOption,
    ticketTypeUpdateOption,
    updateRecordsRequestInternalCommentsOption,
    createRecordsRequestInternalCommentsOption,
    updateRecordsRequestExternalCommentsOption,
    createRecordsRequestExternalCommentsOption
} from "./sectionOptions/taskManager";

import {
    policyTypeCollections,
    policyTypeExports,
    policyTypeFranchiseCollections,
    policyTypeLocalisations,
    policyTypePlatformManagement,
    policyTypeSwimlanes,
    policyTypeUserGroups,
    policyTypeUserRolesAndPolicies,
} from "./accessOptions";

import { BuildStructureInterface, OptionInterface } from "../interfaces";

function buildStructure({
    sections = [],
    prefix,
}: BuildStructureInterface): Array<OptionInterface> {
    if (!Array.isArray(sections)) {
        return [];
    }

    return sections.map((item: OptionInterface) => {
        const { children: itemChildren = [] } = item;

        let children = itemChildren.map((child) => {
            return {
                ...child,
                path: prefix ? `${prefix}.${child.path}` : child.path,
            };
        });

        return {
            ...item,
            path: prefix ? `${prefix}.${item.path}` : item.path,
            children,
        };
    });
}

export function getDynamicAccess(
    sections: OptionInterface[],
): Array<OptionInterface> {
    return buildStructure({
        sections,
    });
}

function getFeatureStructure(): any {
    return [
        {
            ...metadataOption,
            children: [
                alternativeNumberingSection,
                originationSection,
                originationCustomFieldsSection,
                descriptorsSection,
                classificationSection,
                certificationsSection,
                linksSection,
            ],
        },
        idsOption,
        {
            ...regionalOptions,
            children: [
                titlesSection,
                akasSection,
                descriptionsSection,
                additionalInformationSection,
            ],
        },
        imagesOption,
        creditsOption,
        awardsOptions,
        rightsOption,
        linkageOption,
        rekognitionOption,
        canvasAIOption,
        assetsOption,
        measurementsOption,
        programmingOption,
        financialsOption,
        gracenoteOption,
        schedulesOption,
        videosOption,
        ratingsOption,
        ancillariesOption,
    ];
}

function getDiscoverySections(): any {
    return {
        [CategoryTags.Feature.short]: getFeatureStructure(),
        [CategoryTags.Promotional.short]: getFeatureStructure(),
        [CategoryTags.Component.short]: getFeatureStructure(),
        [CategoryTags.Program.short]: getFeatureStructure(),
        [CategoryTags.Pilot.short]: getFeatureStructure(),
        [CategoryTags.Special.short]: [
            {
                ...metadataOption,
                children: [,
                    alternativeNumberingSection,
                    originationSection,
                    originationCustomFieldsSection,
                    descriptorsSection,
                    classificationSection,
                    certificationsSection,
                    linksSection,
                ],
            },
            idsOption,
            {
                ...regionalOptions,
                children: [
                    titlesSection,
                    akasSection,
                    descriptionsSection,
                    additionalInformationSection,
                ],
            },
            imagesOption,
            creditsOption,
            awardsOptions,
            rightsOption,
            linkageOption,
            rekognitionOption,
            canvasAIOption,
            assetsOption,
            measurementsOption,
            programmingOption,
            financialsOption,
            gracenoteOption,
            schedulesOption,
            videosOption,
            ratingsOption,
            ancillariesOption,
        ],
        [CategoryTags.Extras.short]: [
            {
                ...metadataOption,
                children: [
                    alternativeNumberingSection,
                    originationSection,
                    originationCustomFieldsSection,
                    descriptorsSection,
                    classificationSection,
                    certificationsSection,
                    linksSection,
                ],
            },
            idsOption,
            {
                ...regionalOptions,
                children: [
                    titlesSection,
                    akasSection,
                    descriptionsSection,
                    additionalInformationSection,
                ],
            },
            imagesOption,
            creditsOption,
            awardsOptions,
            rightsOption,
            linkageOption,
            rekognitionOption,
            canvasAIOption,
            assetsOption,
            measurementsOption,
            programmingOption,
            financialsOption,
            gracenoteOption,
            schedulesOption,
            videosOption,
            ratingsOption,
            ancillariesOption,
        ],
        [CategoryTags.Short.short]: [
            {
                ...metadataOption,
                children: [
                    alternativeNumberingSection,
                    originationSection,
                    originationCustomFieldsSection,
                    descriptorsSection,
                    classificationSection,
                    certificationsSection,
                    linksSection,
                    shortNumberingSection,
                ],
            },
            idsOption,
            {
                ...regionalOptions,
                children: [
                    titlesSection,
                    akasSection,
                    descriptionsSection,
                    additionalInformationSection,
                ],
            },
            imagesOption,
            creditsOption,
            awardsOptions,
            rightsOption,
            linkageOption,
            rekognitionOption,
            canvasAIOption,
            assetsOption,
            measurementsOption,
            programmingOption,
            financialsOption,
            gracenoteOption,
            schedulesOption,
            videosOption,
            ratingsOption,
            ancillariesOption,
        ],
        [CategoryTags.Show.short]: [
            {
                ...metadataOption,
                children: [
                    alternativeNumberingSection,
                    originationSection,
                    originationCustomFieldsSection,
                    descriptorsSection,
                    classificationSection,
                    certificationsSection,
                    linksSection,
                ],
            },
            idsOption,
            {
                ...regionalOptions,
                children: [
                    titlesSection,
                    akasSection,
                    descriptionsSection,
                    additionalInformationSection,
                ],
            },
            imagesOption,
            creditsOption,
            awardsOptions,
            rightsOption,
            linkageOption,
            rekognitionOption,
            {
                ...measurementsOption,
                children: [ratingsSection, releaseDatesSection],
            },
            canvasAIOption,
            {
                ...assetsOption,
                children: [
                    regionalSupplyChainSection,
                    digitalReadinessStatusSection
                ],
            },
            financialsOption,
            gracenoteOption,
            schedulesOption,
            videosOption,
            ratingsOption,
            gamesOption,
            airingOrderOption,
            ancillariesOption,
        ],
        [CategoryTags.Season.short]: [
            {
                ...metadataOption,
                children: [
                    seasonNumberingSection,
                    alternativeNumberingSection,
                    originationSection,
                    originationCustomFieldsSection,
                    descriptorsSection,
                    classificationSection,
                    certificationsSection,
                    linksSection,
                ],
            },
            idsOption,
            {
                ...regionalOptions,
                children: [
                    titlesSection,
                    akasSection,
                    descriptionsSection,
                    additionalInformationSection,
                ],
            },
            imagesOption,
            creditsOption,
            rightsOption,
            linkageOption,
            videosOption,
            ratingsOption,
            awardsOptions,
            rekognitionOption,
            financialsOption,
            {
                ...measurementsOption,
                children: [ratingsSection, releaseDatesSection],
            },
            canvasAIOption,
            {
                ...assetsOption,
                children: [
                    regionalSupplyChainSection,
                    digitalReadinessStatusSection
                ],
            },
            gracenoteOption,
            schedulesOption,
            programmingOption,
            runningOrderOption,
            ancillariesOption,
        ],
        [CategoryTags.Episode.short]: [
            {
                ...metadataOption,
                children: [
                    episodeNumberingSection,
                    alternativeNumberingSection,
                    originationSection,
                    originationCustomFieldsSection,
                    descriptorsSection,
                    classificationSection,
                    certificationsSection,
                    linksSection,
                ],
            },
            idsOption,
            {
                ...regionalOptions,
                children: [
                    titlesSection,
                    akasSection,
                    descriptionsSection,
                    additionalInformationSection,
                ],
            },
            imagesOption,
            canvasAIOption,
            assetsOption,
            creditsOption,
            awardsOptions,
            rightsOption,
            linkageOption,
            rekognitionOption,
            financialsOption,
            measurementsOption,
            gracenoteOption,
            schedulesOption,
            programmingOption,
            videosOption,
            ratingsOption,
            ancillariesOption,
        ],
        [CategoryTags.ConstructedEpisode.short]: [
            {
                ...metadataOption,
                children: [
                    episodeNumberingSection,
                    alternativeNumberingSection,
                    originationSection,
                    originationCustomFieldsSection,
                    descriptorsSection,
                    classificationSection,
                    certificationsSection,
                    linksSection,
                ],
            },
            idsOption,
            {
                ...regionalOptions,
                children: [
                    titlesSection,
                    akasSection,
                    descriptionsSection,
                    additionalInformationSection,
                ],
            },
            imagesOption,
            creditsOption,
            awardsOptions,
            rightsOption,
            linkageOption,
            assetsOption,
            canvasAIOption,
            rekognitionOption,
            financialsOption,
            measurementsOption,
            gracenoteOption,
            schedulesOption,
            programmingOption,
            videosOption,
            ratingsOption,
            ancillariesOption,
        ],
        [CategoryTags.Storyline.short]: [
            {
                ...metadataOption,
                children: [
                    storylineNumberingSection,
                    alternativeNumberingSection,
                    originationSection,
                    originationCustomFieldsSection,
                    descriptorsSection,
                    classificationSection,
                    certificationsSection,
                    linksSection,
                ],
            },
            idsOption,
            {
                ...regionalOptions,
                children: [
                    titlesSection,
                    akasSection,
                    descriptionsSection,
                    additionalInformationSection,
                ],
            },
            imagesOption,
            creditsOption,
            awardsOptions,
            rightsOption,
            linkageOption,
            assetsOption,
            canvasAIOption,
            rekognitionOption,
            financialsOption,
            measurementsOption,
            gracenoteOption,
            schedulesOption,
            programmingOption,
            videosOption,
            ratingsOption,
            ancillariesOption,
        ],
        [CategoryTags.Game.short]: [
            {
                ...metadataOption,
                children: [
                    descriptorsSection,
                    classificationSection,
                    certificationsSection,
                    linksSection,
                    propertiesSection,
                ],
            },
            idsOption,
            regionalOptions,
            imagesOption,
            creditsOption,
            awardsOptions,
            rightsOption,
            linkageOption,
            rekognitionOption,
            financialsOption,
            canvasAIOption,
            {
                ...assetsOption,
                children: [
                    regionalSupplyChainSection,
                    digitalReadinessStatusSection
                ],
            },
            measurementsOption,
            gracenoteOption,
            schedulesOption,
            programmingOption,
            channelsOption,
            showsOption,
            charactersOption,
            videosOption,
            ratingsOption,
            ancillariesOption,
        ],
        [CategoryTags.Podcast.short]: [
            {
                ...metadataOption,
                children: [
                    alternativeNumberingSection,
                    originationSection,
                    originationCustomFieldsSection,
                    descriptorsSection,
                    classificationSection,
                    certificationsSection,
                    linksSection,
                ],
            },
            idsOption,
            {
                ...regionalOptions,
                children: [
                    titlesSection,
                    akasSection,
                    descriptionsSection,
                    additionalInformationSection,
                ],
            },
            imagesOption,
            creditsOption,
            awardsOptions,
            rightsOption,
            linkageOption,
            rekognitionOption,
            canvasAIOption,
            assetsOption,
            measurementsOption,
            programmingOption,
            financialsOption,
            gracenoteOption,
            schedulesOption,
            videosOption,
            ratingsOption,
            ancillariesOption,
        ],
        [CategoryTags.Version.short]: [
            {
                ...metadataOption,
                children: [
                    alternativeNumberingSection,
                    originationSection,
                    originationCustomFieldsSection,
                    descriptorsSection,
                    classificationSection,
                    certificationsSection,
                    linksSection,
                ],
            },
            idsOption,
            {
                ...regionalOptions,
                children: [
                    titlesSection,
                    akasSection,
                    descriptionsSection,
                    additionalInformationSection,
                ],
            },
            imagesOption,
            creditsOption,
            awardsOptions,
            rightsOption,
            linkageOption,
            rekognitionOption,
            canvasAIOption,
            assetsOption,
            measurementsOption,
            programmingOption,
            financialsOption,
            gracenoteOption,
            schedulesOption,
            videosOption,
            ratingsOption,
            ancillariesOption,
        ],
    };
}

export function getDisoveryAccess(category = ""): Array<OptionInterface> {
    const options = getDiscoverySections();
    let sections = options[category];

    if (typeof category == "object") {
        sections = Object.keys(options)
            .filter((option) => category[option])
            .map((key) => {
                return {
                    name: key,
                    path: `record.category.${key}`,
                    children: options[key],
                };
            });
    }

    const result = buildStructure({
        sections,
    });

    return result;
}

export function getCollectionsAccess(): Array<OptionInterface> {
    let sections = [
        collectionTitlesSection,
        {
            ...metadataOption,
            children: [
                originationSection,
                originationCustomFieldsSection,
                descriptorsSection,
                linksSection,
            ],
        },
        imagesOption,
        {
            ...regionalOptions,
            children: [
                titlesSection,
                descriptionsSection,
                additionalInformationSection,
            ],
        },
        idsOption,
        schedulesOption,
        ancillariesOption,
    ];

    return buildStructure({
        sections,
        prefix: policyTypeCollections,
    });
}

export function getFranchiseCollectionsAccess(): Array<OptionInterface> {
    let sections = [
        collectionTitlesSection,
        {
            ...metadataOption,
            children: [
                originationSection,
                originationCustomFieldsSection,
                descriptorsSection,
                linksSection,
            ],
        },
        imagesOption,
        {
            ...regionalOptions,
            children: [
                titlesSection,
                descriptionsSection,
                additionalInformationSection,
            ],
        },
        idsOption,
        ancillariesOption,
    ];

    return buildStructure({
        sections,
        prefix: policyTypeFranchiseCollections,
    });
}

export function getLocalisationsAccess(): Array<OptionInterface> {
    let sections = [
        collectionTitlesSection,
        imagesOption,
        {
            ...regionalOptions,
            children: [
                titlesSection,
                descriptionsSection,
                additionalInformationSection,
            ],
        },
        localisationTabOption,
    ];

    return buildStructure({
        sections,
        prefix: policyTypeLocalisations,
    });
}

export function getExportsAccess(): Array<OptionInterface> {
    let sections = [
        collectionTitlesSection,
        {
            ...metadataOption,
            children: [
                originationSection,
                originationCustomFieldsSection,
            ],
        },
        imagesOption,
        {
            ...regionalOptions,
            children: [
                titlesSection,
                descriptionsSection,
                additionalInformationSection,
            ],
        },
        financialsOption,
    ];

    return buildStructure({
        sections,
        prefix: policyTypeExports,
    });
}

export function getSwimlanesAccess(): Array<OptionInterface> {
    let sections = [
        collectionTitlesSection,
        imagesOption,
        {
            ...regionalOptions,
            children: [
                titlesSection,
                descriptionsSection,
                additionalInformationSection,
            ],
        },
        financialsOption,
        schedulesOption,
    ];

    return buildStructure({
        sections,
        prefix: policyTypeSwimlanes,
    });
}

export function getLineupsUserGroupsAccess(): Array<OptionInterface> {
    let sections = [
        lineupsOption,
        groupSettingsOption,
        catalogAvailabilityOption,
        cyclesOption,
    ];

    return buildStructure({
        sections,
        prefix: policyTypeUserGroups,
    });
}

export function getReportingAccess(): Array<OptionInterface> {
    return [reportingOption];
}

export function getApplicationSettingAccess(): Array<OptionInterface> {
    let sections = [
        userRegistrationsAndPermissionsOption,
        dataManagerOption,
        dataIngestOption,
        catalogsOption,
        imageUploaderOption,
        contributorsOption,
    ];

    return buildStructure({
        sections,
        prefix: policyTypePlatformManagement,
    });
}

export function getUserRolesAndPoliciesAccess(): Array<OptionInterface> {
    let sections = [userRoleManagementOption, userPolicyManagementOption];

    return buildStructure({
        sections,
        prefix: policyTypeUserRolesAndPolicies,
    });
}

export function getRecordBuilderAccess(): Array<OptionInterface> {
    return [recordBuilderOption];
}

export function getRecordPropertiesAccess(): Array<OptionInterface> {
    let sections = [
        recordStatusOption,
        catalogOption,
        securityLevelOption,
        graceNoteSyndicationOption,
    ];

    return buildStructure({
        sections,
    });
}

export function getTicketTypeAccess(): Array<OptionInterface> {
    let sections = [
        ticketTypeStandaloneOption,
        ticketTypeTvShowOption,
        ticketTypeUpdateOption,
    ];

    return buildStructure({
        sections,
    });
}

export function getCreateRecordsRequestStatusAccess(): Array<OptionInterface> {
    let sections = [
        createRecordsRequestStatusDraftOption,
        createRecordsRequestStatusAwatingApprovalOption,
        createRecordsRequestStatusApprovedOption,
        createRecordsRequestStatusRejectedOption,
        createRecordsRequestStatusChangeInProgressOption,
        createRecordsRequestStatusCancelledOption,
        createRecordsRequestStatusSubmissionInProgressOption,
        createRecordsRequestStatusSubmittedOption,
        createRecordsRequestStatusSubmissionRejectedOption,
    ];

    return buildStructure({
        sections,
    });
}

export function getUpdateRecordsRequestStatusAccess(): Array<OptionInterface> {
    let sections = [
        updateRecordsRequestStatusDraftOption,
        updateRecordsRequestStatusAwaitingApprovalOption,
        updateRecordsRequestStatusApprovedOption,
        updateRecordsRequestStatusRejectedOption,
        updateRecordsRequestStatusChangeInProgressOption,
        updateRecordsRequestStatusCancelledOption,
        updateRecordsRequestStatusSubmissionInProgressOption,
        updateRecordsRequestStatusSubmittedOption,
        updateRecordsRequestStatusSubmissionRejectedOption,
    ];

    return buildStructure({
        sections,
    });
}

export function getTaskManagerTabsAccess(): Array<OptionInterface> {
    let sections = [
        taskManagerTicketsOptions,
        taskManagerMyTicketsOptions,
        taskManagerArchiveOptions,
    ];

    return buildStructure({
        sections,
    });
}

export function getTaskManagerOwnApprovalsAccess(): Array<OptionInterface> {
    let sections = [
        ownApprovalsStandaloneOption,
        ownApprovalsTvShowOption,
        ownApprovalsUpdateOption,
    ];

    return buildStructure({
        sections,
    });
}

export function getTaskManagerOtherApprovalsAccess(): Array<OptionInterface> {
    let sections = [
        otherApprovalsStandaloneOption,
        otherApprovalsTvShowOption,
        otherApprovalsUpdateOption,
    ];

    return buildStructure({
        sections,
    });
}

export function getUpdateRecordsRequestTicketActivityAccess(): Array<OptionInterface> {
    let sections = [
        updateRecordsRequestInternalCommentsOption,
        updateRecordsRequestExternalCommentsOption,
    ];

    return buildStructure({
        sections,
    });
}

export function getCreateRecordsRequestTicketActivityAccess(): Array<OptionInterface> {
    let sections = [
        createRecordsRequestInternalCommentsOption,
        createRecordsRequestExternalCommentsOption,
    ];

    return buildStructure({
        sections,
    });
}
