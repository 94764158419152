import React from "react";
import "./styles/_styles.scss";
import RenderPositionBlank from "./src/RenderPositionBlank";
import RenderTitle from "./src/RenderTitle";
import RenderTotalCategories from "./src/RenderTotalCategories";
import RenderDoubleTagAmount from "../Cycles/src/RenderDoubleTagAmount";
import RenderDoubleTagPercentage from "../Cycles/src/RenderDoubleTagPercentage";

const CycleRowMonth = (props) => {
    const { monthStats = [] } = props;

    const {
        month,
        total_sell_rate,
        total_buy_rate,
        overall_margin,
        features,
        shows,
        episodes,
    } = monthStats;

    const tagStyles = {
        marginLeft: "0.75rem",
    };

    return (
        <div className={`meta-numbered-record-table--row--blank`}>
            {RenderPositionBlank()}

            <div className="meta-numbered-record-table--content">
                {RenderTitle({
                    original_title: month,
                })}
            </div>

            <div className="meta-numbered-record-table--content-right meta-numbered-record-table--month-stat">
                <RenderTotalCategories
                    features={features}
                    shows={shows}
                    episodes={episodes}
                />

                <RenderDoubleTagAmount
                    text="Total sell rate"
                    rightText={total_sell_rate}
                    styles={tagStyles}
                    className="total-sell-rate"
                />

                <RenderDoubleTagAmount
                    text="Total buy rate"
                    rightText={total_buy_rate}
                    styles={tagStyles}
                    className="total-buy-rate"
                />

                <RenderDoubleTagPercentage
                    text="Overall margin"
                    rightText={overall_margin}
                    styles={tagStyles}
                    className="total-overall-margin-rate"
                />
            </div>
        </div>
    );
};

export default CycleRowMonth;
