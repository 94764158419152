import { isEmpty } from "lodash";
import { NuvoRecord } from "../containers/record/tv-series/types/nuvo.types";
import { RecordCollectionVT } from "../containers/record/types";

class CollectionHelpers {
    fromNuvoRecord(nuvoRecord: NuvoRecord): RecordCollectionVT["collection"] {
        // If all fields are blank then no collection has been imported, so we return null
        if (
            isEmpty(nuvoRecord.collection_meta_id) &&
            isEmpty(nuvoRecord.collection_title) &&
            isEmpty(nuvoRecord.collection_category)
        ) {
            return null;
        }

        // If meta_id is filled then this is an existing collection
        if (!isEmpty(nuvoRecord.collection_meta_id)) {
            return {
                metaId: nuvoRecord.collection_meta_id,
                title: nuvoRecord.collection_title,
                category: nuvoRecord.collection_category,
            };
        }

        // If meta_id is empty then this is a new collection
        return {
            metaId: null,
            title: nuvoRecord.collection_title,
            category: nuvoRecord.collection_category,
        };
    }

    toNuvoRecord(
        collection: RecordCollectionVT["collection"],
    ): Pick<
        NuvoRecord,
        "collection_meta_id" | "collection_title" | "collection_category"
    > {
        if (isEmpty(collection)) {
            return {
                collection_meta_id: "",
                collection_title: "",
                collection_category: "",
            };
        }

        return {
            collection_meta_id: collection.metaId,
            collection_title: collection.title,
            collection_category: collection.category,
        };
    }
}

export default new CollectionHelpers();
