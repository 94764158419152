import _ from "lodash";

import {
    LANGUAGE_KEY_PRIMARY_LANGUAGE,
    ADDITIONAL_SPOKEN_LANGUAGES,
    CLIENT_MANAGED_COUNTRIES,
    CLIENT_MANAGED_LANGUAGES,
    CLIENT_MANAGED_ASSET_COMPLIANCES,
    CLIENT_MANAGED_ASSET_CERTIFICATIONS,
    CLIENT_MANAGED_RIGHTS_LICENSORS,
    CLIENT_MANAGED_RIGHTS_PLATFORMS,
    CLIENT_MANAGED_PRODUCTION_QUOTAS,
    CLIENT_MANAGED_DESCRIPTORS,
    CLIENT_MANAGED_PROVENANCES,
    CLIENT_MANAGED_CAST_AND_CREW,
    DUBBING_TRACKS,
    SUBTITLES,
    AWARDS,
    CAST_AND_CREW,
    TITLES_AND_SYNOPSES,
    CLIENT_MANAGED_RIGHTS_VERSIONS,
    CLIENT_MANAGED_RECORD_STATUSES,
    CLIENT_MANAGED_RELEASE_DATE_TYPES,
    CLIENT_MANAGED_TERRITORIES,
    CLIENT_MANAGED_SUPPLIERS,
    CLIENT_MANAGED_CONTENT_TYPES,
    CLIENT_MANAGED_PURCHASE_TYPES,
    CLIENT_MANAGED_ENHANCED_FIRSTLOOK_SERIES,
    CLIENT_MANAGED_BRANDING_TYPE_NAMES,
    CLIENT_MANAGED_TARGET_DEMOS,
    CLIENT_MANAGED_VIEWERSHIPS,
    CLIENT_MANAGED_MEDIAN_AGES,
    CLIENT_MANAGED_SERIES_NETWORKS,
    CLIENT_MANAGED_RIGHTS_HIATUS_TYPES,
    CLIENT_MANAGED_PROGRAM_DIRECTORS,
    CLIENT_MANAGED_PROGRAM_STATUSES,
    CLIENT_MANAGED_SP_REVIEW_NAMES,
    CLIENT_MANAGED_SP_REVIEW_CAUSES,
    CLIENT_MANAGED_PROGRAM_USAGES,
    CLIENT_MANAGED_PRIMARY_PROGRAM_USAGES,
    CLIENT_MANAGED_TAXONOMY,
    CLIENT_MANAGED_PROGRAM_SUBTYPES,
    CLIENT_MANAGED_PROGRAM_BRANDING,
    CLIENT_MANAGED_RECORD_TYPES,
    CLIENT_MANAGED_CHANNEL_ORIGIN,
    CLIENT_MANAGED_LIBRARY_PREFIX,
    CLIENT_MANAGED_ACTION_TYPE,
    CLIENT_MANAGED_APPROVAL_GROUPS,
    CLIENT_MANAGED_APPROVAL_STATUSES,
    CLIENT_MANAGED_APPROVAL_JUSTIFICATIONS,
    CLIENT_MANAGED_EPISODE_TYPE,
    CLIENT_MANAGED_AMC_DESCRIPTION_PREFIXES,
    CLIENT_MANAGED_PREMIER_PLATFORM,
    CLIENT_MANAGED_AMC_PREMIER_PLATFORM,
    CLIENT_MANAGED_RELEASE_TYPES,
    CLIENT_MANAGED_PRODUCTION_STUDIOS,
    CLIENT_MANAGED_PLATFORMS,
    CLIENT_MANAGED_TITLES_AND_SYNOPSES_STATUS
} from "./options";

import {
    tranformClientManagedLanguages,
    isSpokenLanguage,
    shouldGroupByRegion,
    isScriptLanguage,
} from "../ClientManagedOptions/languages";
import { formatClientManagedCountries } from "../ClientManagedOptions/countries";
import { ClientManagedFullRightsPlatformsOptions } from "../ClientManagedOptions/rights_platforms_all";
import { CastAndCrewOptionsTransformer } from "../ClientManagedOptions/cast_and_crew";
import { SupplierOptionsTransformer } from "../ClientManagedOptions/supplier";
import OptionsSearchApi from "../Options/OptionsSearchApi";
import { GetFromLocalStorage } from "../Storage/localStorage";
import { transformScriptLanguages } from "../ClientManagedOptions/scripts";
import { formatClientManagedTerritories } from "../ClientManagedOptions/territories";
import seriesNetworksOptionsTransformer from "./seriesNetworks";
import { transformSpReviewOptions } from "../ClientManagedOptions/sp_review";
import { transformRecordStatus } from "./record_statuses";
import {
    transformBasicOptions,
    transformTextValueOptions,
} from "../../models/ClientManaged/model";

export default function fetchClientManagedOptions(props) {
    const {
        clientManagedType = "",
        onResponse = () => {},
        onError = () => {},
        apiUrls = {},
        clientFeatures = {},
        list,
        searchTerm = "",
        type = "",
        testingData,
        searchAll = false,
    } = props;

    const {
        getClientManagedLanguages = "",
        getClientManaged = "",
        getClientManagedAssetCertifications = "",
        searchClientManaged = "",
        getClientManagedTaxonomies = "",
        getDataList = ""
    } = apiUrls;

    const { managedLists = [] } = clientFeatures;

    switch (clientManagedType) {
        case LANGUAGE_KEY_PRIMARY_LANGUAGE:
        case CLIENT_MANAGED_LANGUAGES:
        case DUBBING_TRACKS:
        case SUBTITLES:
        case ADDITIONAL_SPOKEN_LANGUAGES:
        case AWARDS:
        case CAST_AND_CREW:
        case TITLES_AND_SYNOPSES:
            const spoken = isSpokenLanguage(clientManagedType);
            const groupByRegion = shouldGroupByRegion(clientManagedType);

            let localPrimary = GetFromLocalStorage(CLIENT_MANAGED_LANGUAGES);

            if (localPrimary) {
                const languages = JSON.parse(localPrimary);

                let transformedLanguages = tranformClientManagedLanguages({
                    client_managed_languages: languages,
                    languageKey: clientManagedType,
                    spoken,
                    groupByRegion,
                });

                if (isScriptLanguage(clientManagedType)) {
                    transformedLanguages = transformScriptLanguages({
                        languages,
                        listKey: clientManagedType,
                    });
                }

                onResponse(transformedLanguages);
            }

            if (!localPrimary) {
                OptionsSearchApi({
                    storeKey: CLIENT_MANAGED_LANGUAGES,
                    updateList: (value) => {
                        let transformedLanguages =
                            tranformClientManagedLanguages({
                                client_managed_languages: value || {},
                                languageKey: clientManagedType,
                                spoken,
                                groupByRegion,
                            });

                        if (isScriptLanguage(clientManagedType)) {
                            transformedLanguages = transformScriptLanguages({
                                languages: value || {},
                                listKey: clientManagedType,
                            });
                        }

                        onResponse(transformedLanguages);
                    },
                    onError,
                    url: getClientManagedLanguages,
                });
            }
            break;

        case CLIENT_MANAGED_COUNTRIES:
            let localCountries = GetFromLocalStorage(CLIENT_MANAGED_COUNTRIES);

            if (localCountries) {
                onResponse(
                    formatClientManagedCountries(JSON.parse(localCountries)),
                );
            }

            if (!localCountries) {
                OptionsSearchApi({
                    storeKey: CLIENT_MANAGED_COUNTRIES,
                    updateList: (value) => {
                        onResponse(formatClientManagedCountries(value));
                    },
                    url: getClientManaged.replace(
                        "{table}",
                        CLIENT_MANAGED_COUNTRIES,
                    ),
                    onError,
                });
            }
            break;

        case CLIENT_MANAGED_TERRITORIES:
            let territories = GetFromLocalStorage(CLIENT_MANAGED_TERRITORIES);

            if (territories) {
                onResponse(
                    formatClientManagedTerritories(JSON.parse(territories)),
                );
            }

            if (!territories) {
                OptionsSearchApi({
                    storeKey: CLIENT_MANAGED_TERRITORIES,
                    updateList: (value) => {
                        onResponse(formatClientManagedTerritories(value));
                    },
                    url: getClientManaged.replace(
                        "{table}",
                        CLIENT_MANAGED_TERRITORIES,
                    ),
                    onError,
                });
            }
            break;

        case CLIENT_MANAGED_ASSET_COMPLIANCES:
            if (list && managedLists.includes(list)) {
                const localManagedCompliances = GetFromLocalStorage(
                    CLIENT_MANAGED_ASSET_COMPLIANCES,
                );

                if (localManagedCompliances) {
                    onResponse(JSON.parse(localManagedCompliances));
                }

                if (!localManagedCompliances) {
                    OptionsSearchApi({
                        storeKey: CLIENT_MANAGED_ASSET_COMPLIANCES,
                        url: getClientManaged.replace(
                            "{table}",
                            CLIENT_MANAGED_ASSET_COMPLIANCES,
                        ),
                        onError,
                        updateList: (values) => onResponse(values),
                        testingData
                    });
                }
            }
            break;

        case CLIENT_MANAGED_ASSET_CERTIFICATIONS:
            if (list && managedLists.includes(list)) {
                const localManagedCertifications = GetFromLocalStorage(
                    CLIENT_MANAGED_ASSET_CERTIFICATIONS,
                );

                if (localManagedCertifications) {
                    return onResponse(JSON.parse(localManagedCertifications));
                }

                if (!localManagedCertifications) {
                    OptionsSearchApi({
                        storeKey: CLIENT_MANAGED_ASSET_CERTIFICATIONS,
                        url: getClientManagedAssetCertifications,
                        onError,
                        updateList: (values) => onResponse(values),
                        testingData
                    });
                }
            }

            break;

        case CLIENT_MANAGED_TITLES_AND_SYNOPSES_STATUS:
        case CLIENT_MANAGED_RIGHTS_LICENSORS:
        case CLIENT_MANAGED_PRODUCTION_QUOTAS:
        case CLIENT_MANAGED_RIGHTS_VERSIONS:
        case CLIENT_MANAGED_RELEASE_DATE_TYPES:
        case CLIENT_MANAGED_RECORD_STATUSES:
        case CLIENT_MANAGED_SUPPLIERS:
            let data = GetFromLocalStorage(
                clientManagedType,
            );

            if (data) {
                onResponse(JSON.parse(data));
            } else {
                OptionsSearchApi({
                    updateList: (value) => {
                        onResponse(value);
                    },
                    url: getClientManaged.replace(
                        "{table}",
                        clientManagedType,
                    ),
                    storeKey: clientManagedType,
                    onError,
                });
            }
            break;
        case CLIENT_MANAGED_RIGHTS_PLATFORMS:
            let localRightsPlatforms = GetFromLocalStorage(
                CLIENT_MANAGED_RIGHTS_PLATFORMS,
            );

            if (!_.isNull(localRightsPlatforms)) {
                onResponse(
                    ClientManagedFullRightsPlatformsOptions({
                        client_managed_rights_platforms:
                            JSON.parse(localRightsPlatforms),
                    }),
                );
            }

            if (_.isNull(localRightsPlatforms)) {
                OptionsSearchApi({
                    storeKey: CLIENT_MANAGED_RIGHTS_PLATFORMS,
                    updateList: (value) => {
                        onResponse(
                            ClientManagedFullRightsPlatformsOptions({
                                client_managed_rights_platforms: value || [],
                            }),
                        );
                    },
                    url: getClientManaged.replace(
                        "{table}",
                        CLIENT_MANAGED_RIGHTS_PLATFORMS,
                    ),
                    onError,
                });
            }
            break;

        case CLIENT_MANAGED_DESCRIPTORS:
            OptionsSearchApi({
                updateList: (value) => {
                    onResponse(value);
                },
                searchTerm,
                type,
                url: `${(searchAll ? getClientManaged : searchClientManaged).replace(
                    "{table}",
                    CLIENT_MANAGED_DESCRIPTORS,
                )}`,
                onError,
            });
            break;

        case CLIENT_MANAGED_CAST_AND_CREW:
            let localCastAndCrew = GetFromLocalStorage(
                CLIENT_MANAGED_CAST_AND_CREW,
            );

            if (!_.isNull(localCastAndCrew)) {
                onResponse(
                    CastAndCrewOptionsTransformer(JSON.parse(localCastAndCrew)),
                );
            } else {
                OptionsSearchApi({
                    storeKey: CLIENT_MANAGED_CAST_AND_CREW,
                    updateList: (value) => {
                        onResponse(CastAndCrewOptionsTransformer(value));
                    },
                    url: `${(searchAll ? getClientManaged : searchClientManaged).replace(
                        "{table}",
                        CLIENT_MANAGED_CAST_AND_CREW,
                    )}`,
                    onError,
                    testingData
                });
            }
            break;

        case CLIENT_MANAGED_SERIES_NETWORKS:
            let localSeriesNetworks = GetFromLocalStorage(
                CLIENT_MANAGED_SERIES_NETWORKS,
            );

            if (localSeriesNetworks) {
                onResponse(
                    seriesNetworksOptionsTransformer(localSeriesNetworks),
                );
            } else {
                OptionsSearchApi({
                    updateList: (value) => {
                        onResponse(seriesNetworksOptionsTransformer(value));
                    },
                    url: getClientManaged.replace(
                        "{table}",
                        CLIENT_MANAGED_SERIES_NETWORKS,
                    ),
                    storeKey: CLIENT_MANAGED_SERIES_NETWORKS,
                    onError,
                });
            }
            break;

        case CLIENT_MANAGED_SP_REVIEW_NAMES:
            let localSpReviewNames = GetFromLocalStorage(
                CLIENT_MANAGED_SP_REVIEW_NAMES,
            );

            if (localSpReviewNames) {
                onResponse(transformSpReviewOptions(localSpReviewNames));
            } else {
                OptionsSearchApi({
                    updateList: (value) => {
                        onResponse(transformSpReviewOptions(value));
                    },
                    url: getClientManaged.replace(
                        "{table}",
                        CLIENT_MANAGED_SP_REVIEW_NAMES,
                    ),
                    storeKey: CLIENT_MANAGED_SP_REVIEW_NAMES,
                    onError,
                });
            }
            break;

        case CLIENT_MANAGED_SP_REVIEW_CAUSES:
            let localSpReviewCauses = GetFromLocalStorage(
                CLIENT_MANAGED_SP_REVIEW_CAUSES,
            );

            if (localSpReviewCauses) {
                onResponse(transformSpReviewOptions(localSpReviewCauses));
            } else {
                OptionsSearchApi({
                    updateList: (value) => {
                        onResponse(transformSpReviewOptions(value));
                    },
                    url: getClientManaged.replace(
                        "{table}",
                        CLIENT_MANAGED_SP_REVIEW_CAUSES,
                    ),
                    storeKey: CLIENT_MANAGED_SP_REVIEW_CAUSES,
                    onError,
                });
            }
            break;

        case CLIENT_MANAGED_TAXONOMY:
            let localTaxonomy = GetFromLocalStorage(CLIENT_MANAGED_TAXONOMY);

            if (localTaxonomy) {
                const parsed = JSON.parse(localTaxonomy);

                onResponse(parsed.hierarchy || []);
            } else {
                OptionsSearchApi({
                    updateList: (value) => {
                        onResponse(value.hierarchy || []);
                    },
                    url: getClientManagedTaxonomies.replace(
                        "{globalKey}",
                        type,
                    ),
                    storeKey: CLIENT_MANAGED_TAXONOMY,
                    testingData,
                    onError,
                });
            }
            break;

        case CLIENT_MANAGED_APPROVAL_GROUPS:
        case CLIENT_MANAGED_APPROVAL_STATUSES:
        case CLIENT_MANAGED_APPROVAL_JUSTIFICATIONS:
            let localApprovalItem = GetFromLocalStorage(clientManagedType);

            if (localApprovalItem) {
                onResponse(JSON.parse(localApprovalItem));
            } else {
                OptionsSearchApi({
                    testingData,
                    onError,
                    url: getDataList.replace("{list}", clientManagedType),
                    updateList: (values) => onResponse(values),
                    storeKey: clientManagedType,
                });
            }
            break;

        // basic options
        case CLIENT_MANAGED_ACTION_TYPE:
        case CLIENT_MANAGED_CHANNEL_ORIGIN:
        case CLIENT_MANAGED_PROGRAM_BRANDING:
        case CLIENT_MANAGED_PROGRAM_SUBTYPES:
        case CLIENT_MANAGED_PROGRAM_USAGES:
        case CLIENT_MANAGED_PRIMARY_PROGRAM_USAGES:
        case CLIENT_MANAGED_PROGRAM_STATUSES:
        case CLIENT_MANAGED_RIGHTS_HIATUS_TYPES:
        case CLIENT_MANAGED_MEDIAN_AGES:
        case CLIENT_MANAGED_VIEWERSHIPS:
        case CLIENT_MANAGED_TARGET_DEMOS:
        case CLIENT_MANAGED_BRANDING_TYPE_NAMES:
        case CLIENT_MANAGED_ENHANCED_FIRSTLOOK_SERIES:
        case CLIENT_MANAGED_PURCHASE_TYPES:
        case CLIENT_MANAGED_CONTENT_TYPES:
        case CLIENT_MANAGED_PROVENANCES:
        case CLIENT_MANAGED_EPISODE_TYPE:
        case CLIENT_MANAGED_AMC_DESCRIPTION_PREFIXES:
        case CLIENT_MANAGED_PREMIER_PLATFORM:
        case CLIENT_MANAGED_AMC_PREMIER_PLATFORM:
        case CLIENT_MANAGED_RELEASE_TYPES:
        case CLIENT_MANAGED_PRODUCTION_STUDIOS:
            let localValues = GetFromLocalStorage(clientManagedType);

            if (localValues) {
                onResponse(transformBasicOptions(JSON.parse(localValues)));
            } else {
                OptionsSearchApi({
                    updateList: (values) => {
                        onResponse(transformBasicOptions(values));
                    },
                    url: getClientManaged.replace("{table}", clientManagedType),
                    storeKey: clientManagedType,
                    testingData,
                    onError,
                });
            }
            break;

        // text and value
        case CLIENT_MANAGED_PROGRAM_DIRECTORS:
        case CLIENT_MANAGED_RECORD_TYPES:
        case CLIENT_MANAGED_LIBRARY_PREFIX:
        case CLIENT_MANAGED_PLATFORMS:
            let localTextValueItems = GetFromLocalStorage(clientManagedType);

            if (localTextValueItems) {
                onResponse(
                    transformTextValueOptions(JSON.parse(localTextValueItems)),
                );
            } else {
                OptionsSearchApi({
                    updateList: (values) => {
                        onResponse(transformTextValueOptions(values));
                    },
                    url: getClientManaged.replace("{table}", clientManagedType),
                    storeKey: clientManagedType,
                    testingData,
                    onError,
                });
            }
            break;
    }
}
