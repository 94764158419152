import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Button, Link, Stack, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MuiLinearProgress from "@mui/material/LinearProgress";
import Tooltip from "@mui/material/Tooltip";
import { observer } from "mobx-react";
import React, { PropsWithChildren } from "react";
import { Components } from "../../../../core/QA/Components";
import { TaskManagerRoutes } from "../../types/constants";
import { generateTestId } from "../config/CreateDataTestId";
import { useBackgroundProcessingStore } from "../store/useBackgroundProcessingStore";
import {
    BackgroundProcessStatuses,
    BackgroundProcessType,
    ProcessIdType,
    WidgetState,
} from "../types";
import { errorOrErrors } from "../utils/errorOrErrors";
import { normaliseValue } from "../utils/normaliseValue";
import { ClearButton } from "./Clear.button";

const GREEN = "#5ACD32";
const ProgressCounter = observer(({ processId }) => {
    const store = useBackgroundProcessingStore();
    const totalRecords = store.getProcessRecordsCount(processId);
    const processedItemsCount =
        store.getProcessedRecordsCountForProcess(processId);

    return (
        <Typography
            data-testid={generateTestId(
                Components.text,
                "processed-items-count",
            )}
            variant="caption"
        >
            {processedItemsCount} out of {totalRecords} records processed
        </Typography>
    );
});

const LinearProgress = observer(({ processId }: ProcessIdType) => {
    const store = useBackgroundProcessingStore();
    const recordsCount = store.getRecordsCountForProcess(processId);
    const processHasFailed = store.hasProcessFailed(processId);
    const processedItemsCount =
        recordsCount - store.getPendingRecordsCountForProcess(processId);
    const hasCompleted = store.hasProcessFinished(processId);

    let value = 100;

    if (!processHasFailed) {
        value = normaliseValue(processedItemsCount, recordsCount);
    }

    let backgroundColor;

    // TODO: move colors to theme
    if (processHasFailed) {
        backgroundColor = "#F5576C";
    } else if (hasCompleted) {
        backgroundColor = GREEN;
    } else {
        backgroundColor = "#72A1F0";
    }

    return (
        <MuiLinearProgress
            data-testid={generateTestId(Components.progress, "linear")}
            variant="determinate"
            value={value}
            sx={{
                marginTop: "0.2rem",
                paddingY: "0.1875rem",
                "& .MuiLinearProgress-bar": {
                    backgroundColor,
                },
            }}
        />
    );
});

const KillProcessButton = observer(({ processId }: ProcessIdType) => {
    const store = useBackgroundProcessingStore();

    // We have to hide the button in any case except when the process has failed
    if (
        store.getProcessById(processId)?.status !==
        BackgroundProcessStatuses.FAILED
    ) {
        return null;
    }

    return (
        <IconButton
            size="small"
            disableRipple
            data-testid={generateTestId(Components.button, "kill-process")}
            sx={{
                padding: 0,
                color: "rgba(84, 83, 84, 1)",
                marginLeft: "0.5rem",
            }}
            onClick={() => store.killProcess(processId)}
        >
            <CancelIcon fontSize="0.9375rem" />
        </IconButton>
    );
});

const SuccessIconButton = ({ processId }: ProcessIdType) => {
    const store = useBackgroundProcessingStore();

    if (
        store.getProcessById(processId)?.status !==
        BackgroundProcessStatuses.FINISHED
    ) {
        return null;
    }

    return (
        <svg
            data-testid={generateTestId(Components.icon, "success-process")}
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_4363_19537)">
                <path
                    d="M7.5 0C3.375 0 0 3.375 0 7.5C0 11.625 3.375 15 7.5 15C11.625 15 15 11.625 15 7.5C15 3.375 11.625 0 7.5 0ZM6 11.25L2.25 7.5L3.3075 6.4425L6 9.1275L11.6925 3.435L12.75 4.5L6 11.25Z"
                    fill="#5ACD32"
                />
            </g>
            <defs>
                <clipPath id="clip0_4363_19537">
                    <rect width="15" height="15" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

const ProgressSection = observer(({ processId }: ProcessIdType) => {
    const store = useBackgroundProcessingStore();
    const errorItemsCount = store.getProcessErrorsCount(processId);

    return (
        <Box>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                height="100%"
                marginTop="0.2rem"
            >
                <ProgressCounter processId={processId} />

                <Stack direction="row" alignItems="center" width="fit-content">
                    {store.hasProcessErrors(processId) && (
                        <Typography
                            data-testid={generateTestId(
                                Components.text,
                                "errors-count",
                            )}
                            paddingY={0}
                            sx={{
                                color: (theme) => theme.palette.secondary.main,
                                fontSize: "0.75rem",
                            }}
                        >
                            {errorItemsCount}
                            {errorOrErrors(errorItemsCount)}
                        </Typography>
                    )}

                    <KillProcessButton processId={processId} />
                    <SuccessIconButton processId={processId} />
                </Stack>
            </Stack>
            <LinearProgress processId={processId} />
        </Box>
    );
});

function BackgroundProcessingStaticTitle() {
    return (
        <Typography
            data-testid={generateTestId(
                Components.text,
                "Background Processing",
            )}
            variant="h5"
        >
            Background Processing
        </Typography>
    );
}

const TicketLink = ({
    ticketId,
}: {
    ticketId: BackgroundProcessType["payload"]["ticketId"];
}) => {
    return (
        <Link
            data-testid={generateTestId(Components.link, "ticket-link")}
            href={TaskManagerRoutes.TICKET_DETAILS.replace(
                ":ticketId",
                String(ticketId),
            )}
        >
            Ticket #{ticketId}
        </Link>
    );
};

const ViewErrorsButton = observer(({ processId }: ProcessIdType) => {
    const store = useBackgroundProcessingStore();
    const errorItemsCount = store.getProcessErrorsCount(processId);

    return (
        <Button
            data-testid={generateTestId(Components.button, "view-errors")}
            variant="view-errors"
            onClick={() => {
                store.setProcessIdDetails(processId);
            }}
        >
            View {errorOrErrors(errorItemsCount)}
        </Button>
    );
});

function StaticTitle({ children }: PropsWithChildren) {
    return <Typography variant="h6">{children}</Typography>;
}

const ProcessItem = observer(({ processId }: ProcessIdType) => {
    const store = useBackgroundProcessingStore();
    const ticketId = store.getTicketIdForProcess(processId);
    const hasErrors = store.hasProcessErrors(processId);

    return (
        <Box
            sx={{
                backgroundColor: "white",
                borderRadius: "0.375rem",
                padding: "0.9375rem",
                width: "100%",
            }}
        >
            <Stack direction="row" spacing={0.5} alignItems="center">
                <StaticTitle>Task Manager Record Create</StaticTitle>
                <StaticTitle>•</StaticTitle>
                <TicketLink ticketId={ticketId} />
            </Stack>

            <ProgressSection processId={processId} />

            {hasErrors && (
                <Stack direction="row" marginTop="1rem">
                    <ViewErrorsButton processId={processId} />
                    <Box ml={0.5}>
                        <ClearButton processId={processId}>Clear</ClearButton>
                    </Box>
                </Stack>
            )}
        </Box>
    );
});

const ProcessList = observer(() => {
    const store = useBackgroundProcessingStore();
    const processes = store.processes || [];

    return (
        <Stack
            direction="column"
            spacing={1}
            width="100%"
            maxHeight="50vh"
            overflow="scroll"
            sx={{
                padding: "0.9375rem",
            }}
        >
            <BackgroundProcessingStaticTitle />
            {processes?.map((process) => {
                return (
                    <ProcessItem
                        key={`process-list-item-${process.processId}`}
                        processId={process.processId}
                    />
                );
            })}
        </Stack>
    );
});

type ProcessingPreviewProps = {
    triggerComponent: React.ReactElement<any, any>;
};

export const Overview = observer(
    ({ triggerComponent }: ProcessingPreviewProps) => {
        return (
            <Tooltip
                data-testid={generateTestId(Components.tooltip, "overview")}
                enterNextDelay={200}
                title={<ProcessList />}
                arrow
                placement="right"
            >
                {triggerComponent}
            </Tooltip>
        );
    },
);
