import React from "react";
import _ from "lodash";
import styles from "../styles";
import DynamicTable from "../../DynamicTable/linkageGeneral";
import Index from "./Index";
import deepcopy from "deepcopy";
import { diff } from "deep-object-diff";

export default class Shows extends Index {
    constructor(props) {
        super(props);

        let data = [];

        if (Array.isArray(this.props.data)) {
            data = this.props.data;
        }

        /**
         * @var object
         */
        this.styles = styles(this.props);

        /**
         * @var object
         */
        this.state = {
            data,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        const nextPropsData = nextProps.data || {};
        const thisPropsData = this.props.data || {};
        const difference = diff(nextPropsData, thisPropsData);

        if (!_.isEmpty(difference)) {
            return true;
        }

        if (!_.isEqual(nextProps.disabled, this.props.disabled)) {
            return true;
        }

        if (!_.isEqual(nextState, this.state)) {
            return true;
        }

        return false;
    }

    handleChange(value) {
        if (typeof this.props.handleFormChange == "function") {
            this.props.handleFormChange(value);
        }

        this.setData(value);
    }

    renderContent() {
        const data = deepcopy(this.state.data || []);
        const copy = this.props.copy || {};
        const linkTitle = copy.showLink || "Show Link";
        const linkPlaceholder =
            copy.startTypingShowName || "Start typing Show name";

        if (this.props.disabled && _.isEmpty(data)) {
            return false;
        }

        return (
            <div style={this.styles.marginItem}>
                <DynamicTable
                    classPrefix="meta-shows"
                    disabled={this.props.disabled}
                    data={data}
                    concatData={data}
                    searchUrl={this.props.searchUrl}
                    api_token={this.props.api_token}
                    links={true}
                    logoBranding={true}
                    linkTitle={linkTitle}
                    linkPlaceholder={linkPlaceholder}
                    handleChange={(item) => this.handleChange(item)}
                    handleLinkSearch={this.props.handleLinkSearch.bind(this)}
                />
            </div>
        );
    }
}
