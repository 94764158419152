export const SET_CONDITIONS_CONFIGURATION = "set_conditions_configuration";
export const SET_CONDITIONS_DATA = "set_conditions_data";
export const SET_STATE = "set_state";

export const setConditionsConfiguration = (configuration: any) => {
    return {
        type: SET_CONDITIONS_CONFIGURATION,
        state: {
            conditionsConfiguration: configuration,
        },
    };
};

export const setState = (data: any) => {
    return {
        type: SET_STATE,
        state: { ...data },
    };
};

export const setConditionsData = (data: any) => {
    return {
        type: SET_CONDITIONS_DATA,
        state: {
            schedulingConditions: data,
        },
    };
};

export default (state, action) => {
    const { handleConditionsFormChange = () => {} } = state;

    let resultState = {};

    switch (action.type) {
        case SET_STATE:
        case SET_CONDITIONS_CONFIGURATION:
            resultState = Object.assign({}, state, action.state);
            break;
        case SET_CONDITIONS_DATA:
            handleConditionsFormChange(action.state.schedulingConditions);
            resultState = Object.assign({}, state, action.state);
            break;
    }

    return resultState;
};
