import React, { Component } from "react";
import styles from "./styles";

export default class PlaceholderBubble extends Component {
    /**
     * PlaceholderBubble
     *
     * @param object props
     * @return false
     */
    constructor(props) {
        super(props);

        /**
         * @var object
         */
        this.styles = styles(this.props);
    }

    /**
     * Renders html dom elements
     *
     * @return DOM elements
     */
    render() {
        if (typeof this.props.text == "string") {
            return (
                <div style={this.styles.container} id={this.props.id}>
                    {this.props.text}
                </div>
            );
        }

        if (typeof this.props.placeholder == "string") {
            return (
                <div
                    style={Object.assign(
                        {},
                        this.styles.container,
                        this.styles.placeholder,
                    )}
                    id={this.props.id}
                >
                    {this.props.placeholder}
                </div>
            );
        }

        return false;
    }
}
