import React from "react";
import { SectionInterface } from "./interface";
import Ratings from "../../../AgeGuidance";
import _ from "lodash";

const AgeGuidance = (props: SectionInterface) => {
    const { data = {}, handleFormChange = () => {}, disabled = true } = props;

    const { age_guidance = [], classification = {} } = data;

    return (
        <Ratings
            disabled={disabled}
            age_guidance={age_guidance}
            classification={classification}
            handleFormChange={(data: Array<any>) =>
                handleFormChange("age_guidance", data)
            }
            handleFormChangeClassification={(data: {
                maximum_age: number | string | null;
                mainimum_age: number | string | null;
            }) => handleFormChange("classification", data)}
        />
    );
};

export default AgeGuidance;
