import { observer } from "mobx-react";
import { Chip } from "@mui/material";
import { useStores, RootStore } from "../../../../features/Credits/v3/store/rootStore";
import { CoreTableCell } from "../../components/CoreTableCell";
import { TableCreditRow } from "../types/CastCrew.type";
import roleColors from "../../../../features/Credits/utils/roleColors";

export const RenderContributorNameCell = observer(({
    row,
    value,
}: {
    row: TableCreditRow;
    value: string | undefined;
}) => {
    const rootStore: RootStore|{} = useStores() || {}
    const enableCallback = !(rootStore as RootStore).getEditStatus

    const colors = roleColors(row?.role)

    const chipLabel =
        row?.ranking == null || row?.ranking <= -1 ? "-" : row?.ranking;

    return (
        <CoreTableCell
            enableCopyToClipboard
            boldText
            leftAdornment={
                <Chip
                    sx={{
                        color: colors.color,
                        backgroundColor: colors.background,
                    }}
                    label={chipLabel}
                    className="fabric-chip--ranking"
                />
            }
            underlineButtonProps={enableCallback ? {
                onClick: () => {
                    row?.onContributorNameClick(row);
                },
                color: "#5F5F5F",
            } : undefined}
        >
            {value}
        </CoreTableCell>
    );
})
