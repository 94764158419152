import React from "react";
import { SectionInterface } from "./interface";
import GracenoteMetaIntegration from "../../views/GracenoteMetaIntegration";
import KeywordLabel from "../../../KeywordLabel";
import _ from "lodash";

const SectionGracenote = (props: SectionInterface) => {
    const {
        handleFormChange,
        options = {},
        cmsData = {},
        data = {},
        disabled,
        newRecord,
    } = props;

    const { clientDataStructure = {}, clientFeatures = {} } = cmsData;

    const { gracenote = {} } = data;

    const { tms_id = "MO00010229" } = gracenote;

    if (!_.isEmpty(newRecord)) {
        return false;
    }

    const { gracenote_meta_migration = {} } = clientFeatures;

    if (gracenote_meta_migration.active) {
        const RenderRightContent = () => {
            if (_.isEmpty(data.gracenote)) {
                return false;
            }

            return (
                <KeywordLabel
                    title="TMS ID"
                    background="#d7d7d7"
                    color="#3c3c3c"
                    weighting={tms_id}
                    weightingBackground="#B0B0B0"
                    weightColor="#2F2F2F"
                    styles={{
                        marginLeft: "auto",
                    }}
                />
            );
        };

        return (
            <div>
                <GracenoteMetaIntegration
                    name="gracenote"
                    className="meta-record-gracenote"
                    title="Gracenote"
                    data={data}
                    disabled={disabled}
                    lastSection={true}
                    clientDataStructure={clientDataStructure}
                    clientFeatures={clientFeatures}
                    handleFormChange={(options: any) =>
                        handleFormChange(options.section, options.result)
                    }
                    titleRightContent={<RenderRightContent />}
                    options={options}
                />
            </div>
        );
    }

    return false;
};

export default SectionGracenote;
