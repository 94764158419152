import update from "react-addons-update";
import _ from "lodash";
import HandleChange from "./HandleChange";
import GetRecordData from "./GetRecordData";
import deepcopy from "deepcopy";

const HandleDisplayTitleValueChange = (props) => {
    const { title, value, state, handleChange } = props;

    const record = GetRecordData({
        state,
    });

    const { titles = [] } = record;

    const copiedTitlesData = deepcopy(titles);

    // // Update title
    let updatedTitle = deepcopy(title);
    updatedTitle.title = value;

    //find index
    const titleIndex = _.findIndex(copiedTitlesData, function (tl) {
        return tl.locale == title.locale && tl.display;
    });

    let updatedState = copiedTitlesData;

    // update
    if (titleIndex !== -1) {
        updatedState = update(copiedTitlesData, {
            [titleIndex]: {
                $set: updatedTitle,
            },
        });
    }

    // create
    if (titleIndex == -1) {
        updatedState = update(copiedTitlesData, { $push: [updatedTitle] });
    }

    if (typeof handleChange == "function") {
        handleChange({
            key: "titles",
            data: updatedState,
        });

        return;
    }

    HandleChange({
        key: "titles",
        data: updatedState,
        state,
    });
};

export default HandleDisplayTitleValueChange;
