import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import CancelIcon from '@mui/icons-material/Cancel';
import { getTrimedString, getCharactersOnly } from "../../components/Helpers/stringTrim"
import "./styles/_styles.scss";

interface ButtonBarContainerInterface {
    leftChildren?: JSX.Element;
    rightChildren?: JSX.Element;
    rightChildrenClass?: string;
    className?: string
}

interface ButtonBarContainerWithSearchInterface {
    leftChildren?: JSX.Element
    rightChildren: JSX.Element
    rightChildrenClass?: string;
    searchDisabled?: boolean
    handleSearch: (searchTerm: string) => void
    searchPlaceholder?: string,
    searchEnabled?: boolean,
    delay?: number
}

export const ButtonBarContainer = ({
    leftChildren,
    rightChildren,
    rightChildrenClass,
    className
}: ButtonBarContainerInterface) => {
    return (
        <div 
            data-testid="button-bar-container"
            className={`button-bar-container ${className || ''}`}>
            {leftChildren && <div className="left-content">{leftChildren}</div>}

            <div className={`right-content ${rightChildrenClass || ''}`}>{rightChildren}</div>
        </div>
    );
};

export const ButtonBarContainerWithSearch = ({
    leftChildren,
    rightChildren,
    rightChildrenClass,
    handleSearch,
    searchDisabled,
    searchPlaceholder = "Search",
    searchEnabled = true,
    delay = 400
}: ButtonBarContainerWithSearchInterface) => {
    const [search, setSearch] = useState<string>("");
    const [searchString, setSearchString] = useState<string>("");

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            handleSearch(search);
        }, delay)

        return () => clearTimeout(delayDebounceFn)
    }, [search])

    return (
        <ButtonBarContainer
            leftChildren={
                <>
                    {searchEnabled &&
                        <TextField
                            value={searchString}
                            onChange={(e: any) => {
                                const value: string = e.target.value
                                setSearchString(value)

                                if (getCharactersOnly(value) !== getCharactersOnly(search)) {
                                     setSearch(getTrimedString(value))
                                }
                            }}
                            placeholder={searchPlaceholder}
                            type="text"
                            variant="filled"
                            disabled={searchDisabled || false}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    return
                                }
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: search && (
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        size="small"
                                        onClick={() => {
                                            setSearch("");
                                            setSearchString("");
                                            handleSearch("");
                                        }}
                                        sx={{
                                            "&:hover": {
                                                backgroundColor: "transparent"
                                            }
                                        }}
                                    >
                                        <CancelIcon fontSize="small" />
                                    </IconButton>
                                ),
                            }}
                        />
                    }

                    {leftChildren && 
                        leftChildren
                    }
                </>
            }
            rightChildren={rightChildren}
            rightChildrenClass={rightChildrenClass}
        />
    );
};

export default ButtonBarContainer;
