import React from "react";
import styles from "../styles";
import EnvironmentVersion from "./EnvironmentVersion";

export default (props) => {
    const classStyles = styles(props);
    let className = "";
    let platform = false;

    if (props.animate) {
        className = "react-fade-in-slow";
    }

    if (process.env.APP_PLATFORM) {
        platform = process.env.APP_PLATFORM.replace(" ", "").toLowerCase();
    }

    return (
        <div
            style={Object.assign(
                {},
                classStyles.container.default,
                platform && classStyles.container[platform],
            )}
            className={className}
        >
            <div
                style={Object.assign(
                    {},
                    classStyles.content.default,
                    platform && classStyles.content[platform],
                )}
            >
                <EnvironmentVersion {...props} platform={platform} />

                {props.children}
            </div>
        </div>
    );
};
