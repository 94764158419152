
import React from "react";
import Input from "../../interfaces/Input.interface";
import { observer } from "mobx-react";
import { useStores } from '../../store/rootStore'
import getFieldDateTimeFormat from "../utils/DateTimeFormat";
import { CoreInputDateTimePicker } from '../../../components/inputs/DateTime/CoreInputDateTimePicker';

export const DateTimePicker = observer(({ field }: Input) => {
    const { formStore } = useStores()

    return (
        <div data-testid={"fabric-dynamic-form-datetimepicker-input"}>
            <CoreInputDateTimePicker
                disabled={field.validation?.readOnly?.enabled || formStore.disabled}
                path={field.key}
                labelProps={{
                    text: field.label,
                    isRequired: field.validation?.required?.enabled,
                }}
                format={getFieldDateTimeFormat(field)}
            />
        </div>
    )
})