import SendRequest from "../../../components/SendRequest";
import GetApiUrls from "../GetApiUrls";

const HandleFetchCyclesRequest = (props) => {
    const { state, options } = props;

    const { handleSuccess, handleError } = options;

    const { activeRecord = {}, cyclesYear } = state;

    const { meta_id } = activeRecord;

    const { cycles = "" } = GetApiUrls(state);

    const requestOptions = {
        url: cycles.replace("{meta_id}", meta_id).replace("{year}", cyclesYear),
        method: "GET",
    };

    try {
        SendRequest(
            requestOptions,
            (response) => {
                const { message, status } = response;

                if (status == 200) {
                    handleSuccess(response);
                    return;
                }

                handleError(message);
            },
            (error) => {
                handleError(error);
            },
        );
    } catch (e) {
        handleError(e);
    }
};

export default HandleFetchCyclesRequest;
