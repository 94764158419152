import React from "react";
import AssetInformationCertificatesAdvisory from "./AssetInformationCertificatesAdvisory";
import _ from "lodash";
import deepcopy from "deepcopy";
import BubbleList from "../../BubbleList";

export default class AssetInformationCertificatesAdvisorySingle extends AssetInformationCertificatesAdvisory {
    transformOutgoingData(certificate) {
        let result = deepcopy(certificate);
        result.advisory_code = [];

        if (
            result.advisory &&
            Array.isArray(result.advisory) &&
            !_.isEmpty(result.advisory)
        ) {
            result.advisory_code = this.getAdvisoryCode(result.advisory);
        }

        return result;
    }

    getCertifications() {
        const { certifications } = this.state;

        const { options } = this.props;

        const { countries } = options;

        const { internal_systems = {} } = options.certifications || {};

        const { regions = {} } = internal_systems;

        let result = [];

        Object.keys(regions).map((regionName) => {
            let region = countries.find((geo) => geo.text === regionName) || {};
            let regionSystems = regions[regionName];
            let regionAbleToSelect = false;
            let regionRatings = [];

            Object.keys(regionSystems).map((system) => {
                if (
                    regionSystems[system].ratings &&
                    Array.isArray(regionSystems[system].ratings)
                ) {
                    regionRatings = _.concat(
                        regionRatings,
                        regionSystems[system].ratings,
                    );
                }
            });

            const certWithRegionRating = certifications.find(
                (cert) =>
                    cert.country_code === region.value &&
                    regionRatings.includes(cert.rating) &&
                    !cert.deleted,
            );
            if (
                !certWithRegionRating ||
                (certWithRegionRating &&
                    certifications.indexOf(certWithRegionRating) ===
                        this.getEditCertificateIndex())
            ) {
                regionAbleToSelect = true;
            }

            if (regionAbleToSelect) {
                result.push({
                    ...region,
                    options: {
                        Rating: regionRatings,
                    },
                });
            }
        });

        return result;
    }

    updateRegionRatingRelatedList(props) {
        const { certificate = {} } = this.state;

        const { advisory = [] } = certificate;

        const { options } = this.props;

        const { internal_systems = {} } = options.certifications || {};

        const { advisory_groups = [] } = internal_systems;

        let result = [];

        advisory_groups.map((group) => {
            let isActiveGroup = true;
            group.advisory.map((item) => {
                if (advisory.includes(item.name)) {
                    isActiveGroup = false;
                }
            });

            if (isActiveGroup) {
                let groupListItem = {
                    text: group.type,
                    value: group.code,
                };

                if (group.advisory.length > 1) {
                    groupListItem.options = {
                        Advisory: group.advisory.map((item) => {
                            return { text: item.name, value: item.code };
                        }),
                    };
                }
                result.push(groupListItem);
            }
        });

        this.setState({
            advisoryList: result,
        });
    }

    getAdvisoryCode(value = []) {
        const { options } = this.props;

        const { certifications = {} } = options;

        const { internal_systems = {} } = certifications;

        const { advisory_groups = {} } = internal_systems;

        let result = [];

        advisory_groups.map((group) => {
            let groupAdvisory = group.advisory || [];
            value.map((certAdvisoryName) => {
                groupAdvisory.map((listAdvisory) => {
                    if (listAdvisory.name == certAdvisoryName) {
                        result.push(listAdvisory.code);
                    }
                });
            });
        });

        return result;
    }

    renderAdvisory(props) {
        const { styles = {} } = props;

        const { certificate = {}, advisoryList = [] } = this.state;

        const { rating, advisory = [] } = certificate;

        const disabled = !rating;
        return (
            <div>
                {!disabled && (
                    <BubbleList
                        label="Advisory"
                        name="advisory"
                        id="advisory"
                        inputId="advisory"
                        background="#0088b5"
                        color="white"
                        deleteWhite={true}
                        readOnly={disabled}
                        emptyPlaceholder={
                            rating ? "Start Typing Advisories" : "Select rating"
                        }
                        data={advisory}
                        handleFormChange={(items) =>
                            this.handleUpdateCertificateData("advisory", items)
                        }
                        datalistId={"advisory_list"}
                        datalist={advisoryList}
                        ranking={false}
                        limitResults={50}
                        dataListUseEnterButton={true}
                        filterStaticDataList={true}
                        openOnClick={true}
                        wrapperStyles={styles}
                        disabled={disabled}
                        dataListSegregationLines={true}
                        datalistMultiDimensional={true}
                        datalistMultiDimensionalUseSingle={true}
                    />
                )}
                {disabled && (
                    <div style={{ paddingTop: "1.5rem" }}>
                        <label
                            style={{
                                display: "block",
                                fontSize: "0.90625rem",
                                fontWeight: 500,
                                color: "rgba(110, 110, 110, 0.87)",
                            }}
                        >
                            Advisory
                        </label>

                        <p style={{ color: "#C7C7CD" }}>Select Rating</p>
                    </div>
                )}
            </div>
        );
    }
}
