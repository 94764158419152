import _ from "lodash";

const ConvertTextToMultiple = (props) => {
    const { text, items } = props;

    let result = text;
    let multiple = text + "s";

    if (!_.isEmpty(items)) {
        result = multiple;
    }

    if (Number.isInteger(items) && parseInt(items) > 1) {
        result = multiple;
    }

    return result;
};

export default ConvertTextToMultiple;
