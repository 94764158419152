import React from "react";
import GlobalPageContext from "./context";

export const useStore = () => React.useContext(GlobalPageContext);
export const StoreProvider = ({ children, initialState, reducer }) => {
    const [globalState, dispatch] = React.useReducer(reducer, initialState);

    return (
        <GlobalPageContext.Provider value={[globalState, dispatch]}>
            {children}
        </GlobalPageContext.Provider>
    );
};
