import { useGridApiContext } from "@mui/x-data-grid-pro";
import React from "react";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

export const CustomReorderButton = () => {
    const apiRef = useGridApiContext();
   
    
    const handleDragStart = React.useCallback(
        (row) => {
            const targetRow = apiRef.current.getRow(row.id)
            const mainDiv = document.querySelector(`[data-id="${targetRow.id}"]`);
            if (mainDiv) {
                mainDiv.style.backgroundColor = 'rgba(25, 118, 210, 0.08)'; 
            }
        },
        [apiRef],
    );

    const handleDragEnd = React.useCallback(
        (row) => {
            const targetRow = apiRef.current.getRow(row.id)
            const mainDiv = document.querySelector(`[data-id="${targetRow.id}"]`);
            if (mainDiv) {
                mainDiv.style.backgroundColor = 'transparent';
            }
        },
        [apiRef],
    );

    apiRef.current.subscribeEvent("rowDragStart", handleDragStart);
    apiRef.current.subscribeEvent("rowDragEnd", handleDragEnd); 

    return (
        <>
            <DragIndicatorIcon />
        </>
        
    );
};
