import DoubleColumnContentFieldInterface from '../../../Boilerplates/DoubleColumnContent/ContentField.interface'
import FieldInterface, { DataType, InputType } from '../../../../models/RecordBuilder/Field.interface'
import mapBooleanDisplayProperty from './mapBooleanDisplayProperty'
import _ from 'lodash'

const mapField = ({ initialField, value, dateFormat }: {
    initialField: FieldInterface
    value: any
    dateFormat?: string
}): DoubleColumnContentFieldInterface => {    
    const editable = _.get(initialField, "options.editable.enabled", false)

    let field: DoubleColumnContentFieldInterface = {
        value,
        name: initialField.displayName,
        key: initialField.key,
        editable: editable,
        required: false,
    }

    // Assign type property
    
    const inputType = _.get(initialField, "options.editable.inputType", false)
    field.type = inputType
        ? inputType.toLowerCase() 
        : initialField.dataType?.toLowerCase() || 'string'


    // Assign view type property
    if (initialField.dataType == DataType.Link) {
        field.viewType = "link";
    }

    // Map Boolean field option and value
    if (initialField.dataType == DataType.Boolean) {
        const mappedBooleanDisplayProperty = mapBooleanDisplayProperty({
            booleanDisplay: _.get(initialField, "options.editable.booleanDisplay", {}),
            value: !!field.value,
        })

        field.list = mappedBooleanDisplayProperty.list
        field.value = mappedBooleanDisplayProperty.value
    }

    // Map Datetime field
    if (inputType && dateFormat && (inputType == InputType.Date || inputType == InputType.DateTime)) {
        field.dateFormat = dateFormat;
    }

    // Handle Array DataType
    if (initialField.dataType == DataType.Array && !Array.isArray(field.value)) {
        field.value = [];
    }

    // Map optionManagedListProperty
    field.optionsManagedList = {
        enabled: _.get(initialField, "options.editable.optionsManagedList.enabled", false),
        key: _.get(initialField, "options.editable.optionsManagedList.key", null),
        textField: _.get(initialField, "options.editable.optionsManagedList.textField", 'text'),
        valueField: _.get(initialField, "options.editable.optionsManagedList.valueField", 'value'),
        dynamic: _.get(initialField, "options.editable.optionsManagedList.dynamic", false),
    }


    return field
}

export default mapField