import React from "react";
import { SectionInterface } from "./interface";
import ContentDeliveryPreview from "../../views/ContentDeliveryPreview";
import _ from "lodash";

const SectionDelivery = (props: SectionInterface) => {
    const { cmsData = {}, data = {} } = props;

    const { clientFeatures = {} } = cmsData;

    return (
        <ContentDeliveryPreview
            name="content-delivery-preview"
            className="meta-record-content-delivery-preview"
            title="Platform Content Delivery Preview"
            lastSection={true}
            data={data}
            cmsData={cmsData}
            clientFeatures={clientFeatures}
        />
    );
};

export default SectionDelivery;
