import React, { useState } from 'react'
import { observer } from "mobx-react";
import { useStores } from "../../../store/rootStore";
import Notification from "../../../../../../components/Notification";
import Settings from "../../../../../../components/Settings";
import Input from "../../../../../../components/Input/basic"
import AutocompleteInput from "../../../../../../components/AutocompleteInput";
import { CreditListInterface, EmptyObject } from "../../../credits.interface";
import { isEmpty } from "../../../utils/helpers"
import { TempMetaIdPrefix } from "../../../config";

const CreateOrUpdateForm: React.FC = observer(() => {
    const rootStore = useStores()
    const { creditListsStore } = rootStore || {}
    const creditListToEdit: CreditListInterface | EmptyObject = creditListsStore.getEditedList
    const creditsSource: string[] = creditListsStore.getCreditsSourceSelectOptions

    const {
        isDisabled,
        isNewList
    } = creditListsStore.getFormProperties

    const validation = creditListsStore.getFormValidation

    const [creditListName, setCreditListName] = useState<string>(!isEmpty(creditListToEdit) ? creditListToEdit.name : "")
    const [creditListCode, setCreditListCode] = useState<string>(!isEmpty(creditListToEdit) ? creditListToEdit.code : "")

    const newCreditList: CreditListInterface = {
        name: "",
        code: "",
        meta_id: ""
    }

    const handleFormElements = (field: string, value: string) => {
        let creditListObject: CreditListInterface | EmptyObject;

        if (!isEmpty(creditListToEdit)) {
            creditListObject = Object.assign({}, creditListToEdit)
        } else {
            creditListObject = Object.assign({}, newCreditList)
            creditListObject.meta_id = `${TempMetaIdPrefix}${(Math.floor(Math.random() * 100000000) + 1)}`
        }

        switch (field) {
            case 'name':
                setCreditListName(value)
                creditListsStore.validateFormInput(field, value)
                creditListsStore.validateFormInput("code", creditListCode)
                if (validation.name.valid) {
                    creditListObject.name = value
                } else {
                    creditListObject.name = creditListName;
                }
                break;

            case 'code':
                setCreditListCode(value)
                creditListsStore.validateFormInput(field, value)
                creditListsStore.validateFormInput("name", creditListName)

                if (validation.code.valid) {
                    creditListObject.code = value
                } else {
                    creditListObject.code = creditListCode
                }
                break;

            case 'creditsSource':
                creditListObject.creditsSource = value.split(' • ').pop()
                break;
        }

        creditListsStore.setEditedCreditList(creditListObject)
        creditListsStore.setFormProperty("isDisabled", !Object.values(validation).every(({ valid }) => valid === true))
    }

    const handleConfirm = () => {
        creditListsStore.validateFormInput("name", creditListName)
        creditListsStore.validateFormInput("code", creditListCode)

        if (Object.values(validation).every(({ valid }) => valid === true)) {
            const isNewCreditList = ((creditListToEdit as CreditListInterface).meta_id.includes(TempMetaIdPrefix) && isNewList)

            isNewCreditList ? creditListsStore.createCreditList() : creditListsStore.updateCreditList()
            creditListsStore.setFormStatus({
                isActive: false,
                isDisabled: true,
                isNewList: false,
                isDeleteForm: false
            })
        }
    }

    const handleCancelClick = () => {
        creditListsStore.setFormStatus({
            isActive: false,
            isDisabled: true,
            isNewList: false,
            isDeleteForm: false
        })

        creditListsStore.resetFormValidation()
        creditListsStore.setEditedCreditList({})
    }

    const renderFormElements = () => {
        return (
            <div className="update-create-credit-form">
                <div className="">
                    <Input
                        label="Credit List Name (80 Characters)"
                        placeholder="Enter Credit List Name"
                        value={creditListName}
                        id="create-new-credit-list--name"
                        className={validation.name.message ? "error-validation" : "credit-list-name"}
                        margin={"1.5rem 0 0"}
                        recordBasic={true}
                        inputDelay={200}
                        max={80}
                        handleChange={(value: string) => handleFormElements('name', value)}
                    />

                    {!validation.name.valid &&
                        <div className="error-message">
                            {validation.name.message}
                        </div>
                    }
                </div>

                <div className="form-half-content">
                    <div
                        className="credit-list-code"
                        style={
                            Object.assign({},
                                Settings.components.credits.item.double,
                                { paddingRight: '0.5rem' })
                        }>
                        <Input
                            label="Credit List Code (3 Characters)"
                            placeholder="Credit List Code"
                            defaultValue={creditListCode}
                            id="create-new-credit-list--code"
                            className={validation.code.message ? "error-validation" : "credit-list-code"}
                            margin="1.5rem 0 0"
                            recordBasic={true}
                            inputDelay={200}
                            maxLength="3"
                            handleChange={(value: string) => handleFormElements('code', value)}
                        />
                        {!validation.code.valid &&
                            <div className="error-message">
                                {validation.code.message}
                            </div>
                        }
                    </div>

                    {isNewList &&
                        <div
                            className="credit-list-from"
                            style={
                                Object.assign({},
                                    Settings.components.credits.item.double,
                                    { paddingLeft: '0.5rem' })
                            }>

                            <AutocompleteInput
                                label="Create Credit List From"
                                placeholder="Choose Credit List"
                                styles={{ marginTop: '1.5rem' }}
                                openOnClick={true}
                                keepSelected={true}
                                staticDatalist={creditsSource || []}
                                handleListSelection={(value: string) => handleFormElements('creditsSource', value)}
                            />
                        </div>
                    }
                </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            <Notification
                intercationStatus={"alert"}
                background={"white"}
                icon={`${Settings.images.path}/svg/alert.svg}`}
                disabled={isDisabled}
                title={!isNewList ? "Update Credit List" : "Add New Credit List"}
                html={renderFormElements()}
                confirm={handleConfirm}
                onClick={handleCancelClick}
            />
        </React.Fragment>
    )
})

export default CreateOrUpdateForm