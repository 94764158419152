import { createTheme, PaletteOptions } from "@mui/material/styles";

declare module "@mui/material/Button" {
    interface ButtonPropsVariantOverrides {
        ["view-errors"]: true;
        ["clear-errors"]: true;
    }
}

declare module "@mui/material/IconButton" {
    interface IconButtonPropsVariantOverrides {
        ["cancel"]: true;
    }
}

export const palette: PaletteOptions = {
    primary: {
        light: "#286CD2",
        main: "#2D5BB9",
        dark: "#002884",
        contrastText: "#fff",
    },
    secondary: {
        light: "#C04055",
        main: "#D6465E",
        dark: "#ba000d",
        contrastText: "#000",
    },
    common: {
        white: "#FFFFFF",
        green: "#5ACD32",
    },
};

export const theme = createTheme({
    palette,
    components: {
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            variants: [
                {
                    props: { variant: "view-errors" },
                    style: {
                        textTransform: "none",
                        padding: "0.375rem 1rem",
                        lineHeight: "1",
                        height: "2rem",
                        borderRadius: "0.25rem",
                        background: palette.secondary?.main,
                        "&:hover": {
                            background: palette.secondary?.light,
                        },
                    },
                },
                {
                    props: { variant: "clear-errors" },
                    style: {
                        textTransform: "none",
                        padding: "0.375rem 1rem",
                        lineHeight: "1",
                        height: "2rem",
                        background: palette.primary?.main,
                        borderRadius: "0.25rem",
                        "&:hover": {
                            background: palette.primary?.light,
                        },
                    },
                },
            ],
        },

        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    // Apply the CustomTooltip styling here
                    backgroundColor: "#F7F6F6",
                    boxShadow: "0rem 0.25rem 0.625rem 0rem #4A5A7126", // TODO:  add to pallete
                    minWidth: "31.25rem",
                    padding: 0,
                },
                arrow: {
                    backgroundColor: "none",
                    opacity: 0,
                    "&::before": {
                        backgroundColor: palette.common.white,
                        border: "0.0625rem solid #999", // TODO:  add to pallete
                    },
                },
            },
        },

        MuiTypography: {
            styleOverrides: {
                caption: {
                    flex: 1,
                    height: "100%",
                    paddingY: 0,
                    color: "rgba(47, 47, 47, 0.57)", // TODO:  add to pallete
                    fontSize: "0.75rem",
                },
                h5: {
                    padding: 0,
                    fontWeight: 500,
                    color: "rgba(47, 47, 47, 0.87)",
                    fontSize: "1rem",
                },
                h6: {
                    fontWeight: 500,
                    fontSize: "0.875rem",
                    color: "rgba(47, 47, 47, 0.87)",
                    marginY: 0,
                },
            },
        },

        MuiLink: {
            defaultProps: {
                target: "_blank",
            },
            styleOverrides: {
                root: {
                    // Add your custom Link styles here
                    textDecoration: "none",
                    fontSize: "0.875rem", // TODO:  add to spacing
                    fontWeight: 500,
                    marginY: 0,
                    "&:hover": {
                        textDecoration: "underline",
                        color: palette.primary?.light,
                    },
                },
            },
        },
    },
});
