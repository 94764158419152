import Prefixer from "inline-style-prefixer";

export default function styles() {
    let s = {
        container: {
            minHeight: "37.09375rem",
            height: "100%",
            background: "#f7f7f7",
            position: "relative",
            paddingBottom: "1rem",
        },
        contentContainer: {
            background: "rgba(0, 0, 0, 0.5)",
            position: "absolute",
            left: 0,
            top: 0,
            zIndex: 1,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: "1.3125rem",
            borderRadius: "0.625rem",
        },
        content: {
            position: "relative",
            zIndex: 0,
        },
        contentTags: {
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
        },
        contentPadding: {
            padding: "0 0.53125rem",
        },
        inlineTags: {
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
        },
    };

    const prefixer = new Prefixer();
    s = prefixer.prefix(s);

    return s;
}
