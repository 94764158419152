import React from "react";
import "./styles/_styles.scss";
import RecordBodySkeleton from "./RecordBodySkeleton";
import TabSectionSkeleton from "./TabSectionSkeleton";

type Props = {
    recordBody?: boolean;
    loading?: boolean;
};

const SkeletonLoader: React.FC<Props> = (props) => {
    const { recordBody = false, loading = false } = props;

    return (
        <div>
            {recordBody ? (
                <RecordBodySkeleton loading={loading} />
            ) : (
                <TabSectionSkeleton />
            )}
        </div>
    );
};

export default SkeletonLoader;
