import { SET_NEW_RECORD_EDIT } from "../actions/action_types";
import { defaultNewRecordEdit } from "../initialState";

export default function (
    state = { newRecordEdit: defaultNewRecordEdit },
    action,
) {
    switch (action.type) {
        case SET_NEW_RECORD_EDIT:
            return Object.assign({}, state, {
                newRecordEdit: action.newRecordEdit,
            });
    }

    return state;
}
