import React from "react";
import _ from "lodash";
import Index from "./Index";
import styles from "../styles";
import "../styles/_awsrekognition.scss";
import KeywordLabel from "../../KeywordLabel";
import Tag from "../../Tag";
import Settings from "../../Settings";
import AssetTrafficLight from "../../AssetTrafficLight";
import DoubleTransparentTag from "../../DoubleTransparentTag";
import TitleValue from "../../TitleValue";
import keywords from "../keywords";
import celebrities from "../celebrities";
import PlaceholderText from "../../PlaceholderText";

export default class AWSRekognition extends Index {
    constructor(props) {
        super(props);

        /**
         * @var object
         */
        this.copy = this.props.copy || {};

        /**
         * @var object
         */
        this.styles = styles(this.props);

        /**
         * @var string
         */
        this.tagFontSize = "0.78125rem";

        /**
         * @var string
         */
        this.grey = "#838383";

        /**
         * @var string
         */
        this.lightGrey = "#d9d9d9";

        /**
         * @var integer
         */
        this.fontWeight = 500;

        /**
         * @var string
         */
        this.marginRight = "0.59375rem";

        /**
         * @var object
         */
        this.state = {
            data: {},
        };
    }

    renderIcon(name) {
        return <img src={`${Settings.images.path}/svg/${name}`} />;
    }

    renderComplianceCode(compliance_code) {
        if (compliance_code) {
            return (
                <Tag
                    text={compliance_code}
                    className="meta-job-details--compliance-code"
                    styles={{
                        fontSize: "0.78125rem",
                        color: "#838383",
                        background: "transparent",
                        borderColor: "#838383",
                        border: "0.065rem solid",
                    }}
                />
            );
        }

        return;
    }

    renderDoubleTag(margin, className, text, value) {
        return (
            <DoubleTransparentTag
                textLeft={text}
                textRight={value || "-"}
                fontSize={this.tagFontSize}
                leftBorderColor={this.grey}
                leftBackgroundColor="transparent"
                leftColor={this.grey}
                rightBackgroundColor={this.grey}
                rightColor={this.lightGrey}
                margin={margin}
                fontWeight={this.fontWeight}
                className={className}
            />
        );
    }

    renderJobDetails() {
        const margin = "0 0.59375rem 0 0";

        return (
            <div style={this.styles.marginItem}>
                <h4 style={this.styles.section.subTitle}>
                    {this.copy.jobDetails || "Job Details"}
                </h4>

                <div className="meta-job-details">
                    <div className="meta-job-details--row parent">
                        {this.renderIcon("assets-video-grey.svg")}

                        <span className="meta-job-details--title">
                            Normalised
                        </span>

                        {this.renderComplianceCode("NORM")}

                        <div className="meta-job-details--parent-right-content">
                            {this.renderDoubleTag(
                                margin,
                                "meta-assets--video",
                                this.copy.video || "Video",
                                "ENG",
                            )}

                            {this.renderDoubleTag(
                                margin,
                                "meta-assets--audio",
                                this.copy.audio || "Audio",
                                "ENG",
                            )}

                            {this.renderDoubleTag(
                                margin,
                                "meta-assets--duration",
                                this.copy.duration || "Duration",
                                "00.01.03.04",
                            )}

                            {this.renderDoubleTag(
                                margin,
                                "meta-assets--duration",
                                "TMC MEDIA ID",
                                "SF00001544_NORM",
                            )}

                            <AssetTrafficLight status="placeholder created" />
                        </div>
                    </div>

                    <div className="meta-job-details--row child">
                        <TitleValue
                            title="Meta Asset ID"
                            value="AS00002547"
                            style={{
                                marginRight: "2.875rem",
                                width: "auto",
                            }}
                        />

                        <TitleValue
                            title="Published to AWS"
                            value="08:27, 4th April 2019"
                            style={{
                                marginRight: "2.875rem",
                                width: "auto",
                            }}
                        />

                        <TitleValue
                            title="User"
                            value="patrick.mccarthy@turner.com"
                            style={{
                                marginRight: "2.875rem",
                                width: "auto",
                            }}
                        />

                        <TitleValue
                            title="Attributes"
                            value="Keywords, Celebrities"
                            style={{
                                marginRight: "2.875rem",
                                width: "auto",
                            }}
                        />

                        <TitleValue
                            title="Filename"
                            value="SF00001544_NORM_META-PREREKOG.mp4"
                            style={{
                                width: "auto",
                            }}
                        />

                        <div className="meta-job-details--child-right-content">
                            <a
                                href="/assets/aws-rekognition/SF00001544_NORM_META-PREREKOG.js"
                                target="_blank"
                                download
                            >
                                {this.renderIcon("json-download-grey.svg")}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderKeywords() {
        return (
            <div style={this.styles.marginItem}>
                <h4 style={this.styles.section.subTitle}>
                    {this.copy.keywords || "Keywords"}
                </h4>

                {keywords.map((item, index) => {
                    return (
                        <KeywordLabel
                            title={item.keyword}
                            timestamp={item.timestamp}
                            weighting={item.confidence}
                            margin={this.styles.section.keywords.margin}
                            background="#B2D2FF"
                            weightingBackground="#74A6EE"
                            timestampBackground="#93BFFF"
                            color="#315284"
                            timestampColor="#354A68"
                            weightColor="#213B5E"
                            key={index}
                        />
                    );
                })}
            </div>
        );
    }

    renderCelebrities() {
        return (
            <div style={this.styles.marginItem}>
                <h4 style={this.styles.section.subTitle}>
                    {this.copy.celebrities || "Celebrities"}
                </h4>

                {celebrities.map((item, index) => {
                    return (
                        <KeywordLabel
                            title={item.name}
                            imdb={item.imdb}
                            weighting={item.confidence}
                            margin={this.styles.section.keywords.margin}
                            color="#8B6C1C"
                            background="#FFF9B6"
                            weightingBackground="#DBDBDB"
                            imdbBackground="#FEEF51"
                            weightColor="#5C5C5C"
                            key={index}
                        />
                    );
                })}
            </div>
        );
    }

    renderModeration() {
        return (
            <div style={this.styles.marginItem}>
                <h4 style={this.styles.section.subTitle}>
                    {this.copy.moderation || "Moderation"}
                </h4>

                <KeywordLabel
                    title="Sexual Activity"
                    timestamp="00:30"
                    weighting="53.55"
                    margin={this.styles.section.keywords.margin}
                    background="#FFA5A3"
                    weightingBackground="#EA625E"
                    timestampBackground="#FD7E7A"
                    color="#711E1C"
                    timestampColor="#6F1D1C"
                    weightColor="#461615"
                />

                <KeywordLabel
                    title="Female Swimwear Or Underwear"
                    timestamp="00:37"
                    weighting="70.82"
                    margin={this.styles.section.keywords.margin}
                    background="#FFA5A3"
                    weightingBackground="#EA625E"
                    timestampBackground="#FD7E7A"
                    color="#711E1C"
                    timestampColor="#6F1D1C"
                    weightColor="#461615"
                />
            </div>
        );
    }

    renderNoneAdded() {
        return false;
    }

    renderContent() {
        const { data = {} } = this.props;

        const { category, original_title } = data;

        if (
            category == "Short" &&
            original_title == "Jumanji: Welcome to the Jungle • Trailer"
        ) {
            return (
                <React.Fragment>
                    {this.renderJobDetails()}
                    {this.renderKeywords()}
                    {this.renderCelebrities()}
                    {this.renderModeration()}
                </React.Fragment>
            );
        }

        return <PlaceholderText styles={{ marginTop: "1.5rem" }} />;
    }
}
