export default function Attribute(domElement, attribute) {
    let result = false;

    if (typeof window !== "undefined") {
        if (document.body.contains(document.querySelector(domElement))) {
            let element = document.querySelector(domElement);

            if (element) {
                let attributeValue = element.getAttribute(attribute);

                if (attributeValue) {
                    result = attributeValue;
                }
            }
        }
    }

    return result;
}
