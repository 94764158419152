import React from "react";
import Parent from "./parent";
import _ from "lodash";
import "./styles/_scrollbar-visible.scss";

export default class Select extends Parent {
    componentDidMount() {
        this.checkValue();
        this.setSingleOnLoad();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setValue(this.props.value || "");
            this.checkValue();
        }

        if (!_.isEqual(this.props.data, prevProps.data)) {
            this.checkValue();
        }

        //this.setAutoWidth()
    }
}
