import deepcopy from "deepcopy";
import { defaultFilters } from "../../context/Collections/DefaultState";

const ApplySearchFilters = (props) => {
    const { item, state = {} } = props;

    const { set, activeSearchFilters } = state;

    const { name, checked } = item;

    let activeSearchFiltersState = deepcopy(activeSearchFilters);

    // Add
    if (checked) {
        activeSearchFiltersState.push(name);

        if (name == "all") {
            activeSearchFiltersState = [
                ...defaultFilters,
                'all'
            ]
        }
    }

    // Remove
    if (!checked) {
        activeSearchFiltersState = activeSearchFiltersState.filter(
            (item) => item !== name,
        );

        if (name == "all") {
            activeSearchFiltersState = []
        }
    }

    // Add Title Collections
    if (activeSearchFiltersState.length > 0 && !activeSearchFilters.includes("TitleCollections")) {
        activeSearchFiltersState.push("TitleCollections");
    }


    // Logic for All Filter
    if (activeSearchFiltersState.filter(item => item !== "all").length === defaultFilters.filter(item => item !== "all").length) {
        activeSearchFiltersState.push("all");
    } else {
        activeSearchFiltersState = activeSearchFiltersState.filter(item => item !== "all")
    }

    set({
        activeSearchFilters: activeSearchFiltersState,
    });
};

export default ApplySearchFilters;
