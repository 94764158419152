import { styled } from "@mui/material/styles"

export const StyledAwards = styled('div')({
    ".grid-cell--contributor": {
        alignItems: "center",
        display: "flex",
        flexWrap: "wrap",
    },
    ".item-contributor" : {
        color: "#959595",
        "&.active": {
            "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
            },
        },
    }
})