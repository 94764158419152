import React from "react";
import { SectionInterface } from "./interface";
import RelatedAssets from "../../views/RelatedAssets";
import AssetsData from "../../src/AssetsData";
import AssetsBuilder from "../../../Assets/builder";
import IsActiveRecordBuilder from "../../../RecordBuilder/IsActive";
import { ASSETS } from "../../../../models/RecordBuilder";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";
import { userHasPermission } from "../../../../services/PermissionService";
import { accessTypeStrictDelete } from "../../../../features/UserRoleManagement/utils/accessOptions";

const SectionRelatedAssets = (props: SectionInterface) => {
    const {
        handleFormChange,
        cmsData = {},
        data = {},
        userIsSuperAdmin,
        attributes = {},
        state = {},
    } = props;

    const { clientDataStructure = {}, clientFeatures = {} } = cmsData;
    const { userPermissions = [] } = state;

    const assetsData = AssetsData({
        data,
        clientFeatures,
    });

    if (IsActiveRecordBuilder({ page: ASSETS, clientFeatures })) {
        return (
            <AssetsBuilder
                {...getSectionCommonProps({ ...props, field: "assets" })}
                assets={assetsData}
                category={data.category}
            />
        );
    }

    return (
        <RelatedAssets
            {...getSectionCommonProps({ ...props, field: "assets" })}
            name="related-assets"
            className="meta-record-related-assets"
            title="Related Assets & Placeholders"
            clientFeatures={clientFeatures}
            parentData={data || {}}
            validationData={data || {}}
            meta_id={data.meta_id}
            origination={data.origination || {}}
            data={assetsData}
            canDeleteAllAssets={userIsSuperAdmin}
            assetResendWorkflowNotificationsUrl={
                attributes.assetResendWorkflowNotificationsUrl
            }
            handleMandatoryData={(data: any) =>
                handleFormChange("asset_mandatory", data)
            }
            dataStructure={clientDataStructure.assets}
            canDeleteCompliance={userHasPermission({
                cmsData,
                userPermissions,
                pathField: `${data.category}.tab.assets`,
                access: accessTypeStrictDelete,
                legacyPermission: "discovery.assets.deleteCompliance",
            })}
            cmsData={cmsData}
        />
    );
};

export default SectionRelatedAssets;
