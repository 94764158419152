import React from "react";

const BorderRadius = (props) => {
    const { children, styles = {} } = props;

    return (
        <div
            style={{
                borderRadius: "0.3125rem",
                overflow: "hidden",
                ...styles,
            }}
        >
            {children}
        </div>
    );
};

export default BorderRadius;
