const InceptionSony = [
    {
        meta_id: "AS00328817",
        international_id: "FF_INCEPTION0",
        asset_type: "Original Master",
        compliance_code: "ORIG",
        system_type: "Asset Management System",
        system_name: "Sony Media Backbone",
        system_environment: "Live",
        status: "placeholder created",
        eidr: "10.5240/2827-DD3F-EC00-DDC8-DBEF-H",
        video: "CAT",
        audio: "CAT",
        duration: "02.42.23.12",
        created_at: "05/07/2015 10:12:00",
        asset_id: "FF00029837",
        emea_id: "1987498",
        latam_id: "MOV00012390",
        apac_id: "MOV136544A/3883001",
        ibms_house_id: "FE018829388",
        media_id: "FF_INCEPTION0",
        filename: "FF_INCEPTION0_INT-ORIG_EN.mov",
        meta_users: [],
        image_width: "1920",
        image_height: "1080",
        color_encoding: "4:4:4",
        frame_rate: "23.976",
        stereoscopy: "Monoscopic",
        jpeg_200_profile: "2k IMF Single ML4 SL2 400 Mbit/s",
        colorimetry: "P3 D65 / SMPTE ST 2084 (PQ)",
        pixel_bit_depth: "12-bit",
        mdcv_metadata: "SMPTE ST-2086",
        dynamic_metadata_for_color_volume_transformation: "Dolby VisionTM",
        resolution: "1920x1080",
        fps: "25",
        audio_format: "stereo",
        bit_depth: "24-bit",
        sample_rate: "48 KHz",
        locale: "ENGLISH • ENG",
        language_code: "ENGLISH • ENG",
        media_url:
            "https://d1l6jr5rwjzctx.cloudfront.net/videos/FE00000010/Full%20Length%20Master%20%E2%80%A2%20English.mp4",
        email_addresses: [
            "robert.cranfield@warnermedia.com",
            "joanna.browne@warnermedia.com",
            "james.ash@warnermedia.com",
        ],
        certifications: [],
        alternate_language_audio_tracks: [
            {
                filename: "Inception_Theatrical-Edit_Alt-Audio_French-FRE.mxf",
                format: "STEREO",
                bit_depth: "24-bit",
                sample_rate: "48 KHz",
                language: "French",
                language_code: "FRE",
                placeholder: null,
            },
            {
                filename:
                    "Inception_Theatrical-Edit_Alt-Audio_German-de-DE.mxf",
                format: "5:1 SURROUND",
                bit_depth: "24-bit",
                sample_rate: "48 KHz",
                language: "German",
                language_code: "DEU",
                placeholder: null,
            },
            {
                filename:
                    "Inception_Theatrical-Edit_Alt_Latin-Spanish-es-419.mxf",
                format: "STEREO",
                bit_depth: "24-bit",
                sample_rate: "48 KHz",
                language: "Spanish",
                language_code: "SPA",
                placeholder: null,
            },
            {
                language: "Filipino",
                language_code: "FIL",
                placeholder: "PLACEHOLDER • 25.02.21",
                bgColor: "#d2d2d2",
            },
            {
                language: "Tagalog",
                language_code: "TAG",
                placeholder: "PLACEHOLDER • 25.02.21",
                bgColor: "#d2d2d2",
            },
        ],
        subtitle_tracks: [
            {
                language: "German • DEU",
                format: "SDH",
                standard: "WebVTT",
                placeholder: null,
            },
            {
                format: "CLOSED CAPTIONS",
                standard: "TTML",
                language: "JAPANESE • JAP",
                placeholder: null,
            },
            {
                format: "SDH",
                standard: "WebVTT",
                language: "FILIPINO • FIL",
                bgColor: "#d2d2d2",
                placeholder: "PLACEHOLDER • 25.02.21",
            },
            {
                format: "SDH",
                standard: "WebVTT",
                language: "TAGALOG • TAG",
                bgColor: "#d2d2d2",
                placeholder: "PLACEHOLDER • 25.02.21",
            },
        ],
        compliances: [
            {
                asset_type: "Normalised",
                system_type: "Asset Management System",
                system_name: "Sony DADC",
                system_environment: "Live",
                compliance_code: "NORM",
                status: "placeholder created",
            },
            {
                asset_type: "Middle East Pre-Watershed",
                system_type: "Asset Management System",
                system_name: "Sony DADC",
                system_environment: "Live",
                compliance_code: "MEPR",
                status: "placeholder created",
                meta_users: [],
                certifications: [
                    {
                        country_code: "MENA",
                        system: "Munich Internal",

                        rating: "15",
                        advisory: [
                            "Religious Beliefs and Practices",
                            "Religious Sensitivity",
                        ],
                    },
                ],
            },
            {
                meta_id: "AS00328332",
                asset_type: "Middle East Post-Watershed",
                international_id: "FF_INCEPTION0_MEPO",
                system_type: "Asset Management System",
                system_name: "Sony DADC",
                system_environment: "Live",
                compliance_code: "MEPO",
                status: "placeholder created",

                emea_id: "1987525",
                apac_id: "MOV136544A/3883001",
                latam_id: "MOV00012460",
                eidr: "10.5240/5C70-D261-D103-02EB-5D48-N",
                video: "ENG",
                audio: "ENG",
                ibms_house_id: "FE018829388",
                duration: "02.42.23.12",
                resolution: "1920x1080",
                fps: "25",
                audio_format: "stereo",
                bit_depth: "24-bit",
                sample_rate: "48 KHz",
                created_at: "12/05/2015 10:12:00",
                asset_id: "FF00029837",

                color_encoding: "4:4:4",
                frame_rate: "23.976",
                stereoscopy: "Monoscopic",
                jpeg_200_profile: "2k IMF Single ML4 SL2 400 Mbit/s",
                colorimetry: "P3 D65 / SMPTE ST 2084 (PQ)",
                pixel_bit_depth: "12-bit",
                mdcv_metadata: "SMPTE ST-2086",
                dynamic_metadata_for_color_volume_transformation:
                    "Dolby VisionTM",
                language_code: "ENGLISH • ENG",
                meta_users: [],
                certifications: [
                    {
                        country_code: "MENA",
                        system: "Munich Internal",
                        rating: "15",
                        advisory: [
                            "Religious Beliefs and Practices",
                            "Religious Sensitivity",
                        ],
                    },
                    {
                        country_code: "Greece",
                        system: "Munich Internal",

                        rating: "15",
                        advisory: [
                            "Religious Beliefs and Practices",
                            "Religious Sensitivity",
                        ],
                    },
                ],
                alternate_language_audio_tracks: [
                    {
                        filename:
                            "Inception_Theatrical-Edit_Alt-Audio_French-FRE.mxf",
                        format: "STEREO",
                        bit_depth: "24-bit",
                        sample_rate: "48 KHz",
                        language: "ARABIC • MACRO",
                        language_code: "ARB",
                        placeholder: null,
                    },
                    {
                        filename:
                            "Inception_Theatrical-Edit_Alt-Audio_French-FRE.mxf",
                        format: "STEREO",
                        bit_depth: "24-bit",
                        sample_rate: "48 KHz",
                        language: "GERMAN",
                        language_code: "DEU",
                        placeholder: null,
                    },
                    {
                        filename:
                            "Inception_Theatrical-Edit_Alt-Audio_French-FRE.mxf",
                        format: "STEREO",
                        bit_depth: "24-bit",
                        sample_rate: "48 KHz",
                        language: "SPANISH",
                        language_code: "SPA",
                        placeholder: null,
                    },
                ],
                subtitle_tracks: [
                    {
                        language: "ARABIC • MACRO • ARB",
                        format: "SDH",
                        standard: "WebVTT",
                        placeholder: null,
                    },
                    {
                        format: "CLOSED CAPTIONS",
                        standard: "TTML",
                        language: "ENGLISH • ENG",
                        placeholder: null,
                    },
                    {
                        format: "SDH",
                        standard: "WebVTT",
                        language: "ARABIC • MACRO • ARB",
                        bgColor: "#d2d2d2",
                        placeholder: "PLACEHOLDER • 18.01.21",
                    },
                ],

                email_addresses: [
                    "robert.cranfield@warnermedia.com",
                    "joanna.browne@warnermedia.com",
                    "james.ash@warnermedia.com",
                ],
            },
        ],
    },
    {
        international_id: "FF_INCEPTION0_ORIG",
        asset_type: "Alternate Language Master",
        compliance_code: "ORIG_FRE",
        system_type: "Asset Management System",
        system_name: "Sony Media Backbone",
        system_environment: "Live",
        status: "placeholder created",
        audio: "FRE",
        video: "FRE",
        media_id: "FF_INCEPTION0",
        filename: "FF_INCEPTION0_INT-ORIG_EN.mov",
        meta_users: [],
        certifications: [],
        language_code: "ENGLISH • ENG",
        compliances: [
            {
                asset_type: "Normalised",
                system_type: "Asset Management System",
                system_name: "Sony DADC",
                system_environment: "Live",
                compliance_code: "NORM",
                status: "placeholder created",
                meta_users: [],
            },
            {
                asset_type: "South East Asia • General Entertainment",
                system_type: "Asset Management System",
                system_name: "Sony DADC",
                system_environment: "Live",
                compliance_code: "MEPR",
                status: "placeholder created",
                meta_users: [],
            },
            {
                asset_type: "Northern Europe • General Entertainment",
                system_type: "Asset Management System",
                system_name: "Sony DADC",
                system_environment: "Live",
                compliance_code: "MEPO",
                status: "placeholder created",
                meta_users: [],
                certifications: [
                    {
                        country_code: "MYS",
                        system: "Munich Internal",
                        rating: "P13S",
                    },
                ],
            },
        ],
    },
    {
        meta_id: "AS00328817",
        international_id: "EF85-856A-06C7-BB7E-C1D7-L",
        asset_type: "Theatrical Master",
        compliance_code: "ORIG",
        system_type: "Asset Management System",
        system_name: "Prime Focus Clear",
        system_environment: "Live",
        status: "placeholder created",
        eidr: "10.5240/2827-DD3F-EC00-DDC8-DBEF-H",
        audio: "FRE",
        video: "FRE",
        duration: "02.42.23.12",
        created_at: "05/07/2015 10:12:00",
        asset_id: "FF00029837",
        emea_id: "1987498",
        latam_id: "MOV00012390",
        apac_id: "MOV136544A/3883001",
        ibms_house_id: "FE018829388",
        media_id: "FF_INCEPTION0",
        filename: "FF_INCEPTION0_INT-ORIG_EN.mov",
        meta_users: [],
        image_width: "1920",
        image_height: "1080",
        color_encoding: "4:4:4",
        frame_rate: "23.976",
        stereoscopy: "Monoscopic",
        jpeg_200_profile: "2k IMF Single ML4 SL2 400 Mbit/s",
        colorimetry: "P3 D65 / SMPTE ST 2084 (PQ)",
        pixel_bit_depth: "12-bit",
        mdcv_metadata: "SMPTE ST-2086",
        dynamic_metadata_for_color_volume_transformation: "Dolby VisionTM",
        resolution: "1920x1080",
        fps: "25",
        audio_format: "stereo",
        bit_depth: "24-bit",
        sample_rate: "48 KHz",
        locale: "ENGLISH • ENG",
        language_code: "ENGLISH • ENG",
        media_url:
            "https://d1l6jr5rwjzctx.cloudfront.net/videos/FE00000010/Full%20Length%20Theatrical%20%E2%80%A2%20English.mp4",
        email_addresses: [
            "robert.cranfield@warnermedia.com",
            "joanna.browne@warnermedia.com",
            "james.ash@warnermedia.com",
        ],
        certifications: [],
        compliances: [
            {
                asset_type: "Normalised",
                international_id: "FF_INCEPTION0_NORM",
                system_type: "Asset Management System",
                system_name: "Prime Focus Clear",
                system_environment: "Live",
                compliance_code: "NORM",
                status: "placeholder created",
            },
        ],
    },
];

export default InceptionSony;
