import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledIconButton = styled(IconButton)(() => ({
    "&:hover": {
        borderRadius: 2,
        backgroundColor: "#E0E0E0",
    },
    borderRadius: "0.25rem",
    margin: 0,
}));
StyledIconButton.defaultProps = {
    edge: "end",
    size: "small",
    disableFocusRipple: true,
};
