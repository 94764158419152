export const CMS_DATA: string = "cmsData";
export const OPTIONS: string = "options";
export const CLIENT_MANAGED_OPTIONS_VERSIONS: string = "clientManagedOptionsVersions";
export const CLIENT_MANAGED_OPTIONS_LANGUAGES: string = "clientManagedOptionsLanguages";
export const USER_ROLES: string = "userRoles";
export const ACCESS_CATALOGS: string = "accessCatalogs";
export const ACCESS_POLICY_CATALOGS: string = "accessPolicyCatalogs";
export const USER_PERMISSIONS: string = "userPermissions";
export const CONNECT_BRANDING: string = "connectBranding";
export const RECORD_BUILDER: string = "recordBuilder";
export const CLIENT_MANAGED_LISTS: string = 'clientManagedLists'


