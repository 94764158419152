import React from "react";
import { extendObservable } from "mobx";
import { AncillariesStore } from "./ancillariesStore";
import { CmsInterface } from "../../../components/Global/interfaces";
import { RootStoreInitializeInterface } from "../interfaces"
import { rootStoreInitialState } from "./initialState"

export class RootStore {
    rootStore: RootStore;
    ancillariesStore: AncillariesStore;
    cmsData: CmsInterface | {}

    constructor() {
        this.rootStore = this
        this.ancillariesStore = new AncillariesStore()

        extendObservable(this, rootStoreInitialState);
    }

    initialize({
        cmsData
    }: RootStoreInitializeInterface): void {
        this.setCmsData(cmsData)
    }

    setCmsData(cmsData: CmsInterface | {}): void {
        this.cmsData = cmsData;
    }
}

const StoresContext = React.createContext(new RootStore());
export const useStores = () => React.useContext(StoresContext);
