import React from "react";
import {
    GridFilterMenuItem,
    GridColumnMenuProps, 
    GridColumnMenuContainer, 
    SortGridMenuItems
} from "@mui/x-data-grid-pro";

export function CustomColumnMenu(props: GridColumnMenuProps) {
    const { hideMenu, currentColumn, color, ...other } = props;

    return (
        <GridColumnMenuContainer
            hideMenu={hideMenu}
            currentColumn={currentColumn}
            {...other}
        >
            {/* <SortGridMenuItems onClick={hideMenu} column={currentColumn!} /> */}
            <GridFilterMenuItem onClick={hideMenu} column={currentColumn!} />
        </GridColumnMenuContainer>
    );
}
