import _ from "lodash"

const GetContributorFullName = props => {
    const {
        contributor = {}
    } = props

    if (!_.isObject(contributor) || _.isEmpty(contributor)) return ''

    let name = `${contributor.first_name}`
    if (!_.isEmpty(contributor.middle_names)) name += ` ${contributor.middle_names}`
    if (!_.isEmpty(contributor.last_name)) name += ` ${contributor.last_name || ''}`
    name = name.trim()

    return name || ''
}

export default GetContributorFullName