import deepcopy from "deepcopy";
import { ValidationResult } from "./interfaces/Validation";
import DisabledRuleActive from "./rules/disabled";

export default function ValidateLinkedFranchise(
    result: ValidationResult,
    options: any,
): ValidationResult {
    let response: ValidationResult = deepcopy(result);

    const { data = {}, status, clientDataStructure = {} } = options;

    const franchiseValidation =
        clientDataStructure?.fieldValidation?.linkage?.franchise_collection;

    const {
        linkage = [],
        category = false,
        status: recordStatus = ''
    } = data;

    const { type } = linkage;

    if (
        DisabledRuleActive({
            recordStatus,
            category,
            type,
            dataStructure: clientDataStructure,
            fieldPath: "linkage.franchise_collection",
        })
    ) {
        return response;
    }

    if (
        (!linkage ||
            !linkage.some(
                (item) => item.category === "Franchise Collection",
            )) &&
        franchiseValidation
    ) {
        response.result = false;
        response.notification = {
            title: "Please check Linkage",
            description: "Record is not linked to a Franchise Collection",
            status,
        };

        response.validationKeys = result.validationKeys.concat([
            "franchise_collection",
        ]);
    }

    return response;
}
