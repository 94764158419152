import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { CoreTableCell } from "../../components/CoreTableCell";
import { CoreTableGridColDef } from "../../types";

export const ImageIdCol: CoreTableGridColDef = {
    headerName: "Image ID",
    field: "meta_id",
    flex: 1,
    renderCell: (params: GridRenderCellParams<string>) => {
        const { row } = params;

        return <CoreTableCell>{row.meta_id || ''}</CoreTableCell>;
    },
};
