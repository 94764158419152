import React from "react";
import _ from "lodash";
import styles from "../styles";
import Input from "../../Input/basic";
import KeywordLabel from "../../KeywordLabel";
import update from "react-addons-update";
import Index from "./Index";
import deepcopy from "deepcopy";
import { diff } from "deep-object-diff";
import DynamicInputs from "../../Input/DynamicInputs";

export default class Classification extends Index {
    constructor(props) {
        super(props);

        let data = this.props.data;

        /**
         * @var object
         */
        this.styles = styles(this.props);

        /**
         * @var object
         */
        this.state = {
            data,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        const nextPropsData = nextProps.data || {};
        const thisPropsData = this.props.data || {};
        const difference = diff(nextPropsData, thisPropsData);

        if (!_.isEmpty(difference)) {
            return true;
        }

        if (!_.isEqual(nextProps.disabled, this.props.disabled)) {
            return true;
        }

        if (!_.isEqual(nextState, this.state)) {
            return true;
        }

        return false;
    }

    handleChange(name, value) {
        let stateData = deepcopy(this.state.data || {});
        let result = { ...stateData };

        if (typeof name == "string") {
            if (typeof value == "string") {
                if (value.length == 0) {
                    value = null;
                }
            }

            result[name] = value;

            if (typeof this.props.handleFormChange == "function") {
                this.props.handleFormChange(result);
            }

            const newData = update(this.state.data, { $set: result });
            this.setData(newData);
        }
    }

    renderNoneAdded() {
        return false;
    }

    renderContent() {
        const { clientDataStructure = {}, category = "" } = this.props;

        const { classification = [] } = clientDataStructure;

        const data = deepcopy(this.state.data || {});
        const copy = this.props.copy || {};

        return (
            <React.Fragment>
                <div style={this.styles.marginItem}>
                    <h4 style={this.styles.section.subTitle}>
                        {copy.ageGuidance || "Age Guidance"}
                    </h4>

                    {this.props.disabled && (
                        <React.Fragment>
                            {classification
                                .filter((item) =>
                                    !item.categories
                                        ? true
                                        : item.categories.includes(category),
                                )
                                .map((item, index) => {
                                    return (
                                        <KeywordLabel
                                            title={item.label}
                                            weighting={
                                                data[item.key] || "None Entered"
                                            }
                                            margin={
                                                this.styles.section.keywords
                                                    .margin
                                            }
                                            background="#C3F7CF"
                                            weightingBackground="#87D999"
                                            color="#3A6845"
                                            key={index}
                                        />
                                    );
                                })}
                        </React.Fragment>
                    )}

                    {!this.props.disabled && (
                        <div style={this.styles.section.classification}>
                            {!_.isEmpty(classification) && (
                                <DynamicInputs
                                    options={classification}
                                    handleChange={(key, value) =>
                                        this.handleChange(key, value)
                                    }
                                    styles={{
                                        ...this.styles.flexMarginItem,
                                        marginLeft: 0,
                                    }}
                                    data={data}
                                    disabled={this.props.disabled}
                                    category={category}
                                />
                            )}
                        </div>
                    )}
                </div>
            </React.Fragment>
        );
    }
}
