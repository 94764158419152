import _ from "lodash";
import { CLIENT_MANAGED_LANGUAGES } from "./options";
import { GetFromLocalStorage } from "../Storage/localStorage";
import clientManagedController from "./clientManagedController";
import { isClientManagedLanguages } from "./languages";

export default function ClientManagedLanguages(props) {
    const { options = {}, fallback = [], clientFeatures = {}, listKey } = props;

    const local = GetFromLocalStorage(CLIENT_MANAGED_LANGUAGES);
    const isClientManaged = isClientManagedLanguages({
        clientFeatures,
        languageKey: listKey,
    });

    const languages = clientManagedController({
        clientManagedOptions: options.client_managed_languages || [],
        fallback,
        localStorage: JSON.parse(local),
        isClientManaged,
    });

    let result = fallback;

    if (isClientManaged) {
        result = transformScriptLanguages({
            languages,
            listKey,
        });
    }

    return result;
}

export function transformScriptLanguages(props) {
    const { languages = {}, listKey } = props;

    const { written_languages = [] } = languages;

    let writtenLanguages = written_languages[listKey] || [];
    return transformWrittenLanguages(writtenLanguages);
}

export function transformWrittenLanguages(languages) {
    if (_.isEmpty(languages) || !Array.isArray(languages)) {
        return [];
    }

    return languages.map((item) => {
        const {
            meta_code = "",
            text,
            name,
            language_name_script_example,
        } = item;

        return {
            text: `${text || name} ${language_name_script_example || ""}`,
            value: meta_code,
        };
    });
}
