import mapField from '../../../../components/RecordBuilder/map/doubleColumnContent/mapField'
import _ from "lodash";

interface PropsInterface {
    domain: any;
    dateFormat?: string;
}

export default function mapSections(
    props: PropsInterface,
): Array<any> {
    const { domain, dateFormat } = props;

    let sections: Array<any> = [];

    if (!_.isEmpty(domain.sections)) {
        domain.sections.forEach((section: any) => {
            let fields: any = section.fields.map((field: any) => {
                return mapField({
                    dateFormat,
                    initialField: field,
                    value: null
                })
            });

            sections.push({
                ...section,
                fields,
            });
        });
    }

    return sections;
}
