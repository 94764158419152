import deepcopy from "deepcopy";
import validateEidr from "../Helpers/validateEidr";

export default function ValidateEidr(result = {}, options = {}) {
    let response = deepcopy(result);

    const { data = {}, copy = {}, status } = options;

    const { eidr_id } = data;

    if (eidr_id && !validateEidr(eidr_id)) {
        response.result = false;
        response.notification = {
            title: copy.pleaseCheckEidr || "Please check EIDR",
            description:
                copy.eidrFormatIsNotCorrect || "EIDR format is not correct",
            status,
        };

        response.validationKeys = result.validationKeys.concat(["eidr_id"]);
    }

    return response;
}
