import deletedUser from "../../../../utils/deletedUser";
import unassignedUser from "../../../../utils/unassignedUser";
import { StandaloneTicket } from "../../../ticket/shared/validationSchema";

export const adaptFromBackend = (tickets: any[]): StandaloneTicket[] => {
    return tickets.map((t) => {
        const records =
            t?.records
                ?.map((r) => {
                    if (!r) return null;

                    return {
                        ...r,
                        id: String(r.id),
                    };
                })
                .filter((r) => r !== null) || [];

        return {
            id: t.id,
            name: t.name,
            ticketUuid: t.ticketUuid,
            createdAt: t.createdAt,
            updatedAt: t.updatedAt,
            author: t.author
                ? {
                      id: t.author.id,
                      name: t.author.name,
                      email: t.author.email,
                      accountStatus: t.author.accountStatus,
                  }
                : deletedUser,
            status: {
                label: t.status.label,
                value: t.status.id,
            },
            type: {
                label: t.type.label,
                value: t.type.id,
            },
            priority: {
                label: t.priority.label,
                value: t.priority.id,
            },
            assignee: t.assignee
                ? {
                      id: t.assignee.id,
                      name: t.assignee.name,
                      email: t.assignee.email,
                      accountStatus: t.assignee.accountStatus,
                  }
                : unassignedUser,
            records,
            description: t.description || null,
        };
    });
};
