import React from "react";
import { SectionInterface } from "./interface";
import Descriptions from "../../views/Descriptions";
import DescriptionsV2 from "../../views/DescriptionsV2";
import DescriptionsBuilder from "../../views/DescriptionsBuilder";
import { DESCRIPTIONS } from "../../../../models/RecordBuilder";
import IsActiveRecordBuilder from "../../../RecordBuilder/IsActive";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";

const SectionDescriptions = (props: SectionInterface) => {
    const { cmsData = {}, data = {}, handleOnBlur = () => {} } = props;

    const { clientDataStructure = {}, clientFeatures = {} } = cmsData;

    const { synopses = [], updating_logs = [], category } = data;

    function shouldHaveNoBorder(key) {
        let result = false;

        if (typeof data == "object") {
            if (data.category == "Game") {
                if (key == "descriptions") {
                    result = true;
                }
            }
        }

        return result;
    }

    const descriptionsProps = {
        ...getSectionCommonProps({ ...props, field: "synopses" }),
        name: "descriptions",
        className: "meta-record-descriptions",
        title: "Descriptions",
        data: synopses,
        recordData: data,
        category: category,
        updatingLogs: updating_logs,
        noBorder: () => shouldHaveNoBorder("descriptions"),
        clientFeatures,
        handleOnBlur: () => handleOnBlur(),
        clientDataStructure,
    };

    if (IsActiveRecordBuilder({ page: DESCRIPTIONS, clientFeatures})) {
        return <DescriptionsBuilder {...descriptionsProps} />;
    }

    if (!_.isEmpty(clientDataStructure.synopses)) {
        return <DescriptionsV2 {...descriptionsProps} />;
    }

    return <Descriptions {...descriptionsProps} />;
};

export default SectionDescriptions;
