import UrlParser from "../../components/UrlParser";

const HandleClearUrlSearch = (props) => {
    const { state = {}, location } = props;

    const { set, urlSearch } = state;

    if (typeof history == "object" && urlSearch) {
        history.pushState({}, "", "/" + location);
        const urlComponents = UrlParser(window.location);
        set({
            urlSearch: false,
            urlComponents,
        });
    }
};

export default HandleClearUrlSearch;
