import React, { Component } from "react";
import isIndexInArray from "../Helpers/isIndexInArray";
import Index from "./index";
import _ from "lodash";
import deepcopy from "deepcopy";

export default class SelectGroup extends Index {
    constructor(props) {
        super(props);

        /**
         * @var object
         */
        this.state = {
            value: this.props.value || "",
        };
    }

    checkValue() {
        let result = "disabled_value";
        let data = this.props.data;

        if (typeof this.props.data == "object" && !_.isEmpty(this.props.data)) {
            data = [];

            Object.keys(this.props.data).forEach((key) => {
                let item = this.props.data[key];

                if (Array.isArray(item)) {
                    data.push(...item);
                }
            });
        }

        if (Array.isArray(data)) {
            data.map((item) => {
                if (
                    typeof this.props.value == "object" &&
                    this.props.value &&
                    typeof item == "object"
                ) {
                    if (item.value == this.props.value.value) {
                        result = this.props.value.value;
                    }

                    if (item.text == this.props.value.text) {
                        result = this.props.value.text;
                    }
                }

                if (
                    typeof this.props.value == "string" ||
                    Number.isInteger(this.props.value)
                ) {
                    if (item.text == this.props.value) {
                        result = this.props.value;
                    }

                    if (item.value == this.props.value) {
                        result = this.props.value;
                    }

                    if (_.isEqual(item, this.props.value)) {
                        result = this.props.value;
                    }
                }
            });
        }

        this.setValue(result);
    }

    renderOptions() {
        const {
            data,
            filterArray,
            disabledOptions,
            disabledOptionException,
            checkedKeys,
        } = this.props;

        let optionGroups = false;
        let filteredData = data;

        if (Array.isArray(filterArray)) {
            if (typeof data == "object") {
                filteredData = {};

                Object.keys(data).forEach((key) => {
                    filteredData[key] = [];

                    if (Array.isArray(data[key])) {
                        data[key].forEach((dataItem, index) => {
                            // Check if key already selected
                            if (typeof dataItem == "object") {
                                if (
                                    !isIndexInArray(
                                        filterArray,
                                        dataItem.value || dataItem.text,
                                    )
                                ) {
                                    filteredData[key].push(dataItem);
                                }
                            }

                            // Delete empty keys
                            if (
                                index == data[key].length - 1 &&
                                _.isEmpty(filteredData[key])
                            ) {
                                delete filteredData[key];
                            }
                        });
                    }
                });
            }
        }

        if (typeof filteredData == "object" && !_.isEmpty(filteredData)) {
            optionGroups = [];
            Object.keys(filteredData).forEach((key) => optionGroups.push(key));
        }

        const RenderGroupItems = (groupProps) => {
            if (Array.isArray(groupProps.data)) {
                return (
                    <React.Fragment>
                        {groupProps.data.map((option, index) => {
                            const values = this.getOptionValues(option);
                            let disabled = disabledOptions;

                            if (disabledOptionException == values.value) {
                                disabled = false;
                            }

                            let text = values.text;
                            let checkedKey = false;

                            if (checkedKeys) {
                                if (
                                    !_.isEmpty(checkedKeys) &&
                                    isIndexInArray(checkedKeys, values.value)
                                ) {
                                    checkedKey = true;
                                }
                            }

                            const optionProps = {
                                key: index,
                                value: values.value,
                                disabled,
                            };

                            return this.renderOptionText({
                                checkedKeys,
                                checkedKey,
                                text,
                                optionProps,
                            });
                        })}
                    </React.Fragment>
                );
            }

            return false;
        };

        // Grouped
        if (Array.isArray(optionGroups)) {
            return (
                <React.Fragment>
                    {optionGroups.map((item, index) => {
                        return (
                            <optgroup label={item} key={index}>
                                <RenderGroupItems data={filteredData[item]} />
                            </optgroup>
                        );
                    })}
                </React.Fragment>
            );
        }

        return false;
    }
}
