import DefaultClientPermissions from "../DefaultClientPermissions";
import _ from "lodash";

export default function ClientPermissions(roles) {
    const {
        Role_RegionalEditor,
        Role_PlatformAdmin,
        Role_SuperAdmin,
        Role_Admin,
    } = roles;

    let permissions = DefaultClientPermissions(roles);

    permissions.collections.title.create = _.concat(
        permissions.collections.title.create,
        [Role_RegionalEditor],
    );

    permissions.collections.report.create = _.concat(
        permissions.collections.report.create,
        [Role_RegionalEditor],
    );

    permissions.reporting.looker = [
        Role_SuperAdmin,
        Role_PlatformAdmin,
        Role_RegionalEditor,
        Role_Admin,
    ];

    permissions.dataIngest.view = _.concat(permissions.dataIngest.view, [
        Role_Admin,
    ]);

    permissions.platformManagement = _.concat(permissions.platformManagement, [
        Role_Admin,
    ]);

    permissions.discovery.delete = _.concat(permissions.discovery.delete, [
        Role_PlatformAdmin,
    ]);

    return permissions;
}
