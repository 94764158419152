import SendRequest from "../../../components/SendRequest";
import GetApiUrls from "../GetApiUrls";
import HandleRequestError from "../HandleRequestError";

const HandleSwimlaneDelete = (props) => {
    const { state = {}, options } = props;

    const { swimlane, meta_id, handleError, handleSuccess } = options;

    const apiUrls = GetApiUrls(state);

    const url = apiUrls.swimlaneDelete
        .replace("{meta_id}", swimlane.meta_id)
        .replace("{lineup_meta_id}", meta_id);

    const requestOptions = {
        url,
        method: "DELETE",
        body: swimlane,
    };

    try {
        SendRequest(
            requestOptions,
            (response) => {
                const { message, status } = response;

                if (status == 200) {
                    handleSuccess();
                    return;
                }

                handleError(message);
            },
            (error) => {
                handleError(error);
            },
        );
    } catch (e) {
        handleError(e);
    }
};

export default HandleSwimlaneDelete;
