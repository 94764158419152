import _ from "lodash";
import { LanguageList } from "../types/OriginalAudioLanguage/LanguageList";
import { OriginalAudioLanguageHandlerProps } from "../types/OriginalAudioLanguage/OriginalAudioLanguageHandlerProps";
import { OriginalAudioLanguage } from "../types/OriginalAudioLanguage/OriginalAudioLanguage";
import LanguageListData from "../../Testing/LanguageListData";
import { envTest } from "../../Environment";

function OriginalAudioLanguageHandler(
    props: OriginalAudioLanguageHandlerProps,
): OriginalAudioLanguage {
    const { languagesList, language: text = "" } = props;

    let locale: string | null = null;
    const list: LanguageList[] = envTest ? LanguageListData : languagesList;

    if (!_.isEmpty(list) && !_.isEmpty(text)) {
        const languageFound: LanguageList | undefined = list.find(
            (language) => language.name.toLowerCase() == text.toLowerCase(),
        );
        if (languageFound !== undefined && !_.isEmpty(languageFound)) {
            locale = languageFound.meta_code;
        }
    }

    return {
        original_audio_language: locale,
        original_audio_language_transformed: {
            text,
            isValid: !_.isEmpty(locale) ? true : false,
        },
    };
}

export default OriginalAudioLanguageHandler;
