import React, { Component, Fragment } from "react";
import _ from "lodash";
import stylesv2 from "../styles/v2";
import "../styles/_styles_v4.scss";
import ReactSVG from "react-svg";
import Settings from "../../../components/Settings";
import ProgressIndicator from "../../../components/ProgressIndicator";
import AuthService from "../../../services/AuthService";
import GetHref from "./Hrefs";
import $ from "jquery";
import { Link } from "react-router-dom";
import { navMainMenu, navItemBack } from "./NavConfig";
import { diff } from "deep-object-diff";
import EclipseString from "../../../components/Helpers/EclipseString";
import { VersionDescription } from "./VersionDescription";
import {
    featureUserRoleManagement,
    userHasPermission,
} from "../../../services/PermissionService";
import { accessTypeView } from "../../../features/UserRoleManagement/utils/accessOptions";
import { policyTypeServiceDesk } from "../../../features/UserRoleManagement/utils/accessOptions";
import {
    BackgroundProcessingMain,
    BackgroundProcessingVariants,
} from "../../../features/TaskManager/background-processing/BackgroundProcessing.main";

export default class NavLegacy extends Component {
    constructor(props) {
        super(props);

        this.maxWidth = "3.25rem";
        this.maxItemLabelLength = 16;
        this.state = {
            subMenuItem: {},
            navOpen: false,
            userPermissionsMenuSet: false,
        };
    }

    componentWillMount() {
        const { cmsData = {} } = this.props;

        this.userRoleManagement = featureUserRoleManagement({ cmsData });

        this.setFeaturesAndStyles();
        this.setTooltip();
        this.setHrefs();
        this.getMenuActiveItemConfig(this.props.active || "");
    }

    componentDidMount() {
        this.fadeInIcons();
    }

    componentDidUpdate(prevProps) {
        const thisGlobalState = this.props.globalState;
        const prevGlobalState = prevProps.globalState;

        if (
            !_.isEqual(
                thisGlobalState.userPermissions,
                prevGlobalState.userPermissions,
            ) &&
            !this.state.userPermissionsMenuSet
        ) {
            this.getMenuActiveItemConfig(this.props.active || "");
            this.fadeInIcons();

            this.setState({
                userPermissionsMenuSet: true,
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const nextPropsData = nextProps || {};
        const thisPropsData = this.props || {};

        const nextStateData = nextState || {};
        const thisStatesData = this.state || {};

        const difference = diff(nextPropsData, thisPropsData);
        const differenceState = diff(nextStateData, thisStatesData);

        if (!_.isEmpty(difference)) {
            return true;
        }

        if (!_.isEmpty(differenceState)) {
            return true;
        }

        return false;
    }

    setFeaturesAndStyles() {
        const {
            connect,
            reporting = {},
            connectManager,
            localisationCollections,
            userRoleManagement,
            recordBuilder,
            preferences,
            taskManager,
            advancedSearch,
            legacyDiscovery,
            advancedSearchCollections,
        } = this.getCmsFeatures();

        const { looker, explore, subMenu } = reporting;

        this.features = {
            looker,
            explore,
            connectManager,
            connect,
            localisationCollections,
            recordBuilder,
            userRoleManagement,
            preferences,
            taskManager,
            subMenu,
            advancedSearch,
            legacyDiscovery,
            advancedSearchCollections,
        };

        this.styles = stylesv2();
    }

    getFilteredMenu() {
        const { active } = this.props;

        const { globalState = {} } = this.props;

        const { userPermissions = [], cmsData = {} } = globalState;

        let menu = navMainMenu(active, this.features).filter(
            (item) => !_.isEmpty(item),
        );

        if (this.userRoleManagement) {
            menu = menu.filter((item) => {
                if (!item.policyPermission) {
                    return true;
                }

                return userHasPermission({
                    cmsData,
                    userPermissions,
                    pathField: item.policyPermission.pathField,
                    access: accessTypeView,
                });
            });
        }

        return menu;
    }

    getClientData() {
        const { cmsData = {} } = this.props;

        const { clientData = {} } = cmsData;

        return clientData;
    }

    getUserFeatures() {
        const { cmsData = {} } = this.props;

        const { userFeatures = {} } = cmsData;

        return userFeatures;
    }

    getCmsFeatures() {
        const { cmsData = {} } = this.props;

        const { clientFeatures = {} } = cmsData;

        return clientFeatures;
    }

    setHrefs() {
        this.hrefs = GetHref({
            ...this.props,
        });
    }

    setTooltip() {
        const getSuccess = (text) => {
            return {
                text,
                type: "success",
            };
        };

        const getError = (text) => {
            return {
                text,
                type: "error",
            };
        };

        this.tooltip = {
            recordsSearch: getSuccess("Record Search"),
            discovery: getSuccess("Discovery"),
            account: getSuccess("User Account"),
            platformManagement: getSuccess("Platform Management"),
            connectManager: getSuccess("Connect Manager"),
            connectUsergroup: getSuccess("Connect User Group Management"),
            reporting: getSuccess("Reporting"),
            localisations: getSuccess("Localisations"),
            collections: getSuccess("Title Collections"),
            connectCollections: getSuccess("Connect Collections"),
            logout: getError("End Session"),
            addRecord: getSuccess("Add new record"),
            addCollection: getSuccess("Add new collection"),
            help: getSuccess("Support"),
            reportingLooker: getSuccess("Quick View"),
            lineupManager: getSuccess("Lineup Manager"),
            reportingExplore: getSuccess("Explore Catalogue"),
            recordBuilder: getSuccess("Record Builder"),
            preferences: getSuccess("Preferences"),
            taskManager: getSuccess("Task Manager"),
        };
    }

    getLink(name) {
        let link = "";
        const href = this.hrefs;

        if (href[name]) {
            if (href[name].href) {
                link = href[name].href;
            }
        }

        return link;
    }

    fadeInIcons() {
        $("div.icon-text-container")
            .css("display", "flex")
            .hide()
            .fadeIn(600, () => {
                $("div.icon-text-container").addClass("visible");
            });
    }

    handleNavBarAnimation(type, navType = "v4") {
        $(`div.meta-nav-${navType}`).stop();

        if (type == "out") {
            $(`div.nav-section`).css({
                opacity: "1",
                height: "auto",
                padding: "1rem 0",
            });

            return $(`div.meta-nav-${navType}`).animate(
                {
                    maxWidth: "12.5rem",
                },
                {
                    duration: 200,
                    start: function () {
                        $("div.icon-text").addClass("visible");
                        $(`div.meta-nav-${navType}`).addClass("shadow");
                    },
                    always: function () {
                        $(`div.meta-nav-${navType}`).css({
                            overflowY: "visible",
                        });
                    },
                },
            );
        }

        $(`div.meta-nav-${navType}`).css({ overflowY: "scroll" });
        $(`div.meta-nav-${navType}`).animate(
            {
                maxWidth: this.maxWidth,
            },
            {
                duration: 200,
                start: function () {
                    $(`div.nav-section`).animate({
                        opacity: 0,
                        height: "0px",
                        padding: 0,
                    });

                    $(`div.meta-nav-${navType}`).removeClass("shadow");
                    $("div.icon-text").removeClass("visible");
                },
            },
        );
    }

    getLinkUrl(link) {
        let windowLocation = window && window.location ? window.location : "";
        return !_.isEmpty(link) ? link : windowLocation;
    }

    renderSectionDivider() {
        return <div className="nav-section-divider"></div>;
    }

    renderMenuSection({ section = "", items = [] }) {
        return (
            <div>
                <div className="nav-section">{section}</div>
                {items.map((item) => {
                    return this.renderIconWithText(item);
                })}
            </div>
        );
    }

    renderIconWithText(props) {
        const {
            item = "",
            icon,
            text = "",
            description,
            permission,
            customClass = "",
            secondIcon = "",
            subMenu = [],
            external,
            iconTypeBasic
        } = props;

        const {
            handleMouseEnterIcon = () => {},
            handleMouseLeaveIcon = () => {},
        } = this.props;

        if (
            !this.userRoleManagement &&
            permission &&
            !AuthService.hasPermission(permission)
        ) {
            return false;
        }

        const link = this.getLink(item);

        const overflow =
            text.length > this.maxItemLabelLength
                ? {
                      full: text,
                      short: EclipseString({
                          string: text,
                          length: this.maxItemLabelLength,
                      }),
                  }
                : null;

        const renderTextIconBlock = () => {
            return (
                <div
                    className={`icon-text-container ${customClass} ${item}-container`}
                    onMouseEnter={handleMouseEnterIcon.bind(
                        this,
                        item,
                        "",
                        overflow,
                    )}
                    onMouseLeave={handleMouseLeaveIcon.bind(
                        this,
                        item,
                        "",
                        overflow,
                    )}
                    onClick={() => this.handleOpenSubMenuClick(props)}
                >
                    <div className="svg-container">
                        {!iconTypeBasic &&
                           this.renderSVG(item, icon, "", true)
                        }

                        {iconTypeBasic &&
                            this.renderBasicSVG(item, icon)
                        }
                    </div>

                    <div>
                        <div className="icon-text">
                            {EclipseString({
                                string: text,
                                length: this.maxItemLabelLength,
                            })}
                            {secondIcon &&
                                this.renderSVG(
                                    item,
                                    secondIcon,
                                    "",
                                    true,
                                    true,
                                )}
                        </div>

                        {description && (
                            <VersionDescription description={description} />
                        )}
                    </div>
                </div>
            );
        };

        const renderLinks = () => {
            const linkProps = this.getLinkProps(item);

            const linkUrl = this.getLinkUrl(link);

            if (external) {
                return (
                    <a href={linkProps.href} target="_blank">
                        {renderTextIconBlock()}
                    </a>
                );
            }

            return (
                <Link to={linkUrl} {...linkProps}>
                    {renderTextIconBlock()}
                </Link>
            );
        };

        return (
            <React.Fragment key={`sub-links-${item}`}>
                {_.isEmpty(subMenu) && renderLinks()}
                {!_.isEmpty(subMenu) && renderTextIconBlock()}
            </React.Fragment>
        );
    }

    handleOpenSubMenuClick(props) {
        const { subMenu = [], item = "" } = props;

        if (item === navItemBack.item) {
            this.setState({ subMenuItem: {} }, () => {
                this.fadeInIcons();
                this.handleNavBarAnimation("out", "v4");
            });
            return;
        }

        if (_.isEmpty(subMenu)) {
            return;
        }

        this.setState({ subMenuItem: props }, () => {
            this.fadeInIcons();
            this.handleNavBarAnimation("out", "v4");
        });
    }

    getHrefItem(name) {
        let hrefItem = this.hrefs;
        return hrefItem[name] || {};
    }

    getLinkProps(name) {
        const { handleMenuClick } = this.props;

        const hrefItem = this.getHrefItem(name);

        let linkProps = {};
        if (!hrefItem.link && !linkProps.external) {
            linkProps = {
                onClick: (e) => handleMenuClick(hrefItem.href, e),
                ...hrefItem,
            };
        }

        return linkProps;
    }

    renderSVG(name, icon, strokeName, blankTag, isSecondIcon = false) {
        const { setActiveColor, pendingUser, active } = this.props;

        const tooltip = this.tooltip;
        const tooltipItem = tooltip[name] || {};

        const isActive = active == name;
        const link = this.getLink(name);

        const RenderSVGContent = () => (
            <Fragment key={`svg-content-${name}`}>
                <div
                    className={`${name}-placeholder ${
                        isActive ? "icon-placeholder-large" : `icon-placeholder`
                    }`}
                    style={this.styles.iconPlaceholder}
                ></div>

                {process.env.NODE_ENV !== "test" && (
                    <ReactSVG
                        path={`${Settings.images.path}/svg/${icon}`}
                        className={`${isActive ? "icon-large" : `icon-svg`} ${
                            isSecondIcon
                                ? `${name}-second-icon second-icon-svg`
                                : ""
                        }`}
                        callback={(svg) => setActiveColor(name, strokeName)}
                    />
                )}
            </Fragment>
        );

        return (
            <div
                className={"icon-container"}
                data-tip={tooltipItem.text}
                data-type={tooltipItem.type}
            >
                {blankTag && <RenderSVGContent />}

                {!blankTag && (
                    <Link
                        to={this.getLinkUrl(link)}
                        {...this.getLinkProps(name)}
                    >
                        <RenderSVGContent />
                    </Link>
                )}

                {name == "platformManagement" && pendingUser && (
                    <span
                        className="pending"
                        style={this.styles.notification}
                    ></span>
                )}
            </div>
        );
    }

    renderBasicSVG(name, icon) {
        const RenderSVGContent = () => (
            <Fragment key={`svg-content-${name}`}>
                {process.env.NODE_ENV !== "test" && (
                    <img
                        src={`${Settings.images.path}/svg/${icon}`}
                        className={`icon-svg`}
                    />
                )}
            </Fragment>
        );

        return (
            <div
                className={"icon-container"}>

                <RenderSVGContent />
            </div>
        );
    }

    renderDesignV4Nav() {
        return this.renderNavBoilerplateV4(this.getContent());
    }

    getContent() {
        const { subMenuItem = {} } = this.state;
        const { subMenu = [] } = subMenuItem;

        return (
            <React.Fragment key={`get-content`}>
                {!_.isEmpty(subMenu)
                    ? this.renderSubMenuItems()
                    : this.renderMenuItems()}
                {this.getBottomNavContent()}
            </React.Fragment>
        );
    }

    renderSubMenuItems() {
        const { subMenuItem } = this.state;

        const { subMenu = [] } = subMenuItem;

        return (
            <React.Fragment key="sub-menu-items">
                {this.renderGoBackItem()}
                {subMenu.map((section, index) => {
                    return (
                        <React.Fragment key={`section-sub-index-${index}`}>
                            {this.renderSectionDivider()}
                            {this.renderMenuSection(section)}
                        </React.Fragment>
                    );
                })}
            </React.Fragment>
        );
    }

    renderGoBackItem() {
        const { navOpen, subMenuItem } = this.state;
        const { icon } = subMenuItem;
        let itemObject = Object.assign({}, { ...navItemBack });

        if (!navOpen) {
            itemObject.icon = icon;
        }

        return (
            <Fragment key="go-back">
                {this.renderIconWithText(itemObject)}
            </Fragment>
        );
    }

    renderMenuItems() {
        let menu = this.getFilteredMenu();

        return (
            <React.Fragment key="render-menu-items">
                {menu.map((item, index) => {
                    let content = <React.Fragment key={`menu-item-${index}`} />;
                    if (
                        !item.featureToggle ||
                        (this.features[item.featureToggle] &&
                            !item.counterToggle)
                    ) {
                        content = (
                            <Fragment key={`menu-it-${index}`}>
                                {this.renderIconWithText(item)}
                            </Fragment>
                        );
                    }

                    return content;
                })}
            </React.Fragment>
        );
    }

    getMenuActiveItemConfig(active) {
        let isMainItem = this.getFilteredMenu().find(
            (item) => item.item == active,
        );

        let parentItem = {};

        const fromSubMenu = (menuItem) => {
            if (menuItem.item == active) {
                return menuItem;
            }

            const sections = _.get(menuItem, "subMenu", []);
            if (!_.isEmpty(sections)) {
                let subMenuItem = {};
                sections.map((section) => {
                    subMenuItem =
                        section.items.find((item) => item.item == active) ||
                        subMenuItem;
                });

                return subMenuItem;
            }

            return false;
        };

        if (!isMainItem) {
            this.getFilteredMenu().map((item) => {
                const fromSubMenuResult = fromSubMenu(item);
                parentItem = fromSubMenuResult ? item : parentItem;
            });
        }

        if (!_.isEmpty(parentItem)) {
            this.setState({ subMenuItem: parentItem });
        }

        return;
    }

    getBottomNavContent() {
        const { 
            clientName,
            fabricIconVariantOne
        } = this.getClientData();

        const { globalState = {} } = this.props;

        const { userPermissions = [], cmsData = {} } = globalState;

        const { appVersion, apiUrls = {}, clientFeatures = {} } = cmsData;

        const { 
            enableKnowledgeBase = false,
            appEnvInNav = true 
        } = clientFeatures;

        const { knowledgeBaseAuthenticatedUrl = "" } = apiUrls;

        const supportPermission = userHasPermission({
            cmsData,
            userPermissions,
            pathField: policyTypeServiceDesk,
            access: accessTypeView,
        });
        
        const clientNameVersionText = appEnvInNav 
            ? `${clientName || ""} ${process.env.APP_ENV || ""}`
            : `${clientName || ""}` 
        
        let navItems = [
            {
                item: "logout",
                icon: "logout-grey-v2.svg",
                text: "Logout",
            },
            {
                item: "logo",
                icon: fabricIconVariantOne,
                iconTypeBasic: true,
                text: clientNameVersionText,
                description: `Version ${process.env.APP_VERSION || appVersion}`,
                customClass: "logo",
            },
        ];

        if (supportPermission) {
            navItems.unshift({
                item: "help",
                icon: "service-desk-grey-v2.svg",
                text: "Service Desk",
                secondIcon: "external-link-grey.svg",
                external: true,
            });
        }

        if (enableKnowledgeBase && knowledgeBaseAuthenticatedUrl) {
            navItems.unshift({
                item: "knowledge",
                icon: "knowledgebase-grey.svg",
                text: "Knowledge Base",
                secondIcon: "external-link-grey.svg",
                external: true,
            });
        }

        return (
            <div className="bottom-container">
                {this.renderProgressIndicator()}
                {this.renderRecordsProcessing()}

                {navItems.map((item) => this.renderIconWithText(item))}
            </div>
        );
    }

    renderNavBoilerplateV4(content) {
        return (
            <div
                className="meta-nav-v4"
                onMouseEnter={() => {
                    this.setState({ navOpen: true });
                    this.handleNavBarAnimation("out", "v4");
                }}
                onMouseLeave={() => {
                    this.setState({ navOpen: false });
                    this.handleNavBarAnimation("in", "v4");
                }}
            >
                {content}
            </div>
        );
    }

    renderProgressIndicator(original) {
        const { processIndicator, progressGetUrl, handleMessageResponse } =
            this.props;

        if (!processIndicator) {
            return false;
        }

        let styles = {
            width: "1.6875rem",
            margin: "1rem auto",
        };

        if (!original) {
            styles = {
                width: "1.95rem",
                margin: "1rem auto",
                marginLeft: "0.28rem",
            };
        }

        return (
            <div
                className={
                    original ? "icon-container" : "icon-text-container divider"
                }
            >
                <div className="svg-container">
                    <ProgressIndicator
                        progressGetUrl={progressGetUrl}
                        handleMessageResponse={handleMessageResponse}
                        strokeWidth={10}
                        styles={styles}
                    />
                </div>
            </div>
        );
    }

    renderRecordsProcessing() {
        if (!this.props.globalState?.cmsData?.clientFeatures?.taskManager) {
            return null;
        }

        return (
            <BackgroundProcessingMain
                variant={BackgroundProcessingVariants.TASK_MANAGER}
            />
        );
    }

    render() {
        return this.renderDesignV4Nav();
    }
}
