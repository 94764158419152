import { CircularProgress, IconButton, MenuItem, Skeleton, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CreateIcon from '@mui/icons-material/Create';
import Settings from "../../components/Settings";
import { useStore } from "../../components/Global/store/store";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { StyleMenu, StyledAvatar } from "./StyledAvatar";

interface Props {
    imgsrc?: string
    loading?: boolean
}

const CoreAvatar: React.FC<Props> = (props) => {
    const {
        imgsrc = '',
        loading
    } = props

    const [globalState] = useStore() || [{}];

    const {
        cmsData = {}
    } = globalState

    const {
        clientFeatures = {}
    } = cmsData

    const {
        contributorsPreferencesAvatarUpload = true
    } = clientFeatures

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [imageLoaded, setImageLoaded] = useState<boolean>(false)
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null)
    };

    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click()
        }
    };

    const menuItems = [
        {
            label: "Upload Image",
            icon: <FileUploadIcon />,
            onClick: handleUploadClick,
            disabled: false,
        },
        {
            label: "Remove Image",
            icon: <DeleteIcon sx={{ fontSize: "1.25rem", width: "1.5rem" }} />,
            onClick: handleClose,
            disabled: !imgsrc,
        },
    ];

    useEffect(() => {
        setImageLoaded(false)
    }, [imgsrc])

    return (
        <Stack 
            direction="row" 
            alignItems="center" 
            justifyContent="center">

            <StyledAvatar 
                imgsrc={imgsrc}
                data-testid="core-avatar">

                <div className="avatar__container">
                    <div className="avatar__image">
                        {imgsrc ? (
                            <img src={imgsrc} data-testid="avatar__image_avatar" onLoad={() => setImageLoaded(true)} />
                        ) : (loading && !imageLoaded) ? (
                            <CircularProgress data-testid="circular-progress" sx={{color: "#DEDEDE"}} />
                        ) : (
                            <img src={`${Settings.images.path}/svg/avatar-placeholder.svg`} data-testid="avatar-placeholder" />
                        )}
                    </div>

                    {contributorsPreferencesAvatarUpload &&
                        <label 
                            className="avatar__edit-button"
                            data-testid="edit-button">

                            <IconButton onClick={handleClick}>
                                <CreateIcon />
                            </IconButton>
                        </label>
                    }

                    <input
                        type="file"
                        accept="image/*"
                        id="fileInput"
                        data-testid="fileInput"
                        ref={fileInputRef}
                    />

                    {contributorsPreferencesAvatarUpload &&
                        <StyleMenu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            transformOrigin={{ horizontal: 30, vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            data-testid="avatar-menu">
                            {menuItems.map(({
                                label = '',
                                icon = null,
                                onClick = () => {},
                                disabled = false
                            }, index) => (
                                <MenuItem
                                    key={index}
                                    onClick={onClick}
                                    disableRipple
                                    disabled={disabled}>
                                    {icon}
                                    {label}
                                </MenuItem>
                            ))}
                        </StyleMenu>
                    }
                </div>
            </StyledAvatar>
        </Stack>
    )
}

export default CoreAvatar;