import _ from "lodash";

const DoesTitleReadOnly = (props) => {
    let result = false;

    const { title = {}, languageIso = "", digital_readiness_status } = props;

    const {
        readOnly = false,
        readOnlyLanguages = [],
        readOnlyClauses = {},
    } = title;

    const { digitalReadiness = false } = readOnlyClauses;

    if (readOnly) {
        if (_.isEmpty(readOnlyLanguages) && _.isEmpty(readOnlyClauses)) {
            result = true;
        } else if (readOnly && title.hasOwnProperty("readOnlyLanguages")) {
            readOnlyLanguages.map((language) => {
                if (language == languageIso) {
                    result = true;
                }
            });
        } else if (readOnly && title.hasOwnProperty("readOnlyClauses")) {
            if (
                digitalReadiness &&
                digitalReadiness == digital_readiness_status
            ) {
                result = true;
            }
        }
    }

    return result;
};

export default DoesTitleReadOnly;
