import { SeriesNetworksInterface } from "../../models/ClientManaged/seriesNetworksInterface";

const seriesNetworksOptionsTransformer = (
    client_managed_series_networks: SeriesNetworksInterface[] & string,
): SeriesNetworksInterface[] => {
    let seriesNetworks: SeriesNetworksInterface[] =
        client_managed_series_networks;

    if (!Array.isArray(seriesNetworks)) {
        seriesNetworks = JSON.parse(client_managed_series_networks);
    }

    return seriesNetworks
        .filter((item) => !item.deleted_at)
        .map((item: SeriesNetworksInterface) => {
            return {
                text: item.text,
                value: item.value,
            };
        });
};

export default seriesNetworksOptionsTransformer;
