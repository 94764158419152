import {
    getAllLists as getAllListsQuery,
    getListItems as getListItemsQuery,
    updateListItems as updateListItemsQuery,
    createList as createListQuery,
} from "../../services/ApiClients/CML";
import {
    mapListItemsForUpdate,
    mapListItems,
} from "../../models/ClientManaged/dynamicCML";
import { CmsInterface } from "../../components/Global/interfaces";

export async function getAllLists(cmsData: CmsInterface) {
    return getAllListsQuery(cmsData);
}

export async function getListItems(cmsData: CmsInterface, list: string) {
    return getListItemsQuery(cmsData, list);
}

export async function updateListItems(
    cmsData: CmsInterface,
    list: string,
    items: any[],
    itemsOriginal: any[] = [],
) {
    let data = await updateListItemsQuery(
        cmsData,
        list,
        mapListItemsForUpdate(items, itemsOriginal),
    );

    return mapListItems(data);
}

export async function createList(cmsData: CmsInterface, listData) {
    return createListQuery(cmsData, listData);
}
