import React from "react";

export type ConditionalWrapperProps = {
    condition: boolean;
    wrapper: (children: JSX.Element) => JSX.Element;
    children: JSX.Element;
};

const ConditionalWrapper = (props: ConditionalWrapperProps): JSX.Element => {
    const { condition, wrapper, children } = props;

    return condition ? wrapper(children) : children;
};

export default ConditionalWrapper;
