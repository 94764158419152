import React from "react";
import "./_styles.scss";
import Settings from "../../../../components/Settings";
import PopupState, { bindPopover, bindHover } from "material-ui-popup-state";
import HoverPopover from "material-ui-popup-state/HoverPopover";

interface LicencePreviewInterface {}

const LicenceDates = () => {
    return (
        <div className="licence-dates">
            <div className="licence">
                <div className="licence-title">
                    <div className="dot green"></div>
                    <span>Licence Start</span>
                </div>

                <span className="date">00:00 • 12th December 2022</span>
            </div>

            <div className="licence">
                <div className="licence-title">
                    <div className="dot red"></div>
                    <span>Licence End</span>
                </div>

                <span className="date">00:00 • 12th December 2022</span>
            </div>
        </div>
    );
};

const LicencePreview = ({}: LicencePreviewInterface) => {
    return (
        <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState: any) => (
                <div>
                    <div {...bindHover(popupState)} className="licence-preview">
                        <img
                            src={`${Settings.images.path}/svg/calendar-timer-grey.svg`}
                        />
                    </div>

                    <HoverPopover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    >
                        <LicenceDates />
                    </HoverPopover>
                </div>
            )}
        </PopupState>
    );
};

export default LicencePreview;
