import _ from "lodash"
import { ContributorSectionInterface, DefaultValuesInterface } from '../../../../../services/ApiClients/RecordBuilder/graphql/interfaces/contributorSectionsInterface'

export default function getDefaultValues(activeSectionConfig: ContributorSectionInterface, contributorSectionData: any, options: any): any { 
    let result: any = {}

    if (activeSectionConfig?.options?.dataKey === 'locale' && !contributorSectionData?.locale) {
        result.locale = options.locale
    }

    activeSectionConfig?.options?.defaultValues?.forEach((item: DefaultValuesInterface) => {
        result = _.set(result, item.key, item.value)
    })

    return result
}