import React from "react";
import _ from "lodash";
import Input from "../../Input/basic";
import EidrMatchButton from "../../EidrMatchButton";
import TitleValue from "../../TitleValue";
import Settings from "../../Settings";
import ClientManagedSimpleOnEnterBubbleList from "../../BubbleList/ClientManagedSimpleOnEnter";

const AssetIds = (props) => {
    const {
        ids = {},
        compliance = {},
        handleUpdate,
        clientFeatures = {},
        apiUrls = {},
    } = props;

    const {
        turner_emea_id,
        turner_apac_id,
        turner_latam_id,
        ibms_house_id,
        titles_edit_id,
        hbo_eu_regional_clip_id,
        hbo_eu_content_id,
        pavi_id,
        eidr,
        territories,
    } = ids;

    const { system_name, asset_type, compliance_code } = compliance;

    const marginTop = { marginTop: "1.5rem" };

    return (
        <div className="meta-ids-container asset-information">
            <TitleValue
                title="Destination Platform"
                value={system_name}
                style={marginTop}
                titleStyles={Settings.components.label}
                valueStyle={Settings.components.input.disabled}
            />

            <TitleValue
                title="Asset Type"
                value={`${compliance_code} • ${asset_type}`}
                titleStyles={Settings.components.label}
                valueStyle={Settings.components.input.disabled}
                style={marginTop}
            />

            <div style={marginTop}>
                <Input
                    label="EMEA ID"
                    placeholder="Enter EMEA ID"
                    id="turner_emea_id"
                    value={turner_emea_id}
                    recordBasic={true}
                    borderHiddenPadding={true}
                    handleChange={(value) =>
                        handleUpdate("turner_emea_id", value)
                    }
                />
            </div>

            <div style={marginTop}>
                <Input
                    label="APAC ID"
                    placeholder="Enter APAC ID"
                    id="turner_apac_id"
                    value={turner_apac_id}
                    recordBasic={true}
                    borderHiddenPadding={true}
                    handleChange={(value) =>
                        handleUpdate("turner_apac_id", value)
                    }
                />
            </div>

            <div style={marginTop}>
                <Input
                    label="LATAM ID"
                    placeholder="Enter LATAM ID"
                    id="turner_latam_id"
                    value={turner_latam_id}
                    recordBasic={true}
                    borderHiddenPadding={true}
                    handleChange={(value) =>
                        handleUpdate("turner_latam_id", value)
                    }
                />
            </div>

            <div style={marginTop}>
                <Input
                    label="IBMS House ID"
                    placeholder="Enter IBMS House ID"
                    id="ibms_house_id"
                    value={ibms_house_id}
                    recordBasic={true}
                    borderHiddenPadding={true}
                    handleChange={(value) =>
                        handleUpdate("ibms_house_id", value)
                    }
                />
            </div>

            <div style={marginTop}>
                <Input
                    label="Titles Edit ID"
                    placeholder="Enter Titles Edit ID"
                    id="titles_edit_id"
                    value={titles_edit_id}
                    recordBasic={true}
                    borderHiddenPadding={true}
                    handleChange={(value) =>
                        handleUpdate("titles_edit_id", value)
                    }
                />
            </div>

            <div style={marginTop}>
                <Input
                    label="HBO EU Regional Clip ID"
                    placeholder="Enter HBO EU Regional Clip ID"
                    id="hbo_eu_regional_clip_id"
                    value={hbo_eu_regional_clip_id}
                    recordBasic={true}
                    borderHiddenPadding={true}
                    handleChange={(value) =>
                        handleUpdate("hbo_eu_regional_clip_id", value)
                    }
                />
            </div>

            <div style={marginTop}>
                <Input
                    label="HBO EU Content ID"
                    placeholder="Enter HBO EU Content ID"
                    id="hbo_eu_content_id"
                    value={hbo_eu_content_id}
                    recordBasic={true}
                    borderHiddenPadding={true}
                    handleChange={(value) =>
                        handleUpdate("hbo_eu_content_id", value)
                    }
                />
            </div>

            <div style={marginTop}>
                <Input
                    label="Pavi Edit ID"
                    placeholder="Enter Pavi ID"
                    id="pavi_id"
                    value={pavi_id}
                    recordBasic={true}
                    borderHiddenPadding={true}
                    handleChange={(value) => handleUpdate("pavi_id", value)}
                />
            </div>

            {_.get(clientFeatures, "assets.territory", false) && (
                <div style={marginTop}>
                    <ClientManagedSimpleOnEnterBubbleList
                        disabled={false}
                        handleFormChange={(value) =>
                            handleUpdate(
                                "territories",
                                value.map((item) => {
                                    item.name = item.text;
                                    return item;
                                }),
                            )
                        }
                        label="Territories"
                        emptyPlaceholder="Enter Territories"
                        id="territories"
                        name="territories"
                        data={territories}
                        readOnly={false}
                        clientFeatures={clientFeatures}
                        apiUrls={apiUrls}
                        filterStaticDataList={true}
                        dataListUseEnterButton={true}
                        useEnterButton={true}
                        fullyDelete={false}
                        dataKey="datalist"
                        handleChange={null}
                        noneSelectedText="None Added"
                        clientManagedType="client_managed_territories"
                        isClientManaged={true}
                        styles={{ ...marginTop }}
                    />
                </div>
            )}

            <div
                style={{
                    ...marginTop,
                    display: "flex",
                    alignItems: "flex-end",
                }}
            >
                <Input
                    label="EIDR"
                    placeholder="Enter EIDR"
                    id="eidr"
                    value={eidr}
                    recordBasic={true}
                    margin="0 0.4375rem 0 0"
                    borderHiddenPadding={true}
                    handleChange={(value) => handleUpdate("eidr", value)}
                />

                <EidrMatchButton
                    id="meta-match-eidr"
                    loading={false}
                    //onClick={this.handleEidrClick.bind(this)}
                />
            </div>
        </div>
    );
};

export default AssetIds;
