export default function getDays(numberOfDays = 31) {
    let result = [];

    for (let i = 1; i < numberOfDays + 1; i++) {
        result.push({
            value: ("0" + i).slice(-2),
            text: ("0" + i).slice(-2),
        });
    }

    return result;
}
