import React from "react";
import ExpandableRow from "../../../ExpandableRow";
import Advisories from "./Advisories";
import AssetDetailsBasicInfo from "./AssetDetailsBasicInfo";
import AssetDetails from "./AssetDetails";
import AudioVideoTracks from "./AudioVideoTracks";

const AssetDetailsSection = (props) => {
    const { dataStructure = {} } = props;

    const { basic_info_options = {} } = dataStructure;

    const { inline, customInline } = basic_info_options;

    const header = <div className="title">Asset Details</div>;
    const body = (
        <div className="meta-asset-compliance-body">
            <div
                className={`${inline ? "inline" : ""} ${
                    customInline ? "custom-inline" : ""
                }`}
            >
                <Advisories {...props} />
                <AssetDetailsBasicInfo {...props} />
            </div>

            <AssetDetails {...props} />
            <AudioVideoTracks {...props} />
        </div>
    );

    const { clientFeatures = {} } = props;

    const { assetDetailsExpanded = true } = clientFeatures;

    return (
        <ExpandableRow
            className="meta-expandable-rows--compliance-inner"
            defaultOpen={assetDetailsExpanded}
            header={header}
            body={body}
            headerStyles={{
                border: "none",
            }}
            noHeaderBackground={true}
            bodyStyles={{ padding: 0 }}
        />
    );
};

export default AssetDetailsSection;
