import { z } from "zod";
import FieldInterface from '../../interfaces/Field.interface'
import { ValidationLayer } from "../../../../../models/RecordBuilder/Field.interface";
import { toString } from "lodash";

export const NumericInputRule = (field: FieldInterface) => {
    let numeric: any = z.number().nonnegative().nullable().optional()

    if (
        field.validation?.characterLimit?.enabled 
        && field.validation?.characterLimit?.limit
        && field.validation?.characterLimit?.validation != ValidationLayer.None
    ) {
        numeric = numeric.pipe(
            z.number().nonnegative().nullable().optional().refine((value) => {
                if (!value) {
                    return true
                }

                return toString(value).length <= field.validation?.characterLimit?.limit
            }, {
                message: `Maximum character length ${field.validation?.characterLimit?.limit}`
            })
        )
    }

    if (
        field.validation?.characterLimit?.enabled 
        && field.validation?.characterLimit?.minLimit
        && field.validation?.characterLimit?.validation != ValidationLayer.None
    ) {
        numeric = numeric.pipe(
            z.number().nonnegative().nullable().optional().refine((value) => {
                if (!value) {
                    return true
                }

                return toString(value).length >= field.validation?.characterLimit?.minLimit
            }, {
                message: `Minimum character length required ${field.validation?.characterLimit?.minLimit}`
            })
        )
    }

    if (field.validation?.required?.enabled) {
        numeric = numeric.pipe(
            z.number().nonnegative({message: 'Please enter a valid number'}).nullable().refine((val) => val !== null, {
                message: `Please enter ${field.label}`, 
            })
        )
    }

    return z.object({
        [field.key]: numeric,
    })
}