import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import { CoreCellText } from "./CoreCellText";

// TODO: review this component's usages and fix it.
export const RenderCellOnlyText = (
    params: GridRenderCellParams<string | number> & { isNumber?: boolean },
) => {
    const { value, isNumber } = params;

    return useMemo(() => {
        let finalValue = value;

        if (value == null) {
            finalValue = "-";
            // finalValue = Number(value);
        }
        // if (isNumber && value == null) {
        //     finalValue = "-";
        //     // finalValue = Number(value);
        // }

        return (
            <CoreCellText color="rgba(149, 149, 149, 1)">
                <span> {finalValue}</span>
            </CoreCellText>
        );
    }, [value]);
};
