import React from "react";
import { SectionInterface } from "./interface";
import ContentAdvisories from "../../views/ContentAdvisories";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";

const SectionContentAdvisories = (props: SectionInterface) => {
    const { data = {} } = props;

    return (
        <ContentAdvisories
            {...getSectionCommonProps({
                ...props,
                field: "content_advisories",
            })}
            name="content-advisories"
            className="meta-record-content-advisories"
            title="Content Advisories"
            data={data.content_advisories || []}
            dataFromProps={true}
        />
    );
};

export default SectionContentAdvisories;
