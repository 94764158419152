import React, { useState, useEffect, useMemo, Fragment } from "react";
import Input from "../../Input/basic";
import EidrMatchButton from "../../EidrMatchButton";
import TitleValue from "../../TitleValue";
import Settings from "../../Settings";
import Title from "../../Title";
import _ from "lodash";
import Select from "../../Select";
import CategoryTags from "../../CategoryTags";
import DynamicInputs from "../../Input/DynamicInputs";
import mapTerritories from "./helpers/mapTerritories";

const EditIdsForm = (props) => {
    const {
        asset,
        dataStructure = {},
        onChange,
        clientFeatures = {},
        category,
    } = props;

    const {
        editable = {},
        system_name: structureSystemName,
        extra_info = {},
        version_codes = [],
    } = dataStructure;

    const { left = [], right = [] } = extra_info;

    const { ids = [] } = editable;

    const eidrEnabled = ids.some((id) => id.key === "eidr");
    const actualIds = ids.filter((id) => id.key !== "eidr");
    const mergedExtraInfo = _.concat(left, right).map((field) => {
        field.label = field.title;
        field.placeholder = `Enter ${field.title}`;
        field.component = field.component || "text";
        return field;
    });

    const {
        system_name,
        asset_type,
        compliance_code,
        version_name,
        version_code,
        code,
    } = asset;

    let typeValue = code;
    if (asset_type && compliance_code) {
        typeValue = `${compliance_code} • ${asset_type}`;
    } else if (version_name && version_code) {
        typeValue = `${version_code} • ${version_name}`;
    }

    const marginTop = { marginTop: "1.5rem" };

    const defaultNewIds = _.concat(mergedExtraInfo, ids).reduce((obj, { key }) => {
        if (asset[key]) {
            obj[key] = asset[key];

            if (key == "territories" && Array.isArray(asset[key])) {
                obj[key] = mapTerritories(asset[key])
            }
        }
        return obj;
    }, {});

    const [newIds, setNewIds] = useState(defaultNewIds);

    const handleUpdate = (key, newValue) => {
        if (key == "territories") {
            newValue = newValue.map((item) => {
                item.name = item.text;
                return item;
            });
        }

        setNewIds({
            ...newIds,
            [key]: newValue,
        });
    };

    useEffect(() => {
        if (
            Object.keys(newIds).includes("version_name") &&
            _.isEmpty(newIds.version_name)
        ) {
            onChange(newIds, false);
            return;
        }

        onChange(newIds, true);
    }, [newIds]);

    const idsInputProps = {
        recordBasic: true,
        borderHiddenPadding: true,
    };

    const isEditableVersionNameCode =
        _.get(clientFeatures, "assets.editableVersionNameCode", false) &&
        [CategoryTags.Short.short, CategoryTags.Extras.short].includes(
            category,
        );

    return (
        <div className="meta-ids-container asset-information">
            <TitleValue
                title="Destination Platform"
                value={system_name || structureSystemName}
                style={marginTop}
                titleStyles={Settings.components.label}
                valueStyle={Settings.components.input.disabled}
            />

            {!isEditableVersionNameCode && (
                <TitleValue
                    title="Asset Type"
                    value={typeValue}
                    titleStyles={Settings.components.label}
                    valueStyle={Settings.components.input.disabled}
                    style={marginTop}
                />
            )}

            {isEditableVersionNameCode && (
                <React.Fragment>
                    <div style={marginTop}>
                        <Input
                            recordBasic={true}
                            borderHiddenPadding={true}
                            label={"Version Name"}
                            placeholder={"Enter Version Name"}
                            id={"version_name"}
                            value={version_name}
                            handleChange={(value) =>
                                handleUpdate("version_name", value)
                            }
                        />
                    </div>

                    <div style={marginTop}>
                        <Select
                            data={version_codes}
                            recordBasic={true}
                            borderHiddenPadding={true}
                            label={"Version Code"}
                            placeholder={"Enter Version Code"}
                            id={"version_code"}
                            value={version_code}
                            disabled={false}
                            handleChange={(value) =>
                                handleUpdate("version_code", value)
                            }
                        />
                    </div>
                </React.Fragment>
            )}

            {actualIds.map((id, index) => (
                <div style={marginTop} key={`edit_id_${index}`}>
                    <Input
                        {...idsInputProps}
                        label={id.title}
                        placeholder={`Enter ${id.title}`}
                        id={id.key}
                        value={newIds[id.key]}
                        handleChange={(value) => handleUpdate(id.key, value)}
                    />
                </div>
            ))}

            {!_.isEmpty(mergedExtraInfo) && (
                <Fragment>
                    <Title
                        title="Technical MetaData"
                        fontSize="1.05rem"
                        margin="2rem 0 0"
                    />

                    <DynamicInputs
                        options={mergedExtraInfo}
                        disabled={false}
                        handleChange={handleUpdate}
                        data={newIds}
                        category={category}
                        styles={{ ...marginTop }}
                    />
                </Fragment>
            )}

            {eidrEnabled && (
                <div
                    style={{
                        ...marginTop,
                        display: "flex",
                        alignItems: "flex-end",
                    }}
                >
                    <Input
                        label="EIDR"
                        placeholder="Enter EIDR"
                        id="eidr"
                        value={newIds.eidr}
                        recordBasic={true}
                        margin="0 0.4375rem 0 0"
                        borderHiddenPadding={true}
                        handleChange={(value) => handleUpdate("eidr", value)}
                    />

                    <EidrMatchButton id="meta-match-eidr" loading={false} />
                </div>
            )}
        </div>
    );
};

export default EditIdsForm;
