import React from 'react'
import { makeObservable, action, extendObservable } from 'mobx'
import { RootStore } from '../rootStore'
import { validationStoreInitialState } from "../state"
import { CreditListInterface } from "../interfaces"

export class CreditListValidationStore {
    rootStore: RootStore;
    errors: any | null;
    errorAlerts: string[];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            initialize: action,

            setErrorAlerts: action,
            setErrors: action,
            handleValidation: action
        });

        extendObservable(this, validationStoreInitialState);
    }

    initialize() {
        this.reset()
    }

    reset() {
        Object.keys(validationStoreInitialState).forEach(
            (key) => (this[key] = validationStoreInitialState[key]),
        );
    }

    getMandatoryFieldsForSection(fields: Array<any>): Array<string> {
        return fields
            ?.filter((item: any) => item?.options?.validation?.required?.enabled)
            .map((item: any) => item.key);
    }

    setErrorAlerts(errorAlerts: string[]): void {
        this.errorAlerts = errorAlerts
    }

    setErrors(errors: any): void {
        this.errors = errors
    }

    handleValidation(configuration: any, creditList: CreditListInterface, creditListOriginal: CreditListInterface): void {
        let errorAlerts: string[] = [];
        const mandatoryFields: Array<string> = this.getMandatoryFieldsForSection(configuration?.fields);

        mandatoryFields.forEach((field: string) => {
            if (!creditList[field]) {
                const missingField = configuration?.fields.find((item: any) => item.key === field) || {}
                errorAlerts.push(`${missingField.displayName} is required`)
            }
        })

        if (this.rootStore.creditListsStore.getFilteredCreditLists()?.find((item: CreditListInterface) => item.code === creditList.code 
            && creditListOriginal.code !== creditList.code)) {
                
            errorAlerts.push("Credit list code is used in another credit list")
        }

        this.setErrorAlerts(errorAlerts)
    }
}
