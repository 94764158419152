import deepcopy from "deepcopy";
import { ValidationResult } from "./interfaces/Validation";
import DisabledRuleActive from "./rules/disabled";

export default function ValidateChannelOfOrigin(
    result: ValidationResult,
    options: any,
): ValidationResult {
    let response: ValidationResult = deepcopy(result);

    const { data = {}, status, clientDataStructure = {} } = options;

    const channelOriginValidation =
        clientDataStructure?.fieldValidation?.origination?.channel_origin;

    const {
        origination = {},
        category = false,
        status: recordStatus = ''
    } = data;

    const { type } = origination;

    if (
        DisabledRuleActive({
            recordStatus,
            category,
            type,
            dataStructure: clientDataStructure,
            fieldPath: "origination.channel_origin",
        })
    ) {
        return response;
    }

    if (
        (!origination || !origination.channel_origin) &&
        channelOriginValidation
    ) {
        response.result = false;
        response.notification = {
            title: "Please check channel of origin",
            description: "Channel of Origin is missing",
            status,
        };

        response.validationKeys = result.validationKeys.concat([
            "channel_origin",
        ]);
    }

    return response;
}
