import MaterialDatePicker from "../../../../../components/Input/MaterialDatePicker"
import Input from '../../../../../components/Input/basic';
import AutocompleteInput from '../../../../../components/AutocompleteInput';
import ScriptSelect from '../components/ScriptSelect';
import { StyledForm } from "../../styles/StyledForm";

interface StyledFormProps {
    columnSpacing?: string
    rowSpacing?: string
}

export const RenderEditContributorForm = (props: StyledFormProps) => {
    const baseInputProps = {
        margin: "1.5rem 0 0",
        recordBasic: true
    }

    const staticTitleData = ['Title Test', 'Test 2'] // DUMMY

    return (
        <StyledForm columnSpacing="0.328125rem">
            <MaterialDatePicker
                label='Date of Birth'
                className="datepicker-full-width datepicker-small"
            />

            <div className="form-item half">
                <Input
                    label="IMDB ID"
                    placeholder="Enter IMDB ID"
                    recordBasic={true}
                    noDelay={true}
                />
            </div>
            <div className="form-item">
                <ScriptSelect
                    wrapperStyles={{
                        margin: "0.75rem 0 0",
                        minWidth: "30rem"
                    }}
                />
            </div>
            <div 
                className="form-item half"
                style={{marginTop: "1.5rem"}}>
                <AutocompleteInput
                    label="Title"
                    placeholder="Select Title"
                    className="contributor-title"
                    openOnClick={true}
                    keepSelected={true}
                    staticDatalist={staticTitleData}
                    recordBasic={true}
                />
            </div>
            <div className="form-item half">
                <Input
                    label="First Name"
                    placeholder="Enter First Name"
                    id="create-new-contributor--first-name"
                    className="contributor-first-name"
                    noDelay={true}
                    autoFocus={""}
                    enterOnFocusOut={true}
                    onFocus={() => { }}
                    {...baseInputProps}
                />
            </div>
            <div className="form-item half">
                <Input
                    label="Middle Name"
                    placeholder="Enter Middle Name"
                    className="contributor-middle-name"
                    noDelay={true}
                    {...baseInputProps}
                />
            </div>
            <div className="form-item half">
                <Input
                    label="Last Name"
                    placeholder="Enter Last Name"
                    className="contributor-last-name"
                    noDelay={true}
                    {...baseInputProps}
                />
            </div>
            <div className="form-item half">
                <Input
                    label="Alternative Name"
                    placeholder="Enter Alternative Name"
                    className="contributor-alternative-name"
                    noDelay={true}
                    {...baseInputProps}
                />
            </div>
            <div className="form-item half">
                <Input
                    label="Alternative Name 2"
                    placeholder="Enter Alternative Name 2"
                    className="contributor-alternative-name"
                    noDelay={true}
                    {...baseInputProps}
                />
            </div>
        </StyledForm>
    )
}
