import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import {
    CoreForm,
    CoreFormProvider,
    CoreFormThemeProvider,
} from "../../../../core/Form/components/CoreForm";
import { CoreSelect } from "../../../../core/Form/components/inputs/Select/components/CoreSelect";
import { CoreFormStack } from "../../../../core/Form/components/layout/CoreForm.stack";
import { useEffect } from "react";
import { getListItems } from '../../../../services/ApiClients/CML'
import { mapListItems } from '../../../../models/ClientManaged/dynamicCML'
import { isEmpty, isObject, isString } from "lodash";
import { CoreSelectMultipleMenu } from "../../../../core/Form/components/inputs/Select/components/CoreSelectMultipleMenu";
import { CoreFormLabel } from "../../../../core/Form/components/styled/CoreForm.label"
import { CoreSelectOptionType } from "../../../../core/Form/components/inputs/Select/types/CoreSelectOptionType";
import "../../styles/_CoreSelect.scss"

type DynamicCoreSelectInputProps = {
    handleChange: Function;
    value: any;
    key: string
    label: string
    isDynamicList: boolean
    apiUrls: any
    clientManagedType: string
    multiple: boolean
    name: string
    disabled: boolean
};

const DynamicCoreSelectInput = (props: DynamicCoreSelectInputProps) => {
    const {
        isDynamicList = false,
        apiUrls,
        clientManagedType,
        value: initialData = [],
        key,
        label,
        handleChange,
        multiple,
        name,
        disabled
    } = props || {}

    const [options, setOptions] = useState<CoreSelectOptionType[]>([]);
    const [fetched, setFetched] = useState<boolean>(false);
    const [fetchingOptions, setFetchingOptions] = useState<boolean>(false);

    let optionsRef = useRef<any>();
    optionsRef.current = options;

    useEffect(() => {
        if (!fetched && isEmpty(options)) {
            fetchOptions();
        }
    }, []);

    const GetMultipleDefaultInput = (values = []) => {
        return values
            .filter(item => !item.deleted)
            .map(item => item.value)
    }
    
    const form = useForm({
        defaultValues: {
            [name]: multiple ? GetMultipleDefaultInput(initialData) :  (initialData?.value || null),
        },
        mode: "onSubmit",
    });

    useEffect(() => {
        const subscription = form.watch((value, { name, type }) => {
            if (multiple) {
                let inputData: any[] = []
                let selectedItems: string[] = isString(value[name]) ? value[name].split(',') : value[name]
                
                optionsRef.current.forEach(item => {
                    if (selectedItems.includes(item.value)) {
                        inputData.push(item)
                    }
                })

                if (Array.isArray(initialData)) {
                    initialData.map(item => {
                        if (!selectedItems.includes(item.value)) {
                            inputData.push({...item, deleted: true})
                        }
                    })
                }
                
                handleChange(inputData)
    
                return 
            }
    
            let findOption = optionsRef.current.find(item => item.value == value[name])
                if (findOption) {
                    handleChange({
                        text: findOption?.text, 
                        value: value[name] 
                    })
                }
        });
        return () => subscription.unsubscribe();
    }, [form.watch]);

    function fetchOptions() {
        setFetchingOptions(true);

        if (isDynamicList) {
            getListItems({ apiUrls }, clientManagedType).then((data) => {
                setOptions(mapListItems(data));
                setFetchingOptions(false);
                setFetched(true)
            })
            return
        }
    }

    let selectSettings = {
        disabled,
        config: {
            options,
            path: name,
            enableSearchbox: true,
            enableScrollToSelected: true,
            labelProps: {
                text: label,
                isRequired: false,
            },
        },
        key: options.join("_") + initialData,
        MenuProps: {
            PaperProps: {
                sx: {
                    boxShadow:
                        "0.125rem 0.1875rem 0.3125rem rgba(79, 94, 116, 0.15)",
                    border: "0.0625rem solid rgba(77, 77, 77, 0.2)",
                    padding: "0.3125rem",
                    overflow: "hidden",
                },
            },
            MenuListProps: {
                sx: {
                    maxHeight: 315,
                    paddingY: 0,
                    "& .MuiPaper-root + div": {
                        overflow: "scroll",
                        maxHeight: "17.5rem",
                    },
                },
            },
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
            },
            transformOrigin: {
                vertical: "top",
                horizontal: "center",
            },
        },
        customStyles: {
            transform: "translateY(-0.3125rem)",
        }
    }

    return (
        <CoreFormThemeProvider>
            <CoreFormProvider {...form}>
                <CoreForm>
                    <CoreFormStack direction="row">
                        {multiple &&
                            <div className="dynamic-core-select-multiple-input">
                                <CoreFormLabel text={label} customStyles={{
                                    color: "rgba(47, 47, 47, 0.87) !important",
                                }}/>
                                <CoreSelectMultipleMenu 
                                    path={name}
                                    disabled={disabled}
                                    name={""}
                                    options={options}
                                    hasSearch={true}
                                    isSingleSelect={false}
                                    key={options.join("_") + initialData} 
                                    showSelectedItemsCount={3}
                                    anchorProps={{
                                        anchorOrigin: {
                                            horizontal: "center",
                                            vertical: "bottom",
                                        },
                                        transformOrigin : {
                                            horizontal: "center",
                                            vertical: "top",
                                        }}
                                    }
                                    customStyles={{
                                        menuButton: {
                                            backgroundColor: "#FFF",

                                            color: "rgba(47, 47, 47, 0.87)",
                                            borderColor: "rgba(77, 77, 77, 0.1)",
                                            borderWidth: "0.0625rem",
                                            textTransform: "none",
                                            "&:hover": {
                                                backgroundColor: "#FFF",
                                                border: "0.0625rem solid rgba(40, 108, 210, 0.75)",
                                            },
                                            "& .MuiTypography-root": {
                                                lineHeight: "1",
                                                color: "rgba(47, 47, 47, 0.57)"
                                            },
                                            "& .MuiChip-root": {
                                                margin: 0,
                                                backgroundColor: "rgba(40, 108, 210, 0.2)",
                                                color: "rgba(0, 0, 0, 1)",
                                                padding: "0.75rem 0.5rem",
                                                "& .MuiChip-label": {
                                                    fontSize: "0.75rem",
                                                },
                                            },
                                            "& .MuiMenu-list": {
                                                paddingTop: 0,
                                            }
                                        }
                                    }
                                        
                                    }
                                />
                            </div>
                        }

                        {!multiple &&
                            <div className="dynamic-core-select-single-input">
                                <CoreSelect {...selectSettings}/>
                            </div>
                        }
                    </CoreFormStack>
                </CoreForm>
            </CoreFormProvider>
        </CoreFormThemeProvider>
    );
};

export default DynamicCoreSelectInput
