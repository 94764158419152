import Switch from "../../Switch";
import React, { Fragment } from "react";
import RenderCertificationsToggle from "./RenderCertificationsToggle";

const RenderAssetsToggles = (props) => {
    const {
        assets = {},
        checkedAssets = [],
        checkedAssetsRatings = [],
        handleAssetSelect = () => {},
        handleAssetRatingSelect = () => {},
        handleCreateRatingSelect = () => {},
    } = props;

    return (
        <Fragment>
            {!_.isEmpty(assets) && (
                <div className="meta-gracenote--assets--video-options">
                    {assets.map((asset, index) => {
                        const { meta_id, version_name } = asset;

                        const isCheckedAsset =
                            checkedAssets.find(
                                (item) => item.meta_id === meta_id,
                            ) || false;

                        return (
                            <div key={index}>
                                <div
                                    className="gracenote-copyto--row"
                                    key={index}
                                >
                                    <div className="asset-row-title">
                                        {" "}
                                        {version_name}{" "}
                                    </div>
                                    <div className="asset-row-line"></div>
                                    <Switch
                                        name={meta_id}
                                        checked={isCheckedAsset}
                                        handleChange={() =>
                                            handleAssetSelect(asset)
                                        }
                                        sliderClass="react-slider--orange"
                                    />
                                </div>
                                {isCheckedAsset && (
                                    <RenderCertificationsToggle
                                        key="key"
                                        handleAssetRatingSelect={(value) =>
                                            handleAssetRatingSelect(value)
                                        }
                                        handleCreateRatingSelect={(value) =>
                                            handleCreateRatingSelect(value)
                                        }
                                        asset={asset}
                                        checkedAssetsRatings={
                                            checkedAssetsRatings
                                        }
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>
            )}

            {_.isEmpty(assets) && (
                <p className={"meta-gracenote--assets--empty"}>No assets</p>
            )}
        </Fragment>
    );
};

export default RenderAssetsToggles;
