import React from "react";
import DoubleTagSimple from "../../Tag/simpleDouble";

const RenderCertificateRatingIso = (props) => {
    const { certificate = {}, styles = {}, clientFeatures = {} } = props;

    const { systemInCertificateRatingTag = false } = clientFeatures;

    let rightText =
        certificate.system && systemInCertificateRatingTag
            ? certificate.system + " • " + certificate.rating
            : certificate.rating;
    return (
        <DoubleTagSimple
            leftBackground="#eaeaea"
            rightBackground="#f15d5d"
            color="white"
            leftText={certificate.country_code}
            rightText={rightText}
            styles={styles}
            leftStyles={{
                color: "#f15d5d",
                border: "0.065rem solid #f15d5d",
            }}
        />
    );
};

export default RenderCertificateRatingIso;
