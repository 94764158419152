import React, { useState} from "react";
import { isEmpty, find } from "lodash"
import { ImageInterface } from "../../../models/Record/images"
import { useStore } from "../../../components/Global/store/store";
import { ImagesTable } from "../../../core/Table/images/Images.table"
import { GridRowParams } from "@mui/x-data-grid";
import ImagePreviewModal from "../../../components/RecordImageContainer/views/ImagePreviewModal"
import getImage from "../../../components/RecordImageContainer/utils/getImage"
import downloadFile from "../../../components/File/downloadFile"
import hasNoPreviewImage from "../../../components/RecordImage/utils/hasNoPreviewImage"
import { TableViewInterface } from "../interfaces"

const TableView = (props: TableViewInterface) => {
    const [globalState] = useStore();
    const [imageModalOpened, setImageModalOpened] = useState<ImageInterface | {}>({})

    const {
        cmsData = {},
        options = {}
    } = globalState

    const {
        data = [],
        disabled,
        meta_id,
        userPermissions,
        handleEdit = () => {},
        handleDelete = () => {}
    } = props

    const { image_types = [] } = options;

    const isFeatureEnabled = Boolean(cmsData?.clientFeatures?.previewDownloadImages)

    return (
        <>
            <ImagesTable 
                images={data
                    .filter((image: ImageInterface) => !image.deleted)
                    .map((image: ImageInterface, index: number) => {

                    const imageType = find(image_types, (t) => t.value === parseInt(image.image_type_id)) || {};

                    return {
                        ...image,
                        name: image.name || "-",
                        id: image.meta_id || image.name,
                        image_type: imageType.text || "-",
                        resolution: (image.horizontal_resolution && image.vertical_resolution) 
                            ? `${image.horizontal_resolution} x ${image.vertical_resolution}`
                            : '',
                        index
                    }
                })}
                config={{
                    state: disabled ? 'default' : 'edit',
                }}
                handleEdit={(params: GridRowParams) => handleEdit(params.row?.index)}
                handleDelete={(params: GridRowParams) => handleDelete(params.row?.index)}
                handlePreview={(params: GridRowParams) => setImageModalOpened(params?.row)}
                handleDownload={((event: React.SyntheticEvent, params: GridRowParams) => downloadFile(event, getImage(params?.row, false, meta_id), params.row?.original_filename))}
                editPermission={userPermissions.update}
                deletePermission={userPermissions.delete}
                hasNoPreview={(params: GridRowParams) => {
                    const image: string = getImage(params?.row, false, meta_id)
                    
                    return image 
                        ? hasNoPreviewImage({ cmsData, image })
                        : true
                }}
            />

            {isFeatureEnabled && !isEmpty(imageModalOpened) && (
                <ImagePreviewModal 
                    open={true}
                    image={getImage(imageModalOpened, false, meta_id)}
                    handleClose={() => setImageModalOpened(false)}
                />
            )}
        </>
    );
}

export default TableView