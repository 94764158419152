import deepcopy from "deepcopy";
import { isCollection } from "../CategoryTags/CollectionCategories";
import _ from "lodash";
import DisabledRuleActive from "./rules/disabled";
import isActiveRecordBuilder from '../RecordBuilder/IsActive'
import { ORIGINATION } from "../../models/RecordBuilder"

export default function ValidateFirstReleaseYear(result = {}, options = {}) {
    let response = deepcopy(result);

    const { data = {}, copy = {}, status, clientDataStructure = {}, clientFeatures = {} } = options;

    const { fieldValidation = {} } = clientDataStructure;

    const { origination: originationValidation = {} } = fieldValidation

    const { first_release_year = {} } = originationValidation;

    const { 
        origination = {},
        category = false,
        status: recordStatus = ''
    } = data;

    const { type } = origination;

    if (isActiveRecordBuilder({ page: ORIGINATION,  clientFeatures})) {
        return response
    }

    if (!origination || !origination.first_release_year) {
        response.result = false;
        response.notification = {
            title:
                copy.checkFirstReleaseYear || "Please check first release year",
            description:
                copy.firstReleaseYearMissing || "First release year is missing",
            status,
        };

        response.validationKeys = result.validationKeys.concat([
            "first_release_year",
        ]);
    }

    const clearResponse = (resp) => {
        resp.result = true;
        resp.notification = {};
        resp.validationKeys = response.validationKeys.filter(
            (value) => value !== "first_release_year",
        );
        return resp;
    };

    if (
        isCollection(category) ||
        DisabledRuleActive({
            recordStatus,
            category,
            type,
            dataStructure: clientDataStructure,
            fieldPath: "origination.first_release_year",
        })
    ) {
        response = clearResponse(response);
    }

    return response;
}
