import { DynamicInputsInterface, OptionsInterface } from "../interfaces";
import deepcopy from "deepcopy";

function filterOptionsByCategory(
    options: OptionsInterface[],
    category: string,
): OptionsInterface[] {
    if (!category) {
        return options;
    }

    return options.filter((option: OptionsInterface) => {
        if (option.categories && !option.categories.includes(category)) {
            return false;
        }

        return option;
    });
}

export default function transformOptions(
    props: DynamicInputsInterface,
): OptionsInterface[] {
    const { options = [], category = "" } = props;

    let result: OptionsInterface[] = deepcopy(options);

    result = filterOptionsByCategory(result, category);

    return result;
}
