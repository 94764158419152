import React, { useEffect } from 'react'
import { observer } from "mobx-react";
import { isEmpty } from "lodash"
import { useStores } from "../../store/rootStore";
import CoreDynamicForm from '../../../../../core/Form/dynamic'
import { coreDialogProps } from "../../utils/constants"
import { CoreDialog } from "../../../../../core/Dialog/CoreDialog";
import { CreditListInterface } from "../../store/interfaces"

const CreateCreditListForm = observer(() => {
    const { 
        creditListCreateStore,
        creditListValidationStore,
        rootStore
    } = useStores();

    useEffect(() => {
        creditListCreateStore.initialize()

        return () => {
            creditListCreateStore.reset()
        }
    }, [])

    if (isEmpty(creditListCreateStore.coreFormFields)) {
        return <></>
    }

    return (
        <CoreDialog
            {...coreDialogProps}
            open={true}
            setOpen={(open) => {}}
            title="New Credit List"
            errorAlerts={creditListValidationStore.errorAlerts}
            buttons={{
                discard: {
                    label: "Cancel",
                    handleOnDiscard: () => {
                        rootStore.setShowCreditListCreateForm(false)
                        creditListCreateStore.reset()
                    }
                },
                submit: {
                    handleOnSubmit: () => creditListCreateStore.handleSubmit(),
                    label: "Add Credit List",
                    disabled: false
                }
            }}>
                
            <CoreDynamicForm 
                fields={creditListCreateStore.coreFormFields} 
                data={creditListCreateStore.creditList}
                onChange={(values: CreditListInterface) => creditListCreateStore.setCreditList(values)}
                defaultValues={creditListCreateStore.creditListOriginal}
                handleErrors={(errors: any) => creditListValidationStore.setErrors(errors)}
                stackStyles={{
                    padding: 0
                }}
            />
        </CoreDialog>
    )
})

export default CreateCreditListForm