import _ from "lodash";

import { AccessTypeInterface } from "../interfaces";

export const defaultCrudValue: number = 0;
export const binaryValueSelected: string = "1";
export const crudKey: string = "_p";

export const policyTypeRecord: string = "record";
export const policyTypeDiscovery: string = "catalog";
export const policyTypeCatalogAccess: string = "catalogueAccess";
export const policyTypeCollections: string = "collections";
export const policyTypeExports: string = "exports";
export const policyTypeLocalisations: string = "localisations";
export const policyTypePlatformManagement: string = "platformManagement";
export const policyTypeReporting: string = "reporting";
export const policyTypeSwimlanes: string = "swimlanes";
export const policyTypeLineups: string = "lineups";
export const policyTypeUserGroups: string = "userGroups";
export const policyTypeUserRolesAndPolicies: string = "userRolesAndPolicies";
export const policyTypeRecordBuilder: string = "recordBuilder";
export const policyTypeFranchiseCollections: string = "franchiseCollections";
export const policyRecordProperties: string = "recordProperties";
export const policyTypeTaskManager: string = "taskManager"
export const policyTypeServiceDesk: string = "serviceDesk"
export const policyTypeRecordApprovals: string = "recordApprovals"

export const accessTypeCreate: AccessTypeInterface = {
    name: "create",
    value: "c",
    index: 0,
    binary: 8,
    parentIndex: []
};

export const accessTypeUpdate: AccessTypeInterface = {
    name: "update",
    value: "u",
    index: 1,
    binary: 4,
    parentIndex: [0],
};

export const accessTypeDelete: AccessTypeInterface = {
    name: "delete",
    value: "d",
    index: 2,
    binary: 2,
    parentIndex: [0, 1],
    checkBinary: true,
    allowedBinaries: [
        2, 6, 10, 11, 14, 15
    ]
};

export const accessTypeView: AccessTypeInterface = {
    name: "view",
    value: "v",
    index: 3,
    binary: 1,
    parentIndex: [0, 1, 2],
};

// Strict Access Types 

export const accessTypeStrictCreate: AccessTypeInterface = {
    ...accessTypeCreate,
    allowedBinaries: [
        8, 9, 10, 11, 12, 13, 14, 15
    ]
};

export const accessTypeStrictUpdate: AccessTypeInterface = {
    ...accessTypeUpdate,
    allowedBinaries: [
        4, 5, 6, 7, 12, 13, 14, 15
    ]
};

export const accessTypeStrictDelete: AccessTypeInterface = {
    ...accessTypeDelete,
    allowedBinaries: [
        2, 3, 6, 7, 10, 11, 14, 15
    ]
};

export const accessTypeStrictView: AccessTypeInterface = {
    ...accessTypeView,
    allowedBinaries: [
        1, 3, 5, 7, 9, 13, 15
    ]
};

export const accessTypes: AccessTypeInterface[] = [
    accessTypeCreate,
    accessTypeStrictCreate,
    accessTypeUpdate,
    accessTypeStrictUpdate,
    accessTypeDelete,
    accessTypeStrictDelete,
    accessTypeView,
    accessTypeStrictView
];
