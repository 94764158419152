import { CreditInterface, CreditListInterface, CreditRankMap } from "../../../credits.interface";
import groupCreditsByRole from "./groupCreditsByRole"

export default function setListScopedRanking(
    credits: CreditInterface[],
    creditList: CreditListInterface
): CreditInterface[] {
    const rankableCredits = credits
        ?.filter((credit: CreditInterface) => {
            if (!credit.credit_lists.length || credit.deleted) {
                return false
            }

            return credit.credit_lists?.some((creditRank: CreditRankMap) => {
                return creditRank.code === creditList.code && !creditRank.deleted
            })
        })
        ?.map((credit: CreditInterface) => {
            const listIndex: number = credit.credit_lists.findIndex(
                (creditRank: CreditRankMap) => creditRank.code === creditList.code
            )

            credit.ranking = credit.credit_lists[listIndex].ranking as number

            return credit
        })
        ?.sort((a: CreditInterface, b: CreditInterface) => {
            return ((a.ranking as number) - (b.ranking as number))
        })

    const rankedCreditGroups =
        Object.values(
            groupCreditsByRole(rankableCredits)
        )
        ?.map(group => {
            return group.map((credit, index: number) => {
                const listIndex: number = credit.credit_lists.findIndex(
                    (creditRank: CreditRankMap) => creditRank.code === creditList.code
                )

                credit.ranking = index + 1
                const updatedCreditLists = [...credit.credit_lists];
                updatedCreditLists[listIndex].ranking = index + 1

                return {
                    ...credit,
                    credit_lists: updatedCreditLists,
                };
            })
        })
        ?.flat()

    return rankedCreditGroups.concat(
        credits.filter(oldCredit => {
            !rankedCreditGroups.some(
                updatedCredit => updatedCredit.local_id === oldCredit.local_id
            )
        })
    )
}