import { CreditListInterface, SelectedCredits } from "../credits.interface";

export const defaultLanguage = {
    name: "English Latin",
    tag: "Eng",
    value: "ENG"
}

export const originalCreditList: CreditListInterface = {
    name: "Original Credit List",
    code: "ORG",
    meta_id: "TEMP_CCL00000000"
}

export const TempMetaIdPrefix: string = "TEMP_CCL"

export const optionURL: string = "/options/contributors"

export const selectedCreditsObject: SelectedCredits = {
    active: [],
    inactive: []
}

export const creditFormValidation = {
    contributor: {
        isValid: true,
        message: ""
    },
    role: {
        isValid: true,
        message: ""
    },
    charachterName: {
        isValid: true,
        message: ""
    }
}


export const creditIDProperites: readonly string[] = [
    "contributor_meta_id",
    "date_of_birth",
    "imdb_id",
    "isni_id",
    "hand_id",
    "iva_person_id",
    "value"
]

export const creditNameProperties : readonly string[] = [
    "title",
    "name",
    "first_name",
    "last_name",
    "middle_names",
    "alternative_name",
    "character_name",
    "script_names",
    "script_character_names"
]

export const creditListRankProperties : readonly string[] = [
    "credit_lists",
    "rank",
    "role",
    "role_name"
]