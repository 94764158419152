import React from 'react'
import { observer } from "mobx-react";
import { useStores } from "../../store/rootStore";
import CreditListCreateOrUpdateForm from '../forms/creditList/CreateOrUpdateForm'
import CreditListDeleteForm from '../forms/creditList/DeleteForm'
import CreditCreateOrUpdateForm from "../forms/credit/CreateOrUpdateForm"
import ContributorCreateOrUpdateForm from "../forms/contributor/CreateOrUpdateForm"
import DeleteForm from '../forms/credit/DeleteForm';

interface RootContainerProps {
    children: React.ReactNode
}

const RootContainer: React.FC<RootContainerProps> =
    observer(({ children }) => {

        const rootStore = useStores()
        const { creditListsStore, creditsStore } = rootStore || {}
        const forms = {
            creditListForm: creditListsStore.getFormProperties.isActive,
            creditListDeleteForm: creditListsStore.getFormProperties.isDeleteForm,
            creditForm: creditsStore.getCreditFormProperties.isActive,
            contributorForm: creditsStore.getContributorFormProperties.isActive,
            creditsDeleteForm: creditsStore.creditDeleteFormProperties.isActive
        }

        return (
            <React.Fragment>
                {children}

                {forms.creditListForm &&
                    <CreditListCreateOrUpdateForm />
                }

                {forms.creditListDeleteForm &&
                    <CreditListDeleteForm />
                }

                {forms.creditForm &&
                    <CreditCreateOrUpdateForm />
                }

                {forms.creditsDeleteForm &&
                    <DeleteForm />
                }

                {forms.contributorForm &&
                    <ContributorCreateOrUpdateForm />
                }
            </React.Fragment>
        )
    })

export default RootContainer