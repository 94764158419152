import deepcopy from "deepcopy";
import { ValidationResult } from "../../models/Validation/interfaces";
import DisabledRuleActive from "./rules/disabled";

export default function ValidateFirstReleaseYearProperties(
    result: ValidationResult,
    options: any,
) {
    let response: ValidationResult = deepcopy(result);

    const { data = {}, copy = {}, status, clientDataStructure = {} } = options;

    const firstReleaseYearPropertiesValidation =
        clientDataStructure?.fieldValidation?.properties?.first_release_year;

    const { properties = {}, recordStatus = "", category = false } = data;

    if (
        DisabledRuleActive({
            recordStatus,
            category,
            type: "",
            dataStructure: clientDataStructure,
            fieldPath: "properties.first_release_year",
        })
    ) {
        return response;
    }

    if (
        (!properties || !properties.first_release_year) &&
        firstReleaseYearPropertiesValidation
    ) {
        response.result = false;
        response.notification = {
            title:
                copy.checkFirstReleaseYear || "Please check first release year",
            description:
                copy.firstReleaseYearMissing || "First release year is missing",
            status,
        };

        response.validationKeys = result.validationKeys.concat([
            "first_release_year",
        ]) as string[];
    }

    return response;
}
