import { GridValueFormatterParams } from "@mui/x-data-grid-pro";
import _ from "lodash";
import { CoreTableGridColDef } from "../../types";

export const YearCol: CoreTableGridColDef = {
    field: "year",
    // flex: 1,
    headerName: "Year",
    minWidth: 40,
    maxWidth: 80,
    valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (_.isNull(params?.value)) {
            return " - ";
        }

        const valueFormatted = Number(params.value);
        return valueFormatted;
    },
};
