import React from "react";
import { Skeleton, Stack, Grid, Divider } from "@mui/material";
import Linear from "../Progress/Linear";
import "./styles/_styles.scss";

type Props = {
    loading?: boolean;
    count?: number;
};

const RecordBodySkeleton: React.FC<Props> = ({
    loading = false,
    count = 7,
}) => {
    return (
        <div className="skeleton--record-body">
            <Stack direction="row" spacing={1}>
                {Array.from(Array(count)).map((_, index) => (
                    <Skeleton width="4.375rem" height="1.625rem" key={index} />
                ))}
            </Stack>

            <div style={{ marginTop: "0.5rem" }}>
                {loading ? <Linear /> : <Divider />}
            </div>

            {Array.from(Array(5)).map((_, index) => (
                <Stack key={index}>
                    <Skeleton
                        width="11.875rem"
                        height="1.625rem"
                        style={{ marginTop: "1.375rem" }}
                    />

                    <Grid container spacing={{ md: 8 }} columns={{ md: 12 }}>
                        {Array.from(Array(3)).map((_, index) => (
                            <Grid item md={4} key={index}>
                                <Stack
                                    spacing={1}
                                    style={{ marginTop: "1.375rem" }}
                                >
                                    <Skeleton
                                        width="8.75rem"
                                        height="0.875rem"
                                    />

                                    <Skeleton height="1.25rem" />

                                    <Skeleton
                                        width="8.75rem"
                                        height="0.875rem"
                                    />

                                    <Skeleton height="1.25rem" />
                                </Stack>
                            </Grid>
                        ))}
                    </Grid>

                    <Stack spacing={1} style={{ marginTop: "1.375rem" }}>
                        <Skeleton width="8.75rem" height="0.875rem" />

                        <Skeleton height="1.625rem" />

                        <Skeleton width="8.75rem" height="0.875rem" />

                        <Skeleton height="1.625rem" />
                    </Stack>

                    <Divider />
                </Stack>
            ))}
        </div>
    );
};

export default RecordBodySkeleton;
