import { isEmpty } from "lodash"
import { CoreFormChip, CoreFormChipArray } from "../../../styled/CoreForm.chip";

export function ChipsArray<T>({
    selected = [],
    options,
    replace,
    formMethods,
    path,
    chipsLabel = '',
    chipArrayStyles = {},
    activeDelete = true,
    singleSelect = false
}) {
    if (singleSelect) {
        const deleteOption = () => {
            formMethods.setValue(path, '');
        }

        return (
            <CoreFormChipArray sx={chipArrayStyles}>
                {!isEmpty(selected) &&
                    <CoreFormChip
                        onDelete={activeDelete ? deleteOption : false}
                        label={selected}
                    />
                }
            </CoreFormChipArray>
        )
    }

    return (
        <CoreFormChipArray sx={chipArrayStyles}>
            {Array.isArray(selected) &&
                selected?.map((option) => {
                    let label = option.text || option;

                    // We may have an overrideLabel for the option
                    const selectedOptionObject = options?.find(
                        (optionObject) => optionObject.value === (option.value || option),
                    );

                    if (selectedOptionObject) {
                        const overrideLabel = selectedOptionObject?.overrideLabel;
                        const listOptionLabel = overrideLabel || selectedOptionObject.label || selectedOptionObject.text

                        label = listOptionLabel ?? label
                    }
                    
                    if (chipsLabel && selectedOptionObject && selectedOptionObject[chipsLabel]) {
                        label = selectedOptionObject[chipsLabel];
                    }

                    const deleteOption = () => {
                        replace(
                            formMethods
                                ?.getValues(path)
                                ?.filter((elem) => elem !== option),
                        );
                    }

                    return (
                        <CoreFormChip
                            key={option}
                            onDelete={activeDelete ? deleteOption : false}
                            label={label}
                        />
                    );
                })}
        </CoreFormChipArray>
    );
}