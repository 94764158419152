import React from "react";
import CheckboxTag from "../../CheckboxTag";

const RenderCheckboxTag = (props) => {
    const { selected, leftText, episode, handleSelection } = props;

    return (
        <CheckboxTag
            selected={selected ? true : false}
            leftText={leftText}
            centeredContent={episode.original_title}
            background="#458e6b"
            styles={{
                marginTop: "0.40625rem",
            }}
            handleSelection={handleSelection.bind(this, {
                selected: selected ? false : true,
                record: episode,
            })}
        />
    );
};

export default RenderCheckboxTag;
