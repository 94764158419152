import React from "react";
import _ from "lodash";
import Tag from "../Tag";
import Settings from "../Settings";
import MetaMenuItems from "../MetaMenu/MetaMenuItems";
import Button from "../Button";
import Select from "../Select";
import Notification from "../Notification";
import Input from "../../components/Input/basic";
import CategoryTags from "../CategoryTags";
import isIndexInArray from "../../components/Helpers/isIndexInArray";
import update from "react-addons-update";
import deepcopy from "deepcopy";
import "./styles/_styles.scss";
import { diff } from "deep-object-diff";
import {
    SortableContainer,
    SortableElement,
    arrayMove,
} from "react-sortable-hoc";

export default class RunningOrders extends React.Component {
    constructor(props) {
        super(props);

        let data = {};
        if (Array.isArray(this.props.data)) {
            data = this.props.data;
        }

        /**
         * @var object
         */
        this.copy = this.props.copy || {};

        /**
         * @var bool
         */
        this.componentIsMounted = false;

        /**
         * @var string
         */
        this.defaultCode = "ORG";

        /**
         * @var string
         */
        this.defaultType = "Episode";

        /**
         * @var object
         */
        this.state = {
            data,
            editIndex: false,
            description: "",
            code: "",
            notification: {},
            dragImage: {},
            removeImage: {},
            addImage: {},
            originalEpisodes: [],
            originalStorylines: [],
            type: this.defaultType,
            selectedCode: this.defaultCode,
        };
    }

    componentDidMount() {
        this.componentIsMounted = true;
        this.loadImage("dragImage", "menu-light-grey.svg");
        this.loadImage("removeImage", "clear-light-grey.svg");
        this.loadImage("addImage", "plus-sign-grey-white.svg");
        this.setOriginalData();
    }

    componentDidUpdate(prevProps, prevState) {
        const difference = diff(prevState.data, this.state.data);

        if (
            !_.isEmpty(difference) &&
            typeof this.props.handleChange == "function"
        ) {
            this.props.handleChange(this.state.data);
        }
    }

    componentWillUnmount() {
        this.componentIsMounted = false;
    }

    loadImage(stateKey, name) {
        let image = new Image();
        image.src = Settings.images.path + "/svg/" + name;

        image.onload = () => {
            if (this.componentIsMounted) {
                this.setState({
                    [stateKey]: image,
                });
            }
        };
    }

    setOriginalData() {
        const originalEpisodes = this.getOriginalRunningOrderTitles(
            CategoryTags.Episode.short,
        );
        const originalStorylines = this.getOriginalRunningOrderTitles(
            CategoryTags.Storyline.short,
        );
        const originalConstructedEpisodes = this.getOriginalRunningOrderTitles(
            CategoryTags.ConstructedEpisode.short,
        );

        this.setState({
            originalEpisodes,
            originalStorylines,
            originalConstructedEpisodes,
        });
    }

    setDefaultTypeCode() {
        const { type, selectedCode } = this.state;

        let result = selectedCode;

        let set = false;
        const originalRunningOrders = this.getRunningOrderTitles(
            this.defaultCode,
        );
        const alternativeRunningOrders = this.getAlternativeRunningOrders();

        alternativeRunningOrders.forEach((item) => {
            if (!item.deleted && !set && item.type == type) {
                result = item.code;
                set = true;
            }
        });

        if (!_.isEmpty(originalRunningOrders)) {
            result = this.defaultCode;
        }

        this.setState({
            selectedCode: result,
        });
    }

    getRunningOrderIndex() {
        const { data, type, selectedCode } = this.state;

        return data.findIndex(
            (ro) => ro.code == selectedCode && ro.type == type,
        );
    }

    getOriginalRunningOrderTitles(type) {
        const { data } = this.state;

        let result = [];

        const item = data.find(
            (item) => item.code == this.defaultCode && item.type == type,
        );

        if (typeof item == "object") {
            result = deepcopy(item.titles || []);
        }

        return result;
    }

    getRunningOrderTitles(code) {
        const { data, type } = this.state;

        let result = [];

        const item = data.find(
            (item) => item.code == code && item.type == type,
        );

        if (typeof item == "object") {
            result = deepcopy(item.titles || []);
        }

        return result;
    }

    getAlternativeRunningOrders() {
        const { data, type } = this.state;

        let result = [];

        const items = data.filter(
            (item) => item.code !== this.defaultCode && item.type == type,
        );

        if (Array.isArray(items)) {
            result = deepcopy(items);
        }

        return result;
    }

    getData() {
        const { selectedCode } = this.state;

        let runningOrderTitles = [];
        let result = [];

        // Original
        if (selectedCode == this.defaultCode) {
            runningOrderTitles = this.getRunningOrderTitles(this.defaultCode);
        }

        // Alternative
        if (selectedCode !== this.defaultCode) {
            runningOrderTitles = this.getRunningOrderTitles(selectedCode);
        }

        if (!_.isEmpty(runningOrderTitles)) {
            result = this.getBuiltTitleData(runningOrderTitles);
        }

        return result;
    }

    getBuiltTitleData(runningOrderTitles = []) {
        const { type } = this.state;

        let result = [];
        let key = false;

        if (type == CategoryTags.Episode.short) {
            key = "episodes";
        }

        if (type == CategoryTags.Storyline.short) {
            key = "storylines";
        }

        if (type == CategoryTags.ConstructedEpisode.short) {
            key = "constructedEpisodes";
        }

        runningOrderTitles.forEach((item) => {
            let categoryData = {};
            if (Array.isArray(this.props[key])) {
                categoryData = this.props[key].find(
                    (cd) => cd.meta_id == item.meta_id,
                );
            }

            if (typeof item == "object" && typeof categoryData == "object") {
                result.push({
                    meta_id: item.meta_id,
                    position: item.position || null,
                    original_title: categoryData.original_title,
                    production_number: categoryData.production_number,
                });
            }
        });

        return result;
    }

    getDropdownData() {
        let result = [];

        const originalRunningOrders = this.getRunningOrderTitles(
            this.defaultCode,
        );
        const alternativeRunningOrders = this.getAlternativeRunningOrders();

        if (!_.isEmpty(originalRunningOrders)) {
            result.push({
                text: "Original • ORG",
                value: this.defaultCode,
            });
        }

        alternativeRunningOrders.forEach((item) => {
            if (!item.deleted) {
                let text = item.code;
                if (item.description)
                    text = item.description + " • " + item.code;

                result.push({
                    text,
                    value: item.code,
                });
            }
        });

        return result;
    }

    getInactiveRecords() {
        const {
            selectedCode,
            originalEpisodes,
            originalStorylines,
            originalConstructedEpisodes,
            type,
        } = this.state;

        let result = [];
        let originalEpisodesData = deepcopy(originalEpisodes);

        if (selectedCode !== this.defaultCode) {
            const runningOrder = this.getRunningOrderTitles(selectedCode);

            if (Array.isArray(runningOrder)) {
                const runningOrderIds = runningOrder.map(
                    (item) => item.meta_id,
                );

                let data = originalEpisodesData;

                if (type == CategoryTags.Storyline.short) {
                    data = originalStorylines;
                }

                if (type == CategoryTags.ConstructedEpisode.short) {
                    data = originalConstructedEpisodes;
                }

                data.forEach((item) => {
                    if (!isIndexInArray(runningOrderIds, item.meta_id)) {
                        result.push({
                            meta_id: item.meta_id,
                        });
                    }
                });

                result = this.getBuiltTitleData(result);
            }
        }

        return result;
    }

    getNotificationObject() {
        const { description, code, editIndex, data } = this.state;

        let notification = {};

        let disabled = true;
        let errorMessage = false;
        let error =
            this.copy.runningOrderCodeExistsError ||
            "Running Order Code Already Exists within this Season";

        if (typeof description == "string" && typeof code == "string") {
            if (description && code.length == 3) {
                disabled = false;
            }

            if (editIndex === false) {
                // Validates unique code

                const alternativeRunningOrders =
                    this.getAlternativeRunningOrders();
                alternativeRunningOrders.forEach((item) => {
                    if (typeof item.code == "string") {
                        if (
                            item.code.toLowerCase() == code.toLowerCase() &&
                            !item.deleted
                        ) {
                            disabled = true;
                            errorMessage = error;
                        }
                    }
                });
            }

            // If code is original
            if (code.toLowerCase() == this.defaultCode.toLowerCase()) {
                errorMessage = error;
            }

            notification = {
                title: this.copy.episodeRunningOrder || "Episode Running Order",
                html: this.renderNotificationHtml(),
                confirm: Number.isInteger(editIndex)
                    ? this.editRunningOrder.bind(this)
                    : this.createNewRunningOrder.bind(this),
                disabled,
                errorMessage,
            };
        }

        return notification;
    }

    editRunningOrder() {
        const { data, description, code, selectedCode } = this.state;

        let dataCopy = deepcopy(data);
        let item = dataCopy.find((item) => item.code == selectedCode);

        if (typeof item == "object") {
            item.code = code;
            item.description = description;
        }

        if (typeof dataCopy == "object") {
            this.setState({
                data: dataCopy,
                notification: {},
                description: "",
                code: "",
                editIndex: false,
            });
        }
    }

    createNewRunningOrder() {
        const {
            data,
            description,
            code,
            type,
            originalEpisodes,
            originalStorylines,
            originalConstructedEpisodes,
        } = this.state;

        let titles = originalEpisodes;

        if (type == CategoryTags.Storyline.short) {
            titles = originalStorylines;
        }

        if (type == CategoryTags.ConstructedEpisode.short) {
            titles = originalConstructedEpisodes;
        }

        const runningOrder = {
            description,
            code,
            type,
            titles,
        };

        const updatedData = update(data, {
            $push: [runningOrder],
        });

        this.setState({
            data: updatedData,
            selectedCode: code,
            notification: {},
            description: "",
            code: "",
        });
    }

    sortRunningOrder(runningOrder = []) {
        return runningOrder.sort((a, b) => a.position - b.position);
    }

    sortPosition(runningOrder = []) {
        let position = 1;

        runningOrder.forEach((item) => {
            item.position = position;
            position++;
        });

        return runningOrder;
    }

    deleteRunningOrder() {
        const { data } = this.state;

        let dataCopy = deepcopy(data);
        const index = this.getRunningOrderIndex();

        const updatedData = update(dataCopy, {
            [index]: {
                ["deleted"]: {
                    $set: true,
                },
            },
        });

        this.setState({
            data: updatedData,
            type: this.defaultType,
            selectedCode: this.defaultCode,
            notification: {},
        });
    }

    handleChange(key, value) {
        this.setState(
            {
                [key]: value,
            },
            () => {
                if (key == "description" || key == "code") {
                    this.setState({
                        notification: this.getNotificationObject(),
                    });
                }
            },
        );
    }

    handleNewRunningOrderClick() {
        if (!this.props.disabled) {
            this.setState({
                notification: this.getNotificationObject(),
            });
        }
    }

    handleNotificationClose() {
        this.setState({
            description: "",
            code: "",
            notification: {},
        });
    }

    handleCategoryChange(type) {
        if (typeof type == "string") {
            this.setState(
                {
                    type,
                },
                () => {
                    this.setDefaultTypeCode();
                },
            );
        }
    }

    handleManageRunningOrder() {
        const { selectedCode, type } = this.state;

        if (selectedCode !== this.defaultCode) {
            const alternativeRunningOrders = this.getAlternativeRunningOrders();

            let row = false;
            let editIndex = false;

            alternativeRunningOrders.forEach((item, index) => {
                if (
                    item.code == selectedCode &&
                    item.type == type &&
                    !item.deleted
                ) {
                    row = item;
                    editIndex = index;
                }
            });

            if (typeof row == "object") {
                this.setState(
                    {
                        description: row.description,
                        code: row.code,
                        editIndex: editIndex,
                    },
                    () => {
                        this.setState({
                            notification: this.getNotificationObject(),
                        });
                    },
                );
            }
        }
    }

    handleDeleteRunningOrder() {
        const notification = {
            title: this.copy.deleteRunningOrder || "Delete Running Order",
            description:
                this.copy.deleteRunningOrderDescription ||
                "Are you sure you want to delete this running order?",
            confirm: this.deleteRunningOrder.bind(this),
            disabled: false,
        };

        this.setState({
            notification,
        });
    }

    handleOrderOnMove(movedData) {
        const { data, selectedCode } = this.state;

        let titles = this.getRunningOrderTitles(selectedCode);
        let index = this.getRunningOrderIndex();

        if (Array.isArray(titles)) {
            // Orgonises array by position
            titles = titles.sort((a, b) => a.position - b.position);
            let orderedData = arrayMove(
                titles,
                movedData.oldIndex,
                movedData.newIndex,
            );

            if (Array.isArray(orderedData)) {
                orderedData.map((item, index) => {
                    item.position = index + 1;
                });

                const updatedData = update(data, {
                    [index]: {
                        ["titles"]: {
                            $set: orderedData,
                        },
                    },
                });

                if (typeof updatedData == "object") {
                    this.setState({
                        data: updatedData,
                    });
                }
            }
        }
    }

    handleRemoveRecordFromRunningOrder(index) {
        const { data, selectedCode } = this.state;

        const runningOrderIndex = this.getRunningOrderIndex();
        let titles = this.getRunningOrderTitles(selectedCode);

        titles = update(titles, {
            $splice: [[index, 1]],
        });

        titles = this.sortRunningOrder(titles);
        titles = this.sortPosition(titles);

        const updatedData = update(data, {
            [runningOrderIndex]: {
                ["titles"]: {
                    $set: titles,
                },
            },
        });

        this.setState({
            data: updatedData,
        });
    }

    handleAddRecordToRunningOrder(item) {
        const { data, selectedCode } = this.state;

        const runningOrderIndex = this.getRunningOrderIndex();
        let titles = this.getRunningOrderTitles(selectedCode);
        let position = 1;

        titles.forEach((item) => position++);
        titles.push({
            meta_id: item.meta_id,
            position,
        });

        const updatedData = update(data, {
            [runningOrderIndex]: {
                ["titles"]: {
                    $set: titles,
                },
            },
        });

        this.setState({
            data: updatedData,
        });
    }

    renderNotificationHtml() {
        const { description, code } = this.state;

        return (
            <div>
                <Input
                    label={this.copy.runningOrderName || "Running Order Name"}
                    placeholder={
                        this.copy.enterRunningOrderName ||
                        "Enter Running Order Name"
                    }
                    id="description"
                    value={description}
                    name="description"
                    recordBasic={true}
                    margin="1.84375rem 0 0"
                    id="meta-running-order-description"
                    noDelay={false}
                    handleChange={(item) =>
                        this.handleChange("description", item)
                    }
                />

                <Input
                    label={
                        this.copy.runningOrderCode3Characters ||
                        "Running Order Code (3 Characters)"
                    }
                    placeholder={
                        this.copy.enterRunningOrderCode ||
                        "Enter Running Order Code"
                    }
                    id="code"
                    value={code}
                    name="code"
                    recordBasic={true}
                    maxLength={3}
                    margin="1.84375rem 0 0"
                    id="meta-running-order-code"
                    noDelay={false}
                    handleChange={(item) => this.handleChange("code", item)}
                />
            </div>
        );
    }

    renderRow(item, rowIndex = false, inactive) {
        const { dragImage, removeImage, addImage, selectedCode, type } =
            this.state;

        if (typeof item == "object") {
            const index = item.index || rowIndex;
            let evenIndex = index % 2;

            return (
                <div
                    className={`meta-running-orders--row ${
                        evenIndex && "even"
                    }`}
                    key={index}
                >
                    {!inactive && (
                        <div
                            className={`meta-running-orders--position ${type} ${
                                evenIndex && "even"
                            }`}
                        >
                            {item.position}
                        </div>
                    )}

                    {inactive && (
                        <div
                            className={`meta-running-orders--position inactive ${
                                evenIndex && "even"
                            }`}
                        >
                            -
                        </div>
                    )}

                    <div className="meta-running-orders--content">
                        <span className="meta-running-orders--original-title">
                            {item.original_title}
                        </span>

                        <div className="meta-running-orders--content-right">
                            {item.production_number && (
                                <Tag
                                    text={item.production_number}
                                    background="transparent"
                                    className="meta-running-orders--production-number"
                                    styles={{
                                        border: "0.0625rem solid #67567e",
                                        color: "rgba(110, 110, 110, 0.87)",
                                        fontSize: "0.8125rem",
                                    }}
                                />
                            )}

                            {item.meta_id && (
                                <span className="meta-running-orders--meta-id">
                                    {item.meta_id}
                                </span>
                            )}

                            <div className="meta-running-orders--drag">
                                {!this.props.disabled && !inactive && (
                                    <img
                                        src={dragImage.src}
                                        className="meta-running-orders--image"
                                    />
                                )}
                            </div>

                            {selectedCode !== this.defaultCode && (
                                <div className="meta-running-orders--remove">
                                    {!this.props.disabled && !inactive && (
                                        <img
                                            src={removeImage.src}
                                            className="meta-running-orders--image meta-running-orders--remove-record"
                                            onClick={this.handleRemoveRecordFromRunningOrder.bind(
                                                this,
                                                index,
                                            )}
                                        />
                                    )}

                                    {!this.props.disabled && inactive && (
                                        <img
                                            src={addImage.src}
                                            className="meta-running-orders--image"
                                            className="meta-running-orders--image meta-running-orders--add-record"
                                            onClick={this.handleAddRecordToRunningOrder.bind(
                                                this,
                                                item,
                                            )}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            );
        }

        return false;
    }

    renderDivider(runningOrder, inactiveRecords, selectedCode) {
        if (selectedCode !== this.defaultCode) {
            if (runningOrder.length > 0 && inactiveRecords.length > 0) {
                return <div className="meta-running-orders--divider"></div>;
            }
        }

        return false;
    }

    render() {
        const { selectedCode, type } = this.state;

        const {
            disabled,
            clientFeatures = {},
            canCreateNew = true,
        } = this.props;

        const { seasonRunningOrderDragDrop = true } = clientFeatures;

        const runningOrder = this.getData();
        const dropdownData = this.getDropdownData();
        const inactiveRecords = this.getInactiveRecords();

        const sortableOptions = {
            axis: "xy",
            lockToContainerEdges: true,
            lockOffset: "0%",
            helperClass: "sortableHelper",
            distance: 2,
        };

        const SortableList = SortableContainer(() => {
            const sortedRunningOrder = this.sortRunningOrder(runningOrder);

            return (
                <div
                    style={{
                        display: "inline-block",
                        width: "100%",
                        verticalAlign: "top",
                    }}
                >
                    {sortedRunningOrder.map((item, index) => {
                        const sortableItem = {
                            ...item,
                            index,
                        };

                        return (
                            <SortableItem
                                item={sortableItem}
                                key={`item-${index}`}
                                disabled={
                                    disabled || !seasonRunningOrderDragDrop
                                }
                                index={index}
                            />
                        );
                    })}
                </div>
            );
        });

        const SortableItem = SortableElement(({ item }) => {
            if (typeof item == "object") {
                const inactive = false;

                return this.renderRow(item, item.index, inactive);
            }

            return false;
        });

        if (Array.isArray(runningOrder)) {
            return (
                <div>
                    <div className="meta-running-orders--options">
                        <div className="meta-running-orders--options-content">
                            <MetaMenuItems
                                menuActive={type}
                                menu={[
                                    {
                                        text: this.copy.episodes || "Episodes",
                                        name: CategoryTags.Episode.short,
                                    },
                                    {
                                        text:
                                            this.copy.storylines ||
                                            "Storylines",
                                        name: CategoryTags.Storyline.short,
                                    },
                                    {
                                        text: "Episodes+",
                                        name: CategoryTags.ConstructedEpisode
                                            .short,
                                    },
                                ]}
                                styleSheet="RunningOrders"
                                handleOnClick={this.handleCategoryChange.bind(
                                    this,
                                )}
                            />

                            <Select
                                name="type"
                                id="running-orders-type"
                                value={selectedCode}
                                clear={true}
                                customPadding="0.46875rem 1.8125rem 0.46875rem 0.59375rem"
                                noMargin={true}
                                fontSize="0.78125rem"
                                chevron="chevron-light-grey.svg"
                                disabledText={
                                    this.copy.selectRunningOrder ||
                                    "Select Running Order"
                                }
                                noneSelectedText={
                                    this.copy.noneSelected || "None Selected"
                                }
                                data={dropdownData}
                                wrapperStyles={{
                                    maxWidth: "16.46875rem",
                                    marginLeft: "1rem",
                                }}
                                handleChange={(item) =>
                                    this.handleChange("selectedCode", item)
                                }
                                showNullOption={false}
                            />

                            {selectedCode !== "ORG" && !disabled && (
                                <React.Fragment>
                                    <Button
                                        value={this.copy.manage || "Manage"}
                                        className="meta-manage-running-order"
                                        type="button"
                                        margin="0 0 0 1rem"
                                        padding="0.625rem 0.8125rem"
                                        background="#d3d3d3"
                                        hoverBackground="#d3d3d3"
                                        color="#868484"
                                        fontSize="0.78125rem"
                                        fontWeight="500"
                                        border="0.0625rem solid #8f8f8f"
                                        borderRadius="0.25rem"
                                        onClick={this.handleManageRunningOrder.bind(
                                            this,
                                        )}
                                    />

                                    <Button
                                        value={this.copy.delete || "Delete"}
                                        className="meta-delete-running-order"
                                        type="button"
                                        margin="0 0 0 1rem"
                                        padding="0.625rem 0.8125rem"
                                        background="#e8375c"
                                        hoverBackground="#e8375c"
                                        color="white"
                                        fontSize="0.78125rem"
                                        fontWeight="500"
                                        border="0.0625rem solid #8f8f8f"
                                        borderRadius="0.25rem"
                                        onClick={this.handleDeleteRunningOrder.bind(
                                            this,
                                        )}
                                    />
                                </React.Fragment>
                            )}

                            {!disabled && (
                                <Button
                                    value={
                                        this.copy.createNewRunningOrder ||
                                        "Create New Running Order"
                                    }
                                    className="meta-create-running-order"
                                    type="button"
                                    margin="0 0 0 auto"
                                    padding="0.5625rem 0.6875rem"
                                    background="#8f8f8f"
                                    hoverBackground="#8f8f8f"
                                    color="white"
                                    fontSize="0.78125rem"
                                    fontWeight="500"
                                    border="0.0625rem solid #8f8f8f"
                                    borderRadius="0.25rem"
                                    leftIcon={
                                        Settings.images.path +
                                        "/svg/plus-sign-white-grey.svg"
                                    }
                                    leftIconStyles={{
                                        width: "0.75rem",
                                    }}
                                    onClick={this.handleNewRunningOrderClick.bind(
                                        this,
                                    )}
                                    disabled={!canCreateNew}
                                />
                            )}
                        </div>
                    </div>

                    <SortableList
                        onSortEnd={(data) => this.handleOrderOnMove(data)}
                        {...sortableOptions}
                    />

                    {this.renderDivider(
                        runningOrder,
                        inactiveRecords,
                        selectedCode,
                    )}

                    {selectedCode !== this.defaultCode &&
                        inactiveRecords.map((item, index) => {
                            const inactive = true;

                            return this.renderRow(item, index, inactive);
                        })}

                    <Notification
                        title={this.state.notification.title || false}
                        description={
                            this.state.notification.description || false
                        }
                        html={this.state.notification.html || false}
                        confirm={this.state.notification.confirm || false}
                        disabled={this.state.notification.disabled || false}
                        intercationStatus={
                            this.state.notification.intercationStatus || "alert"
                        }
                        onClick={this.handleNotificationClose.bind(this)}
                        okText={this.state.notification.okText || undefined}
                        errorMessage={this.state.notification.errorMessage}
                        background="#eeeeee"
                        closeOnWrapperClick={false}
                        confirmOnEnter={true}
                    />
                </div>
            );
        }

        return false;
    }
}
