import { CreditInterface, CreditListInterface, CreditRankMap, DataTablePayload } from "../../credits.interface";

export default function detachGroupCreditsFromList(
    selectedCredits: DataTablePayload[],
    credits: CreditInterface[],
    activeCreditList: CreditListInterface
): CreditInterface[] {
    return credits.map((credit: CreditInterface) => {
        const selectedCreditIndex = selectedCredits.findIndex(
            (creditData: DataTablePayload) =>
                creditData.localID === credit.local_id
        );

        if (selectedCreditIndex === -1) {
            return credit;
        }

        const listIndex = credit.credit_lists.findIndex(
            (creditList: CreditRankMap) => creditList.code === activeCreditList.code
        );

        if (listIndex === -1) {
            return credit;
        }

        credit.ranking = false
        const updatedCreditLists = [...credit.credit_lists];
        updatedCreditLists[listIndex].deleted = true;

        return {
            ...credit,
            credit_lists: updatedCreditLists,
        };
    })
}