import { SET_BADRESPONSE } from "../actions/action_types";
import { defaultBadResponse } from "../initialState";

export default function (state = { badResponse: defaultBadResponse }, action) {
    switch (action.type) {
        case SET_BADRESPONSE:
            return Object.assign({}, state, {
                badResponse: action.badResponse,
            });
    }

    return state;
}
