import { Box, ListItem, Stack, Switch, SxProps } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useEffect } from "react";
import { Controller, FieldValues, useFormContext } from "react-hook-form";
import { FieldPath } from "../../../types";
import { CoreFormFieldError } from "../../styled/CoreFormFieldError";
import { CoreFormListItemText } from "../../styled/CoreFormListItemText";
import { CoreTooltip } from "../../../../Tooltip/CoreTooltip";

export type CoreInputSwitchProps<T extends FieldValues> = {
    path: FieldPath<T>;
    label: string;
    labelColor?: string; // TODO: add corresponding type instead of string
    customStyles?: React.CSSProperties;
    textInputChild?: {
        component: React.ReactNode;
        path?: FieldPath<T>;
    };
    tooltip?: ReactNode;
    inputHoverText?: string
    disabled?: boolean;
    listItemStyles?: SxProps
    stackProps?: {
        direction: "row" | "column";
        alignItems: string;
    }
};

export function CoreInputSwitch<T extends FieldValues>({
    path,
    label,
    labelColor,
    customStyles,
    textInputChild,
    tooltip,
    inputHoverText,
    disabled = false,
    listItemStyles = {},
    stackProps = {
        direction: "row",
        alignItems: "center",
    }
}: CoreInputSwitchProps<T>) {
    const formMethods = useFormContext<T>();
    const errorMessage = _.get(formMethods.formState.errors, path)?.message; // formMethods?.getFieldState(path).error?.message;
    const watchSwitch = formMethods.watch(path);

    useEffect(() => {
        if (watchSwitch === false && textInputChild?.path) {
            formMethods.resetField(textInputChild?.path);
        }
    }, [textInputChild?.path, watchSwitch]);

    return (
        <>
            <ListItem sx={listItemStyles}>
                <Stack justifyContent="space-between" width="100%" {...stackProps}>
                    <Box display={'flex'}>
                        <CoreFormListItemText
                            primary={label}
                            sx={{
                                ...customStyles,
                                color: labelColor
                            }}
                        />

                        {tooltip && (
                            <Box marginTop={-1}>
                                {tooltip}
                            </Box>
                        )}
                    </Box>
                    <CoreTooltip title={inputHoverText} arrow>
                        <Box>
                            <Controller
                                name={path}
                                control={formMethods?.control}
                                defaultValue={false}
                                render={({ field }) => {
                                    return <Switch
                                        checked={field.value}
                                        data-testid={`switch-${path}`}
                                        {...field}
                                        disabled={disabled}
                                    />
                                }}
                            />
                        </Box>
                    </CoreTooltip>
                </Stack>
                {errorMessage && (
                    <CoreFormFieldError path={path} message={errorMessage} />
                )}
            </ListItem>
            {watchSwitch && textInputChild?.component}
        </>
    );
}
CoreInputSwitch.defaultProps = {
    textInputChild: null,
    labelColor: "rgba(47, 47, 47, 0.57)",
};
