import { BuilderFieldConfigInterface, FieldConfigInterface } from "../../../interfaces";
import { ValidationLayer } from "../../../../RecordBuilder/Field.interface";

const transformBuilderPayload = (configs: BuilderFieldConfigInterface[], fieldType: string): FieldConfigInterface[] => {
    const transformedConfigs: FieldConfigInterface[] = configs.map(item => {
        
        const {
            displayName,
            key,
            options,
        } = item

        const {
            validation
        } = options

        const {
            characterLimit,
            required
        } = validation

        return {
            [fieldType]: displayName,
            type: key,
            length: characterLimit.enabled && characterLimit.validation != ValidationLayer.None ? characterLimit.limit : null,
            required: required.enabled,
        }
    })

    return transformedConfigs;
}

export default transformBuilderPayload;
