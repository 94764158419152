import CategoryTags from "../../../components/CategoryTags";

const DeleteRecordValidation = (props) => {
    const { record = {}, copy = {} } = props;

    const { category, linkage } = record;

    let result = {};

    if (Array.isArray(linkage)) {
        // Season
        if (category == CategoryTags.Season.short) {
            const episodes = linkage.filter(
                (e) => e.category == CategoryTags.Episode.short,
            );

            if (!_.isEmpty(episodes)) {
                result = {
                    title: copy.cannotDeleteSeason || "Cannot delete a Season",
                    description:
                        copy.cannotDeleteSeasonDescription ||
                        "Cannot delete a Season with Existing Episodes, you need to delete the Episodes first.",
                };
            }
        }

        // Show
        if (category == CategoryTags.Show.short) {
            const seasons = linkage.filter(
                (e) => e.category == CategoryTags.Season.short,
            );

            if (!_.isEmpty(seasons)) {
                result = {
                    title: copy.cannotDeleteShow || "Cannot delete a Show",
                    description:
                        copy.cannotDeleteShowDescription ||
                        "Cannot Delete a Show with Existing Seasons, you need to delete the Seasons first.",
                };
            }
        }
    }

    return result;
};

export default DeleteRecordValidation;
