import React from 'react'
import { makeObservable, action, extendObservable } from 'mobx'
import { GridRowParams } from "@mui/x-data-grid";
import update from "react-addons-update";
import { RootStore } from './rootStore'
import { activeCreditsStoreInitialState } from "./state"
import { CreditInterface, CreditRowInterface } from './interfaces';
import _, { sortBy } from 'lodash';
import { generateTableRows } from '../utils/CreditsTable/rows';
import { CoreTableConfig } from '../../../../core/Table/types';
import groupCreditsByRole from '../../v3/services/CreditService/helpers/groupCreditsByRole';

export class ActiveCreditsStore {
    rootStore: RootStore;
    activeCredits: CreditInterface[];
    activeTableCredits: CreditRowInterface[];
    tableConfig: CoreTableConfig;
    selected: string[]
    selectedId: string;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            initialize: action,
            setActiveCredits: action,
            setTableConfig: action,
            setSelected: action,
            setSelectedId: action,

            handleSetConfig: action,
            handleDeleteCredit: action,
            handleDeleteCreditsInBulk: action,
            handleMoveCreditsToInactiveInBulk: action,
            handleOrderCredits: action,
        });

        extendObservable(this, activeCreditsStoreInitialState);
    }

    initialize() {
        this.reset()
    }

    reset() {
        Object.keys(activeCreditsStoreInitialState).forEach(
            (key) => (this[key] = activeCreditsStoreInitialState[key]),
        );
    }

    setActiveCredits(activeCredits: CreditInterface[]): void {
        this.activeCredits = activeCredits
    }

    setActiveTableCredits(activeTableCredits: CreditRowInterface[]): void {
        this.activeTableCredits = activeTableCredits
    }

    setTableConfig(tableConfig: CoreTableConfig): void {
        this.tableConfig = tableConfig
    }

    setSelected(selected: string[]): void {
        this.selected = selected
    }

    setSelectedId(selectedId: string): void {
        this.selectedId = selectedId
    }

    getActiveTableCreditById(id: string): CreditRowInterface {
        return this.activeTableCredits.find((credit: CreditRowInterface) => credit.id === id)
    }

    getOrderedCreditRowsByRole(credits: CreditRowInterface[]): CreditRowInterface[] {
        const groupedCredits = groupCreditsByRole(credits, 'role')

        return Object.values(groupedCredits)
            ?.map(group => {
                return group.map((credit, index: number) => {
                    if (!credit.inactive) {
                        credit.ranking = index + 1
                    }

                    return {
                        ...credit
                    };
                })
            })
            ?.flat()
    }

    getActiveCredits(): CreditInterface[] {
        const credits: CreditInterface[] = this.rootStore.creditsStore.getCredits()
        
        return credits
            ?.filter((credit: CreditInterface) => this.rootStore.creditsStore.isCreditInActiveCreditList(credit))
            ?.map((credit: CreditInterface) => {
                return {
                    ...credit,
                    ranking: this.rootStore.creditListsStore.getCreditRanking(credit)
                }
            }) || [];
    }

    handleSetCredits(): void {
        const credits: CreditInterface[] = this.getActiveCredits()
        this.setActiveCredits(credits)

        let tableCredits: CreditRowInterface[] = generateTableRows(credits, this.rootStore.contributorsStore.contributors, this.rootStore.optionsStore.roles, this.rootStore.optionsStore.languageCode)
        tableCredits = sortBy(tableCredits, ['roleID', 'ranking'])

        this.setActiveTableCredits(tableCredits)
    }

    handleSetConfig(): void {
        this.setTableConfig({
            variant: "default",
            state: 'edit',
            emptyFallback: "No Active Credits",
            tableName: "ActiveCredits",
            leftFooterText: this.rootStore.creditListsStore.getCreditListFooterText(),
            resetCounter: 0
        })
    }

    handleDeleteCredit(params: GridRowParams): void {
        this.rootStore.creditsStore.handleDeleteCredit(params.row)
        this.handleSetCredits()

        this.rootStore.handleChange()
    }

    handleDeleteCreditsInBulk(): void {
        this.selected?.forEach((id: string) => {
            const tableCredit: CreditRowInterface = this.getActiveTableCreditById(id)

            if (tableCredit) {
                this.rootStore.creditsStore.handleDeleteCredit(tableCredit)
            }
        })

        this.handleSetCredits()
        this.rootStore.handleChange()
    }

    handleMoveCreditsToInactiveInBulk(): void {
        this.selected?.forEach((id: string) => {
            const tableCredit: CreditRowInterface = this.getActiveTableCreditById(id)

            if (tableCredit) {
                this.rootStore.creditListsStore.handleDeleteCreditFromActiveCreditList(tableCredit)
            }
        })

        this.handleSetCredits()
        this.rootStore.inactiveCreditsStore.handleSetCredits()
    }

    handleOrderCredits(movedData: any): void {
        const oldTableCredit: CreditRowInterface = this.getActiveTableCreditById(this.activeTableCredits[movedData.oldIndex].id)
        const newTableCredit: CreditRowInterface = this.getActiveTableCreditById(this.activeTableCredits[movedData.newIndex].id)

        if (oldTableCredit && newTableCredit) {
            const creditListIndex: number = this.rootStore.creditListsStore.getActiveCreditListIndex()

            const oldCreditIndex: number = this.rootStore.creditListsStore.getActiveCreditListCreditIndex({
                id: oldTableCredit.creditID,
                role_id: oldTableCredit.roleID,
                contributor_id: oldTableCredit.contributorID,
            })

            const newCreditIndex: number = this.rootStore.creditListsStore.getActiveCreditListCreditIndex({
                id: newTableCredit.creditID,
                role_id: newTableCredit.roleID,
                contributor_id: newTableCredit.contributorID,
            })

            if (oldCreditIndex === -1 || newCreditIndex === -1) {
                return this.rootStore.handleDispatchError("Failed to sort credits")
            }

            const result = update(this.rootStore.creditListsStore.creditLists, {
                [creditListIndex]: {
                    credits: {
                        [oldCreditIndex]: {
                            ranking: {
                                $set: movedData.newIndex + 1,
                            },
                        },
                        [newCreditIndex]: {
                            ranking: {
                                $set: movedData.oldIndex + 1,
                            },
                        },
                    },
                }
            });

            this.rootStore.creditListsStore.setCreditLists(result)
            this.rootStore.handleChange()
            this.handleSetCredits()
            
        } else {
            this.rootStore.handleDispatchError("Failed to sort credits")
        }
    }
}
