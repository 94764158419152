import { CreditInterface } from "../../../credits.interface";

export default function getContributorScriptedNamesValue(
    credit: CreditInterface,
    disabled: boolean,
    isScript: boolean,
    script: string,
) {
    const {
        script_names = []
    } = credit

    let result = {
        name: '',
        namePlaceholder: '-'
    }

    const enterName: string = 'Enter Name'

    result.namePlaceholder = enterName
 
    const getName = (credit: CreditInterface) => {
        let result: string = ""

        if (credit.name) {
            result = credit.name
        }

        if (credit.first_name) {
            if (credit.middle_names) {
                result = `${credit.first_name} ${credit.middle_names || ""} ${credit.last_name || ""}`
            } else {
                result = `${credit.first_name} ${credit.last_name || ""}`
            }
        }

        return result.trim()
    }

    if (Object.keys(credit).length > 0) {
        if (credit.hasOwnProperty("name")) {
            result.name = getName(credit)
        }
    }

    if (isScript) {
        let scriptedName = {}

        if (Array.isArray(script_names) && script_names.length > 0) {
            scriptedName = script_names.find(name => name.code === script) || {}
        }

        result = {
            name: getName(scriptedName as CreditInterface),
            namePlaceholder: credit.name || enterName
        }
    }

    return result
}