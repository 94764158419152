import { GridRenderCellParams } from "@mui/x-data-grid";
import { CoreTableCell } from "../../components/CoreTableCell";
import { CoreTableGridColDef } from "../../types";
import SeasonName from "../../../../components/SeasonName";
import { isSeason } from "../../../../components/CategoryTags/SeasonCategories";

export const TitleCol: CoreTableGridColDef = {
    field: "original_title",
    headerName: "Original Title",
    flex: 3,
    renderCell: (params: GridRenderCellParams<string>) => {
        const { row } = params;

        let title = row.original_title;

        if (isSeason(row.category) && !title) {
            if (Number.isInteger(row.season_number)) {
                title = SeasonName(row);
            }
        }

        // Defaults to meta id
        if (!title && row.meta_id) {
            title = row.meta_id;
        }

        return <CoreTableCell boldText>{title}</CoreTableCell>;
    },
};
