import NavItemInterface from "./NavItemInterface";
import _ from "lodash"

import { discoveryOption } from "../../../features/UserRoleManagement/utils/sectionOptions/recordSections";
import { collectionsOption } from "../../../features/UserRoleManagement/utils/sectionOptions/collections";
import { localisationsOption } from "../../../features/UserRoleManagement/utils/sectionOptions/localisations";
import { reportingOption } from "../../../features/UserRoleManagement/utils/sectionOptions/reporting";
import { taskManagerOption } from "../../../features/UserRoleManagement/utils/sectionOptions/taskManager";
import { userGroupsOption } from "../../../features/UserRoleManagement/utils/sectionOptions/userGroups";
import { platformManagementOption } from "../../../features/UserRoleManagement/utils/sectionOptions/applicationSettings";
import { exportsOption } from "../../../features/UserRoleManagement/utils/sectionOptions/exports";
import { recordBuilderOption } from "../../../features/UserRoleManagement/utils/sectionOptions/recordBuilder";
import { userRolesAndPoliciesOption } from "../../../features/UserRoleManagement/utils/sectionOptions/userRolesAndPolicies";
import { swimlanesOption } from "../../../features/UserRoleManagement/utils/sectionOptions/swimlanes";

import { IsEnabled, IsVersionV1 } from "../../../components/RecordBuilder/IsActive";

export const navItemBack: NavItemInterface = {
    item: "go-back",
    icon: "go-back-arrow-menu.svg",
    text: "Back To Home",
    permission: "discovery.view",
};

const getReportingSubMenu = (
    active: string,
    features: any
): NavItemInterface[] => {
    if (features && features.subMenu) {
        return [        {
            item: 'reporting-submenu',
            icon: 'reporting-submenu.svg',
            text: 'Reporting',
            permission: 'collections.view',
            customClass: active === "reporting-submenu" ? "active" : "",
            secondIcon: 'go-forward-arrow-menu.svg',
            policyPermission: {
                pathField: reportingOption.path
            },
            subMenu: [
                {
                    section: "Catalogue Reporting",
                    items: [
                        {
                            item: "reportingLooker",
                            icon: "insights-grey-v2.svg",
                            text: "Insights",
                            permission: "reporting.looker",
                            customClass: active === "reportingLooker" ? "active" : "",
                            featureToggle: "looker",
                        },
                        {
                            item: "reportingExplore",
                            icon: "explore-grey-v2.svg",
                            text: "Explore",
                            permission: "reporting.looker",
                            customClass: active === "reportingExplore" ? "active" : "",
                            featureToggle: "explore",
                        },
                    ],
                },
                {
                    section: "Saved Reports",
                    items: [
                        {
                            item: "fabricStudiosCatalogSnapshot",
                            icon: "nav-saved-report.svg",
                            text: "Fabric Studios • Catalog Snapshot",
                            permission: "reporting.looker",
                            customClass:
                                active === "fabricStudiosCatalogSnapshot"
                                    ? "active"
                                    : "",
                        },
                        {
                            item: "titleDuplicateDashboard",
                            icon: "nav-saved-report.svg",
                            text: "Title Duplicate Dashboard",
                            permission: "reporting.looker",
                            customClass:
                                active === "titleDuplicateDashboard" ? "active" : "",
                        },
                        {
                            item: "d2CDeliveryDashboard",
                            icon: "nav-saved-report.svg",
                            text: "D2C Regional Delivery Dashboard",
                            permission: "reporting.looker",
                            customClass:
                                active === "d2CDeliveryDashboard" ? "active" : "",
                        },
                        {
                            item: "q3VODDeliveryDashboard",
                            icon: "nav-saved-report.svg",
                            text: "Q3 VOD Partner Delivery Dashboard",
                            permission: "reporting.looker",
                            customClass:
                                active === "q3VODDeliveryDashboard" ? "active" : "",
                        },
                        {
                            item: "localisationStatusDashboard",
                            icon: "nav-saved-report.svg",
                            text: "Localisation Status Dashboard",
                            permission: "reporting.looker",
                            customClass:
                                active === "localisationStatusDashboard"
                                    ? "active"
                                    : "",
                        },
                    ],
                },
            ],
        }]
    }

    return [
        {
            item: "reportingLooker",
            icon: "insights-grey-v2.svg",
            text: "Insights",
            permission: "reporting.looker",
            customClass: active === "reportingLooker" ? "active" : "",
            featureToggle: "looker",
            policyPermission: {
                pathField: reportingOption.path,
            },
        },
        {
            item: "reportingExplore",
            icon: "explore-grey-v2.svg",
            text: "Explore",
            permission: "reporting.looker",
            customClass: active === "reportingExplore" ? "active" : "",
            featureToggle: "explore",
            policyPermission: {
                pathField: reportingOption.path,
            },
        },
    ]

};

const getRecordBuilderOption = (active: string, features): NavItemInterface => {
    if (features
        && IsEnabled(features)
        && IsVersionV1(features)
    ) {
        return {
            item: "recordBuilder",
            icon: "record-builder-grey.svg",
            text: "Record Builder",
            permission: "recordBuilder",
            featureToggle: "recordBuilder",
            customClass: active === "recordBuilder" ? "active" : "",
            policyPermission: {
                pathField: recordBuilderOption.path,
            },
        }
    }

    return {}
}

function getPreferencesOption(active: string, features: any): NavItemInterface {
    if (features && features.preferences) {
        return {
            item: "preferences",
            icon: "preferences-grey.svg",
            text: "Preferences",
            permission: "platformManagement",
            customClass: active === "preferences" ? "active" : "",
            policyPermission: {
                pathField: platformManagementOption.path,
            },
            featureToggle: "preferences",
        };
    }

    return {
        item: "platformManagement",
        icon: "settings-grey-v2.svg",
        text: "Settings",
        permission: "platformManagement",
        customClass: active === "platformManagement" ? "active" : "",
        policyPermission: {
            pathField: platformManagementOption.path,
        },
    };
}

export const navMainMenu = (
    active: string,
    features: any,
    reporting: any
): Array<NavItemInterface> => {
    const { legacyDiscovery = "true" } = features;

    return [
        {
            item: "recordsSearch",
            icon: "records-search-grey.svg",
            text: legacyDiscovery ? "Record Search" : "Discovery",
            permission: "discovery.view",
            customClass: active === "recordsSearch" ? "active" : "",
            featureToggle: "advancedSearch",
            policyPermission: {
                pathField: discoveryOption.path,
            },
        },
        {
            item: "discovery",
            icon: "discovery-grey-v2.svg",
            text: "Discovery",
            permission: "discovery.view",
            customClass: active === "discovery" ? "active" : "",
            featureToggle: "legacyDiscovery",
            policyPermission: {
                pathField: discoveryOption.path,
            },
        },
        {
            item: "collections",
            icon: "collections-grey-v2.svg",
            text: "Collections",
            permission: "collections.view",
            customClass: active === "collections" ? "active" : "",
            policyPermission: {
                pathField: collectionsOption.path,
            },
        },
        {
            item: "localisations",
            icon: "translate-grey-v2.svg",
            text: "Localisations",
            permission: "collections.view",
            customClass: active === "localisations" ? "active" : "",
            featureToggle: "localisationCollections",
            policyPermission: {
                pathField: localisationsOption.path,
            },
        },
        {
            item: "reporting",
            icon: "reporting-grey.svg",
            text: "Exports",
            permission: "collections.view",
            customClass: active === "reporting" ? "active" : "",
            policyPermission: {
                pathField: exportsOption.path,
            },
        },
        ...getReportingSubMenu(active, features),
        //connect
        {
            item: "connectCollections",
            icon: "lineups-grey-svg.svg",
            text: "Lineups",
            permission: "connect.swimlanes.view",
            customClass: active === "connectCollections" ? "active" : "",
            featureToggle: "connect",
            policyPermission: {
                pathField: swimlanesOption.path,
            },
        },
        // connect & !connectManager
        {
            item: "connectManager",
            icon: "user-groups-grey-v2.svg",
            text: "User Groups",
            permission: "connect.userGroupManagement.view",
            customClass: active === "connectManager" ? "active" : "",
            featureToggle: "connect",
            //counterToggle: 'connectManager',
            policyPermission: {
                pathField: userGroupsOption.path,
            },
        },
        {
            item: "account",
            icon: "account-grey-v2.svg",
            text: "Account",
            permission: "userAccount",
            customClass: active === "account" ? "active" : "",
        },
        getPreferencesOption(active, features),
        {
            item: "userRoleManagement",
            icon: "toggles-grey.svg",
            text: "Role Management",
            permission: "userRoleManagement",
            featureToggle: "userRoleManagement",
            customClass: active === "userRoleManagement" ? "active" : "",
            policyPermission: {
                pathField: userRolesAndPoliciesOption.path,
            },
        },
        getRecordBuilderOption(active, features),
        {
            item: "taskManager",
            icon: "task-manager-grey.svg",
            text: "Task Manager",
            permission: "taskManager",
            featureToggle: "taskManager",
            customClass: active === "taskManager" ? "active" : "",
            policyPermission: {
                pathField: taskManagerOption.path,
            },
        },
    ];
};
