import React, { useReducer, useContext, useEffect } from 'react'
import reducer, { setScript } from './reducer'
import { transformCreditsPayload } from '../../../../models/Record/credits'

const CreditsContext = React.createContext({
    state: {},
    dispatch: () => {}
})

export const useStore = () => useContext(CreditsContext)

export const CreditsProvider = props => {
    const {
        cmsData = {},
        options = {},
        handleFormChange = () => {},
        disabled = true,
        data = [],
        children,
        script,
        defaultScript
    } = props

    const[state, dispatch] = useReducer(
        reducer,
        {
            cmsData,
            options,
            handleFormChange,
            disabled,
            script,
            defaultScript,
            credits: transformCreditsPayload(data)
        }
    )

    return(
        <CreditsContext.Provider value={[state, dispatch]}>
            {children}
        </CreditsContext.Provider>
    )
}