import React from "react";
import _ from "lodash";
import Boilerplate from "./Boilerplate";
import PlaceholderText from "../../PlaceholderText";
import DynamicTable from "../../DynamicTable/links";
import Index from "./Index";
import styles from "../styles";
import deepcopy from "deepcopy";
import { diff } from "deep-object-diff";

export default class Links extends Index {
    constructor(props) {
        super(props);

        let data = [];

        if (Array.isArray(this.props.data)) {
            data = this.props.data;
        }

        // multilingual
        if (this.props.multilingual) {
            data = {};

            if (typeof this.props.data == "object") {
                data = this.props.data;
            }
        }

        /**
         * @var object
         */
        this.styles = styles(this.props);

        /**
         * @var object
         */
        this.state = {
            data,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        const nextPropsData = nextProps.data || [];
        const thisPropsData = this.props.data || [];
        const difference = diff(nextPropsData, thisPropsData);

        if (!_.isEmpty(difference)) {
            return true;
        }

        if (!_.isEqual(nextProps.disabled, this.props.disabled)) {
            return true;
        }

        if (!_.isEqual(nextProps.options, this.props.options)) {
            return true;
        }

        if (!_.isEqual(nextProps.audits, this.props.audits)) {
            return true;
        }

        if (!_.isEqual(nextProps.languageIso, this.props.languageIso)) {
            return true;
        }

        if (!_.isEqual(nextState, this.state)) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps) {
        const previousPropsData = prevProps.data || [];
        const thisPropsData = this.props.data || [];

        if (!_.isEqual(previousPropsData, thisPropsData)) {
            if (Array.isArray(this.props.data)) {
                this.setState({
                    data: this.props.data,
                });
            }
        }
    }

    getDataFromState() {
        let data = deepcopy(this.state.data || []);
        let result = data;

        // Multilingual
        if (
            typeof this.props.languageIso == "string" &&
            typeof this.state.data == "object"
        ) {
            data = deepcopy(this.state.data || {});
            const localData = data[this.props.languageIso];

            if (typeof localData == "object" && localData) {
                result = localData;
            }
        }

        return result;
    }

    handleChange(value) {
        let data = deepcopy(value);
        let updateData = data;

        // Multilingual
        if (
            this.props.multilingual &&
            typeof this.props.languageIso == "string"
        ) {
            data = deepcopy(this.state.data || {});

            if (Array.isArray(data) && _.isEmpty(data)) {
                data = {};
            }

            data[this.props.languageIso] = value;
            updateData = data;
        }

        if (typeof this.props.handleFormChange == "function") {
            this.props.handleFormChange(updateData);
        }

        this.setState({
            data: updateData,
        });
    }

    renderNoneAdded() {
        const data = this.getDataFromState();
        let active = false;

        if (Array.isArray(data)) {
            data.forEach((item) => {
                if (!item.deleted) {
                    active = true;
                }
            });
        }

        if (active) {
            return false;
        }

        if (this.props.disabled) {
            return (
                <div style={this.styles.marginItem}>
                    <PlaceholderText copy={this.copy} />
                </div>
            );
        }

        return false;
    }

    renderContent() {
        const data = this.getDataFromState();

        if (this.props.disabled && _.isEmpty(data)) {
            return false;
        }

        return (
            <div style={this.styles.marginItem}>
                <DynamicTable
                    {...this.props}
                    data={data}
                    id="meta-links-table"
                    handleChange={(item) => this.handleChange(item)}
                    audits={this.props.audits || []}
                />
            </div>
        );
    }
}
