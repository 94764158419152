import { StandaloneTicket } from "../containers/ticket/shared/validationSchema";
import unassignedUser from "./unassignedUser";

export default (users: StandaloneTicket["assignee"][], email: string) => {
    const user = users.find((u) => u.email === email);

    if (!user) {
        return unassignedUser;
    }

    return user;
};
