import _ from "lodash";

const HandleDataByRule = (props) => {
    const { metadata = [], gracenoteData = [], rule = "" } = props;

    let result = metadata;

    if (rule === "mergeAttributes" && Array.isArray(gracenoteData)) {
        result = _.concat(metadata, gracenoteData);
    } else if (
        rule == "overwriteAndReplace" &&
        Array.isArray(gracenoteData) &&
        !_.isEmpty(gracenoteData)
    ) {
        result = gracenoteData;
    }

    return result;
};

export default HandleDataByRule;
