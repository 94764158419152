import { observer } from "mobx-react";
import { CoreSelect } from "../../../../../../core/Form/components/inputs/Select/components/CoreSelect";
import { CoreSelectOptionType } from "../../../../../../core/Form/components/inputs/Select/types/CoreSelectOptionType";
import { PrimaryVT } from "../../../../config/types";
import { HideFormConditionally } from "./HideFormConditionally";

// ! DO NOT CHANGE THE ORDER OF THESE OPTIONS
export const displayTitleOptions: CoreSelectOptionType[] = [
    {
        label: "Title",
        value: "Title",
    },
    {
        label: "Short Title",
        value: "Short Title",
    },
    {
        label: "Sort Title",
        value: "Sort Title",
    },
    {
        label: "Alternative Title",
        value: "Alternative Title",
    },
];

export const getTitleFromDisplayTitleOptions = () =>
    displayTitleOptions[0].value;

export const DisplayTitle = observer(() => {
    return (
        <HideFormConditionally>
            <CoreSelect<PrimaryVT>
                config={{
                    path: "displayTitle",
                    labelProps: {
                        text: "Display Title",
                        isRequired: true,
                    },
                    options: displayTitleOptions,
                }}
            />
        </HideFormConditionally>
    );
});
