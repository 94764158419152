type Territory = {
    id?: number
    name: string
    text: string
    value: string
}

const mapTerritories = (territories: { name: string }[]): Territory[] => {
    const territoriesUpdated: Territory[] = territories.map((item: { name: string }) => {
        item.text = item.name
        item.value = item.value || item.id
        return item
    })

    return territoriesUpdated
}

export default mapTerritories