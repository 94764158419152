import React from "react";
import Stack from "@mui/material/Stack";

const NoRowsOverlay = () => {
    return (
        <Stack
            height="100%"
            alignItems="center"
            justifyContent="center"
            color="#5F5F5"
        >
            None Added
        </Stack>
    );
};

export default NoRowsOverlay;
