import React from "react";
import Component from "./index";

const TrippleLeftColor = (props) => {
    const {
        leftText,
        rightText,
        centerText,
        styles = {},
        color = "#9a9a9a",
        fontSize = "0.78125rem",
    } = props;

    return (
        <Component
            leftText={leftText}
            centerText={centerText}
            rightText={rightText}
            leftTagStyles={{
                background: color,
                color: "white",
                borderColor: color,
            }}
            centerTextTransparent={true}
            rightTextTransparent={true}
            styles={styles}
            fontSize={fontSize}
        />
    );
};

export default TrippleLeftColor;
