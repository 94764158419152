/* eslint-disable no-unused-expressions */
import React from "react";
import { observer } from "mobx-react";
import { Details } from "./components/Details";
import { BackgroundProcessingMenuItem } from "./components/RecordsProcessingMenuItem";
import { useBackgroundProcessingStore } from "./store/useBackgroundProcessingStore";

export const BackgroundProcessingTM = observer(() => {
    const store = useBackgroundProcessingStore();

    return (
        <>
            <BackgroundProcessingMenuItem />
            {store.processIdDetails && (
                <Details processId={store.processIdDetails} />
            )}
        </>
    );
});
