const HandleRecordReset = (state) => {
    const { set } = state;

    set({
        recordData: {},
        activeRecord: {},
        newRecord: {},
        recordChanges: {},
        originalRecord: {},
        unsavedChanges: false,
        savingRecord: false,
        disabled: true,
        editRecord: false,
        notification: {},
        collectionTitle: {},
        urlComponents: {},
    });
};

export default HandleRecordReset;
