import React from "react";
import { SectionInterface } from "./interface";
import Games from "../../views/Games";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";

const SectionGames = (props: SectionInterface) => {
    const { attributes = {}, data = {} } = props;

    return (
        <Games
            {...getSectionCommonProps({ ...props, field: "games" })}
            name="games"
            className="meta-record-games"
            title="Games"
            data={data.games || []}
            lastSection={true}
            searchUrl={attributes.searchGamesUrl}
        />
    );
};

export default SectionGames;
