import React from "react";
import _ from "lodash";
import "./styles/_styles.scss";

export default class TitleValue extends React.Component {
    render() {
        const {
            title,
            value,
            values,
            style,
            valueStyle,
            titleStyles = {},
            className,
            emptyPlaceholder = "None Added.",
        } = this.props;

        let singleValue = value;
        if (typeof values == "string") singleValue = values;

        return (
            <div
                style={style}
                className={`meta-asset-title-value ${className}`}
            >
                {title && (
                    <span
                        className="meta-asset-title-value--title"
                        style={titleStyles}
                    >
                        {title}
                    </span>
                )}

                {singleValue && (
                    <span
                        style={valueStyle}
                        className={`meta-asset-title-value--value ${className}`}
                    >
                        {singleValue}
                    </span>
                )}

                {!_.isEmpty(values) &&
                    Array.isArray(values) &&
                    values.map((item, index) => {
                        return (
                            <span
                                style={valueStyle}
                                className={`meta-asset-title-value--value ${className}`}
                                key={index}
                            >
                                {item}
                            </span>
                        );
                    })}

                {!value && _.isEmpty(values) && (
                    <span className="meta-asset-title-value--none-added">
                        {emptyPlaceholder}
                    </span>
                )}
            </div>
        );
    }
}
