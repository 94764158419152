import { SET_RECORD_CHANGES } from "../actions/action_types";
import { defaultRecordChanges } from "../initialState";

export default function (
    state = { recordChanges: defaultRecordChanges },
    action,
) {
    switch (action.type) {
        case SET_RECORD_CHANGES:
            return Object.assign({}, state, {
                recordChanges: action.recordChanges,
            });
    }

    return state;
}
