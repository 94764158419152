import isIndexInArray from "../Helpers/isIndexInArray";
import GetMetaGoogleTranslateLanguages from "./GetMetaGoogleTranslateLanguages";
import _ from "lodash";

export default function GoogleTranslateItems(props) {
    const {
        languages = [],
        top_written_languages = [],
        populatedLocales = [],
    } = props;

    let result = [];

    const metaGoogleTranslateLanguages = GetMetaGoogleTranslateLanguages({
        top_written_languages,
        languages,
    });

    if (!_.isEmpty(metaGoogleTranslateLanguages)) {
        result = metaGoogleTranslateLanguages.map((item) => {
            const populated = isIndexInArray(populatedLocales, item.value);

            let languageCode = "";
            if (typeof item.iso_639_1 == "string") {
                languageCode = item.iso_639_1.toLowerCase();
            }

            return {
                text: `• Google Translate • ${item.text} (${
                    populated ? "Populated" : "Empty"
                })`,
                value: languageCode,
            };
        });

        result.unshift({
            text: "• Google Translate All Empty Languages",
            value: "empty_values",
        });
    }

    return result;
}
