import React from "react";
import _ from "lodash";
import TransformMetaUsers from "./TransformMetaUsers";
import BubbleList from "../../BubbleList";
import BubbleListSimpleOnEnter from "../../BubbleList/simpleOnEnter";

const WorkflowNotifications = (props) => {
    const {
        emails = {},
        handleUpdate,
        transformedMetaUsersDatalist = [],
    } = props;

    const { email_addresses } = emails;

    const marginTop = { marginTop: "1.5rem" };

    const metaUsers = TransformMetaUsers({
        metaUsers: emails.meta_users,
        meta_users: props.meta_users,
    });

    return (
        <div className="meta-emails-container asset-information">
            <BubbleList
                label="Fabric Users"
                name="meta-users"
                id="meta-users"
                inputId="meta-users"
                background="#9b78cf"
                color="white"
                deleteWhite={true}
                readOnly={false}
                emptyPlaceholder="Start Typing User’s Name"
                data={metaUsers}
                deleteObject={true}
                handleFormChange={(value) => handleUpdate("meta_users", value)}
                datalistId="meta-users-data-list"
                datalist={transformedMetaUsersDatalist}
                filterStaticDataList={true}
                wrapperStyles={marginTop}
            />

            <BubbleListSimpleOnEnter
                label="Email Addresses"
                name="meta-email-addresses"
                id="meta-email-addresses"
                inputId="meta-email-addresses"
                background="#787acf"
                color="white"
                deleteWhite={true}
                readOnly={false}
                emptyPlaceholder="Type email address & hit Enter"
                data={email_addresses}
                handleFormChange={(value) =>
                    handleUpdate("email_addresses", value)
                }
                handleEnter={(item) => console.log(item)}
                datalistId="meta-users-data-list"
                useEnterButtonOnSelect={true}
                filterStaticDataList={true}
                wrapperStyles={marginTop}
            />
        </div>
    );
};

export default WorkflowNotifications;
