import React from "react";
import _ from "lodash";
import "./styles/_styles.scss";
import TagsTrack from "../../../TagsTrack";
import DoubleTagSimple from "../../../Tag/simpleDouble";
import RenderCertificateRatingIso from "../RenderCertificateRatingIso";
import TagSimple from "../../../Tag/simple";
import AssetStateColorMap from "../ComplianceBodyComponents/AssetStateColorMap";
import ReactTooltip from "react-tooltip";

const QuickView = (props) => {
    const {
        orignalMaster = {},
        alternativeMasters = [],
        styles = {},
        placeholderCreatedStatus,
        errorStatus,
        masterLocale,
        options = {},
        data = [],
        clientFeatures = {},
    } = props;

    const RenderMasterHeader = (props) => {
        const { master = {}, locale } = props;

        const { asset_type, version_name } = master;

        const { spoken_languages = [] } = options;

        const language =
            spoken_languages.find((item) => item.value == locale) || {};
        let languageText = locale;

        if (language && language.text && language.value) {
            languageText = `${language.text} • ${language.value}`;
        }

        return (
            <div className="quick-view--master-header">
                <DoubleTagSimple
                    leftBackground="#626262"
                    rightBackground="#6785f4"
                    color="white"
                    leftText={asset_type || version_name || "ORIGINAL MASTER"}
                    rightText={languageText}
                />
            </div>
        );
    };

    const RenderTagTrackLeft = (props) => {
        const { compliance = {}, leftText } = props;

        const { asset_type, compliance_code, version_code, version_name } =
            compliance;

        let rightBackground = "#ff8a00";
        if (compliance.status == placeholderCreatedStatus)
            rightBackground = "#188651";
        if (compliance.status == errorStatus) rightBackground = "#ff0000";

        return (
            <DoubleTagSimple
                leftBackground="#626262"
                rightBackground={rightBackground}
                color="white"
                leftText={leftText || asset_type || version_name}
                rightText={compliance_code || version_code}
            />
        );
    };

    const RenderSubsDubsTags = (props) => {
        const { data = [], type } = props;

        const empty = _.isEmpty(data);
        const dubs = type == "dubs";

        const contentProps = {
            empty: dubs ? "No Dubs Present" : "No Subs Present",
            notEmpty: dubs ? "Dubs" : "Subs",
        };

        const style = {
            marginLeft: "0.3125rem",
        };

        return (
            <React.Fragment>
                <TagSimple
                    background={empty ? "#bcbcbc" : "#d2d2d2"}
                    color="#797979"
                    text={empty ? contentProps.empty : contentProps.notEmpty}
                    styles={style}
                />

                {data.map((item, index) => {
                    let statusColors =
                        item.status && !_.isEmpty(item.status)
                            ? AssetStateColorMap.subtitleStatuses[
                                  item.status.toLowerCase()
                              ]
                            : AssetStateColorMap.subtitleStatuses.empty;

                    let backgroundColor =
                        !_.isEmpty(statusColors) && "background" in statusColors
                            ? statusColors && statusColors.background
                            : "#bcbcbc";

                    let textColor =
                        !_.isEmpty(statusColors) && "text" in statusColors
                            ? statusColors.text
                            : "#797979";

                    return (
                        <div
                            data-tip={(item.status
                                ? item.status
                                : "empty"
                            ).toUpperCase()}
                            className="assets-quick-view-subsdubs-lang-tag"
                            key={index}
                        >
                            <ReactTooltip
                                key={"tip-" + index}
                                delayShow={100}
                                place="bottom"
                                type="light"
                                effect="solid"
                                className="assets-quick-view-subsdubs-tooltip"
                            />

                            <TagSimple
                                background={backgroundColor}
                                color={textColor}
                                text={
                                    item.language || item.language_code || "-"
                                }
                                styles={style}
                            />
                        </div>
                    );
                })}
            </React.Fragment>
        );
    };

    const RenderTagTrackRight = (props) => {
        const { compliance = {} } = props;

        const {
            alternate_language_audio_tracks = [],
            subtitle_tracks = [],
            certifications = [],
        } = compliance;

        const emptyCertifications = _.isEmpty(certifications);

        return (
            <div className="quick-view--tag-track">
                <div>
                    <RenderSubsDubsTags
                        data={alternate_language_audio_tracks}
                        type="dubs"
                    />
                </div>

                <div>
                    <RenderSubsDubsTags data={subtitle_tracks} type="subs" />
                </div>

                <div className="certifications">
                    {emptyCertifications && (
                        <TagSimple
                            background="#767676"
                            color="#959595"
                            text="No Rating"
                            styles={{
                                marginLeft: "0.3125rem",
                            }}
                        />
                    )}

                    {!emptyCertifications &&
                        certifications
                            .filter((item) => !item.deleted)
                            .map((certificate, index) => {
                                return (
                                    <RenderCertificateRatingIso
                                        certificate={certificate}
                                        clientFeatures={clientFeatures}
                                        styles={{
                                            marginLeft: "0.3125rem",
                                        }}
                                        key={index}
                                    />
                                );
                            })}
                </div>
            </div>
        );
    };

    const RenderCompliance = (props) => {
        return (
            <div className="quick-view--compliance">
                <TagsTrack
                    leftContent={<RenderTagTrackLeft {...props} />}
                    rightContent={<RenderTagTrackRight {...props} />}
                    background="#959595"
                    contentStyles={{
                        height: "auto",
                        paddingTop: "0.3125rem",
                    }}
                    leftContentStyles={{
                        alignItems: "flex-start",
                    }}
                    rightContentStyles={{
                        justifyContent: "flex-end",
                    }}
                />
            </div>
        );
    };

    return (
        <div className="meta-asset-body quick-view-body" style={styles}>
            <RenderMasterHeader master={orignalMaster} locale={masterLocale} />

            {!_.isEmpty(orignalMaster) && (
                <RenderCompliance compliance={orignalMaster} />
            )}

            {Array.isArray(orignalMaster.compliances) &&
                orignalMaster.compliances.map((compliance, index) => {
                    return (
                        <RenderCompliance compliance={compliance} key={index} />
                    );
                })}

            {Array.isArray(data) &&
                data.map((compliance, index) => {
                    return (
                        <RenderCompliance compliance={compliance} key={index} />
                    );
                })}

            {alternativeMasters.map((alternateMaster, index) => {
                return (
                    <React.Fragment key={index}>
                        <RenderMasterHeader
                            master={alternateMaster}
                            locale={alternateMaster.locale}
                        />

                        <RenderCompliance compliance={alternateMaster} />

                        {alternateMaster.compliances.map(
                            (compliance, index) => {
                                return (
                                    <RenderCompliance
                                        key={index}
                                        compliance={compliance}
                                    />
                                );
                            },
                        )}
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default QuickView;
