import { SPReviewFieldsInterface } from "../../models/ClientManaged/spReviewFieldsInterface";

export function transformSpReviewOptions(
    client_managed_sp_review: SPReviewFieldsInterface[],
): SPReviewFieldsInterface[] {
    let spReviewOptions = client_managed_sp_review;

    if (!Array.isArray(spReviewOptions)) {
        spReviewOptions = JSON.parse(client_managed_sp_review.toString());
    }

    return spReviewOptions
        .filter((item) => !item.deleted_at)
        .map((item) => {
            return {
                name: item.name,
                value: item.value,
            };
        });
}
