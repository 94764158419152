import React from "react";
import _ from "lodash";
import styles from "../styles";
import Index from "./Index";
import RunningOrders from "../../RunningOrders";
import deepcopy from "deepcopy";
import { diff } from "deep-object-diff";

export default class SeasonRunningOrders extends Index {
    constructor(props) {
        super(props);

        let data = [];

        if (Array.isArray(this.props.data)) {
            data = this.props.data;
        }

        /**
         * @var object
         */
        this.state = {
            data,
            notification: {},
        };

        /**
         * @var object
         */
        this.copy = this.props.copy || {};

        /**
         * @var object
         */
        this.styles = styles(this.props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const nextPropsData = nextProps.data || {};
        const thisPropsData = this.props.data || {};
        const difference = diff(nextPropsData, thisPropsData);

        if (!_.isEmpty(difference)) {
            return true;
        }

        if (!_.isEqual(nextProps.disabled, this.props.disabled)) {
            return true;
        }

        if (!_.isEqual(nextProps.options, this.props.options)) {
            return true;
        }

        if (!_.isEqual(nextState, this.state)) {
            return true;
        }

        return false;
    }

    handleChange(data) {
        this.setState(
            {
                data,
            },
            () => {
                if (typeof this.props.handleFormChange == "function") {
                    this.props.handleFormChange(data);
                }
            },
        );
    }

    renderNoneAdded() {
        return false;
    }

    renderContent() {
        const data = deepcopy(this.state.data || {});

        return (
            <div style={this.styles.marginItem}>
                <RunningOrders
                    {...this.props}
                    data={data}
                    id="meta-running-orders-table"
                    handleChange={(data) => this.handleChange(data)}
                />
            </div>
        );
    }
}
