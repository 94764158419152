import React from "react"
import { observer } from "mobx-react";
import { RootStore, useStores } from "../../store/rootStore";
import Button from "../../../../../components/Button"

const InActiveTableActionBar: React.FC = observer(() => {
    const rootStore = useStores() || {}
    const { creditsStore } = rootStore as RootStore
    const selectedCredits = creditsStore.selectedCredits

    const handleDeleteCredits = () => {
        creditsStore.setCreditDeleteFormStatus(true)
        creditsStore.setCreditDeleteFormProperty("source", "inactive")
    }

    return (
        <React.Fragment>
            <div className="credits-top-container">
                <div className="credits-actions">
                    {/*
                        <Button
                            value="Clear Sorting"
                            onClick={() => {}}
                            type="button"
                            marginLeftTop={true}
                            buttonSection={true}
                        />
                    */}

                    {!!selectedCredits.inactive.length &&
                        <>
                            <Button
                                value="Delete"
                                onClick={() => handleDeleteCredits()}
                                type="button"
                                buttonSection={true}
                                buttonDelete={true}
                                disabled={!selectedCredits.inactive.length}
                                className={!selectedCredits.inactive.length ? "disabled" : ''}
                            />
                        </>
                    }

                    <span className="divider" />

                    <Button
                        value="Add to Credit List"
                        onClick={() => creditsStore.addCreditsToCreditList()}
                        type="button"
                        buttonSection={true}
                        disabled={!selectedCredits.inactive.length}
                        className={(!selectedCredits.inactive.length) ? "disabled" : ''}
                    />
                </div>
            </div>
        </React.Fragment>
    )
})

export default InActiveTableActionBar