import { TableCreditRow } from "./types/CastCrew.type";

export function onSelectionModelChange(
    rows: TableCreditRow[],
    onSelect: (data: TableCreditRow[]) => void,
) {
    return (ids) => {
        const selectedRows: TableCreditRow[] = [];
        ids.forEach((id) => {
            const foundRow = rows?.find((row) => row?.id === id);
            if (foundRow) {
                selectedRows.push(foundRow);
            }
        });

        onSelect(selectedRows);
    };
}
