import React from "react";
import { observer } from "mobx-react";
import Input from "../../interfaces/Input.interface";
import { CoreColorPalette } from "../../../components/inputs/Color/CoreColorPalette";

export const ColorPalette = observer(({ field }: Input) => {
    return (
        <div data-testid={"fabric-dynamic-form-color-palette-input"}>
            <CoreColorPalette 
                {...field}
                path={field.key}
                labelProps={{
                    text: field.label,
                    isRequired: field.validation?.required?.enabled
                }}
            />
        </div>
    )
})