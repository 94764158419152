import React from "react";
import styles from "./styles";
import Index from "./index";
import KeywordLabel from "../KeywordLabel";
import PlaceholderBubble from "../PlaceholderBubble";
import _ from "lodash";

export default class Input extends Index {
    componentDidMount() {
        this.isComponentMounted = true;
        this.filterDataListArray();

        if (this.props.setFocusOnMount) {
            this.handleFocus.bind(this, true);
        }

        if (this.props.bubbleOnSelect && this.props.value) {
            this.setState({
                selectedItem: this.props.value,
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!_.isEqual(this.props.value, nextProps.value)) {
            return true;
        }

        if (!_.isEqual(this.state.data, nextState.data)) {
            return true;
        }

        if (!_.isEqual(this.props.disabled, nextProps.disabled)) {
            return true;
        }

        if (!_.isEqual(this.props.display, nextProps.display)) {
            return true;
        }

        if (!_.isEqual(this.props.datalist, nextProps.datalist)) {
            return true;
        }

        if (
            !_.isEqual(
                this.props.clearSelectedOption,
                nextProps.clearSelectedOption,
            )
        ) {
            return true;
        }

        if (!_.isEqual(this.props.error, nextProps.error)) {
            return true;
        }

        if (!_.isEqual(this.props.loading, nextProps.loading)) {
            return true;
        }

        if (!_.isEqual(this.props.placeholder, nextProps.placeholder)) {
            return true;
        }

        if (
            !_.isEqual(
                this.props.handleNoMatchText,
                nextProps.handleNoMatchText,
            )
        ) {
            return true;
        }

        if (!_.isEqual(this.state, nextState)) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value,
            });
        }

        if (
            this.props.clearValue !== prevProps.clearValue &&
            this.props.clearValue
        ) {
            this.setState({
                value: "",
                focus: false,
            });
        }

        if (!_.isEqual(prevProps.datalist, this.props.datalist)) {
            this.filterDataListArray();
        }

        if (!_.isEqual(prevState.value, this.state.value)) {
            this.filterDataListArray();
        }

        if (
            !this.props.value &&
            this.state.selectedItem &&
            !this.props.selectedItemKeep
        ) {
            this.setState({
                selectedItem: false,
            });
        }
    }

    handleChange(event) {
        if (event) {
            if (event.target) {
                let value = event.target.value;

                this.setState(
                    {
                        value,
                    },
                    () => {
                        this.handleChangeCallback();
                    },
                );
            }
        }
    }

    handleKeyUp(e) {
        if (typeof e == "object") {
            const { filteredDataList, datalistActiveIndex } = this.state;

            this.handleFocus(true);

            if (Array.isArray(filteredDataList)) {
                // up
                if (e.keyCode === 38) {
                    if (filteredDataList.length == 1) {
                        this.setStateValue("datalistActiveIndex", 1);
                    }

                    if (datalistActiveIndex !== 0) {
                        this.setStateValue(
                            "datalistActiveIndex",
                            datalistActiveIndex - 1,
                        );
                    }
                }

                // down
                if (e.keyCode === 40) {
                    if (filteredDataList.length == 1) {
                        this.setStateValue("datalistActiveIndex", 1);
                    }

                    if (datalistActiveIndex + 1 !== filteredDataList.length) {
                        this.setStateValue(
                            "datalistActiveIndex",
                            datalistActiveIndex + 1,
                        );
                    }
                }
            }
        }
    }

    handleDatalistInput(item, enterPressed) {
        const {
            bubbleOnSelect,
            keepSelected,
            handleEnter,
            handleListSelection,
            single,
            keepValue,
        } = this.props;

        let value = "";
        let selectedItem = false;

        // Keep selected item from list
        if (keepSelected || keepValue) {
            value = item.text || "";
        }

        if (typeof item == "object" && bubbleOnSelect) {
            selectedItem = item.original_title;

            if (this.props.selectedItemKey) {
                selectedItem = item[this.props.selectedItemKey];
            }
        }

        if (typeof handleEnter == "function" && enterPressed === true) {
            handleEnter(item);
        }

        if (typeof handleListSelection == "function") {
            handleListSelection(item);
        }

        if (keepValue || value !== this.state.value) {
            this.setState({
                value,
                focus: false,
            });
        }

        if (bubbleOnSelect) {
            this.setState({
                selectedItem,
            });
        }

        if (typeof this.inputRef.focus == "function" && !single) {
            //this.inputRef.focus()
            //this.handleFocus(true)
        }
    }

    handleNoMatchTextSelect(value) {
        if (typeof this.props.handleNoMatchText == "function") {
            this.props.handleNoMatchText(value.text);
        }

        this.handleDatalistInput(value);
    }

    handleKeyPress(event) {
        const { useEnterButtonOnSelect } = this.props;

        const { value } = this.state;

        if (typeof event == "object") {
            if (event.key === "Enter" && !_.isEmpty(value)) {
                event.preventDefault();
                const item =
                    this.state.filteredDataList[this.state.datalistActiveIndex];

                if (item) {
                    this.handleDatalistInput(item, true);
                    this.handleFocus(false);
                    this.setStateValue("datalistActiveIndex", 0);
                }

                if (!item && useEnterButtonOnSelect) {
                    this.handleDatalistInput(value, true);
                }
            }
        }
    }

    filterDataListArray() {
        const { datalist, noFilter, filterStaticDataList } = this.props;

        const { value = "" } = this.state;

        if (!filterStaticDataList) {
            this.setStateValue("filteredDataList", datalist);
            return;
        }

        if (Array.isArray(datalist)) {
            let inputValue = value;

            if (typeof inputValue == "string") {
                inputValue = inputValue.trim();
            }

            if (typeof inputValue == "string") {
                inputValue = inputValue.toLowerCase();
            }

            let filteredDataList = datalist;

            if (!noFilter && typeof inputValue == "string") {
                filteredDataList = datalist.filter((item) => {
                    if (typeof item == "object") {
                        if (typeof item.text == "string") {
                            if (
                                item.text
                                    .toLowerCase()
                                    .startsWith(inputValue.toLowerCase())
                            ) {
                                return item;
                            }
                        }

                        if (typeof item.value == "string") {
                            if (
                                item.value
                                    .toLowerCase()
                                    .startsWith(inputValue.toLowerCase())
                            ) {
                                return item;
                            }
                        }
                    }

                    if (typeof item == "string") {
                        if (
                            item
                                .toLowerCase()
                                .startsWith(inputValue.toLowerCase())
                        ) {
                            return item;
                        }
                    }
                });
            }

            this.setStateValue("filteredDataList", filteredDataList);
        }
    }

    handleBubbleDelete() {
        this.setState(
            {
                selectedItem: false,
                value: "",
            },
            () => {
                this.handleChangeCallback();

                if (typeof this.props.callBackOnDelete == "function") {
                    this.props.callBackOnDelete();
                }
            },
        );
    }

    renderDataList() {
        const { value = "", focus, filteredDataList = [] } = this.state;

        const {
            datalist = [],
            disabled,
            copy = {},
            noMatchText = false,
        } = this.props;

        const datalistMatch = datalist.find((item) => {
            if (
                typeof item == "object" &&
                item.text &&
                value &&
                typeof value == "string" &&
                typeof item.text == "string"
            ) {
                if (
                    item.text.toLowerCase().trim() == value.toLowerCase().trim()
                ) {
                    return item;
                }
            }

            if (typeof item == "string" && value && typeof value == "string") {
                if (item.toLowerCase().trim() == value.toLowerCase().trim()) {
                    return item;
                }
            }
        });

        if (datalist && focus && !disabled && value.trim().length > 0) {
            return (
                <div style={this.classStyles.datalistContainer}>
                    <ul
                        style={this.classStyles.datalistUl}
                        className="meta-datalist"
                    >
                        {typeof noMatchText == "string" && !datalistMatch && (
                            <li
                                style={this.classStyles.datalistLi}
                                className="meta-datalist-item--none-found"
                                onClick={this.handleNoMatchTextSelect.bind(
                                    this,
                                    {
                                        value: null,
                                        text: value,
                                    },
                                )}
                            >
                                {noMatchText.replace("{value}", `"${value}"`)}
                            </li>
                        )}

                        {!_.isEmpty(filteredDataList) && (
                            <li
                                style={Object.assign(
                                    {},
                                    this.classStyles.datalistLi,
                                    this.classStyles.label,
                                )}
                            >
                                {copy.suggestions || "suggestions"}
                            </li>
                        )}

                        {filteredDataList.map((item, index) => {
                            let text = item;

                            if (typeof item == "object") {
                                text = item.text || item.original_title;

                                if (item.list) {
                                    text = item.list;
                                }
                            }

                            return (
                                <li
                                    style={Object.assign(
                                        {},
                                        styles().datalistLi,
                                        index ==
                                            this.state.datalistActiveIndex &&
                                            styles().datalistLiActive,
                                    )}
                                    key={index}
                                    className="meta-datalist-item"
                                    onMouseEnter={this.setStateValue.bind(
                                        this,
                                        "datalistActiveIndex",
                                        index,
                                    )}
                                    onClick={this.handleDatalistInput.bind(
                                        this,
                                        item,
                                    )}
                                >
                                    {text}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            );
        }

        return false;
    }

    renderInputBubble(options) {
        const { selectedItem, value } = this.state;

        const { bubbleOnSelect, noneEntered, disabled, readOnly } = this.props;

        const keywordLabelProps = this.props.keywordLabel || {};

        const { background, color, postfix } = keywordLabelProps;

        // display none entered
        if (noneEntered && _.isEmpty(value) && (disabled || readOnly)) {
            return false;
        }

        if (bubbleOnSelect) {
            let title = selectedItem;
            if (typeof selectedItem == "object") {
                title = selectedItem.text;
            }

            return (
                <React.Fragment>
                    {typeof title == "string" && (
                        <KeywordLabel
                            title={title}
                            postfix={postfix}
                            weighting={false}
                            background={background || "grey"}
                            color={color || "white"}
                            deleteWhite={true}
                            margin="0.71875rem 0 0 0"
                            delete={true}
                            disabled={disabled}
                            handleDelete={(item) =>
                                this.handleBubbleDelete(item)
                            }
                        />
                    )}

                    {!selectedItem && this.renderInput(options)}
                </React.Fragment>
            );
        }

        return this.renderInput(options);
    }

    renderInput(options) {
        const { className, loading } = this.props;

        return (
            <input
                {...options}
                className={`placeholder-light-grey ${className || ""} ${
                    loading ? "input-loading" : ""
                }`}
                ref={(ref) => (this.inputRef = ref)}
            />
        );
    }

    renderNoneEntered() {
        const { noneEntered, disabled, readOnly } = this.props;

        const { value } = this.state;

        if (noneEntered && !value && (disabled || readOnly)) {
            return (
                <PlaceholderBubble
                    placeholder="None Entered"
                    margin="0.71875rem 0 0 0"
                    background="#afafaf"
                    color="#000000"
                />
            );
        }
    }

    render() {
        const recordBasicOptions = this.props.recordBasic
            ? this.getRecordBasicProps()
            : {};
        const optionsProps = {
            ...this.props,
            ...recordBasicOptions,
            onKeyPress: this.handleKeyPress.bind(this),
            onKeyUp: this.handleKeyUp.bind(this),
        };

        const options = this.getOptions(optionsProps) || {};

        return (
            <div
                id={this.props.containerId}
                style={styles(optionsProps, this.state.value).container}
                ref={(node) => {
                    this.node = node;
                }}
            >
                {this.renderLabel()}
                {this.renderInputBubble(options)}
                {this.renderNoneEntered()}
                {this.renderDataList()}
            </div>
        );
    }
}
