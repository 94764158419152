import deepcopy from "deepcopy";
import { ValidationResult } from "./interfaces/Validation";
import DisabledRuleActive from "./rules/disabled";

export default function ValidateBrand(
    result: ValidationResult,
    options: any,
): ValidationResult {
    let response: ValidationResult = deepcopy(result);

    const { data = {}, status, clientDataStructure = {} } = options;

    const brandValidation =
        clientDataStructure?.fieldValidation?.origination?.brand;

    const {
        origination = {},
        category = false,
        status: recordStatus = ''
    } = data;

    const { type } = origination;

    if (
        DisabledRuleActive({
            recordStatus,
            category,
            type,
            dataStructure: clientDataStructure,
            fieldPath: "origination.brand",
        })
    ) {
        return response;
    }

    if ((!origination || !origination.brand) && brandValidation) {
        response.result = false;
        response.notification = {
            title: "Please check brand",
            description: "Brand is missing",
            status,
        };

        response.validationKeys = result.validationKeys.concat(["brand"]);
    }

    return response;
}
