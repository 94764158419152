const credits = {
    wrapper: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
    },
    item: {
        single: {
            width: "calc(100% - .375rem)",
        },
        double: {
            width: "calc(50% - .375rem)",
        },
    },
};

export default credits;
