import React from "react"
import { isEmpty, get } from "lodash"
import Boilerplate from "./Boilerplate"
import { useStore as useGlobalStore } from '../../Global/store/store'
import { RECORD_BUILDER } from '../../Global/Options'
import { NUMBERING } from "../../../models/RecordBuilder"
import BuilderError from "../../RecordBuilder/FetchError";
import CoreFormFieldInterface from '../../../core/Form/dynamic/interfaces/Field.interface'
import CoreDynamicFormFieldsMapper from '../../RecordBuilder/map/dynamicCoreForm/coreDynamicForm'
import CoreDynamicForm from '../../../core/Form/dynamic'

type Props = {
    disabled: boolean
    handleFormChange: Function
    category: string
    data: any
}

const AlternativeNumbering = (props: Props) => {
    const {
        disabled,
        category,
        data,
        handleFormChange,
    } = props

    const [globalState] = useGlobalStore() || [{}];

    const handleChange = (numbering) => {
        handleFormChange(numbering);
    }

    let recordBuilderData = globalState[RECORD_BUILDER]
    let recordBuilderError = globalState.recordBuilderError
    
    let configuration = get(recordBuilderData, NUMBERING, [])

    let coreFormFields: CoreFormFieldInterface[] = CoreDynamicFormFieldsMapper({ disabled, category, configuration })
    const isEmptyConfiguration = isEmpty(coreFormFields) && !recordBuilderError

    if (isEmptyConfiguration) {
        return <></>
    }

    return (
        <div className="numbering-builder">
            <Boilerplate
                title={"Alternative Numbering"}
                className={"meta-record-numbering-builder"}
                displayTitle={true}
            >
                {recordBuilderError && <BuilderError />}

                <CoreDynamicForm 
                    fields={coreFormFields} 
                    data={data}
                    originalData={data}
                    disabled={disabled}
                    onChange={(numbering) => handleChange(numbering)}
                    stackStyles={{ paddingTop: "0.4rem" }}
                    handleErrors={(errors) => console.log("errors", errors)}
                />
            </Boilerplate>
        </div>
    )
}

export default AlternativeNumbering