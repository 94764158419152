
import React from "react";
import Input from "../../interfaces/Input.interface";
import { observer } from "mobx-react";
import { useStores } from '../../store/rootStore'
import getFieldDateTimeFormat from "../utils/DateTimeFormat";
import { CoreInputTimePicker } from "../../../components/inputs/DateTime/CoreInputTimePicker";

export const TimePicker = observer(({ field }: Input) => {
    const { formStore } = useStores()

    return (
        <div data-testid={"fabric-dynamic-form-timepicker-input"}>
            <CoreInputTimePicker
                disabled={field.validation?.readOnly?.enabled || formStore.disabled}
                path={field.key}
                labelProps={{
                    text: field.label,
                    isRequired: field.validation?.required?.enabled,
                }}
                format={getFieldDateTimeFormat(field)}
            />
        </div>
    )
})