import { Box, Typography } from "@mui/material";
import _ from "lodash";
import { styled } from "@mui/material/styles";
import { Function, isEmpty } from "lodash";
import { useFormContext } from "react-hook-form";
import { ChipsArray } from "../Form/components/inputs/Select/components/ChipsArray";
import { CoreFormLabelProps } from "../Form/components/styled/CoreForm.label";
import { AutocompleteOption } from "./AutocompleteOption";
import { CoreAutocomplete } from "./CoreAutocomplete";
import { NoMatch, NoMatchCreateNew } from "./NoMatch";

export type AutocompleteSuggestionsProps<TOption> = {
    path: string;
    arrayPath: string;
    labelProps: CoreFormLabelProps;
    customStyles?: React.CSSProperties;
    headerText: string;
    disabled?: boolean;
    /**
     * Will be called when the user clicks on a suggestion record card
     */
    onClick?: (option: TOption) => void;
    noMatchOnClick?: () => void;
    suggestions;
    addNewValue: (value: string) => void;
    replace: Function;
    placeholder: string;
    popperProps: {
        open: boolean;
        closePopper: Function;
        openPopper: Function;
    };
    onRenderOption?: (option: TOption) => JSX.Element;
    isFixedList?: boolean; // fixed or growing list
    completeSuggestions?: TOption[];
    chipsLabel?: string;
    chipArrayStyles?: any;
    singleSelect?: boolean;
    textInputStyles?: React.CSSProperties;
    loading?: boolean;
};

const GroupHeaderText = styled(Typography)(({ theme }) => ({
    color: "rgba(47, 47, 47, 0.57)",
    fontSize: "0.75rem",
    fontWeight: "500",
}));

export function AutocompleteSuggestions<TOption>(
    props: AutocompleteSuggestionsProps<TOption>,
) {
    const {
        path,
        arrayPath,
        labelProps = {},
        textInputStyles = {},
        headerText,
        disabled = false,
        onClick,
        noMatchOnClick,
        suggestions = [],
        addNewValue,
        replace,
        placeholder,
        popperProps,
        onRenderOption,
        isFixedList,
        completeSuggestions,
        chipsLabel,
        chipArrayStyles = {},
        singleSelect,
        loading = false
    } = props;

    const form = useFormContext();

    const enabledClick = typeof onClick === "function";

    const value = form.watch(path);

    const selected = form.getValues(arrayPath);
    const pathValue = form.getValues(path);

    const isMatch = () => {
        return suggestions
            ?.map((suggestion) => suggestion[path])
            ?.includes(pathValue);
    };

    function handleEnterKeyDown() {
        if (isFixedList) {
            return null;
        }

        return (event) => {
            if (event.key === "Enter") {
                event.preventDefault();
                addNewValue(pathValue);
                popperProps?.closePopper();
                form.resetField(path);
            }
        };
    }

    const NoMatchComponent = isFixedList ? (
        <NoMatch pathValue={pathValue} suggestionsExist={!_.isEmpty(suggestions)} />
    ) : (
        <NoMatchCreateNew
            pathValue={pathValue}
            onClick={noMatchOnClick || onClick}
            inputPath={path}
            suggestionsExist={!_.isEmpty(suggestions)}
        />
    );

    const ChipsArrayComponent = (
        <ChipsArray
            selected={selected}
            options={completeSuggestions ?? []}
            replace={replace}
            formMethods={form}
            path={arrayPath}
            chipsLabel={chipsLabel}
            chipArrayStyles={chipArrayStyles}
            singleSelect={singleSelect}
        />
    );

    return (
        <Box>
            <CoreAutocomplete
                open={popperProps?.open}
                onOpen={popperProps?.openPopper}
                onClose={popperProps?.closePopper}
                path={path}
                arrayPath={arrayPath}
                isEmptyMaxLength
                disabled={disabled}
                disablePortal
                labelProps={labelProps}
                options={suggestions || []}
                groupBy={() => headerText}
                onKeyDown={handleEnterKeyDown()}
                placeholder={placeholder}
                textInputStyles={textInputStyles}
                loading={loading}
                renderGroup={(params) => (
                    <Box key={params.key} maxHeight="17.5rem">
                        {(!isMatch() && !isEmpty(suggestions) && !isEmpty(pathValue)) &&
                            NoMatchComponent}

                        <GroupHeaderText>{headerText}</GroupHeaderText>
                        {params?.children}
                    </Box>
                )}
                renderOption={(props, option: TOption) => (
                    <AutocompleteOption<TOption>
                        option={option}
                        value={value}
                        selected={selected}
                        enabledClick={enabledClick}
                        onClick={onClick}
                        path={path}
                        closePopper={popperProps?.closePopper}
                        form={form}
                        onRenderOption={onRenderOption}
                        keepSelected={singleSelect}
                    />
                )}
                noOptionsText={NoMatchComponent}
            />
            {ChipsArrayComponent}
        </Box>
    );
}

AutocompleteSuggestions.defaultProps = {
    disabled: false,
    onClick: null,
    isFixedList: false,
};
