import React from "react";
import _ from "lodash";
import styles from "../styles";
import DottedContentArray from "../../DottedContentArray";
import Settings from "../../Settings";
import isIndexInArray from "../../Helpers/isIndexInArray";
import InputLabel from "../../Input/inputLabel";
import PlaceholderText from "../../PlaceholderText";
import DoubleContent from "../../DoubleContent";
import Input from "../../Input/basic";
import update from "react-addons-update";
import Index from "./Index";
import deepcopy from "deepcopy";
import { diff } from "deep-object-diff";
import AuditTooltip from "../../AuditTooltip";
import Label from "../../Input/label";
import { displaySectionFields } from "../actions";

export default class Ratings extends Index {
    constructor(props) {
        super(props);

        const data = this.getDataFromProps(this.props.data);

        /**
         * @var object
         */
        this.copy = this.props.copy || {};

        /**
         * @var object
         */
        this.styles = styles(this.props);

        /**
         * @var object
         */
        this.state = {
            data,
        };

        this.marginTop = { marginTop: "0.46875rem" };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    shouldComponentUpdate(nextProps, nextState) {
        const nextPropsData = nextProps.data || {};
        const thisPropsData = this.props.data || {};
        const difference = diff(nextPropsData, thisPropsData);

        if (!_.isEmpty(difference)) {
            return true;
        }

        if (!_.isEqual(nextProps.disabled, this.props.disabled)) {
            return true;
        }

        if (!_.isEqual(nextState, this.state)) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.data, prevProps.data)) {
            const data = this.getDataFromProps(this.props.data);
            this.setStateValue("data", data);
        }
    }

    getDataFromProps(data = {}) {
        let result = {};

        if (typeof data == "object") {
            if (data.imdb !== undefined) {
                result.imdb = data.imdb;
            }

            if (data.metacritic !== undefined) {
                result.metacritic = data.metacritic;
            }

            if (data.rottenTomatoes !== undefined) {
                result.rottenTomatoes = data.rottenTomatoes;
            }

            if (data.iva !== undefined) {
                result.iva = data.iva;
            }
        }

        return result;
    }

    handleChange(key, type, value) {
        const data = this.getDataFromProps(this.state.data);
        let stateData = deepcopy(data || {});

        let result = stateData[key] || {};
        result[type] = value;

        const updatedState = update(this.state.data, {
            [key]: {
                $set: result,
            },
        });

        this.setState(
            {
                data: updatedState,
            },
            () => {
                if (typeof this.props.handleFormChange == "function") {
                    this.props.handleFormChange(updatedState);
                }
            },
        );
    }

    renderNoneAdded() {
        return false;
    }

    renderNoneAddedPlaceholder() {
        return <PlaceholderText styles={this.marginTop} />;
    }

    renderRatings(options) {
        const { item, scoreSuffix, countSuffix, type = "" } = options;

        const { score, count } = item;

        if (type === "scoreOnly" && score) {
            return (
                <div style={this.marginTop}>
                    <InputLabel
                        text={item.score}
                        lightSufix={scoreSuffix}
                        margin="0"
                    />
                </div>
            );
        }

        if (score && count) {
            return (
                <DottedContentArray styles={this.marginTop}>
                    <InputLabel
                        text={item.score}
                        lightSufix={scoreSuffix}
                        margin="0"
                    />

                    <InputLabel
                        text={item.count}
                        lightSufix={countSuffix}
                        margin="0"
                    />
                </DottedContentArray>
            );
        }
        return this.renderNoneAddedPlaceholder();
    }

    renderInput(itemProps) {
        const { placeholder, value, handleChange, margin, width } = itemProps;

        return (
            <Input
                placeholder={placeholder}
                value={value}
                recordBasic={true}
                handleChange={handleChange}
                margin={margin}
                width={width}
                numeric={true}
            />
        );
    }

    renderContent() {
        const { cmsData = {}, copy = {}, disabled } = this.props;

        const { clientDataStructure = {} } = cmsData;

        const { ratings = false } = clientDataStructure;

        const { data = {} } = this.state;

        const {
            imdb = {},
            metacritic = {},
            rottenTomatoes = {},
            iva = {},
        } = data;

        const imageStyles = {
            marginTop: "0.84375rem",
            maxWidth: "100%",
            height: "1.8125rem",
        };

        return (
            <div style={this.styles.flexMarginContainer}>
                {displaySectionFields(ratings, "imdb") && (
                    <div
                        style={this.styles.flexMarginItemQuoter}
                        id="meta-ratings--imdb"
                    >
                        <AuditTooltip
                            dataSection={"measurement_ratings"}
                            fieldName={"imdb"}
                            fieldKey={["measurement_ratings", "imdb"].join("_")}
                        >
                            <Label
                                label={this.copy.imdbRating || "IMDB Rating"}
                                fieldKey={["measurement_ratings", "imdb"].join(
                                    "_",
                                )}
                            />

                            {disabled && (
                                <React.Fragment>
                                    {this.renderRatings({
                                        item: imdb,
                                        scoreSuffix: this.copy.stars || "Stars",
                                        countSuffix: this.copy.votes || "Votes",
                                    })}

                                    <img
                                        src={`${Settings.images.path}/ids/imdb.svg`}
                                        style={imageStyles}
                                    />
                                </React.Fragment>
                            )}

                            {!disabled && (
                                <DoubleContent>
                                    {this.renderInput({
                                        placeholder:
                                            this.copy.enterStars ||
                                            "Enter Stars",
                                        value: imdb.score,
                                        handleChange: (score) =>
                                            this.handleChange(
                                                "imdb",
                                                "score",
                                                score,
                                            ),
                                        margin: 0,
                                    })}

                                    {this.renderInput({
                                        placeholder:
                                            this.copy.enterVotes ||
                                            "Enter Votes",
                                        value: imdb.count,
                                        handleChange: (count) =>
                                            this.handleChange(
                                                "imdb",
                                                "count",
                                                count,
                                            ),
                                        margin: 0,
                                    })}
                                </DoubleContent>
                            )}
                        </AuditTooltip>
                    </div>
                )}

                {displaySectionFields(ratings, "metacritic") && (
                    <div
                        style={this.styles.flexMarginItemQuoter}
                        id="meta-ratings--metacritic"
                    >
                        <AuditTooltip
                            dataSection={"measurement_ratings"}
                            fieldName={"metacritic"}
                            fieldKey={[
                                "measurement_ratings",
                                "metacritic",
                            ].join("_")}
                        >
                            <Label
                                label={
                                    this.copy.metacriticScore ||
                                    "Metacritic Score"
                                }
                                fieldKey={[
                                    "measurement_ratings",
                                    "metacritic",
                                ].join("_")}
                            />

                            {disabled && (
                                <React.Fragment>
                                    {this.renderRatings({
                                        item: metacritic,
                                        scoreSuffix:
                                            copy.metascore || "Metascore",
                                        countSuffix: copy.reviews || "Reviews",
                                    })}

                                    <img
                                        src={`${Settings.images.path}/ids/Metacritic_logo.svg`}
                                        style={imageStyles}
                                    />
                                </React.Fragment>
                            )}

                            {!disabled && (
                                <DoubleContent>
                                    {this.renderInput({
                                        placeholder:
                                            this.copy.enterScore ||
                                            "Enter Score",
                                        value: metacritic.score,
                                        handleChange: (score) =>
                                            this.handleChange(
                                                "metacritic",
                                                "score",
                                                score,
                                            ),
                                        margin: 0,
                                    })}

                                    {this.renderInput({
                                        placeholder:
                                            this.copy.enterReviews ||
                                            "Enter Reviews",
                                        value: metacritic.count,
                                        handleChange: (count) =>
                                            this.handleChange(
                                                "metacritic",
                                                "count",
                                                count,
                                            ),
                                        margin: 0,
                                    })}
                                </DoubleContent>
                            )}
                        </AuditTooltip>
                    </div>
                )}

                {displaySectionFields(ratings, "rottenTomatoes") && (
                    <div
                        style={Object.assign(
                            {},
                            this.styles.flexMarginItemDouble,
                            {
                                marginTop: "1.5rem",
                            },
                        )}
                        id="meta-ratings--rotten"
                    >
                        <AuditTooltip
                            dataSection={"measurement_ratings"}
                            fieldName={"rottenTomatoes"}
                            fieldKey={[
                                "measurement_ratings",
                                "rottenTomatoes",
                            ].join("_")}
                        >
                            <Label
                                label={
                                    this.copy.rottenTomatoes ||
                                    "Rotten Tomatoes"
                                }
                                fieldKey={[
                                    "measurement_ratings",
                                    "rottenTomatoes",
                                ].join("_")}
                            />

                            {disabled && (
                                <React.Fragment>
                                    {this.renderRatings({
                                        item: {
                                            score:
                                                rottenTomatoes.score &&
                                                `${rottenTomatoes.score}%`,
                                            count:
                                                rottenTomatoes.count &&
                                                `${rottenTomatoes.count}%`,
                                        },
                                        scoreSuffix:
                                            this.copy.stars || "Tomatometer",
                                        countSuffix:
                                            this.copy.votes || "Audience Score",
                                    })}

                                    <img
                                        src={`${Settings.images.path}/ids/Rotten_Tomatoes_logo.svg`}
                                        style={imageStyles}
                                    />
                                </React.Fragment>
                            )}

                            {!disabled && (
                                <DoubleContent>
                                    {this.renderInput({
                                        placeholder:
                                            this.copy.enterScore ||
                                            "Enter Tomatometer Score",
                                        value: rottenTomatoes.score,
                                        handleChange: (score) =>
                                            this.handleChange(
                                                "rottenTomatoes",
                                                "score",
                                                score,
                                            ),
                                        margin: 0,
                                        width: "13rem",
                                    })}

                                    {this.renderInput({
                                        placeholder:
                                            this.copy.enterReviews ||
                                            "Enter Audience Score",
                                        value: rottenTomatoes.count,
                                        handleChange: (count) =>
                                            this.handleChange(
                                                "rottenTomatoes",
                                                "count",
                                                count,
                                            ),
                                        margin: 0,
                                    })}
                                </DoubleContent>
                            )}
                        </AuditTooltip>
                    </div>
                )}

                {displaySectionFields(ratings, "iva") && (
                    <div
                        style={this.styles.flexMarginItemQuoter}
                        id="meta-ratings--iva"
                    >
                        <AuditTooltip
                            dataSection={"measurement_ratings"}
                            fieldName={"iva"}
                            fieldKey={["measurement_ratings", "iva"].join("_")}
                        >
                            <Label
                                label="Fabric Origin Rating"
                                fieldKey={["measurement_ratings", "iva"].join(
                                    "_",
                                )}
                            />

                            {disabled && (
                                <React.Fragment>
                                    {this.renderRatings({
                                        item: {
                                            score: iva.score && `${iva.score}%`,
                                        },
                                        scoreSuffix:
                                            this.copy.stars || "Fabric Origin Rating",
                                        type: "scoreOnly",
                                    })}

                                    <img
                                        src={`${Settings.images.path}/ids/origin.svg`}
                                        style={imageStyles}
                                        alt="origin-logo"
                                    />
                                </React.Fragment>
                            )}

                            {!disabled && (
                                <>
                                    {this.renderInput({
                                        placeholder:
                                            this.copy.enterScore ||
                                            "Enter IVA Score",
                                        value: iva.score,
                                        handleChange: (score) =>
                                            this.handleChange(
                                                "iva",
                                                "score",
                                                score,
                                            ),
                                        margin: 0,
                                    })}
                                </>
                            )}
                        </AuditTooltip>
                    </div>
                )}
            </div>
        );
    }
}
