import { Box, Chip, Popover, Stack, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import * as React from "react";
import { CoreFormSectionLabel } from "../../../../../../core/Form/components/layout/CoreFormSectionLabel";
import { CoreCellText } from "../../../../../../core/Table/components/core-cell-v2/CoreCellText";
import { trimString } from "../../../../../Credits/v3/utils/helpers";

type RenderLongContentProps = {
    value: string;
    title: string;
    chipLabel: string | number;
};

export const RenderLongContent = ({
    value,
    title,
    chipLabel,
}: RenderLongContentProps) => {
    if (isEmpty(value)) {
        return <CoreCellText text="-" />;
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const isDescriptionValid = () => {
        if (typeof chipLabel !== "number") {
            return false;
        }

        if (typeof value === "string") {
            return value.length <= chipLabel;
        }

        return false;
    };

    return (
        <div>
            <Box
                aria-owns={open ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                <CoreCellText> {trimString(value, 29, "...")}</CoreCellText>
            </Box>

            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: "none",
                }}
                PaperProps={{
                    style: {
                        width: "22.5rem",
                        borderRadius: "0.25rem",
                        boxShadow:
                            "0.125rem 0.25rem 0.9375rem 0rem rgba(74, 90, 113, 0.25)",
                    },
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Stack direction="column" padding="0.625rem" width="100%">
                    <Stack
                        direction="row"
                        spacing={2}
                        alignContent="center"
                        width="100%"
                    >
                        <CoreFormSectionLabel>{title}</CoreFormSectionLabel>
                        {isDescriptionValid() ? (
                            <Chip label={chipLabel} />
                        ) : (
                            <Chip
                                sx={{
                                    color: "rgba(118, 20, 36, 1)",
                                    backgroundColor: "rgba(251, 236, 239, 1)",
                                }}
                                label={chipLabel}
                            />
                        )}
                    </Stack>

                    <Typography
                        sx={{
                            marginTop: "0.625rem",
                        }}
                    >
                        {value}

                        {!isDescriptionValid() && (
                            <Typography
                                sx={{
                                    marginTop: "0.625rem",
                                    color: "rgba(214, 70, 94, 1)",
                                }}
                            >
                                {`Maximum ${chipLabel} characters reached!`}
                            </Typography>
                        )}
                    </Typography>
                </Stack>
            </Popover>
        </div>
    );
};
