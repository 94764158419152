import React, { useEffect, useState } from 'react'
import { useStore } from '../../context'
import ClientManagedScripts from '../../../../../../components/ClientManagedOptions/scripts'
import ClientManagedSelectWithTag from '../../../../../../components/SelectWithTag/ClientManaged'
import isClientManaged from "../../../../../../components/ClientManagedOptions/isClientManaged";
import { CAST_AND_CREW } from "../../../../../../components/ClientManagedOptions/options";
import { getDefaultWrittenLanguageCodeFromCache } from "../../../../../../models/ClientManaged/languagesModel";


const SelectLanguageTag = ({script, handleChange, noMargin = false, marginLeft = false}) => {

    const[store] = useStore()

    const {
        cmsData = {},
        options = {}
    } = store

    const {
        clientDataStructure = {},
        clientFeatures = {},
        apiUrls = {}
    } = cmsData

    const scriptOptions = ClientManagedScripts({
        options,
        fallback: options.scripts || [],
        clientFeatures,
        listKey: CAST_AND_CREW,
    })

    const defaultScriptCheck = scriptOptions.findIndex(
        (scriptOption) =>
            scriptOption.value === script
    )

    const fallBackScript = getDefaultWrittenLanguageCodeFromCache()

    const isClientManagedList = isClientManaged({ 
        clientFeatures, 
        clientManagedType: CAST_AND_CREW 
    })

    return(
        <ClientManagedSelectWithTag
            name="script"
            id="script"
            value={defaultScriptCheck !== -1 ? script : fallBackScript}
            clear={true}
            disabledText="Select a script"
            disabled={false}
            readOnly={false}
            data={scriptOptions}
            wrapperStyles={{marginLeft: marginLeft ? '0.85rem' : '0'}}
            selectStyles={{
                lineHeight: "1.2",
            }}
            noMargin={noMargin}
            handleChange={lang => handleChange(lang)}
            clientManagedType={CAST_AND_CREW}
            clientFeatures={clientFeatures}
            apiUrls={apiUrls}
            isClientManaged={isClientManagedList}
        />
    )
}

export default SelectLanguageTag