import React from "react";
import _ from "lodash";
import { envTest } from "../../../components/Environment";
import findPath from "../../../components/Helpers/findPath";
import decimalToBinary from "../../../components/Helpers/decimalToBinary";
import binaryToDecimal from "../../../components/Helpers/binaryToDecimal";
import replaceCharInString from "../../../components/Helpers/replaceCharInString";

import {
    policyTypeRecord,
    crudKey,
    accessTypeView,
    defaultCrudValue,
    binaryValueSelected,
} from "../utils/accessOptions";

import {
    OptionInterface,
    PolicyAccessInterface,
    AccessFieldsInterface,
    AccessSectionInterface,
    ReducerPropsInterface,
    UpdateCRUDBinaryFromNumberInterface,
    ApiRequestPropsInterface,
    TopLevelChangePropsInterface,
} from "../interfaces";

import {
    SET_BASIC_NOTIFICATION,
    SET_ACCESS_CATALOGS,
    SET_POLICIES,
    SET_FETCHING_POLICIES,
    SET_DEFAULT_STATE,
    SET_DELETING_ID,
    SET_FETCHING_ROLES,
    SET_ROLES,
    SET_WORKING,
    SET_NOTIFICATION_OPTIONS,
    SET_CREATE_NEW,
    SET_CREATE_NEW_INITIAL,
} from "./reducer";

import { AccessTypeInterface, PolicyInterface } from "../interfaces";
import { AccessBoxesChangeProps } from "../views/AccessTable";
import deepcopy from "deepcopy";

import { policiesValidation, validationTypeInclude } from "../utils/validation";

import {
    getRoles,
    createRole,
    updateRole,
    deleteRole,
} from "../../../services/RoleService";

import {
    getPolicies,
    deletePolicy,
    updatePolicy,
    createPolicy,
} from "../../../services/PolicyService";

import { getCatalogs } from "../../../services/CatalogService";

import { workingSave } from "../utils/constables";

import {
    getDynamicAccess
} from "../utils/accessStructure";

import { recordPropertiesEmptyPolicy } from "../utils/data/recordProperties"

export function erroMessageToString(
    message: string | Array<string>,
): string | Array<string> {
    let result = message;

    if (Array.isArray(message)) {
        result = message.join(". ");
    }

    return result;
}

export async function handleFetchRoles({
    cmsData,
    dispatch,
}: ApiRequestPropsInterface): Promise<void> {
    dispatch({
        type: SET_FETCHING_ROLES,
        fetchingRoles: true,
    });

    if (envTest) {
        dispatch({
            type: SET_ROLES,
            roles: process.TESTING_DATA_FETCH_ROLES || [],
        });

        dispatch({
            type: SET_FETCHING_ROLES,
            fetchingRoles: false,
        });

        return;
    }

    let response = await getRoles(cmsData);

    if (response.data) {
        dispatch({
            type: SET_ROLES,
            roles: response.data,
        });

        dispatch({
            type: SET_FETCHING_ROLES,
            fetchingRoles: false,
        });
    } else {
        dispatch({
            type: SET_FETCHING_ROLES,
            fetchingRoles: false,
        });
    }
}

export async function handleCreateRole({
    cmsData,
    dispatch,
    item,
}: ApiRequestPropsInterface): Promise<void> {
    dispatch({
        type: SET_WORKING,
        working: workingSave,
    });

    let response = await createRole(cmsData, transformRolesPayload(item));

    dispatch({
        type: SET_WORKING,
        working: "",
    });

    if (response.status == 200) {
        if (process && process.TESTING_DATA_FETCH_ROLES) {
            process.TESTING_DATA_FETCH_ROLES = [
                ...process.TESTING_DATA_FETCH_ROLES,
                item,
            ];
        }

        if (response && response.status == 200) {
            dispatch({
                type: SET_DEFAULT_STATE,
            });

            handleFetchRoles({
                cmsData,
                dispatch,
            });

            return;
        }

        dispatch({
            type: SET_NOTIFICATION_OPTIONS,
            notificationOptions: {
                errorMessage: erroMessageToString(response.message),
            },
        });
    } else {
        dispatch({
            type: SET_WORKING,
            working: false,
        });

        dispatch({
            type: SET_NOTIFICATION_OPTIONS,
            notificationOptions: {
                errorMessage:
                    erroMessageToString(response.message) ||
                    "Failed to create role",
            },
        });
    }
}

export async function handleUpdateRole({
    cmsData,
    dispatch,
    item,
}: ApiRequestPropsInterface): Promise<void> {
    dispatch({
        type: SET_WORKING,
        working: workingSave,
    });

    let response = await updateRole(
        cmsData,
        item.id,
        transformRolesPayload(item),
    );

    dispatch({
        type: SET_WORKING,
        working: "",
    });

    if (process && process.TESTING_DATA_FETCH_ROLES) {
        let items = process.TESTING_DATA_FETCH_ROLES || [];
        items = items.map((it: any) => {
            if (it.id == item.id) {
                return it;
            }

            return item;
        });

        process.TESTING_DATA_FETCH_ROLES = items;
    }

    if (response && response.status == 200) {
        dispatch({
            type: SET_DEFAULT_STATE,
        });

        handleFetchRoles({
            cmsData,
            dispatch,
        });
    } else {
        dispatch({
            type: SET_NOTIFICATION_OPTIONS,
            notificationOptions: {
                errorMessage:
                    erroMessageToString(response.message) ||
                    "Failed to update role",
            },
        });
    }
}

export async function handleDeleteRole({
    cmsData,
    dispatch,
    item,
}: ApiRequestPropsInterface): Promise<void> {
    dispatch({
        type: SET_DELETING_ID,
        deletingId: item.id,
    });

    let response = await deleteRole(cmsData, item.id);

    dispatch({
        type: SET_DELETING_ID,
        deletingId: "",
    });

    if (process && process.TESTING_DATA_FETCH_ROLES) {
        let items = process.TESTING_DATA_FETCH_ROLES || [];
        items = items.filter((it) => it.id !== item.id);

        process.TESTING_DATA_FETCH_ROLES = items;
    }

    if (response && response.status == 200) {
        handleFetchRoles({
            cmsData,
            dispatch,
        });
    } else {
        dispatch({
            type: SET_NOTIFICATION_OPTIONS,
            notificationOptions: {
                errorMessage:
                    erroMessageToString(response.message) ||
                    "Failed to delete role",
            },
        });
    }
}

export async function handleFetchPolicies({
    cmsData,
    dispatch,
}: ApiRequestPropsInterface): Promise<void> {
    dispatch({
        type: SET_FETCHING_POLICIES,
        fetchingPolicies: true,
        corsHeaders: true,
    });

    if (envTest) {
        dispatch({
            type: SET_POLICIES,
            policies:
                process.TESTING_DATA_FETCH_POLICIES ||
                process.TESTING_DATA_FETCH_POLICIES_ROLE ||
                [],
        });

        dispatch({
            type: SET_FETCHING_POLICIES,
            fetchingPolicies: false,
        });
    } else {
        let response = await getPolicies(cmsData);

        dispatch({
            type: SET_POLICIES,
            policies: response.data || [],
        });

        dispatch({
            type: SET_FETCHING_POLICIES,
            fetchingPolicies: false,
        });
    }
}

export async function handleCreatePolicy({
    cmsData,
    dispatch,
    item,
}: ApiRequestPropsInterface): Promise<void> {
    dispatch({
        type: SET_WORKING,
        working: workingSave,
    });

    const payload: PolicyInterface = transformToPayload(item);
    let response = await createPolicy(cmsData, payload);

    dispatch({
        type: SET_WORKING,
        working: false,
    });

    if (response.status == 200) {
        dispatch({
            type: SET_DEFAULT_STATE,
        });

        handleFetchPolicies({
            cmsData,
            dispatch,
        });
    }

    if (response.message) {
        dispatch({
            type: SET_BASIC_NOTIFICATION,
            basicNotification: {
                title: "Validation Error",
                description:
                    erroMessageToString(response.message) ||
                    "Failed to create policy",
            },
        });
    }
}

export async function handleUpdatePolicy({
    cmsData,
    dispatch,
    item,
}: ApiRequestPropsInterface): Promise<void> {
    dispatch({
        type: SET_WORKING,
        working: workingSave,
    });

    const payload: PolicyInterface = transformToPayload(item);
    let response = await updatePolicy(cmsData, item.id, payload);

    dispatch({
        type: SET_WORKING,
        working: false,
    });

    if (response.status == 200) {
        dispatch({
            type: SET_DEFAULT_STATE,
        });

        handleFetchPolicies({
            cmsData,
            dispatch,
        });
    }

    if (response.message) {
        dispatch({
            type: SET_BASIC_NOTIFICATION,
            basicNotification: {
                title: "Validation Error",
                description:
                    erroMessageToString(response.message) ||
                    "Failed to update policy",
            },
        });
    }
}

export async function handleFetchAccessCatalogs({
    cmsData,
    dispatch,
}: ApiRequestPropsInterface): void {
    if (envTest) {
        dispatch({
            type: SET_ACCESS_CATALOGS,
            accessCatalogs: process.TESTING_DATA_FETCH_CATALOGS_ACTION || [],
        });

        return;
    }

    let response = await getCatalogs(cmsData);

    if (Array.isArray(response.data) && !_.isEmpty(response.data)) {
        dispatch({
            type: SET_ACCESS_CATALOGS,
            accessCatalogs: response.data,
        });

        return;
    }

    if (response.status !== 200) {
        dispatch({
            type: SET_BASIC_NOTIFICATION,
            basicNotification: {
                title: "Technical Error",
                message: "Failed to fetch catalogs",
            },
        });

        return;
    }

    if (_.isEmpty(response.data)) {
        dispatch({
            type: SET_BASIC_NOTIFICATION,
            basicNotification: {
                title: "Error",
                message: "No catalogs found",
            },
        });

        return;
    }
}

export async function handleDeletePolicy({
    cmsData,
    dispatch,
    item,
}: ApiRequestPropsInterface): Promise<void> {
    dispatch({
        type: SET_DELETING_ID,
        deletingId: item.id,
    });

    const response = await deletePolicy(cmsData, item.id);

    switch (response.status) {
        case 200:
            dispatch({
                type: SET_DEFAULT_STATE,
            });

            dispatch({
                type: SET_DELETING_ID,
                deletingId: "",
            });

            handleFetchPolicies({
                cmsData,
                dispatch,
            });
            break;
        case 409:
             dispatch({
                type: SET_BASIC_NOTIFICATION,
                basicNotification: {
                    title: "Validation Error",
                    description: response.message || "Failed to delete policy",
                },
            });
            break;
        default:
            dispatch({
                type: SET_BASIC_NOTIFICATION,
                basicNotification: {
                    title: "Technical Error",
                    message:
                        erroMessageToString(response.message) ||
                        "Failed to delete policy",
                },
            });
            break;
    }
}

export async function handleDuplicatePolicy({
    cmsData,
    dispatch,
    item,
}: ApiRequestPropsInterface): Promise<void> {
    const duplicateItem = transformFromPayload(item);
    duplicateItem.name = `COPY - ${item.name}`;

    dispatch({
        type: SET_CREATE_NEW,
        createNew: duplicateItem,
    });
    dispatch({
        type: SET_CREATE_NEW_INITIAL,
        createNewInitial: duplicateItem,
    });
}

export function isCreateNew(state: any): boolean {
    return !_.isEmpty(state.createNew);
}

export function handleSetAllDiscoveryCategories({
    state,
    action,
}: ReducerPropsInterface): any {
    const { categories = [], value, path } = action;

    const createNew = isCreateNew(state);

    let result = createNew ? { ...state.createNew } : { ...state.edit };
    let stateKey = createNew ? "createNew" : "edit";

    let recordState = result[policyTypeRecord] || {};
    let recordCategories = recordState.category || {};

    let allcategories: any = {};
    categories.forEach((it) => {
        if (!recordCategories[it.value]) {
            allcategories[it.value] = {
                [crudKey]: accessTypeView.binary,
            };
        }
    });

    let updatedState = value ? { ...recordCategories, ...allcategories } : [];

    result = _.set(result, `${path}`, {
        ...updatedState,
    });

    return {
        ...state,
        [stateKey]: result,
    };
}

export function assignAccess(
    values: any,
    type: AccessTypeInterface,
    item: OptionInterface,
    value: boolean,
): any {
    const { path = "", children = [], parent } = item;
    const pathItem = deepcopy(_.get(values, path) || {});

    let change = pathItem[crudKey] || 0;

    change = countPermissionValue(change, type, value);

    if (path) {
        values = _.set(values, `${path}`, {
            ...pathItem,
            [crudKey]: change,
        });
    }

    if (!_.isEmpty(children)) {
        children.forEach((parentChildOption: AccessSectionInterface) => {
            function setChildren(child: AccessSectionInterface) {
                let categoryPath = "";

                if (
                    path.split(".").length >= 2 &&
                    path.split(".").slice(-2).join(".") ==
                        child.path.split(".").slice(0, 2).join(".")
                ) {
                    categoryPath = path
                        .split(".")
                        .slice(0, path.split(".").length - 2)
                        .join(".");
                } else if (path.split(".")[0] == policyTypeRecord) {
                    categoryPath = path;
                }

                values = handleLayerUpdate(
                    { type, value },
                    child,
                    values,
                    true,
                    categoryPath,
                );
            }

            setChildren(parentChildOption);

            if (!_.isEmpty(parentChildOption.children)) {
                parentChildOption.children.forEach(
                    (childOption: AccessSectionInterface) => {
                        setChildren(childOption);
                    },
                );
            }
        });
    }

    // set section crud from children
    if (_.isEmpty(children) && parent && path) {
        const mySubString: Array<string> = path.split(parent);

        if (!_.isEmpty(mySubString)) {
            const parentPath: string = mySubString[0] + parent;
            values = setParentToChildrenValues(values, parentPath);
        }
    }

    return values;
}

function setParentToChildrenValues(
    values: OptionInterface[],
    parentPath: string,
): OptionInterface[] {
    const parentData: any = _.get(values, parentPath) || {};

    if (!_.isEmpty(parentData?.section)) {
        let sectionArray: Array<any> = Object.keys(parentData.section).map(
            (key) => {
                return {
                    name: key,
                    [crudKey]: parentData.section[key][crudKey],
                };
            },
        );

        sectionArray = _.orderBy(sectionArray, [crudKey], ["desc"]);

        // Set parent crud to lowest of the children
        if (!_.isEmpty(sectionArray)) {
            const mapToParentItem = _.first(sectionArray);

            if (mapToParentItem && Number.isInteger(mapToParentItem[crudKey])) {
                values = _.set(values, parentPath, {
                    ...parentData,
                    [crudKey]: mapToParentItem[crudKey],
                });
            }
        }
    }

    return values;
}

export function handleSetAccessPolicy({
    state,
    action,
}: ReducerPropsInterface): any {
    const { options = {}, item = {} } = action;

    const { type = {}, value } = options;

    let result = {};
    let updateKey = "";

    if (isCreateNew(state)) {
        result = { ...state.createNew };
        updateKey = "createNew";
    } else {
        result = { ...state.edit };
        updateKey = "edit";
    }

    result = assignAccess(result, type, item, value);

    return {
        ...state,
        [updateKey]: result,
    };
}

export function isBinarySet(binary: any): boolean {
    let result = false;
    if (binary && Number.isInteger(binary)) result = true;

    return result;
}

export function isCrudValueSet(item: any): boolean {
    if (!_.isEmpty(item)) {
        const result: any = _.get(item, findPath(item, crudKey)) || {};
        return isBinarySet(result[crudKey]);
    }

    return false;
}

export function getBinaryViewCRUD(): number {
    return parseInt("0001", 2);
}

export function getBinaryFromNumber(number: number | string): string {
    let result = Number(number).toString(2);

    return result.padStart(4, "0");
}

export function getFormattedBinaryArray(
    number: number | undefined,
): Array<string> {
    let binary: string = Number.isInteger(number)
        ? Number(number).toString(2)
        : "";

    binary = binary.padStart(4, "0");
    return binary.split("");
}

export function checkBinaryValueWithParents(
    number: number | undefined,
    access: AccessTypeInterface,
): boolean {
    const { index, parentIndex } = access;

    const array: Array<string> = getFormattedBinaryArray(number);

    // check for exact match
    if (array[index] == "1") {
        return true;
    }

    // check for child indexes match
    const parentMatches = parentIndex.filter(
        (index: number) => array[index] == "1",
    );

    if (!_.isEmpty(parentMatches)) {
        return true;
    }

    return false;
}

export function getBinaryValueFromNumber(
    number: number | undefined,
    index: number,
): boolean {
    const array: Array<string> = getFormattedBinaryArray(number);
    return array[index] == "1" ? true : false;
}

export function updateCRUDBinaryFromNumber({
    CRUDValue,
    index,
    value,
}: UpdateCRUDBinaryFromNumberInterface): number {
    String.prototype.replaceAt = function (strIndex: number, char: string) {
        let a = this.split("");
        a[strIndex] = char;
        return a.join("");
    };

    let updatedCRUD = getBinaryFromNumber(CRUDValue);
    updatedCRUD = updatedCRUD.toString().replaceAt(index, value ? 1 : 0);

    return parseInt(updatedCRUD, 2);
}

export function transformRolesPayload(data: any): any {
    let results = { ...data };

    if (!_.isEmpty(results.policies)) {
        results.policies = results.policies.map(
            (item: PolicyInterface) => item.id,
        );
    }

    return results;
}

export function transformFromPayload(data: any): any {
    const permissions: any = data.permissions || {};
    const catalog: any = permissions.catalog || {};

    let result: any = {
        id: data.id || "",
        name: data.name || "",
        ...permissions,
    };

    if (!_.isEmpty(catalog)) {
        result.catalogs = Object.keys(catalog);
        const firstCatalog: string = Object.keys(catalog)[0];
        const catalogItem: any = catalog[firstCatalog] || {};

        if (catalogItem && catalogItem.record) {
            result.record = catalogItem.record;
        }
    }

    return result;
}

export function transformToPayload(data: any): any {
    let result: any = {
        permissions: {},
        name: data.name || "",
    };

    const {
        record = {},
        catalogs = [],
        catalog = {},
        userRolesAndPolicies = {},
        platformManagement = {},
        userGroups = {},
        reporting = {},
        exports = {},
        localisations = {},
        collections = {},
        recordBuilder = {},
        swimlanes = {},
        franchiseCollections = {},
        recordProperties = {},
        taskManager = {},
        serviceDesk = {},
        recordApprovals = {}
    } = data;

    let updatedRecord = { ...record };

    if (record.category) {
        let updatedRecordCategory: any = { ...(record.category || {}) };

        Object.keys(record.category).forEach((category: any) => {
            if (_.isEmpty(record.category[category])) {
                delete updatedRecordCategory[category];
            }
        });

        updatedRecord.category = updatedRecordCategory;
    }

    if (_.isEmpty(updatedRecord.category)) {
        delete updatedRecord.category;
    }

    if (!_.isEmpty(updatedRecord.category)) {
        Object.keys(updatedRecord.category).forEach((category) => {
            const categoryWithCrudUpdate = applyCrudToParent(
                updatedRecord.category[category],
            );

            updatedRecord.category[category] = categoryWithCrudUpdate;
        });
    }

    // change crud on record level
    updatedRecord = applyCrudToParent(updatedRecord, "category");

    if (!_.isEmpty(catalogs)) {
        catalogs.forEach((catalog: string) => {
            result = _.set(
                result,
                `permissions.catalog.${catalog}.record`,
                updatedRecord,
            );
        });
    } else {
        result.permissions.catalog = catalog;
    }

    if (!_.isEmpty(userRolesAndPolicies))
        result.permissions.userRolesAndPolicies =
            applyCrudToParent(userRolesAndPolicies);
    if (!_.isEmpty(platformManagement))
        result.permissions.platformManagement =
            applyCrudToParent(platformManagement);
    if (!_.isEmpty(userGroups))
        result.permissions.userGroups = applyCrudToParent(userGroups);
    if (!_.isEmpty(reporting))
        result.permissions.reporting = applyCrudToParent(reporting);
    if (!_.isEmpty(exports))
        result.permissions.exports = applyCrudToParent(exports);
    if (!_.isEmpty(localisations))
        result.permissions.localisations = applyCrudToParent(localisations);
    if (!_.isEmpty(collections))
        result.permissions.collections = applyCrudToParent(collections);
    if (!_.isEmpty(recordBuilder))
        result.permissions.recordBuilder = applyCrudToParent(recordBuilder);
    if (!_.isEmpty(swimlanes))
        result.permissions.swimlanes = applyCrudToParent(swimlanes);
    if (!_.isEmpty(franchiseCollections))
        result.permissions.franchiseCollections =
            applyCrudToParent(franchiseCollections);

    if (!_.isEmpty(recordProperties))
        result.permissions.recordProperties =
            removeCrudKeyFromParent(recordProperties);

    if (recordProperties?.[crudKey] == defaultCrudValue) {
        result.permissions.recordProperties = recordPropertiesEmptyPolicy
    }

    if (!_.isEmpty(taskManager)) result.permissions.taskManager = taskManager;
    if (!_.isEmpty(serviceDesk)) result.permissions.serviceDesk = serviceDesk;
    if (!_.isEmpty(recordApprovals)) result.permissions.recordApprovals = recordApprovals;

    return result;
}

function removeCrudKeyFromParent(parent: OptionInterface): OptionInterface {
    let updatedParent = { ...parent };
    delete updatedParent[crudKey];

    return updatedParent;
}

function applyCrudToParent(
    values: OptionInterface,
    tabKey: string = "tab",
): OptionInterface {
    let childBinary: Array<string> = [];

    const parentCrud: number = values[crudKey];
    const tabValues = values?.[tabKey] || {};

    if (!_.isEmpty(tabValues)) {
        Object.keys(tabValues).forEach((key) => {
            let itemCrud = tabValues[key][crudKey];

            if (itemCrud) {
                childBinary.push(decimalToBinary(itemCrud));
            }

            if (tabValues[key].section) {
                Object.keys(tabValues[key].section).forEach((sectionKey) => {
                    let itemCrud = tabValues[key].section[sectionKey][crudKey];

                    if (itemCrud) {
                        childBinary.push(decimalToBinary(itemCrud));
                    }
                });
            }
        });
    }

    let parentBinary: string = decimalToBinary(
        !_.isEmpty(childBinary) ? 0 : parentCrud,
    );

    childBinary.forEach((it) => {
        if (it[0] == binaryValueSelected) {
            parentBinary = replaceCharInString(
                parentBinary,
                binaryValueSelected,
                0,
            );
        }

        if (it[1] == binaryValueSelected) {
            parentBinary = replaceCharInString(
                parentBinary,
                binaryValueSelected,
                1,
            );
        }

        if (it[2] == binaryValueSelected) {
            parentBinary = replaceCharInString(
                parentBinary,
                binaryValueSelected,
                2,
            );
        }

        if (it[3] == binaryValueSelected) {
            parentBinary = replaceCharInString(
                parentBinary,
                binaryValueSelected,
                3,
            );
        }
    });

    const childCrudDecimal: number = binaryToDecimal(parentBinary);

    return {
        ...values,
        [crudKey]: childCrudDecimal ? childCrudDecimal : parentCrud,
    };
}

export function handleSetRecordValue({
    state,
    action,
}: ReducerPropsInterface): any {
    let result = state;
    const createNew = isCreateNew(state);

    let createUpdateItem = createNew
        ? { ...state.createNew }
        : { ...state.edit };

    const { path, value } = action;

    if (path && value) {
        createUpdateItem = _.set(createUpdateItem, path, value);
    }

    if (createNew) {
        result.createNew = createUpdateItem;
    } else {
        result.edit = createUpdateItem;
    }

    return result;
}

export function checkChildrenAccessValues(
    item: any,
    itemValues: any,
    type: any,
): boolean {
    let dash: boolean = false;

    if (item && !_.isEmpty(item.children)) {
        const childrenLength: number = item.children.length;
        const childAccessCount: number = getChildrenAccessCount(
            item,
            itemValues,
            type,
        );

        if (childAccessCount > 0 && childAccessCount !== childrenLength) {
            dash = true;
        }
    }

    return dash;
}

export function isCheckedTopLevelAccessType(
    item: any,
    itemValues: any,
    type: any,
): boolean {
    if (item && !_.isEmpty(item.children)) {
        const childrenLength: number = item.children.length;
        const accessCount: number = getChildrenAccessCount(
            item,
            itemValues,
            type,
        );

        if (accessCount == childrenLength) {
            return true;
        }
    }

    return false;
}

export function getChildrenAccessCount(
    item: any,
    itemValues: any,
    type: any,
): number {
    let accessCount: number = 0;

    const loopChildAccess = (sectionKey: string): void => {
        if (itemValues && !_.isEmpty(itemValues[sectionKey])) {
            Object.keys(itemValues[sectionKey]).forEach((section: any) => {
                const item = itemValues[sectionKey][section] || {};
                const checked = getBinaryValueFromNumber(
                    item[crudKey],
                    type.index,
                );

                if (checked) {
                    accessCount = accessCount + 1;
                }
            });
        }
    };

    if (item.name == policyTypeRecord && !_.isEmpty(item.children)) {
        let categoriesFullPermissionCount: number = 0;
        item.children.forEach((element: any) => {
            if (!_.isEmpty(element.children)) {
                categoriesFullPermissionCount += Number(
                    isCheckedTopLevelAccessType(
                        element,
                        itemValues.category[element.name],
                        type,
                    ),
                );
            }
        });

        return categoriesFullPermissionCount;
    }

    if (item && !_.isEmpty(item.children)) {
        loopChildAccess("tab");
        loopChildAccess("section");
    }

    return accessCount;
}

export const countPermissionValue = (
    result: number,
    type: AccessTypeInterface,
    value: boolean | null = null,
): number => {
    const { binary, index } = type;

    if (value && !getBinaryValueFromNumber(result, index)) {
        result = result ? result + binary : binary;
    }

    if (!value && getBinaryValueFromNumber(result, index)) {
        result = result && result - binary >= 0 ? result - binary : result;
    }

    return result;
};

export const handleLayerUpdate = (
    values: AccessBoxesChangeProps,
    field: AccessSectionInterface,
    updatedSection: any,
    fieldPath: boolean = true,
    category: string | null = null,
) => {
    const { value } = values;

    const type: AccessTypeInterface = values.type || {};

    const { disabledAccessTypes = [] } = field;

    if (!_.isEmpty(disabledAccessTypes)) {
        const disabled: boolean = !_.isEmpty(
            disabledAccessTypes.find(
                (access: AccessTypeInterface) => access.name == type.name,
            ),
        );

        if (disabled) {
            return updatedSection;
        }
    }

    const categoryPart = !_.isEmpty(category) ? `${category}.` : "";
    const fieldPart = fieldPath ? `${field.path}.` : "";
    const fullPath = `${categoryPart}${fieldPart}${crudKey}`;

    let valueToSet = _.get(updatedSection, fullPath);
    valueToSet = countPermissionValue(valueToSet, type, value);

    updatedSection = _.set(
        updatedSection,
        fullPath,
        valueToSet || defaultCrudValue,
    );

    return updatedSection;
};

export function handleTopLevelChange({
    item,
    options,
    setState,
    section,
    values,
}: TopLevelChangePropsInterface) {
    let updatedState = {
        ...item,
    };

    let updatedSection = {
        [section]: deepcopy({ ...updatedState[section] })
    };

    if (!_.isEmpty(options)) {
        options.forEach((option: PolicyAccessInterface) => {
            let categoryPath: string = "";

            if (section == policyTypeRecord) {
                categoryPath = option.path;
                updatedSection = handleLayerUpdate(
                    values,
                    option,
                    updatedSection,
                    false,
                    categoryPath,
                );
            } else {
                updatedSection = handleLayerUpdate(
                    values,
                    option,
                    updatedSection,
                );
            }

            if (!_.isEmpty(option.children)) {
                option.children.forEach(
                    (childOption: AccessSectionInterface) => {
                        updatedSection = handleLayerUpdate(
                            values,
                            childOption,
                            updatedSection,
                            true,
                            categoryPath,
                        );

                        if (!_.isEmpty(childOption.children)) {
                            childOption.children.forEach(
                                (field: AccessFieldsInterface) => {
                                    updatedSection = handleLayerUpdate(
                                        values,
                                        field,
                                        updatedSection,
                                        true,
                                        categoryPath,
                                    );
                                },
                            );
                        }
                    },
                );
            }
        });
    }

    updatedState = {
        ...updatedState,
        ...updatedSection,
    };

    setState(updatedState);
}

export function setValidationMessage(data: PolicyAccessInterface): string {
    let result: string = "";

    if (!_.isEmpty(policiesValidation)) {
        policiesValidation.forEach((item) => {
            if (item.paths) {
                item.paths.forEach((path) => {
                    const pathItem = _.get(data, path);
                    const emptyPath: boolean =
                        _.isEmpty(pathItem) ||
                        _.isEqual(pathItem, { [crudKey]: 0 });

                    if (item.type == validationTypeInclude && emptyPath) {
                        result = item.description;
                    }
                });
            }
        });
    }

    return result;
}

export function filterDataBySearchTerm(
    searchTerm: string,
    data: Array<any>,
): Array<any> {
    return data.filter((item: any) =>
        item.name.toLowerCase().includes(searchTerm),
    );
}

export function buildDynamicStructure(items: Array<any>, path: string, disabledAccessTypes): OptionInterface[] {
    const sections: OptionInterface[] = items?.map((item: any) => {
        return {
            disabledAccessTypes,
            name: item.text,
            value: item.value,
            path: `${path}.${item.value}`,
            children: [],
        }
    })

    return getDynamicAccess(sections)
}
