import React from "react";
import { observer } from "mobx-react";
import _ from "lodash"
import { StyledMeasurement } from "../styles/styles";
import config from "../config"
import { MeasurementSource } from "../interfaces"
import { classPrefix } from "../utils/constants";
import MeasurementCardView from "./MeasurementCard.view";

const MeasurementView = observer(() => {
    return (
       <StyledMeasurement 
            data-testid={`${classPrefix}-measurement-view`}
            className={`${classPrefix}-measurement-view`}>

            {config.measurementSouces.map((measurement: MeasurementSource, index: number) => {
                return (
                    <MeasurementCardView 
                        key={index}
                        {...measurement}
                    />
                )
            })}
       </StyledMeasurement>
    )
})

export default MeasurementView