/* eslint-disable class-methods-use-this */
import axios, { AxiosError } from "axios";
import { template } from "lodash";
import { TicketAttachment } from "../containers/ticket/attachments/types";
import {
    TicketComment,
    TicketCommentForCreate,
} from "../containers/ticket/comments/validation/commentVS";
import { OutputFiltersType } from "../containers/ticket/filters-form/validation/ticketFiltersVS";
import {
    TicketHistoryEntry,
    TicketHistoryEventName,
} from "../containers/ticket/history/types";
import {
    StandaloneTicket,
    StandaloneTicketForUpdate,
} from "../containers/ticket/shared/validationSchema";
import { RequestPaginationObject } from "../types/interfaces.shared";
import { fetchApi } from "../utils/fetchApi";
import { log } from "../utils/log";
import { mimeTypeFromExtension } from "../utils/mimeTypeFromExtension";
import {
    API_ERROR_BAD_REQUEST,
    API_ERROR_FORBIDDEN,
    API_ERROR_NOT_FOUND,
    API_ERROR_PAYLOAD_TOO_LARGE,
    API_ERROR_UNAUTHORIZED,
    API_ERROR_UNEXPECTED,
    API_VALIDATION_ERROR,
    API_WARNING_CANCELLED,
    ApiResponse,
    CustomDownloadResponse,
    NoDataApiResponse,
    TMBaseUrl,
    TMEndpoints,
    TaskManagerApiReturnType,
    getUrl,
} from "./api.config";

class TaskManagerApi {
    userToken: string = "";

    abortControllers: {
        [k: string]: {
            [k in "GET" | "POST" | "PUT" | "DELETE"]: AbortController;
        };
    } = {};

    constructor(userToken?: string) {
        this.userToken = userToken;

        this.abortControllers = {
            [TMEndpoints.TICKETS]: {
                GET: null,
                POST: null,
                PUT: null,
                DELETE: null,
            },
        };
    }

    async create(
        data: StandaloneTicketForUpdate,
    ): Promise<TaskManagerApiReturnType<StandaloneTicket>> {
        log("[API.TaskManager] Creating a new ticket using form data", data);

        try {
            const url = getUrl(TMBaseUrl, TMEndpoints.TICKETS_NEW);
            const response = await fetchApi<{
                message: string;
                data: StandaloneTicket;
            }>(url, {
                method: "POST",
                data,
                headers: {
                    "Content-Type": "application/json",
                    token: this.userToken,
                },
            });

            return response.data;
        } catch (err) {
            if (err.response?.status === 422) {
                return this.handleValidationErrors(err.response.data);
            }

            return this.handleExceptions(err, "create");
        }
    }

    async update(
        id: StandaloneTicket["id"],
        data: StandaloneTicketForUpdate[],
    ): Promise<TaskManagerApiReturnType<StandaloneTicket>> {
        log(`[API.TaskManager] Updating ticket id [${id}] with new data`, data);

        try {
            const url = getUrl(TMBaseUrl, TMEndpoints.TICKETS);
            const response = await fetchApi<{
                message: string;
                data: StandaloneTicket;
            }>(url, {
                method: "PUT",
                data,
                headers: {
                    "Content-Type": "application/json",
                    token: this.userToken,
                },
            });

            return response.data;
        } catch (err) {
            log("[API.TaskManager] Failed call to update", err);

            if (err.response?.status === 422) {
                return this.handleValidationErrors(err.response.data);
            }

            return {
                message: API_ERROR_UNEXPECTED,
                data: {},
                isError: true,
            };
        }
    }

    async getTicketsByFilters(
        filters: OutputFiltersType,
        pagination: RequestPaginationObject = {
            page: 1,
        },
    ): Promise<TaskManagerApiReturnType<StandaloneTicket[]>> {
        log(
            "[API.TaskManager] Calling getTicketsByFilters using filters & pagination",
            { filters, pagination },
        );

        try {
            // Abort previous request if it exists and is not already aborted
            // (e.g. by quickly switching between filters)
            // Explanation here: https://stackoverflow.com/a/31061838/5434741
            let controller = this.abortControllers[TMEndpoints.TICKETS]?.POST;
            if (controller && !controller.signal.aborted) {
                controller.abort();
                this.abortControllers[TMEndpoints.TICKETS].POST = null;
            }
            controller = new AbortController();
            this.abortControllers[TMEndpoints.TICKETS].POST = controller;

            const url = getUrl(TMBaseUrl, TMEndpoints.TICKETS);
            const response = await fetchApi<ApiResponse<StandaloneTicket>>(
                url,
                {
                    method: "POST",
                    data: {
                        ...filters,
                        page: pagination.page,
                    },
                    headers: {
                        "Content-Type": "application/json",
                        token: this.userToken,
                    },
                    signal: controller.signal,
                },
            );

            return response.data;
        } catch (err) {
            if (axios.isCancel(err)) {
                log(
                    "[API.TaskManager] Request getTicketsByFilters canceled",
                    err,
                );
                return {
                    message: API_WARNING_CANCELLED,
                    data: [],
                    isError: true,
                };
            }

            return this.handleExceptions(err, "getTicketsByFilters");
        }
    }

    async getTicketsByIds(
        ids: StandaloneTicket["id"][],
    ): Promise<TaskManagerApiReturnType<StandaloneTicket[]>> {
        log("[API.TaskManager] Obtaining tickets from API using ids", ids);

        try {
            const url = getUrl(TMBaseUrl, TMEndpoints.TICKETS);
            const data = {
                id: ids,
            };
            const response = await fetchApi<ApiResponse<StandaloneTicket>>(
                url,
                {
                    method: "POST",
                    data,
                    headers: {
                        "Content-Type": "application/json",
                        token: this.userToken,
                    },
                },
            );

            return response.data;
        } catch (err) {
            return this.handleExceptions(err, "getTicketsByIds");
        }
    }

    async getTicketById(
        id: StandaloneTicket["id"],
    ): Promise<TaskManagerApiReturnType<StandaloneTicket>> {
        log("[API.TaskManager] Obtaining full ticket from API using id", id);

        try {
            const endpoint = template(TMEndpoints.TICKET_BY_ID)({
                ticketId: id,
            });
            const url = getUrl(TMBaseUrl, endpoint);
            const response = await fetchApi<{ data: StandaloneTicket }>(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    token: this.userToken,
                },
            });

            return response.data;
        } catch (err) {
            return {
                data: {},
                message: API_ERROR_UNEXPECTED,
                isError: true,
            };
        }
    }

    async deleteMulti(
        ticketIds: StandaloneTicket["id"][],
    ): Promise<TaskManagerApiReturnType<NoDataApiResponse>> {
        log("[API.TaskManager] Called delete with ticketIds: ", ticketIds);

        try {
            const url = getUrl(TMBaseUrl, TMEndpoints.TICKETS);
            const data = {
                id: ticketIds,
            };
            const response = await fetchApi<
                TaskManagerApiReturnType<NoDataApiResponse>
            >(url, {
                method: "DELETE",
                data,
                headers: {
                    "Content-Type": "application/json",
                    token: this.userToken,
                },
            });

            return response.data;
        } catch (err) {
            log("[API.TaskManager] Failed call to delete", err);
            return {
                data: {
                    message: API_ERROR_UNEXPECTED,
                },
                message: API_ERROR_UNEXPECTED,
                isError: true,
            };
        }
    }

    async updateFieldMulti<K extends keyof StandaloneTicketForUpdate>(
        ticketIds: Array<StandaloneTicket["id"]>,
        field: K,
        value: StandaloneTicketForUpdate[K],
    ): Promise<TaskManagerApiReturnType<NoDataApiResponse>> {
        log("[API.TaskManager] Called update with: ", {
            ticketIds,
            field,
            value,
        });

        try {
            const url = getUrl(TMBaseUrl, TMEndpoints.TICKETS);
            const data = ticketIds.map((t) => {
                return {
                    id: t,
                    [field]: value,
                };
            });
            const response = await fetchApi<
                TaskManagerApiReturnType<NoDataApiResponse>
            >(url, {
                method: "PUT",
                data,
                headers: {
                    "Content-Type": "application/json",
                    token: this.userToken,
                },
            });

            return response.data;
        } catch (err) {
            log("[API.TaskManager] Failed call to update", err);
            return {
                data: {
                    message: API_ERROR_UNEXPECTED,
                },
                message: API_ERROR_UNEXPECTED,
                isError: true,
            };
        }
    }

    async getComments(
        ticketId: StandaloneTicket["id"],
    ): Promise<TaskManagerApiReturnType<TicketComment[]>> {
        log(
            "[API.TaskManager] Obtaining comments from API using ticket id",
            ticketId,
        );

        try {
            const endpoint = template(TMEndpoints.TICKET_COMMENTS)({
                ticketId,
            });
            const url = getUrl(TMBaseUrl, endpoint);
            const response = await fetchApi<ApiResponse<TicketComment>>(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    token: this.userToken,
                },
            });

            return response.data;
        } catch (err) {
            this.handleExceptions(err, "getComments");
        }
    }

    async postComment(
        ticketId: StandaloneTicket["id"],
        data: TicketCommentForCreate,
    ): Promise<TaskManagerApiReturnType<TicketComment[]>> {
        log("[API.TaskManager] Called postComment with: ", {
            ticketId,
            data,
        });

        try {
            const endpoint = template(TMEndpoints.TICKET_COMMENTS)({
                ticketId,
            });
            const url = getUrl(TMBaseUrl, endpoint);
            const response = await fetchApi<ApiResponse<TicketComment>>(url, {
                method: "POST",
                data,
                headers: {
                    "Content-Type": "application/json",
                    token: this.userToken,
                },
            });

            return response.data;
        } catch (err) {
            return this.handleExceptions(err, "postComment");
        }
    }

    async deleteComment(
        ticketId: StandaloneTicket["id"],
        commentId: TicketComment["id"],
    ): Promise<TaskManagerApiReturnType<TicketComment[]>> {
        log("[API.TaskManager] Called deleteComment with: ", {
            ticketId,
            commentId,
        });

        try {
            const endpoint = template(TMEndpoints.TICKET_COMMENTS)({
                ticketId,
            });
            const url = getUrl(TMBaseUrl, endpoint);
            const data = {
                id: commentId,
            };
            const response = await fetchApi<ApiResponse<TicketComment>>(url, {
                method: "DELETE",
                data,
                headers: {
                    "Content-Type": "application/json",
                    token: this.userToken,
                },
            });

            return response.data;
        } catch (err) {
            return this.handleExceptions(err, "deleteComment");
        }
    }

    async getHistory(
        ticketId: StandaloneTicket["id"],
    ): Promise<
        TaskManagerApiReturnType<TicketHistoryEntry<TicketHistoryEventName>[]>
    > {
        log(
            "[API.TaskManager] Obtaining history from API using ticket id",
            ticketId,
        );

        try {
            const endpoint = template(TMEndpoints.TICKET_HISTORY)({
                ticketId,
            });
            const url = getUrl(TMBaseUrl, endpoint);
            const response = await fetchApi<
                ApiResponse<TicketHistoryEntry<TicketHistoryEventName>>
            >(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    token: this.userToken,
                },
            });

            return response.data;
        } catch (err) {
            return this.handleExceptions(err, "getHistory");
        }
    }

    async getAttachments(
        ticketId: StandaloneTicket["id"],
    ): Promise<TaskManagerApiReturnType<TicketAttachment[]>> {
        log(
            "[API.TaskManager] Obtaining attachments from API using ticket id",
            ticketId,
        );

        try {
            const endpoint = template(TMEndpoints.TICKET_ATTACHMENTS)({
                ticketId,
            });
            const url = getUrl(TMBaseUrl, endpoint);
            const response = await fetchApi<ApiResponse<TicketAttachment>>(
                url,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        token: this.userToken,
                    },
                },
            );

            return response.data;
        } catch (err) {
            return this.handleExceptions(err, "getAttachments");
        }
    }

    async uploadAttachments(
        ticketId: StandaloneTicket["id"],
        files: FileList,
    ): Promise<TaskManagerApiReturnType<TicketAttachment[]>> {
        log(
            "[API.TaskManager] Uploading attachments to API using ticket id",
            ticketId,
        );

        try {
            const endpoint = template(TMEndpoints.TICKET_ATTACHMENTS)({
                ticketId,
            });
            const url = getUrl(TMBaseUrl, endpoint);

            const form = new FormData();
            for (let i = 0; i < files.length; i++) {
                const extension = files[i].name.split(".").pop();

                // Mimetype is not always available, so we need to add it manually
                const f = new File([files[i]], files[i].name, {
                    type: mimeTypeFromExtension(extension),
                });

                form.append("attachment", f);
            }

            const response = await fetchApi<ApiResponse<TicketAttachment>>(
                url,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "multipart/form-data",
                        token: this.userToken,
                    },
                    data: form,
                },
            );

            return response.data;
        } catch (err) {
            return this.handleExceptions(err, "uploadAttachments");
        }
    }

    async deleteAttachments(
        ticketId: StandaloneTicket["id"],
        attachmentIds: TicketAttachment["id"][],
    ): Promise<TaskManagerApiReturnType<NoDataApiResponse>> {
        log(
            "[API.TaskManager] Deleting attachments from API using ticket id",
            ticketId,
        );

        try {
            const endpoint = template(TMEndpoints.TICKET_ATTACHMENTS)({
                ticketId,
            });
            const url = getUrl(TMBaseUrl, endpoint);
            const response = await fetchApi<
                TaskManagerApiReturnType<NoDataApiResponse>
            >(url, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    token: this.userToken,
                },
                data: {
                    attachments: attachmentIds,
                },
            });
            return response.data;
        } catch (err) {
            log("[API.TaskManager] Failed call to deleteAttachments", err);
            return {
                data: {
                    message: API_ERROR_UNEXPECTED,
                },
                message: API_ERROR_UNEXPECTED,
                isError: true,
            };
        }
    }

    async downloadAttachments(
        ticketId: StandaloneTicket["id"],
        attachmentIds: TicketAttachment["id"][],
    ): Promise<CustomDownloadResponse> {
        log(
            "[API.TaskManager] Downloading attachments from API using ticket id",
            ticketId,
        );

        try {
            const endpoint = template(TMEndpoints.TICKET_DOWNLOAD_ATTACHMENTS)({
                ticketId,
            });
            const url = getUrl(TMBaseUrl, endpoint);
            const response = await fetchApi<Blob>(url, {
                method: "POST",
                responseType: "blob",
                headers: {
                    "Content-Type": "application/json",
                    token: this.userToken,
                },
                data: {
                    attachments: attachmentIds,
                },
            });

            let filename = "download";
            const contentDisposition = response.headers["content-disposition"];
            if (contentDisposition) {
                const dispositionParts = contentDisposition.split("filename=");
                if (dispositionParts.length > 1) {
                    filename = dispositionParts[1];
                }
            }

            return {
                data: response.data,
                mimeType: response.headers["content-type"],
                name: filename,
            };
        } catch (err) {
            log("[API.TaskManager] Failed call to downloadAttachments", err);
        }
    }

    async approve(
        ticketId: StandaloneTicket["id"],
    ): Promise<TaskManagerApiReturnType<NoDataApiResponse>> {
        log("[API.TaskManager] Approving ticket with id", ticketId);

        try {
            const endpoint = template(TMEndpoints.TICKET_APPROVE)({
                ticketId,
            });
            const url = getUrl(TMBaseUrl, endpoint);
            const response = await fetchApi<NoDataApiResponse>(url, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    token: this.userToken,
                },
            });

            return response;
        } catch (err) {
            return this.handleExceptions(err, "approve");
        }
    }

    private handleExceptions(err: AxiosError, methodName: string) {
        log(`[API.TaskManager] Failed call to ${methodName}`, err);

        return {
            data: [],
            message: this.userMessageFromError(err),
            isError: true,
        };
    }

    private handleValidationErrors(data) {
        return {
            data: [],
            message: `Validation errors: ${data.errors
                ?.map((e) => e.message)
                .join(", ")}`,
            isError: true,
        };
    }

    private userMessageFromError = (error: AxiosError) => {
        if (error.response?.status === 400 || error.status === 400) {
            return API_ERROR_BAD_REQUEST;
        }

        if (error.response?.status === 413 || error.status === 413) {
            return API_ERROR_PAYLOAD_TOO_LARGE;
        }

        if (error.response?.status === 401 || error.status === 401) {
            return API_ERROR_UNAUTHORIZED;
        }

        if (error.response?.status === 403 || error.status === 403) {
            return API_ERROR_FORBIDDEN;
        }

        if (error.response?.status === 404 || error.status === 404) {
            return API_ERROR_NOT_FOUND;
        }

        if (error.response?.status === 422) {
            return API_VALIDATION_ERROR;
        }

        return API_ERROR_UNEXPECTED;
    };
}

export default TaskManagerApi;
