import SendRequest from "../../components/SendRequest";
import { getSession, connect, buildTopic } from "../../components/IoT";
import deepcopy from "deepcopy";
import { recordSave } from "./DefaultCollectionStateValues";
import _ from "lodash";
import { getTransformedRecordToApi } from "../../models/Record/model"

const HandleCreateNewRecordRequest = (props) => {
    const { state = {}, copy = {}, category } = props;

    const {
        set,
        cmsData = {},
        newRecord,
        handleRecentActivitySearch = () => {},
        handleRecordUpdateAfterIoT = () => {},
    } = state;

    const { apiUrls = {}, clientData = {}, clientFeatures = {} } = cmsData;

    const { iot } = clientFeatures;

    set({
        savingRecord: true,
        loadingId: "save",
    });

    const requestData = getTransformedRecordToApi(newRecord, cmsData);

    const options = {
        url: apiUrls.createRecordUrl,
        method: "POST",
        body: newRecord,
    };

    const handleError = (data) => {
        set({
            savingRecord: false,
            loadingId: false,
            notification: {
                title: "Technical Error",
                description: "Record could not be created",
                status: "alert",
                onClick: () => {
                    set({
                        notification: {},
                    });
                },
            },
        });
    };

    const handleSuccess = (description) => {
        set({
            savingRecord: false,
            loadingId: false,
            notification: {
                title: `${category} has been submitted`,
                description: description,
                status: "alert",
                onClick: () => {
                    handleRecentActivitySearch(category);
                    set({
                        notification: {},
                    });
                },
            },
        });
    };

    SendRequest(
        options,
        async (response) => {
            const { status, data, message } = response;

            const { titles = [], meta_id, original_title = "" } = data;

            const titleItem =
                titles.find((item) => item.display && item.title) || {};
            const displayTitle = titleItem.title || original_title || category;
            const description = `${displayTitle} has been submitted. ${category} creation may take few minutes.`;

            if (status == 200) {
                let updatedState = deepcopy(recordSave);

                if (iot) {
                    const IoT = await getSession();
                    const topic = buildTopic({
                        client: clientData.clientRsName || process.env.CLIENT,
                        env: process.env.APP_ENV,
                        meta_id,
                        topic: "created",
                    });

                    await connect({
                        ...IoT,
                        topic,
                        onMessage: () => handleRecordUpdateAfterIoT(),
                    });

                    updatedState.IoTConnection = newRecord;
                }

                set(updatedState, () => {});
                handleSuccess(description);
                return;
            } else {
                handleError(message);
            }
        },
        (e) => {
            handleError(e);
            console.log(e);
        },
    );
};

export default HandleCreateNewRecordRequest;
