import { gql } from "@apollo/client";
import { FieldMapInterface } from "../../interfaces";
import editableOption from './options/editable';
import validationOption from './options/validation';
import permissions from "./options/permissions";
import taskManager from "./options/taskManager";
import messages from "./options/messages";

const properties = `
    id
    key
    displayName
    order
    archived
    dataType
    view
    categories
    options {
        ${editableOption}
        ${validationOption}
        ${messages}
        ${taskManager}
    }
`;

export const schemaGetAll = `
    getNumberingConfigs {
        label
        ${properties}
        ${permissions}
    }
`;

export const queryGetAll = gql`
    query {
        ${schemaGetAll}
    }
`;

export const queryGetOne = gql`
    query {
        getNumberingConfig(id: $id) {
            ${properties}
        }
    }
`;

export const mutationCreate = gql`
    mutation ($input: CreateNumberingConfigInput!) {
        createNumberingConfig(input: $input) {
            ${properties}
        }
    }
`;

export const mutationUpdate = gql`
    mutation ($id: ID!, $input: UpdateNumberingConfigInput!) {
        updateNumberingConfig(id: $id, input: $input) {
            ${properties}
        }
    }
`;

const map: FieldMapInterface = {
    createMutation: mutationCreate,
    updateMutation: mutationUpdate,
    queryAll: queryGetAll,
    dataPath: "getNumberingConfigs",
};

export default map;
