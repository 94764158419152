import AutocompleteInput from "../../../../../components/AutocompleteInput";
import BubbleList from "../../../../../components/BubbleList";
import { StyledForm } from "../../styles/StyledForm";
import { observer } from "mobx-react";
import { useStores } from "../../store/rootStore";
import getYears from "../../../../../components/Options/years";

interface Props {
    cmsData: any;
    contributors: any[];
}

export const RenderEditAwardForm: React.FC<Props> = observer((props: Props) => {
    const { cmsData = {}, contributors = [] } = props;

    const apiUrls = cmsData.apiUrls || {};

    const { awardsStore } = useStores() || {};

    return (
        <StyledForm columnSpacing="1rem">
            <div className="form-item half">
                <AutocompleteInput
                    label="Awards Body"
                    placeholder="Enter Awards Body"
                    itemTextKey={"name"}
                    apiUrls={apiUrls}
                    searchKey={"award_bodies"}
                    searchOnClick={true}
                    value={awardsStore.editItem.award_body.value}
                    openOnClick={true}
                    keepSelected={true}
                    useEnterButtonOnSelect={true}
                    handleListSelection={(value) => {
                        awardsStore.handleEditItemFieldUpdate(
                            "award_body",
                            value,
                            [],
                        );
                    }}
                />
            </div>
            <div className="form-item fourth">
                <AutocompleteInput
                    label="Result"
                    placeholder="Enter Result"
                    itemTextKey={"name"}
                    apiUrls={apiUrls}
                    searchKey={"results"}
                    searchOnClick={true}
                    value={awardsStore.editItem.result.value}
                    openOnClick={true}
                    keepSelected={true}
                    useEnterButtonOnSelect={true}
                    handleListSelection={(value) =>
                        awardsStore.handleEditItemFieldUpdate(
                            "result",
                            value,
                            [],
                        )
                    }
                />
            </div>
            <div className="form-item fourth">
                <AutocompleteInput
                    label="Year"
                    placeholder="Enter Year"
                    staticDatalist={getYears(awardsStore.rootStore.year)}
                    value={awardsStore.editItem.year}
                    openOnClick={true}
                    keepSelected={true}
                    useEnterButtonOnSelect={true}
                    handleListSelection={(value) => {
                        awardsStore.handleEditItemFieldUpdate(
                            "year",
                            value.value,
                            [],
                        );
                    }}
                />
            </div>
            <div className="form-item" style={{ marginTop: "1rem" }}>
                <AutocompleteInput
                    label="Category"
                    placeholder="Enter Category"
                    itemTextKey={"name"}
                    apiUrls={apiUrls}
                    searchKey={"categories"}
                    searchOnClick={true}
                    value={awardsStore.editItem.category.value}
                    openOnClick={true}
                    keepSelected={true}
                    useEnterButtonOnSelect={true}
                    handleListSelection={(value) =>
                        awardsStore.handleEditItemFieldUpdate(
                            "category",
                            value,
                            [],
                        )
                    }
                />
            </div>
            <div className="form-item" style={{ marginTop: "1rem" }}>
                <BubbleList
                    label={"Contributor"}
                    id="award-contributors"
                    inputId="award-contributors"
                    background="#F0B6C7"
                    color="#861F3C"
                    deleteWhite={true}
                    readOnly={false}
                    emptyPlaceholder={"Enter Contributor"}
                    data={awardsStore.editItem.contributors.map((item) => ({
                        text: item.value,
                        scripts: item.scripts,
                    }))}
                    datalist={contributors.map((item) => ({
                        text: item.name,
                        scripts: item.scripts,
                    }))}
                    deleteObject={true}
                    handleFormChange={(value) => {
                        const valuesToModify = value.map(
                            (valueItem) => valueItem.text,
                        );
                        awardsStore.handleEditItemFieldUpdate(
                            "contributors",
                            valuesToModify,
                            [],
                        );
                    }}
                    datalistId="award-contributors-data-list"
                    filterStaticDataList={true}
                    openOnClick={true}
                />
            </div>
        </StyledForm>
    );
});
