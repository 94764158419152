export const Components = {
    button: "button",
    dialog: "dialog",
    progress: "progress",
    input: "input",
    select: "select",
    table: "table",
    text: "text",
    wrapper: "wrapper",
    link: "link",
    tooltip: "tooltip",
    accordion: "accordion",
    icon: "icon",
} as const;

export type ComponentsType = keyof typeof Components;
