import React from "react";
import { CoreFormStack } from "../../../components/layout/CoreForm.stack";
import Input from "../../interfaces/Input.interface";
import { TooltipInfo } from "../../../../Tooltip/TooltipInfo";
import { observer } from "mobx-react";
import { useStores } from '../../store/rootStore'
import { CoreSelect } from "../../../components/inputs/Select/components/CoreSelect";

export const MultipleSelectChips = observer(({ field }: Input) => {
    const { listsStore, formStore } = useStores()

    const Tooltip = () => {
        if (field.validation?.rangeLimit?.enabled) {
            return <TooltipInfo title={<div>Selection Limits <br />Minimum: {field.validation?.rangeLimit?.min} <br />Maximum: {field.validation?.rangeLimit?.max}</div>} />
        }

        return <></>
    }

    const options = listsStore.lists[field.type.select.listKey]?.options || []
    const disabled = field.validation?.readOnly?.enabled || formStore.disabled

    return (
        <CoreFormStack>
            <CoreSelect
                placeholder="Select Options"
                config={{
                    disabled,
                    path: field.key,
                    labelProps: {
                        text: field.label,
                        isRequired: field.validation?.required?.enabled,
                        tooltip: <Tooltip />,
                        customStyles: {
                            color: "rgba(47, 47, 47, 0.87) !important",
                        }
                    },
                    options: options,
                    showChipArray: true,
                    multiple: true,
                    showSelectAll: options.length < 10,
                    noneSelected: true,
                    enableSearchbox: true,
                    chipsLabel: 'text'
                }}
                wrapperStyles={{
                    "& .MuiInputBase-root": {
                        display: disabled && "none",
                    },
                    "& .MuiChip-deleteIcon": {
                        display: disabled && "none"
                    },
                }}
            />
        </CoreFormStack>
    );
});
