import React, { useRef } from "react";
import deepcopy from "deepcopy";
import AddNewRowButton from "../../../../../AddNewRowButton";
import { useStore } from "../../../state/context";
import setNotification from "../../../state/actions/notification";
import AssetCreateForm from "./createForm";
import { confirmAssetCreate } from "../../../state/actions/assetCreate";
import { featureUserRoleManagement } from "../../../../../../services/PermissionService";

const AssetCreateButton: React.FC = () => {
    const [store, dispatch] = useStore();

    const {
        disabled,
        assets,
        newAsset = {},
        notification = {},
        cmsData = {},
        permissionCreate
    } = store;

    const notificationRef = useRef();
    notificationRef.current = notification;

    const newAssetRef = useRef();
    newAssetRef.current = newAsset;

    let canCreateNew: boolean = store.canCreateNew || true

    if (featureUserRoleManagement({ cmsData })) {
        canCreateNew = permissionCreate
    }

    const ConfirmAssetCreate = (): void => {
        if (!newAssetRef.current.destination_platform) {
            let notificationObject = deepcopy(notificationRef.current);
            notificationObject.errorMessage =
                "Destination Platform is required field!";
            dispatch(setNotification(notificationObject));
            return;
        }

        if (!newAssetRef.current.type) {
            let notificationObject = deepcopy(notificationRef.current);
            notificationObject.errorMessage = "Type is required field!";
            dispatch(setNotification(notificationObject));
            return;
        }

        dispatch(confirmAssetCreate());
        dispatch(setNotification({}));
    };

    return (
        <div className="asset-create-button">
            {!disabled && (
                <AddNewRowButton
                    disabled={!canCreateNew || disabled}
                    data={assets}
                    onClick={() => {
                        dispatch(
                            setNotification({
                                html: <AssetCreateForm isRoot={true} />,
                                title: "Create Asset",
                                confirm: () => ConfirmAssetCreate(),
                            }),
                        );
                    }}
                    className={"asset-add-new-button"}
                    styles={{
                        background: "#f2f2f1",
                        marginTop: "1rem",
                        borderRadius: "0.5rem",
                    }}
                />
            )}
        </div>
    );
};

export default AssetCreateButton;
