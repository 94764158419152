/* eslint-disable class-methods-use-this */
import { isEmpty } from "lodash";
import { action, extendObservable, makeObservable, observable } from "mobx";
import { ZodSchema } from "zod";
import { requiredFieldsVS } from "../config/clients/viacom/validation/allFieldsVS";
import { PrimaryVT } from "../config/types";
import { KeyValueType } from "../types/interfaces";
import { initialState, RootStore } from "./rootStore";
import TaskManagerStoreInterface from "./TaskManagerStoreInterface";

export class TaskManagerValidatorStore implements TaskManagerStoreInterface {
    rootStore: RootStore;
    mandatoryFieldsValidationSchema: ZodSchema<Required<PrimaryVT>>;
    invalidFields: (keyof PrimaryVT)[] = [];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.mandatoryFieldsValidationSchema = requiredFieldsVS;
        this.invalidFields = [];

        makeObservable(this, {
            validateMandatoryFields: action,
            getErrorPaths: action,
            initialize: action,
            invalidFields: observable,
        });

        extendObservable(this, initialState);
    }

    async initialize(...args: any[]) {
        if (this.rootStore.storesInit["validator"]) {
            throw new Error(
                `Task Manager validator Store has already been initialized.`,
            );
        }

        this.rootStore.finishLoading("validator");
    }

    getErrorPaths(error) {
        return (
            error?.issues
                .map((error) => error.path)
                .concat()
                .flat() || []
        );
    }

    addInvalidField(field: keyof PrimaryVT) {
        if (!this.invalidFields.includes(field)) {
            this.invalidFields.push(field);
        }
    }

    validateMandatoryFields(data: PrimaryVT) {
        try {
            this.mandatoryFieldsValidationSchema?.parse(data);
            return [];
        } catch (error) {
            const errorPaths = this.getErrorPaths(error) || [];
            errorPaths.forEach((field) => {
                this.addInvalidField(field);
            });

            return errorPaths;
        }
    }

    async isKeyUnique({ key, value }: KeyValueType) {
        const data = await this.rootStore.recordServiceApi.searchByIdentifier({
            key,
            value,
        });

        return isEmpty(data);
    }

    async isS4MPMIdUnique({ value }: Pick<KeyValueType, "value">) {
        return this.isKeyUnique({ key: "s4_mpm_id", value });
    }
}
export default TaskManagerValidatorStore;
