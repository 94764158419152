import React from "react";
import "./styles/_styles.scss";
import RenderCountryTitle from "./src/RenderCountryTitle";
import RenderRemoveImage from "./src/RenderRemoveImage";
import RenderDate from "./src/RenderDate";
import RenderReleaseDateTypeTag from "./src/RenderReleaseDateTypeTag";
import Clients from "../Clients";
import _ from "lodash";
import AuditTooltip from "../AuditTooltip";
import MaterialTable from "../Table/Material";

const ReleaseDates = (props) => {
    const {
        data = [],
        options = {},
        disabled,
        handleDelete,
        clientFeatures = {},
        audits = [],
        cmsData,
        canDelete
    } = props;

    const tableHeaders = ["Country", "Date", "Type"];

    if (Array.isArray(data)) {
        const tableBody = data
            .filter((item) => !item.deleted)
            .map((item, index) => {
                let dateFormat = "Do MMMM YYYY";
                if (
                    Clients.isClientEpix() &&
                    item.date &&
                    !_.isEmpty(item.date)
                )
                    dateFormat = "M/D/YYYY";

                const row = [
                    RenderCountryTitle({
                        item,
                        options,
                        clientFeatures,
                        cmsData,
                    }),

                    RenderDate({
                        item,
                        format: dateFormat,
                    }),

                    RenderReleaseDateTypeTag({
                        item,
                    }),
                ];

                if (!disabled) {
                    row.push(
                        RenderRemoveImage({
                            disabled: !canDelete || disabled,
                            handleDelete: handleDelete.bind(this, index),
                            icon: "trash.svg",
                        }),
                    );
                }

                return row;
            });

        return (
            <MaterialTable
                headers={disabled ? tableHeaders : [...tableHeaders, ""]}
                body={tableBody}
                placeholder="None Added"
            />
        );
    }

    return false;
};

export default ReleaseDates;
