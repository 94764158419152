import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import BoilerplateView from "./views/BoilerplateView";
import ErrorHandler from "../../components/ErrorHandler";
import Notification from "../../components/Notification";
import Attribute from "../../components/Attribute";
import RedirectLoggedOutUser from "../../features/User/RedirectLoggedOutUser";
import $ from "jquery";
import AuthService from "../../services/AuthService";
import messages from "../../config/messages"

export default class AbstractBoilerplate extends React.Component {
    constructor(props) {
        super(props);

        /**
         * @var object
         */
        this.state = {
            notification: {},
        };

        /**
         * @var object
         */
        this.attributes = {};

        /**
         * @var bool
         */
        this.safari = false;

        /**
         * @var string
         */
        this.element = "#nav";

        /**
         * @var int
         */
        this.transitionSpeed = 75;

        AuthService.init(this.element);
    }

    componentWillMount() {
        this.setAttributes();
        this.checkBrowser();
    }

    componentDidCatch(error, info) {
        ErrorHandler(error, info);
    }

    setAttributes() {
        this.attributes = {
            discovery: Attribute(this.element, "data-discovery-text") || false,
            account: Attribute(this.element, "data-account-text") || false,
            platform: Attribute(this.element, "data-platform-text") || false,
            api: Attribute(this.element, "data-api-text") || false,
            logout: Attribute(this.element, "data-logout-text") || false,
            pendingUser: Attribute(this.element, "data-pending-user") || false,
            userIsSuperAdmin:
                Attribute(this.element, "data-user-is-super-admin") || false,
            userIsAdmin: Attribute(this.element, "data-user-is-admin") || false,
            displayApiGateway:
                Attribute("#feature", "data-feature-api-gateway") || false,
            progressGetUrl:
                Attribute("#discovery", "data-progress-get-url") || false,
        };
    }

    handleMouseEnterIcon(name, strokeName, overflow = false) {
        if (this.props.active !== name && name) {
            $(`.${name}-container .${name}`).css(
                { fill: "white" },
                this.transitionSpeed,
            );
            $(`.${name}-container .${name}-svg`).css(
                { transform: "scale(1.1)" },
                this.transitionSpeed,
            );
            $(`.${name}-container .${name}-second-icon`).css(
                { visibility: "visible" },
                this.transitionSpeed,
            );

            if (overflow) {
                $(`.${name}-container`).css(
                    { width: "max-content", paddingRight: "0.5rem" },
                    this.transitionSpeed,
                );
                $(`.${name}-container .icon-text`).html(overflow.full);
            }

            if (strokeName) {
                $(`.${strokeName}`).css(
                    { stroke: "white" },
                    this.transitionSpeed,
                );
            }
        }
    }

    handleMouseLeaveIcon(name, strokeName, overflow = false) {
        if (this.props.active !== name && name) {
            $(`.${name}-container .${name}`).css(
                { fill: "#545454" },
                this.transitionSpeed,
            );
            $(`.${name}-container .${name}-svg`).css(
                { transform: "scale(1)" },
                this.transitionSpeed,
            );
            $(`.${name}-container .${name}-second-icon`).css(
                { visibility: "hidden" },
                this.transitionSpeed,
            );

            if (overflow) {
                $(`.${name}-container`).css(
                    { width: "auto", paddingRight: "unset" },
                    this.transitionSpeed,
                );
                $(`.${name}-container .icon-text`).html(overflow.short);
            }

            if (strokeName) {
                $(`.${strokeName}`).css(
                    { stroke: "#545454" },
                    this.transitionSpeed,
                );
            }
        }
    }

    setActiveColor(name, strokeName) {
        $(`.${name}-placeholder`).fadeOut(10, function () {
            $(`.${name}-container .${name}-svg`).fadeIn();
        });

        if (this.props.active == name) {
            $(`.${name}-container .${name}`).css({ fill: "white" });

            if (strokeName) {
                $(`.${strokeName}`).css({ stroke: "white" });
            }
        }
    }

    setNotification(notification) {
        if (!_.isEqual(this.state.notification, notification)) {
            this.setState({
                notification,
            });
        }
    }

    handleMenuClick(href = "", e) {
        if (href == "/logout") {
            e.preventDefault();

            return this.setNotification({
                title: this.attributes.logout || "Logout",
                description:
                    this.attributes.logoutDescription ||
                    "Are you sure you want to logout?",
                intercationStatus: "alert",
                okText: this.attributes.yes || "Yes",
                confirm: () => {
                    window.location = href;
                },
            });
        }

        if (this.props.unsavedChanges) {
            e.preventDefault();

            if (href == "") {
                this.setNotification({
                    title: this.attributes.cancelTitle || "Unsaved Changes",
                    description:
                        this.attributes.cancelDescription ||
                        messages.recordUnsavedChanges,
                    intercationStatus: "error",
                    okText: this.attributes.cancelOkText || "Leave this Page",
                    confirm: () => {
                        this.setNotification({});
                    },
                });
            }
        }

        if (href !== "") {
            window.location = href;
        }
    }

    handleNotificationClose() {
        this.setNotification({});
    }

    handleMessageResponse(description) {
        this.setNotification({
            title: this.attributes.backgroundProcess || "Background Process",
            description,
            intercationStatus: "alert",
            okText: this.attributes.ok || "Ok",
            confirm: () => {
                this.setNotification({});
            },
        });
    }

    checkBrowser() {
        if (navigator !== undefined) {
            if (navigator.userAgent) {
                if (
                    navigator.userAgent.indexOf("Safari") != -1 &&
                    navigator.userAgent.indexOf("Chrome") == -1
                ) {
                    this.safari = true;
                }
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <BoilerplateView
                    {...this.props}
                    discoveryText={this.attributes.discovery}
                    accountText={this.attributes.account}
                    platformText={this.attributes.platform}
                    apiText={this.attributes.api}
                    logoutText={this.attributes.logout}
                    state={this.state}
                    safari={this.safari}
                    userIsSuperAdmin={this.attributes.userIsSuperAdmin}
                    userIsAdmin={this.attributes.userIsAdmin}
                    pendingUser={this.attributes.pendingUser}
                    displayApiGateway={this.attributes.displayApiGateway}
                    progressGetUrl={this.attributes.progressGetUrl}
                    setActiveColor={this.setActiveColor.bind(this)}
                    handleMouseEnterIcon={this.handleMouseEnterIcon.bind(this)}
                    handleMouseLeaveIcon={this.handleMouseLeaveIcon.bind(this)}
                    handleMenuClick={this.handleMenuClick.bind(this)}
                    handleMessageResponse={this.handleMessageResponse.bind(
                        this,
                    )}
                />

                <Notification
                    title={this.state.notification.title || false}
                    description={this.state.notification.description || false}
                    html={this.state.notification.html || false}
                    confirm={this.state.notification.confirm || false}
                    disabled={this.state.notification.disabled || false}
                    intercationStatus={
                        this.state.notification.intercationStatus || "alert"
                    }
                    onClick={this.handleNotificationClose.bind(this)}
                    okText={this.state.notification.okText || undefined}
                />

                <RedirectLoggedOutUser />
            </React.Fragment>
        );
    }
}

AbstractBoilerplate.propTypes = {
    active: PropTypes.string,
};
