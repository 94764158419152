import { linkQueryParams } from "../linkQueryParams"
import { LinkQueryParamInterface } from "../interfaces"

export default function applyLinkQueryParams(url: string): string {
    const linkQueryItem: LinkQueryParamInterface | null = linkQueryParams.find(it => url.includes(it.domain))

    if (linkQueryItem) {
        const urlTransformed: string = url.replace(/\/+$/, '')

        return linkQueryItem.queryParam
            .replace('{url}', urlTransformed)
    }

    return url
}