import React from "react";
import Component from "./index";

const SimpleSlimSelect = (props) => {
    const { selectMargin = 0, wrapperMargin = 0, disabled } = props;

    return (
        <Component
            {...props}
            clear={true}
            wrapperStyles={{
                width: "auto",
                minWidth: "7rem",
                margin: wrapperMargin,
            }}
            selectStyles={{
                margin: selectMargin,
                padding: "0.28125rem 0.28125rem 0.28125rem 0.7rem",
                fontSize: "0.78125rem",
                letterSpacing: "-0.01875rem",
                textTransform: "uppercase",
                lineHeight: 1,
                display: "flex",
                alignItems: "center",
                opacity: disabled ? 0.5 : 1,
            }}
        />
    );
};

export default SimpleSlimSelect;
