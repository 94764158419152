import React, { useState } from "react";
import "./styles/_styles.scss";
import _ from "lodash";

const ExpandableParagraph = (props) => {
    const { firstParagraph, moreParagraphs = [], styles = {} } = props;

    if (!firstParagraph) {
        return false;
    }

    const [expanded, setExpanded] = useState(false);

    return (
        <div style={styles} className="meta-expandable-paragraph">
            <p>{firstParagraph}</p>

            {expanded &&
                moreParagraphs.map((item, index) => {
                    return <p key={index}>{item}</p>;
                })}

            {!_.isEmpty(moreParagraphs) && (
                <p
                    style={{
                        cursor: "pointer",
                    }}
                    onClick={() => setExpanded(!expanded)}
                >
                    {" "}
                    <b>{!expanded ? "show more ..." : "show less"}</b>
                </p>
            )}
        </div>
    );
};

export default ExpandableParagraph;
