import moment from "moment";

export default function ActiveLicenceCheck(start, end) {
    let result = false;

    const momentDate = moment();
    const momentNow = momentDate.unix();
    const license_start = moment(start).unix();
    const license_end = moment(end).unix();

    if (
        Number.isInteger(momentNow) &&
        Number.isInteger(license_start) &&
        Number.isInteger(license_end) &&
        momentDate._isValid
    ) {
        if (momentNow >= license_start && momentNow <= license_end) {
            result = true;
        }
    }

    return result;
}

export function ActiveStartDateCheck(start) {
    let result = false;

    const momentDate = moment();
    const momentNow = momentDate.unix();
    const license_start = moment(start).unix();

    if (
        Number.isInteger(momentNow) &&
        Number.isInteger(license_start) &&
        momentDate._isValid
    ) {
        if (momentNow >= license_start) {
            result = true;
        }
    }

    return result;
}