import React from "react";
import { SectionInterface } from "./interface";
import AlternativeNumberingBuilder from "../../views/NumberingBuilder";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";
import { NUMBERING } from "../../../../models/RecordBuilder";
import IsActiveRecordBuilder from "../../../RecordBuilder/IsActive";

const SectionAlternativeNumbering = (props: SectionInterface) => {
    const { data = {}, cmsData = {} } = props;

    const { clientFeatures = {} } = cmsData;

    if (!IsActiveRecordBuilder({ page: NUMBERING, clientFeatures})) {
        return <></>;
    }

    return (
        <AlternativeNumberingBuilder
            {...getSectionCommonProps({ ...props, field: "numbering" })}
            category={data.category}
            data={data.numbering || {}}
        />
    );
};

export default SectionAlternativeNumbering;
