import RegionalAgeGuidanceRowInterface from "../../interfaces/RegionalAgeGuidanceRow.interface";
import _ from "lodash";

type ListItems = Array<{ text: string; value: number | string; label: string }>;

const TransformTerritoriesList = (
    list: Array<{ text: string; value?: number | string }>,
    ratings: RegionalAgeGuidanceRowInterface[],
    editRating?: RegionalAgeGuidanceRowInterface,
): ListItems => {
    const result: ListItems = list
        .filter(
            (item) =>
                !ratings.find((rating) => {
                    let territories = []
                    territories = territories.concat(rating.territories)

                    if (
                        editRating
                        && rating.maximum_age == editRating.maximum_age
                        && rating.minimum_age == editRating.minimum_age
                    ) {
                        territories = !_.isEmpty(editRating.territories) 
                            ? territories.concat(editRating.territories)
                            : []
                    }

                    return (
                        !rating.deleted &&
                        territories.includes(item.text)
                    );
                }),
        ).map((item) => {
            item.value = item.text;
            return item;
        })

    return result;
};

export default TransformTerritoriesList;
