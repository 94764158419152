import React from "react";
import _ from "lodash";
import Input from "../Input/basic";
import styles from "./styles/recordTimeInput";
import moment from "moment";
import Label from "../Input/label";
import { getDurationObject } from "../../models/Record/origination";

export default class RecordTimeInput extends React.Component {
    constructor(props) {
        super(props);

        /**
         * @var object
         */
        this.state = {
            value: "",
            hours: 0,
            minutes: 0,
            seconds: 0,
        };
    }

    componentDidMount() {
        this.setValuesFromProps();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.state, prevState)) {
            this.setValueFromProps();

            if (typeof this.props.handleFormChange == "function") {
                const value = this.getSecondsFromState();

                if (this.props.value !== value) {
                    this.props.handleFormChange(value);
                }
            }
        }

        if (this.props.value !== prevProps.value) {
            this.setValuesFromProps();
        }
    }

    setStateString(key, value) {
        if (this.state[key] !== value) {
            this.setState({
                [key]: value,
            });
        }
    }

    setValueFromProps() {
        if (!this.state.seconds && !this.state.minutes && !this.state.hours) {
            this.setStateString("value", "");
        } else {
            const seconds = this.formatTime(this.state.seconds);
            const minutes = this.formatTime(this.state.minutes);

            this.setStateString(
                "value",
                `${this.state.hours}:${minutes}:${seconds}`,
            );
        }
    }

    setValuesFromProps() {
        try {
            const durationObject = getDurationObject(this.props.value);
            this.setState(durationObject);
        } catch (e) {
            console.log(e);
        }
    }

    getSecondsFromState() {
        let result = null;
        let totalSeconds = 0;

        const hours = 3600 * this.state.hours;
        const minutes = 60 * this.state.minutes;
        const seconds = this.state.seconds;

        totalSeconds = Number(hours) + Number(minutes) + Number(seconds);

        if (totalSeconds > 0) {
            result = totalSeconds;
        }

        return result;
    }

    formatTime(time) {
        return ("0" + time).slice(-2);
    }

    render() {
        const copy = this.props.copy || {};

        return (
            <div>
                {this.props.label && (
                    <Label label={this.props.label} {...this.props} />
                )}

                {!this.props.disabled && (
                    <div style={styles().container}>
                        <Input
                            placeholder={copy.hours || "Hours"}
                            id="hours"
                            type="number"
                            value={this.state.hours}
                            name="approximate_release_duration_hours"
                            disabled={this.props.disabled}
                            margin="0.71875rem 1.46875rem 0 0"
                            width="5.125rem"
                            min={0}
                            max={99}
                            recordBasic={true}
                            error={this.props.error}
                            handleChange={(hours) =>
                                this.setStateString("hours", hours)
                            }
                        />

                        <Input
                            placeholder={copy.minutes || "Minutes"}
                            id="minutes"
                            type="number"
                            value={this.state.minutes}
                            name="approximate_release_duration_minutes"
                            disabled={this.props.disabled}
                            margin="0.71875rem 1.46875rem 0 0"
                            width="5.125rem"
                            min={0}
                            max={59}
                            recordBasic={true}
                            error={this.props.error}
                            handleChange={(minutes) =>
                                this.setStateString("minutes", minutes)
                            }
                        />

                        <Input
                            placeholder={copy.seconds || "Seconds"}
                            id="seconds"
                            type="number"
                            value={this.state.seconds}
                            name="approximate_release_duration_seconds"
                            disabled={this.props.disabled}
                            margin="0.71875rem 0 0"
                            width="5.125rem"
                            min={0}
                            max={59}
                            recordBasic={true}
                            error={this.props.error}
                            handleChange={(seconds) =>
                                this.setStateString("seconds", seconds)
                            }
                        />
                    </div>
                )}

                {this.props.disabled && (
                    <Input
                        placeholder={copy.noneEntered || "None Entered"}
                        className="meta-record-duration"
                        value={this.state.value}
                        id={this.props.disabled_id}
                        disabled={true}
                        margin="0"
                        width="100%"
                        recordBasic={true}
                    />
                )}
            </div>
        );
    }
}
