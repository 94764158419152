import { TableCreditRow } from "../types/CastCrew.type";

export const updateRowPosition = (
    oldIndex: number,
    targetIndex: number,
    rowsArray: TableCreditRow[],
) => {
    const rowsClone = [...rowsArray];
    const row = rowsClone.splice(oldIndex, 1)[0];
    rowsClone.splice(targetIndex, 0, row);
    return rowsClone;
};
