/* eslint-disable camelcase */
import React, { Component, Fragment } from "react";
import _ from "lodash";
import styles from "./styles";
import RecordImage from "../RecordImage";
import hasNoPreviewImage from "../RecordImage/utils/hasNoPreviewImage"
import Tag from "../Tag";
import Button from "../Button";
import StarComment from "../StarComment";
import TitleValue from "../TitleValue";
import Settings from "../Settings";
import RightTransparentLeftColor from "../DoubleTags/RightTransparentLeftColor";
import { bytesToSize } from "./imageUtils";
import DownloadImageButton from "./DownloadImageButton";
import ExpandImageButton from "./ExpandImageButton";
import DownloadButtonOnHover from "./DownloadButtonOnHover";
import "./styles/_styles.scss"
import ImagePreviewModal from "./views/ImagePreviewModal"
import getImage from "./utils/getImage"

export default class RecordImageContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hover: false,
            imageModalOpened: false,
        };

        this.componentIsMounted = false;
    }

    UNSAFE_componentWillMount() {
        this.componentIsMounted = false;
    }

    componentDidMount() {
        this.componentIsMounted = true;
    }

    setHover(value) {
        const { hover } = this.state;
        if (value !== hover && this.componentIsMounted) {
            this.setState({
                hover: value,
            });
        }
    }

    renderSourceTag(props) {
        const { data = {}, margin } = props;

        const { source, api_name } = data;

        if (source === "open" || !source) {
            return (
                <>
                    {this.renderTag({
                        className: "meta-tag-source",
                        text: "Open",
                        disabled: true,
                        background: "#6a6a6a",
                        styles: { margin },
                    })}
                </>
            );
        }

        let text = source;
        if (api_name) text = api_name;

        return (
            <RightTransparentLeftColor
                leftText={text}
                rightText="API"
                color="#6a6a6a"
                leftColor="white"
                styles={{ margin }}
            />
        );
    }

    renderTag(props) {
        return (
            <Tag
                className={props.className}
                text={props.text}
                key={props.index}
                background={props.background}
                styles={props.styles}
                fontSize="0.78125rem"
                active={props.true}
                disabled={props.disabled}
            />
        );
    }

    renderInlineTags(props) {
        const {
            classStyles,
            taken,
            leftOver,
            className,
            background,
            leftOverBackground,
            marginTop,
            margin,
            label,
        } = props;

        if (!_.isEmpty(taken)) {
            return (
                <>
                    {label && (
                        <span
                            style={{
                                ...Settings.components.label,
                                margin: `${marginTop} 0 0.5625rem 0`,
                            }}
                        >
                            {label}
                        </span>
                    )}

                    <div style={classStyles.inlineTags}>
                        {taken && (
                            <>
                                {taken.map((item) => (
                                    <Fragment key={item}>
                                        {this.renderTag({
                                            className,
                                            text: item,
                                            background,
                                            styles: {
                                                margin,
                                            },
                                        })}
                                    </Fragment>
                                ))}
                            </>
                        )}

                        {leftOver > 0 &&
                            this.renderTag({
                                className,
                                text: `+ ${leftOver}`,
                                background: leftOverBackground,
                                styles: { margin },
                            })}
                    </div>
                </>
            );
        }

        return false;
    }

    render() {
        const marginTop = "0.90625rem";
        const classStyles = styles();

        const {
            data = {},
            copy = {},
            options = {},
            disabled,
            name,
            handleEdit,
            handleDelete,
            cmsData = {},
            userPermissions = {}
        } = this.props;

        const {
            clientFeatures = {}
        } = cmsData

        const isFeatureEnabled = Boolean(clientFeatures?.previewDownloadImages);

        const { hover, imageModalOpened } = this.state;

        // eslint-disable-next-line camelcase
        const { image_types = [] } = options;

        const { countries = [], contributors = [], tags = [] } = data;

        const margin = "0 0.3125rem 0.3125rem 0";
        const imageType =
            _.find(image_types, (t) => t.value === data.image_type_id) || {};

        const takenCountries = countries
            .filter((item) => !item.deleted)
            .slice(0, 5)
            .map((item) => item.value);
        const leftOverCountries =
            countries.filter((item) => !item.deleted).length - 5;

        const takenContributors = contributors
            .filter((item) => !item.deleted)
            .slice(0, 4)
            .map((item) => item.text);
        const leftOverContributors =
            countries.filter((item) => !item.deleted).length - 4;

        const takenTags = tags
            .filter((item) => !item.deleted)
            .slice(0, 4)
            .map((item) => item.text);
        const leftOverTags = tags.filter((item) => !item.deleted).length - 4;

        const image = getImage(data, false, this.props.meta_id)
        const noPreviewImage = hasNoPreviewImage({ cmsData, image })

        return (
            <div
                onMouseEnter={() => this.setHover(true)}
                onMouseLeave={() => this.setHover(false)}
                className="fabric-record-image-container--hover"
                style={classStyles.container}
            >
                {(isFeatureEnabled && !noPreviewImage && disabled) &&
                    <ExpandImageButton
                        onClick={() =>
                            this.setState({ imageModalOpened: true })
                        }
                    />
                }
                
                <div
                    style={{
                        ...classStyles.contentContainer,
                        ...((!hover || disabled) && {
                            display: "none",
                        }),
                    }}
                >
                    <Button
                        value={copy.editImage || "Edit Image"}
                        imagesButtonPrimary
                        type="button"
                        margin="0"
                        disabled={!userPermissions.update}
                        className={`meta-edit-image-container ${!userPermissions.update ? "disabled" : ""}`}
                        onClick={() => handleEdit()}
                    />

                    {(isFeatureEnabled && image) &&
                        <DownloadButtonOnHover
                            href={getImage(data, false, this.props.meta_id)}
                            text={copy.downloadImage || "Download Image"}
                            fileName={data.original_filename}
                        />
                    }

                    <Button
                        value={copy.deleteImage || "Delete Image"}
                        imagesButtonDelete
                        type="button"
                        margin="0.5rem 0 0 0"
                        className={`meta-delete-image-container ${!userPermissions.delete ? "disabled" : ""}`}
                        disabled={!userPermissions.delete}
                        onClick={() => handleDelete()}
                    />
                </div>

                <div style={classStyles.content}>
                    <RecordImage
                        icon={getImage(data, true, this.props.meta_id)}
                        image={image}
                        title={name}
                        poster={data.poster || false}
                        onClick={
                            isFeatureEnabled
                                ? () => {
                                      this.setState({ imageModalOpened: true });
                                  }
                                : undefined
                        }
                        cmsData={cmsData}
                    />
                </div>

                <div
                    style={{
                        marginTop,
                        ...classStyles.content,
                        ...classStyles.contentTags,
                        ...classStyles.contentPadding,
                    }}
                    className="meta-record-image-tags"
                >
                    {data.extension &&
                        this.renderTag({
                            className: "meta-tag-extension",
                            text: data.extension,
                            active: true,
                            disabled: true,
                            styles: { margin },
                        })}

                    {this.renderSourceTag({
                        data,
                        margin,
                    })}

                    {data.locale &&
                        this.renderTag({
                            className: "meta-tag-language",
                            text: data.locale,
                            disabled: true,
                            background: "#6a6a6a",
                            styles: { margin },
                        })}

                    {isFeatureEnabled && disabled && (
                        <DownloadImageButton href={getImage(data, false, this.props.meta_id)} fileName={data.original_filename}/>
                    )}
                </div>

                <div
                    style={{
                        ...classStyles.content,
                        ...classStyles.contentPadding,
                    }}
                >
                    {this.renderInlineTags({
                        classStyles,
                        taken: takenCountries,
                        leftOver: leftOverCountries,
                        className: "meta-tag-country",
                        background: "#2c9f7a",
                        leftOverBackground: "#90cab7",
                        margin,
                    })}

                    <StarComment
                        text={data.comment}
                        displayNoComments
                        noCommentsText={copy.noComments}
                        styles={{ marginTop: "0.5rem", width: "100%" }}
                        disabled
                        noBorder
                        inputPadding="0"
                    />

                    {this.renderInlineTags({
                        label: "Contributors",
                        classStyles,
                        taken: takenContributors,
                        leftOver: leftOverContributors,
                        className: "meta-tag-contributor",
                        background: "#b77e8d",
                        leftOverBackground: "#e2abba",
                        margin,
                        marginTop,
                    })}

                    {this.renderInlineTags({
                        label: "Tags",
                        classStyles,
                        taken: takenTags,
                        leftOver: leftOverTags,
                        className: "meta-tag-tag",
                        background: "#957bc9",
                        leftOverBackground: "#bfabe6",
                        margin,
                        marginTop,
                    })}

                    <TitleValue
                        title="Image Name"
                        value={data.name || "-"}
                        style={{ marginTop }}
                        className="image-name"
                    />

                    <TitleValue
                        title="Image Type"
                        value={imageType.text || "-"}
                        style={{ marginTop }}
                        className="image-type"
                    />

                    <TitleValue
                        title="Fabric ID"
                        value={data.meta_id || "-"}
                        style={{ marginTop }}
                        className="image-meta-id"
                    />

                    {data.iconic_image_asset_id && (
                        <TitleValue
                            title="Iconik Image Asset ID"
                            value={data.iconic_image_asset_id}
                            style={{ marginTop }}
                            className="iconic_id"
                        />
                    )}

                    <TitleValue
                        title="Horizontal Resolution"
                        value={
                            data.horizontal_resolution
                                ? `${data.horizontal_resolution} ${
                                      copy.pixels || "Pixels"
                                  }`
                                : "-"
                        }
                        style={{ marginTop }}
                        className="image-horizontal-resolution"
                    />

                    <TitleValue
                        title="Vertical Resolution"
                        value={
                            data.vertical_resolution
                                ? `${data.vertical_resolution} ${
                                      copy.pixels || "Pixels"
                                  }`
                                : "-"
                        }
                        style={{ marginTop }}
                        className="image-vertical-resolution"
                    />

                    <TitleValue
                        title="Filesize"
                        value={data.filesize ? bytesToSize(data.filesize) : "-"}
                        style={{ marginTop }}
                        className="image-filesize"
                    />
                </div>

                {isFeatureEnabled && imageModalOpened && (
                    <ImagePreviewModal 
                        open={imageModalOpened}
                        image={getImage(data, false, this.props.meta_id)}
                        handleClose={() => this.setState({ imageModalOpened: false })}
                    />
                )}
            </div>
        );
    }
}
