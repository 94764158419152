import React, { useState, useEffect, useRef } from "react";
import "./styles/_stylesV2.scss";
import Settings from "../Settings";
import $ from "jquery";
import { v4 as uuidv4 } from "uuid";
import { ActionIconDoubleArrow, ActionIconSingleArrow } from "../ActionIcon";

const ExpandableRowV2 = (props) => {
    const {
        header,
        body,
        className,
        id,
        bodyStyles = {},
        styles = {},
        noHeaderBackground,
        borderRadius,
        defaultOpen = false,
        forceOpen = false,
        arrowIconType = "small", //small, large,
        bodyOpen = () => {},
        doubleArrows,
    } = props;

    const [open, setOpen] = useState(defaultOpen);
    const [allOpen, setAllOpen] = useState(false);
    const [iconUuid, setIconUuid] = useState<string>(uuidv4());
    const expandableElement = useRef(null);

    const handleOnClick = (e) => {
        e.stopPropagation();
        animate($(e.currentTarget));
        bodyOpen();
    };

    const animate = (element) => {
        if (element.hasClass("double-arrows")) {
            if (!allOpen) {
                $(".meta-expandable-row--body").slideDown(200);
                $(`.meta-expandable-row--icon`).css(
                    "transform",
                    "rotate(90deg)",
                );
                $(`.double-arrow--icon`).css("transform", "rotate(0deg)");

                setAllOpen(true);
            } else {
                $(".meta-expandable-row--body").slideUp(200);
                $(`.meta-expandable-row--icon`).css(
                    "transform",
                    "rotate(0deg)",
                );
                $(`.double-arrow--icon`).css("transform", "rotate(180deg)");

                setAllOpen(false);
            }
        } else {
            // slide body down
            const body = element.children()[1];
            setOpen(!open);

            $(body).slideToggle(200);
            $(`.meta-expandable-row--icon-${iconUuid}`).css(
                "transform",
                !open ? "rotate(90deg)" : "rotate(0deg)",
            );
        }
    };

    let headerStyles = {
        ...props.headerStyles,
    };

    useEffect(() => {
        if (forceOpen) {
            animate($(expandableElement.current));
            setOpen(true);
            bodyOpen();
        }
        if (!forceOpen && open) {
            animate($(expandableElement.current));
            setOpen(false);
        }
    }, [forceOpen]);

    if (borderRadius) {
        headerStyles = {
            ...props.headerStyles,
        };
    }

    return (
        <div className="meta-expandable-row-v2">
            <div
                className={`meta-expandable-row ${className} ${
                    defaultOpen ? "default-open" : ""
                } ${open ? "open" : ""} ${
                    noHeaderBackground ? "no-background-selector" : ""
                } ${borderRadius ? "border-radius" : ""}`}
                id={id}
                style={styles}
                onClick={handleOnClick.bind(this)}
                ref={expandableElement}
            >
                <div
                    className={
                        "meta-expandable-row--header " +
                        (props.headerClassName || "")
                    }
                    style={headerStyles}
                >
                    <div className="meta-expandable-row--header--content">
                        {header}
                    </div>

                    <div className="expandable-row-icon-wrapper">
                        {doubleArrows && (
                            <ActionIconDoubleArrow
                                handleOnClick={handleOnClick.bind(this)}
                                className={`double-arrow--icon-${iconUuid}`}
                            />
                        )}

                        <ActionIconSingleArrow
                            className={`meta-expandable-row--icon-${iconUuid}`}
                            variant={arrowIconType}
                        />
                    </div>
                </div>

                <div
                    className="meta-expandable-row--body"
                    style={bodyStyles}
                    onClick={(e) => e.stopPropagation()}
                >
                    {body}
                </div>
            </div>
        </div>
    );
};

export default ExpandableRowV2;
