import * as React from "react";
import "../styles/_autocomplete-one-line-input.scss";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import { ListItem } from "./ContentField.interface";

type Props = {
    id: string;
    dataList: Array<ListItem>;
    value: Array<ListItem> | ListItem;
    placeholder?: string;
    label?: string;
    clientManagedList?: string;
    multiple?: boolean;
    disabled: boolean;
    limitTags?: number;
    handleChange: (value: ListItem | Array<ListItem>) => {};
};

const AutocompleteMutipleOneLineInput: React.FC<Props> = (
    props,
): JSX.Element => {
    const {
        dataList = [],
        placeholder,
        multiple: multipleField = false,
        value: data = [],
        disabled = true,
        handleChange,
        limitTags = 2,
    } = props;

    const HandleOnChange = (editData: Array<ListItem>): void => {
        if (multipleField || editData.length == 0) {
            handleChange(editData);
            return;
        }

        handleChange(editData[editData.length - 1]);
    };

    const TransformDataToListItem = (list: any[]) => {
        return list.map((item) => {
            if (typeof item == "string") {
                item = {
                    text: item,
                    value: item,
                };
            }

            if (typeof item === "object" && item.value && !item.text) {
                item.text = item.name || item.value;
            }

            if (
                typeof item === "object" &&
                !item.value &&
                (item.text || item.name)
            ) {
                item.value = item.name || item.text;
            }

            return item;
        });
    };

    const GetValue = (): Array<ListItem> => {
        if (_.isEmpty(data)) {
            return [];
        }

        if (Array.isArray(data)) {
            return TransformDataToListItem(data);
        }

        return TransformDataToListItem([data]);
    };

    return (
        <div className="autocomplete-one-line-input">
            <Autocomplete
                autoComplete
                multiple
                readOnly={disabled}
                id="size-small-outlined-multi"
                size="small"
                forcePopupIcon={!disabled}
                options={dataList}
                disableClearable
                disableCloseOnSelect={multipleField}
                filterSelectedOptions={true}
                filterOptions={(options, state) => {
                    return options.filter((option: ListItem) => {
                        return (
                            !GetValue().find(
                                (item) => item.text == option.text,
                            ) && option.text.includes(state.inputValue || "")
                        );
                    });
                }}
                getOptionLabel={(option) => option.text}
                value={GetValue()}
                disablePortal={true}
                onChange={(event, value) => {
                    HandleOnChange(value);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={disabled ? "" : placeholder}
                    />
                )}
                classes={{
                    inputRoot: "input-wrapper",
                    tag: "value-tag",
                    option: "option-wrapper",
                    input: "autocomplete-input",
                }}
                limitTags={limitTags}
            />
        </div>
    );
};

export default AutocompleteMutipleOneLineInput;
