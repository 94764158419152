import React from "react";
import _ from "lodash";

type HeaderTagType = {
    text: string;
    secondText?: string;
    type?: string;
};

const HeaderTag: React.FC = (props: HeaderTagType) => {
    const { text, type, secondText } = props;

    if (type == "double") {
        return (
            <div className="asset-header-tag">
                <div className="double-tag">
                    <div className="text-first tag">{text}</div>

                    {secondText &&
                        <div className="text-second tag">{secondText}</div>
                    }
                </div>
            </div>
        );
    }

    return (
        <div className="asset-header-tag">
            <div className="text">{text}</div>
        </div>
    );
};

export default HeaderTag;
