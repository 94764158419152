import React, { useEffect, Fragment, useState } from "react";
import { useStore } from "../state/context";
import { ASSET_CODES_CONFIG, ASSET_PLATFORMS_CONFIG, ASSET_TYPES_CONFIG } from "../configuration";
import setConfiguration from "../state/actions/configuration";
import "./styles/_styles.scss";
import PlatformContainer from "./PlatformContainer";
import {
    AssetPlatformConfigurations,
    AssetTypeConfigurations,
    AssetCodeConfigurations,
} from "../test/configurationPayload";
import Notification from "../../../Notification";
import setNotification from "../state/actions/notification";
import AssetCreateButton from "./asset/forms/createButton";
import _ from "lodash";
import PlaceholderText from "../../../PlaceholderText";
import FadeWithSkeleton from "../../../SkeletonLoader/FadeWithSkeleton";
import { envTest } from "../../../Environment";
import BuilderError from "../../../RecordBuilder/FetchError";

const MainAssetComponent: React.FC = () => {
    const [store, dispatch] = useStore();

    const {
        loadingBuilderConfig = false,
        notification = {},
        disabled = true,
        assets = [],
        assetPlatformsBuilderConfig = [],
        assetCodesBuilderConfig = [],
        assetTypesBuilderConfig = [],
        recordBuilderError = false,
    } = store;

    useEffect(() => {
        if (envTest) {
            dispatch(
                setConfiguration(
                    AssetPlatformConfigurations,
                    ASSET_PLATFORMS_CONFIG,
                ),
            );

            dispatch(setConfiguration(AssetCodeConfigurations, ASSET_CODES_CONFIG));
            dispatch(setConfiguration(AssetTypeConfigurations, ASSET_TYPES_CONFIG));
            return;
        }

        dispatch(setConfiguration(assetPlatformsBuilderConfig, ASSET_PLATFORMS_CONFIG));
        dispatch(setConfiguration(assetCodesBuilderConfig, ASSET_CODES_CONFIG));
        dispatch(setConfiguration(assetTypesBuilderConfig, ASSET_TYPES_CONFIG));
    }, []);

    return (
        <div className={"assets-builder-main-component"}>
            <FadeWithSkeleton
                loading={loadingBuilderConfig && !recordBuilderError}
            />
            <h2 className="section-title">Related Assets &amp; Placeholders</h2>

            {!recordBuilderError && (
                <>
                    {_.isEmpty(assets) && disabled && (
                        <div className="none-added-wrapper">
                            <PlaceholderText />
                        </div>
                    )}

                    <PlatformContainer />
                    <AssetCreateButton />
                </>
            )}

            {recordBuilderError && <BuilderError />}

            <Notification
                intercationStatus="alert"
                background="white"
                onClick={() => {
                    dispatch(setNotification({}));
                }}
                closeOnWrapperClick={true}
                {...notification}
            />
        </div>
    );
};

export default MainAssetComponent;
