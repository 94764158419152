/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import React from "react";
import { ThemeProvider } from "@emotion/react";
import { observer } from "mobx-react";
import { BackgroundProcessingTM } from "./BackgroundProcessingTM";
import { theme } from "./theme/background-processing.theme";
import { isWebSocketURLValid } from "./services/isWebSocketURLValid";

export enum BackgroundProcessingVariants {
    TASK_MANAGER = "TASK_MANAGER",
}

export type BackgroundProcessingMainProps = {
    variant: BackgroundProcessingVariants;
};

export const BackgroundProcessingMain = observer(
    ({ variant }: BackgroundProcessingMainProps) => {
        if (!isWebSocketURLValid()) {
            return null;
        }

        let component = null;
        switch (variant) {
            case BackgroundProcessingVariants.TASK_MANAGER: {
                component = <BackgroundProcessingTM />;
                break;
            }

            default: {
                return null;
            }
        }

        return <ThemeProvider theme={theme}>{component}</ThemeProvider>;
    },
);
