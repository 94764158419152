
import { v4 as uuidv4 } from 'uuid'

export interface ScriptInterface {
    code: string,
    value: string
}

export interface AwardsInitialPayloadInterface {
	award_body: {
		value: string;
		scripts: ScriptInterface[];
	};
	category: {
		value: string;
		scripts: ScriptInterface[];
	};
	contributors?: {
		value: string;
		scripts: ScriptInterface[];
	}[];
	contributor?: {
		value: string;
		scripts: ScriptInterface[];
	};
	order: number | boolean;
	result: {
		value: string;
		scripts: ScriptInterface[];
	};
	year: number;
	deleted?: boolean;
	source?: string | null;
}

export interface AwardInterface extends AwardsInitialPayloadInterface {
	local_id?: string;
};

export const transformAwardsPayload = (
    payload: AwardsInitialPayloadInterface[]
): AwardInterface[] => {
    return payload.map((awardPayload, index) => {
        return {
            id: index,
            local_id: uuidv4(),
            ...awardPayload
        };
    }) ?? [];
}
