import { SET_ORIGINAL_RECORD } from "../actions/action_types";
import { defaultOriginalRecord } from "../initialState";

export default function (
    state = { originalRecord: defaultOriginalRecord },
    action,
) {
    switch (action.type) {
        case SET_ORIGINAL_RECORD:
            return Object.assign({}, state, {
                originalRecord: action.originalRecord,
            });
    }

    return state;
}
