import React, { Component } from "react";
import styles from "./styles";
import "./styles/_styles.scss";
import Settings from "../Settings";
import ReactSVG from "react-svg";
import { envTest } from "../Environment";
import { DeleteIconDynamicWhite } from "../Icons/DeleteIcons"

export default class KeywordLabel extends Component {
    /**
     * KeywordLabel
     *
     * @param object props
     * @return false
     */
    constructor(props) {
        super(props);

        /**
         * @var object
         */
        this.state = {};

        /**
         * @var object
         */
        this.styles = styles(this.props);
    }

    /**
     * Handles delete click
     *
     * @return callback
     */
    handleDeleteClick() {
        if (typeof this.props.handleDelete == "function") {
            this.props.handleDelete();
        }
    }

    /**
     * Renders html dom elements
     *
     * @return DOM elements
     */
    render() {
        const {
            onClick = () => {},
            deleteRed,
            deleteWhite,
            deleteBlack,
            deleteGrey,
            deleteDarkYellow,
            title,
            color,
            className,
        } = this.props;

        if (title) {
            let clearIcon = `${Settings.images.path}/svg/clear-light-grey.svg`;

            if (deleteWhite) {
                clearIcon = `${Settings.images.path}/svg/clear-white.svg`;
            }

            if (deleteBlack) {
                clearIcon = `${Settings.images.path}/svg/clear-black.svg`;
            }

            if (deleteGrey) {
                clearIcon = `${Settings.images.path}/svg/clear-grey.svg`;
            }

            if (deleteRed) {
                clearIcon = `${Settings.images.path}/svg/clear-red.svg`;
            }

            if (deleteDarkYellow) {
                clearIcon = `${Settings.images.path}/svg/clear-dark-yellow.svg`;
            }

            const suffix = this.props.postfix || this.props.suffix;

            return (
                <div
                    className={`meta-keyword-label--container ${
                        className || ""
                    }`}
                    onClick={() => onClick()}
                    style={{
                        ...this.styles.container,
                        ...(this.props.styles || {}),
                    }}
                >
                    <div style={this.styles.content}>
                        <span
                            style={Object.assign(
                                {},
                                this.styles.title,
                                this.props.disabled && styles().disabled,
                                this.props.delete &&
                                    !this.props.disabled &&
                                    this.styles.titleWithIconPadding,
                                this.props.weighting &&
                                    this.styles.titleWeightingPadding,
                                this.props.contentStyles &&
                                    this.props.contentStyles,
                            )}
                            className="meta-keyword-label--text"
                        >
                            {this.props.leftIcon && this.props.leftIcon}

                            <div
                                style={this.styles.copy}
                                className="meta-keyword-label--copy"
                            >
                                {this.props.title}

                                {suffix && (
                                    <span
                                        className="meta-keyword-label--postfix"
                                        style={this.styles.postfix}
                                    >
                                        {suffix}
                                    </span>
                                )}

                                {this.props.delete && !this.props.disabled && (
                                    <span
                                        className="delete-icon-wrapper"
                                        onClick={this.handleDeleteClick.bind(
                                            this,
                                        )}
                                    >
                                        {!envTest && (
                                            <DeleteIconDynamicWhite 
                                                color={color}
                                                className="delete-icon"
                                            />
                                        )}

                                        {envTest && (
                                            <div className="delete-icon"></div>
                                        )}
                                    </span>
                                )}
                            </div>
                        </span>

                        {this.props.timestamp && (
                            <span
                                className="meta-keyword-label--timestamp"
                                style={this.styles.timestamp}
                            >
                                {this.props.timestamp}
                            </span>
                        )}

                        {this.props.imdb && (
                            <span
                                className="meta-keyword-label--imdb"
                                style={this.styles.imdb}
                            >
                                <a
                                    href={this.props.imdb}
                                    style={this.styles.imbdbLink}
                                    target="_blank"
                                >
                                    <span>IMDB</span>
                                </a>
                            </span>
                        )}

                        {this.props.weighting && (
                            <span
                                className="meta-keyword-label--weighting"
                                style={this.styles.weighting}
                            >
                                <span>{this.props.weighting}</span>

                                {this.props.deleteRight &&
                                    !this.props.disabled && (
                                        <img
                                            src={clearIcon}
                                            style={this.styles.delete}
                                            onClick={this.handleDeleteClick.bind(
                                                this,
                                            )}
                                            alt="x"
                                        />
                                    )}
                            </span>
                        )}
                    </div>
                </div>
            );
        }

        return false;
    }
}
