import React from "react";
import { observer } from "mobx-react";
import { FilmographyTable } from "../../tables/Filmography.table"
// import generateFilmographyTableRows from "../utils/generateFilmographyTableRows"
import { Box } from "@mui/material";

const FilmographyTableView = observer(() => {
    return (
        <Box sx={{margin: "1.125rem 0 0"}}>
            <FilmographyTable 
                loading={false}
                filmography={[
                    {
                        id: 'FE00001',
                        record: "Dune (2021)",
                        roles: [
                            'Actor'
                        ]
                    }
                ]}
            />
        </Box>
    )
})

export default FilmographyTableView