import { Chip, Stack } from "@mui/material";
import { isEmpty } from "lodash";
import { RenderCellOnlyText } from "./core-cell-v2/RenderCellOnlyText";
import ExpandableTags from "../../Tags/expandable";

export const CoreTableCellRenderMultipleChips = ({
    value,
}: {
    value: string | string[];
}) => {
    if (isEmpty(value)) {
        return <RenderCellOnlyText text={value} />;
    }

    if (Array.isArray(value) && value.length >= 2) {
        const arrayWithFirstTwoElements = [value[0], value[1]];
        const arrayWithoutFirstTwoElements =
            value.filter(
                (el, index) => index > arrayWithFirstTwoElements?.length - 1,
            ) || [];

        return (
            <>
                <Stack direction="row" spacing={1}>
                    {arrayWithFirstTwoElements?.map((genre) => (
                        <Chip key={genre} label={genre} />
                    ))}
                </Stack>

                {!isEmpty(arrayWithoutFirstTwoElements) && (
                    <ExpandableTags
                        text={`+${arrayWithoutFirstTwoElements?.length}`}
                        dataList={arrayWithoutFirstTwoElements}
                    />
                )}
            </>
        );
    }

    return <Chip label={value} />;
};
