import _ from 'lodash'
import { makeObservable, action, extendObservable } from 'mobx'
import { isEmpty } from 'lodash'
import { RootStore } from './rootStore'
import { ContributorStore } from './contributorStore'
import { contributorUpdateStoreInitialState } from "./state"
import { CoreDialogButtonsType } from "../../../../../core/Dialog/CoreDialog"
import { updateContributor, deleteContributor, getContributor } from "../../../../../services/Contributors"
import { setSnackbar } from "../../../../../components/Global/store/reducer"
import { envTest } from '../../../../../components/Environment'
import { contributorUpdateData } from "../../test-react/testData"

export class ContributorUpdateStore extends ContributorStore {
    deleteDialogActive: boolean
    errorDialogActive: boolean
    fetching: boolean
    errorDialogConfig: {
        title: string
        description: string
    }
    handleSetOpen: Function
    handleContributorUpdated: Function
    handleContributorDeleted: Function

    constructor(rootStore: RootStore) {
        super(rootStore)
        
        makeObservable(this, {
            getContributorDialogButtons: action,
            setDeleteDialogActive: action,
            setErrorDialogActive: action,
            setErrorDialogConfig: action,
            setFetching: action,

            handleFetchContributor: action,
            handleDeleteContributor: action
        });

        extendObservable(this, contributorUpdateStoreInitialState);
    }

    initialize(
        contributorId: string | null, 
        handleSetOpen: Function,
        handleContributorUpdated: Function,
        handleContributorDeleted: Function
    ) {

        this.handleSetOpen = handleSetOpen
        this.handleContributorUpdated = handleContributorUpdated
        this.handleContributorDeleted = handleContributorDeleted

        this.reset()
        this.handleSetDataKey()

        if (contributorId && !envTest) {
            this.handleFetchContributor(contributorId)
        }

        if  (envTest) {
            this.setContributor(contributorUpdateData)
            this.setContributorOriginal(contributorUpdateData)
            this.handleSetContributorSectionData()
        }
    }

    reset() {
        Object.keys(contributorUpdateStoreInitialState).forEach(
            (key) => (this[key] = contributorUpdateStoreInitialState[key]),
        );

        this.setOpen(false)
        this.rootStore.navigationStore.handleActiveMenuReset()
        this.rootStore.validationStore.reset()
    }

    getContributorDialogButtons(): CoreDialogButtonsType {
        let result: CoreDialogButtonsType = {
            discard: {
                label: "Cancel",
                handleOnDiscard: () => this.handleResetAndCloseForm()
            }
        }

        if (this.rootStore.permissionsStore.userHasUpdatePermission) {
            result.submit = {
                handleOnSubmit: () => {
                    if (!isEmpty(this.rootStore.validationStore.getErrorSections())) {
                        this.setShowValidationErrors(true)
                    } else {
                        return this.handleSubmit()
                    }
                },
                label: "Submit",
                disabled: isEmpty(this.userChanges) || this.showValidationErrors
            }
        }

        if (this.rootStore.permissionsStore.userHasDeletePermission) {
            result.delete = {
                label: "Delete Contributor",
                handleOnDelete: () => this.setDeleteDialogActive(true),
                position: "left"
            }
        }

        return result
    }

    setFetching(fetching: boolean): void {
        this.fetching = fetching
    }

    setDeleteDialogActive(deleteDialogActive: boolean): void {
        this.deleteDialogActive = deleteDialogActive
    }
    
    setErrorDialogActive(errorDialogActive: boolean): void {
        this.errorDialogActive = errorDialogActive
    }

    setErrorDialogConfig(errorDialogConfig: { title: string, description: string }): void {
        this.errorDialogConfig = errorDialogConfig
    }

    handleResetAndCloseForm(): void {
        this.rootStore.reset()
        this.handleSetOpen(false)
    }

    async handleFetchContributor(contributorId: string | null) {
        this.setFetching(true)
        const response = await getContributor(this.rootStore.cmsData, contributorId)

        if (response.status === 200) {
            this.setContributor(response.data)
            this.setContributorOriginal(response.data)
            this.rootStore.integrationsStore.initialize(response.data)
            
            this.handleSetContributorSectionData()
        } else {
            this.rootStore.validationStore.handleSetResponseErrors(response)
            
            this.rootStore.dispatch(
                setSnackbar({ 
                    message: "Failed to fetch contributor",
                    severity: "error" 
                })
            )
        }

        this.setFetching(false)
    }

    async handleSubmit() {
        this.setWorking(true)
        const response = await updateContributor(this.rootStore.cmsData, this.contributor.id, this.getTranformedContributor())

        this.setWorking(false)

        if (response.status === 200) {
            this.handleResetAndCloseForm()
            this.handleContributorUpdated()
        } else {
            this.rootStore.validationStore.handleSetResponseErrors(response)
            
            this.rootStore.dispatch(
                setSnackbar({ 
                    message: "Failed to update contributor",
                    severity: "error" 
                })
            )
        }
    }

    async handleDeleteContributor() {
        this.setWorking(true)
        const response = await deleteContributor(this.rootStore.cmsData, this.contributor.id)

        this.setWorking(false)

        if (response.status === 204) {
            this.setDeleteDialogActive(false)
            this.handleResetAndCloseForm()
            this.handleContributorDeleted()
        } else {
            if (response?.error?.response?.data?.error?.message?.includes("is linked to a Credit")) {
                await this.setDeleteDialogActive(false)

                await this.setErrorDialogConfig({
                    title: "Unable to Delete Contributor",
                    description: 
                        "This Contributor is linked to a Credit in an active Record(s). To maintain data integrity, please delete the associated Credit(s) in the Records before removing the Contributor from Preferences. This ensures accurate and seamless management of your Contributors and their contributions across your Records."
                })

                this.setErrorDialogActive(true)
            }

            this.rootStore.dispatch(
                setSnackbar({ 
                    message: "Failed to delete contributor",
                    severity: "error" 
                })
            )
        }
    }
}
