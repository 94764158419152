import React from "react";
import { SectionInterface } from "./interface";
import EsbRegionalSupplyChain from "../../views/EsbRegionalSupplyChain";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";

const SectionEsbRegionalSupplyChain = (props: SectionInterface) => {
    const {
        cmsData = {},
        data = {},
        handleRecordUpdateEsb = () => {},
        loadingId,
    } = props;

    const { clientFeatures = {}, clientDataStructure = {} } = cmsData;

    const { regionalSupplyChainAssignment = {} } = clientDataStructure;

    const { regions, tags } = regionalSupplyChainAssignment;

    if (!clientFeatures.regionalSupplyChainAssignment) {
        return <></>
    }

    return (
        <EsbRegionalSupplyChain
            {...getSectionCommonProps({ ...props, field: "regions" })}
            name="regional-supply-chain"
            className="meta-record-regional-supply-chain"
            title={
                regionalSupplyChainAssignment.title ||
                "Regional Supply Chain Assignment"
            }
            data={data.regions || []}
            validationData={data || {}}
            esb_status={data.esb_status || {}}
            handleRecordUpdateEsb={() =>
                handleRecordUpdateEsb({ usbRecordUpdate: true })
            }
            updatingEsb={loadingId == "esbRecordUpdate"}
            regions={regions}
            tags={tags}
        />
    );
};

export default SectionEsbRegionalSupplyChain;
