import _ from 'lodash'
import { makeObservable, action, extendObservable } from 'mobx'
import { isEmpty } from "lodash"
import { AxiosResponse } from 'axios'
import { RootStore } from './rootStore'
import { validationStoreInitialState } from "./state"

export class ValidationStore {
    rootStore: RootStore;
    validationMessages: string[]
    fieldValidationMessages: string[]
    fieldErrorSections: string[]
    errorSections: string[]

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            setValidationMessage: action,
            setValidationMessages: action,
            setErrorSection: action,
            setErrorSections: action,
            setFieldValidationMessages: action,
            setFieldErrorSections: action,

            handleContributorValidation: action,
            handleContributorFieldErrors: action
        });

        extendObservable(this, validationStoreInitialState);
    }

    initialize(): void {
        this.reset()
    }

    reset() {
        Object.keys(validationStoreInitialState).forEach(
            (key) => (this[key] = validationStoreInitialState[key])
        );
    }

    getValidationMessages(): string[] {
        return [
            ...this.validationMessages,
            ...this.fieldValidationMessages
        ]
    }

    getErrorSections(): string[] {
        return [
            ...this.errorSections,
            ...this.fieldErrorSections
        ]
    }

    setValidationMessages(validationMessages: string[]): void {
        this.validationMessages = validationMessages
    }

    setValidationMessage(validationMessage: string): void {
        if (!this.validationMessages.includes(validationMessage)) {
            this.validationMessages.push(validationMessage)
        }
    }

    setErrorSections(errorSections: string[]): void {
        this.errorSections = errorSections
    }

    setErrorSection(errorSection: string): void {
        if (!this.errorSections.includes(errorSection)) {
            this.errorSections.push(errorSection)
        }
    }

    setFieldValidationMessages(fieldValidationMessages: string[]): void {
        this.fieldValidationMessages = fieldValidationMessages
    }

    setFieldValidationMessage(fieldValidationMessage: string): void {
        if (!this.fieldValidationMessages.includes(fieldValidationMessage)) {
            this.fieldValidationMessages.push(fieldValidationMessage)
        }
    }

    setFieldErrorSections(fieldErrorSections: string[]): void {
        this.fieldErrorSections = fieldErrorSections
    }

    setFieldErrorSection(fieldErrorSection: string): void {
        if (!this.fieldErrorSections.includes(fieldErrorSection)) {
            this.fieldErrorSections.push(fieldErrorSection)
        }
    }

    handleContributorValidation(): void {
        this.setErrorSections([])

        const messagesMandatoryFields: string[] = this.handleRecordBuilderMandatoryFieldsValidation()

        const messages: string[] = [
            ...messagesMandatoryFields
        ]

        const result = isEmpty(messages) 

        if (result) {
            this.setValidationMessages([])
        } else {
            this.setValidationMessages(messages)
        }
    }

    handleContributorFieldErrors(errors: any): void {
        const sectionCode: string = this.rootStore.optionsStore.activeSectionConfig?.code || ""
        const sectionName: string = this.rootStore.optionsStore.activeSectionConfig?.displayName || ""
        const errorMessage: string = `There are validation errors in ${sectionName} tab`

        if (!isEmpty(errors)) {
            this.setFieldErrorSection(sectionCode)
            this.setFieldValidationMessage(errorMessage)

            return 
        }

        if (this.fieldErrorSections.includes(sectionCode) && isEmpty(errors)) {
            this.setFieldErrorSections(
                this.fieldErrorSections.filter((item: string) => item !== sectionCode)
            )

            this.setFieldValidationMessages(
                this.fieldValidationMessages.filter((item: string) => item !== errorMessage)
            )
        }
    }

    handleSetResponseErrors(response: AxiosResponse): void {
        if (response?.response?.data?.error?.details) {
            const errors: Array<any> = response?.response?.data?.error?.details

            if (!isEmpty(errors)) {
                errors.forEach((error: any) => {
                    this.setValidationMessage(
                        `${error?.instancePath}: ${error?.message}`
                    )
                })

                this.rootStore.getActiveContributorStore()?.setShowValidationErrors(true)
            }
        }
    }

    handleRecordBuilderMandatoryFieldsValidation(): string[] {
        let messages: string[] = []

        this.rootStore.optionsStore.recordBuilderConfig.forEach((item: any) => {
            const mandatoryFields: Array<string> = this.rootStore.optionsStore.getMandatoryFieldsForSection(item.fields)
            const items: Array<any> = this.rootStore.getActiveContributorStore()?.contributor[item.code] || {}

            if (Array.isArray(items)) {
                const emptyItems = items.filter((item: any) =>
                    !mandatoryFields.every((field: string) => !!item[field])
                )

                emptyItems.forEach((em: any) => {
                    if (em.locale) {
                        messages.push(`${em.locale} is missing mandatory fields`)
                    } else {
                        messages.push(`Regional language code is missing for: ${em.full_name}`)
                    }

                    this.setErrorSection(item.code)
                })
            }

            if (!isEmpty(mandatoryFields) && isEmpty(items)) {
                messages.push(`${item.displayName} tab is missing mandatory fields`)
                this.setErrorSection(item.code)
            }
        })

        return messages
    }
}
