import React from "react";
import _ from "lodash";
import styles from "../styles";
import Index from "./Index";
import RenameObjectKey from "../../Helpers/RenameObjectKey";
import BubbleList from "../../BubbleList";
import SendRequest from "../../SendRequest";
import deepcopy from "deepcopy";
import { diff } from "deep-object-diff";

export default class Characters extends Index {
    constructor(props) {
        super(props);

        let data = [];

        if (Array.isArray(this.props.data)) {
            data = this.props.data;
        }

        /**
         * @var object
         */
        this.styles = styles(this.props);

        /**
         * @var object
         */
        this.timer = null;

        /**
         * @var object
         */
        this.state = {
            data,
            datalist: [],
            fetchCompleted: false,
            loading: false,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        const nextPropsData = nextProps.data || {};
        const thisPropsData = this.props.data || {};
        const difference = diff(nextPropsData, thisPropsData);

        if (!_.isEmpty(difference)) {
            return true;
        }

        if (!_.isEqual(nextProps.disabled, this.props.disabled)) {
            return true;
        }

        if (!_.isEqual(nextState, this.state)) {
            return true;
        }

        return false;
    }

    handleFormChange(data) {
        const changedData = RenameObjectKey(
            deepcopy(data),
            "text",
            "character",
        );
        const dataToUpdate = changedData.map((data) => {
            console.log(data);
            if (data && data.value === data.character) {
                return {
                    character: data.value,
                    ranking: data.ranking,
                };
            }
            return data;
        });
        console.log("changedData", dataToUpdate);

        if (typeof this.props.handleFormChange == "function") {
            this.props.handleFormChange(dataToUpdate);
        }

        this.setData(data);
    }

    handleChange(value) {
        this.setStateValue("fetchCompleted", false);
        clearTimeout(this.timer);
        this.timer = setTimeout(this.fetchDataList.bind(this, value), 350);
    }

    fetchDataList(value) {
        const url = this.props.searchCharactersUrl.replace(
            "{searchTerm}",
            value,
        );
        const options = {
            url,
            method: "GET",
        };

        const handleError = (error) => {
            console.log(error);
            this.setStateValue("fetchCompleted", true);
            this.setStateValue("loading", false);
        };

        this.setStateValue("loading", true);

        try {
            SendRequest(
                options,
                (data) => {
                    if (typeof data == "object") {
                        if (data.status == 200) {
                            let datalist = data.data;

                            this.setStateValue("datalist", datalist);
                            this.setStateValue("fetchCompleted", true);
                            this.setStateValue("loading", false);
                            return;
                        }
                    }

                    handleError(
                        "Something went wrong when fetching characters",
                    );
                },
                (e) => {
                    handleError();
                    console.log(e);
                },
            );
        } catch (e) {
            handleError();
            console.log(e);
        }

        clearTimeout(this.timer);
    }

    renderContent() {
        const { datalist, data, fetchCompleted, loading } = this.state;

        const stateData = deepcopy(data || []);
        const changedData = RenameObjectKey(stateData, "character", "text");
        let handleNoMatchText = false;
        let active = false;

        if (Array.isArray(data)) {
            data.forEach((item) => {
                if (!item.deleted) {
                    active = true;
                }
            });
        }

        if (fetchCompleted) {
            handleNoMatchText = true;
        }

        if (this.props.disabled && !active) {
            return false;
        }

        return (
            <div style={this.styles.marginItem}>
                <BubbleList
                    name="characters"
                    inputId="characters"
                    background="#6a6a6a"
                    color="white"
                    deleteWhite={true}
                    readOnly={this.props.disabled}
                    emptyPlaceholder={
                        this.copy.startTypingGenres || "Start Typing Characters"
                    }
                    data={changedData}
                    handleFormChange={this.handleFormChange.bind(this)}
                    datalistId="characters_list"
                    datalist={datalist || []}
                    ranking={true}
                    loading={loading}
                    handleNoMatchText={handleNoMatchText}
                    noMatchText={
                        this.copy.noMatchText ||
                        "No match for {value}. Create New?"
                    }
                    handleChange={this.handleChange.bind(this)}
                />
            </div>
        );
    }
}
