import { GridRenderCellParams } from "@mui/x-data-grid";
import { CoreTableCell } from "../../components/CoreTableCell";
import { CoreTableGridColDef } from "../../types";

export const MetaIdCol: CoreTableGridColDef = {
    field: "meta_id",
    headerName: "ID",
    flex: 1,
    renderCell: (params: GridRenderCellParams<string>) => {
        const { row } = params;

        return (
            <CoreTableCell boldText>
                {row.meta_id}
            </CoreTableCell>
        )
    },
};
