import React from 'react'
import { observer } from "mobx-react-lite";
import { useStores } from "../../store/rootStore";
import SelectV2 from "../../../../../components/Select/SelectV2";
import { CreditListInterface } from "../../credits.interface";
import { trimString } from '../../utils/helpers';

const CreditListSelect: React.FC = observer(() => {
    const rootStore = useStores()
    const { creditListsStore, creditsStore } = rootStore || {}

    const editStatus = rootStore.getEditStatus
    const selectOptions = creditListsStore.getCreditListSelectOptions
    const activeCreditList = creditListsStore.getActiveCreditList

    const handleSelect = (code: string) => {
        creditListsStore.setActiveCreditList(code)
        creditsStore.refreshTableData()
    }

    const handleAddNew = () => {
        creditListsStore.setFormStatus({
            isActive: true,
            isDisabled: true,
            isNewList: true,
            isDeleteForm: false
        })
    }

    const handleEdit = (creditList: CreditListInterface) => {
        creditListsStore.setActiveCreditList(creditList.code)
        creditListsStore.setEditedCreditList(creditList)
        creditListsStore.setFormStatus({
            isActive: true,
            isDisabled: true,
            isNewList: false,
            isDeleteForm: false
        })
    }

    const handleDelete = (creditList: CreditListInterface) => {
        creditListsStore.setFormStatus({
            isActive: false,
            isDisabled: true,
            isNewList: false,
            isDeleteForm: true
        })

        creditListsStore.setCreditListForDelete(creditList)
        creditsStore.refreshTableData()
    }

    return (
        <React.Fragment>
            <SelectV2
                disabledProp={editStatus}
                dataList={selectOptions}
                defaultValue={activeCreditList.code}
                forceValue={activeCreditList.code}
                setSelectedValue={(code: string) => handleSelect(code)}
                handleAddNew={handleAddNew}
                handleEdit={(creditList: CreditListInterface) => handleEdit(creditList)}
                handleDelete={(creditList: CreditListInterface) => handleDelete(creditList)}
                renderValue={() => `${trimString(activeCreditList.name, 20)} • ${activeCreditList.code}` as unknown as React.ReactElement }
                actions={true}
                addNew={true}
                addNewText="Add New Credit List..."
                wrapperStyles={{
                    margin: "0 0.5rem 0 0",
                    minWidth: "17.24rem"
                }}
            />
        </React.Fragment>
    )
})

export default CreditListSelect