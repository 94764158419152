import React, { Fragment, useState } from "react";
import _ from "lodash";
import BubbleList from "../../BubbleList/simpleOnEnter";
import Settings from "../../Settings";
import Switch from "../../Switch";
import CheckboxTag from "../../CheckboxTag";
import Select from "../../Select";
import Input from "../../Input/basic";
import RightsPlatformsOptions from "../../ClientManagedOptions/rights_platforms";
import AutocompleteInput from "../../AutocompleteInput";
import ClientManagedLanguages from "../../ClientManagedOptions/languages";
import GetLanguagesFromRegions from "../../Languages/GetLanguagesFromRegions";
import ClientManagedCountries from "../../ClientManagedOptions/countries";
import { disabledSection } from "../config";
import { useStore } from "../../Global/store/store";
import {
    featureUserRoleManagement,
    userHasPermission,
} from "../../../services/PermissionService";
import {
    accessTypeUpdate,
    policyTypeRecord,
} from "../../../features/UserRoleManagement/utils/accessOptions";
import { groupSettingsOption } from "../../../features/UserRoleManagement/utils/sectionOptions/userGroups";

const UsergroupInformation = (props) => {
    const [platformType, setPlatformType] = useState("");
    const [globalState] = useStore() || [{}];

    const { cmsData = {}, userPermissions = [], options = {} } = globalState;

    const { state = {}, record = {}, handleChange = () => {} } = props;

    const { editRecord = false } = state;

    const { clientFeatures = {}, clientBranding = {} } = cmsData;

    const { product_availability = [] } = clientBranding;

    const { countries = [], rights = {} } = options;

    const { versions = [] } = rights;

    const {
        linups_record_visibility_only,
        company_code,
        country_iso = [],
        platforms = [],
        version_priorities = [],
        platform_priorities = [],
        play_period = "",
        audio_tracks = [],
        subtitles = [],
        product,
    } = record;

    const { spoken_languages = [], all_written_languages = [] } = options;

    const userRoleManagement = featureUserRoleManagement({ cmsData });
    const userUpdatePermission = userHasPermission({
        cmsData,
        userPermissions,
        pathField: groupSettingsOption.path,
        access: accessTypeUpdate,
    });
    let disabled = !editRecord;
    const marginTop = "1.5rem";

    if (editRecord && userRoleManagement) {
        disabled = !userUpdatePermission;
    }

    const rightsPlatforms = RightsPlatformsOptions({
        options,
        platformType,
        clientFeatures,
    });

    const spanStyles = {
        ...Settings.components.noneEnteredPlaceholder,
        marginTop: "0.5rem",
    };

    const FormatLanguage = (languages) => {
        return languages.map((item) => item.text);
    };

    function handleMultipleSelections(props) {
        let data = props.data;
        let item = props.item;
        let updatedState = [...data];

        if (_.includes(data, item)) {
            // remove
            updatedState = updatedState.filter((it) => it !== item);
        } else {
            // add
            updatedState.push(item);
        }

        handleChange(props.stateKey, updatedState);
    }

    const spokenLanguages = FormatLanguage(
        ClientManagedLanguages({
            options,
            spoken: true,
            fallback: spoken_languages,
            clientFeatures,
            languageKey: "dubbing_tracks",
        }),
    );

    const written_languages = GetLanguagesFromRegions({
        languages: all_written_languages,
    });

    const writtenLanguages = FormatLanguage(
        ClientManagedLanguages({
            options,
            written: true,
            fallback: written_languages,
            clientFeatures,
            languageKey: "subtitles",
        }),
    );

    const countriesList = ClientManagedCountries({
        options,
        fallback: countries,
        clientFeatures,
    }).map((item) => item.text);

    const playPeriods = [
        {
            text: "1 Month",
            value: 1,
        },
        {
            text: "2 Months",
            value: 2,
        },
        {
            text: "3 Months",
            value: 3,
        },
        {
            text: "4 Months",
            value: 4,
        },
        {
            text: "6 Months",
            value: 6,
        },
        {
            text: "12 Months",
            value: 12,
        },
    ];

    const selectedPlayPeriod =
        playPeriods.find((item) => item.value == play_period) || {};

    const renderAutocompleteInput = (item) => {
        const data = item.bubbleListData || [];
        const staticDatalist = item.staticDatalist.filter(
            (di) => !data.includes(di),
        );

        let options = {};
        if (item.bubbleListData) {
            options.handleListSelection = (it) =>
                handleMultipleSelections({
                    stateKey: item.stateKey,
                    data,
                    item: it,
                });

            options.callBackOnDelete = (it) =>
                handleMultipleSelections({
                    stateKey: item.stateKey,
                    data,
                    item: it,
                });
        } else {
            options.handleListSelection = (it) => {
                if (typeof it == "object" && it) {
                    handleChange(item.stateKey, it.value || item.text);
                }
            };

            options.callBackOnDelete = () => {
                handleChange(item.stateKey, null);
            };
        }

        return (
            <AutocompleteInput
                {...item}
                {...options}
                handleMove={(items) => handleChange(item.stateKey, items)}
                openOnClick={true}
                staticDatalist={staticDatalist}
                disabled={disabled}
                keywordLabel={{
                    background: item.background,
                    color: item.color,
                    deleteGrey: item.deleteGrey,
                }}
                displayInput={!disabled}
                filterStaticDataList={true}
                disabled={disabled}
            />
        );
    };

    return (
        <div
            className={`meta-usergroup-information ${
                disabled ? disabledSection : ""
            }`}
        >
            <div className="meta-record-body--title-content">
                <h2>Connect User Group Information</h2>
            </div>

            <div
                style={{
                    marginTop,
                    width: "10rem",
                }}
            >
                <Input
                    label="Company Code"
                    placeholder="Enter Company Code"
                    id="company_code"
                    name="company_code"
                    value={company_code}
                    recordBasic={true}
                    margin="1.78125rem 0 0 0"
                    disabled={disabled}
                    handleChange={(value) =>
                        handleChange("company_code", value)
                    }
                />
            </div>

            <div
                style={{
                    marginTop,
                    position: "relative",
                }}
            >
                <BubbleList
                    label="Countries • Rights Holding Territories"
                    background="#CFEEFF"
                    className="user-group-countries"
                    color="#2F6E90"
                    readOnly={disabled}
                    disabled={disabled}
                    data={country_iso}
                    datalist={countriesList}
                    emptyPlaceholder="Start typing countries"
                    handleFormChange={(data) =>
                        handleChange("country_iso", data)
                    }
                    filterStaticDataList={true}
                    useEnterButtonOnSelect={true}
                />

                <div
                    style={{
                        position: "absolute",
                        right: 0,
                        top: 0,
                        textAlign: "right",
                    }}
                >
                    <label
                        style={{
                            ...Settings.components.label,
                            marginBottom: "0.3rem",
                        }}
                    >
                        Limit Connect Record Visibility to Lineups Only
                    </label>

                    <Switch
                        name="linups_record_visibility_only"
                        disabled={disabled}
                        checked={linups_record_visibility_only}
                        handleChange={(item) =>
                            handleChange(
                                "linups_record_visibility_only",
                                item.checked,
                            )
                        }
                    />
                </div>
            </div>

            {!disabled && (
                <div style={{ marginTop }}>
                    <Select
                        name="platform_type_Select"
                        id="platform_type"
                        value={platformType}
                        clear={true}
                        disabled={disabled}
                        readOnly={disabled}
                        label="Platform Type"
                        disabledText="Select Platform Type"
                        noneSelectedText="None Selected"
                        data={_.uniq(rightsPlatforms.types)}
                        width="50%"
                        wrapperStyles={{
                            marginBottom: "0.5rem",
                        }}
                        handleChange={(value) => setPlatformType(value)}
                    />

                    {platformType && (
                        <Fragment>
                            {_.isEmpty(rightsPlatforms.platforms) && (
                                <span style={spanStyles}>
                                    No platforms found
                                </span>
                            )}

                            {rightsPlatforms.platforms.map((item, index) => {
                                return (
                                    <CheckboxTag
                                        key={index}
                                        selected={platforms.includes(item)}
                                        leftText={item}
                                        background="#28a38d"
                                        styles={{
                                            display: "inline-flex",
                                            width: "auto",
                                            margin: "0.71875rem 0.5rem 0 0",
                                        }}
                                        handleSelection={() =>
                                            handleMultipleSelections({
                                                stateKey: "platforms",
                                                data: platforms,
                                                item,
                                            })
                                        }
                                    />
                                );
                            })}
                        </Fragment>
                    )}
                </div>
            )}

            <div style={{ marginTop }}>
                <span style={Settings.components.label}>
                    Selected Platforms
                </span>

                {_.isEmpty(platforms) && (
                    <span style={spanStyles}>No platforms selected</span>
                )}

                {platforms.map((item, index) => {
                    return (
                        <CheckboxTag
                            key={index}
                            selected={true}
                            selected={platforms.includes(item)}
                            leftText={item}
                            background="#28a38d"
                            styles={{
                                display: "inline-flex",
                                width: "auto",
                                margin: "0.71875rem 0.5rem 0 0",
                            }}
                            selectedDisabled={disabled}
                            handleSelection={() =>
                                handleMultipleSelections({
                                    stateKey: "platforms",
                                    data: platforms,
                                    item,
                                })
                            }
                        />
                    );
                })}
            </div>

            {!_.isEmpty(product_availability) &&
                Array.isArray(product_availability) && (
                    <Fragment>
                        <div className="meta-record-body--title-content large-spacing">
                            <h2>Product Availability</h2>
                        </div>

                        <Select
                            value={product}
                            clear={true}
                            disabled={disabled}
                            readOnly={disabled}
                            disabledText="Select Product Availability"
                            noneSelectedText="None Selected"
                            data={product_availability}
                            handleChange={(value) =>
                                handleChange("product", value)
                            }
                            width="50%"
                        />
                    </Fragment>
                )}

            <div className="meta-record-body--title-content large-spacing">
                <h2>User Group Default Options</h2>
            </div>

            <div style={{ marginTop }}>
                {renderAutocompleteInput({
                    label: "Version Priorities",
                    placeholder: "Select Version",
                    bubbleListData: version_priorities,
                    staticDatalist: versions,
                    stateKey: "version_priorities",
                    background: "#A7EFDF",
                    color: "#366B60",
                    orderBubbles: true,
                    className: "user-group-version-priorities",
                    chevronBackground: false,
                })}
            </div>

            <div style={{ marginTop }}>
                {renderAutocompleteInput({
                    label: "Platform Priority",
                    placeholder: "Select Platform",
                    bubbleListData: platform_priorities,
                    staticDatalist: platforms,
                    background: "#C1F2E5",
                    color: "#466F64",
                    stateKey: "platform_priorities",
                    className: "user-group-platform-priorities",
                    chevronBackground: false,
                })}
            </div>

            <div style={{ marginTop }}>
                {renderAutocompleteInput({
                    label: "Play Period",
                    placeholder: "Select Play Period",
                    value: selectedPlayPeriod.text || "",
                    keepSelected: true,
                    bubbleOnSelect: true,
                    staticDatalist: playPeriods,
                    background: "#C1F2E5",
                    color: "#466F64",
                    stateKey: "play_period",
                    className: "user-group-play-period",
                    chevronBackground: false,
                })}
            </div>

            <div style={{ marginTop }}>
                {renderAutocompleteInput({
                    label: "Audio Tracks",
                    placeholder: "Select Audio Tracks",
                    bubbleListData: audio_tracks,
                    staticDatalist: spokenLanguages,
                    background: "#A3EEDA",
                    color: "#4C665E",
                    stateKey: "audio_tracks",
                    className: "user-group-audio-tracks",
                    chevronBackground: false,
                })}
            </div>

            <div style={{ marginTop }}>
                {renderAutocompleteInput({
                    label: "Subtitles",
                    placeholder: "Select Subtitles",
                    bubbleListData: subtitles,
                    staticDatalist: writtenLanguages,
                    background: "#FFB57C",
                    color: "#6E4A2E",
                    stateKey: "subtitles",
                    className: "user-group-subtitles",
                    chevronBackground: false,
                })}
            </div>
        </div>
    );
};

export default UsergroupInformation;
