import deepcopy from "deepcopy";
import _ from "lodash";
import CategoryTags from "../../../components/CategoryTags";
import SendRequest from "../../../components/SendRequest";

const HandleSelectedTitlesChange = (props) => {
    const { selectedTitles = [], state = {}, testingData = {} } = props;

    const { setCollectionTitleKey, cmsData = {} } = state;

    const { apiUrls = {} } = cmsData;

    let result = deepcopy(selectedTitles);

    if (Array.isArray(result)) {
        result.map(async (title, index) => {
            const { category, category_name, meta_id, linkage } = title;

            const categoryName = category || category_name;

            if (categoryName == CategoryTags.Show.short) {
                const options = {
                    url: apiUrls.recordLinkage,
                    method: "POST",
                    body: {
                        meta_id,
                    },
                    testingData,
                };

                const seasons = linkage.filter(
                    (item) => item.category == CategoryTags.Season.short,
                );
                const episodes = linkage.filter(
                    (item) => item.category == CategoryTags.Episode.short,
                );
                const storylines = linkage.filter(
                    (item) => item.category == CategoryTags.Storyline.short,
                );
                const episodesAndStorylines = _.concat(episodes, storylines);

                let suffix = "";
                if (!_.isEmpty(seasons)) {
                    suffix = seasons.length + " Season";

                    if (seasons.length > 1) {
                        suffix = suffix + "s";
                    }
                }

                if (!_.isEmpty(seasons) && !_.isEmpty(episodesAndStorylines)) {
                    suffix = suffix + " • ";
                }

                if (!_.isEmpty(episodesAndStorylines)) {
                    suffix = suffix + episodesAndStorylines.length + " Ep";

                    if (episodesAndStorylines.length > 1) {
                        suffix = suffix + "s";
                    }
                }

                if (!_.isEmpty(suffix)) {
                    title.suffix = suffix;
                }

                let promise = new Promise((resolve, reject) => {
                    SendRequest(
                        options,
                        (response) => {
                            const { data = [], status, message } = response;

                            if (status == 200) {
                                resolve(data);
                            }

                            reject(message);
                        },
                        (e) => {
                            reject(e);
                        },
                    );
                });

                const titlesLinkage = await promise;
                title.linkage = titlesLinkage;
            }

            return title;
        });
    }

    setCollectionTitleKey("selectedTitles", result);
};

export default HandleSelectedTitlesChange;
