import React, { useState } from "react";
import "./styles/_styles.scss";
import _ from "lodash";
import Settings from "../Settings";

const GracenoteLabel = (props) => {
    const { text = "" } = props;

    const styles = {
        ...Settings.components.label,
        ...(props.styles || {}),
    };

    return <span style={styles}>{text}</span>;
};

export default GracenoteLabel;
