import { Box } from "@mui/material";
import { PropsWithChildren } from "react";
import {
    CORE_TABLE_CELL_ERROR_BACKGROUND_COLOR,
    CORE_TABLE_CELL_ERROR_CLASSNAME,
} from "../CoreTable";

export const CoreTableErrorWrapper = ({ children }: PropsWithChildren) => {
    return (
        <Box
            sx={{
                [`& .${CORE_TABLE_CELL_ERROR_CLASSNAME}`]: {
                    backgroundColor: CORE_TABLE_CELL_ERROR_BACKGROUND_COLOR,
                },
            }}
        >
            {children}
        </Box>
    );
};
