import React, { Fragment } from "react";
import AssetInterface from "../interfaces/AssetInterface";
import SubDubInterface from "../interfaces/SubDubInterface";
import AssetTypeInterface, {
    AssetTypeSectionInterface,
} from "../interfaces/AssetTypeInterface";
import GetSectionConfiguration, {
    IsAdditionalSection,
    SUBTITLE_SECTION,
    AUDIO_SECTION,
} from "../section/AdditionalSection";
import Section from "../section";

type Props = {
    asset: AssetInterface | SubDubInterface;
    typeConfiguration: AssetTypeInterface;
};

const SubDubContent = (props: Props) => {
    const { asset, typeConfiguration } = props;

    const { type } = asset;

    const subsConf: AssetTypeSectionInterface = GetSectionConfiguration({
        typeConfiguration,
        sectionKey: SUBTITLE_SECTION,
    });
    const dubsConf = GetSectionConfiguration({
        typeConfiguration,
        sectionKey: AUDIO_SECTION,
    });

    return (
        <Fragment>
            {type == SUBTITLE_SECTION &&
                subsConf &&
                subsConf.sections &&
                subsConf.sections.map((section, key) => {
                    return (
                        <Section
                            asset={asset}
                            configuration={section}
                            key={key}
                        />
                    );
                })}

            {type == AUDIO_SECTION &&
                dubsConf &&
                dubsConf.sections &&
                dubsConf.sections.map((section, key) => {
                    return (
                        <Section
                            asset={asset}
                            configuration={section}
                            key={key}
                        />
                    );
                })}
        </Fragment>
    );
};

export default SubDubContent;
