import { RootStore } from './rootStore'
import { globalStateInterface } from "../../../../components/Global/interfaces";
import FieldInterface, { InputType, ListSource } from '../interfaces/Field.interface'
import { CoreSelectOptionType } from "../../components/inputs/Select/types/CoreSelectOptionType";
import { action, extendObservable, makeObservable, observable } from "mobx";
import { DynamicList } from '../interfaces/DynamicList.type';
import { getList } from "../../../../services/ClientManagedListService"
import { SelectType } from '../../../../models/RecordBuilder/Field.interface';
import selectTransformer from "../../../../core/Form/utils/selectTransformer"
import { envTest } from ".././../../../components/Environment";
import { transformList } from '../transformers/ListTransformer';

interface InitialInterface {
    fields: FieldInterface[]
}

const initialState: InitialInterface = {
    fields: []
};

export class ListsStore {

    fields: FieldInterface[]
    lists: { [key: string]: DynamicList } = []
    globalState: globalStateInterface
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore

        makeObservable(this, {
            initialize: action,
            lists: observable,
            isListFetched: action,
            listOptions: action,
            setListFetched: action,
            setListOptions: action,
        });

        extendObservable(this, initialState);
    }

    initialize(
        fields,
        globalState,
    ): void {

        this.reset()
        this.globalState = globalState
        this.fields = fields
        this.fetchLists()
    }

    reset() {
        Object.keys(initialState).forEach(
            (key) => (this[key] = initialState[key]),
        );
    }

    isListFetched(listKey: string): boolean {
        if (this.lists[listKey]) {
            return this.lists[listKey].isFetched
        }
        
        return false
    }

    listOptions(listKey: string): CoreSelectOptionType[] {
        if (this.lists[listKey]) {
            return this.lists[listKey].options
        }

        return []
    }

    fetchLists() {
        this.fields.forEach((field: FieldInterface) => {
            const listShouldBeSet: boolean = !this.lists[field.type?.select?.listKey]

            if (
                SelectType[field.type.input]
                && field.type.select.listKey
                && listShouldBeSet
            ) {
                this.lists = {
                    ...this.lists,
                    [field.type.select.listKey]: {
                        key: field.type.select.listKey,
                        options: [],
                        error: null,
                        isFetched: false,
                    }
                }

                if (field.type.select.listSource == ListSource.Config) {
                    return this.handleSetListFromConfig(field)
                }

                if (field.type.select.listSource == ListSource.Options) {
                    return this.handleSetListFromOptions(field)
                }

                this.handleSetClientManagedList(field)
            }
        })
    }

    setListOptions(key: string, options: CoreSelectOptionType[] = []): void {
        if (this.lists[key]) {
            this.lists[key].options = options
        }
    }

    setListFetched(key: string, fetched: boolean = false): void {
        if (this.lists[key]) {
            this.lists[key].isFetched = fetched
        }
    }

    handleSetListFromConfig(field: FieldInterface): void {
        this.setListOptions(field.type.select.listKey, selectTransformer(
            field.type.select.list || []
        ));

        this.setListFetched(field.type.select.listKey, true)
    }

    handleSetListFromOptions(field: FieldInterface): void {
        this.setListOptions(field.type.select.listKey, selectTransformer(
            this.globalState?.options[field.type.select.listKey] || []
        ));

        this.setListFetched(field.type.select.listKey, true)
    }

    handleSetClientManagedList(field: FieldInterface): void {
        if (envTest && field.testingData) {
            this.setListOptions(field.type.select.listKey, field.testingData);
            this.setListFetched(field.type.select.listKey, true)
        }

        getList(
            this.globalState?.cmsData, 
            field.type.select.listSource == ListSource.Dynamic, 
            field.type.select.listKey
        ).then((data) => {
            const listOptions = field.type.select.listSource == ListSource.Dynamic
                ? transformList({ options: data, field })
                : data
            
            this.setListOptions(field.type.select.listKey, listOptions)
            this.setListFetched(field.type.select.listKey, true)
        })
    }
}