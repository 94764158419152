import React from "react";
import CheckboxTag from "./index";
import "./styles/_double_grid.scss";
import _ from "lodash";

const DoubleGridList = (props) => {
    const {
        label,
        items = [],
        selected = [],
        background = "#28a38d",
        styles = {},
        itemStyles = {},
        emptyText,
        handleSelection = () => {},
        rightLabelText,
        handleRightTextClick = () => {},
        selectedDisabled = [],
    } = props;

    let rowCount = 2;
    if (Array.isArray(items)) {
        rowCount = Math.ceil(items.length / 2);
    }

    return (
        <div className="double-grid-list" style={styles}>
            <div className="label">
                {label && <label>{label}</label>}

                {rightLabelText && (
                    <span onClick={() => handleRightTextClick()}>
                        {rightLabelText}
                    </span>
                )}
            </div>

            {_.isEmpty(items) && emptyText && <span>{emptyText}</span>}

            {!_.isEmpty(items) && Array.isArray(items) && (
                <div
                    style={{
                        gridTemplateRows: `repeat(${rowCount}, 1fr)`,
                    }}
                >
                    {items.map((item, index) => {
                        const text = typeof item == "string" ? item : item.text;
                        const value =
                            typeof item == "string" ? item : item.value;

                        return (
                            <CheckboxTag
                                key={index}
                                selected={selected?.includes(value)}
                                selectedDisabled={selectedDisabled.includes(
                                    value,
                                )}
                                leftText={text}
                                background={background}
                                styles={itemStyles}
                                handleSelection={() => handleSelection(value)}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default DoubleGridList;
