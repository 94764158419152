import React, { Component } from "react";
import styles from "../styles";
import SelectClientManagedGroup from "../../Select/ClientManagedGroup";
import SelectClientManaged from "../../Select/ClientManaged";
import ClientManagedLanguages from "../../ClientManagedOptions/languages";
import ClientManagedCountries from "../../ClientManagedOptions/countries";
import isClientManaged from "../../ClientManagedOptions/isClientManaged";
import {
    TITLES_AND_SYNOPSES,
    CLIENT_MANAGED_COUNTRIES,
} from "../../ClientManagedOptions/options";
import { CoreSelect } from "../../../core/Form/components/inputs/Select/components/CoreSelect";
import { CoreFormPaper } from "../../../core/Form/components/layout/CoreForm.paper";
import { CoreFormStack } from "../../../core/Form/components/layout/CoreForm.stack";
import { CoreSelectOptionType } from "../../../core/Form/components/inputs/Select/types/CoreSelectOptionType";
import { CoreFormSubmitButton } from "../../../core/Form/components/inputs/CoreFormSubmit.button";
import { useForm } from "react-hook-form";
import {
    CoreFormProvider,
    CoreFormThemeProvider,
} from "../../../core/Form/components/CoreForm";
import { LocaleCoreForm } from "./LocaleCoreForm";
import generateSelectOptions from "../../../core/Form/components/inputs/Select/utils/generateSelectOptions";
import renderChip from "../../../core/Form/components/inputs/Select/utils/renderChip";
import { envTest } from "../../../components/Environment";
import { isEmpty } from "lodash";

export default class LocaleSelect extends Component {
    constructor(props) {
        super(props);

        const { cmsData = {} } = this.props;

        this.optionsKey = this.props.optionsKey || TITLES_AND_SYNOPSES;

        const { clientFeatures = {} } = cmsData;

        this.isClientManagedTitlesAndSynopses = isClientManaged({
            clientFeatures,
            clientManagedType: TITLES_AND_SYNOPSES,
        });

        this.isClientManagedCountries = isClientManaged({
            clientFeatures,
            clientManagedType: CLIENT_MANAGED_COUNTRIES,
        });

        this.isClientManagedList = false;
        if (
            this.optionsKey == TITLES_AND_SYNOPSES &&
            this.isClientManagedTitlesAndSynopses
        )
            this.isClientManagedList = true;
        if (
            this.optionsKey == CLIENT_MANAGED_COUNTRIES &&
            this.isClientManagedCountries
        )
            this.isClientManagedList = true;
    }

    handleLanguageChange(value) {
        if (typeof this.props.handleLanguageChange == "function") {
            this.props.handleLanguageChange(value);
        }
    }

    getData() {
        const { options = {}, clientFeatures = {} } = this.props;

        let data = options[this.optionsKey] || [];

        if (this.optionsKey == TITLES_AND_SYNOPSES) {
            data = ClientManagedLanguages({
                options,
                written: true,
                fallback: data,
                clientFeatures,
                languageKey: "titles_and_synopses",
                groupByRegion: true,
            });
        }

        if (this.optionsKey == CLIENT_MANAGED_COUNTRIES) {
            data = ClientManagedCountries({
                options,
                clientFeatures,
                fallback: options.countries || [],
            });
        }

        return data;
    }

    getSelectData() {
        const data = this.getData();
        const { checkedKeys = [] } = this.props;
        let result = []

        if (this.optionsKey == CLIENT_MANAGED_COUNTRIES) {
            result = generateSelectOptions(data, checkedKeys) 
        } 

        if (this.optionsKey == TITLES_AND_SYNOPSES) {
            Object.keys(data).forEach((key) => {
                const regionItems = generateSelectOptions(
                    Array.isArray(data[key]) ? data[key] : data,
                    checkedKeys
                ) 

                result.push(...regionItems)
            });
        }

        return result;
    }

    render() {
        const data = this.getData();
        const selectData = this.getSelectData();

        const {
            value,
            disabledText = "Select Language",
            group = true,
            checkedKeys = [],
            cmsData = {},
            wrapperStyles = {
                minWidth: "100%",
                textAlign: "left",
                margin: "1rem 0rem",
            },
            noMargin,
            className,
            id = "meta-languages",
        } = this.props;

        const { clientFeatures = {}, apiUrls = {} } = cmsData;

        const onSubmit = (locale) => {
            if(typeof locale == "string" && !isEmpty(locale)){
                this.props.handleLanguageChange(locale);
            }
        };

        const componentOptions = {
            name: "languageIso",
            id,
            value,
            handleChange: this.handleLanguageChange.bind(this),
            data,
            styles: styles().langIso,
            backgroundIconLeft: "svg/globe-grey.svg",
            disabledText,
            checkedKeys,
            className: `${className} scrollbar-visible`,
            clientFeatures,
            apiUrls,
            isClientManaged: this.isClientManagedList,
            clientManagedType: this.optionsKey,
            noMargin,
        };

        const shouldUseLocaleV2 =
            !envTest && clientFeatures?.localeVersion === "v2";

        return (
            <div style={wrapperStyles}>
                <div
                    style={{
                        display: "inline-block",
                        float: "right",
                        minWidth: "12.5rem",
                    }}
                >
                    {!shouldUseLocaleV2 && group && (
                        <SelectClientManagedGroup {...componentOptions} />
                    )}
                    {!shouldUseLocaleV2 && !group && (
                        <SelectClientManaged {...componentOptions} />
                    )}

                    {shouldUseLocaleV2 && (
                        <LocaleCoreForm
                            options={selectData}
                            wrapperStyles={wrapperStyles}
                            onChange={(e) => onSubmit(e)}
                            value={value}
                            key={checkedKeys.join("_") + value}
                        />
                    )}
                </div>
            </div>
        );
    }
}
