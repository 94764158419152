const languages = [
    {
        language: "Afrikaans",
        locale: "af",
    },
    {
        language: "Albanian",
        locale: "sq",
    },
    {
        language: "Amharic",
        locale: "am",
    },
    {
        language: "Arabic",
        locale: "ar",
    },
    {
        language: "Armenian",
        locale: "hy",
    },
    {
        language: "Azerbaijani",
        locale: "az",
    },
    {
        language: "Basque",
        locale: "eu",
    },
    {
        language: "Belarusian",
        locale: "be",
    },
    {
        language: "Bengali",
        locale: "bn",
    },
    {
        language: "Bosnian",
        locale: "bs",
    },
    {
        language: "Bulgarian",
        locale: "bg",
    },
    {
        language: "Catalan",
        locale: "ca",
    },
    {
        language: "Cebuano",
        locale: "ceb",
    },
    {
        language: "Chinese",
        locale: "zh",
    },
    {
        language: "Corsican",
        locale: "co",
    },
    {
        language: "Croatian",
        locale: "hr",
    },
    {
        language: "Czech",
        locale: "cs",
    },
    {
        language: "Danish",
        locale: "da",
    },
    {
        language: "Dutch",
        locale: "nl",
    },
    {
        language: "English",
        locale: "en",
    },
    {
        language: "Esperanto",
        locale: "eo",
    },
    {
        language: "Estonian",
        locale: "et",
    },
    {
        language: "Finnish",
        locale: "fi",
    },
    {
        language: "French",
        locale: "fr",
    },
    {
        language: "Frisian",
        locale: "fy",
    },
    {
        language: "Galician",
        locale: "gl",
    },
    {
        language: "Georgian",
        locale: "ka",
    },
    {
        language: "German",
        locale: "de",
    },
    {
        language: "Greek",
        locale: "el",
    },
    {
        language: "Gujarati",
        locale: "gu",
    },
    {
        language: "Haitian Creole",
        locale: "ht",
    },
    {
        language: "Hausa",
        locale: "ha",
    },
    {
        language: "Hawaiian",
        locale: "haw",
    },
    {
        language: "Hebrew",
        locale: "he",
    },
    {
        language: "Hindi",
        locale: "hi",
    },
    {
        language: "Hmong",
        locale: "hmn",
    },
    {
        language: "Hungarian",
        locale: "hu",
    },
    {
        language: "Icelandic",
        locale: "is",
    },
    {
        language: "Igbo",
        locale: "ig",
    },
    {
        language: "Indonesian",
        locale: "id",
    },
    {
        language: "Irish",
        locale: "ga",
    },
    {
        language: "Italian",
        locale: "it",
    },
    {
        language: "Japanese",
        locale: "ja",
    },
    {
        language: "Javanese",
        locale: "jv",
    },
    {
        language: "Kannada",
        locale: "kn",
    },
    {
        language: "Kazakh",
        locale: "kk",
    },
    {
        language: "Khmer",
        locale: "km",
    },
    {
        language: "Korean",
        locale: "ko",
    },
    {
        language: "Kurdish",
        locale: "ku",
    },
    {
        language: "Kyrgyz",
        locale: "ky",
    },
    {
        language: "Lao",
        locale: "lo",
    },
    {
        language: "Latin",
        locale: "la",
    },
    {
        language: "Latvian",
        locale: "lv",
    },
    {
        language: "Lithuanian",
        locale: "lt",
    },
    {
        language: "Luxembourgish",
        locale: "lb",
    },
    {
        language: "Macedonian",
        locale: "mk",
    },
    {
        language: "Malagasy",
        locale: "mg",
    },
    {
        language: "Malay",
        locale: "ms",
    },
    {
        language: "Malayalam",
        locale: "ml",
    },
    {
        language: "Maltese",
        locale: "mt",
    },
    {
        language: "Maori",
        locale: "mi",
    },
    {
        language: "Marathi",
        locale: "mr",
    },
    {
        language: "Mongolian",
        locale: "mn",
    },
    {
        language: "Myanmar",
        locale: "my",
    },
    {
        language: "Nepali",
        locale: "ne",
    },
    {
        language: "Norwegian",
        locale: "no",
    },
    {
        language: "Nyanja",
        locale: "ny",
    },
    {
        language: "Pashto",
        locale: "ps",
    },
    {
        language: "Persian",
        locale: "fa",
    },
    {
        language: "Polish",
        locale: "pl",
    },
    {
        language: "Portuguese",
        locale: "pt",
    },
    {
        language: "Punjabi",
        locale: "pa",
    },
    {
        language: "Romanian",
        locale: "ro",
    },
    {
        language: "Russian",
        locale: "ru",
    },
    {
        language: "Samoan",
        locale: "sm",
    },
    {
        language: "Scots Gaelic",
        locale: "gd",
    },
    {
        language: "Serbian",
        locale: "sr",
    },
    {
        language: "Sesotho",
        locale: "st",
    },
    {
        language: "Shona",
        locale: "sn",
    },
    {
        language: "Sindhi",
        locale: "sd",
    },
    {
        language: "Sinhala",
        locale: "si",
    },
    {
        language: "Slovak",
        locale: "sk",
    },
    {
        language: "Slovenian",
        locale: "sl",
    },
    {
        language: "Somali",
        locale: "so",
    },
    {
        language: "Spanish",
        locale: "es",
    },
    {
        language: "Sundanese",
        locale: "su",
    },
    {
        language: "Swahili",
        locale: "sw",
    },
    {
        language: "Swedish",
        locale: "sv",
    },
    {
        language: "Tagalog",
        locale: "tl",
    },
    {
        language: "Tajik",
        locale: "tg",
    },
    {
        language: "Tamil",
        locale: "ta",
    },
    {
        language: "Telugu",
        locale: "te",
    },
    {
        language: "Thai",
        locale: "th",
    },
    {
        language: "Turkish",
        locale: "tr",
    },
    {
        language: "Ukrainian",
        locale: "uk",
    },
    {
        language: "Urdu",
        locale: "ur",
    },
    {
        language: "Uzbek",
        locale: "uz",
    },
    {
        language: "Vietnamese",
        locale: "vi",
    },
    {
        language: "Welsh",
        locale: "cy",
    },
    {
        language: "Xhosa",
        locale: "xh",
    },
    {
        language: "Yiddish",
        locale: "yi",
    },
    {
        language: "Yoruba",
        locale: "yo",
    },
    {
        language: "Zulu",
        locale: "zu",
    },
];

export default languages;
