import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { CoreTableCell } from "../../components/CoreTableCell";
import { CoreTableGridColDef } from "../../types";

export const FormatCol: CoreTableGridColDef = {
    headerName: "Format",
    field: "extension",
    flex: 1,
    renderCell: (params: GridRenderCellParams<string>) => {
        const { row } = params;
        
        return <CoreTableCell uppercase>{row.extension || ''}</CoreTableCell>;
    },
};
