const DefaultIncludingListShow = [
    {
        text: "Show level only",
        value: "show_only",
    },
    {
        text: "Include",
        value: "show_season_episodes",
    },
];

export default DefaultIncludingListShow;
