import React from "react";
import ApplySIFMultiple from "../../ApplySIFMultiple";

const RenderTotalCategories = (props) => {
    const { features, shows, episodes } = props;

    const countItems = (array) => {
        let result = 0;

        if (Array.isArray(array)) result = array.length;

        return result;
    };

    const featureCount = countItems(features);
    const showCount = countItems(shows);
    const episodeCount = countItems(episodes);

    return (
        <span className="meta-numbered-record-table--categories">
            {featureCount} Feature{ApplySIFMultiple(featureCount)} • {showCount}{" "}
            Show{ApplySIFMultiple(showCount)} • {episodeCount} Episode
            {ApplySIFMultiple(episodeCount)}
        </span>
    );
};

export default RenderTotalCategories;
