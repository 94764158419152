import { ValidationLayer } from "../../../../models/RecordBuilder/Field.interface"

export enum InputType {
    String = 'String',
    Text = 'Text',
    TextArea = 'TextArea',
    BooleanRadio = 'BooleanRadio',
    BooleanToggle = 'BooleanToggle',
    BooleanCheckbox = 'BooleanCheckbox',
    Select = 'Select',
    SelectFramed = 'SelectFramed',
    SelectMultiple = 'SelectMultiple',
    SelectMultipleChips = 'SelectMultipleChips',
    Year = 'Year',
    Duration = 'Duration',
	Date = 'Date',
    Time = 'Time',
    DateTime = 'DateTime',
    PhoneNumber = 'PhoneNumber',
    Autocomplete = 'Autocomplete',
    ColorPalette = 'ColorPalette',
    TextFramed = 'TextFramed',

    Alphanumeric = 'Alphanumeric',
    Numeric = 'Numeric',

    // Special Components
    AutocompleteContributors = 'AutocompleteContributors'
}

export enum ItemType {
    Object = 'Object',
    String = 'String'
}

export enum ListSource {
    Dynamic = 'dynamic',
    Legacy = 'legacy',
    Options = 'options',
    Config = 'config'
}

export enum DataType {
    Array = 'Array',
    Boolean = 'Boolean',
    Integer = 'Integer',
    Link = 'Link',
    Number = 'Number',
    Object = 'Object',
    String = 'String'
}

export type Option = {
    dataType: DataType
    textField?: string
    valueField?: string
}

export type Type = {
    dataType: DataType
    input: InputType,
    select?: {
        listSource: ListSource
        listKey: string
        adornmentKey?: string
        list?: any[]
        growing?: boolean
        color?: string
        textColor?: string
        option?: Option
        listTextField?: string
        listValueField?: string
    },
    boolean?: {
        trueLabel: string,
        falseLabel: string,
    };
    datetTime?: {
        format: string
    }
}

export type Layer = {
    level: number
    columns: number
}

export type Validation = {
    readOnly?: {
        enabled: boolean
        validation?: ValidationLayer
    }
    required?: {
        enabled: boolean
        validation?: ValidationLayer
    }
    characterLimit?: {
        enabled: boolean
        validation: ValidationLayer
        limit: number
        minLimit?: number
    }
    rangeLimit?: {
        enabled: boolean
        validation: ValidationLayer
        min: number
        max: number
    },
    email?: {
        enabled: boolean
        validation: ValidationLayer
    },
    url?: {
        enabled: boolean
        validation: ValidationLayer
    }
}

export default interface DynamicField {
    type: Type
    label: string,
    key: string
    layer: Layer
    order?: number
    loading?: boolean,
    disabled?: boolean,
    labelMessage?: string,
    labelTooltipText?: string,
    value?: any
    placeholder?: string
    info?: string
    validation?: Validation
	checkedKeys?: string[] | null,
    groupName?: string | null,
    testingData?: any[],
    characterCounter?: {
        enabled: boolean
    },
    tooltip?: string
}