import React from "react";
import _ from "lodash";
import Settings from "../Settings";
import Button from "../Button";
import Notification from "../Notification";
import Select from "../Select";
import AssetTrafficLight from "../AssetTrafficLight";
import Tag from "../Tag";
import Checkbox from "../Input/checkbox";
import ViewMessageDeleteButtons from "../ViewMessageDeleteButtons";
import CreatedByTags from "../CreatedByTags";
import AddNewRowButton from "../AddNewRowButton";
import update from "react-addons-update";
import ArrayDifference from "array-difference";
import "./styles/_styles.scss";

export default class RegionalSupplyChain extends React.Component {
    constructor(props) {
        super(props);

        let data = [];
        if (Array.isArray(this.props.data)) {
            data = this.props.data;
        }

        /**
         * @var object
         */
        this.copy = this.props.copy || {};

        /**
         * @var object
         */
        this.state = {
            data,
            notification: {},
            region: false,
            allRegions: false,
        };
    }

    getTitleFromCode(code) {
        const regions = this.getRegionOptions();
        const result = regions.find((item) => item.value == code) || {};
        return result.text || "";
    }

    getRegionOptions() {
        const { options = {} } = this.props;

        const { regions = [] } = options;

        return regions;
    }

    getAllRegionsCodes() {
        let result = this.getRegionOptions();
        return result.map((item) => item.value);
    }

    getRegions() {
        let result = this.getRegionOptions();

        const { data } = this.state;

        data.forEach((item) => {
            result = result.filter((region) => region.value !== item);
        });

        return result;
    }

    getRegionsWithCodes() {
        let result = this.getRegions();
        return result.map((item) => {
            return {
                ...item,
                text: `${item.text} - ${item.value}`,
            };
        });
    }

    getNotificationObject() {
        const { region, allRegions } = this.state;

        let disabled = true;
        let confirm = this.handleSave.bind(this);

        if (region || allRegions) {
            disabled = false;
        }

        if (allRegions) {
            confirm = this.handleAllRegionsSave.bind(this);
        }

        const notification = {
            title: this.copy.regionalAssignment || "Regional Assignment",
            description: false,
            html: this.renderNotificationHtml(),
            okText: this.copy.save || "Save",
            disabled: disabled,
            background: "white",
            contentNoPadding: false,
            titlePadding: false,
            status: "alert",
            confirm,
            onClick: this.handleNotificationCancel.bind(this),
        };

        return notification;
    }

    getEsbStatusObject(item) {
        const { esb_status = [] } = this.props;

        let result = null;

        if (Array.isArray(esb_status)) {
            const esbStatusItem = esb_status.find((i) => i.type == item);

            if (esbStatusItem) {
                result = esbStatusItem;
            }
        }

        return result;
    }

    isComplete() {
        const { data } = this.state;

        let result = false;

        const differnce = ArrayDifference(this.getAllRegionsCodes(), data);

        if (_.isEmpty(differnce)) {
            result = true;
        }

        return result;
    }

    deleteRegion(index) {
        const { data } = this.state;

        const updatedState = update(data, { $splice: [[index, 1]] });

        this.handleSaveDataToState(updatedState);
    }

    canAddNewValidation() {
        const { invalidKeys = [] } = this.props;

        return invalidKeys.length === 0;
    }

    handleNewRow() {
        if (!this.canAddNewValidation()) {
            const html = () => {
                return (
                    <div>
                        <p>
                            Regions cannot be asigned for potential duplicated
                            record.
                        </p>
                    </div>
                );
            };

            const notification = {
                title:
                    this.copy.esbValidation || "Regional Assignment Validation",
                description: false,
                html: html(),
                okText: this.copy.ok || "Ok",
                disabled: false,
                background: false,
                contentNoPadding: false,
                titlePadding: false,
                status: "alert",
                confirm: this.handleNotificationCancel.bind(this),
                onClick: this.handleNotificationCancel.bind(this),
            };

            this.setState({
                notification,
            });

            return;
        }

        this.setState({
            notification: this.getNotificationObject(),
        });
    }

    handleSave() {
        const { region, data } = this.state;

        let updatedState = data;

        if (region) {
            updatedState = update(data, { $push: [region] });
        }

        this.handleSaveDataToState(updatedState);
    }

    handleSaveDataToState(data) {
        const { handleFormChange } = this.props;

        this.setState(
            {
                data,
                region: false,
                allRegions: false,
                notification: {},
            },
            () => {
                this.handleNotificationCancel();

                if (typeof handleFormChange == "function") {
                    handleFormChange(data);
                }
            },
        );
    }

    handleAllRegionsSave() {
        const data = this.getAllRegionsCodes();
        this.handleSaveDataToState(data);
    }

    handleNotificationCancel() {
        this.setState({
            notification: {},
            region: false,
            allRegions: false,
        });
    }

    handleRegionChange(region) {
        this.setState(
            {
                region,
            },
            () => {
                this.setState({
                    notification: this.getNotificationObject(),
                });
            },
        );
    }

    handleViewMessage(esbStatus) {
        let { message } = esbStatus;

        let esbMessage = message;
        esbMessage = `${esbMessage}. ${
            this.copy.regionRetryCopy ||
            "This region will be retried on the next save."
        }`;

        if (message) {
            const notification = {
                title:
                    this.copy.regionalSupplyChainMessage ||
                    "Regional Supply Chain Message",
                description: esbMessage,
                okText: this.copy.ok || "Ok",
                status: "alert",
                onClick: this.handleNotificationCancel.bind(this),
            };

            this.setState({
                notification,
            });
        }
    }

    handleDelete(index) {
        const notification = {
            title: this.copy.delete || "Delete",
            description:
                this.copy.deleteRegionDescription ||
                "Are you sure you want to delete this region?",
            okText: this.copy.delete || "Delete",
            status: "alert",
            confirm: this.deleteRegion.bind(this, index),
            onClick: this.handleNotificationCancel.bind(this),
        };

        this.setState({
            notification,
        });
    }

    handleAllRegionsSelect(allRegions) {
        this.setState(
            {
                allRegions,
            },
            () => {
                this.setState({
                    notification: this.getNotificationObject(),
                });
            },
        );
    }

    renderNotificationHtml() {
        const { region } = this.state;

        const regions = this.getRegionsWithCodes();

        return (
            <React.Fragment>
                <Select
                    name="region"
                    id="region"
                    value={region}
                    clear={true}
                    label={
                        this.copy.destinationSupplyChain ||
                        "Destination Supply Chain"
                    }
                    disabledText={this.copy.selectRegion || "Select Region"}
                    noneSelectedText={this.copy.noneSelected || "None Selected"}
                    data={regions}
                    wrapperStyles={{
                        marginTop: "1.125rem",
                    }}
                    handleChange={this.handleRegionChange.bind(this)}
                />

                {!_.isEmpty(regions) && (
                    <Checkbox
                        label={
                            this.copy.selectAllRegions || "Select All Regions"
                        }
                        style={{
                            marginTop: "1.125rem",
                        }}
                        handleChange={this.handleAllRegionsSelect.bind(this)}
                    />
                )}
            </React.Fragment>
        );
    }

    renderAddNewButton() {
        const { disabled, canCreateNew = true } = this.props;

        const { data } = this.state;

        if (!disabled && !this.isComplete()) {
            return (
                <AddNewRowButton
                    onClick={this.handleNewRow.bind(this)}
                    disabled={!canCreateNew || disabled}
                    data={data}
                    className="meta-regional--add-new"
                />
            );
        }

        return;
    }

    renderButton(options = {}) {
        const {
            value,
            className,
            onClick,
            margin = "0.90625rem 0 0.90625rem 0.90625rem",
            disabled,
        } = options;

        return (
            <Button
                value={value}
                type="button"
                className={className}
                margin={margin}
                disabled={disabled}
                disabledOpacity={disabled}
                padding="0.34375rem"
                background="#8f8f8f"
                hoverBackground="#9a9a9a"
                fontSize="0.78125rem"
                color="white"
                display="inline-block"
                onClick={onClick}
                leftIcon={`${Settings.images.path}/svg/new-record-white.svg`}
                leftIconStyles={{
                    height: "0.75rem",
                    marginRight: "0.1875rem",
                }}
            />
        );
    }

    renderSupplyChains() {
        const { data } = this.state;

        const { disabled } = this.props;

        return (
            <div className="meta-regional--supply-chains">
                {data.map((item, index) => {
                    const esbStatus = this.getEsbStatusObject(item);
                    let status = "placeholder created";
                    let displayMessage = false;
                    let displayDelete = true;

                    if (typeof esbStatus == "object" && esbStatus) {
                        status = esbStatus.status;
                        displayDelete = false;

                        if (esbStatus.message) {
                            displayMessage = true;
                        }
                    }

                    return (
                        <div className="meta-regional--region" key={index}>
                            {this.renderIcon("regional-assignment-grey.svg")}

                            <span className="meta-regional--title">
                                {this.getTitleFromCode(item)}
                                {this.renderCode(item)}
                            </span>

                            <div className="meta-regional--right-content">
                                {esbStatus && esbStatus.status !== "error" && (
                                    <CreatedByTags
                                        created_at={esbStatus.published}
                                        created_by={esbStatus.created_by}
                                        created_by_name={esbStatus.created_by}
                                        copy={this.copy}
                                        color="#838383"
                                        leftText={
                                            this.copy.published || "Published"
                                        }
                                        styles={{
                                            marginRight: "0.59375rem",
                                        }}
                                    />
                                )}

                                {!disabled && (
                                    <ViewMessageDeleteButtons
                                        copy={this.copy}
                                        displayMessage={displayMessage}
                                        displayDelete={displayDelete}
                                        onClickViewMessage={this.handleViewMessage.bind(
                                            this,
                                            esbStatus,
                                        )}
                                        onClickDelete={this.handleDelete.bind(
                                            this,
                                            index,
                                        )}
                                        style={{
                                            marginRight: "0.59375rem",
                                        }}
                                    />
                                )}

                                <AssetTrafficLight status={status} />
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    renderCode(code) {
        if (code) {
            return (
                <Tag
                    text={code}
                    className="meta-regioonal--code"
                    styles={{
                        fontSize: "0.78125rem",
                        color: "#838383",
                        background: "transparent",
                        borderColor: "#838383",
                        border: "0.065rem solid",
                        margin: "0 0 0 0.90625rem",
                    }}
                />
            );
        }

        return;
    }

    renderIcon(name) {
        return <img src={`${Settings.images.path}/svg/${name}`} />;
    }

    render() {
        return (
            <React.Fragment>
                <div className="meta-regional-supply-chain-assignment">
                    {this.renderSupplyChains()}
                    {this.renderAddNewButton()}
                </div>

                <Notification
                    title={this.state.notification.title || false}
                    description={this.state.notification.description || false}
                    intercationStatus={this.state.notification.status || false}
                    html={this.state.notification.html || false}
                    confirm={this.state.notification.confirm || false}
                    background={this.state.notification.background || false}
                    disabled={this.state.notification.disabled || false}
                    onClick={this.handleNotificationCancel.bind(this)}
                    okText={this.state.notification.okText || undefined}
                    datalist={this.state.notification.datalist || undefined}
                    className={this.notificationClass}
                    titlePadding={this.state.notification.titlePadding}
                    contentNoPadding={this.state.notification.contentNoPadding}
                />
            </React.Fragment>
        );
    }
}
