import { isEmpty } from "lodash"
import { AncillariesInterface } from "../../../models/Record/ancillaries"
import { ItemDataInterface as MultiLevelViewInterface } from "../../../components/RecordBuilder/views/MultiLevelExpandable/interfaces"

interface FieldInterface {
    name?: string,
    key: string,
    type?: string,
}

interface AncillaryCategoryFieldsInterface {
    [key: string]: Array<string>
}

const fields: FieldInterface[] = [
    {
        key: 'meta_id',
        type: 'string',
        name: "Fabric {type} ID"
    },
    {
        key: 'name',
        type: 'string',
        name: "{type} Name",
    },
    {
        name: 'External ID',
        key: 'external_id',
        type: 'string'
    },
    {
        key: 'end_date',
        type: 'string',
        name: "End Date"
    }
]

const ancillaryCategoryFields: AncillaryCategoryFieldsInterface = {
    ancillary: [
        'meta_id',
        'name',
        'external_id',
        'end_date'
    ],
    category: [
        'meta_id',
        'name',
        'external_id'
    ],
    'sub-category': [
        'meta_id',
        'name',
        'external_id'
    ]
}

function getAncillariesFields(item: AncillariesInterface): FieldInterface[] {
    const type: string = transformType(item?.type)
    const itemFields: Array<string> = ancillaryCategoryFields[type]

    if (itemFields) {
        return itemFields.map((fieldKey: string) => {
            const field: FieldInterface | {} = fields.find((it: any) => it.key == fieldKey) || {}

            return {
                ...field,
                name: field.name?.replace('{type}', type),
                value: item[fieldKey]
            }
        })
    }

    return []
}

function transformType(type: string): string {
    return type?.replace('_', '-')
}

export default function multiLevelViewTransformer(ancillaries: AncillariesInterface[]): MultiLevelViewInterface[] {
    if (isEmpty(ancillaries) || !Array.isArray(ancillaries)) {
        return []
    }

    return ancillaries?.map((ancillary: AncillariesInterface) => {
        return {
            name: ancillary.name,
            tags: [transformType(ancillary.type)],
            fields: getAncillariesFields(ancillary),
            children: ancillary.children?.map((category: AncillariesInterface) => {
                return {
                    name: category.name,
                    tags: [transformType(category.type)],
                    fields: getAncillariesFields(category),
                    children: category.children?.map((subCategory: AncillariesInterface) => {
                        return {
                            name: subCategory.name,
                            tags: [transformType(subCategory.type)],
                            fields: getAncillariesFields(subCategory),
                        }
                    })
                }
            })
        }
    })
}