import { alpha, Menu, MenuProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CoreMenuStyled = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        transformOrigin={{
            horizontal: "right",
            vertical: "top",
        }}
        anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
        padding: "0.3125rem",
        border: "0.0625rem solid #E6E5E5",
        boxShadow: "0.125rem 0.1875rem 0.3125rem rgba(79, 94, 116, 0.15)",
        "& .MuiList-root": {
            maxHeight: "22.5rem",
            padding: 0,
            "& .MuiPaper-root": {
                border: 0,
                "& + div": {
                    overflow: "scroll",
                    maxHeight: "17.5rem",
                }
            },
        },
    },
}));
