import {
    accessTypeCreate,
    accessTypeDelete,
    accessTypeView,
    accessTypeUpdate,
} from "../sectionOptions";
import { OptionInterface } from "../../interfaces";

// Task Manager - Ticket Types

export const ticketTypeStandaloneOption: OptionInterface = {
    name: "Record Requests - Standalone",
    value: "standalone",
    path: "taskManager.ticketTypes.recordRequest.type.standalone",
    children: [],
};

export const ticketTypeTvShowOption: OptionInterface = {
    name: "Record Request - TV Show",
    value: "tvShow",
    path: "taskManager.ticketTypes.recordRequest.type.tvShow",
    children: [],
};

export const ticketTypeUpdateOption: OptionInterface = {
    name: "Update Request",
    value: "update",
    path: "taskManager.ticketTypes.recordUpdate.type",
    children: [],
};

// Task Manager - Record Update Request statuses

export const updateRecordsRequestStatusDraftOption: OptionInterface = {
    name: "Draft in Progress",
    value: "draft",
    path: "taskManager.ticketTypes.recordUpdate.statuses.draft",
    children: [],
    disabledAccessTypes: [accessTypeCreate, accessTypeDelete, accessTypeView],
};

export const updateRecordsRequestStatusAwaitingApprovalOption: OptionInterface =
    {
        name: "Awaiting Approval",
        value: "awaitingApproval",
        path: "taskManager.ticketTypes.recordUpdate.statuses.awaitingApproval",
        children: [],
        disabledAccessTypes: [
            accessTypeCreate,
            accessTypeDelete,
            accessTypeView,
        ],
    };

export const updateRecordsRequestStatusApprovedOption: OptionInterface = {
    name: "Approved",
    value: "approved",
    path: "taskManager.ticketTypes.recordUpdate.statuses.approved",
    children: [],
    disabledAccessTypes: [accessTypeCreate, accessTypeDelete, accessTypeView],
};

export const updateRecordsRequestStatusRejectedOption: OptionInterface = {
    name: "Rejected",
    value: "rejected",
    path: "taskManager.ticketTypes.recordUpdate.statuses.rejected",
    children: [],
    disabledAccessTypes: [accessTypeCreate, accessTypeDelete, accessTypeView],
};

export const updateRecordsRequestStatusChangeInProgressOption: OptionInterface =
    {
        name: "Change in Progress",
        value: "changeInProgress",
        path: "taskManager.ticketTypes.recordUpdate.statuses.changeInProgress",
        children: [],
        disabledAccessTypes: [
            accessTypeCreate,
            accessTypeDelete,
            accessTypeView,
        ],
    };

export const updateRecordsRequestStatusCancelledOption: OptionInterface = {
    name: "Cancelled",
    value: "cancelled",
    path: "taskManager.ticketTypes.recordUpdate.statuses.cancelled",
    children: [],
    disabledAccessTypes: [accessTypeCreate, accessTypeDelete, accessTypeView],
};

export const updateRecordsRequestStatusSubmissionInProgressOption: OptionInterface =
    {
        name: "Submission in Progress",
        value: "submissionInProgress",
        path: "taskManager.ticketTypes.recordUpdate.statuses.submissionInProgress",
        children: [],
        disabledAccessTypes: [
            accessTypeCreate,
            accessTypeDelete,
            accessTypeView,
        ],
    };

export const updateRecordsRequestStatusSubmittedOption: OptionInterface = {
    name: "Submitted",
    value: "submitted",
    path: "taskManager.ticketTypes.recordUpdate.statuses.submitted",
    children: [],
    disabledAccessTypes: [accessTypeCreate, accessTypeDelete, accessTypeView],
};

export const updateRecordsRequestStatusSubmissionRejectedOption: OptionInterface =
    {
        name: "Submission Rejected",
        value: "submissionRejected",
        path: "taskManager.ticketTypes.recordUpdate.statuses.submissionRejected",
        children: [],
        disabledAccessTypes: [
            accessTypeCreate,
            accessTypeDelete,
            accessTypeView,
        ],
    };

// Task Manager - Record Create Request statuses
export const createRecordsRequestStatusDraftOption: OptionInterface = {
    name: "Draft in Progress",
    value: "draft",
    path: "taskManager.ticketTypes.recordRequest.statuses.draft",
    children: [],
    disabledAccessTypes: [accessTypeCreate, accessTypeDelete, accessTypeView],
};

export const createRecordsRequestStatusAwatingApprovalOption: OptionInterface =
    {
        name: "Awaiting Approval",
        value: "awaitingApproval",
        path: "taskManager.ticketTypes.recordRequest.statuses.awaitingApproval",
        children: [],
        disabledAccessTypes: [
            accessTypeCreate,
            accessTypeDelete,
            accessTypeView,
        ],
    };

export const createRecordsRequestStatusApprovedOption: OptionInterface = {
    name: "Approved",
    value: "approved",
    path: "taskManager.ticketTypes.recordRequest.statuses.approved",
    children: [],
    disabledAccessTypes: [accessTypeCreate, accessTypeDelete, accessTypeView],
};

export const createRecordsRequestStatusRejectedOption: OptionInterface = {
    name: "Rejected",
    value: "rejected",
    path: "taskManager.ticketTypes.recordRequest.statuses.rejected",
    children: [],
    disabledAccessTypes: [accessTypeCreate, accessTypeDelete, accessTypeView],
};

export const createRecordsRequestStatusChangeInProgressOption: OptionInterface =
    {
        name: "Change in Progress",
        value: "changeInProgress",
        path: "taskManager.ticketTypes.recordRequest.statuses.changeInProgress",
        children: [],
        disabledAccessTypes: [
            accessTypeCreate,
            accessTypeDelete,
            accessTypeView,
        ],
    };

export const createRecordsRequestStatusCancelledOption: OptionInterface = {
    name: "Cancelled",
    value: "cancelled",
    path: "taskManager.ticketTypes.recordRequest.statuses.cancelled",
    children: [],
    disabledAccessTypes: [accessTypeCreate, accessTypeDelete, accessTypeView],
};

export const createRecordsRequestStatusSubmissionInProgressOption: OptionInterface =
    {
        name: "Submission in Progress",
        value: "submissionInProgress",
        path: "taskManager.ticketTypes.recordRequest.statuses.submissionInProgress",
        children: [],
        disabledAccessTypes: [
            accessTypeCreate,
            accessTypeDelete,
            accessTypeView,
        ],
    };

export const createRecordsRequestStatusSubmittedOption: OptionInterface = {
    name: "Submitted",
    value: "submitted",
    path: "taskManager.ticketTypes.recordRequest.statuses.submitted",
    children: [],
    disabledAccessTypes: [accessTypeCreate, accessTypeDelete, accessTypeView],
};

export const createRecordsRequestStatusSubmissionRejectedOption: OptionInterface =
    {
        name: "Submission Rejected",
        value: "submissionRejected",
        path: "taskManager.ticketTypes.recordRequest.statuses.submissionRejected",
        children: [],
        disabledAccessTypes: [
            accessTypeCreate,
            accessTypeDelete,
            accessTypeView,
        ],
    };

// Task Manager - ticket tabs

export const taskManagerTicketsOptions: OptionInterface = {
    name: "Tickets",
    value: "tickets",
    path: "taskManager.tabs.tickets",
    children: [],
    disabledAccessTypes: [accessTypeCreate, accessTypeDelete, accessTypeUpdate],
};

export const taskManagerMyTicketsOptions: OptionInterface = {
    name: "My Tickets",
    value: "myTickets",
    path: "taskManager.tabs.myTickets",
    children: [],
    disabledAccessTypes: [accessTypeCreate, accessTypeDelete, accessTypeUpdate],
};

export const taskManagerArchiveOptions: OptionInterface = {
    name: "Archive",
    value: "archive",
    path: "taskManager.tabs.archive",
    children: [],
    disabledAccessTypes: [accessTypeCreate, accessTypeDelete, accessTypeUpdate],
};

// Task Manager - Ticket Approvals

export const ownApprovalsStandaloneOption: OptionInterface = {
    name: "Record Request - Standalone",
    value: "standalone",
    path: "taskManager.ticketTypes.recordRequest.approvals.own.standalone",
    children: [],
    disabledAccessTypes: [accessTypeCreate, accessTypeDelete, accessTypeView],
};

export const ownApprovalsTvShowOption: OptionInterface = {
    name: "Record Request - TV Show",
    value: "tvShow",
    path: "taskManager.ticketTypes.recordRequest.approvals.own.tvShow",
    children: [],
    disabledAccessTypes: [accessTypeCreate, accessTypeDelete, accessTypeView],
};

export const ownApprovalsUpdateOption: OptionInterface = {
    name: "Update Request",
    value: "recordUpdate",
    path: "taskManager.ticketTypes.recordUpdate.approvals.own",
    children: [],
    disabledAccessTypes: [accessTypeCreate, accessTypeDelete, accessTypeView],
};

export const otherApprovalsStandaloneOption: OptionInterface = {
    name: "Record Request - Standalone",
    value: "standalone",
    path: "taskManager.ticketTypes.recordRequest.approvals.other.standalone",
    children: [],
    disabledAccessTypes: [accessTypeCreate, accessTypeDelete, accessTypeView],
};

export const otherApprovalsTvShowOption: OptionInterface = {
    name: "Record Request - TV Show",
    value: "tvShow",
    path: "taskManager.ticketTypes.recordRequest.approvals.other.tvShow",
    children: [],
    disabledAccessTypes: [accessTypeCreate, accessTypeDelete, accessTypeView],
};

export const otherApprovalsUpdateOption: OptionInterface = {
    name: "Update Request",
    value: "recordUpdate",
    path: "taskManager.ticketTypes.recordUpdate.approvals.other",
    children: [],
    disabledAccessTypes: [accessTypeCreate, accessTypeDelete, accessTypeView],
};

export const taskManagerOption: OptionInterface = {
    name: "Task Manager",
    value: "taskManager",
    path: "taskManager",
    children: [],
    disabledAccessTypes: [accessTypeCreate, accessTypeDelete, accessTypeView],
};

export const updateRecordsRequestInternalCommentsOption: OptionInterface = {
    name: "Internal Comments",
    value: "internal",
    path: "taskManager.ticketTypes.recordUpdate.comments.internal",
    children: [],
    disabledAccessTypes: [accessTypeDelete, accessTypeUpdate],
};

export const createRecordsRequestInternalCommentsOption: OptionInterface = {
    name: "Internal Comments",
    value: "internal",
    path: "taskManager.ticketTypes.recordRequest.comments.internal",
    children: [],
    disabledAccessTypes: [accessTypeDelete, accessTypeUpdate],
};

export const updateRecordsRequestExternalCommentsOption: OptionInterface = {
    name: "External Comments",
    value: "external",
    path: "taskManager.ticketTypes.recordUpdate.comments.external",
    children: [],
    disabledAccessTypes: [accessTypeDelete, accessTypeUpdate],
};

export const createRecordsRequestExternalCommentsOption: OptionInterface = {
    name: "External Comments",
    value: "external",
    path: "taskManager.ticketTypes.recordRequest.comments.external",
    children: [],
    disabledAccessTypes: [accessTypeDelete, accessTypeUpdate],
};