import React from "react";

interface Props {
    description: string;
}

export const VersionDescription: React.FC<Props> = (props: Props) => {
    const { description = "" } = props;

    return (
        <div className="description" id="nav-app-version">
            {description}
        </div>
    );
};
