import SendRequest from "../../../components/SendRequest";
import { recordSave } from "../DefaultCollectionStateValues";

const HandleRestoreRecordConfirm = (props) => {
    const { state = {}, meta_id } = props;

    const {
        set,
        cmsData,
        handleRecentActivitySearch = () => {},
        handleSearchFiltersChange = () => {},
    } = state;

    const { apiUrls = {} } = cmsData;

    const url = apiUrls.restoreRecord.replace("{meta_id}", meta_id);
    const options = {
        url,
        method: "PUT",
    };

    set({ notification: {} });

    const handleError = (data) => {
        set({
            notification: {
                title: "Technical error",
                description: "Record could not be restored",
                status: "error",
                okText: "Ok",
            },
        });
    };

    try {
        SendRequest(
            options,
            async (data) => {
                if (typeof data == "object") {
                    if (data.status == 200) {
                        set(recordSave, () => {
                            handleRecentActivitySearch("collection");
                        });

                        handleSearchFiltersChange({
                            name: "deleted_records",
                            checked: false,
                        });

                        return;
                    }
                }

                handleError(data);
            },
            (e) => {
                handleError(e);
                console.log(e);
            },
        );
    } catch (e) {
        handleError();
        console.log(e);
    }
};

export default HandleRestoreRecordConfirm;
