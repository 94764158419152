import _ from "lodash";
import SendRequest from "../../../components/SendRequest";
import GetApiUrls from "../GetApiUrls";
import moment from "moment";
import HandleRequestError from "../HandleRequestError";

const HandleLineupManagerRequest = (props) => {
    const { state = {}, options } = props;

    const { handleError, handleSuccess } = options;

    const { activeRecord = {}, lineupPeriod } = state;

    const { meta_id } = activeRecord;

    const period = lineupPeriod || moment().format("YYYY-MM");

    const { lineupManagerGet = "" } = GetApiUrls(state);

    const url = lineupManagerGet
        .replace("{usergroup_meta_id}", meta_id)
        .replace("{duration_period}", period);

    const requestOptions = {
        url,
        method: "GET",
    };

    try {
        SendRequest(
            requestOptions,
            (response) => {
                const { status } = response;

                if (status == 200 || status == 400) {
                    handleSuccess(response);
                    return;
                }

                handleError();
            },
            (error) => {
                handleError(error);
            },
        );
    } catch (e) {
        handleError(e);
    }
};

export default HandleLineupManagerRequest;
