import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import { first } from "lodash";

const RightsColumn = ({
    data = {},
    field = {},
    RenderValue = () => {},
    isOpen = false,
}) => {
    const column = useRef(null);
    const [colWidth, setColWidth] = useState("auto");

    useEffect(() => {
        if (column.current && isOpen) {
            const parentElement = $(
                ".meta-rights-v3 .rights-v3:first-child .body",
            );
            const { width } = column.current.getBoundingClientRect();
            const { width: parentWidth } =
                first(parentElement).getBoundingClientRect();
            const childPercentageWidth = Math.ceil((100 * width) / parentWidth);
            const oversize = childPercentageWidth / 16;

            if (oversize > 1) {
                const multiplier = 16 * Math.ceil(oversize);
                setColWidth(`${multiplier}%`);
            }
        }
    }, [field, column.current, isOpen]);

    return (
        <div
            className={`rights-v3-column ${field.key}`}
            ref={column}
            style={{ width: colWidth }}
        >
            <span className={`title ${field.key}`}>{field.title}</span>
            <span className={`content ${field.key}`}>
                <span className="tags">
                    {RenderValue({ data, field }) || "None"}
                </span>
            </span>
        </div>
    );
};

export default RightsColumn;
