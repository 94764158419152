export default function formatMultipleString(array, string) {
    if (Array.isArray(array) && array.length > 1) return `${string}s`;
    return string;
}

export function formatMultipleStringFromInteger(length, string) {
    if (length > 1) {
        return `${string}s`;
    }
    
    return string;
}
