import Prefixer from "inline-style-prefixer";
import Settings from "../../Settings";

export default function styles(props = {}) {
    const {
        value = ""
    } = props

    const defaultColor = "rgba(47, 47, 47, 0.87)"
    const emptyColor = "rgba(188, 188, 188, 0.87)"

    const backgroundImage =
        props.backgroundImage ||
        `${Settings.images.path}/svg/select-arrow-light-grey.svg`;

    let styles = {
        wrapper: {
            width: props.width || "100%",
            lineHeight: 1,
        },
        select: {
            background: props.background || "#E1E1E1",
            padding: "0.5rem 1.8125rem 0.5rem 0.59375rem",
            fontSize: props.fontSize || "1rem",
            margin: "0.71875rem 0 0",
            outline: "none",
            borderRadius: "0.3rem",
            color: "rgba(47, 47, 47, 0.87)",
            border: "none",
            WebkitAppearance: "none",
            cursor: "pointer",
            fontWeight: "normal",
            width: "100%",
            textTransform: props.textTransform || "none",
            opacity: 1
        },
        li: {},
        customPadding: {
            padding: props.customPadding,
        },
        noMargin: {
            margin: 0,
        },
        border: {
            border: props.border,
        },
        required: {
            fontSize: "0.90625rem",
            fontWeight: 500,
            color: "rgb(106, 106, 106)",
        },
        error: {
            border: "0.125rem solid rgb(232, 55, 92)",
        },
        selectSingle: {
            backgroundImage: `url('${Settings.images.path}/svg/${
                props.chevron || "select-arrow-light-grey.svg"
            }')`,
        },
        loading: {
            backgroundImage: `url('${Settings.images.loadingBlack}')`,
        },
        loadingWhite: {
            backgroundImage: `url('${Settings.images.loadingWhite}')`,
        },
        background: {
            backgroundImage: `url('${backgroundImage}')`,
            backgroundPosition:
                props.backgroundPosition || "right 0.65625rem top 51.5%",
            backgroundRepeat: "no-repeat",
            backgroundSize: props.backgroundSize || "0.625rem",
        },
        backgroundArrowRight: {
            backgroundImage: `url('${Settings.images.path}/svg/select-arrow-light-grey-right.svg')`,
            backgroundPosition:
                props.backgroundPosition || "right 0.65625rem top 51.5%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "0.425rem",
        },
        doubleBackground: {
            padding: "0.5625rem 1.8125rem",
            backgroundImage: `url('${Settings.images.path}/${props.backgroundIconLeft}'), url('${backgroundImage}')`,
            backgroundPosition:
                "left 0.65625rem top 51.5%, right 0.65625rem top 51.5%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "0.8125rem",
        },
        dark: {
            background: "#3b3b3b",
            color: "rgba(255, 255, 255, 0.87)",
            backgroundImage: `url('${Settings.images.path}/svg/chevron-bottom-medium-grey.svg')`,
        },
        null: {
            color: "rgba(188, 188, 188, 0.87)",
            fontWeight: 400,
        },
        label: {
            display: "block",
            fontSize: "0.90625rem",
            fontWeight: 500,
            color: "rgba(110, 110, 110, 0.87)",
        },
        labelError: {
            color: "rgb(232, 55, 92)",
        },
        readOnly: {
            cursor: "default",
            background: "none",
            backgroundImage: "none",
            padding: "0",
            fontWeight: props.readOnlyFontWeight || "normal",
            color: value ? defaultColor : emptyColor,
            boxShadow: "none",
        },
        readOnlyPadding: {
            padding: "0.5625rem 1.8125rem 0.5625rem 0.59375rem",
        },
        readOnlyNoneSelectedText: {
            cursor: "default",
            background: "none",
            backgroundImage: "none",
            display: "block",
            padding: "0",
            border: 0,
            fontSize: "1rem",
            fontWeight: "normal",
            outline: "none",
            lineHeight: 1,
            color: "rgba(47, 47, 47, 0.37)",
            boxShadow: "none",
        },
        selectContainer: {
            width: "100%",
        },
        selectContainerReadOnly: {
            //height: '2.1875rem'
        },
        readOnlyColor: {
            color: props.readOnlyColor,
        },
        small: {
            padding: "0.3125rem 1.8125rem 0.3125rem 0.40625rem",
            backgroundPosition: "right 0.4rem top 50.5%",
            backgroundSize: props.backgroundSize || "0.8125rem",
        },
    };

    let prefixer = new Prefixer();
    styles = prefixer.prefix(styles);

    return styles;
}
