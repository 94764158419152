
import React from "react";
import { get, isEmpty } from "lodash";
import { CoreInputText } from "../../../components/inputs/CoreInput.text";
import { useFormContext } from "react-hook-form";
import { InputType } from "../../interfaces/Field.interface";
import { envTest } from "../../../../../components/Environment";
import { observer } from "mobx-react";
import { useStores } from '../../store/rootStore'
import Input from "../../interfaces/Input.interface";
import { MAX_CHARACTER_COUNT } from "../../../../../features/Preferences/RecordBuilder/views/forms/origination/tile/validation/tileVS";
import { TooltipInfo } from "../../../../Tooltip/TooltipInfo";

export enum TextInputType {
    Number = 'number',
    Text = 'text',
}

export type TextInputProps = {
    type?: TextInputType
}

export const TextInput = observer(({ field, type = TextInputType.Text, framed = false }: Input & TextInputProps) => {
    const { formStore } = useStores()

    let value = 'Lorem ipsum'
    if (!envTest) {
        const { watch } = useFormContext();
        value = watch(field.key);
    }
    
    const characterLimitOption = get(field, 'validation.characterLimit')

    let characterLimit: number  = characterLimitOption?.enabled ? characterLimitOption.limit : null

    if (!characterLimit && field?.layer?.level === 1) {
        characterLimit = MAX_CHARACTER_COUNT
    }

    let placeholder = field.placeholder || `Enter ${field.label}`
    let customStyles = {}

    if (!framed && (field.validation?.readOnly?.enabled || formStore.disabled)) {
        customStyles = {
            '.MuiInputBase-input': {
                paddingBottom: '0.5rem !important',
            },
            '.MuiOutlinedInput-notchedOutline': {
                border: '0'
            },
            '.Mui-disabled': {
                paddingLeft: '0',
                color: "rgba(47, 47, 47, 0.87) !important;",
                "-webkit-text-fill-color": "rgba(47, 47, 47, 0.87) !important;",
            }
        }

        placeholder = !isEmpty(value) ? value : 'None Entered'
    }

    const labelProps = {
        text: field.label,
        isRequired: field.validation?.required?.enabled,
        tooltip: field.labelTooltipText ? <TooltipInfo title={field.labelTooltipText} /> : null,
    }

    if (field.characterCounter?.enabled) {
        labelProps.characterCounter = {
            inputTextMaxLength: characterLimit,
            inputTextLength: value ? String(value).length : 0,
        }
    }

    return (
        <div data-testid={"fabric-dynamic-form-text-input"}>
            <CoreInputText
                path={field.key}
                type={type}
                showLongTextTooltip={true}
                labelProps={labelProps}
                disabled={field.validation?.readOnly?.enabled || formStore.disabled}
                placeholder={placeholder}
                customStyles={customStyles}
                textarea={field.type?.input == InputType.TextArea}
            />
        </div>
    )
})