import { z } from "zod";
import FieldInterface from '../../interfaces/Field.interface'
import { ValidationLayer } from "../../../../../models/RecordBuilder/Field.interface";

export const AlphaNumericInputRule = (field: FieldInterface) => {
    let string: any = z.union([
        z.string().regex(new RegExp('^[A-Za-z0-9]*$'), `${field.label} must be an alphanumeric`),
        z.literal(""),
        z.literal(null)
    ]).optional()

    if (
        field.validation?.characterLimit?.enabled 
        && field.validation?.characterLimit?.limit
        && field.validation?.characterLimit?.validation != ValidationLayer.None
    ) {
        string = string.pipe(
            z.string().optional().nullish().refine((value) => {
                if (!value) {
                    return true
                }

                return value.length <= field.validation?.characterLimit?.limit
            }, {
                message: `Maximum character length ${field.validation?.characterLimit?.limit}`
            })
        )
    }

    if (
        field.validation?.characterLimit?.enabled 
        && field.validation?.characterLimit?.minLimit
        && field.validation?.characterLimit?.validation != ValidationLayer.None
    ) {
        string = string.pipe(
            z.string().optional().nullish().refine((value) => {
                if (!value) {
                    return true
                }

                return value.length >= field.validation?.characterLimit?.minLimit
            }, {
                message: `Minimum character length required ${field.validation?.characterLimit?.minLimit}`
            })
        )
    }

    if (field.validation?.required?.enabled) {
        string = string.pipe(
            z.string()
        )
    }

    return z.object({
        [field.key]: string,
    })
}