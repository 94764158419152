import React from "react";
import _ from 'lodash'
import { extendObservable, makeObservable, action } from 'mobx'
import { RootStore } from './rootStore'
import { optionsStoreInitialState } from "./state"
import { recordBuilderResponse } from "../../test-react/testData"
import { envTest } from "../../../../../components/Environment"
import { map as mapContributorSections, name as contributorSectionsName } from '../../../../../services/ApiClients/RecordBuilder/graphql/schemas/contributorSections'
import { ContributorSectionInterface, ContributorSectionFieldInterface } from '../../../../../services/ApiClients/RecordBuilder/graphql/interfaces/contributorSectionsInterface'
import { setSnackbar } from "../../../../../components/Global/store/reducer"
import getDefaultValues from "../utils/getDefaultValues"
import { globalStateInterface } from "../../../../../components/Global/interfaces"
import { getDefaultWrittenLanguageCodeFromCache } from "../../../../../models/ClientManaged/languagesModel"

export class OptionsStore {
    rootStore: RootStore

    defaultLanguageCode: string
    fetching: boolean
    recordBuilderError: any
    recordBuilderConfig: ContributorSectionInterface[]
    activeSectionConfig: ContributorSectionInterface
    activeSectionDefaultValues: any
    viewKey: string

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            setDefaultLanguageCode: action,
            setRecordBuilderError: action,
            setRecordBuilderConfig: action,
            setActiveSectionConfig: action,
            setViewKey: action,
            setFetching: action,
            setActiveSectionDefaultValues: action,

            isRecordBuilderConfigActive: action,
            handleSetDefaultLanguageCode: action,
            handleSetRecordBuilderConfig: action,
            handleSetRecordBuilderConfigTest: action,
            handleSetActiveSectionConfig: action
        })

        extendObservable(this, optionsStoreInitialState);
    }

    initialize(globalState: globalStateInterface): void {
        this.handleSetDefaultLanguageCode()

        envTest
            ? this.handleSetRecordBuilderConfigTest()
            : this.handleSetRecordBuilderConfig(globalState?.recordBuilder, globalState?.recordBuilderError)
    }

    reset() {
        Object.keys(optionsStoreInitialState).forEach(
            (key) => (this[key] = optionsStoreInitialState[key]),
        );
    }

    getDisabledTabs(): Array<string> {
        if (this.rootStore.cmsData.clientFeatures.contributorsPreferencesDisabledTabs) {
            return this.rootStore.cmsData.clientFeatures.contributorsPreferencesDisabledTabs.split(',')
        }

        return []
    }

    getActiveSectionCode(): string | null {
        return this.activeSectionConfig?.code
    }

    getActiveSectionDataKey(): string | null {
        return this.activeSectionConfig?.options?.dataKey
    }

    getSectionKeysWithDataKey(): Array<string> {
        return this.recordBuilderConfig
            .filter((item: ContributorSectionInterface) => item.options?.dataKey)
            .map((item: ContributorSectionInterface) => item.code)
    }

    getMandatoryFieldsForSection(fields: ContributorSectionFieldInterface[]): Array<string> {
        return fields
            .filter((item: any) => item?.options?.validation?.required?.enabled)
            .map((item: any) => item.key);
    }

    getSectionByKey(key: string): ContributorSectionInterface | undefined {
        return this.recordBuilderConfig.find((item: ContributorSectionInterface) => item.code === key)
    }

    setFetching(fetching: boolean): void {
        this.fetching = fetching
    }

    setViewKey(viewKey: string): void {
        this.viewKey = viewKey
    }

    setDefaultLanguageCode(defaultLanguageCode: string): void {
        this.defaultLanguageCode = defaultLanguageCode
    }

    setRecordBuilderError(recordBuilderError: any): void {
        this.recordBuilderError = recordBuilderError
    }

    setRecordBuilderConfig(recordBuilderConfig: any): void {
        this.recordBuilderConfig = recordBuilderConfig
    }

    setActiveSectionConfig(activeSectionConfig: ContributorSectionInterface): void {
        this.activeSectionConfig = activeSectionConfig
    }

    setActiveSectionDefaultValues(): void {
        this.activeSectionDefaultValues = getDefaultValues(
            this.activeSectionConfig, 
            this.rootStore.getActiveContributorStore()?.contributorSectionData,
            { 
                locale: this.defaultLanguageCode 
            }
        )
    }

    isRecordBuilderConfigActive(): boolean {
        return !this.recordBuilderError && !_.isEmpty(this.recordBuilderConfig)
    }

    handleSetDefaultLanguageCode(): void {
        this.setDefaultLanguageCode(
            getDefaultWrittenLanguageCodeFromCache()
        )
    }

    handleSetRecordBuilderConfigTest(): void {
        this.setFetching(false)
        this.setRecordBuilderConfig(
            recordBuilderResponse.data[mapContributorSections.dataPath] || []
        )

        this.rootStore.navigationStore.handleSetMenu()
        this.handleSetActiveSectionConfig()
    }

    handleSetRecordBuilderConfig(globalRecordBuilderConfig: any = {}, globalRecordBuilderError: any): void {
        const contributorSections: ContributorSectionInterface[] = (globalRecordBuilderConfig[contributorSectionsName] || [])
            .filter((item: ContributorSectionInterface) => !this.getDisabledTabs().includes(item.code))

        if (_.isEmpty(contributorSections) && globalRecordBuilderError) {
            this.setFetching(false)
            this.setRecordBuilderError("Failed to fetch Record Builder config")

            this.rootStore.dispatch(
                setSnackbar({ 
                    message: "Failed to fetch Record Builder config",
                    severity: "error" 
                })
            )

            return
        } else {
            this.setRecordBuilderConfig(contributorSections)
            this.rootStore.navigationStore.handleSetMenu()
            this.handleSetActiveSectionConfig()
            this.setFetching(false)
        }
    }

    handleSetActiveSectionConfig(): void {
        const activeSectionConfig = this.recordBuilderConfig[this.rootStore.navigationStore.activeMenuIndex || 0]

        this.setActiveSectionConfig(activeSectionConfig)
        this.setViewKey(activeSectionConfig?.options?.viewKey)
        this.setActiveSectionDefaultValues()
    }
}