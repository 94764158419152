import Prefixer from "inline-style-prefixer";
import Settings from "../../Settings";

export default function styles(props = {}, value = false) {
    const getWidth = () => {
        let result = "100%";

        if (props.width) {
            result = props.width;
        }

        if (props.dynamicWidth && value) {
            result = (value.length * 8.2) / 16;
            result = `${result}rem`;
        }

        return result;
    };

    const width = getWidth();

    let styles = {
        container: {
            width: width,
            minWidth: props.minWidth || "none",
            maxWidth: props.maxWidth || "100%",
            display: props.display || "inline-block",
            margin: props.margin || 0,
            position: "relative",
        },
        input: {
            width: "100%",
            display: "block",
            padding: props.inputPadding || "0.71875rem 0",
            margin: props.inputMargin || "0",
            border: props.border || 0,
            borderRadius: props.borderRadius || 0,
            transition: props.transition || "0.1s",
            fontSize: props.fontSize || "1rem",
            fontWeight: props.fontWeight || "normal",
            boxShadow: props.boxShadow || "none",
            outline: props.outline || "none",
            lineHeight: props.lineHeight || 1.2,
            background: props.background || "transparent",
            color: props.color || "white",
            cursor: props.cursor || "text",
            textTransform: props.textTransform || "none",
            textOverflow: props.textOverflow || "initial",
        },
        inputShallow: {
            padding: "0.46875rem 0",
        },
        inputBorder: {
            boxShadow: `0px 0.0625rem 0px 0px ${
                props.borderBackground || "#424242"
            }`,
        },
        inputBorderError: {
            boxShadow: `0px 0.125rem 0px 0px rgb(232, 55, 92)`,
        },
        borderHidden: {
            boxShadow: props.boxShadow || "none",
            padding: props.inputPadding || "0.71875rem 0 0 0",
        },
        borderHiddenPadding: {
            padding: props.inputPadding || "0.71875rem 0",
            boxShadow: `0px 0.0625rem 0px 0px transparent`,
        },
        borderFocus: {
            boxShadow: `0px 0.125rem 0px 0px ${
                props.borderBackgroundFocus || "#5f74b4"
            }`,
        },
        displayBorder: {
            padding: props.inputPadding || "0.71875rem 0",
            boxShadow: `0px 0.0625rem 0px 0px ${
                props.borderBackground || "#d1d1d1"
            }`,
        },
        textArea: {
            lineHeight: 1.43,
            overflow: "hidden",
            height: "auto",
        },
        focus: {
            color: props.focusColor || "rgba(47, 47, 47, 0.87)",
        },
        hidden: {
            display: "none",
        },
        counter: {
            fontSize: "0.78125rem",
            padding: "0.11875rem 0.25rem",
            borderRadius: "0.3rem",
            border: "none",
            color: "#87918E",
            backgroundColor: "#D7D7D7",
            marginLeft: "0.46875rem",
            lineHeight: 1,
        },
        required: {
            fontSize: "0.90625rem",
            fontWeight: 500,
            color: "#6a6a6a",
        },
        counterOverLimit: {
            border: "none",
            borderColor: "transparent",
            color: "#fafafafa",
            backgroundColor: "#EB483E",
        },
        datalistContainer: {
            padding: "0.28125 0",
            position: "absolute",
            zIndex: props.dataListZindex || 1,
            boxShadow: "0 0 12px 0 rgba(7, 7, 7, 0.25)",
            background: "#f0f0f0",
            minWidth: "18.75rem",
            borderRadius: "0.3125rem",
        },
        datalistUl: {
            listStyle: "none",
            margin: 0,
            padding: 0,
        },
        datalistLi: {
            padding: "0.6875rem 1rem",
            display: "block",
            lineHeight: "1.5rem",
            fontSize: "1rem",
            color: "#000000",
            cursor: "pointer",
        },
        label: {
            fontSize: "0.90625rem",
            fontWeight: 500,
            textTransform: "capitalize",
            color: props.labelColor || "rgba(110, 110, 110, 0.87)",
            display: "block",
        },
        labelError: {
            color: "rgb(232, 55, 92)",
        },
        labelAudit: {
            color: "rgba(110, 110, 110, 0.87)",
        },
        datalistLiActive: {
            background: "rgba(215, 215, 215, 0.7)",
        },
        prefixContainer: {
            width: "100%",
            display: "flex",
            alignItems: "center",
        },
        chevronbackground: {
            backgroundImage: `url('${Settings.images.path}/svg/chevron-bottom-light-grey.svg')`,
            backgroundPosition: "right 0.65625rem top 51.5%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "0.8125rem",
            cursor: "pointer",
        },
        chevronbackgroundLightDark: {
            backgroundImage: `url('${Settings.images.path}/svg/chevron-light-grey.svg')`,
            padding: props.inputPadding || "0.71875rem 1.8rem 0.71875rem 0",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
        },
        chevronbackgroundDark: {
            backgroundImage: `url('${Settings.images.path}/svg/chevron-bottom-grey.svg')`,
        },
        loading: {
            backgroundImage: `url('${Settings.images.path}/svg/loading-dots-grey.svg')`,
            padding: props.inputPadding || "0.71875rem 1.8rem 0.71875rem 0",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            backgroundSize: "0.8125rem",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right 0.65625rem top 51.5%",
        },
        placeholderPrefixIcon: {
            backgroundImage: `url('${Settings.images.path}/svg/${props.placeholderPrefixIcon}')`,
            backgroundPosition: "left 0 top 51.5%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "0.8125rem",
            paddingLeft: "1.2rem",
        },
        placeholderSuffixIcon: {
            backgroundImage: `url('${Settings.images.path}/svg/${props.placeholderSuffixIcon}')`,
            backgroundPosition: "right 0 top 51.5%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "1rem",
            paddingRight: "1.2rem",
        },
    };

    let prefixer = new Prefixer();
    styles = prefixer.prefix(styles);

    return styles;
}
