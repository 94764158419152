import React from "react";

const RenderPosition = () => {
    return (
        <div
            className="meta-numbered-record-table--position"
            style={{ background: "#787878" }}
        >
            -
        </div>
    );
};

export default RenderPosition;
