import { Box } from "@mui/material";
import Chip from "@mui/material/Chip";
import { isEmpty } from "lodash";
import {
    Controller,
    useFieldArray,
    useForm,
    useFormContext,
} from "react-hook-form";
import { RED_COLOR } from "../../../../features/TaskManager/types/constants";
import { CoreFormPath } from "../../types";
import { CoreForm } from "../CoreForm";
import { CoreFormChipArray } from "../styled/CoreForm.chip";
import { CoreInputText, CoreInputTextProps } from "./CoreInput.text";

type FormData = {
    inputValue: string;
};
export function CoreInputTextWithChips<T>({
    coreInputTextLabelProps = {},
    path: arrayFieldPath,
}: {
    coreInputTextLabelProps: Pick<
        CoreInputTextProps<T>,
        "labelProps" | "placeholder" | "helperText"
    >;
    path?: CoreFormPath<T>["path"];
}) {
    const form = useFormContext();
    const { remove, append } = useFieldArray({
        control: form.control,
        name: arrayFieldPath,
    });

    const { control, handleSubmit, setValue } = useForm<FormData>({
        defaultValues: {
            inputValue: "",
        },
    });

    const inputValuePath: CoreFormPath<FormData>["path"] = "inputValue";

    const onSubmit = (data: FormData) => {
        const trimmedInputValue = data.inputValue.trim();
        if (!isEmpty(trimmedInputValue)) {
            append(trimmedInputValue); // Use append to add a new chip
            setValue(inputValuePath, "");
        }
    };

    const handleChipDelete = (index: number) => () => {
        remove(index); // Use remove to delete a chip at the specified index
    };

    return (
        <Box>
            <CoreForm onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name={inputValuePath}
                    control={control}
                    render={({ field }) => (
                        <CoreInputText<FormData>
                            registerDisabled
                            path={inputValuePath}
                            {...coreInputTextLabelProps}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    event.preventDefault();
                                    handleSubmit(onSubmit)();
                                }
                            }}
                            {...field}
                            onBlur={(event) => {
                                // Should be added at the end because
                                // field also contains onBlur handler
                                event.preventDefault();

                                // This is needed to trigger the validation
                                // The TS error is a false positive
                                field.onBlur(event);

                                handleSubmit(onSubmit)();
                            }}
                            sx={{
                                "& .MuiFormHelperText-root": {
                                    marginLeft: 0,
                                    color: RED_COLOR,
                                    display: isEmpty(field.value)
                                        ? "none"
                                        : "block",
                                },
                            }}
                        />
                    )}
                />
            </CoreForm>
            <CoreFormChipArray>
                {(form.getValues(arrayFieldPath) || [])?.map((field, index) => (
                    <Chip
                        key={field}
                        label={field}
                        onDelete={handleChipDelete(index)}
                        sx={{
                            marginRight: "0.3125rem",
                            marginBottom: "0.3125rem",
                        }}
                    />
                ))}
            </CoreFormChipArray>
        </Box>
    );
}

CoreInputTextWithChips.defaultProps = {
    path: "",
};
