import React from "react";
import _ from "lodash";
import "./styles/_meta_checkbox.scss";

export default class Checkbox extends React.Component {
    constructor(props) {
        super(props);

        /**
         * @var object
         */
        this.state = {
            checked: this.props.checked || false,
        };
    }

    componentWillMount() {
        this.id = `toggle_${Math.random().toString().replace(/0\./, "")}`;
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.checked !== this.props.checked &&
            this.props.checked !== this.state.checked &&
            typeof prevProps.checked == "boolean"
        ) {
            this.setState({
                checked: this.props.checked,
            });
        }
    }

    handleChange(e) {
        const { handleChange, disabled } = this.props;

        const { checked } = this.state;

        e.stopPropagation();

        if (disabled) {
            return;
        }

        const value = !checked;

        this.setState(
            {
                checked: value,
            },
            () => {
                if (typeof handleChange == "function") {
                    handleChange(value);
                }
            },
        );
    }

    render() {
        const {
            label,
            name,
            className,
            style = {},
            disabled,
            inputStyles = {},
            customCheckbox = true,
            customCheckboxClass = "",
            inputClassName,
            useDash,
        } = this.props;

        const { checked } = this.state;

        const dash = useDash && !disabled && !checked;

        return (
            <div
                className={`meta-checkbox meta-custom-checkbox--container ${className}`}
                style={style}
            >
                <input
                    type="checkbox"
                    name={name}
                    checked={checked || dash}
                    id={this.id}
                    disabled={disabled}
                    onChange={this.handleChange.bind(this)}
                    style={inputStyles}
                    className={inputClassName}
                />

                {customCheckbox && (
                    <span
                        className={`
							custom-checkbox meta-custom-checkbox 
							${customCheckboxClass} 
							${dash ? "use-dash" : ""}
							${disabled ? "disabled-checkbox" : ""} 
						`}
                        onClick={
                            disabled ? () => {} : this.handleChange.bind(this)
                        }
                    ></span>
                )}

                {label && <label htmlFor={this.id}>{label}</label>}
            </div>
        );
    }
}
