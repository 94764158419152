import React from 'react'
import _ from 'lodash'
import { observer } from "mobx-react";
import { useStores } from "../store/rootStore";
import StyledTab from "../../../../../core/Tabs/Tab.styled";
import StyledTabs from "../../../../../core/Tabs/Tabs.styled";
import { TabInterface } from "../../../../../core/Tabs/interfaces";
import { classPrefix } from '../utils/constants'

const TabsView = observer(() => {
    const { 
        rootStore,
        navigationStore,
        validationStore
    } = useStores()

    return (
        <StyledTabs
            value={navigationStore.activeMenuIndex}
            onChange={(event: React.SyntheticEvent, newTabIndex: number) => navigationStore.setActiveMenuIndex(newTabIndex)}
        >
            {navigationStore.formMenu?.map((tab: TabInterface) => {
                const error: boolean = (!rootStore.getActiveContributorStore()?.fetching && rootStore.getActiveContributorStore()?.showValidationErrors)
                    ?  validationStore.getErrorSections()?.includes(tab.key)
                    : false

                return (
                    <StyledTab
                        {...tab}
                        disableFocusRipple={true}
                        disableRipple={true}
                        data-testid={`${classPrefix}-tab-${tab.key}`}
                        sx={{
                            margin: "0 0.3125rem 0.5rem 0"
                        }}
                        style={!error ? {} : {
                            color: "#ff0000"
                        }}
                    />
                )
            })}
        </StyledTabs>
    )
})

export default TabsView