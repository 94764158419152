import React from "react";
import MainAssetComponent from "./components/main";
import { AssetBuilderContextProvider } from "./state/context";
import Boilerplate from "../../RecordBody/views/Boilerplate";
import AssetInterface from "./components/asset/interfaces/AssetInterface";

type AssetsBuilderProps = {
    assets: Array<AssetInterface>;
    handleFormChange: Function;
    disabled: boolean;
    category: string;
};

const AssetsBuilder: React.FC<AssetsBuilderProps> = (props) => {
    return (
        <AssetBuilderContextProvider
            assets={props.assets}
            handleFormChange={props.handleFormChange}
            disabled={props.disabled}
            category={props.category}
        >
            <Boilerplate
                title={"Related Assets & Placeholders"}
                className={"meta-record-related-assets"}
                displayTitle={false}
            >
                <MainAssetComponent />
            </Boilerplate>
        </AssetBuilderContextProvider>
    );
};

export default AssetsBuilder;
