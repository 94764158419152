export const recordSave = {
    savingRecord: false,
    loadingId: false,
    notification: {},
    newRecord: {},
    originalNewRecord: {},
    unsavedChanges: false,
    activeRecord: false,
    editRecord: false,
    urlComponents: {},
    recordData: {},
    originalRecordData: {},
    collectionTitle: {},
};
