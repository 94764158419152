import { CoreFormPath } from "../../../../../core/Form/types";
import { C255 } from "../../../config/clients/viacom/validation/constants";
import { TvShowVT } from "../../../config/types";
import { AutocompleteRecords } from "./smart/AutocompleteRecords";
import { Categories } from "./types/types";

export const Title = () => {
    const path: CoreFormPath<TvShowVT>["path"] = "title";
    const disabled = false;

    return (
        <AutocompleteRecords
            disabled={disabled}
            labelProps={{
                text: "Title",
                isRequired: true,
                isDisabled: disabled,
            }}
            maxLength={C255}
            path={path}
            headerText="Existing TV Shows"
            disableEmptyCard
            category={Categories.Show}
        />
    );
};
