import { observer } from "mobx-react";
import { CoreSelect } from "../../../../../../core/Form/components/inputs/Select/components/CoreSelect";
import { AdditionalVT } from "../../../../config/types";
import { useChannelOfOriginOptions } from "../../../../hooks/useChannelOfOriginOptions";
import { HideFormConditionally } from "./HideFormConditionally";

export const ChannelOfOrigin = observer(() => {
    const options = useChannelOfOriginOptions();
    return (
        <HideFormConditionally>
            <CoreSelect<AdditionalVT>
                config={{
                    path: "other.channelOfOrigin",
                    labelProps: {
                        text: "Channel Of Origin",
                        isRequired: false,
                    },
                    options,
                    enableSearchbox: true,
                    multiple: false,
                }}
            />
        </HideFormConditionally>
    );
});
