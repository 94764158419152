import HandleUserGroupUpdateRequest from "./HandleUserGroupUpdateRequest";

const HandleUserGroupUpdate = (props) => {
    const { state = {}, key, value } = props;

    const { recordData = {} } = state;

    let userGroup = { ...recordData };
    userGroup[key] = value;

    HandleUserGroupUpdateRequest({
        state,
        userGroup,
    });
};

export default HandleUserGroupUpdate;
