import { UserInterface, GetUserRoleInterface, GetUserPermissionsInterface } from './interfaces'
import UsersConfig from '../../config/users'
import {
    PermissionInterface,
    RoleInterface,
    PolicyInterface,
} from "../../features/UserRoleManagement/interfaces";

export function getUserRole({ cmsData }: GetUserRoleInterface) {
    const user: UserInterface = cmsData?.user || {};
    return user?.role;
}

export function getUserPermissions({
    roles,
    cmsData,
}: GetUserPermissionsInterface): PermissionInterface[] {
    const userRoleName = getUserRole({ cmsData });
    const userRole: RoleInterface | any = roles?.find((item: RoleInterface) => item.role == userRoleName) || {};
    const policies: PolicyInterface[] = userRole?.policies || {};

    let permissions: Array<any> = policies?.map((item: any) => item.permissions);

    return permissions;
}

export function isFabricUserEmailCheck(user: UserInterface): boolean {
    return UsersConfig.fabricEmailDomains.includes(user.email.split('@')[1])
}


