const SeasonLabel = (props) => {
    const {
        original_title = false,
        season_number = "",
        number_of_episodes = 0,
        seasonLabel = "Season",
        episodesLabel = "Episodes",
        divider = " • ",
    } = props;

    let title = original_title
        ? original_title
        : seasonLabel + " " + season_number;

    return title + divider + number_of_episodes + " " + episodesLabel;
};

export default SeasonLabel;
