import { Typography } from "@mui/material";
import { PropsWithChildren } from "react";

export const CoreFormSectionLabel = ({ children }: PropsWithChildren) => {
    return (
        <Typography
            sx={{
                color: "rgba(47, 47, 47, 0.87)",
                fontSize: "1rem",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "150%",
            }}
        >
            {children}
        </Typography>
    );
};
