import React from "react";
import _ from "lodash";
import styles from "../styles";
import Input from "../../Input/basic";
import isIndexInArray from "../../Helpers/isIndexInArray";
import update from "react-addons-update";
import Index from "./Index";
import deepcopy from "deepcopy";
import { diff } from "deep-object-diff";
import Config from "../../Clients/Config/index";
import DynamicInputs from "../../Input/DynamicInputs";

export default class EpisodeNumbering extends Index {
    constructor(props) {
        super(props);

        let data = {};
        if (typeof this.props.data == "object") {
            data = this.props.data;
        }

        /**
         * @var object
         */
        this.copy = this.props.copy || {};

        /**
         * @var object
         */
        this.styles = styles(this.props);

        /**
         * @var object
         */
        this.state = {
            data,
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    shouldComponentUpdate(nextProps, nextState) {
        const nextPropsData = nextProps.data || {};
        const thisPropsData = this.props.data || {};
        const difference = diff(nextPropsData, thisPropsData);

        if (!_.isEmpty(difference)) {
            return true;
        }

        if (!_.isEqual(nextProps.disabled, this.props.disabled)) {
            return true;
        }

        if (!_.isEqual(nextProps.validationKeys, this.props.validationKeys)) {
            return true;
        }

        if (!_.isEqual(nextState, this.state)) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props.data, prevProps.data)) {
            if (typeof this.props.data == "object") {
                this.setStateValue("data", this.props.data);
            }
        }
    }

    handleChange(name, value) {
        const data = this.state.data || {};
        let stateData = deepcopy(data);
        let result = { ...stateData };

        if (typeof name == "string") {
            result[name] = value;

            const newData = update(this.state.data, { $set: result });

            this.setState(
                {
                    data: newData,
                },
                () => {
                    if (typeof this.props.handleFormChange == "function") {
                        this.props.handleFormChange(result);
                    }
                },
            );
        }
    }

    fieldTitle(name) {
        let result = false;

        let configs = Config.getNumberingViewConfig();
        if (typeof configs == "object" && configs.hasOwnProperty(name)) {
            result = configs[name].label;
        }

        return result;
    }

    renderNoneAdded() {
        return false;
    }

    renderContent() {
        const data = deepcopy(this.state.data || {});

        const {
            category,
            disabled,
            validationKeys,
            clientDataStructure = {},
        } = this.props;

        const { episodeNumbering } = clientDataStructure;

        return (
            <div
                className="episode-numbering-dynamic"
                style={{ ...this.styles.flexMarginContainer }}
            >
                {!_.isEmpty(episodeNumbering) && (
                    <DynamicInputs
                        options={episodeNumbering}
                        handleChange={(key, value) =>
                            this.handleChange(key, value)
                        }
                        styles={{ ...this.styles.flexMarginItem }}
                        data={data}
                        disabled={disabled}
                        category={category}
                        validationKeys={validationKeys}
                    />
                )}
            </div>
        );
    }
}
