import {CreditInterface, CreditListInterface, CreditRankMap, DataTablePayload} from "../../credits.interface";
import generateTableRows from "./generateTableRows";

export default function getInactiveCredits(
    credits: CreditInterface[],
    activeCreditList: CreditListInterface,
    onContributorEdit: (data: DataTablePayload) => void,
    script: string,
    defaultScript: string,
): DataTablePayload[] {
    const inActiveCredits: CreditInterface[] =
        credits?.filter((credit: CreditInterface) =>
                !credit.deleted && !credit.credit_lists.some(
                    (creditList: CreditRankMap) => creditList.code === activeCreditList.code && !creditList.deleted
                )
        )
            ?.sort((a: CreditInterface, b: CreditInterface) => {
                return (a.role_name as string).localeCompare(b.role_name as string);
            });

    return generateTableRows(
        inActiveCredits,
        (row: DataTablePayload) => onContributorEdit(row),
        script,
        defaultScript
    )
}