import React from "react";
import styles from "./styles";
import ButtonV1 from "../v1";
import _ from "lodash";

export default class ButtonV2 extends ButtonV1 {
    setStyles() {
        this.styles = styles(this.props);
    }
}
