import _ from "lodash";

const HanldeRecordCancel = (props) => {
    const { state = {} } = props;

    const { set } = state;

    set({
        newRecord: {},
        editRecord: false,
        activeRecord: {},
        recordData: {},
    });
};

export default HanldeRecordCancel;
