import {
    securityLevelOption,
    recordStatusOption,
    graceNoteSyndicationOption,
    catalogOption,
} from "../sectionOptions/recordProperties";

import { crudKey, defaultCrudValue } from "../accessOptions"

export const recordPropertiesEmptyPolicy = {
    [catalogOption.value]: {
        [crudKey]: defaultCrudValue
    },
    [securityLevelOption.value]: {
        [crudKey]: defaultCrudValue
    },
    [recordStatusOption.value]: {
        [crudKey]: defaultCrudValue
    },
    [graceNoteSyndicationOption.value]: {
        [crudKey]: defaultCrudValue
    }
}