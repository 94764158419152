import SendRequest from "../../../components/SendRequest";
import GetRecordData from "../GetRecordData";

const HandleCollectionTitleQueryChange = (props) => {
    const { options = {}, state = {} } = props;

    const { searchQuery, searchCategory } = options;

    const { cmsData = {}, collectionTitle = {} } = state;

    const { category } = collectionTitle;

    const record = GetRecordData({
        state,
    });

    const { linkage = [] } = record;

    if (!searchQuery) {
        state.setCollectionTitleKey("selectedTitle", {});
        state.setCollectionTitleKey("selectedSeason", "");
        state.setCollectionTitleKey("episodes", []);
    }

    if (searchQuery && cmsData && typeof category == "string") {
        const { apiUrls } = cmsData;

        let url = apiUrls.linkageSearchUrl || "";
        url = url
            .replace("{searchTerm}", searchQuery)
            .replace("{category}", searchCategory.toLowerCase());

        const options = {
            url,
            method: "GET",
        };

        const HandleError = (error) => {
            if (error) {
                console.log(error);
            }

            state.setCollectionTitleKey("error", "Could not fetch titles");
        };

        SendRequest(
            options,
            (response) => {
                const { data, status, message } = response;

                if (status == 200) {
                    if (Array.isArray(data)) {
                        state.setCollectionTitleKey("searchDataList", data);
                    }

                    return;
                }

                HandleError(message);
            },
            (e) => {
                HandleError(e);
            },
        );
    }
};

export default HandleCollectionTitleQueryChange;
