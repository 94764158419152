import { SET_NEW_RECORD } from "../actions/action_types";
import { defaultNewRecord } from "../initialState";

export default function (state = { newRecord: defaultNewRecord }, action) {
    switch (action.type) {
        case SET_NEW_RECORD:
            return Object.assign({}, state, {
                newRecord: action.newRecord,
            });
    }

    return state;
}
