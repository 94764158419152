import _ from "lodash";

const CheckIfDataExists = (props) => {
    const { item = {}, data = {} } = props;

    const { checkIfDataExists = {} } = item;

    const {
        active = false,
        fields = [],
        errorMessage = "",
    } = checkIfDataExists;

    let result = {
        allowCopy: true,
        message: "",
    };

    if (active) {
        fields.map((field) => {
            if (
                !(Object.keys(data).includes(field) && !_.isEmpty(data[field]))
            ) {
                result.allowCopy = false;
                result.message = errorMessage;
            }
        });
    }

    return result;
};

export default CheckIfDataExists;
