import _ from "lodash";
import clientManagedController from "./clientManagedController";
import {
    StringPriorityTransformer,
    PRIORITY_KEYS_NAME_OR_TEXT,
} from "../Transformers/StringPriorityTransformer";

export function isClientManagedRightsVersions(props) {
    const { clientFeatures = {} } = props;

    const { managedLists = [] } = clientFeatures;

    return managedLists.includes("rights_versions");
}

const RightsVersionsOptions = (props) => {
    const { options = {} } = props;

    let result = clientManagedController({
        clientManagedOptions: options.client_managed_rights_versions || [],
        fallback: _.get(options, "rights.versions", []),
        isClientManaged: isClientManagedRightsVersions(props),
    });

    return result.map((item) =>
        StringPriorityTransformer({
            item,
            priority: PRIORITY_KEYS_NAME_OR_TEXT,
        }),
    );
};

export default RightsVersionsOptions;
