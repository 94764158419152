import React, { useEffect } from 'react'
import { observer } from "mobx-react";
import { isEmpty } from "lodash"
import { useStores } from "../../store/rootStore";
import CoreDynamicForm from '../../../../../core/Form/dynamic'
import { coreDialogProps } from "../../utils/constants"
import { CoreDialog } from "../../../../../core/Dialog/CoreDialog";
import { CreditInterface } from "../../store/interfaces"
import { fieldCharacterNameLanguage } from "../../config/creditForm.config"
import { ContributorListItem } from "../../../../../core/Autocomplete/AutocompleteContributors/ContributorListItem"
import { StyledContributorListItem } from '../../styles/styles';

const UpdateCreditForm = observer(() => {
    const { 
        creditFormUpdateStore,
        creditFormValidationStore,
        activeCreditsStore,
        contributorsStore,
        rootStore
    } = useStores();

    useEffect(() => {
        creditFormUpdateStore.initialize()

        return () => {
            creditFormUpdateStore.reset()
            creditFormValidationStore.reset()
        }
    }, [])

    if (isEmpty(creditFormUpdateStore.coreFormFields) || isEmpty(creditFormUpdateStore.credit)) {
        return <></>
    }

    return (
        <CoreDialog
            {...coreDialogProps}
            open={true}
            setOpen={(open) => {}}
            title="Edit Credit"
            errorAlerts={creditFormValidationStore.errorAlerts}
            buttons={{
                discard: {
                    label: "Cancel",
                    handleOnDiscard: () => {
                        activeCreditsStore.setSelectedId(null)
                        rootStore.setShowCreditUpdateForm(false)

                        creditFormUpdateStore.reset()
                    }
                },
                submit: {
                    handleOnSubmit: () => creditFormUpdateStore.handleSubmit(),
                    label: "Submit",
                    disabled: false
                }
            }}>

            <>
                <StyledContributorListItem>
                    <ContributorListItem 
                        {...contributorsStore.getContributorById(creditFormUpdateStore.credit?.contributor_id)}
                    />
                </StyledContributorListItem>

                <CoreDynamicForm 
                    fields={creditFormUpdateStore.coreFormFields} 
                    data={creditFormUpdateStore.credit}
                    onChange={(values: CreditInterface, item: any) => creditFormUpdateStore.handleChange(values, item)}
                    defaultValues={creditFormUpdateStore.creditOriginal}
                    handleErrors={(errors: any) => creditFormValidationStore.setErrors(errors)}
                    key={`credit-form-${creditFormUpdateStore.credit?.[fieldCharacterNameLanguage.key]}-${creditFormUpdateStore.changeId}`}
                    stackStyles={{
                        padding: 0
                    }}
                    fieldJquery={creditFormUpdateStore.getFieldJquery()}
                    loading={creditFormUpdateStore.fetching}
                    disabled={creditFormUpdateStore.fetching}
                />
            </>
        </CoreDialog>
    )
})

export default UpdateCreditForm