import UnsavedChanges from "./Notifications/UnsavedChanges";
import { recordSave } from "./DefaultCollectionStateValues";

const HandleSearchFiltersChange = (props) => {
    const { item, state = {} } = props;

    const {
        set,
        unsavedChanges,
        applySearchFilters,
        handleRecentActivitySearch,
    } = state;

    if (unsavedChanges) {
        let notification = UnsavedChanges({});

        notification.onClick = () => {
            applySearchFilters(item);
            set({ notification: {} });
        };

        notification.confirm = () => {
            applySearchFilters(item);
            set(recordSave, () => {
                handleRecentActivitySearch("collection");
            });
        };

        set({ notification });
    } else {
        applySearchFilters(item);
    }
};

export default HandleSearchFiltersChange;
