import { z } from "zod";
import FieldInterface from '../../interfaces/Field.interface'

export const SelectMultipleInputRule = (field: FieldInterface) => {
    
    let arraySchema = null
    let arrayOptionSchema = z.string()


    if (field.type.select?.growing) {
        arrayOptionSchema = z.object({ text: z.string(), value: z.string() })
    }

    if (field.validation?.rangeLimit?.enabled) {
        if (field.validation.rangeLimit.min) {
            arraySchema = arrayOptionSchema.array().min(field.validation?.rangeLimit.min, "Minimum selections are not reached")
        }

        if (field.validation?.rangeLimit?.max) {
            arraySchema = arrayOptionSchema.array().max(field.validation?.rangeLimit.max, "Maximum selections reached")
        }
    }

    if (field.validation?.required?.enabled) {
        arraySchema = arrayOptionSchema.array().nonempty({
            message: "Please select at least 1 option"
        })
    }

    if (arraySchema) {
        return z.object({
            [field.key]: arraySchema
        })
    }

    return z.object({})
}