import React from "react";
import _ from "lodash"
import { observer } from "mobx-react";
import Settings from "../../../../../components/Settings";
import { classPrefix } from "../../utils/constants";
import { StyledMeasurementCard } from "../styles/styles";
import { useStores } from "../store/rootStore";
import { MeasurementSource } from "../interfaces"

interface RenderSecondaryValueInterface {
    text: string
}

const RenderSecondaryValue = (props: RenderSecondaryValueInterface) => {
    return (
        <span 
            className="rating-secondary-value"
            data-testid={`measurement-card__rating-secondary-value`}>
            
            {props.text}
        </span>
    )
}

const RenderSecondaryValues = ({ measurementId, fetching, rating, text }) => {
    if (!measurementId) {
        return (
            <RenderSecondaryValue text={`No valid ${text} added`}/>
        )
    }

    if (fetching) {
        return (
            <RenderSecondaryValue text={`Fetching rating...`}/>
        )
    }

    if ((!fetching && !rating)) {
        return (
            <RenderSecondaryValue text={`No rating found`}/>
        )
    }

    return <></>
}

const MeasurementCardView: React.FC<MeasurementSource> = observer((props) => {
    const {
        logo = '',
        title = '',
        ratingIcon = '',
        id = {},
        ratingName = ''
    } = props

    const {
        path = '',
        text = ''
    } = id

    const { 
        rootStore,
        integrationsStore
    } = useStores()

    const contributor = rootStore.getActiveContributorStore()?.contributor
    const measurementId: string = _.get(contributor, path, '')

    const rating = integrationsStore.ratings[ratingName]
    const fetching = integrationsStore.fetchingKeys[ratingName]

    return (
       <StyledMeasurementCard data-testid={`${classPrefix}-measurement-view-card`}>
            <div className="measurement-card__logo">
                <img 
                    src={`${Settings.images.path}/logos/${logo}`} 
                    className="logo" 
                    data-testid="measurement-card__logo"
                />
            </div>

            <div className="measurement-card__title">
                {title}
            </div>

            <div className="measurement-card__rating">
                {(rating && measurementId) &&
                    <>
                        <span className="rating-icon">
                            <img 
                                src={`${Settings.images.path}/svg/${ratingIcon}`} 
                                className="icon" 
                                data-testid="measurement-card__rating-icon"
                            />
                        </span>

                        <span className="rating-value">
                            {rating}
                        </span>
                    </>
                }

                <RenderSecondaryValues 
                    measurementId={measurementId}
                    fetching={fetching}
                    rating={rating}
                    text={text}
                />
            </div>
       </StyledMeasurementCard>
    )
})

export default MeasurementCardView