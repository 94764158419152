import React from "react";
import _ from "lodash";
import "./styles/_styles.scss";
import "./styles/_meta_record.scss";

export default class MetaMenuItems extends React.Component {
    constructor(props) {
        super(props);

        /**
         * @var object
         */
        this.state = {
            menuActive: this.props.menuActive || "",
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.menuActive !== this.props.menuActive) {
            if (this.props.menuActive !== this.state.menuActive) {
                this.setState({
                    menuActive: this.props.menuActive,
                });
            }
        }
    }

    handleOnClick(menuActive) {
        this.setState(
            {
                menuActive,
            },
            () => {
                if (typeof this.props.handleOnClick == "function") {
                    this.props.handleOnClick(menuActive);
                }
            },
        );
    }

    render() {
        const { menu, styleSheet } = this.props;

        if (menu) {
            if (!_.isEmpty(menu)) {
                return (
                    <ul
                        className={`meta-menu-items ${
                            styleSheet ? styleSheet : ""
                        }`}
                    >
                        {menu.map((item, index) => {
                            const { text, name, count, disabled } = item;

                            if (text && name) {
                                return (
                                    <li
                                        className={`
											${this.state.menuActive == name ? "active" : ""} 
											${styleSheet ? styleSheet : ""}
											${name}
											${disabled ? "menu-item-disabled" : ""}
										`}
                                        key={index}
                                        style={Object.assign(
                                            {},
                                            disabled && {
                                                color: "rgba(106, 106, 106, 0.5)",
                                            },
                                        )}
                                        onClick={
                                            !disabled
                                                ? this.handleOnClick.bind(
                                                      this,
                                                      name,
                                                  )
                                                : () => {}
                                        }
                                    >
                                        {text}
                                        {Number.isInteger(count) && (
                                            <span className="meta-menu--count">
                                                {count}
                                            </span>
                                        )}
                                    </li>
                                );
                            }
                        })}
                    </ul>
                );
            }
        }

        return false;
    }
}
