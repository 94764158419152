import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

interface Props {
    className: string
}

const iconComponent: React.FC<Props> = ({ className }) => {
    return (
        <ExpandMoreIcon className={className} />
    )
}

export default iconComponent