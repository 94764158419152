import React from "react";
import ExtraInfo from "./ExtraInfo";
import ExpandableRow from "../../../ExpandableRow";

const TechnicalMetadataSection = (props) => {
    const header = <div className="title">Technical Metadata</div>;
    const body = (
        <div className="meta-asset-compliance-body">
            <ExtraInfo {...props} />
        </div>
    );

    return (
        <ExpandableRow
            className="meta-expandable-rows--compliance-inner"
            header={header}
            body={body}
            headerStyles={{
                border: "none",
            }}
            noHeaderBackground={true}
            bodyStyles={{ padding: 0 }}
            defaultOpen={true}
        />
    );
};

export default TechnicalMetadataSection;
