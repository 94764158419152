
import React from "react";
import { useStores } from '../../store/rootStore'
import {
    CoreInputPhoneNumber,
    CoreInputPhoneNumberType
} from "../../../components/inputs/CoreInputPhoneNumber";
import Input from "../../interfaces/Input.interface";
import { CoreFormType } from "../../../types";
import { observer } from "mobx-react";

export const PhoneNumber = observer(({ field }: Input) => {

    const { formStore } = useStores()

    const phoneNumberPath: CoreFormType<CoreInputPhoneNumberType> = "phone_number";
    const countryCodePath: CoreFormType<CoreInputPhoneNumberType> = "phone_number.country_code";
    const phoneNumberNumberPath: CoreFormType<CoreInputPhoneNumberType> = "phone_number.number";

    return (
        <CoreInputPhoneNumber<CoreInputPhoneNumberType>
            disabled={!!field.validation?.readOnly?.enabled || formStore.disabled}
            labelProps={{
                text: "Phone Number",
            }}
            phoneNumberPath={phoneNumberPath}
            countryCodePath={countryCodePath}
            phoneNumberNumberPath={phoneNumberNumberPath}
        />
    )
})