import React from 'react'
import _ from 'lodash';
import { makeObservable, extendObservable, action } from 'mobx'
import { GridRowParams } from "@mui/x-data-grid";
import { RootStore } from './rootStore'
import { inactiveCreditsStoreInitialState } from "./state"
import { CoreTableConfig } from '../../../../core/Table/types';
import { CreditInterface, CreditRowInterface } from './interfaces';
import { generateTableRows } from '../utils/CreditsTable/rows';

export class InactiveCreditsStore {
    rootStore: RootStore;
    tableConfig: CoreTableConfig;
    inactiveCredits: CreditInterface[];
    inactiveTableCredits: CreditRowInterface[];
    selected: string[]

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            setTableConfig: action,
            setInactiveCredits: action,
            setInactiveTableCredits: action,
            setSelected: action,

            handleSetConfig: action,
            handleAddCreditToCreditList: action,
            handleAddCreditsInBulkToCreditList: action,
            handleDeleteCreditsInBulk: action,
        });

        extendObservable(this, inactiveCreditsStoreInitialState);
    }

    initialize() {
        this.reset()
    }

    reset() {
        Object.keys(inactiveCreditsStoreInitialState).forEach(
            (key) => (this[key] = inactiveCreditsStoreInitialState[key]),
        );
    }

    setInactiveCredits(inactiveCredits: CreditInterface[]): void {
        this.inactiveCredits = inactiveCredits
    }

    setTableConfig(tableConfig: CoreTableConfig): void {
        this.tableConfig = tableConfig
    }
    
    setInactiveTableCredits(inactiveTableCredits: CreditRowInterface[]): void {
        this.inactiveTableCredits = inactiveTableCredits
    }

    setSelected(selected: string[]): void {
        this.selected = selected
    }

    getInactiveCredits(): CreditInterface[] {
        const credits: CreditInterface[] = this.rootStore.creditsStore.getCredits()

        return credits?.filter((credit: CreditInterface) => !this.rootStore.creditsStore.isCreditInActiveCreditList(credit)) || [];
    }
    
    getInactiveTableCreditById(id: string): CreditRowInterface {
        return this.inactiveTableCredits.find((credit: CreditRowInterface) => credit.id === id)
    }

    handleSetConfig(): void {
        this.setTableConfig({
            variant: "inactive",
            state: 'edit',
            emptyFallback: "No Inactive Credits",
            tableName: "InactiveCredits",
            leftFooterText: this.rootStore.creditListsStore.getCreditListFooterText(),
            resetCounter: 0
        })
    }

    handleSetCredits(): void {
        const credits: CreditInterface[] = this.getInactiveCredits()
        this.setInactiveCredits(credits)

        let tableCredits: CreditRowInterface[] = generateTableRows(credits, this.rootStore.contributorsStore.contributors, this.rootStore.optionsStore.roles, this.rootStore.optionsStore.languageCode)

        this.setInactiveTableCredits(tableCredits)
    }

    handleAddCreditToCreditList(params: GridRowParams): void {
        const credit: CreditInterface = this.rootStore.creditsStore.getCredit(
            this.rootStore.creditsStore.getTransformedTableCredit(params.row)
        )

        if (!credit) {
            return this.rootStore.handleDispatchError("Failed to add credit to credit list. Credit not found.")
        }

        this.rootStore.creditListsStore.handleAddCreditToCreditList(credit)
        this.rootStore.handleChange()
    
        this.handleSetCredits()
        this.rootStore.activeCreditsStore.handleSetCredits()
    }

    handleAddCreditsInBulkToCreditList(): void {
        this.selected?.forEach((id: string) => {
            const tableCredit: CreditRowInterface = this.getInactiveTableCreditById(id)

            if (tableCredit) {
                const credit: CreditInterface = this.rootStore.creditsStore.getCredit(
                    this.rootStore.creditsStore.getTransformedTableCredit(tableCredit)
                )

                if (!credit) {
                    return this.rootStore.handleDispatchError(`Failed to add ${tableCredit.characterName || 'credit'} to credit list. Credit not found.`)
                }

                this.rootStore.creditListsStore.handleAddCreditToCreditList(credit)
            }
        })

        this.rootStore.activeCreditsStore.handleSetCredits()
        this.rootStore.handleChange()
        
        this.handleSetCredits()
        this.setSelected([])
    }   

    handleDeleteCredit(params: GridRowParams): void {
        this.rootStore.creditsStore.handleDeleteCredit(params.row)
        this.handleSetCredits()

        this.rootStore.handleChange()
    }

    handleDeleteCreditsInBulk(): void {
        this.selected?.forEach((id: string) => {
            const tableCredit: CreditRowInterface = this.getInactiveTableCreditById(id)

            if (tableCredit) {
                this.rootStore.creditsStore.handleDeleteCredit(tableCredit)
            }
        })

        this.handleSetCredits()

        this.rootStore.handleChange()
    }
}
