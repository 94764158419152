import React from 'react'
import Input from "../../interfaces/Input.interface";
import { CoreRadioStack } from "../../../components/inputs/Radio/CoreRadioStack";
import { observer } from "mobx-react";
import { useStores } from '../../store/rootStore'

export const BooleanRadio = observer(({ field }: Input) => {
    const { formStore } = useStores()

    return (
        <div data-testid={"fabric-dynamic-form-boolean-radio-input"}>
            <CoreRadioStack
                path={field.key}
                labelProps={{
                    text: field.label,
                }}
                disabled={field.validation?.readOnly?.enabled || formStore.disabled}
                variant="condensed"
                options={[
                    {
                        label: field.type.boolean.trueLabel,
                        value: true,
                        sx: { fontSize: '1 rem' },
                    },
                    {
                        label: field.type.boolean.falseLabel,
                        value: false,
                        sx: { fontSize: '1 rem' },
                    }
                ]}
            />
        </div>
    )
})