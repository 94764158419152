import { accessTypeCreate, accessTypeDelete } from "../sectionOptions"
import { OptionInterface } from "../../interfaces"

export const recordPropertiesOption: OptionInterface = {
    name: "Record Properties",
    value: "recordProperties",
    path: "recordProperties",
    children: [],
};

export const recordStatusOption: OptionInterface = {
    name: "Record Status",
    value: "status",
    path: "recordProperties.status",
    children: [],
    disabledAccessTypes: [accessTypeCreate, accessTypeDelete],
};

export const catalogOption: OptionInterface = {
    name: "Catalog",
    value: "catalog",
    path: "recordProperties.catalog",
    children: [],
    disabledAccessTypes: [accessTypeCreate, accessTypeDelete],
};

export const securityLevelOption: OptionInterface = {
    name: "Security Level",
    value: "securityLevel",
    path: "recordProperties.securityLevel",
    children: [],
    disabledAccessTypes: [accessTypeCreate, accessTypeDelete],
};

export const graceNoteSyndicationOption: OptionInterface = {
    name: "Gracenote Syndication",
    value: "gracenoteSyndication",
    path: "recordProperties.gracenoteSyndication",
    children: [],
    disabledAccessTypes: [accessTypeCreate, accessTypeDelete],
};