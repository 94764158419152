import React, { Fragment } from "react";
import _ from "lodash";
import InputCounter from "../../Input/basicCounter";
import InputTextArea from "../../Input/textarea";
import TitleTypeSwitches from "../../TitleTypeSwitches";
import StarComment from "../../StarComment";
import TitleStatusDisplayReleaseTags from "../../TitleStatusDisplayReleaseTags";
import isIndexInArray from "../../Helpers/isIndexInArray";
import CustomSelect from "../../CustomSelect";
import ClientManagedSelect from "../../Select/ClientManagedSimpleSlim";
import AuditTooltip from "../../AuditTooltip";
import { TITLES_AND_SYNOPSES_STATUS, CLIENT_MANAGED_TITLES_AND_SYNOPSES_STATUS } from "../../ClientManagedOptions/options"
import { useStore } from "../../Global/store/store";
import { getRegionalStatusesOptions } from '../../../models/ClientManaged/regionalStatusModel'

const RegionalItem = (props) => {
    const {
        label,
        placeholder,
        name = "",
        data,
        disabled,
        maxCharacter,
        textArea = false,
        validationKeys,
        type,
        presentation_title,
        handleRegionalTitleSwitchChange,
        googleTranslateItems,
        googleTranslate,
        handleOnBlur,
        handleChange,
        handleGooleTranslateClick,
        inputKey,
        inlineCommentsAndButtons = false,
        className = "",
        commentWidth,
        displayStatusSelect = true,
        switchOptions = {},
        stateKey,
        readOnly,
        languageIso,
        commentsOptions = {},
        statusOptions
    } = props;

    const {
        comment = true,
        defaultComment = false,
        defaultCommentValue = false,
    } = commentsOptions;

    const RenderSlimSelect = () => {
        const [globalState] = useStore() || [{}];

        const clientFeatures = globalState?.cmsData?.clientFeatures || {};
        const apiUrls = globalState?.cmsData?.apiUrls || {};

        if (!displayStatusSelect) {
            return false;
        }

        return (
            <ClientManagedSelect
                disabled={!data[inputKey]}
                value={data.status}
                data={statusOptions}
                handleChange={(value) =>
                    handleChange({
                        type,
                        key: "status",
                        value,
                    })
                }
                wrapperMargin="0 0 0 0.8rem"
                selectMargin="0"
                apiUrls={apiUrls}
                showNullOption={false}
            />
        );
    };

    const commonInputProps = {
        label,
        placeholder,
        id: name,
        value: data[inputKey],
        name,
        disabled,
        maxCharacter,
        counter: true,
        recordBasic: true,
        borderHiddenPadding: true,
        error: isIndexInArray(
            validationKeys,
            `${type}_${inputKey}_${data.locale}`,
        ),
        onBlur: handleOnBlur.bind(this, name),
        fieldKey: `${type}_${data.locale || languageIso}`,
        handleChange: (value) =>
            handleChange({
                type,
                key: inputKey,
                value,
            }),
    };

    const starCommentProps = {
        text: data.comment || (defaultComment ? defaultCommentValue : null),
        name: `${type}_comment`,
        id: `${type}_comment`,
        inputShallow: true,
        disabled: disabled || !data[inputKey],
        width: commentWidth || "100%",
        handleChange: (value) =>
            handleChange({
                type,
                key: "comment",
                value,
            }),
    };

    return (
        <div className={className}>
            <AuditTooltip
                fieldName={"type"}
                value={type}
                dataSection={props.dataSource || null}
                locale={data.locale || languageIso}
                fieldKey={`${type}_${data.locale || languageIso}`}
            >
                {textArea && (
                    <InputTextArea
                        {...commonInputProps}
                        disabled={disabled || readOnly}
                        key={stateKey}
                    />
                )}

                {!textArea && (
                    <InputCounter
                        {...commonInputProps}
                        disabled={disabled || readOnly}
                    />
                )}
            </AuditTooltip>

            {disabled && (
                <TitleStatusDisplayReleaseTags
                    data={data}
                    statusOptions={statusOptions}
                    disabled={disabled}
                />
            )}

            {!inlineCommentsAndButtons && (
                <Fragment>
                    {!disabled && (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                margin: "0.90625rem 0 0.5rem 0",
                            }}
                        >
                            <TitleTypeSwitches
                                data={data}
                                presentation_title={presentation_title}
                                customOptions={switchOptions}
                                inputKey={inputKey}
                                handleTitleChange={(item, type) =>
                                    handleRegionalTitleSwitchChange(
                                        item.checked,
                                        data,
                                        type,
                                        "titles",
                                    )
                                }
                            />

                            <div
                                style={{
                                    marginLeft: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {googleTranslate && (
                                    <CustomSelect
                                        label="Translate"
                                        disabled={!data[inputKey]}
                                        items={googleTranslateItems}
                                        handleClick={(locale) =>
                                            handleGooleTranslateClick({
                                                key: type,
                                                locale,
                                                text: data[inputKey],
                                                label,
                                            })
                                        }
                                    />
                                )}

                                <RenderSlimSelect />
                            </div>
                        </div>
                    )}

                    {comment && (
                        <StarComment
                            {...starCommentProps}
                            styles={
                                props.styles || {
                                    marginTop: "0.9375rem",
                                }
                            }
                        />
                    )}
                </Fragment>
            )}

            {inlineCommentsAndButtons && (
                <div
                    style={{
                        display: "flex",
                        marginTop: "0.9375rem",
                        alignItems: "flex-end",
                    }}
                >
                    {comment && (
                        <StarComment {...starCommentProps} styles={{}} />
                    )}

                    {!disabled && (
                        <React.Fragment>
                            {googleTranslate && (
                                <CustomSelect
                                    label="Translate"
                                    styles={{
                                        margin: "0 0 0 0.8rem",
                                        display: "inline-block",
                                    }}
                                    itemsStyles={{
                                        right: 0,
                                        left: 0,
                                    }}
                                    disabled={!data[inputKey]}
                                    items={googleTranslateItems}
                                    handleClick={handleGooleTranslateClick}
                                />
                            )}

                            <RenderSlimSelect />
                        </React.Fragment>
                    )}
                </div>
            )}
        </div>
    );
};

export default RegionalItem;
