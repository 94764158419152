import $ from "jquery";
import _ from "lodash";

const Effects = (props) => {
    const { collectionTitle = {} } = props;

    const { category, selectedTitle, selectedSeason } = collectionTitle;

    const speed = 150;

    // Category
    if (category) {
        $("div#meta-collection-titles--search").slideDown(speed);
    }

    if (!category) {
        $("div#meta-collection-titles--search").slideUp(speed);
    }

    // SelectedTitle
    if (!_.isEmpty(selectedTitle)) {
        $("div#meta-collection-titles--season-select").slideDown(speed);
        $("div#meta-collection-titles--show-specials").slideDown(speed);
    }

    if (_.isEmpty(selectedTitle)) {
        $("div#meta-collection-titles--season-select").slideUp(speed);
        $("div#meta-collection-titles--show-specials").slideUp(speed);
    }

    if (!_.isEmpty(selectedSeason)) {
        $("div#meta-collection-titles--episode-select").slideDown(speed);
    }

    if (_.isEmpty(selectedSeason)) {
        $("div#meta-collection-titles--episode-select").slideUp(speed);
    }
};

export default Effects;
