import deepcopy from "deepcopy";
import DisabledRuleActive from "./rules/disabled";
import isActiveRecordBuilder from '../RecordBuilder/IsActive'
import { ORIGINATION } from "../../models/RecordBuilder"

export default function ValidateCountryOfOrigin(result = {}, options = {}) {
    let response = deepcopy(result);

    const { data = {}, copy = {}, status, clientDataStructure = {}, clientFeatures = {} } = options;

    const {
        origination = {},
        category = false,
        status: recordStatus = ''
    } = data;

    const { type } = origination;

    if (isActiveRecordBuilder({ page: ORIGINATION,  clientFeatures}) || 
        DisabledRuleActive({
            recordStatus,
            category,
            type,
            dataStructure: clientDataStructure,
            fieldPath: "origination.country_iso",
        })
    ) {
        return response;
    }

    if (!origination || !origination.country_iso) {
        response.result = false;
        response.notification = {
            title: "Please check country of origin",
            description: "Country of origin is missing",
            status,
        };

        response.validationKeys = result.validationKeys.concat(["country_iso"]);
    }

    return response;
}
