import Prefixer from "inline-style-prefixer";

export default function styles(props = {}) {
    let styles = {
        container: {
            display: props.display || "inline-block",
            borderRadius: props.borderRadius || "1.5rem",
            background: props.background || "#afafaf",
            color: props.color || "#000000",
            border: props.border || "none",
            cursor: props.cursor || "text",
            padding: props.padding || "0.45rem 0.875rem",
            margin: props.margin || 0,
            fontSize: props.fontSize || "0.9375rem",
        },
        placeholder: {
            background: "transparent",
            color: "#c5c5c5",
            border: "0.065rem solid #c5c5c5",
        },
    };

    let prefixer = new Prefixer();
    styles = prefixer.prefix(styles);

    return styles;
}
