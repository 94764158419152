import React from "react";
import Settings from "../Settings";
import "./styles/_styles.scss";

const CheckboxTag = (props) => {
    const {
        background = "#f1f1f1",
        defaultBackground = "#f1f1f1",
        selected,
        selectedDisabled,
        leftText = "",
        centeredContent,
        styles = {},
        leftTextStyle = {},
        centeredContentStyle = {},
        rightContentStyle = {},
        selectPlaceholderStyles = {},
        handleSelection = () => {},
        className,
        clearIcon = "clear-white.svg",
        disabledBackground = "#a7d0c9",
        disabledTick = "tick-single-white.svg",
        disabledColor = "rgba(255, 255, 255, 0.75)",
    } = props;

    const selectedBackground = {
        background: background,
    };

    const disabledSelection = !selected && selectedDisabled;

    return (
        <div
            className={`meta-checkbox-tag ${
                disabledSelection ? "meta-checkbox-tag-disabled-selection" : ""
            } ${className}`}
            data-selected={selected}
            onClick={selectedDisabled ? () => {} : handleSelection.bind(this)}
            style={Object.assign(
                {},
                background && { background: defaultBackground },
                selected && selectedBackground,
                disabledSelection && {
                    background: disabledBackground,
                },
                styles && styles,
            )}
        >
            {leftText && (
                <span
                    className="meta-checkbox-tag--text"
                    style={Object.assign(
                        {},
                        leftTextStyle && leftTextStyle,
                        disabledSelection && {
                            color: disabledColor,
                        },
                    )}
                >
                    {leftText}
                </span>
            )}

            {centeredContent && (
                <div
                    className="meta-checkbox-tag--centered-content meta-checkbox-tag--text"
                    style={Object.assign(
                        {},
                        centeredContentStyle && centeredContentStyle,
                    )}
                >
                    {centeredContent}
                </div>
            )}

            <div
                className={`meta-checkbox-tag--right-content ${
                    centeredContent ? "with-centered-content" : ""
                }`}
                style={rightContentStyle}
            >
                {!selected && !selectedDisabled && (
                    <div
                        className="meta-checkbox-tag--select-placeholder"
                        style={selectPlaceholderStyles}
                    ></div>
                )}

                {selected && !selectedDisabled && clearIcon && (
                    <img
                        src={`${Settings.images.path}/svg/${clearIcon}`}
                        className="meta-checkbox-tag--deselect"
                    />
                )}

                {(disabledSelection || selectedDisabled) && (
                    <img
                        src={`${Settings.images.path}/svg/${disabledTick}`}
                        className="meta-checkbox-tag--selected-disabled"
                    />
                )}
            </div>
        </div>
    );
};

export default CheckboxTag;
