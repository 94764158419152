import { Box, Stack, Typography } from "@mui/material";
import { CoreMenuChip } from "./CoreMenu.chip";

type CoreMenuButtonContentProps = {
    selected: string[];
    name: string;
};

export function CoreMenuButtonContent({
    selected,
    name,
}: CoreMenuButtonContentProps) {
    const typographyProps = {
        component: "span",
        overflow: "hidden",
        textOverflow: "ellipsis",
        lineHeight: "1.5 !important",
    };

    return (
        <Stack direction="row" spacing={1.5} width="93%" alignItems="center" justifyContent="space-between" fontSize="1rem">
            {selected?.length === 0 && (
                <Typography {...typographyProps}>{name ? name : "Select options"}</Typography>
            )}
            {selected?.length === 1 && (
                <Typography {...typographyProps}>{name ? `${name}: ${selected[0]}` : selected[0]}</Typography>
            )}

            {selected?.length > 1 && (
                <>
                    <Box width="95%" display="flex">
                        <Typography marginRight={2} {...typographyProps}>
                            {name ? `${name}: ${selected[0]}` : selected[0]}
                        </Typography>
                    </Box>

                    {selected?.length > 1 && <CoreMenuChip array={selected} />}
                </>
            )}
        </Stack>
    );
}
