import ContentFieldInterface from "../../../Boilerplates/DoubleColumnContent/ContentField.interface";
import FieldInterface from '../../../../models/RecordBuilder/Field.interface'
import mapField from '../../../RecordBuilder/map/doubleColumnContent/mapField'
import _ from "lodash";
import CategoryTags from "../../../CategoryTags";

type Props = {
    conditionsConfiguration: any[];
    conditions: {
        [key: string]: any;
    };
    recordCategory: string;
    dateFormat?: string;
};

type ScheduleConditionType = FieldInterface & {
    type: string
    categories: string[]
}

const mapConditionsBuilderToBoilerplateConfiguration = (
    props: Props,
): ContentFieldInterface[] => {
    const { 
        conditionsConfiguration, 
        conditions, 
        recordCategory, 
        dateFormat 
    } = props;

    let fields: ContentFieldInterface[] = [];

    const categoryLongName = (): string => {
        let result = ''
        Object.keys(CategoryTags).forEach((key: string) => {
            if (CategoryTags[key].short == recordCategory) {
                result = CategoryTags[key].long
            }
        })
        
        return result
    }

    conditionsConfiguration
        .filter((field: ScheduleConditionType) => {
            return field.type == 'Conditions' && !field.archived && field.categories.includes(categoryLongName())
        })
        .forEach(field => {
            let fieldConfig = mapField({
                dateFormat,
                initialField: field,
                value: conditions[field.key] || null,
            })
            fields.push(fieldConfig);
        })

    return fields;
};

export default mapConditionsBuilderToBoilerplateConfiguration;
