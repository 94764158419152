import React from "react";
import Descriptions from "./Descriptions";
import styles from "../styles";
import Clients from "../../Clients";
import moment from "moment";
import Notification from "../../Notification";
import FullScreenLoadingSpinner from "../../FullScreenLoadingSpinner";
import transformDescriptionsToDataStructure from "./components/builder/transformDescriptionsToDataStructure";
import _ from "lodash";
import RegionalItem from "./RegionalItem";
import { LockedSynopsisField } from "../../LockedField";
import BuilderError from "../../RecordBuilder/FetchError";
import GlobalPageContext from "../../Global/store/context";
import { DESCRIPTIONS } from '.././../../models/RecordBuilder'
import FadeWithSkeleton from "../../SkeletonLoader/FadeWithSkeleton";
import { getRegionalStatusesOptions, getSelectedDefaultRegionalStatus } from '../../../models/ClientManaged/regionalStatusModel'

class DescriptionsBuilder extends Descriptions {
    constructor(props, context) {
        super(props, context);

        let globalState = [];

        if (context) {
            [globalState] = context;
        }

        this.recordBuilder = globalState.recordBuilder || {};
        this.recordBuilderError = globalState.recordBuilderError || false;
    }

    componentDidMount() {
        if (!this.state.structureFetched) {
            this.setState({
                builderStructure: transformDescriptionsToDataStructure({
                    data: this.recordBuilder[DESCRIPTIONS],
                    category: this.props.category,
                }),
                structureFetched: true,
            });
        }

        if (!Array.isArray(this.state.regionalStatuses)) {
            getRegionalStatusesOptions(this.props.clientFeatures, this.props.cmsData).then((regionalStatuses) => {
                this.setState({
                    regionalStatuses,
                });
            });
        }
    }

    renderContent() {
        const {
            data = [],
            notification,
            fetchingTranslations,
            builderStructure = [],
        } = this.state;

        const { languageIso } = this.props;
        const localeSynopses =
            data.filter((item) => item.locale == languageIso) || [];
        const classStyles = styles(this.props);

        return (
            <>
                {this.recordBuilderError && <BuilderError />}

                {!this.recordBuilderError && (
                    <>
                        {this.renderUpdatedAt()}

                        {builderStructure.map((synopsisItem, index) => {
                            return (
                                <>
                                    <div
                                        style={Object.assign(
                                            {},
                                            classStyles.marginItem,
                                        )}
                                        key={index}
                                    >
                                        {this.renderSynopsis({
                                            ...synopsisItem,
                                            data:
                                                localeSynopses.find(
                                                    (item) =>
                                                        item.type ==
                                                        synopsisItem.key,
                                                ) || {},
                                        })}
                                    </div>
                                </>
                            );
                        })}
                    </>
                )}

                <Notification {...notification} />

                {fetchingTranslations && <FullScreenLoadingSpinner />}
            </>
        );
    }

    renderSynopsis(props) {
        const {
            key,
            label,
            maxCharacter,
            data = {},
            status: displayStatusSelect,
            comment,
            defaultComment,
            defaultCommentValue,
            googleTranslate,
            characterLimit,
            disableValidation,
            readOnly,
        } = props;

        const {
            validationKeys = [],
            disabled,
            handleOnBlur = () => {},
            clientFeatures = {},
            clientDataStructure = {},
            recordData = {},
            stateKey,
            options= {},
            cmsData = {},
        } = this.props;

        let lockedField = LockedSynopsisField({
            item: props,
            clientDataStructure,
            recordData,
        });

        return (
            <RegionalItem
                inputKey="synopsis"
                className="meta-record-synopsis"
                commentWidth="50%"
                label={label}
                placeholder="Enter Synopsis"
                name={key}
                data={data}
                readOnly={readOnly}
                disabled={lockedField || props.disabled || disabled}
                maxCharacter={disableValidation ? 999999 : maxCharacter}
                counter={true}
                validationKeys={validationKeys}
                type={key}
                displaySwitches={false}
                googleTranslateItems={this.getGoogleTranslateItems(key)}
                googleTranslate={googleTranslate}
                handleOnBlur={handleOnBlur.bind(this, key)}
                handleChange={this.handleChange.bind(this)}
                inlineCommentsAndButtons={true}
                textArea={true}
                stateKey={stateKey}
                clientFeatures={clientFeatures}
                dataSource={"synopses"}
                displayStatusSelect={displayStatusSelect}
                handleGooleTranslateClick={(locale) =>
                    this.handleGooleTranslateClick({
                        key,
                        locale,
                        text: data.synopsis,
                        label,
                    })
                }
                languageIso={this.props.languageIso || null}
                commentsOptions={{
                    comment,
                    defaultComment,
                    defaultCommentValue,
                }}
                statusOptions={this.state.regionalStatuses}
            />
        );
    }

    changeSynopsesUpdatedAt(date) {
        if (Clients.isClientEpix() && date) {
            date = moment(date).format("M/D/YYYY");
        }
        return date;
    }
}

DescriptionsBuilder.contextType = GlobalPageContext;

export default DescriptionsBuilder;
