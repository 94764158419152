import React, { useState, Fragment } from "react";
import Settings from "../Settings";
import FadeIn from "../TransitionGroup/FadeIn";
import "./styles.scss";
import _ from "lodash";

type Props = {
    link: string;
};

const LinkTooltip = (props: Props) => {
    const { link = "" } = props;

    const [containerHovered, setContainerHovered] = useState<boolean>(false);

    const onMouseEnterHandler = () => {
        setContainerHovered(true);
    };

    const onMouseLeaveHandler = () => {
        setContainerHovered(false);
    };

    return (
        <div
            className="link-tooltip-container"
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
        >
            {!_.isEmpty(link) && (
                <Fragment>
                    <a
                        className="link"
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {link}
                    </a>
                    {containerHovered && (
                        <div className={"tooltip-icon-state"}>
                            <img
                                data-tip="Link"
                                data-for="hovered-link"
                                src={Settings.images.externalLinkSmall}
                            />
                        </div>
                    )}
                </Fragment>
            )}
        </div>
    );
};

export default LinkTooltip;
