export default function DefaultClientPermissions(roles) {
    const {
        Role_SuperAdmin,
        Role_PlatformAdmin,
        Role_Admin,
        Role_Viewer,
        Role_LocalisationManager,
        Role_LanguageManager,
        Role_RegionalEditor,
        Role_Okta_User,
        Role_Viewer_No_Rights = null,
        Role_RecordAdmin,
    } = roles;

    const defaultRoles = [
        Role_SuperAdmin,
        Role_PlatformAdmin,
        Role_Admin,
        Role_Viewer,
        Role_LocalisationManager,
        Role_LanguageManager,
        Role_RegionalEditor,
        Role_Okta_User,
        Role_RecordAdmin,
    ];

    const editRoles = [
        Role_SuperAdmin,
        Role_PlatformAdmin,
        Role_Admin,
        Role_LocalisationManager,
        Role_LanguageManager,
        Role_RegionalEditor,
        Role_RecordAdmin,
    ];

    if (Role_Viewer_No_Rights) defaultRoles.push(Role_Viewer_No_Rights);

    let permissions = {
        eidr: {
            registration: [Role_SuperAdmin, Role_PlatformAdmin],
            request_registration: [Role_Admin, Role_RecordAdmin],
        },
        platformManagement: [Role_SuperAdmin, Role_PlatformAdmin],
        userRoleManagement: [Role_SuperAdmin, Role_PlatformAdmin],
        userAccount: defaultRoles,
        lineupManager: {
            view: defaultRoles,
            edit: editRoles,
        },
        localisations: {
            approve: [
                Role_SuperAdmin,
                Role_PlatformAdmin,
                Role_LocalisationManager,
            ],
            approveAndSubmit: [Role_LocalisationManager],
        },
        discovery: {
            create: [
                Role_SuperAdmin,
                Role_PlatformAdmin,
                Role_LocalisationManager,
                Role_Admin,
                Role_RecordAdmin,
            ],
            delete: [
                Role_SuperAdmin
            ],
            view: defaultRoles,
            edit: editRoles,
            header: {
                edit: editRoles,
            },
            metadata: {
                edit: editRoles,
                origination: {
                    release_type: {
                        populate: editRoles,
                    },
                },
            },
            ids: {
                edit: editRoles,
            },
            regional: {
                edit: editRoles,
            },
            games: {
                edit: editRoles,
            },
            images: {
                edit: editRoles,
            },
            cast_and_crew: {
                edit: editRoles,
                editContributor: defaultRoles,
            },
            awards: {
                edit: editRoles,
            },
            linkages: {
                edit: editRoles,
            },
            assets: {
                edit: editRoles,
                deleteCompliance: editRoles,
            },
            measurements: {
                edit: editRoles,
            },
            videos: {
                edit: editRoles,
            },
            gracenote: {
                edit: editRoles,
            },
            rights: {
                view: defaultRoles,
            },
            airingOrder: [Role_SuperAdmin, Role_PlatformAdmin],
            inheritData: [Role_SuperAdmin, Role_PlatformAdmin],
            record_security: [
                Role_SuperAdmin,
                Role_PlatformAdmin,
                Role_RecordAdmin,
            ]
        },
        collections: {
            create: [
                Role_SuperAdmin,
                Role_PlatformAdmin,
                Role_LocalisationManager,
                Role_Admin,
                Role_RecordAdmin,
                Role_LanguageManager,
            ],
            view: defaultRoles,
            edit: defaultRoles,
            title: {
                create: [
                    Role_SuperAdmin,
                    Role_PlatformAdmin,
                    Role_LocalisationManager,
                    Role_Admin,
                    Role_RecordAdmin,
                    Role_LanguageManager,
                ],
            },
            report: {
                create: [
                    Role_SuperAdmin,
                    Role_PlatformAdmin,
                    Role_LocalisationManager,
                    Role_Admin,
                    Role_RecordAdmin,
                    Role_LanguageManager,
                ],
            },
            franchise: {
                create: [
                    Role_SuperAdmin,
                    Role_PlatformAdmin,
                    Role_LocalisationManager,
                    Role_Admin,
                    Role_RecordAdmin,
                    Role_LanguageManager,
                ],
            },
        },
        connect: {
            userGroupManagement: {
                view: [Role_SuperAdmin, Role_PlatformAdmin],
                create: [Role_SuperAdmin, Role_PlatformAdmin],
            },
            swimlanes: {
                view: defaultRoles,
                edit: defaultRoles,
            },
            manager: {
                information: {
                    view: [Role_SuperAdmin, Role_PlatformAdmin],
                },
                users: {
                    view: [Role_SuperAdmin, Role_PlatformAdmin],
                },
            },
        },
        financials: {
            view: defaultRoles,
        },
        lineupPublishSubmitButton: [],
        growingLists: {
            add: [Role_SuperAdmin, Role_PlatformAdmin],
        },
        reporting: {
            looker: defaultRoles,
        },
        dataManager: {
            view: [Role_SuperAdmin],
            edit: [Role_SuperAdmin],
        },
        dataIngest: {
            view: [Role_SuperAdmin, Role_PlatformAdmin],
        },
        recordBuilder: [Role_SuperAdmin, Role_PlatformAdmin],
        userRegistrations: {
            view: [Role_SuperAdmin, Role_PlatformAdmin],
        },
        verifiedFields: [Role_SuperAdmin],
        changeRequest: {
            view: [Role_Viewer],
        },
        taskManager: [Role_SuperAdmin, Role_PlatformAdmin],
    };

    if (process.env.FEATURE_CONNECT_SUPER_ADMIN) {
        permissions.lineupManager.view = [Role_SuperAdmin];

        permissions.lineupManager.edit = [Role_SuperAdmin];
    }

    if (process.env.FEATURE_LANGUAGE_MANAGER_ADD_COLLECTIONS) {
        permissions.collections.create = [
            Role_SuperAdmin,
            Role_PlatformAdmin,
            Role_LocalisationManager,
            Role_Admin,
            Role_RecordAdmin,
            Role_LanguageManager,
        ];
    }

    return permissions;
}
