import AssetTypeInterface, {
    AssetTypeSectionInterface,
} from "../interfaces/AssetTypeInterface";
import _ from "lodash";

export const SUBTITLE_SECTION = "subtitle_tracks";
export const AUDIO_SECTION = "audio_tracks";
export const CERTIFICATIONS_SECTION = "certifications";

type Props = {
    sectionKey: string;
    typeConfiguration: AssetTypeInterface;
};

export const IsAdditionalSection = (section: string): boolean => {
    return [SUBTITLE_SECTION, AUDIO_SECTION, CERTIFICATIONS_SECTION].includes(
        section,
    );
};

const GetSectionConfiguration = (
    props: Props,
): AssetTypeSectionInterface | undefined => {
    const { sectionKey, typeConfiguration } = props;

    const { sections = [] } = typeConfiguration;

    return sections.find((item) => {
        return (
            !item.archived &&
            item.key == sectionKey &&
            !_.get(item, "options.hidden.enabled", false)
        );
    });
};

export default GetSectionConfiguration;
