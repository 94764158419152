import React from "react";
import AssetsV2 from "../v2";
import HeaderTags from "./HeaderTags";
import "./styles/Section.scss";

const Section = (props) => {
    const { dataStructure, ids = {} } = props;

    const {
        system_type,
        system_name,
        media_id = {},
        options = {},
        send_to_ai = {},
    } = dataStructure;

    const { key: media_id_key } = media_id;

    const { languages = {} } = options;

    return (
        <div className="asset-section">
            <HeaderTags system_type={system_type} system_name={system_name} />
            <AssetsV2
                {...props}
                languages_options={languages}
                send_to_ai={send_to_ai}
                media_id={ids[media_id_key] || ""}
            />
        </div>
    );
};

export default Section;
