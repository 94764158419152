import NoneElement from "../NoneElement";

export const DateTimeDisabled = ({ value }: { 
    value: any
}) => {

    return (
        <div className="fabric-timepicker-material">
            {value &&
                <p className="fabric-timepicker-value" style={{ color: 'rgba(47, 47, 47, 0.87)', paddingTop: '0.5rem', display: 'block', margin: '0'}}>
                    {value}
                </p>
            }

            {!value &&
                <NoneElement />
            }
        </div>
    )
}