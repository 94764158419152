import HandleChange from "../../HandleChange";
import deepcopy from "deepcopy";

const UpdateLocalisations = ({
    data = [],
    callback = () => {},
    state = {},
}) => {
    HandleChange({
        key: "localisations",
        data: deepcopy(data),
        callback,
        state,
    });
};

export default UpdateLocalisations;
