import { isEmpty } from "lodash";
import settings from "../../../../../../components/Settings";
import {
    CORE_FORM_ARRAY_DEFAULT_VALUE,
    CORE_FORM_NUMBER_DEFAULT_VALUE,
    CORE_FORM_STRING_DEFAULT_VALUE,
} from "../../../../../../core/Form/constants";
import { SearchRecord } from "../../../../types/interfaces";
import { AutocompleteRecord } from "../components/AutocompleteRecordCard";

export function adaptToAutocompleteRecord(record: SearchRecord) {
    const {
        original_title: title = CORE_FORM_STRING_DEFAULT_VALUE,
        first_release_year: releaseYear = CORE_FORM_NUMBER_DEFAULT_VALUE,
        meta_id: metaId = CORE_FORM_STRING_DEFAULT_VALUE,
        category_name: category = CORE_FORM_STRING_DEFAULT_VALUE,
        images = CORE_FORM_ARRAY_DEFAULT_VALUE,
    } = record || {};

    let foundImage = images?.find((image) => image?.poster === 1);
    if (isEmpty(foundImage) && images?.length > 0) {
        foundImage = images[0];
    }
    const imageFilename = foundImage?.filename;
    const imageSrc = imageFilename
        ? `${settings.recordImages.path}/${metaId}/${imageFilename}`
        : undefined;

    const adaptedRecord: Required<AutocompleteRecord> = {
        title,
        releaseYear,
        metaId,
        category,
        image: imageSrc,
        genres: record?.descriptors?.genres || CORE_FORM_ARRAY_DEFAULT_VALUE,
    };

    return adaptedRecord;
}
