export default function downloadFile(event: React.SyntheticEvent, href: string, fileName: string): void {
    if (event && typeof event.preventDefault == 'function') {
        event.preventDefault();

        if (window) {
            window.open(href, '_blank').focus();
        }
    }

    fetch(href)
        .then(response => response.blob())
        .then(blob => {

            const blobUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = fileName || href;

            link.click();

            URL.revokeObjectURL(blobUrl);
        })
}