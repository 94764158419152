const SplitColumns = (
    fields: any[],
): {
    first: any[];
    second: any[];
} => {
    const hasMode: boolean = !(fields.length % 2 == 0);
    const splicePiece = Math.floor(fields.length / 2) + (hasMode ? 1 : 0);

    let firstColumn: any[] = fields.slice(0, splicePiece);
    let secondColumn: any[] = fields.slice(splicePiece, fields.length);

    return {
        first: firstColumn,
        second: secondColumn,
    };
};

export default SplitColumns;
