import React from "react";
import "./styles/_styles.scss";
import BorderRadius from "../BorderRadius";
import AddNewRowButton from "../AddNewRowButton";
import ExpandableRow from "../ExpandableRow";
import ExpandableRowHeader from "./src/ExpandableRowHeader";
import ExpandableRowBody from "./src/ExpandableRowBody";
import PlatformTransformer from "./src/PlatformTransformer";

const Financials = (props) => {
    const {
        data = [],
        disabled,
        handleAddNew = () => {},
        handleDelete = () => {},
        handleEdit = () => {},
        canCreateNew = true,
    } = props;

    return (
        <div className="meta-finance-inner-block">
            <BorderRadius>
                {Array.isArray(data) &&
                    data.map((item, index) => {
                        const platform = PlatformTransformer(item);

                        return (
                            <ExpandableRow
                                header={<ExpandableRowHeader item={platform} />}
                                body={
                                    <ExpandableRowBody
                                        item={platform}
                                        disabled={disabled}
                                        handleDelete={handleDelete.bind(
                                            this,
                                            item.original_index,
                                        )}
                                        handleEdit={handleEdit.bind(
                                            this,
                                            item.original_index,
                                        )}
                                    />
                                }
                                key={index}
                                className="meta-expandable-rows--financials-platforms"
                            />
                        );
                    })}

                {!disabled && (
                    <AddNewRowButton
                        onClick={handleAddNew}
                        disabled={!canCreateNew || disabled}
                        data={data}
                    />
                )}
            </BorderRadius>
        </div>
    );
};

export default Financials;
