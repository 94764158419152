import HandleCollectionTitleEpisodesSearch from "./HandleCollectionTitleEpisodesSearch";
import CategoryTags from "../../../components/CategoryTags";

const HandleCollectionTitleSeasonSelect = (props) => {
    const { selectedSeason, state = {} } = props;

    const { setCollectionTitleKey, collectionTitle = {} } = state;

    const { category } = collectionTitle;

    setCollectionTitleKey("selectedSeason", selectedSeason);
    setCollectionTitleKey("episodes", []);

    if (
        category == CategoryTags.Episode.short ||
        category == CategoryTags.Storyline.short ||
        category == CategoryTags.Special.short ||
        category == CategoryTags.Season.short
    ) {
        HandleCollectionTitleEpisodesSearch({
            selectedSeason,
            state,
        });
    }
};

export default HandleCollectionTitleSeasonSelect;
