import validateLetter from "./validateLetter";

export default function validateEidr(eidr) {
    let result = true;

    if (typeof eidr == "string") {
        if (eidr.length !== 34) {
            result = false;
        }

        // 0-7th character is 10.5240
        if (eidr.substring(0, 7) !== "10.5240") {
            result = false;
        }

        // 8th is a dash
        if (eidr.charAt(7) !== "/") {
            result = false;
        }

        // 13th, 18th, 23rd, 28th, 33rd is a dash
        if (
            eidr.charAt(12) !== "-" ||
            eidr.charAt(17) !== "-" ||
            eidr.charAt(22) !== "-" ||
            eidr.charAt(27) !== "-" ||
            eidr.charAt(32) !== "-"
        ) {
            result = false;
        }

        // 34th is a character
        if (
            !validateLetter(eidr.charAt(33)) &&
            !Number.isInteger(parseInt(eidr.charAt(33)))
        ) {
            result = false;
        }
    }

    return result;
}
