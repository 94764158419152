import Field from '../../../../models/RecordBuilder/Field.interface'

const fieldName: Field = {
    dataType: 'String',
    displayName: 'Credit List Name (80 Characters)',
    key: 'name',
    options: {
        validation: {
            required: {
                enabled: true
            }
        },
        layer: {
            columns: 1,
            level: 1
        }
    }
}

const fieldCode: Field = {
    dataType: 'String',
    displayName: 'Credit List Code (3 Characters)',
    key: 'code',
    options: {
        validation: {
            required: {
                enabled: true
            },
            rangeLimit: {
                enabled: true,
                max: 3,
                min: 3
            }
        },
        layer: {
            columns: 2,
            level: 2
        }
    }
}

const fieldFrom: Field = {
    dataType: 'String',
    displayName: 'Create New Credit List From',
    key: 'clone_from',
    options: {
        validation: {
            required: {
                enabled: true
            }
        },
        layer: {
            columns: 2,
            level: 2
        },
        editable: {
            inputType: 'Select',
            optionsManagedList: {
                listSource: 'config',
                key: 'clone_from',
                option: {
                    dataType: 'String'
                },
                list: []
            }
        }
    }
}

export const configurationCreate = {
    code: 'credit-list',
    fields: [
        fieldName,
        fieldCode,
        fieldFrom
    ]
}

export const configurationUpdate = {
    code: 'credit-list',
    fields: [
        fieldName
    ]
}

export const apiExistingCreditFields: string[] = [
    'id',
    'role_id',
    'ranking',
    'deleted'
]

export const apiNewCreditFields: string[] = [
    'ranking',
    'role_id',
    'contributor_id',
    'deleted'
]