import GetRecordData from "../../GetRecordData";
import UpdateLocalisations from "./UpdateLocalisations";
import update from "react-addons-update";

const AddNewLocalisation = ({ data = {}, callback = () => {}, state = {} }) => {
    const { localisations = [] } = GetRecordData({ state });

    const updatedState = update(localisations, { $push: [data] });

    UpdateLocalisations({
        data: updatedState,
        callback,
        state,
    });
};

export default AddNewLocalisation;
