import { CmsInterface } from "../../../components/Global/interfaces";
import { userHasPermission } from "../../../services/PermissionService";
import { AccessTypeInterface } from "../../UserRoleManagement/interfaces";
import { policyTypeTaskManager } from "../../UserRoleManagement/utils/accessOptions";
import { StandaloneTicket } from "../containers/ticket/shared/validationSchema";
import {
    TabAllTickets,
    TabArchivedTickets,
    TabMyTickets,
    TicketTypeStandalone,
} from "../types/constants";
import { TaskManagerTabSlug } from "../types/interfaces";
import { TicketCommentScope, TicketType } from "../types/interfaces.shared";
import {
    taskManagerAccessCreate,
    taskManagerAccessTypeDelete,
    taskManagerAccessTypeUpdate,
    taskManagerAccessTypeView,
} from "../utils/accessOptions";
import TicketTypePathHandler from "./permissions/TicketTypePathHandler";

class TaskManagerPermissionsService {
    private tabsToPermissions: {
        [k: string]: TaskManagerTabSlug;
    } = {
        [TabAllTickets.label]: "tickets",
        [TabMyTickets.label]: "myTickets",
        [TabArchivedTickets.label]: "archive",
    };

    private userPermissions: any;

    private cmsData: CmsInterface;

    private ticketTypePathHandler: TicketTypePathHandler;

    constructor(cmsData: CmsInterface, userPermissions: any) {
        this.cmsData = cmsData;
        this.userPermissions = userPermissions;
        this.ticketTypePathHandler = new TicketTypePathHandler();
    }

    private can(pathField: string, access: AccessTypeInterface) {
        const permission = userHasPermission({
            cmsData: this.cmsData,
            userPermissions: this.userPermissions,
            pathField,
            access,
            prefix: policyTypeTaskManager,
        });

        return permission;
    }

    private canRead(path: string) {
        return this.can(path, taskManagerAccessTypeView);
    }

    private canUpdate(path: string) {
        return this.can(path, taskManagerAccessTypeUpdate);
    }

    private canDelete(path: string) {
        return this.can(path, taskManagerAccessTypeDelete);
    }

    private canCreate(path: string) {
        return this.can(path, taskManagerAccessCreate);
    }

    private getCRUDPath(ticket: StandaloneTicket) {
        return this.ticketTypePathHandler
            .getHandler(ticket)
            .getCRUDPath(ticket);
    }

    getAllowedTabs() {
        return [TabAllTickets, TabMyTickets, TabArchivedTickets].filter(
            (tab) => {
                const type = this.tabsToPermissions[tab.label];

                return this.canRead(`tabs.${type}`);
            },
        );
    }

    isAllowedToApprove(ticket: StandaloneTicket) {
        // Either I can approve my own tickets or I can approve other people's tickets
        const isMyTicket = ticket.author.id === this.cmsData.user.userId;
        const { own, other } = this.ticketTypePathHandler
            .getHandler(ticket)
            .getApprovalPaths(ticket);

        return (
            (isMyTicket && this.canUpdate(own)) ||
            (!isMyTicket && this.canUpdate(other))
        );
    }

    isAllowedToViewComments(ticket: StandaloneTicket, scopes: TicketCommentScope): boolean {
        const ticketActivityPath = this.ticketTypePathHandler
            .getHandler(ticket)
            .getTicketCommentPath()
        
        return this.can(`${ticketActivityPath}.${scopes}`, taskManagerAccessTypeView)
    }

    isAllowedToCreateComments(ticket: StandaloneTicket, scopes: TicketCommentScope): boolean {
        const ticketActivityPath = this.ticketTypePathHandler
            .getHandler(ticket)
            .getTicketCommentPath()
        
        return this.can(`${ticketActivityPath}.${scopes}`, taskManagerAccessCreate)
    }

    isAllowedToView(ticket: StandaloneTicket) {
        const path = this.getCRUDPath(ticket);

        return this.canRead(path);
    }

    isAllowedToEdit(tickets: StandaloneTicket[]) {
        return tickets.reduce((accumulator, ticket: StandaloneTicket) => {
            const path = this.getCRUDPath(ticket);

            return this.canUpdate(path) && accumulator;
        }, true);
    }

    isAllowedToCreate(ticketTypeLabel: string) {
        const path = this.getCRUDPath({ type: { label: ticketTypeLabel } });

        return this.canCreate(path);
    }

    isAllowedToDelete(tickets: StandaloneTicket[]) {
        return tickets.reduce((accumulator, ticket: StandaloneTicket) => {
            const path = this.getCRUDPath(ticket);

            return this.canDelete(path) && accumulator;
        }, true);
    }

    getAllowedStatuses(ticketType: Partial<TicketType> = TicketTypeStandalone) {
        const data = this.ticketTypePathHandler
            .getHandler({
                type: ticketType,
            })
            .getStatusesPaths();

        return data
            .filter((item) => {
                const path = item.path;

                return this.canUpdate(path);
            })
            .map((item) => item.status);
    }

    isAllowedToMoveRecordsToNewTicket(ticket: StandaloneTicket) {
        if (
            this.isAllowedToCreate(ticket.type.label) &&
            this.isAllowedToEdit([ticket])
        ) {
            return true;
        }

        return false;
    }
}

export default TaskManagerPermissionsService;
