import { CreditInterface, CreditListInterface, DataTablePayload } from "../../credits.interface";

export default function attachGroupCreditsToList(
    selectedCredits: DataTablePayload[] | CreditInterface[],
    credits: CreditInterface[],
    activeCreditList: CreditListInterface
): CreditInterface[] {
    return credits.map(credit => {
        const isSelected = selectedCredits.findIndex(
            (creditData) =>
                (creditData as DataTablePayload).localID === credit.local_id
        )

        if (isSelected === -1) {
            return credit;
        }

        const listIndex = credit.credit_lists.findIndex(
            (creditList) =>
                creditList.code === activeCreditList.code
        )

        if (listIndex === -1) {
            credit.credit_lists.push({
                name: activeCreditList.name,
                code: activeCreditList.code,
                ranking: 999
            });
        } else {
            credit.credit_lists[listIndex].deleted = false;
            credit.credit_lists[listIndex].ranking = 999
        }

        credit.ranking = 999

        return {
            ...credit,
        }
    })
}