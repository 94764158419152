/* eslint-disable no-case-declarations */
/* eslint-disable no-restricted-syntax */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-plusplus */
import _, { isEmpty } from "lodash";
import { action, computed, makeObservable, observable, reaction } from "mobx";
import { SubmitHandler, UseFormReturn } from "react-hook-form";
import {
    CORE_FORM_ARRAY_DEFAULT_VALUE,
    CORE_FORM_STRING_DEFAULT_VALUE,
} from "../../../../../../core/Form/constants";
import { inheritFields } from "../../../../config/clients/viacom/generators/inheritFields";
import {
    AllFieldsVT,
    EpisodeVT,
    PrimaryVT,
    SeasonVT,
    TvShowVT,
} from "../../../../config/types";
import { adapters } from "../../../../helpers/TaskManagerAdapters";
import { StandaloneRecord } from "../../../../types/interfaces.shared";
import randomNumber from "../../../../utils/randomNumber";
import { StandaloneTicket } from "../../../ticket/shared/validationSchema";
import { ChangeCollectionRecordVT } from "../../types";
import { Categories } from "../types/types";

class TvSeriesStore {
    /**
     * Every time a new record is added, it will be added to the corresponding array
     * Example: if a new TvShowVT is added via CoreForm or Nuvo, it will be added to the tvShows array and the CoreTable will be updated automatically
     */
    tvShows: TvShowVT[] = [];
    seasons: SeasonVT[] = [];
    episodes: EpisodeVT[] = [];

    parentShow: TvShowVT = null;
    parentSeason: SeasonVT = null;

    editMode = false;

    isSequencingTableInEditMode = false;

    formRef: UseFormReturn<StandaloneTicket>;

    loading = false;

    constructor() {
        makeObservable(this, {
            loading: observable,
            setLoading: action,

            getParentShowTitle: action,
            getParentSeasonTitle: action,
            getParentModularEpisodeTitle: action,
            getTitle: action,

            parentShow: observable,
            setParentShow: action,

            parentSeason: observable,
            setParentSeason: action,

            editMode: observable,
            setEditModeOn: action,
            setEditModeOff: action,

            tvShows: observable,
            seasons: observable,
            episodes: observable,

            getShowById: action,
            getSeasonById: action,
            getEpisodeById: action,

            addTvShowsBulk: action,
            addSeasonsBulk: action,
            addEpisodesBulk: action,

            setTVShows: action,
            setSeasons: action,
            setEpisodes: action,

            updateSeasonById: action,
            updateEpisodeById: action,

            findByTitle: action,
            findByMetaId: action,

            findByKey: action,

            removeRecord: action,
            bulkRemoveRecords: action,

            addRecordsToCollection: action,
            removeCollectionFromRecords: action,

            isSequencingTableInEditMode: observable,
            setIsSequencingTableInEditMode: action,

            coreTableRows: computed,

            clear: action,
        });

        reaction(
            () => this.coreTableRows,
            (rows) => {
                this.formRef?.setValue("records", rows, {
                    shouldDirty: true,
                });
            },
        );
    }

    clear() {
        this.tvShows = [];
        this.seasons = [];
        this.episodes = [];
        this.parentSeason = null;
        this.parentShow = null;
        this.editMode = false;
        this.isSequencingTableInEditMode = false;
    }

    get coreTableRows() {
        return []
            .concat(this.tvShows, this.seasons, this.episodes)
            .map((record) => {
                return adapters.adaptTvSeriesRecordToCoreTable(record);
            });
    }

    setLoading(value: boolean) {
        this.loading = value;
    }

    setFormRef(formRef: UseFormReturn<StandaloneTicket>) {
        this.formRef = formRef;
    }

    setIsSequencingTableInEditMode(value: boolean) {
        this.isSequencingTableInEditMode = value;
    }

    getParentShowTitle = (row: AllFieldsVT) => {
        const parentShowId = row?.parentShow?.id;
        const parentShow = this.findByKey({
            category: Categories.Show,
            key: "id",
            value: parentShowId,
        });
        const parentShowTitle = parentShow?.title || row?.parentShow?.title;

        if (isEmpty(parentShowTitle)) {
            return `_Parent_Show_Title_Missing_${randomNumber()}`;
        }

        return parentShowTitle;
    };

    getParentSeasonTitle = (row: AllFieldsVT) => {
        const parentSeasonId = row?.parentSeason?.id;
        const parentSeason = this.findByKey({
            category: Categories.Season,
            key: "id",
            value: parentSeasonId,
        });
        const parentSeasonTitle = parentSeason?.title;

        if (isEmpty(parentSeasonTitle)) {
            return `_Parent_Season_Title_Missing_${randomNumber()}`;
        }

        return parentSeasonTitle;
    };

    getParentModularEpisodeTitle = (row: AllFieldsVT) => {
        console.log("getParentModularEpisodeTitle", row);
        const parentModularEpisodeId = row?.parentModularEpisode?.id;
        const parentModularEpisode = this.findByKey({
            category: Categories.ModularEpisode,
            key: "id",
            value: parentModularEpisodeId,
        });
        const parentModularEpisodeTitle = parentModularEpisode?.title;

        if (isEmpty(parentModularEpisodeTitle)) {
            return "_Parent_Modular_Episode_Title_Missing_";
        }

        return parentModularEpisodeTitle;
    };

    getTitle = (row: AllFieldsVT) => {
        return (
            row?.title || `_${row.category}_Title_Missing_ ${randomNumber()}`
        );
    };

    setParentShow(show: TvShowVT) {
        this.parentShow = show;
    }

    setParentSeason(season: SeasonVT) {
        this.parentSeason = season;
    }

    setEditModeOn() {
        this.editMode = true;
    }

    setEditModeOff() {
        this.editMode = false;
    }

    findByTitle(
        category: AllFieldsVT["category"],
        title: AllFieldsVT["title"],
    ) {
        return this.findByKey({
            category,
            key: "title",
            value: title,
        });
    }

    findByMetaId(
        category: AllFieldsVT["category"],
        metaId: AllFieldsVT["metaId"],
    ) {
        return this.findByKey({
            category,
            key: "metaId",
            value: metaId,
        });
    }

    findByKey({
        category,
        key,
        value,
    }: {
        category: AllFieldsVT["category"];
        key: keyof AllFieldsVT;
        value: AllFieldsVT[keyof AllFieldsVT];
    }): AllFieldsVT {
        switch (category) {
            case Categories.Show:
                return this.tvShows.find((show) => show[key] === value);
            case Categories.Season:
                return this.seasons.find((season) => season[key] === value);
            case Categories.Episode:
            case Categories.ModularEpisode:
            case Categories.Storyline:
                return this.episodes.find((episode) => episode[key] === value);
            default:
                return null;
        }
    }

    findByKeys({
        category,
        keyValuePairs,
    }: {
        category: AllFieldsVT["category"];
        keyValuePairs: {
            key: keyof AllFieldsVT;
            value: AllFieldsVT[keyof AllFieldsVT];
        }[];
    }): AllFieldsVT {
        switch (category) {
            case Categories.Show:
                return this.tvShows.find((show) => {
                    return keyValuePairs.every((pair) => {
                        return _.get(show, pair.key) === pair.value;
                    });
                });
            case Categories.Season:
                return this.seasons.find((season) => {
                    return keyValuePairs.every((pair) => {
                        return _.get(season, pair.key) === pair.value;
                    });
                });
            case Categories.Episode:
            case Categories.ModularEpisode:
            case Categories.Storyline:
                return this.episodes.find((episode) => {
                    return keyValuePairs.every((pair) => {
                        return _.get(episode, pair.key) === pair.value;
                    });
                });
            default:
                return null;
        }
    }

    updateSeasonById(id: SeasonVT["id"], season: SeasonVT) {
        const index = this.seasons.findIndex((s) => s.id === id);
        if (index !== -1) {
            this.seasons[index] = season;

            if (this.parentSeason?.id === id) {
                this.parentSeason = {
                    ...this.parentSeason,
                    title: season.title,
                    seasonNo: season.seasonNo,
                };
            }

            // Update all episodes that belong to this season with parentSeason.id = season.id
            // This is needed to ensure parentSeason.title is updated properly even though
            // it is not displayed in the table
            const episodes = this.episodes.filter(
                (episode) => episode.parentSeason?.id === id,
            );
            for (const episode of episodes) {
                const updatedEpisode = {
                    ...episode,
                    parentSeason: {
                        ...episode.parentSeason,
                        title: season.title,
                        seasonNo: season.seasonNo,
                    },
                    parentShow: {
                        ...episode.parentShow,
                        title: season.parentShow.title,
                    },
                };

                console.log(
                    "Updating episode",
                    episode.id,
                    "to fix parent fields",
                    updatedEpisode,
                );
                this.updateEpisodeById(episode.id, updatedEpisode);
            }

            // Bulk Update Feature: Update all episodes that belong to this season
            const inheritance =
                season?.episodeInheritance || CORE_FORM_ARRAY_DEFAULT_VALUE;
            if (!isEmpty(inheritance)) {
                const episodes = this.episodes.filter(
                    (episode) => episode.parentSeason?.id === id,
                );
                for (const episode of episodes) {
                    const updatedEpisode = inheritFields(season, episode);
                    // TODO: store into memory. this.updateSeasonById(season.id, updatedSeason); is very slow
                    this.updateEpisodeById(episode.id, updatedEpisode);
                }
            }
        }
    }

    updateEpisodeById(id: EpisodeVT["id"], episode: EpisodeVT) {
        const index = this.episodes.findIndex((s) => s.id === id);
        if (index !== -1) {
            this.episodes[index] = episode;

            // Update all storylines that belong to this episode with parentEpisode.id = episode.id
            // to ensure parentModularEpisode.title is updated properly
            const storylines = this.episodes.filter(
                (storyline) => storyline.parentModularEpisode?.id === id,
            );
            for (const storyline of storylines) {
                const updatedStoryline = {
                    ...storyline,
                    parentShow: {
                        ...storyline.parentShow,
                        title: episode.parentShow.title,
                    },
                    parentSeason: {
                        ...storyline.parentSeason,
                        title: episode.parentSeason.title,
                        seasonNo: episode.parentSeason.seasonNo,
                    },
                    parentModularEpisode: {
                        ...storyline.parentModularEpisode,
                        title: episode.title,
                        episodeNo: episode.episodeNo,
                    },
                };

                console.log(
                    "Updating storyline",
                    storyline.id,
                    "to fix parent fields",
                    updatedStoryline,
                );
                this.updateEpisodeById(storyline.id, updatedStoryline);
            }

            // Bulk Update Feature: Update all episodes that belong to this season
            const inheritance =
                episode?.episodeInheritance || CORE_FORM_ARRAY_DEFAULT_VALUE;
            if (!isEmpty(inheritance)) {
                // only for Storylines under the modular episodes
                const modularStorylines = this.episodes.filter(
                    (modularStoryline) =>
                        !isEmpty(
                            modularStoryline?.parentModularEpisode?.title,
                        ) && modularStoryline?.parentModularEpisode?.id === id,
                );
                for (const modularStoryline of modularStorylines) {
                    const updatedModularStoryline = inheritFields(
                        episode,
                        modularStoryline,
                    );
                    // TODO: store into memory. this.updateSeasonById(season.id, updatedSeason); is very slow
                    this.updateEpisodeById(
                        modularStoryline.id,
                        updatedModularStoryline,
                    );
                }
            }
        }
    }

    updateShowById(id: TvShowVT["id"], show: TvShowVT) {
        const index = this.tvShows.findIndex((s) => s.id === id);
        if (index !== -1) {
            this.tvShows[index] = show;

            if (this.parentShow?.id === id) {
                this.parentShow = {
                    ...this.parentShow,
                    title: show.title,
                };
            }

            // Update all seasons and episodes that belong to this show with parentShow.id = show.id
            // This is needed to ensure parentShow.title is updated properly even though
            // it is not displayed in the table
            const seasons = this.seasons.filter(
                (season) => season.parentShow?.id === id,
            );
            for (const season of seasons) {
                const updatedSeason = {
                    ...season,
                    parentShow: {
                        ...season.parentShow,
                        title: show.title,
                    },
                };

                console.log(
                    "Updating season",
                    season.id,
                    "to fix parent fields",
                    updatedSeason,
                );
                this.updateSeasonById(season.id, updatedSeason);
            }

            // Bulk Update Feature: Update all seasons that belong to this show
            const inheritance =
                show?.episodeInheritance || CORE_FORM_ARRAY_DEFAULT_VALUE;
            if (!isEmpty(inheritance)) {
                const seasons = this.seasons.filter(
                    (season) => season.parentShow?.id === id,
                );
                for (const season of seasons) {
                    const updatedSeason = inheritFields(show, season);
                    // TODO: store into memory. this.updateSeasonById(season.id, updatedSeason); is very slow
                    this.updateSeasonById(season.id, updatedSeason);
                }
            }
        }
    }

    addTvShowsBulk(tvShows: TvShowVT[]) {
        if (_.isEmpty(tvShows)) {
            return;
        }

        // tvShows?.some((tvShow) => {
        //     if
        // });

        // this.tvShows = _.uniqBy([...this.tvShows, ...tvShows], "id");
        const all = _.uniqBy([...this.tvShows, ...tvShows], "id");
        console.log("store: all tvShows", all);
        // forEach tvShow if it doesn't exist in the array, add it to this.tvShows
        all.forEach((tvShow) => {
            const exists = this.tvShows.find(
                (show) => show.title === tvShow.title,
                // && show.releaseYear === tvShow.releaseYear,
                // TODO: add more fields to check ?
            );
            if (!exists) {
                // TODO: move to validator store
                //  TODO: check all field types to be sure they are valid
                let tvShowAux = tvShow;
                if (tvShow?.metaId === "null") {
                    tvShowAux.metaId = CORE_FORM_STRING_DEFAULT_VALUE;
                }
                if (Array.isArray(tvShow?.provenance)) {
                    tvShowAux.provenance = CORE_FORM_STRING_DEFAULT_VALUE;
                }

                tvShowAux = adapters.adaptTvSeriesRecordToCoreTable(tvShowAux);
                this.tvShows.push(tvShowAux);
            }
        });
    }

    addSeasonsBulk(seasons: SeasonVT[]) {
        if (_.isEmpty(seasons)) {
            return;
        }

        const all = _.uniqBy([...this.seasons, ...seasons], "id");
        console.log("store: all seasons", all);
        // forEach season if it doesn't exist in the array, add it to this.seasons
        all.forEach((season) => {
            const exists = this.seasons.find(
                (s) =>
                    s.parentShow?.title === season?.parentShow.title &&
                    s?.title === season?.title,
                // s.seasonNo === season.seasonNo,
                // TODO: add more fields to check ?
            );
            if (!exists) {
                let seasonAux = season;
                if (season?.metaId === "null") {
                    seasonAux.metaId = CORE_FORM_STRING_DEFAULT_VALUE;
                }
                if (Array.isArray(season?.provenance)) {
                    seasonAux.provenance = CORE_FORM_STRING_DEFAULT_VALUE;
                }

                seasonAux = adapters.adaptTvSeriesRecordToCoreTable(seasonAux);
                this.seasons.push(seasonAux);
            }
        });
    }

    addEpisodesBulk(episodes: EpisodeVT[]) {
        if (_.isEmpty(episodes)) {
            return;
        }

        const all = _.uniqBy([...this.episodes, ...episodes], "id");
        console.log("store: all episodes", episodes);
        // forEach episode if it doesn't exist in the array, add it to this.episodes
        all.forEach((episode) => {
            const exists = this.episodes.find(
                (e) =>
                    e.parentShow.title === episode.parentShow.title &&
                    e.parentSeason.title === episode.parentSeason.title &&
                    e.title === episode.title,
                // e.episodeNo === episode.episodeNo,
                // TODO: add more fields to check ?
            );

            if (!exists) {
                let episodeAux = episode;
                if (episode?.metaId === "null") {
                    episodeAux.metaId = CORE_FORM_STRING_DEFAULT_VALUE;
                }
                if (Array.isArray(episode?.provenance)) {
                    episodeAux.provenance = CORE_FORM_STRING_DEFAULT_VALUE;
                }

                episodeAux =
                    adapters.adaptTvSeriesRecordToCoreTable(episodeAux);
                this.episodes.push(episodeAux);
            }
        });
    }

    setTVShows(tvShows: TvShowVT[]) {
        this.tvShows = tvShows;
    }

    setSeasons(seasons: SeasonVT[]) {
        this.seasons = seasons;
    }

    setEpisodes(episodes: EpisodeVT[]) {
        this.episodes = episodes;
    }

    getShowById(id: TvShowVT["id"]) {
        return this.tvShows.find((show) => show.id === id);
    }

    getSeasonById(id: SeasonVT["id"]) {
        return this.seasons.find((season) => season.id === id);
    }

    getEpisodeById(id: EpisodeVT["id"]) {
        return this.episodes.find((episode) => episode.id === id);
    }

    getRecordList() {
        const tvShows: TvShowVT[] = this.tvShows ?? [];
        const seasons: SeasonVT[] = this.seasons ?? [];
        const episodes: EpisodeVT[] = this.episodes ?? [];

        const rows: AllFieldsVT[] = [];
        if (tvShows && Array.isArray(tvShows)) rows.push(...tvShows);
        if (seasons && Array.isArray(seasons)) rows.push(...seasons);
        if (episodes && Array.isArray(episodes)) rows.push(...episodes);

        return rows;
    }

    reset() {
        this.setTVShows([]);
        this.setSeasons([]);
        this.setEpisodes([]);
        this.parentSeason = null;
        this.parentShow = null;
        this.editMode = false;
        this.isSequencingTableInEditMode = false;

        console.log("TvSeriesStore has been reset");
    }

    removeRecord({
        id,
        category,
        title,
    }: {
        id: AllFieldsVT["id"];
        category: AllFieldsVT["category"];
        title: AllFieldsVT["title"];
    }) {
        console.log("Current records", this.getRecordList());
        console.log(
            `Removing record from ticket with id: ${id}, category: ${category} and title: ${title}`,
        );

        switch (category) {
            case Categories.Show:
                const show = this.tvShows.find(
                    // TODO: fix duplication
                    (item) => item.id === id && item.title === title,
                );

                this.removeTvShowRecord(show);
                break;
            case Categories.Season:
                const season = this.seasons.find(
                    // TODO: fix duplication
                    (item) => item.id === id && item.title === title,
                );

                this.removeSeasonRecord(season);
                break;
            case Categories.Episode:
            case Categories.Storyline:
                const episode = this.episodes.find(
                    // TODO: fix duplication
                    (item) => item.id === id && item.title === title,
                );

                this.removeEpisodeRecord(episode);
                break;
            case Categories.ModularEpisode:
                const modularEpisode = this.episodes.find(
                    // TODO: fix duplication
                    (item) => item.id === id && item.title === title,
                );

                this.removeModularEpisodeRecord(modularEpisode);
                break;
            default:
                console.error("Unknown category", category);
                break;
        }

        console.log(
            "After removeRecord - current records",
            this.getRecordList(),
        );
    }

    private removeEpisodeRecord(episode: EpisodeVT) {
        const { id, title } = episode;
        console.log("Removing episode record from ticket", id, title);

        // Remove episode from episodes list
        this.episodes = this.episodes.filter(
            (item) => item.id !== id && item.title !== title,
        );
    }

    private removeModularEpisodeRecord(episode: EpisodeVT) {
        const { id, title, metaId } = episode;
        console.log("Removing episode record from ticket", id, title);

        // Remove modular episode from episodes list
        this.removeEpisodeRecord(episode);

        // Remove all storylines from episodes list that belong to this modular episode
        this.episodes = this.episodes.filter((item) => {
            // If episode belongs to this Fabric modular episode (metaId valid), also remove it
            if (metaId && item?.parentModularEpisode?.metaId === metaId) {
                console.log(
                    "Removing storyline record from ticket (modular episode removed)",
                    item.id,
                );
                return false;
            }

            // If episode does not belong to a Fabric modular episode (no metaId), also remove it
            // Here we identifying parent modular episode by title
            if (!metaId && item?.parentModularEpisode?.title === title) {
                console.log(
                    "Removing storyline record from ticket (modular episode removed)",
                    item.id,
                );
                return false;
            }

            return true;
        });
    }

    private removeSeasonRecord(season: SeasonVT) {
        const { id, title, metaId } = season;

        // Remove season from seasons list
        this.seasons = this.seasons.filter((item) => {
            if (item.id !== id && item.title !== title) {
                return true;
            }

            console.log("Removing season record from ticket", item.id);
            return false;
        });

        // Remove all episodes from episodes list that belong to this season
        this.episodes = this.episodes.filter((item) => {
            // If episode belongs to this Fabric season (metaId valid), also remove it
            if (metaId && item.parentSeason?.metaId === metaId) {
                console.log(
                    "Removing episode record from ticket (season removed)",
                    item.id,
                );
                return false;
            }

            // If episode does not belong to a Fabric season (no metaId), also remove it
            // Here we identifying parent season by title
            if (!metaId && item.parentSeason?.title === title) {
                console.log(
                    "Removing episode record from ticket (season removed)",
                    item.id,
                );
                return false;
            }

            return true;
        });
    }

    private removeTvShowRecord(tvShow: TvShowVT) {
        const { id, title, metaId } = tvShow;

        // Remove tvShow from tvShows list
        this.tvShows = this.tvShows.filter((item) => {
            if (item.id !== id && item.title !== title) {
                return true;
            }

            console.log("Removing tvShow record from ticket", item.id);
            return false;
        });

        // Find all seasons that belong to this tvShow
        const seasonsToRemove = this.seasons.filter((item) => {
            return (
                (metaId && item.parentShow?.metaId === metaId) ||
                item.parentShow?.title === title
            );
        });

        // Remove all seasons from seasons list that belong to this tvShow
        for (const season of seasonsToRemove) {
            this.removeSeasonRecord(season);
        }

        // Find all episodes that belong to this tvShow
        const episodesToRemove = this.episodes.filter((item) => {
            return (
                (metaId && item.parentShow?.metaId === metaId) ||
                item.parentShow?.title === title
            );
        });

        // Remove all episodes from episodes list that belong to this tvShow
        for (const episode of episodesToRemove) {
            this.removeEpisodeRecord(episode);
        }
    }

    bulkRemoveRecords(recordIds: StandaloneRecord["id"][]) {
        for (const recordId of recordIds) {
            // Search for record in all lists
            const tvShow = this.tvShows.find((r) => r.id === recordId);
            const season = this.seasons.find((r) => r.id === recordId);
            const episode = this.episodes.find((r) => r.id === recordId);

            let toDelete = null;
            if (tvShow) {
                toDelete = tvShow;
            } else if (season) {
                toDelete = season;
            } else if (episode) {
                toDelete = episode;
            } else {
                console.error(
                    "Record to delete not found in any list. Skipping.",
                );
                continue;
            }

            this.removeRecord(toDelete);
        }
    }

    addRecordsToCollection(
        data: ChangeCollectionRecordVT,
        recordIds: PrimaryVT["id"][],
    ) {
        for (let tvShow of this.tvShows) {
            if (recordIds.includes(tvShow.id)) {
                tvShow.collection = {
                    ...data.collection,
                };
            }
        }

        for (let season of this.seasons) {
            if (recordIds.includes(season.id)) {
                season.collection = {
                    ...data.collection,
                };
            }
        }

        for (let episode of this.episodes) {
            if (recordIds.includes(episode.id)) {
                episode.collection = {
                    ...data.collection,
                };
            }
        }
    }

    removeCollectionFromRecords(recordIds: PrimaryVT["id"][]) {
        for (let tvShow of this.tvShows) {
            if (recordIds.includes(tvShow.id)) {
                tvShow.collection = null;
            }
        }

        for (let season of this.seasons) {
            if (recordIds.includes(season.id)) {
                season.collection = null;
            }
        }

        for (let episode of this.episodes) {
            if (recordIds.includes(episode.id)) {
                episode.collection = null;
            }
        }
    }

    getCollectionCount(recordIds: PrimaryVT["id"][]) {
        let count = 0;
        for (let tvShow of this.tvShows) {
            if (recordIds.includes(tvShow.id) && tvShow.collection) {
                count++;
            }
        }

        for (let season of this.seasons) {
            if (recordIds.includes(season.id) && season.collection) {
                count++;
            }
        }

        for (let episode of this.episodes) {
            if (recordIds.includes(episode.id) && episode.collection) {
                count++;
            }
        }

        return count;
    }

    isMoveToNewTicketDisabled(recordIds: StandaloneRecord["id"][]) {
        // Records must be moved together with their parents
        // If a record is selected, but its parent is not, then the Move to New Ticket button should be disabled

        // Find all seasons
        const seasons = this.seasons.filter((item) => {
            return recordIds.includes(item.id);
        });

        // Find all episodes
        const episodes = this.episodes.filter((item) => {
            return recordIds.includes(item.id);
        });

        for (let season of seasons) {
            if (!recordIds.includes(season.parentShow.id)) {
                return true;
            }
        }

        for (let episode of episodes) {
            if (!recordIds.includes(episode.parentSeason.id)) {
                return true;
            }

            if (!recordIds.includes(episode.parentShow.id)) {
                return true;
            }
        }

        return false;
    }

    getShowsAndDependents(recordIds: StandaloneRecord["id"][]) {
        const shows = this.tvShows.filter((item) => {
            return recordIds.includes(item.id);
        });

        const seasons = this.seasons.filter((item) => {
            return shows.some((show) => show.id === item.parentShow.id);
        });

        const episodes = this.episodes.filter((item) => {
            return (
                seasons.some((season) => season.id === item.parentSeason.id) ||
                shows.some((show) => show.id === item.parentShow.id)
            );
        });

        return [...shows, ...seasons, ...episodes];
    }

    async moveRecordsToNewTicket({
        form,
        onSubmit,
        snackbarAction,
        copyTicket,
        selectedRecordIds,
    }: {
        form: UseFormReturn<StandaloneTicket>;
        onSubmit: SubmitHandler<StandaloneTicket>;
        snackbarAction: (ticketId: number) => JSX.Element;
        copyTicket: () => Promise<void>;
        selectedRecordIds: StandaloneRecord["id"][];
    }) {
        // Delete records from the current ticket
        // Save the current ticket
        // Redirect to new ticket page and use URLParams to populate the new ticket
        // with the records from the current ticket

        const recordsToMove = this.getShowsAndDependents(selectedRecordIds);

        // Source of truth is the Store, but without the effect
        // we cannot sync the form with the store
        const currentRecords = this.getRecordList();

        const remainingRecords = currentRecords.filter((record) => {
            return !recordsToMove.some(
                (recordToMove) => recordToMove.id === record.id,
            );
        });

        // Remove the selected records from the current ticket
        this.bulkRemoveRecords(recordsToMove.map((record) => record.id));
        form.setValue("records", remainingRecords);

        // Submit the form to save the current ticket
        form.handleSubmit(onSubmit)();

        const formData = form.getValues();

        // TODO The ReturnType isn't working here for some reason
        await copyTicket(formData, recordsToMove, snackbarAction);
    }

    getSearchResults(searchTerm: string) {
        // We need to search in episodes/seasons/tvShows arrays by title
        // The problem is that we don't know which array to search in
        // and that the parent structure HAS to be maintained in order
        // to display the results correctly in the table

        // Example: if we search for "The Simpsons", we need to return
        // the TvShowVT with title "The Simpsons" and all its seasons and episodes
        // but we also need to return the SeasonVT with title "The Simpsons" and all its episodes
        // and we also need to return the EpisodeVT with title "The Simpsons"

        const results = [];

        // Search in episodes
        const episodes = this.episodes.filter((episode) => {
            return episode.title
                .toLowerCase()
                .includes(searchTerm.toLowerCase());
        });

        // Search in seasons
        const seasons = this.seasons.filter((season) => {
            return season.title
                .toLowerCase()
                .includes(searchTerm.toLowerCase());
        });

        // Search in tvShows
        const tvShows = this.tvShows.filter((tvShow) => {
            return tvShow.title
                .toLowerCase()
                .includes(searchTerm.toLowerCase());
        });

        // Add all episodes that belong to the seasons that match the search term
        let episodesInSeason = [];
        let showsInSeason = [];
        for (const season of seasons) {
            // Episodes belonging to the seasons that match the search term
            episodesInSeason.push(
                ...this.episodes.filter((episode) => {
                    return episode.parentSeason?.id === season.id;
                }),
            );

            // Episodes belonging to episodes that belong to the seasons that match the search term
            episodesInSeason.push(
                ...this.episodes.filter((episode) => {
                    return episode.parentSeason?.id === season.id;
                }),
            );

            // Shows belonging to the seasons that match the search term
            showsInSeason.push(
                ...this.tvShows.filter((show) => {
                    return show.id === season.parentShow?.id;
                }),
            );
        }

        let episodesInTvShow = [];
        let seasonsInTvShow = [];
        for (const tvShow of tvShows) {
            // Add all seasons that belong to the tvShows that match the search term
            seasonsInTvShow.push(
                ...this.seasons.filter((season) => {
                    return season.parentShow?.id === tvShow.id;
                }),
            );

            // Add all episodes that belong to the tvShows that match the search term
            episodesInTvShow.push(
                ...this.episodes.filter((episode) => {
                    return episode.parentShow?.id === tvShow.id;
                }),
            );
        }

        let episodesInEpisode = [];
        for (const episode of episodes) {
            // Add all episodes that belong to the parent modular episodes that match the search term
            episodesInEpisode.push(
                ...this.episodes.filter((ep) => {
                    return episode.id === ep.parentModularEpisode?.id;
                }),
            );

            // Add all parent modular episodes that are parents of the episodes that match the search term
            episodesInEpisode.push(
                ...this.episodes.filter((ep) => {
                    return ep.id === episode.parentModularEpisode?.id;
                }),
            );

            // Add all parent seasons
            seasonsInTvShow.push(
                ...this.seasons.filter((season) => {
                    return season.id === episode.parentSeason?.id;
                }),
            );

            // Add all parent shows
            showsInSeason.push(
                ...this.tvShows.filter((show) => {
                    return show.id === episode.parentShow?.id;
                }),
            );
        }

        // Merge all results
        results.push(
            ...tvShows,
            ...showsInSeason,
            ...seasons,
            ...seasonsInTvShow,
            ...episodes,
            ...episodesInSeason,
            ...episodesInTvShow,
            ...episodesInEpisode,
        );

        // Remove duplicates
        const uniqueResults = _.uniqBy(results, "id");

        console.log("TV Series Search results", uniqueResults);

        return uniqueResults;
    }
}
const store = new TvSeriesStore();
export default store;
