import React, { createRef } from "react";
import ReactDOM from "react-dom";

import _ from "lodash";
import ExpandableRow from "../../ExpandableRow";
import ExpandableRowHeader from "./ExpandableRowHeader";
import ExpandableRowBody from "./ExpandableRowBody";
import $ from "jquery";

export default class AssetContainer extends React.Component {
    constructor(props) {
        super(props);

        this.element = createRef();

        this.state = {
            quickView: false,
        };
    }

    render() {
        const { quickView } = this.state;

        const handleAnimation = (shouldOpen) => {
            const element = $(ReactDOM.findDOMNode(this.element.current));
            const body = element.children()[1];
            if (shouldOpen) {
                // open up
                element.addClass("open");
                $(body).slideDown(200);
            } else {
                // close
                element.removeClass("open");
                $(body).slideUp(200);
            }
        };

        const { clientFeatures = {}, cmsData = {} } = this.props;

        const { assetsRowExpanded = false } = clientFeatures;

        const bodyProps = {
            quickView,
            handleOpen: quickView,
            ...this.props,
        };

        return (
            <div
                className="meta-asset-container"
                style={{ position: "relative" }}
            >
                <ExpandableRow
                    header={
                        <ExpandableRowHeader
                            {...this.props}
                            handleSetQuickView={(value) => {
                                this.setState({ quickView: value }, () =>
                                    handleAnimation(value),
                                );
                            }}
                        />
                    }
                    body={<ExpandableRowBody {...bodyProps} />}
                    bodyStyles={{ padding: 0 }}
                    borderRadius={true}
                    className="meta-expandable-rows--asset-container"
                    headerClassName="meta-asset-header-main"
                    defaultOpen={assetsRowExpanded}
                    arrowIconColorType="dark"
                    ref={this.element}
                    cmsData={cmsData}
                />
            </div>
        );
    }
}
