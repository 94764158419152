import _ from "lodash";
import MonthYearDates from "../../../components/Helpers/MonthYearDates";

const HandleLineupPeriodNextPreviousEvent = (props) => {
    const { state = {}, options = {} } = props;

    const { event, handleSuccess } = options;

    const { set, lineupPeriod } = state;

    let dates = [];
    const target = MonthYearDates();

    for (var k in target) {
        if (target.hasOwnProperty(k)) {
            dates.push(...target[k]);
        }
    }

    const periodIndex = _.findIndex(dates, function (item) {
        return item.value == lineupPeriod;
    });

    let updatedState = lineupPeriod;

    if (event === "next") {
        if (periodIndex == dates.length - 1) {
            return;
        }

        updatedState = dates[periodIndex + 1].value;
    }

    if (event === "previous") {
        if (periodIndex == 0) {
            return;
        }

        updatedState = dates[periodIndex - 1].value;
    }

    set(
        {
            lineupPeriod: updatedState,
        },
        () => {
            handleSuccess();
        },
    );
};

export default HandleLineupPeriodNextPreviousEvent;
