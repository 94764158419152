export function downloadBlob(data: BlobPart, type: string, name: string) {
    let blob = new Blob([data], { type });
    let url = window.URL.createObjectURL(blob);

    let link = document.createElement("a");
    link.download = name;
    link.href = url;
    link.click();

    window.URL.revokeObjectURL(url);
}
