import {
    APIRoleOptionInterface,
    CreditInterface,
} from "../../credits.interface";

export default function assignCreditRole(
    credit: CreditInterface,
    value: APIRoleOptionInterface,
) {
    if (value === null) return credit;
    let creditCopy: CreditInterface = Object.assign({}, credit);

    creditCopy.character_name = null;
    creditCopy.ranking = false;
    creditCopy.role_name = null;

    if (typeof value == "object") {
        creditCopy.role = parseInt(
            (value.value as string) || (value.id as string),
        );
        creditCopy.role_name = value.text || value.name;
    }

    return creditCopy;
}
