const CheckIncludingTypesListOnSingle = (list) => {
    let result = false;

    try {
        if (Array.isArray(list) && list.length == 1) {
            let firstElement = list[0];
            if (firstElement && firstElement.value) result = firstElement.value;
        }
    } catch (error) {}

    return result;
};

export default CheckIncludingTypesListOnSingle;
