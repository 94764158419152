import React from "react";
import { observable, makeObservable, action } from "mobx";
import { AwardsStore } from "./awardsStore";

export class RootStore {
    awardsStore: AwardsStore;
    scriptValue: string = "";
    defaultScriptValue: string = "";
    year: number = 2019;

    constructor() {
        this.awardsStore = new AwardsStore(this);
        makeObservable(this, {
            scriptValue: observable,
            defaultScriptValue: observable,
            year: observable,

            setScriptValue: action,
            setDefaultScriptValue: action,
            setYear: action,
        });
    }

    initialize(script: string): void {
        this.setDefaultScriptValue(script);
        this.setScriptValue(script);
        this.setYear(new Date().getFullYear());
    }

    setScriptValue(value: string) {
        this.scriptValue = value;
    }

    setDefaultScriptValue(value: string) {
        this.defaultScriptValue = value;
    }

    setYear(value: number) {
        this.year = value;
    }
}

export const StoresContext = React.createContext(new RootStore());
export const useStores = () => React.useContext(StoresContext);
