import titlesMap from "./graphql/schemas/titles";
import descriptionsMap from "./graphql/schemas/descriptions";
import origiginationMap from './graphql/schemas/origination';
import idsMap from "./graphql/schemas/ids";
import assetsTypesMap from "./graphql/schemas/assetsTypes";
import assetsPlatformsMap from "./graphql/schemas/assetsPlatforms";
import assetsCodesMap from "./graphql/schemas/assetsCodes";
import rightsMap from './graphql/schemas/rights';
import schedulingMap from './graphql/schemas/scheduling';
import schedulingSystemsMap from './graphql/schemas/schedulingSystem';
import schedulingPlatformsMap from './graphql/schemas/schedulingPlatform';
import contributorSectionsMap from './graphql/schemas/contributorSections';
import numberingSectionMap from './graphql/schemas/numbering'
import getAllMap from "./graphql/schemas/queryGetAll";

import {
    TITLES,
    DESCRIPTIONS,
    ASSET_DESTINATION_PLATFORMS,
    ASSET_TYPES,
    RIGHTS,
    ORIGINATION,
    CUSTOM_FIELDS,
    SCHEDULING,
    SCHEDULING_SYSTEMS,
    SCHEDULING_PLATFORMS,
    IDS,
    CLIENT_IDS,
    ALTERNATE_IDS,
    SUPPLY_CHAIN_IDS,
    ASSET_CODES,
    CONTRIBUTOR_SECTIONS,
    NUMBERING,
} from '../../../models/RecordBuilder';

export default {
    [TITLES]: titlesMap,
    [DESCRIPTIONS]: descriptionsMap,
    [ORIGINATION]: origiginationMap,
    [CUSTOM_FIELDS]: origiginationMap,
    [IDS]: idsMap,
    [CLIENT_IDS]: idsMap,
    [ALTERNATE_IDS]: idsMap,
    [SUPPLY_CHAIN_IDS]: idsMap,
    [ASSET_DESTINATION_PLATFORMS]: assetsPlatformsMap,
    [ASSET_CODES]: assetsCodesMap,
    [ASSET_TYPES]: assetsTypesMap,
    [RIGHTS]: rightsMap,
    [SCHEDULING]: schedulingMap,
    [SCHEDULING_SYSTEMS]: schedulingSystemsMap,
    [SCHEDULING_PLATFORMS]: schedulingPlatformsMap,
    [CONTRIBUTOR_SECTIONS]: contributorSectionsMap,
    [NUMBERING]: numberingSectionMap,
    all: getAllMap,
};
