import React from "react";
import HandleSelectedRecord from "../../Selected/handleSelectedRecord";

const RenderMetaId = (meta_id, handleLinkSearch, styles = {}) => {
    const handleOnClick = () => {
        HandleSelectedRecord({
            handleLinkSearch,
            selected: meta_id,
        });
    };

    if (meta_id) {
        return (
            <span
                className="meta-numbered-record-table--meta-id"
                onClick={() => handleOnClick()}
                style={styles}
            >
                {meta_id}
            </span>
        );
    }

    return false;
};

export default RenderMetaId;
