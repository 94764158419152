import _ from "lodash";

export default function isIndexInArray(array = [], index = false) {
    let result = false;

    if (Array.isArray(array) && index) {
        const arrayIndex = _.indexOf(array, index);

        if (arrayIndex > -1) {
            result = true;
        }
    }

    return result;
}
