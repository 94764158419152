import { z } from "zod";

export const recordCollectionVS = z.object({
    collection: z
        .object({
            category: z.string().nonempty("Please select a category"),
            title: z.string().nonempty("Please enter a title"),
            metaId: z.string().nullish(),
        })
        .nullish(),
});
export type RecordCollectionVT = z.infer<typeof recordCollectionVS>;

export const requiredRecordCollectionVS = z.object({
    collection: z.object({
        title: z.string().nonempty("Please enter a title"),
    }),
});

// Form to change the collection of record(s)
export const changeCollectionRecordVS = z
    .object({
        searchMode: z.enum(["Create new", "Select existing"]),
    })
    .merge(recordCollectionVS);
export type ChangeCollectionRecordVT = z.infer<typeof changeCollectionRecordVS>;
