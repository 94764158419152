export default function handleSelectedRecord(props) {
    const { handleLinkSearch, selected, path } = props;

    const url = {
        params: `?selected=${selected}`,
    };

    if (path && typeof url.params == "string" && window && window.location) {
        const win = window.open(`${path}/${selected}${url.params}`, "_blank");
        return win.focus();
    }

    if (typeof handleLinkSearch == "function") {
        handleLinkSearch(selected, url);
    }
}
