import { CreditInterface } from "../../../credits.interface";

export default function (
    validationIsOn: boolean,
    isNewCredit: boolean,
    activeCredit: CreditInterface,
    credits: CreditInterface[]
) {
    let valid: boolean = true
    let message: string = ""
    const errorMessage: string = "The Credit listed against this role already exists. Please try again."

    if (!validationIsOn) {
        return {
            valid,
            message
        }
    }

    if (isNewCredit) {
        const creditCheckIndex: number = credits.findIndex(credit => {
            if (!credit?.deleted) {
                if (credit.first_name === activeCredit.first_name 
                    && credit.middle_names === activeCredit.middle_names 
                    && credit.last_name === activeCredit.last_name) {

                    return true
                }
            }
        })

        if (creditCheckIndex !== -1 && credits[creditCheckIndex].role === activeCredit.role) {
            valid = false
            message = errorMessage
        } else {
            valid = true
        }
    }

    if (!isNewCredit) {
        const activeCreditRepeats: CreditInterface[] = credits.filter(
            (credit) =>
                credit.role === activeCredit.role &&
                    credit.role_name === activeCredit.role_name
        );

        if (activeCreditRepeats.length > 1) {
            const conflictingCredit: CreditInterface | undefined = activeCreditRepeats.find(
                (credit) =>
                    credit.local_id !== activeCredit.local_id &&
                    credit.role_name === activeCredit.role_name &&
                    credit.name === activeCredit.name
            );

            valid = conflictingCredit === undefined;
            message = valid ? "" : errorMessage;
        }
    }

    return {
        valid,
        message
    }
}