import { observer } from "mobx-react";
import { CoreSelect } from "../../../../../../core/Form/components/inputs/Select/components/CoreSelect";
import { PrimaryVT } from "../../../../config/types";
import useGlobalData from "../../../../hooks/useGlobalData";
import { useLanguageOptions } from "../../../../hooks/useLanguageOptions";
import { HideFormConditionally } from "./HideFormConditionally";

export const OriginalLanguage = observer(() => {
    const [cmsData, options] = useGlobalData(); // TODO: move to useLanguageOptions
    const languageOptions = useLanguageOptions(options);
    return (
        <HideFormConditionally>
            <CoreSelect<PrimaryVT>
                config={{
                    path: "originalLanguage",
                    labelProps: {
                        text: "Primary Language",
                        isRequired: true,
                    },
                    options: languageOptions,
                    enableSearchbox: true,
                    multiple: false,
                }}
            />
        </HideFormConditionally>
    );
});
