import React from "react";
import Settings from "../Settings";
import "./styles/_menuIconStyles.scss";

interface MenuIconBoilerPlateInterface {
    arrowRight?: boolean;
}

const MenuIconBoilerPlate = (props: MenuIconBoilerPlateInterface) => {
    const { arrowRight = true } = props;

    return (
        <div className="menu-icon-boilerplate">
            <img
                className="menu-icon"
                src={`${Settings.images.path}/svg/three-dots-menu.svg`}
            />

            {arrowRight && (
                <img
                    className="menu-arrow-icon"
                    src={`${Settings.images.path}/svg/arrow-expandable-smallest.svg`}
                />
            )}
        </div>
    );
};

export default MenuIconBoilerPlate;
