import HandleCatalogAvailabilityRequest from "./HandleCatalogAvailabilityRequest";

const HandleUpdateCatalogAvailability = (props) => {
    const { state = {}, options = {} } = props;

    const { handleError, handleSuccess, body } = options;

    HandleCatalogAvailabilityRequest({
        state,
        method: "PUT",
        body,
        handleError,
        handleSuccess,
    });
};

export default HandleUpdateCatalogAvailability;
