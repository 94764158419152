import AssetInterface from "../components/asset/interfaces/AssetInterface";
import AssetTypeInterface from "../components/asset/interfaces/AssetTypeInterface";
import { ASSET_TYPES_CONFIG } from "../configuration";

const GetAssetTypeConfiguration = (
    configuration,
    asset: AssetInterface,
): AssetTypeInterface | undefined => {
    const { type = "", destination_platform = "" } = asset;

    return configuration[ASSET_TYPES_CONFIG].find(
        (item: AssetTypeInterface) => {
            const {
                code = "",
                destinationPlatform = "",
                archived = true,
            } = item;

            return (
                !archived &&
                code.toLowerCase() == type.toLocaleLowerCase() &&
                destinationPlatform.toLowerCase() ==
                    destination_platform.toLowerCase()
            );
        },
    );
};

export default GetAssetTypeConfiguration;
