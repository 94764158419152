import Prefixer from "inline-style-prefixer";

export default function styles(props) {
    const { display = false, maxWidth } = props;

    let styles = {
        scrollbars: {
            position: "fixed",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            minHeight: "100vh",
            zIndex: 9999999999,
            background: "rgba(0,0,0, 0.5)",
            display: display ? "flex" : "none",
            alignItems: "center",
            justifyContent: "center",
        },
        container: {
            width: "100%",
            height: "100%",
            minHeight: "100vh",
            display: display ? "flex" : "none",
            alignItems: "center",
            justifyContent: "center",
            padding: "4rem 2rem",
        },
        wrapper: {
            maxWidth: maxWidth || "32rem",
            width: "100%",
            margin: "auto",
        },
        contentWrapper: {
            boxShadow: "0px 0px 0.1875rem 0px rgba(0,0,0,0.2)",
            margin: "5rem auto",
            maxWidth: "93.75rem",
        },
        content: {
            display: "block",
            padding: "1.125rem",
            background: "#e3e3e3",
            borderTopRightRadius: "0.2rem",
            borderTopLeftRadius: "0.2rem",
        },
        contentNoPadding: {
            padding: 0,
        },
        contentCentralised: {
            textAlign: "center",
        },
        image: {
            display: "block",
            maxWidth: "3rem",
            margin: "0 auto 1.125rem auto",
        },
        title: {
            fontSize: "1.05rem",
            fontWeight: 500,
            lineHeight: 1.49,
            color: "#000000",
        },
        titlePadding: {
            padding: "1.125rem 1.125rem 0 1.125rem",
            display: "block",
        },
        description: {
            fontSize: "1.05rem",
            lineHeight: 1.49,
            color: "#5a5a5a",
            margin: "1rem 0 0",
            wordWrap: "break-word",
        },
        interaction: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "100%",
            color: "white",
            background: "#E4944C",
            padding: "1.125rem",
            textAlign: "right",
            borderBottomRightRadius: "0.2rem",
            borderBottomLeftRadius: "0.2rem",
        },
        interactionError: {
            background: "#B34437",
        },
        interactionSuccess: {
            background: "#588F4A",
        },
        interactionEidr: {
            background: "#576aaf",
        },
        interactionImdb: {
            background: "#e6b91e",
            color: "rgba(1, 1, 1, 0.87)",
        },
        interactionEnrichment: {
            background: "#464646",
            color: "rgba(1, 1, 1, 0.87)",
        },
        action: {
            fontSize: "1.05rem",
            lineHeight: 1.07,
            letterSpacing: "0.03125rem",
            color: "white",
            textTransform: "uppercase",
            cursor: "pointer",
        },
        actionConfirm: {
            marginRight: "1rem",
        },
        actionConfirmImdb: {
            color: "rgba(1, 1, 1, 0.87)",
            fontWeight: 500,
        },
        actionConfirmEnrichment: {
            color: "white",
            fontWeight: 500,
        },
        disabled: {
            opacity: 0.5,
        },
        loading: {
            width: "1rem",
        },
        errorContainerDefault: {
            transition: "0.15s",
        },
        errorContainer: {
            padding: "1.125rem",
            background: "#e8375c",
            color: "white",
        },
    };

    let prefixer = new Prefixer();
    styles = prefixer.prefix(styles);

    return styles;
}
