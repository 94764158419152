import React from 'react'
import _ from "lodash"
import { observer } from "mobx-react";
import { useStores } from "../store/rootStore";
import { classPrefix } from '../utils/constants'
import CoreFormFieldInterface from '../../../../../core/Form/dynamic/interfaces/Field.interface'
import CoreDynamicFormFieldsMapper from '../../../../../components/RecordBuilder/map/dynamicCoreForm/coreDynamicForm'
import CoreDynamicForm from '../../../../../core/Form/dynamic'
import { Box } from '@mui/material';

const FormCreateView = observer(() => {
    const {
        navigationStore, 
        optionsStore, 
        contributorCreateStore,
        permissionsStore,
        validationStore
    } = useStores()

    const coreFormFields: CoreFormFieldInterface[] = CoreDynamicFormFieldsMapper({ 
        category: '', 
        configuration: optionsStore.activeSectionConfig?.fields || [],
        path:  optionsStore.activeSectionConfig?.code,
        disabled: !permissionsStore.userHasCreatePermission,
        checkedKeys: contributorCreateStore.getCheckedKeys()
    })

    if (_.isEmpty(coreFormFields)) {
        return <></>
    }

    return (
        <Box data-testid={`${classPrefix}-${navigationStore.getTabKeyByActiveIndex()}`}
            className={`${classPrefix}-forms-view`}
            sx={{
                ".MuiStack-root": {
                    paddingTop: "0.125rem"
                }
            }}>

            <CoreDynamicForm 
                fields={coreFormFields} 
                data={contributorCreateStore.contributorSectionData || {}}
                onChange={(values: any, item: any) => contributorCreateStore.handleFormChange(values, item)}
                key={`core-dynamic-form-${navigationStore.activeMenuIndex} ${contributorCreateStore.dataKey}`}
                defaultValues={optionsStore.activeSectionDefaultValues}
                originalData={contributorCreateStore.getContributorSectionOriginalData() || {}}
                disabled={false}
                handleErrors={(errors: any) => validationStore.handleContributorFieldErrors(errors)}
                triggerEnabled={true}
                useInitialValues={true}
            />
        </Box>
    )
})

export default FormCreateView