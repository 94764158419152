import { first } from "lodash"
import { CreditInterface, CreditLocalisedData } from "../../../features/Credits/v4/store/interfaces";
import { RecordInterface } from "../model";
import { getContributors, getFirstCharacterName } from "../contributors"

export interface CreditsModelInterface extends CreditInterface {
    role: string,
    contributor_name: string
}

export interface CreditLocalisedDataProps {
    byLocale: CreditLocalisedData | null,
    first: CreditLocalisedData
}

export function getCreditLocalisedDataByLocale(credit: CreditInterface, locale: string): CreditLocalisedData | null {
    return credit?.localised_data?.find((item) => item?.locale == locale)
}

export function getFirstCreditLocalisedData(credit: CreditInterface): CreditLocalisedData {
    return first(credit?.localised_data)
}

export function getCreditLocalisedData(credit: CreditInterface, locale: string): CreditLocalisedDataProps {
    return {
        byLocale: getCreditLocalisedDataByLocale(credit, locale),
        first: getFirstCreditLocalisedData(credit)
    }
}

export function getCreditsFromRecord(record: RecordInterface): CreditsModelInterface[] {
    return getContributors(record)?.map((contributor: any) => {
        const character_name = getFirstCharacterName(contributor)

        return {
            id: contributor.credit_meta_id,
            contributor_name: contributor.name,
            character_name,
            contributor_id: contributor.contributor_meta_id,
            role_id: contributor.role,
            role: contributor.role_name,
            ranking: contributor.ranking,
        }
    })
}