import React, { useEffect, useState } from "react";
import MaskInput from "../../Input/maskInput";

const EditDurationForm = (props) => {
    const { asset, durationKey = "duration", onChange } = props;

    const marginTop = { marginTop: "1.5rem" };
    const [duration, setDuration] = useState(
        asset[durationKey] || "00:00:00:00",
    );
    const [timeError, setError] = useState(false);

    const handleUpdate = (duration) => {
        setDuration(duration);

        const timeInputs = duration.split(":");
        const timeInputError =
            parseInt(timeInputs[1]) > 59 ||
            parseInt(timeInputs[2]) > 59 ||
            parseInt(timeInputs[3]) > 29;

        setError(timeInputError);
        onChange(duration, timeInputError);
    };

    return (
        <div className="meta-ids-container asset-information">
            <div style={marginTop}>
                <MaskInput
                    label={
                        timeError
                            ? "Version Duration is invalid. Format should be: 99:59:59:29"
                            : "Duration"
                    }
                    placeholder="Enter duration"
                    id={"meta-asset-duration-edit"}
                    recordBasic={true}
                    borderHiddenPadding={true}
                    value={duration}
                    handleChange={(value) => {
                        handleUpdate(value);
                    }}
                    maskChar="0"
                    mask="99:99:99:99"
                />
            </div>
        </div>
    );
};

export default EditDurationForm;
