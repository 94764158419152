import _ from "lodash";

export interface CountriesInterface {
    name?: string;
    value?: string;
    text?: string;
    iso_code?: string;
    country_code?: string;
    deleted?: boolean;
}

export function getCountryByCode(
    countries: CountriesInterface[],
    code: string = "",
): CountriesInterface {
    const country: CountriesInterface =
        countries.find(
            (country: CountriesInterface) =>
                country.value == code || country.text == code,
        ) || {};
    return country;
}

export function getCountryCode(country: any): string {
    let result: string = "";

    if (_.isObject(country)) {
        result = country.value! || country.iso_code!;
    }

    if (_.isString(country)) {
        result = country;
    }

    return result;
}
