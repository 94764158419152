import { isEmpty } from "lodash";

export const getStringLengthPx = (text: string, fontSize?: string = "1rem"): number => {
    
    if (isEmpty(text)) {
        return 0
    }
    
    let length = 0
    let virtualString = document.createElement('div');

    virtualString.style.display = 'inline-block'
    virtualString.style.fontSize = fontSize
    virtualString.innerHTML = text

    document.body.appendChild(virtualString);

    length = virtualString.offsetWidth
    
    document.body.removeChild(virtualString);

    return length
}