import React, { useState } from "react";
import ExpandableRow from "../../components/ExpandableRow/v2";
import DoubleColumnContentExpandable from "../../components/Boilerplates/DoubleColumnContent/DoubleColumnContentExpandable";
import { useStore as useGlobalStore } from "../../components/Global/store/store";
import Pagination from "../../core/Pagination";
import Header from "./containers/header";
import "./_styles.scss";
import _ from "lodash";

interface BuilderExpandableContainerInterface {
    components: Array<string>;
    className?: string;
    headerClassName?: string;
    disabled?: boolean;
    sections: Array<any>;
    handleChange: (field: string, value: any) => void;
    data: Array<any>;
    itemProps?: {
        actionsList?: Array<any>;
    };
    pagination?: boolean;
    paginationSize?: number;
}

const BuilderExpandableContainer = ({
    components,
    className,
    headerClassName,
    disabled,
    handleChange,
    sections,
    itemProps,
    data,
    pagination,
    paginationSize,
}: BuilderExpandableContainerInterface) => {
    const [globalState] = useGlobalStore() || [{}];

    const { cmsData = {} } = globalState;

    const { clientFeatures = {} } = cmsData;

    const { readOnlyRights } = clientFeatures;

    const [paginationCurrentPage, setPaginationCurrentPage] =
        useState<number>(1);
    const featureDisabled: boolean = readOnlyRights ? true : disabled!;

    function getDataToShow() {
        let result = data;

        if (pagination && paginationSize) {
            const chunks: Array<any> = _.chunk(data, [paginationSize]);
            result = chunks[paginationCurrentPage - 1] || [];
        }

        return result;
    }

    const dataToShow: Array<any> = getDataToShow();

    return (
        <div className="builder-container">
            <div className="content-container">
                {dataToShow.map((values, index) => {
                    return (
                        <ExpandableRow
                            header={
                                <Header
                                    components={components}
                                    itemProps={{
                                        ...itemProps,
                                        disabled: featureDisabled,
                                        values,
                                    }}
                                />
                            }
                            body={
                                <>
                                    {sections.map(
                                        (section: any, index: number) => {
                                            return (
                                                <DoubleColumnContentExpandable
                                                    fields={section.fields}
                                                    disabled={featureDisabled}
                                                    handleChange={(
                                                        field: string,
                                                        value: any,
                                                    ) =>
                                                        handleChange(
                                                            field,
                                                            value,
                                                        )
                                                    }
                                                    headerText={
                                                        section.displayName
                                                    }
                                                    rowBodyStyles={{
                                                        padding: 0,
                                                        borderRadius:
                                                            "0.375rem",
                                                    }}
                                                    values={values}
                                                    key={index}
                                                />
                                            );
                                        },
                                    )}
                                </>
                            }
                            className={className}
                            headerClassName={headerClassName}
                            arrowIconType="large"
                            doubleArrows={true}
                            key={index}
                        />
                    );
                })}

                {pagination &&
                    paginationSize &&
                    data.length > paginationSize && (
                        <div className="footer">
                            <Pagination
                                pageSize={paginationSize}
                                page={paginationCurrentPage}
                                length={data.length}
                                handleChange={(page: number) =>
                                    setPaginationCurrentPage(page)
                                }
                            />
                        </div>
                    )}
            </div>
        </div>
    );
};

export default BuilderExpandableContainer;
