import { CoreSelectOptionType } from "../../../core/Form/components/inputs/Select/types/CoreSelectOptionType";
import useGlobalData from "./useGlobalData";

export function useCountries(): CoreSelectOptionType[] {
    const [cmsData, options] = useGlobalData();
    return options?.client_managed_countries?.map((c) => {
        return {
            label: c.name,
            value: c.iso_3166_1_alpha_3,
        };
    });
}
