import { createTheme } from "@mui/material/styles";
import { gridClasses } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
    COLOR_BLUE,
    COLOR_CELL_CONTENT,
    COLOR_COLUMN_HEADER,
    COLOR_ROW_BORDER,
    COLOR_TABLE_BORDER,
} from "../Table/constants";
import type {} from "@mui/x-data-grid-pro/themeAugmentation";

// A custom theme for this app
const heightInRem = "2.25rem !important";

export const theme = createTheme({
    spacing: (factor: number) => `${0.25 * factor}rem`,
    typography: {
        htmlFontSize: 16,
    },

    components: {
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    borderRadius: "0.375rem",
                    overflow: "hidden",
                    position: "relative",
                    "&.active": {
                        "&:before": {
                            content: '""',
                            position: "absolute",
                            top: 0,
                            right: 0,
                            width: "2rem",
                            height: "100%",
                            background:
                                "linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.2) 100%)",
                            zIndex: 1,
                        },
                    },
                    ".scrollArea": {
                        height: "fit-content !important",
                    },
                },
                main: {},
                columnHeaders: {
                    borderRadius: "0",
                    border: 0,
                    minHeight: heightInRem,
                    maxHeight: heightInRem,
                    lineHeight: heightInRem,
                    zIndex: 3,
                },
                pinnedColumnHeaders: {
                    backgroundColor: "#EBF0F9",
                },
                pinnedColumns: {
                    "&.MuiDataGrid-pinnedColumns--right": {
                        ".MuiDataGrid-cell[data-field=actions]": {
                            paddingLeft: "0.625rem",
                        },
                    },
                },
                columnHeader: {
                    height: heightInRem,
                    ".MuiIconButton-root": {
                        borderRadius: "0.375rem",
                        margin: "0 0 0 0.3125rem",
                    },
                    "&:focus-within": {
                        outline: "none",
                    },
                    "&.MuiDataGrid-columnHeaderCheckbox": {
                        width: "2.5rem !important",
                        minWidth: "2.5rem !important",
                        maxWidth: "2.5rem !important",
                    },
                },
                columnHeaderTitle: {
                    color: COLOR_COLUMN_HEADER,
                    fontSize: "0.875rem",
                    lineHeight: "1",
                    fontWeight: "500",
                },
                columnSeparator: {
                    display: "none",
                },
                virtualScroller: {
                    marginTop: "0 !important",
                    paddingTop: "2.25rem",
                    "&::-webkit-scrollbar": {
                        width: "0.3125rem !important",
                        height: "0.4375rem",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        borderRadius: "0.625rem",
                    },
                    "&:hover": {
                        "&::-webkit-scrollbar": {
                            display: "block",
                        },
                        "&::-webkit-scrollbar-track": {
                            backdropFilter: "blur(0.3125rem)",
                            background: "rgba(0,0,0,0.05)",
                        },
                        "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#7a7a7a",
                        },
                    },
                    ".__react_component_tooltip.place-top": {
                        marginTop: "-0.125rem",
                    },
                },
                rowReorderCell: {
                    ".MuiSvgIcon-root": {
                        fill: "rgba(77,77,77,0.7)",
                    },
                    "&:hover": {
                        ".MuiSvgIcon-root": {
                            fill: "rgba(77,77,77,1)",
                        },
                    },
                },
                cell: {
                    minHeight: heightInRem,
                    maxHeight: heightInRem,
                    padding: "0 0.625rem",
                    "&[data-field=actions]": {
                        paddingLeft: "0",
                        paddingRight: "0",
                    },
                    "&:focus-within": {
                        outline: "none",
                    },
                    ".copy-tooltip-container": {
                        ".tooltip-icon-state": {
                            display: "flex",
                        },
                        img: {
                            marginTop: "0.0625rem",
                        },
                        ".__react_component_tooltip.place-top": {
                            marginTop: "-0.125rem",
                        },
                    },
                    ".MuiDataGrid-rowReorderCell--draggable": {
                        color: COLOR_CELL_CONTENT,
                    },
                    ".MuiDataGrid-actionsCell": {
                        gridGap: "0.3125rem",
                        ".MuiButtonBase-root": {
                            height: "1.5rem",
                            padding: "0.3125rem",
                            width: "1.5rem",
                            img: {
                                opacity: "0.7",
                                height: "0.875rem"
                            },
                            "&:hover": {
                                background: "#E0E0E0",
                                borderRadius: "0.25rem",
                                img: {
                                    opacity: "1",
                                },
                            },
                        },
                    },
                    ".fabric--contributor-name": {
                        marginLeft: "0.625rem",
                    },
                    "&.core-table-cell-error": {
                        border: "1px solid rgba(214, 70, 94, 0.75) !important",
                    },
                },
                cellCheckbox: {
                    minWidth: "2.5rem !important",
                    maxWidth: "2.5rem !important",
                },
                row: {
                    backgroundColor: "transparent",
                    minHeight: heightInRem,
                    maxHeight: heightInRem,
                    "&:last-child": {
                        ".MuiDataGrid-cell": {
                            border: "0",
                        },
                    },
                    "&:hover": {
                        backgroundColor: COLOR_ROW_BORDER,
                    },
                },
                toolbarContainer: {
                    background: "#F7F6F6",
                    borderRadius: "0.3125rem",
                    margin: "0.625rem 0",
                    padding: "0.4375rem",
                    ".MuiFormControl-root": {
                        background: "#FFF",
                        borderRadius: "0.1875rem",
                        padding: "0.3125rem",
                    },
                    ".MuiInputBase-root": {
                        ".MuiSvgIcon-root": {
                            color: "#8D8D8D",
                            fontSize: "1rem",
                        },
                        ".MuiButtonBase-root": {
                            padding: 0,
                        },
                        "&:before": {
                            display: "none",
                        },
                        "&:after": {
                            display: "none",
                        },
                    },
                    ".MuiInputBase-input": {
                        fontSize: "0.875rem",
                        height: "unset",
                        lineHeight: "normal",
                        padding: 0,
                    },
                },
                footerContainer: {
                    backgroundColor: "#F5F5F5",
                    minHeight: "1.875rem",
                    justifyContent: "space-between",
                },
                menu: {
                    ".MuiPaper-root": {
                        padding: "0.3125rem",
                        borderRadius: "0.25rem",
                        border: "1px solid rgba(77, 77, 77, 0.10)",
                        boxShadow: "2px 3px 5px 0px rgba(79, 94, 116, 0.15)"
                    },
                    ".MuiList-root": {
                        padding: 0,
                        ".MuiMenuItem-root": {
                            color: "rgba(47, 47, 47, 0.87)",
                            padding: '0.5rem',
                            lineHeight: 'normal',
                            fontSize: '0.875rem',
                            borderRadius: '3px'
                        }
                    }
                },
                panel: {
                    ".MuiPaper-root": {
                        padding: "0.3125rem",
                        borderRadius: "0.25rem",
                        border: "1px solid rgba(77, 77, 77, 0.10)",
                        boxShadow: "2px 3px 5px 0px rgba(79, 94, 116, 0.15)"
                    },
                },
                panelFooter: {
                    ".MuiButtonBase-root": {
                        color: "#286CD2"
                    }
                },
                filterForm: {
                    ".MuiFormLabel-root": {
                        "&.Mui-focused": {
                            color: "#286CD2"
                        }
                    },
                    ".MuiInputBase-root": {
                        color: "rgba(47, 47, 47, 0.87)",
                        fontSize: "0.875rem",
                        "&:after": {
                            borderBottom: "2px solid #286CD2"
                        }
                    }
                }
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    background: "transparent !important",
                    ".MuiSvgIcon-root": {
                        fontSize: "1.375rem",
                    },
                    ".MuiTouchRipple-root": {
                        display: "none",
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: "0.25rem",
                    height: "1.5rem",
                    lineHeight: "normal",
                    padding: "0.25rem 0.5rem",
                    ".MuiChip-label": {
                        padding: 0,
                    },
                    ".MuiChip-icon": {
                        order: 2,
                        margin: "0 0 0 0.3125rem",
                    },
                    "&.fabric-chip--ranking": {
                        fontSize: "0.75rem",
                        height: "1.375rem",
                        width: "1.5rem",
                        padding: 0,
                        margin: "0 0.25rem 0 0",
                        textAlign: "center",
                    },
                },
            },
        },
        MuiPagination: {
            styleOverrides: {
                root: {
                    padding: "0.375rem 0",
                },
                ul: {
                    "& > li": {
                        "&:hover": {
                            "& .MuiButtonBase-root": {
                                "&.Mui-disabled": {
                                    background: "transparent",
                                },
                            }
                        }
                    }
                }
            },
        }
    },
});
