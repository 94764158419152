import React from "react";
import Component from "./index";

const RightTransparentLeftColor = (props) => {
    const {
        leftText,
        rightText,
        styles = {},
        color = "rgb(135, 145, 142)",
        textColor = "white",
        leftColor,
        lineHeight = 1,
        tagStyles = {},
    } = props;

    return (
        <Component
            leftText={leftText}
            rightText={rightText}
            styles={styles}
            leftTagStyles={{
                borderStyle: "solid",
                borderColor: color,
                borderWidth: `0.065rem`,
                borderLeft: 0,
                borderRight: 0,
                background: color,
                color: leftColor || textColor,
                lineHeight,
                ...tagStyles,
            }}
            rightTagStyles={{
                background: "transparent",
                border: `0.065rem solid ${color}`,
                color,
                lineHeight,
                ...tagStyles,
            }}
        />
    );
};

export default RightTransparentLeftColor;
