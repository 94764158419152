import React from "react";
import AssetTrafficLight from "./index";
import Settings from "../Settings";

const AssetTrafficLightText = (props) => {
    const { text, status } = props;

    return (
        <AssetTrafficLight
            status={status}
            square={true}
            text={text}
            style={{
                ...Settings.components.actionButton.container,
                ...Settings.components.actionButton.text,
                width: "auto",
                display: "flex",
                alignItems: "center",
            }}
        />
    );
};

export default AssetTrafficLightText;
