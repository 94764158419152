import { gql } from "@apollo/client";
import { schemaGetAll as assetsTypesSchemaGetAll } from "./assetsTypes";
import { schemaGetAll as assetsPlatformsGetAll } from './assetsPlatforms';
import { schemaGetAll as assetsCodesGetAll } from './assetsCodes';
import { schemaGetAll as descriptionsSchemaGetAll } from "./descriptions";
import { schemaGetAll as idsSchemaGetAll } from "./ids";
import { schemaGetAll as rightsSchemaGetAll } from "./rights";
import { schemaGetAll as schedulingSchemaGetAll } from "./scheduling";
import { schemaGetAll as schedulingSystemsSchemaGetAll } from "./schedulingSystem";
import { schemaGetAll as schedulingPlatformsSchemaGetAll } from "./schedulingPlatform";
import { schemaGetAll as titlesSchemaGetAll } from "./titles";
import { schemaGetAll as originationSchemaGetAll } from './origination';
import { schemaGetAll as numberingSchemaGetAll } from './numbering'
import { schemaGetAll as contributorSectionsGetAll } from './contributorSections';

export const getAllEntities = gql`
    query {
        ${assetsTypesSchemaGetAll}
        ${assetsPlatformsGetAll}
        ${assetsCodesGetAll}
        ${descriptionsSchemaGetAll}
        ${idsSchemaGetAll}
        ${rightsSchemaGetAll}
        ${originationSchemaGetAll}
        ${titlesSchemaGetAll}
        ${schedulingSchemaGetAll}
        ${schedulingSystemsSchemaGetAll}
        ${schedulingPlatformsSchemaGetAll}
        ${contributorSectionsGetAll}
        ${numberingSchemaGetAll}
    }
`;

const getAllMap = {
    queryAll: getAllEntities,
    dataPath: "",
};

export default getAllMap;
