import React, { useMemo } from 'react'
import { Divider, Typography } from "@mui/material";
import { CoreFormStack } from "../../components/layout/CoreForm.stack"
import FieldInterface from '../interfaces/Field.interface'
import LayerInterface from '../interfaces/Layer.interface'
import Layer from "./layer"
import { sortBy } from 'lodash'
import { observer } from "mobx-react"

type Props = {
    fields: FieldInterface[],
    stackStyles?: any,
    disabled: boolean
}

type RenderTitleProps = {
    title: string,
    index: number
}

function getTitleFromLayer(layer: LayerInterface): string | null {
    const field: FieldInterface = layer?.fields?.find((field: FieldInterface) => field.groupName)
    return field?.groupName || null
}

const RenderTitle = ({ title, index }: RenderTitleProps) => {
    return (
        <>
            {index !== 0 &&
                <Divider />
            }

            <Typography 
                variant="h1" 
                component="h2"
                sx={{
                    fontSize: "1rem",
                    fontWeight: 500,
                    color: '#313E52'
                }}>

                {title}
            </Typography>
        </>
    )
}

const CoreDynamicFormBuilder = observer(({ fields = [], stackStyles, disabled }: Props) => {
    const getLayers = (): LayerInterface[] => {
        let layers: LayerInterface[] = []

        fields.forEach((field: FieldInterface) => {
            const searchLayerHook = (layer: LayerInterface) => layer.level == field.layer.level && layer.columns == field.layer.columns
            const layer = layers.find((layer: LayerInterface) => searchLayerHook(layer))
            
            if (layer) {
                layers = layers.map((layer: LayerInterface) => {
                    if (searchLayerHook(layer)) layer.fields.push(field)
                    return layer
                })
            } else {
                let layer: LayerInterface = {
                    fields: [field],
                    level: field.layer.level,
                    columns: field.layer.columns,
                }

                const title: string | null = getTitleFromLayer(layer)

                if (!layers.find((layer: LayerInterface) => layer.title == title)) {
                    layer.title = title
                }

                layers.push(layer)
            }
        })

        return sortBy(layers, ['level'])
    }

    const layers = useMemo(() => getLayers(), [disabled])

    return (
        <div data-testid={"fabric-dynamic-form-builder"} className="fabric-dynamic-form-builder">
            <CoreFormStack customStyles={stackStyles ? stackStyles : { paddingTop: "1.5rem"}}>
                {layers.map((layer: LayerInterface, index: number) => {
                    return (
                        <>
                            {layer.title &&
                                <RenderTitle 
                                    title={layer.title} 
                                    index={index}
                                />
                            }

                            <Layer 
                                layer={layer} 
                                key={`dynamic-form-layer-${index}`}
                            />
                        </>
                    )
                })}
            </CoreFormStack>
        </div>
    )
})

export default CoreDynamicFormBuilder
