import GeneralValidation from "../../components/Validation/General";
import GameValidation from "../../components/Validation/Game";
import SeasonValidation from "../../components/Validation/Season";
import EpisodeValidation from "../../components/Validation/Episode";
import StorylineValidation from "../../components/Validation/Storyline";
import CollectionValidation from "../../components/Validation/Collection";
import CategoryTags from "../../components/CategoryTags";

const HandleRecordValidation = (props) => {
    const {
        data = {},
        copy = {},
        options = {},
        state = {},
        key = false,
        cmsData = {},
        recordBuilder = {}
    } = props;

    const { set } = state;

    const status = "alert";
    const description =
        copy.valueExceedsMaximum ||
        "The value entered either exceeds the maximum character count or contains unsupported / special characters. You will need to either update or delete this metadata to save this record.";

    const { category, category_name } = data;

    const optionsData = {
        copy,
        data,
        key,
        status,
        description,
        options,
        cmsData,
        builderConfig: recordBuilder
    };

    const categoryKey = category || category_name;

    let response = {};

    switch (categoryKey) {
        case CategoryTags.Game.short:
            response = GameValidation(optionsData);
            break;
        case CategoryTags.Season.short:
            response = SeasonValidation(optionsData);
            break;
        case CategoryTags.Episode.short:
            response = EpisodeValidation(optionsData);
            break;
        case CategoryTags.Storyline.short:
            response = StorylineValidation(optionsData);
            break;
        case CategoryTags.Collection.short:
            response = CollectionValidation(optionsData);
            break;
        case CategoryTags.ConnectCollection.short:
            response = CollectionValidation(optionsData);
            break;
        case CategoryTags.LocalisationOrder.short:
            response = CollectionValidation(optionsData);
            break;
        case CategoryTags.Report.short:
            response = CollectionValidation(optionsData);
            break;
        case CategoryTags.FranchiseCollection.short:
            response = CollectionValidation(optionsData);
            break;
        default:
            response = GeneralValidation(optionsData);
    }

    const { result, validationKeys, notification } = response;

    set({
        validationKeys,
        validationNotification: notification,
    });

    return result;
};

export default HandleRecordValidation;
