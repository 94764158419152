export const apiCreditFields: string[] = [
    'id',
    'character_names',
    'locale',
    'localised_data',
    'contributor_id',
    'notes',
    'role_id',
    'deleted'
]

export const apiCreditUpdateFields: string[] = [
    'id',
    'notes',
    'localised_data',
    'deleted'
]

export const apiCreditCreateFields: string[] = [
    'contributor_id',
    'role_id',
    'notes',
    'localised_data',
    'deleted'
]