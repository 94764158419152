import React from "react";
import { isEmpty } from "lodash"
import { observer } from "mobx-react";
import { useStores } from "../store/rootStore";
import AssetsEmptyState from "../../../components/RecordBuilder/views/EmptyState";

const EmptyState = observer(() => {
    const { ancillariesStore } = useStores() || {};

    if (!isEmpty(ancillariesStore.multiLevelViewData)) {
        return <></>
    }

    return (
        <AssetsEmptyState 
            className="ancillaries-empty-container"
            title="No Ancillaries added for this Record"
        />
    )
});

export default EmptyState;
