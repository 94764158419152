
import React from "react";
import Input from "../../../interfaces/Input.interface";
import { AutocompleteContributors as Autocomplete } from "../../../../../Autocomplete/AutocompleteContributors";
import { observer } from "mobx-react";
import { useStores } from '../../../store/rootStore'

export const AutocompleteContributors = observer(({ field }: Input) => {
    const { formStore } = useStores()

    return (
        <div data-testid={"fabric-dynamic-form-autocomplete-contributors-input"}>
            <Autocomplete
                disabled={field.validation?.readOnly?.enabled || formStore.disabled}
                path={field.key}
                labelProps={{
                    text: field.label,
                    isRequired: field.validation?.required?.enabled,
                }}
            />
        </div>
    )
})