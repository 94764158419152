import React, { useEffect } from "react";
import _ from "lodash";
import { observer } from "mobx-react";
import { useStores } from "../../../features/Awards/v2/store/rootStore";
import AwardsContainer from "./container/Awards.container";
import { AwardInterface } from "../interfaces/awards.interfaces";

interface Props {
    disabled: boolean;
    data: AwardInterface[];
    defaultScript: string;
    handleChange: (item: any) => void;
    cmsData: any;
    contributors: any[];
}

const Awards: React.FC<Props> = observer((props: Props) => {
    const { awardsStore } = useStores() || {};
    const rootStore = useStores();

    const {
        disabled = true,
        data = [],
        defaultScript,
        handleChange = () => {},
        cmsData = {},
        contributors = [],
    } = props;

    useEffect(() => {
        awardsStore.initialize({ handleChange, data });
        rootStore.initialize(defaultScript);
    }, []);

    return (
        <div>
            <AwardsContainer
                disabled={disabled}
                cmsData={cmsData}
                contributors={contributors}
            />
        </div>
    );
});

export default Awards;
