import Snackbar, { SnackbarProps } from "@mui/material/Snackbar";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface CoreSnackbarInterface extends SnackbarProps {
    handleClose: Function;
    config?: {};
}

export default function CoreSnackbar(props: CoreSnackbarInterface) {
    const { handleClose = () => {}, autoHideDuration = 6000, action } = props;

    const defaultAction = (
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => handleClose()}
        >
            <CloseIcon fontSize="small" />
        </IconButton>
    );

    return (
        <Snackbar
            sx={{ zIndex: 100000000 }}
            {...props}
            onClose={() => handleClose()}
            action={action ?? defaultAction}
            autoHideDuration={autoHideDuration}
            anchorOrigin={{
                horizontal: "center",
                vertical: "bottom",
            }}
        />
    );
}
