import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

export const TooltipNoBG = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "transparent",
        padding: 0,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "rgba(28, 44, 96, 1)",
    },
}));
