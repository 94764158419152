import { Box, BoxTypeMap, Stack } from "@mui/material";
import { CoreSelectOptionType } from "../types/CoreSelectOptionType";
import { CoreSelectListItem } from "./CoreSelect.list.item";
import { TooltipNoBG } from "../../../../../Tooltip/TooltipNoBG";
import { useEffect, useRef } from "react";
import { CoreFormDivider } from "../../../styled/CoreForm.dvider";

export type CoreSelectListProps = {
    options: CoreSelectOptionType[];
    selectedItems: string[] | string; // could be string only when 'isSingleSelect' is true
    onChange: (...event: any[]) => void;
    onBlur: any;
    ref: any;
    isSingleSelect?: boolean;
    enableScrollToSelected?: boolean;
    enableNoneSelect?: boolean;
    disabled?: boolean;
    showRightAdornment?: boolean;
};

const toggleOptionInArray = (optionValue: string, selectedArray: string[]) => {
    // Check if the selected option is already in the array
    const isSelected = selectedArray?.includes(optionValue);

    // If the selected option is already in the array, remove it to deselect it
    // If it's not in the array, add it to the array to select it
    if (isSelected) {
        return selectedArray?.filter((value) => value !== optionValue);
    }
    return [...selectedArray, optionValue];
};

export function CoreSelectList({
    options,
    selectedItems = [],
    onChange,
    onBlur,
    ref,
    isSingleSelect,
    enableScrollToSelected,
    enableNoneSelect,
    disabled,
    showRightAdornment
}: CoreSelectListProps) {
    const selectedRef = useRef(null);

    const handleScrollToSelected = (selectedRef) => {
        if (selectedRef?.current) {
            selectedRef.current.scrollIntoView({
                block: "center",
            });
        }
    };

    useEffect(() => {
        enableScrollToSelected && handleScrollToSelected(selectedRef);
    }, []);

    if (enableNoneSelect && isSingleSelect) {
        options = [
            {
                label: "None",
                value: "",
            },
            ...options,
        ];
    }

    // This function toggles an option in an array of selected options
    const fixedItemOption = options?.find(
        (option) => option?.isFixedFooterNewItemOption,
    );

    // This function handles a change event on a form input element
    const handleOnChange = (optionValue: string) => {
        // Check if the form input element allows multiple selections
        if (!isSingleSelect) {
            // For a multiple select element, get the currently selected options as an array
            const selectedArray = Array.isArray(selectedItems) ? selectedItems : [];

            // Toggle the selected option in the array
            const newSelected = toggleOptionInArray(optionValue, selectedArray);

            // Call the onChange function with the new selected options array to reflect the user's selection in the UI
            if (onChange) {
                onChange(newSelected);
            }
        } else if (onChange) {
            onChange(optionValue); // For a single select element, directly call the onChange function with the selected value
        }
    };

    return (
        <Stack rowGap={1}>
            {options?.map((option) => {
                if (!option?.isFixedFooterNewItemOption) {
                    let FinalListItem = (
                        <Box
                            key={option?.value}
                            sx={{
                                "& .MuiChip-root": {
                                   margin: "0",
                                },
                                "& .MuiChip-label": {
                                    fontSize: "0.75rem",
                                },
                                "& .MuiBox-root": {
                                    padding: 0,
                                },
                            }}
                        >
                            <CoreSelectListItem
                                option={option}
                                handleOnChange={() => handleOnChange(option?.value)}
                                selected={Array.isArray(selectedItems) ? selectedItems : []}
                                onBlur={onBlur}
                                ref={ref}
                                isSingleSelect={isSingleSelect}
                                selectedRef={selectedRef}
                                disabled={disabled}
                                showRightAdornment={showRightAdornment}
                            />
                        </Box>
                    );

                    if (option?.tooltip) {
                        FinalListItem = (
                            <TooltipNoBG
                                title={option?.tooltip}
                                placement="right"
                                arrow
                            >
                                {FinalListItem}
                            </TooltipNoBG>
                        );
                    }
                        
                    return FinalListItem;
                }
            })}

            {fixedItemOption && (
                <div style={fixedItemOption?.fixedFooterNewItemOptionStyles}>
                    <CoreFormDivider variant="middle" />
                    <Box key={fixedItemOption?.value}>
                        <CoreSelectListItem
                            option={fixedItemOption}
                            handleOnChange={() =>
                                handleOnChange(fixedItemOption?.value)
                            }
                            selected={selectedItems}
                            onBlur={onBlur}
                            ref={ref}
                            isSingleSelect={isSingleSelect}
                            selectedRef={selectedRef}
                            disabled={disabled}
                        />
                    </Box>
                </div>
            )}
        </Stack>
    );
}

CoreSelectList.defaultProps = {
    isSingleSelect: true,
};
