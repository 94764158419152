import deepcopy from "deepcopy";
import _ from "lodash";
import CategoryTags from "../CategoryTags";

export function SortCollectionTitlesFull(data) {
    let sortedData = deepcopy(data);

    if (!_.isEmpty(sortedData) && Array.isArray(sortedData)) {
        sortedData = sortedData.filter((item) => !item.deleted);
        sortedData = _.sortBy(sortedData, ["show_title"]);
        sortedData = _.sortBy(sortedData, ["season_number"]);
        sortedData = _.sortBy(sortedData, ["episode_number"]);
        sortedData = _.sortBy(sortedData, ["storyline_number"]);
        sortedData = _.sortBy(sortedData, ["category"]);
    }

    return sortedData;
}

export function SortCollectionByShowSeason(data) {
    const categories = {
        [CategoryTags.Show.short]: 1,
        [CategoryTags.Season.short]: 2,
        [CategoryTags.Episode.short]: 3,
        [CategoryTags.ConstructedEpisode.short]: 3,
        [CategoryTags.Storyline.short]: 4,
        [CategoryTags.Special.short]: 5,
    };

    const compareCategories = (left, right) => {
        return (
            categories[left.category || left.category_name] -
            categories[right.category || right.category_name]
        );
    };

    let sortedData = deepcopy(data);

    if (!_.isEmpty(sortedData) && Array.isArray(sortedData)) {
        sortedData = sortedData.sort(compareCategories);

        sortedData = sortedData.sort((a, b) => {
            if (
                Number.isInteger(a.season_number) &&
                Number.isInteger(b.season_number)
            ) {
                return a.season_number - b.season_number;
            }
        });
    }

    return sortedData;
}

export default function SortCollectionTitles(data) {
    let sortedData = deepcopy(data);

    if (!_.isEmpty(sortedData) && Array.isArray(sortedData)) {
        sortedData = sortedData.filter((item) => !item.deleted);

        sortedData = sortedData.sort((a, b) => {
            if (Number.isInteger(a.order) && Number.isInteger(b.order)) {
                return a.order - b.order;
            }
        });
    }

    return sortedData;
}

export function SortTitlesInView(data) {
    let result = data;

    if (data.length && data[0].order !== null) {
        result = data.sort((first, second) => {
            return first.order < second.order ? -1 : 0;
        });
    }

    return result;
}
