import React from "react";
import Settings from "../Settings";
import "./styles/_styles.scss";

const EidrMatchButton = (props) => {
    const { id = "", loading, onClick = () => {} } = props;

    let leftIcon = `${Settings.images.path}/svg/eidr-link-white.svg`;

    if (loading) {
        leftIcon = Settings.images.loadingWhite;
    }

    return (
        <div className="meta-eidr-match-button" id={id} onClick={onClick}>
            <img src={leftIcon} />
            <img src={`${Settings.images.path}/svg/eidr-white.svg`} />
        </div>
    );
};

export default EidrMatchButton;
