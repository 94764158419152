import React from "react";
import { SectionInterface } from "./interface";
import Cycles from "../../src/Cycles";
import CyclesV2 from "../../src/CyclesV2";
import { getSectionCommonProps } from "../../actions";

const SectionCycles = (props: SectionInterface) => {
    const { 
        cmsData = {} 
    } = props;

    const {
        clientFeatures = {}
    } = cmsData

    const { cyclesv2 } = clientFeatures;

    if (cyclesv2) {
        return <CyclesV2 {...getSectionCommonProps({ ...props })} {...props} />;
    }

    return <Cycles {...getSectionCommonProps({ ...props })} {...props} />;
};

export default SectionCycles;
