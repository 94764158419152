import { SET_URL_COMPONENTS } from "../actions/action_types";
import { defaultUrlComponents } from "../initialState";

export default function (
    state = { urlComponents: defaultUrlComponents },
    action,
) {
    switch (action.type) {
        case SET_URL_COMPONENTS:
            return Object.assign({}, state, {
                urlComponents: action.urlComponents,
            });
    }

    return state;
}
