import deepcopy from "deepcopy";
import validateString from "../Helpers/validateString";
import GetLocaleNameFromOptions from "../GetLocaleNameFromOptions";

export default function ValidateAlternativeTitle(
    result = {},
    optionsData = {},
) {
    let response = deepcopy(result);

    const {
        data = {},
        copy = {},
        status,
        description,
        options = {},
    } = optionsData;

    const { titles = [] } = data;

    if (Array.isArray(titles)) {
        const alternativeTitles = titles.filter(
            (ot) => ot.type == "alternative" && ot.title,
        );

        if (Array.isArray(alternativeTitles)) {
            alternativeTitles.forEach((item) => {
                if (!validateString(item.title, 190) && item.title) {
                    const validationLocaleName = GetLocaleNameFromOptions(
                        item.locale,
                        options,
                    );
                    const validationLocaleCode = item.locale;

                    response.result = false;
                    response.notification = {
                        title: `${
                            copy.checkOriginalTitle ||
                            "Please check original title"
                        } ${validationLocaleName}`,
                        description:
                            "Maximum Age field OR Minimum Age field is required",
                        status,
                    };

                    response.validationKeys = result.validationKeys.concat([
                        `alternative_title_${validationLocaleCode}`,
                    ]);
                }
            });
        }
    }

    return response;
}
