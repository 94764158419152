const celebrities = [
    {
        name: "Alex Wolff",
        confidence: 100,
        imdb: "http://imdb.com/name/nm1842974",
    },
    {
        name: "Dwayne Johnson",
        confidence: 99.99,
        imdb: "http://imdb.com/name/nm0425005",
    },
    {
        name: "Kevin Hart",
        confidence: 99.99,
        imdb: "http://imdb.com/name/nm0366389",
    },
    {
        name: "Lisa Lambe",
        confidence: 99.94,
        imdb: "http://imdb.com/name/nm1550230",
    },
    {
        name: "Nick Jonas",
        confidence: 91.28,
        imdb: "http://imdb.com/name/nm2679917",
    },
    {
        name: "Karen Gillan",
        confidence: 76.18,
        imdb: "http://imdb.com/name/nm2394794",
    },
    {
        name: "Jack Black",
        confidence: 72.24,
        imdb: "http://imdb.com/name/nm0085312",
    },
    {
        name: "Camryn Grimes",
        confidence: 63.85,
        imdb: "http://imdb.com/name/nm0342190",
    },
];

export default celebrities;
