const MapBubbleDataToArrayOfStrings = ({ data = [] }) => {
    let result = [];

    data.map((item) => {
        if (typeof item === "string") {
            result.push(item);
        }

        if (typeof item === "object" && !item.deleted) {
            if (item.text) {
                result.push(item.text);
            }

            if (
                item.value &&
                typeof item.value === "object" &&
                item.value.text
            ) {
                result.push(item.value.text);
            }
        }
    });

    return result;
};
export default MapBubbleDataToArrayOfStrings;
