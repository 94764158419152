import _ from "lodash";
import SendRequest from "../../../components/SendRequest";

const HanldeFetchDataListRequest = (props) => {
    const {
        apiUrls = {},
        onError = () => {},
        onSuccess = () => {},
        list,
        type,
    } = props;

    const { getDataList = "" } = apiUrls;

    let url = getDataList.replace("{list}", list);
    if (type) {
        url = `${url}?type=${type}`;
    }

    const options = {
        url,
        method: "GET",
        testingData: [],
    };

    const handleError = (error) => {
        console.log(error);
        onError(error);
    };

    try {
        SendRequest(
            options,
            (response) => {
                const { message, status, data } = response;

                if (status == 200) {
                    return onSuccess(data);
                }

                handleError(message);
            },
            (error) => {
                handleError(error);
            },
        );
    } catch (e) {
        handleError(e);
    }
};

export default HanldeFetchDataListRequest;
