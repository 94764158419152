import _ from 'lodash'

const GetContributorFieldValue = (contributor, field, script) => {
    if (script == 'ENG') {
        return contributor[field]
    }

    if (contributor && _.has(contributor, 'script_names') && Array.isArray(contributor.script_names)) {
        const scripts = contributor.script_names.find(item => item.code.toLowerCase() == script.toLowerCase()) || {}
        return scripts[field] || ''
    }

    return ''
}

export default GetContributorFieldValue