import deepcopy from 'deepcopy'

const AssignRole = props => {
    const {
        item,
        value,
    } = props

    let itemCopy = deepcopy(item)
    itemCopy.character_name = null
    itemCopy.ranking = false
    itemCopy.role_name = null

    if(typeof value == 'object') {
        itemCopy.role = parseInt(value.value || value.id)
        itemCopy.role_name = value.text || value.name
    }

    return itemCopy
}

export default AssignRole