import { SET_SAVING_RECORD } from "../actions/action_types";
import { defaultSavingRecord } from "../initialState";

export default function (
    state = { savingRecord: defaultSavingRecord },
    action,
) {
    switch (action.type) {
        case SET_SAVING_RECORD:
            return Object.assign({}, state, {
                savingRecord: action.savingRecord,
            });
    }

    return state;
}
