import React from "react";
import "../styles/_styles.scss"
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import { DialogContent, DialogTitle, IconButton, styled } from "@mui/material";

interface ImagePreviewModalInterface {
    open: boolean,
    image: string | null,
    handleClose: Function
}

const DarkModal = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
        background: "#3F3F3F",
        borderRadius: "0.71rem",
        maxWidth: '60rem'
    },

    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },

    "& .MuiDialogTitle-root": {
        padding: theme.spacing(1),
        display: "flex",
        justifyContent: "flex-end",
    },

    "& .MuiSvgIcon-root": {
        color: "#fff",
        fontSize: "1.625rem",
        width: "1.5rem",
        height: "1.5rem"
    },

    "& .record-img-full-screen": {
        width: "100%",
        borderRadius: "0.57rem",
    },

    "& .MuiButtonBase-root": {
        borderRadius: "0.25rem",
        padding: "0.25rem"
    }
}));

const ImagePreviewModal = (props: ImagePreviewModalInterface) => {
    const {
        open,
        image,
        handleClose = () => {}
    } = props

    return (
        <DarkModal
            open={open}
            onClose={() => handleClose()}
        >
            <DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => handleClose()}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent
                sx={{
                    p: "1rem",
                    maxWidth: "60rem",
                    maxHeight: "60rem",
                }}>
                    
                <img
                    className="record-img-full-screen"
                    src={image}
                    alt={image}
                />
            </DialogContent>
        </DarkModal>
    )
}

export default ImagePreviewModal