const GetColorsFromSpectrum = (props) => {
    const { value, first } = props;

    const percentage = (100 * value) / first;

    let result = {
        background: "#CFCFCF",
        color: "#787878",
        weightBackground: "#848484",
        weightColor: "#CACACA",
    };

    if (percentage > 0) {
        result = {
            background: "#A6CBFF",
            weightBackground: "#639CEF",
            color: "#2E5182",
            weightColor: "#1A3255",
        };
    }

    if (percentage > 0.67) {
        result = {
            background: "#A9CDFF",
            weightBackground: "#639AE4",
            color: "#415775",
            weightColor: "#1C2F48",
        };
    }

    if (percentage > 6.06) {
        result = {
            background: "#A9CDFF",
            weightBackground: "#639AE4",
            color: "#415775",
            weightColor: "#1C2F48",
        };
    }

    if (percentage > 7.64) {
        result = {
            background: "#ADE9FF",
            weightBackground: "#62BADB",
            color: "#246A83",
            weightColor: "#1D4655",
        };
    }

    if (percentage > 10.28) {
        result = {
            background: "#C2F7D9",
            weightBackground: "#6FC092",
            color: "#407356",
            weightColor: "#244934",
        };
    }

    if (percentage > 14.36) {
        result = {
            background: "#C2F7D9",
            weightBackground: "#6FC092",
            color: "#407356",
            weightColor: "#244934",
        };
    }

    if (percentage > 16.11) {
        result = {
            background: "#C2F7D9",
            weightBackground: "#6FC092",
            color: "#407356",
            weightColor: "#244934",
        };
    }

    if (percentage > 21.77) {
        result = {
            background: "#FFDCB0",
            weightBackground: "#E1AA62",
            color: "#7D5522",
            weightColor: "#574123",
        };
    }

    if (percentage > 26.36) {
        result = {
            background: "#FFB195",
            weightBackground: "#EB6F42",
            color: "#84391E",
            weightColor: "#472315",
        };
    }

    if (percentage > 37.05) {
        result = {
            background: "#FFB195",
            weightBackground: "#EB6F42",
            color: "#84391E",
            weightColor: "#472315",
        };
    }

    if (percentage > 54.89) {
        result = {
            background: "#FFA5A3",
            weightBackground: "#F4594E",
            color: "#711E1C",
            weightColor: "#481212",
        };
    }

    if (percentage > 82.32) {
        result = {
            background: "#FFA5A3",
            weightBackground: "#F4594E",
            color: "#711E1C",
            weightColor: "#481212",
        };
    }

    return result;
};

export default GetColorsFromSpectrum;
