import React from "react";
import "./styles/_styles.scss";

const MetaRows = (props) => {
    const { children } = props;

    return <div className="meta-rows">{children}</div>;
};

export default MetaRows;
