const fonts = {
    medium: {
        fontSize: "0.78125rem",
    },
    title: {
        small: {
            fontSize: "1.05rem",
            fontWeight: 500,
            color: "#000000",
        },
    },
};

export default fonts;
