import React, { Component } from "react";
import "./styles/_styles.scss";
import Tag from "../Tag";
import CategoryTags from "../CategoryTags";

export default class DoubleTags extends Component {
    getOptions() {
        let leftText = false;
        let centerText = false;
        let rightText = false;
        let leftBackground = "#87918e";
        let rightBackground = "#87918e";
        let leftTagStyles = false;
        let centerTagStyles = false;
        let rightTagStyles = false;

        if (typeof this.props.category == "string") {
            const tag = CategoryTags[this.props.category] || {};
            leftBackground = CategoryTags.feature;
            leftText = tag.long;
            let color = tag.color;

            if (color) {
                leftBackground = color;
            }

            if (typeof this.props.type == "string") {
                rightText = this.props.type;
            }
        }

        if (this.props.leftText) {
            leftText = this.props.leftText;
        }

        if (this.props.rightText) {
            rightText = this.props.rightText;
        }

        if (this.props.centerText) {
            centerText = this.props.centerText;
        }

        if (typeof this.props.leftTagStyles == "object") {
            leftTagStyles = this.props.leftTagStyles;
        }

        if (typeof this.props.rightTagStyles == "object") {
            rightTagStyles = this.props.rightTagStyles;
        }

        if (typeof this.props.centerTagStyles == "object") {
            centerTagStyles = this.props.centerTagStyles;
        }

        return {
            leftText,
            centerText,
            leftBackground,
            leftTagStyles,
            rightText,
            rightBackground,
            centerTagStyles,
            rightTagStyles,
        };
    }

    render() {
        const options = this.getOptions();

        const {
            leftText,
            centerText,
            rightText,
            leftTagStyles,
            centerTagStyles,
            rightTagStyles,
        } = options;

        const {
            styles,
            rightTextTransparent,
            centerTextTransparent,
            rightTextHref,
            className,
            fontSize,
            handleOnClick,
            icon
        } = this.props;

        const leftBackground = this.props.leftBackground || options.leftBackground

        if (leftText || icon) {
            return (
                <div
                    className={`meta-double-tags ${className}`}
                    style={Object.assign({}, styles && styles)}
                >
                    <Tag
                        className="meta-tag meta-tag-category"
                        text={leftText}
                        background={leftBackground}
                        icon={icon}
                        styles={Object.assign(
                            {},
                            rightText && {
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                            },
                            leftTagStyles && leftTagStyles,
                            fontSize && {
                                fontSize,
                            },
                        )}
                        handleOnClick={handleOnClick}
                    />

                    {centerText && rightText && (
                        <Tag
                            className="meta-tag meta-tag-center"
                            text={centerText}
                            disabled={true}
                            styles={Object.assign(
                                {},
                                {
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                },
                                centerTextTransparent && {
                                    background: "transparent",
                                    border: `0.0625rem solid ${leftBackground}`,
                                    borderRight: 0,
                                    padding: "0.1375rem 0.3125rem",
                                    color: leftBackground,
                                },
                                centerTagStyles && centerTagStyles,
                                fontSize && {
                                    fontSize,
                                },
                            )}
                            handleOnClick={handleOnClick}
                        />
                    )}

                    {rightText && (
                        <Tag
                            className="meta-tag meta-tag-type"
                            text={rightText}
                            disabled={true}
                            href={rightTextHref}
                            styles={Object.assign(
                                {},
                                {
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                },
                                rightTextTransparent && {
                                    background: "transparent",
                                    border: `0.0625rem solid ${leftBackground}`,
                                    padding: "0.1375rem 0.3125rem",
                                    color: leftBackground,
                                },
                                rightTagStyles && rightTagStyles,
                                fontSize && {
                                    fontSize,
                                },
                            )}
                            handleOnClick={handleOnClick}
                        />
                    )}
                </div>
            );
        }

        return false;
    }
}
