import { DataListInterface } from "./interfaces";

const yesNoDataList: Array<string> = ["Yes", "No"];

const yesNoBooleanDataList: DataListInterface[] = [
    {
        text: "Yes",
        value: 1,
    },
    {
        text: "No",
        value: 0,
    },
];

const scriptedNonScriptedDataList: DataListInterface[] = [
    {
        text: "Scripted",
        value: "Scripted",
    },
    {
        text: "Non-Scripted",
        value: "Non-Scripted",
    },
];

const gracenotePublicationStatus: DataListInterface[] = [
    {
        text: "Send to Gracenote",
        value: "Send to Gracenote",
    },
    {
        text: "Delivered",
        value: "Delivered",
    },
];

export const datalists: any = {
    yesNoDataList,
    yesNoBooleanDataList,
    scriptedNonScriptedDataList,
    gracenotePublicationStatus
};
