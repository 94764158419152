import { Chip, Stack } from "@mui/material";
import { styled } from "@mui/system";

// TODO: implement theming
export const CoreFormChip = styled(Chip)(({ theme }) => ({}));

export const CoreFormChipArray = styled(Stack)({
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "left",
    justifyContent: "left",
  });
