import React from "react";
import { Box, Stack } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Controller, useFormContext } from "react-hook-form";
import { CoreFormType } from "../../../types";
import { CoreFormLabel, CoreFormLabelProps } from "../../styled/CoreForm.label";
import { formatDateTimeToPayload } from "../../../utils/formatDateTimeToPayload";
import { useStore } from "../../../../../components/Global/store/store";
import { CoreFormMenuItem } from "../../styled/CoreFormDate.menuItem";
import { CoreFormFieldError } from "../../styled/CoreFormFieldError";
import { StyledTextField } from "../../styled/CoreFormText.field";
import { DateTimeDisabled } from "./DateTimeDisabled";
import system from "../../../../../config/system";
import moment from "moment";
import dayjs from 'dayjs'
import { get, isEmpty } from "lodash";

export function CoreInputDateTimePicker<T>({
    path,
    labelProps,
    format,
    disabled,
    payloadFormat,
    ...muiDateTimePickerProps
}: {
    path: CoreFormType<T>;
    format?: string;
    disabled?: boolean;
    payloadFormat?: string,
} & { labelProps?: CoreFormLabelProps }) {
    const [store] = useStore() || [{}]
    const formMethods = useFormContext<T>();
    const value = formMethods.watch(path);
    const { setValue } = formMethods

    let dateTimeFormat: string = format || store?.cmsData?.clientDataStructure?.date_time_format || system.datetime.payload.format.datetime
    let paylaodDateTimeFormat: string = payloadFormat || system.datetime.payload.format.datetime
    const errorMessage = get(formMethods?.formState.errors, path)?.message;

    let views = ['day', 'month', 'year', 'hours', 'minutes']
    if (dateTimeFormat.toLowerCase().includes('ss')) {
        views.push('seconds')
    }

    return (
        <Stack width="100%">
            {labelProps && <CoreFormLabel {...labelProps} />}
            <Controller
                name={path}
                render={({
                    field: { ref, onBlur, name, ...field },
                    fieldState,
                }) => {
                    if (disabled) {
                        const formattedValue = value ? moment(value, paylaodDateTimeFormat).format(format) : null
                        return (<DateTimeDisabled value={formattedValue} />)
                    }

                    return (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                {...field}
                                {...muiDateTimePickerProps}
                                format={dateTimeFormat}
                                views={views}
                                inputRef={ref}
                                value={dayjs(value)}
                                error={!isEmpty(errorMessage)}
                                onChange={(value: any) => setValue(
                                    path,
                                    formatDateTimeToPayload(value, paylaodDateTimeFormat)
                                )}
                                slots={{
                                    textField: StyledTextField,
                                    digitalClockSectionItem: CoreFormMenuItem
                                }}
                                slotProps={{
                                    textField: {
                                        path,
                                        onBlur,
                                        name,
                                        error: !!fieldState.error,
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    )
                }
            }
            />
            {errorMessage && (
                <CoreFormFieldError path={path} message={errorMessage} />
            )}
        </Stack>
    );
}

CoreInputDateTimePicker.defaultProps = {
    labelProps: null,
};
