const ProvideGroupRanking = props => {
    let {
        data
    } = props

    Object.keys(data).map(role => {
        let groupedData = data[role]
        let filteredOnDeleteGroupedData = groupedData.filter(item => !item.deleted)
        let deleteGroupedData = groupedData.filter(item => item.deleted) || []
        let rankedGroupedData = filteredOnDeleteGroupedData.map((item, index) => {
            item.ranking = index + 1
            return item
        })
        data[role] = rankedGroupedData.concat(deleteGroupedData)
    })

    return data
}

export default ProvideGroupRanking