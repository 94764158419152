import React from 'react'
import { observer } from "mobx-react";
import { useStores } from "../../../store/rootStore";
import Notification from "../../../../../../components/Notification";
import Settings from "../../../../../../components/Settings";
import { CreditListInterface } from "../../../credits.interface";

const DeleteForm: React.FC = observer(() => {
    const rootStore = useStores()
    const { creditListsStore } = rootStore || {}
    const creditListForDelete = creditListsStore.getCreditListForDelete

    const renderFormElements = () => {
        return (
            <div className="update-create-credit-form">
                <p>Are you sure you want to delete this Credit List?</p>
            </div>
        )
    }

    const handleConfirm = () => {
        creditListsStore.deleteCreditList(creditListForDelete as CreditListInterface)
        creditListsStore.setFormStatus({
            isActive: false,
            isDisabled: true,
            isNewList: false,
            isDeleteForm: false
        })
    }

    const handleCancel = () => {
        creditListsStore.setFormStatus({
            isActive: false,
            isDisabled: true,
            isNewList: false,
            isDeleteForm: false
        })
        creditListsStore.setCreditListForDelete({})
    }

    return (
        <React.Fragment>
            <Notification
                intercationStatus="error"
                background={"white"}
                icon={`${Settings.images.path}/svg/alert.svg}`}
                disabled={false}
                okText="Delete"
                title="Delete Credit List"
                html={renderFormElements()}
                confirm={handleConfirm}
                cancelClick={handleCancel}
            />
        </React.Fragment>
    )
})

export default DeleteForm

