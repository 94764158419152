let environment = "Development Environment";

if (process && process.env) {
    if (process.env.APP_ENV == "QA") {
        environment = "Staging Environment";
    }

    if (process.env.APP_ENV == "Live") {
        environment = "Live Environment";
    }
}

export default environment;
export const envTest = process.env.NODE_ENV == "test";
export const envDev = !["Live", "QA", "test"].includes(process.env.NODE_ENV);
