import {
    CreditInterface,
    NameComponents
} from "../../credits.interface";
import { parseName } from '../../utils/helpers/index'

interface AssignParsedNameToActiveCreditProps {
    credit: CreditInterface
    defaultScriptValue: string
    isNewContributor: boolean
    setScriptValue: () => void
    refreshTableData: () => void
}

export default function assignParsedNameToActiveCredit(
    props: AssignParsedNameToActiveCreditProps
): CreditInterface {

    const {
        credit,
        defaultScriptValue,
        isNewContributor,
        setScriptValue,
        refreshTableData
    } = props

    const nameComponents = parseName(credit.name as string) as NameComponents
    credit.first_name = nameComponents.first_name
    credit.last_name = nameComponents.last_name
    credit.middle_names = nameComponents.middle_name

    credit.script_names.push({
        code: defaultScriptValue,
        first_name: nameComponents.first_name,
        last_name: nameComponents.last_name,
        middle_names: nameComponents.middle_name,
    })

    if (isNewContributor) {
        setScriptValue()
        refreshTableData()    
    }
        
    return credit
}