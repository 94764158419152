import FieldInterface, { InputType } from '../interfaces/Field.interface'
import { optionValue, optionsValues } from './OptionTransformer'
import validString from '../utils/validString'

type Props = {
    fields: FieldInterface[]
    data: { [key: string]: any },
    useNullValues?: boolean
}

const DefaultValues = ({fields = [], data = {}, useNullValues }: Props) => {
    let values = {}

    const defaultNullValue: string | null = useNullValues ? null : ''
    
    fields.forEach(field => {
        let value: any = ''

        switch(field.type.input) {
            case InputType.BooleanRadio:
            case InputType.BooleanToggle:
            case InputType.BooleanCheckbox:
                value = !!data[field.key]
                break

            case InputType.Select:
            case InputType.SelectFramed:
                value = data[field.key]
                    ? optionValue({ field, option: data[field.key] })
                    : defaultNullValue

                break

            case InputType.SelectMultiple:
            case InputType.SelectMultipleChips:
                value = optionsValues({ field, options: data[field.key] })
                break
                
            case InputType.Autocomplete:
                values[`text_${field.key}`] = ''
                value = data[field.key] || []
                break

            case InputType.AutocompleteContributors:
                values[`text_${field.key}`] = ''
                value = data[field.key] || ''
                break

            case InputType.Duration:
            case InputType.Date:
            case InputType.Time:
            case InputType.DateTime:
            case InputType.Year:
            case InputType.String:
            case InputType.Text:
            case InputType.TextArea:
            case InputType.TextFramed:
            case InputType.ColorPalette:
            case InputType.Alphanumeric:
                value = validString(data[field.key], defaultNullValue)
                break
            
            case InputType.Numeric:
                value = data[field.key] || null
                break

            case InputType.PhoneNumber:
                value = data[field.key]
                    ? data[field.key]
                    : {}
                break
        }

        values[field.key] = value
    })

    return values
}

export default DefaultValues