import React from "react";
import CustomSelect from "./index";
import MenuIconBoilerPlate from "../Boilerplates/MenuIconBoilerplate";
import './styles/_selectWithMenuStyles.scss'

interface SelectWithMenuIconInterface {
    actionsList: any;
    arrowRight?: boolean;
    chevronImage?: boolean;
    disabled?: boolean;
}

const SelectWithMenuIcon = ({
    actionsList,
    arrowRight,
    chevronImage = false,
    disabled,
}: SelectWithMenuIconInterface) => {
    return (
        <div 
            className="custom-select-with-menu-icon" 
            data-testid="custom-select-with-menu-icon">
                
            <CustomSelect
                chevronImage={chevronImage}
                background="none"
                items={actionsList}
                disabled={disabled}
                customImageRight={<MenuIconBoilerPlate arrowRight={arrowRight} />}
                itemsStyles={{
                    fontSize: '0.875rem',
                    fontWeight: 400,
                    lineHeight: '145%',
                    minWidth: '12.5rem'
                }}
                itemStyles={{
                    minWidth: '0',
                    padding: '0.563rem'
                }}
            />
        </div>
    );
};

export default SelectWithMenuIcon;
