import React, { Fragment } from 'react'
import { useStore } from '../context'
import Button from '../../../../../components/Button'
import Settings from '../../../../../components/Settings'
import CreateOrUpdateCredit from './forms/CreateOrUpdateCredit'
import GetRoleColor from "../../src/GetRoleColor";
import GetRolesOptions from '../../src/GetRolesOptions'
import deepcopy from 'deepcopy'
import _ from 'lodash'
import CreateOrUpdateContributor from './forms/CreateOrUpdateContributor'
import GetScriptedValues from '../../src/GetScriptedValues'
import { 
    setState,
    setNotification, 
    setActiveCredit, 
    updateCredit, 
    setActiveContributor, 
    updateContributor 
} from '../reducer'
import CopyTooltip from '../../../../../components/CopyTooltip'
import Paragraph from '../../../../../components/Paragraph'
import externalLinks from "../../../../../config/external_links"

const ImdbLink = props => {
    const {
        imdb_id = ''
    } = props

    return(
        <a href={externalLinks.imdb_url_name.replace("{id}", imdb_id)} target="_blank">
            <span>
                {imdb_id}
            </span> 
        </a>
    )
}

const TableItem = props => {
    const {
        credit = {}
    } = props
    
    const [store, dispatch] = useStore()

    const {
        disabled = true,
        options,
        cmsData = {},
        script,
        defaultScript
    } = store

    const {
        clientFeatures = {}
    } = cmsData

    const nameFieldExposed = _.get(clientFeatures, 'contributorFullNameExposed', false)

    const roleOptions = GetRolesOptions({options, clientFeatures: cmsData.clientFeatures || {}})
    const activeRole = roleOptions.find(item => item.name == credit.role_name)

    const EditAction = () => {
        dispatch(setActiveCredit(credit))
        
        dispatch(setNotification({
            html: <CreateOrUpdateCredit 
                credit={credit}
                activeRole={activeRole}
            />,
            title: "Edit Credit",
            confirm: () => {
                dispatch(updateCredit(credit.role, credit.ranking, credit.local_id))
                dispatch(setNotification({}))
            },
            onClick: () => dispatch(setNotification({}))
        }))
    }

    const DeleteAction = () => {
        let creditCopy = deepcopy(credit)
        creditCopy.deleted = true
        dispatch(setActiveCredit(creditCopy))
        dispatch(updateCredit(credit.role, credit.ranking, credit.local_id))
    }

    const UpdateContributor = () => {
        dispatch(setActiveCredit(credit))
        dispatch(setActiveContributor(credit))
        dispatch(setNotification({
            html: <CreateOrUpdateContributor />,
            title: "Edit Contributor",
            confirm: () => {
                dispatch(setNotification({
                    html: <Paragraph
                        color="rgb(90, 90, 90)"
                        margin="0.5rem 0 0 0"
                        fontWeight="400"
                        items={[
                            "Any change to the Contributor will affect all other titles' Credit lists across the entire catalog. Are you sure?"
                        ]}
                    />,
                    title: "Edit Contributor",
                    confirm: () => {
                        dispatch(updateContributor())
                        dispatch(updateCredit(credit.role, credit.ranking, credit.local_id))
                        dispatch(setState({activeCredit: {}, activeContributor: {}, notification: {}}))
                    }
                }))
            },
        }))
    }

    let rowColor = GetRoleColor({
        roleName: credit.role_name || '', 
        ranking: credit.ranking, 
        roleOptions
    })

    const scriptedValues = GetScriptedValues({
        item: credit,
        disabled: true,
        isScript: script !== defaultScript,
        script,
        nameFieldExposed
    })

    const getContributorName = (scriptedValues) => {
        if (!_.isEmpty(scriptedValues.first_name) && !nameFieldExposed) {
            const first_name = scriptedValues.first_name || ''
            const middle_names = scriptedValues.middle_names || ''
            const last_name = scriptedValues.last_name || ''

            return `${first_name} ${middle_names} ${last_name}`.trim()
        }
        
        return _.isEmpty(scriptedValues.name) ? scriptedValues.namePlaceholder : scriptedValues.name
    }

    const contributorName = getContributorName(scriptedValues)
    const contributorNameStyles = _.isEmpty(scriptedValues.name) ? {color: "rgba(188, 188, 188, 0.87)"} : {}

    const contributorCharacterName = _.isEmpty(scriptedValues.character_name) ? scriptedValues.characterPlaceholder : scriptedValues.character_name
    const contributorCharacterNameStyles = _.isEmpty(scriptedValues.character_name) ? {color: "rgba(188, 188, 188, 0.87)"} : {}

    return(
        <div className={"credits-table-item"}>
            <div className={"credit-field number"} style={{backgroundColor: rowColor}}>{credit.ranking}</div>
            <div className={"credit-field name"}>
                <CopyTooltip text={contributorName}>
                    {disabled 
                        ? <span style={contributorNameStyles}>{contributorName}</span>
                        : <span style={contributorNameStyles} onClick={() => UpdateContributor()} className="edit-mode">{contributorName}</span>
                    }
                </CopyTooltip>
            </div>
            <div className={"credit-field role"}>
                <CopyTooltip text={credit.role_name || ''}>
                    {credit.role_name || ''}
                </CopyTooltip>
            </div>

            <div className={"credit-field role_type"}>
                <CopyTooltip text={activeRole?.type || ''}>
                    {activeRole?.type || ''}
                </CopyTooltip>
            </div>
            
            <div className={"credit-field character"} style={contributorCharacterNameStyles}>
                <CopyTooltip text={contributorCharacterName}>
                    {contributorCharacterName}
                </CopyTooltip>
            </div>

            <div className={"credit-field contributor_id"}>
                <CopyTooltip text={credit.contributor_meta_id || ''}>
                    {credit.contributor_meta_id || ''}
                </CopyTooltip>
            </div>
            
            <div className={"credit-field contributor_imdb"}>
                <CopyTooltip text={credit.imdb_id || ''}>
                    {!_.isEmpty(credit.imdb_id) ? <ImdbLink imdb_id={credit.imdb_id} /> : '-'}
                </CopyTooltip>
            </div>
            
            <div className={"credit-field credit_id"}>
                <CopyTooltip text={credit.credit_meta_id || ''}>
                    {credit.credit_meta_id || ''}
                </CopyTooltip>
            </div>
            
            {!disabled &&
                <div className={"control-container"}>
                    <div 
                        className="credit-btn" 
                        style={{ backgroundImage: `url('${Settings.images.path}/svg/hamburger-svg-white.svg')` }}
                    />
                    <Button
                        value="EDIT"
                        type="button"
                        small={true}
                        onClick={() => EditAction()}
                        height={'1.5rem'}
                        padding={0}
                    />
                    <Button
                        value="DELETE" 
                        type="button"
                        small={true}
                        buttonDelete={true}
                        onClick={() => DeleteAction()}
                        height={'1.5rem'}
                        padding={0}
                    />
                </div>
            }
        </div>
    )
}

export default TableItem
