import GoogleTranslateItems from "../Languages/GoogleTranslateItems";

const GetPopulatedLanguages = (props) => {
    const {
        clientFeatures,
        languages,
        top_written_languages,
        data = [],
        itemKey,
        itemType,
    } = props;

    const { googleTranslate } = clientFeatures;

    let result = [];
    if (googleTranslate) {
        const populatedLocales = data
            .filter((item) => item[itemKey] && item.type == itemType)
            .map((item) => item.locale);

        result = GoogleTranslateItems({
            languages,
            top_written_languages,
            populatedLocales,
        });
    }

    return result;
};

export default GetPopulatedLanguages;
