import React from "react";
import { SectionInterface } from "./interface";
import Ratings from "../../views/Ratings";
import DividerLine from "../../../DividerLine";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";

const SectionRatings = (props: SectionInterface) => {
    const { data = {} } = props;

    const { ratings = {} } = data;

    return (
        <React.Fragment>
            <Ratings
                {...getSectionCommonProps({ ...props, field: "ratings" })}
                name="ratings"
                className="meta-record-ratings"
                title="Ratings"
                data={ratings}
                noBorder={true}
            />

            <DividerLine />
        </React.Fragment>
    );
};

export default SectionRatings;
