import _ from "lodash";
import { CLIENT_MANAGED_ASSET_COMPLIANCES, ASSET_COMPLIANCES } from "./options";
import { GetFromLocalStorage } from "../Storage/localStorage";
import clientManagedController from "./clientManagedController";

export function isClientManagedAssetCompliances(props) {
    const { clientFeatures = {} } = props;

    const { managedLists = [] } = clientFeatures;

    return managedLists.includes(ASSET_COMPLIANCES);
}

export default function AssetCompliances(props) {
    const { options = {} } = props;

    const local = GetFromLocalStorage(CLIENT_MANAGED_ASSET_COMPLIANCES);

    const assetCompliances = clientManagedController({
        clientManagedOptions: options.client_managed_asset_compliances || [],
        fallback: [],
        localStorage: JSON.parse(local),
        isClientManaged:
            !_.isEmpty(options.client_managed_asset_compliances) || local,
    });

    return assetCompliances.map((item) => {
        return {
            ...item,
            text: item.code + " • " + item.name,
            value: item.name,
        };
    });
}
