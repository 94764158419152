/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useMemo } from "react";
import update from "react-addons-update";
import { GridRowParams } from "@mui/x-data-grid";
import { RenderActionsCol } from "../components/RenderActions.column";
import { CoreTable } from "../CoreTable";
import { CoreTableConfig, CoreTableGridColDef, CoreTableRow } from "../types";
import { TitleCol } from "./columns/Title.column";
import { MetaIdCol } from "./columns/MetaId.column";
import { CustomIdsCol } from "./columns/CustomIds.column";
import { NumberingCol }  from "./columns/Numbering.column";
import { FirstReleaseYearCol } from "./columns/FirstReleaseYear.column";
import { LinkagesType } from "./types/Linkages.type";
import HandleSelectedRecord from "../../../components/Selected/handleSelectedRecord";
import { CoreDialog } from "../../Dialog/CoreDialog";
import { LinkageInterface } from "../../../models/Record/linkage"
import { isEpisode } from "../../../components/CategoryTags/EpisodeCategories"
import { useStore as globalStore } from "../../../components/Global/store/store";

type LinkageRow = LinkageInterface & {
    id: string
}

type LinkagesTableProps = {
    linkages: LinkageRow[];
    disabled: boolean;
    category: string;
    handleLinkSearch: Function;
    handleChange: Function
}

export const LinkagesTable = (props: LinkagesTableProps) => {
    const {
        linkages,
        disabled,
        category,
        handleLinkSearch,
        handleChange
    } = props

    const [globalState] = globalStore() || [{}];
    const [deleteRow, setDeleteRow] = useState<GridRowParams | null>(null);
    const episodeCategory: boolean = isEpisode(category);

    const { 
        linkage = {} 
    } = globalState?.cmsData?.clientFeatures || {};

    const { 
        customIds
    } = linkage;

    const config: CoreTableConfig = {
        state: disabled ? "default" : "edit",
        emptyFallback: "No Linkages Added",
        tableName: 'linkages'
    };

    const actions = RenderActionsCol({
        preview: {
            handleOnClick: (params: GridRowParams) => {
                HandleSelectedRecord({
                    handleLinkSearch,
                    selected: params.id
                });
            },
            disabled: () => false,  
        },
        delete: {
            handleOnClick: (params: GridRowParams) => {
                setDeleteRow(params)
            },
        },
        onlyShowInDefaultState: {
            preview: true
        },
        state: config.state
    });

    const rows: CoreTableRow<LinkagesType> = linkages.filter((item: LinkageRow) => !item.deleted)

    const columns: CoreTableGridColDef[] = useMemo(() => {
        let result = [
            TitleCol,
            FirstReleaseYearCol,
            MetaIdCol
        ]

        if (episodeCategory) result.push(NumberingCol)
        if (customIds) result.push(CustomIdsCol)

        result.push(...actions)

        return result

    }, [episodeCategory, customIds])

    return (
        <>
            <CoreTable<LinkagesType>
                config={config}
                columns={columns}
                rows={rows}
                disabled={disabled}
                hideFooter={true}
            />

            <CoreDialog
                title="Delete Linkage"
                open={deleteRow ? true : false}
                setOpen={() => setDeleteRow(null)}
                buttons={{
                    submit: {
                        handleOnSubmit: () => {
                            const index: number = linkages.findIndex((item: LinkageRow) => item.meta_id == deleteRow?.id && !item.deleted)

                            if (index !== -1) {
                                const updatedState = update(linkages, {
                                    [index]: {
                                        $set: {
                                            ...deleteRow?.row,
                                            deleted: true,
                                        },
                                    },
                                })

                                if (typeof handleChange == "function") {
                                    handleChange(updatedState);
                                }
                            }

                            setDeleteRow(null)
                        },
                        label: "Delete",
                    },
                    cancel: {
                        label: "Cancel",
                    },
                }}
                dialogMaxWidth="37.5rem"
                dialogContentWidth="37.5rem"
            >
                Are you sure you want to delete this linkage?
            </CoreDialog>
        </>
    );
};