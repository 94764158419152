import { CORE_FORM_STRING_DEFAULT_VALUE } from "../../../../../core/Form/constants";
import { TvShowVT, SeasonVT } from "../../../config/types";
import randomNumber from "../../../utils/randomNumber";

export const createParentShow = (option: TvShowVT): SeasonVT["parentShow"] => {
    return {
        id: option?.id || randomNumber(),
        metaId: option?.metaId || CORE_FORM_STRING_DEFAULT_VALUE,
        title: option?.title || CORE_FORM_STRING_DEFAULT_VALUE,
    };
};
