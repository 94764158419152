import { Box, Checkbox, Stack } from "@mui/material";
import { CoreSelectOptionType } from "../types/CoreSelectOptionType";

export type CoreSelectListItemProps = {
    option: CoreSelectOptionType;
    handleOnChange: () => void;
    onBlur: any;
    ref: any;
    selected: string[];
    isSingleSelect?: boolean;
    selectedRef?: any;
    disabled?: boolean;
    showRightAdornment?: boolean;
};

export function CoreSelectListItem({
    option,
    handleOnChange,
    selected = [],
    onBlur,
    ref,
    isSingleSelect,
    selectedRef,
    disabled,
    showRightAdornment
}: CoreSelectListItemProps) {
    const value = option?.value;

    const disabledItem: boolean = isSingleSelect
        ? disabled
        : !selected?.includes(value) && disabled;

    if (!Array.isArray(selected)) {
        selected = [selected];
    }

    return (
        <Box
            key={value}
            component="li"
            role="menuitem"
            aria-label={`menuitem-${value}`}
            style={{ marginBottom: 0 }}
            onClick={!disabledItem && handleOnChange}
            ref={selected?.includes(value) ? selectedRef : null}
        >
            <Stack
                direction="row"
                alignItems="center"
                sx={{
                    borderRadius: "0.1875rem",
                    backgroundColor:
                        selected?.includes(value) && "rgba(40, 108, 210, 0.1)",
                    "&:hover": {
                        backgroundColor: "rgba(240, 240, 240, 1)",
                        cursor: "pointer",
                    },
                    color: "rgba(47, 47, 47, 0.87)",
                    height: "2.25rem",
                    padding: "0 0.5rem",
                }}
                paddingY={isSingleSelect && 4}
                paddingX={isSingleSelect && 2}
                // padding={isSingleSelect ? 4 : 0}
            >
                {!isSingleSelect && (
                    <Box>
                        <Checkbox
                            disableRipple
                            edge="start"
                            // inputProps={{
                            //     "aria-labelledby":
                            //         labelId,
                            // }}
                            onBlur={onBlur} // notify when input is touched
                            onChange={handleOnChange} // send value to react-hook-form library
                            checked={selected?.includes(value)}
                            inputRef={ref}
                            size="small"
                            sx={{
                                margin: 0,
                                padding: "0.5rem 0.625rem 0.5rem 0",
                            }}
                            disabled={disabledItem}
                        />
                    </Box>
                )}

                {option?.leftAdornment && (
                    <Box paddingRight="0.5rem !important">
                        {option.leftAdornment}
                    </Box>
                )}
                {option?.overrideLabel ? (
                    <Box sx={!value ? { fontStyle: "italic" } : {}} flex={1}>
                        {option.overrideLabel}
                    </Box> // TODO: fix flex={1} duplication
                ) : (
                    <Box sx={!value ? { fontStyle: "italic" } : {}} flex={1}>
                        {option.label}
                    </Box>
                )}
                {option?.rightAdornment && showRightAdornment && (
                    <Box paddingRight="0.5rem">{option.rightAdornment}</Box>
                )}
            </Stack>
        </Box>
    );
}

CoreSelectListItem.defaultProps = {
    isSingleSelect: false,
};
