import { useCountries } from "./useCountries";

export const useCountryByCode = (code: string) => {
    const countries = useCountries();
    const country = countries?.find(
        (c) => c?.value?.toUpperCase() === code?.toUpperCase(),
    );

    return country;
};
