import _, { isEmpty, isNaN } from "lodash";
import {
    CORE_FORM_NUMBER_DEFAULT_VALUE,
    CORE_FORM_STRING_DEFAULT_VALUE,
} from "../../../core/Form/constants";
import { CoreFormPath } from "../../../core/Form/types";
import { TMFieldsConfig, getViacomFieldsConfig } from "../config/fields.config";
import { AllFieldsVT, FieldObject } from "../config/types";
import { NuvoRecord } from "../containers/record/tv-series/types/nuvo.types";
import randomNumber from "../utils/randomNumber";
import { unflattenObject } from "../utils/unflatten";

const ARRAY_SEPARATOR = "|";

interface TaskManagerNuvoSerializer {
    deserialize(record: NuvoRecord): AllFieldsVT;

    serialize(record: AllFieldsVT): NuvoRecord;
}

class BaseNuvoSerializer {
    fieldsConfig: TMFieldsConfig;

    constructor(fieldsConfig: TMFieldsConfig) {
        this.fieldsConfig = fieldsConfig;
    }

    extractField<T>(
        record: NuvoRecord,
        nuvoConfig: FieldObject<T>["nuvoConfig"],
    ) {
        if (isEmpty(record)) {
            return null;
        }

        if (nuvoConfig.serdes.type === "basic") {
            return this.basicDeserialize(
                record,
                nuvoConfig.key,
                nuvoConfig.serdes.dataType ?? "string",
            );
        } else if (nuvoConfig.serdes.type === "array") {
            return this.arrayDeserialize(
                record,
                nuvoConfig.key,
                nuvoConfig.serdes.separator,
            );
        } else if (nuvoConfig.serdes.type === "custom") {
            return this.customDeserialize(
                record,
                nuvoConfig.serdes.deserializeFn,
            );
        }

        throw new Error(`Unknown serdes type "${nuvoConfig.serdes.type}"`);
    }

    writeField(
        record: AllFieldsVT,
        fieldPath: CoreFormPath<AllFieldsVT>["path"],
        nuvoConfig: FieldObject<AllFieldsVT>["nuvoConfig"],
    ) {
        if (isEmpty(record)) {
            return null;
        }

        if (nuvoConfig.serdes.type === "basic") {
            return this.basicSerialize(
                record,
                fieldPath,
                nuvoConfig.key,
                nuvoConfig.serdes.dataType ?? "string",
            );
        } else if (nuvoConfig.serdes.type === "array") {
            return this.arraySerialize(
                record,
                fieldPath,
                nuvoConfig.key,
                nuvoConfig.serdes.separator,
            );
        } else if (nuvoConfig.serdes.type === "custom") {
            return this.customSerialize(
                record,
                fieldPath,
                nuvoConfig.key,
                nuvoConfig.serdes.serializeFn,
                nuvoConfig.serdes.unflatten,
            );
        }

        throw new Error(`Unknown serdes type "${nuvoConfig.serdes.type}"`);
    }

    basicSerialize = (
        record: AllFieldsVT,
        fieldPath: keyof AllFieldsVT,
        nuvoField: keyof NuvoRecord,
        dataType: "string" | "number",
    ) => {
        const value = _.get(record, fieldPath);
        return {
            [nuvoField]:
                dataType === "string"
                    ? !isEmpty(value)
                        ? value
                        : CORE_FORM_STRING_DEFAULT_VALUE
                    : !isNaN(value)
                    ? value
                    : CORE_FORM_NUMBER_DEFAULT_VALUE,
        };
    };

    customSerialize = (
        record: AllFieldsVT,
        fieldPath: keyof AllFieldsVT,
        nuvoField: keyof NuvoRecord,
        serializeFn: (data: unknown) => unknown,
        unflatten: boolean = false,
    ) => {
        const value = _.get(record, fieldPath);

        try {
            const result = serializeFn(value);

            if (!unflatten) {
                return {
                    [nuvoField]: result,
                };
            } else {
                return unflattenObject(result);
            }
        } catch (error) {
            console.error("Error serializing field", fieldPath, error);
            return null;
        }
    };

    customDeserialize = (
        record: NuvoRecord,
        deserializeFn: (nuvoRecord: NuvoRecord) => unknown,
    ) => {
        return deserializeFn(record);
    };

    basicDeserialize = (
        record: NuvoRecord,
        nuvoPath: keyof NuvoRecord,
        dataType: "string" | "number",
    ) => {
        let value;
        switch (dataType) {
            case "string":
                value = _.get(record, nuvoPath);
                return !isEmpty(value) ? value : CORE_FORM_STRING_DEFAULT_VALUE;
            case "number":
                value = _.get(record, nuvoPath);
                return !isNaN(value) ? value : CORE_FORM_NUMBER_DEFAULT_VALUE;

            default:
                throw new Error(`Unknown data type "${dataType}"`);
        }
    };

    arraySerialize = (
        record: AllFieldsVT,
        fieldPath: keyof AllFieldsVT,
        nuvoField: keyof NuvoRecord,
        separator: string = ARRAY_SEPARATOR,
    ) => {
        const value = _.get(record, fieldPath) as string[];

        return {
            [nuvoField]: !isEmpty(value) ? value.join(separator) : "",
        };
    };

    arrayDeserialize = (
        record: NuvoRecord,
        nuvoPath: keyof NuvoRecord,
        separator: string = ARRAY_SEPARATOR,
    ) => {
        const value = _.get(record, nuvoPath);
        return !isEmpty(value) ? value.split(separator) : [];
    };

    getNuvoConfig(
        path: CoreFormPath<AllFieldsVT>["path"],
    ): FieldObject<AllFieldsVT>["nuvoConfig"] {
        return _.get(this.fieldsConfig, path)?.nuvoConfig;
    }
}

export class TMNuvoSerializer
    extends BaseNuvoSerializer
    implements TaskManagerNuvoSerializer
{
    paths: string[];

    constructor() {
        super(TaskManagerConfigReader.getConfig());

        // TODO Could we get this from the config?
        this.paths = [
            this.fieldsConfig.title.path,
            this.fieldsConfig.category.path,
            this.fieldsConfig.brand.path,
            this.fieldsConfig.provenance.path,
            this.fieldsConfig.originalLanguage.path,
            this.fieldsConfig.countryOfOrigin.path,
            this.fieldsConfig.releaseYear.path,
            this.fieldsConfig.metaId.path,

            this.fieldsConfig.titles.aka.path,
            this.fieldsConfig.titles.fka.path,
            this.fieldsConfig.titles.short.path,
            this.fieldsConfig.titles.sort.path,
            this.fieldsConfig.titles.alternative.path,
            this.fieldsConfig.titles.release.path,
            this.fieldsConfig.ids.rms.path,
            this.fieldsConfig.ids.legacySap.path,
            this.fieldsConfig.ids.s4_mpm_id.path,
            this.fieldsConfig.ids.change_record_id.path,
            this.fieldsConfig.descriptions.genre.path,
            this.fieldsConfig.descriptions.subGenre.path,
            this.fieldsConfig.descriptions.description100.path,
            this.fieldsConfig.descriptions.description250.path,
            this.fieldsConfig.descriptions.longDescription1024.path,
            this.fieldsConfig.other.channelOfOrigin.path,
            this.fieldsConfig.displayTitle.path,

            this.fieldsConfig.collection.path,
            this.fieldsConfig.episodeNo.path,
            this.fieldsConfig.seasonNo.path,
            this.fieldsConfig.originalProductionNo.path,

            this.fieldsConfig.parentSeason.path,
            this.fieldsConfig.parentShow.path,
            this.fieldsConfig.parentModularEpisode.path,
        ];
    }

    deserialize(record: NuvoRecord): AllFieldsVT {
        return unflattenObject({
            ...this.paths.reduce(
                (acc, fieldPath) => {
                    const nuvoConfig = this.getNuvoConfig(fieldPath);

                    if (!nuvoConfig) {
                        console.warn("No Nuvo config for field", fieldPath);
                        return acc;
                    }

                    return {
                        ...acc,
                        [fieldPath]: this.extractField<AllFieldsVT>(
                            record,
                            nuvoConfig,
                        ),
                    };
                },
                {
                    id: record.id ?? randomNumber(),
                },
            ),
        }) as AllFieldsVT;
    }

    serialize(record: AllFieldsVT): NuvoRecord {
        return {
            ...this.paths.reduce((acc, fieldPath) => {
                const nuvoConfig = this.getNuvoConfig(fieldPath);

                if (!nuvoConfig) {
                    console.warn("No Nuvo config for field", fieldPath);
                    return acc;
                }

                return _.merge(
                    acc,
                    this.writeField(record, fieldPath, nuvoConfig),
                );
            }, {} as NuvoRecord),
        };
    }
}

class TaskManagerConfigReader {
    static getConfig() {
        // TODO This has to read some environment variable to determine which config to use
        return getViacomFieldsConfig();
    }
}
