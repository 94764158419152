import FieldInterface from '../interfaces/Field.interface'
import InputStyled from './InputStyled'
import LayerInterface from '../interfaces/Layer.interface'
import CoreDynamicInput from './input'
import { observer } from 'mobx-react'

type Props = {
    layer: LayerInterface
}

const Layer = observer(({ layer }: Props) => {
    const { fields = [], columns = 1 } = layer

    return (
        <div className='core-form-dynamic-layer' style={{ display: "flex", flexWrap: "wrap", marginTop: "0"}} data-testid={"fabric-dynamic-form-builder-layer"}>
            {fields.map((field: FieldInterface, index: number) => {
                return (
                    <InputStyled 
                        sortIndex={index} 
                        columns={columns}
                        field={field}
                        key={`dynamic-layer-${index}`}>

                        <div data-testid={"fabric-dynamic-form-builder-layer-input"}>
                            <CoreDynamicInput field={field} />
                        </div>
                    </InputStyled>
                )
            })}
        </div>
    )
})

export default Layer