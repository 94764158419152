import React, { useEffect } from 'react'
import { observer } from "mobx-react";
import { Typography } from "@mui/material";
import "./styles/_styles.scss"
import { classPrefix } from './utils/constants'
import { useStores as useStores } from "./store/rootStore";
import { useStore } from "../../../components/Global/store/store";
import CreateCreditListForm from "./views/credit-list/CreateCreditListForm.view"
import ActiveCreditsTable from './views/credit-table/ActiveCreditsTable.view';
import UpdateCreditListForm from "./views/credit-list/UpdateCreditListForm.view"
import CreateCreditForm from "./views/credit/CreateCreditForm.view"
import UpdateCreditForm from "./views/credit/UpdateCreditForm.view"
import { CoreDialog } from "../../../core/Dialog/CoreDialog";
import { coreDialogProps, dialogTitleProps } from "./utils/constants"
import InactiveCreditsTable from './views/credit-table/InactiveCreditsTable.view';
import { RecordInterface } from "../../../models/Record/model"

interface CreditsProps {
    disabled: boolean,
    recordData: RecordInterface,
    handleFormChange: Function
}

const Credits = observer(({ disabled, recordData, handleFormChange }: CreditsProps) => {
    const { 
        rootStore
    } = useStores();

    const [globalState, dispatch] = useStore() || [{}];

    useEffect(() => {
        rootStore.initialize(globalState, dispatch, recordData, handleFormChange)
    }, [])

    useEffect(() => {
        rootStore.setDisabled(disabled)
    }, [disabled])

    return (
        <div 
            className={classPrefix} 
            data-testid={classPrefix}>

            <div className="tab-header">
                <Typography variant="h2">
                    Credits
                </Typography>
            </div>
            
            <Typography
                sx={{
                    color: "rgba(47, 47, 47, 0.57)",
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "0.62rem"
                }}
            >
                Active Credits
            </Typography>

            <ActiveCreditsTable />
            <InactiveCreditsTable />

            {/* Credit List Forms */}
            {rootStore.showCreditListCreateForm &&
                <CreateCreditListForm /> 
            }

            {rootStore.showCreditListUpdateForm &&
                <UpdateCreditListForm /> 
            }

            {/* Credit Forms */}
            {rootStore.showCreditCreateForm &&
                <CreateCreditForm /> 
            }

            {rootStore.showCreditUpdateForm &&
                <UpdateCreditForm /> 
            }

            {rootStore.coreDialog?.open &&
                <CoreDialog
                    {...coreDialogProps}
                    open={true}
                    setOpen={(open) => {}}
                    title={rootStore.coreDialog.title}
                    buttons={rootStore.coreDialog.buttons}
                    data-testid={`${classPrefix}-core-dialog`}
                >
                    {rootStore.coreDialog.paragraph &&
                        <Typography
                            sx={dialogTitleProps}>

                            {rootStore.coreDialog.paragraph}
                        </Typography>
                    }
                </CoreDialog>
            }
        </div>
    )
})

export default Credits