import deepcopy from "deepcopy";
import _ from "lodash";

export default function ValidateAgeGuidance(result = {}, optionsData = {}) {
    let response = deepcopy(result);

    const { data = {}, copy = {}, status, description } = optionsData;

    const { age_guidance: dataList = [] } = data;

    dataList
        .filter((item) => !item.deleted)
        .forEach((ageGuidance) => {
            const isEmptyMaximum =
                !ageGuidance.maximum_age || ageGuidance.maximum_age == "";
            const isEmptyMinimum =
                !ageGuidance.minimum_age || ageGuidance.minimum_age == "";

            if (
                (isEmptyMaximum && isEmptyMinimum) ||
                _.isEmpty(ageGuidance.territory)
            ) {
                response.result = false;
                (response.notification = {
                    title: `${
                        copy.checkOriginalTitle ||
                        "Please, check Age Guidance Territory exists or at least one Age value is not empty"
                    }`,
                    description,
                    status,
                }),
                    (response.validationKeys = ["age_guidance"]);
            }
        });

    return response;
}
