import _ from "lodash";

export function TransformMetaUsersToSimpleArray(meta_users) {
    let result = meta_users;

    if (Array.isArray(meta_users)) {
        result = [];

        meta_users.forEach((user) => {
            if (typeof user == "string") {
                result.push(user);
            }

            if (typeof user == "object" && user.value) {
                result.push(user.value);
            }
        });
    }

    return result;
}

export function GetNotifications(props) {
    const { meta_users, email_addresses } = props;

    const metaUsers = TransformMetaUsersToSimpleArray(meta_users);

    let emails = _.concat(metaUsers || [], email_addresses);
    return emails.join(", ");
}
