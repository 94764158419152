import { SET_ERROR } from "../actions/action_types";
import { defaultError } from "../initialState";

export default function (state = { error: defaultError }, action) {
    switch (action.type) {
        case SET_ERROR:
            return Object.assign({}, state, {
                error: action.error,
            });
    }

    return state;
}
