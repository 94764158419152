import React from "react";
import ButtonV2 from "./v2";

const Button = (props) => {
    return (
        <React.Fragment>
            <ButtonV2 {...props} />
        </React.Fragment>
    );
};

export default Button;
