const DefaultIncludingListSeason = [
    {
        text: "Season only",
        value: "season_only",
    },
    {
        text: "Show & Season",
        value: "show_season",
    },
    {
        text: "Show & Season & Episodes",
        value: "show_season_episodes",
    },
];

export default DefaultIncludingListSeason;
