import React from "react";
import "./styles/_styles.scss";
import Switch from "../Switch";

const TitleTypeSwitches = (props) => {
    const {
        styles = {},
        data = {},
        inputKey = "title",
        presentation_title,
        handleTitleChange = () => {},
        handleReleaseTitleChange = () => {},
        customOptions = {}
    } = props;

    const { display, release, presentation, title } = data;

    const {
        displayText = "Display",
        hideDisplay = false,
        displayRelease = true,
        disableOnNotEmpty = true,
    } = customOptions;

    const RenderSwitch = (props) => (
        <div className="meta-title-type-switches--type">
            <Switch
                name={props.name}
                inputClassName={props.inputClassName}
                disabled={props.disabled}
                checked={props.checked}
                sliderClass="react-slider--short-dark-green"
                handleChange={(title) =>{
                    handleTitleChange(title, props.itemKey)
                    handleReleaseTitleChange(title, props.itemKey)
                }
                }
            />

            <span className="meta-title-type-switches--text">{props.text}</span>
        </div>
    );

    return (
        <div className="meta-title-type-switches" style={styles}>
            {!hideDisplay && (
                <RenderSwitch
                    name="DisplayTitle"
                    inputClassName="meta-display-title-switch"
                    disabled={
                        disableOnNotEmpty
                            ? display || !data[inputKey]
                            : !data[inputKey]
                    }
                    checked={display}
                    itemKey="display"
                    text={displayText}
                />
            )}

            {!presentation_title && displayRelease && (
                <RenderSwitch
                    name="OriginalReleaseTitle"
                    inputClassName="meta-release-title-switch"
                    disabled={!data[inputKey]}
                    checked={release}
                    itemKey="release"
                    text="Release"
                />
            )}

            {presentation_title && displayRelease && (
                <RenderSwitch
                    name="PresentationTitle"
                    inputClassName="meta-presentation-title-switch"
                    disabled={presentation || !data[inputKey]}
                    checked={presentation}
                    itemKey="presentation"
                    text="Presentation"
                />
            )}
        </div>
    );
};

export default TitleTypeSwitches;
