import { GridRowParams } from "@mui/x-data-grid-pro";
import { RenderActionsColProps } from "../../components/RenderActions.column";
import { StyledIconButton } from "../../components/StyledIconButton";
import Settings from "../../../../components/Settings"

export const RenderAddAction = (props: RenderActionsColProps) => {
    return [
        {
            onlyShowInEditState: true,
            field: "actions",
            type: "actions",
            width: 60,
            getActions: (params: GridRowParams) => {
                const actionButton = (
                    <StyledIconButton
                        aria-label={`add-row-${params.id}`}
                        onClick={() => props?.add?.handleOnClick(params)}
                    >
                        <img src={`${Settings.images.path}/svg/add-grey.svg`} alt="Add" />
                    </StyledIconButton>
                );

                return [actionButton];
            },
        },
    ];
};
