import React, { Component } from "react";
import deepcopy from "deepcopy";
import _ from "lodash";
import CheckboxTag from "../CheckboxTag";
import BubbleListSimpleOnEnterSingle from "../BubbleList/simpleOnEnterSingle";
import SelectAllToggle from "../SelectAllToggle";
import ClientManagedLanguages from "../ClientManagedOptions/languages";
import "./styles/_styles.scss";

export default class LocalisationOrderLanguages extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formData: {
                selectedLang: [],
            },
            formValid: false,
        };

        this.columnCount = 2;

        this.handleSelectAll = this.handleSelectAll.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.formValid != this.state.formValid) {
            if (typeof this.props.onFormValidChange == "function") {
                this.props.onFormValidChange(this.state.formValid);
            }
        }
    }

    getLanguages() {
        const { state = {}, clientFeatures = {} } = this.props;

        const { options = {} } = state;

        const {
            localisations: { languages = [] },
        } = options;

        const spokenLanguages = ClientManagedLanguages({
            options,
            spoken: true,
            fallback: languages,
            clientFeatures,
            languageKey: "localisation_orders",
        });

        return spokenLanguages;
    }

    isLanguageInArray(langArr, inLang) {
        return langArr.some((lang) => lang.value == inLang.value);
    }

    isFormDataValid({ selectedLang }) {
        return selectedLang.length > 0;
    }

    isSelectedAll(selectedLang) {
        return selectedLang.length == this.getLanguages().length;
    }

    generateLocalisationData() {
        const {
            orderData: {
                localisation_request_title,
                po_number,
                content_type,
                subtype,
                regions,
                casting,
                scripts,
                screeners,
                type,
                distributor,
                localisation_requester,
            },
        } = this.props;
        const { selectedLang } = this.state.formData;
        return {
            localisation_request_title,
            localisation_id: null,
            content_type,
            subtype,
            regions: deepcopy(regions),
            po_number,
            casting,
            distributor,
            localisation_requester,
            assigned_to: null,
            languages: selectedLang.map((lang) => ({
                locale: lang.value,
                language: lang.text,
                preferred_location: null,
                first_use_date: null,
                type: deepcopy(type),
                compliance: null,
                subtitles: false,
                dubbing: false,
                voicecover: false,
                accessibility: false,
                translations: false,
                studio: null,
                audio_description: false,
                sign_language: false,
                hard_of_hearing: false,
                all_screen_graphics_in_vision: false,
                episode_title_in_vision: false,
                original_credits_in_vision: false,
                end_dub_card_in_vision: false,
                screen_graphics_voiced: false,
                series_title_voiced: false,
                episode_title_voiced: false,
                original_credits_voiced: false,
                opening_songs_dubbed: false,
                all_songs_dubbed: false,
                songs_by_case: false,
                scripts,
                screeners,
                approver_email: null,
                user_notification: lang.email || null,
                status: "incomplete",
                language_notes: null,
            })),
        };
    }

    submit() {
        return this.state.formValid ? this.generateLocalisationData() : false;
    }

    getStateUpdateForSelectedLang(newSelectedLang) {
        const newFormData = { selectedLang: newSelectedLang };
        return {
            formData: newFormData,
            formValid: this.isFormDataValid(newFormData),
        };
    }

    handleLanguageClick(inLang) {
        this.setState(({ formData: { selectedLang } }) => {
            const newSelectedLang = this.isLanguageInArray(selectedLang, inLang)
                ? selectedLang.filter((lang) => lang.value != inLang.value)
                : [...selectedLang, deepcopy(inLang)];
            return this.getStateUpdateForSelectedLang(newSelectedLang);
        });
    }

    handleSelectAll() {
        this.setState(({ formData: { selectedLang } }) => {
            const newSelectedLang = this.isSelectedAll(selectedLang)
                ? []
                : _.unionBy(
                      selectedLang,
                      deepcopy(this.getLanguages()),
                      "value",
                  );
            return this.getStateUpdateForSelectedLang(newSelectedLang);
        });
    }

    handleLanguageEmailChange(inLang, [inEmail]) {
        if (!this.isLanguageInArray(this.state.formData.selectedLang, inLang)) {
            return;
        }

        this.setState(({ formData: { selectedLang } }) => {
            const lang = selectedLang.find(
                (lang) => lang.value == inLang.value,
            );
            lang.email = inEmail;
            return this.getStateUpdateForSelectedLang(selectedLang);
        });
    }

    renderHeader() {
        return (
            <div className="header">
                {this.renderTitle()}
                {this.renderSelectAll()}
            </div>
        );
    }

    renderTitle() {
        return <h3 className="title">Localisation Order Languages</h3>;
    }

    renderSelectAll() {
        return (
            <SelectAllToggle
                selected={this.isSelectedAll(this.state.formData.selectedLang)}
                onClick={this.handleSelectAll}
            />
        );
    }

    renderBody() {
        const rowCount = Math.ceil(
            this.getLanguages().length / this.columnCount,
        );
        return (
            <div
                className="body"
                style={{
                    gridTemplateRows: `auto repeat(${rowCount}, 1fr)`,
                }}
            >
                {this.renderColumnHeader()}
                {this.renderLanguages()}
            </div>
        );
    }

    renderColumnHeader() {
        return [...Array(this.columnCount)].map((_, i) => (
            <div
                key={`localisation-order-languages-column-header-${i}`}
                className="cell column-header"
            >
                <span>Select Languages</span>
                <span>User Notification</span>
            </div>
        ));
    }

    renderLanguages() {
        return this.getLanguages().map((language) => (
            <React.Fragment
                key={`localisation-order-languages-cell-${language.value}`}
            >
                {this.renderLanguage(language)}
            </React.Fragment>
        ));
    }

    renderLanguage(lang) {
        const { text: language, value: locale, tmc_code } = lang;
        const selected = this.isLanguageInArray(
            this.state.formData.selectedLang,
            lang,
        );
        return (
            <div className="cell">
                <CheckboxTag
                    leftText={language}
                    centeredContent={tmc_code || locale}
                    background="#2896a3"
                    leftTextStyle={{
                        marginRight: "0.5rem",
                    }}
                    centeredContentStyle={{
                        marginRight: "0.1875rem",
                        borderRadius: "0.3rem",
                        padding: "0.2rem 0.3125rem",
                        fontSize: "0.78125rem",
                        textAlign: "center",
                        minWidth: "2.1875rem",
                        color: selected ? "#2896a3" : "#ffffff",
                        background: selected ? "#ffffff" : "#787878",
                    }}
                    styles={{
                        minWidth: "18rem",
                    }}
                    selected={selected}
                    handleSelection={this.handleLanguageClick.bind(this, lang)}
                />
                {selected && (
                    <BubbleListSimpleOnEnterSingle
                        name={`meta-localisation-language-email-${lang.value}`}
                        id={`meta-localisation-language-email-${lang.value}`}
                        inputId={`meta-localisation-language-email-${lang.value}-input`}
                        background="#28a38d"
                        color="white"
                        inputStyles={{
                            padding: "0.375rem 0",
                            fontSize: "0.9375rem",
                            margin: "0",
                        }}
                        deleteWhite={true}
                        readOnly={false}
                        emptyPlaceholder="Type email address & hit Enter"
                        handleFormChange={this.handleLanguageEmailChange.bind(
                            this,
                            lang,
                        )}
                        handleEnter={() => {}}
                        useEnterButtonOnSelect={true}
                    />
                )}
            </div>
        );
    }

    render() {
        return (
            <div className="meta-localisation-order-languages">
                {this.renderHeader()}
                {this.renderBody()}
            </div>
        );
    }
}
