import React from "react";
import Input from "../../../Input/textarea";
import StatusTags from "../../../StatusTags";
import StarComment from "../../../StarComment";
import CustomSelect from "../../../CustomSelect";
import SimpleSlimSelect from "../../../Select/simpleSlim";

export default class Synopsis extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            copy = {},
            options = {},
            googleTranslate,
            googleTranslateItems,
            handleGooleTranslateClick,
            disabled,
        } = this.props;

        const { data = {} } = options;

        return (
            <div className="meta-record-synopsis">
                <Input
                    key={this.props.stateKey}
                    label={options.label}
                    placeholder={options.placeholder}
                    id={options.id}
                    value={data.synopsis}
                    name={options.id}
                    disabled={disabled}
                    maxCharacter={options.maxCharacter}
                    counter={true}
                    textArea={true}
                    recordBasic={true}
                    borderHiddenPadding={true}
                    error={this.props.error}
                    onBlur={this.props.handleOnBlur.bind(this)}
                    handleChange={(value) =>
                        this.props.handleChange({
                            type: options.id,
                            key: "synopsis",
                            value,
                        })
                    }
                />

                {disabled && (
                    <StatusTags data={data} copy={copy} text={data.synopsis} />
                )}

                <div
                    style={{
                        display: "flex",
                        marginTop: "0.9375rem",
                        alignItems: "flex-end",
                    }}
                >
                    <StarComment
                        id={`${options.id}_comment`}
                        name={`${options.id}_comment`}
                        text={data.comment}
                        disabled={disabled}
                        noCommentsText={copy.noCommentsText}
                        inputShallow={true}
                        handleChange={(value) =>
                            this.props.handleChange({
                                type: options.id,
                                key: "comment",
                                value,
                            })
                        }
                    />

                    {!disabled && (
                        <React.Fragment>
                            {googleTranslate && (
                                <CustomSelect
                                    label="Translate"
                                    styles={{
                                        margin: "0 0 0 0.8rem",
                                        display: "inline-block",
                                    }}
                                    itemsStyles={{
                                        right: 0,
                                        left: 0,
                                    }}
                                    disabled={!data.synopsis}
                                    items={googleTranslateItems}
                                    handleClick={handleGooleTranslateClick}
                                />
                            )}

                            <SimpleSlimSelect
                                disabled={!data.synopsis}
                                value={data.status}
                                data={[
                                    {
                                        text: "Approved",
                                        value: "approved",
                                    },
                                    {
                                        text: "Proposed",
                                        value: "proposed",
                                    },
                                ]}
                                handleChange={(value) =>
                                    this.props.handleChange({
                                        type: options.id,
                                        key: "status",
                                        value,
                                    })
                                }
                                wrapperMargin="0 0 0 0.8rem"
                                selectMargin="0"
                            />
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    }
}
