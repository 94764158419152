import _ from 'lodash'
import update from "react-addons-update";

const HandleCollectionTitleSelection = (props) => {
    const { item = {}, state = {} } = props;

    const { collectionTitle = {} } = state;

    const { selectedEpisodes = [] } = collectionTitle;

    const { selected, record } = item;

    let updatedState = selectedEpisodes;

    // Add
    if (selected && record) {
        updatedState = update(selectedEpisodes, { $push: [record] });
    }

    // Remove
    if (!selected && record) {
        const recordIndex = _.findIndex(selectedEpisodes, function (rc) {
            return rc.meta_id == record.meta_id;
        });

        updatedState = update(selectedEpisodes, {
            $splice: [[recordIndex, 1]],
        });
    }

    state.setCollectionTitleKey("selectedEpisodes", updatedState);
    state.setCollectionTitleKey("selectedAll", false);
};

export default HandleCollectionTitleSelection;
