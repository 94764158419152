import React from "react";
import _ from "lodash";
import styles from "../styles";
import Index from "./Index";
import Settings from "../../Settings";
import SendRequest from "../../SendRequest";
import PlaceholderText from "../../PlaceholderText";
import ContentLoading from "../../ContentLoading";
import GEEMultimediaDataTransformer from "../../Transformers/GEEMultimediaDataTransformer";
import Select from "../../Select";
import { Player } from "video-react";
import deepcopy from "deepcopy";
import { diff } from "deep-object-diff";
import "../styles/_styles.scss";
import Clients from "../../Clients";

export default class Videos extends Index {
    constructor(props) {
        super(props);

        let data = [];

        if (Array.isArray(this.props.data)) {
            data = this.props.data;
        }

        /**
         * @var object
         */
        this.styles = styles(this.props);

        /**
         * @var object
         */
        this.state = {
            data,
            videoIndex: 0,
            loading: false,
            geMultiMediaApiLoading: false,
            error: false,
        };

        this.IsMounted = false;
    }

    componentDidMount() {
        this.IsMounted = true;

        this.fetchS3Videos();
        this.fetchGeeMultiMediaVideos();
    }

    componentWillUnmount() {
        this.IsMounted = false;
    }

    shouldComponentUpdate(nextProps, nextState) {
        const nextPropsData = nextProps.data || [];
        const thisPropsData = this.props.data || [];
        const difference = diff(nextPropsData, thisPropsData);

        if (!_.isEmpty(difference)) {
            return true;
        }

        if (!_.isEqual(nextProps.disabled, this.props.disabled)) {
            return true;
        }

        if (!_.isEqual(nextProps.recordVideosUrl, this.props.recordVideosUrl)) {
            return true;
        }

        if (!_.isEqual(nextState, this.state)) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props.data, prevProps.data)) {
            let data = deepcopy(this.props.data || []);

            if (Array.isArray(this.props.data)) {
                data = deepcopy(this.props.data || []);
            }

            this.setStateValue("data", data);
        }
    }

    fetchS3Videos() {
        if (
            process.env.NODE_ENV !== "test" &&
            typeof this.props.recordVideosUrl == "string"
        ) {
            this.setStateValue("loading", true);

            const handleError = () => {
                this.setStateValue("loading", false);
                this.setStateValue("error", true);
            };

            this.fetchVideos({
                url: this.props.recordVideosUrl.replace(
                    "{meta_id}",
                    this.props.meta_id,
                ),
                onSuccess: (response) => {
                    if (this.IsMounted) {
                        if (typeof response == "object") {
                            if (
                                response.status == 200 &&
                                !_.isEmpty(response.data)
                            ) {
                                if (Array.isArray(response.data)) {
                                    this.setStateValue("videoIndex", 0);
                                }

                                let data = deepcopy(this.state.data || []);
                                data.push(...response.data);

                                this.setStateValue("loading", false);
                                this.setStateValue("error", false);
                                this.setStateValue("data", data);

                                return;
                            }
                        }

                        handleError();
                    }
                },
                onError: () => {
                    if (this.IsMounted) {
                        handleError();
                    }
                },
            });
        }
    }

    fetchGeeMultiMediaVideos() {
        const { cmsData = {}, recordData = {} } = this.props;

        let ids = {};
        if (recordData.ids && typeof recordData.ids == "object")
            ids = recordData.ids;

        const { imdb_id } = ids;

        const { apiUrls = {} } = cmsData;

        if (
            process.env.NODE_ENV !== "test" &&
            typeof process.env.GEE_MULTIMEDIA_API_URL == "string" &&
            imdb_id &&
            Clients.isClientGlobalEagle()
        ) {
            this.setStateValue("geMultiMediaApiLoading", true);

            const handleError = (error) => {
                this.setStateValue("geMultiMediaApiLoading", false);
                this.setStateValue("error", true);

                if (error) console.log(error);
            };

            this.fetchVideos({
                url: apiUrls.basicAuthProxy,
                method: "POST",
                body: {
                    url: process.env.GEE_MULTIMEDIA_API_URL.replace(
                        "{imdb_id}",
                        imdb_id,
                    ),
                    method: "POST",
                    username: process.env.GEE_MULTIMEDIA_API_USERNAME,
                    password: process.env.GEE_MULTIMEDIA_API_PASSWORD,
                },
                onSuccess: (response) => {
                    if (
                        response.data &&
                        !response.data.error &&
                        this.IsMounted
                    ) {
                        this.setStateValue("geMultiMediaApiLoading", false);
                        this.setStateValue("error", false);
                        this.setGeeMultimediaData(response.data);

                        return;
                    }
                },
                onError: (error) => {
                    if (this.IsMounted) {
                        handleError(error);
                    }
                },
            });
        }
    }

    setGeeMultimediaData(data) {
        let result = deepcopy(this.state.data || []);
        const results = GEEMultimediaDataTransformer(data);
        result.push(...results);

        this.setStateValue("data", result);
    }

    fetchVideos(props) {
        const { url, onError, onSuccess, body, method = "GET" } = props;

        let requestOptions = {
            url,
            method,
        };

        if (body) requestOptions.body = body;

        const handleError = () => {
            if (this.IsMounted) {
                onError();
            }
        };

        try {
            SendRequest(
                requestOptions,
                (response) => {
                    onSuccess(response);
                },
                (e) => {
                    handleError();
                },
            );
        } catch (e) {
            console.log(e);
            handleError();
        }
    }

    isLoading() {
        const { loading, geMultiMediaApiLoading } = this.state;

        let result = false;
        if (loading || geMultiMediaApiLoading) result = true;

        return result;
    }

    shouldShowPlayer() {
        return Array.isArray(this.state.data) && this.state.data.length > 0;
    }

    renderLoadingBasic() {
        if (this.isLoading() && !this.state.error) {
            return <ContentLoading />;
        }

        return false;
    }

    renderVideoSelect() {
        const { data, error, videoIndex } = this.state;

        let options = [];

        if (Array.isArray(data)) {
            data.forEach((item, index) => {
                let itemObject = {
                    text: item.source,
                    value: index,
                };

                if (typeof item.source == "string") {
                    itemObject.text = item.source.replace(
                        `${this.props.meta_id}/`,
                        "",
                    );
                }

                if (typeof item.title == "string") {
                    itemObject.text = item.title;
                }

                options.push(itemObject);
            });
        }

        if (!this.isLoading() && !error && !_.isEmpty(data)) {
            const containerStyle = {
                display: "flex",
                alignItems: "flex-end",
                width: "100%",
                justifyContent: "space-between",
            };

            return (
                <div style={containerStyle}>
                    <Select
                        value={videoIndex}
                        label={this.copy.selectVideo || "Select Video"}
                        noneSelectedText={
                            this.copy.noneSelected || "None Selected"
                        }
                        data={options}
                        wrapperStyles={{
                            width: "30rem",
                            marginLeft: "auto",
                            textAlign: "right",
                        }}
                        handleChange={(index) =>
                            this.setStateValue("videoIndex", index)
                        }
                    />
                </div>
            );
        }

        return false;
    }

    renderPlayer() {
        if (this.shouldShowPlayer()) {
            const { data, videoIndex } = this.state;

            let video = false;
            if (Array.isArray(data)) {
                video = data[videoIndex];
            }

            let videoOptions = {
                id: "my-player",
                preload: "auto",
            };

            if (typeof video == "object" && typeof video.poster == "string") {
                videoOptions.poster = `${Settings.videoAssets}/${video.poster}`;
            }

            if (this.state.data[videoIndex]) {
                let poster = this.state.data[videoIndex].poster
                    ? `${Settings.videoAssets}/${this.state.data[videoIndex].poster}`
                    : "";
                let source = this.state.data[videoIndex].source
                    ? `${Settings.videoAssets}/${this.state.data[videoIndex].source}`
                    : "";

                if (this.state.data[videoIndex].api) {
                    source = this.state.data[videoIndex].source;
                }

                if (source) {
                    return (
                        <div className="meta-video-container">
                            <Player playsInline poster={poster} src={source} />
                        </div>
                    );
                }
            }
        }

        return false;
    }

    /**
     * Renders DOm elements
     *
     * @return DOM elements
     */
    renderNoneAdded() {
        const data = this.state.data || [];

        if (Array.isArray(data)) {
            if (!_.isEmpty(data)) {
                return false;
            }
        }

        return (
            <div style={this.styles.marginItem}>
                <PlaceholderText copy={this.copy} />
            </div>
        );
    }

    renderContent() {
        return (
            <React.Fragment>
                {this.renderLoadingBasic()}
                {this.renderVideoSelect()}
                {this.renderPlayer()}
            </React.Fragment>
        );
    }
}
