import _ from 'lodash'
import { makeObservable, action, extendObservable } from 'mobx'
import { isEmpty } from 'lodash'
import { RootStore } from './rootStore'
import { ContributorStore } from './contributorStore'
import { contributorStoreInitialState } from "./state"
import { CoreDialogButtonsType } from "../../../../../core/Dialog/CoreDialog"
import { createContributor } from "../../../../../services/Contributors"
import { setSnackbar } from "../../../../../components/Global/store/reducer"
import { ContributorInterface } from "../../interfaces"

export class ContributorCreateStore extends ContributorStore {
    handleSetOpen: Function
    handleContributorCreated: (contributor: ContributorInterface) => void

    constructor(rootStore: RootStore) {
        super(rootStore)
        
        makeObservable(this, {
            getContributorDialogButtons: action
        });

        extendObservable(this, contributorStoreInitialState);
    }

    initialize(handleSetOpen: Function, handleContributorCreated: (contributor: ContributorInterface) => void) {
        this.handleSetOpen = handleSetOpen
        this.handleContributorCreated = handleContributorCreated

        this.reset()
        this.handleSetDataKey()
        this.rootStore.integrationsStore.initialize({})
    }

    reset() {
        Object.keys(contributorStoreInitialState).forEach(
            (key) => (this[key] = contributorStoreInitialState[key]),
        );

        this.setOpen(false)
        this.rootStore.navigationStore.handleActiveMenuReset()
        this.rootStore.validationStore.reset()
    }

    getContributorDialogButtons(): CoreDialogButtonsType {
        let result: CoreDialogButtonsType = {
            discard: {
                label: "Cancel",
                handleOnDiscard: () => this.handleResetAndCloseForm()
            }
        }

        if (this.rootStore.permissionsStore.userHasUpdatePermission) {
            result.submit = {
                handleOnSubmit: () => {
                    if (!isEmpty(this.rootStore.validationStore.getErrorSections())) {
                        this.setShowValidationErrors(true)
                    } else {
                        return this.handleSubmit()
                    }
                },
                label: "Save",
                disabled: isEmpty(this.userChanges) || this.showValidationErrors
            }
        }

        return result
    }

    handleResetAndCloseForm(): void {
        this.rootStore.reset()
        this.handleSetOpen(false)
    }

    async handleSubmit() {
        this.setWorking(true)
        const response = await createContributor(this.rootStore.cmsData, this.getTranformedContributor())

        this.setWorking(false)

        if (response.status === 201) {
            this.handleResetAndCloseForm()
            this.handleContributorCreated(response.data)
        } else {
            this.rootStore.validationStore.handleSetResponseErrors(response)

            this.rootStore.dispatch(
                setSnackbar({ 
                    message: "Failed to create contributor",
                    severity: "error" 
                })
            )
        }
    }
}
