import React from "react";
import _ from "lodash";
import { SectionInterface } from "./interface";
import ShortNumbering from "../../views/ShortNumbering";
import { getSectionCommonProps } from "../../actions";

const SectionShortNumbering = (props: SectionInterface) => {
    const { data = {} } = props;

    return (
        <ShortNumbering
            {...getSectionCommonProps({ ...props, field: "short_numbering" })}
            name="short-numbering"
            className="meta-record-short-numbering"
            title="Short Form Numbering"
            data={data.short_numbering || {}}
        />
    );
};

export default SectionShortNumbering;
