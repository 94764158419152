import React from "react";
import { SectionInterface } from "./interface";
import Shows from "../../views/Shows";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";

const SectionShows = (props: SectionInterface) => {
    const { attributes = {}, data = {} } = props;

    return (
        <Shows
            {...getSectionCommonProps({ ...props, field: "shows" })}
            name="shows"
            className="meta-record-shows"
            title="Shows"
            data={data.shows}
            lastSection={true}
            searchUrl={attributes.searchShowsUrl}
        />
    );
};

export default SectionShows;
