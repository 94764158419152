import React from "react";
import ExpandableRowV2 from "../../ExpandableRow/v2";
import _ from "lodash";
import "../styles/_doubleColumnContentExpandableStyles.scss";
import DoubleColumnContent from "./DoubleColumnContent";
import ContentFieldInterface from './ContentField.interface'

type Props = {
    headerText?: string | null | undefined;
    fields: ContentFieldInterface[];
    defaultOpen?: boolean;
    rowBodyStyles?: any;
    className?: string;
    disabled?: boolean;
    editable?: boolean;
    values?: any;
    handleChange: (key: string, value: any) => void;
};

const DoubleColumnContentExpandable: React.FC<Props> = (props) => {
    const {
        headerText = "",
        rowBodyStyles = {},
        defaultOpen = false,
        className = "",
    } = props;

    const RenderBody: React.FC = () => {
        return <DoubleColumnContent {...props} />;
    };

    const RenderHeader: React.FC = () => {
        return (
            <div className="double-column-content-section-header">
                <span>{headerText && headerText}</span>
            </div>
        )
    }

    return (
        <div className={`double-column-content-expandable ${className}`}>
            <ExpandableRowV2
                header={<RenderHeader/>}
                body={<RenderBody />}
                bodyStyles={rowBodyStyles}
                borderRadius={true}
                className="double-column-content-expandable-row"
                headerClassName="double-column-content-expandable-row-header"
                defaultOpen={defaultOpen}
                arrowIconType="small"
            />
        </div>
    );
};

export default DoubleColumnContentExpandable;
