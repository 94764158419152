import _ from "lodash";

const HandleNewRecordChange = (props) => {
    const { state = {}, key, value } = props;

    const { set, newRecord } = state;

    let updatedState = { ...newRecord };
    updatedState[key] = value;

    set({
        newRecord: updatedState,
        validated: true,
    });
};

export default HandleNewRecordChange;
