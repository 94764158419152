import _ from "lodash";

const AdaptListItem = (item) => {
    let result = item;
    if (!_.isEmpty(item) || _.isNumber(item)) {
        switch (typeof item) {
            case "object":
                if (!item.hasOwnProperty("text")) {
                    result.text =
                        item.text ||
                        item.name ||
                        item.value ||
                        item.original_title ||
                        "";
                }
                break;

            default:
                result = {
                    text: item,
                };
        }
    } else {
        result = {
            text: "",
        };
    }

    return result;
};

export default AdaptListItem;
