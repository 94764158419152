import update from "react-addons-update";
import deepcopy from "deepcopy";
import _ from "lodash";
import HandleChange from "../HandleChange";
import RecordData from "../GetRecordData";

const HandleDeleteCollectionTitles = (props) => {
    const { metaIds = [], state = {} } = props;

    const record = RecordData({
        state,
    });

    let updatedState = deepcopy(record.linkage || []);

    metaIds.forEach((meta_id) => {
        const index = _.findIndex(updatedState, function (item) {
            return item && item.meta_id == meta_id && !item.deleted;
        });

        if (Array.isArray(updatedState) && index !== -1) {
            updatedState = update(updatedState, {
                [index]: {
                    deleted: {
                        $set: true,
                    },
                },
            });
        }
    });

    HandleChange({
        key: "linkage",
        data: updatedState,
        state,
    });
};

export default HandleDeleteCollectionTitles;
