const handleSetCollectionTitle = (props) => {
    const { state, collectionTitle } = props;

    const { set } = state;

    set({
        collectionTitle,
    });
};

export default handleSetCollectionTitle;
