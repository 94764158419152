import { observable, makeObservable, extendObservable, action } from 'mobx'
import { RootStore } from "./rootStore"
import { copyCreditsToList } from "../services/CreditService"
import { validateCreditListForm } from "../services/FormService"
import { CreditListsInitialStateInterface, CreditListInterface, EmptyObject, CreditListFormProperty } from '../credits.interface'
import { originalCreditList, TempMetaIdPrefix } from "../config";
import { isEmpty, trimString, deepCopy } from "../utils/helpers"

const storeInitialState: CreditListsInitialStateInterface = {
    creditLists: []
}

export default class CreditListsStore {
    rootStore: RootStore

    creditLists: CreditListInterface[] = []
    activeCreditList: CreditListInterface = originalCreditList
    editedCreditList: CreditListInterface | EmptyObject = {}
    creditListForDelete: CreditListInterface | {} = {}
    creditListSelectOptions: CreditListInterface[] = []
    creditsSourceSelectOptions: string[] = []

    formProperties = {
        isActive: false,
        isDisabled: true,
        isNewList: false,
        isDeleteForm: false,
    }

    formValidation = {
        name: {
            valid: false,
            message: ""
        },
        code: {
            valid: false,
            message: ""
        }
    }

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore

        makeObservable(this, {
            activeCreditList: observable,
            editedCreditList: observable,
            creditListForDelete: observable,
            creditListSelectOptions: observable,
            creditsSourceSelectOptions: observable,
            formProperties: observable,
            formValidation: observable,

            initialize: action,
            setInitialCreditsLists: action,
            setInitialActiveList: action,
            setCreditLists: action,
            setActiveCreditList: action,
            setEditedCreditList: action,
            setCreditListForDelete: action,
            setCreditListSelectOptions: action,
            setCreditsSourceSelectOptions: action,
            setFormProperty: action,
            setFormStatus: action,
            createCreditList: action,
            updateCreditList: action,
            validateFormInput: action,
            resetFormValidation: action
        })

        extendObservable(this, storeInitialState)
    }

    initialize({ creditLists }: CreditListsInitialStateInterface): void {
        this.setInitialCreditsLists(creditLists)
        this.setInitialActiveList(creditLists)
        this.setCreditListSelectOptions()
        this.setCreditsSourceSelectOptions()
    }

    setInitialCreditsLists(creditLists: CreditListInterface[]): void {
        !isEmpty(creditLists) ? this.creditLists = creditLists : this.creditLists.push(originalCreditList)
    }

    setInitialActiveList(creditLists: CreditListInterface[]): void {
        this.activeCreditList = creditLists?.find(
            (list) =>
                list.code === originalCreditList.code
        ) || originalCreditList
    }

    setCreditListSelectOptions(): void {
        this.creditListSelectOptions = this.creditLists?.filter(
            (creditList) => {
                creditList.key = creditList.code
                creditList.value = creditList.code
                creditList.text = `${trimString(creditList.name, 20)} • ${creditList.code}`

                return creditList.deleted !== true
            })
    }

    setCreditsSourceSelectOptions(): void {
        this.creditsSourceSelectOptions =
            this.creditLists.filter(
                (creditList: CreditListInterface) => !creditList.deleted
            )
                .map(
                    (creditList: CreditListInterface) =>
                        `${creditList.name} • ${creditList.code}`
                )
    }

    setCreditLists(
        creditLists: CreditListInterface[],
        withSave: boolean = true
    ): void {
        this.creditLists = creditLists
        if (withSave) {
            this.rootStore.handleDataChange()
        }
    }

    setActiveCreditList(code: string) {
        let targetIndex = this.creditListSelectOptions.findIndex(
            (creditList) => creditList.code === code
        )

        if (targetIndex !== -1) {
            this.activeCreditList = this.creditListSelectOptions[targetIndex]
        }
    }

    setEditedCreditList(creditList: CreditListInterface | {}) {
        this.editedCreditList = deepCopy(creditList)
    }

    setCreditListForDelete(creditList: CreditListInterface | {}) {
        this.creditListForDelete = deepCopy(creditList)
    }

    setFormStatus({ isActive, isDisabled, isNewList, isDeleteForm }: CreditListFormProperty): void {
        this.formProperties = {
            isActive,
            isDisabled,
            isNewList,
            isDeleteForm
        }
    }

    setFormProperty(key: string, value: boolean) {
        this.formProperties[key as keyof CreditListFormProperty] = value
    }

    validateFormInput(field: string, value: string) {
        this.formValidation[field as 'name' | 'code'] = validateCreditListForm(field, value, this.creditLists, this.activeCreditList)[field]
    }

    resetFormValidation() {
        this.formValidation = {
            name: {
                valid: false,
                message: ""
            },
            code: {
                valid: false,
                message: ""
            }
        }
    }

    createCreditList(): void {
        if (!isEmpty(this.editedCreditList.creditsSource)) {
            this.rootStore.creditsStore.assignCreditsToCreditList(this.editedCreditList as CreditListInterface)
        }

        let creditLists: CreditListInterface[] = this.creditLists
        creditLists.push(this.editedCreditList as CreditListInterface)
        this.setCreditLists(creditLists)

        this.setCreditListSelectOptions()
        this.setActiveCreditList(this.editedCreditList.code)
        this.editedCreditList = {}
        this.rootStore.creditsStore.refreshTableData()
    }

    updateCreditList(): void {
        const targetListIndex = this.creditLists.findIndex(
            (creditList) =>
                creditList.meta_id === this.editedCreditList.meta_id
        );

        if (targetListIndex !== -1) {
            if (this.editedCreditList.code !== this.creditLists[targetListIndex].code) {

                this.creditLists[targetListIndex].deleted = true;
                this.editedCreditList.meta_id = `${TempMetaIdPrefix}${Math.floor(Math.random() * 100000000) + 1}`;

                let updateCreditLists: CreditListInterface[] = this.creditLists
                updateCreditLists.push(this.editedCreditList as CreditListInterface)
                this.setCreditLists(updateCreditLists);

                this.setCreditListSelectOptions();
                this.setActiveCreditList(this.editedCreditList.code);

                let updatedCredits = copyCreditsToList(
                    this.rootStore.creditsStore.credits,
                    this.creditLists[targetListIndex],
                    this.editedCreditList as CreditListInterface
                )

                this.rootStore.creditsStore.setCredits(updatedCredits)

            } else {
                const creditListsCopy = this.creditLists
                this.creditLists[targetListIndex].name = this.editedCreditList.name
                this.setCreditLists(creditListsCopy)
                this.setCreditListSelectOptions()
                this.setActiveCreditList(this.editedCreditList.code);
            }

            this.editedCreditList = {};
            this.rootStore.creditsStore.refreshTableData();
        }
    }

    deleteCreditList(creditListToDelete: CreditListInterface): void {
        const targetIndex = this.creditLists.findIndex(
            (creditList) => creditList.meta_id === creditListToDelete.meta_id
        )

        if (targetIndex !== -1) {
            let updateCreditLists: CreditListInterface[] = this.creditLists
            updateCreditLists[targetIndex].deleted = true;

            this.setCreditLists(updateCreditLists)
            this.setCreditListSelectOptions()
            this.setCreditListForDelete({})

            this.setActiveCreditList(this.creditLists[0].code)
            this.rootStore.creditsStore.refreshTableData()
        }
    }

    get getCreditLists(): CreditListInterface[] {
        return this.creditLists
    }

    get getActiveCreditList(): CreditListInterface {
        return this.activeCreditList
    }

    get getCreditListSelectOptions() {
        return this.creditListSelectOptions
    }

    get getCreditsSourceSelectOptions(): string[] {
        return this.creditsSourceSelectOptions
    }

    get getEditedList(): CreditListInterface | {} {
        return this.editedCreditList
    }

    get getCreditListForDelete(): CreditListInterface | {} {
        return this.creditListForDelete
    }

    get getFormStatus() {
        return this.formProperties
    }

    get getFormProperties() {
        return this.formProperties
    }

    get getFormValidation() {
        return this.formValidation
    }
}