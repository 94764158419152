import { gql } from "@apollo/client";
import { FieldMapInterface } from "../../interfaces";

const properties = `
    id
    displayName
    key
    order
    archived
`;

export const schemaGetAll = `
    getAssetCodeConfigs {
        ${properties}       
    }
`
export const queryGetAll = gql`
    query {
        ${schemaGetAll}
    }
`;

const map: FieldMapInterface = {
    queryAll: queryGetAll,
    dataPath: "getAssetCodeConfigs",
}

export default map