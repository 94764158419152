import React from "react";
import "./_styles.scss";
import Settings from "../../../../components/Settings";
import ActiveLicenceCheck from "../../../../components/ActiveLicenceCheck";

interface DateLockInterface {
    values: any;
}

const DateLock = ({ values }: DateLockInterface) => {
    const statusActive: boolean = ActiveLicenceCheck(
        values.license_start,
        values.license_end,
    );

    return (
        <div className={`date-lock ${statusActive ? "date-lock-active" : ""}`}>
            {statusActive && (
                <img src={`${Settings.images.path}/svg/lock-tick-white.svg`} />
            )}

            {!statusActive && (
                <img src={`${Settings.images.path}/svg/lock-cross-white.svg`} />
            )}
        </div>
    );
};

export default DateLock;
