import React, { Fragment } from "react";
import _ from "lodash";
import TitleValue from "../../../TitleValue";

const AssetDetailsBasicInfo = (props) => {
    const { dataStructure = {}, compliance = {} } = props;

    const { asset_details = {}, basic_info_options = {} } = dataStructure;

    const { basic_info = [] } = asset_details;

    const { width = "100%" } = basic_info_options;

    if (_.isEmpty(basic_info)) return false;

    const marginBottom = "1.15625rem";
    const dataNotAvailable = "Data not available.";

    return (
        <Fragment>
            {basic_info.map((item, index) => {
                return (
                    <TitleValue
                        key={index}
                        title={item.title}
                        values={compliance[item.key]}
                        style={{ marginBottom, width }}
                        emptyPlaceholder={dataNotAvailable}
                    />
                );
            })}
        </Fragment>
    );
};

export default AssetDetailsBasicInfo;
