import React, { useContext } from "react";
import { useStore } from "../../components/Global/store/store";
import { get } from 'lodash'

type FeatureEnabledContainerProps = {
    children: JSX.Element | JSX.Element[];
    featureName: string;
}

const FeatureEnabledContainer = ({ children, featureName }: FeatureEnabledContainerProps) => {
    const [state] = useStore() || [{}];
    const { cmsData = {} } = state;
    const { clientFeatures = {} } = cmsData;

    const enabled: boolean = !!get(clientFeatures, featureName, false)

    if (!enabled) {
        return <></>;
    }

    return (
        <>{children}</>
    )
};

export default FeatureEnabledContainer;