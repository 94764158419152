import React, { Component } from "react";
import styles from "./styles";
import Label from "./label";
import applyLinkQueryParams from "../Links/utils/applyLinkQueryParams";
import _ from "lodash";

export default class Input extends Component {
    constructor(props) {
        super(props);

        /**
         * @var object
         */
        this.state = {
            focus: this.props.focus || false,
            value: this.props.value || "",
            counter: 0,
            counterOverLimit: false,
            datalistActiveIndex: 0,
            filteredDataList: this.props.datalist || [],
            selectedItem: false,
        };

        /**
         * @var object|object
         */
        this.handleOutsideClick = this.handleOutsideClick.bind(this);

        /**
         * @var object|object
         */
        this.ref = false;

        /**
         * @var object|object
         */
        this.inputRef = {};

        /**
         * @var bool
         */
        this.isComponentMounted = false;

        /**
         * @var object
         */
        this.timer = null;

        /**
         * @var object
         */
        this.classStyles = styles(this.props);
    }

    componentWillMount() {
        document.addEventListener("click", this.handleOutsideClick, false);
    }

    componentDidMount() {
        this.isComponentMounted = true;

        if (this.props.setFocusOnMount) {
            this.handleFocus.bind(this, true);
        }
    }

    componentWillUnmount() {
        this.isComponentMounted = false;

        document.removeEventListener("click", this.handleOutsideClick, false);
    }

    setStateValue(key, value) {
        if (typeof key == "string") {
            if (!_.isEqual(this.state[key], value)) {
                this.setState({
                    [key]: value,
                });
            }
        }
    }

    setCounter() {
        if (this.props.counter) {
            const value = this.state.value || "";
            const counter = value.length;

            if (counter !== this.state.counter) {
                this.setState({
                    counter,
                });
            }
        }
    }

    setCounterOverLimit() {
        if (this.props.counter && this.props.maxCharacter) {
            let counterOverLimit = false;
            const value = this.state.value || "";

            if (value.length > this.props.maxCharacter) {
                counterOverLimit = true;
            }

            if (this.state.counterOverLimit !== counterOverLimit) {
                this.setState({
                    counterOverLimit,
                });
            }
        }
    }

    getOptions(props = {}) {
        const theme = process.THEME || {};
        const inputTheme = theme.input || {};

        let styleProps = {
            ...props,
        };

        const { borderBackground } = inputTheme;

        if (borderBackground) {
            styleProps.borderBackground = borderBackground;
        }

        const classStyles = styles(styleProps);

        let style = Object.assign(
            {},
            classStyles.input,
            !props.noBorder && classStyles.inputBorder,
            props.disabled && classStyles.borderHidden,
            props.disabled &&
                props.borderHiddenPadding &&
                classStyles.borderHiddenPadding,
            props.disabled && props.displayBorder && classStyles.displayBorder,
            !props.disabled && this.state.focus && classStyles.borderFocus,
            props.textArea && classStyles.textArea,
            !props.disabled && this.state.focus && classStyles.focus,
            !props.disabled && props.error && classStyles.inputBorderError,
            props.inputShallow && classStyles.inputShallow,
            props.placeholderPrefixIcon && classStyles.placeholderPrefixIcon,
            props.chevronBackground && classStyles.chevronbackground,
            props.placeholderSuffixIcon && classStyles.placeholderSuffixIcon,
            props.chevronbackgroundLightDark &&
                classStyles.chevronbackgroundLightDark,
            props.loading && classStyles.loading,
            props.styles && props.styles,
        );

        if (this.props.disabled) {
            style = {
                ...style,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                lineHeight: this.props.lineHeight || "1.2",
            };

            if (!this.props.value) {
                style.color = "rgba(188, 188, 188, 0.87)";
            }
        }

        let options = {
            type: props.type || "text",
            placeholder: props.placeholder || "",
            name: props.name || undefined,
            id: props.id || undefined,
            className: props.className || undefined,
            required: props.required || undefined,
            onChange: this.handleChange.bind(this),
            onFocus: this.handleFocus.bind(this, true),
            onBlur: this.handleBlur.bind(this),
            onClick: this.handleClick.bind(this),
            onMouseEnter: this.handleHover.bind(this, true),
            onMouseLeave: this.handleHover.bind(this, false),
            value: this.state.value || "",
            style: style,
        };

        if (typeof props.min !== undefined) {
            options.min = props.min;
        }

        if (typeof props.max !== undefined) {
            options.max = props.max;
        }

        if (typeof props.size !== undefined) {
            options.size = props.size;
        }

        if (
            typeof props.disabled !== undefined ||
            typeof props.readOnly !== undefined
        ) {
            options.readOnly = props.disabled || props.readOnly;
        }
        
        if (typeof props.autoFocus !== undefined) {
            options.autoFocus = props.autoFocus;
        }

        if (typeof props.datalistId !== undefined) {
            options.list = props.datalistId;
        }

        if (typeof props.pattern !== undefined) {
            options.pattern = props.pattern;
        }

        if (typeof props.autoComplete !== undefined) {
            options.autoComplete = props.autoComplete;
        }

        if (typeof props.onKeyPress !== undefined) {
            options.onKeyPress = props.onKeyPress;
        }

        if (typeof props.onKeyUp !== undefined) {
            options.onKeyUp = props.onKeyUp;
        }

        if (typeof props.maxLength !== undefined) {
            options.maxLength = props.maxLength;
        }

        if (props.defaultValue) {
            options.defaultValue = props.defaultValue;
            delete options.value;
        }

        return options;
    }

    getRecordBasicProps() {
        const options = {
            color: this.props.color || "rgba(47, 47, 47, 0.87)",
            borderBackground: this.props.borderBackground || "#CFCECE",
            borderBackgroundFocus: this.props.borderBackground || "#5f74b4",
            borderHeightFocus: "2px",
            borderHeight: "0.065rem",
            padding: this.props.padding || "0.25rem 0 0.6875rem",
            margin: this.props.margin || 0,
            display: this.props.display || "block",
        };

        return options;
    }

    handleOutsideClick(e) {
        if (this.node) {
            if (e.target) {
                if (
                    this.props.single &&
                    e.target.className == "meta-datalist-item"
                ) {
                    this.setState({
                        focus: false,
                    });
                }

                if (e.target.className == "meta-datalist-item") {
                    return;
                }
            }

            if (this.node.contains(e.target)) {
                return;
            }
        }

        if (this.props.enterOnFocusOut) {
            this.handleChangeCallback();
        }

        this.setState({
            focus: false,
        });
    }

    handleFocus(focus) {
        const { disableFocus } = this.props;

        if (!disableFocus) {
            this.setState({
                focus,
            });

            if (typeof this.props.onFocus === "function" && focus) {
                this.props.onFocus(true);
            }

            if (typeof this.props.onFocus === "function" && !focus) {
                this.props.onFocus(false);
            }
        }
    }

    handleBlur(event) {
        const { value = "" } = event.target;

        if (!this.props.datalist) {
            this.handleFocus(false);

            if (typeof this.props.onBlur === "function") {
                this.props.onBlur(value);
            }
        }
    }

    handleHover(hover) {
        if (typeof this.props.handleHover == "function") {
            this.props.handleHover(hover);
            this.setState({ hover });
        }
    }

    handleClick(e) {
        if (typeof this.props.href == "string" && this.state.value) {
            window.open(applyLinkQueryParams(this.props.href), "_blank");
        }

        if (typeof this.props.onClick == "function") {
            this.props.onClick(e);
        }
    }

    handleChangeCallback() {
        if (this.isComponentMounted && process.env.NODE_ENV !== "test") {
            if (!this.props.noDelay) {
                clearTimeout(this.timer);
                this.timer = setTimeout(
                    () => this.callBack(),
                    this.props.inputDelay || 600,
                );
            }

            if (this.props.noDelay) {
                this.callBack();
            }
        }

        if (process.env.NODE_ENV == "test") {
            this.callBack();
        }
    }

    callBack() {
        if (
            this.props.handleChange &&
            typeof this.props.handleChange === "function"
        ) {
            this.props.handleChange(this.state.value);
            clearTimeout(this.timer);
        }
    }

    renderLabel() {
        return <Label {...this.props} state={this.state} />;
    }

    renderDisabledInput(options, props = {}) {
        let spanOptions = { ...options };
        spanOptions.style.width = "auto";

        return (
            <span {...spanOptions}>
                {props.disabledPrefix && <b>{props.disabledPrefix} </b>}

                {this.props.value || this.props.placeholder}
            </span>
        );
    }
}
