import moment from 'moment'

export const formatDateTimeToPayload = (value, formatTo): string | null => {
    const dateString: Date | string = value ? moment(value.toDate()).toDate() : ""
    const date: string = moment(dateString).format(formatTo)

    if (moment(value).isValid()) {
        return date
    }

    return null
}