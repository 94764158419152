/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */

export enum BackgroundProcessingRecordStatuses {
    PENDING = "pending",
    PROCESSING = "processing",
    SUCCESS = "success",
    FAILED = "failed",
}

export type GetProcessDetailsMessageType = {
    action: string;
    payload: {
        processId: string;
    };
};

export enum BackgroundProcessStatuses {
    STARTED = "started",
    FINISHED = "finished",
    FAILED = "failed",
}

export enum WidgetState {
    HIDDEN = "hidden",
    LOADING = "loading",
    ERROR = "error",
    SUCCESS = "success",
}

export type BackgroundProcessingErrorType = {
    status: number;
    data: string[];
    message: string;
};

export type BackgroundProcessRecordType = {
    id: string;
    status: BackgroundProcessingRecordStatuses;
    title: string;
    category: string;
    error?: BackgroundProcessingErrorType | null | {};
};

export type BackgroundProcessPayloadType = {
    ticketId: number;
    records: BackgroundProcessRecordType[];
};

export type BackgroundProcessType = {
    processId: string;
    type: string;
    userId: number;
    status: BackgroundProcessStatuses;
    createdAt: string;
    payload: BackgroundProcessPayloadType;
};

export type BackgroundProcessingConnectedUserType = {
    processes: string[];
    instances: string[];
};

export enum BackgroundProcessTypes {
    TASK_MANAGER = "task-manager",
}

export enum BackgroundProcessingHandlersEnum {
    GET_PROCESS_DETAILS = "get-process-details",
    LIST_PROCESSES = "list-processes",
    KILL_PROCESS = "kill-process",
    NOTIFY_USER = "notify-user",
}

export type BackgroundProcessingSocketMessageType = {
    action: BackgroundProcessingHandlersEnum;
    subject: "background-process-started";
    receiver: string;
    process: BackgroundProcessType;
};

export type Message<TEvent> = {
    event: TEvent;
    message?: string;
};

export type WebSocketData<T> = { data?: T };

export type Event = {};

export type WebSocketMessageTypes = {
    [BackgroundProcessingHandlersEnum.GET_PROCESS_DETAILS]: Message<BackgroundProcessingHandlersEnum.GET_PROCESS_DETAILS> &
        BackgroundProcessType;
    [BackgroundProcessingHandlersEnum.LIST_PROCESSES]: BackgroundProcessType[] &
        Pick<Message<BackgroundProcessingHandlersEnum.LIST_PROCESSES>, "event">;
    [BackgroundProcessingHandlersEnum.KILL_PROCESS]: Message<BackgroundProcessingHandlersEnum.KILL_PROCESS> &
        Message<string>;
    [BackgroundProcessingHandlersEnum.NOTIFY_USER]: BackgroundProcessType &
        Pick<Message<BackgroundProcessingHandlersEnum.NOTIFY_USER>, "event">;
};

export type EventMap<T extends Record<string, any>> = {
    [K in keyof T]: (response: T[K]) => void;
};

export type ProcessIdType = Pick<BackgroundProcessType, "processId">;
