import React, { useContext } from "react";
import { SectionInterface } from "./interface";
import Awards from "../../../../features/Awards";
import { AppContext } from "../../store/context";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";

const SectionAwards = (props: SectionInterface) => {
    const { state } = useContext(AppContext);

    const { data = {} } = props;

    const { script } = state;

    return (
        <React.Fragment>
            <Awards
                {...getSectionCommonProps({ ...props, field: "awards" })}
                name="awards"
                className="meta-record-awards"
                title="Awards"
                data={data.awards || []}
                contributors={data.contributors || []}
                lastSection={true}
                script={script}
                allowCreateNew={true}
            />
        </React.Fragment>
    );
};

export default SectionAwards;
