import React from 'react'
import { observer } from "mobx-react";
import { RootStore, useStores } from "../../store/rootStore";
import Boilerplate from "../../../../../components/RecordBody/views/Boilerplate";
import ActiveTableActionBar from "./ActiveTableActionBar";
import { CastCrewTable } from "../../../../../core/Table/cast-crew/CastCrew.table"
import { DataTablePayload, CreditListInterface } from "../../credits.interface";
import { deepCopy } from '../../utils/helpers';


const ActiveCreditsContainer: React.FC = observer(() => {
    const rootStore = useStores() || {}
    const { creditsStore, creditListsStore } = rootStore as RootStore

    const tableRows: DataTablePayload[] = creditsStore.activeTableCredits
    const activeCreditList: CreditListInterface = creditListsStore.getActiveCreditList
    const tableFooterdata: string = `${activeCreditList.name} • ${activeCreditList.code} • Credit List ID: ${activeCreditList.meta_id}`

    const titleStyles = {
        flexFlow: 'column',
        paddingBottom: '0.625rem'
    }

    const handleCreditEdit = (credit: DataTablePayload): void => {
        let targetCreditIndex = creditsStore.credits.findIndex(
            (creditItem) =>
                creditItem.local_id === credit.localID
        )

        const activeCreditInstance = deepCopy(creditsStore.credits[targetCreditIndex])
        if (targetCreditIndex !== -1) creditsStore.setActiveCredit(activeCreditInstance)

        creditsStore.setCreditFormStatus({
            isActive: true,
            isDisabled: false,
            searchIsActive: false,
            isNewCredit: false
        })
    }

    return (
        <React.Fragment>
            <div id="active-credits">
                <Boilerplate
                    title="Cast & Crew"
                    titleRightContent={<ActiveTableActionBar />}
                    titleStyles={titleStyles}
                    noBorder={true}
                    className="meta-record-credits-v3">

                    <CastCrewTable
                        variant="active"
                        credits={tableRows}
                        footerData={tableFooterdata}
                        onSelect={(data) => creditsStore.setSelectedCredits(data, "active")}
                        onCreditEdit={(credit) => handleCreditEdit(credit)}
                        onReorder={(data: DataTablePayload[]) => creditsStore.syncCreditsOnReorder(data)}
                        onRowsChanged={() => { }}
                        onAdd={() => { }}
                    />
                </Boilerplate>
            </div>
        </React.Fragment>
    )
})

export default ActiveCreditsContainer