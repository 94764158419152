/**
 * @param array data
 */
export default function LinkageSearchTransformer(data = []) {
    let results = [];

    if (Array.isArray(data)) {
        data.map((item) => {
            let result = {};

            if (item.original_title) {
                result.original_title = item.original_title;
                result.text = item.original_title;
            }

            if (item.meta_id) {
                result.meta_id = item.meta_id;
            }

            if (item.id) {
                result.id = item.id;
            }

            if (item.first_release_year) {
                result.first_release_year = item.first_release_year;
            }

            if (item.category) {
                result.category = item.category;
            }

            if (item.category_name) {
                result.category = item.category_name;
            }

            if (typeof item.record_type == "object" && item.record_type) {
                result.type = item.record_type.type;
            }

            if (item.original_title && item.first_release_year) {
                result.text = `${item.original_title} (${item.first_release_year})`;

                if (typeof item.category_name == "string") {
                    result.text = `${item.original_title} (${item.category_name} - ${item.first_release_year})`;
                }
            }

            results.push(result);
        });
    }

    return results;
}
