import DefaultRoles from "./DefaultRoles";
import GlobalEagleRoles from "./GlobalEagle/Roles";
import MGMRoles from "./MGM/Roles";
import Clients from "../../components/Clients";

export default function ClientRoles() {
    let result = DefaultRoles();

    if (Clients.isClientGlobalEagle()) {
        result = GlobalEagleRoles();
    }

    if (Clients.isClientMGM() || Clients.isClientEpix()) {
        result = MGMRoles();
    }

    return result;
}
