import React from "react";
import { SectionInterface } from "./interface";
import EpisodeNumbering from "../../views/EpisodeNumbering";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";
import IsActiveModel from "../../../RecordBuilder/IsActive";
import { NUMBERING } from "../../../../models/RecordBuilder";
import SectionEpisodeNumberingCore from "./EpisodeNumberingCore";

const SectionEpisodeNumbering = (props: SectionInterface) => {
    const { cmsData = {}, data = {} } = props;

    const { clientFeatures = {}, clientDataStructure = {} } = cmsData;

    if (IsActiveModel({ page: NUMBERING, clientFeatures })) {
        return <SectionEpisodeNumberingCore {...props} />
    }
    
    return (
        <EpisodeNumbering
            {...getSectionCommonProps({ ...props, field: "episode_numbering" })}
            name="episode-numbering"
            className="meta-record-episode-numbering"
            title="Episode Numbering"
            data={data.episode_numbering || {}}
            clientFeatures={clientFeatures}
            clientDataStructure={clientDataStructure}
            category={data.category}
        />
    );
};

export default SectionEpisodeNumbering;
