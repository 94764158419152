import deepcopy from "deepcopy";
import { ValidationResult } from "./interfaces/Validation";
import DisabledRuleActive from "./rules/disabled";

export default function ValidateRecordType(
    result: ValidationResult,
    options: any,
): ValidationResult {
    let response: ValidationResult = deepcopy(result);

    const { data = {}, status, clientDataStructure = {} } = options;

    const recordTypeValidation =
        clientDataStructure?.fieldValidation?.origination?.type;

    const {
        origination = {},
        category = false,
        status: recordStatus = ''
    } = data;

    const { type } = origination;

    if (
        DisabledRuleActive({
            recordStatus,
            category,
            type,
            dataStructure: clientDataStructure,
            fieldPath: "origination.type",
        })
    ) {
        return response;
    }

    if ((!origination || !origination.type) && recordTypeValidation) {
        response.result = false;
        response.notification = {
            title: "Please check record type",
            description: "Record Type is missing",
            status,
        };

        response.validationKeys = result.validationKeys.concat(["type"]);
    }

    return response;
}
