import { isArray } from "lodash";
import { WSBaseUrl, WSParams } from "../../services/api.config";
import { RootStore } from "../../store/rootStore";
import {
    BackgroundProcessingHandlersEnum,
    BackgroundProcessStatuses,
    BackgroundProcessType,
    GetProcessDetailsMessageType,
    ProcessIdType,
    WebSocketMessageTypes,
} from "../types";
import { isWebSocketURLValid } from "./isWebSocketURLValid";

function logWebSocketEvent(eventName, type: "SENT" | "RECEIVED", message) {
    // console.log(
    //     `[WebSocket] [EVENT: ${eventName}] [${type}] [MESSAGE: ${JSON.stringify(
    //         message,
    //     )}]`,
    // );
}

export class WebSocketService {
    private ws: WebSocket | null = null;
    private serverUrl: string;
    private rootStore: RootStore;

    constructor(userToken: string, rootStore: RootStore) {
        this.rootStore = rootStore;

        if (isWebSocketURLValid()) {
            try {
                this.serverUrl = `${WSBaseUrl}${WSParams.TOKEN}${userToken}`;
                this.initializeWebSocket();
            } catch (error) {
                console.log("Error initializing WebSocket", error);
            }
        }
    }

    eventHandlerMap = {
        [BackgroundProcessingHandlersEnum.GET_PROCESS_DETAILS]: (
            data: WebSocketMessageTypes[BackgroundProcessingHandlersEnum.GET_PROCESS_DETAILS],
        ) => {
            logWebSocketEvent(
                BackgroundProcessingHandlersEnum.GET_PROCESS_DETAILS,
                "RECEIVED",
                data,
            );
        },

        [BackgroundProcessingHandlersEnum.LIST_PROCESSES]: (
            data: WebSocketMessageTypes[BackgroundProcessingHandlersEnum.LIST_PROCESSES],
        ) => {
            logWebSocketEvent(
                BackgroundProcessingHandlersEnum.LIST_PROCESSES,
                "RECEIVED",
                data,
            );

            if (!isArray(data)) {
                console.log(
                    `[ERR] Received invalid data for ${BackgroundProcessingHandlersEnum.LIST_PROCESSES} event`,
                    data,
                );
                return;
            }

            const processes: BackgroundProcessType[] = data;
            console.log("Received Processes: ", processes);
            this.rootStore.backgroundProcessingStore.setProcesses(processes);
        },
        [BackgroundProcessingHandlersEnum.KILL_PROCESS]: (
            data: WebSocketMessageTypes[BackgroundProcessingHandlersEnum.KILL_PROCESS],
        ) => {
            logWebSocketEvent(
                BackgroundProcessingHandlersEnum.KILL_PROCESS,
                "RECEIVED",
                data,
            );
        },
        [BackgroundProcessingHandlersEnum.NOTIFY_USER]: (
            process: WebSocketMessageTypes[BackgroundProcessingHandlersEnum.NOTIFY_USER],
        ) => {
            logWebSocketEvent(
                BackgroundProcessingHandlersEnum.NOTIFY_USER,
                "RECEIVED",
                process,
            );

            this.rootStore.backgroundProcessingStore.updateProcess(process);

            if (process?.status === BackgroundProcessStatuses.FINISHED) {
                this.rootStore.pushSnackbar(
                    `✅ Records from 'Ticket #${process.payload.ticketId}' have processed successfully!`,
                );
            }
        },
        // You can add more event handlers here for other events as needed
    };

    private initializeWebSocket() {
        this.ws = new WebSocket(this.serverUrl);

        this.ws.onopen = () => {
            console.log("WebSocket connection opened");
        };

        this.ws.onmessage = (messageEvent: MessageEvent) => {
            const messageJSON = JSON.parse(messageEvent.data);
            const event = messageJSON?.event;
            const data = messageJSON?.data;

            const eventHandler = this.eventHandlerMap[event];

            if (!eventHandler) {
                console.log("WebSocket Received message:", messageEvent);
                return;
            }

            eventHandler(data);
        };

        this.ws.onerror = (event) => {
            console.log("WebSocket connection error:", event);
            this.rootStore.pushSnackbar("WebSocket connection error");
        };

        this.ws.onclose = (event) => {
            console.log("WebSocket connection closed:", event.reason);
        };
    }

    public sendMessage(message: any) {
        if (this.ws && this.ws.readyState === WebSocket.OPEN) {
            this.ws.send(JSON.stringify(message));
        }
    }

    public closeWebSocket() {
        if (this.ws && this.ws.readyState === WebSocket.OPEN) {
            this.ws.close();
        }
    }

    public getProcessDetails(processId: ProcessIdType["processId"]) {
        const message: GetProcessDetailsMessageType = {
            action: BackgroundProcessingHandlersEnum.GET_PROCESS_DETAILS,
            payload: {
                processId,
            },
        };
        logWebSocketEvent(
            BackgroundProcessingHandlersEnum.GET_PROCESS_DETAILS,
            "SENT",
            message,
        );
        this.sendMessage(message);
    }

    public killProcess(processId: ProcessIdType["processId"]) {
        const message: GetProcessDetailsMessageType = {
            action: BackgroundProcessingHandlersEnum.KILL_PROCESS,
            payload: {
                processId,
            },
        };
        logWebSocketEvent(
            BackgroundProcessingHandlersEnum.KILL_PROCESS,
            "SENT",
            message,
        );
        this.sendMessage(message);
    }

    public listProcesses() {
        const message: Pick<GetProcessDetailsMessageType, "action"> = {
            action: BackgroundProcessingHandlersEnum.LIST_PROCESSES,
        };
        logWebSocketEvent(
            BackgroundProcessingHandlersEnum.LIST_PROCESSES,
            "SENT",
            message,
        );
        this.sendMessage(message);
    }
}

export default WebSocketService;
