import ValidateFirstReleaseYearProperties from "./ValidateFirstReleaseYearProperties";
import ValidateGenres from "./ValidateGenres";
import ValidateSubGenres from "./ValidateSubGenres";
import ValidateProperties from "./ValidateProperties";
import ValidateEidr from "./ValidateEidr";
import ValidateAssets from "./ValidateAssets";
import { validateTitles } from "../../models/Validation/regional/validateTitles";
import { validateSynopses } from "../../models/Validation/regional/validateSynopses";
import { validateIdentifiers } from "../../models/Validation/Identifiers";

export default function Game(options = {}) {
    let result = {
        result: true,
        notification: {},
        validationKeys: [],
    };

    // First release year
    result = ValidateFirstReleaseYearProperties(result, options);

    // Regional
    result = validateTitles({ result, options });
    result = validateSynopses({ result, options });

    // Genrres
    result = ValidateGenres(result, options);

    // Sub Genrres
    result = ValidateSubGenres(result, options);

    // EIDR
    result = ValidateEidr(result, options);

    // Assets
    result = ValidateAssets(result, options);

    // Properties
    result = ValidateProperties(result, options);

    // Identifiers
    result = validateIdentifiers({ result, options });

    return result;
}
