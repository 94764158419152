import React from "react";
import Button from "./v1";
import filterFilesByExtension from "../File/filterFilesByExtension"

export default class ButtonFileUpload extends Button {
    /**
     * Handles change
     *
     * @param object e
     * @return DOM elements
     */
    handleOnChange(e) {
        if (typeof this.props.handleChange == "function") {
            this.props.handleChange(
                filterFilesByExtension(e, this.props.acceptedFormats)
            )
        }
    }

    /**
     * Renders html dom elements
     *
     * @return DOM elements
     */
    render() {
        let inputProps = {};
        if (this.props.acceptedFormats) {
            inputProps.accept = this.props.acceptedFormats;
        }

        return (
            <React.Fragment>
                <label
                    htmlFor="file-upload"
                    id="custom-file-upload"
                    style={this.getStyles()}
                >
                    {this.renderContent()}
                </label>

                <input
                    type="file"
                    id="file-upload"
                    multiple={this.props.multiple}
                    style={{
                        display: "none",
                    }}
                    onChange={this.handleOnChange.bind(this)}
                    {...inputProps}
                />
            </React.Fragment>
        );
    }
}
