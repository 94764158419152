/* eslint-disable @typescript-eslint/no-unused-vars */
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useMemo } from "react";
import { CoreTableGridColDef } from "../../../../../core/Table/types";
import { CoreTableCell } from "../../../../../core/Table/components/CoreTableCell";

const RenderCell = (params: GridRenderCellParams<string>) => {
    const elem = useMemo(
        () => (
            <CoreTableCell
                linkProps={{
                    href: "#",
                    target: "_blank",
                }}
                color="#286CD2"
            >
                {params?.value}
            </CoreTableCell>
        ),
        [params?.value],
    );
    return elem;
};


export const FilmographyRecordCol: CoreTableGridColDef = {
    headerName: "Record",
    field: "record",
    flex: 1,
    minWidth: 160,
    maxWidth: 250,
    renderCell: (params: GridRenderCellParams<string>) => {
        return <RenderCell {...params} />;
    },
};
