import React from "react";
import { SectionInterface } from "./interface";
import Rights from "../../views/Rights";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";
import IsActiveRecordBuilder from "../../../RecordBuilder/IsActive";
import { RIGHTS } from "../../../../models/RecordBuilder";
import RightsRecordBuilder from "../../../../features/Rights/RecordBuilder";

const SectionRights = (props: SectionInterface) => {
    const { cmsData = {}, data = {}, disabled, attributes = {} } = props;

    const { clientDataStructure = {}, clientFeatures = {} } = cmsData;

    const { rights = [] } = data;

    const { readOnlyRights = false } = clientFeatures;

    if (IsActiveRecordBuilder({ page: RIGHTS, clientFeatures })) {
        return (
            <RightsRecordBuilder
                {...getSectionCommonProps({ ...props, field: "rights" })}
                name="rights"
                className="meta-record-rights"
                data={rights}
            />
        );
    }

    return (
        <Rights
            {...getSectionCommonProps({ ...props, field: "rights" })}
            name="rights"
            className="meta-record-rights"
            title="Rights"
            data={rights}
            disabled={disabled || readOnlyRights}
            fetchRightsUrl={attributes.fetchRightsUrl}
            cmsData={cmsData}
            rights={rights}
            lastSection={true}
            date={attributes.date}
            clientDataStructure={clientDataStructure}
        />
    );
};

export default SectionRights;
