import CategoryTags from "./index";
import { eidrCategories } from "./eidrCategories";
import _ from "lodash";
import deepcopy from "deepcopy";
import Config from "../Clients/Config/index";
import { getCustomCategories } from "../../models/Record/categories";

const ClientFeatures = (props) => {
    const {
        clientFeatures,
        clientDataStructure = {},
        createRecord = false,
        eidr = false,
    } = props;

    const { categories = {} } = clientDataStructure;

    const { hidden = [], overwrite = {} } = categories;

    let result = [];

    if (eidr) {
        result = deepcopy(eidrCategories);
    } else {
        result = deepcopy(CategoryTags);
    }

    if (createRecord) {
        Object.keys(result).map((key) => {
            if (!result[key].createRecord) {
                delete result[key];
            }
        });
    }

    if (clientFeatures && !clientFeatures.games) {
        delete result.Game;
    }

    // Remove unused Custom Categories
    const customCategories =
        getCustomCategories({
            clientDataStructure,
            clientFeatures,
        }) || [];

    Object.keys(result).map((key) => {
        if (
            result[key].customCategory &&
            !customCategories.includes(result[key].short)
        ) {
            delete result[key];
        }
    });

    // Remove hidden categories
    if (!_.isEmpty(hidden) && Array.isArray(hidden)) {
        hidden.forEach((item) => {
            delete result[item];
        });
    }

    // Overwrite categories
    if (!_.isEmpty(overwrite)) {
        Object.keys(overwrite).map((category) => {
            Object.keys(overwrite[category]).map((key) => {
                result[category][key] = overwrite[category][key];
            });
        });
    }

    //check on Config
    if (
        !_.isEmpty(result) &&
        !_.isEmpty(Config.getCreateRecordConfig()) &&
        createRecord
    ) {
        let configs = Config.getCreateRecordConfig();

        if (
            typeof configs == "object" &&
            configs.hasOwnProperty("availableCategories")
        ) {
            if (Array.isArray(configs.availableCategories)) {
                Object.keys(result).map((key) => {
                    if (
                        !configs.availableCategories.includes(result[key].short)
                    ) {
                        delete result[key];
                    }
                });
            }
        }
    }

    return result;
};

export default ClientFeatures;
