import React, { Fragment } from "react";
import { useStore } from "../../../state/context";
import { ASSET_TYPES_CONFIG } from "../../../configuration";
import Section from "../section";
import HierarchyBody from "./HierarchyBody";
import _ from "lodash";
import AssetInterface from "../interfaces/AssetInterface";
import SubDubInterface from "../interfaces/SubDubInterface";
import AssetTypeInterface from "../interfaces/AssetTypeInterface";
import GetSectionConfiguration, {
    IsAdditionalSection,
    SUBTITLE_SECTION,
    AUDIO_SECTION,
} from "../section/AdditionalSection";
import SubDubContent from "./SubDubContent";

type BodyProps = {
    asset: AssetInterface | SubDubInterface;
    isLastChild: boolean;
    hierarchyLevel: number;
    expandAll: boolean;
};

const Body: React.FC = (props: BodyProps) => {
    const [store] = useStore();

    const {
        asset,
        isLastChild = true,
        hierarchyLevel = 0,
        expandAll = false,
    } = props;

    const {
        type,
        destination_platform,
        parentType,
        child_assets = [],
        subtitle_tracks = [],
        alternate_language_audio_tracks = [],
    } = asset;

    const { configuration, category } = store;

    let assetTypeConfig: AssetTypeInterface | undefined = configuration[
        ASSET_TYPES_CONFIG
    ].find((item) => item.code == type && item.destinationPlatform == destination_platform );

    if (!assetTypeConfig) {
        assetTypeConfig = configuration[ASSET_TYPES_CONFIG].find(
            (item) => item.code == parentType,
        );
    }

    const assetHasChildRows: boolean =
        !_.isEmpty(child_assets) ||
        !_.isEmpty(subtitle_tracks) ||
        !_.isEmpty(alternate_language_audio_tracks);

    return (
        <div className="asset-body">
            <HierarchyBody
                hierarchyLevel={hierarchyLevel}
                isLastChild={isLastChild}
                assetHasChildRows={assetHasChildRows}
            />
            <div className="content">
                {assetTypeConfig && (
                    <Fragment>
                        {(type == SUBTITLE_SECTION ||
                            type == AUDIO_SECTION) && (
                            <SubDubContent
                                asset={asset}
                                typeConfiguration={assetTypeConfig}
                            />
                        )}

                        {!(type == SUBTITLE_SECTION || type == AUDIO_SECTION) &&
                            assetTypeConfig.sections
                                .filter(
                                    (item) => !IsAdditionalSection(item.key),
                                )
                                .filter((item) => !item.archived)
                                .map((section, key) => {
                                    return (
                                        <Section
                                            asset={asset}
                                            configuration={section}
                                            key={key}
                                            expandAll={expandAll}
                                            category={category}
                                        />
                                    );
                                })}
                    </Fragment>
                )}
            </div>
        </div>
    );
};

export default Body;
