import { Box, MenuItem } from "@mui/material";
import { AutocompleteRecordCard } from "../../features/TaskManager/containers/record/tv-series/components/AutocompleteRecordCard";

export const AutocompleteOptionItem = ({
    option,
    enabledClick,
    disableEmptyCard,
    hideSuggestions,
    onClick,
}) => {
    const Item = enabledClick ? MenuItem : Box;

    return (
        <Item
            key={option.title + option.metaId}
            value={option.title}
            onClick={() => {
                if (enabledClick) {
                    hideSuggestions();
                    onClick(option);
                }
            }}
            sx={{
                background: "#fff",
                cursor: disableEmptyCard ? "auto" : "pointer",
                height: "5.625rem",
                border: "1px solid #E6E6E6",
                borderRadius: "0.1875rem",
                margin: "0 0 0.625rem 0",
                overflow: "hidden",
                padding: "0",
                disabled: disableEmptyCard,
                "&:hover": {
                    background: disableEmptyCard
                        ? "transparent"
                        : "rgba(40, 108, 210, 0.05)",
                    border: disableEmptyCard
                        ? "1px solid #E6E6E6"
                        : "1px solid rgba(40, 108, 210, 0.75)",
                },
                "&:last-child": {
                    margin: 0,
                },
                "& .MuiPaper-root": {
                    background: "transparent",
                    margin: 0,
                    padding: 0,
                },
            }}
            disableRipple
        >
            {/* Assuming AutocompleteRecordCard is a component to render the record */}
            <AutocompleteRecordCard record={option} />
        </Item>
    );
};
