import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { CoreTableCell } from "../../components/CoreTableCell";
import { CoreTableGridColDef } from "../../types";

export const ResolutionCol: CoreTableGridColDef = {
    headerName: "Resolution (px)",
    field: "resolution",
    flex: 1,
    renderCell: (params: GridRenderCellParams<string>) => {
        const { row } = params;
        
        return <CoreTableCell>{row.resolution || "-"}</CoreTableCell>;
    },
};
