import { isFunction } from "lodash"
import { getSearchUrl } from "../../services/Record/api.config";

export function openNewWindow(props) {
    const { searchTerm, url = {}, openNewTab, advancedSearch = false } = props;

    let urlPath = `/discovery/${searchTerm}`;

    if (typeof url.params == "string" && !advancedSearch) {
        urlPath = `/discovery/${searchTerm}${url.params}`;
        const category = url.categoryTag;

        if (typeof category == "object" && isFunction(window.open)) {
            if (category.url) {
                return window
                    .open(
                        `${category.url}/${searchTerm}${url.params}`,
                        "_blank",
                        "noreferrer",
                    )
            }
        }
    }

    if (advancedSearch) {
        urlPath = getSearchUrl(searchTerm)
    }

    if (openNewTab) {
        return window.open(`${urlPath}`, "_blank", "noreferrer")
    }
}

export function clearUrl(props) {
    const { url } = props;

    if (history) {
        history.pushState({}, "", url);
    }
}

export default function getObject(location = {}) {
    let result = {};

    if (typeof location == "object") {
        let pathname = location.pathname;
        let search = location.search;

        if (typeof pathname == "string") {
            if (pathname.charAt(0) == "/") {
                pathname = pathname.substring(1);
            }

            const pathArray = pathname.split("/");

            if (Array.isArray(pathArray)) {
                if (pathArray[0] == "discovery") {
                    result.base = pathArray[0];

                    if (typeof pathArray[1] == "string") {
                        result.search = pathArray[1];
                    }
                }
            }
        }

        if (typeof search == "string" && typeof window == "object" && window) {
            if (URLSearchParams) {
                const urlParams = new URLSearchParams(search);

                if (urlParams.has("selected")) {
                    const selected = urlParams.get("selected");
                    result.selected = selected;
                    result.params = `?selected=${selected}`;
                }

                if (urlParams.has("menu")) {
                    const menu = urlParams.get("menu");
                    result.menu = menu;
                    result.params = result.params + `&menu=${menu}`;
                }
            }
        }
    }

    return result;
}
