import { v4 as uuidv4 } from "uuid";

const HandleCancelConfirm = (state) => {
    const { originalRecord, newRecord, originalNewRecord, set } = state;

    set({
        recordData: originalRecord,
        unsavedChanges: false,
        disabled: true,
        editRecord: false,
        notification: {},
        recordChanges: {},
        collectionTitle: {},
        recordChangesUuid: uuidv4()
    });

    if (newRecord && originalNewRecord) {
        set({
            newRecord: originalNewRecord,
        });
    }
};

export default HandleCancelConfirm;
