import React from "react";
import { RightsStore } from "./rightsStore";

export class RootStore {
    rightsStore: RightsStore;

    constructor() {
        this.rightsStore = new RightsStore();
    }
}

const StoresContext = React.createContext(new RootStore());
export const useStores = () => React.useContext(StoresContext);
