import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { PrimaryVT } from "../../../../config/types";
import { useStores } from "../../../../store/rootStore";
import { RecordResponse } from "../../../../types/interfaces";
import { TvSeriesErrorMap } from "../TvSeriesErrorMap";

export const useFetchRecordByMetaId = (metaId: PrimaryVT["metaId"]) => {
    const rootStore = useStores();
    const [record, setRecord] = useState<RecordResponse | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchRecord = async () => {
            try {
                setLoading(true);
                const response =
                    await rootStore.recordServiceApi.searchByMetaIds([metaId]);
                if (!isEmpty(response[0]?.data)) {
                    setRecord(response[0].data);
                }
            } catch (e) {
                console.error(e);
                rootStore.pushSnackbar(TvSeriesErrorMap.ERR3);
            } finally {
                setLoading(false);
            }
        };

        if (!isEmpty(metaId)) {
            fetchRecord();
        }

        return () => {
            setLoading(false);
        };
    }, [metaId]);

    return {
        record,
        loading,
    };
};
