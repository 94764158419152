import CategoryTags from "../../../../CategoryTags";

const ShowLabelOfSeasonsEpisodesCounts = (props) => {
    const {
        parentShowLinkages = [],
        seasonsLabel = "Seasons",
        episodesLabel = "Eps",
        divider = " • ",
    } = props;

    let seasons = parentShowLinkages.filter((record) => {
        let recordCategory = record.category || record.categry_name;
        if (recordCategory) {
            return recordCategory == CategoryTags.Season.short;
        } else return false;
    });

    let episodesCount = 0;
    seasons.map((season) => {
        if (season.number_of_episodes)
            episodesCount += season.number_of_episodes;
    });

    let seasonsText = seasons.length + " " + seasonsLabel;
    let episodesText = episodesCount + " " + episodesLabel;

    return {
        seasonCount: seasons.length,
        episodesCount: episodesCount,
        seasonsLabel: seasonsText,
        episodesLabel: episodesText,
        scopeLabel: seasonsText + divider + episodesText,
    };
};

export default ShowLabelOfSeasonsEpisodesCounts;
