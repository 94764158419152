export default function TransformMetaUsers(props) {
    const { metaUsers, meta_users = [] } = props;

    let result = [];

    if (Array.isArray(metaUsers)) {
        metaUsers.forEach((item) => {
            if (typeof item == "string") {
                const metaUser = meta_users.find((user) => user.email == item);

                if (metaUser) {
                    result.push({
                        text: metaUser.fullName,
                        list: `${metaUser.fullName} (${metaUser.email})`,
                        value: metaUser.email,
                    });
                }
            }

            if (typeof item == "object" && item) {
                result.push(item);
            }
        });
    }

    return result;
}
