import _ from "lodash";

const PRIORITY_KEY_NAME: string = "name";
const PRIORITY_KEY_TEXT: string = "text";
const PRIORITY_KEY_VALUE: string = "value";
const PRIORITY_KEY_ORIGINAL_TITLE: string = "original_title";

export const PRIORITY_KEYS_NAME_OR_TEXT = [
    PRIORITY_KEY_VALUE,
    PRIORITY_KEY_NAME,
    PRIORITY_KEY_TEXT,
];

export const PRIORITY_KEYS_TEXT_OR_ORIGINAL_TITLE = [
    PRIORITY_KEY_ORIGINAL_TITLE,
    PRIORITY_KEY_VALUE,
    PRIORITY_KEY_NAME,
    PRIORITY_KEY_TEXT,
];

interface PropsInterface {
    item: any;
    priority: Array<string>;
}

export function StringPriorityTransformer({
    item,
    priority,
}: PropsInterface): any {
    let result = item;

    if (!_.isEmpty(item) && _.isObject(item) && Array.isArray(priority)) {
        priority.forEach((key: string) => {
            if (item[key]) {
                result = item[key];
            }
        });
    }

    return result;
}
