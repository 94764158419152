import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 500,
    fontSize: "1.25rem",
    lineHeight: "150%",
    color: "#313E52", // TODO: get from theme
    // marginBottom: "1rem",
}));

export type CoreFormTitleProps = {
    text: string;
};

export function CoreFormTitle({ text }: CoreFormTitleProps) {
    return (
        <StyledTitle align="left" margin="dense">
            {text}
        </StyledTitle>
    );
}
