import { CreditScriptedNameInterface } from "../../../credits.interface";
//import { isEmpty } from "../../../utils/helpers"

const setNameScriptValue = (
    scriptNames: CreditScriptedNameInterface[],
    field: string,
    script: string,
    value: string
): CreditScriptedNameInterface[] => {
    const updatedScriptNames: CreditScriptedNameInterface[] = [...scriptNames];

    const scriptNameIndex = updatedScriptNames.findIndex(
        (scriptName: CreditScriptedNameInterface) => scriptName?.code.toLowerCase() === script.toLowerCase()
    );


    if (scriptNameIndex !== -1) {
        updatedScriptNames[scriptNameIndex][field as keyof CreditScriptedNameInterface] = value;

        if (field === "first_name" || field === "last_name" || field === "middle_names") {
            scriptNames[scriptNameIndex].name =
                scriptNames[scriptNameIndex].first_name +
                " " +
                (scriptNames[scriptNameIndex].middle_names || "") +
                " " +
                (scriptNames[scriptNameIndex].last_name || "")
        }
    } else {
        scriptNames.push({
            code: script,
            [field]: value,
            name: (field === "first_name" || field === "last_name") ? value : "",
        });
    }

    scriptNames.forEach((scriptName: CreditScriptedNameInterface) => {
        if (scriptName?.name && !scriptName?.first_name && !scriptName?.last_name) {
            scriptName.name = "";
        }
    });

    return scriptNames;
}

export default setNameScriptValue