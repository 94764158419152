import React from "react";
import ActionButton from "../ActionButton";
import "./styles/_styles.scss";

export default class ViewMessageDeleteButtons extends React.Component {
    render() {
        const {
            copy = {},
            displayMessage,
            displayDelete,
            onClickViewMessage,
            onClickDelete,
            style = {},
        } = this.props;

        const margin = `0 0.59375rem 0 0`;

        return (
            <div style={style} className="meta-view-delete-buttons">
                {displayMessage && (
                    <ActionButton
                        text={copy.viewMessage || "View Message"}
                        background="#ff8a00"
                        hoverBackground="#ffa132"
                        margin={margin}
                        onClick={onClickViewMessage}
                    />
                )}

                {displayDelete && (
                    <ActionButton
                        text={copy.delete || "Delete"}
                        background="#ff0000"
                        hoverBackground="#ff3232"
                        margin="0"
                        onClick={onClickDelete}
                    />
                )}
            </div>
        );
    }
}
