import React, { useState, Fragment } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactTooltip from "react-tooltip";
import Settings from "../Settings";
import FadeIn from "../TransitionGroup/FadeIn";
import "./styles.scss";
import _ from "lodash";

interface CopyTooltipPropsInterface {
    children: React.ReactNode;
    text: string | undefined | null;
}

const CopyTooltip: React.FC = (props: CopyTooltipPropsInterface) => {
    const { children, text = "" } = props;

    const [containerHovered, setContainerHovered] = useState<boolean>(false);
    const [linkHovered, setLinkHovered] = useState<boolean>(false);
    const [copied, setCopied] = useState<boolean>(false);

    const onMouseEnterHandler = () => {
        setContainerHovered(true);
    };

    const onMouseLeaveHandler = () => {
        setContainerHovered(false);
        setLinkHovered(false);
        setCopied(false);
    };

    return (
        <div
            className={"copy-tooltip-container"}
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
        >
            {children}

            {!_.isEmpty(text) && (
                <div className="copy-tooltip--icon-wrapper">
                    <FadeIn>
                        {containerHovered && !copied && (
                            <CopyToClipboard
                                text={text}
                                onCopy={() => setCopied(true)}
                            >
                                <div
                                    className={"tooltip-icon-state"}
                                    onMouseEnter={() => setLinkHovered(true)}
                                    onMouseLeave={() => setLinkHovered(false)}
                                >
                                    {linkHovered && (
                                        <Fragment>
                                            <img
                                                data-tip="Copy"
                                                data-for="hovered-link"
                                                src={
                                                    Settings.images
                                                        .linkSmallDarkGrey
                                                }
                                            />

                                            <ReactTooltip
                                                id="hovered-link"
                                                delayShow={100}
                                                place="top"
                                                type="dark"
                                                effect="solid"
                                                className="copy-tooltip"
                                            />
                                        </Fragment>
                                    )}

                                    {!linkHovered && (
                                        <img
                                            src={Settings.images.linkSmallGrey}
                                        />
                                    )}
                                </div>
                            </CopyToClipboard>
                        )}
                    </FadeIn>

                    <FadeIn>
                        {containerHovered && copied && (
                            <div className={"tooltip-icon-state"}>
                                <img
                                    className={"copied-icon"}
                                    data-tip="Copied"
                                    data-for="copied"
                                    src={Settings.images.checkGreen}
                                />

                                <ReactTooltip
                                    id="copied"
                                    delayShow={100}
                                    place="top"
                                    type="dark"
                                    effect="solid"
                                    className="copy-tooltip"
                                />
                            </div>
                        )}
                    </FadeIn>
                </div>
            )}
        </div>
    );
};

export default CopyTooltip;
