import _ from "lodash";
import HandleUserGroupUpdateRequest from "./HandleUserGroupUpdateRequest";

const HanldeImageDelete = (props) => {
    const { state = {} } = props;

    const { set, newRecord, recordData } = state;

    set({
        notification: {
            title: "Delete Image",
            description: "Are you sure you want to delete this image?",
            status: "alert",
            onClick: () => {
                if (!_.isEmpty(newRecord)) {
                    let updatedState = { ...newRecord };
                    updatedState.logo = null;
                    updatedState.original_filename = null;

                    set({
                        newRecord: updatedState,
                    });
                }

                if (!_.isEmpty(recordData)) {
                    let updatedState = { ...recordData };
                    updatedState.logo = null;
                    updatedState.original_filename = null;

                    HandleUserGroupUpdateRequest({
                        state,
                        userGroup: updatedState,
                    });
                }

                set({
                    notification: {},
                });
            },
        },
    });
};

export default HanldeImageDelete;
