import { Grid } from "@mui/material";
import { PropsWithChildren } from "react";

export const GridXS6 = ({ children }: PropsWithChildren) => (
    <Grid item xs={6}>
        {children}
    </Grid>
);

export const GridXS12 = ({ children }: PropsWithChildren) => (
    <Grid item xs={12}>
        {children}
    </Grid>
);

export const GridContainer = ({ children }: PropsWithChildren) => (
    <Grid container alignItems="flex-left" rowSpacing={2} columnSpacing={2}>
        {children}
    </Grid>
);
