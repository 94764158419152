import React from "react";
import Tag from "../../Tag";

function getEnv() {
    let result = "Development Environment";

    if (process && process.env) {
        if (process.env.APP_ENV == "QA") {
            result = "Staging Environment";
        }

        if (process.env.APP_ENV == "Live") {
            result = "Live Environment";
        }
    }

    return result;
}

const HeaderTags = (props) => {
    const { system_type, system_name } = props;

    const fontSize = "0.78125rem";
    const marginBottom = "0.96875rem";
    const marginRight = "0.4375rem";
    const environment = getEnv();

    return (
        <div
            style={{
                marginBottom,
            }}
        >
            <Tag
                text={system_type}
                background="#626262"
                styles={{
                    marginRight,
                    fontSize,
                }}
            />

            <Tag
                text={system_name}
                background="#888888"
                styles={{
                    marginRight,
                    fontSize,
                }}
            />

            <Tag
                text={environment}
                background={
                    environment == "Development Environment"
                        ? "#e58c00"
                        : "#1c654d"
                }
                styles={{
                    fontSize,
                }}
            />
        </div>
    );
};

export default HeaderTags;
