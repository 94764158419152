import { ResponsePaginationObject } from "../types/interfaces.shared";

type ServiceKey =
    | "TaskManager"
    | "RecordService"
    | "UserService"
    | "WebSocketService";

const getBaseUrl = (service: ServiceKey): string => {
    let url: string;
    switch (service) {
        case "RecordService":
            url = process.env.RECORD_SERVICE_URL;
            break;
        case "UserService":
            url = process.env.USER_SERVICE_URL;
            break;
        case "WebSocketService":
            url = process.env.BACKGROUND_PROCESSING_SERVICE_URL;
            break;
        default:
            url = process.env.TASK_MANAGER_SERVICE_URL;
    }

    return url;
};

export const TMBaseUrl = getBaseUrl("TaskManager");

export const RSBaseUrl = getBaseUrl("RecordService");

export const USBaseUrl = getBaseUrl("UserService");

export const WSBaseUrl = getBaseUrl("WebSocketService");

export const TMEndpoints = {
    TICKETS: "/tickets",
    TICKET_BY_ID: "/tickets/<%=ticketId%>",
    TICKETS_NEW: "/tickets/new",
    TICKET_COMMENTS: "/ticket/<%=ticketId%>/comments",
    TICKET_HISTORY: "/history/<%=ticketId%>/ticket",
    TICKET_ATTACHMENTS: "/tickets/<%=ticketId%>/attachments",
    TICKET_DOWNLOAD_ATTACHMENTS: "/tickets/<%=ticketId%>/attachments/download",
    TICKET_APPROVE: "/api/v2/tickets/<%=ticketId%>/approve",
};

export const RSEndpoints = {
    SEARCH: "/search",
    SEARCH_BY_META_ID: "/record",
    SEARCH_BY_IDENTIFIER: "/record/identifier",
};

export const FabricEndpoints = {
    COLLECTIONS_BY_ID:
        "/collections/<%=collectionId%>?selected=<%=collectionId%>",
    DISCOVERY: "/discovery",
};

export const USEndpoints = {
    USERS: "/users",
};

export const WSParams = {
    TOKEN: "/?token=",
};

export const getUrl = (url: string, endpoint: string) => `${url}${endpoint}`;

export const API_ERROR_UNEXPECTED = "An unexpected error has occured";

export const API_WARNING_CANCELLED =
    "Your request is taking a bit longer than usual. Please wait...";

export const API_ERROR_PAYLOAD_TOO_LARGE =
    "The file size is too large. Please try again with a smaller file.";

export const API_ERROR_UNAUTHORIZED =
    "You are not authorized to perform this action.";

export const API_ERROR_FORBIDDEN =
    "You are not authorized to perform this action.";

export const API_ERROR_NOT_FOUND = "The requested resource was not found.";

export const API_ERROR_BAD_REQUEST = "The request was invalid.";

export const API_VALIDATION_ERROR = "Validation error";

export type TaskManagerApiReturnType<T> = {
    data: T;
    pagination?: ResponsePaginationObject;
    message?: string;
    isError?: boolean;
};

export type ApiResponse<T> = {
    data: T[];
    pagination?: ResponsePaginationObject;
};

export type NoDataApiResponse = {
    message?: string;
};

export type RecordsProcessingResponse = {
    processing: boolean;
};

export type CustomDownloadResponse = {
    data: BlobPart;
    mimeType: string;
    name: string;
};
