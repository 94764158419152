import React from "react";
import SchedulingContextProvider from "./state/context";
import Boilerplate from "../RecordBody/views/Boilerplate";
import Scheduling from "./src/main";

type SchedulingPropsType = {
    disabled: boolean;
    platformScheduling: Array<object>;
    schedulingConditions: any;
    handleFormChange: Function;
    handleConditionsFormChange: Function;
    category: string;
};

const SchedulingMain: React.FC<SchedulingPropsType> = (props) => {
    return (
        <SchedulingContextProvider {...props} recordCategory={props.category}>
            <Boilerplate
                title="Platform Scheduling"
                className={"meta-record-platform-scheduling"}
                displayTitle={false}
            >
                <Scheduling />
            </Boilerplate>
        </SchedulingContextProvider>
    );
};

export default SchedulingMain;
