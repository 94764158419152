import { OptionInterface } from "../../interfaces"
import { 
    defaultSectionOptions,
    accessTypeCreate,
    accessTypeDelete,
    accessTypeUpdate
} from "./index"

import Origination from "../../../../components/RecordBody/src/sections/Origination";
import OriginationCustomFields from "../../../../components/RecordBody/src/sections/OriginationCustomFields";
import Descriptors from "../../../../components/RecordBody/src/sections/Descriptors";
import Classification from "../../../../components/RecordBody/src/sections/Classification";
import Certifications from "../../../../components/RecordBody/src/sections/Certifications";
import Links from "../../../../components/RecordBody/src/sections/Links";
import Properties from "../../../../components/RecordBody/src/sections/Properties";
import SeasonNumbering from "../../../../components/RecordBody/src/sections/SeasonNumbering";
import AlternativeNumbering from "../../../../components/RecordBody/src/sections/AlternativeNumbering";
import ShortNumbering from "../../../../components/RecordBody/src/sections/ShortNumbering";
import EpisodeNumbering from "../../../../components/RecordBody/src/sections/EpisodeNumbering";
import StorylineNumbering from "../../../../components/RecordBody/src/sections/StorylineNumbering";
import ContentAdvisories from "../../../../components/RecordBody/src/sections/ContentAdvisories";
import Ids from "../../../../components/RecordBody/src/sections/Ids";
import RegionalProperties from "../../../../components/RecordBody/src/sections/RegionalProperties";
import Titles from "../../../../components/RecordBody/src/sections/Titles";
import Akas from "../../../../components/RecordBody/src/sections/Akas";
import Descriptions from "../../../../components/RecordBody/src/sections/Descriptions";
import AdditionalInformation from "../../../../components/RecordBody/src/sections/AdditionalInformation";
import RelatedAssets from "../../../../components/RecordBody/src/sections/RelatedAssets";
import EsbRegionalSupplyChain from "../../../../components/RecordBody/src/sections/EsbRegionalSupplyChain";
import DigitalReadinessStatus from "../../../../components/RecordBody/src/sections/DigitalReadinessStatus";
import Ratings from "../../../../components/RecordBody/src/sections/Ratings";
import BoxOfficeAndPerformance from "../../../../components/RecordBody/src/sections/BoxOfficeAndPerformance";
import ReleaseDates from "../../../../components/RecordBody/src/sections/ReleaseDates";
import DailySeriesLandscape from "../../../../components/RecordBody/src/sections/DailySeriesLandscape";
import VideoLandscape from "../../../../components/RecordBody/src/sections/VideoLandscape";
import Images from "../../../../components/RecordBody/src/sections/Images";
import Credits from "../../../../components/RecordBody/src/sections/Credits";
import Awards from "../../../../components/RecordBody/src/sections/Awards";
import Rights from "../../../../components/RecordBody/src/sections/Rights";
import RelatedTitles from "../../../../components/RecordBody/src/sections/RelatedTitles";
import Games from "../../../../components/RecordBody/src/sections/Games";
import Videos from "../../../../components/RecordBody/src/sections/Videos";
import Channels from "../../../../components/RecordBody/src/sections/Channels";
import Shows from "../../../../components/RecordBody/src/sections/Shows";
import Characters from "../../../../components/RecordBody/src/sections/Characters";
import SeasonRunningOrders from "../../../../components/RecordBody/src/sections/SeasonRunningOrders";
import AWSRekognition from "../../../../components/RecordBody/src/sections/AWSRekognition";
import CollectionTitles from "../../../../components/RecordBody/src/sections/CollectionTitles";
import ProgrammingDates from "../../../../components/RecordBody/src/sections/ProgrammingDates";
import AiringOrder from "../../../../components/RecordBody/src/sections/AiringOrder";
import Financials from "../../../../components/RecordBody/src/sections/Financials";
import Gracenote from "../../../../components/RecordBody/src/sections/Gracenote";
import Delivery from "../../../../components/RecordBody/src/sections/Delivery";
import Scheduling from "../../../../components/RecordBody/src/sections/Scheduling";
import HomeScreenLineupManager from "../../../../components/RecordBody/src/sections/HomeScreenLineupManager";
import CatalogAvailability from "../../../../components/RecordBody/src/sections/CatalogAvailability";
import Cycles from "../../../../components/RecordBody/src/sections/Cycles";
import LocaleSelect from "../../../../components/RecordBody/src/sections/LocaleSelect";
import AgeGuidance from "../../../../components/RecordBody/src/sections/AgeGuidance";
import Ancillaries from "../../../../components/RecordBody/src/sections/Ancillaries";

export const discoveryOption: OptionInterface = {
    name: "discovery",
    path: "record",
    children: [],
};

export const originationSection: OptionInterface = {
    name: "origination",
    path: "tab.metadata.section.origination",
    parent: "tab.metadata",
    Component: Origination,
    ...defaultSectionOptions,
};

export const originationCustomFieldsSection: OptionInterface = {
    name: "Custom Fields",
    path: "tab.metadata.section.originationCustomFields",
    parent: "tab.metadata",
    Component: OriginationCustomFields,
    ...defaultSectionOptions,
};

export const classificationSection: OptionInterface = {
    name: "classification",
    path: "tab.metadata.section.classification",
    parent: "tab.metadata",
    Component: Classification,
    ...defaultSectionOptions,
};

export const descriptorsSection: OptionInterface = {
    name: "descriptors",
    path: "tab.metadata.section.descriptors",
    parent: "tab.metadata",
    Component: Descriptors,
    ...defaultSectionOptions,
};

export const certificationsSection: OptionInterface = {
    name: "certifications",
    path: "tab.metadata.section.certifications",
    parent: "tab.metadata",
    Component: Certifications,
    ...defaultSectionOptions,
};

export const linksSection: OptionInterface = {
    name: "links",
    path: "tab.metadata.section.links",
    parent: "tab.metadata",
    Component: Links,
    ...defaultSectionOptions,
};

export const propertiesSection: OptionInterface = {
    name: "properties",
    path: "tab.metadata.section.properties",
    parent: "tab.metadata",
    Component: Properties,
    ...defaultSectionOptions,
};

export const alternativeNumberingSection: OptionInterface = {
    value: "alternativeNumbering",
    name: "Alternative Numbering",
    path: "tab.metadata.section.alternativeNumbering",
    parent: "tab.metadata",
    Component: AlternativeNumbering,
    ...defaultSectionOptions,
}

export const seasonNumberingSection: OptionInterface = {
    value: "seasonNumbering",
    name: "Season Numbering",
    path: "tab.metadata.section.seasonNumbering",
    parent: "tab.metadata",
    Component: SeasonNumbering,
    ...defaultSectionOptions,
};

export const shortNumberingSection: OptionInterface = {
    value: "shortNumbering",
    name: "Short Numbering",
    path: "tab.metadata.section.shortNumbering",
    parent: "tab.metadata",
    Component: ShortNumbering,
    ...defaultSectionOptions,
};

export const episodeNumberingSection: OptionInterface = {
    value: "episodeNumbering",
    name: "Episode Numbering",
    path: "tab.metadata.section.episodeNumbering",
    parent: "tab.metadata",
    Component: EpisodeNumbering,
    ...defaultSectionOptions,
};

export const storylineNumberingSection: OptionInterface = {
    value: "storylineNumbering",
    name: "Storyline Numbering",
    path: "tab.metadata.section.storylineNumbering",
    parent: "tab.metadata",
    Component: StorylineNumbering,
    ...defaultSectionOptions,
};

export const contentAdvisoriesSection: OptionInterface = {
    value: "contentAdvisories",
    name: "Content Advisories",
    path: "tab.metadata.section.content_advisories",
    parent: "tab.metadata",
    Component: ContentAdvisories,
    ...defaultSectionOptions,
};

export const localeSelectSection: OptionInterface = {
    value: "localeSelect",
    path: "tab.regional",
    Component: LocaleSelect,
    userHidden: true,
    ...defaultSectionOptions,
};

export const regionalPropertiesSection: OptionInterface = {
    value: "regionalProperties",
    name: "Regional Properties",
    path: "tab.regional.section.regionalProperties",
    parent: "tab.regional",
    Component: RegionalProperties,
    ...defaultSectionOptions,
};

export const titlesSection: OptionInterface = {
    name: "titles",
    path: "tab.regional.section.titles",
    parent: "tab.regional",
    Component: Titles,
    ...defaultSectionOptions,
};

export const akasSection: OptionInterface = {
    name: "akas",
    path: "tab.regional.section.akas",
    parent: "tab.regional",
    Component: Akas,
    ...defaultSectionOptions,
};

export const descriptionsSection: OptionInterface = {
    name: "descriptions",
    path: "tab.regional.section.descriptions",
    parent: "tab.regional",
    Component: Descriptions,
    ...defaultSectionOptions,
};

export const additionalInformationSection: OptionInterface = {
    value: "additionalInformation",
    name: "Additional Information",
    path: "tab.regional.section.additionalInformation",
    parent: "tab.regional",
    Component: AdditionalInformation,
    ...defaultSectionOptions,
};

export const relatedAssetsSection: OptionInterface = {
    value: "relatedAssets",
    name: "Related Assets",
    path: "tab.assets.section.relatedAssets",
    parent: "tab.assets",
    Component: RelatedAssets,
};

export const regionalSupplyChainSection: OptionInterface = {
    value: "regionalSupplyChain",
    name: "Regional Supply Chain",
    path: "tab.assets.section.regionalSupplyChain",
    parent: "tab.assets",
    Component: EsbRegionalSupplyChain,
    ...defaultSectionOptions,
};

export const digitalReadinessStatusSection: OptionInterface = {
    value: "digitalReadinessStatus",
    name: "Digital Readiness Status",
    path: "tab.assets.section.digitalReadinessStatus",
    parent: "tab.assets",
    Component: DigitalReadinessStatus,
    ...defaultSectionOptions,
};

export const ratingsSection: OptionInterface = {
    name: "ratings",
    path: "tab.measurements.section.ratings",
    parent: "tab.measurements",
    Component: Ratings,
    ...defaultSectionOptions,
};

export const boxOfficeAndPerformanceSection: OptionInterface = {
    value: "boxOfficeAndPerformance",
    name: "Box Office And Performance",
    path: "tab.measurements.section.boxOfficeAndPerformance",
    parent: "tab.measurements",
    Component: BoxOfficeAndPerformance,
    ...defaultSectionOptions,
};

export const releaseDatesSection: OptionInterface = {
    value: "releaseDates",
    name: "Release Dates",
    path: "tab.measurements.section.releaseDates",
    parent: "tab.measurements",
    Component: ReleaseDates,
    disabledAccessTypes: [accessTypeUpdate]
};

export const dailySeriesLandscapeSection: OptionInterface = {
    name: "Daily Series Landscape",
    value: "dailySeriesLandscape",
    path: "tab.canvasAI.section.dailySeriesLandscape",
    parent: "tab.canvasAI",
    Component: DailySeriesLandscape,
    ...defaultSectionOptions,
};

export const videoLandscape: OptionInterface = {
    value: "videoLandscape",
    name: "Video Landscape",
    path: "tab.canvasAI.section.videoLandscape",
    parent: "tab.canvasAI",
    Component: VideoLandscape,
    ...defaultSectionOptions,
};

export const metadataSections: Array<OptionInterface> = [
    originationSection,
    originationCustomFieldsSection,
    classificationSection,
    descriptorsSection,
    certificationsSection,
    linksSection,
    propertiesSection,
    seasonNumberingSection,
    shortNumberingSection,
    episodeNumberingSection,
    storylineNumberingSection,
    contentAdvisoriesSection,
];

export const regionalSections: Array<OptionInterface> = [
    regionalPropertiesSection,
    titlesSection,
    akasSection,
    descriptionsSection,
    additionalInformationSection,
];

export const assetsSections: Array<OptionInterface> = [
    relatedAssetsSection,
    regionalSupplyChainSection,
    digitalReadinessStatusSection,
];

export const measurementsSections: Array<OptionInterface> = [
    ratingsSection,
    boxOfficeAndPerformanceSection,
    releaseDatesSection,
];

export const canvasAISections: Array<OptionInterface> = [
    dailySeriesLandscapeSection,
    videoLandscape,
];

export const metadataOption: OptionInterface = {
    name: "metadata",
    path: "tab.metadata",
    children: metadataSections,
    ...defaultSectionOptions,
};

export const creditsOption: OptionInterface = {
    name: "credits",
    value: "credits",
    text: "Credits",
    path: "tab.credits",
    children: [],
    Component: Credits
};

export const ratingsOption: OptionInterface = {
    name: "ratings",
    text: "Ratings",
    path: "tab.ratings",
    children: [],
    Component: AgeGuidance,
    ...defaultSectionOptions,
};

export const awardsOptions: OptionInterface = {
    name: "awards",
    path: "tab.awards",
    children: [],
    Component: Awards,
    ...defaultSectionOptions,
};

export const regionalOptions: OptionInterface = {
    name: "regional",
    path: "tab.regional",
    children: regionalSections,
    hiddenChildren: [localeSelectSection],
    ...defaultSectionOptions,
};

export const collectionTitlesSection: OptionInterface = {
    name: "titles",
    text: "Collection Records",
    value: "titles",
    path: "tab.titles",
    children: [],
    Component: CollectionTitles,
    ...defaultSectionOptions,
};

export const idsOption: OptionInterface = {
    name: "IDs",
    value: "ids",
    text: "IDs",
    path: "tab.ids",
    children: [],
    Component: Ids,
    ...defaultSectionOptions,
};

export const gamesOption: OptionInterface = {
    name: "games",
    path: "tab.games",
    children: [],
    Component: Games,
    ...defaultSectionOptions,
};

export const measurementsOption: OptionInterface = {
    name: "measurements",
    path: "tab.measurements",
    text: "Measurement",
    children: measurementsSections,
    ...defaultSectionOptions,
};

export const financialsOption: OptionInterface = {
    name: "financials",
    path: "tab.financials",
    children: [],
    Component: Financials,
    ...defaultSectionOptions,
};

export const linkageOption: OptionInterface = {
    name: "linkage",
    path: "tab.linkage",
    children: [],
    Component: RelatedTitles,
    ...defaultSectionOptions,
};

export const imagesOption: OptionInterface = {
    name: "images",
    path: "tab.images",
    children: [],
    Component: Images
};

export const rightsOption: OptionInterface = {
    name: "rights",
    path: "tab.rights",
    children: [],
    Component: Rights,
    ...defaultSectionOptions,
};

export const videosOption: OptionInterface = {
    name: "videos",
    path: "tab.videos",
    children: [],
    Component: Videos,
    ...defaultSectionOptions,
};

export const showsOption: OptionInterface = {
    name: "shows",
    path: "tab.shows",
    children: [],
    Component: Shows,
    ...defaultSectionOptions,
};

export const charactersOption: OptionInterface = {
    name: "characters",
    path: "tab.characters",
    children: [],
    Component: Characters,
    ...defaultSectionOptions,
};

export const channelsOption: OptionInterface = {
    name: "channels",
    path: "tab.channels",
    children: [],
    Component: Channels,
    ...defaultSectionOptions,
};

export const assetsOption: OptionInterface = {
    name: "assets",
    path: "tab.assets",
    children: assetsSections,
};

export const programmingOption: OptionInterface = {
    name: "programming",
    path: "tab.programming",
    children: [],
    Component: ProgrammingDates,
    ...defaultSectionOptions,
};

export const airingOrderOption: OptionInterface = {
    value: "airingOrder",
    name: "Airing Order",
    text: "Airing Order",
    path: "tab.airingOrder",
    children: [],
    Component: AiringOrder,
    ...defaultSectionOptions,
};

export const runningOrderOption: OptionInterface = {
    value: "runningOrder",
    name: "Running Order",
    text: "Running Order",
    path: "tab.runningOrder",
    children: [],
    Component: SeasonRunningOrders,
    ...defaultSectionOptions,
};

export const schedulesOption: OptionInterface = {
    name: "schedules",
    text: "Scheduling",
    path: "tab.schedules",
    children: [],
    Component: Scheduling,
    ...defaultSectionOptions,
};

export const gracenoteOption: OptionInterface = {
    name: "gracenote",
    path: "tab.gracenote",
    children: [],
    Component: Gracenote,
    ...defaultSectionOptions,
};

export const deliveryOption: OptionInterface = {
    name: "delivery",
    path: "tab.delivery",
    children: [],
    Component: Delivery,
    ...defaultSectionOptions,
};

export const rekognitionOption: OptionInterface = {
    name: "rekognition",
    path: "tab.rekognition",
    children: [],
    Component: AWSRekognition,
    ...defaultSectionOptions,
};

export const lineupsOption: OptionInterface = {
    name: "lineups",
    path: "tab.lineups",
    children: [],
    Component: HomeScreenLineupManager,
    ...defaultSectionOptions,
};

export const catalogAvailabilityOption: OptionInterface = {
    name: "Catalog Availability",
    value: "catalogAvailability",
    path: "tab.catalogAvailability",
    children: [],
    Component: CatalogAvailability,
    ...defaultSectionOptions,
};

export const cyclesOption: OptionInterface = {
    name: "financials",
    path: "tab.financials",
    children: [],
    Component: Cycles,
    ...defaultSectionOptions,
};

export const canvasAIOption: OptionInterface = {
    name: "canvasAI",
    text: "Canvas.ai",
    path: "tab.canvasAI",
    children: canvasAISections,
    ...defaultSectionOptions,
};

export const ancillariesOption: OptionInterface = {
    name: "Ancillaries",
    value: "ancillaries",
    path: "tab.ancillaries",
    Component: Ancillaries,
    disabledAccessTypes: [
        accessTypeCreate,
        accessTypeDelete,
        accessTypeUpdate
    ],
};