import deepcopy from 'deepcopy'
import _ from "lodash"
import ProvideRanking from './src/ProvideRanking'
import UpdateCreditAction from './src/UpdateCreditAction'
import { addLocalIdToCredit } from '../../../../models/Record/credits'

export const SET_CREDITS = 'set_credits'
export const SET_STATE = 'set_state'
export const SET_SCRIPT = 'set_script'
export const SET_NOTIFICATION = 'set_notification'

export const SET_ACTIVE_CREDIT = 'set_active_credit'
export const SET_NEW_CREDIT = 'set_new_credit'
export const UPDATE_CREDIT = 'update_credit'

export const SET_ACTIVE_CONTRIBUTOR = 'set_active_contributor'
export const UPDATE_CONTRIBUTOR = 'update_contributor'

export const setCredits = credits => {
    return {
        type: SET_CREDITS,
        state: { credits }
    }
}

export const setState = data => {
    return {
        type: SET_STATE,
        state: {...data}
    }
}

export const setScript = script => {
    return {
        type: SET_SCRIPT,
        state: { script }
    } 
}

export const setNotification = notification => {
    return {
        type: SET_NOTIFICATION,
        state: { notification }
    }
}

export const setActiveCredit = activeCredit => {
    return {
        type: SET_ACTIVE_CREDIT,
        state: { activeCredit }
    }
}

export const setNewCredit = () => {
    return {
        type: SET_NEW_CREDIT,
        state: {}
    }
}

export const updateCredit = (role, ranking, local_id = '') => {
    return {
        type: UPDATE_CREDIT,
        state: { role, ranking, local_id }
    }
}

export const setActiveContributor = activeContributor => {
    return {
        type: SET_ACTIVE_CONTRIBUTOR,
        state: { activeContributor }
    }
}

export const updateContributor = () => {
    return {
        type: UPDATE_CONTRIBUTOR,
        state: {}
    }
}

export default (state, action) => {
    const {
        handleFormChange = () => {}
    } = state

    let resultState = {}
    const defaultLanguageCode = state.script;

    switch (action.type) {
        case SET_STATE:
        case SET_SCRIPT:
        case SET_NOTIFICATION:
        case SET_ACTIVE_CONTRIBUTOR:
            resultState =  Object.assign({}, state, action.state)
            break;

        case SET_ACTIVE_CREDIT:
            resultState = Object.assign({}, state, action.state)
            if (resultState.notification) {
                if (resultState.activeCredit.role && resultState.activeCredit.name) {
                    resultState.notification.disabled = false
                    resultState.notification.requirementText = ''
                }
            }
            break;

        case SET_CREDITS:
            resultState =  Object.assign({}, state, action.state)
            handleFormChange({ credits: resultState.credits })
            break;

        case SET_NEW_CREDIT:
            let credits = deepcopy(state.credits)
            credits.push(state.activeCredit)
            resultState = Object.assign({}, state, { credits: ProvideRanking(credits) })
            handleFormChange({ credits: resultState.credits })
            break;

        case UPDATE_CREDIT:
            resultState = Object.assign({}, state, { credits: UpdateCreditAction(state, action) })
            handleFormChange({ credits: resultState.credits })
            break;

        case UPDATE_CONTRIBUTOR:
            let credit = deepcopy(state.activeCredit)
            let contributor = state.activeContributor
            if (credit.value) delete credit.value

            if (!credit.local_id) {
                credit = addLocalIdToCredit(credit)
            }

            const hasDefaultLanguageInScriptNames = credit.script_names?.some(({ code }) => code === defaultLanguageCode);
            const hasDefaultLanguageInScriptCharacterNames = credit.script_character_names?.some(({ code }) => code === defaultLanguageCode);

            if (contributor.character_name && !hasDefaultLanguageInScriptCharacterNames) {
                credit.script_character_names = [
                    ...(credit.script_character_names || []),
                    { code: defaultLanguageCode, character_name: contributor.character_name },
                ];
            }

            if (!hasDefaultLanguageInScriptNames) {
                credit.script_names = [
                    ...(credit.script_names || []),
                    { 
                        code: defaultLanguageCode, 
                        name: contributor.name,
                        first_name: contributor.first_name,
                        middle_names: contributor.middle_names || null,
                        last_name: contributor.last_name,
                        alternative_name: contributor.alternative_name || null,
                        second_alternative_name: contributor.second_alternative_name || null,
                    },  
                ];
            }

            resultState = Object.assign({}, state, { 
                activeCredit: Object.assign({}, credit, state.activeContributor) 
            });
            
            break;
    }
    
    return resultState
}