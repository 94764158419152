import deepcopy from "deepcopy";
import { ValidationResult } from "./interfaces/Validation";
import DisabledRuleActive from "./rules/disabled";

export default function ValidatePrimaryLanguage(
    result: ValidationResult,
    options: any,
): ValidationResult {
    let response: ValidationResult = deepcopy(result);

    const { data = {}, status, clientDataStructure = {} } = options;

    const primareLanguageValidation =
        clientDataStructure?.fieldValidation?.origination?.locale;

    const {
        origination = {},
        category = false,
        status: recordStatus = ''
    } = data;

    const { type } = origination;

    if (!primareLanguageValidation) {
        return response;
    }

    if (
        DisabledRuleActive({
            recordStatus,
            category,
            type,
            dataStructure: clientDataStructure,
            fieldPath: "origination.locale",
        })
    ) {
        return response;
    }

    if ((!origination || !origination.locale) && primareLanguageValidation) {
        response.result = false;
        response.notification = {
            title: "Please check primary language",
            description: "Primary Language is missing",
            status,
        };

        response.validationKeys = result.validationKeys.concat(["locale"]);
    }

    return response;
}
