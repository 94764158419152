const clientManagedLanguages = {
    English: {
        text: "English",
        name: "English",
        meta_code: "ENG",
        language_name_script_example: "Latin",
    },
    Spanish: {
        text: "Spanish",
        name: "Spanish",
        meta_code: "SPA",
    },
    German: {
        text: "German",
        name: "German",
        meta_code: "DUE",
    },
    French: {
        text: "French",
        name: "French",
        meta_code: "FRA",
    },
};

const { English, Spanish, German, French } = clientManagedLanguages;

const options = {
    record_types: [
        { category: "Feature", value: "live_action", type: "Live Action" },
        { category: "Feature", value: "documentary", type: "Documentary" },
        { category: "Show", value: "animation", type: "Animation" },
        { category: "Show", value: "reality", type: "Reality" },
    ],
    record_statuses: [
        {
            text: "Draft",
            value: "draft",
            background: "#be8807",
            default_value: 1,
        },
        {
            text: "On Hold",
            value: "on_hold",
            background: "#d81e1f",
            default_value: 0,
        },
        {
            text: "Disabled",
            value: "disabled",
            background: "#d81e1f",
            default_value: 0,
            deleted_at: "22-01-01",
        },
        {
            text: "Enabled",
            value: "enabled",
            background: "#d81e1f",
            default_value: 0,
        },
    ],
    categories: [{ value: "Feature" }, { value: "Show" }],
    countries: [
        { text: "United Kingdom", value: "GBR" },
        { text: "USA", value: "USA" },
        { text: "FRA", value: "France" },
        { text: "Estonia", value: "EST" },
    ],
    client_managed_territories: [
        { text: "United Kingdom territory", value: "GBR" },
        { text: "USA territory", value: "USA" },
        { text: "FRA territory", value: "France" },
        { text: "Estonia territory", value: "EST" },
    ],
    spoken_languages: [
        { text: "English", value: "ENG" },
        { text: "French", value: "FRA" },
        { text: "Estonian", value: "EST" },
    ],
    producers: [
        { text: "Companies", value: 1 },
        { text: "Warner", value: 2 },
        { text: "Time Warner", value: 3 },
    ],
    genres: [
        { text: "Action", value: 1 },
        { text: "Romance", value: 2 },
        { text: "Comedy", value: 110 },
        { text: "Crime", value: 112 },
    ],
    sub_genres: [
        { text: "Slapstick", value: 1 },
        { text: "Soviet Union", value: 2 },
        { text: "Romantic", value: 172 },
        { text: "Families & Relationships", value: 340 },
        { text: "Dark Humour & Black Comedies", value: 341 },
    ],
    moods: [
        { text: "Happy", value: 1 },
        { text: "Sad", value: 2 },
    ],
    certifications: {
        types: [
            {
                text: "Official Body",
                value: "official body",
            },
            {
                text: "External Resource",
                value: "external resource",
            },
            {
                text: "Internal",
                value: "internal",
            },
        ],
        environments: [
            {
                value: 72,
                parent: 454,
            },
            {
                value: 70,
                parent: 400,
            },
        ],
        external_ratings: [],
        internal_ratings: [],
        official_ratings: [
            {
                value: 1102,
                parent: 454,
            },
            {
                value: 900,
                parent: 400,
            },
        ],
        record_types: [
            {
                value: 66,
                parent: 454,
            },
            {
                value: 60,
                parent: 400,
            },
        ],
        regions: [
            {
                value: 390,
            },
            {
                value: 300,
            },
        ],
        systems: [
            {
                value: 454,
                parent: 390,
            },
            {
                value: 400,
                parent: 300,
            },
        ],
        region_systems: {
            "United States": {
                Canada: {
                    ratings: ["18+", "21+"],
                },
            },
        },
    },
    contributor_roles: [
        {
            value: 1,
            text: "Actor",
        },
        {
            value: 2,
            text: "Director",
        },
    ],
    regional_owners: ["Owner1"],
    formats: ["Format1"],
    control_mechanisms: ["Mechanism1"],
    loading_menu_audio: ["Audio1"],
    orientations: ["Orientation1"],
    devices: ["Device1"],
    asset_management: [
        {
            system_type: "Asset Management System",
            system_name: {
                text: "Sony Media Backbone",
                value: "Sony Media Backbone",
            },
            asset_types: {
                Masters: [
                    {
                        text: "Title, Original & Normalised",
                        value: "Title, Original & Normalised",
                        code: "ORIG",
                    },
                ],
                "General Entertainment": [
                    {
                        text: "Middle East: Pre-Watershed",
                        value: "Middle East: Pre-Watershed",
                        code: "MPRE",
                    },
                    {
                        text: "GE Complied",
                        value: "GE Complied",
                        code: "NGCP",
                        popular: true,
                    },
                ],
                Kids: [
                    {
                        text: "Kids CEE",
                        value: "Kids CEE",
                        code: "CECP",
                        popular: true,
                    },
                ],
                Other: [
                    {
                        text: "CNN Compliance",
                        value: "CNN Compliance",
                        code: "CNNC",
                        popular: true,
                    },
                    {
                        text: "Legacy",
                        value: "Legacy",
                        code: "LAGA",
                    },
                ],
            },
        },
        {
            system_type: "Asset Management System",
            system_name: {
                text: "Metadata Only",
                value: "Metadata Only",
            },
            asset_types: {
                "General Entertainment": [
                    {
                        code: "LATM",
                        popular: true,
                        text: "HBO Max LatAm",
                        value: "HBO Max LatAm",
                    },
                ],
            },
        },
    ],
    tmc_languages: [
        {
            name: "French",
            code: "FRA",
            tmc_code: "FRA",
        },
        {
            name: "Spanish",
            code: "SPA",
        },
        {
            name: "German",
            code: "DEU",
        },
    ],
    all_written_languages: {
        ["EMEA"]: [
            {
                text: "French",
                value: "FRA",
            },
            {
                text: "Spanish",
                value: "SPA",
            },
            {
                text: "German",
                value: "DEU",
            },
            {
                text: "English",
                value: "ENG",
            },
        ],
    },
    top_written_languages: {
        ["EMEA"]: [
            {
                text: "French",
                value: "FRA",
            },
            {
                text: "Spanish",
                value: "SPA",
            },
            {
                text: "German",
                value: "DEU",
            },
            {
                text: "English",
                value: "ENG",
            },
        ],
    },
    localisations: {
        casting: ["Already Cast", "Not Required"],
        compliances: ["ORIG", "MPRE"],
        content_regions: ["Africa", "France"],
        content_subtypes: ["Shared", "Local"],
        content_types: ["GE", "Kids"],
        languages: [
            {
                text: "Afrikaans",
                value: "AFR",
            },
            {
                text: "Arabic",
                value: "ARB",
            },
            {
                text: "French",
                value: "FRE",
            },
        ],
        screeners: ["All", "Other"],
        scripts: ["All", "Other"],
        status: ["Unopened", "Submitted"],
        studios: ["BTI Dubberman", "Chinkel SA"],
        types: [
            { text: "Subtitles", value: "Subbing" },
            { text: "Dubbing", value: "Dubbing" },
            { text: "Voiceover", value: "Voice Over" },
            { text: "Accessibility", value: "Accessibility" },
            { text: "Translations", value: "Translations" },
        ],
        graphics: [
            { text: "Credits in Vision", value: "original_credits_in_vision" },
            {
                text: "Graphics in Vision",
                value: "all_screen_graphics_in_vision",
            },
            {
                text: "Episode Title in Vision",
                value: "episode_title_in_vision",
            },
            { text: "End Dub Card in Vision", value: "end_dub_card_in_vision" },
            { text: "Graphics Voiced", value: "screen_graphics_voiced" },
            { text: "Series Title Voiced", value: "series_title_voiced" },
            { text: "Episode Title Voiced", value: "episode_title_voiced" },
            { text: "Credits Voiced", value: "original_credits_voiced" },
        ],
        songs: [
            { text: "Songs by Case", value: "songs_by_case" },
            { text: "Opening Songs Dubbed", value: "opening_songs_dubbed" },
            { text: "All Songs Dubbed", value: "all_songs_dubbed" },
        ],
        accessibilities: [
            { text: "Audio Described", value: "audio_description" },
            { text: "Sign Language", value: "sign_language" },
            { text: "SDH: Hard of Hearing", value: "hard_of_hearing" },
        ],
    },
    release_dates_types: ["Theatrical", "First Aired", "Physical", "Digital"],
    rights: {
        platforms: ["Linear", "Non-Linear"],
        versions: ["Theatrical", "Saudi Edit", "Airline Edit"],
    },
    rights_platforms: [
        {
            platform: "Linear + Catch Up",
            type: "General",
        },
        {
            platform: "Linear",
            type: "General",
        },
        {
            platform: "D2C SVOD",
            type: "General",
        },
    ],
    client_managed_languages: {
        written_languages: {
            awards: [English],
            subtitles: [English, Spanish, German, French],
        },
        spoken_languages: {
            dubbing_tracks: [English, Spanish, German, French],
        },
    },
    client_managed_countries: [
        {
            iso_code: "USA",
            name: "United States of America",
        },
        {
            iso_code: "UKR",
            name: "Ukraine",
        },
    ],
    client_managed_asset_compliances: [
        {
            code: "LWTA",
            group: "General Entertainment",
            id: 60,
            name: "LWTA",
            order: 5,
            popular: true,
            systems: ["Metadata Only"],
        },
    ],
    currency: [
        {
            name: "AUD",
            description: "Australian Dollar",
            symbol: "A$",
        },
        {
            name: "GBP",
            description: "British Pound",
            symbol: "£",
        },
    ],
};

export default options;
