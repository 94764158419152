import { CreditInterface, DataTablePayload } from "../../credits.interface";

export default function deleteCredits(
    selectedCredits: DataTablePayload[],
    credits: CreditInterface[],
): CreditInterface[] {
    return credits.map(credit => {
        const isSelected = selectedCredits.findIndex(
            (creditData) =>
                creditData.role === credit.role_name &&
                creditData.localID === credit.local_id
        )

        if (isSelected === -1) {
            return credit;
        }

        credit.deleted = true
        credit.ranking = false

        const updatedCreditLists = [...credit.credit_lists];
        updatedCreditLists?.map(creditList => {
            creditList.deleted = true
            creditList.ranking = -1
        })

        return {
            ...credit,
            credit_lists: updatedCreditLists
        }
    })
}