import React, { useEffect } from "react";
import AutocompleteInput from "../../components/AutocompleteInput";
import "./_styles.scss";
import { useStore as globalStore } from "../../components/Global/store/store";
import {
    GlobalTaxonomyInterface,
    TaxonomyInterface,
} from "../../models/Record/taxonomy";
import { useStores } from "./store/rootStore";
import { observer } from "mobx-react";
import Settings from "../../components/Settings";
import KeywordLabel from "../../components/KeywordLabel";
import NoneEntered from "../../components/KeywordLabel/noneEntered";
import { TaxonomyType } from "./taxonomy.interface";
import _ from "lodash";

interface GlobalTaxonomyPropsInterface {
    data: GlobalTaxonomyInterface | {};
    disabled: boolean;
    handleFormChange: (item: any) => void;
}

const GlobalTaxonomy = observer((props: GlobalTaxonomyPropsInterface) => {
    const { taxonomyStore } = useStores() || {};
    const [globalState] = globalStore() || {};

    const { disabled, handleFormChange = () => {} } = props;

    const { cmsData = {} } = globalState;

    const { clientDataStructure = {} } = cmsData;

    const { taxonomy = {} } = clientDataStructure;

    const { types = [], levels = [] } = taxonomy;

    useEffect(() => {
        taxonomyStore.initialize({
            handleFormChange,
            structure: taxonomy,
            data: props.data || {},
            cmsData,
        });
    }, []);

    return (
        <>
            {types.map((type: TaxonomyType, index: number) => {
                const { name, background, color, single } = type;

                const itemData: TaxonomyInterface[] =
                    taxonomyStore.data?.[taxonomyStore.globalKey]?.[name] || [];
                const hideSingleWhenNotEmpty: boolean =
                    single && !_.isEmpty(itemData);

                return (
                    <div
                        className={`taxonomy--${name}`}
                        key={`${index}--${name}`}
                    >
                        <span
                            style={Settings.components.label}
                            className="taxonomy-label"
                        >
                            {`${name} ${taxonomyStore.globalKey} Taxonomy`}
                        </span>

                        <span
                            style={Settings.components.secondaryLabel}
                            className="taxonomy-label"
                        >
                            {levels.join(" • ")}
                        </span>

                        {itemData.map(
                            (item: TaxonomyInterface, index: number) => {
                                return (
                                    <KeywordLabel
                                        key={`sdsd-${index}`}
                                        title={taxonomyStore.getItemTitle(item)}
                                        weighting={false}
                                        background={background}
                                        color={color}
                                        deleteWhite={true}
                                        margin="0.71875rem 0.5rem 0px 0px"
                                        delete={true}
                                        disabled={disabled}
                                        handleDelete={() =>
                                            taxonomyStore.handleDeleteItem(
                                                item,
                                                name,
                                            )
                                        }
                                    />
                                );
                            },
                        )}

                        {_.isEmpty(itemData) && disabled && <NoneEntered />}

                        {!disabled && !hideSingleWhenNotEmpty && (
                            <AutocompleteInput
                                disabled={disabled}
                                placeholder="Select Taxonomy"
                                value=""
                                dataListSegregationLines={true}
                                className={`${name}-taxonomy`}
                                handleListSelection={(item: any) =>
                                    taxonomyStore.handleAddToData(item, name)
                                }
                                openOnClick={true}
                                createNew={false}
                                datalistMultiDimensional={true}
                                loading={taxonomyStore.loadingOptions}
                                staticDatalist={
                                    taxonomyStore.transformedOptions
                                }
                                key={`${name}-${index}`}
                            />
                        )}
                    </div>
                );
            })}
        </>
    );
});

export default GlobalTaxonomy;
