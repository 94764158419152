import React, { Component } from "react";
import _ from "lodash";
import {
    SortableContainer,
    SortableElement,
    arrayMove,
} from "react-sortable-hoc";
import update from "react-addons-update";
import styles from "./styles";
import KeywordLabel from "../KeywordLabel";
import Settings from "../Settings";
import Input from "../Input/datalist";
import AutocompleteInput from "../AutocompleteInput";
import NoneEntered from "../KeywordLabel/noneEntered";
import toLowerCase from "../Helpers/toLowerCase";

export default class BubbleList extends Component {
    constructor(props) {
        super(props);

        let data = [];
        let valueSet = new Set();

        if (Array.isArray(this.props.data)) {
            data = this.props.data;

            if (this.props.ranking) {
                data = this.sortData(this.props.data);
            }

            valueSet = new Set(
                data.map((element) => {
                    return toLowerCase(element.value);
                }),
            );
        }

        this.styles = styles(this.props);

        /**
         * @var object
         */
        this.state = {
            data,
            value: "",
            showInput: false,
            valueSet,
        };

        this.handleSetCheck = this.handleSetCheck.bind(this);
    }

    handleSetCheck(value) {
        return this.state.valueSet.has(toLowerCase(value));
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.data, prevProps.data)) {
            let data = [];

            if (Array.isArray(this.props.data)) {
                data = this.props.data;

                if (this.props.ranking) {
                    data = this.sortData(this.props.data);
                }
            }

            this.setData(data);
        }
    }

    setStateValue(key, value) {
        if (!_.isEqual(this.state[key], value)) {
            this.setState({
                [key]: value,
            });
        }
    }

    setData(data) {
        if (!_.isEqual(this.state.data, data)) {
            this.setState({
                data,
            });
        }
    }

    addEntryToDataState(entry) {
        let selectedItem = _.cloneDeep(entry);
        let valid = false;

        const { handleEnter = () => {}, handleChangeText = () => {} } =
            this.props;

        if (typeof selectedItem === "string") {
            if (selectedItem !== "") {
                valid = true;
            }
        }

        if (typeof selectedItem === "object") {
            if (
                (selectedItem.value || selectedItem.text) &&
                (selectedItem.value !== "" || selectedItem.text !== "")
            ) {
                valid = true;
            }
        }

        if (valid) {
            const data = this.state.data;
            let ranking = 1;
            let valueExists = false;
            let itemDeleted = false;
            let deletedItemIndex = false;

            // Converts string to object
            if (typeof selectedItem === "string") {
                selectedItem = {
                    value: selectedItem,
                    text: selectedItem,
                };
            }

            if (data.length > 0) {
                data.forEach((item, index) => {
                    if (item && !item.deleted) {
                        ranking++;
                    }

                    if (
                        selectedItem.value === item.value &&
                        selectedItem.value
                    ) {
                        valueExists = true;
                    }

                    if (
                        selectedItem.value === item.value &&
                        selectedItem.value &&
                        item.deleted
                    ) {
                        itemDeleted = true;
                        deletedItemIndex = index;
                    }

                    // Null values
                    if (!selectedItem.value && selectedItem.text) {
                        if (selectedItem.text === item.text) {
                            valueExists = true;
                        }
                    }
                });
            }

            if (!valueExists && !itemDeleted) {
                let newItem = {
                    ...selectedItem,
                };

                if (this.props.ranking) {
                    newItem = {
                        ...selectedItem,
                        ranking,
                    };
                }

                const updatedState = update(this.state.data, {
                    $push: [newItem],
                });

                this.setState(
                    {
                        data: updatedState,
                    },
                    () => {
                        this.handleFormChange(updatedState);
                    },
                );
            }

            if (
                valueExists &&
                itemDeleted &&
                Number.isInteger(deletedItemIndex)
            ) {
                const stateData = Object.assign([], this.state.data);
                if (Array.isArray(stateData)) {
                    const item = stateData[deletedItemIndex];

                    if (typeof item === "object") {
                        delete item.deleted;
                        item.ranking = ranking;
                    }

                    const updatedState = update(this.state.data, {
                        [deletedItemIndex]: {
                            $set: item,
                        },
                    });

                    updatedState.push(
                        updatedState.splice(deletedItemIndex, 1)[0],
                    );

                    this.setState(
                        {
                            data: updatedState,
                        },
                        () => {
                            this.handleFormChange(updatedState);
                        },
                    );
                }
            }

            let itemToPush = selectedItem.value;

            if (selectedItem.text) {
                handleChangeText(selectedItem.text);
            }

            if (typeof itemToPush === "string") {
                itemToPush = toLowerCase(selectedItem.value);
            }

            this.state.valueSet.add(itemToPush);
            handleEnter(itemToPush);
        }
    }

    handleDelete(deletedItem = {}) {
        const { deleteObject, handleDelete } = this.props;

        const { data } = this.state;

        const updatedItem = {
            ...deletedItem,
            deleted: true,
        };

        const index = updatedItem.index;

        delete updatedItem.ranking;
        delete updatedItem.index;

        let updatedState = update(data, {
            [index]: {
                $set: updatedItem,
            },
        });

        if (this.props.ranking) {
            updatedState = this.orderData(updatedState);
        }

        // fully delete
        if (deleteObject) {
            updatedState = update(data, { $splice: [[index, 1]] });
        }

        this.setData(updatedState);

        if (typeof handleDelete === "function") {
            return handleDelete(deletedItem);
        }

        if (this.state.valueSet.has(toLowerCase(deletedItem.value))) {
            this.state.valueSet.delete(toLowerCase(deletedItem.value));
        }

        this.handleFormChange(updatedState);
    }

    handleFormChange(data = []) {
        let updatedData = Object.assign([], data);

        if (Array.isArray(updatedData)) {
            updatedData = updatedData.map((item) => {
                delete item.index;
                return item;
            });
        }

        if (typeof this.props.handleFormChange === "function") {
            this.props.handleFormChange(updatedData);
        }
    }

    orderDataOnMove(data) {
        if (typeof data === "object") {
            let orderedData = arrayMove(
                this.state.data,
                data.oldIndex,
                data.newIndex,
            );

            if (this.props.ranking) {
                orderedData = this.orderData(orderedData);
            }

            let dataState = this.state.data;
            dataState = orderedData;

            this.setData(dataState);
            this.handleFormChange(dataState);
        }
    }

    orderData(data) {
        let ranking = 1;
        const result = Object.assign([], data);

        if (Array.isArray(data)) {
            if (result.length > 0) {
                result.map((item, index) => {
                    if (item && !item.deleted) {
                        item.ranking = ranking;
                        ranking++;
                    }
                });
            }
        }

        return result;
    }

    sortData(data) {
        let copy = Object.assign([], data);

        if (Array.isArray(copy)) {
            copy = copy.sort((a, b) => a.ranking - b.ranking);
            return this.orderData(copy);
        }

        return copy;
    }

    filterDatalist(data, datalist) {
        if (Array.isArray(data) && Array.isArray(datalist)) {
            let result = Object.assign([], datalist);

            data.forEach((item) => {
                if (item && _.isObject(item) && !item.deleted) {
                    result = result.filter((dl) => {
                        const dlText = dl.text || dl.name;
                        const itemText = item.text || item.name;

                        return itemText !== dlText && item.value !== dl.value;
                    });
                }
            });
            return result;
        }

        return datalist;
    }

    getDataWithFilteredDeleted() {
        return this.state.data.filter(item => !item.deleted)
    }

    renderNoneEnteredReadOnly(copy = {}) {
        const { showNoneEntered = true } = this.props;

        let display = true;

        if (Array.isArray(this.state.data)) {
            this.state.data.map((item) => {
                if (item && !item.deleted) {
                    display = false;
                }
            });
        }

        if (display && this.props.readOnly && showNoneEntered) {
            return (
                <NoneEntered
                    title={this.props.noneEnteredText || "None Entered"}
                />
            );
        }

        return false;
    }

    renderData() {
        let display = false;

        if (Array.isArray(this.state.data)) {
            this.state.data.map((item) => {
                if (item && !item.deleted) {
                    display = true;
                }
            });
        }

        if (this.state.data.length > 0 && display) {
            const sortableOptions = {
                axis: "xy",
                lockToContainerEdges: true,
                lockOffset: "0%",
                helperClass: "sortableHelper",
                distance: 2,
            };

            const SortableList = SortableContainer(() => {
                return (
                    <div
                        style={{
                            display: "inline-block",
                            verticalAlign: "top",
                        }}
                    >
                        {this.state.data.map((item, index) => {
                            if (item && !item.deleted) {
                                const sortableItem = {
                                    item,
                                    index,
                                };

                                let title = "";
                                if (typeof item === "string") title = item;
                                if (item && (item.text || item.name)) {
                                    title = item.text || item.name;
                                }
                                if (
                                    item &&
                                    item.value &&
                                    typeof item.value === "object" &&
                                    item.value.text
                                ) {
                                    title = item.value.text;
                                }

                                if (!title) {
                                    return false;
                                }

                                return (
                                    <SortableItem
                                        sortableItem={sortableItem}
                                        title={title}
                                        key={`item-${index}`}
                                        disabled={this.props.readOnly}
                                        index={index}
                                    />
                                );
                            }
                        })}
                    </div>
                );
            });

            const SortableItem = SortableElement((props) => {
                const { sortableItem = {}, title } = props;

                const { item } = sortableItem;

                let background = this.props.background;
                if (item && item.background) background = item.background;

                return this.renderKeyWordLabel({
                    title,
                    background,
                    ...sortableItem,
                    handleDelete: true,
                });
            });

            return (
                <>
                    <SortableList
                        onSortEnd={(data) => this.orderDataOnMove(data)}
                        {...sortableOptions}
                    />

                    <input
                        name={this.props.name}
                        onChange={() => {}}
                        value={JSON.stringify(this.state.data)}
                        type="hidden"
                        style={{
                            display: "none",
                        }}
                    />
                </>
            );
        }

        return false;
    }

    renderKeyWordLabel(props) {
        const {
            title,
            background = "#dedede",
            item = {},
            index,
            handleDelete,
            onClick = () => {},
            className,
        } = props;

        const {
            color = "#333333",
            deleteWhite,
            deleteBlack,
            readOnly,
            fontWeight,
        } = this.props;

        let leftIcon = false;
        if (!handleDelete) {
            leftIcon = (
                <img
                    src={`${Settings.images.path}/svg/plus-white-grey.svg`}
                    style={{
                        width: "0.625rem",
                        marginRight: "0.3125rem",
                    }}
                />
            );
        }

        return (
            <KeywordLabel
                title={title}
                weighting={false}
                background={background || this.props.background}
                color={color}
                margin="0.65375rem 0.71875rem 0 0"
                delete={handleDelete}
                deleteWhite={deleteWhite}
                deleteBlack={deleteBlack}
                disabled={readOnly}
                suffix={item.suffix}
                leftIcon={leftIcon}
                handleDelete={
                    !handleDelete
                        ? () => {}
                        : this.handleDelete.bind(this, {
                              ...item,
                              index,
                          })
                }
                fontWeight={fontWeight}
                onClick={onClick}
                className={className}
            />
        );
    }

    renderInput(styles) {
        const { displayInput = true } = this.props;

        if (!displayInput) {
            return false;
        }

        const datalist = this.filterDatalist(
            this.state.data || [],
            this.props.datalist || [],
        );
        this.datalist = datalist;

        const { showInput } = this.state;

        const {
            readOnly,
            latestAutocomplete = true,
            emptyPlaceholder,
            value,
            inputStyles,
            className,
            openOnClick,
            createNew,
            noMatchText = null,
            filterStaticDataList,
            disabled,
            limitResults,
            showAddNewButton,
            label,
            datalistMultiDimensional = false,
            dataListSegregationLines = false,
            datalistMultiDimensionalUseSingle = false,
        } = this.props;

        if (!showInput && !readOnly && showAddNewButton) {
            return (
                <div style={{ width: "100%", transition: "0.3s" }}>
                    {this.renderKeyWordLabel({
                        title: `Add ${label}`,
                        onClick: () => this.setState({ showInput: true }),
                        className: "add-new--label",
                    })}
                </div>
            );
        }

        if (!readOnly) {
            if (latestAutocomplete) {
                return (
                    <AutocompleteInput
                        placeholder={emptyPlaceholder}
                        value={value || ""}
                        inputStyles={inputStyles || {}}
                        handleListSelection={(value) =>
                            this.addEntryToDataState(value)
                        }
                        className={className}
                        openOnClick={openOnClick}
                        createNew={createNew || noMatchText}
                        staticDatalist={datalist || []}
                        handleChange={this.props.handleChange}
                        filterStaticDataList={filterStaticDataList}
                        inputName={this.props.inputName}
                        id={this.props.inputId}
                        datalistId={this.props.datalistId}
                        useEnterButtonOnSelect={
                            this.props.useEnterButtonOnSelect
                        }
                        validateEnterButton={this.props.validateEnterButton}
                        disabled={disabled}
                        limitResults={limitResults}
                        color={this.props.inputColor}
                        focusColor={this.props.inputColor}
                        datalistMultiDimensional={datalistMultiDimensional}
                        dataListSegregationLines={dataListSegregationLines}
                        datalistMultiDimensionalUseSingle={
                            datalistMultiDimensionalUseSingle
                        }
                        handleSetCheck={this.handleSetCheck}
                        noDelay={this.props.noDelay}
                        noMatchText={noMatchText}
                        searchOnClick={this.props.searchOnClick}
                        chevronBackground={this.props.chevronBackground}
                    />
                );
            }

            return (
                <Input
                    placeholder={emptyPlaceholder}
                    value={value || ""}
                    name={this.props.inputName}
                    id={this.props.inputId}
                    className={className}
                    handleEnter={(value) => this.addEntryToDataState(value)}
                    handleListSelection={(value) =>
                        this.addEntryToDataState(value)
                    }
                    handleChange={this.props.handleChange}
                    handleNoMatchText={this.props.handleNoMatchText}
                    noMatchText={noMatchText}
                    useEnterButtonOnSelect={this.props.useEnterButtonOnSelect}
                    minWidth="15rem"
                    width="100%"
                    styles={inputStyles || {}}
                    padding={this.props.padding || "0.6rem 0 0.6875rem"}
                    borderBackgroundFocus={
                        this.props.borderBackgroundFocus || "#5f74b4"
                    }
                    borderBackground={this.props.borderBackground}
                    borderHeightFocus="0.15625rem"
                    datalistId={this.props.datalistId}
                    datalist={datalist}
                    recordBasic
                    dataListZindex={this.props.dataListZindex}
                    single={this.props.single}
                    autoComplete="bubble-list"
                    error={this.props.error}
                    filterStaticDataList={filterStaticDataList}
                    labelColor={this.props.labelColor}
                    color={this.props.inputColor}
                    focusColor={this.props.inputColor}
                    inputMargin={this.props.inputMargin}
                    noDelay={this.props.noDelay}
                />
            );
        }

        return false;
    }

    render() {
        const {
            copy = {},
            wrapperStyles,
            id,
            label,
            longLabel = "",
            single
        } = this.props;

        const renderInputCondition = !single || this.getDataWithFilteredDeleted().length === 0;

        return (
            <div
                style={{
                    ...this.styles.container,
                    ...(wrapperStyles && wrapperStyles),
                }}
                className="meta-bubble-list"
                id={id}
            >
                {label && (
                    <label style={this.styles.label}>
                        {longLabel || label}
                    </label>
                )}

                {this.renderNoneEnteredReadOnly(copy)}

                <div style={this.styles.inlineContent}>
                    {this.renderData()}
                    {renderInputCondition && this.renderInput(this.styles)}
                </div>
            </div>
        );
    }
}
