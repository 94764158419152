import {
    CreditInterface,
    CreditListInterface,
    CreditRankMap,
    DataTablePayload
} from "../../credits.interface";
export default function syncCreditsOnRankReorder(
    updatedCredits: DataTablePayload[],
    credits: CreditInterface[],
    activeCreditList: CreditListInterface
) {
    return credits.map((credit: CreditInterface) => {
        const updatedCredit = updatedCredits.find(
            (updatedCredit: DataTablePayload) =>
                updatedCredit.localID === credit.local_id
        )

        if (!updatedCredit) {
            return credit;
        }

        const updatedCreditLists = [...credit.credit_lists];
        const listIndex = credit.credit_lists.findIndex(
            (creditList: CreditRankMap) => creditList.code === activeCreditList.code
        );

        if (listIndex !== -1) {
            updatedCreditLists[listIndex].ranking = updatedCredit.ranking;
            credit.ranking = (updatedCredit as DataTablePayload).ranking;
        }

        return {
            ...credit,
            credit_lists: updatedCreditLists
        };
    })
}