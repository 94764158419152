import React, { createContext, useReducer } from "react";
import { recordBodyReducer } from "./reducer";
import { getDefaultWrittenLanguageCodeFromCache } from "../../../models/ClientManaged/languagesModel"

export const DEFAULT_SCRIPT_CODE = "ENG";
export const DEFAULT_LANGUAGE_ISO = getDefaultWrittenLanguageCodeFromCache();

export type initialStateInterface = {
    menuActive: string;
    languageIso: string;
    script: string;
    activeParent: string;
};

export const initialState = {
    menuActive: "",
    languageIso: DEFAULT_LANGUAGE_ISO,
    script: DEFAULT_SCRIPT_CODE,
    activeParent: "title_data",
};

const AppContext = createContext<{
    state: initialStateInterface;
    dispatch: React.Dispatch<any>;
}>({
    state: initialState,
    dispatch: () => null,
});

const AppProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(recordBodyReducer, initialState);

    return (
        <AppContext.Provider value={{ state, dispatch }}>
            {children}
        </AppContext.Provider>
    );
};

export { AppContext, AppProvider };
