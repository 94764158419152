export const testConditions = {
    is_available_for_vice: true,
    taxonomy_defined: false,
    is_off_air_only: ['yeas'],
}

export default [
    {
        id: "1",
        displayName: "Is Available For Vice",
        key: "is_available_for_vice",
        type: "Conditions",
        archived: false,
        order: 1,
        dataType: "Boolean",
        options: {
            editable: {
                enabled: true,
                inputType: "Boolean",
                optionsListName: "",
                minLength: "",
                maxLength: "",
                booleanDisplay: {
                    type: "Select",
                    trueLabel: "Yes",
                    falseLabel: "No",
                },
            },
            unique: {
                enabled: false,
            },
            default: {
                enabled: false,
                value: null,
            },
        },
        categories: ["Episode"],
    },
    {
        id: "2",
        displayName: "Taxonomy Defined As Special",
        key: "taxonomy_defined",
        type: "Conditions",
        archived: false,
        order: 2,
        dataType: "Boolean",
        options: {
            editable: {
                enabled: true,
                inputType: "Boolean",
                optionsListName: "",
                minLength: "",
                maxLength: "",
                booleanDisplay: {
                    type: "Select",
                    trueLabel: "Yes",
                    falseLabel: "No",
                },
            },
            unique: {
                enabled: false,
            },
            default: {
                enabled: false,
                value: null,
            },
        },
        categories: ["Episode"],
    },
    {
        id: "3",
        displayName: "Is Off Air Only",
        key: "is_off_air_only",
        type: "Conditions",
        archived: false,
        order: 1,
        dataType: "Array",
        options: {
            editable: {
                enabled: true,
                inputType: "SelectMultiple",
                optionsListName: "",
                minLength: "",
                maxLength: "",
            },
            unique: {
                enabled: false,
            },
            default: {
                enabled: false,
                value: null,
            },
        },
        categories: ["Show"],
    },
    {
        id: "4",
        displayName: "Series Network",
        key: "series_network",
        type: "Conditions",
        archived: false,
        order: 1,
        dataType: "Array",
        options: {
            editable: {
                enabled: true,
                inputType: "SelectMultiple",
                optionsManagedList: {
                    enabled: true,
                    key: "client_managed_series_networks",
                    textField: "text",
                    valueField: "value",
                },
                minLength: "",
                maxLength: "",
            },
            unique: {
                enabled: false,
            },
            default: {
                enabled: false,
                value: null,
            },
        },
        categories: ["Show"],
    },
];
