import { CoreSelectOptionType } from "../../../core/Form/components/inputs/Select/types/CoreSelectOptionType";
import { TMChip } from "../../../core/Table/task-manager/chips/TMChip";
import { useCountries } from "./useCountries";

export function useCountryOptions(): CoreSelectOptionType[] {
    let countries = useCountries();
    countries = countries?.map((country) => {
        return {
            ...country,
            rightAdornment: (
                <TMChip colorVariant="black" label={country.value} />
            ),
        };
    });

    return countries;
}
