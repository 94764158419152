import { ValidationLayer } from '../../../../../models/RecordBuilder/Field.interface';
import BuilderFilterByCategory from '../../../../RecordBuilder/map/filterCategory'
import _ from "lodash"

const transformDescriptionsToDataStructure = ({
    data = [],
    fallback = [],
    category = "",
}) => {
    try {
        return BuilderFilterByCategory({ category, models: data })
            .filter(item => !item.archived)
            .sort((prev, next) => prev.order - next.order)
            .map((synopsesItem, index) => {
                const {
                    displayName: label = "",
                    key,
                    options = {},
                } = synopsesItem;

                return {
                    label,
                    key,
                    presentation_title: options.presentation?.enabled,
                    maxCharacter: options.validation.characterLimit.limit,
                    disableValidation: !options.validation.characterLimit.enabled,
                    characterLimitEnforce: options.validation.characterLimit.enabled && options.validation.characterLimit.limit && options.validation.characterLimit.validation !== ValidationLayer.None,
                    characterLimitLength: options.validation.characterLimit.limit,
                    status: options.status.enabled,
                    googleTranslate: options.googleTranslate.enabled,
                    recordHeaderDisplay: options.recordHeaderDisplay.enabled,
                    comment: options.comment.enabled,
                    defaultComment: options.comment.default.enabled,
                    defaultCommentValue: options.comment.default.value,
                    readOnly: options.validation.readOnly.enabled,
                };
            });
    } catch (error) {
        return fallback;
    }
};

export default transformDescriptionsToDataStructure;
