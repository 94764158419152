import { Contributor, CreditInterface, CreditListInterface } from "../../credits.interface";
import { assignCreditLocalId, assignCreditNames, assignCreditList } from "./index"

/**
 * Generates a CreditInterface object based on information from a Contributor object and an active CreditListInterface.
 *
 * @function
 * @param {CreditListInterface} activeCreditList - The active credit list.
 * @param {Contributor} contributor - The contributor information.
 * @returns {CreditInterface} - The generated CreditInterface object.
 */

export default function composeCredit(
    activeCreditList: CreditListInterface,
    contributor: Contributor,
    defaultScript: string = "ENG"
): CreditInterface {
    let credit: CreditInterface = {
        local_id: "",
        language: defaultScript,
        value: null,
        credit_meta_id: null,
        contributor_meta_id: null,
        date_of_birth: null,
        imdb_id: null,
        isni_id: null,
        hand_id: null,
        iva_person_id: null,
        title: null,
        name: null,
        first_name: null,
        last_name: null,
        middle_names: null,
        alternative_name: null,
        second_alternative_name: null,
        script_names: [],
        script_character_names: [],
        role: undefined,
        role_name: null,
        credit_lists: []
    }

    credit = assignCreditLocalId(credit)

    if (contributor as Contributor) {
        credit.value = contributor.value || null
        credit.contributor_meta_id = contributor.contributor_meta_id || null
        credit.date_of_birth = contributor.date_of_birth || null
        credit.imdb_id = contributor.imdb_id || null
        credit.isni_id = contributor.isni_id || null
        credit.hand_id = contributor.hand_id || null
        credit.iva_person_id = contributor.iva_person_id || null
    }

    let namedCredit: CreditInterface = assignCreditNames(credit as CreditInterface, contributor as Contributor)

    let listedCredit = assignCreditList(
        namedCredit as CreditInterface,
        activeCreditList
    )

    return listedCredit as CreditInterface
}