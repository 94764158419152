import { OptionInterface } from "../../interfaces"
import { defaultSectionOptions } from "./"
import UsergroupInformation from "../../../../components/RecordBody/src/sections/UsergroupInformation";

export const groupSettingsOption: OptionInterface = {
    name: "Usergroup Information",
    value: "groupSettings",
    path: "tab.groupSettings",
    children: [],
    Component: UsergroupInformation,
    ...defaultSectionOptions,
};

export const userGroupsOption: OptionInterface = {
    name: "userGroups",
    path: "userGroups",
    children: [],
};

export const lineupsUserGrpupsOption: OptionInterface = {
    name: "lineups & user groups",
    value: "userGroups",
    path: "userGroups",
    children: [],
};