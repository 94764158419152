import ProvideGroupRanking from "../../src/ProvideGroupRanking"
import sortByRanking from "./helpers/sortByRanking"
import _ from 'lodash'

const ProvideRanking = list => {
    const rankedList = sortByRanking(list)

    let groupedResult = ProvideGroupRanking({ data: Array.isArray(rankedList) ? _.groupBy(rankedList, 'role') : rankedList })

    let result = []
    Object.keys(groupedResult).map(key => {
        result = result.concat(groupedResult[key])
    })

    return result
}

export default ProvideRanking