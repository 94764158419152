import validateString from "../../../../components/Helpers/validateString";
import { DataStructureFieldConfigInterface, ValidationResult, ValidationUtilProps } from "../../interfaces";

const validateLength = ({
    response = {},
    options = {},
    configOptions,
}: ValidationUtilProps): ValidationResult | {} => {
    const {
        data = [],
        fieldType = '',
        fieldsConfig = [],
    } = configOptions;

    const {
        status,
        description,
    } = options;

    const filteredData: any = data.filter((item: any) => !item.deleted);

    if (Array.isArray(filteredData)) {
        filteredData.map((item) => {
            
            const fieldConfig: DataStructureFieldConfigInterface = fieldsConfig.find((config: DataStructureFieldConfigInterface) => item.type === config.type);
            if (
                fieldConfig
                && item[fieldType] 
                && fieldConfig.length
                && !validateString(item[fieldType], fieldConfig.length)
            ) {
                response.result = false;
                response.notification = {
                    title: `Please check ID ${fieldConfig.id}`,
                    description,
                    status,
                };
        
                response.validationKeys = response.validationKeys.concat([fieldConfig.type]);
            }
        });
    }

    return response;
};

export default validateLength;
