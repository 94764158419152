import SendRequest from "../../../components/SendRequest";
import GetApiUrls from "../GetApiUrls";

const HandleRecordDataSet = (props) => {
    const { state = {} } = props;

    const { set, activeRecord } = state;

    const { meta_id } = activeRecord;

    const { userGroupGetByMetaId = "" } = GetApiUrls(state);

    set({
        fetchingMetaData: true,
    });

    const requestOptions = {
        url: userGroupGetByMetaId.replace("%meta_id%", meta_id),
        method: "GET",
        basic: true,
    };

    const handleError = (error) => {
        console.log(error);

        set({
            fetchingMetaData: false,
            notification: {
                title: `Failed to fetch user group data`,
                description: error
                    ? error
                    : "Technical error whilst fetching user group data, please try again.",
                status: "error",
                onClick: () =>
                    set({
                        notification: {},
                    }),
            },
        });
    };

    try {
        SendRequest(
            requestOptions,
            (response) => {
                const { message, status, data } = response;

                if (status == 200) {
                    return set({
                        fetchingMetaData: false,
                        recordData: data,
                    });
                }

                handleError(message);
            },
            (error) => {
                handleError(error);
            },
        );
    } catch (e) {
        handleError(e);
    }
};

export default HandleRecordDataSet;
