import React from "react";
import { extendObservable, makeObservable, action } from 'mobx'
import { OptionsStore } from "./optionsStore"
import { ContributorCreateStore } from "./contributorCreateStore"
import { ContributorUpdateStore } from "./contributorUpdateStore"
import { NavigationStore } from "./navigationStore"
import { PermissionsStore } from "./permissionsStore"
import { IntegrationsStore } from "./integrationsStore"
import { ValidationStore } from "./validationStore"
import { rootStoreInitialState } from "./state"
import { CmsInterface, globalStateInterface } from "../../../../../components/Global/interfaces"

export class RootStore {
    rootStore: RootStore;
    optionsStore: OptionsStore;
    contributorCreateStore: ContributorCreateStore;
    contributorUpdateStore: ContributorUpdateStore;
    navigationStore: NavigationStore;
    permissionsStore: PermissionsStore;
    integrationsStore: IntegrationsStore;
    validationStore: ValidationStore;

    cmsData: CmsInterface;
    dispatch: any

    constructor() {
        this.rootStore = this;
        this.optionsStore = new OptionsStore(this);
        this.contributorCreateStore = new ContributorCreateStore(this)
        this.contributorUpdateStore = new ContributorUpdateStore(this)
        this.navigationStore = new NavigationStore(this)
        this.permissionsStore = new PermissionsStore(this)
        this.integrationsStore = new IntegrationsStore(this)
        this.validationStore = new ValidationStore(this)

        makeObservable(this, {
            getActiveContributorStore: action
        })

        extendObservable(this, rootStoreInitialState);
    }

    initialize(globalState: globalStateInterface, dispatch: any): void {
        this.cmsData = globalState?.cmsData
        this.dispatch = dispatch || {}
        
        this.optionsStore.initialize(globalState)
        this.navigationStore.initialize()
        this.permissionsStore.initialize(globalState)
        this.validationStore.initialize()
    }

    reset(): void {
        this.contributorCreateStore.reset()
        this.contributorUpdateStore.reset()
        this.integrationsStore.reset()
        this.validationStore.reset()
    }

    getDateFormat(): string {
        return this.cmsData?.clientDataStructure?.api_date_format
    }

    getActiveContributorStore(): ContributorCreateStore | ContributorUpdateStore {
        if (this.rootStore.contributorCreateStore.open) {
            return this.rootStore.contributorCreateStore
        }

        if (this.rootStore.contributorUpdateStore.open) {
            return this.rootStore.contributorUpdateStore
        }
    }
}

const StoresContext = React.createContext(new RootStore());
export const useStores = () => React.useContext(StoresContext);
