import React from "react";
import Settings from "../../Settings";
import CategoryTags from "../../CategoryTags";
import Button from "../../Button";
import TrippleTag from "../../DoubleTags/TrippleCenterColor";
import Tag from "../../Tag";
import "../styles/_homescreen_lineup_manager.scss";
import CollectionContentText from "./CollectionContentText";
import HandleSelectedRecord from "../../Selected/handleSelectedRecord";
import GetCollectionsQuickLinkPath from "./GetCollectionsQuickLinkPath";
import PermissionService from "../../../services/PermissionService";
import { accessTypeUpdate } from "../../../features/UserRoleManagement/utils/accessOptions";
import { lineupsOption } from "../../../features/UserRoleManagement/utils/sectionOptions/recordSections";

const HomeScreenLineupManagerRowContent = (props) => {
    const {
        item = {},
        collection = {},
        disabled,
        copy = {},
        editClick,
        deleteClick,
    } = props;

    const { title, duration, collection_type } = item;

    let titles = [];
    let linkage = [];
    let original_title = "";

    // Fixes null value
    Array.isArray(collection.titles) ? (titles = collection.titles) : [];
    Array.isArray(collection.linkage) ? (linkage = collection.linkage) : [];
    typeof collection.original_title == "string"
        ? (original_title = collection.original_title)
        : "";

    const collectionTitle = titles.find((item) => item.display) || {};

    const handleOnClick = () => {
        const path = GetCollectionsQuickLinkPath(collection_type);

        HandleSelectedRecord({
            selected: item.collection,
            path,
        });
    };

    return (
        <div className="meta-home-screen-linup-manager--row">
            <div className="left-content">
                <img src={CategoryTags.Feature.icon} />

                <span className="title">{title}</span>

                <span className="collection-content">
                    {CollectionContentText({
                        linkage,
                    })}
                </span>
            </div>

            <div className="right-content">
                <TrippleTag
                    leftText={collection_type}
                    centerText={collectionTitle.title || original_title}
                    rightText={item.collection}
                    customCenterTagStyles={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "15rem",
                    }}
                    handleOnClick={handleOnClick}
                />

                <Tag
                    text={duration}
                    background={duration == "single" ? "#f0b569" : "#f09669"}
                    styles={{
                        marginLeft: "0.625rem",
                    }}
                />

                {!disabled && (
                    <PermissionService
                        policyPermission={{
                            pathField: lineupsOption.path,
                            access: accessTypeUpdate,
                        }}
                    >
                        <Button
                            value={copy.edit || "Edit"}
                            type="button"
                            margin="0 0 0 0.75rem"
                            padding="0.1875rem 0.3125rem"
                            background="#8f8f8f"
                            hoverBackground="#9a9a9a"
                            fontSize="0.78125rem"
                            color="white"
                            lineHeight="initial"
                            onClick={editClick}
                        />

                        <div
                            className="button sort"
                            style={{
                                backgroundImage: `url(${Settings.images.path}/svg/menu-light-grey.svg)`,
                            }}
                        ></div>

                        <img
                            src={`${Settings.images.path}/svg/clear-light-grey.svg`}
                            className="delete"
                            onClick={deleteClick}
                        />
                    </PermissionService>
                )}
            </div>
        </div>
    );
};

const HomeScreenLineupManagerRows = (props) => {
    const {
        data = {},
        included = {},
        disabled,
        handleEditClick,
        handleDeleteClick,
    } = props;

    const { swimlanes = [], collections = [] } = included;

    let result = [];

    if (
        Array.isArray(data.swimlanes) &&
        Array.isArray(swimlanes) &&
        Array.isArray(collections)
    ) {
        data.swimlanes.forEach((item, index) => {
            const swimlane = swimlanes.find((swln) => item == swln.meta_id);

            let collection = {};
            if (swimlane && swimlane.collection) {
                collection =
                    collections.find(
                        (col) => swimlane.collection == col.meta_id,
                    ) || {};
            }

            result.push(
                <HomeScreenLineupManagerRowContent
                    item={swimlane}
                    collection={collection}
                    disabled={disabled}
                    editClick={handleEditClick.bind(this, item)}
                    deleteClick={handleDeleteClick.bind(this, item)}
                />,
            );
        });
    }

    return result;
};

export default HomeScreenLineupManagerRows;
