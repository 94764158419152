import React from "react";
import "./styles/_styles.scss";
import Switch from "../Switch";
import Title from "../Title";

const TitleWithImage = (props) => {
    const { styles = {}, image } = props;

    return (
        <div className="meta-title-image" style={styles}>
            <Title {...props} />

            <div>{image}</div>
        </div>
    );
};

export default TitleWithImage;
