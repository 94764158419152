import HandleCancelConfirm from "./HandleCancelConfirm";
import messages from "../../config/messages"

const HandleEditRecordClick = (props) => {
    const { state, value, copy = {} } = props;

    const { set, unsavedChanges } = state;

    if (!value) {
        if (unsavedChanges) {
            set({
                notification: {
                    title: copy.cancelTitle || "Unsaved Changes",
                    description:
                        copy.cancelDescription ||
                        messages.recordUnsavedChanges,
                    status: "error",
                    okText: copy.cancelOkText || "Leave this Page",
                    confirm: HandleCancelConfirm.bind(this, state),
                },
            });
        } else {
            HandleCancelConfirm(state);
        }
    }

    if (value) {
        set({
            editRecord: value,
        });
    }
};

export default HandleEditRecordClick;
