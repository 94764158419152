import React from "react";
import PlaceholderText from "../PlaceholderText";

const BuilderError: React.FC = () => (
    <div className="none-added-wrapper">
        <PlaceholderText text="Failed to fetch configuration" />
    </div>
);

export default BuilderError;
