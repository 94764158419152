import moment from "moment";
import _ from "lodash";
import CertificationEnrichmentHandler from "./handlers/CertificationEnrichmentHandler";
import UpdateRegionalLocaleValue from "./handlers/UpdateRegionalLocaleValue";
import ContributorHandler from "./handlers/ContributorHandler";
import OriginalAudioLanguageHandler from "./handlers/OriginalAudioLanguageHandler";
import USTheatricalReleaseDataHandler from "./handlers/USTheatricalReleaseDataHandler";
import ClientManagedLanguages from "../ClientManagedOptions/languages";

export default function DataTransformer(props) {
    const { data = {}, options, clientFeatures = {}, apiUrls = {} } = props;

    const { searchContributorsTerm = "" } = apiUrls;

    const { contributor_roles = [] } = options;

    let result = { ...data };

    const regionalSections = ["titles", "synopses"];

    if (result.release_duration) {
        result.release_duration_transformed = moment
            .utc(result.release_duration * 1000)
            .format("HH:mm:ss");
    }

    if (result.tv_premiere_date) {
        result.tv_premiere_date_transformed = moment(
            result.tv_premiere_date,
        ).format("Do MMMM YYYY");
    }

    if (result.original_air_date) {
        result.original_air_date_transformed = moment(
            result.original_air_date,
        ).format("Do MMMM YYYY");
    }

    if (!_.isEmpty(result.genres)) {
        result.genres = result.genres.map((item) => {
            return {
                text: item,
                value: item,
                ranking: 1,
            };
        });
    }

    if (result.original_audio_language) {
        const languagesList = ClientManagedLanguages({
            options,
            clientFeatures,
            spoken: true,
            languageKey: "primary_language",
        }).map((language) => {
            return {
                meta_code: language.meta_code,
                name: language.name,
            };
        });

        const transformLanguage = OriginalAudioLanguageHandler({
            language: result.original_audio_language,
            languagesList,
        });

        result = {
            ...result,
            ...transformLanguage,
        };
    }

    if (result.us_theatrical_release_date) {
        const transformReleaseData = USTheatricalReleaseDataHandler(
            result.us_theatrical_release_date,
        );

        result = {
            ...result,
            ...transformReleaseData,
        };
    }

    if (!_.isEmpty(result.contributors)) {
        const handleTransformation = (props) => {
            const { recordData = [], data = {} } = props;

            const { role = "" } = data;

            let rankCount = recordData.filter((item) => {
                return item.role == data.role && _.isEmpty(item.deleted);
            }).length;
            return {
                ...data,
                ranking: rankCount + 1,
            };
        };

        result.contributors = result.contributors.map((contributor) => {
            const defaultContributor = ContributorHandler({
                item: contributor,
                contributor_roles,
                searchContributorsTerm,
            });

            return {
                ...contributor,
                storeKey: defaultContributor.storeKey,
                isValid: defaultContributor.isValid,
                name: contributor.contributor,
                handleTransformation,
            };
        });
    }

    if (!_.isEmpty(result.production_companies)) {
        result.production_companies = result.production_companies.map(
            (item) => {
                return {
                    text: item,
                    value: item,
                    ranking: 1,
                };
            },
        );
    }

    regionalSections.map((section) => {
        if (
            result[section] &&
            Array.isArray(result[section]) &&
            !_.isEmpty(result[section])
        ) {
            result[section] = result[section].map((item) => {
                return UpdateRegionalLocaleValue({
                    options,
                    clientFeatures,
                    regionalItem: item,
                });
            });
        }
    });

    if (!_.isEmpty(result.certifications)) {
        result.certifications = result.certifications.map((item) => {
            return CertificationEnrichmentHandler({
                options: options,
                certificate: item,
            });
        });
    }

    return result;
}
