import React from "react";
import _ from "lodash";
import styles from "../styles";
import Input from "../../Input/basic";
import update from "react-addons-update";
import Index from "./Index";
import deepcopy from "deepcopy";
import { diff } from "deep-object-diff";
import DynamicInputs from "../../Input/DynamicInputs";

export default class SeasonNumbering extends Index {
    constructor(props) {
        super(props);

        let data = {};
        if (typeof this.props.data == "object") {
            data = this.props.data;
        }

        /**
         * @var object
         */
        this.copy = this.props.copy || {};

        /**
         * @var object
         */
        this.styles = styles(this.props);

        /**
         * @var object
         */
        this.state = {
            data,
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    shouldComponentUpdate(nextProps, nextState) {
        const nextPropsData = nextProps.data || {};
        const thisPropsData = this.props.data || {};
        const difference = diff(nextPropsData, thisPropsData);

        if (!_.isEmpty(difference)) {
            return true;
        }

        if (!_.isEqual(nextProps.disabled, this.props.disabled)) {
            return true;
        }

        if (!_.isEqual(nextProps.validationKeys, this.props.validationKeys)) {
            return true;
        }

        if (!_.isEqual(nextState, this.state)) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props.data, prevProps.data)) {
            if (typeof this.props.data == "object") {
                this.setStateValue("data", this.props.data);
            }
        }
    }

    handleChange(name, value) {
        const data = this.state.data || {};
        let stateData = deepcopy(data);
        let result = { ...stateData };

        if (typeof name == "string") {
            result[name] = value;

            // revert changes
            if (name == "season_number" && typeof value == "string") {
                result.season_number = data.season_number;
            }

            if (typeof this.props.handleFormChange == "function") {
                this.props.handleFormChange(result);
            }

            const newData = update(this.state.data, { $set: result });
            this.setData(newData);
        }
    }

    renderNoneAdded() {
        return false;
    }

    renderContent() {
        const data = deepcopy(this.state.data || {});

        const {
            disabled,
            clientDataStructure = {},
            category,
            validationKeys = [],
        } = this.props;

        const { seasonNumbering = [] } = clientDataStructure;

        return (
            <React.Fragment>
                {!_.isEmpty(seasonNumbering) && (
                    <div
                        className="season-numbering-dynamic"
                        style={{ ...this.styles.flexMarginContainer }}
                    >
                        <DynamicInputs
                            options={seasonNumbering}
                            handleChange={(key, value) =>
                                this.handleChange(key, value)
                            }
                            styles={{ ...this.styles.flexMarginItem }}
                            data={data}
                            disabled={disabled}
                            category={category}
                            validationKeys={validationKeys}
                        />
                    </div>
                )}
            </React.Fragment>
        );
    }
}
