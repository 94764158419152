import messages from "../../../config/messages"

const UnsavedChanges = (props) => {
    const { copy = {} } = props;

    return {
        title: copy.unsavedChanges || "Unsaved Changes",
        description:
            copy.unsavedChangesDescription ||
            messages.recordUnsavedChanges,
        okText: copy.cancelOkText || "Leave this Page",
        status: "error",
    };
};

export default UnsavedChanges;
