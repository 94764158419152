import React from "react";
import _ from 'lodash'
import { CmsInterface } from "../../components/Global/interfaces"
import { SendRequestResponseInterface } from "../../components/SendRequest/interface"
import fetchClientManagedOptions from "../../components/ClientManagedOptions/fetchClientManagedOptions"
import selectTransformer from "../../core/Form/utils/selectTransformer"
import { getListItems } from '../ApiClients/CML'
import { mapListItems } from '../../models/ClientManaged/dynamicCML'
import FieldInterface from '../../core/Form/dynamic/interfaces/Field.interface'

export async function getList(cmsData: CmsInterface, isDynamicList: boolean, listKey: string): Promise<SendRequestResponseInterface> {
    return isDynamicList 
        ? getDynamicList(cmsData, listKey)
        : getLegacyList(cmsData, listKey)
}

export async function getLegacyList(cmsData: CmsInterface, listKey: string): Promise<SendRequestResponseInterface> {
    return await new Promise(function(resolve, reject) {
        fetchClientManagedOptions({
            clientManagedType: listKey,
            apiUrls: cmsData?.apiUrls || {},
            clientFeatures: cmsData?.clientFeatures || {},
            testingData: [],
            searchAll: true,
            onResponse: (items: any) => {
                if (_.isArray(items)) {
                    resolve(
                        selectTransformer(items)
                    )
                }
            },
            onError: () => reject(),
        })
    })
}

export async function getDynamicList(cmsData: CmsInterface, listKey: string): Promise<SendRequestResponseInterface> {
    const result: Array<any> = await getListItems(cmsData, listKey)
    return mapListItems(result)
}