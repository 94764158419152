import { CoreSelectOptionType } from "../../../core/Form/components/inputs/Select/types/CoreSelectOptionType";
import { TMChip } from "../../../core/Table/task-manager/chips/TMChip";
import { useLanguages } from "./useLanguages";

export function useLanguageOptions(): CoreSelectOptionType[] {
    const languages = useLanguages();
    const languageOptions: CoreSelectOptionType[] = languages?.map(
        (language) => {
            return {
                ...language,
                rightAdornment: (
                    <TMChip colorVariant="black" label={language.value} />
                ),
            };
        },
    );

    return languageOptions;
}
