import validateString from "../../../../../components/Helpers/validateString";
import GetLocaleNameFromOptions from "../../../../../components/GetLocaleNameFromOptions";
import { DataStructureFieldConfigInterface, ValidationResult } from "../../../interfaces";
import { ValidateLengthProps } from "../../../interfaces";

const validateLength = ({
    response = {},
    options = {},
    configOptions,
}: ValidateLengthProps): ValidationResult | {} => {
    const {
        data = [],
        fieldType = '',
        fieldsConfig = [],
    } = configOptions;

    const {
        copy = {},
        status,
        description,
        options: cmsOptions = {},
    } = options;

    let validationLocaleName: string = '';
    let validationLocaleCode: string = '';
    let validationTitleType: string = '';

    const filteredData: any = data.filter((item: any) => !item.deleted);

    if (Array.isArray(filteredData)) {
        filteredData.map((item) => {
            
            const fieldConfig: DataStructureFieldConfigInterface = fieldsConfig.find((config: DataStructureFieldConfigInterface) => item.type === config.type);
            if (
                fieldConfig
                && item[fieldType] 
                && fieldConfig.length
                && !validateString(item[fieldType], fieldConfig.length)
            ) {
                validationLocaleName = GetLocaleNameFromOptions(item.locale, cmsOptions);
                validationLocaleCode = item.locale;
                validationTitleType = item.type;
            }
        });
    }

    if (validationLocaleName && validationLocaleCode) {
        response.result = false;
        response.notification = {
            title: `${
                copy.checkOriginalTitle || `Please check ${fieldType}`
            } ${validationLocaleName} - ${validationTitleType.toUpperCase()}`,
            description,
            status,
        };

        response.validationKeys = response.validationKeys.concat([
            `${validationTitleType}_${fieldType}_${validationLocaleCode}`,
        ]);
    }

    return response;
};

export default validateLength;
