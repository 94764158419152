import _ from "lodash";
import isIndexInArray from "../../Helpers/isIndexInArray";
import CategoryTags from "../../CategoryTags";
import Margins from "../../Financials/src/Margins";

const CyclesTransformer = (response) => {
    const { data = [], included = [] } = response;

    let result = {};
    let sortedBy = _.orderBy(data, ["onboarding_month"], ["asc"]);
    let groupedBy = _.chain(sortedBy).groupBy("onboarding_month_name").value();

    if (typeof groupedBy == "object") {
        Object.keys(groupedBy).forEach((month) => {
            const recordIds =
                groupedBy[month].map((item) => item.record_id) || [];
            const records = included.filter((item) =>
                isIndexInArray(recordIds, item.id),
            );

            const movies = records.filter(
                (item) => item.category == CategoryTags.Feature.short,
            );
            const episodes = records.filter(
                (item) => item.category == CategoryTags.Episode.short,
            );

            let sumTotalMargin = 0;
            let sumTotalSellRate = 0;
            let linkageEpisodes = 0;

            // count linkages
            records.forEach((record) => {
                if (Array.isArray(record.linkage)) {
                    const recordLinkageEpisodes = record.linkage.filter(
                        (item) => item.category == CategoryTags.Episode.short,
                    );

                    if (!_.isEmpty(recordLinkageEpisodes)) {
                        linkageEpisodes =
                            parseInt(linkageEpisodes) +
                            parseInt(recordLinkageEpisodes.length);
                    }
                }
            });

            // Total Margin
            groupedBy[month].forEach((item) => {
                let { total_margin, total_sell_rate } = Margins(item);

                sumTotalMargin =
                    parseInt(sumTotalMargin) + parseInt(total_margin);
                sumTotalSellRate =
                    parseInt(sumTotalSellRate) + parseInt(total_sell_rate);
            });

            result[month] = {
                movies: movies.length,
                episodes: parseInt(episodes.length) + parseInt(linkageEpisodes),
                total_margin: sumTotalMargin,
                margin: (sumTotalMargin / sumTotalSellRate) * 100,
            };
        });
    }

    return result;
};

export default CyclesTransformer;
