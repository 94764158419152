import { observer } from "mobx-react";
import { useFormContext } from "react-hook-form";
import { CoreInputText } from "../../../../../../core/Form/components/inputs/CoreInput.text";
import { EpisodeVT } from "../../../../config/types";
import { Categories } from "../../tv-series/types/types";
import { HideFormConditionally } from "./HideFormConditionally";

export const EpisodeNo = observer(() => {
    const form = useFormContext<EpisodeVT>();
    const category = form?.getValues("category");

    let labelText = "Episode No.";
    if (category === Categories.Storyline) {
        labelText = "Storyline No.";
    }

    return (
        <HideFormConditionally>
            <CoreInputText<EpisodeVT>
                path="episodeNo"
                labelProps={{
                    text: labelText,
                    isRequired: false,
                }}
                type="number"
                placeholder={`Enter ${category?.toLowerCase()} number...`}
            />
        </HideFormConditionally>
    );
});
