import React, { Component } from "react";
import _ from "lodash";
import styles from "./styles";
import Input from "../Input/datalist";
import SendRequest from "../SendRequest";
import GetStamp from "../SendRequest/Stamp";
import DoubleTags from "../DoubleTags";
import CategoryTags from "../CategoryTags";
import Switch from "../Switch";
import LinkageSearchTransformer from "../Transformers/LinkageSearchTransformer";
import Index from "./index";
import update from "react-addons-update";
import deepcopy from "deepcopy";

export default class LinkageGeneral extends Index {
    constructor(props) {
        super(props);

        let data = {};
        if (typeof this.props.data == "object") {
            data = this.props.data;
        }

        /**
         * @var object
         */
        this.defaultActiveRowData = {
            id: null,
            link_record_id: null,
            original_title: false,
            first_release_year: false,
            meta_id: false,
            category: false,
            type: false,
        };

        if (this.props.logoBranding) {
            this.defaultActiveRowData.logo_branding = false;
        }

        /**
         * @var object
         */
        this.state = {
            data,
            editRow: false,
            hoverRow: false,
            newRow: true,
            activeRow: { ...this.defaultActiveRowData },
            notification: {},
            datalist: this.props.datalist || [],
        };

        /**
         * @var object
         */
        this.styles = styles(this.props);

        /**
         * @var object
         */
        this.copy = this.props.copy || {};

        /**
         * @var string
         */
        this.interactionButtonClassName = "meta-notification-linkage-save";

        /**
         * @var bool
         */
        this.keyPressEnterDisabled = true;

        /**
         * @var string|bool
         */
        this.stamp = false;

        /**
         * @var object
         */
        this.timer = null;

        /**
         * @var string
         */
        this.classPrefix = this.props.classPrefix || "meta-linkage";
    }

    componentDidUpdate(prevProps, prevState) {
        // Edit record
        if (!_.isEqual(this.props.data, prevProps.data)) {
            this.setDataFromProps();
        }
    }

    setDataFromProps() {
        let result = {};

        if (typeof this.props.data == "object") {
            result = this.props.data;
        }

        if (Array.isArray(this.props.data)) {
            result = this.props.data;
        }

        this.setData(result);
    }

    setNotification(notification) {
        if (!_.isEqual(this.state.notification, notification)) {
            this.setState({
                notification,
            });
        }
    }

    setDatalist(datalist) {
        if (!_.isEqual(this.state.datalist, datalist) && this._isMounted) {
            this.setState({
                datalist,
            });
        }
    }

    checkEmptyDataKeys() {
        let result = true;

        if (typeof this.state.data == "object") {
            this.state.data.map((item) => {
                if (
                    item.original_title == false ||
                    item.first_release_year == false ||
                    item.meta_id == false ||
                    item.link_record_id == false ||
                    item.category == false ||
                    item.type == false
                ) {
                    result = false;
                }
            });
        }

        return result;
    }

    isActiveRowValidated() {
        let result = false;

        if (
            this.state.activeRow.original_title ||
            this.state.activeRow.first_release_year ||
            this.state.activeRow.meta_id ||
            this.state.activeRow.link_record_id
        ) {
            result = true;
        }

        return result;
    }

    handleSearch(searchTerm) {
        if (typeof this.props.handleLinkSearch == "function") {
            const url = {
                params: `?selected=${searchTerm}`,
            };

            this.props.handleLinkSearch(searchTerm, url);
        }
    }

    async handleInactiveStateChange(key, index, value) {
        const row = deepcopy(this.state.data[index]);

        if (typeof row == "object") {
            row[key] = value;
        }

        const data = update(this.state.data, {
            [index]: {
                $set: row,
            },
        });

        await this.setData(data);
        this.handleChangeCallBack();
    }

    async handleChange(key, value) {
        let activeRow = {
            id: this.state.activeRow.id,
            link_record_id: this.state.link_record_id,
            original_title: this.state.activeRow.original_title,
            first_release_year: this.state.activeRow.first_release_year,
            meta_id: this.state.activeRow.meta_id,
            category: this.state.activeRow.category,
            type: this.state.activeRow.type,
        };

        let notification = {
            title: this.state.notification.title,
            okText: this.state.notification.okText,
            html: this.state.notification.html,
            confirm: this.state.notification.confirm,
            status: this.state.notification.status,
            disabled: true,
            background: this.state.notification.background,
        };

        if (key == "record_title") {
            activeRow.link_record_id = false;
            activeRow.original_title = false;
            activeRow.first_release_year = false;
            activeRow.meta_id = false;
            activeRow.category = false;
            activeRow.type = false;

            this.setDatalist([]);

            if (typeof value == "string" && value && this.props.searchUrl) {
                try {
                    clearTimeout(this.timer);
                    this.timer = setTimeout(
                        this.sendRequest.bind(this, notification, value),
                        350,
                    );
                } catch (e) {
                    console.log(e);
                }
            }

            // API faker
            if (process.env.NODE_ENV == "test" && value == "Jumanji") {
                activeRow.id = 1234;
                activeRow.link_record_id = 12345;
                activeRow.original_title = "Jumanji";
                activeRow.first_release_year = 2018;
                activeRow.meta_id = "METAID";
                activeRow.category = "Feature";
                activeRow.type = "Live Action";
            }

            if (typeof value == "object") {
                activeRow.id = value.id;
                activeRow.link_record_id = value.id;
                activeRow.original_title = value.original_title;
                activeRow.first_release_year = value.first_release_year;
                activeRow.meta_id = value.meta_id;
                activeRow.category = value.category;
                activeRow.type = value.type;
            }
        }

        this.setState(
            {
                activeRow,
            },
            () => {
                if (this.isActiveRowValidated()) {
                    notification.disabled = false;
                }

                this.setNotification(notification);
            },
        );
    }

    sendRequest(notification = {}, value = {}) {
        const stamp = GetStamp();
        const options = {
            url: this.props.searchUrl.replace("{searchTerm}", value),
            method: "GET",
            stamp,
        };

        this.stamp = stamp;

        SendRequest(
            options,
            async (data) => {
                if (this.stamp == data.stamp) {
                    let transformedData = LinkageSearchTransformer(data.data);
                    await this.setDatalist(transformedData);

                    // Filter records already selected
                    if (Array.isArray(this.props.concatData)) {
                        this.props.concatData.forEach((stateItem) => {
                            transformedData = transformedData.filter(
                                (item) => item.meta_id !== stateItem.meta_id,
                            );
                        });
                    }

                    notification.html = this.renderNotificationHtml(
                        {},
                        false,
                        transformedData,
                    );
                    this.setNotification(notification);
                }
            },
            (e) => {
                console.log(e);
            },
        );

        clearTimeout(this.timer);
    }

    handleNewRow() {
        if (
            this.state.newRow &&
            this.state.editRow === false &&
            this.checkEmptyDataKeys() &&
            typeof this.state.data == "object"
        ) {
            let title = this.copy.relationshipLink || "Relationship Link";

            if (this.props.linkTitle) {
                title = this.props.linkTitle;
            }

            const notification = {
                title,
                okText: this.copy.save || "Save",
                html: this.renderNotificationHtml(),
                confirm: this.handleSave.bind(this),
                status: "alert",
                disabled: !this.isActiveRowValidated(),
                background: "white",
            };

            this.setNotification(notification);
        }
    }

    handleSave() {
        if (this.isActiveRowValidated()) {
            if (typeof this.state.activeRow == "object") {
                const data = update(this.state.data, {
                    $push: [this.state.activeRow],
                });

                this.setState(
                    {
                        data,
                    },
                    () => {
                        this.setNotification({});
                        this.setActiveRowDefault();
                        this.setEditRow(false);
                        this.handleChangeCallBack();
                    },
                );
            }
        }
    }

    handleNotificationCancel() {
        this.setNotification({});
        this.setEditRow(false);
    }

    renderNotificationHtml(
        item = {},
        index = false,
        datalist = this.state.datalist,
    ) {
        let placeholder = this.copy.startTypingTitle || "Start Typing Title";
        if (this.props.linkPlaceholder) {
            placeholder = this.props.linkPlaceholder;
        }

        return (
            <div>
                <Input
                    placeholder={placeholder}
                    className={`${this.classPrefix}-record-title`}
                    id={`${this.classPrefix}-record-title`}
                    datalist={datalist}
                    single={true}
                    noFilter={true}
                    autoFocus={true}
                    recordBasic={true}
                    keepSelected={true}
                    value={item.original_title}
                    handleChange={this.handleChange.bind(this, "record_title")}
                    handleListSelection={this.handleChange.bind(
                        this,
                        "record_title",
                    )}
                />
            </div>
        );
    }

    renderDataRow(item, index, count) {
        if (typeof item == "object") {
            let disabled = true;
            let oddIndex = count % 2;
            const tag = CategoryTags[item.category || item.category_name] || {};

            if (!this.props.disabled) {
                disabled = false;
            }

            return (
                <div
                    style={Object.assign(
                        {},
                        this.styles.tableContainer,
                        disabled && this.styles.tableContainerDisabled,
                    )}
                    key={index}
                    onMouseEnter={this.handleRowHover.bind(this, index)}
                    onMouseLeave={this.handleRowHover.bind(this, false)}
                >
                    <div style={this.styles.tableContent}>
                        <table
                            style={Object.assign(
                                {},
                                this.styles.table,
                                disabled && this.styles.tableDisabled,
                            )}
                            cellPadding="0"
                            cellSpacing="0"
                        >
                            <tbody style={this.styles.tbody}>
                                <tr
                                    style={Object.assign(
                                        {},
                                        this.styles.trow,
                                        oddIndex == 1 && this.styles.trEven,
                                    )}
                                >
                                    <td
                                        style={Object.assign(
                                            {},
                                            this.styles.tdExtraPadding,
                                            { width: "60%" },
                                        )}
                                    >
                                        <div style={this.styles.flexContent}>
                                            {tag.icon && (
                                                <img
                                                    src={tag.icon}
                                                    alt={item.original_title}
                                                    style={
                                                        this.styles.categoryIcon
                                                    }
                                                />
                                            )}

                                            <span
                                                style={this.styles.linkageTitle}
                                                className={`${this.classPrefix}-original-title`}
                                            >
                                                {this.props.links && (
                                                    <a
                                                        style={
                                                            this.styles
                                                                .originalTitleLinkage
                                                        }
                                                        onClick={this.handleSearch.bind(
                                                            this,
                                                            item.meta_id,
                                                        )}
                                                    >
                                                        {item.original_title}
                                                    </a>
                                                )}

                                                {!this.props.links && (
                                                    <span
                                                        style={
                                                            this.styles
                                                                .originalTitleLinkage
                                                        }
                                                    >
                                                        {item.original_title}
                                                    </span>
                                                )}
                                            </span>

                                            <div
                                                style={{
                                                    marginLeft: "auto",
                                                    display: "inline-flex",
                                                }}
                                            >
                                                {this.props.logoBranding && (
                                                    <span
                                                        style={Object.assign(
                                                            {},
                                                            this.styles
                                                                .logoBrandingContainer,
                                                        )}
                                                        className={`${this.classPrefix}-logo-branding`}
                                                    >
                                                        <span
                                                            style={
                                                                this.styles
                                                                    .logoBrandingText
                                                            }
                                                        >
                                                            {this.copy
                                                                .logoBranding ||
                                                                "Logo Branding"}
                                                        </span>

                                                        <Switch
                                                            name="logo_branding"
                                                            disabled={disabled}
                                                            checked={
                                                                item.logo_branding
                                                            }
                                                            handleChange={(
                                                                item,
                                                            ) =>
                                                                this.handleInactiveStateChange(
                                                                    "logo_branding",
                                                                    index,
                                                                    item.checked,
                                                                )
                                                            }
                                                        />
                                                    </span>
                                                )}

                                                <span
                                                    className={`${this.classPrefix}-first-release-year`}
                                                >
                                                    {item.first_release_year}
                                                </span>
                                            </div>
                                        </div>
                                    </td>

                                    <td
                                        style={Object.assign(
                                            {},
                                            this.styles.tdExtraPadding,
                                        )}
                                    >
                                        <div
                                            style={Object.assign(
                                                {},
                                                this.styles.flexContent,
                                                { justifyContent: "flex-end" },
                                            )}
                                        >
                                            <span
                                                style={Object.assign(
                                                    {},
                                                    { marginRight: "auto" },
                                                )}
                                                className={`${this.classPrefix}-meta-id`}
                                            >
                                                {this.props.links && (
                                                    <a
                                                        style={this.styles.link}
                                                        onClick={this.handleSearch.bind(
                                                            this,
                                                            item.meta_id,
                                                        )}
                                                    >
                                                        {item.meta_id}
                                                    </a>
                                                )}

                                                {!this.props.links &&
                                                    item.meta_id}
                                            </span>

                                            {this.props.type == "current" && (
                                                <div>
                                                    <CurrentTag
                                                        copy={this.copy}
                                                    />
                                                </div>
                                            )}

                                            {this.props.type !== "current" && (
                                                <div
                                                    style={Object.assign(
                                                        {},
                                                        {
                                                            marginLeft:
                                                                "1.03125rem",
                                                        },
                                                    )}
                                                >
                                                    <DoubleTags
                                                        category={item.category}
                                                        type={item.type}
                                                    />
                                                </div>
                                            )}

                                            {this.props.type !== "current" &&
                                                !disabled && (
                                                    <div
                                                        style={Object.assign(
                                                            {},
                                                            {
                                                                marginLeft:
                                                                    "1.03125rem",
                                                            },
                                                        )}
                                                    >
                                                        {this.renderDeleteIcon(
                                                            item,
                                                            index,
                                                            "meta-linkage-delete-icon",
                                                        )}
                                                    </div>
                                                )}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        }

        return false;
    }
}
