import _ from "lodash"
import BuilderFilterByCategory from '../../../../RecordBuilder/map/filterCategory'

const transformIDsToDataStructure = ({
    data = [],
    fallback = [],
    category = "",
}) => {
    try {
        return BuilderFilterByCategory({ category, models: data })
            .filter(item => !item.archived && !item.options?.hidden?.enabled)
            .sort((prev, next) => prev.order - next.order)
            .map((item, index) => {
                const {
                    displayName: title = "",
                    key,
                    archived: hide,
                    options = {},
                    type: section,
                    categories = [],
                } = item;

                const {
                    nonEditable,
                } = options;

                return { 
                    key, 
                    title, 
                    section,
                    readOnly: options.validation.readOnly.enabled,
                    hidden: options.hidden.enabled,
                    url: options?.url?.enabled ? options?.url?.value : false,
                    maxCharacter: options.validation.characterLimit.enabled && options.validation.characterLimit.limit,
                    required: options.validation.required.enabled,
                };
            });
    } catch (error) {
        return fallback;
    }
};

export default transformIDsToDataStructure;
