import React from "react";
import DoubleTransparentTag from "../../DoubleTransparentTag";
import moment from "moment";
import NumberFormat from "react-number-format";

const ExpandableRowHeader = (props) => {
    const { item = {} } = props;

    const { platform, onboarding_date, created_at, total_margin } = item;

    const grey = "#838383";
    const lightGrey = "#d9d9d9";

    const RenderDoubleTag = (props) => {
        return (
            <DoubleTransparentTag
                textLeft={props.text}
                textRight={props.value || "-"}
                fontSize="0.78125rem"
                leftBorderColor={grey}
                leftBackgroundColor="transparent"
                leftColor={grey}
                rightBackgroundColor={grey}
                rightColor={lightGrey}
                margin="0 0 0 1rem"
                fontWeight="500"
                className={props.className}
            />
        );
    };

    return (
        <div className="header">
            <span className="title">{platform}</span>

            <div className="tags">
                <RenderDoubleTag
                    text="First Aired"
                    value={moment(onboarding_date).format("DD.MM.YYYY") || "-"}
                    className="first-aired"
                />

                {created_at && (
                    <RenderDoubleTag
                        text="Created"
                        value={
                            moment(created_at).format("DD.MM.YYYY • hh:mm") ||
                            "-"
                        }
                        className="created"
                    />
                )}

                <RenderDoubleTag
                    text="Margin"
                    value={total_margin || "-"}
                    className="total_margin"
                    value={
                        <NumberFormat
                            value={total_margin}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                        />
                    }
                />
            </div>
        </div>
    );
};

export default ExpandableRowHeader;
