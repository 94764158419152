import { Skeleton, Stack } from "@mui/material";

export const ExistingItemsSkeleton = () => {
    const skeleton = <Skeleton animation="wave" height={28} />;

    return (
        <Stack direction="row" sx={{ width: 300 }} spacing={1}>
            {skeleton}
            {skeleton}
            {skeleton}
        </Stack>
    );
};
