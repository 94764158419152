import HandleRecordDuplicationRequest from "./HandleRecordDuplicationRequest";
import React from "react";
import Select from "../../components/Select";
import Attribute from "../../components/Attribute";
import CategoryTags from "../../components/CategoryTags";

const collectionCategories = [
    CategoryTags.Collection.short,
    CategoryTags.ConnectCollection.short,
    CategoryTags.LocalisationOrder.short,
    CategoryTags.Report.short,
];

const allowedCategories = [CategoryTags.Collection];

if (Attribute("#feature", "data-feature-localisation-collections")) {
    allowedCategories.push(CategoryTags.LocalisationOrder);
}

allowedCategories.push(CategoryTags.Report);

const SelectCategory = (props) => {
    const {
        category: defaultCategory,
        handleCategoryChange = () => {},
        connect,
    } = props;

    if (
        connect &&
        !allowedCategories.includes(CategoryTags.ConnectCollection)
    ) {
        allowedCategories.push(CategoryTags.ConnectCollection);
    }

    const categoryData = allowedCategories.map((category) => ({
        text: category.long,
        value: category.short,
    }));

    return (
        <div>
            <Select
                name="selectCollectionCategory"
                id="selectCollectionCategory"
                value={defaultCategory || ""}
                handleChange={handleCategoryChange}
                disabledText={"Select Category"}
                data={categoryData}
                background="#c5c5c5"
                wrapperStyles={{
                    width: "calc(50% - 0.328125rem)",
                    marginRight: "0.328125rem",
                }}
            />
        </div>
    );
};

let selectedCategory;

const HandleRecordDuplication = (props) => {
    const { data, state } = props;

    const { meta_id, category, connect } = data;

    const { set } = state;

    selectedCategory = category;

    const handleCategoryChange = (category) => {
        selectedCategory = category;
    };

    const html = collectionCategories.includes(category) ? (
        <SelectCategory
            category={category}
            handleCategoryChange={handleCategoryChange}
            connect={connect}
        />
    ) : null;
    const descriptionExtend = collectionCategories.includes(category)
        ? "as"
        : "";

    let notification = {
        title: "Duplicate record",
        description: `Are you sure you want to duplicate ${
            meta_id ? meta_id : ""
        } record ${descriptionExtend}`,
        html,
        status: "info",
    };

    if (meta_id) {
        set({
            notification: {
                ...notification,
                confirm: () => {
                    set({
                        notification: {
                            ...notification,
                            loading: true,
                        },
                    });

                    HandleRecordDuplicationRequest({
                        meta_id,
                        category: selectedCategory,
                        state,
                        handleSuccess: () => {
                            set({
                                notification: {
                                    title: "Duplicate record",
                                    description: `Record is duplicating in background`,
                                    status: "success",
                                    confirm: () => {
                                        if (location) location.reload();
                                    },
                                },
                            });
                        },
                        handleError: () => {
                            set({
                                notification: {
                                    title: "Techincal error",
                                    description: `There was a problem duplicating record, please try again.`,
                                    status: "error",
                                },
                            });
                        },
                    });
                },
            },
        });

        return;
    }

    set({
        notification: {
            title: "Technical error",
            description: `There was a problem with finding the record to duplicate, please try again.`,
            status: "info",
        },
    });
};

export default HandleRecordDuplication;
