import React, { useState, useEffect } from "react";
import "./styles/LocalisationLanguageDetailsForm.scss";
import CheckboxTag from "../CheckboxTag";
import Input from "../Input/basic";
import InputCounter from "../Input/basicCounter";
import MaterialDatePicker from "../Input/MaterialDatePicker";
import Label from "../Input/label";
import Select from "../Select";
import deepycopy from "deepcopy";
import moment from "moment";
import SendRequest from "../SendRequest";

const LocalisationLanguageDetailsForm = ({
    props = {},
    state = {},
    data = {},
    onUpdate = () => {},
    validationsKeys = [],
    cmsData = {},
    meta_id,
}) => {
    const {
        options: {
            localisations: {
                types = [],
                graphics = [],
                songs = [],
                accessibilities = [],
                studios = [],
                compliances = [],
                scripts = [],
                screeners = [],
            },
        },
    } = state;

    const { apiUrls = {}, clientFeatures = {}, clientDataStructure = {} } = cmsData;

    const { complianceOptionsUrl = "" } = apiUrls;

    const { type = [] } = data;

    const { localisations_order = {} } = clientFeatures;

    const { complianceOptionsApi = "" } = localisations_order;

    const { localisation = {} } = clientDataStructure;

    const { components = {} } = localisation;

    const updateField = (key, input) => {
        const newData = deepycopy(data);
        newData[key] = input;

        onUpdate({
            newData,
            key,
        });
    };

    const updateType = (input) => {
        const newType = deepycopy(type);
        const index = type.indexOf(input);
        if (index < 0) {
            newType.push(input);
        } else {
            newType.splice(index, 1);
        }
        updateField("type", newType);
    };

    const todaysDate = moment().format(clientDataStructure.date_format);

    const [complianceOptions, setComplianceOptions] = useState([]);
    const [complianceOptionsFetching, setComplianceOptionsFetching] =
        useState(false);

    useEffect(() => {
        if (complianceOptionsApi) {
            setComplianceOptionsFetching(true);

            const options = {
                url: complianceOptionsUrl.replace("{meta_id}", meta_id),
                method: "GET",
            };

            SendRequest(
                options,
                (response) => {
                    setComplianceOptionsFetching(false);
                    setComplianceOptions(response.data);
                },
                (e) => {
                    console.log(e);
                    setComplianceOptionsFetching(false);
                },
            );
        }
    }, []);

    return (
        <div className="meta-localisation-lang-details">
            <div className="flex">
                <div>
                    <Input
                        label="Language User Notification"
                        placeholder="Enter User Notification"
                        id="localisation-lang-user-notification"
                        name="localisation-lang-user-notification"
                        value={data.user_notification}
                        recordBasic={true}
                        required={true}
                        handleChange={(value) =>
                            updateField("user_notification", value)
                        }
                    />
                </div>
                <div>
                    <MaterialDatePicker
                        labelLegacy="First Use Date - Required"
                        placeholder="Select a Date"
                        id="localisation-lang-first-use-date"
                        name="localisation-lang-first-use-date"
                        defaultValue={data.first_use_date}
                        className="datepicker-full-width datepicker-small"
                        minDate={todaysDate}
                        handleChange={(value) =>
                            updateField("first_use_date", value)
                        }
                    />
                </div>
                <div>
                    <Input
                        label="Turnaround Time"
                        placeholder="Enter turnaround time"
                        id="localisation-lang-turnaround-time"
                        name="localisation-lang-turnaround-time"
                        value={data.turnaround_time}
                        recordBasic={true}
                        required={true}
                        handleChange={(value) =>
                            updateField("turnaround_time", value)
                        }
                    />
                </div>
            </div>
            <div>
                <Label required={true} label="Localisation Order Types" />

                <div className="options flex">
                    {types.map((optionType) => (
                        <CheckboxTag
                            key={`localisations-lang-order-type-${optionType.value}`}
                            className={`localisations-lang-order-type-${optionType.value}`}
                            selected={type.includes(optionType.value)}
                            leftText={optionType.text}
                            background="#28a38d"
                            styles={{
                                justifyContent: "space-between",
                            }}
                            handleSelection={() => updateType(optionType.value)}
                        />
                    ))}
                </div>
            </div>
            <div className="grid">
                <Select
                    name="localisation-lang-studio"
                    id="localisation-lang-studio"
                    value={data.studio || null}
                    clear={true}
                    label="Localisation Studio"
                    disabledText="Select Studio"
                    noneSelectedText="None Selected"
                    data={studios}
                    width="auto"
                    required={true}
                    handleChange={(value) => updateField("studio", value)}
                />
                {components["localisation-lang-compliance"] && (
                    <Select
                        name="localisation-lang-compliance"
                        id="localisation-lang-compliance"
                        value={data.compliance || null}
                        clear={true}
                        label={components["localisation-lang-compliance"].label}
                        disabledText={
                            components["localisation-lang-compliance"]
                                .disabledText
                        }
                        noneSelectedText="None Selected"
                        data={
                            complianceOptionsApi
                                ? complianceOptions
                                : compliances
                        }
                        loading={complianceOptionsFetching}
                        disabled={complianceOptionsFetching}
                        width="auto"
                        required={true}
                        handleChange={(value) =>
                            updateField("compliance", value)
                        }
                    />
                )}

                <Select
                    name="localisation-lang-scripts"
                    id="localisation-lang-scripts"
                    value={data.scripts || null}
                    clear={true}
                    label="Scripts"
                    disabledText="Select"
                    noneSelectedText="None Selected"
                    data={scripts}
                    width="auto"
                    showNullOption={false}
                    handleChange={(value) => updateField("scripts", value)}
                />
                <Select
                    name="localisation-lang-screeners"
                    id="localisation-lang-screeners"
                    value={data.screeners || null}
                    clear={true}
                    label="Screeners"
                    disabledText="Select"
                    noneSelectedText="None Selected"
                    data={screeners}
                    width="auto"
                    showNullOption={false}
                    handleChange={(value) => updateField("screeners", value)}
                />
            </div>
            <div className="flex">
                <div>
                    <InputCounter
                        label="Studio Notes"
                        placeholder="Enter Studio Notes"
                        id="localisation-lang-studio-notes"
                        name="localisation-lang-studio-notes"
                        value={data.language_notes}
                        recordBasic={true}
                        counter={true}
                        maxCharacter={200}
                        required={data.studio == "Other Studio"}
                        handleChange={(value) =>
                            updateField("language_notes", value)
                        }
                    />
                </div>
                <div>
                    <Input
                        label="Script & Screener Approval Email"
                        placeholder="Enter Email Address"
                        id="localisation-lang-approveer-email"
                        name="localisation-lang-approveer-email"
                        value={data.approver_email}
                        recordBasic={true}
                        handleChange={(value) =>
                            updateField("approver_email", value)
                        }
                    />
                </div>
            </div>
            <div>
                <label>Graphic Localisation</label>
                <div className="options grid">
                    {graphics.map((attr) => (
                        <CheckboxTag
                            key={`localisations-lang-graphics-${attr.value}`}
                            className={`localisations-lang-graphics-${attr.value}`}
                            selected={data[attr.value]}
                            leftText={attr.text}
                            background="#28a38d"
                            styles={{
                                justifyContent: "space-between",
                            }}
                            handleSelection={() =>
                                updateField(attr.value, !data[attr.value])
                            }
                        />
                    ))}
                </div>
            </div>
            <div>
                <label>Songs</label>
                <div className="options grid">
                    {songs.map((attr) => (
                        <CheckboxTag
                            key={`localisations-lang-songs-${attr.value}`}
                            className={`localisations-lang-songs-${attr.value}`}
                            selected={data[attr.value]}
                            leftText={attr.text}
                            background="#28a38d"
                            styles={{
                                justifyContent: "space-between",
                            }}
                            handleSelection={() =>
                                updateField(attr.value, !data[attr.value])
                            }
                        />
                    ))}
                </div>
            </div>
            <div>
                <label>Accessibility</label>
                <div className="options grid">
                    {accessibilities.map((attr) => (
                        <CheckboxTag
                            key={`localisations-lang-accessibilities-${attr.value}`}
                            className={`localisations-lang-accessibilities-${attr.value}`}
                            selected={data[attr.value]}
                            leftText={attr.text}
                            background="#28a38d"
                            styles={{
                                justifyContent: "space-between",
                            }}
                            handleSelection={() =>
                                updateField(attr.value, !data[attr.value])
                            }
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LocalisationLanguageDetailsForm;
