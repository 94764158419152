import { GetFromLocalStorage } from "../Storage/localStorage";
import { CLIENT_MANAGED_COUNTRIES } from "./options";
import clientManagedController from "./clientManagedController";

export const WORLDWIDE = "Worldwide";

export function isClientManagedCountries(props) {
    const { clientFeatures = {} } = props;

    const { managedLists = [] } = clientFeatures;

    if (managedLists.includes("countries")) {
        return true;
    }

    return false;
}

export function formatClientManagedCountries(client_managed_countries) {
    if (!Array.isArray(client_managed_countries)) return [];

    return client_managed_countries.map((row) => {
        return { text: row.name, value: row.iso_code, order: row.order };
    });
}

export default function ClientManagedCountries(props) {
    const { options = {}, fallback = [], clientFeatures = {} } = props;

    const clientManaged = isClientManagedCountries({ clientFeatures });
    const local = GetFromLocalStorage(CLIENT_MANAGED_COUNTRIES);

    let countries = clientManagedController({
        clientManagedOptions: options.client_managed_countries || [],
        fallback,
        localStorage: JSON.parse(local),
        isClientManaged: clientManaged,
    });

    if (clientManaged) {
        return formatClientManagedCountries(countries);
    }

    return countries;
}
