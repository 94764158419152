import React from "react";
import Settings from "../Settings";
import "./styles/_styles.scss";

const FullScreenLoadingSpinner = (props) => {
    return (
        <div className="fullscreen-loading-spinner">
            <div>
                <img src={Settings.images.loadingWhite} />
            </div>
        </div>
    );
};

export default FullScreenLoadingSpinner;
