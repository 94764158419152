export const Role_SuperAdmin_Value = "super_admin";

export default function DefaultRoles() {
    const Role_SuperAdmin = "super-admin";
    const Role_PlatformAdmin = "platform-admin";
    const Role_Admin = "admin";
    const Role_Viewer = "viewer";
    const Role_LocalisationManager = "localisation-manager";
    const Role_LanguageManager = "language-manager";
    const Role_Okta_User = "okta-user";
    const Role_RegionalEditor = "regional-editor";
    const Role_RecordAdmin = "record_admin";

    return {
        Role_SuperAdmin,
        Role_PlatformAdmin,
        Role_Admin,
        Role_Viewer,
        Role_LocalisationManager,
        Role_LanguageManager,
        Role_Okta_User,
        Role_RegionalEditor,
        Role_RecordAdmin,
    };
}
