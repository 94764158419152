import { Typography } from "@mui/material";

type CharacterCounterProps = {
    value?: number;
    maxValue: number;
};

const CharacterCounterChip = ({
    value = 0,
    maxValue,
}: CharacterCounterProps) => {
    return (
        <>
            {maxValue && maxValue > 0 && 
                <Typography
                    component="span"
                    sx={{
                        borderRadius: "0.3rem",
                        fontSize: "0.78125rem",
                        padding: "0.11875rem 0.25rem",
                        lineHeight: 1.3,
                        backgroundColor:
                            value > maxValue
                                ? "rgb(235, 72, 62)"
                                : "rgb(215, 215, 215)",
                        color:
                            value > maxValue
                                ? "rgba(250, 250, 250, 0.98)"
                                : "rgb(135, 145, 142)",
                        marginLeft: "0.46875rem",
                        alignSelf: "baseline",
                    }}
                >
                    {value}
                </Typography>
            }
        </>
    );
};

export default CharacterCounterChip;
