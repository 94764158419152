import CategoryTags from '../../../../components/CategoryTags'

export const transformIncomeCategories = (categories: string[]): string[] => {
    const categoriesMap = {
        ConstructedEpisode: "Constructed Episode",
        ConnectCollection: "Connect Collection",
        LocalisationOrder: "Localisation Order",
        FranchiseCollection: "Franchise Collection",
    };

    return categories.map((category: string) => {
            const categoryKey = categoriesMap[category] || category
            let name = categoryKey
            Object.keys(CategoryTags).forEach((key) => {
                if (CategoryTags[key].recordBuilderKey == categoryKey) {
                    name = CategoryTags[key].short
                }
            });

            return name
        }
    );
}

export const transformOutgoingCategories = (categories: string[]): string[] => {
    return categories.map(category => {
        let name = category
        Object.keys(CategoryTags).forEach((key) => {
            if (CategoryTags[key].short == category) {
                name = CategoryTags[key].recordBuilderKey
            }
        });

        return name.replace(/\s+/g, "")
    })
}