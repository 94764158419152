import React, { ReactNode } from "react";
import Fade from "@mui/material/Fade";
import "./styles/_styles.scss";

type Props = {
    children?: ReactNode;
    loading?: boolean;
    timeout?: number;
};

const FadeMaterial: React.FC<Props> = (props) => {
    const { children = <></>, loading = true, timeout = 300 } = props;

    return (
        <Fade
            in={loading}
            timeout={timeout}
            className={`fabric-fade--material ${loading ? "loading" : ""}`}
        >
            <div>{children}</div>
        </Fade>
    );
};

export default FadeMaterial;
