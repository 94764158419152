const data = {
    hierarchy: [
        {
            id: 1,
            capacity: 1,
            value: "Enterprise Genre",
            children: [
                {
                    id: 2,
                    capacity: 2,
                    value: "Mega Genre",
                    children: [
                        {
                            id: 3,
                            capacity: null,
                            value: "History",
                            children: [
                                {
                                    id: 4,
                                    capacity: null,
                                    value: "Sub History",
                                    children: [],
                                },
                            ],
                        },
                        {
                            id: 5,
                            capacity: null,
                            value: "Drama",
                            children: [
                                {
                                    id: 17,
                                    capacity: null,
                                    value: "Sub Drama 1",
                                    children: [],
                                },
                                {
                                    id: 18,
                                    capacity: null,
                                    value: "Sub Drama 2",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
};

export default data;
