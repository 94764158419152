import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { template } from "lodash";
import { RenderCellWithChipAndLink } from "../../../../features/TaskManager/containers/record/shared/CollectionChipCell";
import { FabricEndpoints } from "../../../../features/TaskManager/services/api.config";
import { CoreTableGridColDef } from "../../types";
import { StandaloneRecordType } from "../types/StandaloneRecord.type";

type Params = GridRenderCellParams<
    StandaloneRecordType["collection"]["title"],
    StandaloneRecordType
>;

export const CollectionColumn: CoreTableGridColDef = {
    type: "string",
    headerName: "Collection",
    field: "collection",
    width: 200,

    valueGetter: (params: Params) => {
        return params.row.collection?.title || "-";
    },

    renderCell: (params: Params) => {
        const { row } = params;

        const props = {
            ...params,
            linkUrl: row.collection?.metaId
                ? template(FabricEndpoints.COLLECTIONS_BY_ID)({
                      collectionId: row.collection?.metaId,
                  })
                : null,
        };

        return <RenderCellWithChipAndLink {...props} />;
    },
};
