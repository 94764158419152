import React from "react";
import "./styles/_styles.scss";

const InputLabel = (props) => {
    const {
        label,
        text,
        styles,
        containerId,
        margin,
        tag,
        lighPrefix,
        lightSufix,
        light,
        lineClamp,
        customTextStyles = {},
        customLabelStyles = {},
    } = props;

    const textStyles = Object.assign(
        {},
        margin && { margin },
        light && { color: "rgba(152, 152, 152, 0.87)" },
        customTextStyles && customTextStyles,
        lineClamp && {
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: lineClamp,
            WebkitBoxOrient: "vertical",
        },
    );

    return (
        <div style={Object.assign({}, styles && styles)} id={containerId}>
            {label && (
                <label
                    className="meta-input-label--label"
                    style={customLabelStyles}
                >
                    {label}
                </label>
            )}

            {text &&
                <p
                    className={`meta-input-label--text ${lineClamp}`}
                    style={textStyles}
                >
                    {tag ? (
                        <span className="meta-input-label--tag">{tag}</span>
                    ) : (
                        ""
                    )}
                    {lighPrefix ? (
                        <span className="meta-input-label--light-text">
                            {lighPrefix}
                        </span>
                    ) : (
                        ""
                    )}

                    {text}

                    {lightSufix ? (
                        <span className="meta-input-label--light-text sufix">
                            {lightSufix}
                        </span>
                    ) : (
                        ""
                    )}
                </p>
            }
        </div>
    );
};

export default InputLabel;
