const data = [
    {
        Movie: "Star Wars: The Rise of Skywalker",
        Platform: "YouTube",
        "Video Type": "Trailer",
        "Time Range": "1 Day",
        "Number of Assets": 9,
        "iMDB ID": "tt2527338",
        "Gracenote ID": "MV010023510000",
        "Estimated Budget": "",
        "Release Date": "12/20/19",
        Distributors: "Walt Disney",
        "Canvs Link":
            "https://app.canvs.ai/movies/youtube/59c5a8fb3e381deb0a053f56_181812_youtube/",
        "Start Date": "Apr 12, 2019 5:11:25 PM",
        "End Date": "Apr 13, 2019 6:41:24 PM",
        "Date Updated (Coordinated Universal Time)": "Nov 8, 2019 5:13:56 AM",
        "Total Engagement": 106897,
        Reactions: 21599,
        "Emotional Reactions": 4108,
        "Comments/Replies": 9612,
        "Reaction Rate": "41%",
        "Emotional Tag Rate": "",
        "Reaction Replies": "18%",
        keywords: {
            Love: 418,
            Crazy: 271,
            Enjoy: 814,
            Excited: 450,
            Funny: 196,
            Hate: 323,
            Beautiful: 11,
            Happy: 78,
            Congrats: 13,
            Afraid: 11,
            Dislike: 418,
            Sad: 49,
            Angry: 78,
            Annoying: 64,
            Disappointed: 39,
            Boring: 90,
            Cried: 19,
            Unsure: 76,
            Idiot: 102,
            Awkward: 5,
            Brilliant: 9,
            Brutal: 1,
            Weird: 8,
            Interesting: 27,
            Ugly: 4,
            Badass: 5,
            "Looks Good": 0,
            Sentimental: 2,
            Nervous: 1,
            Disgusting: 12,
            Jealous: 0,
            Lucky: 0,
            Worried: 2,
            Thrilling: 1,
            Embarrassing: 9,
            Fake: 2,
            Goosebumps: 8,
            Supportive: 13,
            "Not Funny": 1,
            Fml: 2,
            "Not Scary": 0,
            Rage: 0,
            Mixed: 476,
        },
        FB_Likes: "",
        FB_Anger: "",
        FB_Haha: "",
        FB_Love: "",
        FB_Wow: "",
        FB_Sorry: "",
        FB_Shares: "",
        YT_Likes: 71675,
        YT_Dislikes: 17599,
        YT_Views: 8008569,
    },
    {
        Movie: "Star Wars: The Rise of Skywalker",
        Platform: "Facebook",
        "Video Type": "Trailer",
        "Time Range": "1 Day",
        "Number of Assets": 1,
        "iMDB ID": "tt2527338",
        "Gracenote ID": "MV010023510000",
        "Estimated Budget": "",
        "Release Date": "12/20/19",
        Distributors: "Walt Disney",
        "Canvs Link":
            "https://app.canvs.ai/movies/facebook/59c5a8fb3e381deb0a053f56_181812_facebook/",
        "Start Date": "Apr 12, 2019 5:31:00 PM",
        "End Date": "Apr 12, 2019 5:31:00 PM",
        "Date Updated (Coordinated Universal Time)": "Nov 8, 2019 5:02:58 AM",
        "Total Engagement": 48218,
        Reactions: 11514,
        "Emotional Reactions": 1041,
        "Comments/Replies": 3764,
        "Reaction Rate": "27%",
        "Emotional Tag Rate": 0.49183476,
        "Reaction Replies": "49%",
        keywords: {
            Love: 220,
            Crazy: 124,
            Enjoy: 164,
            Excited: 227,
            Funny: 53,
            Hate: 25,
            Beautiful: 4,
            Happy: 10,
            Congrats: 1,
            Afraid: 2,
            Dislike: 59,
            Sad: 4,
            Angry: 2,
            Annoying: 2,
            Disappointed: 5,
            Boring: 6,
            Cried: 8,
            Unsure: 19,
            Idiot: 6,
            Awkward: 0,
            Brilliant: 2,
            Brutal: 0,
            Weird: 1,
            Interesting: 11,
            Ugly: 0,
            Badass: 0,
            "Looks Good": 0,
            Sentimental: 0,
            Nervous: 0,
            Disgusting: 3,
            Jealous: 0,
            Lucky: 0,
            Worried: 0,
            Thrilling: 0,
            Embarrassing: 10,
            Fake: 1,
            Goosebumps: 4,
            Supportive: 5,
            "Not Funny": 0,
            Fml: 1,
            "Not Scary": 0,
            Rage: 0,
            Mixed: 62,
        },
        FB_Likes: 19198,
        FB_Anger: 27,
        FB_Haha: 70,
        FB_Love: 6765,
        FB_Wow: 1758,
        FB_Sorry: 29,
        FB_Shares: 16212,
        YT_Likes: "",
        YT_Dislikes: "",
        YT_Views: "",
    },
];

export default data;
