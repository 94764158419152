import { StandaloneTicket } from "../containers/ticket/shared/validationSchema";
import { AccountStatus } from "../types/constants";

const UnassignedUser: StandaloneTicket["assignee"] = {
    id: null,
    name: "Unassigned",
    email: "___Unassigned___@fabricdata.com",
    accountStatus: AccountStatus.VERIFIED,
};

export default UnassignedUser;
