export default `messages {
    editWarning {
        enabled
        code
    }
    archiveWarning {
        enabled
        code
    }
}`;
