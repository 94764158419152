
import { AwardInterface, AwardsDataTablePayload } from '../../interfaces/awards.interfaces'

export default function deleteAwards(
    selectedAwards: AwardsDataTablePayload[],
    awards: AwardInterface[],
): AwardInterface[] {
    const updatedAwards = awards.map((award) => {
        const isSelected: number = 
            selectedAwards.findIndex(
                (awardData) =>
                    awardData.localId === award.local_id
            );

        if (isSelected !== -1) {
            award.deleted = true;
            award.order = 9999;
        }

        return award;
    });

    const deletedAwards: AwardInterface[] = updatedAwards.filter((award) => award.deleted);
    const notDeletedAwards: AwardInterface[] = updatedAwards.filter((award) => !award.deleted);

    return notDeletedAwards.concat(deletedAwards);
}
