import React from "react";
import "./styles/_styles.scss"
import DoubleTags from "../DoubleTags";
import CategoryTags from "../CategoryTags";
import Tag from "../Tag";
import ClientFeatureContainer from '../../containers/ClientFeature'
import { ProductionNumberChipLinkage } from "../Chip/ProductionNumberChip";
import { isEpisode } from "../CategoryTags/EpisodeCategories";

const SeasonAndEpisodeNumber = (props) => {
    const {
        season_number,
        category,
        episode_number,
        storyline_number,
        production_number,
        seasonClassName = "",
        episodeClassName = "",
    } = props;

    const episode = episode_number || storyline_number;

    return (
        <div className="season-episode-number--container">
            {Number.isInteger(season_number) &&
                category !== CategoryTags.Season.short && (
                    <DoubleTags
                        leftText="Season"
                        rightText={parseInt(season_number)}
                        rightTextTransparent={true}
                        className={seasonClassName}
                    />
                )}

            {Number.isInteger(episode) && (
                <Tag
                    text={parseInt(episode)}
                    className={episodeClassName}
                    background="transparent"
                    color="#9a9a9a"
                    styles={{
                        padding: "0.1369rem 0.3rem",
                        border: "0.0625rem solid #9a9a9a",
                    }}
                />
            )}

            {isEpisode(category) && production_number &&
                <ClientFeatureContainer featureName="episodeProductionNumber">
                    <ProductionNumberChipLinkage label={production_number}/>
                </ClientFeatureContainer>
            }
        </div>
    );
};

export default SeasonAndEpisodeNumber;
