import { ThemeProvider } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { FormProvider } from "react-hook-form";
import { theme } from "../theme/CoreForm.theme";

export const CoreForm = styled("form")(() => ({}));

export const CoreFormProvider = FormProvider;

export const CoreFormThemeProvider = ({
    children,
}: React.PropsWithChildren) => (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
);
