import React from "react";
import {
    Box,
    Chip,
    Link,
    LinkProps,
    Stack,
    Typography,
} from "@mui/material";
import _ from "lodash";
import { COLOR_CELL_CONTENT } from "../constants";
import { useCopyToClipboard } from "../hooks/useCopyToClipboard";
import CopyTooltip from "../../../components/CopyTooltip";

export type CoreTableCellProps = {
    children: React.ReactNode;
    boldText?: boolean;
    enableCopyToClipboard?: boolean;
    linkProps?: LinkProps;
    yellowChip?: boolean;
    leftAdornment?: React.ReactElement | null;
    underlineButtonProps?: {
        onClick: React.MouseEventHandler<HTMLAnchorElement> | undefined;
        color?: string;
    };
    uppercase?: boolean
    renderChildren?: boolean,
    color?: string,
    className?: string
};

export const CoreTableCell = ({
    children,
    boldText,
    enableCopyToClipboard,
    linkProps,
    yellowChip,
    leftAdornment,
    underlineButtonProps,
    uppercase,
    renderChildren,
    color,
    className = ''
}: CoreTableCellProps) => {
    const [valueInClipboard, copyFn] = useCopyToClipboard();
    let Elem = children;
    const stringValue = children;
    // const stringValue = typeof children === "string" ? children : "";

    if (_.isEmpty(children)) {
        Elem = "-";
    }

    Elem = (
        <Typography
            sx={{
                color: boldText ? "#5F5F5F" : COLOR_CELL_CONTENT,
                border: "0",
                padding: "0",
                fontSize: "0.875rem",
                lineHeight: "1.138",
                textTransform: uppercase ? 'uppercase' : 'initial'
            }}
            className={className}
        >
            <span dangerouslySetInnerHTML={{ __html: children as string }}></span>

        </Typography>
    );


    if (linkProps) {
        let underlineColor;        

        if (yellowChip) {
            underlineColor = "#984E08";
        }

        const linkColor = color || 'inherit';

        Elem = (
            <Link
                underline="none"
                sx={{
                    color: yellowChip ? underlineColor : linkColor,
                    cursor: "pointer",
                    ".MuiLink-underlineHover": {
                        color: underlineColor,
                    }, 
                    "&.MuiLink-root": {
                        "&:hover, &:focus": {
                            color: color || 'inherit',
                        },
                    }                   
                }}
                {...linkProps}
            >
                {children}
            </Link>
        );
    }

    if (underlineButtonProps) {
        Elem = (
            <Box
                component="a"
                sx={{
                    "&:hover": {
                        textDecoration: "underline",
                        cursor: "pointer",
                        color: underlineButtonProps?.color,
                    },
                }}
                onClick={underlineButtonProps?.onClick}
                className="fabric--contributor-name"
            >
                {Elem}
            </Box>
        );
    }

    if (yellowChip && !_.isEmpty(children)) {       
        Elem = (
            <Chip
                sx={{
                    color: "#984E08",
                    backgroundColor: "#FAEC90",
                    "&:hover": {
                        textDecoration: "underline",
                        cursor: "pointer",
                    },
                }}
                label={Elem} 
            />
        );
    } 

    if (yellowChip &&  _.isEmpty(children)) {
        Elem = <Typography textAlign='center' width="100%">
            -
        </Typography>
    }

    if (enableCopyToClipboard && !_.isEmpty(stringValue)) {
        Elem = (
            <CopyTooltip text={extractTextFromHTML(stringValue)}>
                {Elem}
            </CopyTooltip>
        );
    }

    if (renderChildren && !_.isEmpty(children)) {     
        Elem = children;
    } 

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            {leftAdornment}
            {Elem}
        </Stack>
    );
};

function extractTextFromHTML(htmlString: string): string {
  const div = document.createElement("div");
  div.innerHTML = htmlString;
  return div.textContent || div.innerText || "";
}

CoreTableCell.defaultProps = {
    boldText: false,
    enableCopyToClipboard: false,
    linkProps: null,
    yellowChip: false,
    leftAdornment: null,
    underlineButtonProps: null,
};
