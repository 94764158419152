import _ from "lodash";

const MapCertificateRegionLists = (props) => {
    const {
        regionOptions = {},
        countryCode = "",
        system = "",
        key = "advisories",
    } = props;

    if (_.isEmpty(regionOptions)) {
        return null;
    }

    let result = null;

    Object.keys(regionOptions).map((item) => {
        const systems = regionOptions[item];

        if (typeof systems == "object") {
            const regionSystem = Object.keys(systems).find(
                (item) =>
                    countryCode == systems[item].region_code && system == item,
            );

            if (regionSystem && key == "advisories") {
                result = systems[regionSystem].advisories || null;
            }

            if (regionSystem && key == "schedule_restrictions") {
                result = systems[regionSystem].schedule_restrictions || null;
            }
        }
    });

    return result;
};

export default MapCertificateRegionLists;
