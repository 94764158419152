import { useEffect, useState, useRef } from 'react';

export function useVisibleNames(contributors) {
    const [visibleNames, setVisibleNames] = useState([]);
    const [hiddenNames, setHiddenNames] = useState([]);
    const contributorWrapperRef = useRef(null);
    
    useEffect(() => {
        const handleVisibleRow = (contributors) => {
            if(contributorWrapperRef.current) {
                const width = contributorWrapperRef.current.offsetWidth;
                let index = 0;
                let currentWidth = 0;
                for (let i = 0; i < contributors.length; i++) {
                    const name = contributors[i];
                    const span = document.createElement('span');
                    span.innerText = name;
                    document.body.appendChild(span);
                    const style = getComputedStyle(span);
                    const nameWidth = span.offsetWidth + parseFloat(style.marginLeft) + parseFloat(style.marginRight);
                    span.remove();
                    if (currentWidth + nameWidth < width) {
                        currentWidth += nameWidth;
                        index++;
                    } else {
                        break;
                    }
                }
                setVisibleNames(contributors.slice(0, index));
                setHiddenNames(contributors.slice(index));
            }
        }
        if (typeof window !== 'undefined') {
          handleVisibleRow(contributors);
        }
    }, [contributors]);

    return { visibleNames, hiddenNames, contributorWrapperRef };
}
