import React, { Fragment } from "react";
import SwitchFilters from "../SwitchFilters";
import _ from "lodash";
const RecordConverter = (props) => {
    const {
        configuration = {},
        sourceRecordData = {},
        handleChange = () => {},
    } = props;

    const { inheritFields = [] } = configuration;

    const defaultFiltersList = inheritFields.map((item) => {
        return {
            checked: false,
            disabled: _.isEmpty(_.get(sourceRecordData, item.key)),
            name: item.title,
        };
    });

    const CheckFilters = (filtersHandled) => {
        if (Array.isArray(filtersHandled) && filtersHandled.length > 0) {
            handleChange(
                filtersHandled[0]
                    .filter((item) => item.checked)
                    .map((item) => {
                        item.key = inheritFields.find(
                            (i) => i.title == item.name,
                        ).key;
                        return item;
                    }),
            );
        }
    };

    return (
        <div className={"record-category-convert"}>
            <SwitchFilters
                data={[defaultFiltersList]}
                sliderClass="react-slider--orange"
                style={{
                    margin: "1.6875rem auto 0.5625rem auto",
                    width: "17.375rem",
                }}
                handleChange={(data) => CheckFilters(data)}
            />
        </div>
    );
};

export default RecordConverter;
