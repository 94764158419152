import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export async function fetchApi<T>(
    url: string,
    options?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> {
    try {
        const response = await axios<T>(url, {
            ...options,
            ...{
                withCredentials: false,
            },
        });
        return response;
    } catch (error) {
        console.log(error); // TODO: handle error
        throw error;
    }
}
