import deepcopy from "deepcopy";
import { ValidationResult } from "../interfaces/Validation";
import ConfigModel from '../../../models/RecordBuilder/Field.interface'
import _ from "lodash"
import BuilderFilterByCategory from '../../RecordBuilder/map/filterCategory'
import CoreDynamicFormFieldsMapper from "../../RecordBuilder/map/dynamicCoreForm/coreDynamicForm";
import ValidationSchemaBuilder from "../../../core/Form/dynamic/validation/SchemaBuilder";
import DefaultValues from "../../../core/Form/dynamic/transformers/DefaultValues.transformer";

export default function ValidateNumbering(
    result: ValidationResult,
    options: any,
): ValidationResult {
    let response: ValidationResult = deepcopy(result);
    const {
        data = {},
        builderConfig = {},
        status,
    } = options;

    const { category } = data;

    const configuration: ConfigModel[] = BuilderFilterByCategory({
        category,
        models: _.get(builderConfig, 'numbering', [])
    }).filter(config => !config.archived)

    const dynamicFormFields = CoreDynamicFormFieldsMapper({ disabled: false, category, configuration })
    const zodSchema = ValidationSchemaBuilder({ fields: dynamicFormFields })
    const values = DefaultValues({ 
        data: data.numbering,
        fields: dynamicFormFields, 
        useNullValues: true,
    })

    const parse = zodSchema.safeParse(values)

    const assignAlert = (key) => {
        response.result = false;
        response.notification = {
            title: `Validation Alert`,
            description: "Oops! It seems there are some issues in Alternative Numbering section that need your attention before saving your changes.",
            maxWidth: "40rem",
            status,
        }
        response.validationKeys = result.validationKeys.concat([key])
    }

    if (!parse.success) {
        assignAlert('numbering')
    }

    return response;
}