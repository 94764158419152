import BubbleListSimpleOnEnter from "./simpleOnEnter";

export default class BubbleListSimpleOnEnterSingle extends BubbleListSimpleOnEnter {
    renderData(styles = {}) {
        return super.renderData({
            margin: "0",
            paddingTop: "0.4375rem",
            paddingBottom: "0.4375rem",
            ...this.props.styles
        });
    }

    renderInput(style) {
        const { data } = this.state;

        if (data.length > 0) {
            return false;
        }

        return super.renderInput(style);
    }
}
