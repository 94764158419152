import _ from "lodash";
import { z } from "zod";
import {
    categoriesValidation,
    keyValidation,
    limitsValidation,
    optionsValidation,
    readOnlyValidation,
    includeInSNSValidation,
    requiredValidation,
    selectionsIntervalValidation,
    uniqueValidation,
    featureIntegrationValidation,
} from "../../../shared/validation/base.validation";
import { booleanOptions } from "../data/boolean.options";
import { DateOptionFormat, InputType, MultiSelectType, SelectType, TimeOptionFormat } from "../../../../../../../../models/RecordBuilder/Field.interface";
import OriginationViewType from "../../../../../interfaces/OriginationView.type";

export const booleanTileValidation = z.object({
    boolean: z.object({
        type: z.string().optional(),
        value1: z.string().optional(), // .min(1, { message: "Please enter a Value name" }),
        value2: z.string().optional(), // .min(1, { message: "Please enter a Value name" }),
    }),
});

export const dataSetTileValidation = z.object({
    optionsManagedList: z.object({
        value: z.any().optional(),
        newOption: z.string().optional(),
        dynamic: z.boolean().optional().default(true),
        color: z.string().optional(),
        growing: z.boolean().optional().default(false),
    }),
});

export const dateTimeTileValidation = z.object({
    dateTime: z.object({
        format: z.string().optional(),
        dateFormat: z.string().optional().default(DateOptionFormat.MMDDYYYY),
        timeFormat: z.string().optional().default(TimeOptionFormat.HHMMSS),
    }),
});

export const MAX_CHARACTER_COUNT = 180;
export const MAX_TILE_NAME_LENGTH = 50;

export const MINIMUM_SELECTIONS_NUMBER = 1;
export const MAXIMUM_SELECTIONS_NUMBER = 20;

export const tileVS = z
    .object({
        name: z
            .string()
            .min(1, { message: "Please provide Tile Name" })
            .max(50, {
                message: `Tile Name has to be maximum ${MAX_TILE_NAME_LENGTH} characters`,
            }),
        dataType: z.string().optional(),
        type: z.string(),
        isEnabled: z.boolean().optional(),
        view: z.string().optional(),
    })
    .merge(limitsValidation)
    .merge(requiredValidation)
    .merge(readOnlyValidation)
    .merge(includeInSNSValidation)
    .merge(uniqueValidation)
    .merge(featureIntegrationValidation)
    .merge(categoriesValidation)
    .merge(keyValidation)
    .merge(booleanTileValidation)
    .merge(dataSetTileValidation)
    .merge(selectionsIntervalValidation)
    .merge(optionsValidation)
    .merge(dateTimeTileValidation)
    .superRefine(
        (
            { type, boolean, limits, optionsManagedList, selectionsInterval, view },
            ctx,
        ) => {
            if (Array.isArray(optionsManagedList.value)) {
                optionsManagedList.value = "";
            }

            if (type === InputType.Boolean && _.isEmpty(boolean?.type)) {
                ctx.addIssue({
                    message: "Please select Boolean Type",
                    code: z.ZodIssueCode.custom,
                    path: ["boolean.type"],
                });
            }

            if (
                type === InputType.Boolean &&
                boolean.type !== booleanOptions[0].value &&
                _.isEmpty(boolean?.value1)
            ) {
                ctx.addIssue({
                    message: "Please enter a Value name",
                    code: z.ZodIssueCode.custom,
                    path: ["boolean.value1"],
                });
            }

            if (
                type === InputType.Boolean &&
                _.isEmpty(boolean?.value2) &&
                boolean.type !== booleanOptions[0].value
            ) {
                ctx.addIssue({
                    message: "Please enter a Value name",
                    code: z.ZodIssueCode.custom,
                    path: ["boolean.value2"],
                });
            }

            if (
                type === InputType.Text 
                && view === OriginationViewType.ThreeWay
                && Number(limits?.value) > MAX_CHARACTER_COUNT
            ) {
                ctx.addIssue({
                    message: `Maximum ${MAX_CHARACTER_COUNT} characters`,
                    code: z.ZodIssueCode.custom,
                    path: ["limits.value"],
                });
            }

            if (
                SelectType[type] &&
                optionsManagedList.value === "new_option" &&
                Number(optionsManagedList.newOption) >= 255
            ) {
                ctx.addIssue({
                    message: `Maximum 255 characters`,
                    code: z.ZodIssueCode.custom,
                    path: ["optionsManagedList.newOption"],
                });
            }

            if (
                SelectType[type] &&
                optionsManagedList.value === "new_option" &&
                Number(optionsManagedList.newOption) < 2
            ) {
                ctx.addIssue({
                    message: `Minimum 2 characters`,
                    code: z.ZodIssueCode.custom,
                    path: ["optionsManagedList.newOption"],
                });
            }

            if (SelectType[type]) {
                if (!optionsManagedList?.value) {
                    ctx.addIssue({
                        message: `Please select a Data Set`,
                        code: z.ZodIssueCode.custom,
                        path: ["optionsManagedList.value"],
                    });
                }

                if (
                    optionsManagedList.value === "new_option" &&
                    !optionsManagedList?.newOption
                ) {
                    ctx.addIssue({
                        message: `Please enter a Data Set name`,
                        code: z.ZodIssueCode.custom,
                        path: ["optionsManagedList.newOption"],
                    });
                }
            }

            if (MultiSelectType[type]) {
                if (
                    selectionsInterval?.minimum &&
                    selectionsInterval?.maximum &&
                    Number(selectionsInterval?.minimum) >
                        Number(selectionsInterval?.maximum)
                ) {
                    ctx.addIssue({
                        message: `Maximum cannot be smaller than Minimum`,
                        code: z.ZodIssueCode.custom,
                        path: ["selectionsInterval.maximum"],
                    });
                }
            }
        },
    );

export type TileVT = z.infer<typeof tileVS>;
