export const SET_MENU_ACTIVE: string = "SET_MENU_ACTIVE";
export const SET_LANGUAGE_ISO: string = "SET_LANGUAGE_ISO";
export const SET_SCRIPT: string = "SET_SCRIPT";
export const SET_ACTIVE_PARENT: string = "SET_ACTIVE_PARENT";

import { initialStateInterface } from "./context";

export const recordBodyReducer = (
    state: initialStateInterface,
    action: any,
) => {
    let result: any = {
        ...state,
    };

    if (action.type == SET_MENU_ACTIVE) {
        result.menuActive = action.menuActive;
    }

    if (action.type == SET_LANGUAGE_ISO) {
        result.languageIso = action.languageIso;
    }

    if (action.type == SET_SCRIPT) {
        result.script = action.script;
    }

    if (action.type == SET_ACTIVE_PARENT) {
        result.activeParent = action.activeParent;
    }

    return result;
};
