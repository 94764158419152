import React from 'react';

import { Link } from "react-router-dom";
import Settings from '../../../../../components/Settings';
import { StyledOriginBadge } from '../styles/styles';
import { CoreTooltip } from '../../../../../core/Tooltip/CoreTooltip';

interface Props {
    linkurl?: string
}

const ContributorOriginBadge: React.FC<Props> = (props) => {
    const {
        linkurl = ''
    } = props

    const originLogo = linkurl ? 'origin' : 'origin-grey'

    const badgeContent = (
        <div className="origin-badge__container">
            <div className="origin-badge__image">
                <img
                    src={`${Settings.images.path}/logos/${originLogo}.svg`}
                    alt="Fabric Origin"
                    data-testid="fabric-origin-logo"
                />
            </div>

            <div className="origin-badge__details">
                <span>See me on</span>
                <span className="title">Fabric Origin</span>
            </div>
        </div>
    );

    return (
        <StyledOriginBadge linkurl={linkurl}>
            {linkurl ? (
                <Link 
                    to={{ pathname: linkurl }} 
                    target="_blank" 
                    data-testid="origin-badge-anchor">
                    {badgeContent}
                </Link>
                ) : (
                <CoreTooltip
                    title="Please provide a valid Fabric Origin Person ID"
                    placement="bottom">
                    {badgeContent}
                </CoreTooltip>
            )}
        </StyledOriginBadge>
    );
};

export default ContributorOriginBadge;
