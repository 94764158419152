import { AccessTypeInterface } from "../../UserRoleManagement/interfaces";
import {
    accessTypeUpdate,
    accessTypeCreate,
    accessTypeDelete,
    accessTypeView,
} from "../../UserRoleManagement/utils/accessOptions";

/**
 * C U D R
 * 8 4 2 1
 */

/**
 * Create access definition
 */
const taskManagerAccessCreate: AccessTypeInterface = {
    ...accessTypeCreate,
    ...{
        parentIndex: [],
        allowedBinaries: [8, 9, 10, 11, 12, 13, 14, 15],
    },
};

/**
 * Update access definition
 */
const taskManagerAccessTypeUpdate: AccessTypeInterface = {
    ...accessTypeUpdate,
    ...{
        parentIndex: [],
        allowedBinaries: [4, 5, 6, 7, 12, 13, 14, 15],
    },
};

/**
 * Delete access definition
 */
const taskManagerAccessTypeDelete: AccessTypeInterface = {
    ...accessTypeDelete,
    ...{
        parentIndex: [],
        allowedBinaries: [2, 3, 6, 7, 10, 14, 15],
    },
};

/**
 * View (or read) access definition
 */
const taskManagerAccessTypeView: AccessTypeInterface = {
    ...accessTypeView,
    ...{
        parentIndex: [],
        allowedBinaries: [1, 3, 5, 7, 9, 13, 15],
    },
};

export {
    taskManagerAccessCreate,
    taskManagerAccessTypeUpdate,
    taskManagerAccessTypeDelete,
    taskManagerAccessTypeView,
};
