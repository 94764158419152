import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
    spacing: (factor: number) => `${0.25 * factor}rem`,
    typography: {
        htmlFontSize: 16,
    },

    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    borderRadius: "0.25rem",
                    color: "rgba(47, 47, 47, 0.87)",
                    outline: "none",
                    boxShadow: "none",
                    "&.Mui-focused.MuiOutlinedInput-root": {
                        ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(77, 77, 77, 0.1)",
                            borderWidth: "0.0625rem",
                        },
                    },
                    "&.Mui-disabled": {
                        "&.MuiOutlinedInput-root": {
                            ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(77, 77, 77, 0.1)",
                            },
                        },
                    },
                    "&:not(.Mui-disabled).MuiOutlinedInput-root": {
                        "&:hover": {
                            ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgb(40, 108, 210, .75)",
                            },
                        },
                    },
                    "&.Mui-error.MuiOutlinedInput-root": {
                        ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(214, 70, 94, 0.75)",
                        },
                        "&:hover": {
                            ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(214, 70, 94, 0.75)",
                            },
                        },
                    },
                },
                input: {
                    padding: "0.5rem 0.625rem !important",
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: "rgba(77, 77, 77, 0.1)",
                    borderWidth: "0.0625rem",
                    boxShadow: "none",
                    transition: "all .2s",
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    display: "flex",
                    alignItems: "center",
                    lineHeight: "1.5",
                    margin: "0 0 0.3125rem 0",
                    fontSize: "0.875rem",
                    fontWeight: "500",
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    "&.MuiDivider-fullWidth": {
                        marginTop: "1.125rem",
                    },
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    ".MuiListItemText-root": {
                        margin: 0,
                    },
                    "&.MuiList-dense": {
                        margin: 0,
                    },
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    padding: "0.625rem 0 0",
                    "& + div": {
                        ".MuiFormControl-root": {
                            margin: "0.625rem 0 0",
                        },
                    },
                    "&.switch-group__header": {
                        padding: 0,
                        label: {
                            margin: 0,
                        },
                        ".MuiSwitch-root": {
                            marginLeft: "0.625rem",
                        },
                    },
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    margin: 0,
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontSize: "0.875rem",
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    padding: "0.3125rem 0.5rem",
                    borderRadius: "0.25rem",
                    height: "unset",
                    margin: "0.3125rem 0.3125rem 0 0",
                    ".MuiSvgIcon-root": {
                        color: "rgba(73, 73, 73, 1)",
                        fontSize: "1.125rem",
                        margin: "0 0 0 0.3125rem",
                    },
                },
                label: {
                    color: "#494949",
                    fontSize: "0.9375rem",
                    padding: 0,
                    lineHeight: "normal",
                },
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                    fontWeight: "400",
                    letterSpacing: "normal",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                    fontWeight: "400",
                    letterSpacing: "normal",
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    width: "1.875rem",
                    height: "1.125rem",
                    padding: 0,
                    margin: "0 0 0 0.625rem",
                    display: "flex",
                    // '&.switch__select-all': {
                    //     marginLeft: '0.625rem'
                    // },
                    ".MuiSwitch-track": {
                        backgroundColor: "#BBBBBB",
                        borderRadius: "1.9125rem",
                        opacity: 1,
                    },
                    ".MuiSwitch-switchBase": {
                        padding: 0,
                        left: "0.0625rem",
                        top: "0.0625rem",
                        "&.Mui-checked": {
                            transform: "translateX(0.75rem)",
                            color: "#fff",
                            "& + .MuiSwitch-track": {
                                backgroundColor: "#53D769",
                                opacity: 1,
                            },
                            ".MuiSwitch-thumb": {
                                boxShadow:
                                    "-0.0625rem 0 0.25rem rgba(0, 0, 0, 0.13), 0 0.0625rem 0.25rem rgba(0, 0, 0, 0.2)",
                            },
                        },
                    },
                    ".MuiSwitch-thumb": {
                        boxShadow: "0 0.0625rem 0.25rem rgba(0, 0, 0, 0.2)",
                        width: "1rem",
                        height: "1rem",
                        borderRadius: "50%",
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    border: 0,
                    padding: 0,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    ".MuiTypography-root": {
                        fontSize: "1rem",
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                popper: {
                    '&[data-popper-placement*="top"] .MuiTooltip-tooltip': {
                        "&.MuiTooltip-tooltipPlacementTop": {
                            padding: "0.3125rem 0.5rem",
                            color: "rgba(255, 255, 255, 1)",
                            backgroundColor: "rgba(59, 68, 98, 1)",
                            marginBottom: "0.3125rem",

                            "& .MuiTooltip-arrow": {
                                color: "rgba(59, 68, 98, 1)",
                            },
                        },
                    },
                },
            },
        },
        // MuiDialogContent: {
        //     styleOverrides: {
        //         root: {
        //             padding: '1.125rem',
        //             maxWidth: "30.125rem",
        //         },
        //     },
        // },
        // MuiDialogActions: {
        //     styleOverrides: {
        //         root: {
        //             borderTop: '1px solid #E5E5E5',
        //             padding: '1.125rem',
        //         },
        //     },
        // }
    },
});
