import React from "react";
import Tag from "../../Tag";

const RenderReleaseDateTypeTag = (props) => {
    const { item = {} } = props;

    const { type } = item;

    const backgrounds = {
        "Disney+": "#C4D8F6",
        Theatrical: "#BDEBF6",
        Digital: "#F9A893",
        Physical: "#E6C9F3",
        "First Aired": "#FAC6D5",
        "First Linear TX": "#FFCC93",
    };

    const colors = {
        "Disney+": "#1E3E6F",
        Theatrical: "#26606F",
        Digital: "#592315",
        Physical: "#663D79",
        "First Aired": "#95143A",
        "First Linear TX": "#523617",
    };

    return (
        <span className="meta-numbered-record-table--release-dates">
            <Tag
                background={backgrounds[type]}
                color={colors[type]}
                text={type}
                fontSize="0.875rem"
                textTransform="capitalize"
            />
        </span>
    );
};

export default RenderReleaseDateTypeTag;
