export default {
    datetime: {
        payload: {
            format: {
                date: 'YYYY-MM-DD',
                time: 'HH:mm:ss',
                datetime: 'YYYY-MM-DD HH:mm:ss',
            }
        }
    }
}