import isEmpty from "lodash/isEmpty";
import { APIRoleOptionInterface, CreditInterface } from "../../../credits.interface";
export default function validateCreditForm(
    isActive: boolean,
    field: string,
    value: string | APIRoleOptionInterface | CreditInterface,
) {
    let valid: boolean = true
    let message: string = ""

    if (!isActive) return {
        [field]: {
            valid,
            message
        }
    }

    switch (field) {
        case "contributor":
            if (isEmpty(value)) {
                valid = false
                message = `Contributor is required.`
            }

            break;

        case "role":
            if (isEmpty(value)) {
                valid = false
                message = `Credit's ${field} is required.`
            }
            break;
    }

    return {
        [field]: {
            valid,
            message
        }
    }
}