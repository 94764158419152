import { SET_ORIGINAL_NEW_RECORD } from "../actions/action_types";
import { defaultOriginalNewRecord } from "../initialState";

export default function (
    state = { originalNewRecord: defaultOriginalNewRecord },
    action,
) {
    switch (action.type) {
        case SET_ORIGINAL_NEW_RECORD:
            return Object.assign({}, state, {
                originalNewRecord: action.originalNewRecord,
            });
    }

    return state;
}
