import Settings from "../Settings";

const urls = {
    discover: "/discovery",
    collections: {
        title: "/collections",
        reporting: "/reporting",
        connect: "/connect-collections",
        localisations: "/localisations",
    },
};

const Feature = {
    short: "Feature",
    long: "Feature",
    multiple: "Features",
    color: "#67567e",
    icon: `${Settings.images.path}/svg/movie-light-grey.svg`,
    createRecord: true,
    linkage: ["Feature", "Collection", "Extras", "Short", "Show"],
    displayParentNavigation: true,
    url: urls.discover,
    tagBackground: "#DAC7F6",
    tagColor: "#684A91",
    recordBuilder: true,
    recordBuilderKey: "Feature",
};

let Collection = {
    short: "Collection",
    long: "Title Collection",
    multiple: "Title Collections",
    color: "#18bd98",
    icon: `${Settings.images.path}/svg/collections-light-grey.svg`,
    linkage: ["Feature", "Extras", "Short", "Show", "Special"],
    url: urls.collections.title,
    recordBuilder: true,
    recordBuilderKey: "Collection",
};

const FranchiseCollection = {
    short: "Franchise Collection",
    long: "Franchise Collection",
    multiple: "Franchise Collections",
    color: "#2F6DCB",
    icon: `${Settings.images.path}/svg/collections-light-grey.svg`,
    linkage: ["Feature", "Extras", "Short", "Show", "Special"],
    url: urls.collections.title,
    recordBuilder: true,
    recordBuilderKey: "FranchiseCollection",
};

const ConnectCollection = {
    short: "Connect Collection",
    long: "Connect Collection",
    multiple: "Connect Collections",
    color: "#18bd98",
    icon: `${Settings.images.path}/svg/collections-light-grey.svg`,
    linkage: ["Feature", "Extras", "Short", "Show", "Special"],
    url: urls.collections.connect,
    recordBuilder: true,
    recordBuilderKey: "ConnectCollection",
};

const LocalisationOrder = {
    short: "Localisation Order",
    long: "Localisation Order",
    multiple: "Localisation Orders",
    color: "#18bd98",
    icon: `${Settings.images.path}/svg/collections-light-grey.svg`,
    linkage: ["Feature", "Extras", "Short", "Show", "Special"],
    url: urls.collections.localisations,
    recordBuilder: true,
    recordBuilderKey: "LocalisationOrder",
};

const Report = {
    short: "Report",
    long: "Report",
    multiple: "Reports",
    color: "#18bd98",
    icon: `${Settings.images.path}/svg/collections-light-grey.svg`,
    linkage: ["Feature", "Extras", "Short", "Show", "Special"],
    url: urls.collections.reporting,
    recordBuilder: true,
    recordBuilderKey: "Report",
};

const Extras = {
    short: "Extras",
    long: "Supplemental Content",
    multiple: "Supplemental Content",
    supplemental: "Supplemental",
    color: "#ee5e6e",
    icon: `${Settings.images.path}/svg/supplemental-content-light-grey.svg`,
    createRecord: true,
    linkage: [
        "Feature",
        "Collection",
        "Extras",
        "Short",
        "Show",
        "Season",
        "Episode",
        "Special",
    ],
    displayParentNavigation: true,
    url: urls.discover,
    tagBackground: "#FFDFE1",
    tagColor: "#C91C2A",
    recordBuilder: true,
    recordBuilderKey: "Extras",
};

const Short = {
    short: "Short",
    long: "Short Form Content",
    multiple: "Short Form Content",
    supplemental: "Short Form",
    color: "#eba854",
    icon: `${Settings.images.path}/svg/short-form-light-grey.svg`,
    createRecord: true,
    linkage: [
        "Feature",
        "Collection",
        "Extras",
        "Short",
        "Show",
        "Season",
        "Episode",
        "Special",
    ],
    displayParentNavigation: true,
    url: urls.discover,
    tagBackground: "#FFDFA9",
    tagColor: "#895C0E",
    recordBuilder: true,
    recordBuilderKey: "Short",
};

const Show = {
    short: "Show",
    long: "Show",
    multiple: "Shows",
    color: "#1c654d",
    icon: `${Settings.images.path}/svg/show-light-grey.svg`,
    createRecord: true,
    linkage: ["Feature", "Collection", "Extras", "Short", "Special"],
    displayParentNavigation: true,
    url: urls.discover,
    tagBackground: "#9EF1D3",
    tagColor: "#1F6F5F",
    recordBuilder: true,
    recordBuilderKey: "Show",
};

const Season = {
    short: "Season",
    long: "Season",
    multiple: "Seasons",
    color: "#137a63",
    createRecord: true,
    icon: `${Settings.images.path}/svg/season-light-grey.svg`,
    linkage: ["Feature", "Collection", "Extras", "Short", "Special"],
    displayParentNavigation: true,
    url: urls.discover,
    tagBackground: "#9DE9D0",
    tagColor: "#0C704F",
    recordBuilder: true,
    recordBuilderKey: "Season",
};

const Episode = {
    short: "Episode",
    long: "Episode • Complete",
    multiple: "Episodes",
    type: "complete",
    color: "#5aa17f",
    createRecord: true,
    icon: `${Settings.images.path}/svg/episode-light-grey.svg`,
    linkage: ["Collection", "Extras", "Short", "Special"],
    displayParentNavigation: true,
    url: urls.discover,
    tagBackground: "#B6FFE5",
    tagColor: "#0F6D57",
    recordBuilder: true,
    recordBuilderKey: "Episode",
};

const ConstructedEpisode = {
    short: "Constructed Episode",
    long: "Episode • Constructed",
    supplemental: "Episode+",
    multiple: "Episodes",
    type: "constructed",
    color: "#5aa17f",
    createRecord: true,
    icon: `${Settings.images.path}/svg/show-light-grey.svg`,
    linkage: ["Collection", "Extras", "Short", "Special"],
    displayParentNavigation: true,
    url: urls.discover,
    tagBackground: "#B6FFE5",
    tagColor: "#0F6D57",
    recordBuilder: true,
    recordBuilderKey: "ConstructedEpisode",
};

const Storyline = {
    short: "Storyline",
    long: "Storyline",
    multiple: "Storylines",
    color: "#5cb9a9",
    createRecord: true,
    icon: `${Settings.images.path}/svg/storyline-light-grey.svg`,
    linkage: ["Collection", "Extras", "Short", "Special"],
    displayParentNavigation: true,
    url: urls.discover,
    tagBackground: "#B2FAED",
    tagColor: "#1B785E",
    recordBuilder: true,
    recordBuilderKey: "Storyline",
};

const Special = {
    short: "Special",
    long: "Special",
    multiple: "Specials",
    color: "#6db892",
    icon: `${Settings.images.path}/svg/show-light-grey.svg`,
    linkage: [
        "Collection",
        "Extras",
        "Short",
        "Game",
        "Show",
        "Season",
        "Episode",
        "ConstructedEpisode",
        "Storyline",
    ],
    createRecord: true,
    displayParentNavigation: true,
    url: urls.discover,
    tagBackground: "#C7FFE9",
    tagColor: "#2E786E",
    recordBuilder: true,
    recordBuilderKey: "Special",
};

const Game = {
    short: "Game",
    long: "Game",
    multiple: "Games",
    color: "#344190",
    icon: `${Settings.images.path}/svg/game-light-grey.svg`,
    createRecord: true,
    displayParentNavigation: true,
    url: urls.discover,
    tagBackground: "#B0BAFA",
    tagColor: "#36418B",
    recordBuilder: true,
    recordBuilderKey: "Game",
};

const Promotional = {
    short: "Promotional",
    long: "Promotional",
    multiple: "Promotional",
    supplemental: "Promotional+",
    color: "#fcbe70",
    icon: `${Settings.images.path}/svg/short-form-light-grey.svg`,
    createRecord: true,
    linkage: [
        "Feature",
        "Collection",
        "Extras",
        "Short",
        "Show",
        "Season",
        "Episode",
        "Special",
    ],
    displayParentNavigation: true,
    url: urls.discover,
    tagBackground: "#FFD3C9",
    tagColor: "#B92200",
    recordBuilder: true,
    customCategory: true,
    recordBuilderKey: "Promotional",
};

const Component = {
    short: "Component",
    long: "Component",
    multiple: "Component",
    supplemental: "Component+",
    color: "#ff8e00",
    icon: `${Settings.images.path}/svg/short-form-light-grey.svg`,
    createRecord: true,
    linkage: [
        "Feature",
        "Collection",
        "Extras",
        "Short",
        "Show",
        "Season",
        "Episode",
        "Special",
    ],
    displayParentNavigation: true,
    url: urls.discover,
    tagBackground: "#D7FBF6",
    tagColor: "#167A6C",
    recordBuilder: true,
    customCategory: true,
    recordBuilderKey: "Component",
};

const Program = {
    short: "Program",
    long: "Program",
    multiple: "Program",
    supplemental: "Program",
    color: "#cc2908",
    icon: `${Settings.images.path}/svg/short-form-light-grey.svg`,
    createRecord: true,
    linkage: [
        "Feature",
        "Collection",
        "Extras",
        "Short",
        "Show",
        "Season",
        "Episode",
        "Special",
    ],
    displayParentNavigation: true,
    url: urls.discover,
    tagBackground: "#CEE6FF",
    tagColor: "#2364A1",
    recordBuilder: true,
    customCategory: true,
    recordBuilderKey: "Program",
};

const Pilot = {
    short: "Pilot",
    long: "Pilot",
    multiple: "Pilot",
    supplemental: "Pilot+",
    color: "#6db892",
    icon: `${Settings.images.path}/svg/show-light-grey.svg`,
    linkage: [
        "Collection",
        "Extras",
        "Short",
        "Game",
        "Show",
        "Season",
        "Episode",
        "ConstructedEpisode",
        "Storyline",
    ],
    createRecord: true,
    displayParentNavigation: true,
    url: urls.discover,
    tagBackground: "#BBF1EC",
    tagColor: "#257C70",
    recordBuilder: true,
    customCategory: true,
    recordBuilderKey: "Pilot",
};

const Podcast = {
    short: "Podcast",
    long: "Podcast",
    multiple: "Podcasts",
    supplemental: "Podcast",
    color: "#CE50C2",
    icon: `${Settings.images.path}/svg/short-form-light-grey.svg`,
    createRecord: true,
    linkage: [
        "Feature",
        "Collection",
        "Extras",
        "Short",
        "Show",
        "Season",
        "Episode",
        "Special",
    ],
    displayParentNavigation: true,
    url: urls.discover,
    tagBackground: "#FFDFA9",
    tagColor: "#CE50C2",
    recordBuilder: true,
    customCategory: true,
    recordBuilderKey: "Podcast",
};

const Version = {
    short: "Version",
    long: "Version",
    multiple: "Version",
    supplemental: "Version",
    color: "#BE8CDE",
    icon: `${Settings.images.path}/svg/short-form-light-grey.svg`,
    createRecord: true,
    linkage: [
        "Feature",
        "Collection",
        "Extras",
        "Short",
        "Show",
        "Season",
        "Episode",
        "Special",
    ],
    displayParentNavigation: true,
    url: urls.discover,
    tagBackground: "#FFDFA9",
    tagColor: "#BE8CDE",
    recordBuilder: true,
    customCategory: true,
    recordBuilderKey: "Version",
};

const data = {
    Feature,
    Collection,
    ConnectCollection,
    FranchiseCollection,
    Extras,
    Short,
    Game,
    Show,
    Season,
    Episode,
    ConstructedEpisode,
    Storyline,
    Special,
    LocalisationOrder,
    Report,
    Promotional,
    Component,
    Program,
    Pilot,
    Podcast,
    Version,
};

export function isShow(category) {
    return category == Show.short;
}

export default data;
