import React from 'react'
import { makeObservable, action, extendObservable } from 'mobx'
import { RootStore } from './rootStore'
import { permissionsStoreInitialState } from "./state"
import { globalStateInterface } from "../../../../components/Global/interfaces"
import { userHasPermission } from "../../../../services/PermissionService"; 
import { creditsOption } from "../../../UserRoleManagement/utils/sectionOptions/recordSections"
import { accessTypeStrictCreate, accessTypeStrictUpdate, accessTypeStrictDelete } from "../../../UserRoleManagement/utils/accessOptions";

export class PermissionsStore {
    rootStore: RootStore;
    userHasCreatePermission: boolean
    userHasUpdatePermission: boolean
    userHasDeletePermission: boolean

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            initialize: action,

            setUserHasDeletePermission: action,
            setUserHasUpdatePermission: action,
            setUserHasCreatePermission: action
        });

        extendObservable(this, permissionsStoreInitialState);
    }

    initialize(): void {
        this.reset()

        this.handleSetUserHasDeletePermission(this.rootStore.globalState)
        this.handleSetUserHasCreatePermission(this.rootStore.globalState)
        this.handleSetUserHasUpdatePermission(this.rootStore.globalState)
    }

    reset() {
        Object.keys(permissionsStoreInitialState).forEach(
            (key) => (this[key] = permissionsStoreInitialState[key]),
        );
    }

    setUserHasDeletePermission(userHasDeletePermission: boolean): void {
        this.userHasDeletePermission = userHasDeletePermission
    }

    setUserHasUpdatePermission(userHasUpdatePermission: boolean): void {
        this.userHasUpdatePermission = userHasUpdatePermission
    }

    setUserHasCreatePermission(userHasCreatePermission: boolean): void {
        this.userHasCreatePermission = userHasCreatePermission
    }

    handleSetUserHasDeletePermission(globalState: globalStateInterface): void {
        const deletePermission = userHasPermission({
            cmsData: this.rootStore.globalState?.cmsData,
            userPermissions: globalState.userPermissions,
            pathField: creditsOption.path,
            access: accessTypeStrictDelete
        });

        this.setUserHasDeletePermission(deletePermission)
    }

    handleSetUserHasCreatePermission(globalState: globalStateInterface): void {
        const createPermission = userHasPermission({
            cmsData: this.rootStore.globalState?.cmsData,
            userPermissions: globalState.userPermissions,
            pathField: creditsOption.path,
            access: accessTypeStrictCreate
        });

        this.setUserHasCreatePermission(createPermission)
    }

    handleSetUserHasUpdatePermission(globalState: globalStateInterface): void {
        const updatePermission = userHasPermission({
            cmsData: this.rootStore.globalState?.cmsData,
            userPermissions: globalState.userPermissions,
            pathField: creditsOption.path,
            access: accessTypeStrictUpdate
        });

        this.setUserHasUpdatePermission(updatePermission)
    }
}
