import { CoreSelectOptionType } from "../../../core/Form/components/inputs/Select/types/CoreSelectOptionType";
import { TaskManagerFabricOptions } from "../types/interfaces";

export function useBrandOptions(
    options: TaskManagerFabricOptions,
): CoreSelectOptionType[] {
    return options?.brands?.map((b) => ({
        label: b,
        value: b,
    }));
}
