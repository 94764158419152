import React, { useState } from 'react'
import { useFormContext } from "react-hook-form";
import { useFetchContributors } from "./utils/useFetchContributors";
import { AutocompleteSuggestions } from "../../Autocomplete/AutocompleteSuggestions";
import { useAutocompleteSuggestions } from "../../Autocomplete/useAutocompleteSuggestions";
import { CoreFormType } from "../../Form/types";
import { usePopperState } from "../../Form/utils/usePopperState";
import { ContributorListItem } from "./ContributorListItem";
import { ContributorInterface } from "../../../features/Preferences/Contributors/interfaces"
import ContributorDialogCreate from "../../../features/Preferences/Contributors/forms/ContributorDialogCreate.view";
import { getContributorName } from "../../../models/Contributor/model";

export type FormType = {
    contributors: string[];
    title: string;
};

export interface AutocompleteRecord extends ContributorInterface {
    contributorQuery: string;
    selectedContributor: string[];
}

type Props = {
    disabled: boolean,
    path: string,
    labelProps: {
        text: string,
        isRequired?: boolean,
    }
}

export const AutocompleteContributors = ({ path, labelProps, disabled }: Props) => {
    const arrayPath: string = path;
    const inputPath: CoreFormType<AutocompleteRecord> = `text_${path}`;
    const form = useFormContext();
    const [newContributorOpen, setNewContributorOpen] = useState<boolean>(false);

    const { addNewValue, replace, value } = useAutocompleteSuggestions({
        arrayPath,
        inputPath,
    });

    const { suggestions, loading } = useFetchContributors(value);
    const { open, closePopper, openPopper } = usePopperState();

    return (
        <>
            <AutocompleteSuggestions
                labelProps={{
                    text: "Contributor Name",
                    isRequired: labelProps.isRequired
                }}
                placeholder="Search Contributor"
                suggestions={suggestions}
                path={inputPath}
                arrayPath={arrayPath}
                headerText="Suggestions"
                onClick={(option: (typeof suggestions)[0]) => {
                    form.setValue('contributor', {
                        contributor: option,
                        [inputPath]: getContributorName(option),
                        [path]: option.id
                    })

                    form.setValue(arrayPath, option.id)
                    form.setValue(inputPath, null)

                    closePopper();
                }}
                noMatchOnClick={() => {
                    setNewContributorOpen(true)
                }}
                addNewValue={addNewValue}
                replace={replace}
                popperProps={{
                    open,
                    closePopper,
                    openPopper,
                }}
                singleSelect={true}
                loading={loading}
                onRenderOption={(option: AutocompleteRecord) => {
                    return (
                        <ContributorListItem
                            {...option}
                        />
                    );
                }}
            />

            {newContributorOpen &&
                <ContributorDialogCreate
                    open={newContributorOpen}
                    handleSetOpen={(open: boolean) => setNewContributorOpen(open)}
                    handleContributorCreated={(contributor: ContributorInterface) => {
                        form.setValue('contributor', {
                            contributor,
                            [inputPath]: getContributorName(contributor),
                            [path]: contributor.id
                        })

                        closePopper()
                    }}
                />
            }
        </>
    );
};
