import React from "react";
import CategoryTags from "../../../CategoryTags";
import LinkShow from "./LinkShow";
import LinkSeason from "./LinkSeason";
import Select from "../../../Select";
import LinkEpisodes from "./LinkEpisodes";
import LinkSimpleMultiple from "./LinkSimpleMultiple";
import GetCategoryMatrix from "./src/GetCategoryMatrix";

export default class AddRecordLinkage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            linkRecordCategory: "",
            warnUser: false,
        };
    }

    render() {
        return (
            <div className="new-add-linkage-form">
                {this.renderLinkRecordCategory()}
                {this.renderLinkageView()}
            </div>
        );
    }

    assignResult(data, flushError = true) {
        const {
            recordType = "",
            setError = () => {},
            clientFeatures = {},
        } = this.props;

        const { supplementalContentFlag = false } = clientFeatures;

        const categories = new Set();

        if (typeof setError === "function" && flushError) {
            setError("");
        }

        let result = data.map((row) => {
            if (!row.category && row.category_name)
                row.category = row.category_name;
            if (!row.category_name && row.category)
                row.category_name = row.category;

            if (supplementalContentFlag && recordType === "vam") {
                if (categories && categories.has(row.category)) {
                    if (setError) {
                        setError(
                            "When linking VAM to multiple Records of the same Category - Image Naming Convention will use VAM Title to generate the Image Placeholder Names",
                        );
                    }
                }
                categories.add(row.category);
            }
            return row;
        });

        this.props.handleChange(result);
    }

    renderLinkageView() {
        let renderingMethod = null;

        switch (this.state.linkRecordCategory) {
            case CategoryTags.Feature.short:
            case CategoryTags.Extras.short:
            case CategoryTags.Program.short:
            case CategoryTags.Component.short:
            case CategoryTags.Promotional.short:
            case CategoryTags.Short.short:
            case CategoryTags.Collection.short:
            case CategoryTags.FranchiseCollection.short:
            case CategoryTags.ConnectCollection.short:
            case CategoryTags.Report.short:
            case CategoryTags.LocalisationOrder.short:
            case CategoryTags.Pilot.short:
            case CategoryTags.Podcast.short:
            case CategoryTags.Version.short:
                renderingMethod = this.renderLinkageSimpleMultiple(
                    this.state.linkRecordCategory,
                );
                break;

            case CategoryTags.Episode.short:
            case CategoryTags.ConstructedEpisode.short:
                renderingMethod = this.renderLinkageEpisodes({
                    categories: [
                        CategoryTags.Episode.short,
                        CategoryTags.ConstructedEpisode.short,
                    ],
                });
                break;

            case CategoryTags.Storyline.short:
                renderingMethod = this.renderLinkageEpisodes({
                    categories: [CategoryTags.Storyline.short],
                });
                break;

            case CategoryTags.Season.short:
                renderingMethod = this.renderLinkageSeason();
                break;

            case CategoryTags.Show.short:
                renderingMethod = this.renderLinkageShow();
                break;

            case CategoryTags.Special.short:
                renderingMethod = this.renderLinkageSimpleMultiple(
                    this.state.linkRecordCategory,
                );
                break;
        }

        return <div className="linkage-block">{renderingMethod}</div>;
    }

    renderLinkRecordCategory() {
        const {
            category = "",
            options = {},
            data = [],
            recordType = "",
            setError = null,
            clientFeatures = {},
            clientDataStructure = {},
        } = this.props;

        const { supplementalContentFlag = false } = clientFeatures;

        let linkageRecordTypeList = [];

        if (process.env.NODE_ENV == "test") {
            Object.keys(CategoryTags).forEach((value) => {
                CategoryTags.Extras.linkage.forEach((item) => {
                    if (CategoryTags[value].short == item) {
                        linkageRecordTypeList.push({
                            value: CategoryTags[value].short,
                            text: CategoryTags[value].multiple,
                        });
                    }
                });
            });
        } else {
            linkageRecordTypeList = GetCategoryMatrix({
                clientFeatures,
                clientDataStructure,
                options,
                categoryName: category,
            });
        }

        return (
            <div className="linkage-record-type" style={{ paddingTop: "1rem" }}>
                <Select
                    label="Record Type"
                    name="linkageRecordType"
                    id="linkage_record_type"
                    disabledText="Select Record Type"
                    value={this.state.linkRecordCategory || ""}
                    handleChange={(value) => {
                        this.setState({ warnUser: false });
                        if (setError) {
                            setError("");
                        }
                        if (supplementalContentFlag && recordType === "vam") {
                            let exist = false;
                            exist = data.find((item) => {
                                if (item.category === value) {
                                    return true;
                                }
                            });
                            if (exist) {
                                this.setState({ warnUser: true });
                                if (setError) {
                                    setError(
                                        "When linking VAM to multiple Records of the same Category - Image Naming Convention will use VAM Title to generate the Image Placeholder Names",
                                    );
                                }
                            }
                        }

                        this.setState({ linkRecordCategory: value });
                    }}
                    data={linkageRecordTypeList}
                />
            </div>
        );
    }

    renderLinkageShow() {
        return (
            <LinkShow
                onChange={(rows) => this.assignResult(rows)}
                searchUrl={this.props.searchLinkageUrl}
                searchFullUrl={this.props.searchLinkageFullUrl}
                clientDataStructure={this.props.clientDataStructure || {}}
            />
        );
    }

    renderLinkageSeason() {
        const {
            category = "",
            setError = () => {},
            searchLinkageFullUrl = "",
            searchLinkageUrl = "",
            clientFeatures = {},
            clientDataStructure = {},
        } = this.props;

        return (
            <LinkSeason
                handleConverting={(data) => this.props.handleConverting(data)}
                onChange={(rows) => this.assignResult(rows, false)}
                searchUrl={searchLinkageUrl}
                searchFullUrl={searchLinkageFullUrl}
                clientDataStructure={clientDataStructure || {}}
                category={category}
                setError={setError.bind(this)}
            />
        );
    }

    renderLinkageEpisodes(props) {
        return (
            <LinkEpisodes
                onChange={(rows) => this.assignResult(rows)}
                searchUrl={this.props.searchLinkageUrl}
                searchFullUrl={this.props.searchLinkageFullUrl}
                {...props}
            />
        );
    }

    renderLinkageSimpleMultiple(category) {
        return (
            <LinkSimpleMultiple
                onChange={(rows) => this.assignResult(rows)}
                searchUrl={this.props.searchLinkageUrl}
                searchFullUrl={this.props.searchLinkageFullUrl}
                linkCategory={category}
            />
        );
    }
}
