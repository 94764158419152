const HandleRestoreRecordClick = (props) => {
    const { state = {}, meta_id } = props;

    const { handleRestoreRecordConfirm, set } = state;

    set({
        notification: {
            title: "Restore record",
            description: "Are you sure you want to restore this record?",
            status: "error",
            okText: "Restore",
            confirm: handleRestoreRecordConfirm.bind(this, meta_id),
        },
    });
};

export default HandleRestoreRecordClick;
