import React from "react";
import _ from "lodash";
import Switch from "../Switch";
import update from "react-addons-update";
import "./styles/_styles.scss";

export default class SwitchFilters extends React.Component {
    constructor(props) {
        super(props);

        /**
         * @var object
         */
        this.copy = this.props.copy || {};

        this.state = {
            data: this.props.data || [],
        };
    }

    componentWillUpdate(nextProps) {
        if (
            Array.isArray(nextProps.data) &&
            !_.isEmpty(nextProps.data) &&
            (_.isEmpty(this.state.data) ||
                !_.isEqual(this.props.data, nextProps.data))
        ) {
            this.setState({
                data: nextProps.data,
            });
        }
    }

    handleChange(item, ulIndex, liIndex) {
        const { data } = this.state;

        const { handleChange } = this.props;

        const updatedData = update(data, {
            [ulIndex]: {
                [liIndex]: {
                    $set: item,
                },
            },
        });

        this.setState(
            {
                data: updatedData,
            },
            () => {
                if (typeof handleChange == "function") {
                    handleChange(updatedData);
                }
            },
        );
    }

    render() {
        const { style, sliderClass } = this.props;

        const { data } = this.state;

        if (!_.isEmpty(data) && Array.isArray(data)) {
            return (
                <div className="meta-switch-filters" style={style}>
                    {data.map((ul, ulIndex) => {
                        return (
                            <ul key={ulIndex}>
                                {ul.map((li, liIndex) => {
                                    return (
                                        <li key={liIndex}>
                                            {li.name}

                                            <Switch
                                                name={li.name}
                                                checked={li.checked}
                                                disabled={li.disabled}
                                                amber={li.amber}
                                                tooltip={li.tooltip}
                                                sliderClass={sliderClass}
                                                handleChange={(item) =>
                                                    this.handleChange(
                                                        item,
                                                        ulIndex,
                                                        liIndex,
                                                    )
                                                }
                                            />
                                        </li>
                                    );
                                })}
                            </ul>
                        );
                    })}
                </div>
            );
        }

        return false;
    }
}
