import React from "react";
import _ from "lodash";
import styles from "../styles";
import Boilerplate from "./Boilerplate";
import PlaceholderText from "../../PlaceholderText";
import GetPopulatedLanguages from "../../GoogleTranslateApi/GetPopulatedLanguages";

export default class IndexComponent extends React.Component {
    constructor(props) {
        super(props);

        /**
         * @var object
         */
        this.state = {
            data: this.props.data || [],
        };

        /**
         * @var object
         */
        this.copy = this.props.copy || {};

        /**
         * @var object
         */
        this.styles = styles(this.props);
    }

    getData() {
        return this.props.dataFromProps
            ? this.props.data
            : this.state.data || [];
    }

    setData(data) {
        if (!_.isEqual(this.state.data, data)) {
            this.setState({
                data,
            });
        }
    }

    setStateValue(key, value) {
        if (!_.isEqual(this.state[key], value)) {
            this.setState({
                [key]: value,
            });
        }
    }

    shouldRenderNoneadded() {
        return true;
    }

    getGoogleTranslateItems(key) {
        const { clientFeatures = {}, options = {} } = this.props;

        const { spoken_languages = [], top_written_languages = [] } = options;

        return GetPopulatedLanguages({
            clientFeatures,
            languages: spoken_languages,
            top_written_languages,
            data: this.state.data,
            itemKey: "title",
            itemType: key,
        });
    }

    renderNoneAdded() {
        if (!this.shouldRenderNoneadded()) {
            return false;
        }

        const data = this.getData();
        let active = false;

        if (Array.isArray(data)) {
            data.forEach((item) => {
                if (!item.deleted) {
                    active = true;
                }
            });
        }

        if (active) {
            return false;
        }

        if (this.props.disabled && !active) {
            return (
                <div style={this.styles.marginItem}>
                    <PlaceholderText copy={this.copy} />
                </div>
            );
        }

        return false;
    }

    renderTitle(title) {
        return <h2 style={this.styles.section.title}>{title}</h2>;
    }

    render() {
        return (
            <Boilerplate {...this.props}>
                {this.renderNoneAdded()}
                {this.renderContent()}
            </Boilerplate>
        );
    }
}
