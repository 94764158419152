import { Chip, Tooltip } from "@mui/material";
import { TooltipInBox } from "../Tooltip/TooltipInBox";
import React, { ReactNode } from "react";

export type CoreMenuChipProps = {
    array: any[];
};
export const CoreMenuChip = ({ array }: CoreMenuChipProps) => {
    const selectedAdditionTooltipTitle = (): ReactNode => {
        return (
            <>
                {array.slice(1).map(option => {
                    const { label = false } = option
                    return <div className={"tooltip-text"}>{label ?? option}<br /></div>
                })}
            </>
        )
    }

    return (
        <TooltipInBox title={selectedAdditionTooltipTitle()}>
            <Chip
                sx={{
                    marginLeft: "0.5rem",
                    borderRadius: "0.25rem",
                    height: "1rem",
                    lineHeight: "80%",
                    padding: "0.6rem 0.25rem",
                    pointerEvents: "all",
                    ".MuiChip-label": {
                        padding: 0,
                        fontSize: "11px",
                        color: "black",
                    },
                }}
                size="small"
                label={`+${array?.length - 1}`}
            />   
        </TooltipInBox>
        
    );
};
