import React from 'react'
import { CreditsProvider } from './context'
import CreditsTable from './src/Table'

const CreditsV2 = props => {
    const {
        disabled = true
    } = props

    return(
        <CreditsProvider {...props}>
            <CreditsTable
                {...props}
                disabled={disabled}
            />
        </CreditsProvider>
    )
}

export default CreditsV2