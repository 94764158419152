import { StandaloneTicket } from "../containers/ticket/shared/validationSchema";
import {
    TicketTypeStandalone,
    TicketTypeTVShow,
    TicketTypeUpdate,
} from "../types/constants";
import { TicketType } from "../types/interfaces.shared";

export default function getTicketType(ticket: StandaloneTicket): TicketType {
    switch (ticket.type.label) {
        case TicketTypeStandalone.label:
            return TicketTypeStandalone;
        case TicketTypeTVShow.label:
            return TicketTypeTVShow;
        default:
            return TicketTypeUpdate;
    }
}
