import { useStores } from "../store/rootStore";

export function useGenresOptions() {
    const stores = useStores();

    return stores?.clientManaged?.descriptors?.genres.map((d) => ({
        label: d.name,
        value: d.value,
    }));
}
