import React from "react";
import DoubleGridList from "../../../CheckboxTag/DoubleGridList";
import Select from "../../../Select";
import DataFetch from "./DataFetch";
import CategoryTags from "../../../CategoryTags";
import AutocompleteInput from "../../../AutocompleteInput";
import _ from "lodash";
import ShowLabelOfSeasonsEpisodesCounts from "./src/ShowLabelOfSeasonsEpisodesCounts";
import SeasonLabel from "./src/SeasonLabel";
import BubleListSearchDataList from "../../../AddTitleToCollection/src/BubleListSearchDataList";
import FetchIncludingList from "./helpers/FetchIncludingList";
import DefaultIncludingListSeason from "./src/data/DefaultIncludingListSeason";
import CheckIncludingTypesListOnSingle from "./helpers/CheckIncludingTypesListOnSingle";
import CircleInput from "../../../CircleInput";
import FetchConvertConfig from "../../../RecordConverter/src/FetchConfig";

export default class LinkSeason extends React.Component {
    constructor(props) {
        super(props);

        const { clientDataStructure = {} } = props;

        const { linkages = [] } = clientDataStructure;

        this.includingTypesList = FetchIncludingList({
            category: CategoryTags.Season.short,
            configs: linkages,
            fallback: DefaultIncludingListSeason,
        });

        this.state = {
            searchValue: "",
            searchList: [],
            searchListLinks: [],
            readySearchSelection: false,
            seasonIncludingType:
                CheckIncludingTypesListOnSingle(this.includingTypesList) ||
                "season_only",
            seasonsList: [],
            selectedSeason: null,
            enabledConverting: false,
        };

        this.updateSearchList = this.updateSearchList.bind(this);
        this.handleSeasonEpisodes = this.handleSeasonEpisodes.bind(this);
    }

    handleSearchSelection(value) {
        if (!value.meta_id) value.meta_id = value.value;
        DataFetch.linksByMetaId({
            searchUrl: this.props.searchFullUrl,
            meta_id: value.meta_id,
            filterCategories: [CategoryTags.Season.short],
            onResult: (seasons) => {
                seasons.sort((a, b) =>
                    a.season_number > b.season_number ? 1 : -1,
                );
                let seasonsList = seasons.map((item) => {
                    return {
                        value: item.meta_id,
                        text: SeasonLabel({ ...item }),
                    };
                });

                value.postfix = ShowLabelOfSeasonsEpisodesCounts({
                    parentShowLinkages: seasons,
                }).seasonsLabel;

                this.setState({
                    searchValue: value,
                    readySearchSelection: true,
                    seasonsData: seasons,
                    seasonsList,
                });
            },
        });
    }

    updateSearchList(data) {
        const list = BubleListSearchDataList({ searchDataList: data });
        this.setState({ searchList: list, searchListLinks: data });
    }

    handleSeasonSelection(value) {
        this.setState({ seasonMetaId: value }, () => {
            DataFetch.linksByMetaId({
                searchUrl: this.props.searchFullUrl,
                meta_id: value,
                filterCategories: [
                    CategoryTags.Episode.short,
                    CategoryTags.ConstructedEpisode.short,
                ],
                onResult: (episodes) =>
                    this.handleSeasonEpisodes(episodes, value),
            });
        });
    }

    handleSeasonEpisodes(episodes = [], seasonMetaId) {
        const {
            seasonIncludingType = null,
            seasonsData = [],
            searchListLinks = [],
            searchValue = {},
        } = this.state;

        let show = DataFetch.recordsFromArray({
            records: searchListLinks,
            searchable: [searchValue],
        });

        let season = DataFetch.recordsFromArray({
            records: seasonsData,
            searchable: [{ meta_id: seasonMetaId }],
        });

        let result = season || [];

        if (seasonIncludingType == "show_season") {
            result = result.concat(show);
        }

        if (seasonIncludingType == "show_season_episodes") {
            result = result.concat(episodes).concat(show);
        }

        this.handleConvertingChange();
        this.props.onChange(result);
    }

    handleConvertingChange() {
        let convertSettings = FetchConvertConfig({
            dataStructure: this.props.clientDataStructure,
            sourceCategory: this.props.category,
            targetCategory: "Episode",
        });

        if (convertSettings && this.state.seasonMetaId) {
            this.props.handleConverting({
                enabled: this.state.enabledConverting,
                filters: [],
                deleteSource: convertSettings.deleteSource,
                targetCategory: convertSettings.targetCategory,
                targetParentRecordMetaId: this.state.seasonMetaId,
            });
        }

        if (this.state.enabledConverting) {
            this.props.setError(
                "The Pilot Record will be converted to Episode 1 of the selected Season. It will also be assigned a new Meta ID.",
            );
        } else {
            this.props.setError("");
        }
    }

    render() {
        let circleLabelStyles = {
            opacity: "0.87",
            fontSize: "0.875rem",
            fontWeight: 300,
            lineHeight: 2.02,
            color: "rgba(110, 110, 110, 0.87)",
            paddingRight: "0.313rem",
        };

        let seasonSelectWrapperStyles = {
            paddingTop: "1.85rem",
            display: "flex",
            flexDirection: "column",
            flexFlow: "row-reverse",
        };

        let convertSettings = FetchConvertConfig({
            dataStructure: this.props.clientDataStructure,
            sourceCategory: this.props.category,
            targetCategory: "Episode",
        });

        return (
            <div className="new-linkage-season-search">
                <AutocompleteInput
                    id="new-linkage-season-search-input"
                    label="Search by Parent Show"
                    placeholder="Start typing Show name"
                    noneEntered={true}
                    value={this.state.searchValue}
                    staticDatalist={this.state.searchList}
                    single={true}
                    recordBasic={true}
                    keepSelected={true}
                    bubbleOnSelect={true}
                    callBackOnDelete={() =>
                        this.setState({
                            searchValue: null,
                            readySearchSelection: false,
                        })
                    }
                    handleListSelection={(item) =>
                        this.handleSearchSelection(item)
                    }
                    filterStaticDataList={true}
                    handleChange={(search) => {
                        DataFetch.links({
                            searchQuery: search,
                            searchUrl: this.props.searchUrl,
                            recordType: CategoryTags.Season.short,
                            onResult: this.updateSearchList,
                        });
                        this.setState({ readySearchSelection: false });
                    }}
                    styles={{
                        margin: "1.5rem 0 0 0",
                    }}
                    inputStyles={{
                        padding: "0.71875rem 0",
                    }}
                    keywordLabel={{
                        background: "rgb(69, 142, 107)",
                        postfix:
                            (this.state.searchValue &&
                                this.state.searchValue.postfix) ||
                            "",
                    }}
                />

                {this.state.readySearchSelection &&
                    this.includingTypesList.length > 1 && (
                        <DoubleGridList
                            id="new-linkage-season-grid-list-options"
                            label="Include Parent Show"
                            items={this.includingTypesList}
                            styles={{
                                margin: "1.8rem 0 0",
                            }}
                            selected={[this.state.seasonIncludingType]}
                            handleSelection={(value) => {
                                this.setState({ seasonIncludingType: value });
                            }}
                        />
                    )}

                {this.state.seasonIncludingType &&
                    !_.isEmpty(this.state.seasonsList) && (
                        <div
                            style={seasonSelectWrapperStyles}
                            className="new-linkage-season-select-season"
                        >
                            {convertSettings && (
                                <div
                                    style={{
                                        position: "absolute",
                                        margin: "-0.438rem 0 0 0",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <span style={circleLabelStyles}>
                                            Convert to Episode 1
                                        </span>
                                        <CircleInput
                                            background="#dfdfdf"
                                            innerBackground={
                                                this.state.enabledConverting
                                                    ? "#787878"
                                                    : "#dfdfdf"
                                            }
                                            width="1.34375rem"
                                            borderWidth="0.3rem"
                                            title="Link Record"
                                            onClick={(value) =>
                                                this.setState(
                                                    {
                                                        enabledConverting:
                                                            !this.state
                                                                .enabledConverting,
                                                    },
                                                    () => {
                                                        this.handleConvertingChange();
                                                    },
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                            <Select
                                label="Season"
                                disabledText="Select Season"
                                value={this.state.selectedSeason || ""}
                                handleChange={(seasonMetaId) =>
                                    this.handleSeasonSelection(seasonMetaId)
                                }
                                data={this.state.seasonsList}
                                showNullOption={false}
                            />
                        </div>
                    )}
            </div>
        );
    }
}
