import deepcopy from 'deepcopy'

const AssignName = props => {
    const {
        item,
        value
    } = props
    
    let credit = deepcopy(item)

    credit.name = typeof value == 'string' ? value : ''
    credit.character_name = null

    if(typeof value == 'object') {
        if(value.value) {
            credit.value = value.value
        }

        credit.script_names = value.script_names || []
        credit.script_character_names = value.script_character_names || []
        credit.character_name = value.character_name || item.character_name || null
        credit.first_name = value.first_name || null
        credit.last_name = value.last_name || null
        credit.middle_names = value.middle_names || null
        credit.alternative_name = value.alternative_name || null
        credit.title = value.title || null
        credit.contributor_id = value.contributor_id || null
        credit.date_of_birth = value.date_of_birth || null
        credit.imdb_id = value.imdb_id || null

        credit.name = value.text || value.name
        credit.updated = true
    }

    return credit
}

export default AssignName