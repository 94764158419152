import React, { useEffect, useReducer, useRef } from 'react'
import { useStore } from '../context'
import { setState, setScript, setNotification, setActiveCredit, setNewCredit } from "../reducer"
import Notification from '../../../../../components/Notification'
import _ from "lodash"
import SortContainer from './SortContainer'
import "./styles/_styles.scss"
import Button from '../../../../../components/Button'
import CreateOrUpdateCredit from './forms/CreateOrUpdateCredit'
import SelectLanguageTag from './forms/SelectLanguageTag'
import Boilerplate from '../../../../../components/RecordBody/views/Boilerplate'
import TableHeader from './TableHeader'
import { addLocalIdToCredit } from "../../../../../models/Record/credits";

const CreditsTable = props => {
    const {
        disabled: disabledProp = true,
        canCreateNew = true
    } = props

    const [store, dispatch] = useStore()
    
    const {
        disabled,
        script,
        notification = {},
        credits = [],
        activeCredit = {}
    } = store

    useEffect(() => {
        if(disabledProp != disabled) {
            dispatch(setState({disabled: disabledProp}))
        }
    }, [disabledProp])

    const notificationRef = useRef()
    notificationRef.current = notification

    const creditRef = useRef()
    creditRef.current = activeCredit

    const CreditCreate = () => {
        dispatch(setState({ activeCredit: {} }))
        dispatch(setNotification({
            html: <CreateOrUpdateCredit isNewCredit={true} />,
            confirm: () => {
                dispatch(setActiveCredit(
                    addLocalIdToCredit(creditRef.current)
                ))
                dispatch(setNewCredit())
                dispatch(setNotification({}))
            },
            disabled: true,
            okText: 'Create',
            title: 'Add New Credit',
            requirementText: "Credit Name and Role is required."
        }))
    }

    const groups = _.groupBy(credits.filter(credit => !credit.deleted), 'role')
    let containerTotalItems = 0

    return (
        <div className="credits-v2">
            <Boilerplate
                title={'Credits'}
                className={'meta-record-credits'}
                titleRightContent={
                    <div className={"credits-top-container"}>
                        {!disabledProp &&
                            <Button
                                value="Add New Credit"
                                onClick={() => CreditCreate()}
                                type="button"
                                className={'meta-add-new-credit'}
                                marginLeftTop={true}
                                disabled={!canCreateNew}
                            />
                        }
                        
                        <SelectLanguageTag
                            script={script}
                            handleChange={lang => dispatch(setScript(lang))}
                            noMargin={true}
                            marginLeft={true}
                        />
                    </div>
                }
                noBorder={true}
                titleStyles={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingBottom: '1rem',
                    borderBottom: '0.065rem solid rgb(229, 229, 229)'
                }}
            >
                <div className={"container header-container"}>
                    {
                        TableHeader(disabled).map(
                            (item, index) => <div key={index} className={item.className}><span>{item.label}</span></div>
                        )
                    }
                </div>
                <div className={"credits-container"}>
                    {
                        Object.keys(groups).map(role => {
                            containerTotalItems += groups[role].length
                            return(
                                <SortContainer
                                    credits={groups[role]}
                                    role={role}
                                    indexStartFrom={containerTotalItems - groups[role].length}
                                />
                            )
                        })
                    }
                </div>
            </Boilerplate>

            <Notification
                {...notification}
                intercationStatus="alert"
                background="white"
                onClick={() => {
                    dispatch(setActiveCredit({}))
                    dispatch(setNotification({}))
                }}
                closeOnWrapperClick={false}
                confirmOnEnter={false}
            />
        </div>
    )
}

export default CreditsTable