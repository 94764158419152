import { CoreSelect } from "../../../../../../core/Form/components/inputs/Select/components/CoreSelect";
import { PrimaryVT } from "../../../../config/types";
import { useBrandOptions } from "../../../../hooks/useBrandOptions";
import useGlobalData from "../../../../hooks/useGlobalData";
import { HideFormConditionally } from "./HideFormConditionally";

export const Brand = () => {
    const [, options] = useGlobalData();
    const brandOptions = useBrandOptions(options);
    return (
        <HideFormConditionally>
            <CoreSelect<PrimaryVT>
                config={{
                    path: "brand",
                    labelProps: {
                        text: "Brand",
                        isRequired: true,
                    },
                    options: brandOptions,
                    enableSearchbox: true,
                    multiple: false,
                }}
            />
        </HideFormConditionally>
    );
};
