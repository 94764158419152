export default function EclipseString(props) {
    const { string, length } = props;

    let result = string;

    if (typeof string == "string" && string.length > length && length) {
        result = string.substring(0, length) + "...";
    }

    return result;
}
