import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IconButton } from "@mui/material";
import { CoreTooltip } from "./CoreTooltip";

type TooltipInfoProps = {
    title: string | JSX.Element;
};
export const TooltipInfo = ({ title }: TooltipInfoProps) => {
    return (
        <CoreTooltip title={title}>
            <IconButton
                size="small"
                disableRipple
                sx={{
                    // color: disabled
                    //     ? "rgba(0, 0, 0, 0.37)"
                    //     : "rgba(0, 0, 0, 1)",
                    marginBottom: 0.7,
                }}
            >
                <InfoOutlinedIcon sx={{ fontSize: "13px" }} />
            </IconButton>
        </CoreTooltip>
    );
};
