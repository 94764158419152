import React from "react";
import Input from "../../Input/basic";
import Select from "../../Select";
import DoubleGridList from "../../CheckboxTag/DoubleGridList";
import WorkflowNotifications from "./WorkflowNotifications";
import { TransformMetaUsersToSimpleArray } from "./Notifications";
import _ from "lodash";

export default class OriginMasterCreateVersion extends React.Component {
    constructor(props) {
        super(props);

        const { dataStructure } = props;

        const destinationPlatforms = dataStructure.map(
            ({ system_option }) => system_option,
        );

        if (dataStructure.length !== 1) {
            destinationPlatforms.unshift({
                text: "Select Destination Platform",
                value: "",
                disabled: true,
            });
        }

        const selectedStructure =
            dataStructure.length === 1 ? dataStructure[0] : null;

        const destinationPlatform = selectedStructure
            ? selectedStructure.system_option.value
            : "";

        const defaultVersionCode = selectedStructure
            ? (selectedStructure.version_codes || []).find(
                  (code) => !!code.default,
              ).value
            : "";

        this.state = {
            destinationPlatforms,
            selectedStructure,
            destinationPlatform,
            mediaUrl: "",
            versionName: "",
            versionCode: defaultVersionCode || "",
            emails: {},
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.destinationPlatform !== prevState.destinationPlatform) {
            const { dataStructure } = this.props;

            const selectedStructure = dataStructure.find(
                ({ system_option }) =>
                    system_option.value === this.state.destinationPlatform,
            );
            const defaultVersionCode = (
                selectedStructure.version_codes || []
            ).find((code) => !!code.default);

            this.setState({
                selectedStructure,
                versionCode: defaultVersionCode.value,
            });
        }
    }

    handleVersionNameInput(data) {
        this.setState({ versionName: data }, () => this.onChange());
    }

    handleVersionCodeInput(data) {
        this.setState({ versionCode: data }, () => this.onChange());
    }

    onChange() {
        const { onChange } = this.props;

        if (typeof onChange === "function") {
            onChange(this.isValidToSubmit());
        }
    }

    submit() {
        if (!this.isValidToSubmit()) {
            return;
        }

        const { onSubmitLoad, onSubmitComplete } = this.props;

        const { emails = {} } = this.state;

        const { email_addresses = [], meta_users } = emails;

        const metaUsers = TransformMetaUsersToSimpleArray(meta_users);

        if (typeof onSubmitLoad === "function") {
            onSubmitLoad();
        }

        if (typeof onSubmitComplete === "function") {
            onSubmitComplete({
                destination_platform: this.state.destinationPlatform,
                version_code: this.state.versionCode,
                version_name: this.state.versionName,
                media_url: this.state.mediaUrl,
                email_addresses,
                meta_users: metaUsers,
                status: "pending",
            });
        }
    }

    isValidToSubmit() {
        return (
            !_.isEmpty(this.state.destinationPlatform) &&
            !_.isEmpty(this.state.versionCode) &&
            !_.isEmpty(this.state.versionName)
        );
    }

    handleWorkflowNotificationsChange(key, value) {
        const { emails = {} } = this.state;

        let updatedState = { ...emails };
        updatedState[key] = value;

        this.setState(
            {
                emails: updatedState,
            },
            () => this.onChange(),
        );
    }

    getTransformMetaUsersDataList() {
        const { meta_users = [] } = this.props;

        let result = [];

        if (Array.isArray(meta_users)) {
            result = meta_users.map((item) => {
                return {
                    text: item.fullName,
                    list: `${item.fullName} (${item.email})`,
                    value: item.email,
                };
            });
        }

        return result;
    }

    render() {
        const {
            destinationPlatforms,
            selectedStructure,
            emails = {},
        } = this.state;

        const { workFlowNotificationsInMaster } = selectedStructure || {};

        const { compliance = {}, meta_users = [] } = this.props;

        const versionCodes = selectedStructure
            ? selectedStructure.version_codes
            : [];

        return (
            <div>
                <div style={{ paddingTop: "1.5rem" }}>
                    <Select
                        label="Destination Platform"
                        name="destinationPlatform"
                        id="destination-platform"
                        value={this.state.destinationPlatform}
                        handleChange={(value) =>
                            this.setState({ destinationPlatform: value })
                        }
                        data={destinationPlatforms}
                        showNullOption={false}
                    />
                </div>

                <div style={{ paddingTop: "1.5rem" }}>
                    <Input
                        placeholder="Enter Version Name"
                        label="Version Name"
                        required={true}
                        color="#000000"
                        handleChange={(data) =>
                            this.handleVersionNameInput(data)
                        }
                        styles={{
                            boxShadow:
                                "rgb(207, 206, 206) 0px 0.0625rem 0px 0px",
                        }}
                    />
                </div>

                <div style={{ paddingTop: "1.5rem" }}>
                    <Input
                        placeholder="Enter Media URL"
                        label="Media URL"
                        name="media_url"
                        required={false}
                        value={this.state.mediaUrl}
                        color="#000000"
                        handleChange={(value) =>
                            this.setState({ mediaUrl: value })
                        }
                        styles={{
                            boxShadow:
                                "rgb(207, 206, 206) 0px 0.0625rem 0px 0px",
                        }}
                    />
                </div>

                <div style={{ paddingTop: "1.5rem" }}>
                    <DoubleGridList
                        label="Version Type"
                        items={versionCodes}
                        selected={this.state.versionCode}
                        handleSelection={(data) =>
                            this.handleVersionCodeInput(data)
                        }
                    />
                </div>

                {workFlowNotificationsInMaster && (
                    <WorkflowNotifications
                        emails={emails}
                        compliance={compliance}
                        transformedMetaUsersDatalist={this.getTransformMetaUsersDataList()}
                        meta_users={meta_users}
                        handleUpdate={this.handleWorkflowNotificationsChange.bind(
                            this,
                        )}
                    />
                )}
            </div>
        );
    }
}
