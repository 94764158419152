import { Button, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { isEmpty } from "lodash";
import { observer } from "mobx-react";
import { useCallback } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import DividerLine from "../../../../../../components/DividerLine";
import { CORE_FORM_STRING_DEFAULT_VALUE } from "../../../../../../core/Form/constants";
import { RenderCellOnlyText } from "../../../../../../core/Table/components/core-cell-v2/RenderCellOnlyText";
import {
    CoreTable,
    CORE_TABLE_CELL_ERROR_CLASSNAME,
} from "../../../../../../core/Table/CoreTable";
import { CoreTableGridColDef } from "../../../../../../core/Table/types";
import {
    ArrayOperation,
    arrayOperation,
} from "../../../../../../core/utils/arrayOperation";
import { C12 } from "../../../../config/clients/viacom/validation/constants";
import { AllFieldsVT, SequenceVT } from "../../../../config/types";
import { RED_COLOR } from "../../../../types/constants";
import randomNumber from "../../../../utils/randomNumber";
import { HideFormConditionally } from "../../standalone/fields/HideFormConditionally";
import { ChildRecordCategory } from "../components/ChildRecordCategory";
import store from "../store/store";
import { Categories } from "../types/types";
import { ExistingLinkedItems } from "./ExistingLinkedItems";
import { SequencingTitle } from "./SequencingTitle";

function getHeadersLabel(category: string, childCategory: string) {
    let headerName1: string;
    let headerName2: string;
    let headerName3: string;

    switch (category) {
        case Categories.Show:
        case Categories.Season: {
            headerName1 = "Season No.";
            headerName2 = "No. of Episodes";
            headerName3 = null;

            if (childCategory === Categories.Storyline) {
                headerName2 = "No. of Storylines";
            }

            if (childCategory === Categories.ModularEpisode) {
                headerName2 = "No. of Modular Eps.";
                headerName3 = "No. of Storylines per Ep.";
            }
            break;
        }
        case Categories.Episode:
            headerName1 = "Episode No.";
            headerName2 = "Original Production Number";
            headerName3 = null;

            if (childCategory === Categories.Storyline) {
                headerName1 = "Storyline No.";
            }

            break;
        case Categories.Storyline: {
            headerName1 = "Storyline No.";
            headerName2 = "Original Production Number";
            headerName3 = null;

            if (childCategory === Categories.Storyline) {
                headerName1 = "Storyline No.";
            }

            break;
        }
        case Categories.ModularEpisode: {
            headerName1 = "Modular Episode No.";
            headerName2 = "No. of Storylines";
            headerName3 = null;

            break;
        }

        default: {
            headerName1 = "headerName1";
            headerName2 = "headerName2";
            headerName3 = "headerName3";
            break;
        }
    }
    return { headerName1, headerName2, headerName3 };
}

const SequencingAddButton = observer(() => {
    const form = useFormContext<AllFieldsVT>();
    const { append } = useFieldArray({
        control: form.control,
        name: "sequences",
    });
    const childCategory = form.getValues("childCategory");
    const sequences = form.getValues("sequences") || [];
    // get maximum value of value1
    const maxValue1 = Math.max(
        ...sequences.map((sequence) => sequence.value1),
        0,
    );

    return (
        <Button
            sx={{
                backgroundColor: "rgba(47, 47, 47, 0.05)",
                borderRadius: "0.25rem",
                height: "1.3125rem",
            }}
            size="small"
            onClick={() => {
                const value1 = maxValue1 + 1;
                append({
                    id: randomNumber(),
                    childCategory,
                    value1,
                    value2: "",
                    value3: 0,
                });
            }}
        >
            <svg
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M7 4.5H4.5V7C4.5 7.275 4.275 7.5 4 7.5C3.725 7.5 3.5 7.275 3.5 7V4.5H1C0.725 4.5 0.5 4.275 0.5 4C0.5 3.725 0.725 3.5 1 3.5H3.5V1C3.5 0.725 3.725 0.5 4 0.5C4.275 0.5 4.5 0.725 4.5 1V3.5H7C7.275 3.5 7.5 3.725 7.5 4C7.5 4.275 7.275 4.5 7 4.5Z"
                    fill="#2F2F2F"
                    fillOpacity="0.87"
                />
            </svg>
        </Button>
    );
});

const RenderValue = ({
    params,
}: {
    params: GridRenderCellParams<any, any, any>;
}) => {
    const {
        value,
        colDef: { headerName },
    } = params;

    if (Number(value) <= 0) {
        return <RenderCellOnlyText value={`Enter ${headerName}`} />;
    }

    return <RenderCellOnlyText value={String(value)} />;
};

const TableContainer = observer(() => {
    const field1Path: keyof Pick<SequenceVT, "value1"> = "value1";
    const field2Path: keyof Pick<SequenceVT, "value2"> = "value2";
    const field3Path: keyof Pick<SequenceVT, "value3"> = "value3";

    const form = useFormContext<AllFieldsVT>();
    const getDefaultSequence = () => {
        return {
            id: randomNumber(),
            value1: 0,
            value2: "",
            value3: 0,
        };
    };

    const existingSequences = form.watch("sequences") || [];

    const sequences = isEmpty(existingSequences)
        ? [getDefaultSequence(), getDefaultSequence()]
        : existingSequences?.length === 1
        ? [...existingSequences, getDefaultSequence()]
        : existingSequences;

    const { update } = useFieldArray({
        control: form.control,
        name: "sequences",
    });

    const category = form.watch("category");
    const childCategory = form.watch("childCategory");

    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };

        // find the index of the row to be updated
        const index = sequences.findIndex((row) => row.id === updatedRow.id);
        update(index, updatedRow);

        form.trigger("sequences");

        return updatedRow;
    };

    const rows: SequenceVT[] = sequences;
    const columns: CoreTableGridColDef[] = [
        {
            type: "number",
            headerName: getHeadersLabel(category, childCategory).headerName1,
            field: field1Path,
            flex: 1,
            headerAlign: "left",
            align: "left",
            editable: true,
            sortable: false,
            renderCell: (params) => {
                return <RenderValue params={params} />;
            },
        },
        {
            type: "string",
            headerName: getHeadersLabel(category, childCategory).headerName2,
            field: field2Path,
            flex: 1.5,
            headerAlign: "left",
            align: "left",
            editable: true,
            sortable: false,
            renderCell: (params) => {
                return <RenderValue params={params} />;
            },
        },
    ];

    if (
        (category === Categories.Show || category === Categories.Season) &&
        childCategory === Categories.ModularEpisode
    ) {
        columns.push({
            type: "number",
            headerName: getHeadersLabel(category, childCategory).headerName3,
            field: field3Path,
            flex: 1.5,
            headerAlign: "left",
            align: "left",
            editable: true,
            sortable: false,
            renderCell: (params) => {
                return <RenderValue params={params} />;
            },
        });
    }

    const getCellClassName = useCallback(
        (params) => {
            const row = params.row as SequenceVT;

            if (row?.value1 <= 0) {
                return CORE_FORM_STRING_DEFAULT_VALUE;
            }

            if (params.field === "value2") {
                if (row?.value2 && String(row.value2?.length) > C12) {
                    return CORE_TABLE_CELL_ERROR_CLASSNAME;
                }
            }

            if (params.field === "value1") {
                const hasValue = arrayOperation<SequenceVT>({
                    array: sequences,
                    key: "value1",
                    operation: ArrayOperation.HAS_VALUE,
                    value: params?.value,
                });

                if (hasValue) {
                    return CORE_TABLE_CELL_ERROR_CLASSNAME;
                }
            }

            return CORE_FORM_STRING_DEFAULT_VALUE;
        },
        [sequences],
    );

    return (
        <CoreTable<SequenceVT>
            config={{
                state: "edit",
                tableName: "TV Shows Sequencing",
                emptyFallback: "No Sequences",
                leftFooterText: null,
            }}
            editMode="row"
            processRowUpdate={processRowUpdate}
            columns={columns}
            rows={rows}
            experimentalFeatures={{
                newEditingApi: true,
            }}
            onRowEditStart={(params) => {
                store.setIsSequencingTableInEditMode(true);
            }}
            onRowEditStop={(params) => {
                store.setIsSequencingTableInEditMode(false);
            }}
            hideFooter
            getCellClassName={getCellClassName}
        />
    );
});

const WarningMessage = styled(Typography)(({ theme }) => ({
    color: RED_COLOR,
}));

const SequencingTable = observer(() => {
    const form = useFormContext<AllFieldsVT>();
    const sequencesFieldState = form.getFieldState("sequences");

    return (
        <Stack direction="column" justifyContent="center" spacing={2}>
            <TableContainer />
            {store.isSequencingTableInEditMode && (
                <WarningMessage>
                    Press enter once finished typing to save the values
                </WarningMessage>
            )}
            {!isEmpty(sequencesFieldState?.error?.message) &&
                !store.isSequencingTableInEditMode && (
                    <WarningMessage>
                        {sequencesFieldState?.error?.message}
                    </WarningMessage>
                )}
            <SequencingAddButton />
        </Stack>
    );
});

export const SequencingSection = observer(() => {
    const form = useFormContext<AllFieldsVT>();
    const category = form.getValues("category");

    return (
        <HideFormConditionally>
            <br />
            <DividerLine />
            <br />
            <SequencingTitle />
            <br />
            {(category === Categories.Show ||
                category === Categories.Season ||
                category === Categories.Episode ||
                category === Categories.Storyline) && (
                <ExistingLinkedItems category={category} />
            )}
            {category === Categories.ModularEpisode && (
                <>
                    <ExistingLinkedItems category={Categories.Episode} />
                    <ExistingLinkedItems category={Categories.Storyline} />
                </>
            )}
            <ChildRecordCategory />

            <SequencingTable />
        </HideFormConditionally>
    );
});
