import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CoreFormCaption = styled(Typography)(({ theme }) => ({
    fontWeight: "400",
    fontSize: "0.875rem",
    lineHeight: "135%",
    marginTop: "0.625rem",
    color: "rgba(129, 129, 120, 0.67)",
}));
