import React from "react";
import _ from "lodash";
import $ from "jquery";
import deepcopy from "deepcopy";
import update from "react-addons-update";
import Index from "./Index";
import Notification from "../../Notification";
import CategoryTags from "../../CategoryTags";
import Button from "../../Button";
import Settings from "../../Settings";
import TrafficLight from "../../TrafficLight";
import DoubleTransparentTag from "../../DoubleTransparentTag";
import TitleValue from "../../TitleValue";
import ActionButton from "../../ActionButton";
import LocalisationOrderInfo from "../../LocalisationOrderInfo";
import LocalisationOrderLanguages from "../../LocalisationOrderLanguages";
import LocalisationLanguageManager from "../../LocalisationLanguageManager";
import "../styles/_collectionLocalisations.scss";
import AuthService from "../../../services/AuthService";
import SendRequest from "../../SendRequest";
import ExpandableParagraph from "../../ExpandableParagraph";
import isIndexInArray from "../../Helpers/isIndexInArray";
import AddNewRowButton from "../../AddNewRowButton";
import validateEmailAddress from "../../Helpers/validateEmailAddress";
import ClientManagedLanguages from "../../ClientManagedOptions/languages";

export default class CollectionLocalisations extends Index {
    constructor(props) {
        super(props);

        this.copy = this.props.copy || {};

        this.state = {
            notification: {},
            approving: false,
        };

        this.classPrefix = "meta-collection-localisations";

        this.trafficLightColor = {
            approved: "#2a8618",
            submitted: "#ff8a00",
            incomplete: "#838383",
        };

        this.grey = "#838383";
        this.lightGrey = "#d9d9d9";
        this.tagFontSize = "0.78125rem";
        this.fontWeight = 500;
    }

    getData() {
        return this.getActiveData();
    }

    getActiveData() {
        const { data } = this.props;

        let result = [];

        if (Array.isArray(data)) {
            result = data.filter((item) => !item.deleted);
        }

        return result;
    }

    canAddNew() {
        return !this.props.disabled;
    }

    getLocalisationStatus(data) {
        const { languages = [] } = data;

        const uniqueStatus = languages
            .map((lang) => lang.status)
            .filter((status, index, self) => self.indexOf(status) === index);

        if (uniqueStatus.length === 0) {
            return null;
        }

        if (uniqueStatus.length === 1) {
            return uniqueStatus[0];
        }

        return "submitted";
    }

    getActualIndex(length, index) {
        while (index < 0) {
            index = length + index;
        }
        return index;
    }

    getOrderByIndex(orderIndex) {
        const index = this.getActualIndex(this.props.data.length, orderIndex);
        return this.props.data[index];
    }

    animateClass(targetClass, e) {
        const openClassName = "open";
        const toggleClass = (ele) => {
            ele = $(ele);
            if (ele.hasClass(openClassName)) {
                ele.removeClass(openClassName);
            } else {
                ele.addClass(openClassName);
            }
        };

        $(`.${targetClass}`).slideToggle(200, function () {
            toggleClass(this);
        });

        const clickedEle = $(e.currentTarget);
        toggleClass(clickedEle);
        toggleClass(clickedEle.find(".indicator"));
    }

    handleAddNewRow() {
        if (!this.canAddNew()) {
            return;
        }

        this.openOrderInfoForm();
    }

    handleApprovalRequest(localisation_id) {
        const {
            state: {
                cmsData: { apiUrls = {} },
                updateLocalisations = () => {},
            },
            data = [],
        } = this.props;

        let url = apiUrls.approveLocalisationOrder || "";

        const options = {
            url: url.replace("{meta_id}", localisation_id),
            method: "PUT",
        };

        const handleError = (error) => {
            this.setState({
                approving: false,
            });

            if (error) {
                console.log(error);
            }
        };

        this.setState({
            approving: localisation_id,
        });

        SendRequest(
            options,
            (response) => {
                const { status, message } = response;

                if (status == 200 && response.data) {
                    const localisationIndex = _.findIndex(
                        data,
                        (item) => item.localisation_id == localisation_id,
                    );

                    if (localisationIndex !== -1) {
                        let updatedState = deepcopy(data);
                        let localisationOrder = updatedState[localisationIndex];

                        localisationOrder.status = "approved";

                        if (Array.isArray(localisationOrder.languages)) {
                            localisationOrder.languages =
                                localisationOrder.languages.map((language) => {
                                    return {
                                        ...language,
                                        status: "approved",
                                    };
                                });
                        }

                        this.setState({
                            approving: false,
                        });

                        updateLocalisations(updatedState);

                        return;
                    }
                }

                handleError(message);
            },
            (e) => {
                handleError(e);
                console.log(e);
            },
        );
    }

    openOrderInfoForm(formData = null, updateIndex = false) {
        const { updateLocalisations } = this.props.state;

        const updateState = Number.isInteger(updateIndex) ? true : false;

        const props = {
            clientFeatures: this.props.clientFeatures,
            state: this.props.state,
            initFormData: formData,
            update: updateState,
            onFormValidChange: (isValid) => {
                const notification = deepcopy(this.state.notification);
                notification.html = this.state.notification.html;
                notification.disabled = !isValid;
                this.setState({ notification });
            },
            onSubmit: (formData) => {
                if (updateState) {
                    const updatedState = update(this.props.data, {
                        [updateIndex]: {
                            $set: formData,
                        },
                    });

                    updateLocalisations(updatedState);
                    this.handleNotificationClose();

                    return;
                }

                this.openOrderLanguagesForm(formData);
            },
        };

        const ref = React.createRef();

        this.setState({
            notification: {
                html: <LocalisationOrderInfo {...props} ref={ref} />,
                okText: !updateState
                    ? this.copy.next || "Next"
                    : this.copy.update || "Update",
                background: "white",
                closeOnWrapperClick: false,
                confirmOnEnter: false,
                disabled: true,
                confirm: () => {
                    if (ref.current) {
                        ref.current.submit();
                    }
                },
            },
        });
    }

    openOrderLanguagesForm(formData) {
        const { clientFeatures = {} } = this.props;

        const props = {
            state: this.props.state,
            orderData: formData,
            clientFeatures,
            onFormValidChange: (isValid) => {
                const notification = deepcopy(this.state.notification);
                notification.html = this.state.notification.html;
                notification.disabled = !isValid;
                const saveOpenButton = notification.customMiddleButton.find(
                    (button) => button.id == "save_open",
                );
                if (saveOpenButton) {
                    saveOpenButton.disabled = !isValid;
                }
                this.setState({ notification });
            },
        };

        const { addNewLocalisation } = this.props.state;

        const ref = React.createRef();

        this.setState({
            notification: {
                html: <LocalisationOrderLanguages {...props} ref={ref} />,
                okText: this.copy.saveClose || "Save + Close",
                background: "white",
                closeOnWrapperClick: false,
                confirmOnEnter: false,
                disabled: true,
                wrapperStyle: {
                    maxWidth: "none",
                    width: "100%",
                },
                confirm: () => {
                    if (!ref.current) {
                        return;
                    }
                    const data = ref.current.submit();
                    if (!data) {
                        return;
                    }
                    addNewLocalisation(data);
                    this.handleNotificationClose();
                },
                customMiddleButton: [
                    {
                        text: "Back",
                        onClick: () => this.openOrderInfoForm(formData),
                    },
                    {
                        id: "save_open",
                        text: "Save + Open Language Manager",
                        disabled: true,
                        onClick: () => {
                            if (!ref.current) {
                                return;
                            }
                            const data = ref.current.submit();
                            if (!data) {
                                return;
                            }

                            addNewLocalisation(data, () =>
                                this.openLanguageManager(-1),
                            );
                        },
                    },
                ],
            },
        });
    }

    validateLanguage(language) {
        if (_.isEmpty(language)) {
            return false;
        }

        let valid = true;
        let validationErrors = [];

        const {
            user_notification,
            first_use_date,
            turnaround_time,
            type = [],
            studio,
            compliance,
            language_notes,
        } = language;

        if (
            _.isEmpty(user_notification) ||
            _.isEmpty(first_use_date) ||
            _.isEmpty(turnaround_time) ||
            _.isEmpty(type) ||
            _.isEmpty(studio) ||
            _.isEmpty(compliance)
        ) {
            valid = false;
        }

        if (!_.isEmpty(language_notes) && typeof language_notes == "string") {
            if (language_notes.length > 200) {
                valid = false;
            }
        }

        if (_.isEmpty(language_notes) && studio == "Other Studio") {
            valid = false;
        }

        if (
            !_.isEmpty(user_notification) &&
            !validateEmailAddress(user_notification)
        ) {
            valid = false;
        }

        return valid;
    }

    validateLanguages(data = {}) {
        let valid = false;

        const { languages = [] } = data;

        if (Array.isArray(languages)) {
            valid = languages.some(this.validateLanguage);
        }

        return valid;
    }

    validateAllLanguages(data) {
        let valid = false;

        const { languages = [] } = data;

        if (Array.isArray(languages)) {
            valid = languages.every(this.validateLanguage);
        }
        return valid;
    }

    handleFetchTitlesCompliances(props) {
        const { onSucess, onError, data = {}, langIndex } = props;

        const { languages = [] } = data;

        const { state = {}, linkage = [] } = this.props;

        const { cmsData = {} } = state;

        const { apiUrls = {} } = cmsData;

        const filteredCategories = [
            CategoryTags.Show.short,
            CategoryTags.Season.short,
        ];

        const language = languages[langIndex] || {};
        const compliance = language.compliance;
        const filteredLinkage =
            linkage.filter(
                (item) => !isIndexInArray(filteredCategories, item.category),
            ) || [];

        const options = {
            url: apiUrls.checkTitlesCompliances,
            method: "POST",
            body: {
                meta_ids: filteredLinkage.map((item) => item.meta_id),
                record_ids: filteredLinkage.map(
                    (item) => item.link_record_id || item.id,
                ),
                compliances: [compliance],
            },
        };

        SendRequest(
            options,
            (response) => {
                const { status, message, data } = response;

                if (status == 200) {
                    return onSucess(data);
                }

                return onError(message);
            },
            (e) => {
                console.log(e);
                return onError(e);
            },
        );
    }

    transformComplianceResponse(props) {
        const { complianceResponse } = props;

        const { data = [] } = complianceResponse;

        let result = {
            moreParagraphs: [],
        };

        if (Array.isArray(data)) {
            const compliances = data.map((item) => item.compliances) || [];
            const uniqueCompliances = _.chain(compliances)
                .flatten()
                .uniq()
                .join(", ")
                .value();

            result.firstParagraph = `Compliance ${uniqueCompliances} is not available for the below Titles in this Collection, please create placeholder using Meta at record creation level or complete a Content and Compliance Request in Jira <br />
				<a target="_blank" href="https://jiraprod.turner.com/projects/MRR/issues/">
					(https://jiraprod.turner.com/projects/MRR/issues/):
				</a>`;

            data.forEach((item) => {
                const { original_title = "", international_id } = item;

                result.moreParagraphs.push(
                    `${
                        international_id ? international_id + " - " : ""
                    } ${original_title}`,
                );
            });
        }

        return result;
    }

    checkForCompliance(props) {
        const { data, langIndex } = props;

        let notification = deepcopy(this.state.notification);
        notification.loading = true;

        this.setState(
            {
                notification,
            },
            async () => {
                // Check for compliances
                let promise = new Promise((resolve, reject) => {
                    this.handleFetchTitlesCompliances({
                        data,
                        langIndex,
                        onSucess: (response) => resolve(response),
                        onError: (error) => {
                            notification.loading = false;
                            notification.errorMessage =
                                "Failed to check for compliances. Please try again.";

                            this.setState({
                                notification,
                            });

                            reject(error);
                        },
                    });
                });

                let complianceResponse = await promise;

                if (complianceResponse && complianceResponse.type == "error") {
                    const RenderHtml = () => {
                        const paragraphData = this.transformComplianceResponse({
                            complianceResponse,
                        });

                        return (
                            <ExpandableParagraph
                                firstParagraph={paragraphData.firstParagraph}
                                moreParagraphs={paragraphData.moreParagraphs}
                            />
                        );
                    };

                    notification.errorMessageHtml = <RenderHtml />;
                    notification.loading = false;

                    this.setState({
                        notification,
                    });

                    return;
                }

                notification.errorMessageHtml = false;
                notification.loading = false;

                this.setState({
                    notification,
                });
            },
        );
    }

    handleDeleteLanguage(orderIndex, locale) {
        this.setState({
            notification: {
                title: "Delete Language",
                description: `Are you sure you want to delete this language? (${locale})`,
                confirm: () => this.deleteLanguage(orderIndex, locale),
            },
        });
    }

    isStatusApproved(data) {
        return data && data.status == "approved";
    }

    deleteLanguage(orderIndex, locale) {
        const { updateLocalisations } = this.props.state;

        const data = this.getActiveData();
        const languageIndex = _.findIndex(
            data[orderIndex].languages,
            function (item) {
                return item.locale == locale;
            },
        );

        if (Number.isInteger(languageIndex)) {
            const updatedState = update(data, {
                [orderIndex]: {
                    languages: {
                        $splice: [[languageIndex, 1]],
                    },
                },
            });

            updateLocalisations(updatedState);
            return this.handleNotificationClose();
        }

        this.setState({
            notification: {
                title: "Technical Error",
                description:
                    "Failed to delete localisation language, please try again.",
            },
        });
    }

    getLanguages() {
        const {
            state: {
                options: {
                    localisations: { languages = [] },
                },
            },
        } = this.props;

        const { clientFeatures } = this.props;

        const result = ClientManagedLanguages({
            options: this.props.options,
            spoken: true,
            fallback: languages,
            clientFeatures,
            languageKey: "localisation_orders",
        });

        return result;
    }

    openLanguageManager(orderIndex, locale = null) {
        const ref = React.createRef();
        let data = this.getOrderByIndex(orderIndex);
        const disabled = !this.validateLanguages(data);

        const { updateLocalisations } = this.props.state;

        const {
            clientFeatures = {},
            clientDataStructure = {},
            options,
            state = {},
        } = this.props;

        const { cmsData = {} } = state;

        const props = {
            state: this.props.state,
            data,
            statusColors: this.trafficLightColor,
            validateLanguages: this.validateLanguages.bind(this),
            validateLanguage: this.validateLanguage.bind(this),
            disabled,
            clientFeatures,
            clientDataStructure,
            options,
            onFormChange: (props) => {
                const { isValid, newData, key, langIndex } = props;

                if (key == "compliance") {
                    if (
                        process.env.NODE_ENV !== "test" &&
                        clientFeatures.complianceAssetsCheck
                    ) {
                        this.checkForCompliance({
                            data: newData,
                            langIndex,
                        });

                        return;
                    }
                }

                if (isValid !== undefined) {
                    const notification = deepcopy(this.state.notification);
                    notification.html = this.state.notification.html;
                    notification.disabled = !isValid;

                    this.setState({ notification });
                }
            },
        };

        const RenderHtml = (htmlProps) => {
            let localisationLanguageManagerProps = {
                ...props,
                cmsData,
                disabled: htmlProps.disabled === false ? false : disabled,
            };

            return (
                <LocalisationLanguageManager
                    {...localisationLanguageManagerProps}
                    initLocale={locale}
                    meta_id={this.props.meta_id}
                    ref={ref}
                />
            );
        };

        this.setState({
            notification: {
                html: <RenderHtml />,
                okText: this.copy.saveClose || "Save + Close",
                background: "white",
                closeOnWrapperClick: false,
                confirmOnEnter: false,
                contentNoPadding: true,
                disabled,
                wrapperStyle: {
                    maxWidth: "none",
                    width: "100%",
                },
                confirm: () => {
                    if (!ref.current) {
                        return;
                    }

                    const data = ref.current.submit();

                    if (!data) {
                        return;
                    }

                    const newData = deepcopy(this.props.data);
                    newData.splice(orderIndex, 1, data);

                    updateLocalisations(newData);
                    this.handleNotificationClose();
                },
            },
        });
    }

    resendNotification(data, localisation_id) {
        const {
            state: {
                cmsData: { apiUrls = {} },
            },
            meta_id,
        } = this.props;

        let url = apiUrls.resendLocalisationNotification || "";

        url = url
            .replace("{metaId}", meta_id)
            .replace("{locMetaId}", localisation_id)
            .replace("{locale}", data.locale);

        const options = {
            url,
            method: "PUT",
        };

        const handleError = (error) => {
            const notification = {
                okText: this.copy.ok || "Ok",
                onClick: this.handleNotificationClose.bind(this),
                title: this.copy.technicalError || "Technical Error",
                description:
                    this.copy.resendNotificationDescription ||
                    "Email notification did not send, please try again",
                status: "alert",
            };

            this.setState({
                notification,
            });

            console.log(error);
        };

        SendRequest(
            options,
            (response) => {
                const { status, message } = response;

                if (status == 200) {
                    return;
                }

                handleError(message);
            },
            (e) => {
                handleError(e);
                console.log(e);
            },
        );
    }

    handleNotificationClose() {
        this.setState({
            notification: {},
        });
    }

    handleNotificationResend(data, localisation_id) {
        const notification = {
            okText: this.copy.resend || "Resend",
            onClick: this.handleNotificationClose.bind(this),
            title: this.copy.resendNotification || "Resend Notifications",
            description:
                this.copy.resendNotificationDescription ||
                "Are you sure you want to resend notification?",
            status: "alert",
            confirm: () => {
                this.resendNotification(data, localisation_id);
                this.handleNotificationClose();
            },
        };

        this.setState({
            notification,
        });
    }

    renderIcon(name) {
        return (
            <img className="icon" src={`${Settings.images.path}/svg/${name}`} />
        );
    }

    renderButton(options = {}) {
        const {
            value,
            className,
            onClick,
            margin = "0.90625rem 0 0.90625rem 0.90625rem",
            disabled,
            id,
        } = options;

        return (
            <Button
                id={id}
                value={value}
                type="button"
                className={className}
                margin={margin}
                disabled={disabled}
                disabledOpacity={disabled}
                padding="0.34375rem"
                background="#8f8f8f"
                hoverBackground="#9a9a9a"
                fontSize="0.78125rem"
                color="white"
                display="inline-block"
                onClick={onClick}
                leftIcon={`${Settings.images.path}/svg/new-record-white.svg`}
                leftIconStyles={{
                    height: "0.75rem",
                    marginRight: "0.1875rem",
                }}
            />
        );
    }

    renderAddNewButton() {
        const { canCreateNew = true, disabled } = this.props;

        if (!this.canAddNew()) {
            return;
        }

        const data = this.getActiveData();

        return (
            <AddNewRowButton
                disabled={!canCreateNew || disabled}
                onClick={this.handleAddNewRow.bind(this)}
                data={data}
                className="localisations-meta-add-row"
                styles={{
                    marginTop: _.isEmpty(data) ? "1.5rem" : 0,
                }}
            />
        );
    }

    renderLocalisations() {
        const data = this.getActiveData();

        if (_.isEmpty(data)) {
            return;
        }

        const noBorder = !_.isEmpty(data) && !this.props.disabled;

        return (
            <div
                className={`${this.classPrefix} ${
                    noBorder ? "no-border-radius" : ""
                }`}
            >
                {data.map((item, index) => {
                    return (
                        <div
                            key={`${this.classPrefix}-${index}`}
                            className={`${this.classPrefix}-item-container`}
                        >
                            {this.renderLocalisation(item, index)}
                        </div>
                    );
                })}
            </div>
        );
    }

    renderLocalisation(data, index) {
        const targetClassNamePrefix = `${this.classPrefix}-item-details`;
        const targetClassName = `${targetClassNamePrefix}-${index}`;
        return (
            <React.Fragment>
                <div
                    className={`${this.classPrefix}-item-header header`}
                    onClick={this.animateClass.bind(this, targetClassName)}
                >
                    {this.renderLocalisationHeader(data)}
                </div>
                <div className={`${targetClassNamePrefix} ${targetClassName}`}>
                    {this.renderLocalisationInfo(data, index)}
                    {this.renderLanguages(data, index)}
                </div>
            </React.Fragment>
        );
    }

    renderLocalisationHeader(data) {
        const status = this.getLocalisationStatus(data);

        return (
            <React.Fragment>
                <div className="front">
                    {this.renderOpenCloseIndicator()}
                    <div className="icon-container">
                        {this.renderIcon("translation.svg")}
                    </div>
                    <div className="title">
                        {data.localisation_request_title}
                    </div>
                </div>
                <div className="rear">
                    <DoubleTransparentTag
                        textLeft={"Localisation ID"}
                        textRight={data.localisation_id || "-"}
                        fontSize={this.tagFontSize}
                        leftBorderColor={this.grey}
                        leftBackgroundColor="transparent"
                        leftColor={this.grey}
                        rightBackgroundColor={this.grey}
                        rightColor={this.lightGrey}
                        fontWeight={this.fontWeight}
                        className={`${this.classPrefix}-item-id-tag`}
                    />
                    <TrafficLight
                        status={status}
                        statusColors={this.trafficLightColor}
                        style={{
                            marginLeft: "0.875rem",
                        }}
                    />
                </div>
            </React.Fragment>
        );
    }

    renderOpenCloseIndicator() {
        return (
            <div className="indicator icon-container">
                {this.renderIcon("chevron-bottom-light-grey.svg")}
            </div>
        );
    }

    renderLocalisationInfo(data, index) {
        const { approving } = this.state;

        const {
            localisation_id = "-",
            localisation_requester = "-",
            po_number = "-",
            content_type = "",
            subtype = "",
            regions = [],
            created_at = "-",
            languages = [],
        } = data;

        const { disabled, state = {} } = this.props;
        const {
            options: {
                localisations: { content_subtypes = [] },
            },
        } = state;
        const getSubtypeCaption = (sub_type) => {
            const contentSubType = content_subtypes.find(
                (type) => type.value === sub_type,
            );
            return contentSubType ? contentSubType.text : sub_type;
        };

        const isUniqueFilter = (type, index, self) =>
            self.indexOf(type) === index;

        const studios = languages
            .map((lang) => lang.studio)
            .filter((studio) => studio)
            .filter(isUniqueFilter);

        let studio = "-";
        if (studios.length === 1) {
            studio = studios[0];
        } else if (studios.length > 1) {
            studio = "Multiple";
        }

        const orderType = languages
            .flatMap((lang) => lang.type || [])
            .filter(isUniqueFilter)
            .sort()
            .join(", ");

        const status = this.getLocalisationStatus(data);
        const orderApproved = status == "approved";
        const allowOrderApproval = this.validateAllLanguages(data);

        return (
            <div className={`${this.classPrefix}-item-info`}>
                <div className={`${this.classPrefix}-item-info-entity meta_id`}>
                    <TitleValue
                        title="Fabric Localisation ID"
                        value={localisation_id}
                    />
                </div>
                <div
                    className={`${this.classPrefix}-item-info-entity created_by`}
                >
                    <TitleValue
                        title="Created by User"
                        value={localisation_requester}
                    />
                </div>
                <div
                    className={`${this.classPrefix}-item-info-entity long po_number`}
                >
                    <TitleValue title="PO Number" value={po_number} />
                </div>
                <div
                    className={`${this.classPrefix}-item-info-entity long content_type`}
                >
                    <TitleValue
                        title="Content Type"
                        value={`${content_type}, ${getSubtypeCaption(
                            subtype,
                        )}, ${regions[0] || ""}`}
                    />
                </div>
                <div
                    className={`${this.classPrefix}-item-info-entity created_at`}
                >
                    <TitleValue title="Created" value={created_at} />
                </div>
                <div className={`${this.classPrefix}-item-info-entity studio`}>
                    <TitleValue title="Localisation Studio" value={studio} />
                </div>
                <div
                    className={`${this.classPrefix}-item-info-entity long order_type`}
                >
                    <TitleValue title="Order Type" value={orderType} />
                </div>
                <div className={`${this.classPrefix}-item-info-entity action`}>
                    {!disabled && (
                        <React.Fragment>
                            <ActionButton
                                text={this.copy.edit || "Edit"}
                                background="#8f8f8f"
                                disabledBackground="#8f8f8f"
                                disabledColor="white"
                                hoverBackground="#9a9a9a"
                                margin="0"
                                onClick={this.openOrderInfoForm.bind(
                                    this,
                                    data,
                                    index,
                                )}
                            />

                            {AuthService.hasPermission(
                                "localisations.approve",
                            ) &&
                                data.localisation_id &&
                                !orderApproved && (
                                    <ActionButton
                                        text={
                                            this.copy.submitOrder ||
                                            "Approve & Submit Order"
                                        }
                                        background="#2a8618"
                                        disabledBackground="#2a8618"
                                        disabledColor="white"
                                        hoverBackground="#2a8618"
                                        margin="0 0 0 0.5rem"
                                        loading={
                                            approving == data.localisation_id
                                        }
                                        onClick={this.handleApprovalRequest.bind(
                                            this,
                                            data.localisation_id,
                                        )}
                                        disabled={!allowOrderApproval}
                                        disabledOpacity={true}
                                    />
                                )}

                            {orderApproved && (
                                <ActionButton
                                    text={this.copy.approved || "Approved"}
                                    background="#2a8618"
                                    disabledBackground="#2a8618"
                                    disabledColor="white"
                                    hoverBackground="#2a8618"
                                    margin="0 0 0 0.5rem"
                                    disabled={true}
                                />
                            )}
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    }

    renderLanguages(data, index) {
        const targetClassNamePrefix = `${this.classPrefix}-item-lang`;
        const targetClassName = `${targetClassNamePrefix}-${index}`;
        const { languages = [], localisation_id } = data;

        const sortedLanguages = _.sortBy(languages, ["language"]);

        return (
            <React.Fragment>
                <div
                    className={`${this.classPrefix}-item-lang-title header`}
                    onClick={this.animateClass.bind(this, targetClassName)}
                >
                    {this.renderLanguageTitle(data)}
                </div>
                <div className={`${targetClassNamePrefix} ${targetClassName}`}>
                    <div
                        className={`${this.classPrefix}-item-lang-details-header row`}
                    >
                        {this.renderLanguageHeader(index)}
                    </div>
                    {sortedLanguages.map((language, langIndex) => {
                        return (
                            <div
                                key={`${targetClassName}-${langIndex}`}
                                className={`${this.classPrefix}-item-lang-details row`}
                            >
                                {this.renderLanguage(
                                    index,
                                    language,
                                    localisation_id,
                                )}
                            </div>
                        );
                    })}
                </div>
            </React.Fragment>
        );
    }

    renderLanguageTitle(data) {
        const status = this.getLocalisationStatus(data);
        return (
            <React.Fragment>
                <div className="front">
                    {this.renderOpenCloseIndicator()}
                    <div className="icon-container">
                        {this.renderIcon("globe-grey.svg")}
                    </div>
                    <div className="title">Language</div>
                </div>
                <div className="rear">
                    <TrafficLight
                        status={status}
                        statusColors={this.trafficLightColor}
                        style={{
                            marginLeft: "0.875rem",
                        }}
                    />
                </div>
            </React.Fragment>
        );
    }

    renderLanguageHeader(index) {
        const { disabled } = this.props;

        return (
            <React.Fragment>
                <div className="language">Languages</div>
                <div className="locale">Code</div>
                <div className="email">User Notification</div>
                <div className="resend">Resend Notification?</div>
                <div className="status">Status</div>
                <div className="open">
                    {!disabled && (
                        <ActionButton
                            text={this.copy.openAll || "Open All"}
                            background="#8f8f8f"
                            disabledBackground="#8f8f8f"
                            disabledColor="white"
                            hoverBackground="#9a9a9a"
                            margin="0 auto"
                            onClick={() => this.openLanguageManager(index)}
                        />
                    )}
                </div>
                <div className="remove">
                    {!disabled && <span>Remove Language</span>}
                </div>
            </React.Fragment>
        );
    }

    renderLanguage(parentIndex, data, localisation_id) {
        const { disabled } = this.props;

        const isApproved = this.isStatusApproved(data);
        const languages = this.getLanguages();

        const language =
            languages.find((language) => language.value === data.locale) || {};
        const displayLanguageText = language.text || data.language || "";
        const displayLanguageCode =
            language.tmc_code || language.value || data.locale || "";

        return (
            <React.Fragment>
                <div className="language">{displayLanguageText}</div>
                <div className="locale">{displayLanguageCode}</div>
                <div className="email">{data.user_notification || "-"}</div>
                <div className="resend">
                    {data.user_notification && (
                        <ActionButton
                            text={this.copy.resend || "Resend"}
                            background="#8f8f8f"
                            disabledBackground="#8f8f8f"
                            disabledColor="lightgrey"
                            hoverBackground="#9a9a9a"
                            margin="0 auto"
                            disabled={!localisation_id}
                            onClick={this.handleNotificationResend.bind(
                                this,
                                data,
                                localisation_id,
                            )}
                        />
                    )}

                    {!data.user_notification && <span>-</span>}
                </div>
                <div className="status">{data.status || "-"}</div>
                <div className="open">
                    {!disabled && (
                        <ActionButton
                            text={this.copy.open || "Open"}
                            background="#8f8f8f"
                            disabledBackground="#8f8f8f"
                            disabledColor="white"
                            hoverBackground="#9a9a9a"
                            margin="0 auto"
                            className={`${this.classPrefix}-open-language`}
                            onClick={() =>
                                this.openLanguageManager(
                                    parentIndex,
                                    data.locale,
                                )
                            }
                        />
                    )}
                </div>

                <div className="remove">
                    {!disabled && (
                        <ActionButton
                            text="Remove"
                            background={Settings.colors.red.default}
                            hoverBackground={Settings.colors.red.light}
                            margin="0 auto"
                            disabled={isApproved}
                            className={`${this.classPrefix}-delete-language`}
                            onClick={this.handleDeleteLanguage.bind(
                                this,
                                parentIndex,
                                data.locale,
                            )}
                        />
                    )}
                </div>

                <div className="traffic-light">
                    <TrafficLight
                        status={data.status}
                        statusColors={this.trafficLightColor}
                        style={{
                            marginLeft: "0.875rem",
                        }}
                    />
                </div>
            </React.Fragment>
        );
    }

    renderNotification() {
        const { notification = {} } = this.state;

        if (!_.isEmpty(notification)) {
            return (
                <Notification
                    title={notification.title || false}
                    description={notification.description || false}
                    html={notification.html || false}
                    bottomIntercationHtml={
                        notification.bottomIntercationHtml || false
                    }
                    okText={notification.okText || undefined}
                    confirmOnEnter={notification.confirmOnEnter}
                    closeOnWrapperClick={notification.closeOnWrapperClick}
                    disabled={notification.disabled || false}
                    loading={notification.loading || false}
                    errorMessageHtml={notification.errorMessageHtml}
                    errorMessage={notification.errorMessage}
                    intercationStatus={
                        notification.intercationStatus || "alert"
                    }
                    background={notification.background}
                    wrapperStyle={notification.wrapperStyle || false}
                    contentNoPadding={notification.contentNoPadding}
                    confirm={notification.confirm || false}
                    cancelClick={notification.cancelClick || false}
                    onClick={
                        notification.onClick ||
                        this.handleNotificationClose.bind(this)
                    }
                    customMiddleButton={
                        notification.customMiddleButton || false
                    }
                />
            );
        }
    }

    renderContent() {
        return (
            <React.Fragment>
                {this.renderLocalisations()}
                {this.renderAddNewButton()}
                {this.renderNotification()}
            </React.Fragment>
        );
    }
}
