import { Tab, styled } from "@mui/material";

const StyledTab = styled(Tab)(({ theme }) => ({
    borderRadius: "0.25rem",
    textTransform: "capitalize",
    fontSize: "0.875rem",
    fontWeight: "400",
    letterSpacing: "unset",
    padding: "0.3125rem",
    margin: "0 0.625rem 0.5rem 0",
    minWidth: "unset",
    minHeight: "unset",
    lineHeight: "normal",
    transition: "all 0.3s ease",
    "&:hover": {
        background: "#e9e9e9",
    },
    "&.Mui-selected": {
        "&:hover": {
            background: "none",
        },
    },
}));

StyledTab.defaultProps = {
    disableFocusRipple: true,
    disableRipple: true,
};

export default StyledTab;
