
import React from "react";
import Input from "../../interfaces/Input.interface";
import { CoreInputDatePicker } from "../../../components/inputs/DateTime/CoreInputDatePicker";
import { observer } from "mobx-react";
import { useStores } from '../../store/rootStore'
import getFieldDateTimeFormat from "../utils/DateTimeFormat";

export const DatePicker = observer(({ field }: Input) => {
    const { formStore } = useStores()

    return (
        <div data-testid={"fabric-dynamic-form-datepicker-input"}>
            <CoreInputDatePicker
                disabled={field.validation?.readOnly?.enabled || formStore.disabled}
                path={field.key}
                labelProps={{
                    text: field.label,
                    isRequired: field.validation?.required?.enabled,
                }}
                noneElemnt={true}
                format={getFieldDateTimeFormat(field)}
            />
        </div>
    )
})