import ActiveLicenceCheck from "../../ActiveLicenceCheck";

export const statusOnHiatus = "on hiatus";
export const statusArchived = "archived";
export const statusActive = "active";
export const statusInactive = "inactive";

const disabledStatuses = [statusArchived];

function getRightStatus(right) {
    const {
        license_start,
        license_end,
        status,
        hiatus_type,
        hiatus_start_date,
        hiatus_end_date,
    } = right;

    let result = statusInactive;

    if (
        license_start &&
        license_end &&
        ActiveLicenceCheck(license_start, license_end)
    ) {
        result = statusActive;
    }

    if (hiatus_type && ActiveLicenceCheck(hiatus_start_date, hiatus_end_date)) {
        result = statusOnHiatus;
    }

    if (typeof status == "string") {
        result = status;
    }

    return result.toLocaleLowerCase();
}

export default function Status(right = {}) {
    const status = getRightStatus(right);
    let statusIsDisabled = disabledStatuses.includes(status);

    if (right.children) {
        statusIsDisabled = true;
    }

    return {
        status,
        statusIsDisabled,
    };
}
