import React from "react";
import Settings from "../Settings";
import "./styles/_styles.scss";

function PageLoading() {
    return (
        <div
            className="app-loading"
            style={{ background: Settings.colors.grey.light }}
        >
            <img src={Settings.images.loadingDotsWhite} />
        </div>
    );
}

export default PageLoading;
