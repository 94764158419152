import React from "react";
import _ from "lodash";
import AssetRow from "./AssetRow";
import AssetInterface from "./interfaces/AssetInterface";
import { useStore } from "../../state/context";
import GetSectionConfiguration, {
    SUBTITLE_SECTION,
    AUDIO_SECTION,
} from "./section/AdditionalSection";
import GetAssetTypeConfiguration from "../../helpers/GetAssetTypeConfig";
import "../styles/_styles.scss";

type AssetProps = {
    asset: AssetInterface;
    rowIndex: number;
};

const Asset: React.FC<AssetProps> = (props) => {
    const { asset, rowIndex = 0 } = props;

    const [store] = useStore();

    const { configuration } = store;

    const assetConfiguration = GetAssetTypeConfiguration(configuration, asset);

    const AllAssets = (
        assetItem: AssetInterface,
        hierarchyLevel: number,
        isLastChild: boolean,
        assets: Array<any>,
    ) => {
        let result = assets;

        if (assetItem.deleted) {
            return result;
        }

        result.push({
            hierarchyLevel,
            isLastChild,
            asset: assetItem,
        });

        let assetRows: AssetInterface[] = [];
        if (!_.isEmpty(assetItem.child_assets)) {
            assetRows = assetRows.concat(assetItem.child_assets);
        }

        const subtitleSectionConfiguration =
            assetConfiguration &&
            GetSectionConfiguration({
                sectionKey: SUBTITLE_SECTION,
                typeConfiguration: assetConfiguration,
            });

        if (
            subtitleSectionConfiguration &&
            _.get(
                subtitleSectionConfiguration,
                "options.dataPath.path",
                false,
            ) &&
            !_.isEmpty(
                assetItem[
                    _.get(
                        subtitleSectionConfiguration,
                        "options.dataPath.path",
                        false,
                    )
                ],
            )
        ) {
            assetRows = assetRows.concat(
                assetItem[
                    _.get(subtitleSectionConfiguration, "options.dataPath.path")
                ].map((item: AssetInterface) => {
                    item.type = SUBTITLE_SECTION;
                    item.parentType = assetItem.type;
                    return item;
                }),
            );
        }

        const audioSectionConfiguration =
            assetConfiguration &&
            GetSectionConfiguration({
                sectionKey: AUDIO_SECTION,
                typeConfiguration: assetConfiguration,
            });

        if (
            audioSectionConfiguration &&
            _.get(audioSectionConfiguration, "options.dataPath.path", false) &&
            !_.isEmpty(
                assetItem[
                    _.get(
                        audioSectionConfiguration,
                        "options.dataPath.path",
                        false,
                    )
                ],
            )
        ) {
            assetRows = assetRows.concat(
                assetItem[
                    _.get(audioSectionConfiguration, "options.dataPath.path")
                ].map((item) => {
                    item.parentType = assetItem.type;
                    item.type = AUDIO_SECTION;
                    return item;
                }),
            );
        }

        assetRows.map((item, key) => {
            result.concat(
                AllAssets(
                    item,
                    hierarchyLevel + 1,
                    key == assetRows.length - 1,
                    result,
                ),
            );
        });

        return result;
    };

    const assetsSorted = AllAssets(asset, 0, false, []);

    return (
        <div className="asset-container">
            {assetConfiguration &&
                assetsSorted.map((item, key) => {
                    return (
                        <AssetRow
                            asset={item.asset}
                            hierarchyLevel={item.hierarchyLevel}
                            isLastChild={
                                assetsSorted.length == 1 || item.isLastChild
                            }
                            isLastItem={key == assetsSorted.length - 1}
                            key={key}
                        />
                    );
                })}
        </div>
    );
};

export default Asset;
