import React, { Component } from "react";
import Select from "../Select";
import SelectGroup from "../Select/group";
import "./styles/_styles.scss";
import Label from "../Input/label";

export default class SelectWithTag extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {
            label,
            selectStyles,
            wrapperStyles,
            disabled,
            group,
            displayTagOnDisabled,
            noMargin,
            theme,
            error,
            ...props
        } = this.props;

        delete props.error;

        let tag = disabled ? null : "---";
        let selectedTitle = false;
        let newSelectStyles = Object.assign({}, selectStyles, {
            margin: "0px",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
        });

        if (props.value) {
            let selectedTitle = false;

            if (Array.isArray(props.data)) {
                selectedTitle = props.data.find(
                    (title) => title.value == props.value,
                );
            }

            if (group && typeof props.data == "object") {
                const target = props.data;

                for (var k in target) {
                    if (target.hasOwnProperty(k)) {
                        const item = target[k].find(
                            (title) => title.value == props.value,
                        );
                        if (item) selectedTitle = item;
                    }
                }
            }

            if (selectedTitle) {
                tag = selectedTitle.tag || selectedTitle.value;
            }
        }

        let disabledClass = "disabled";
        if (displayTagOnDisabled) {
            disabledClass = "";
        }

        return (
            <div
                className={`meta-select-with-tag-container ${
                    theme ? theme : ""
                }  ${disabled ? disabledClass : ""}`}
                style={wrapperStyles}
            >
                {label && <Label name={this.props.name} label={label} />}

                <div
                    className={`meta-select-with-tag-row ${
                        noMargin ? "no-margin" : ""
                    } ${error ? "error" : ""}`}
                >
                    {tag && (
                        <div className="meta-select-with-tag-tag-container">
                            <div
                                className={`meta-select-with-tag-tag ${
                                    !selectedTitle ? "empty" : ""
                                }`}
                            >
                                <span>{tag}</span>
                            </div>
                        </div>
                    )}

                    <div className="meta-select-with-tag">
                        {!group && (
                            <Select
                                {...props}
                                disabled={disabled}
                                selectStyles={newSelectStyles}
                                boxShadow="none"
                                theme={theme}
                                className={`scrollbar-visible ${props.className}`}
                            />
                        )}

                        {group && (
                            <SelectGroup
                                {...props}
                                disabled={disabled}
                                selectStyles={newSelectStyles}
                                boxShadow="none"
                                theme={theme}
                                className={`scrollbar-visible ${props.className}`}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
