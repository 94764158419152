import _ from "lodash";
import clientManagedController from "./clientManagedController";

export function IsClientManagedDescriptors(clientFeatures = {}) {
    const { managedLists = [] } = clientFeatures;

    return managedLists.includes("descriptors");
}

export default function ClientManagedDescriptors(props) {
    const {
        fallback = [],
        clientFeatures = {},
        itemKey,
        options = {},
        managed_lists = [],
        category = "",
    } = props;

    const { managedLists } = clientFeatures;

    const { client_managed_descriptors = [] } = options;

    let result = clientManagedController({
        clientManagedOptions: client_managed_descriptors,
        fallback,
        isClientManaged: IsClientManagedDescriptors(clientFeatures),
    });

    const descriptorInManagedLists = managed_lists.find(
        (item) => item.type === itemKey,
    );

    if (
        Array.isArray(managedLists) &&
        managedLists.includes("descriptors") &&
        descriptorInManagedLists
    ) {
        let resultData =
            result.filter((item) => item.types == itemKey && !item.is_game) ||
            [];

        result = resultData.map((item) => {
            let resultItem = { ...item };
            resultItem.text = item.name;

            if (resultItem.id) {
                resultItem.managed_id = resultItem.id;
            }

            delete resultItem.types;
            delete resultItem.order;
            delete resultItem.name;

            return resultItem;
        });
    }

    return result;
}
