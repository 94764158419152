import React from "react";
import { SectionInterface } from "./interface";
import DigitalReadinessStatus from "../../views/DigitalReadinessStatus";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";

const SectionDigitalReadinessStatus = (props: SectionInterface) => {
    const { cmsData = {}, data = {} } = props;

    const { clientFeatures = {}, clientDataStructure = {} } = cmsData;

    return (
        <DigitalReadinessStatus
            {...getSectionCommonProps({ ...props, field: "supply_chain" })}
            name="regional-supply-chain"
            data={data.supply_chain || {}}
            recordData={data}
            validationData={data || {}}
            clientFeatures={clientFeatures}
            clientDataStructure={clientDataStructure}
        />
    );
};

export default SectionDigitalReadinessStatus;
