import React from "react";
import CreditsV3 from "./v3";
import CreditsV4 from "./v4"
import CreditsV2 from "./v2/v2";
import { useStore as globalStore } from "../../components/Global/store/store";
import {
    CreditsFeaturePropsInterface,
} from "./v3/credits.interface";

const Credits: React.FC<CreditsFeaturePropsInterface> = (props) => {
    const {
        disabled,
        credits,
        creditLists,
        audits,
        options,
        handleFormChange,
        data = {}
    } = props;

    const [globalState] = globalStore() || [{}];

    const { 
        cmsData = {},
        defaultLanguageCode
    } = globalState;

    const version: string = cmsData?.clientFeatures?.creditsVersion;

    if (version === "v4") {
        return (
            <CreditsV4
                disabled={disabled}
                recordData={data}
                handleFormChange={handleFormChange}
            />
        );
    }

    if (version === "v3") {
        return (
            <CreditsV3
                disabled={disabled}
                script={defaultLanguageCode}
                defaultScript={defaultLanguageCode}
                creditLists={creditLists}
                credits={credits}
                handleFormChange={handleFormChange}
                cmsData={cmsData}
            />
        );
    }

    return (
        <CreditsV2
            disabled={disabled}
            script={defaultLanguageCode}
            defaultScript={defaultLanguageCode}
            credits={credits}
            handleFormChange={handleFormChange}
            cmsData={cmsData}
            options={options}
            audits={audits || []}
            data={credits}
        />
    );
};

export default Credits;
