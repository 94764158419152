import RegionalAgeGuidanceRowInterface from "../interfaces/RegionalAgeGuidanceRow.interface";
import TransformRegionalDataOutgoing from "../src/transformers/TransformRegionalDataOutgoing";
import ClassificationInterface from "../interfaces/Classification.interface";
import deepcopy from "deepcopy";

export const SET_STATE = "set_state";
export const DELETE_ROW = "delete_row";
export const ADD_NEW_REGIONAL = "add_new_regional";
export const EDIT_REGIONAL_RATING = "edit_regional_rating";

export const DELETE_CLASSIFICATION = "delete_classification";
export const EDIT_CLASSIFICATION = "edit_classification";

type ActionType = {
    type: string;
    state: any;
};

export const setState = (data: any): ActionType => {
    return {
        type: SET_STATE,
        state: { ...data },
    };
};

export const addNewRegional = (): ActionType => {
    return {
        type: ADD_NEW_REGIONAL,
        state: {
            row: {
                minimum_age: null,
                maximum_age: null,
                territories: [],
            },
        },
    };
};

export const deleteRegionalRating = (
    deletedRating: RegionalAgeGuidanceRowInterface,
    age_guidance: RegionalAgeGuidanceRowInterface[],
): ActionType => {
    const allRatings = deepcopy(age_guidance);
    const allRatingsUpdated = allRatings.map(
        (ratingItem: RegionalAgeGuidanceRowInterface) => {
            if (
                ratingItem.maximum_age == deletedRating.maximum_age &&
                ratingItem.minimum_age == deletedRating.minimum_age
            ) {
                ratingItem.deleted = true;
            }

            return ratingItem;
        },
    );

    return {
        type: DELETE_ROW,
        state: {
            age_guidance: allRatingsUpdated,
        },
    };
};

export const editRegionalRow = (
    newRatings: RegionalAgeGuidanceRowInterface[],
): ActionType => {
    return {
        type: EDIT_REGIONAL_RATING,
        state: {
            age_guidance: newRatings,
        },
    };
};

export const editClassification = (
    classification: ClassificationInterface,
): ActionType => {
    return {
        type: EDIT_CLASSIFICATION,
        state: { classification },
    };
};

export const deleteClassification = () => {
    return {
        type: DELETE_CLASSIFICATION,
        state: {
            classification: {
                maximum_age: null,
                minimum_age: null,
            },
        },
    };
};

const reducer = (state, action: ActionType) => {
    const {
        handleFormChange = () => {},
        handleFormChangeClassification = () => {},
        age_guidance,
    } = state;

    let resultState = {};

    switch (action.type) {
        case DELETE_CLASSIFICATION:
        case EDIT_CLASSIFICATION:
            handleFormChangeClassification(action.state.classification);
            resultState = Object.assign({}, state, action.state);
            break;
        case SET_STATE:
            resultState = Object.assign({}, state, action.state);
            break;

        case ADD_NEW_REGIONAL:
            let allRows = deepcopy(age_guidance);
            allRows.push(action.state.row);
            resultState = Object.assign({}, state, { age_guidance: allRows });
            break;

        case DELETE_ROW:
        case EDIT_REGIONAL_RATING:
            handleFormChange(
                TransformRegionalDataOutgoing(action.state.age_guidance),
            );
            resultState = Object.assign({}, state, action.state);
            break;
    }

    return resultState;
};

export default reducer;
