import React from "react";

type HierarchyBodyProps = {
    hierarchyLevel: number;
    isLastChild: boolean;
    assetHasChildRows: boolean;
};

const HierarchyBody: React.FC = (props: HierarchyBodyProps) => {
    const { hierarchyLevel, isLastChild, assetHasChildRows } = props;

    return (
        <div className="hierarchy-padding">
            {!isLastChild && <div className="lines-block-full"></div>}

            {hierarchyLevel == 1 && !isLastChild && !assetHasChildRows && (
                <div style={{ marginLeft: "2.2185rem" }}></div>
            )}

            {hierarchyLevel == 1 && !isLastChild && assetHasChildRows && (
                <div
                    className="lines-block-full"
                    style={{ marginLeft: "2.2185rem" }}
                ></div>
            )}

            {hierarchyLevel == 1 && isLastChild && (
                <div style={{ paddingRight: "2.375rem" }}></div>
            )}

            {hierarchyLevel == 2 && !isLastChild && (
                <div
                    className="lines-block-full"
                    style={{ marginLeft: "2.2185rem", paddingRight: "0.25rem" }}
                ></div>
            )}

            {hierarchyLevel == 2 && isLastChild && (
                <div
                    className="lines-block-full"
                    style={{ paddingRight: "2.375rem" }}
                ></div>
            )}
        </div>
    );
};

export default HierarchyBody;
