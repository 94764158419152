import ValidateEidr from "./ValidateEidr";
import ValidateAssets from "./ValidateAssets";
import { validateTitles } from "../../models/Validation/regional/validateTitles";
import { validateSynopses } from "../../models/Validation/regional/validateSynopses";
import ValidateCustomFields from "./builder/CustomFields";
import ValidateOrigination from "./ValidateOrigination";
import ValidateOriginationBase from "./builder/OriginationBase";

export default function General(options = {}) {
    let result = {
        result: true,
        notification: {},
        validationKeys: [],
    };

    // Regional
    result = validateTitles({ result, options });
    result = validateSynopses({ result, options });

    // EIDR
    result = ValidateEidr(result, options);

    // Assets
    result = ValidateAssets(result, options);

    result = ValidateOrigination({ result, options });
    result = ValidateOriginationBase( result, options );
    result = ValidateCustomFields(result, options);

    return result;
}
