import React, { Fragment } from "react";
import "./styles/_styles.scss";
import _ from "lodash";
import CopyAttributesToMeta from "./CopyAttributesToMeta";
import Switch from "../Switch";
import RenderAssetsToggles from "./advisories/RenderAssetsToggles";
import AssetInformationCertificateAdvisoryCreateByCopy from "../Assets/src/AssetInformationCertificateAdvisoryCreateByCopy";
import HandleDataByRule from "./advisories/HandleDataByRule";
import deepcopy from "deepcopy";
import FilterAdvisoriesByRegionSystem from "./advisories/FilterAdvisoriesByRegionSystem";
import EnrichOptionsList from "./advisories/EnrichOptionsList";
import GetAdvisoriesCodes from "../Assets/GetAdvisoriesCodes";

export default class CopyAssetsAttributesToMeta extends CopyAttributesToMeta {
    constructor(props) {
        super(props);
        this.handleAssetSelect = this.handleAssetSelect.bind(this);
    }

    setNotification() {
        const { item = {}, metadata = {}, data = [] } = this.props;

        const {
            checkFieldForConflict = false,
            checkDestinationTarget = false,
        } = item;

        const { assets = [] } = metadata;

        if (
            item.name &&
            item.name === "content_advisory" &&
            item.disabledDefaultCopyMode
        ) {
            const recordData = this.getMetaData() || [];

            if (checkDestinationTarget && checkFieldForConflict) {
                if (!_.isEmpty(assets)) {
                    this.setState({ assetsData: assets }, () => {
                        return this.updateNotification(
                            "setDestinationTogglesOptions",
                        );
                    });
                } else {
                    return !_.isEmpty(recordData)
                        ? this.setState(
                              {
                                  destination: {
                                      recordContentAdvisoryField: true,
                                  },
                                  ruleDefinitionDestination: "record",
                              },
                              () =>
                                  this.updateNotification(
                                      "chooseAttributeSetRule",
                                  ),
                          )
                        : this.setState({ contentAdvisoryData: data }, () =>
                              this.handleCopy(),
                          );
                }
            } else {
                this.updateNotification("error");
            }
        }
    }

    handleCopy() {
        const { handleChange = () => {}, item } = this.props;

        const { assetsData = [], contentAdvisoryData = [] } = this.state;

        if (_.isEmpty(assetsData) && _.isEmpty(contentAdvisoryData)) {
            this.updateNotification("error");
        } else {
            if (!_.isEmpty(contentAdvisoryData))
                handleChange({ item, result: contentAdvisoryData });
            else if (!_.isEmpty(assetsData))
                handleChange({
                    item: { parent_attribute: "assets" },
                    result: assetsData,
                });
            this.updateNotification("success");
        }
    }

    performCopyDataToAssets() {
        const { checkedAssetsRatings = [], assetsData } = this.state;

        const { data, options = {}, clientDataStructure = {} } = this.props;

        let newAssets = deepcopy(assetsData);

        newAssets = newAssets.map((asset) => {
            if (
                asset.certifications &&
                !_.isEmpty(asset.certifications) &&
                Array.isArray(asset.certifications)
            ) {
                asset.certifications.map((rating) => {
                    let editedRating = checkedAssetsRatings.find(
                        (item) =>
                            item.meta_asset_id === asset.meta_id &&
                            item.system === rating.system &&
                            item.rating === rating.rating,
                    );

                    if (
                        editedRating &&
                        editedRating.rulesResolved &&
                        editedRating.rule &&
                        !_.isEmpty(editedRating.rule)
                    ) {
                        let gracenoteData = FilterAdvisoriesByRegionSystem({
                            advisories: deepcopy(data),
                            options,
                            rating,
                            asset,
                            clientDataStructure,
                        });

                        rating.advisory = HandleDataByRule({
                            metadata: editedRating.advisory,
                            gracenoteData,
                            rule: editedRating.rule || "",
                        });

                        if (!_.isEmpty(rating.advisory)) {
                            rating.advisory_code = GetAdvisoriesCodes({
                                advisories: rating.advisory,
                                options,
                            });
                        }
                    }

                    return rating;
                });
            }

            return asset;
        });

        this.setState({ assetsData: newAssets }, () => {
            this.handleCopy();
        });
    }

    confirmAssetCreate() {
        const {
            assetsData = [],
            checkedAssetsRatings = [],
            newRating = {},
        } = this.state;

        const { asset = {}, rating = {} } = newRating;

        let ratingCopy = deepcopy(rating);
        let newAssets = deepcopy(assetsData);
        let checkedAssetsRatingsCopy = deepcopy(checkedAssetsRatings);

        newAssets = newAssets.map((row) => {
            if (row.meta_id === asset.meta_id && !_.isEmpty(rating)) {
                if (row.certifications && Array.isArray(row.certifications)) {
                    row.certifications.push(deepcopy(rating));
                } else {
                    row.certifications = [deepcopy(rating)];
                }
            }
            return row;
        });

        ratingCopy.rulesResolved = true;
        ratingCopy.meta_asset_id = asset.meta_id;
        checkedAssetsRatingsCopy.push(ratingCopy);

        this.setState(
            {
                assetsData: newAssets,
                checkedAssetsRatings: checkedAssetsRatingsCopy,
                newRating: {},
            },
            () => this.updateNotification("checkAssetRating"),
        );
    }

    handleConfirm() {
        let {
            checkedAssetsRatings = [],
            destination = {},
            assetsData = [],
            option,
        } = this.state;

        const { data = [], options, clientDataStructure = {} } = this.props;

        const {
            recordVideoAssets = false,
            recordContentAdvisoryField = false,
        } = destination;

        if (recordContentAdvisoryField) {
            const metadata = this.getMetaData() || [];

            if (!this.state.recordCopyRule) {
                return !_.isEmpty(metadata)
                    ? this.setState(
                          { ruleDefinitionDestination: "record" },
                          () =>
                              this.updateNotification("chooseAttributeSetRule"),
                      )
                    : this.setState(
                          { recordCopyRule: "overwriteAndReplace" },
                          () => this.handleConfirm(),
                      );
            } else if (!this.state.recordTitleHandled) {
                this.setState(
                    {
                        recordTitleHandled: true,
                        contentAdvisoryData: HandleDataByRule({
                            metadata,
                            gracenoteData: data,
                            rule: this.state.recordCopyRule || "",
                        }),
                    },
                    () =>
                        !recordVideoAssets
                            ? this.handleCopy()
                            : this.handleConfirm(),
                );
                return;
            }
        }

        if (recordVideoAssets) {
            let rating = checkedAssetsRatings.find((rating) => {
                return typeof rating === "object" && !rating.rulesResolved;
            });

            if (rating) {
                let asset = assetsData.find(
                    (item) => item.meta_id === rating.meta_asset_id,
                );
                let state = {
                    asset: asset,
                    certificate: rating,
                    ruleDefinitionDestination: "assets",
                };

                let availableAdvisories = FilterAdvisoriesByRegionSystem({
                    options,
                    rating,
                    advisories: data,
                    asset,
                    clientDataStructure,
                });

                if (
                    Array.isArray(data) &&
                    data.length > 0 &&
                    !_.isEmpty(availableAdvisories)
                ) {
                    this.setState(state, () =>
                        this.updateNotification("chooseAttributeSetRule"),
                    );
                } else {
                    rating.rulesResolved = true;
                    state.rating = rating;
                    this.setState(state, () => {
                        this.handleConfirm();
                    });
                }
            } else {
                this.performCopyDataToAssets();
            }
        }
    }

    handleCopyRuleConfirm(rule) {
        const { ruleDefinitionDestination = "" } = this.state;

        if (ruleDefinitionDestination === "record") {
            return this.setState({ recordCopyRule: rule }, () =>
                this.handleConfirm(),
            );
        } else {
            let { certificate = {}, checkedAssetsRatings = [] } = this.state;

            checkedAssetsRatings = checkedAssetsRatings.map((item) => {
                if (
                    item.meta_asset_id === certificate.meta_asset_id &&
                    item.country_code === certificate.country_code &&
                    item.system === certificate.system
                ) {
                    item.rule = rule;
                    item.rulesResolved = true;
                }
                return item;
            });
            return this.setState(
                { checkedAssetsRatings: checkedAssetsRatings },
                () => {
                    this.handleConfirm();
                },
            );
        }
    }

    updateNotification(stateKey, value) {
        const { notification = {} } = this.state;

        this.setState(
            {
                option: stateKey || value,
            },
            () => {
                const { destination = {} } = this.state;

                let status = "alert";
                let html = "";
                let confirmAction = this.handleConfirm.bind(this, stateKey);
                let title = this.copyTitle;
                let disabled = destination.recordVideoAssets
                    ? _.isEmpty(this.state.checkedAssetsRatings || [])
                    : !destination.recordContentAdvisoryField;
                let description =
                    "The Content Advisory attributes can be copied to both the abstract record level, and also to assets. Please choose the target destination/s.";
                switch (stateKey) {
                    case "assetRatingCreate":
                        html = this.renderCreateRating();
                        title = "Asset Information";
                        description = "";
                        disabled = false;
                        confirmAction = this.confirmAssetCreate.bind(this);
                        break;
                    case "chooseAttributeSetRule":
                        html = this.getHtml();
                        description =
                            this.getNotificationDescriptionOfResolveMergeAssetRule();
                        disabled = true;
                        break;
                    case "mergeAttributes":
                    case "overwriteAndReplace":
                        html = this.getHtml();
                        description =
                            this.getNotificationDescriptionOfResolveMergeAssetRule();
                        confirmAction = this.handleCopyRuleConfirm.bind(
                            this,
                            stateKey,
                            value,
                        );
                        break;
                    case "success":
                        html = "";
                        description =
                            "The selected attributes have been copied across into the corresponding values in the main title record.";
                        status = "success";
                        confirmAction = null;
                        break;
                    case "error":
                        html = "";
                        description =
                            "There was a problem merging in data to Meta. Please try again.";
                        status = "error";
                        confirmAction = null;
                        break;
                    default:
                        html = this.getAssetHtml();
                        break;
                }

                let updatedState = { ...notification };
                updatedState.html = html;
                updatedState.title = title;
                updatedState.description = description;
                updatedState.confirm = confirmAction;
                updatedState.disabled = disabled;
                updatedState.status = status;

                this.setState({ notification: updatedState });
            },
        );
    }

    getAssetHtml() {
        let { assetsData, destination = {} } = this.state;

        const { recordVideoAssets = false } = destination;

        const options = [
            {
                title: "Copy to Title Record Content Advisory Field",
                key: "recordContentAdvisoryField",
            },
            {
                title: "Copy to Video Assets",
                key: "recordVideoAssets",
            },
        ];

        return (
            <div className="gracenote-copyto meta-gracenote--assets--destination-options">
                {options.map((item, index) => {
                    const { key, title = "" } = item;

                    let isChecked = !!destination[key];
                    return (
                        <div className="gracenote-copyto--row" key={index}>
                            <div className="asset-row-title"> {title} </div>
                            <div className="asset-row-line"></div>
                            <Switch
                                name={key}
                                checked={isChecked}
                                handleChange={() => {
                                    destination[key] = !isChecked;
                                    this.setState(
                                        { destination: destination },
                                        () => {
                                            this.updateNotification(key);
                                        },
                                    );
                                }}
                                sliderClass="react-slider--orange"
                            />
                        </div>
                    );
                })}
                {recordVideoAssets && (
                    <RenderAssetsToggles
                        assets={assetsData}
                        checkedAssets={this.state.checkedAssets || []}
                        checkedAssetsRatings={
                            this.state.checkedAssetsRatings || []
                        }
                        handleAssetSelect={(asset) =>
                            this.handleAssetSelect(asset)
                        }
                        handleAssetRatingSelect={(data) =>
                            this.handleAssetRatingSelect(data.cert, data.asset)
                        }
                        handleCreateRatingSelect={(asset) => {
                            this.setState({ asset: asset }, () => {
                                this.updateNotification("assetRatingCreate");
                            });
                        }}
                    />
                )}
            </div>
        );
    }

    renderCreateRating() {
        const { asset = {} } = this.state;

        const { options = {}, data = [] } = this.props;

        // Commented out as it breaks build

        return (
            <Fragment>
                {/* <AssetInformationCertificateAdvisoryCreateByCopy
                    handleUpdate={(ratings) => {
                        this.setState({
                            newRating: {
                                asset: asset,
                                rating: ratings[0] || {}
                            }
                        })}
                    }
                    compliance={asset}
                    options={EnrichOptionsList({options, gracenoteData: data})}
                    preSavedStatus="pre-saved"
                    useDestinationPlatform={false}
                    enableAddNew={false}
                    populateAdvisories={true}
                /> */}
            </Fragment>
        );
    }

    handleAssetSelect(asset) {
        let { checkedAssets = [] } = this.state;

        let isAlreadyChecked = checkedAssets.find(
            (item) => item.meta_id === asset.meta_id,
        );
        if (isAlreadyChecked) {
            checkedAssets = checkedAssets.filter(
                (item) => item.meta_id !== asset.meta_id,
            );
        } else {
            checkedAssets.push(asset);
        }

        this.setState({ checkedAssets: checkedAssets }, () =>
            this.updateNotification("checkAsset"),
        );
    }

    handleAssetRatingSelect(cert, asset) {
        let { checkedAssetsRatings = [] } = this.state;

        let isCheckedRating = checkedAssetsRatings.find((rating) => {
            return (
                rating.meta_asset_id === asset.meta_id &&
                rating.country_code === cert.country_code &&
                rating.system === cert.system
            );
        });

        if (isCheckedRating) {
            checkedAssetsRatings = checkedAssetsRatings.filter((rating) => {
                return !(
                    rating.meta_asset_id === asset.meta_id &&
                    rating.country_code === cert.country_code &&
                    rating.system === cert.system
                );
            });
        } else {
            let certCopy = deepcopy(cert);
            certCopy.meta_asset_id = asset.meta_id;
            checkedAssetsRatings.push(certCopy);
        }

        this.setState({ checkedAssetsRatings: checkedAssetsRatings }, () => {
            this.updateNotification("checkAssetRating");
        });
    }

    getNotificationDescriptionOfResolveMergeAssetRule() {
        const { certificate = {} } = this.state;

        const { item = {} } = this.props;

        let text = `The title record ${
            item.label || ""
        } field already has attributes assigned in the live record for this title. Please choose the desired outcome.`;
        if (
            this.state.ruleDefinitionDestination &&
            this.state.ruleDefinitionDestination === "assets"
        ) {
            let ratingTitle = `<span style="font-weight: bold">${
                certificate.rating || ""
            }</span>`;
            text = `The ${ratingTitle} ${
                item.label || ""
            } field already has attributes assigned in the live record for this title. Please choose the desired outcome.`;
        }
        return text;
    }
}
