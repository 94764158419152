import { Box, Stack } from "@mui/material";
import { UseFormReturn } from "react-hook-form";

type AutocompleteOptionProps<TOption> = {
    option: TOption;
    path: string;
    form: UseFormReturn<any, any>;
    value: string;
    selected: string[];
    enabledClick: boolean;
    keepSelected?: boolean;
    onClick: (option: TOption) => void;
    closePopper: () => void;
    onRenderOption?: (option: TOption) => JSX.Element;
};

export function AutocompleteOption<TOption>(
    props: AutocompleteOptionProps<TOption>,
) {
    const {
        option,
        path,
        form,
        value,
        selected,
        enabledClick,
        onClick,
        closePopper,
        onRenderOption,
        keepSelected
    } = props;

    const isCustomRender = typeof onRenderOption === "function";
    return (
        <Box
            key={option[path]}
            component="li"
            role="menuitem"
            aria-label={`menuitem-${value}`}
            style={{ marginBottom: 0 }}
            value={option[path]}
            onClick={() => {
                if (enabledClick) {
                    onClick(option);

                    if (!keepSelected) form.resetField(path);
                    
                    closePopper();
                }
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                aria-label={`render-option-stack-${value}`}
                sx={{
                    borderRadius: "0.1875rem",
                    backgroundColor:
                        selected && selected?.includes(value) && "rgba(40, 108, 210, 0.1)",
                    "&:hover": {
                        backgroundColor: "rgba(240, 240, 240, 1)",
                        cursor: "pointer",
                    },
                    color: "rgba(47, 47, 47, 0.87)",
                    ".MuiListItem-root": {
                        padding: "0.5rem",
                    },
                    "span.MuiTypography-root": {
                        fontSize: "1.125rem", // 18px
                        color: "rgba(47, 47, 47, 0.87)",
                        fontWeight: 500,
                        lineHeight: "145%",
                        letterSpacing: "0.0112rem",
                    },
                    "p.MuiTypography-root": {
                        fontSize: "0.875rem", // 14px
                        color: "rgba(47, 47, 47, 0.57)",
                        fontWeight: 500,
                        lineHeight: "145%",
                        letterSpacing: "0.0112rem",
                    },
                    padding: !isCustomRender && "0.5rem",
                }}
            >
                <Box flex={1}>
                    {isCustomRender ? onRenderOption(option) : option[path]}
                </Box>
            </Stack>
        </Box>
    );
}
