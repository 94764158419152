const stateColors = {
    empty: {
        background: "#bcbcbc",
        text: "#787777",
    },
    ordered: {
        background: "#6ace9e",
        text: "#FFFFFF",
    },
    received: {
        background: "#6ace9e",
        text: "#FFFFFF",
    },
    normalised: {
        background: "#188651",
        text: "#FFFFFF",
    },
    complied: {
        background: "#188651",
        text: "#FFFFFF",
    },
    rejected: {
        background: "#e58c00",
        text: "#FFFFFF",
    },
};

const AudioStatuses = {
    empty: stateColors["empty"],
    ordered: stateColors["ordered"],
    received: stateColors["received"],
    normalised: stateColors["normalised"],
    complied: stateColors["complied"],
    rejected: stateColors["rejected"],
};

const SubtitleStatuses = {
    empty: stateColors["empty"],
    ordered: stateColors["ordered"],
    received: stateColors["received"],
    normalised: stateColors["normalised"],
    complied: stateColors["complied"],
    rejected: stateColors["rejected"],
};

const data = {
    audioStatuses: AudioStatuses,
    subtitleStatuses: SubtitleStatuses,
};

export default data;
