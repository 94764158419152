import React, { Component, Fragment } from "react";
import _ from "lodash";
import styles from "./styles";
import Settings from "../Settings";
import getFileExtension from "../Helpers/fileExtension"
import NoPreviewAvailable from "./NoPreviewAvailable"
import hasNoPreviewImage from "./utils/hasNoPreviewImage"

export default class RecordImage extends Component {
    constructor(props) {
        super(props);

        /**
         * @var object
         */
        this.state = {
            image: {},
            posterIcon: {},
            loading: true,
            hover: false,
            imageFull: false
        };

        /**
         * @var bool
         */
        this.componentIsMounted = false;
    }

    componentDidMount() {
        this.componentIsMounted = true;

        this.loadImageFropProps()
        this.loadPosterIcon()
    }

    componentWillUnmount() {
        this.componentIsMounted = false;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!_.isEqual(this.props.image, nextProps.image)) {
            return true;
        }

        if (!_.isEqual(this.props.icon, nextProps.icon)) {
            return true;
        }

        if (!_.isEqual(this.props.poster, nextProps.poster)) {
            return true;
        }

        if (!_.isEqual(this.props.loading, nextProps.loading)) {
            return true;
        }

        if (!_.isEqual(this.props.buttons, nextProps.buttons)) {
            return true;
        }

        if (
            !_.isEqual(this.props.isEditableRecord, nextProps.isEditableRecord)
        ) {
            return true;
        }

        if (
            !_.isEqual(this.props.addedToSelection, nextProps.addedToSelection)
        ) {
            return true;
        }

        if (
            !_.isEqual(
                this.props.addingToShortlist,
                nextProps.addingToShortlist,
            )
        ) {
            return true;
        }

        if (
            !_.isEqual(this.props.shortlistMetaIds, nextProps.shortlistMetaIds)
        ) {
            return true;
        }

        if (
            !_.isEqual(
                this.props.selectionsMetaIds,
                nextProps.selectionsMetaIds,
            )
        ) {
            return true;
        }

        if (!_.isEqual(this.props.shortlist, nextProps.shortlist)) {
            return true;
        }

        if (
            !_.isEqual(
                this.props.applyImageHoverStyles,
                nextProps.applyImageHoverStyles,
            )
        ) {
            return true;
        }

        if (!_.isEqual(this.state, nextState)) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.icon !== prevProps.icon ||
            this.props.image !== prevProps.image
        ) {
            const image = this.getImage();

            if (image && this.componentIsMounted) {
                this.setState(
                    {
                        loading: true,
                        image: {},
                    },
                    () => {
                        this.loadImageFropProps();
                        this.loadPosterIcon();
                    },
                );
            }

            if (!image && !this.state.error) {
                this.setError();
            }
        }

        if (this.props.poster !== prevProps.poster) {
            this.loadPosterIcon();
        }
    }

    getImage() {
        let result = false;

        if (typeof this.props.image == "string") {
            result = this.props.image;
        }

        if (typeof this.props.icon == "string") {
            result = this.props.icon;
        }

        return result;
    }

    loadImageFropProps() {
        const image = this.getImage();

        if (image) {
            let imageSrc = new Image();
            imageSrc.src = image;

            imageSrc.onload = () => {
                this.setStateKey("loading", false);
                this.setStateKey("image", imageSrc);
            };

            imageSrc.onerror = () => {
                if (typeof this.props.image == "string") {
                    let imageSrc = new Image();
                    imageSrc.src = this.props.image;

                    imageSrc.onload = () => {
                        this.setStateKey("error", false);
                        this.setStateKey("loading", false);
                        this.setStateKey("image", imageSrc);
                    };

                    imageSrc.onerror = () => {
                        this.setError();
                    };
                } else {
                    this.setError();
                }
            };
        } else {
            this.setError();
        }
    }

    setError() {
        this.setStateKey("loading", false);
        this.setStateKey("error", true);
    }

    setStateKey(key, value) {
        if (!_.isEqual(this.state[key], value) && this.componentIsMounted) {
            this.setState({
                [key]: value,
            });
        }
    }

    loadPosterIcon() {
        if (this.props.poster) {
            let image = new Image();
            image.src = Settings.images.posterIcon;

            image.onload = () => {
                this.setStateKey("posterIcon", image);
            };
        }
    }

    renderButtons() {
        const { buttons = [], displayButtons } = this.props;

        if (_.isEmpty(buttons) || !displayButtons) {
            return false;
        }

        return (
            <div
                style={{
                    position: "absolute",
                    width: "100%",
                    zIndex: 1,
                }}
            >
                {buttons.map((button, index) => {
                    return <Fragment key={index}>{button}</Fragment>;
                })}
            </div>
        );
    }

    render() {
        const classStyles = styles(this.props);
        const { 
            onClick,
            noPreviewPlaceholder = false,
            cmsData = {} 
        } = this.props;

        const noPreviewImage = hasNoPreviewImage({ cmsData, image: this.props.image })

        if (noPreviewImage) {
            return (
                <div style={classStyles.placeholder} id={this.props.id}>
                    <NoPreviewAvailable extension={getFileExtension(this.props.image)}/>
                </div>
            )
        }
        
        // Loading
        if (this.state.loading) {
            return (
                <div style={classStyles.placeholder} id={this.props.id}>
                    <img
                        className="meta-loading-icon"
                        src={
                            this.props.loadingImage ||
                            Settings.images.loadingBlack
                        }
                        alt="'loading..'"
                        style={classStyles.loadingImage}
                    />
                </div>
            );
        }

        // Image
        if (this.state.image.src) {
            return (
                <div
                    style={Object.assign(
                        {},
                        classStyles.placeholder,
                        this.props.onClick &&
                            classStyles.imageInteractiveStyles,
                    )}
                    id={this.props.id}
                    onClick={onClick}
                >
                    <img
                        className="meta-record-poster"
                        src={this.state.image.src}
                        alt="record poster"
                        style={Object.assign(
                            {},
                            classStyles.image,
                            this.props.basicStyles &&
                                classStyles.imageBasicStyles,
                            this.props.imageFull && 
                                classStyles.imageFullStyles,
                        )}
                    />

                    {this.state.posterIcon.src && this.props.poster && (
                        <img
                            className="meta-record-poster-icon"
                            src={this.state.posterIcon.src}
                            alt="poster"
                            style={styles().posterIcon}
                        />
                    )}

                    <div style={classStyles.imageCover}></div>

                    {this.renderButtons()}
                </div>
            );
        }

        // Placeholder
        return (
            <div style={classStyles.placeholder} id={this.props.id}>
                
                <img
                    className="meta-record-poster-placeholder"
                    src={noPreviewPlaceholder ? Settings.images.posterPlaceholder : Settings.images.placeholderDots}
                    alt="placeholder"
                    style={classStyles.placeholderImage}
                />

                {this.renderButtons()}
            </div>
        );
    }
}
