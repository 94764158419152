import React from "react";
import AssetDetails from "./ComplianceBodyComponents/AssetDetails";
import AudioVideoTracks from "./ComplianceBodyComponents/AudioVideoTracks";
import Advisories from "./ComplianceBodyComponents/Advisories";
import BasicInfo from "./ComplianceBodyComponents/BasicInfo";
import ExtraInfo from "./ComplianceBodyComponents/ExtraInfo";
import AssetDetailsSection from "./ComplianceBodyComponents/AssetDetailsSection";
import TechnicalMetadataSection from "./ComplianceBodyComponents/TechnicalMetadataSection";
import "./styles/compliance.scss";
import "./styles/section-compliance.scss";

const ComplianceBody = (props) => {
    return (
        <div className="compliance">
            <div className="meta-asset-compliance-body">
                <BasicInfo {...props} />
                <ExtraInfo {...props} />
                <Advisories {...props} />
                <AssetDetails {...props} title="Asset Details" />
                <AudioVideoTracks {...props} />
            </div>
        </div>
    );
};

const SectionedComplianceBody = (props) => {
    return (
        <div className="section-compliance">
            <div className="meta-asset-compliance-body">
                <BasicInfo {...props} />
            </div>
            <AssetDetailsSection {...props} />
            <TechnicalMetadataSection {...props} />
        </div>
    );
};

const RenderComplianceBody = (props) => {
    const { clientFeatures = {} } = props;

    const { assets = {} } = clientFeatures;

    return assets.sectionedUi ? (
        <SectionedComplianceBody {...props} />
    ) : (
        <ComplianceBody {...props} />
    );
};

export default RenderComplianceBody;
