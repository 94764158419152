export const SET_STATE = "set_state";
export const SET_NOTIFICATION = "set_notification";
export const SET_ERROR_NOTIFICATION = "set_erro_notification";
export const SET_CONFIGURATION = "set_configuration";
export const SET_NEW_ASSET = "set_new_asset";
export const SET_EDIT_ASSET = "set_edit_asset";

export const CONFIRM_ASSET_CREATE = "confirm_asset_create";
export const CONFIRM_ASSET_EDIT = "confirm_asset_edit";
export const CONFIRM_ASSET_DELETE = "confirm_asset_delete";
