import React from 'react'
import { MaterialDatePickerInterface } from './index'
import DatePicker, { MaterialYearPicker } from "./index"
import Label from './Label'
import NoneEntered from "../../KeywordLabel/noneEntered";
import "./_styles.scss"

export const variantDatePicker: string = 'datePicker'
export const variantYearPicker: string = 'yearPicker'

interface DatePickerRecordViewControllerInterface extends MaterialDatePickerInterface {
    variant: 'datePicker' | 'yearPicker'
}

const DatePickerRecordViewController = (props: DatePickerRecordViewControllerInterface) => {
    const {
        disabled,
        readOnly,
        defaultValue,
        variant = variantDatePicker
    } = props

    if (disabled || readOnly) {
        return (
            <div className="fabric-datepicker-material">
                <Label {...props}/>

                {defaultValue &&
                    <span className="fabric-datepicker-value">
                        {defaultValue}
                    </span>
                }

                {!defaultValue &&
                    <NoneEntered
                        title="None Entered"
                        margin="0"
                    />
                }
            </div>
        )
    }

    if (variant == variantYearPicker) {
        return <MaterialYearPicker {...props}/>
    }

    return <DatePicker {...props}/>
}

export default DatePickerRecordViewController