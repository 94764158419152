import React from "react";
import _ from "lodash";
import Index from "./Index";
import styles from "../styles";
import "../styles/_video_landscape.scss";
import PeriodDropdownWithRefresh from "../../PeriodDropdownWithRefresh";
import SentimentAnalysis from "../../SentimentAnalysis";
import SentimentPopularity from "../../SentimentPopularity";
import PlaceholderText from "../../PlaceholderText";

export default class VideoLandscape extends Index {
    constructor(props) {
        super(props);
    }

    renderNoneAdded() {
        return false;
    }

    renderTitle() {
        return false;
    }

    renderContent() {
        const { data = [] } = this.props;

        const classStyles = styles();

        if (_.isEmpty(data)) {
            return (
                <div>
                    <h2 style={classStyles.section.title}>Video Landscape</h2>

                    <PlaceholderText styles={{ marginTop: "1.5rem" }} />
                </div>
            );
        }

        return (
            <div className="video-landscape">
                <div className="container-flex">
                    <h2 style={classStyles.section.title}>Video Landscape</h2>

                    <PeriodDropdownWithRefresh
                        value="12/12/2019"
                        data={[
                            {
                                text: "12 December",
                                tag: "2019",
                                value: "12/12/2019",
                            },
                            {
                                text: "13 December",
                                tag: "2019",
                                value: "13/12/2019",
                            },
                        ]}
                        styles={{
                            marginLeft: "auto",
                        }}
                    />
                </div>

                <div className="video-landscape--items">
                    {data.map((item, index) => {
                        return (
                            <div className="video-landscape--item" key={index}>
                                <label style={{ margin: "0.84375rem 0" }}>
                                    Sentiment Analysis
                                </label>

                                <SentimentAnalysis
                                    options={[
                                        { key: "Platform", title: "Platform" },
                                        {
                                            key: "Video Type",
                                            title: "Video Type",
                                        },
                                        {
                                            key: "Number of Assets",
                                            title: "Number of Assets",
                                        },
                                        {
                                            key: "Time Range",
                                            title: "Time Range",
                                        },
                                        {
                                            key: "Reactions",
                                            title: "Reactions",
                                            format: "integer",
                                        },
                                        {
                                            key: "Reaction Rate",
                                            title: "Reaction Rate",
                                        },
                                        {
                                            key: "Emotional Reactions",
                                            title: "Emotional Reactions",
                                            format: "integer",
                                        },
                                        {
                                            key: "Reaction Replies",
                                            title: "Reaction Replies",
                                        },
                                        {
                                            key: "Comments & Replies",
                                            title: "Comments/Replies",
                                        },
                                        {
                                            key: "YT_Views",
                                            title: "Youtube Views",
                                            format: "integer",
                                        },
                                        {
                                            key: "YT_Likes",
                                            title: "Youtube Likes",
                                            format: "integer",
                                        },
                                        {
                                            key: "YT_Dislikes",
                                            title: "Youtube Dislikes",
                                            format: "integer",
                                        },
                                        {
                                            key: "FB_Likes",
                                            title: "Facebook Likes",
                                            format: "integer",
                                        },
                                        {
                                            key: "FB_Shares",
                                            title: "Facebook Shares",
                                            format: "integer",
                                        },
                                        {
                                            key: "FB_Love",
                                            title: "Facebook Loves",
                                            format: "integer",
                                        },
                                        {
                                            key: "FB_Anger",
                                            title: "Facebook Anger",
                                            format: "integer",
                                        },
                                        {
                                            key: "FB_Haha",
                                            title: "Facebook Haha",
                                            format: "integer",
                                        },
                                        {
                                            key: "FB_Wow",
                                            title: "Facebook Wow",
                                            format: "integer",
                                        },
                                        {
                                            key: "FB_Sorry",
                                            title: "Facebook Sorry",
                                            format: "integer",
                                        },
                                    ]}
                                    data={item}
                                    styles={{
                                        maxWidth: "85%",
                                    }}
                                />

                                <label>Sentiment Popularity</label>

                                <SentimentPopularity
                                    data={item.keywords || {}}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}
