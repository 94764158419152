import React, { useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../store/rootStore";
import Notification from "../../../../../components/Notification";
import { RenderEditContributorForm } from "../components/RenderEditContributorForm";

interface Props {}

const EditContributorForm: React.FC<Props> = observer((props: Props) => {
    const rootStore = useStores();
    const { awardsStore } = rootStore || {};

    return (
        <Notification
            title="Edit Contributor"
            background="white"
            intercationStatus="alert"
            confirm={true}
            okText="Ok"
            html={<RenderEditContributorForm />}
            cancelClick={() =>
                awardsStore.setAwardEditContributorFormIsActive(false)
            }
        />
    );
});

export default EditContributorForm;
