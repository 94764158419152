import DOMPurify from "isomorphic-dompurify";

export default function StringUnderlineSearch(stringToQuery, searchQuery) {
    const sanitizedStringQuery = DOMPurify.sanitize(stringToQuery);

    if (!searchQuery) return sanitizedStringQuery;

    return sanitizedStringQuery.replace(
        new RegExp(searchQuery, "gi"),
        (match) => `<u>${match}</u>`,
    );
}
