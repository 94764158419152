import SendRequest from "../../components/SendRequest";
import { SetLocalStorage } from "../../components/Storage/localStorage";

const CMSService = (props) => {
    const { handleResponseData, testingData } = props;

    let url = window.location.origin + "/cms";

    const options = {
        url,
        method: "GET",
        testingData,
    };

    const handleError = (message) => {
        if (message) {
            console.log(message);
        }
    };

    SendRequest(
        options,
        (response) => {
            const { data = {} } = response;

            if (typeof handleResponseData == "function" && data) {
                if (window.hasOwnProperty("localStorage")) {
                    SetLocalStorage({
                        name: "meta-client-cms-data",
                        value: data,
                    });
                }

                handleResponseData(data);
            }
        },
        (e) => {
            handleError(e);
        },
    );
};

export default CMSService;
