import React, { createRef } from "react";
import _ from "lodash";
import "./styles/_styles_v2.scss";
import Notification from "../Notification";
import GetActiveData from "./src/GetActiveData";
import AssetInformation from "./src/AssetInformation";
import AssetIds from "./src/AssetIds";
import WorkflowNotifications from "./src/WorkflowNotifications";
import AssetInformationCertificatesAdvisory from "./src/AssetInformationCertificatesAdvisory";
import GetDefaultNewRowValues from "./src/GetDefaultNewRowValues";
import AssetCompliances from "./src/AssetCompliances";
import AssetContainer from "./src/AssetContainer";
import SendRequest from "../SendRequest";
import deepcopy from "deepcopy";
import update from "react-addons-update";
import { TransformMetaUsersToSimpleArray } from "./src/Notifications";
import { diff } from "deep-object-diff";

export default class AssetsV2 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: Array.isArray(props.data) ? props.data : [],
            newRow: {},
        };

        this.masterAssetType = "Title, Original & Normalised";
        this.orignComplianceCode = "ORIG";
        this.originalMasterAssetType = "Original Master";
        this.alternativeMasterAssetType = "Alternate Language Master";
        this.pendingStatus = "pending";
        this.preSavedStatus = "pre-saved";
        this.errorStatus = "error";
        this.placeholderCreatedStatus = "placeholder created";

        this.parentNotificationRef = createRef();
    }

    componentWillMount() {
        this.placeholderCreated = this.checkStatus(
            this.placeholderCreatedStatus,
        );
        this.placeholderError = this.checkStatus(this.errorStatus);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const difference = diff(this.props.data, prevProps.data);
        if (!_.isEmpty(difference)) {
            this.setState({ data: this.props.data });
        }
    }

    checkStatus(status) {
        const { data = [] } = this.state;

        let result = false;
        const originalMaster = data.find(
            (item) =>
                item.compliance_code == this.orignComplianceCode &&
                !item.deleted,
        );

        if (typeof originalMaster == "object" && originalMaster) {
            if (
                typeof originalMaster.status == "string" &&
                originalMaster.status.toLowerCase() == status
            ) {
                result = true;
            }
        }

        return result;
    }

    handleNewRowUpdate(updatedState) {
        this.setState(
            {
                newRow: updatedState,
            },
            () => {
                this.setState({
                    notification: this.notificationNewRowProps(),
                });
            },
        );
    }

    handleMasterUpdate(updatedState) {
        this.setState(
            {
                masterToUpdate: updatedState,
            },
            () => {
                this.setState({
                    notification: this.notificationUpdateMasterProps(),
                });
            },
        );
    }

    getHtmlProps() {
        const {
            options = {},
            clientFeatures = {},
            dataStructure = {},
        } = this.props;

        const { data, newRow = {}, alternateMaster } = this.state;

        const activeData = GetActiveData(data);

        let masterStateData =
            activeData.find(
                (item) => item.compliance_code == this.orignComplianceCode,
            ) || {};
        if (alternateMaster) {
            masterStateData =
                activeData.find(
                    (item) => item.compliance_code == newRow.compliance_code,
                ) || {};
        }

        return {
            data: masterStateData,
            activeData,
            master: newRow,
            options,
            alternateMaster,
            handleUpdate: this.handleNewRowUpdate.bind(this),
            allAssetTypes: this.getCompliancesFromOptions(),
            clientFeatures,
            dataStructure,
        };
    }

    getHtmlPropsUpdateMaster() {
        const { options = {}, clientFeatures = {}, dataStructure } = this.props;

        const { data = [], masterToUpdate = {}, alternateMaster } = this.state;

        return {
            data:
                data.find(
                    (item) =>
                        item.compliance_code ==
                            masterToUpdate.compliance_code &&
                        item.asset_type == masterToUpdate.asset_type,
                ) || {},
            master: masterToUpdate,
            update: true,
            options,
            alternateMaster,
            handleUpdate: this.handleMasterUpdate.bind(this),
            allAssetTypes: this.getCompliancesFromOptions(),
            clientFeatures,
            dataStructure,
        };
    }

    getCompliancesFromOptions() {
        const { options = {}, clientFeatures = {}, dataStructure } = this.props;

        const result = AssetCompliances({
            options,
            clientFeatures,
            dataStructure,
        });

        return result;
    }

    validateMaster() {
        const { alternateMaster, newRow, masterToUpdate } = this.state;

        const { locale } = this.props;

        let notValid = false;
        let errorMessage = false;

        const languageLocale = this.getTmcCodeFromMetaCode(locale);

        if (alternateMaster) {
            const data = !_.isEmpty(newRow) ? newRow : masterToUpdate;
            const activeData = GetActiveData(this.state.data);

            const { audio, video, asset_type, system_name } = data;

            notValid = !audio || !video || !asset_type || !system_name;

            const altMaster = activeData.find(
                (item) =>
                    item.video == video &&
                    item.audio == audio &&
                    item.compliance_code !== this.orignComplianceCode,
            );

            if (altMaster) {
                notValid = true;
                errorMessage =
                    "Alternate master with same audio and video language already exists";
            }

            if (video == languageLocale && audio == languageLocale) {
                notValid = true;
                errorMessage =
                    "Original master with same audio and video language already exists";
            }
        }

        return {
            notValid,
            errorMessage,
        };
    }

    getTmcCodeFromMetaCode(code) {
        const { list_key, languages } = this.getLanguages();

        const opionLanguages =
            languages.find((item) => item.meta_code == code) || {};
        return opionLanguages[list_key] || code;
    }

    notificationNewRowProps() {
        const { notValid, errorMessage } = this.validateMaster();

        return {
            status: "info",
            html: <AssetInformation {...this.getHtmlProps()} />,
            maxWidth: "42rem",
            contentNoPadding: true,
            disabled: notValid,
            errorMessage,
            confirm: () => this.addNewRowToState(),
            onClick: () => this.handleNotificationCancel(),
        };
    }

    notificationUpdateMasterProps() {
        const { notValid, errorMessage } = this.validateMaster();

        return {
            status: "info",
            html: <AssetInformation {...this.getHtmlPropsUpdateMaster()} />,
            maxWidth: "42rem",
            contentNoPadding: true,
            disabled: notValid,
            errorMessage,
            confirm: () => this.updateMaster(),
            onClick: () => this.handleNotificationCancel(),
        };
    }

    setMasterPayload(masterData) {
        const {
            system_type,
            system_name,
            asset_type,
            system_environment,
            compliances = [],
            register_performance_eidr,
            video,
            audio,
        } = masterData;

        const { newRow = {}, alternateMaster } = this.state;

        const { media_id } = this.props;

        let assetType =
            asset_type == this.masterAssetType ? "Original Master" : asset_type;
        if (alternateMaster) {
            assetType = "Alternate Language Master";
        }

        const GetTMCMediaID = (props) => {
            const { compliance_code = "", media_id = "" } = props;

            return media_id + "_" + compliance_code;
        };

        const GetCompliancePayload = (props) => {
            return {
                asset_type: props.asset_type,
                status: this.preSavedStatus,
                compliance_code: props.compliance_code,
                system_environment: system_environment,
                system_name: system_name,
                system_type: system_type,
            };
        };

        let master = {
            system_type: system_type,
            system_name: system_name,
            system_environment: system_environment,
            asset_type: assetType,
            status: this.preSavedStatus,
            register_performance_eidr,
            compliance_code: this.orignComplianceCode,
        };

        let compliancesWithNorm = [...compliances];

        let norm = GetCompliancePayload({
            asset_type: "Normalised",
            compliance_code: "NORM",
        });

        // Alt master
        if (alternateMaster) {
            // master
            master.video = video;
            master.audio = audio;
            master.compliance_code = video + "_" + this.orignComplianceCode;
            master.tmc_media_id = GetTMCMediaID({
                compliance_code: video + "_" + this.orignComplianceCode,
                media_id,
            });

            // norm
            norm.compliance_code = video + "_" + "NORM";
            norm.video = video;
            norm.audio = audio;
            norm.tmc_media_id = GetTMCMediaID({
                compliance_code: video + "_" + "NORM",
                media_id,
            });
        }

        // Add norm compliance
        if (!_.isEmpty(newRow)) {
            compliancesWithNorm = [norm, ...compliances];
        }

        const compliancesToSave = compliancesWithNorm;
        const compliancesFromOptions = this.getCompliancesFromOptions();

        if (!_.isEmpty(compliancesToSave)) {
            master.compliances = compliancesToSave.map((item) => {
                // no need to set payload for compliance that has the object data
                if (item && typeof item == "object") {
                    return item;
                }

                // set new compliance data
                const compliance =
                    compliancesFromOptions.find((ci) => ci.value == item) || {};

                return GetCompliancePayload({
                    asset_type: compliance.value || item,
                    compliance_code: compliance.code || "",
                });
            });
        }

        return master;
    }

    handleEditMaster(master) {
        this.setState(
            {
                masterToUpdate: master,
                alternateMaster:
                    master.asset_type == this.alternativeMasterAssetType,
            },
            () => {
                this.setState({
                    notification: this.notificationUpdateMasterProps(),
                });
            },
        );
    }

    handleCreateNewAlternateMaster() {
        this.setState(
            {
                newRow: GetDefaultNewRowValues(this.props),
                alternateMaster: true,
            },
            () => {
                this.setState({
                    notification: this.notificationNewRowProps(),
                });
            },
        );
    }

    handleChange(updatedState) {
        const { handleChange = () => {} } = this.props;

        handleChange(updatedState);
    }

    addNewRowToState() {
        const { newRow = {}, data = [] } = this.state;

        const master = this.setMasterPayload(newRow);
        let updatedState = [...data];
        updatedState.push(master);

        this.setState(
            {
                data: updatedState,
                newRow: {},
                alternateMaster: false,
            },
            () => {
                this.handleChange(updatedState);

                this.setState({
                    notification: {},
                });
            },
        );
    }

    updateMaster() {
        const { masterToUpdate = {}, data = [] } = this.state;

        const master = this.setMasterPayload(masterToUpdate);

        const index = _.findIndex(data, function (item) {
            return (
                item.compliance_code == masterToUpdate.compliance_code &&
                item.asset_type == masterToUpdate.asset_type
            );
        });

        const updatedState = update(data, {
            [index]: {
                $set: master,
            },
        });

        this.setState(
            {
                data: updatedState,
                masterToUpdate: {},
                newRow: {},
                alternateMaster: false,
            },
            () => {
                this.handleChange(updatedState);

                this.setState({
                    notification: {},
                });
            },
        );
    }

    handleNotificationCancel() {
        this.setState({
            notification: {},
        });
    }

    handleDeletePlaceholder(props) {
        const notification = {
            okText: "Delete",
            confirm: this.deleteAsset.bind(this, props),
            onClick: this.handleNotificationCancel.bind(this),
            title: "Delete Asset",
            description: "Are you sure you want to delete this asset?",
            status: "alert",
        };

        this.setState({
            notification,
        });
    }

    deleteAsset(props) {
        if (_.isEmpty(props)) {
            // delete all failed assets
            this.handleAssetUpdate("delete");
        } else {
            const { masterIndex, complianceIndex } =
                this.findComplianceAndMasterindexes(props);

            const { data = [] } = this.state;

            let updatedState = deepcopy(data);

            // delete master
            if (Number.isInteger(masterIndex) && complianceIndex === false) {
                if (data[masterIndex].status == this.preSavedStatus) {
                    updatedState = update(data, {
                        $splice: [[masterIndex, 1]],
                    });
                } else {
                    updatedState = update(data, {
                        [masterIndex]: {
                            deleted: {
                                $set: true,
                            },
                        },
                    });
                }
            }

            // delete compliance
            if (
                Number.isInteger(masterIndex) &&
                Number.isInteger(complianceIndex)
            ) {
                if (
                    data[masterIndex].compliances[complianceIndex].status && 
                    data[masterIndex].compliances[complianceIndex].status == this.preSavedStatus
                ) {
                    updatedState = update(data, {
                        [masterIndex]: {
                            compliances: {
                                $splice: [[complianceIndex, 1]],
                            },
                        },
                    });
                } else {
                    updatedState = update(data, {
                        [masterIndex]: {
                            compliances: {
                                [complianceIndex]: {
                                    deleted: {
                                        $set: true,
                                    },
                                },
                            },
                        },
                    });
                }
            }

            this.setState(
                {
                    data: updatedState,
                    notification: {},
                },
                () => {
                    this.handleChange(updatedState);
                },
            );
        }
    }

    handlePublishRetry() {
        this.handleAssetUpdate("retry");
    }

    handleAssetUpdate(option) {
        const { data = [] } = this.state;

        let updatedState = [...data];
        updatedState.map((item) => {
            if (item.status == this.errorStatus) {
                if (option == "delete") {
                    item.deleted = true;
                }

                if (option == "retry") {
                    item.status = this.preSavedStatus;
                }
            }

            return item;
        });

        this.setState(
            {
                data: updatedState,
            },
            () => {
                this.placeholderCreated = this.checkStatus(
                    this.placeholderCreatedStatus,
                );
                this.placeholderError = this.checkStatus(this.errorStatus);

                this.handleChange(updatedState);
                this.handleNotificationCancel();
            },
        );
    }

    handleViewErrorMessage() {
        const { data = {} } = this.state;

        const activeData = GetActiveData(data);
        const item =
            activeData.find(
                (item) => !item.deleted && item.status == this.errorStatus,
            ) || {};

        this.viewMessage(item.message);
    }

    handleEditIds(props) {
        const { compliance = {} } = props;

        const {
            turner_emea_id,
            turner_apac_id,
            turner_latam_id,
            ibms_house_id,
            eidr,
            titles_edit_id,
            hbo_eu_regional_clip_id,
            hbo_eu_content_id,
            pavi_id,
            territories,
        } = compliance;

        this.setState(
            {
                ids: {
                    turner_emea_id,
                    turner_apac_id,
                    turner_latam_id,
                    ibms_house_id,
                    eidr,
                    titles_edit_id,
                    hbo_eu_regional_clip_id,
                    hbo_eu_content_id,
                    pavi_id,
                    territories,
                },
            },
            () => {
                this.renderAssetInformationIds(props);
            },
        );
    }

    transformMetaUsersDataList() {
        const { meta_users = [] } = this.props;

        let result = [];

        if (Array.isArray(meta_users)) {
            result = meta_users.map((item) => {
                return {
                    text: item.fullName,
                    list: `${item.fullName} (${item.email})`,
                    value: item.email,
                };
            });
        }

        return result;
    }

    handleEditWorkflowNotifications(props) {
        const { compliance = {} } = props;

        const { meta_users = [], email_addresses = [] } = compliance;

        const transformedMetaUsersDatalist = this.transformMetaUsersDataList();

        this.setState(
            {
                emails: {
                    meta_users,
                    email_addresses,
                },
            },
            () => {
                this.renderAssetInformationWorkflowNotifications({
                    ...props,
                    transformedMetaUsersDatalist,
                    meta_users: this.props.meta_users || [],
                });
            },
        );
    }

    handleEditCertifications(props) {
        const { compliance = {} } = props;

        const { certifications = [], territory } = compliance;

        this.setState(
            {
                certifications,
                territory,
            },
            () => {
                this.renderAssetInformationCertificatesAdvisory({
                    ...props,
                });
            },
        );
    }

    handleIdsChange(key, value) {
        const { ids = {} } = this.state;

        let updatedState = { ...ids };
        updatedState[key] = value;

        this.setState({
            ids: updatedState,
        });
    }

    handleWorkflowNotificationsChange(key, value) {
        const { emails = {} } = this.state;

        let updatedState = { ...emails };
        updatedState[key] = value;

        this.setState({
            emails: updatedState,
        });
    }

    handleCertificationChange(updatedState) {
        this.setState({
            ...updatedState,
        });
    }

    handleAttachIdsToCompliance(props) {
        const { ids = {} } = this.state;

        // Find index
        const { masterIndex, complianceIndex } =
            this.findComplianceAndMasterindexes(props);

        const updatedState = this.updateAssetWithValues({
            values: ids,
            masterIndex,
            complianceIndex,
        });

        this.setState({
            data: updatedState,
            ids: {},
            notification: {},
        });

        this.handleChange(updatedState);
    }

    handleAttachWorkflowNotificationsToCompliance(props) {
        const { emails = {} } = this.state;

        // Find index
        const { masterIndex, complianceIndex } =
            this.findComplianceAndMasterindexes(props);

        // reverts to simple array
        const transformedMetaUsers = TransformMetaUsersToSimpleArray(
            emails.meta_users,
        );

        let values = {
            email_addresses: emails.email_addresses,
            meta_users: transformedMetaUsers,
        };

        const updatedState = this.updateAssetWithValues({
            values,
            masterIndex,
            complianceIndex,
        });

        this.setState({
            data: updatedState,
            emails: {},
            notification: {},
        });

        this.handleChange(updatedState);
    }

    handleAttachCertificationToCompliance(props) {
        const { certifications = [], territory } = this.state;

        // Find index
        const { masterIndex, complianceIndex } =
            this.findComplianceAndMasterindexes(props);

        const updatedState = this.updateAssetWithValues({
            values: {
                certifications,
                territory,
            },
            masterIndex,
            complianceIndex,
        });

        this.setState({
            data: updatedState,
            certifications: [],
            notification: {},
        });

        this.handleChange(updatedState);
    }

    updateAssetWithValues(props) {
        const { values, masterIndex, complianceIndex } = props;

        const { data = [] } = this.state;

        let updatedState = deepcopy(data);

        // only update a master
        if (complianceIndex === false) {
            updatedState = update(data, {
                [masterIndex]: {
                    $set: {
                        ...updatedState[masterIndex],
                        ...values,
                    },
                },
            });
        } else {
            updatedState = update(data, {
                [masterIndex]: {
                    compliances: {
                        [complianceIndex]: {
                            $set: {
                                ...updatedState[masterIndex].compliances[
                                    complianceIndex
                                ],
                                ...values,
                            },
                        },
                    },
                },
            });
        }

        return updatedState;
    }

    findComplianceAndMasterindexes(props) {
        const { compliance, master = {} } = props;

        const { data = [] } = this.state;

        // find master index
        const masterIndex = _.findIndex(data, function (item) {
            return (
                item.compliance_code == master.compliance_code &&
                item.asset_type == master.asset_type &&
                !item.deleted
            );
        });

        // find compliance index
        let complianceIndex = false;

        // check if compliance is not master
        if (compliance && _.isEmpty(compliance.compliances)) {
            complianceIndex = _.findIndex(master.compliances, function (item) {
                return (
                    item.compliance_code == compliance.compliance_code &&
                    item.asset_type == compliance.asset_type &&
                    !item.deleted
                );
            });
        }

        return {
            masterIndex,
            complianceIndex,
        };
    }

    renderAssetInformationIds(props) {
        const { ids = {} } = this.state;

        const { clientFeatures = {}, cmsData = {} } = this.props;

        const { apiUrls = {} } = cmsData;

        const { compliance = {} } = props;

        this.setState({
            notification: {
                okText: "Ok",
                title: "Asset Information",
                html: (
                    <AssetIds
                        ids={ids}
                        compliance={compliance}
                        handleUpdate={this.handleIdsChange.bind(this)}
                        clientFeatures={clientFeatures}
                        apiUrls={apiUrls}
                    />
                ),
                confirm: () => this.handleAttachIdsToCompliance(props),
                onClick: () => {
                    this.handleNotificationCancel();
                    this.setState({ ids: {} });
                },
                status: "alert",
            },
        });
    }

    renderAssetInformationWorkflowNotifications(props) {
        const { emails = {} } = this.state;

        const {
            compliance = {},
            transformedMetaUsersDatalist,
            meta_users,
        } = props;

        this.setState({
            notification: {
                okText: "Ok",
                title: "Workflow Notifications",
                html: (
                    <WorkflowNotifications
                        emails={emails}
                        compliance={compliance}
                        transformedMetaUsersDatalist={
                            transformedMetaUsersDatalist
                        }
                        meta_users={meta_users}
                        handleUpdate={this.handleWorkflowNotificationsChange.bind(
                            this,
                        )}
                    />
                ),
                confirm: () =>
                    this.handleAttachWorkflowNotificationsToCompliance(props),
                onClick: () => {
                    this.handleNotificationCancel();
                    this.setState({ emails: {} });
                },
                status: "alert",
            },
        });
    }

    renderAssetInformationCertificatesAdvisory(props) {
        const { certifications = {}, territory } = this.state;

        const { compliance = {} } = props;

        const { options = {}, clientFeatures = {}, cmsData = {} } = this.props;

        this.setState({
            notification: {
                okText: "Ok",
                title: "Asset Information",
                html: (
                    <AssetInformationCertificatesAdvisory
                        territory={territory}
                        certifications={certifications}
                        cmsData={cmsData}
                        compliance={compliance}
                        options={options}
                        preSavedStatus={this.preSavedStatus}
                        parentRef={this.parentNotificationRef}
                        clientFeatures={clientFeatures}
                        handleUpdate={this.handleCertificationChange.bind(this)}
                    />
                ),
                confirm: () =>
                    this.handleAttachCertificationToCompliance(props),
                onClick: () => {
                    this.handleNotificationCancel();
                    this.setState({ certifications: [] });
                },
                status: "alert",
            },
        });
    }

    handleResendWorkflowNotifications(item) {
        const { notification } = this.state;

        const { meta_id } = item;

        const { assetResendWorkflowNotificationsUrl = "" } = this.props;

        let url = assetResendWorkflowNotificationsUrl.replace(
            "{metaId}",
            meta_id,
        );

        const body = {
            emails: _.concat(item.meta_users || [], item.email_addresses || []),
        };

        const options = {
            url,
            method: "POST",
            body,
        };

        let updatedNotification = deepcopy(notification);
        updatedNotification.loading = true;
        updatedNotification.disabled = true;
        updatedNotification.confirm = false;

        this.setState({
            notification: updatedNotification,
        });

        const handleError = (error) => {
            updatedNotification.okText = this.copy.ok || "Ok";
            updatedNotification.loading = false;
            updatedNotification.disabled = false;
            updatedNotification.title = this.copy.error || "Error";
            updatedNotification.description =
                this.copy.errorResendingWorkflowNotifications ||
                "Error occured whilst resending workflow notification";

            this.setState({
                notification: updatedNotification,
            });

            if (error) {
                console.log(error);
            }
        };

        SendRequest(
            options,
            (response) => {
                const { status } = response;

                if (status == 200) {
                    this.setState({
                        notification: {},
                    });

                    return;
                }

                handleError();
            },
            (e) => {
                handleError(e);
            },
        );
    }

    viewMessage(message) {
        const RenderHtml = () => {
            return (
                <span
                    style={{
                        display: "block",
                        marginTop: "1rem",
                    }}
                >
                    {message}
                </span>
            );
        };

        const notification = {
            okText: "Ok",
            confirm: false,
            onClick: this.handleNotificationCancel.bind(this),
            title: "Asset Message",
            description: false,
            html: RenderHtml(),
            status: "alert",
        };

        this.setState({
            notification,
        });
    }

    getLanguages() {
        const { languages_options = {}, options = {} } = this.props;

        const { options_key = "", list_key = "" } = languages_options;

        return {
            languages: options[options_key] || [],
            list_key,
        };
    }

    renderAssetContainer(props) {
        return <AssetContainer {...props} />;
    }

    render() {
        const { notification = {}, data: activeData = [] } = this.state;

        const {
            disabled,
            media_id,
            options,
            locale,
            canDeleteAllAssets,
            clientFeatures = {},
            send_to_ai,
            dataStructure,
            dispatchNotification,
            audits = [],
            canDeleteCompliance,
            category,
            canCreateNew = true,
            cmsData = {},
        } = this.props;

        const { assets } = clientFeatures;

        const { list_key, languages } = this.getLanguages();

        const optionLanguages = languages;
        const orignalMaster =
            activeData.find((item) => {
                if (
                    clientFeatures.assetsDemo &&
                    item.asset_type === "Theatrical Master"
                ) {
                    return item;
                }
                return (
                    item.compliance_code === this.orignComplianceCode &&
                    item.asset_type === this.originalMasterAssetType &&
                    !item.deleted
                );
            }) || {};
        const alternativeMasters =
            activeData.filter(
                (item) => item.asset_type == this.alternativeMasterAssetType,
            ) || [];
        const alternativeMastersTransformed = alternativeMasters.map(
            (alternateMaster) => {
                let locale = alternateMaster.video;

                const languages = optionLanguages.find(
                    (item) => item[list_key] == alternateMaster.video,
                );
                if (languages && languages[list_key] && languages.name) {
                    locale = `${languages.name} • ${languages[list_key]}`;
                }

                alternateMaster.locale = locale;
                return alternateMaster;
            },
        );

        let editableSectionOriginalMaster = !!(
            assets && assets.editableSectionOriginalMaster
        );
        if (disabled) editableSectionOriginalMaster = false;
        const assetContainerProps = {
            data: activeData,
            orignalMaster,
            alternativeMasters: alternativeMastersTransformed,
            media_id,
            options,
            disabled,
            audits,
            category,
            editableSectionOriginalMaster: editableSectionOriginalMaster,
            masterLocale:
                clientFeatures.assetsDemo && orignalMaster.locale
                    ? orignalMaster.locale
                    : locale,
            pendingStatus: this.pendingStatus,
            preSavedStatus: this.preSavedStatus,
            placeholderCreated: this.placeholderCreated,
            errorStatus: this.errorStatus,
            placeholderError: this.placeholderError,
            placeholderCreatedStatus: this.placeholderCreatedStatus,
            canDeleteAllAssets,
            canDeleteCompliance,
            handleEditMaster: this.handleEditMaster.bind(this),
            handleCreateNewAlternateMaster:
                this.handleCreateNewAlternateMaster.bind(this),
            handlePublishRetry: this.handlePublishRetry.bind(this),
            handleDeletePlaceholder: this.handleDeletePlaceholder.bind(this),
            handleViewErrorMessage: this.handleViewErrorMessage.bind(this),
            handleResendWorkflowNotifications:
                this.handleResendWorkflowNotifications.bind(this),
            handleEditIds: this.handleEditIds.bind(this),
            handleEditWorkflowNotifications:
                this.handleEditWorkflowNotifications.bind(this),
            alternativeMasterAssetType: this.alternativeMasterAssetType,
            handleEditSPHX: () => {},
            handleEditCertifications: this.handleEditCertifications.bind(this),
            send_to_ai,
            clientFeatures,
            dataStructure,
            dispatchNotification,
            handleChange: (updatedState) => {
                this.setState(
                    {
                        data: updatedState,
                    },
                    () => {
                        this.handleChange(updatedState);
                    },
                );
            },
            cmsData: cmsData,
        };

        return (
            <div className="meta-assets-v2">
                {(!_.isEmpty(activeData) && !orignalMaster.deleted) &&
                    this.renderAssetContainer(assetContainerProps)}

                <Notification
                    title={notification.title}
                    description={notification.description}
                    intercationStatus={notification.status}
                    html={notification.html}
                    confirm={notification.confirm}
                    background="white"
                    disabled={notification.disabled}
                    onClick={
                        notification.onClick
                            ? notification.onClick
                            : () => this.handleNotificationCancel.bind(this)
                    }
                    okText={notification.okText}
                    contentNoPadding={notification.contentNoPadding}
                    loading={notification.loading}
                    errorMessage={notification.errorMessage}
                    keyPressEnterDisabled={true}
                    closeOnWrapperClick={false}
                    confirmOnEnter={false}
                    maxWidth={notification.maxWidth}
                    ref={this.parentNotificationRef}
                />
            </div>
        );
    }
}
