export const TYPE_RECORDS_SEARCH = "recordsSearch";
export const TYPE_DISCOVERY = "discovery";
export const TYPE_COLLECTIONS = "collections";
export const TYPE_LOCALISATIONS = "localisations";
export const TYPE_CONNECT_COLLECTIONS = "connectCollections";
export const TYPE_REPORTING = "reporting";
export const TYPE_REPORTING_LOOKER = "reportingLooker";
export const TYPE_REPORTING_EXPLORE = "reportingExplore";
export const TYPE_CATALOG_SNAPSHORT = "fabricStudiosCatalogSnapshot";
export const TYPE_TITLE_DUPLICATE_DASHBOARD = "titleDuplicateDashboard";
export const TYPE_D2C_DELIVERY_DASHBOARD = "d2CDeliveryDashboard";
export const TYPE_QVOD_DELIVERY_DASHBOARD = "q3VODDeliveryDashboard";
export const TYPE_LOCALISATION_STATUS_DASHBOARD = "localisationStatusDashboard";
export const TYPE_CONNECT_MANAGER = "connectManager";
export const TYPE_LINEUP_MANAGER = "lineupManager";
export const TYPE_ACCOUNT = "account";
export const TYPE_PLATFORM_MANAGEMENT = "platformManagement";
export const TYPE_PREFERENCES = "preferences";
export const TYPE_USER_ROLE_MANAGEMENT = "userRoleManagement";
export const TYPE_RECORD_BUILDER = "recordBuilder";
export const TYPE_TASK_MANAGER = "taskManager";
