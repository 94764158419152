import { isString } from 'lodash'

export default function selectTransformer(items: any, options: any = {}): any  {
    if (!Array.isArray(items)) {
        return []
    }

    const result = items.map((item: any) => {
        if (isString(item)) {
            return {
                value: item,
                label: item,
                ...options
            }
        }

        const value: string | number = item.value 
            || item.name 
            || item.text 
            || item.label

        const label: string = item.label
            || item.text 
            || item.value

        return {
            ...item,
            value: value?.toString(),
            label: label?.toString(),
            ...options
        }
    })

    return result
}
