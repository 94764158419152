import { arrayMove } from "react-sortable-hoc";
import update from "react-addons-update";

const HandleSort = (props) => {
    const { items, movedData, setItems, handleChange } = props;

    let updatedState = update(items, {
        [movedData.oldIndex]: {
            order: {
                $set: movedData.newIndex,
            },
        },
    });

    updatedState = arrayMove(
        updatedState,
        movedData.oldIndex,
        movedData.newIndex,
    );

    if (Array.isArray(updatedState)) {
        setItems(updatedState);
        handleChange(updatedState);
    }
};

export default HandleSort;
