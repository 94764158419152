import React from "react"
import { observer } from "mobx-react";
import { useStore as globalStore } from "../../../../../components/Global/store/store";
import { RootStore, useStores } from "../../store/rootStore";
import CreditListSelect from "../components/CreditListSelect";
import ScriptSelect from "../../../../../components/Select/ScriptSelect";
import Button from "../../../../../components/Button"
import { CAST_AND_CREW } from "../../../../../components/ClientManagedOptions/options";


const ActiveTableActionBar: React.FC = observer(() => {
    const [globalState] = globalStore() || [{}];
    const rootStore: RootStore | {} = useStores() || {}
    const { creditsStore } = rootStore as RootStore

    const {
        cmsData = {},
        options = {}
    } = globalState

    const editStatus: boolean = (rootStore as RootStore).getEditStatus
    const selectedCredits = creditsStore.selectedCredits

    const showCreditCreateOrUpdateForm = () => {
        creditsStore.setCreditFormStatus({
            isActive: true,
            isDisabled: true,
            searchIsActive: true,
            isNewCredit: true
        })
    }


    const handleDeleteCredits = () => {
        creditsStore.setCreditDeleteFormStatus(true)
        creditsStore.setCreditDeleteFormProperty("source", "active")
    }

    return (
        <React.Fragment>
            <div className="credits-top-container">
                <CreditListSelect />

                <ScriptSelect
                    cmsData={cmsData}
                    options={options}
                    scriptValue={(rootStore as RootStore).scriptValue}
                    scriptOptionsListKey={CAST_AND_CREW}
                    setScriptValue={(value) => {
                        (rootStore as RootStore).setScriptValue(value);
                        (rootStore as RootStore).creditsStore.refreshTableData();
                    }}
                    wrapperStyles={{
                        margin: "0 0.5rem 0 0",
                        minWidth: "20rem"
                    }}
                />

                <div className="credits-actions">
                    {!!selectedCredits.active.length &&
                        <>
                            <Button
                                value="Delete"
                                onClick={() => handleDeleteCredits()}
                                type="button"
                                buttonSection={true}
                                buttonDelete={true}
                                disabled={editStatus}
                                className={editStatus ? "disabled" : ''}
                            />

                            <Button
                                value="Remove from Credit List"
                                onClick={() => creditsStore.removeCreditsFromCreditList()}
                                type="button"
                                buttonSection={true}
                                disabled={editStatus}
                                className={editStatus ? "disabled" : ''}
                            />
                        </>
                    }

                    {!!(rootStore as RootStore).getTableSortStatus.active &&
                        <Button
                            value="Clear Sorting"
                            onClick={() => (rootStore as RootStore).resetTableSort()} 
                            type="button"
                            marginLeftTop={true}
                            buttonSection={true}
                        />
                    }

                    <span className="divider" />

                    <Button
                        value="New Credit"
                        onClick={() => showCreditCreateOrUpdateForm()}
                        type="button"
                        buttonSection={true}
                        disabled={editStatus}
                        className={editStatus ? "disabled" : ''}
                    />
                </div>
            </div>
        </React.Fragment>
    )
})

export default ActiveTableActionBar