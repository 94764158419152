import { useFormContext } from "react-hook-form";
import { CoreFormPath } from "../../../../../../core/Form/types";
import { C255 } from "../../../../config/clients/viacom/validation/constants";
import { StandaloneVT } from "../../../../config/types";
import { AutocompleteRecords } from "../../tv-series/smart/AutocompleteRecords";

export const StandaloneTitle = () => {
    const path: CoreFormPath<StandaloneVT>["path"] = "title";
    const disabled = false;

    const form = useFormContext<StandaloneVT>();
    const category = form.getValues("category");

    return (
        <AutocompleteRecords
            disabled={disabled}
            labelProps={{
                text: "Title",
                isRequired: true,
                isDisabled: disabled,
            }}
            maxLength={C255}
            path={path}
            headerText="Existing Records"
            disableEmptyCard
            category={category}
        />
    );
};
