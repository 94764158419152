import { isEmpty } from "lodash";
import { WSBaseUrl } from "../../services/api.config";

export const isWebSocketURLValid = () => {
    if (WSBaseUrl?.includes("undefined") || isEmpty(WSBaseUrl?.trim())) {
        console.log(
            "The WebSocket URL is not provided, which means that the background processing feature won't be initialized.",
        );
        return false;
    }
    return true;
};
