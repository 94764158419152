const HandleCollectionTitleSelectAll = (props) => {
    const { state = {} } = props;

    const { collectionTitle = {}, setCollectionTitleKey = () => {} } = state;

    const { selectedAll, episodes = [] } = collectionTitle;

    let result = !selectedAll;

    setCollectionTitleKey("selectedAll", result);

    // Add all selected episodes
    if (result && Array.isArray(episodes)) {
        setCollectionTitleKey("selectedEpisodes", episodes);
    }

    // Remove all selected episodes
    if (!result) {
        setCollectionTitleKey("selectedEpisodes", []);
    }
};

export default HandleCollectionTitleSelectAll;
