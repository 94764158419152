import _ from "lodash";
import SendRequest from "../../../components/SendRequest";
import { SendRequestResponseInterface, SendRequestOptionsInterface } from "../../../components/SendRequest/interface";
import { CmsInterface } from "../../../components/Global/interfaces";

export async function getAllLists(
    cmsData: CmsInterface,
): Promise<SendRequestResponseInterface> {
    const options: SendRequestOptionsInterface = {
        method: "GET",
        url: _.get(cmsData, "apiUrls.clientManagedLists", ""),
    };

    const response: SendRequestResponseInterface = await SendRequest(options);

    return new Promise((resolve, reject) => {
        if (response.status == 200) {
            resolve(response.data);
            return;
        }

        reject(response.message || "Server Error");
    });
}

export async function getListItems(
    cmsData: CmsInterface,
    list: string,
): Promise<SendRequestResponseInterface> {
    const options: SendRequestOptionsInterface = {
        method: "GET",
        url: _.get(cmsData, "apiUrls.clientManagedListItems", "").replace(
            "{code}",
            list,
        ),
    };

    const response: SendRequestResponseInterface = await SendRequest(options);

    return new Promise((resolve, reject) => {
        if (response.status == 200 && response.data) {
            resolve(response.data);
            return;
        }

        reject(response.message || "Server Error");
    });
}

export async function searchListItems(
    cmsData: CmsInterface,
    list: string,
): Promise<SendRequestResponseInterface> {
    const options: SendRequestOptionsInterface = {
        method: "GET",
        url: _.get(cmsData, "apiUrls.clientManagedListItemsSearch", "").replace(
            "{code}",
            list,
        ),
    };

    const response: SendRequestResponseInterface = await SendRequest(options);

    return new Promise((resolve, reject) => {
        if (response.status == 200 && response.data?.items) {
            resolve(response.data?.items || []);
            return;
        }

        reject(response.message || "Server Error");
    });
}

export async function searchListItemsByAttribute(
    cmsData: CmsInterface,
    list: string,
): Promise<SendRequestResponseInterface> {
    const options: SendRequestOptionsInterface = {
        method: "GET",
        url: _.get(cmsData, "apiUrls.clientManagedListItemsSearch", "").replace(
            "{code}",
            list,
        ),
    };

    const response: SendRequestResponseInterface = await SendRequest(options);

    return new Promise((resolve, reject) => {
        if (response.status == 200 && response.data?.items) {
            resolve(response.data?.items || []);
            return;
        }

        reject(response.message || "Server Error");
    });
}

export async function updateListItems(
    cmsData,
    list: string,
    items: any,
): Promise<SendRequestResponseInterface> {
    const options: SendRequestOptionsInterface = {
        method: "PUT",
        url: _.get(cmsData, "apiUrls.clientManagedListItems", "").replace(
            "{code}",
            list,
        ),
        body: {
            items,
        },
    };

    const response: SendRequestResponseInterface = await SendRequest(options);

    return new Promise((resolve, reject) => {
        if (response.status == 200 && response.data) {
            resolve(response.data);
            return;
        }

        reject(response.message || "Server Error");
    });
}

export async function createList(
    cmsData: CmsInterface,
    listData,
): Promise<SendRequestResponseInterface> {
    const options: SendRequestOptionsInterface = {
        method: "POST",
        url: _.get(cmsData, "apiUrls.clientManagedListCreate", ""),
        body: listData,
    };

    const response: SendRequestResponseInterface = await SendRequest(options);

    return new Promise((resolve, reject) => {
        if (response.status == 200 && response.data) {
            resolve(response.data);
            return;
        }

        reject(response.message || "Server Error");
    });
}
