import React from "react";
import { Box, Grid } from "@mui/material";
import { FieldValues, useFormContext } from "react-hook-form";
import randomcolor from "randomcolor"
import { FieldPath } from "../../../types";
import "../../styles/ColorPalette.scss";
import { CoreFormLabel } from "../../../../Form/components/styled/CoreForm.label";
import { CoreRadio } from "../../../../Form/components/inputs/Radio/CoreRadio";
import { chipColors } from "../../utils/ColorPicker";

export type CoreColorPaletteProps<T extends FieldValues> = {
    path: FieldPath<T>;
    disabled?: boolean;
    labelProps: {
        text: string,
        isRequired?: boolean
    }
};

interface RandomOptionInterface {
    label: string,
    value: string,
    sx: any
}

export function CoreColorPalette<T extends FieldValues>({
    path,
    labelProps
}: CoreColorPaletteProps<T>) {
    const formMethods = useFormContext<T>();
    const value = formMethods.watch(path);

    const randomOption: RandomOptionInterface = {
        label: 'Surprise Me!',
        value: randomcolor(),
        sx: {
            color: '#286CD2',
            marginLeft: 0
        }
    }

    return (
        <>
            <CoreFormLabel 
                text={labelProps?.text}
                isRequired={labelProps?.isRequired}
            />

            <Grid 
                container 
                width="100%" 
                spacing={1} 
                marginTop={'0 !important'}>

                {chipColors?.map((option) => {
                    return (
                        <Grid item xs={3} width="100%">
                            <CoreRadio
                                key={option.value}
                                option={option}
                                path={path}
                                variant='condensed'
                                error={false}
                                customRadioStyles={{
                                    backgroundColor: option.value,
                                    borderRadius: '0.1875rem',
                                    height: '3.125rem',
                                    "&:hover": {
                                        cursor: "pointer",
                                        opacity: value !== option.value && '0.85'
                                    }
                                }}
                                hideSelector={true}
                                hideLabel={true}
                                disableMaxWidth
                            />
                        </Grid>
                    );
                })}

                <Grid item xs={3} width="100%">
                    <CoreRadio
                        key={randomOption.value}
                        option={randomOption}
                        path={path}
                        variant='condensed'
                        error={false}
                        customRadioStyles={{
                            backgroundColor: 'rgba(40, 108, 210, 0.05)',
                            textAlign: 'center',
                            borderRadius: '0.1875rem',
                            height: '3.125rem',
                            "&:hover": {
                                cursor: "pointer",
                                opacity: value !== randomOption.value && '0.85'
                            }
                        }}
                        hideSelector={true}
                        disableMaxWidth
                    />
                </Grid>
            </Grid>

            <CoreFormLabel 
                text={`Selected ${labelProps?.text}`}
                containerStyles={{ marginTop: '1rem' }}
            />

            <Box className="color-palette--box">
                <div 
                    style={{ backgroundColor: value || '#F3F3F3' }} 
                    className="color-palette--selected">

                    {!value && 'No Colour Selected'}
                </div>

                {value && 
                    <div className="color-palette--selected-text-container">
                        <div>HEX: <span>{value}</span></div>
                    </div>
                }
            </Box>
        </>
    );
}