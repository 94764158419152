import React, { Component } from "react";
import _ from "lodash";
import deepcopy from "deepcopy";
import moment from "moment";
import getYears from "../Options/years";
import SelectWithTag from "../SelectWithTag";
import Select from "../Select";
import Input from "../Input/basic";
import RecordTimeInput from "../Boilerplates/RecordTimeInput";
import "./styles/_styles.scss";
import CategoryTags from "../CategoryTags";

export default class AssetMandatoryAttrForm extends Component {
    constructor(props) {
        super(props);

        const { requiredMetadata, data = {} } = props;

        const { origination = {} } = data;

        let ids = {};
        if (typeof data.ids == "object" && data.ids) {
            ids = data.ids;
        }

        this.state = {
            formData: {
                original_title: data.original_title,
                ids: {
                    international_id: ids.international_id,
                },
                origination: {
                    first_release_year: origination.first_release_year,
                    type: origination.type,
                    locale: origination.locale,
                    release_duration: origination.release_duration,
                },
            },
            formError: [],
            formDisabled: false,
        };

        const defaultKeys = [
            "original_title",
            "ids.international_id",
            "origination.first_release_year",
            "origination.type",
            "origination.locale",
            "origination.release_duration",
        ];

        this.fieldKeys = Array.isArray(requiredMetadata)
            ? requiredMetadata
            : defaultKeys;

        this.numberingKey = null;
        if (data.category == "Episode") {
            this.numberingKey = "episode_numbering";
        } else if (data.category == "Storyline") {
            this.numberingKey = "storyline_numbering";
        }

        if (this.numberingKey) {
            this.state.formData[this.numberingKey] = {
                production_number:
                    data[this.numberingKey].production_number || null,
            };
            this.fieldKeys.push(`${this.numberingKey}.production_number`);
        }

        this.customFieldKeys = [];
    }

    setFormData(key, value) {
        let formData = deepcopy(this.state.formData);
        _.set(formData, key, value);

        this.setState({
            formData,
        });

        this.unsetFormError(key);
    }

    isFormDataValid() {
        return (
            this.fieldKeys.every((key) => !!_.get(this.state.formData, key)) &&
            this.customFieldKeys.every((key) => this.isCustomFieldValid(key))
        );
    }

    isCustomFieldValid(key) {
        return true;
    }

    handleInvalidFormData() {
        const customFieldError = this.customFieldKeys.filter(
            (key) => !this.isCustomFieldValid(key),
        );
        const formError = this.fieldKeys
            .filter((key) => !_.get(this.state.formData, key))
            .concat(customFieldError);
        this.setState({ formError });
    }

    isFieldInvalid(key) {
        return this.state.formError.includes(key);
    }

    unsetFormError(key) {
        const formError = this.state.formError.filter(
            (errorKey) => errorKey != key,
        );
        this.setState({ formError });
    }

    submit() {
        if (!this.isFormDataValid()) {
            this.handleInvalidFormData();
            return;
        }

        this.setState({
            formDisabled: true,
        });

        if (typeof this.props.onSubmitLoad == "function") {
            this.props.onSubmitLoad();
        }

        if (typeof this.props.onSubmitComplete == "function") {
            this.props.onSubmitComplete(this.state.formData);
        }
    }

    render() {
        const {
            attributes = {},
            copy = {},
            options = {},
            data = {},
        } = this.props;
        const defaultYear = moment().year();

        const typeOptions = options.record_types
            .filter((type) => type.category == data.category)
            .map((type) => ({
                value: type.value,
                text: type.type,
            }));

        const { formData, formDisabled } = this.state;

        return (
            <div className="meta-asset-mandatory-attr-form">
                <div className="meta-asset-mandatory-attr-form-title">
                    <h3>Mandatory Attributes for Create Assets</h3>
                </div>
                <div className="meta-asset-mandatory-attr-form-content">
                    <div>
                        <Input
                            id="original_title"
                            name="original_title"
                            value={formData.original_title}
                            label={copy.apacId || "Original Title"}
                            placeholder={
                                copy.enterOriginalTitle || "Enter Title"
                            }
                            disabled={formDisabled}
                            recordBasic={true}
                            error={this.isFieldInvalid("original_title")}
                            handleChange={(value) =>
                                this.setFormData("original_title", value)
                            }
                        />
                    </div>
                    {this.numberingKey && (
                        <div>
                            <Input
                                id="production_number"
                                name="production_number"
                                value={
                                    formData[this.numberingKey]
                                        .production_number
                                }
                                label={
                                    copy.originalProductionNumber ||
                                    "Original Production Number"
                                }
                                placeholder={
                                    copy.originalProductionNumber ||
                                    "Enter Production Number"
                                }
                                disabled={formDisabled}
                                recordBasic={true}
                                error={this.isFieldInvalid(
                                    `${this.numberingKey}.production_number`,
                                )}
                                handleChange={(value) =>
                                    this.setFormData(
                                        `${this.numberingKey}.production_number`,
                                        value,
                                    )
                                }
                            />
                        </div>
                    )}
                    <div>
                        <Input
                            id="international_id"
                            name="international_id"
                            value={formData.ids.international_id}
                            label={copy.internationalId || "International ID"}
                            placeholder={
                                copy.enterInternationalId ||
                                "Enter International ID"
                            }
                            disabled={formDisabled}
                            recordBasic={true}
                            error={this.isFieldInvalid("ids.international_id")}
                            handleChange={(value) =>
                                this.setFormData("ids.international_id", value)
                            }
                        />
                    </div>
                    <div className="meta-asset-mandatory-attr-form-content-row">
                        <Select
                            id="type"
                            name="type"
                            value={formData.origination.type}
                            clear={true}
                            label={copy.recordType || "Record Type"}
                            disabledText={copy.selectType || "Select Type"}
                            noneSelectedText={
                                copy.noneSelected || "None Selected"
                            }
                            readOnly={formDisabled}
                            disabled={formDisabled}
                            data={typeOptions}
                            error={this.isFieldInvalid("origination.type")}
                            handleChange={(value) =>
                                this.setFormData("origination.type", value)
                            }
                        />
                        <Select
                            name="first_release_year"
                            id="first_release_year"
                            value={formData.origination.first_release_year}
                            clear={true}
                            label={copy.releaseYear || "Release Year"}
                            disabledText={
                                copy.selectReleaseYear || "Select Release Year"
                            }
                            noneSelectedText={
                                copy.noneSelected || "None Selected"
                            }
                            readOnly={formDisabled}
                            disabled={formDisabled}
                            data={getYears(attributes.year || defaultYear)}
                            width="auto"
                            wrapperStyles={{ flexBasis: "5.625rem" }}
                            error={this.isFieldInvalid(
                                "origination.first_release_year",
                            )}
                            handleChange={(value) =>
                                this.setFormData(
                                    "origination.first_release_year",
                                    value,
                                )
                            }
                        />
                        <SelectWithTag
                            name="locale"
                            id="locale"
                            value={formData.origination.locale}
                            clear={true}
                            label={copy.locale || "Primary Language"}
                            disabledText={
                                copy.selectLocale || "Select Primary Language"
                            }
                            noneSelectedText={
                                copy.noneSelected || "None Selected"
                            }
                            readOnly={formDisabled}
                            disabled={formDisabled}
                            data={options.spoken_languages}
                            width="auto"
                            wrapperStyles={{ flexGrow: 1 }}
                            error={this.isFieldInvalid("origination.locale")}
                            handleChange={(value) =>
                                this.setFormData("origination.locale", value)
                            }
                        />
                    </div>
                    <div>
                        <RecordTimeInput
                            copy={copy}
                            id="release_duration"
                            name="release_duration"
                            value={formData.origination.release_duration}
                            disabled_id="disabled_release_duration"
                            label={
                                copy.approximateDurationRelease ||
                                (data &&
                                    data.category ===
                                        CategoryTags.Feature.short)
                                    ? "Approximate release duration"
                                    : "Approx. Duration of Episodes (h:m:s)"
                            }
                            disabled={formDisabled}
                            error={this.isFieldInvalid(
                                "origination.release_duration",
                            )}
                            handleFormChange={(value) =>
                                this.setFormData(
                                    "origination.release_duration",
                                    value,
                                )
                            }
                        />
                    </div>
                </div>
            </div>
        );
    }
}
