import React from "react";

class LoadScript extends React.Component {
    componentDidMount() {
        let element = document.getElementById("loadScript");
        let timeOut = this.props.timeOut || 2000;

        if (element) {
            let src = element.getAttribute("data-src");

            if (src) {
                setTimeout(() => {
                    let script = document.createElement("script");
                    script.setAttribute("src", src);
                    document.body.appendChild(script);
                }, timeOut);
            }
        }
    }

    render() {
        return false;
    }
}

export default LoadScript;
