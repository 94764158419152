import _ from "lodash";

const HandleCollectionTitleCategoryChange = (props) => {
    const { state, category } = props;

    const { set } = state;

    if (category) {
        set({
            collectionTitle: {
                category,
            },
        });
    }
};

export default HandleCollectionTitleCategoryChange;
