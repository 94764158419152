import React, { Component } from "react";
import deepcopy from "deepcopy";
import "./styles/_styles.scss";
import Input from "../Input/basic";
import Select from "../Select";
import CheckboxTag from "../CheckboxTag";

export default class LocalisationOrderInfo extends Component {
    constructor(props) {
        super(props);

        const { initFormData = null, clientFeatures = {} } = props;

        const { localisations_order: localisationOrderFeatures = {} } =
            clientFeatures;

        const {
            content_type: contentTypeFeature = {
                disabled: false,
            },
            source_version: sourceVersionFeature = {
                disabled: false,
            },
        } = localisationOrderFeatures;

        const defaultFormData = {
            localisation_request_title: null,
            po_number: null,
            distributor: null,
            content_type: null,
            subtype: null,
            regions: [],
            casting: null,
            scripts: null,
            screeners: null,
            type: [],
        };

        let formDataTransformed = deepcopy(initFormData);
        if (typeof initFormData == "object" && initFormData) {
            formDataTransformed = {
                localisation_request_title:
                    initFormData.localisation_request_title,
                po_number: initFormData.po_number,
                distributor: initFormData.distributor,
                content_type: initFormData.content_type,
                subtype: initFormData.subtype,
                regions: initFormData.regions || [],
                casting: initFormData.casting,
                scripts: initFormData.scripts,
                screeners: initFormData.screeners,
                type: initFormData.type || [],
                languages: initFormData.languages || [],
                localisation_id: initFormData.localisation_id,
                localisation_requester: initFormData.localisation_requester,
                status: initFormData.status,
            };
        }

        const formData = formDataTransformed
            ? deepcopy(formDataTransformed)
            : defaultFormData;

        if (contentTypeFeature.disabled) {
            formData.content_type = "-";
        }

        if (sourceVersionFeature.disabled) {
            formData.subtype = "-";
        }

        this.state = {
            formData,
            formValid: this.isFormDataValid(formData),
        };

        if (typeof this.props.onFormValidChange == "function") {
            this.props.onFormValidChange(this.state.formValid);
        }
    }

    isFormDataValid(data) {
        const { update, clientFeatures = {} } = this.props;

        const { localisations_order: localisationOrderFeatures = {} } =
            clientFeatures;

        const {
            content_type: contentTypeFeature = {
                disabled: false,
            },
            source_version: sourceVersionFeature = {
                disabled: false,
            },
        } = localisationOrderFeatures;

        let validationData = {
            localisation_request_title: data.localisation_request_title,
            po_number: data.po_number,
            content_type: data.content_type,
            subtype: data.subtype,
            regions: data.regions,
            casting: data.casting,
            scripts: data.scripts,
            screeners: data.screeners,
            type: data.type || [],
        };

        let validationKeys = ["regions", "type"];

        if (update) {
            validationKeys = ["regions"];

            validationData = {
                localisation_request_title: data.localisation_request_title,
                po_number: data.po_number,
                content_type: data.content_type,
                subtype: data.subtype,
                regions: data.regions,
                casting: data.casting,
            };
        }

        if (contentTypeFeature.disabled) {
            delete validationData["content_type"];
        }

        if (sourceVersionFeature.disabled) {
            delete validationData["subtype"];
        }

        return Object.keys(validationData).every((key) => {
            const value = validationData[key];

            if (validationKeys.includes(key)) {
                return Array.isArray(value) && value.length > 0;
            }

            return !!value;
        });
    }

    updateFormData(key, value) {
        this.setState(
            (state) => ({
                formData: {
                    ...state.formData,
                    [key]: value,
                },
            }),
            () => {
                const { formData, formValid } = this.state;
                const currentValid = this.isFormDataValid(formData);

                if (currentValid == formValid) {
                    return;
                }

                this.setState({ formValid: currentValid });
                if (typeof this.props.onFormValidChange == "function") {
                    this.props.onFormValidChange(currentValid);
                }
            },
        );
    }

    updateRegions(value) {
        const setValue = value ? [value] : [];
        this.updateFormData("regions", setValue);
    }

    updateType(value) {
        const type = Array.of(...this.state.formData.type);
        const index = type.indexOf(value);
        if (index >= 0) {
            type.splice(index, 1);
        } else {
            type.push(value);
        }
        this.updateFormData("type", type);
    }

    submit() {
        const { formData, formValid } = this.state;
        if (!formValid) {
            return;
        }

        const { onSubmit } = this.props;
        if (typeof onSubmit == "function") {
            onSubmit(formData);
        }
    }

    render() {
        const { state = {}, update, clientFeatures = {} } = this.props;
        const { localisations_order: localisationOrderFeatures = {} } =
            clientFeatures;
        const {
            content_type: contentTypeFeature = {
                disabled: false,
            },
            source_version: sourceVersionFeature = {
                disabled: false,
            },
        } = localisationOrderFeatures;
        const { formData } = this.state;

        const {
            options: {
                localisations: {
                    types = [],
                    scripts = [],
                    screeners = [],
                    casting = [],
                    content_types = [],
                    content_subtypes = [],
                    content_regions = [],
                },
            },
        } = state;

        return (
            <div className="meta-localisation-order-info">
                <div className="header">
                    <h3 className="title">Localisation Order Information</h3>
                </div>
                <div className="body">
                    <div className="row">
                        <Input
                            label="Title"
                            placeholder="Enter Title"
                            id="localisation-title"
                            name="localisation-title"
                            value={formData.localisation_request_title}
                            recordBasic={true}
                            handleChange={(value) =>
                                this.updateFormData(
                                    "localisation_request_title",
                                    value,
                                )
                            }
                        />
                    </div>
                    <div className="row select">
                        <Input
                            label="PO Number"
                            placeholder="Enter PO Number"
                            id="localisation-po-number"
                            name="localisation-po-number"
                            value={formData.po_number}
                            recordBasic={true}
                            width="50%"
                            margin="0 0.5rem 0 0"
                            alphaNumeric="[a-zA-Z0-9]"
                            handleChange={(value) =>
                                this.updateFormData("po_number", value)
                            }
                        />

                        <Input
                            label="Distributor"
                            placeholder="Enter Distributor"
                            id="localisation-distributor"
                            name="localisation-distributor"
                            value={formData.distributor}
                            recordBasic={true}
                            width="50%"
                            margin="0 0 0 0.5rem"
                            handleChange={(value) =>
                                this.updateFormData("distributor", value)
                            }
                        />
                    </div>
                    <div className="row select">
                        {!contentTypeFeature.disabled && (
                            <Select
                                name="localisation-content-type"
                                id="localisation-content-type"
                                value={formData.content_type}
                                clear={true}
                                label="Content Type"
                                disabledText="Select Content Type"
                                noneSelectedText="None Selected"
                                data={content_types}
                                width="auto"
                                handleChange={(value) =>
                                    this.updateFormData("content_type", value)
                                }
                                showNullOption={false}
                            />
                        )}
                        {!sourceVersionFeature.disabled && (
                            <Select
                                name="localisation-content-subtype"
                                id="localisation-content-subtype"
                                value={formData.subtype}
                                clear={true}
                                label={`${
                                    process.env.APP_ENV === "Live"
                                        ? "Shared / Local"
                                        : "Source Version"
                                }`}
                                disabledText={`Select ${
                                    process.env.APP_ENV === "Live"
                                        ? "Shared / Local"
                                        : "Source Version"
                                }`}
                                noneSelectedText="None Selected"
                                data={content_subtypes}
                                width="auto"
                                handleChange={(value) =>
                                    this.updateFormData("subtype", value)
                                }
                                showNullOption={false}
                            />
                        )}
                    </div>

                    <div className="row select">
                        <Select
                            name="localisation-content-region"
                            id="localisation-content-region"
                            value={
                                formData.regions.length > 0
                                    ? formData.regions[0]
                                    : null
                            }
                            clear={true}
                            label="Content Region"
                            disabledText="Select Content Region"
                            noneSelectedText="None Selected"
                            data={content_regions}
                            width="auto"
                            handleChange={this.updateRegions.bind(this)}
                            showNullOption={false}
                        />
                        <Select
                            name="localisation-casting"
                            id="localisation-casting"
                            value={formData.casting}
                            clear={true}
                            label="Casting"
                            disabledText="Select Casting"
                            noneSelectedText="None Selected"
                            data={casting}
                            width="auto"
                            handleChange={(value) =>
                                this.updateFormData("casting", value)
                            }
                            showNullOption={false}
                        />
                    </div>

                    {!update && (
                        <React.Fragment>
                            <div className="row select">
                                <Select
                                    name="localisation-scripts"
                                    id="localisation-scripts"
                                    value={formData.scripts}
                                    clear={true}
                                    label="Scripts"
                                    disabledText="Select Scripts"
                                    noneSelectedText="None Selected"
                                    data={scripts}
                                    width="auto"
                                    handleChange={(value) =>
                                        this.updateFormData("scripts", value)
                                    }
                                    showNullOption={false}
                                />
                                <Select
                                    name="localisation-screeners"
                                    id="localisation-screeners"
                                    value={formData.screeners}
                                    clear={true}
                                    label="Screeners"
                                    disabledText="Select Screeners"
                                    noneSelectedText="None Selected"
                                    data={screeners}
                                    width="auto"
                                    handleChange={(value) =>
                                        this.updateFormData("screeners", value)
                                    }
                                    showNullOption={false}
                                />
                            </div>

                            <div className="row types">
                                <label>Localisation Order Types</label>
                                <div className="options">
                                    {types.map((type) => (
                                        <CheckboxTag
                                            key={`localisations-order-type-${type.value}`}
                                            className={`localisations-order-type-${type.value}`}
                                            selected={formData.type.includes(
                                                type.value,
                                            )}
                                            leftText={type.text}
                                            background="#28a38d"
                                            styles={{
                                                justifyContent: "space-between",
                                            }}
                                            handleSelection={() =>
                                                this.updateType(type.value)
                                            }
                                        />
                                    ))}
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    }
}
