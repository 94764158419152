import React from "react";
import _ from "lodash";
import styles from "../styles";
import RecordTimeInput from "../../Boilerplates/RecordTimeInput";
import Select from "../../Select";
import ClientManagedSelectWithTag from "../../SelectWithTag/ClientManaged";
import BubbleList from "../../BubbleList";
import MaterialDatePicker from "../../Input/MaterialDatePicker/RecordViewController";
import ClientManagedSimpleOnEnterBubbleList from "../../BubbleList/ClientManagedSimpleOnEnter";
import getYears from "../../Options/years";
import isIndexInArray from "../../Helpers/isIndexInArray";
import update from "react-addons-update";
import IndexComponent from "./Index";
import deepcopy from "deepcopy";
import { diff } from "deep-object-diff";
import FilterRecordCategoryTypesList from "../src/FilterRecordCategoryTypesList";
import SendRequest from "../../SendRequest";
import AuditTooltip from "../../AuditTooltip";
import AutocompleteInput from "../../AutocompleteInput";
import ClientManagedSelect from "../../Select/ClientManaged";
import {
    LANGUAGE_KEY_PRIMARY_LANGUAGE,
    ADDITIONAL_SPOKEN_LANGUAGES,
    CLIENT_MANAGED_COUNTRIES,
    CLIENT_MANAGED_PRODUCTION_QUOTAS,
    CLIENT_MANAGED_PROVENANCES,
    CLIENT_MANAGED_RECORD_TYPES,
    CLIENT_MANAGED_RELEASE_TYPES,
} from "../../ClientManagedOptions/options";
import Switch from "../../Switch";
import { isClientManagedProvenance } from "../../ClientManagedOptions/provenance";
import { isClientManagedRecordTypes } from "../../ClientManagedOptions/record_types";
import { isClientManagedLanguages } from "../../ClientManagedOptions/languages";
import { isClientManagedCountries } from "../../ClientManagedOptions/countries";
import Input from "../../Input/basic";
import getFilteredCountriesFromAllSources from "../../Countries/src/getFilteredCountriesFromAllSources";
import MapEditableOptionBoolean from "../../RecordBuilder/map/doubleColumnContent/mapBooleanDisplayProperty";
import { isClientManagedField } from "../../ClientManagedOptions/isClientManagedField";
import { transformParentSeriesList } from "../../../models/Origination";
import BubbleListSimpleOnEnter from "../../BubbleList/simpleOnEnter";
import ClientManagedAutocomplete from "../../AutocompleteInput/ClientManaged";
import DynamicInputs from "../../Input/DynamicInputs";
import { getListOfBrandHubValues } from "../../../models/Record/origination";
import GlobalPageContext from "../../Global/store/context";
import OriginationViewType from '../../../features/Preferences/RecordBuilder/interfaces/OriginationView.type'
import originationMap from '../../RecordBuilder/map/dynamicInputs/origination'
import { RECORD_BUILDER } from '../../../components/Global/Options'
import { ORIGINATION } from "../../../models/RecordBuilder"
import RecordTypeSelect from "../../Record/RecordTypeSelect"
import DatePicker, { variantYearPicker } from "../../Input/MaterialDatePicker/RecordViewController"

class Origination extends IndexComponent {
    constructor(props, context) {
        super(props, context);

        const data = this.props.data;

        let sectionOptions = [];
        if (Array.isArray(this.props.sectionOptions)) {
            sectionOptions = this.props.sectionOptions;
        }

        /**
         * @var object
         */
        this.copy = this.props.copy || {};

        /**
         * @var object
         */
        this.styles = styles(this.props);

        /**
         * @var object
         */
        this.state = {
            data,
            clearValue: false,
            sectionOptions,
            dataList: {},
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    shouldComponentUpdate(nextProps, nextState) {
        const nextPropsData = nextProps.data || {};
        const thisPropsData = this.props.data || {};
        const difference = diff(nextPropsData, thisPropsData);

        if (!_.isEmpty(difference)) {
            return true;
        }

        if (!_.isEqual(nextProps.category, this.props.category)) {
            return true;
        }

        if (!_.isEqual(nextProps.disabled, this.props.disabled)) {
            return true;
        }

        if (!_.isEqual(nextProps.options, this.props.options)) {
            return true;
        }

        if (!_.isEqual(nextProps.sectionOptions, this.props.sectionOptions)) {
            return true;
        }

        if (!_.isEqual(nextProps.validationKeys, this.props.validationKeys)) {
            return true;
        }

        if (!_.isEqual(nextProps.audits, this.props.audits)) {
            return true;
        }

        if (!_.isEqual(nextState, this.state)) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps, prevState) {
        // sectionOptions
        if (!_.isEqual(this.props.sectionOptions, prevProps.sectionOptions)) {
            if (Array.isArray(this.props.sectionOptions)) {
                this.setStateValue("sectionOptions", this.props.sectionOptions);
            }
        }

        // data
        if (!_.isEqual(this.props.data, prevProps.data)) {
            this.setStateValue("data", this.props.data);
        }

        if (
            this.state.clearValue !== prevState.clearValue &&
            this.state.clearValue
        ) {
            this.setStateValue("clearValue", false);
        }
    }

    getValueFromDataStructure(key, field) {
        let result = false;

        const { dataStructure = [] } = this.props;

        let fieldStructure = dataStructure.find(
            (item) => item.key == field && item[key],
        );

        if (!_.isEmpty(fieldStructure)) {
            result = fieldStructure[key];
        }

        return result;
    }

    isDisabledForCategory(field) {
        const { dataStructure = [] } = this.props;

        let fieldStructure = dataStructure.find(
            (item) => item.key == field
        );

        if (fieldStructure && fieldStructure.disabledCategories) {
            return fieldStructure.disabledCategories.includes(this.props.category)
        }

        return false;
    }

    displaySectionFields(sectionOptions, item) {
        if (sectionOptions == false) {
            return true;
        }

        return sectionOptions.includes(item);
    }

    handleChange(name, value) {
        let stateData = deepcopy(this.state.data || {});
        let result = { ...stateData };

        if (typeof name == "string") {
            result[name] = value;

            if (typeof this.props.handleFormChange == "function") {
                this.props.handleFormChange(result);
            }

            const newData = update(this.state.data, { $set: result });
            this.setData(newData);
        }
    }

    renderNoneAdded() {
        return false;
    }

    shouldRenderRecordType(sectionOptions) {
        return this.displaySectionFields(sectionOptions, "RecordType");
    }

    shouldRenderBrandHub(sectionOptions) {
        return this.displaySectionFields(sectionOptions, "brandHub");
    }

    shouldRenderCountryOfOrigin(sectionOptions) {
        return this.displaySectionFields(sectionOptions, "CountryOfOrigin");
    }

    shouldRenderPrimaryLanguage(sectionOptions) {
        return this.displaySectionFields(sectionOptions, "PrimaryLanguage");
    }

    shouldRenderFirstReleaseYear(sectionOptions) {
        return this.displaySectionFields(sectionOptions, "FirstReleaseYear");
    }

    shouldRenderProductionYear(sectionOptions) {
        return this.displaySectionFields(sectionOptions, "ProductionYear");
    }

    shouldRenderReleaseType(sectionOptions) {
        return this.displaySectionFields(sectionOptions, "ReleaseType");
    }

    shouldRenderProductionStartYear(sectionOptions) {
        return this.displaySectionFields(sectionOptions, "ProductionStartYear");
    }

    shouldRenderLastReleaseYear(sectionOptions) {
        return this.displaySectionFields(sectionOptions, "LastReleaseYear");
    }

    shouldRenderReleaseDuration(sectionOptions) {
        return this.displaySectionFields(sectionOptions, "ReleaseDuration");
    }

    shouldRenderSequential(sectionOptions) {
        return this.displaySectionFields(sectionOptions, "Sequential");
    }

    shouldRenderBrand(sectionOptions) {
        return this.displaySectionFields(sectionOptions, "Brand");
    }

    shouldRenderProductionCountry(sectionOptions) {
        return this.displaySectionFields(sectionOptions, "ProductionCountry");
    }

    shouldRenderOriginalAspectRatio(sectionOptions) {
        return this.displaySectionFields(sectionOptions, "OriginalAspectRatio");
    }

    shouldRenderAdditionalLanguages(sectionOptions) {
        return this.displaySectionFields(sectionOptions, "AdditionalLanguages");
    }

    shouldRenderAdditionalCountriesOfOrigin(sectionOptions) {
        return this.displaySectionFields(
            sectionOptions,
            "AdditionalCountriesOfOrigin",
        );
    }

    shouldRenderProducers(sectionOptions) {
        return this.displaySectionFields(sectionOptions, "Producers");
    }

    shouldRenderDistributor(sectionOptions) {
        return this.displaySectionFields(sectionOptions, "Distributor");
    }

    shouldRenderFirstReleaseDate(sectionOptions) {
        return this.displaySectionFields(sectionOptions, "FirstReleaseDate");
    }

    shouldRenderProductionQuotas(sectionOptions) {
        return this.displaySectionFields(sectionOptions, "ProductionQuotas");
    }

    shouldRenderProvenance(sectionOptions) {
        return this.displaySectionFields(sectionOptions, "Provenance");
    }

    shouldRenderParentSeries(sectionOptions) {
        return this.displaySectionFields(sectionOptions, "ParentSeries");
    }

    fetchList(props) {
        const { apiUrls = {} } = this.props;

        const { recordService = "" } = apiUrls;

        const {
            apiUrl = "",
            url = "",
            field = "",
            searchTerm = "",
            category = "",
            onResult = () => {},
        } = props;

        if (field || apiUrl || url) {
            try {
                let requestUrl = url
                    ? url
                    : `${recordService}${apiUrl}?s=${searchTerm}`;

                if (category) {
                    requestUrl += `&category=${category}`;
                }

                const options = {
                    url: requestUrl,
                    method: "GET",
                };

                const handleError = () => {
                    console.log("searchTerm");
                };

                SendRequest(
                    options,
                    (data) => {
                        if (data.status == 200) {
                            const dataState = data.data || [];

                            let dataList = this.state.dataList;

                            dataList[field] = onResult(dataState);

                            this.setState({ dataList: dataList }, () => {
                                this.forceUpdate();
                            });
                        } else {
                            handleError();
                        }
                    },
                    (error) => {
                        handleError();
                    },
                );
            } catch (e) {
                handleError();
            }
        }
    }

    renderThreeWayOptions() {
        const { sectionOptions } = this.state;
        let data = deepcopy(this.state.data);

        const {
            copy = {},
            options = {},
            validationKeys = [],
            disabled,
            clientFeatures = {},
            clientDataStructure = {},
            audits = [],
            category,
        } = this.props;

        const {
            record_types = [],
            countries = [],
            sequential = [],
            brands = [],
            spoken_languages = [],
        } = options;

        const { date_format = "", origination_dynamic_inputs = {} } =
            clientDataStructure;

        const { three_way_options } = origination_dynamic_inputs;

        const years = getYears(this.props.year);

        const getTypes = () => {
            let result = [];

            record_types.map((item) => {
                if (item.category == category) {
                    result.push({
                        value: item.value,
                        text: item.type,
                    });
                }
            });

            result = FilterRecordCategoryTypesList({
                list: result,
                category: category,
            });

            return result;
        };

        const booleanOptionsList = (trueLabel = "Yes", falseLabel = "No") => {
            return (
                MapEditableOptionBoolean({ booleanDisplay: { trueLabel, falseLabel } }).list || []
            );
        };

        const commonSelectProps = {
            clear: true,
            noneSelectedText: copy.noneSelected || "None Selected",
            readOnly: disabled,
            disabled: disabled,
            data: booleanOptionsList(),
            wrapperStyles: this.styles.flexMarginItem,
        };

        const commonClientManagedSelectProps = {
            ...commonSelectProps,
            data: null,
            clientFeatures: clientFeatures,
            apiUrls: this.props.apiUrls,
            transformListHook: (list) => list.map((item) => item.name),
        };

        let firstReleaseYearDates = years;
        let lastReleaseYearDates = years;

        if (data.last_release_year) {
            firstReleaseYearDates = years.filter(
                (item) => item.value < parseInt(data.last_release_year),
            );
        }

        if (data.first_release_year) {
            lastReleaseYearDates = years.filter(
                (item) => item.value > parseInt(data.first_release_year),
            );
        }

        return (
            <div style={this.styles.flexMarginContainer}>
                {this.shouldRenderRecordType(sectionOptions) && (
                    <RecordTypeSelect 
                        name="type"
                        id="type"
                        fallBackData={getTypes()}
                        category={category}
                        value={data.type}
                        disabled={this.isDisabledForCategory('record_type') || disabled}
                        wrapperStyles={this.styles.flexMarginItem}
                        handleChange={(item) => this.handleChange("type", item)}
                        label={
                            copy.recordType ||
                            this.getValueFromDataStructure(
                                "title",
                                "record_type",
                            ) ||
                            "Record Type"
                        }
                        error={isIndexInArray(
                            validationKeys,
                            "type",
                        )}
                    />
                )}

                {this.shouldRenderCountryOfOrigin(sectionOptions) && (
                    <AuditTooltip
                        fieldName="country_iso"
                        dataSection={"records"}
                        wrapperStyles={this.styles.flexMarginItem}
                    >
                        <ClientManagedSelectWithTag
                            name="country_iso"
                            id="country_iso"
                            value={data.country_iso}
                            clear={true}
                            label={
                                copy.countryOfOrigin ||
                                this.getValueFromDataStructure(
                                    "title",
                                    "country_iso",
                                ) ||
                                "Country of Origin"
                            }
                            disabledText={
                                copy.selectCountryOfOrigin ||
                                "Select Country of Origin"
                            }
                            noneSelectedText={
                                copy.noneSelected || "None Selected"
                            }
                            readOnly={disabled}
                            disabled={disabled}
                            data={countries}
                            error={isIndexInArray(
                                validationKeys,
                                "country_iso",
                            )}
                            handleChange={(item) =>
                                this.handleChange("country_iso", item)
                            }
                            clientManagedType={CLIENT_MANAGED_COUNTRIES}
                            clientFeatures={clientFeatures}
                            apiUrls={this.props.apiUrls}
                            isClientManaged={isClientManagedCountries({
                                clientFeatures,
                            })}
                            transformListHook={(list) => _.sortBy(list, "order")}
                        />
                    </AuditTooltip>
                )}

                {this.shouldRenderPrimaryLanguage(sectionOptions) && (
                    <AuditTooltip
                        fieldName="locale"
                        dataSection={"records"}
                        wrapperStyles={this.styles.flexMarginItem}
                    >
                        <ClientManagedSelectWithTag
                            name="locale"
                            id="locale"
                            value={data.locale}
                            clear={true}
                            label={
                                copy.primaryLanguage ||
                                this.getValueFromDataStructure(
                                    "title",
                                    "locale",
                                ) ||
                                "Primary Language"
                            }
                            noneSelectedText={
                                copy.noneSelected || "None Selected"
                            }
                            disabledText={
                                copy.selectPrimaryLanguage ||
                                "Select Primary Language"
                            }
                            readOnly={disabled}
                            disabled={disabled}
                            data={spoken_languages}
                            handleChange={(item) =>
                                this.handleChange("locale", item)
                            }
                            clientManagedType={LANGUAGE_KEY_PRIMARY_LANGUAGE}
                            clientFeatures={clientFeatures}
                            apiUrls={this.props.apiUrls}
                            isClientManaged={isClientManagedLanguages({
                                clientFeatures,
                                languageKey: LANGUAGE_KEY_PRIMARY_LANGUAGE,
                            })}
                            error={isIndexInArray(validationKeys, "locale")}
                        />
                    </AuditTooltip>
                )}

                {this.shouldRenderFirstReleaseYear(sectionOptions) && (
                    <AuditTooltip
                        fieldName="first_release_year"
                        dataSection={"records"}
                        wrapperStyles={this.styles.flexMarginItem}
                    >

                    {!clientFeatures.materialYearPicker &&
                        <Select
                            name="first_release_year"
                            id="first_release_year"
                            value={data.first_release_year}
                            clear={true}
                            label={
                                copy.releaseYear ||
                                this.getValueFromDataStructure(
                                    "title",
                                    "release_year",
                                ) ||
                                "Release Year"
                            }
                            disabledText={
                                copy.selectReleaseYear || "Select Release Year"
                            }
                            noneSelectedText={
                                copy.noneSelected || "None Selected"
                            }
                            readOnly={
                                disabled ||
                                this.getValueFromDataStructure(
                                    "readOnly",
                                    "release_year",
                                )
                            }
                            disabled={
                                disabled ||
                                this.getValueFromDataStructure(
                                    "readOnly",
                                    "release_year",
                                )
                            }
                            data={firstReleaseYearDates}
                            error={isIndexInArray(
                                validationKeys,
                                "first_release_year",
                            )}
                            handleChange={(item) =>
                                this.handleChange("first_release_year", item)
                            }
                        />
                    }

                    {clientFeatures.materialYearPicker &&
                        <DatePicker 
                            variant={variantYearPicker}
                            name="first_release_year"
                            id="first_release_year"
                            className="datepicker-full-width"
                            defaultValue={data.first_release_year}
                            labelLegacy={
                                copy.releaseYear ||
                                this.getValueFromDataStructure(
                                    "title",
                                    "release_year",
                                ) ||
                                "Release Year"
                            }
                            disabled={
                                disabled ||
                                this.getValueFromDataStructure(
                                    "readOnly",
                                    "release_year",
                                )
                            }
                            error={isIndexInArray(
                                validationKeys,
                                "first_release_year",
                            )}
                            handleChange={(item) =>
                                this.handleChange("first_release_year", parseInt(item))
                            }
                            styles={{
                                width: "100%"
                            }}
                        />
                    }
  
                    </AuditTooltip>
                )}

                {this.shouldRenderProductionYear(sectionOptions) && (
                    <AuditTooltip
                        clientFeatures={clientFeatures}
                        fieldName="production_year"
                        audits={audits}
                        wrapperStyles={this.styles.flexMarginItem}
                    >
                        {!clientFeatures.materialYearPicker &&
                            <Select
                                name="production_year"
                                id="production_year"
                                value={data.production_year}
                                clear={true}
                                label={
                                    copy.releaseYear ||
                                    this.getValueFromDataStructure(
                                        "title",
                                        "production_year",
                                    ) ||
                                    "Production Year"
                                }
                                disabledText={
                                    copy.selectReleaseYear ||
                                    "Select Production Year"
                                }
                                noneSelectedText={
                                    copy.noneSelected || "None Selected"
                                }
                                readOnly={
                                    this.isDisabledForCategory('production_year') ||
                                    disabled ||
                                    this.getValueFromDataStructure(
                                        "readOnly",
                                        "production_year",
                                    )
                                }
                                disabled={
                                    this.isDisabledForCategory('production_year') ||
                                    disabled ||
                                    this.getValueFromDataStructure(
                                        "readOnly",
                                        "production_year",
                                    )
                                }
                                data={firstReleaseYearDates}
                                error={isIndexInArray(
                                    validationKeys,
                                    "production_year",
                                )}
                                handleChange={(item) =>
                                    this.handleChange("production_year", item)
                                }
                            />
                        }

                        {clientFeatures.materialYearPicker &&
                            <DatePicker 
                                variant={variantYearPicker}
                                name="production_year"
                                id="production_year"
                                className="datepicker-full-width"
                                defaultValue={data.production_year}
                                labelLegacy={
                                    copy.releaseYear ||
                                    this.getValueFromDataStructure(
                                        "title",
                                        "production_year",
                                    ) ||
                                    "Production Year"
                                }
                                disabled={
                                    disabled ||
                                    this.getValueFromDataStructure(
                                        "readOnly",
                                        "production_year",
                                    )
                                }
                                error={isIndexInArray(
                                    validationKeys,
                                    "production_year",
                                )}
                                handleChange={(item) =>
                                    this.handleChange("production_year", parseInt(item))
                                }
                                styles={{
                                    width: "100%"
                                }}
                            />
                        }
                    </AuditTooltip>
                )}

                {this.shouldRenderLastReleaseYear(sectionOptions) && (
                    <AuditTooltip
                        clientFeatures={clientFeatures}
                        fieldName="last_release_year"
                        dataSection={"records"}
                        audits={audits}
                        wrapperStyles={this.styles.flexMarginItem}
                    >
                        <Select
                            name="last_release_year"
                            id="last_release_year"
                            value={data.last_release_year}
                            clear={true}
                            label={
                                this.getValueFromDataStructure(
                                    "title",
                                    "last_release_year",
                                ) || "Last Release Year"
                            }
                            disabledText={
                                copy.selectReleaseYear ||
                                "Select Last Release Year"
                            }
                            noneSelectedText={
                                copy.noneSelected || "None Selected"
                            }
                            readOnly={disabled}
                            disabled={disabled}
                            data={lastReleaseYearDates}
                            error={isIndexInArray(
                                validationKeys,
                                "last_release_year",
                            )}
                            handleChange={(item) =>
                                this.handleChange("last_release_year", item)
                            }
                        />
                    </AuditTooltip>
                )}

                {this.shouldRenderFirstReleaseDate(sectionOptions) && (
                    <div style={this.styles.flexMarginItem}>
                        <AuditTooltip
                            clientFeatures={clientFeatures}
                            fieldName="first_release_date"
                            audits={audits}
                            dataSection={"origination"}
                        >
                            <MaterialDatePicker
                                id="first_release_date"
                                labelLegacy={
                                    this.getValueFromDataStructure(
                                        "title",
                                        "first_release_date",
                                    ) || "First Release Date"
                                }
                                placeholder="Select a Date"
                                name="first_release_date"
                                className="datepicker-full-width"
                                defaultValue={data.first_release_date}
                                disabled={
                                    disabled ||
                                    this.getValueFromDataStructure(
                                        "readOnly",
                                        "first_release_date",
                                    )
                                }
                                handleChange={(date) =>
                                    this.handleChange(
                                        "first_release_date",
                                        date,
                                    )
                                }
                            />
                        </AuditTooltip>
                    </div>
                )}

                {this.shouldRenderReleaseDuration(sectionOptions) && (
                    <div style={this.styles.flexMarginItem}>
                        <AuditTooltip
                            fieldName="release_duration"
                            dataSection="records"
                        >
                            <RecordTimeInput
                                copy={copy}
                                name="release_duration"
                                disabled_id="disabled_release_duration"
                                label={
                                    copy.approximateDurationRelease ||
                                    this.getValueFromDataStructure(
                                        "title",
                                        "release_duration",
                                    ) ||
                                    "Approx. Release Duration (h:m:s)"
                                }
                                value={data.release_duration}
                                disabled={disabled}
                                error={isIndexInArray(
                                    validationKeys,
                                    "release_duration",
                                )}
                                handleFormChange={(item) =>
                                    this.handleChange("release_duration", item)
                                }
                            />
                        </AuditTooltip>
                    </div>
                )}

                {this.shouldRenderSequential(sectionOptions) && (
                    <Select
                        name="sequential"
                        id="sequential"
                        value={data.sequential}
                        clear={true}
                        label={
                            copy.sequential ||
                            this.getValueFromDataStructure(
                                "title",
                                "sequential",
                            ) ||
                            "Sequential/Non-Sequential"
                        }
                        disabledText={
                            copy.selectReleaseYear ||
                            "Select Sequential/Non-Sequential"
                        }
                        noneSelectedText={copy.noneSelected || "None Selected"}
                        readOnly={disabled}
                        disabled={disabled}
                        data={sequential}
                        wrapperStyles={this.styles.flexMarginItem}
                        error={isIndexInArray(validationKeys, "sequential")}
                        handleChange={(item) =>
                            this.handleChange("sequential", item)
                        }
                    />
                )}

                {this.shouldRenderBrand(sectionOptions) && (
                    <Select
                        name="brand"
                        id="brand"
                        value={data.brand}
                        clear={true}
                        label={
                            copy.brand ||
                            this.getValueFromDataStructure("title", "brand") ||
                            "Brand"
                        }
                        disabledText={copy.selectReleaseYear || "Select Brand"}
                        noneSelectedText={copy.noneSelected || "None Selected"}
                        readOnly={disabled}
                        disabled={disabled}
                        data={brands}
                        wrapperStyles={this.styles.flexMarginItem}
                        error={isIndexInArray(validationKeys, "brand")}
                        handleChange={(item) =>
                            this.handleChange("brand", item)
                        }
                    />
                )}

                {this.shouldRenderProductionStartYear(sectionOptions) && (
                    <AuditTooltip
                        clientFeatures={clientFeatures}
                        fieldName="production_start_year"
                        audits={audits}
                        wrapperStyles={this.styles.flexMarginItem}
                    >
                        {!clientFeatures.materialYearPicker &&
                            <Select
                                name="production_start_year"
                                id="production_start_year"
                                value={data.production_start_year}
                                clear={true}
                                label={"Production Start Year"}
                                disabledText={"Select Production Start Year"}
                                noneSelectedText={copy.noneSelected || "None Selected"}
                                readOnly={disabled}
                                disabled={disabled}
                                data={firstReleaseYearDates}
                                handleChange={(item) =>
                                    this.handleChange("production_start_year", item)
                                }
                                styles={{
                                    width: "100%"
                                }}
                            />
                        }

                        {clientFeatures.materialYearPicker &&
                            <DatePicker 
                                variant={variantYearPicker}
                                name="production_start_year"
                                id="production_start_year"
                                className="datepicker-full-width"
                                defaultValue={data.production_start_year}
                                labelLegacy={
                                    copy.releaseYear ||
                                    this.getValueFromDataStructure(
                                        "title",
                                        "production_start_year",
                                    ) ||
                                    "Production Start Year"
                                }
                                disabled={
                                    disabled ||
                                    this.getValueFromDataStructure(
                                        "readOnly",
                                        "production_start_year",
                                    )
                                }
                                error={isIndexInArray(
                                    validationKeys,
                                    "production_start_year",
                                )}
                                handleChange={(item) =>
                                    this.handleChange("production_start_year", parseInt(item))
                                }
                                styles={{
                                    width: "100%"
                                }}
                            />
                        }
                    </AuditTooltip>
                )}

                {this.shouldRenderProvenance(sectionOptions) && (
                    <ClientManagedSelect
                        name="provenance"
                        id="provenance"
                        value={
                            (typeof data.provenance === "string" &&
                                data.provenance) ||
                            ""
                        }
                        clear={true}
                        label={"Provenance"}
                        noneSelectedText={copy.noneSelected || "None Selected"}
                        disabledText={
                            copy.selectPrimaryLanguage || "Select Provenance"
                        }
                        readOnly={disabled}
                        disabled={disabled}
                        handleChange={(item) =>
                            this.handleChange("provenance", item)
                        }
                        clientManagedType={CLIENT_MANAGED_PROVENANCES}
                        clientFeatures={clientFeatures}
                        apiUrls={this.props.apiUrls}
                        wrapperStyles={this.styles.flexMarginItem}
                        isClientManaged={isClientManagedProvenance({
                            clientFeatures,
                        })}
                        error={isIndexInArray(validationKeys, "provenance")}
                    />
                )}

                {!_.isEmpty(three_way_options) && (
                    <DynamicInputs
                        options={three_way_options}
                        disabled={disabled}
                        handleChange={(key, value) =>
                            this.handleChange(key, value)
                        }
                        styles={this.styles.flexMarginItem}
                        data={data}
                        validationKeys={validationKeys}
                        category={category}
                    />
                )}
            </div>
        );
    }

    isDisabledInConfig(key) {
        const {
            clientDataStructure = {}
        } = this.props;

        const { 
            origination = [] 
        } = clientDataStructure;

        const item = origination?.find(it => it.key == key)

        return item?.disabled || false
    }

    renderFullWidthOptions() {
        const {
            options = {},
            disabled,
            copy = {},
            clientFeatures = {},
            clientDataStructure = {},
            audits = [],
            canPopulateReleaseType,
            apiUrls = {},
            validationKeys = [],
            category,
        } = this.props;

        const {
            producers = [],
            spoken_languages = [],
            production_quotas = [],
            release_types = [],
        } = options;

        const { 
            origination_dynamic_inputs = {}, 
            origination = {} 
        } = clientDataStructure;

        const { full_width_options } = origination_dynamic_inputs;

        const { sectionOptions } = this.state;

        let data = deepcopy(this.state.data);

        const { additional_countries_of_origin = [] } = data;

        const {
            fetchingClientManagedLanguages,
            fetchingClientManagedProductionQuotas,
            dataList = {},
        } = this.state;

        const { distributorsOptions = "" } = apiUrls;

        const filteredCountries = getFilteredCountriesFromAllSources({
            countries: additional_countries_of_origin || [],
            options,
        });

        return (
            <>
                {this.shouldRenderBrandHub(sectionOptions) && (
                    <div style={this.styles.marginItem}>
                        <BubbleListSimpleOnEnter
                            id="brand-hub"
                            label="BrandHub Values"
                            background="rgb(222, 222, 222)"
                            emptyPlaceholder="No BrandHub Values"
                            color="rgb(0, 0, 0)"
                            readOnly
                            data={
                                Array.isArray(data.brand_hub)
                                ? getListOfBrandHubValues(data)
                                : []
                            }
                        />
                    </div>
                )}

                {this.shouldRenderParentSeries(sectionOptions) &&
                    !data.is_parent && (
                        <div style={this.styles.marginItem}>
                            <BubbleListSimpleOnEnter
                                id="meta-parent-series"
                                label={"Parent Series"}
                                background="#DEDEDE"
                                color="#494949"
                                readOnly={disabled}
                                data={data.parent_series || []}
                                emptyPlaceholder="Start Typing Parent Series"
                                handleFormChange={(data) =>
                                    this.handleChange("parent_series", data)
                                }
                                datalistId="parent_series"
                                loading={fetchingClientManagedLanguages}
                                dataListUseEnterButton={true}
                                filterStaticDataList={true}
                                useEnterButtonOnSelect={true}
                                handleChange={(value) => {
                                    this.fetchList({
                                        apiUrl: "/search",
                                        searchTerm: value,
                                        category: "Show",
                                        field: "parent_series",
                                        onResult: transformParentSeriesList,
                                    });
                                }}
                                datalist={dataList.parent_series || []}
                                apiUrls={this.props.apiUrls}
                                fullyDelete={false}
                                titleKey="title"
                            />
                        </div>
                    )}

                {this.shouldRenderAdditionalLanguages(sectionOptions) && (
                    <div style={this.styles.marginItem}>
                        <AuditTooltip
                            clientFeatures={clientFeatures}
                            fieldName="additional_languages"
                            audits={audits}
                            dataSection={"origination"}
                        >
                            <ClientManagedSimpleOnEnterBubbleList
                                label={
                                    this.getValueFromDataStructure(
                                        "title",
                                        "additional_languages",
                                    ) || "Additional Spoken Languages"
                                }
                                background="#DEDEDE"
                                color="#494949"
                                readOnly={this.isDisabledForCategory('additional_languages') || disabled}
                                className="meta-additional-spoken-languages"
                                emptyPlaceholder="Start Typing Languages"
                                data={data.additional_languages || []}
                                handleFormChange={(data) =>
                                    this.handleChange(
                                        "additional_languages",
                                        data.map((item) => item.name || item),
                                    )
                                }
                                datalistId="additional_languages_list"
                                loading={fetchingClientManagedLanguages}
                                datalist={spoken_languages.map(
                                    (item) => item.text,
                                )}
                                dataListUseEnterButton={true}
                                filterStaticDataList={true}
                                useEnterButtonOnSelect={true}
                                clientManagedType={ADDITIONAL_SPOKEN_LANGUAGES}
                                clientFeatures={clientFeatures}
                                apiUrls={this.props.apiUrls}
                                transformListHook={(list) => list.map((item) => item.text)}
                            />
                        </AuditTooltip>
                    </div>
                )}

                {this.shouldRenderAdditionalCountriesOfOrigin(
                    sectionOptions,
                ) && (
                    <div style={this.styles.marginItem}>
                        <AuditTooltip
                            clientFeatures={clientFeatures}
                            fieldName="additional_countries_of_origin"
                            audits={audits}
                            dataSection={"origination"}
                        >
                            <ClientManagedSimpleOnEnterBubbleList
                                id="additional-countries-of-origin"
                                label={
                                    this.getValueFromDataStructure(
                                        "title",
                                        "additional_countries_of_origin",
                                    ) || "Additional Countries of Origin"
                                }
                                background="#DEDEDE"
                                color="#494949"
                                readOnly={disabled}
                                emptyPlaceholder="Start Typing Countries"
                                data={filteredCountries}
                                handleFormChange={(data) =>
                                    this.handleChange(
                                        "additional_countries_of_origin",
                                        data,
                                    )
                                }
                                datalistId="additional_countries_of_origin_list"
                                loading={fetchingClientManagedLanguages}
                                dataListUseEnterButton={true}
                                filterStaticDataList={true}
                                useEnterButtonOnSelect={true}
                                clientManagedType={CLIENT_MANAGED_COUNTRIES}
                                clientFeatures={clientFeatures}
                                apiUrls={this.props.apiUrls}
                                fullyDelete={false}
                                dataKey="datalist"
                            />
                        </AuditTooltip>
                    </div>
                )}

                {this.shouldRenderReleaseType(sectionOptions) && (
                    <div style={this.styles.marginItem}>
                        <ClientManagedAutocomplete
                            placeholder={
                                copy.startTypingStudiosProducers ||
                                "Start Typing Release Type"
                            }
                            noneEntered={true}
                            value={data.release_type}
                            apiUrls={this.props.apiUrls}
                            clientFeatures={clientFeatures}
                            clientManagedType={CLIENT_MANAGED_RELEASE_TYPES}
                            isClientManaged={isClientManagedField({
                                clientFeatures,
                                field: "release_types",
                            })}
                            data={release_types}
                            single={true}
                            recordBasic={true}
                            keepSelected={true}
                            bubbleOnSelect={true}
                            callBackOnDelete={(item) =>
                                this.handleChange("release_type", null)
                            }
                            handleListSelection={(item) =>
                                this.handleChange("release_type", item)
                            }
                            createNew={isClientManagedField({
                                clientFeatures,
                                field: "release_types",
                            }) ? false : canPopulateReleaseType}
                            displayNoneEntered={true}
                            name="release_type"
                            inputId="release_type"
                            id="meta-release_type"
                            label={
                                copy.studiosAndProductionCompanies ||
                                this.getValueFromDataStructure(
                                    "title",
                                    "release_type",
                                ) ||
                                "Release Type"
                            }
                            disabled={this.props.disabled}
                            datalistId="origination_release_types"
                            ranking={true}
                            handleNoMatchText={true}
                            noMatchText={
                                copy.noMatchText ||
                                "No match for {value}. Create New?"
                            }
                            clearValue={this.state.clearValue}
                            dataListUseEnterButton={true}
                            useEnterButton={true}
                            filterStaticDataList={true}
                            transformListHook={(list) => list.map((item) => item.text)}
                        />
                    </div>
                )}

                {this.shouldRenderProducers(sectionOptions) && (
                    <div style={this.styles.marginItem}>
                        <BubbleList
                            name="producers"
                            inputId="producers"
                            id="meta-producers"
                            label={
                                copy.studiosAndProductionCompanies ||
                                this.getValueFromDataStructure(
                                    "title",
                                    "producers",
                                ) ||
                                "Studios & Production Companies"
                            }
                            readOnly={this.isDisabledInConfig('producers') || this.props.disabled}
                            disabled={this.isDisabledInConfig('producers') || this.props.disabled}
                            emptyPlaceholder={
                                copy.startTypingStudiosProducers ||
                                "Start Typing Studios / Producers"
                            }
                            data={data.producers}
                            datalistId="origination_producers_list"
                            datalist={producers}
                            handleFormChange={(item) =>
                                this.handleChange("producers", item)
                            }
                            ranking={true}
                            handleNoMatchText={true}
                            noMatchText={
                                copy.noMatchText ||
                                "No match for {value}. Create New?"
                            }
                            clearValue={this.state.clearValue}
                            dataListUseEnterButton={true}
                            useEnterButton={true}
                            filterStaticDataList={true}
                        />
                    </div>
                )}

                {this.shouldRenderProductionQuotas(sectionOptions) && (
                    <div style={this.styles.marginItem}>
                        <ClientManagedSimpleOnEnterBubbleList
                            label={
                                this.getValueFromDataStructure(
                                    "title",
                                    "production_quotas",
                                ) || "Production Quotas"
                            }
                            background="rgb(222, 222, 222)"
                            color="rgb(0, 0, 0)"
                            readOnly={disabled}
                            emptyPlaceholder="Start Typing Production Quotas"
                            data={data.production_quotas || []}
                            handleFormChange={(data) =>
                                this.handleChange("production_quotas", data)
                            }
                            datalistId="production_quotas"
                            loading={fetchingClientManagedProductionQuotas}
                            filterStaticDataList={true}
                            clientManagedType={CLIENT_MANAGED_PRODUCTION_QUOTAS}
                            clientFeatures={clientFeatures}
                            apiUrls={this.props.apiUrls}
                            fullyDelete={false}
                        />
                    </div>
                )}

                {this.shouldRenderDistributor(sectionOptions) && (
                    <div style={this.styles.marginItem}>
                        <AuditTooltip
                            clientFeatures={clientFeatures}
                            fieldName="distributor"
                            audits={audits}
                        >
                            <BubbleList
                                name="distributor"
                                inputId="distributor"
                                id="meta-distributor"
                                label={
                                    copy.licensorDistributor ||
                                    this.getValueFromDataStructure(
                                        "title",
                                        "distributor",
                                    ) ||
                                    "Licensor/Distributor"
                                }
                                readOnly={
                                    this.props.disabled ||
                                    this.getValueFromDataStructure(
                                        "readOnly",
                                        "distributor",
                                    )
                                }
                                disabled={this.props.disabled}
                                emptyPlaceholder={
                                    copy.startTypingLicensorDistributor ||
                                    "Start Typing Licensor/Distributor"
                                }
                                data={data.distributor}
                                datalistId=""
                                datalist={dataList.distributor || []}
                                handleFormChange={(item) =>
                                    this.handleChange("distributor", item)
                                }
                                ranking={true}
                                handleNoMatchText={true}
                                noMatchText={
                                    copy.noMatchText ||
                                    "No match for {value}. Create New?"
                                }
                                clearValue={this.state.clearValue}
                                dataListUseEnterButton={true}
                                useEnterButton={true}
                                filterStaticDataList={true}
                                handleChange={(value) => {
                                    this.fetchList({
                                        url: distributorsOptions,
                                        searchTerm: value,
                                        field: "distributor",
                                    });
                                }}
                            />
                        </AuditTooltip>
                    </div>
                )}

                {this.shouldRenderProductionCountry(sectionOptions) && (
                    <div style={this.styles.marginItem}>
                        <ClientManagedSimpleOnEnterBubbleList
                            label={
                                this.getValueFromDataStructure(
                                    "title",
                                    "production_countries",
                                ) || "Production Country"
                            }
                            background="rgb(222, 222, 222)"
                            color="rgb(0, 0, 0)"
                            readOnly={disabled}
                            emptyPlaceholder="Start Typing Production Country"
                            data={data.production_countries || []}
                            handleFormChange={(data) =>
                                this.handleChange(
                                    "production_countries",
                                    data.map((item) => item.text || item),
                                )
                            }
                            datalistId="production_countries"
                            filterStaticDataList={true}
                            clientManagedType={CLIENT_MANAGED_COUNTRIES}
                            clientFeatures={clientFeatures}
                            apiUrls={this.props.apiUrls}
                            transformListHook={(list) => list.map((item) => item.text)}
                            id="production_country"
                        />
                    </div>
                )}

                {!_.isEmpty(full_width_options) && (
                    <DynamicInputs
                        options={full_width_options}
                        disabled={disabled}
                        handleChange={(key, value) =>
                            this.handleChange(key, value)
                        }
                        styles={this.styles.marginItem}
                        data={data}
                        validationKeys={validationKeys}
                        category={category}
                        apiUrls={this.props.apiUrls}
                    />
                )}

                {this.shouldRenderOriginalAspectRatio(sectionOptions) && (
                    <div style={this.styles.marginItem}>
                        <Input
                            label="Original Aspect Ratio"
                            placeholder={
                                disabled
                                    ? "None Entered"
                                    : "Enter Original Aspect Ratio"
                            }
                            id="original_aspect_ratio"
                            value={data.original_aspect_ratio}
                            recordBasic={true}
                            handleChange={(item) =>
                                this.handleChange("original_aspect_ratio", item)
                            }
                            disabled={disabled}
                        />
                    </div>
                )}
            </>
        );
    }

    renderContent() {
        return (
            <>
                {this.renderThreeWayOptions()}
                {this.renderFullWidthOptions()}
            </>
        );
    }
}

Origination.contextType = GlobalPageContext;

export default Origination;
