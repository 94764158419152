import React from "react";
import _ from "lodash";
import styles from "../styles";
import DynamicTable from "../../DynamicTable/channels";
import Index from "./Index";
import deepcopy from "deepcopy";
import { diff } from "deep-object-diff";

export default class Channels extends Index {
    constructor(props) {
        super(props);

        let data = [];

        if (Array.isArray(this.props.data)) {
            data = this.props.data;
        }

        /**
         * @var object
         */
        this.styles = styles(this.props);

        /**
         * @var object
         */
        this.state = {
            data,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        const nextPropsData = nextProps.data || {};
        const thisPropsData = this.props.data || {};
        const difference = diff(nextPropsData, thisPropsData);

        if (!_.isEmpty(difference)) {
            return true;
        }

        if (!_.isEqual(nextProps.disabled, this.props.disabled)) {
            return true;
        }

        if (!_.isEqual(nextProps.options, this.props.options)) {
            return true;
        }

        if (!_.isEqual(nextState, this.state)) {
            return true;
        }

        return false;
    }

    handleChange(value) {
        if (typeof this.props.handleFormChange == "function") {
            this.props.handleFormChange(value);
        }

        this.setData(value);
    }

    renderContent() {
        const data = deepcopy(this.state.data || []);
        const options = this.props.options || {};

        if (this.props.disabled && _.isEmpty(data)) {
            return false;
        }

        return (
            <div style={this.styles.marginItem}>
                <DynamicTable
                    options={options}
                    disabled={this.props.disabled}
                    data={data}
                    handleChange={(item) => this.handleChange(item)}
                />
            </div>
        );
    }
}
