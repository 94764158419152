import _ from "lodash";
import SendRequest from "../../../components/SendRequest";

const HandleUserGroupSearchRequest = (props) => {
    const { state = {}, searchQuery = "" } = props;

    const { set, cmsData = {} } = state;

    const { apiUrls = {} } = cmsData;

    const options = {
        url: apiUrls.userGroupSearch + "?s=" + searchQuery,
        method: "GET",
        basic: true,
        testingData: [],
    };

    const handleError = (error) => {
        set({
            fetchingSearchData: false,
            notification: {
                title: "Technical Error",
                description: "Record could not fetch user groups",
                status: "alert",
                onClick: () => {
                    set({
                        notification: {},
                    });
                },
            },
        });

        if (error) console.log(error);
    };

    set({
        fetchingSearchData: true,
        searchQuery,
    });

    SendRequest(options, (response) => {
        const { status, data, message } = response;

        if (status == 200) {
            set({
                fetchingSearchData: false,
                searchData: data,
            });

            return;
        }

        // On Error
        handleError(message);
    });
};

export default HandleUserGroupSearchRequest;
