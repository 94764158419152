import Categories from "../../CategoryTags/Categories";

const FetchCategoryList = (props) => {
    const {
        linkages = [],
        clientFeatures = {},
        clientDataStructure = {},
        sourceCategory = "",
        options = {},
    } = props;

    const { inheritance = [] } = clientDataStructure;

    const categoriesFullList = Categories({
        options,
        cmsData: { clientFeatures },
    });

    let categories = [];

    linkages.forEach((linkage) => {
        const categoryItem = categoriesFullList.find(
            (item) => item.value === linkage.category,
        );

        const categoryInList = categories.find(
            (item) => item.value === linkage.category,
        );

        const categoryInConfiguration = inheritance.find((item) => {
            const { targetCategory, inheritFromCategory } = item;

            return (
                sourceCategory === inheritFromCategory &&
                targetCategory === linkage.category
            );
        });

        if (categoryItem && !categoryInList && categoryInConfiguration)
            categories.push(categoryItem);
    });

    return categories;
};

export default FetchCategoryList;
