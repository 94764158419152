const titleValue = {
    title: {
        width: "100%",
        display: "block",
        fontSize: "0.78125rem",
        lineHeight: 1,
        color: "#6a6a6a",
        marginBottom: "0.5625rem",
    },
};

export default titleValue;
