import { Tabs, styled } from "@mui/material";

const StyledTabs = styled(Tabs)(({ theme }) => ({
    "& .MuiTabs-scrollButtons": {
        marginBottom: "0.375rem",
        position: "relative",
        width: "auto",
        zIndex: 1,
        opacity: 1,
        transition: "opacity 0.3s ease",
        borderRadius: "0.25rem",
        "&:before": {
            content: "''",
            width: "2.5rem",
            height: "100%",
            display: "block",
            right: "0",
            position: "absolute",
            opacity: "1",
            transition: "opacity 0.3s ease",
            background: "linear-gradient(to right, rgba(255, 255, 255, 0.38) 0%, rgba(255, 255, 255, 0.41) 1%, white 50%)",
            filter: 'progid:DXImageTransform.Microsoft.gradient( startColorstr=\'#61ffffff\', endColorstr=\'#ffffff\',GradientType=1 )',
        },
        "&:first-child": {
            "&:before": {
                left: 0,
                right: 'auto',
                transform: 'rotate(180deg)',
            }
        },
        "& .MuiSvgIcon-root": {
            color: 'rgba(77,77,77, 0.7)',
            zIndex: 10,
        },
        "&.Mui-disabled": {
            opacity: '0.3'
        }
    },

    // "& .MuiTabs-root": {
    //     "&:before": {
    //         color: "tomato"
    // },

    // "& div.MuiTabs-root > div:nth-child(1) > svg": {
    "& .MuiButtonBase-root > .MuiSvgIcon-root": {
        fontSize: "1.5rem",
        // color: "rgba(77, 77, 77, 0.7)",
        // color: "tomato",
    },

    minHeight: "unset",
    position: "relative",
    color: "black",
    "&:before": {
        borderBottom: "0.125rem solid #F7F6F6",
        content: "''",
        width: "100%",
        height: "0.125rem",
        display: "block",
        position: "absolute",
        bottom: "0",
    },
}));

export default StyledTabs;
