import React, { Fragment, useState, useMemo } from "react";
import _ from "lodash";
import BubbleList from "../../BubbleList/simpleOnEnter";
import BubbleListClientManagedSimpleOnEnter from "../../BubbleList/ClientManagedSimpleOnEnter";
import AutocompleteInput from "../../AutocompleteInput";
import ClientManagedAutocomplete from "../../AutocompleteInput/ClientManaged";
import RecordDateInput from "../../RecordDateInput";
import Settings from "../../Settings";
import RightStatus from "./Status";
import Select from "../../Select";
import ClientManagedSelect from "../../Select/ClientManaged";
import Input from "../../Input/basic";
import Clients from "../../Clients";
import RightsLicensorsOptions from "../../ClientManagedOptions/rights_licensors";
import ExpandableRow from "../../ExpandableRow";
import { ExpandableRowHeader } from "./ExpandableRowHeader";
import { ExpandableRowBody } from "./ExpandableRowBody";
import {
    CLIENT_MANAGED_RIGHTS_LICENSORS,
    CLIENT_MANAGED_RIGHTS_VERSIONS,
    CLIENT_MANAGED_RIGHTS_PLATFORMS,
    CLIENT_MANAGED_COUNTRIES,
    SUBTITLES,
    DUBBING_TRACKS,
} from "../../ClientManagedOptions/options";
import isClientManaged from "../../ClientManagedOptions/isClientManaged";
import { isClientManagedRightsPlatforms } from "../../ClientManagedOptions/rights_platforms_all";
import { isClientManagedRightsLicensors } from "../../ClientManagedOptions/rights_licensors";
import ClientManagedCountries, {
    isClientManagedCountries,
} from "../../ClientManagedOptions/countries";
import { isClientManagedLanguages } from "../../ClientManagedOptions/languages";
import ObjectArrayToKeyArrayTransformer from "../../Transformers/ObjectArrayToKeyArrayTransformer";
import {
    StringPriorityTransformer,
    PRIORITY_KEYS_NAME_OR_TEXT,
} from "../../Transformers/StringPriorityTransformer";
import { isClientManagedRightsVersions } from "../../ClientManagedOptions/rights_versions";

const RenderItem = (props) => {
    const {
        data = {},
        disabled,
        edit,
        editDisabled,
        isValidated,
        options = {},
        rightsSubsDubs,
        licensorText,
        multipleVersions,
        dataStructure = {},
        righsV3DataStructure = {},
        handleChange = () => {},
        handleStateSave = () => {},
        handleEdit = () => {},
        handleDelete = () => {},
        clientFeatures = {},
        written_languages = [],
        cmsData = {},
    } = props;

    const {
        spoken_languages = [],
        rights_platforms = [],
        rights = {},
        countries = [],
    } = options;

    const { versions = [] } = rights;

    const { apiUrls = {} } = cmsData;

    const { platforms } = data;

    const marginTop = "1.6875rem";

    const { rightsV3 = false } = clientFeatures;

    const isClientManagedPlatforms = useMemo(() =>
        isClientManagedRightsPlatforms({ clientFeatures }),
    );
    const isClientManagedLicensors = useMemo(() =>
        isClientManagedRightsLicensors({ clientFeatures }),
    );
    const isClientManagedCountriesList = useMemo(() =>
        isClientManagedCountries({ clientFeatures }),
    );
    const isClientManagedWrittenLanguages = useMemo(() =>
        isClientManagedLanguages({ clientFeatures, languageKey: SUBTITLES }),
    );
    const isClientManageSpokenLanguages = useMemo(() =>
        isClientManagedLanguages({
            clientFeatures,
            languageKey: DUBBING_TRACKS,
        }),
    );
    const isClientManagedRightVersions = useMemo(() =>
        isClientManagedRightsVersions({ clientFeatures }),
    );

    const customLists = {
        versions,
        countries,
    };

    const RenderInput = (props) => {
        return (
            <Input
                label={props.label}
                placeholder={props.placeholder}
                value={props.value}
                recordBasic={true}
                handleChange={(value) => handleChange(props.stateKey, value)}
                noneEntered={true}
                disabled={!edit}
                styles={{
                    margin: "0.28125rem 0 0 0",
                }}
            />
        );
    };

    const RenderSingleInput = (props) => {
        const inputChange = (value) => {
            handleChange(
                props.stateKey,
                StringPriorityTransformer({
                    item: value,
                    priority: PRIORITY_KEYS_NAME_OR_TEXT,
                }),
            );
        };

        let Component = AutocompleteInput;
        if (props.isClientManaged && props.clientManagedType) {
            Component = ClientManagedAutocomplete;
        }

        return (
            <Component
                {...props}
                staticDatalist={props.datalist}
                noneEntered={true}
                single={true}
                recordBasic={true}
                keepSelected={true}
                bubbleOnSelect={true}
                readOnly={!edit}
                disabled={!edit}
                callBackOnDelete={() => handleChange(props.stateKey, "")}
                handleListSelection={(value) => inputChange(value)}
                filterStaticDataList={true}
                styles={props.styles || {}}
                keywordLabel={{
                    background: "#CFEEFF",
                    color: "#2F6E90",
                }}
                inputStyles={{
                    margin: "0.28125rem 0 0 0",
                }}
                apiUrls={apiUrls}
                searchOnClick={true}
                clientFeatures={clientFeatures}
            />
        );
    };

    const RenderBubbleList = (props) => {
        const {
            label,
            background = "#CFEEFF",
            color = "#2F6E90",
            emptyPlaceholder,
            data = [],
            datalist = [],
            stateKey,
            styles = {},
            margin = true,
            openOnClick = true,
            noMatchText,
            isClientManaged,
        } = props;

        let Component = BubbleList;
        if (props.clientManagedType) {
            Component = BubbleListClientManagedSimpleOnEnter;
        }

        return (
            <Component
                label={label}
                background={background}
                color={color}
                deleteWhite={true}
                emptyPlaceholder={emptyPlaceholder}
                handleFormChange={(value) =>
                    handleChange(
                        stateKey,
                        TransformValue({
                            value,
                            isClientManaged,
                        }),
                    )
                }
                filterStaticDataList={true}
                useEnterButtonOnSelect={true}
                readOnly={!edit}
                data={data}
                datalist={datalist}
                openOnClick={openOnClick}
                wrapperStyles={Object.assign(
                    {},
                    margin && { marginTop },
                    styles,
                )}
                inputMargin="0.28125rem 0 0 0"
                noMatchText={noMatchText}
                clientManagedType={props.clientManagedType}
                clientFeatures={clientFeatures}
                apiUrls={apiUrls}
            />
        );
    };

    const RenderSelect = (props) => {
        const {
            id,
            value,
            label,
            disabledText,
            data,
            stateKey,
            isClientManaged,
            transformListHook,
            className = "",
        } = props;

        let Component = Select;
        if (props.clientManagedType && isClientManaged) {
            Component = ClientManagedSelect;
        }

        return (
            <Component
                id={id}
                value={value}
                clear={true}
                label={label}
                disabledText={disabledText}
                noneSelectedText="None Selected"
                readOnly={!edit}
                disabled={!edit}
                data={data}
                textTransform="capitalize"
                handleChange={(value) => handleChange(stateKey, value)}
                clientManagedType={props.clientManagedType}
                clientFeatures={clientFeatures}
                apiUrls={apiUrls}
                isClientManaged={isClientManaged}
                transformListHook={transformListHook}
                className={className}
            />
        );
    };

    const countriesList = ClientManagedCountries({
        clientFeatures,
        options,
        fallback: countries,
    }).map((item) => item.text);

    const spokenLanguages = spoken_languages.map((item) => item.text);
    const writtenLanguages = written_languages.map((item) => item.text);

    const platformsDatalist = rights_platforms.map((item) => item.platform);

    const { status, statusIsDisabled } = RightStatus(data);

    const statusClass = _.isString(status) ? status.replace(" ", "") : status;

    let platformValue = platforms;
    if (Array.isArray(platforms)) platformValue = platforms[0];

    let platformsDatalistTransformed = platformsDatalist;
    if (platformValue == "*") {
        platformsDatalistTransformed = [...platformsDatalistTransformed, "*"];
    }

    const RenderIcons = () => {
        return (
            <div className="iconsSource">
                {!edit && !disabled && !statusIsDisabled && (
                    <img
                        src={`${Settings.images.path}/svg/edit-grey.svg`}
                        className="meta-edit-row"
                        onClick={!editDisabled ? () => handleEdit() : () => {}}
                        style={{
                            marginRight: "1rem",
                        }}
                    />
                )}

                {!disabled && edit && (
                    <React.Fragment>
                        <img
                            src={`${Settings.images.path}/svg/clear-light-grey.svg`}
                            alt="delete"
                            className="meta-delete-row"
                            onClick={() => handleDelete()}
                        />

                        <img
                            src={`${Settings.images.path}/svg/tick-light-grey.svg`}
                            className="meta-save-row"
                            style={Object.assign(
                                {},
                                {
                                    marginLeft: "0.5rem",
                                },
                                !isValidated && {
                                    opacity: "0.5",
                                },
                            )}
                            onClick={
                                isValidated ? () => handleStateSave() : () => {}
                            }
                        />
                    </React.Fragment>
                )}

                {!edit && status && (
                    <div className={`status ${statusClass}`}>
                        <span>{status}</span>
                    </div>
                )}
            </div>
        );
    };

    const RenderExpandableRow = (
        { dataStructure = {}, options = {}, icon = {} },
        props,
    ) => {
        //todo: default structure tbd
        const { header = [], body = [] } = dataStructure;

        const [isOpen, setIsOpen] = useState(false);

        const handleOpen = () => {
            setIsOpen(true);
        };

        return (
            <div className="rights-v3">
                <ExpandableRow
                    header={
                        <ExpandableRowHeader
                            dataStructure={header}
                            icon={icon}
                            data={data}
                            edit={edit}
                        />
                    }
                    body={
                        <ExpandableRowBody
                            dataStructure={body}
                            icon={icon}
                            data={data}
                            edit={edit}
                            isOpen={isOpen}
                        />
                    }
                    arrowIconColorType="dark"
                    className="rights-v3-main-header"
                    bodyOpen={handleOpen}
                />
            </div>
        );
    };

    const RenderClassic = (props) => {
        const {
            licensor,
            platforms,
            countries = [],
            business_models = [],
            license_start,
            license_end,
            dubbing_avails,
            subtitle_avails,
            version,
            children,
        } = data;

        const { dataStructure = [], options } = props;

        const isManagedList = useMemo(() => {
            return {
                CLIENT_MANAGED_RIGHTS_LICENSORS: isClientManagedLicensors,
                CLIENT_MANAGED_RIGHTS_PLATFORMS: isClientManagedPlatforms,
                CLIENT_MANAGED_COUNTRIES: isClientManagedWrittenLanguages,
                CLIENT_MANAGED_LANGUAGES: isClientManageSpokenLanguages,
                CLIENT_MANAGED_RIGHTS_VERSIONS: isClientManagedRightVersions,
            };
        });

        if (Array.isArray(dataStructure)) {
            return (
                <div className="meta-rights-v2--item">
                    <div className="content wrap">
                        {dataStructure.map((item, index) => {
                            const {
                                component_type,
                                key,
                                title,
                                placeholder,
                                list,
                                custom_list,
                                single,
                                disabled_text = "Select",
                                createNew = false,
                                disabled_if_not_empty,
                                client_managed_list_type = "",
                            } = item;

                            const clientManaged = isClientManaged({
                                clientFeatures,
                                clientManagedType: client_managed_list_type,
                            });

                            const isClientManagedList =
                                clientManaged ||
                                isManagedList[client_managed_list_type || list];

                            let datalist = options[list] || [];

                            if (custom_list) {
                                datalist = customLists[custom_list] || [];
                            }

                            let commonProps = {
                                label: title,
                                stateKey: key,
                                placeholder,
                                value: data[key],
                                disabled: !edit,
                                clientManagedType:
                                    client_managed_list_type || list,
                                isClientManaged: isClientManagedList,
                                list,
                            };

                            if (
                                disabled_if_not_empty &&
                                !data[disabled_if_not_empty] &&
                                edit
                            ) {
                                commonProps.disabled = true;
                            }

                            let useDateFormat = false;
                            if (Clients.isClientEpix())
                                useDateFormat = "M/D/YYYY HH:mm";

                            return (
                                <div className="item" key={index}>
                                    {component_type == "input" && (
                                        <RenderInput {...commonProps} />
                                    )}

                                    {component_type == "list" &&
                                        single &&
                                        !createNew && (
                                            <RenderSingleInput
                                                {...commonProps}
                                                datalist={datalist}
                                            />
                                        )}

                                    {component_type == "list" &&
                                        !single &&
                                        !createNew && (
                                            <RenderBubbleList
                                                {...commonProps}
                                                data={data[key]}
                                                emptyPlaceholder={placeholder}
                                                datalist={datalist}
                                                margin={false}
                                            />
                                        )}

                                    {component_type == "list" &&
                                        createNew &&
                                        !single && (
                                            <RenderBubbleList
                                                {...commonProps}
                                                data={data[key]}
                                                emptyPlaceholder={placeholder}
                                                datalist={datalist}
                                                margin={false}
                                                noMatchText="No match for {value}. Create New?"
                                            />
                                        )}

                                    {component_type == "list" &&
                                        createNew &&
                                        single && (
                                            <RenderSingleInput
                                                {...commonProps}
                                                datalist={datalist}
                                                noMatchText="No match for {value}. Create New?"
                                            />
                                        )}

                                    {component_type == "select" && (
                                        <RenderSelect
                                            {...commonProps}
                                            disabledText={disabled_text}
                                            data={datalist}
                                        />
                                    )}

                                    {component_type == "date_time" && (
                                        <RecordDateInput
                                            {...commonProps}
                                            disabledText={
                                                data[key]
                                                    ? false
                                                    : "None Entered"
                                            }
                                            handleChange={(value) =>
                                                handleChange(key, value)
                                            }
                                            dateFormat={useDateFormat}
                                        />
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    <RenderIcons />
                </div>
            );
        }

        const managedLicensors = RightsLicensorsOptions({
            options,
            clientFeatures,
        });

        return (
            <div className="meta-rights-v2--item">
                <div className="content">
                    <div className="container-flex">
                        <div>
                            <RenderSingleInput
                                label={
                                    licensorText && licensorText.label
                                        ? licensorText.label
                                        : "Licensor"
                                }
                                stateKey="licensor"
                                placeholder={
                                    licensorText && licensorText.placeholder
                                        ? licensorText.placeholder
                                        : "Enter Licensor"
                                }
                                value={licensor}
                                datalist={managedLicensors}
                                clientManagedType={
                                    CLIENT_MANAGED_RIGHTS_LICENSORS
                                }
                                isClientManaged={isClientManagedLicensors}
                                className="licensor-input"
                            />
                        </div>

                        <div>
                            <RenderSelect
                                value={platformValue}
                                stateKey="platforms"
                                label="Platform"
                                disabledText="Select Platform"
                                data={platformsDatalistTransformed}
                                isClientManaged={isClientManagedPlatforms}
                                clientManagedType={
                                    CLIENT_MANAGED_RIGHTS_PLATFORMS
                                }
                                className="platform-select"
                            />
                        </div>
                    </div>

                    <div className="container-flex">
                        <div>
                            <RenderBubbleList
                                label="Business Models"
                                stateKey="business_models"
                                emptyPlaceholder="Start Typing Business Models"
                                data={business_models}
                                datalist={options.business_models || []}
                            />
                        </div>

                        <div>
                            <RenderBubbleList
                                label="Countries"
                                stateKey="countries"
                                emptyPlaceholder="Start Typing Countries"
                                data={countries}
                                datalist={countriesList || []}
                                isClientManaged={isClientManagedCountriesList}
                                clientManagedType={CLIENT_MANAGED_COUNTRIES}
                            />
                        </div>
                    </div>

                    {rightsSubsDubs && (
                        <React.Fragment>
                            <div
                                style={{
                                    marginTop,
                                    width: "48%",
                                }}
                            >
                                {!multipleVersions && (
                                    <RenderSelect
                                        value={version}
                                        stateKey="version"
                                        label="Version"
                                        disabledText="Select Version"
                                        data={versions}
                                        transformListHook={(list) =>
                                            list.map((item) => item.name)
                                        }
                                        isClientManaged={
                                            isClientManagedRightVersions
                                        }
                                        clientManagedType={
                                            CLIENT_MANAGED_RIGHTS_VERSIONS
                                        }
                                    />
                                )}

                                {multipleVersions && (
                                    <RenderBubbleList
                                        label="Versions"
                                        stateKey="version"
                                        emptyPlaceholder="Start Typing Versions"
                                        data={version}
                                        datalist={versions}
                                    />
                                )}
                            </div>

                            <div className="container-flex">
                                <div>
                                    <RenderBubbleList
                                        label="Subtitle Tracks"
                                        stateKey="subtitle_avails"
                                        emptyPlaceholder="Start Typing Subtitle Track"
                                        data={subtitle_avails}
                                        datalist={writtenLanguages || []}
                                        isClientManaged={
                                            isClientManagedWrittenLanguages
                                        }
                                        clientManagedType={SUBTITLES}
                                    />
                                </div>

                                <div>
                                    <RenderBubbleList
                                        label="Dubbing Tracks"
                                        stateKey="dubbing_avails"
                                        emptyPlaceholder="Start Typing Dubbing Track"
                                        data={dubbing_avails}
                                        datalist={spokenLanguages || []}
                                        isClientManaged={
                                            isClientManageSpokenLanguages
                                        }
                                        clientManagedType={DUBBING_TRACKS}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    )}

                    <div style={{ marginTop }} className="container-flex">
                        <div>
                            <RecordDateInput
                                label="Licence Start"
                                value={license_start}
                                disabled={!edit}
                                handleChange={(value) =>
                                    handleChange("license_start", value)
                                }
                            />
                        </div>

                        <div>
                            <RecordDateInput
                                label="Licence End"
                                value={license_end}
                                disabled={!edit}
                                handleChange={(value) =>
                                    handleChange("license_end", value)
                                }
                            />
                        </div>
                    </div>
                </div>
                <RenderIcons />
            </div>
        );
    };

    const TransformValue = (props) => {
        const { value, key = "text", isClientManaged } = props;

        if (isClientManaged) {
            return ObjectArrayToKeyArrayTransformer({
                value,
                key,
            });
        } else {
            return value;
        }
    };

    return (
        <Fragment>
            {rightsV3 && (
                <RenderExpandableRow
                    options={options}
                    dataStructure={righsV3DataStructure}
                    icon={<RenderIcons />}
                />
            )}

            {!rightsV3 && (
                <RenderClassic
                    options={options}
                    dataStructure={dataStructure}
                />
            )}
        </Fragment>
    );
};

export default RenderItem;
