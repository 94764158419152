const GetAdvisoriesCodes = (props) => {
    const { options = {}, advisories = [] } = props;

    const { certifications } = options;

    const { internal_systems = {} } = certifications;

    const { advisory_groups = [] } = internal_systems;

    let result = [];

    advisory_groups.map((group) => {
        group.advisory.map((advisoryItem) => {
            if (advisories.includes(advisoryItem.name)) {
                result.push(advisoryItem.code);
            }
        });
    });

    return result;
};

export default GetAdvisoriesCodes;
