import React from "react";
import Settings from "../../Settings";

const RenderRemoveImage = (props) => {
    const {
        disabled,
        handleDelete = () => {},
        icon = "clear-light-grey.svg",
    } = props;

    if (!disabled) {
        return (
            <div className="meta-numbered-record-table--remove">
                <img
                    src={`${Settings.images.path}/svg/${icon}`}
                    className="meta-numbered-record-table--image"
                    onClick={handleDelete}
                />
            </div>
        );
    }

    return false;
};

export default RenderRemoveImage;
