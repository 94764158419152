import React from "react";
import _ from "lodash";
import Index from "./Index";
import styles from "../styles";
import Select from "../../Select";
import BubbleList from "../../BubbleList";
import isIndexInArray from "../../Helpers/isIndexInArray";
import update from "react-addons-update";
import deepcopy from "deepcopy";
import { diff } from "deep-object-diff";

export default class RegionalProperties extends Index {
    constructor(props) {
        super(props);

        const data = this.getDataFromProps(this.props.data);

        /**
         * @var object
         */
        this.copy = this.props.copy || {};

        /**
         * @var object
         */
        this.styles = styles(this.props);

        /**
         * @var object
         */
        this.state = {
            data,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        const nextPropsData = nextProps.data || [];
        const thisPropsData = this.props.data || [];
        const difference = diff(nextPropsData, thisPropsData);

        if (!_.isEmpty(difference)) {
            return true;
        }

        if (!_.isEqual(nextProps.disabled, this.props.disabled)) {
            return true;
        }

        if (!_.isEqual(nextProps.sectionOptions, this.props.sectionOptions)) {
            return true;
        }

        if (!_.isEqual(nextProps.options, this.props.options)) {
            return true;
        }

        if (!_.isEqual(nextProps.languageIso, this.props.languageIso)) {
            return true;
        }

        if (!_.isEqual(nextState, this.state)) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps, prevState) {
        // data
        if (!_.isEqual(this.props.data, prevProps.data)) {
            const data = this.getDataFromProps(this.props.data);
            this.setStateValue("data", this.props.data);
        }
    }

    getDataFromProps(data = {}) {
        let result = {};

        if (typeof data == "object") {
            result = deepcopy(data);
        }

        return result;
    }

    getLocalDataFromState() {
        let result = this.state.data;

        if (
            typeof this.props.languageIso == "string" &&
            typeof this.state.data == "object"
        ) {
            const localData = deepcopy(this.state.data[this.props.languageIso]);

            if (typeof localData == "object" && localData) {
                result = localData;
            }
        }

        return result;
    }

    handleChange(name, value) {
        let data = deepcopy(this.state.data || {});

        // Creating language object if doesnt exist
        if (!data[this.props.languageIso]) {
            data[this.props.languageIso] = {};
        }

        let lang = deepcopy(data[this.props.languageIso] || {});
        lang[name] = value;

        let newData = update(this.state.data, {
            [this.props.languageIso]: {
                $set: lang,
            },
        });

        // Remove empty lang
        if (
            _.isEmpty(lang.publication_status) &&
            _.isEmpty(lang.seo_keywords)
        ) {
            delete newData[this.props.languageIso];
        }

        if (typeof this.props.handleFormChange == "function") {
            this.props.handleFormChange(newData);
        }

        this.setState({
            data: newData,
        });
    }

    renderNoneAdded() {
        return false;
    }

    renderSelects(data) {
        const { sectionOptions } = this.props;

        const options = this.props.options || {};
        const publication_status = options.publication_status || [];

        return (
            <div style={this.styles.flexMarginContainer}>
                {isIndexInArray(sectionOptions, "PublicationStatus") && (
                    <Select
                        name="PublicationStatus"
                        id="PublicationStatus"
                        value={data.publication_status}
                        label={
                            this.copy.publicationOptions || "Publication Status"
                        }
                        disabledText={this.copy.selectStatus || "Select Status"}
                        noneSelectedText={
                            this.copy.noneSelected || "None Selected"
                        }
                        readOnly={this.props.disabled}
                        disabled={this.props.disabled}
                        wrapperStyles={this.styles.flexMarginItem}
                        data={publication_status}
                        handleChange={this.handleChange.bind(
                            this,
                            "publication_status",
                        )}
                    />
                )}
            </div>
        );
    }

    renderBubbleLists(data) {
        const { sectionOptions } = this.props;

        const options = this.props.options || {};
        const seo_keywords = options.seo_keywords || [];

        return (
            <React.Fragment>
                {isIndexInArray(sectionOptions, "SeoKeywords") && (
                    <div style={this.styles.marginItem}>
                        <BubbleList
                            name="SeoKeywords"
                            inputId="SeoKeywords"
                            background="#24ab76"
                            color="white"
                            deleteWhite={true}
                            label={this.copy.seoKeywords || "Seo Keywords"}
                            readOnly={this.props.disabled}
                            disabled={this.props.disabled}
                            emptyPlaceholder={
                                this.copy.startTypingKeywords ||
                                "Start Typing Keywords"
                            }
                            data={data.seo_keywords || []}
                            datalistId="SeoKeywordsList"
                            datalist={seo_keywords}
                            handleFormChange={this.handleChange.bind(
                                this,
                                "seo_keywords",
                            )}
                            ranking={true}
                            handleNoMatchText={true}
                            noMatchText={
                                this.copy.noMatchText ||
                                "No match for {value}. Create New?"
                            }
                        />
                    </div>
                )}
            </React.Fragment>
        );
    }

    renderContent() {
        const localData = this.getLocalDataFromState();

        return (
            <React.Fragment>
                {this.renderSelects(localData)}
                {this.renderBubbleLists(localData)}
            </React.Fragment>
        );
    }
}
