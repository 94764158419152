import React from "react"
import Input from "../../interfaces/Input.interface";
import { CoreSelect } from "../../../components/inputs/Select/components/CoreSelect";
import { useStores } from '../../store/rootStore'
import { observer } from "mobx-react";
import { Chip } from "@mui/material";
import FieldInterface from '../../interfaces/Field.interface';
import RenderChip from "../../../components/inputs/Select/utils/renderChip";
import { isEmpty } from "lodash";

function getOptions(options: any[], field: FieldInterface) {
    if (field.type.select?.adornmentKey) {
        return options.map((item: any) => {
            return {
                ...item,
                rightAdornment: RenderChip(item.value, field?.checkedKeys?.includes(item.value))
            }
        })
    }

    return options.map((item: any) => {
        let result = item

        if (result.label_color && result.label_background) {
            result.overrideLabel = <Chip
                sx={{
                    backgroundColor: result.label_background,
                    margin: 0,
                    "& .MuiChip-label": {
                        color: result.label_color,
                        fontSize: "0.75rem",
                    },
                }}
                label={result.label}
            />
        }

        return result
    })
}

export const Select = observer(({ field, framed = false }: Input) => {
    const { listsStore, formStore } = useStores()

    let customStyles = {}

    if (!framed && field.validation?.readOnly?.enabled) {
        customStyles = {
            '.MuiInputBase-input': {
                paddingLeft: '0 !important',
                paddingBottom: '0.5rem !important',
            },
            ".MuiSelect-icon": {
                display: "none",
            },
            '.MuiOutlinedInput-notchedOutline': {
                border: '0'
            },
            '.Mui-disabled': {
                color: "rgba(47, 47, 47, 0.87) !important;",
                "-webkit-text-fill-color": "rgba(47, 47, 47, 0.87) !important;",
            }
        }
    }

    const staticList = !isEmpty(field.type.select.list) ? field.type.select.list : null;

    const options =
        staticList ||
        getOptions(
            listsStore.lists[field.type.select.listKey]?.options || [],
            field,
        );
    
    return (
        <CoreSelect
            placeholder={`Select ${field.label}`}
            config={{
                options,
                optionsFetched: listsStore.lists[field.type.select.listKey]?.isFetched,
                path: field.key,
                labelProps: {
                    text: field.label,
                    isRequired: field.validation?.required?.enabled,
                },
                enableSearchbox: true,
                showChipArray: false,
                noneSelected: true,
                disabled: field.validation?.readOnly?.enabled || formStore.disabled,
                showRightAdornment: false,
                tooltip: field.tooltip || ''
            }}
            customStyles={customStyles}
        />
    )
})
