import React from "react";
import _ from "lodash";
import "./styles/_styles.scss";
import Settings from "../Settings";
import Button from "../Button";

const AddNewRowButton = (props) => {
    const {
        onClick,
        id,
        copy = {},
        styles = {},
        className,
        disabled,
        text,
        data = [],
    } = props;

    let value = text || copy.addNew || "Add new";

    let borderRadiusFull = _.isEmpty(data);
    let borderRadiusBottom = !_.isEmpty(data);

    return (
        <div
            className={`meta-add-new-row-button 
				${borderRadiusFull ? "border-radius-full" : ""}
				${borderRadiusBottom ? "border-radius-bottom" : ""}
			`}
            style={styles}
        >
            <Button
                id={id}
                value={value}
                type="button"
                className={`${className} meta-add-row ${disabled ? 'add-new-button-disabled' : ""}`}
                margin="0"
                disabled={disabled}
                disabledOpacity={disabled}
                padding="0.34375rem"
                background="#8f8f8f"
                hoverBackground="#9a9a9a"
                fontSize="0.78125rem"
                color="white"
                display="inline-block"
                onClick={onClick}
                leftIcon={`${Settings.images.path}/svg/new-record-white.svg`}
                leftIconStyles={{
                    height: "0.75rem",
                    marginRight: "0.1875rem",
                }}
            />
        </div>
    );
};

export default AddNewRowButton;
