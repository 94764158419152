import SendRequest from "../../SendRequest";
import { VersionsInLocalStorageInterface } from "../../ClientManagedOptions/clientManagedController";

interface PropsInterface {
    state: any;
    onSuccess: (response: any) => void;
    testingData: VersionsInLocalStorageInterface;
}

export default function HandleClientManagedOptionsVersionsRequest({
    state,
    onSuccess,
    testingData,
}: PropsInterface): void {
    const cmsData: any = state.cmsData || {};
    const apiUrls: any = cmsData.apiUrls || {};
    const getClientManagedVersions: string =
        apiUrls.getClientManagedVersions || "";

    const options = {
        url: getClientManagedVersions,
        method: "GET",
        testingData,
    };

    const errorHandle = (error: any) => {
        console.log(error);
    };

    try {
        SendRequest(
            options,
            (response: any) => {
                const { data, message } = response;

                if (data) {
                    onSuccess(data);
                    return;
                }

                errorHandle(message);
            },
            (error: any) => {
                errorHandle(error);
            },
        );
    } catch (e) {
        errorHandle(e);
    }
}
