import CategoryTags from "../../../components/CategoryTags";
import MetaIdTemp from "../../../components/MetaIdTemp";
import deepcopy from "deepcopy";
import { FRANCHISE } from "../../Collections/src/CollectionType";
import GetPageTypeByCategory from "../../Collections/src/GetPageTypeByCategory";

const HandleNewCollection = (props) => {
    const { state = {}, unsavedChanges, selectedCategory } = props;

    const { type, set, handleClearUrlSearch, category } = state;

    if (!unsavedChanges) {
        const newRecord = {
            category: selectedCategory || category,
            meta_id_temp: MetaIdTemp,
            origination: {},
        };

        set({
            newRecord,
            originalNewRecord: newRecord,
            recordData: {},
            activeRecord: false,
            searchData: [],
            unsavedChanges: false,
            editRecord: true,
            collectionTitle: {},
            displaySearchBoxFilters: false,
            notification: {},
            type: GetPageTypeByCategory({ category }),
        });

        handleClearUrlSearch();
    }
};

export default HandleNewCollection;
