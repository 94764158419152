import React from "react";
import HeaderCube from "./HeaderCube";

const TreeElement: React.FC = (props) => {
    const {
        hierarchyLevel = 0,
        isChildExists = false,
        isLastChild = true,
        asset,
    } = props;

    return (
        <div className="header-tree-element">
            {hierarchyLevel == 1 && !isLastChild && (
                <div className="lines-block-full">
                    <div className="horizontal-line"></div>
                </div>
            )}

            {hierarchyLevel == 1 && isLastChild && (
                <div
                    className="lines-block-partial"
                    style={{ display: "flex", flexDirection: "column" }}
                >
                    <div className="partial-side-line">
                        <div className="vertical-line-half"></div>
                    </div>
                    <div className="horizontal-line"></div>
                </div>
            )}

            {hierarchyLevel == 2 && !isLastChild && (
                <div className="lines-block-full">
                    <div
                        className="lines-block-full"
                        style={{ marginLeft: "2.2185rem" }}
                    ></div>
                    <div className="horizontal-line"></div>
                </div>
            )}

            {hierarchyLevel == 2 && isLastChild && (
                <div className="lines-block-full" style={{ display: "block" }}>
                    <div
                        className="lines-block-partial"
                        style={{ marginLeft: "1.4935rem" }}
                    >
                        <div
                            className="partial-side-line"
                            style={{ maxHeight: "1.625rem" }}
                        >
                            <div className="vertical-line-half"></div>
                        </div>
                        <div className="horizontal-line"></div>
                    </div>
                </div>
            )}
            <HeaderCube
                hierarchyLevel={hierarchyLevel}
                isChildExists={isChildExists}
                asset={asset}
            />
        </div>
    );
};

export default TreeElement;
