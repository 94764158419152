import getFileExtension from "../../Helpers/fileExtension"

interface HasNoPreviewImageInterface {
    cmsData: any,
    image: string
}

export default function hasNoPreviewImage(props: HasNoPreviewImageInterface) {
    const {
        cmsData = {},
        image = ""
    } = props

    const {
        clientDataStructure = {}
    } = cmsData

    const {
        images = {}
    } = clientDataStructure

    const {
        no_preview_extensions = []
    } = images

    return no_preview_extensions.includes(
        getFileExtension(image)
    )
}