import { CORE_FORM_STRING_DEFAULT_VALUE } from "../../../../../core/Form/constants";
import randomNumber from "../../../utils/randomNumber";

export const createParentSeason = (
    option: AutocompleteRecord = {},
): Episode["parentSeason"] => {
    return {
        // ...option,
        id: option?.id || randomNumber(),
        metaId: option?.metaId || CORE_FORM_STRING_DEFAULT_VALUE,
        title: option?.title || CORE_FORM_STRING_DEFAULT_VALUE,
        seasonNo: option?.seasonNo || CORE_FORM_STRING_DEFAULT_VALUE,
    };
};
