import { action, extendObservable, makeObservable, observable } from "mobx";
import { TicketComment } from "../containers/ticket/comments/validation/commentVS";

import TaskManagerStoreInterface from "./TaskManagerStoreInterface";
import { RootStore } from "./rootStore";
import { TicketCommentScope } from "../types/interfaces.shared";

export type InitialStateType = {
    comments: TicketComment[];
    draft: string;
    defaultValues: TicketComment;
};

const initialState: InitialStateType = {
    comments: [],
    draft: "",
    defaultValues: {
        id: null,
        text: "",
        author: {
            id: -1,
            name: "",
        },
        createdAt: undefined,
    },
};

class TicketCommentsStore implements TaskManagerStoreInterface {
    rootStore: RootStore;

    comments: InitialStateType["comments"];

    draft: InitialStateType["draft"];

    defaultValues: InitialStateType["defaultValues"];

    scopes: TicketCommentScope[];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            scopes: observable,
            setScopes: action,
            setDraft: action,
            setComments: action,
            setUseInternalComment: action,
            setUseExternalComment: action,
            setUseReplyToAllComment: action,
        });

        extendObservable(this, initialState);
    }

    async initialize(...args: any[]) {
        if (this.rootStore.storesInit["ticketComments"]) {
            throw new Error(
                `Task Manager ticketComments Store has already been initialized.`,
            );
        }

        this.scopes = [];
        this.rootStore.finishLoading("ticketComments");
    }

    setDraft(draft: string) {
        this.draft = draft;
    }

    setComments(comments: TicketComment[]) {
        this.comments = Array.from(comments);
    }

    setUseInternalComment() {
        this.setScopes([TicketCommentScope.Internal])
    }

    setUseExternalComment() {
        this.setScopes([TicketCommentScope.External])
    }

    setUseReplyToAllComment() {
        this.setScopes([TicketCommentScope.Internal, TicketCommentScope.External])
    }

    setScopes(scopes: TicketCommentScope[]) {
        this.scopes = scopes
    }

    async getComments(ticketId: number) {
        if (!ticketId) {
            return;
        }

        const response = await this.rootStore.taskManagerApi.getComments(
            ticketId,
        );

        if (response.isError) {
            this.rootStore.handleApiError(response);
        } else {
            this.setComments(response.data);
        }
    }

    async postComment(
        ticketId: number,
        formData: TicketComment,
        onSuccess: () => void,
    ) {
        if (!ticketId) {
            return;
        }

        const authorId = this.rootStore.userId;

        const response = await this.rootStore.taskManagerApi.postComment(
            ticketId,
            {
                ...formData,
                ...{
                    author: authorId,
                    scopes: this.scopes,
                },
            },
        );
        if (response.isError) {
            this.rootStore.handleApiError(response);
        } else {
            onSuccess();

            this.rootStore.pushSnackbar(response.message);

            this.setComments(response.data);
        }
    }

    async deleteComment(ticketId: number, commentId: TicketComment["id"]) {
        if (!ticketId) {
            return;
        }

        const response = await this.rootStore.taskManagerApi.deleteComment(
            ticketId,
            commentId,
        );
        if (response.isError) {
            this.rootStore.handleApiError(response);
        } else {
            this.rootStore.pushSnackbar(response.message);

            this.setComments(response.data);
        }
    }

    isOwnComment(comment: TicketComment) {
        return comment.author?.id === this.rootStore.userId;
    }
}

export default TicketCommentsStore;
