import React from "react";
import _ from "lodash";
import "./styles/_styles.scss";

const DigitalReadinessValidationMessages = (props) => {
    const { styles = {}, items = [] } = props;

    if (_.isEmpty(items)) {
        return null;
    }

    return (
        <div className="digital-validation-messages" style={styles}>
            {items.map((item, index) => {
                return <div key={index}>{item}</div>;
            })}
        </div>
    );
};

export default DigitalReadinessValidationMessages;
