import { SET_INHERITANCE_FILTERS } from "../actions/action_types";
import { defaultInheritanceFilters } from "../initialState";

export default function (
    state = { inheritanceFilters: defaultInheritanceFilters },
    action,
) {
    switch (action.type) {
        case SET_INHERITANCE_FILTERS:
            return Object.assign({}, state, {
                inheritanceFilters: action.inheritanceFilters,
            });
    }

    return state;
}
