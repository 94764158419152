import React from "react";
import AbstractPreFrameworkBoilerplate from "./AbstractPreFrameworkBoilerplate";
import Attribute from "../../components/Attribute";

// Adds custom methods
export default class PreFrameworkBoilerplate extends AbstractPreFrameworkBoilerplate {
    /**
     * PreFrameworkBoilerplate
     *
     * @param object props
     * @return false
     */
    constructor(props) {
        super(props);

        /**
         * @var string
         */
        this.element = "#preFramework";
    }

    /**
     * Sets class attributes
     *
     * @return false
     */
    setAttributes() {
        this.attributes = {
            environmentText:
                Attribute(this.element, "data-environment-text") || false,
        };
    }
}
