import Prefixer from "inline-style-prefixer";

export default function styles(props = {}) {
    let styles = {
        mainContainer: {
            marginTop: "1.5rem",
        },
        tdText: {
            color: "rgba(101, 101, 101, 0.87)",
            padding: "0.5rem 1.063rem",
            width: "100%",
        },
        tableInlineIcon: {
            width: "1.25rem",
            display: "block",
            cursor: "pointer",
            transition: "0.3s",
        },
        tableIconMargin: {
            marginLeft: "0.5rem",
        },
        tableIcon: {
            width: "1.25rem",
            margin: "0 0 0 auto",
            display: "block",
            cursor: "pointer",
        },
        tableTextStyle: {
            letterSpacing: "normal",
            width: "100%",
            padding: "0.71875rem 0px",
        },
        noneAddedLabel: {
            color: "rgba(188, 188, 188, 0.87)",
            display: "block",
            fontSize: "1.05rem",
        },
        headerTitle: {
            color: "rgb(0, 0, 0)",
            fontSize: "1.3rem",
            padding: "0 0 1.375rem 0",
            margin: "0",
        },
        label: {
            display: "block",
            fontSize: "0.90625rem",
            fontWeight: 500,
            color: "rgba(110, 110, 110, 0.87)",
        },
    };

    let prefixer = new Prefixer();
    styles = prefixer.prefix(styles);

    return styles;
}
