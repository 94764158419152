import React from "react";
import TitleValue from "../../../TitleValue";
import ConvertExtraInfoToString from "./ConvertExtraInfoToString";

const ExtraFields = (props) => {
    const { compliance = {}, extraFields = [], style } = props;

    return (
        <React.Fragment>
            {extraFields.map((field, index) => {
                let value;
                if (compliance[field.key]) {
                    value = compliance[field.key];
                } else if (field.otherKeys && Array.isArray(field.otherKeys)) {
                    for (const key of field.otherKeys) {
                        if (compliance[key]) {
                            value = compliance[key];
                            break;
                        }
                    }
                }

                return (
                    <TitleValue
                        title={field.title}
                        value={ConvertExtraInfoToString(value)}
                        style={style}
                        emptyPlaceholder={field.emptyPlaceholder}
                        className={`compliance--${field.key}`}
                        key={index}
                    />
                );
            })}
        </React.Fragment>
    );
};

const ExtraInfo = (props) => {
    const { dataStructure = {} } = props;

    const { extra_info = {} } = dataStructure;

    const { left: extra_info_left = [], right: extra_info_right = [] } =
        extra_info;

    const marginBottom = "1.15625rem";

    const leftColumnStyles = { marginBottom, width: "100%" };
    const rightColumnStyles = {
        marginBottom,
        width: "30%",
        marginRight: "1rem",
    };

    return (
        <div className="double-inline-30-70 asset-ids">
            <div style={{ flexDirection: "column" }}>
                <ExtraFields
                    {...props}
                    extraFields={extra_info_left}
                    style={leftColumnStyles}
                />
            </div>

            <div style={{ flexWrap: "wrap", flexGrow: 1 }}>
                <ExtraFields
                    {...props}
                    extraFields={extra_info_right}
                    style={rightColumnStyles}
                />
            </div>
        </div>
    );
};

export default ExtraInfo;
