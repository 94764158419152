import React from "react";
import { featureUserRoleManagement } from "../../../services/PermissionService";
import { useStore } from "../../../components/Global/store/store";
import NavUserManagement from "./NavUserManagement";
import NavLegacy from "./NavLegacy";

const Nav = (props: any) => {
    const [state] = useStore();

    const { cmsData = {} } = state;

    // const userRoleManagement:boolean = featureUserRoleManagement({ cmsData })

    // if(userRoleManagement) {
    //     return <NavUserManagement {...props}/>
    // }

    return <NavLegacy {...props} globalState={state} />;
};

export default Nav;
