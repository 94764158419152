import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputBase, Paper } from "@mui/material";
import React from "react";

type CoreSelectSearchBoxProps = {
    autocomplete: {
        searchedValue: string;
        handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
        handleKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    };
    inputSearchRef: React.MutableRefObject<HTMLInputElement>;
};
export function CoreSelectSearchBox({
    autocomplete,
    inputSearchRef,
}: CoreSelectSearchBoxProps) {
    const { searchedValue, handleChange, handleKeyDown } = autocomplete;
    return (
        <Paper
            component="div"
            elevation={0}
            sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                margin: "0 0 5px 0",
                backgroundColor: "#F7F6F6",
                boxShadow: "none !important",
                borderRadius: "0.1875rem !important",
                padding: "0.3125rem",
                "& .MuiButtonBase-root": {
                    padding: 0,
                    "& .MuiSvgIcon-root": {
                        fontSize: "1.125rem"
                    },
                },
                "& .MuiInputBase-root": {
                    fontSize: "0.875rem",
                    marginLeft: "0.5rem",
                    "& .MuiInputBase-input": {
                        height: "1.125rem",
                        padding: "0 !important",
                    },
                },
            }}
        >
            <IconButton
                disableRipple
                type="button"
                sx={{ p: "0.375rem" }}
                aria-label="search"
            >
                <SearchIcon />
            </IconButton>
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                autoFocus={true}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                value={searchedValue}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                ref={inputSearchRef}
            />
        </Paper>
    );
}
