import { gql } from "@apollo/client";
import { FieldMapInterface } from "../../interfaces";
import editableOption from "./options/editable";
import validationOption from "./options/validation";
import iconOption from "./options/icon";
import permissions from "./options/permissions";
import includeInSNS from "./options/includeInSNS";
import messages from "./options/messages";
import taskManager from "./options/taskManager";

const coreOriginationFields = `
    id
    key
    displayName
    order
    archived
    dataType
    section
    view
    categories
    options {
        ${editableOption}
        ${validationOption}
        ${iconOption}
        ${includeInSNS}
        ${taskManager}
        ${messages}
    }
`;

const properties = `
    ${coreOriginationFields}
`;

export const schemaGetAll = `
    getOriginationConfigs {
        label
        ${properties}
        ${permissions}
    }
`;

export const queryGetAll = gql`
    query {
        ${schemaGetAll}
    }
`;

export const queryGetOne = gql`
    query {
        getOriginationConfigs(id: $id) {
            ${properties}
        }
    }
`;

export const mutationCreate = gql`
    mutation ($input: CreateOriginationConfigInput!) {
        createOriginationConfig(input: $input) {
            ${properties}
        }
    }
`;

export const mutationUpdate = gql`
    mutation ($id: ID!, $input: UpdateOriginationConfigInput!) {
        updateOriginationConfig(id: $id, input: $input) {
            ${properties}
        }
    }
`;

const map: FieldMapInterface = {
    createMutation: mutationCreate,
    updateMutation: mutationUpdate,
    queryAll: queryGetAll,
    dataPath: "getOriginationConfigs",
};

export default map;
