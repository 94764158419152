import { useFieldArray, useFormContext } from "react-hook-form";

export const useAutocompleteSuggestions = ({ arrayPath, inputPath }) => {
    const form = useFormContext();
    const value = form.getValues(inputPath);

    const { append, replace } = useFieldArray({
        name: arrayPath,
        control: form.control,
    });

    const addNewValue = (val: string | { text: string, value: string }) => {
        if (!val) return;

        // append value only if it doesn't already exist
        if (form.getValues(arrayPath).includes(val)) {
            return;
        }

        append(val);
        form.resetField(inputPath);
        // closePopper();
    };

    return {
        addNewValue,
        replace,
        value,
    };
};
