import { isEmpty } from "lodash";
import { CoreTableGridColDef } from "../../types";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { GreyChip, YellowChip } from "../AwardsTableChips";

export const SourceCol: CoreTableGridColDef = {
    headerName: "Source",
    field: "source",
    flex: 0.75,
    renderCell: (params: GridRenderCellParams<string>) => {
        const { value } = params;
        if (isEmpty(value)) {
            return (
                <GreyChip label={"Manual Entry"} />
            )
        }

        return (
            <YellowChip label={`${value} • API`} />
        )
    }
};
