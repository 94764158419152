import React from "react";
import Index from "./Index";
import deepcopy from "deepcopy";
import MaterialDatePicker from "../../Input/MaterialDatePicker";
import RenderDate from "../../RecordTable/src/RenderDate";
import Label from "../../Input/label";
import "../styles/_programmingDates.scss";
import moment from "moment";
import _ from "lodash";
import CategoryTags from "../../CategoryTags";

export default class ProgrammingDates extends Index {
    constructor(props) {
        super(props);
    }

    handleChange(key, date) {
        const { handleFormChange = () => {}, data = [] } = this.props;

        let updatedData = deepcopy(data);

        let dateItem = data.find((item) => item.type == key);
        if (dateItem) {
            updatedData = updatedData.map((item) => {
                if (item.type == key) {
                    item.date = date;
                }

                return item;
            });
        } else {
            updatedData.push({ type: key, date });
        }

        handleFormChange(updatedData);
    }

    renderContent() {
        const { disabled = true, data = [], category } = this.props;

        const airDateItem = data.find((item) => item.type == "air_date") || {};
        const airDateAvailablePerCategory =
            _.get(
                this.props,
                "clientFeatures.programmingTab.forAllCategories",
            ) ||
            ![CategoryTags.Show.short, CategoryTags.Season.short].includes(
                category,
            );

        const killDateItem =
            data.find((item) => item.type == "kill_date") || {};
        const killDateAvailablePerCategory =
            _.get(
                this.props,
                "clientFeatures.programmingTab.forAllCategories",
            ) || [CategoryTags.Promotional.short].includes(category);

        return (
            <div
                className={"meta-programming-data"}
                style={{ display: "flex" }}
            >
                {disabled &&
                    airDateAvailablePerCategory &&
                    !_.isEmpty(airDateItem) && (
                        <div className={"disabled-date-item"}>
                            <Label label={"Air Date"} />
                            <span className={"date-string"}>
                                {RenderDate({ item: airDateItem })}
                            </span>
                        </div>
                    )}
                    
                {disabled &&
                    killDateAvailablePerCategory &&
                    !_.isEmpty(killDateItem) && (
                        <div className={"disabled-date-item"}>
                            <Label label={"Kill Date"} />
                            <span className={"date-string"}>
                                {RenderDate({ item: killDateItem })}
                            </span>
                        </div>
                    )}

                {!disabled && (
                    <React.Fragment>
                        {airDateAvailablePerCategory && (
                            <MaterialDatePicker
                                labelLegacy="Air Date"
                                placeholder="Select Air Date"
                                id="meta-release-dates-air-date"
                                defaultValue={airDateItem.date}
                                disabled={disabled}
                                handleChange={(value) =>
                                    this.handleChange("air_date", value)
                                }
                                styles={{
                                    width: "14.09375rem",
                                    marginRight: "2rem"
                                }}
                                className="datepicker-full-width"
                            />
                        )}

                        {killDateAvailablePerCategory && (
                            <MaterialDatePicker
                                labelLegacy="Kill Date"
                                placeholder={"Select Kill Date"}
                                id="meta-release-dates-kill-date"
                                defaultValue={killDateItem.date}
                                disabled={disabled}
                                handleChange={(value) =>
                                    this.handleChange("kill_date", value)
                                }
                                styles={{
                                    width: "14.09375rem"
                                }}
                                className="datepicker-full-width"
                            />
                        )}
                    </React.Fragment>
                )}
            </div>
        );
    }
}
