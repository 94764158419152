import update from "react-addons-update";
import _ from "lodash";
import HandleChange from "./HandleChange";
import deepcopy from "deepcopy";
import GetRecordData from "./GetRecordData";

const HandleDisplayLanguageIsoCodeChange = (props) => {
    const { locale, state, handleChange } = props;

    const record = GetRecordData({
        state,
    });

    const { titles = [] } = record;

    const titlesData = deepcopy(titles);

    // Remove dislay title tag from all titles
    let updatedTitles = titlesData.map((tl) => {
        let item = tl;
        delete item.display;

        return item;
    });

    //remove titles that are empty
    updatedTitles = updatedTitles.filter((tl) => {
        return tl.title !== "";
    });

    // find title based on new locale
    const titleIndex = _.findIndex(updatedTitles, function (tl) {
        return tl.locale == locale;
    });

    // if title exists then set that locale to display title
    if (titleIndex !== -1) {
        updatedTitles = update(updatedTitles, {
            [titleIndex]: {
                locale: {
                    $set: locale,
                },
                display: {
                    $set: true,
                },
            },
        });
    } else {
        // if no title exists then create a new title and set it to display title
        updatedTitles = update(updatedTitles, {
            $push: [
                {
                    locale,
                    title: "",
                    type: "title",
                    display: true,
                    // source: 'open',
                    // api_name: null,
                    // status: 'proposed'
                },
            ],
        });
    }

    if (typeof handleChange == "function") {
        handleChange({
            key: "titles",
            data: updatedTitles,
        });

        return;
    }

    HandleChange({
        key: "titles",
        data: updatedTitles,
        state,
    });
};

export default HandleDisplayLanguageIsoCodeChange;
