import { AwardInterface, AwardsDataTablePayload } from '../../interfaces/awards.interfaces'
import { adaptContributorData } from './helpers'

export default function generateAwardsTableRows(
    awards: AwardInterface[],
): AwardsDataTablePayload[]  {
    return awards?.map(
            (award) => {
                if (award.deleted === true) return;
                return award;
        })
        .filter(
            (award) => award !== undefined
        )
        .map((award, index) => {
            return {
                id: index,
                awardsBody: award?.award_body?.value,
                results: award?.result?.value,
                year: award?.year,
                category: award?.category?.value,
                contributor: adaptContributorData(award),
                localId: award?.local_id,
                source: award.source,
            }
        }) || []
}
