import _ from "lodash";
import clientManagedController from "./clientManagedController";
import { RIGHTS_PLATFORMS, RIGHTS_PLATFORMS_FULL } from "./options";

export function isClientManagedRightsPlatforms(props) {
    const { clientFeatures = {} } = props;

    const { managedLists = [] } = clientFeatures;

    return (
        managedLists.includes(RIGHTS_PLATFORMS) ||
        managedLists.includes(RIGHTS_PLATFORMS_FULL)
    );
}

const FullRightsPlatformsOptions = (props) => {
    const { options = {}, fallback = [] } = props;

    let rights = clientManagedController({
        clientManagedOptions: options.client_managed_rights_platforms || [],
        fallback,
        isClientManaged: isClientManagedRightsPlatforms(props),
    });

    let result = [];

    rights.map((item) => {
        result = result.concat(item.platforms);
    });

    return result;
};

export function ClientManagedFullRightsPlatformsOptions(props) {
    const { client_managed_rights_platforms = [] } = props;

    let result = [];

    client_managed_rights_platforms.map((item) => {
        result = result.concat(JSON.parse(item.platforms));
    });

    return result;
}

export default FullRightsPlatformsOptions;
