import { extendObservable, makeObservable, action, observable } from "mobx";
import { arrayMove } from "react-sortable-hoc";
import _ from "lodash";
import update from "react-addons-update";
import {
    MovedDataInterface,
    AwardsDataTablePayload
} from "../../interfaces/awards.interfaces";
import { AwardsType } from "../../../../core/Table/awards/types/Awards.type";
import { AwardInterface, ScriptInterface, transformAwardsPayload } from '../../../../models/Record/awards'
import { RootStore } from "./rootStore";

import { 
    deleteAwards,
    generateAwardsTableRows
} from '../services'



interface AwardsStoreInitialStateInterface {
    awards: AwardInterface[];
    newItem: AwardInterface;
    editItem: AwardInterface;
    selectedRows: AwardsType[];
    awardAddNewFormIsActive: boolean;
    awardEditFormIsActive: boolean;
    awardDeleteFormIsActive: boolean;
    awardEditContributorFormIsActive: boolean;
}

interface InitializeInterface {
    handleChange: (data: any) => void;
    data: AwardInterface[];
}

const awardsStoreInitialState: AwardsStoreInitialStateInterface = {
    awardAddNewFormIsActive: false,
    awardEditFormIsActive: false,
    awardDeleteFormIsActive: false,
    awardEditContributorFormIsActive: false,
    newItem: null,
    editItem: null,
    selectedRows: [],
    awards: [],
};

export class AwardsStore {
    awardAddNewFormIsActive: boolean;
    awardEditFormIsActive: boolean;
    awardDeleteFormIsActive: boolean;
    awardEditContributorFormIsActive: boolean;
    tableData: AwardsDataTablePayload[];
    newItem: AwardInterface;
    editItem: AwardInterface;
    selectedRows: AwardsType[];
    rootStore: RootStore;
    handleChange: (data: any) => void;
    awards: AwardInterface[];

    constructor(rootStore: RootStore) {
        makeObservable(this, {
            tableData: observable,
            setAwards: action,
            setAwardsTableData: action,
            refreshTableData: action,
            setNewItem: action,
            setEditItem: action,
            setAwardAddNewFormIsActive: action,
            setAwardEditFormIsActive: action,
            setAwardDeleteFormIsActive: action,
            setAwardEditContributorFormIsActive: action,
            setSelectedRows: action,

            handleNewItemClick: action,
            handleNewItemFieldUpdate: action,
            handleEditItemClick: action,
            handleEditItemFieldUpdate: action,
            handleOrderItems: action,
            handleSaveNewItem: action,
            handleSaveEditItem: action,
            handleDeleteItems: action,
            handleCancel: action,
        });

        this.rootStore = rootStore;

        extendObservable(this, awardsStoreInitialState);
    }

    initialize({ handleChange, data }: InitializeInterface): void {
        this.handleChange = handleChange;
        this.setAwards(transformAwardsPayload(data), false);
        this.refreshTableData();
    }

    isDefaultScript(): boolean {
        return this.rootStore.scriptValue === this.rootStore.defaultScriptValue;
    }

    setAwards(payload: AwardInterface[], withSave: boolean = true): void {
        this.awards = payload;

        if (withSave) {
            this.handleChange(this.awards);
        }

        this.setAwardsTableData();
    }

    setAwardsTableData(): void {
        this.tableData = generateAwardsTableRows(this.awards) as AwardsDataTablePayload[] || [];
    }

    refreshTableData(): void {
        this.setAwardsTableData();
    }

    setNewItem(item: AwardInterface): void {
        this.newItem = item;
    }

    setEditItem(item: AwardInterface): void {
        this.editItem = item;
    }

    setAwardAddNewFormIsActive(awardAddNewFormIsActive: boolean): void {
        this.awardAddNewFormIsActive = awardAddNewFormIsActive;
    }

    setAwardEditFormIsActive(awardEditFormIsActive: boolean): void {
        this.awardEditFormIsActive = awardEditFormIsActive;
    }

    setAwardDeleteFormIsActive(awardDeleteFormIsActive: boolean): void {
        this.awardDeleteFormIsActive = awardDeleteFormIsActive;
    }

    setAwardEditContributorFormIsActive(
        awardEditContributorFormIsActive: boolean,
    ): void {
        this.awardEditContributorFormIsActive =
            awardEditContributorFormIsActive;
    }

    setSelectedRows(selectedRows: AwardsType[]): void {
        let updatedSelectedRows = [...selectedRows];
        this.selectedRows = updatedSelectedRows;
    }

    handleNewItemFieldUpdate(
        key: string,
        value: any,
        scripts: ScriptInterface[],
    ): void {
        let isDefaultScript = this.isDefaultScript();
        isDefaultScript = true;

        if (isDefaultScript) {
            if (typeof value == "string") {
                this.newItem[key] = value;
            }

            if (typeof value == "number") {
                this.newItem[key] = value;
            }

            if (value && typeof value == "object" && !Array.isArray(value)) {
                this.newItem[key] = {
                    ...this.newItem[key],
                    value: value.name || value.text || value.value,
                    scripts: value.scripts || value.script_names || [],
                };
            }

            if (value && Array.isArray(value)) {
                const setArr = value.map((item) => ({ value: item, scripts }));
                this.newItem[key] = setArr;
            }
        }

        if (!isDefaultScript) {
            if (!Array.isArray(this.newItem[key].scripts)) {
                this.newItem[key].scripts = [];
            }

            const scriptIndex = _.findIndex(
                this.newItem[key].scripts,
                function (item: any) {
                    return item.code == scripts;
                },
            );

            if (scriptIndex !== -1) {
                this.newItem = update(this.newItem, {
                    [key]: {
                        scripts: {
                            [scriptIndex]: {
                                value: {
                                    $set: value,
                                },
                            },
                        },
                    },
                });
            } else {
                this.newItem = update(this.newItem, {
                    [key]: {
                        scripts: {
                            $push: [
                                {
                                    code: scripts,
                                    value: value,
                                },
                            ],
                        },
                    },
                });
            }
        }

        this.setNewItem(this.newItem);
    }

    handleEditItemFieldUpdate(key: string, value: any, scripts: any): void {
        const isDefaultScript = this.isDefaultScript();

        if (isDefaultScript) {
            if (typeof value == "string") {
                this.editItem[key] = value;
            }

            if (typeof value == "number") {
                this.editItem[key] = value;
            }

            if (value && typeof value == "object" && !Array.isArray(value)) {
                this.editItem[key] = {
                    ...this.editItem[key],
                    value: value.name || value.text || value.value,
                    scripts: value.scripts || value.script_names,
                };
            }

            if (value && Array.isArray(value)) {
                const setArr = value.map((item) => ({ value: item, scripts }));
                this.editItem[key] = setArr;
            }
        }

        if (!isDefaultScript) {
            if (!Array.isArray(this.editItem[key].scripts)) {
                this.editItem[key].scripts = [];
            }

            const scriptIndex = _.findIndex(
                this.editItem[key].scripts,
                function (item: any) {
                    return item.code == scripts;
                },
            );

            if (scriptIndex !== -1) {
                this.editItem = update(this.editItem, {
                    [key]: {
                        scripts: {
                            [scriptIndex]: {
                                value: {
                                    $set: value,
                                },
                            },
                        },
                    },
                });
            } else {
                this.editItem = update(this.editItem, {
                    [key]: {
                        scripts: {
                            $push: [
                                {
                                    code: scripts,
                                    value: value,
                                },
                            ],
                        },
                    },
                });
            }
        }

        this.setEditItem(this.editItem);
    }

    handleDeleteItems(selectedRows: any[]): void {
        let updatedState = deleteAwards(selectedRows, this.awards);
        let newOrder = 0;

        updatedState.forEach((award) => {
            if (award.deleted) {
                return;
            }
            
            award.order = newOrder;
            newOrder++;
        });

        this.setAwards(updatedState);
        this.refreshTableData();
        this.setAwardDeleteFormIsActive(false)
    }

    handleOrderItems(movedData: MovedDataInterface): void {
        let updatedState = update(this.awards, {
            [movedData.oldIndex]: {
                order: {
                    $set: movedData.newIndex,
                },
            },
            [movedData.newIndex]: {
                order: {
                    $set: movedData.oldIndex,
                },
            },
        });

        updatedState = arrayMove(
            updatedState,
            movedData.oldIndex,
            movedData.newIndex,
        );

        this.setAwards(updatedState);
    }

    handleEditItemClick(props: any): void {
        const editItem: AwardInterface = {
            award_body: {
                value: props.awardsBody,
                scripts: [],
            },
            result: {
                value: props.results,
                scripts: [],
            },
            year: props.year,
            category: {
                value: props.category,
                scripts: [],
            },
            contributors: props.contributor.map((contributor) => ({
                value: contributor,
                scripts: [],
            })),
            order: props.id,
        };

        this.setEditItem(editItem);
        this.setAwardEditFormIsActive(true);
    }

    handleNewItemClick(): void {
        this.setAwardAddNewFormIsActive(true);

        const maxId = Math.max(...this.awards.map((item) => item.order as number));

        const newItem: AwardInterface = {
            award_body: {
                value: "",
                scripts: [],
            },
            result: {
                value: "",
                scripts: [],
            },
            year: 0,
            category: {
                value: "",
                scripts: [],
            },
            contributors: [],
            order: this.awards.length ? maxId + 1 : 0,
        };

        this.setNewItem(newItem);
    }

    handleSaveNewItem() {
        let updatedState = [...this.awards, this.newItem];
        this.setAwards(updatedState);
        this.setNewItem(null);
        this.setAwardAddNewFormIsActive(false);
    }

    handleSaveEditItem() {
        let updatedState = [...this.awards];

        const index = updatedState.findIndex(
            (item) => item.order === this.editItem.order,
        );

        updatedState[index] = this.editItem;

        this.setAwards(updatedState);

        this.setAwardEditFormIsActive(false);
    }

    handleCancel() {
        this.setNewItem(null);
        this.setEditItem(null);
    }

    get getAwards(): AwardInterface[] {
        return this.awards;
    }

    get getTableData(): AwardsDataTablePayload[] {
        return this.tableData;
    }
}
