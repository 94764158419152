import deepcopy from "deepcopy";
import validateString from "../Helpers/validateString";
import _ from "lodash";

export default function ValidateProperties(result = {}, options = {}) {
    let response = deepcopy(result);

    const { data = {}, copy = {}, status } = options;

    const { properties = {} } = data;

    if (!_.isEmpty(properties.play_notes)) {
        if (!validateString(properties.play_notes, 180)) {
            response.result = false;
            response.notification = {
                title: copy.pleaseCheckPlayNotes || "Please check play notes",
                description:
                    copy.playNotesMustNotBeMoreThan100Characters ||
                    "Play notes must not be more than 180 characters",
                status,
            };

            response.validationKeys = result.validationKeys.concat([
                "PlayNotes",
            ]);
        }
    }

    return response;
}
