import React, { useState } from "react";
import Select from "../../../components/Select";
import CategoryTags from "../../../components/CategoryTags";
import _ from "lodash";
import GetCollectionsAvailableList from "../../Collections/src/GetCollectionsAvailableTypeList";

const HandleSelectCollectionCategory = (props: any) => {
    const { 
        state = {} 
    } = props;

    const {
        cmsData = {},
        userPermissions = []
    } = state

    const { set, type, categoriesList, handleNewCollection } = state;

    let activeCategoriesList = GetCollectionsAvailableList(
        type,
        categoriesList,
        cmsData,
        userPermissions
    );

    if (_.isEmpty(activeCategoriesList) || activeCategoriesList.length == 1) {
        handleNewCollection();
        return;
    }

    const categoriesListTransformed = activeCategoriesList.map(
        (category: string): { text: string; value: string } => {
            return {
                text: CategoryTags[category.replaceAll(/\s/g, "")].long,
                value: category,
            };
        },
    );

    let selectedCategory: string | undefined;

    const renderNotificationHtml = () => {
        return (
            <Select
                name="selectCategory"
                id="selectCategory"
                label="Category"
                value={categoriesList[0]}
                handleChange={(category: string) =>
                    (selectedCategory = category)
                }
                disabledText="Select Category"
                data={categoriesListTransformed}
                wrapperStyles={{
                    marginTop: "1.125rem",
                }}
                showNullOption={false}
            />
        );
    };

    set({
        notification: {
            title: "Create New Collection",
            html: renderNotificationHtml(),
            confirm: () => {
                handleNewCollection(selectedCategory);
            },
            disabled: false,
            background: "white",
            loading: false,
            status: "alert",
        },
    });
};

export default HandleSelectCollectionCategory;
