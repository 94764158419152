import { RecordInterface } from "./model";
import { OriginationInterface } from "../Origination/interfaces";
import dayjs from "dayjs";

dayjs.extend(require("dayjs/plugin/duration"));

export interface DurationInterface {
    hours: string;
    minutes: string;
    seconds: string;
    value: string;
}

function getOrigination(record: RecordInterface): OriginationInterface | any {
    return record.origination || {};
}

export function getReleaseYear(record: RecordInterface): number | false {
    const origination: OriginationInterface | any = getOrigination(record);
    return origination.first_release_year || false;
}

export function getDuration(record: RecordInterface): DurationInterface | any {
    const origination: OriginationInterface | any = getOrigination(record);
    const release_duration: number | null = origination.release_duration;
    return getDurationObject(release_duration);
}

export function getDurationArray(duration: DurationInterface): string[] {
    let durationArray = [];
    if (duration.hours && duration.hours !== "0")
        durationArray.push(`${duration.hours}h`);
    if (duration.minutes) durationArray.push(`${duration.minutes}m`);

    return durationArray;
}

export function getDurationObject(
    release_duration: number,
): DurationInterface | any {
    let duration = dayjs.duration(release_duration, 'seconds');

    if (duration) {
        if (typeof duration.$d == "object") {
            if (duration.$ms > 0) {
                let data = duration.$d;
                const seconds = formatTime(data.seconds);
                const minutes = formatTime(data.minutes);
                const hours = (data.days * 24 + data.hours).toString()

                return {
                    hours: hours,
                    minutes,
                    seconds,
                    value: `${hours}:${minutes}:${seconds}`,
                };
            }
        }
    }

    return {};
}

export function getListOfBrandHubValues(recordOrigination: OriginationInterface) {
    return recordOrigination.brand_hub.map((item) => item?.value);
}

function formatTime(time) {
    return ("0" + time).slice(-2);
}
