import React from "react";
import Component from "./index";

const NoneEntered = (props) => {
    return (
        <Component
            title={props.title || "None Entered"}
            weighting={false}
            background="#F2F2F2"
            color="#6D6D6D"
            margin={props.margin || "0.71875rem 0 0"}
            cursor="default"
        />
    );
};

export default NoneEntered;
