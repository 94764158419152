import React from "react";
import "../_styles.scss";
import { headerComponents } from "./headerComponents";
import { ActionIconContainer } from "../../../components/ActionIcon";
import _ from "lodash";

interface BuilderContainerHeaderInterface {
    components: Array<string>;
    itemProps: any;
}

const BuilderContainerHeader = ({
    components,
    itemProps,
}: BuilderContainerHeaderInterface) => {
    const {
        DateLock,
        DisplayName,
        CustomSelect,
        ActiveInactiveTag,
        LicencePreview,
    } = headerComponents;

    if (_.isEmpty(components)) {
        return <></>;
    }

    return (
        <div className="item-header">
            {components.includes("DateLock") && <DateLock {...itemProps} />}

            {components.includes("DisplayName") && (
                <DisplayName {...itemProps} />
            )}

            <div className="right-content">
                {components.includes("LicencePreview") && (
                    <LicencePreview {...itemProps} />
                )}

                {components.includes("ActiveInactiveTag") && (
                    <ActiveInactiveTag {...itemProps} />
                )}

                {components.includes("CustomSelect") && (
                    <ActionIconContainer>
                        <CustomSelect chevronImage={false} {...itemProps} />
                    </ActionIconContainer>
                )}
            </div>
        </div>
    );
};

export default BuilderContainerHeader;
