import isEmpty from "lodash/isEmpty";
export default function validateContributorForm(
    isActive: boolean,
    field: string,
    value: string,
) {
    let valid: boolean = true
    let message: string = ""

    if (!isActive) return {
        [field]: {
            valid,
            message
        }
    }

    switch (field) {
        case "first_name":
        case "last_name":
            if (isEmpty(value)) {
                valid = false
                message = `Contributor's ${field === "first_name" ? "First Name" : "Last Name"} is required.`
            }
            break;
    }

    return {
        [field]: {
            valid,
            message
        }
    }
}