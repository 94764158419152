import React, { Component } from "react";
import Settings from "../Settings";

const TagSimple = (props) => {
    const { text, background, color, className, styles = {} } = props;

    let style = {
        ...Settings.components.actionButton.container,
        ...Settings.components.actionButton.text,
        background,
        color,
        display: "flex",
        alignItems: "center",
        height: "auto",
        minHeight: "1.465rem",
        ...styles,
    };

    return (
        <div style={style} className={className ? className : ""}>
            {text}
        </div>
    );
};

export default TagSimple;
