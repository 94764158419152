import React from "react";
import { SectionInterface } from "./interface";
import Videos from "../../views/Videos";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";

const SectionVideos = (props: SectionInterface) => {
    const {
        attributes = {},
        cmsData = {},
        data = {},
        index,
        disabled,
        api_token,
    } = props;

    return (
        <Videos
            name="videos"
            className="meta-record-videos"
            title="Videos"
            data={data.videos || []}
            disabled={disabled}
            lastSection={true}
            meta_id={data.meta_id}
            recordData={data}
            api_token={api_token}
            recordVideosUrl={attributes.recordVideosUrl}
            cmsData={cmsData}
            key={index}
        />
    );
};

export default SectionVideos;
