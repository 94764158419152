import React from "react";
import Component from "./AutocompleteMultipleOneLineInput";
import ClientManagedComponent from "../../ClientManagedOptions/Component";
import { ListItem } from "./ContentField.interface";

interface Props {
    id: string;
    dataList: Array<ListItem>;
    value: Array<ListItem> | ListItem;
    placeholder?: string;
    label?: string;
    clientManagedList?: string;
    multiple?: boolean;
    disabled: boolean;
    handleChange: (value: ListItem | Array<ListItem>) => {};
}

const ClientManagedAutoCompleteMultipleOneLineInput: React.FC<Props> = (
    props,
) => {
    return (
        <ClientManagedComponent
            {...props}
            Component={Component}
            onSearchKey="handleChange"
            dataKey="dataList"
        />
    );
};

export default ClientManagedAutoCompleteMultipleOneLineInput;
