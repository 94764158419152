import React, { useState } from "react";
import { StyledPagination } from "./StyledPagination";
import { Stack } from "@mui/material";
import _ from "lodash";
import "./_styles.scss";

interface PaginationInterface {
    pageSize: number;
    length: number;
    page: number;
    handleChange: (page: number) => void;
}

const Pagination = ({
    pageSize,
    length,
    page,
    handleChange,
}: PaginationInterface) => {
    const startIndex: number = 0;
    const count: number = Math.ceil(length / pageSize);

    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ position: "relative" }}
        >
            <div className="counter">
                Displaying {page} -{" "}
                {startIndex + pageSize > length
                    ? length
                    : startIndex + pageSize}{" "}
                of {length}
            </div>

            <StyledPagination
                page={page}
                onChange={(event: React.ChangeEvent<unknown>, page: number) =>
                    handleChange(page)
                }
                count={count}
                variant="outlined"
                shape="rounded"
                showFirstButton
                showLastButton
            />
        </Stack>
    );
};

export default Pagination;
