import React, { Fragment } from "react";
import _ from "lodash";
import TitleValue from "../../TitleValue";
import Settings from "../../Settings";
import AutocompleteInput from "../../AutocompleteInput";
import LabelDynamic from "../../KeywordLabel/Dynamic";
import KeywordLabel from "../../KeywordLabel";
import BubbleList from "../../BubbleList/simpleOnEnter";
import update from "react-addons-update";
import deepcopy from "deepcopy";
import MapCertificateRegionLists from "./helpers/MapCertificateRegionLists";
import MapBubbleDataToArrayOfStrings from "./helpers/MapBubbleDataToArrayOfStrings";
import ClientManagedCountries from "../../ClientManagedOptions/countries";
import ClientManagedSelect from "../../Select/ClientManaged";
import { isClientManagedTerritories } from "../../ClientManagedOptions/territories";
import { cmsData } from "../../../features/Credits/v4/test-react/testData";
import { envTest } from "../../Environment";

import {
    CLIENT_MANAGED_ASSET_CERTIFICATIONS,
    ASSET_CERTIFICATIONS,
    ASSET_COMPLIANCES,
    CLIENT_MANAGED_TERRITORIES
} from "../../ClientManagedOptions/options";

import fetchClientManagedOptions from "../../ClientManagedOptions/fetchClientManagedOptions";
import { clientManagedRegionalCertifications } from "../test/payload";

export default class AssetInformationCertificatesAdvisory extends React.Component {
    constructor(props) {
        super(props);

        const { certifications = [], territory = "" } = this.props;

        this.state = {
            certifications: certifications,
            territory: territory,
            certificate: {},
            editCertificateIndex: null,
            regionRatingRelatedLists: {},
            regionRatingRelatedSelectionOrigin: {
                advisory: [],
                schedule_restrictions: [],
            },
            regionOptions: this.getRegionInitialOptions(),
            fetchingOptions: true
        };

        !envTest && this.handleFetchOptions()
    }

    getRegionInitialOptions() {
        if (envTest) {
            const { options = {}, cmsData = {} } = this.props;

            const { managedLists = [] } = cmsData?.clientFeatures || {}

            if (!managedLists.includes(ASSET_CERTIFICATIONS)) {
                return _.get(options, "certifications.internal_systems.regions", [])
            } else {
                return clientManagedRegionalCertifications
            }
        }

        return {}
    }

    async handleFetchOptions() {
        const regionOptions = await this.getRegionOptions();

        this.setState({ 
            regionOptions,
            fetchingOptions: false
        });
    }

    handleUpdate(props) {
        const { handleUpdate = () => {} } = this.props;

        const { certifications, territory } = props;

        if (territory) {
            handleUpdate({ certifications, territory });

            return;
        }

        handleUpdate({ certifications });
    }

    handleTerritoryUpdate(territory) {
        const { certifications } = this.state;

        this.setState(
            {
                territory,
            },
            () => {
                this.handleUpdate({ certifications, territory });
            },
        );
    }

    handleUpdateCertificateData(key, value) {
        const { certificate = {} } = this.state;

        let updatedState = deepcopy(certificate);

        if (key == "certificate") {
            this.compareCertificateChanges(certificate, value);
            updatedState.rating = value.rating || null;
            updatedState.country_code = value.country_code;
            updatedState.system = value.system || "Rating";
        } else if (key == "advisory" || key == "schedule_restrictions") {
            updatedState[key] = MapBubbleDataToArrayOfStrings({ data: value });
        } else {
            updatedState[key] = value;
        }

        this.setState(
            {
                doesCertificateAbleToSave:
                    this.doesCertAbleToSave(updatedState),
                certificate: updatedState,
            },
            () => {
                this.updateRegionRatingRelatedSelectionOrigin({
                    key,
                    certificate: updatedState,
                });
            },
        );
    }

    updateRegionRatingRelatedSelectionOrigin({ key, certificate }) {
        const { regionRatingRelatedSelectionOrigin = {} } = this.state;

        const { advisory = [], schedule_restrictions = [] } =
            regionRatingRelatedSelectionOrigin;

        let state = { advisory, schedule_restrictions };

        if (key == "advisory") state.advisory = certificate[key] || [];
        if (key == "schedule_restrictions")
            state.schedule_restrictions = certificate[key] || [];

        this.setState({ regionRatingRelatedSelectionOrigin: state }, () => {
            this.updateRegionRatingRelatedList({
                countryCode: certificate.country_code,
                system: certificate.system,
            });
        });
    }

    compareCertificateChanges(certificate, newValue) {
        if (
            certificate.system != newValue.system &&
            certificate.country_code != newValue.country_code &&
            Number.isInteger(this.getEditCertificateIndex())
        ) {
            this.handleDeleteCertificate(this.getEditCertificateIndex());
            this.setEditCertificateIndex();
        }
    }

    handleEditCertificate(data) {
        const { item = {}, index } = data;

        const { country_code = "", system = "" } = item;

        this.setState(
            {
                certificate: item,
            },
            () => {
                this.updateRegionRatingRelatedList({
                    system,
                    countryCode: country_code,
                });
                this.setEditCertificateIndex(index);
            },
        );
    }

    getEditCertificateIndex() {
        return Number.isInteger(this.state.editCertificateIndex)
            ? this.state.editCertificateIndex
            : null;
    }

    updateRegionRatingRelatedList({ countryCode = "", system = "" }) {
        const { certificate = {}, regionRatingRelatedLists, regionOptions } = this.state;

        let regionRatingRelatedListsCopy = deepcopy(regionRatingRelatedLists);

        regionRatingRelatedListsCopy.advisory =
            MapCertificateRegionLists({
                regionOptions,
                countryCode,
                system,
                key: "advisories",
            }) || [];
        regionRatingRelatedListsCopy.schedule_restrictions =
            MapCertificateRegionLists({
                regionOptions,
                countryCode,
                system,
                key: "schedule_restrictions",
            }) || [];

        this.setState({
            certificate: this.updateCertificateOnActualList({
                certificate,
                actualList: regionRatingRelatedListsCopy,
            }),
            regionRatingRelatedLists: regionRatingRelatedListsCopy,
        });
    }

    updateCertificateOnActualList({ certificate = {}, actualList = [] }) {
        const { regionRatingRelatedSelectionOrigin: originLists } = this.state;

        let certificateData = deepcopy(certificate);

        const mapLists = ["advisory", "schedule_restrictions"];
        mapLists.map((key) => {
            if (
                Array.isArray(originLists[key]) &&
                !_.isEmpty(originLists[key]) &&
                Array.isArray(actualList[key])
            ) {
                certificateData[key] = originLists[key].filter((item) =>
                    actualList[key].includes(item),
                );
            }
        });

        return certificateData;
    }

    handleDeleteCertificate(index) {
        const { certifications, territory } = this.state;

        let updatedCertificates = deepcopy(certifications);
        updatedCertificates = update(updatedCertificates, {
            [index]: {
                deleted: {
                    $set: true,
                },
            },
        });

        this.setState(
            {
                certifications: updatedCertificates,
            },
            () => {
                this.handleUpdate({
                    certifications: updatedCertificates,
                    territory,
                });
            },
        );
    }

    handleAddUpdateCertificate() {
        const { certifications, certificate, territory } = this.state;

        let updatedState = deepcopy(certifications);
        let certificateCopy = deepcopy(certificate);
        certificateCopy =
            this.transformOutgoingData(certificateCopy) || certificateCopy;

        // edit
        if (Number.isInteger(this.getEditCertificateIndex())) {
            updatedState[this.getEditCertificateIndex()] = certificateCopy;
        } else {
            updatedState = update(certifications, { $push: [certificateCopy] });
        }

        this.setState(
            {
                certificate: {},
                regionRatingRelatedSelectionOrigin: {
                    advisory: [],
                    schedule_restrictions: [],
                },
                certifications: updatedState,
                doesCertificateAbleToSave: false,
            },
            () => {
                this.setEditCertificateIndex();
                this.handleUpdate({
                    certifications: updatedState,
                    territory,
                });
            },
        );
    }

    doesCertAbleToSave(editedCertificate) {
        const { certificate } = this.state;

        let result = false;

        if (
            !_.isEmpty(editedCertificate.system) &&
            !_.isEmpty(editedCertificate.country_code) &&
            !_.isEmpty(editedCertificate.rating) &&
            (certificate.rating != editedCertificate.rating ||
                certificate.system != editedCertificate.system ||
                certificate.country_code != editedCertificate.country_code ||
                certificate.advisory != editedCertificate.advisory ||
                certificate.schedule_restrictions !=
                    editedCertificate.schedule_restrictions)
        ) {
            result = true;
        }

        return result;
    }

    transformOutgoingData(certificate) {
        return certificate;
    }

    getCountryFromCode(countryCode) {
        const { options = {}, clientFeatures } = this.props;

        const { countries = [] } = options;

        const countriesList = ClientManagedCountries({
            options,
            clientFeatures,
            fallback: countries,
        });

        return (
            countriesList.find(
                (item) =>
                    _.toLower(item.value) == _.toLower(countryCode) ||
                    _.toLower(item.text) == _.toLower(countryCode),
            ) || {}
        );
    }

    getCertifications() {
        const { options } = this.props;

        const { certifications, regionOptions } = this.state;

        const { countries = [] } = options;

        let staticDatalist = [];

        if (typeof regionOptions == "object" && regionOptions) {
            staticDatalist = Object.keys(regionOptions)
                .map((region) => {
                    const regionSystems = { ...(regionOptions[region] || {}) };
                    const country =
                        countries?.find((item) => item.text == region) || {};

                    if (_.isEmpty(country.value)) {
                        if (
                            _.isEmpty(
                                Object.values(regionSystems)[0].region_code,
                            )
                        )
                            return null;
                    }

                    const value =
                        country.value ||
                        Object.values(regionSystems)[0].region_code;

                    let options = {};
                    Object.keys(regionSystems).forEach((system) => {
                        const regionRatings =
                            regionSystems[system].ratings || [];
                        const certWithRegionRating = certifications.find(
                            (cert) =>
                                cert.country_code === value &&
                                cert.system === system &&
                                regionRatings.includes(cert.rating) &&
                                !cert.deleted,
                        );
                        if (
                            !certWithRegionRating ||
                            (certWithRegionRating &&
                                certifications.indexOf(certWithRegionRating) ===
                                    this.getEditCertificateIndex())
                        ) {
                            options[system] = regionRatings;
                        }
                    });

                    if (Object.keys(options).length === 0) {
                        return null;
                    }

                    return {
                        text: region,
                        value,
                        options,
                    };
                })
                .filter((data) => data);
        }

        return staticDatalist;
    }

    async getRegionOptions() {
        const { options = {}, cmsData = {} } = this.props;

        return await new Promise(function(resolve) {
            fetchClientManagedOptions({
                clientManagedType: CLIENT_MANAGED_ASSET_CERTIFICATIONS,
                apiUrls: cmsData.apiUrls || {},
                clientFeatures: cmsData.clientFeatures || {},
                list: ASSET_CERTIFICATIONS,
                onResponse: (response) => resolve(response),
                onError: () => resolve({})
            });
        });
    }

    setEditCertificateIndex(value = null) {
        this.setState({ editCertificateIndex: value });
    }

    renderTitleValue(props) {
        const { title = "", value, styles = {} } = props;

        return (
            <TitleValue
                title={title}
                value={value}
                titleStyles={Settings.components.label}
                valueStyle={Settings.components.input.disabled}
                style={styles}
            />
        );
    }

    renderCertification(props) {
        const { styles = {} } = props;

        const { certificate, fetchingOptions } = this.state;

        const { rating, system, country_code } = certificate;

        const displayInput = !certificate.rating || !certificate.country_code;
        const certificationCountry = this.getCountryFromCode(country_code);
        const certifications = this.getCertifications();

        return (
            <div className="meta-asset-certificate-edit-rating">
                {displayInput && (
                    <AutocompleteInput
                        label="Certification"
                        placeholder="Select rating"
                        value=""
                        dataListSegregationLines={true}
                        styles={styles}
                        handleListSelection={(selection) =>
                            this.handleUpdateCertificateData("certificate", {
                                rating: selection.value,
                                country_code: selection.parent,
                                system: selection.key,
                            })
                        }
                        className="certification"
                        openList={displayInput && !_.isEmpty(certificate)}
                        openOnClick={true}
                        createNew={false}
                        datalistMultiDimensional={true}
                        multiDimensionalSelectionPreset={{
                            value: certificate.rating || null,
                            parent: certificate.country_code || null,
                            key: certificate.system || "Rating",
                        }}
                        staticDatalist={certifications || []}
                        loading={fetchingOptions}
                    />
                )}

                {!displayInput && (
                    <LabelDynamic
                        items={[
                            {
                                title:
                                    certificationCountry.text || country_code,
                                styles: {
                                    background: "#dc143c",
                                    color: "white",
                                },
                            },
                            {
                                title:
                                    system && rating
                                        ? `${system} • ${rating}`
                                        : rating
                                        ? rating
                                        : "",
                                styles: {
                                    color: "white",
                                    maxWidth: "60%",
                                },
                            },
                        ]}
                        handleEdit={() =>
                            this.handleUpdateCertificateData("certificate", {
                                rating: null,
                                country_code: certificate.country_code || null,
                                system: certificate.system || null,
                                advisory: certificate.advisory || [],
                            })
                        }
                        styles={{
                            marginTop: "1.5rem",
                            background: "#cd5c5c",
                            display: "inline-flex",
                            maxWidth: "100%",
                        }}
                    />
                )}
            </div>
        );
    }

    renderScheduleRestrictions(props) {
        const { styles = {} } = props;

        const { certificate = {}, regionRatingRelatedLists = [] } = this.state;

        const { rating, schedule_restrictions = [] } = certificate;

        const disabled = !rating;
        return (
            <Fragment>
                {_.get(
                    this.props,
                    "clientFeatures.assets.certificateScheduleRestrictions",
                    false,
                ) && (
                    <BubbleList
                        label="Schedule Restrictions"
                        id="schedule_restrictions"
                        inputId="schedule_restrictions"
                        background="#ff8a00"
                        color="white"
                        deleteWhite={true}
                        emptyPlaceholder={
                            rating
                                ? "Start Typing Schedule Restrictions"
                                : "Select Schedule Restrictions"
                        }
                        data={schedule_restrictions}
                        handleFormChange={(items) =>
                            this.handleUpdateCertificateData(
                                "schedule_restrictions",
                                items,
                            )
                        }
                        datalist={
                            regionRatingRelatedLists.schedule_restrictions || []
                        }
                        dataListUseEnterButton={true}
                        filterStaticDataList={true}
                        openOnClick={true}
                        wrapperStyles={styles}
                        disabled={disabled}
                    />
                )}
            </Fragment>
        );
    }

    renderAdvisory(props) {
        const { styles = {} } = props;

        const { certificate = {}, regionRatingRelatedLists = {} } = this.state;

        const { rating, advisory = [] } = certificate;

        const disabled = !rating;
        return (
            <BubbleList
                label="Advisory"
                id="advisory"
                inputId="advisory"
                background="#0088b5"
                color="white"
                deleteWhite={true}
                emptyPlaceholder={
                    rating ? "Start Typing Advisories" : "Select rating"
                }
                data={advisory}
                handleFormChange={(items) =>
                    this.handleUpdateCertificateData("advisory", items)
                }
                datalist={regionRatingRelatedLists.advisory || []}
                dataListUseEnterButton={true}
                filterStaticDataList={true}
                openOnClick={true}
                wrapperStyles={styles}
                disabled={disabled}
            />
        );
    }

    renderAddUpdateCertificateBtn(props) {
        const { doesCertificateAbleToSave = false } = this.state;

        const {
            styles = {
                marginTop: "1rem",
                opacity: !doesCertificateAbleToSave ? 0.5 : 1,
                background: !doesCertificateAbleToSave
                    ? "#787878"
                    : "rgb(126, 246, 221)",
                color: !doesCertificateAbleToSave ? "white" : "black",
            },
            contentStyles = { display: "flex", alignItems: "center" },
        } = props;

        return (
            <KeywordLabel
                title={`${
                    Number.isInteger(this.getEditCertificateIndex())
                        ? "Update"
                        : "Add"
                } Certificate & Advisories`}
                background={
                    !doesCertificateAbleToSave
                        ? "#787878"
                        : "rgb(126, 246, 221)"
                }
                onClick={
                    !doesCertificateAbleToSave
                        ? () => {}
                        : () => this.handleAddUpdateCertificate()
                }
                disabled={!doesCertificateAbleToSave}
                leftIcon={
                    <img
                        src={`${Settings.images.path}${
                            doesCertificateAbleToSave
                                ? "/svg/plus-black.svg"
                                : "/svg/plus-white.svg"
                        }`}
                        style={{
                            width: "0.78125rem",
                            marginRight: "0.46875rem",
                        }}
                    />
                }
                contentStyles={contentStyles}
                styles={styles}
            />
        );
    }

    renderTerritories(props) {
        const { clientFeatures = {}, cmsData = {} } = this.props;

        const { assets = {} } = clientFeatures;

        const { territory: assetTerritory = false } = assets;

        if (!assetTerritory) return;

        const { styles = {} } = props;

        const { territory } = this.state;

        const { apiUrls = {} } = cmsData;

        return (
            <div style={styles}>
                <ClientManagedSelect
                    className="territory"
                    name="territory"
                    id="territory"
                    value={territory}
                    clear={true}
                    label={"Territory"}
                    noneSelectedText={"None Selected"}
                    disabledText={"Select territory"}
                    readOnly={false}
                    disabled={false}
                    handleChange={(territory) =>
                        this.handleTerritoryUpdate(territory)
                    }
                    clientManagedType={CLIENT_MANAGED_TERRITORIES}
                    clientFeatures={clientFeatures}
                    apiUrls={apiUrls}
                    transformListHook={(list) => list.map((item) => item.text)}
                    isClientManaged={isClientManagedTerritories({
                        clientFeatures,
                    })}
                />
            </div>
        );
    }

    render() {
        const { compliance } = this.props;

        const { certifications = [] } = this.state;

        const {
            system_name,
            asset_type,
            compliance_code,
            version_name,
            version_code,
            code,
        } = compliance;

        let typeValue = code;
        if (asset_type && compliance_code) {
            typeValue = `${compliance_code} • ${asset_type}`;
        } else if (version_name && version_code) {
            typeValue = `${version_code} • ${version_name}`;
        }

        const marginTop = { marginTop: "1.5rem" };
        const activeCertifications = certifications.map(
            (item) => !item.deleted,
        );

        return (
            <div className="meta-certifications-advisory-container asset-information">
                {this.renderTitleValue({
                    title: "Destination Platform",
                    value: system_name,
                    styles: marginTop,
                })}

                {this.renderTitleValue({
                    title: "Asset Type",
                    value: typeValue,
                    styles: marginTop,
                })}

                {/* {this.renderTerritories({styles: marginTop})} */}

                {this.renderCertification({ styles: marginTop })}

                {this.renderAdvisory({ styles: marginTop })}

                {this.renderScheduleRestrictions({ styles: marginTop })}

                {this.renderAddUpdateCertificateBtn({})}

                {!_.isEmpty(activeCertifications) && (
                    <div
                        style={{ marginTop: "1.5rem", marginBottom: "0.25rem" }}
                    >
                        <span style={Settings.components.label}>
                            Existing Certifications & Advisories
                        </span>

                        {certifications.map((item, index) => {
                            const {
                                rating,
                                system,
                                country_code,
                                advisory = [],
                                deleted = false,
                            } = item;

                            if (
                                deleted ||
                                index === this.getEditCertificateIndex()
                            ) {
                                return false;
                            }

                            const country =
                                this.getCountryFromCode(country_code);

                            const emptyAdvisory =
                                _.isEmpty(advisory) || !_.isArray(advisory);

                            return (
                                <LabelDynamic
                                    items={[
                                        {
                                            title: country.text || country_code,
                                            styles: {
                                                background: "#3c8360",
                                                color: "white",
                                            },
                                        },
                                        {
                                            title:
                                                system && rating
                                                    ? `${system} • ${rating}`
                                                    : rating
                                                    ? rating
                                                    : "",
                                            styles: {
                                                background: "#519774",
                                                color: "white",
                                                maxWidth: "35%",
                                            },
                                        },
                                        {
                                            title: emptyAdvisory
                                                ? "No Advisories Selected"
                                                : advisory.join(" • "),
                                            styles: {
                                                color: "white",
                                                flexShrink: 1,
                                                flexGrow: 1,
                                            },
                                        },
                                    ]}
                                    handleEdit={() =>
                                        this.handleEditCertificate({
                                            item,
                                            index,
                                        })
                                    }
                                    handleDelete={() =>
                                        this.handleDeleteCertificate(index)
                                    }
                                    key={index}
                                    styles={{
                                        marginTop: "0.375rem",
                                        background: "#75b294",
                                    }}
                                />
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }
}
