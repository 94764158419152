import React from "react";
import { useForm } from "react-hook-form";
import {
    CoreForm,
    CoreFormProvider,
    CoreFormThemeProvider,
} from "../../../core/Form/components/CoreForm";
import { CoreSelect } from "../../../core/Form/components/inputs/Select/components/CoreSelect";
import { CoreFormStack } from "../../../core/Form/components/layout/CoreForm.stack";
import { CoreSelectOptionType } from "../../../core/Form/components/inputs/Select/types/CoreSelectOptionType";
import { useEffect } from "react";
import ClientManagedScripts from "../../ClientManagedOptions/scripts";
import { useStore } from "../../Global/store/store";
import { Chip } from "@mui/material";
import { getDefaultWrittenLanguageCodeFromCache } from "../../../models/ClientManaged/languagesModel";

export type ScriptCoreSelectProps = {
    options: CoreSelectOptionType[];
    onChange: (script: string) => void;
    value: string;
    listKey: string;
};

type LanguageObject = {
    text: string;
    value: string;
};

export const ScriptCoreSelect = ({
    options,
    onChange,
    value,
    listKey,
}: ScriptCoreSelectProps) => {
    const [state] = useStore() || [{ cmsData: { clientFeatures: {} } }];
    const { cmsData = {} } = state;
    const { 
        clientFeatures = {}, 
        clientDataStructure = {} 
    } = cmsData;

    const fallBackScript: string = getDefaultWrittenLanguageCodeFromCache()

    const scriptOptions: LanguageObject[] = ClientManagedScripts({
        options,
        fallback: state.fallBackWrittenLanguages,
        clientFeatures,
        listKey,
    });

    const defaultScriptCheck: number = scriptOptions?.findIndex(
        (scriptOption) =>
            scriptOption.value === value
    )

    const form = useForm({
        defaultValues: {
            script: defaultScriptCheck !== -1 ? value : fallBackScript
        },
        mode: "onSubmit",
    });

    const coreSelectOptions = scriptOptions.map((script) => ({
        label: script.text,
        value: script.value,
        rightAdornment: (
            <Chip
                label={script.value}
                sx={{
                    bgcolor: "#e1e1e1",
                    padding: "0.25rem 0.375rem",
                    borderRadius: "0.125rem",
                    margin: "0 0 0 0.75rem",
                }}
            />
        ),
    }));

    useEffect(() => {
        if (value !== form?.watch("script")) {
            onChange(form?.watch("script"));
        }
    }, [form?.watch("script")]);

    return (
        <CoreFormThemeProvider>
            <CoreFormProvider {...form}>
                <CoreForm>
                    <CoreFormStack direction="row">
                        <CoreSelect
                            config={{
                                path: "script",
                                options: coreSelectOptions,
                                enableSearchbox: true,
                                multiple: false,
                                enableScrollToSelected: true,
                            }}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        boxShadow:
                                            "0.125rem 0.1875rem 0.3125rem rgba(79, 94, 116, 0.15)",
                                        border: "0.0625rem solid rgba(77, 77, 77, 0.2)",
                                        padding: "0.3125rem",
                                        overflow: "hidden",
                                    },
                                },
                                MenuListProps: {
                                    sx: {
                                        maxHeight: 315,
                                        paddingY: 0,
                                        "& .MuiPaper-root + div": {
                                            overflow: "scroll",
                                            maxHeight: "17.5rem",
                                        },
                                    },
                                },
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                sx: { transform: "translateY(0.3125rem)" },
                            }}
                            customStyles={{
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderColor:
                                        "rgba(77, 77, 77, 0.2) !important",
                                },
                                transform: "translateY(-0.3125rem)",
                                marginTop: "1rem",

                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor:
                                        "rgb(40, 108, 210, .75) !important",
                                },
                            }}
                        />
                    </CoreFormStack>
                </CoreForm>
            </CoreFormProvider>
        </CoreFormThemeProvider>
    );
};
