import { FRANCHISE } from "../../pages/Collections/src/CollectionType";
import { v4 as uuidv4 } from "uuid";

export const defaultFilters = [
    "TitleCollections",
    "Collection",
    "Franchise Collection",
    "all"
]

const CollectionsDefaultstate = {
    searchData: [],
    searchQuery: "",
    displaySearchBoxFilters: false,
    fetchingSearchData: true,
    editRecord: false,
    newRecord: false,
    loadingId: false,
    savingRecord: false,
    validated: false,
    unsavedChanges: false,
    notification: {},
    recordData: {},
    originalRecord: {},
    originalNewRecord: {},
    options: {},
    validationKeys: [],
    cmsData: {},
    activeSearchFilters: defaultFilters,
    collectionTitle: {},
    recordChangesUuid: uuidv4()
};

export default CollectionsDefaultstate;
