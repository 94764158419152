import React from "react";
import { TaxonomyStore } from "./taxonomyStore";

export class RootStore {
    taxonomyStore: TaxonomyStore;

    constructor() {
        this.taxonomyStore = new TaxonomyStore(this);
    }
}

const StoresContext = React.createContext(new RootStore());
export const useStores = () => React.useContext(StoresContext);
