import React from "react";
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AppsIcon from '@mui/icons-material/Apps';
import { viewTable, viewGrid } from "../index"

interface ViewSwitchIconsInterface {
    view: "table" | "grid",
    handleSetView: Function
}

const ViewSwitchIcons = (props: ViewSwitchIconsInterface) => {
    const {
        view,
        handleSetView = () => {}
    } = props

    return (
        <ButtonGroup
            disableElevation
            size="small"
            aria-label="vertical outlined button group"
            className="view-switch-icons"
            variant="contained">

            {/* selectedTable */}
            {view == viewTable &&
                <>
                    <Button 
                        size="small"
                        variant="contained">
                        <IconButton style={{ padding: "0" }}>
                            <FormatListBulletedIcon 
                                fontSize="small"
                                style={{ color: "white" }}
                            />
                        </IconButton>
                    </Button>

                    <Button 
                        size="small" 
                        color="primary"
                        variant="outlined"
                        onClick={() => handleSetView(viewGrid)}>

                        <IconButton color="primary" style={{ padding: "0" }}>
                            <AppsIcon 
                                fontSize="small"
                            />
                        </IconButton>
                    </Button>
                </>
            }

            {/* selectedGrid */}
            {view == viewGrid &&
                <>
                    <Button 
                        size="small" 
                        color="primary"
                        variant="outlined"
                        style={{ padding: "0.3rem", minWidth: "initial" }}
                        onClick={() => handleSetView(viewTable)}>

                        <IconButton color="primary" style={{ padding: "0" }}>
                            <FormatListBulletedIcon 
                                fontSize="small"
                            />
                        </IconButton>
                    </Button>

                    <Button 
                        size="small" 
                        style={{ color: "white", padding: "0.3rem", minWidth: "initial" }}
                        variant="contained">

                        <IconButton style={{ padding: "0" }}>
                            <AppsIcon 
                                fontSize="small"
                                style={{ color: "white" }}
                            />
                        </IconButton>
                    </Button>
                </>
            }
        </ButtonGroup>
    )
}

export default ViewSwitchIcons