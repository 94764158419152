import _ from "lodash";
import { getSession, connect, buildTopic } from "../../components/IoT";
import GetRecordData from "./GetRecordData";

const HandleRecordSave = async (props) => {
    const { options = {}, state = {} } = props;

    const {
        newRecord,
        validated,
        validationNotification,
        recordChanges = {},
        handleCreateNewRecordRequest = () => {},
        handleUpdateRecordRequest = () => {},
        set,
    } = state;

    const { saveAndContinue } = options;

    // if validation errors
    if (!_.isEmpty(validationNotification) || !validated) {
        set({
            notification: {
                ...validationNotification,
                onClick: () => {
                    set({
                        notification: {},
                    });
                },
            },
        });

        return;
    }

    set({
        savingRecord: true,
        loadingId: saveAndContinue ? "saveAndContinue" : "save",
    });

    const record = GetRecordData({ state });

    // New record
    if (!_.isEmpty(newRecord) && validated) {
        handleCreateNewRecordRequest();
    }

    // Update
    if (_.isEmpty(newRecord) && !_.isEmpty(recordChanges) && validated) {
        handleUpdateRecordRequest({
            saveAndContinue,
            IoTData: record,
        });
    }
};

export default HandleRecordSave;
