import React from "react";
import _ from "lodash";
import Ids from "./Ids";
import IDsFilterOnConfig from "../src/IDsFilterOnConfig";
import "../styles/_ids.scss";
import getIdHref from "../../Links/utils/getIdHref";
import CopyTooltip from "../../CopyTooltip";
import Label from "../../Input/label";
import Tooltip from "@mui/material/Tooltip";
import { characterLimit } from "../src/IDsCharacterLimit";
import AuditTooltip from "../../AuditTooltip";
import Input from "../../Input/basic";
import isIndexInArray from "../../Helpers/isIndexInArray";
import VerificationStatus from "./components/VerificationStatus";

export default class IDsV2 extends Ids {
    getDataStructure() {
        const { dataStructure = {} } = this.props;

        return dataStructure;
    }

    renderInputStructure(item) {
        const { verifiedFields = {}, handleVerifiedFieldsChange = () => {} } =
            this.props;

        let verifiedField = { name: item.key };

        if (!_.isEmpty(verifiedFields[item.key]))
            verifiedField = { ...verifiedFields[item.key] };

        verifiedField.selectedStatus = _.isEmpty(verifiedFields.fields)
            ? null
            : verifiedFields.fields[item.key];

        return this.renderInput({
            label: item.title,
            placeholder: `Enter ${item.title}${
                item.title.toUpperCase().endsWith(" ID") ? "" : " ID"
            }`,
            name: item.key,
            readOnly: item.readOnly || false,
            enableVerificationStatus: item.enableVerificationStatus || false,
            verifiedField: verifiedField,
            handleVerifiedFieldsChange: handleVerifiedFieldsChange,
        });
    }

    renderClientIds() {
        const { client = [] } = this.getDataStructure();

        const { category = false } = this.props;

        if (_.isEmpty(client)) {
            return false;
        }

        return (
            <div className="meta-client-ids">
                {this.renderTitle("Client IDs")}

                <div
                    style={this.styles.flexMarginContainerNarrow}
                    className="meta-client-ids--container"
                >
                    {client.map((item, index) => {
                        let showItem = IDsFilterOnConfig({
                            category,
                            destination: "client",
                            key: item.key,
                        });

                        return (
                            <div
                                key={index}
                                style={this.styles.flexMarginItemQuoterNarrow}
                            >
                                {showItem && this.renderInputStructure(item)}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    renderSupplyChainIds() {
        const { supply_chain = [], alternate = [] } = this.getDataStructure();

        const { category = false } = this.props;

        if (_.isEmpty(supply_chain)) {
            return false;
        }

        return (
            <div
                style={Object.assign(
                    {},
                    { paddingTop: 0 },
                    !_.isEmpty(alternate) && this.styles.section.default,
                    !_.isEmpty(alternate) && this.styles.section.paddingTop,
                )}
                className="meta-supply-chain-ids"
            >
                {this.renderTitle("Supply Chain IDs")}

                <div
                    style={this.styles.flexMarginContainerNarrow}
                    className="meta-client-ids--container"
                >
                    {supply_chain.map((item, index) => {
                        let showItem = IDsFilterOnConfig({
                            category,
                            destination: "supply_chain",
                            key: item.key,
                        });

                        return (
                            <div
                                key={index}
                                style={this.styles.flexMarginItemQuoterNarrow}
                            >
                                {showItem && this.renderInputStructure(item)}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    renderAltenateIds() {
        const { alternate = [], client = [] } = this.getDataStructure();

        if (_.isEmpty(alternate)) {
            return false;
        }

        return (
            <div
                style={Object.assign(
                    {},
                    !_.isEmpty(client) && this.styles.section.paddingTop,
                )}
                className="meta-alternate-ids"
            >
                {this.renderTitle("Alternate IDs")}

                <div
                    style={this.styles.flexMarginContainerNarrow}
                    className="meta-client-ids--container"
                >
                    {alternate.map((item, index) => {
                        return (
                            <div
                                key={index}
                                style={this.styles.flexMarginItemQuoterNarrow}
                            >
                                {!_.isEmpty(item.logo) &&
                                    this.renderLogo(item.logo)}
                                {this.renderInputStructure(item)}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    renderInput(options = {}) {
        const {
            label,
            placeholder,
            name,
            readOnly,
            enableVerificationStatus = false,
            verifiedField = {},
        } = options;

        const { verified = false, selectedStatus = null } = verifiedField;

        const { data } = this.state;

        const { validationKeys, disabled, category } = this.props;

        let borderHiddenPadding = false;
        let color = false;
        let cursor = false;
        let href = false;

        const disableEdit =
            enableVerificationStatus &&
            ((!this.state.overRide && verified) ||
                (this.state.overRide && verified && _.isEmpty(selectedStatus)));

        if (disabled) {
            var additionalData = {
                episode_number: data.episode_number,
                season_number: data.season_number,
            };
            href = getIdHref(name, data[name], category, additionalData);
        }

        if (href) {
            color = "rgba(47, 47, 47, 0.87)";
            cursor = "pointer";
        }

        return (
            <AuditTooltip
                fieldKey={name}
                fieldName={"key"}
                value={name}
                dataSection={"identifiers"}
            >
                <div style={this.styles.section.inputGroup}>
                    {disableEdit || disabled || readOnly ? (
                        <div>
                            <Label label={label} fieldKey={name} />
                            {data[name] ? (
                                <CopyTooltip text={data[name] || ""}>
                                    <Tooltip
                                        title={data[name]}
                                        placement="bottom-start"
                                        arrow
                                        PopperProps={{
                                            disablePortal: true,
                                            datashow:
                                                data[name] &&
                                                data[name].length >=
                                                    characterLimit
                                                    ? "show"
                                                    : "hide",
                                        }}
                                    >
                                        {href ? (
                                            <a
                                                href={href}
                                                target="_blank"
                                                className="id-name"
                                            >
                                                {data[name]}
                                            </a>
                                        ) : (
                                            <span className="id-name">
                                                {data[name]}
                                            </span>
                                        )}
                                    </Tooltip>
                                </CopyTooltip>
                            ) : (
                                <span className="none-entered">
                                    None Entered
                                </span>
                            )}
                        </div>
                    ) : (
                        <Input
                            label={label}
                            placeholder={placeholder}
                            id={name}
                            value={data[name]}
                            name={name}
                            recordBasic={true}
                            color={color}
                            cursor={cursor}
                            href={href}
                            borderHiddenPadding={borderHiddenPadding}
                            error={isIndexInArray(validationKeys, name)}
                            handleChange={(item) =>
                                this.handleChange(name, item)
                            }
                        />
                    )}
                    {enableVerificationStatus && (
                        <VerificationStatus
                            name={name}
                            item={verifiedField}
                            disabled={disabled}
                            handleChange={(item) =>
                                this.handleStatusChange(name, item)
                            }
                            overRide={this.state.overRide}
                            forceUnverified={_.isEmpty(data[name])}
                        />
                    )}
                </div>
            </AuditTooltip>
        );
    }
}
