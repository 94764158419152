import React from 'react'
import { SectionInterface } from "./interface";
import { Akas } from "../../views/Akas";
import { getSectionCommonProps } from "../../actions";

const SectionAkas = (props: SectionInterface) => {
    const {
        data = {},
        languageIso = "",
        cmsData = {}
    } = props;
    const { clientFeatures = {} } = cmsData;

    return (
        <Akas
            {...getSectionCommonProps({
                ...props,
                field: "akas"
            })}
            akas={data.akas || []}
            category={data.category}
            locale={languageIso}
            clientFeatures={clientFeatures}
        />
    );
};

export default SectionAkas;
