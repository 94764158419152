import { RecordInterface } from "../../models/Record/model"
import { CmsInterface } from "../Global/interfaces"
import moment from 'moment'
import _ from 'lodash'
import update from "react-addons-update";

function getDateTimeFormat(item: any, dateFormat: string): string {
    return item.time_format ? `${dateFormat} ${item.time_format}` : dateFormat
}

export default function recordDatesTransformer(record: RecordInterface, cmsData: CmsInterface, dateFormat: string): RecordInterface {
    if (!cmsData?.clientFeatures?.dateTransformation) {
        return record
    }

    const dateTransformation: any = cmsData?.clientDataStructure?.dateTransformation || {}
    let recordResult = {...record}

    Object.keys(dateTransformation)?.forEach((type: string) => {
        const items = dateTransformation[type] || []

        if (type == 'Array') {
            items?.forEach((item: any) => {
                const format: string = getDateTimeFormat(item, dateFormat)

                if (recordResult[item.sectionPath]) {
                    recordResult[item.sectionPath]?.forEach((section: any, parentIndex: number) => {
                        if (section[item.field]) {
                            const momentItemDate = moment(section[item.field])

                            if (momentItemDate.isValid()) {
                                recordResult = update(recordResult, {
                                    [item.sectionPath]: {
                                        [parentIndex]: {
                                            [item.field]: {
                                                $set: momentItemDate.format(format)
                                            }
                                        }
                                    }
                                })
                            }
                        }
                    })
                }
            })
        }

        if (type == 'ArrayNested') {
            items?.forEach((item: any) => {
                const format: string = getDateTimeFormat(item, dateFormat)

                if (recordResult[item.sectionPath]) {
                    recordResult[item.sectionPath]?.forEach((parentItem: any, parentIndex: number) => {
                        if (parentItem[item.innerPath]) {
                            parentItem[item.innerPath]?.forEach((innerItem: any, innerIndex: number) => {
                                if (innerItem[item.field]) {
                                    const momentItemDate = moment(innerItem[item.field])

                                    if (momentItemDate.isValid()) {
                                        recordResult = update(recordResult, {
                                            [item.sectionPath]: {
                                                [parentIndex]: {
                                                    [item.innerPath]: {
                                                        [innerIndex]: {
                                                            [item.field]: {
                                                                $set: momentItemDate.format(format)
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        })
                                    }
                                }
                            })
                        }
                    })
                }
            })
        }

        if (type == 'Object') {
            items?.forEach((item: any) => {
                const itemValue: string = _.get(recordResult, item.field)
                const momentItemDate = moment(itemValue)
                const format: string = getDateTimeFormat(item, dateFormat)

                if (itemValue && momentItemDate.isValid()) {
                    _.set(recordResult, item.field, momentItemDate.format(format))
                }
            })
        }

        if (type == 'ObjectNested') {
            items?.forEach((item: any) => {
                const recordSection = recordResult[item.sectionPath] || {}
                const format: string = getDateTimeFormat(item, dateFormat)

                Object.keys(recordSection)?.forEach((itemKey: string) => {
                    const fieldPath: string = `${itemKey}.${item.field}`
                    const itemValue: string = _.get(recordResult[item.sectionPath], fieldPath)
                    const momentItemDate = moment(itemValue)

                    if (itemValue && momentItemDate.isValid()) {
                        _.set(recordResult[item.sectionPath], fieldPath, momentItemDate.format(format))
                    }
                })
            })
        }
    })

    return recordResult
}