import React from "react";
import Credits from "../../../../features/Credits";
import { getSectionCommonProps } from "../../actions";
import { SectionInterface } from "./interface";
import getFormChangeSection from "../../../../features/Credits/utils/getFormChangeSection";

const SectionCredits = (props: SectionInterface) => {
    const {
        data = {},
        cmsData = {}
    } = props;

    const {
        clientFeatures = {}
    } = cmsData

    return (
        <React.Fragment>
            <Credits
                {...getSectionCommonProps({ ...props, field: getFormChangeSection(clientFeatures) })}
                credits={data.contributors || []}
                creditLists={data.credit_lists || []}
            />
        </React.Fragment>
    );
};

export default SectionCredits;