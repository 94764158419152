import PhilomenaSony from "../PhilomenaSony";
import jumanjiLondonEV from "../jumanjiLondonEV";
import InceptionSony from "../InceptionSony";

export default function AssetsData({ data, clientFeatures }) {
    let assetsData = data.assets || [];

    if (
        clientFeatures.assetsDemo &&
        data.category == "Short" &&
        data.original_title == "Jumanji: Welcome to the Jungle • Trailer"
    ) {
        assetsData = jumanjiLondonEV;
    }

    if (
        clientFeatures.assetsDemo &&
        data.category == "Feature" &&
        (data.original_title == "Philomena" || data.original_title == "Spectre")
    ) {
        assetsData = PhilomenaSony;
    }

    if (
        clientFeatures.assetsDemo &&
        data.category === "Feature" &&
        data.original_title === "Inception"
    ) {
        data.ids = {
            ...data.ids,
            tmc_id: "FF_INCEPTION0",
            pfc_id: "EF85-856A-06C7-BB7E-C1D7-L",
        };
        assetsData = InceptionSony;
    }

    return assetsData;
}
