import _ from "lodash";
import SendRequest from "../../../components/SendRequest";
import GetApiUrls from "../GetApiUrls";

const HandleUserGroupUpdateRequest = (props) => {
    const { state = {}, userGroup } = props;

    const { userGroupUpdate = "" } = GetApiUrls(state);

    const { meta_id, name } = userGroup;

    const { set } = state;

    const requestOptions = {
        url: userGroupUpdate.replace("%meta_id%", meta_id),
        method: "POST",
        basic: true,
        body: userGroup,
    };

    const handleError = (error) => {
        console.log(error);

        set({
            savingRecord: false,
            notification: {
                title: `Failed to update user group ${name || ""}`,
                description: error
                    ? error
                    : "Technical error whilst updating user group",
                status: "error",
                onClick: () =>
                    set({
                        notification: {},
                    }),
            },
        });
    };

    set({
        savingRecord: true,
    });

    try {
        SendRequest(
            requestOptions,
            (response) => {
                const { message, status, data } = response;

                if (status == 200) {
                    set({
                        savingRecord: false,
                        recordData: data,
                    });

                    return;
                }

                handleError(message);
            },
            (error) => {
                handleError(error);
            },
        );
    } catch (e) {
        handleError(e);
    }
};

export default HandleUserGroupUpdateRequest;
