import React from "react";
import _ from "lodash";
import Select from "../Select";
import Input from "../Input/basic";
import days from "../Options/days";
import months from "../Options/months";
import styles from "./styles";
import moment from "moment";

export default class RecordDateInput extends React.Component {
    constructor(props) {
        super(props);

        /**
         * @var object
         */
        this.state = {
            value: "",
            day: "",
            month: "",
            year: "",
            hour: "",
            minute: "",
            formattedDate: "",
        };

        /**
         * @var object
         */
        this.copy = this.props.copy || {};
    }

    componentDidMount() {
        this.setValuesFromProps();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!_.isEqual(nextProps.value, this.props.value)) {
            return true;
        }

        if (!_.isEqual(nextProps.disabled, this.props.disabled)) {
            return true;
        }

        if (!_.isEqual(nextState, this.state)) {
            return true;
        }

        return false;
    }

    setStateString(key, value) {
        if (this.state[key] !== value) {
            this.setState({
                [key]: value,
            });
        }
    }

    setValuesFromProps() {
        const { dateFormat = false } = this.props;

        let value = this.props.value;
        let momentObject = {};

        if (value) {
            momentObject = moment(value);
        }

        if (!value) {
            momentObject = moment();
            momentObject = moment(momentObject).add(1, "M");
        }

        if (typeof momentObject == "object") {
            if (typeof momentObject.isValid == "function") {
                if (momentObject.isValid()) {
                    const year = momentObject.get("year");
                    const month = momentObject.get("month");
                    const day = momentObject.get("date");
                    const hour = momentObject.get("hour");
                    const minute = momentObject.get("minute");

                    this.setStateString("value", value);
                    this.setStateString("day", this.formatString(day));
                    this.setStateString("month", this.formatString(month + 1));
                    this.setStateString("year", year.toString());
                    this.setStateString("hour", this.formatString(hour));
                    this.setStateString("minute", this.formatString(minute));
                    this.setStateString(
                        "formattedDate",
                        momentObject.format(
                            dateFormat ? dateFormat : "Do MMMM YYYY, HH:mm",
                        ),
                    );
                }
            }
        }
    }

    getDaysOptions() {
        let result = days();

        if (this.state.month && this.state.year) {
            const momentObject = moment(
                `${this.state.year}-${this.state.month}`,
            );
            const numberOfDays = moment(momentObject).daysInMonth();

            if (Number.isInteger(numberOfDays)) {
                result = days(numberOfDays);
            }
        }

        return result;
    }

    handleChange(key, value) {
        const { dateFormat = false } = this.props;

        if (typeof key == "string") {
            let state = { ...this.state };
            let formattedDate =
                this.copy.incorrectDateAndTime || "Incorrect date and time";

            state[key] = value;

            let date = moment(
                `${state.year}-${state.month}-${state.day} ${state.hour}:${state.minute}`,
            );
            let isValid = date.isValid();
            let timestamp = state.value;

            if (isValid) {
                timestamp = date.format("YYYY-MM-DD HH:mm");
                this.setStateString(
                    "formattedDate",
                    date.format(
                        dateFormat ? dateFormat : "Do MMMM YYYY, HH:mm",
                    ),
                );
            }

            this.setStateString("value", timestamp);
            this.setStateString(key, value);

            if (typeof this.props.handleChange == "function") {
                this.props.handleChange(timestamp);
            }
        }
    }

    formatString(number) {
        return ("0" + number).slice(-2);
    }

    render() {
        const { disabled, disabledText } = this.props;

        const RenderInputs = () => (
            <div style={styles().container}>
                <Select
                    className="day"
                    value={this.state.day}
                    disabledText={this.copy.day || "Day"}
                    disabled={disabled}
                    data={this.getDaysOptions()}
                    wrapperStyles={{
                        width: "6rem",
                        marginRight: "0.75rem",
                    }}
                    noMargin={true}
                    handleChange={this.handleChange.bind(this, "day")}
                />

                <Select
                    className="month"
                    value={this.state.month}
                    disabledText={this.copy.month || "Month"}
                    disabled={disabled}
                    data={months()}
                    wrapperStyles={{
                        width: "10rem",
                        marginRight: "0.75rem",
                    }}
                    noMargin={true}
                    handleChange={this.handleChange.bind(this, "month")}
                />

                <Input
                    placeholder={this.copy.year || "Year"}
                    className="year"
                    type="number"
                    value={this.state.year}
                    disabled={disabled}
                    margin="0 0.75rem 0 0"
                    width="5rem"
                    recordBasic={true}
                    handleChange={this.handleChange.bind(this, "year")}
                />

                <Input
                    placeholder={this.copy.hour || "Hour"}
                    className="hour"
                    type="number"
                    value={this.state.hour}
                    disabled={disabled}
                    margin="0 0.75rem 0 0"
                    width="3rem"
                    max={23}
                    recordBasic={true}
                    handleChange={this.handleChange.bind(this, "hour")}
                />

                <Input
                    placeholder={this.copy.minute || "Min"}
                    className="minute"
                    type="number"
                    value={this.state.minute}
                    disabled={disabled}
                    margin="0"
                    width="3rem"
                    max={59}
                    recordBasic={true}
                    handleChange={this.handleChange.bind(this, "minute")}
                />
            </div>
        );

        return (
            <div
                style={Object.assign(
                    {},
                    this.props.styles && this.props.styles,
                )}
            >
                {this.props.label && (
                    <label style={styles().label}>{this.props.label}</label>
                )}

                {!disabled && <RenderInputs />}

                {disabled && (
                    <Input
                        className={this.props.className}
                        placeholder={this.copy.noneEntered || "None Entered"}
                        value={disabledText || this.state.formattedDate}
                        disabled={true}
                        margin="0"
                        width="100%"
                        recordBasic={true}
                    />
                )}
            </div>
        );
    }
}
