import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../../components/Global/store/store";
import { useStores } from "./store/rootStore";
import { CoreDialog } from "../../../../core/Dialog/CoreDialog";
import ContentLoading from "../../../../components/ContentLoading";
import ContributorOriginBadge from "./views/ContributorOriginBadge.view";
import { coreDialogProps } from "../utils/constants"
import TabsView from "./views/Tabs.view"
import FormCreate from "./views/Form.create.view"
import CoreAvatar from "../../../../core/Avatar/CoreAvatar";
import CustomSectionsViews from "./views/CustomSections.view";
import RecordBuilderError from "./RecordBuilderError.view"
import { ContributorInterface } from "../interfaces"
import config from "./config";

interface ContributorDialogCreateInterface {
    open: boolean,
    handleSetOpen: (open: boolean) => void,
    handleContributorCreated: (contributor: ContributorInterface) => void
}

const ContributorDialogCreate = observer((props: ContributorDialogCreateInterface) => {
    const [globalState, dispatch] = useStore() || [{}];
    
    const { 
        rootStore,
        contributorCreateStore, 
        optionsStore,
        validationStore,
        integrationsStore
    } = useStores()

    useEffect(() => {
        rootStore.initialize(globalState, dispatch)
    }, [globalState.recordBuilder])

    useEffect(() => {
        if (props.open) {
            contributorCreateStore.initialize(props?.handleSetOpen, props?.handleContributorCreated)
        }

        contributorCreateStore.setOpen(props.open)
    }, [props.open])

    if (props.open && 
        !optionsStore.isRecordBuilderConfigActive() && 
        !optionsStore.fetching) {

        return <RecordBuilderError handleClose={props?.handleSetOpen}/>
    }

    return (
        <CoreDialog
            {...coreDialogProps}
            open={props.open}
            setOpen={(open) => props?.handleSetOpen(open)}
            title="Add Contributor"
            errorAlerts={contributorCreateStore.showValidationErrors 
                ? validationStore.getValidationMessages() 
                : []
            }
            buttons={contributorCreateStore.getContributorDialogButtons()}>
                
            {optionsStore.fetching &&
                <ContentLoading loadingGrey />
            }

            {!optionsStore.fetching &&
                <>
                    <ContributorOriginBadge linkurl={contributorCreateStore.getFabricOriginPersonUrl()} />
                    <CoreAvatar imgsrc={contributorCreateStore.getAvatarUrl()} loading={integrationsStore.fetchingKeys[config.iva.ratingKey]} />

                    <TabsView />
                    <FormCreate />
                    <CustomSectionsViews />
                </>
            }
        </CoreDialog>
    )
})

export default ContributorDialogCreate