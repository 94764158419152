import { useLanguages } from "./useLanguages";

export function useLanguageByCode(codeArg: string) {
    const languages = useLanguages();

    const language = languages?.find(
        (lang) => lang?.value?.toUpperCase() === codeArg?.toUpperCase(),
    );

    return language;
}
