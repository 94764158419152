import React, { Fragment } from "react";
import { isEmpty } from "lodash";
import { DynamicInputsInterface, OptionsInterface } from "./interfaces";
import Input from "../basic";
import RecordTimeInput from "../../Boilerplates/RecordTimeInput"
import { datalists } from "./datalists/lists";
import transformOptions from "./src/transformOptions";
import ClientManagedSelect from "../../Select/ClientManaged";
import MaterialDatePicker from "../MaterialDatePicker/RecordViewController";
import { useStore } from "../../Global/store/store";
import ClientManagedAutocomplete from "../../AutocompleteInput/ClientManaged";
import ClientManagedSimpleOnEnterBubbleList from "../../BubbleList/ClientManagedSimpleOnEnter";
import Switch from "../../Switch";
import RadioBooleanInput from "../radioBoolean";
import DynamicCoreSelectInput from "./src/DynamicCoreSelectInput";

const DynamicInputs = (props: DynamicInputsInterface) => {
    const {
        disabled,
        handleChange = () => {},
        styles = {},
        data = {},
        validationKeys = [],
        useCoreFormInputs = false,
        category,
    } = props;

    const [globalState] = useStore() || [{}];
    const options: OptionsInterface[] = transformOptions(props);

    const { cmsData = {} } = globalState;

    const {
        apiUrls = {},
        clientFeatures = {}
    } = cmsData;

    if (!Array.isArray(options)) {
        return <></>;
    }

    return (
        <>
            {options.map((option, index) => {
                const {
                    component,
                    label,
                    placeholder,
                    dataList,
                    client_managed_type,
                    booleanList,
                    categories,
                    disabled: fieldDisabled,
                    excludedCategories = [],
                    isDynamicList = false,
                    time_format
                } = option;

                let componentOptions: any = {
                    label,
                    placeholder,
                    isDynamicList,
                    disabled: fieldDisabled || disabled,
                    key: `dynamic-input-${index}`,
                    handleChange: (value: any) =>
                        handleChange(option.key, value),
                    handleFormChange: (value: any) =>
                        handleChange(option.key, value),
                    disabledText: placeholder,
                    className: `dynamic_input_${option.key}`,
                    id: option.key,
                    name: option.key,
                    value: data[option.key],
                    error: validationKeys.includes(option.key),
                    readOnly: disabled || option.readOnly,
                    clientFeatures: clientFeatures,
                    apiUrls: apiUrls,
                    alphaNumeric: option.alphaNumeric,
                };

                const wrapperProps: any = {
                    style: styles,
                    className: `dynamic_label_${option.key}`,
                    key: `dynamic-input-wrapper-${index}`,
                };

                if (excludedCategories.includes(category)) {
                    return <></>;
                }

                if (!isEmpty(categories) && !categories.includes(category)) {
                    return <Fragment></Fragment>;
				}

                if ((component == "select" || component == "selectmultiple") && useCoreFormInputs) {
                    return (
                        <div {...wrapperProps}>
                            <DynamicCoreSelectInput
                                {...componentOptions}
                                multiple={component == "selectmultiple"}
                                clientManagedType={client_managed_type}
                            />
                        </div>
                    )
                }

                if (component == "text") {
                    return (
                        <div {...wrapperProps}>
                            <Input
                                {...componentOptions}
                                recordBasic={true}
                                useNoneAdded={true}
                            />
                        </div>
                    );
                }

                if (component == "time") {
                    return (
                        <div {...wrapperProps}>
                            <RecordTimeInput
                                {...componentOptions}
                                disabled_id={option.key}
                            />
                        </div>
                    );
                }

                if (component == "date" || component == "dateTime") {
                    return (
                        <div {...wrapperProps}>
                            <MaterialDatePicker
                                {...componentOptions}
                                label={false}
                                labelLegacy={label}
                                defaultValue={data[option.key]}
                                noneEntered={true}
                                time_format={time_format}
                                className="datepicker-full-width"
                            />
                        </div>
                    );
                }

                if (component == "select" && !useCoreFormInputs) {
                    if (booleanList) {
                        componentOptions = {
                            ...componentOptions,
                            handleChange: (value: any) =>
                                handleChange(option.key, !!Number(value)),
                            value: Number(!!data[option.key]),
                        };
                    }

                    if (isDynamicList) {
                        componentOptions.allowChange = true
                        componentOptions.handleChangeTextValue = componentOptions.handleChange
                        componentOptions.handleChange = null
                    }

                    return (
                        <div {...wrapperProps}>
                            <ClientManagedSelect
                                {...componentOptions}
                                noneSelectedText="None Added"
                                data={datalists[dataList] || []}
                                clientManagedType={client_managed_type}
                                isClientManaged={
                                    client_managed_type ? true : false
                                }
                            />
                        </div>
                    );
                }

                if (component == "number") {
                    return (
                        <div {...wrapperProps}>
                            <Input
                                {...componentOptions}
                                recordBasic={true}
                                useNoneAdded={true}
                                type="number"
                            />
                        </div>
                    );
                }

                if (component == "autocomplete") {
                    componentOptions = {
                        ...componentOptions,
                        handleListSelection: (value: any) =>
                            handleChange(option.key, value),
                        callBackOnDelete: () => handleChange(option.key, null),
                        single: true,
                        recordBasic: true,
                        keepSelected: true,
                        bubbleOnSelect: true,
                        noneEntered: true,
                        displayNoneEntered: true,
                        inputId: option.key,
                        dataListUseEnterButton: true,
                        useEnterButton: true,
                        handleChange: null
                    };

                    return (
                        <div {...wrapperProps}>
                            <ClientManagedAutocomplete
                                {...componentOptions}
                                noneSelectedText="None Added"
                                data={datalists[dataList] || []}
                                clientManagedType={client_managed_type}
                                isClientManaged={
                                    client_managed_type ? true : false
                                }
                            />
                        </div>
                    );
                }

                if (component == "bubbleList") {
                    componentOptions = {
                        ...componentOptions,
                        handleFormChange: (value: any) =>
                            handleChange(option.key, value),
                        emptyPlaceholder: placeholder,
                        filterStaticDataList: true,
                        dataListUseEnterButton: true,
                        useEnterButton: true,
                        fullyDelete: false,
                        dataKey: "datalist",
                        handleChange: null,
                        data: data[option.key],
                        value: null,
                        single: option.single
                    };

                    return (
                        <div {...wrapperProps}>
                            <ClientManagedSimpleOnEnterBubbleList
                                {...componentOptions}
                                noneSelectedText="None Added"
                                clientManagedType={client_managed_type}
                                isClientManaged={
                                    client_managed_type ? true : false
                                }
                            />
                        </div>
                    );
                }

                if (component == "toggle") {
                    return (
                        <div {...wrapperProps}>
                            <label>{label}</label>
                            <div style={{ margin: "0.71875rem 0 0" }}>
                                <Switch
                                    {...componentOptions}
                                    name={label}
                                    checked={data[option.key]}
                                    handleChange={(value: any) =>
                                        handleChange(
                                            option.key,
                                            !!value.checked,
                                        )
                                    }
                                />
                            </div>
                        </div>
                    );
                }

                if (component == "radio") {
                    return (
                        <div {...wrapperProps}>
                            <RadioBooleanInput
                                {...componentOptions}
                                recordBasic={true}
                                useNoneAdded={true}
                                value={!!componentOptions.value}
                                trueLabel={option.trueLabel}
                                falseLabel={option.falseLabel}
                                type="number"
                            />
                        </div>
                    );
                }

                return <></>;
            })}
        </>
    );
};

export default DynamicInputs;
