import React from "react";
import _ from "lodash";
import styles from "../styles";
import Index from "./Index";
import { diff } from "deep-object-diff";
import { default as AssetWrapper } from "../../Assets/wrapper";
import {
    CLIENT_MANAGED_ASSET_COMPLIANCES,
    ASSET_COMPLIANCES,
    CLIENT_MANAGED_ASSET_CERTIFICATIONS,
    ASSET_CERTIFICATIONS,
} from "../../ClientManagedOptions/options";
import fetchClientManagedOptions from "../../ClientManagedOptions/fetchClientManagedOptions";

export default class RelatedAssets extends Index {
    constructor(props) {
        super(props);

        let data = this.props.data;

        this.state = {
            data,
        };
        this.styles = styles(this.props);

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const { cmsData = {}, clientFeatures = {} } = this.props;

        fetchClientManagedOptions({
            clientManagedType: CLIENT_MANAGED_ASSET_COMPLIANCES,
            apiUrls: cmsData.apiUrls || {},
            clientFeatures,
            list: ASSET_COMPLIANCES,
        });

        fetchClientManagedOptions({
            clientManagedType: CLIENT_MANAGED_ASSET_CERTIFICATIONS,
            apiUrls: cmsData.apiUrls || {},
            clientFeatures,
            list: ASSET_CERTIFICATIONS,
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const difference = diff(this.props.data, prevProps.data);
        if (!_.isEmpty(difference)) {
            this.setState({ data: this.props.data });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const nextPropsData = nextProps.data || {};
        const thisPropsData = this.props.data || {};
        const difference = diff(nextPropsData, thisPropsData);
        const validationDataDifference = diff(
            nextProps.validationData,
            this.props.validationData,
        );

        if (!_.isEmpty(difference)) {
            return true;
        }

        if (!_.isEmpty(validationDataDifference)) {
            return true;
        }

        if (!_.isEqual(nextProps.disabled, this.props.disabled)) {
            return true;
        }

        if (!_.isEqual(nextProps.audits, this.props.audits)) {
            return true;
        }

        if (!_.isEqual(nextProps.options, this.props.options)) {
            return true;
        }

        if (!_.isEqual(nextState, this.state)) {
            return true;
        }

        return false;
    }

    getInvalidKeys() {
        const { validationData } = this.props;

        let result = [];

        if (typeof validationData == "object") {
            const {
                category,
                titles = [],
                ids = {},
                origination,
                eidr_status,
            } = validationData;

            let displayTitle = false;
            if (Array.isArray(titles)) {
                displayTitle = titles.find(
                    (item) => item.display && item.title,
                );
            }

            if (!displayTitle) {
                result.push("title");
            }

            if (!ids || !ids.international_id) {
                result.push("ids.international_id");
            }

            // Approximate Release Duration, Release Year, Primary Language, Country of Origin, Record Type
            if (
                !origination ||
                !origination.release_duration ||
                !origination.first_release_year ||
                !origination.locale ||
                !origination.type
            ) {
                result.push("origination");
            }

            if (eidr_status && eidr_status.status === "duplicate") {
                result.push("eidr_status");
            }

            let numberingKey = null;
            if (category == "Episode") {
                numberingKey = "episode_numbering";
            } else if (category == "Storyline") {
                numberingKey = "storyline_numbering";
            } else if (category == "Short") {
                numberingKey = "short_numbering";
            }

            if (
                numberingKey &&
                (!validationData[numberingKey] ||
                    !validationData[numberingKey].production_number)
            ) {
                result.push(`${numberingKey}.production_number`);
            }
        }

        return result;
    }

    handleChange(data) {
        this.setState(
            {
                data,
            },
            () => {
                if (typeof this.props.handleFormChange == "function") {
                    this.props.handleFormChange(data);
                }
            },
        );
    }

    renderContent() {
        const { data: activeData = [] } = this.state;

        const invalidKeys = this.getInvalidKeys();

        const {
            meta_id,
            disabled,
            options = {},
            meta_users,
            assetResendWorkflowNotificationsUrl,
            origination = {},
            copy,
            attributes,
            validationData,
            parentData = {},
            clientFeatures = {},
            dataStructure = {},
            canDeleteAllAssets,
            canDeleteCompliance,
            handleMandatoryData,
            audits = [],
            category,
            canCreateNew = true,
            cmsData,
        } = this.props;

        let ids = {};
        if (!_.isEmpty(parentData.ids)) {
            ids = parentData.ids;
        }

        const { locale } = origination;

        if (disabled && _.isEmpty(activeData)) {
            return false;
        }

        const commonProps = {
            data: activeData,
            disabled,
            handleChange: this.handleChange,
            ids,
            meta_id,
            invalidKeys,
            options,
            copy,
            attributes,
            validationData,
            meta_users,
            locale: locale,
            id: "assets",
            canDeleteAllAssets,
            canDeleteCompliance,
            handleMandatoryData,
            assetResendWorkflowNotificationsUrl,
            clientFeatures,
            dataStructure,
            audits,
            category,
            canCreateNew,
            cmsData,
        };

        return (
            <div style={this.styles.marginItem}>
                <AssetWrapper {...commonProps} />
            </div>
        );
    }
}
