import { CoreSelect } from "../../../../../../core/Form/components/inputs/Select/components/CoreSelect";
import { useSubGenresOptions } from "../../../../hooks/useSubGenresOptions";
import { StandaloneFields } from "../../../../config/clients/viacom/validation/standaloneVS";

export const SubGenres = () => {
    const subGenresOptions = useSubGenresOptions();

    return (
        <CoreSelect<StandaloneFields>
            config={{
                path: "descriptions.subGenre",
                labelProps: {
                    text: "Sub-Genres",
                },
                options: subGenresOptions,
                multiple: true,
                enableSearchbox: true,
                showChipArray: true,
            }}
        />
    );
};
