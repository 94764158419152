import {
    AccessTypeInterface,
    DefaultSectionOptionsInterface,
} from "../../interfaces";

// somehow breaks in testing so have to re write it
export const accessTypeCreate: AccessTypeInterface = { name: "create" };
export const accessTypeDelete: AccessTypeInterface = { name: "delete" };
export const accessTypeUpdate: AccessTypeInterface = { name: "update" };
export const accessTypeView: AccessTypeInterface = { name: "view" };

export const defaultSectionOptions: DefaultSectionOptionsInterface = {
    disabledAccessTypes: [accessTypeDelete],
};