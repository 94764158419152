import update from "react-addons-update";
import _ from "lodash";
import HandleChange from "./HandleChange";
import GetRecordData from "./GetRecordData";

const HandleReleaseDisplayTitleChange = (props) => {
    const { releaseTitle, state, handleChange } = props;

    const { checked } = releaseTitle;

    const record = GetRecordData({
        state,
    });

    const titles = record.titles || [];

    // Remove release title tag from all titles
    let updatedTitles = titles.map((tl) => {
        let item = tl;
        delete item.release;

        return item;
    });

    // Finds display title
    const displayTitleIndex = _.findIndex(titles, function (tl) {
        return tl.display;
    });

    let updatedState = updatedTitles;

    if (displayTitleIndex !== -1 && checked) {
        updatedState = update(updatedTitles, {
            [displayTitleIndex]: {
                release: {
                    $set: checked,
                },
            },
        });
    }

    if (typeof handleChange == "function") {
        handleChange({
            key: "titles",
            data: updatedState,
        });

        return;
    }

    HandleChange({
        key: "titles",
        data: updatedState,
        state,
    });
};

export default HandleReleaseDisplayTitleChange;
