import { Chip, ChipProps } from "@mui/material";

export type TMChipVariant = "red" | "orange" | "green" | "blue" | "black";
export type TMChipsProps = Pick<ChipProps, "label"> & {
    colorVariant: TMChipVariant;
};
export const TMChip = ({ colorVariant, label }: TMChipsProps) => {
    let backgroundColor = "gray";
    let color = "black";

    switch (colorVariant) {
        case "red": {
            backgroundColor = "rgba(214, 70, 94, 0.1)";
            color = "rgba(118, 20, 36, 1)";
            break;
        }
        case "orange": {
            backgroundColor = "rgba(249, 152, 7, 0.2)";
            color = "rgba(119, 52, 1, 1)";
            break;
        }
        case "green": {
            backgroundColor = "rgba(52, 173, 99, 0.1)";
            color = "rgba(19, 87, 45, 1)";
            break;
        }
        case "blue": {
            backgroundColor = "rgba(40, 108, 210, 0.1)";
            color = "rgba(20, 71, 148, 1)";
            break;
        }
        case "black": {
            backgroundColor = "#DBDBDB";
            color = "rgba(47, 47, 47, 0.87)";
            break;
        }

        default:
            break;
    }

    return (
        <Chip
            sx={{
                backgroundColor,
                margin: 0,
                "& .MuiChip-label": {
                    color,
                    fontSize: "0.75rem",
                },
            }}
            label={label}
        />
    );
};
