import MetaIdTemp from "../../../components/MetaIdTemp";

const HandleNewUsergroup = (props) => {
    const { state = {} } = props;

    const { set } = state;

    const newRecord = {
        meta_id_temp: MetaIdTemp,
    };

    set({
        newRecord,
        recordData: {},
        activeRecord: false,
        unsavedChanges: false,
        editRecord: true,
    });
};

export default HandleNewUsergroup;
