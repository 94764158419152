import DefaultClientPermissions from "./DefaultClientPermissions";
import GlobalEagleClientPermissions from "./GlobalEagle/ClientPermissions";
import MGMClientPermissions from "./MGM/ClientPermissions";
import WarnerMediaClientPermissions from "./WarnerMedia/ClientPermissions";
import FoxClientPermissions from "./Fox/ClientPermissions";
import FunimationClientPermissions from "./Funimation/ClientPermissions";
import ViacomClientPermissions from "./Viacom/ClientPermissions";
import PokemonPermissions from "./Pokemon/ClientPermissions";
import AENetworksPermissions from "./AENetworks/ClientPermissions";
import isSinclairPermissions from "./Sinclair/ClientPermissions";
import Clients from "../../components/Clients";

export default function ClientPermissions(roles) {
    let result = DefaultClientPermissions(roles);

    if (Clients.isClientGlobalEagle()) {
        result = GlobalEagleClientPermissions(roles);
    } else if (Clients.isClientMGM() || Clients.isClientEpix()) {
        result = MGMClientPermissions(roles);
    } else if (Clients.isClientWarnerMedia()) {
        result = WarnerMediaClientPermissions(roles);
    } else if (Clients.isClientFox()) {
        result = FoxClientPermissions(roles);
    } else if (Clients.isFunimation()) {
        result = FunimationClientPermissions(roles);
    } else if (Clients.isViacom()) {
        result = ViacomClientPermissions(roles);
    } else if (Clients.isPokemon()) {
        result = PokemonPermissions(roles);
    } else if (Clients.isAENetworks()) {
        result = AENetworksPermissions(roles);
    } else if (Clients.isSinclair()) {
        result = isSinclairPermissions(roles);
    }

    return result;
}
