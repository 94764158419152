import CategoryTags from "../../../CategoryTags";

const config = {
    availableCategories: [CategoryTags.Short.short, CategoryTags.Extras.short],
    availableCategoryTypes: {
        [CategoryTags.Extras.short]: ["vam"],
    },
};

export default config;
