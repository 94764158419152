import SendRequest from "../../SendRequest";

const RecordMetaDataRequest = (props) => {
    const {
        cmsData = {},
        meta_id,
        handleSuccess = () => {},
        handleError = () => {},
    } = props;

    const { apiUrls = {} } = cmsData;

    const { metaDataUrl = "" } = apiUrls;

    const options = {
        url: metaDataUrl.replace("{meta_id}", meta_id),
        method: "GET",
    };

    const errorHandle = (error) => {
        if (error) console.log(error);
        handleError();
    };

    try {
        SendRequest(
            options,
            (response) => {
                const { data, message, status } = response;

                if (status == 200) {
                    handleSuccess(data);
                    return;
                }

                errorHandle(message);
            },
            (error) => {
                errorHandle(error);
            },
        );
    } catch (e) {
        errorHandle(e);
    }
};

export default RecordMetaDataRequest;
