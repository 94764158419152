import DefaultRoles from "../DefaultRoles";

export default function Roles() {
    const Role_Viewer_No_Rights = "viewer-no-rights";
    const Role_MediaAssetEditor = "media-assets-editor";

    return {
        ...DefaultRoles(),
        Role_Viewer_No_Rights,
        Role_MediaAssetEditor,
    };
}
