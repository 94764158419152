import React from "react";
import Chip from "@mui/material/Chip";

export const ProductionNumberChip = ({ label }) => {
    return (
        <Chip
            label={label}
            sx={{
                bgcolor: "#DBDBDB",
                margin: "0",
                "& .MuiChip-label": {
                    color: "rgba(47, 47, 47, 0.87)",
                    fontWeight: 400,
                    fontSize: "0.75rem",
                },
                padding: "0.25rem 0.375rem",
                borderRadius: "0.25rem",
                fontSize: "0.875rem",
                minWidth: "1.625rem"
            }}
        />
    );
};

export const ProductionNumberChipMedia = ({ label, hover = false }) => {
    return (
        <Chip
            className="production-number-chip-media"
            label={label}
            sx={{
                bgcolor: "#838383",
                margin: "0",
                marginRight: "0.313rem",
                "& .MuiChip-label": {
                    color: hover ? "#FFFFFF" : "#D7D7D7",
                    fontWeight: 400,
                    fontSize: "0.7rem",
                    padding: "0",
                },
                padding: "0.188rem 0.313rem",
                borderRadius: "0.25rem",
                fontSize: "0.75rem",
                height: "auto",
            }}
        />
    );
};

export const ProductionNumberChipLinkage = ({ label }) => {
    return (
        <Chip
            label={label}
            sx={{
                bgcolor: "#9E9D9D",
                margin: "0",
                marginLeft: "0.313rem",
                "& .MuiChip-label": {
                    color: "#FFFFFF",
                    fontWeight: 400,
                    fontSize: "0.75rem",
                    padding: "0",
                    lineHeight: "1rem",
                },
                padding: "0.188rem 0.313rem",
                borderRadius: "0.25rem",
                fontSize: "0.75rem",
                height: "100%",
            }}
        />
    );
};