import React from "react";
import DoubleTags from "../../DoubleTags";
import CategoryTags from "../../CategoryTags";

const RenderSeasonTags = (props) => {
    const { season_number, category } = props;

    if (season_number && category !== CategoryTags.Season.short) {
        return (
            <DoubleTags
                leftText="Season"
                rightText={season_number}
                rightTextTransparent={true}
                className="meta-numbered-record-table--tags"
            />
        );
    }

    return false;
};

export default RenderSeasonTags;
