import React, { Component } from "react";
import { connect } from "react-redux";
import ProcessIndicator from "./ProcessIndicator";

/**
 * @var object
 */
const mapStateToProps = (state) => ({ state });

/**
 * @var object
 */
const mapDispatchToProps = (dispatch) => ({ dispatch });

/**
 * @var object
 */
const mergeProps = ({ state }, { dispatch }, ownProps) => {
    /**
     * @var object
     */
    const { backgroundProcess } = state;

    return Object.assign({}, ownProps, {
        dispatch,
        backgroundProcess: backgroundProcess.backgroundProcess,
    });
};

//export const taskProgressId = 'taskProgressId'

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
)(ProcessIndicator);
