import CategoryTags from "../../../CategoryTags";
import SendRequest from "../../../SendRequest";

class DataFetch {
    links(props) {
        const { searchQuery, searchUrl, recordType, onResult } = props;

        let searchCategory = recordType;

        switch (recordType) {
            case CategoryTags.Season.short:
            case CategoryTags.Episode.short:
            case CategoryTags.Storyline.short:
            case CategoryTags.Show.short:
                searchCategory = CategoryTags.Show.short;
                break;
            case CategoryTags.Special.short:
                searchCategory = CategoryTags.Special.short;
                break;
            case CategoryTags.Extras.short:
                searchCategory = CategoryTags.Extras.short;
                break;
            case CategoryTags.Short.short:
                searchCategory = CategoryTags.Short.short;
                break;
            case CategoryTags.Feature.short:
                searchCategory = CategoryTags.Feature.short;
                break;
            case CategoryTags.Collection.short:
                searchCategory = CategoryTags.Collection.short;
                break;
        }

        let url = searchUrl
            .replace("{searchTerm}", searchQuery)
            .replace("{category}", searchCategory.toLowerCase());
        const options = {
            url: url,
            method: "GET",
        };
        SendRequest(
            options,
            (res) => {
                onResult(res.data);
            },
            (e) => {
                console.log(e);
            },
        );
    }

    linksByMetaId(props) {
        const {
            searchUrl,
            meta_id,
            filterCategories = [],
            onResult = () => {},
        } = props;

        const options = {
            url: searchUrl,
            method: "POST",
            body: { meta_id },
        };

        SendRequest(
            options,
            (res) => {
                let rows = res.data.filter((episode) =>
                    filterCategories.includes(episode.category),
                );
                onResult(rows);
            },
            (e) => {
                console.log(e);
            },
        );
    }

    episodesFormLinkage(props) {
        const { linkage = [] } = props;

        return linkage.filter(
            (row) =>
                row.category === CategoryTags.Episode.short ||
                row.category === CategoryTags.ConstructedEpisode.short,
        );
    }

    recordsFromArray(props) {
        const { records = [], searchable = [] } = props;

        let result = [];

        searchable.forEach((item) => {
            let record = records.find((el) => el.meta_id === item.meta_id);
            if (record) result.push(record);
        });

        return result;
    }

    seasonsFromLinkage(props) {
        const { meta_id, records } = props;

        let result = [];

        let record = records.find((el) => el.meta_id === meta_id);

        if (record && typeof record === "object" && record.linkage) {
            record.linkage.map((row) => {
                if (row.category === CategoryTags.Season.short) {
                    result.push(row);
                }
            });
        }

        return result;
    }
}

const instance = new DataFetch();

export { instance as default };
