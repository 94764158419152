import CategoryTags from "../../CategoryTags";
import ConvertTextToMultiple from "../../ConvertTextToMultiple";
import _ from "lodash";

const CollectionContentText = (props) => {
    const { linkage = [], copy = {} } = props;

    let result = "";

    if (Array.isArray(linkage)) {
        const movies = linkage.filter(
            (item) => item.category == CategoryTags.Feature.short,
        );
        const shows = linkage.filter(
            (item) => item.category == CategoryTags.Show.short,
        );
        const episodes = linkage.filter(
            (item) => item.category == CategoryTags.Episode.short,
        );
        const storylines = linkage.filter(
            (item) => item.category == CategoryTags.Storyline.short,
        );

        if (!_.isEmpty(movies)) {
            const moviesText = ConvertTextToMultiple({
                text: copy.movie || "Movie",
                items: movies,
            });

            result = `${movies.length} ${moviesText}`;
        }

        if (!_.isEmpty(shows)) {
            const episodeStorylines = _.concat(episodes, storylines);
            const showsText = ConvertTextToMultiple({
                text: copy.show || "Show",
                items: shows,
            });

            result = `${shows.length} ${showsText}`;

            if (!_.isEmpty(episodeStorylines)) {
                const episodesText = ConvertTextToMultiple({
                    text: copy.episode || "Episode",
                    items: episodeStorylines,
                });

                result =
                    result +
                    " • " +
                    `${episodeStorylines.length} ${episodesText}`;
            }
        }
    }

    return result;
};

export default CollectionContentText;
