import React from "react";
import { isEmpty } from "lodash";
import { CoreInputText } from "../../../core/Form/components/inputs/CoreInput.text";
import { CoreInputTextWithChips } from "../../../core/Form/components/inputs/CoreInputTextWithChips";
import { CoreSelect } from "../../../core/Form/components/inputs/Select/components/CoreSelect";
import { CoreTableCellRenderMultipleChips } from "../../../core/Table/components/CoreTableCellRenderMultipleChips";
import { CollectionColumn } from "../../../core/Table/standalone-records/columns/Collection";
import ObservableTextInputWithChip from "../components/ObservableTextInputWithChip";
import { Brand } from "../containers/record/standalone/fields/Brand";
import { CategoryTvSeries } from "../containers/record/standalone/fields/CategoryTvSeries";
import { ChannelOfOrigin } from "../containers/record/standalone/fields/ChannelOfOrigin";
import { CountryOfOrigin } from "../containers/record/standalone/fields/CountryOfOrigin";
import {
    DisplayTitle,
    displayTitleOptions,
} from "../containers/record/standalone/fields/DisplayTitle";
import { EpisodeNo } from "../containers/record/standalone/fields/EpisodeNo";
import { Genres } from "../containers/record/standalone/fields/Genres";
import { OriginalLanguage } from "../containers/record/standalone/fields/OriginalLanguage";
import { OriginalProductionNo } from "../containers/record/standalone/fields/OriginalProductionNo";
import { ParentModularEpisodeDisabled } from "../containers/record/standalone/fields/ParentModularEpisodeDisabled";
import { ParentShowDisabled } from "../containers/record/standalone/fields/ParentShowDisabled";
import { Provenance } from "../containers/record/standalone/fields/Provenance";
import { ReleaseYear } from "../containers/record/standalone/fields/ReleaseYear";
import { RenderLongContent } from "../containers/record/standalone/fields/RenderLongContent";
import { StandaloneTitle } from "../containers/record/standalone/fields/StandaloneTitle";
import { SubGenres } from "../containers/record/standalone/fields/SubGenres";
import { ParentShow } from "../containers/record/tv-series/ParentShow";
import { ParentShowParentSeasonContainer } from "../containers/record/tv-series/ParentShowParentSeasonContainer";
import { Title } from "../containers/record/tv-series/Title";
import { SequencingSection } from "../containers/record/tv-series/sequencing/SequencingSection";
import { Inheritance } from "../containers/record/tv-series/smart/Inheritance";
import { NuvoRecord } from "../containers/record/tv-series/types/nuvo.types";
import {
    valueFormatterNumber,
    valueFormatterString,
} from "../containers/record/valueFormatters";
import { serdesFns } from "../helpers/serdes/customSerDesFunctions";
import { RenderCountryOfOrigin } from "./RenderCountryOfOrigin";
import { RenderOriginalLanguage } from "./RenderOriginalLanguage";
import {
    SECTION_LABELS,
    TAB_LABELS,
    sections,
} from "./clients/viacom/sections";
import { C100, C1024, C250, C255 } from "./clients/viacom/validation/constants";
import {
    AdditionalVT,
    AllFieldsVT,
    EpisodeVT,
    FieldObject,
    ModularStorylineVT,
    PrimaryVT,
    SeasonVT,
} from "./types";

import { SP4MPMIDCell } from "./SP4MPMIDCell";
export type TMFieldsConfig = ReturnType<typeof getViacomFieldsConfig>;

export const getViacomFieldsConfig = () => {
    const titleAka: FieldObject<AdditionalVT> = {
        path: "titles.aka",
        component: (
            <CoreInputTextWithChips<AdditionalVT>
                coreInputTextLabelProps={{
                    placeholder: "Enter AKA",
                    labelProps: {
                        text: "Also Known As (AKA)",
                    },
                    helperText:
                        "Press enter once finished typing to add the AKA Title",
                }}
                path="titles.aka"
            />
        ),
        tab: TAB_LABELS.Additional,
        section: sections[SECTION_LABELS.Additional_Titles],
        xs: 12,
        gridColDef: {
            field: "titles.aka",
            type: "string",
            headerName: "Also Known As (AKA)",
            minWidth: 162,
            renderCell: (params) => {
                return (
                    <CoreTableCellRenderMultipleChips value={params?.value} />
                );
            },
        },
        nuvoConfig: {
            label: "Also Known As (AKA)",
            key: "tm_titles_aka", // Has to not start with titles_ because Nuvo will ignore them
            columnType: "string",
            serdes: {
                type: "array",
            },
        },
    };

    const titleFka: FieldObject<AdditionalVT> = {
        path: "titles.fka",
        component: (
            <CoreInputTextWithChips<AdditionalVT>
                coreInputTextLabelProps={{
                    placeholder: "Enter FKA",
                    labelProps: {
                        text: "Formerly Known As (FKA)",
                    },
                    helperText:
                        "Press enter once finished typing to add the FKA Title",
                }}
                path="titles.fka"
            />
        ),
        tab: TAB_LABELS.Additional,
        section: sections[SECTION_LABELS.Additional_Titles],
        xs: 12,
        gridColDef: {
            field: "titles.fka",
            type: "string",
            headerName: "Formerly Known As (FKA)",
            minWidth: 192,
            renderCell: (params) => {
                return (
                    <CoreTableCellRenderMultipleChips value={params?.value} />
                );
            },
        },
        nuvoConfig: {
            label: "Formerly Known As (FKA)",
            key: "tm_titles_fka",
            columnType: "string",
            serdes: {
                type: "array",
            },
        },
    };

    const titleShort: FieldObject<AdditionalVT> = {
        path: "titles.short",
        component: (
            <ObservableTextInputWithChip<AdditionalVT>
                path="titles.short"
                placeholder="Enter Short Title"
                labelProps={{
                    text: "Short Title",
                }}
                maxLength={C255}
            />
        ),
        tab: TAB_LABELS.Additional,
        section: sections[SECTION_LABELS.Additional_Titles],
        xs: 6,
        gridColDef: {
            field: "titles.short",
            type: "string",
            headerName: "Short Title",
            width: 220,
            renderCell: (params) => {
                return (
                    <RenderLongContent
                        value={params?.value}
                        title="Short Title"
                        chipLabel={C255}
                    />
                );
            },
        },
        nuvoConfig: {
            label: "Short Title",
            key: "tm_titles_short",
            columnType: "string",
            serdes: {
                type: "basic",
            },
        },
    };

    const titleSort: FieldObject<AdditionalVT> = {
        path: "titles.sort",
        component: (
            <ObservableTextInputWithChip<AdditionalVT>
                path="titles.sort"
                placeholder="Enter Sort Title"
                labelProps={{
                    text: "Sort Title",
                }}
                maxLength={C255}
            />
        ),
        tab: TAB_LABELS.Additional,
        section: sections[SECTION_LABELS.Additional_Titles],
        xs: 6,
        gridColDef: {
            field: "titles.sort",
            type: "string",
            headerName: "Sort Title",
            width: 220,
            renderCell: (params) => {
                return (
                    <RenderLongContent
                        value={params?.value}
                        title="Sort Title"
                        chipLabel={C255}
                    />
                );
            },
        },
        nuvoConfig: {
            label: "Sort Title",
            key: "tm_titles_sort",
            columnType: "string",
            serdes: {
                type: "basic",
            },
        },
    };

    const titleAlternative: FieldObject<AdditionalVT> = {
        path: "titles.alternative",
        component: (
            <ObservableTextInputWithChip<AdditionalVT>
                path="titles.alternative"
                placeholder="Enter Alternative Title"
                labelProps={{
                    text: "Alternative Title",
                }}
                maxLength={C255}
            />
        ),
        tab: TAB_LABELS.Additional,
        section: sections[SECTION_LABELS.Additional_Titles],
        xs: 6,
        gridColDef: {
            field: "titles.alternative",
            type: "string",
            headerName: "Alternative Title",
            width: 220,
            renderCell: (params) => {
                return (
                    <RenderLongContent
                        value={params?.value}
                        title="Alternative Title"
                        chipLabel={C255}
                    />
                );
            },
        },
        nuvoConfig: {
            label: "Alternative Title",
            key: "tm_titles_alternative",
            columnType: "string",
            serdes: {
                type: "basic",
            },
        },
    };

    const titleRelease: FieldObject<AdditionalVT> = {
        path: "titles.release",
        component: (
            <CoreSelect<AdditionalVT>
                config={{
                    path: "titles.release",
                    labelProps: {
                        text: "Release Title",
                        isRequired: false,
                    },
                    options: displayTitleOptions,
                }}
            />
        ),
        tab: TAB_LABELS.Additional,
        section: sections[SECTION_LABELS.Additional_Titles],
        xs: 6,
        gridColDef: {
            field: "titles.release",
            type: "string",
            headerName: "Release Title",
            minWidth: 142,
            valueFormatter: valueFormatterString,
        },
        nuvoConfig: {
            label: "Release Title",
            key: "tm_titles_release",
            columnType: "category",
            dropdownOptions: displayTitleOptions.map((option) => ({
                value: option.value,
                label: option.label,
                type: "string",
            })),
            serdes: {
                type: "basic",
            },
        },
    };

    const descriptorsGenre: FieldObject<AdditionalVT> = {
        path: "descriptions.genre",
        component: <Genres />,
        tab: TAB_LABELS.Additional,
        section: sections[SECTION_LABELS.Descriptions],
        xs: 12,
        gridColDef: {
            field: "descriptions.genre",
            type: "string",
            headerName: "Genres",
            minWidth: 250,
            flex: 1,
            renderCell: (params) => {
                return (
                    <CoreTableCellRenderMultipleChips value={params?.value} />
                );
            },
        },
        nuvoConfig: {
            global: true, // Handled by NuvoUploader, not TM
            label: "Genres",
            key: "descriptors.genres",
            columnType: "string",
            serdes: {
                type: "custom",
                unflatten: false,
                deserializeFn: (nuvoRecord: NuvoRecord) => {
                    const genres = nuvoRecord.descriptors?.genres;
                    if (isEmpty(genres)) {
                        return [];
                    }

                    return genres.map((genre) => genre.value);
                },
                serializeFn: (genres: string[]) => {
                    return genres.join(", ");
                },
            },
        },
    };

    const descriptorsSubGenre: FieldObject<AdditionalVT> = {
        path: "descriptions.subGenre",
        component: <SubGenres />,
        tab: TAB_LABELS.Additional,
        section: sections[SECTION_LABELS.Descriptions],
        xs: 12,
        gridColDef: {
            field: "descriptions.subGenre",
            type: "string",
            headerName: "Sub-Genres",
            minWidth: 250,
            flex: 1,
            renderCell: (params) => {
                return (
                    <CoreTableCellRenderMultipleChips value={params?.value} />
                );
            },
        },
        nuvoConfig: {
            global: true, // Handled by NuvoUploader, not TM
            label: "Sub-Genres",
            key: "descriptors.sub_genres",
            columnType: "string",
            serdes: {
                type: "custom",
                deserializeFn: serdesFns.descriptions.subGenre.deserializeFn,
                serializeFn: serdesFns.descriptions.subGenre.serializeFn,
            },
        },
    };

    const description100: FieldObject<AdditionalVT> = {
        path: "descriptions.description100",
        component: (
            <ObservableTextInputWithChip<AdditionalVT>
                path={"descriptions.description100"}
                placeholder="Enter Super Short Description"
                labelProps={{
                    text: `Super Short Description (${C100})`,
                }}
                maxLength={C100}
            />
        ),
        tab: TAB_LABELS.Additional,
        section: sections[SECTION_LABELS.Descriptions],
        xs: 12,
        gridColDef: {
            field: "descriptions.description100",
            type: "string",
            headerName: `Super Short Description (${C100})`,
            width: 233,
            renderCell: (params) => {
                return (
                    <RenderLongContent
                        value={params?.value}
                        title="Short Description"
                        chipLabel={C100}
                    />
                );
            },
        },
        nuvoConfig: {
            label: `Super Short Description (${C100})`,
            key: "descriptions_description100",
            columnType: "string",
            serdes: {
                type: "basic",
            },
        },
    };

    const description250: FieldObject<AdditionalVT> = {
        path: "descriptions.description250",
        component: (
            <ObservableTextInputWithChip<AdditionalVT>
                path={"descriptions.description250"}
                placeholder="Enter Description"
                labelProps={{
                    text: `Description (${C250}) `,
                }}
                maxLength={C250}
                multiline
                rows={2}
            />
        ),
        tab: TAB_LABELS.Additional,
        section: sections[SECTION_LABELS.Descriptions],
        xs: 12,
        gridColDef: {
            field: "descriptions.description250",
            type: "string",
            headerName: `Description (${C250})`,
            width: 233,
            renderCell: (params) => {
                return (
                    <RenderLongContent
                        value={params?.value}
                        title="Description"
                        chipLabel={C250}
                    />
                );
            },
        },
        nuvoConfig: {
            label: `Description (${C250})`,
            key: "descriptions_description250",
            columnType: "string",
            serdes: {
                type: "basic",
            },
        },
    };

    const longDescription1024: FieldObject<AdditionalVT> = {
        path: "descriptions.longDescription1024",
        component: (
            <ObservableTextInputWithChip<AdditionalVT>
                path="descriptions.longDescription1024"
                placeholder="Enter Long Description"
                labelProps={{
                    text: `Long Description (${C1024})`,
                }}
                maxLength={C1024}
                multiline
                rows={3}
            />
        ),
        tab: TAB_LABELS.Additional,
        section: sections[SECTION_LABELS.Descriptions],
        xs: 12,
        gridColDef: {
            field: "descriptions.longDescription1024",
            type: "string",
            headerName: `Long Description (${C1024})`,
            width: 233,
            renderCell: (params) => {
                return (
                    <RenderLongContent
                        value={params?.value}
                        title="Long Description"
                        chipLabel={C1024}
                    />
                );
            },
        },
        nuvoConfig: {
            label: `Long Description (${C1024})`,
            key: "descriptions_longDescription1024",
            columnType: "string",
            serdes: {
                type: "basic",
            },
        },
    };

    const idRms: FieldObject<AdditionalVT> = {
        path: "ids.rms",
        component: (
            <CoreInputText<AdditionalVT>
                path="ids.rms"
                placeholder="Enter RMS ID"
                labelProps={{
                    text: `RMS ID`,
                }}
            />
        ),
        tab: TAB_LABELS.Additional,
        section: sections[SECTION_LABELS.IDs],
        xs: 12,
        gridColDef: {
            field: "ids.rms",
            type: "string",
            headerName: `RMS ID`,
            minWidth: 142,
            valueFormatter: valueFormatterString,
        },
        nuvoConfig: {
            label: "RMS ID",
            key: "ids.rmsId",
            columnType: "string",
            serdes: {
                type: "basic",
            },
        },
    };

    const idLegacySap: FieldObject<AdditionalVT> = {
        path: "ids.legacySap",
        component: (
            <CoreInputText<AdditionalVT>
                path="ids.legacySap"
                placeholder="Enter Legacy SAP ID (SAP-V ID)"
                labelProps={{
                    text: "Legacy SAP ID (SAP-V ID)",
                }}
            />
        ),
        tab: TAB_LABELS.Additional,
        section: sections[SECTION_LABELS.IDs],
        xs: 12,
        gridColDef: {
            field: "ids.legacySap",
            type: "string",
            headerName: "Legacy SAP ID (SAP-V ID)",
            minWidth: 200,
            valueFormatter: valueFormatterString,
        },
        nuvoConfig: {
            label: "Legacy SAP ID (SAP-V ID)",
            key: "ids.legacySapId",
            columnType: "string",
            serdes: {
                type: "basic",
            },
        },
    };

    const idS4Mpm: FieldObject<AdditionalVT> = {
        path: "ids.s4_mpm_id",
        component: (
            <CoreInputText<AdditionalVT>
                path="ids.s4_mpm_id"
                placeholder="Enter S4 MPM ID"
                labelProps={{
                    text: "S4 MPM ID",
                }}
            />
        ),
        tab: TAB_LABELS.Additional,
        section: sections[SECTION_LABELS.IDs],
        xs: 12,
        gridColDef: {
            field: "ids.s4_mpm_id",
            type: "string",
            headerName: "S4 MPM ID",
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <SP4MPMIDCell
                        value={params?.value}
                        rowId={params?.row?.id}
                    />
                );
            },
            // valueFormatter: valueFormatterString,
        },
        nuvoConfig: {
            label: "S4 MPM ID",
            key: "ids.s4_mpm_id",
            columnType: "string",
            serdes: {
                type: "basic",
            },
        },
    };

    const idChangeRecord: FieldObject<AdditionalVT> = {
        path: "ids.change_record_id",
        component: (
            <CoreInputText<AdditionalVT>
                path="ids.change_record_id"
                placeholder="Enter Change Record ID"
                labelProps={{
                    text: "Change Record ID",
                }}
            />
        ),
        tab: TAB_LABELS.Additional,
        section: sections[SECTION_LABELS.IDs],
        xs: 12,
        gridColDef: {
            field: "ids.change_record_id",
            type: "string",
            headerName: "Change Record ID",
            minWidth: 142,
            valueFormatter: valueFormatterString,
        },
        nuvoConfig: {
            label: "Change Record ID",
            key: "ids.change_record_id",
            columnType: "string",
            serdes: {
                type: "basic",
            },
        },
    };

    const otherChannelOfOrigin: FieldObject<AdditionalVT> = {
        path: "other.channelOfOrigin",
        component: <ChannelOfOrigin />,
        tab: TAB_LABELS.Additional,
        section: sections[SECTION_LABELS.Other],
        xs: 12,
        gridColDef: {
            field: "other.channelOfOrigin",
            type: "string",
            headerName: "Channel of Origin",
            minWidth: 142,
            valueFormatter: valueFormatterString,
        },
        nuvoConfig: {
            global: true, // Handled by NuvoUploader, not TM
            label: "Channel of Origin",
            key: "origination.channel_origin",
            columnType: "category",
            serdes: {
                type: "basic",
            },
        },
    };

    const standaloneCategory: FieldObject<PrimaryVT> = {
        path: "category",
        component: null,
        tab: TAB_LABELS.Primary,
        section: sections[SECTION_LABELS.Default],
        xs: 12,
        gridColDef: {
            field: "category",
            type: "string",
            headerName: "Record Category",
            minWidth: 142,
        },
        nuvoConfig: {
            label: "Record Category",
            key: "category",
            columnType: "string",
            example: "Show",
            serdes: {
                type: "basic",
            },
        },
    };

    const tvShowCategory: FieldObject<PrimaryVT> = {
        path: "category",
        component: <CategoryTvSeries />,
        tab: TAB_LABELS.Primary,
        section: sections[SECTION_LABELS.Default],
        xs: 12,
        gridColDef: {
            field: "category",
            type: "string",
            headerName: "Record Category",
            minWidth: 142,
        },
        nuvoConfig: {
            label: "Record Category",
            key: "category",
            columnType: "string",
            example: "Show",
            serdes: {
                type: "basic",
            },
        },
    };

    const tvShowCategoryEpisodeLevel: FieldObject<PrimaryVT> = {
        ...tvShowCategory,
        component: <CategoryTvSeries variant="episode-level" />,
    };

    const standaloneTitle: FieldObject<PrimaryVT> = {
        path: "title",
        component: <StandaloneTitle />,
        tab: TAB_LABELS.Primary,
        section: sections[SECTION_LABELS.Default],
        xs: 12,
        gridColDef: {
            field: "title",
            type: "string",
            headerName: "Record Title",
            minWidth: 300,
            flex: 1,
            // ! defining renderCell here won't work because it was hijacked by StandaloneTitleCell
        },
        nuvoConfig: {
            label: "Title - English",
            key: "original_title",
            columnType: "string",
            serdes: {
                type: "basic",
            },
        },
    };

    const tvShowTitle: FieldObject<AllFieldsVT> = {
        ...standaloneTitle,
        component: <Title />,
    };

    const id: FieldObject<PrimaryVT> = {
        path: "id",
        component: null,
        tab: null,
        section: null,
        xs: null,
        gridColDef: {
            field: "id",
            type: "number",
            headerName: "ID",
        },
        nuvoConfig: null,
    };

    const metaId: FieldObject<PrimaryVT> = {
        path: "metaId",
        component: null,
        tab: null,
        section: null,
        xs: null,
        gridColDef: {
            field: "metaId",
            type: "string",
            headerName: "Meta ID",
        },
        nuvoConfig: {
            global: true, // Handled differently
            label: "Meta ID",
            key: "metaId",
            columnType: "string",
            serdes: {
                type: "basic",
            },
        },
    };

    const releaseYear: FieldObject<PrimaryVT> = {
        path: "releaseYear",
        component: <ReleaseYear />,
        tab: TAB_LABELS.Primary,
        section: sections[SECTION_LABELS.Default],
        xs: 6,
        gridColDef: {
            type: "number",
            headerName: "Release Year",
            field: "releaseYear",
            width: 120,
            headerAlign: "left",
            align: "left",
            valueFormatter: valueFormatterNumber,
        },

        nuvoConfig: {
            label: "Release Year",
            key: "first_release_year",
            columnType: "date",
            outputFormat: "YYYY",
            dataType: "Number",
            serdes: {
                type: "basic",
                dataType: "number",
            },
        },
    };

    const brand: FieldObject<PrimaryVT> = {
        path: "brand",
        component: <Brand />,
        tab: TAB_LABELS.Primary,
        section: sections[SECTION_LABELS.Default],
        xs: 6,
        gridColDef: {
            type: "string",
            headerName: "Brand",
            field: "brand",
            width: 150,
            headerAlign: "left",
            align: "left",
            valueFormatter: valueFormatterString,
        },
        nuvoConfig: {
            label: "Brand",
            key: "origination_brand",
            columnType: "category",
            serdes: {
                type: "custom",
                deserializeFn: serdesFns.brand.deserializeFn,
                serializeFn: serdesFns.brand.serializeFn,
            },
        },
    };

    const provenance: FieldObject<PrimaryVT> = {
        path: "provenance",
        component: <Provenance />,
        tab: TAB_LABELS.Primary,
        section: sections[SECTION_LABELS.Default],
        xs: 6,
        gridColDef: {
            type: "string",
            headerName: "Provenance",
            field: "provenance",
            width: 150,
            headerAlign: "left",
            align: "left",
            valueFormatter: valueFormatterString,
        },
        nuvoConfig: {
            label: "Provenance",
            key: "origination_provenance",
            list: "client_managed_provenances",
            columnType: "category",
            serdes: {
                type: "custom",
                deserializeFn: serdesFns.provenance.deserializeFn,
                serializeFn: serdesFns.provenance.serializeFn,
            },
        },
    };

    const displayTitle: FieldObject<PrimaryVT> = {
        path: "displayTitle",
        component: <DisplayTitle />,
        tab: TAB_LABELS.Primary,
        section: sections[SECTION_LABELS.Default],
        xs: 6,
        gridColDef: {
            field: "displayTitle",
            type: "string",
            headerName: "Display Title",
            minWidth: 140,
            valueFormatter: valueFormatterString,
        },
        nuvoConfig: {
            label: "Display Title",
            key: "pickDisplayTitle", // This has to not be called "displayTitle" because Nuvo ignores it
            columnType: "category",
            dropdownOptions: displayTitleOptions.map((option) => ({
                value: option.value,
                label: option.label,
                type: "string",
            })),
            serdes: {
                type: "basic",
            },
        },
    };

    const originalLanguage: FieldObject<PrimaryVT> = {
        path: "originalLanguage",
        component: <OriginalLanguage />,
        tab: TAB_LABELS.Primary,
        section: sections[SECTION_LABELS.Default],
        xs: 6,
        gridColDef: {
            type: "string",
            headerName: "Primary Language",
            field: "originalLanguage",
            width: 200,
            headerAlign: "left",
            align: "left",
            renderCell: (params) => {
                return <RenderOriginalLanguage {...params} />;
            },
        },
        nuvoConfig: {
            label: "Primary Language",
            key: "origination_locale",
            columnType: "category",
            list: "primary_language",
            serdes: {
                type: "custom",
                deserializeFn: serdesFns.originalLanguage.deserializeFn,
                serializeFn: serdesFns.originalLanguage.serializeFn,
            },
        },
    };

    const countryOfOrigin: FieldObject<PrimaryVT> = {
        path: "countryOfOrigin",
        component: <CountryOfOrigin />,
        tab: TAB_LABELS.Primary,
        section: sections[SECTION_LABELS.Default],
        xs: 6,
        gridColDef: {
            type: "string",
            headerName: "Country of Origin",
            field: "countryOfOrigin",
            width: 200,
            headerAlign: "left",
            align: "left",
            renderCell: (params) => {
                return <RenderCountryOfOrigin {...params} />;
            },
        },
        nuvoConfig: {
            label: "Country Of Origin",
            key: "origination_countryIso",
            columnType: "category",
            list: "client_managed_countries",
            serdes: {
                type: "custom",
                deserializeFn: serdesFns.countryOfOrigin.deserializeFn,
                serializeFn: serdesFns.countryOfOrigin.serializeFn,
            },
        },
    };

    const episodeNo: FieldObject<EpisodeVT> = {
        path: "episodeNo",
        component: <EpisodeNo />,
        tab: TAB_LABELS.Primary,
        section: sections[SECTION_LABELS.Default],
        xs: 6,
        gridColDef: null,
        nuvoConfig: {
            label: "Episode/Storyline Number",
            key: "episode_number",
            columnType: "int",
            serdes: {
                type: "basic",
                dataType: "number",
            },
        },
    };

    const originalProductionNo: FieldObject<EpisodeVT> = {
        path: "originalProductionNo",
        component: <OriginalProductionNo />,
        tab: TAB_LABELS.Primary,
        section: sections[SECTION_LABELS.Default],
        xs: 6,
        gridColDef: null,
        nuvoConfig: {
            label: "Original Production Number",
            key: "original_production_number",
            columnType: "string",
            serdes: {
                type: "basic",
            },
        },
    };

    const childCategory: FieldObject<AllFieldsVT> = {
        path: "childCategory",
        component: <div>Only placeholder for childCategory</div>,
        tab: TAB_LABELS.Primary,
        section: sections[SECTION_LABELS.Default],
        xs: 12,
        gridColDef: null,
    };

    const sequences: FieldObject<AllFieldsVT> = {
        path: "sequences",
        component: <SequencingSection />,
        tab: TAB_LABELS.Primary,
        section: sections[SECTION_LABELS.Default],
        xs: 12,
        gridColDef: null,
        nuvoConfig: null,
    };

    const seasonEpisodeInheritance: FieldObject<AllFieldsVT> = {
        path: "seasonEpisodeInheritance",
        component: <Inheritance variant="seasonEpisodeInheritance" />,
        tab: TAB_LABELS.Primary,
        section: sections[SECTION_LABELS.Default],
        xs: 12,
        gridColDef: null,
        nuvoConfig: null,
    };

    const seasonInheritance: FieldObject<AllFieldsVT> = {
        path: "seasonInheritance",
        component: <Inheritance variant="seasonInheritance" />,
        tab: TAB_LABELS.Primary,
        section: sections[SECTION_LABELS.Default],
        xs: 12,
        gridColDef: null,
        nuvoConfig: null,
    };

    const episodeInheritance: FieldObject<AllFieldsVT> = {
        path: "episodeInheritance",
        component: <Inheritance variant="episodeInheritance" />,
        tab: TAB_LABELS.Primary,
        section: sections[SECTION_LABELS.Default],
        xs: 12,
        gridColDef: null,
        nuvoConfig: null,
    };

    const parentShow: FieldObject<SeasonVT> = {
        path: "parentShow",
        component: <ParentShow />,
        tab: TAB_LABELS.Primary,
        section: sections[SECTION_LABELS.Default],
        xs: 12,
        gridColDef: null,
        nuvoConfig: {
            global: true, // Handled differently
            label: "Parent Show",
            key: "parent_show",
            columnType: "string",
            serdes: {
                type: "custom",
                unflatten: true,
                deserializeFn: serdesFns.parentShow.deserializeFn,
                serializeFn: serdesFns.parentShow.serializeFn,
            },
        },
    };

    const parentShowDisabled: FieldObject<SeasonVT> = {
        ...parentShow,
        component: <ParentShowDisabled />,
    };

    const parentSeason: FieldObject<EpisodeVT> = {
        path: "parentSeason",
        component: <ParentShowParentSeasonContainer />,
        tab: TAB_LABELS.Primary,
        section: sections[SECTION_LABELS.Default],
        xs: 12,
        gridColDef: null,
        nuvoConfig: {
            global: true, // Handled differently
            label: "Parent Season",
            key: "parent_season",
            columnType: "string",
            serdes: {
                type: "custom",
                unflatten: true,
                deserializeFn: serdesFns.parentSeason.deserializeFn,
                serializeFn: serdesFns.parentSeason.serializeFn,
            },
        },
    };

    const parentModularEpisodeDisabled: FieldObject<ModularStorylineVT> = {
        path: "parentModularEpisode",
        component: <ParentModularEpisodeDisabled />,
        tab: TAB_LABELS.Primary,
        section: sections[SECTION_LABELS.Default],
        xs: 12,
        gridColDef: null,
        nuvoConfig: null,
    };

    const parentModularEpisode: FieldObject<ModularStorylineVT> = {
        path: "parentModularEpisode",
        component: null,
        tab: TAB_LABELS.Primary,
        section: sections[SECTION_LABELS.Default],
        xs: 12,
        gridColDef: null,
        nuvoConfig: {
            global: true, // Handled differently
            label: "Parent Modular Episode",
            key: "parent_modular_episode",
            columnType: "string",
            serdes: {
                type: "custom",
                unflatten: true,
                deserializeFn: serdesFns.parentModularEpisode.deserializeFn,
                serializeFn: serdesFns.parentModularEpisode.serializeFn,
            },
        },
    };

    const seasonNumber: FieldObject<SeasonVT> = {
        path: "seasonNo",
        component: null,
        tab: null,
        section: sections[SECTION_LABELS.Default],
        xs: 6,
        gridColDef: null,
        nuvoConfig: {
            label: "Season Number",
            key: "season_number",
            columnType: "int",
            serdes: {
                type: "custom",
                deserializeFn: serdesFns.seasonNo.deserializeFn,
                serializeFn: serdesFns.seasonNo.serializeFn,
            },
        },
    };

    const collection: FieldObject<AllFieldsVT> = {
        path: "collection",
        component: null,
        tab: TAB_LABELS.Primary,
        section: sections[SECTION_LABELS.Default],
        xs: 12,
        gridColDef: CollectionColumn,
        nuvoConfig: {
            global: true, // Handled differently
            label: "Collection",
            key: "collection",
            columnType: "string",
            serdes: {
                type: "custom",
                unflatten: true,
                deserializeFn: serdesFns.collection.deserializeFn,
                serializeFn: serdesFns.collection.serializeFn,
            },
        },
    };

    const viacomFieldsConfig = {
        id,
        metaId,

        title: standaloneTitle, // TODO These were added due to TaskManagerSerDes
        category: standaloneCategory, // TODO These were added due to TaskManagerSerDes

        releaseYear,
        titles: {
            aka: titleAka,
            fka: titleFka,
            short: titleShort,
            sort: titleSort,
            alternative: titleAlternative,
            release: titleRelease,
        },
        descriptions: {
            genre: descriptorsGenre,
            subGenre: descriptorsSubGenre,
            description100,
            description250,
            longDescription1024,
        },
        ids: {
            rms: idRms,
            legacySap: idLegacySap,
            s4_mpm_id: idS4Mpm,
            change_record_id: idChangeRecord,
        },
        other: {
            channelOfOrigin: otherChannelOfOrigin,
        },
        categories: {
            standaloneCategory,
            tvShowCategory,
            tvShowCategoryEpisodeLevel,
        },

        standaloneTitle,
        tvShowTitle,
        brand,
        provenance,
        displayTitle,
        originalLanguage,
        countryOfOrigin,
        episodeNo,
        originalProductionNo,
        childCategory,
        sequences,
        seasonEpisodeInheritance,
        seasonInheritance,
        episodeInheritance,
        parentShow,
        parentShowDisabled,
        parentSeason,
        parentModularEpisode,
        parentModularEpisodeDisabled,
        seasonNo: seasonNumber,
        collection,
    };

    return viacomFieldsConfig;
};
