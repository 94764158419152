import React, { useState } from "react";
import "./styles/_styles.scss";
import ScrollText from "react-scroll-text";

interface PropsInterface {
    children: React.JSX;
    noAnimation?: boolean;
}

const TextScrolling = ({ children, noAnimation }: PropsInterface) => {
    const [hover, setHover] = useState<boolean>(false);
    const shouldAnimate: boolean = hover && !noAnimation;

    return (
        <div
            className={`${
                !shouldAnimate
                    ? "scrolling-container"
                    : "scrolling-container-active"
            }`}
            onMouseEnter={() => (!hover ? setHover(true) : () => {})}
            onMouseLeave={() => (hover ? setHover(false) : () => {})}
        >
            {shouldAnimate && <ScrollText>{children}</ScrollText>}

            {!shouldAnimate && children}
        </div>
    );
};

export default TextScrolling;
