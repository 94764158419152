import IPlatformScheduling from "./platforms/PlatformSchedulingInterface";

export const headerTimeFormat = "D/M/YYYY HH:mm";

export const activeConfig = {
    text: "Active",
    color: "#1C654D",
    background: "#99E4CC",
    uppercase: true,
    styleType: "thin",
};

export const inactiveConfig = {
    text: "Inactive",
    color: "#CA2A2A",
    background: "#FFBEBE",
    uppercase: true,
    styleType: "thin",
};

export const scheduleInformationSectionConfig = (item: IPlatformScheduling) => [
    {
        name: "Platform",
        value: item.platform,
        type: "string",
    },
    {
        name: "Schedule ID",
        value: item.schedule_id,
        type: "string",
    },
    {
        name: "Platform ID",
        value: item.platform_id,
        type: "string",
    },
    {
        name: "Business Model",
        value: item.business_model,
        type: "string",
    },
];

export const scheduleWindowSectionConfig = (item: IPlatformScheduling) => [
    {
        name: "Territory",
        value: item.territory,
        type: "tag",
    },
    {
        name: "Countries",
        value: item.countries
            ? item.countries.map((country) => country.name)
            : [],
        type: "array",
    },
    {
        name: "Window ID",
        value: item.window_id,
        type: "string",
    },
    {
        name: "Put Up Date",
        value: item.put_up_date,
        type: "datetime",
        dateFormat: "Do MMMM YYYY, HH:mm",
    },
    {
        name: "Take Down Date",
        value: item.take_down_date,
        type: "datetime",
        dateFormat: "Do MMMM YYYY, HH:mm",
    },
    {
        name: "Comments",
        value: item.comments,
        type: "string",
    },
];

export const HeaderTags = (system: string) => {
    let boilerPlateHeaderConfig = {
        tags: [
            [
                {
                    text: "NON-LINEAR SCHEDULING SYSTEM",
                    color: "#626262",
                    background: "#CECECE",
                    uppercase: true,
                },
            ],
            [
                {
                    text: system,
                    color: "#737373",
                    background: "#E4E4E4",
                    uppercase: true,
                },
            ],
        ],
    };

    if (process.env.APP_ENV) {
        boilerPlateHeaderConfig.tags.push([
            {
                text: process.env.APP_ENV,
                color: "#1C654D",
                background: "#99E4CC",
                uppercase: true,
            },
        ]);
    }

    return boilerPlateHeaderConfig;
};
