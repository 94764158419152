import { observer } from "mobx-react";
import { CoreSelect } from "../../../../../../core/Form/components/inputs/Select/components/CoreSelect";
import { PrimaryVT } from "../../../../config/types";
import { useCountryOptions } from "../../../../hooks/useCountryOptions";
import useGlobalData from "../../../../hooks/useGlobalData";
import { HideFormConditionally } from "./HideFormConditionally";

export const CountryOfOrigin = observer(() => {
    const [cmsData, options] = useGlobalData(); // TODO: move to useLanguageOptions
    const countryOptions = useCountryOptions(options);
    return (
        <HideFormConditionally>
            <CoreSelect<PrimaryVT>
                config={{
                    path: "countryOfOrigin",
                    labelProps: {
                        text: "Country Of Origin",
                        isRequired: true,
                    },
                    options: countryOptions,
                    enableSearchbox: true,
                    multiple: false,
                }}
            />
        </HideFormConditionally>
    );
});
