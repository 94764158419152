import React from "react";
import { SectionInterface } from "./interface";
import UsergroupInformation from "../../src/UsergroupInformation";
import { getSectionCommonProps } from "../../actions";
import { useStore } from "../../../Global/store/store";

const SectionUsergroupInformation = (props: SectionInterface) => {
    const { state = {} } = props;

    const { handleUserGroupUpdate = () => {} } = state;

    const [globalState] = useStore();

    return (
        <UsergroupInformation
            {...getSectionCommonProps({ ...props, state: globalState })}
            {...props}
            handleChange={handleUserGroupUpdate.bind(this)}
        />
    );
};

export default SectionUsergroupInformation;
