import React from "react";
import { SectionInterface } from "./interface";
import CatalogAvailability from "../../src/CatalogAvailability";
import { getSectionCommonProps } from "../../actions";
import { useStore } from "../../../Global/store/store";

const SectionCatalogAvailability = (props: SectionInterface) => {
    const [globalState] = useStore();

    return (
        <CatalogAvailability
            {...getSectionCommonProps({ ...props, state: globalState })}
            {...props}
        />
    );
};

export default SectionCatalogAvailability;
