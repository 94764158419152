import React from "react";
import Component from "./simpleSlim";
import ClientManagedComponent from "../ClientManagedOptions/Component";

const ClientManagedSelectSimpleSlim = (props) => {
    const { isClientManaged } = props;

    if (isClientManaged === false || isClientManaged == undefined) {
        return <Component {...props} />;
    }

    return <ClientManagedComponent {...props} Component={Component} />;
};

export default ClientManagedSelectSimpleSlim;
