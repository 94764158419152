// This component will handle the business logic and will render the view (Awards table)

import React from "react";
import _ from "lodash";
import { AwardsTable } from "../../../../core/Table/awards/Awards.table";
import { observer } from "mobx-react";
import { useStores } from "../store/rootStore";
import NewAwardForm from "../view/forms/NewAwardForm";
import DeleteAwardForm from "../view/forms/DeleteAwardForm";
import EditContributorForm from "../view/forms/EditContributorForm";
import { StyledAwards } from "../styles/StyledAwards";
import ButtonBarContainer from "../../../../containers/ButtonBarContainer";
import ScriptSelect from "../view/components/ScriptSelect";
import Button from "../../../../components/Button";
import EditAwardForm from "../view/forms/EditAwardForm";
import { AwardsDataTablePayload } from "../../interfaces/awards.interfaces";

interface AwardsContainerProps {
    disabled: boolean;
    cmsData: any;
    contributors: any[];
}

const AwardsContainer: React.FC<AwardsContainerProps> = observer(
    (props: AwardsContainerProps) => {
        const { awardsStore } = useStores() || {};

        const { 
            disabled = true, 
            cmsData = {}, 
            contributors = [] 
        } = props;

        const addNewFormStatus: boolean = awardsStore.awardAddNewFormIsActive;
        const editFormStatus: boolean = awardsStore.awardEditFormIsActive;
        const deleteFormStatus: boolean = awardsStore.awardDeleteFormIsActive;
        const editContributorFormStatus: boolean = awardsStore.awardEditContributorFormIsActive;
        const awards: AwardsDataTablePayload[] = awardsStore.getTableData || [];

        return (
            <StyledAwards>
                <ButtonBarContainer
                    leftChildren={
                        <ScriptSelect
                            wrapperStyles={{
                                margin: "0 0.4375rem 0 0",
                                minWidth: "20rem",
                            }}
                        />
                    }
                    rightChildren={
                        <>
                            {awardsStore.selectedRows.length ? (
                                <Button
                                    value="Delete"
                                    onClick={() =>
                                        awardsStore.setAwardDeleteFormIsActive(
                                            true,
                                        )
                                    }
                                    type="button"
                                    marginLeftTop={true}
                                    buttonSection={true}
                                    buttonDelete={true}
                                />
                            ) : (
                                ""
                            )}

                            <Button
                                value="New Award"
                                onClick={() => awardsStore.handleNewItemClick()}
                                type="button"
                                marginLeftTop={true}
                                buttonSection={true}
                                disabled={disabled}
                                className={disabled ? "disabled" : ""}
                            />
                        </>
                    }
                />
                
                <AwardsTable 
                    awards={awards} 
                    disabled={disabled} 
                />

                {addNewFormStatus && (
                    <NewAwardForm
                        cmsData={cmsData}
                        contributors={contributors}
                    />
                )}

                {editFormStatus && (
                    <EditAwardForm
                        cmsData={cmsData}
                        contributors={contributors}
                    />
                )}

                {deleteFormStatus && <DeleteAwardForm />}

                {editContributorFormStatus && <EditContributorForm />}
            </StyledAwards>
        );
    },
);

export default AwardsContainer;
