const FetchCategoryConfig = (props) => {
    const { category = "", configs = [] } = props;

    return (
        (Array.isArray(configs) &&
            configs.find((item) => item.linking && item.linking == category)) ||
        {}
    );
};

export default FetchCategoryConfig;
