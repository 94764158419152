import CategoryTags from "../../../CategoryTags";

const config = {
    client: {
        fields: [
            {
                key: "ibms_id",
                disabledForCategories: [
                    CategoryTags.Extras.short,
                    CategoryTags.Short.short,
                ],
            },
        ],
    },
};

export default config;
