import _ from "lodash";

type Props = {
    dataStructure: Object;
    fieldPath: string;
    category: string;
    type: string;
    recordStatus?: string;
};

type CategoryLevel = {
    category: string;
    types: Array<string>;
};

const DisabledRuleActive = (props: Props): boolean => {
    const { dataStructure, fieldPath, category, type, recordStatus } = props;

    const fieldConfiguration = _.get(
        dataStructure,
        "fieldValidation." + fieldPath + ".disabled",
        false,
    );

    const fieldCategories = _.get(
        dataStructure,
        "fieldValidation." + fieldPath + ".categories",
        false,
    );

    const fieldValidationStatus = _.get(
        dataStructure,
        "fieldValidation." + fieldPath + ".recordStatus",
        false,
    );

    if (fieldValidationStatus && !_.isEmpty(fieldValidationStatus)) {
        if (!fieldValidationStatus.includes(recordStatus)) {
            return true;
        }
    }

    if (fieldCategories && !_.isEmpty(fieldCategories)) {
        if (!fieldCategories.includes(category)) {
            return true;
        }
    }

    if (fieldConfiguration && typeof fieldConfiguration === "boolean") {
        return true;
    }

    if (Array.isArray(fieldConfiguration)) {
        const categoryConfig = fieldConfiguration.find(
            (categoryLevel: CategoryLevel) => {
                return categoryLevel.category == category;
            },
        );
        if (!categoryConfig) {
            return false;
        }

        return !categoryConfig.types
            ? true
            : categoryConfig.types.includes(type);
    }

    return false;
};

export default DisabledRuleActive;
