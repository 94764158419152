import deepcopy from "deepcopy";
import _ from 'lodash';
import { envTest } from "../../../components/Environment";
import { ValidationResultInterface } from "../../SelectV2/interfaces";
import { ConfigOptionsInterface, FieldConfigInterface } from "../interfaces";
import transformBuilderPayload from "./utils/transformBuilderPayload/transformBuilderPayload";
import validateLength from "./utils/validateLength/validateLength"
import { ValidateRegionalPropsInterface } from "./interfaces";
import { userHasPermission } from "../../../services/PermissionService";
import { descriptionsSection } from "../../../features/UserRoleManagement/utils/sectionOptions/recordSections";
import { accessTypeUpdate, accessTypeView } from "../../../features/UserRoleManagement/utils/accessOptions";

export const validateSynopses = (props: ValidateRegionalPropsInterface): ValidationResultInterface => {
    const {
        result,
        options,
    } = props;

    const {
        data,
        clientDataStructure = {},
        builderConfig,
        userPermissions,
        cmsData,
    } = options;

    const {
        synopses: synopsesData,
    } = data;

    const hasAccess = (accessType) => {
        return userHasPermission({
            cmsData,
            userPermissions,
            pathField: descriptionsSection.path,
            access: accessType,
            prefix: data.category,
        })
    }

    const response: ValidationResultInterface = deepcopy(result);

    if(
        (!hasAccess(accessTypeView) || !hasAccess(accessTypeUpdate))
        && !envTest
    ) {
        return response
    }

    let fieldsConfig: FieldConfigInterface[] = clientDataStructure.synopses;

    if (!_.isEmpty(builderConfig)) {
        fieldsConfig = transformBuilderPayload(builderConfig.descriptions, 'synopsis');
    }

    const configOptions: ConfigOptionsInterface = {
        data: synopsesData,
        fieldType: 'synopsis',
        fieldsConfig,
    };

    try {
        validateLength({ response, options, configOptions })
    } catch (error) {
        console.log(error);
    }

    return response;
};
