import { Box, CardMedia, Chip, Paper, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { isEmpty, truncate } from "lodash";
import settings from "../../../../../../components/Settings";
import { PrimaryVT } from "../../../../config/types";
import { Categories } from "../types/types";
import { CategoryChip } from "./CategoryChip";

export type AutocompleteRecord = Pick<
    PrimaryVT,
    "title" | "releaseYear" | "metaId" | "category"
> & {
    image?: string;
    genres?: string[];
};

type AutocompleteRecordCardProps = {
    record: AutocompleteRecord;
};

const PaperWrapper = styled(Paper)(({ theme }) => ({
    borderRadius: 0,
    padding: 0,
    margin: 0,
    width: "100%",
}));

PaperWrapper.defaultProps = {
    variant: "outlined",
};

export const AutocompleteRecordCard = ({
    record: { title, releaseYear, metaId, category, image, genres = [] },
}: AutocompleteRecordCardProps) => {
    const imgageSrc = isEmpty(image)
        ? `${settings.images.path}/png/no-record-suggestion.png`
        : image;

    return (
        <PaperWrapper>
            <Stack direction="row" alignItems="flex-start">
                <CardMedia
                    component="img"
                    image={imgageSrc}
                    alt="Image"
                    sx={{ width: "4rem", height: "6rem" }}
                />

                <Stack
                    direction="column"
                    flex={1}
                    justifyContent="space-around"
                    sx={{
                        padding: "0.5rem",
                        overflow: "hidden",
                    }}
                >
                    <Stack
                        direction="row"
                        alignItems="flex-start"
                        flex={1}
                        justifyContent="space-around"
                    >
                        <Stack
                            direction="column"
                            flex={1}
                            justifyContent="space-around"
                            sx={{
                                padding: "0 0.5rem 0 0",
                                minWidth: "0", // To allow text ellipsis
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: 500,
                                    fontSize: "1rem",
                                    color: "rgba(47, 47, 47, 0.87)",
                                    margin: "0 0 0.1875rem 0",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                            >
                                {title}
                            </Typography>
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: "0.875rem",
                                    color: "rgba(47, 47, 47, 0.87)",
                                    margin: "0 0 0.1875rem 0",
                                }}
                            >
                                {releaseYear}
                            </Typography>
                        </Stack>

                        <Stack direction="row" alignItems="center">
                            <CategoryChip category={category} />
                            {metaId && (
                                <Chip
                                    sx={{
                                        borderRadius: "0.1875rem",
                                        margin: "0 0 0 0.3125rem ",
                                        padding: "0 0.3125rem 0 0",
                                        "& .MuiChip-icon": {
                                            margin: 0,
                                        },
                                        "& .MuiChip-label": {
                                            fontSize: "0.75rem",
                                            margin: "0 0 0 0.3125rem",
                                        },
                                    }}
                                    label={metaId}
                                    variant="filled"
                                    icon={
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M0 3C0 1.34315 1.34315 0 3 0H20V20H3C1.34315 20 0 18.6569 0 17V3Z"
                                                fill="#454545"
                                            />
                                            <path
                                                d="M14.8797 14.1124C14.8797 14.5359 14.535 14.8804 14.1113 14.8804H11.0377C7.8851 14.7297 5.34291 12.1675 5.12747 9.0311V5.88756C5.12747 5.46411 5.47217 5.11962 5.89587 5.11962H14.1185C14.5422 5.11962 14.8869 5.46411 14.8869 5.88756V14.1124H14.8797ZM16 14.2129V5.78708C16 4.80383 15.2029 4 14.2118 4H5.78815C4.79713 4 4 4.80383 4 5.78708V14.2129C4 15.1962 4.79713 16 5.78815 16H14.219C15.2029 15.9928 16 15.1962 16 14.2129Z"
                                                fill="white"
                                            />
                                        </svg>
                                    }
                                />
                            )}
                        </Stack>
                    </Stack>

                    {!isEmpty(genres) && (
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: "0.875rem",
                                color: "rgba(47, 47, 47, 0.57)",
                                margin: "0 0 0.1875rem 0",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}
                        >
                            {truncate(genres.join(", "), {
                                length: 80,
                            })}
                        </Typography>
                    )}
                </Stack>
            </Stack>
        </PaperWrapper>
    );
};

const NormalText = styled("div")(({ theme }) => ({
    fontSize: "0.875rem",
    color: "rgba(47, 47, 47, 0.87)",
    textAlign: "center",
}));

export const AutocompleteRecordCardTvShowEmpty = ({
    term,
    infoMessage = "",
}: {
    term: string;
    infoMessage?: string;
}) => {
    return (
        <PaperWrapper>
            <Stack
                direction="column"
                alignContent="center"
                justifyContent="center"
                width="100%"
                spacing={0.5}
                sx={{
                    height: "3.875rem",
                    borderRadius: "0.25rem",
                    border: "0.0625rem solid rgba(77, 77, 77, 0.10)",
                    background: "#FFF",
                    boxShadow:
                        "0.125rem 0.1875rem 0.3125rem 0 rgba(79, 94, 116, 0.15)",
                    lineHeight: "1.5",
                }}
            >
                <NormalText>
                    <Box
                        component="span"
                        sx={{
                            fontWeight: 500,
                            textDecoration: "underline",
                            color: "rgba(0, 0, 0, 1)",
                            marginRight: "0.25rem",
                        }}
                    >
                        {term}
                    </Box>

                    <span>does not exist in your catalog.</span>
                </NormalText>
                <NormalText>{infoMessage}</NormalText>
            </Stack>
        </PaperWrapper>
    );
};

export const AutocompleteRecordCardTvShowEmptyDemo = () => {
    return <AutocompleteRecordCardTvShowEmpty term="Hell’s Kitchen" />;
};

export const AutocompleteRecordCardDemo = () => {
    return (
        <AutocompleteRecordCard
            record={{
                title: "The Simpsons",
                releaseYear: 1989,
                metaId: "123456",
                category: Categories.Show,
                genres: [
                    "Matt Groening",
                    "James L. Brooks",
                    "Sam Simon",
                    "David Silverman",
                    "Wes Archer",
                    "Rich Moore",
                    "Jim Reardon",
                ],
            }}
        />
    );
};
