import React, { Fragment } from "react";
import _ from "lodash";
import TagSimple from "../../../Tag/simple";
import TagsTrack from "../../../TagsTrack";
import ActionButton from "../../../CustomSelect/ActionButton";
import ActionPlayButton from "../../../ActionPlayButton";
import RenderDoubleAssetDetailTag from "../RenderDoubleAssetDetailTag";

const RenderAssetDetailsLeft = (props) => {
    const { compliance = {}, dataStructure = {} } = props;

    const { videoAudioCompliance = {} } = dataStructure;

    const { video = [], audio = [] } = videoAudioCompliance;

    const marginRight = "0.3125rem";

    const dataTagProps = {
        background: "#bcbcbc",
        color: "#797979",
        styles: { marginRight },
    };

    const RenderTags = (props) => {
        const { name = "", classPrefix = "compliance" } = props;

        const value = compliance[name];

        return (
            <TagSimple
                text={!_.isEmpty(value) ? value : "-"}
                className={`${classPrefix}-${name.replace("_", "-")}`}
                {...dataTagProps}
            />
        );
    };

    const RenderVideoTags = () => {
        if (!_.isEmpty(video)) {
            return (
                <div style={{ display: "flex" }}>
                    {video.map((item, index) => (
                        <RenderTags name={item} key={index} />
                    ))}
                </div>
            );
        }

        return (
            <div style={{ display: "flex" }}>
                <RenderTags name="resolution" />
                <RenderTags name="fps" />
            </div>
        );
    };

    const RenderAudioTags = () => {
        if (!_.isEmpty(audio)) {
            return (
                <div style={{ display: "flex" }}>
                    {audio.map((item, index) => (
                        <RenderTags
                            name={item}
                            classPrefix="compliance-audio"
                            key={index}
                        />
                    ))}
                </div>
            );
        }

        return (
            <div style={{ display: "flex" }}>
                <RenderTags name="audio_format" />
                <RenderTags name="bit_depth" />
                <RenderTags name="sample_rate" />
            </div>
        );
    };

    return (
        <div className="audio-video--wrap" style={{ display: "flex" }}>
            <div style={{ display: "flex" }}>
                <TagSimple
                    background="#ffffff"
                    color="#787777"
                    text="Video"
                    styles={{ marginRight }}
                />

                <RenderVideoTags />
            </div>

            <div style={{ display: "flex" }}>
                <TagSimple
                    background="#ffffff"
                    color="#787777"
                    text="Audio"
                    styles={{ marginRight }}
                />

                <RenderAudioTags />
            </div>
        </div>
    );
};

const RenderAssetDetailsRight = (props) => {
    const { compliance = {}, dispatchNotification = () => {} } = props;

    const { duration, language_code, media_url } = compliance;

    const margin = "0 0.3125rem 0 0";
    return (
        <React.Fragment>
            <RenderDoubleAssetDetailTag
                textLeft="Duration"
                textRight={!_.isEmpty(duration) ? duration : "-"}
                margin={margin}
                className="compliance-duration"
            />

            <RenderDoubleAssetDetailTag
                textLeft="Language"
                textRight={!_.isEmpty(language_code) ? language_code : "-"}
                margin={margin}
                className="compliance-language-code"
            />

            <ActionPlayButton
                disabled={!media_url}
                styles={{ margin }}
                onClick={() => {
                    if (!_.isEmpty(media_url)) {
                        dispatchNotification({
                            state: "play video",
                            props: {
                                media_url,
                            },
                            dispatchNotification,
                        });
                    }
                }}
            />

            <ActionButton
                platformImage={true}
                label={false}
                selectStyles={{ margin }}
                groupedItems={true}
                items={[
                    {
                        label: "Video Workflows",
                        items: [
                            "AWS Rekognition • Labels, Celebrities + Moderation",
                            "AWS Transcribe • Transcription & Speaker Identification",
                            "WIREWAX • Automated Segmentation + EDLs [BETA]",
                            "WIREWAX • Subtitle Validation [BETA]",
                            "SMB • Generate / Transcode Preview Proxy",
                        ],
                    },
                    {
                        label: "Audio Workflows",
                        items: [
                            "AWS Transcribe • Transcription & Speaker Identification",
                            "Virtual AI • Music Licensing Report",
                        ],
                    },
                ]}
            />
        </React.Fragment>
    );
};

const AssetDetails = (props) => {
    const { title } = props;

    return (
        <div className="asset-details">
            <TagsTrack
                title={title}
                maxWidth={true}
                leftContent={<RenderAssetDetailsLeft {...props} />}
                rightContent={<RenderAssetDetailsRight {...props} />}
            />
        </div>
    );
};

export default AssetDetails;
