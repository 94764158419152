import React from 'react'
import _ from 'lodash';
import { makeObservable, action, extendObservable } from 'mobx'
import { RootStore } from './rootStore'
import { contributorsStoreInitialState } from "./state"
import { CreditInterface } from './interfaces';
import { creditsTable } from '../test-react/testData';
import { ContributorInterface } from '../../../Preferences/Contributors/interfaces';
import { envTest } from "../../../../components/Environment"
import { getContributor } from "../../../../services/Contributors"

export class ContributorsStore {
    rootStore: RootStore;
    contributors: ContributorInterface[];
    fetching: boolean;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            initialize: action,
            setContributors: action,
            handleFetchContributors: action,
            setFetching: action,
        });

        extendObservable(this, contributorsStoreInitialState);
    }

    initialize() {
        this.reset()
        this.setFetching(true)
    }

    reset() {
        Object.keys(contributorsStoreInitialState).forEach(
            (key) => (this[key] = contributorsStoreInitialState[key]),
        );
    }

    getContributorById(id: string): ContributorInterface {
        return this.contributors.find(contributor => contributor.id === id)
    }

    setContributors(contributors: ContributorInterface[]): void {
        this.contributors = contributors
    }

    setFetching(fetching: boolean): void {
        this.fetching = fetching
    }

    handleAddContributor(contributor: ContributorInterface): void {
        if (this.contributors.find(c => c?.id === contributor?.id)) {
            return
        } 

        this.setContributors([...this.contributors, contributor])
    }

    async handleFetchContributors(): Promise<void> {
        return envTest
            ? this.handleFetchContributorsTest()
            : this.handleFetchContributorsApi()
    }

     handleFetchContributorsTest(): void {
        this.setFetching(true)

        this.rootStore.creditsStore.credits?.forEach((credit: CreditInterface) => {
            const contributor: ContributorInterface = creditsTable.contributors.find(contributor => contributor.id === credit.contributor_id)

            if (contributor) {
                this.handleAddContributor(contributor)
            }
        })

        this.setFetching(false)
    }

    async handleFetchContributorsApi() {
        this.setFetching(true)

        for await (const credit of this.rootStore.creditsStore.credits) {
            const response = await getContributor(this.rootStore.globalState?.cmsData, credit.contributor_id)

            if (response.status === 200) {
                this.handleAddContributor(response.data)
            }
        }

        this.setFetching(false)
    }
}
