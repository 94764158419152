import _ from "lodash";
import {
    DescriptorsInterface,
    DescriptorInterface,
    getDescriptors,
    getGenres,
    getDescriptorText,
} from "./descriptors";
import {
    DurationInterface,
    getReleaseYear,
    getDuration,
    getDurationArray,
} from "./origination";
import { OriginationInterface } from "../../models/Origination/interfaces";
import { RatingsInterface } from "./ratings";
import { CertificateInterface, getMppaCertificate } from "./certifications";
import { ContributorInterface } from "./contributors";
import { getEpisodeNumber } from "./episodeNumbering";
import { Identifiers } from "./identifiers";
import { GlobalTaxonomyInterface } from "./globalTaxonomy";
import { LinkageInterface } from "./linkage";
import recordDatesTransformer from "../../components/Transformers/recordDatesTransformer"
import recordLinkageTransformer from "../../components/Transformers/recordLinkageTransformer"
import { CmsInterface } from "../../components/Global/interfaces";
import { SynopsisInterface } from './synopses';
import compose from "../../components/Helpers/compose"

export interface RecordInterface {
    meta_id: string;
    meta_id_temp?: string;
    descriptors: DescriptorsInterface;
    origination: OriginationInterface;
    ratings?: RatingsInterface;
    certifications?: CertificateInterface[];
    contributors?: ContributorInterface[];
    original_title: string;
    category?: string;
    category_name?: string;
    catalogs?: Array<string>;
    ids?: Identifiers;
    taxonomy?: GlobalTaxonomyInterface;
    linkage: LinkageInterface[];
    synopses: SynopsisInterface[];
    synopsis180?: string;
    child_count?: number;
    titles?: any[];
    status?: string;
    security_level?: number;
    eidr_status?: string;
    eidr_id?: string;
    deleted_at?: string;
}

export function getGenresAndReleaseYear(
    record: RecordInterface,
): string | false {
    let result: boolean | string = false;

    const descriptors: DescriptorsInterface = getDescriptors(record);
    const genres: DescriptorInterface[] = getGenres(descriptors);
    const genre: DescriptorInterface = genres[0] || {};
    const genreText: string = getDescriptorText(genre);
    const releaseYear = getReleaseYear(record);

    if (genreText) {
        result = genreText;
    }

    if (releaseYear) {
        result = releaseYear.toString();
    }

    if (genreText && releaseYear) {
        result = `${genreText} • ${releaseYear}`;
    }

    return result;
}

export function getMppaCertificateAndDuration(
    record: RecordInterface,
): string | false {
    let result = [];
    const certificate: string | false = getMppaCertificate(record);
    const duration: DurationInterface = getDuration(record);
    const durationArray: string[] = getDurationArray(duration);

    if (certificate) {
        result.push(certificate);
    }

    if (!_.isEmpty(durationArray)) {
        result.push(durationArray.join(" "));
    }

    return _.isEmpty(result) ? false : result.join(" • ");
}

export function getEpisodeNumberDuration(
    record: RecordInterface,
): string | false {
    const episodeNumber: number | false = getEpisodeNumber(record);
    const duration: DurationInterface = getDuration(record);
    const durationArray: string[] = getDurationArray(duration);

    let result = [];
    if (episodeNumber) result.push(`Episode ${episodeNumber}`);

    if (!_.isEmpty(durationArray)) {
        result.push(durationArray.join(" "));
    }

    return _.isEmpty(result) ? false : result.join(" • ");
}

export function getRecordCategory(record: RecordInterface): string {
    return record?.category || record?.category_name
}

export function getTransformedRecordFromApi(record: RecordInterface, cmsData: CmsInterface): RecordInterface {
    const dateFormat: string = cmsData?.clientDataStructure?.date_format
    const dateTransformer = (updatedRecord: RecordInterface) => recordDatesTransformer(updatedRecord, cmsData, dateFormat)

    const transformedRecord = compose(
        dateTransformer
    )

    return transformedRecord(record)
}

export function getTransformedRecordToApi(record: RecordInterface, cmsData: CmsInterface): RecordInterface {
    const apiDateFormat: string = cmsData?.clientDataStructure?.api_date_format

    const dateTransformer = (updatedRecord: RecordInterface) => recordDatesTransformer(updatedRecord, cmsData, apiDateFormat) 
    const linkageTransformer = (updatedRecord: RecordInterface) => recordLinkageTransformer(updatedRecord)

    const transformedRecord = compose(
        dateTransformer,
        linkageTransformer
    )

    return transformedRecord(record)
}