import { ASSET_PLATFORMS_CONFIG, ASSET_TYPES_CONFIG } from "../configuration";
import _ from "lodash";
import deepcopy from "deepcopy";
import AssetsStateInterface from "./state.interface";
import {
    SET_STATE,
    SET_NOTIFICATION,
    SET_ERROR_NOTIFICATION,
    SET_CONFIGURATION,
    SET_NEW_ASSET,
    SET_EDIT_ASSET,
    CONFIRM_ASSET_CREATE,
    CONFIRM_ASSET_EDIT,
    CONFIRM_ASSET_DELETE,
} from "./actions";
import AssetInterface from "../components/asset/interfaces/AssetInterface";

export default (state, action) => {
    const {
        handleFormChange = () => {},
        configuration = {},
        newAsset = {},
        assets = [],
    }: AssetsStateInterface = state;

    let resultState = {};

    switch (action.type) {
        case SET_STATE:
        case SET_NOTIFICATION:
        case SET_ERROR_NOTIFICATION:
        case SET_NEW_ASSET:
        case SET_EDIT_ASSET:
            resultState = Object.assign({}, state, action.state);
            break;
        case SET_CONFIGURATION:
            let configs = Object.assign({}, configuration, action.state);
            configs.isConfigurationInited =
                !_.isEmpty(configs[ASSET_PLATFORMS_CONFIG]) &&
                !_.isEmpty(configs[ASSET_TYPES_CONFIG]);
            resultState = Object.assign({}, state, {
                configuration: configs,
                loadingBuilderConfig: !configs.isConfigurationInited,
            });
            break;
        case CONFIRM_ASSET_CREATE:
            let assetStore: Array<AssetInterface> = deepcopy(assets);
            assetStore.push(newAsset);
            handleFormChange(assetStore);
            resultState = Object.assign({}, state, { assets: assetStore });
            break;
        case CONFIRM_ASSET_EDIT:
        case CONFIRM_ASSET_DELETE:
            handleFormChange(action.state.assets);
            resultState = Object.assign({}, state, action.state);
    }

    return resultState;
};
