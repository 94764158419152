import React from 'react'
import { observer } from "mobx-react";
import { useStores } from "../store/rootStore";
import { classPrefix } from '../utils/constants'
import config from "../config"

const CustomSections = observer(() => {
    const { navigationStore, optionsStore } = useStores()
    const activeView: any = config.customSectionViews[optionsStore.viewKey]

    if (!optionsStore.viewKey || !activeView) {
        return <></>
    }

    return (
        <div data-testid={`${classPrefix}-${navigationStore.getTabKeyByActiveIndex()}`}
            className={`${classPrefix}-custom-sections-view`}>

            <activeView.Component />
        </div>
    )
})

export default CustomSections