import React from "react";
import { SectionInterface } from "./interface";
import AWSRekognition from "../../views/AWSRekognition";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";

const SectionAWSRekognition = (props: SectionInterface) => {
    return (
        <AWSRekognition
            {...getSectionCommonProps({ ...props, field: "awards" })}
            name="rekognition"
            className="meta-record-rekognition"
            title="AWS Rekognition"
            lastSection={true}
        />
    );
};

export default SectionAWSRekognition;
