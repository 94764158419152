import React from "react";
import InputLabel from "../../Input/inputLabel";
import ClientManagedCountries from "../../ClientManagedOptions/countries";
import ClientManagedComponent from "../../ClientManagedOptions/Component";
import { CLIENT_MANAGED_COUNTRIES } from "../../ClientManagedOptions/options";
import isClientManaged from "../../ClientManagedOptions/isClientManaged";

const RenderCountryTitle = (props) => {
    const {
        options = [],
        item = {},
        clientFeatures = {},
        cmsData = {},
    } = props;

    const { apiUrls = {} } = cmsData;

    const { country } = item;

    const { countries = [] } = options;

    const countriesList = ClientManagedCountries({
        options,
        clientFeatures,
        fallback: countries,
    });

    const isClientManagedList = isClientManaged({
        clientFeatures,
        clientManagedType: CLIENT_MANAGED_COUNTRIES,
    });

    const RenderInputLabel = (props) => {
        const { data = [] } = props;

        let list = countriesList;
        if (isClientManagedList) list = data;

        let countryName = list.find((item) => item.value == country) || {};

        return (
            <InputLabel
                tag={countryName.value}
                text={countryName.text}
                margin="0"
            />
        );
    };

    return (
        <span className="meta-numbered-record-table--original-title">
            <ClientManagedComponent
                Component={RenderInputLabel}
                apiUrls={apiUrls}
                clientManagedType={CLIENT_MANAGED_COUNTRIES}
                clientFeatures={clientFeatures}
            />
        </span>
    );
};

export default RenderCountryTitle;
