import React, { Fragment } from "react";
import _ from "lodash";
import moment from "moment";
import update from "react-addons-update";
import deepcopy from "deepcopy";
import { diff } from "deep-object-diff";
import NumberFormat from "react-number-format";
import styles from "../styles";
import isIndexInArray from "../../Helpers/isIndexInArray";
import DottedContentArray from "../../DottedContentArray";
import DoubleContent from "../../DoubleContent";
import PlaceholderText from "../../PlaceholderText";
import InputLabel from "../../Input/inputLabel";
import MaterialDatePicker from "../../Input/MaterialDatePicker";
import Input from "../../Input/basic";
import Index from "./Index";
import Clients from "../../Clients";
import SelectWithTag from "../../SelectWithTag";
import TagSimple from "../../Tag/simple";
import AuditTooltip from "../../AuditTooltip";
import Label from "../../Input/label";
import { displaySectionFields } from "../actions";

export default class BoxOfficeAndPerformance extends Index {
    constructor(props) {
        super(props);

        /**
         * @var object
         */
        this.copy = this.props.copy || {};

        /**
         * @var object
         */
        this.styles = styles(this.props);

        /**
         * @var object
         */
        this.state = {
            data: deepcopy(this.props.data),
        };

        this.marginTop = { marginTop: "0.46875rem" };

        this.currency = this.setCurrency();
    }

    componentDidMount() {
        this._isMounted = true;
    }

    shouldComponentUpdate(nextProps, nextState) {
        const nextPropsData = nextProps.data || {};
        const thisPropsData = this.props.data || {};
        const difference = diff(nextPropsData, thisPropsData);

        if (!_.isEmpty(difference)) {
            return true;
        }

        if (!_.isEqual(nextProps.disabled, this.props.disabled)) {
            return true;
        }

        if (!_.isEqual(nextProps.country_iso, this.props.country_iso)) {
            return true;
        }

        if (!_.isEqual(nextState, this.state)) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props.data, prevProps.data)) {
            this.setStateValue("data", deepcopy(this.props.data));
        }
    }

    setCurrency() {
        const { options = {} } = this.props;

        const { currency = [] } = options;

        return currency.map((item) => {
            const { symbol, name, description } = item;

            return {
                ...item,
                value: name,
                text: `${symbol} • ${name} • ${description}`,
                tag: `${symbol} • ${name}`,
            };
        });
    }

    handleChange(key, subKey, value) {
        const { country_iso } = this.props;

        let data = { ...this.state.data };
        let updatedState = data;

        if (!data[country_iso]) {
            updatedState = update(data, {
                [country_iso]: {
                    $set: {},
                },
            });
        }

        let result = updatedState[country_iso][key] || {};

        // Object
        if (subKey) {
            result[subKey] = value;
        }

        // Single key
        if (!subKey) {
            result = value;
        }

        updatedState = update(updatedState, {
            [country_iso]: {
                [key]: {
                    $set: result,
                },
            },
        });

        this.setState(
            {
                data: updatedState,
            },
            () => {
                if (typeof this.props.handleFormChange == "function") {
                    this.props.handleFormChange(updatedState);
                }
            },
        );
    }

    renderNoneAdded() {
        return false;
    }

    renderNoneAddedPlaceholder() {
        return <PlaceholderText styles={this.marginTop} />;
    }

    renderCountryInputLabel(item) {
        const { gross, date, currency } = item;

        let momentDate = moment(date).format("Do MMMM YYYY");

        if (Clients.isClientEpix() && !_.isEmpty(momentDate)) {
            momentDate = moment(date).format("M/D/YYYY");
        }

        if (gross) {
            return (
                <DottedContentArray styles={this.marginTop}>
                    <InputLabel
                        text={
                            <NumberFormat
                                value={gross}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                            />
                        }
                        margin="0"
                    />

                    {currency && this.renderCurrencyTag(currency)}

                    {momentDate && date && (
                        <InputLabel
                            lighPrefix={item.asOf || "As of"}
                            text={momentDate}
                            margin="0"
                        />
                    )}
                </DottedContentArray>
            );
        }

        return this.renderNoneAddedPlaceholder();
    }

    renderAvarage(count, avarage) {
        if (count) {
            return (
                <DottedContentArray styles={this.marginTop}>
                    <InputLabel
                        text={
                            <NumberFormat
                                value={count}
                                displayType={"text"}
                                thousandSeparator={true}
                            />
                        }
                        margin="0"
                    />

                    {avarage && (
                        <InputLabel
                            text={
                                <NumberFormat
                                    value={avarage}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    suffix={` ${
                                        this.copy.average || "Average"
                                    }`}
                                />
                            }
                            margin="0"
                            light={true}
                            decimalScale={2}
                        />
                    )}
                </DottedContentArray>
            );
        }

        return this.renderNoneAddedPlaceholder();
    }

    renderInput(itemProps) {
        const { placeholder, value, handleChange, margin, width } = itemProps;

        return (
            <Input
                placeholder={placeholder}
                value={value}
                recordBasic={true}
                handleChange={handleChange}
                margin={margin}
                width={width}
                maxLength={12}
            />
        );
    }

    renderCurrencySelect(props) {
        const { value, handleChange, width } = props;

        return (
            <SelectWithTag
                value={value}
                name="currency_select"
                className="currency_select"
                clear={true}
                disabledText="Select Currency"
                noneSelectedText="None Selected"
                data={this.currency}
                handleChange={handleChange}
                margin="0 0 0 0.59375rem"
                width={width}
            />
        );
    }

    renderCurrencyTag(currency) {
        const currencyItem =
            this.currency.find((item) => item.value == currency) || {};

        return (
            <TagSimple
                background="#787878"
                color="white"
                text={currencyItem.tag || "-"}
                className="currency-tag"
            />
        );
    }

    renderEditState() {
        return false;
    }

    renderContent() {
        const {
            sectionOptions = {},
            disabled,
            clientFeatures = {},
        } = this.props;

        const { measurement = {} } = clientFeatures;

        const { readOnly = {} } = measurement;

        const data = deepcopy(this.state.data || []);
        const countryData = deepcopy(data[this.props.country_iso]) || {};

        const {
            opening_weekend_gross,
            opening_weekend_currency,
            lifetime_gross = {},
            opening_weekend_number_of_theatres,
            number_of_screens,
        } = countryData;

        const Avarage = (key, value) => {
            let avarage = false;

            if (value && countryData[key]) {
                let convertedGross = value;

                if (typeof value == "string") {
                    convertedGross = value.replace(/,/g, "");
                }

                avarage = parseInt(convertedGross) / parseInt(countryData[key]);
                avarage = parseFloat(avarage).toFixed(0);
            }

            return avarage;
        };

        return (
            <div style={this.styles.flexMarginContainer}>
                {displaySectionFields(
                    sectionOptions,
                    "opening_weekend_gross",
                ) && (
                    <div
                        style={this.styles.marginItemOneThird}
                        id="meta-box-office--opening-weekend-gross"
                    >
                        <AuditTooltip
                            dataSection={
                                "measurement_box_office_and_performance"
                            }
                            fieldName={"opening_weekend_gross"}
                            fieldKey={[
                                "measurement_box_office_and_performance",
                                "opening_weekend_gross",
                            ].join("_")}
                            scriptLanguage={this.props.country_iso || false}
                        >
                            <Label
                                label={
                                    this.copy.openingWeekenedGross ||
                                    "Opening Weekend Gross"
                                }
                                fieldKey={[
                                    "measurement_box_office_and_performance",
                                    "opening_weekend_gross",
                                ].join("_")}
                            />

                            {disabled &&
                                this.renderCountryInputLabel({
                                    gross: opening_weekend_gross,
                                    currency: opening_weekend_currency,
                                })}

                            {!disabled && (
                                <DoubleContent>
                                    {this.renderInput({
                                        placeholder:
                                            this.copy.enterGross ||
                                            "Enter Gross",
                                        value: opening_weekend_gross,
                                        handleChange: (gross) =>
                                            this.handleChange(
                                                "opening_weekend_gross",
                                                false,
                                                gross,
                                            ),
                                        margin: 0,
                                    })}

                                    {this.renderCurrencySelect({
                                        value: opening_weekend_currency,
                                        handleChange: (currency) =>
                                            this.handleChange(
                                                "opening_weekend_currency",
                                                false,
                                                currency,
                                            ),
                                    })}
                                </DoubleContent>
                            )}
                        </AuditTooltip>
                    </div>
                )}

                {displaySectionFields(sectionOptions, "lifetime_gross") && (
                    <div
                        style={this.styles.marginItemTwoThirds}
                        id="meta-box-office--lifetime-gross"
                    >
                        <AuditTooltip
                            dataSection={
                                "measurement_box_office_and_performance"
                            }
                            fieldName={"lifetime_gross"}
                            scriptLanguage={this.props.country_iso || false}
                            fieldKey={
                                "measurement_box_office_and_performance_lifetime_gross"
                            }
                        >
                            <Label
                                label={
                                    this.copy.lifetimeGross || "Lifetime Gross"
                                }
                                fieldKey={
                                    "measurement_box_office_and_performance_lifetime_gross"
                                }
                            />

                            {(disabled || readOnly["lifetime_gross"]) &&
                                this.renderCountryInputLabel(lifetime_gross)}

                            {!disabled && !readOnly["lifetime_gross"] && (
                                <DoubleContent widths={["23%", "43%", "33%"]}>
                                    {this.renderInput({
                                        placeholder:
                                            this.copy.enterLifetimeGross ||
                                            "Enter Lifetime Gross",
                                        value: lifetime_gross.gross,
                                        handleChange: (gross) =>
                                            this.handleChange(
                                                "lifetime_gross",
                                                "gross",
                                                gross,
                                            ),
                                        margin: 0,
                                    })}

                                    {this.renderCurrencySelect({
                                        value: lifetime_gross.currency,
                                        handleChange: (currency) =>
                                            this.handleChange(
                                                "lifetime_gross",
                                                "currency",
                                                currency,
                                            ),
                                    })}

                                    <MaterialDatePicker
                                        placeholder={
                                            this.copy.selectDate ||
                                            "Select a Date"
                                        }
                                        id="lifetime_gross_date"
                                        name="lifetime_gross_date"
                                        defaultValue={lifetime_gross.date}
                                        handleChange={(date) =>
                                            this.handleChange(
                                                "lifetime_gross",
                                                "date",
                                                date,
                                            )
                                        }
                                    />
                                </DoubleContent>
                            )}
                        </AuditTooltip>
                    </div>
                )}

                {displaySectionFields(
                    sectionOptions,
                    "opening_weekend_number_of_theatres",
                ) && (
                    <div
                        style={this.styles.flexMarginItem}
                        id="meta-box-office--opening-weekend-theatres"
                    >
                        <AuditTooltip
                            dataSection={
                                "measurement_box_office_and_performance"
                            }
                            fieldName={"opening_weekend_number_of_theatres"}
                            scriptLanguage={this.props.country_iso || false}
                            fieldKey={
                                "measurement_box_office_and_performance_opening_weekend_number_of_theatres"
                            }
                        >
                            <Label
                                label={
                                    this.copy.openingWeekenedNumberOfTheatres ||
                                    "Opening Weekend Number of Theatres"
                                }
                                fieldKey={
                                    "measurement_box_office_and_performance_opening_weekend_number_of_theatres"
                                }
                            />

                            {(disabled ||
                                readOnly[
                                    "opening_weekend_number_of_theatres"
                                ]) &&
                                this.renderAvarage(
                                    opening_weekend_number_of_theatres,
                                    Avarage(
                                        "opening_weekend_number_of_theatres",
                                        opening_weekend_gross,
                                    ),
                                )}

                            {!disabled &&
                                !readOnly[
                                    "opening_weekend_number_of_theatres"
                                ] &&
                                this.renderInput({
                                    placeholder:
                                        this.copy.enterNumberOfTheatres ||
                                        "Enter Number of Theatres",
                                    value: opening_weekend_number_of_theatres,
                                    margin: 0,
                                    handleChange: (
                                        opening_weekend_number_of_theatres,
                                    ) =>
                                        this.handleChange(
                                            "opening_weekend_number_of_theatres",
                                            false,
                                            opening_weekend_number_of_theatres,
                                        ),
                                })}
                        </AuditTooltip>
                    </div>
                )}

                {displaySectionFields(
                    sectionOptions,
                    "opening_weekend_gross",
                ) &&
                    measurement &&
                    measurement.total_number_of_screens && (
                        <div
                            style={this.styles.flexMarginItem}
                            id="meta-box-office--number-of-screens"
                        >
                            <AuditTooltip
                                dataSection={
                                    "measurement_box_office_and_performance"
                                }
                                fieldName={"opening_weekend_gross"}
                                scriptLanguage={this.props.country_iso || false}
                                fieldKey={
                                    "measurement_box_office_and_performance_opening_weekend_gross"
                                }
                            >
                                <Label
                                    label={"Total Number of Screens"}
                                    fieldKey={
                                        "measurement_box_office_and_performance_opening_weekend_gross"
                                    }
                                />

                                {(disabled ||
                                    readOnly["total_number_of_screens"]) &&
                                    this.renderAvarage(
                                        number_of_screens,
                                        Avarage(
                                            "number_of_screens",
                                            lifetime_gross.gross,
                                        ),
                                    )}

                                {!disabled &&
                                    !readOnly["total_number_of_screens"] &&
                                    this.renderInput({
                                        placeholder: "Enter Number of Screens",
                                        value: number_of_screens,
                                        margin: 0,
                                        handleChange: (number_of_screens) =>
                                            this.handleChange(
                                                "number_of_screens",
                                                false,
                                                number_of_screens,
                                            ),
                                    })}
                            </AuditTooltip>
                        </div>
                    )}
            </div>
        );
    }
}
