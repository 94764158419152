import { SET_EIDR_SEARCH } from "../actions/action_types";
import { defaultEidrSearch } from "../initialState";

export default function (state = { eidrSearch: defaultEidrSearch }, action) {
    switch (action.type) {
        case SET_EIDR_SEARCH:
            return Object.assign({}, state, {
                eidrSearch: action.eidrSearch,
            });
    }

    return state;
}
