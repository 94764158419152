import React from "react";
import CircleInput from "../CircleInput";
import "./styles/_styles.scss";

const CircleInputWithTitle = (props) => {
    const {
        className,
        copy = {},
        styles = {},
        title = "",
        selectAll,
        handleClick = () => {},
        circleTitle = "Select All",
        circleTitleFontWeight = 300,
        circleTitleStyles = {},
        circleBackground = "#e2e2e2",
        renderCircleInput = true,
    } = props;

    return (
        <div className={`meta-select-all ${className}`} style={styles}>
            <span>{title}</span>

            {renderCircleInput && (
                <div>
                    <span
                        style={{
                            fontWeight: circleTitleFontWeight,
                            ...circleTitleStyles,
                        }}
                    >
                        {circleTitle}
                    </span>

                    <CircleInput
                        background={circleBackground}
                        innerBackground={
                            selectAll ? "#787878" : circleBackground
                        }
                        width="1.34375rem"
                        borderWidth="0.25rem"
                        onClick={handleClick.bind(this)}
                    />
                </div>
            )}
        </div>
    );
};

export default CircleInputWithTitle;
