import extractCreditScriptedName from "./helpers/extractCreditScriptedName";
import extractCreditScriptedCharacterName from "./helpers/extractCreditScriptedCharacterName";
import { CreditInterface, DataTablePayload } from "../../credits.interface";
import { extractTextFromHtml } from "../../utils/helpers";

export default function generateTableRows(
    credits: CreditInterface[],
    contributorCallBack: (row: DataTablePayload) => void,
    script: string,
    defaultScript: string
): DataTablePayload[] {
    return credits.map(credit => {
        const rowReorderLabel: string = extractTextFromHtml(extractCreditScriptedName(script, credit))
        return {
            id: `${credit.local_id || credit.contributor_meta_id}-${credit.role_name}`,
            localID: credit.local_id as string,
            contributorName: extractCreditScriptedName(script, credit),
            role: credit.role_name as string,
            characterName: extractCreditScriptedCharacterName(script, defaultScript, credit),
            contributorID: credit.contributor_meta_id as string,
            imdbID: credit.imdb_id as string,
            creditID: credit.credit_meta_id as string,
            ranking: credit.ranking as number,
            onContributorNameClick: contributorCallBack,
            __reorder__: rowReorderLabel
        }
    })
}