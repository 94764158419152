import FieldInterface, { InputType } from '../../interfaces/Field.interface'

const getFieldDateTimeFormat = (field: FieldInterface): string => {
    if (field.type.input == InputType.Year) {
        return 'YYYY'
    }
    
    return field.type.datetTime?.format
}

export default getFieldDateTimeFormat