import React, { Component } from "react";
import styles from "./styles";

export default class CounterLabel extends Component {
    render() {
        const { counter, counterOverLimit, counterStyles = {} } = this.props;

        const classStyles = styles();

        return (
            <span
                style={Object.assign(
                    {},
                    classStyles.counter,
                    counterOverLimit && classStyles.counterOverLimit,
                    counterStyles,
                )}
                className="meta-input-counter"
            >
                {counter}
            </span>
        );
    }
}
