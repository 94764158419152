import _ from "lodash";

export default function GEEMultimediaDataTransformer(data) {
    const { video = [], imdb = {} } = data;

    const { title = "" } = imdb;

    if (!_.isEmpty(video)) {
        let result = [];

        video.forEach((item) => {
            const { mp4 = {}, m3u8 = {}, webm = {} } = item;

            let source = webm.url;

            if (m3u8 && m3u8.url) {
                source = m3u8.url;
            }

            if (mp4 && mp4.url) {
                source = mp4.url;
            }

            result.push({
                title,
                source,
                api: true,
            });
        });

        return result;
    }
}
