import React from "react";
import classStyles from "./styles";
import Input from "./basic";
import Index from "./index";
import _ from "lodash";
import $ from "jquery";
import "@chenfengyuan/datepicker/dist/datepicker";
import "@chenfengyuan/datepicker/dist/datepicker.css";

export default class DatePickerInput extends Index {
    componentWillMount() {
        const { dataToggle = "datepicker", format, cmsData } = this.props;

        this.format = cmsData?.clientDataStructure?.date_format
        
        this.dataToggle = dataToggle;
    }

    componentDidMount() {
        this.isComponentMounted = true;
        this.element = `[data-toggle="${this.dataToggle}"]`;

        this.setDatepickerValue();
        $(this.element).on("pick.datepicker", this.handleChange.bind(this));
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.value !== this.props.value) {
            this.setState(
                {
                    value: this.props.value,
                },
                () => {
                    this.setDatepickerValue();
                },
            );
        }

        if (prevProps.disabled !== this.props.disabled) {
            this.setDatepickerValue();
            $(this.element).on("pick.datepicker", this.handleChange.bind(this));
        }
    }

    setDatepickerValue() {
        $(this.element).datepicker({
            autoHide: true,
            format: this.format,
            date: this.state.value,
            startDate: this.props.startDate || false,
            endDate: this.props.endDate || false,
        });
    }

    handleChange(e) {
        if (e && e.target) {
            let value = e.target.value;

            if (e && e.date) {
                value = $(this.element).datepicker("getDate", true);
            }

            this.setState(
                {
                    value,
                },
                () => {
                    this.handleChangeCallback();
                },
            );
        }
    }

    renderDatePicker() {
        const { value } = this.state;

        return (
            <DatePicker
                variant="inline"
                label="Only calendar"
                helperText="No year selection"
                value={value}
                onChange={this.handleChange.bind(this)}
            />
        );
    }

    render() {
        const { noneEntered, recordBasic, disabled } = this.props;

        const recordBasicOptions = recordBasic
            ? this.getRecordBasicProps()
            : {};
        const optionsProps = {
            ...this.props,
            ...recordBasicOptions,
        };

        let options = this.getOptions(optionsProps) || {};
        let styles = classStyles();

        const updatedStyles = Object.assign(
            {},
            options.style,
            !disabled && styles.chevronbackground,
            !disabled &&
                this.props.chevronbackgroundDark &&
                styles.chevronbackgroundDark,
            disabled && { margin: 0 },
        );

        options.style = updatedStyles;
        const displayNoneEntered = disabled && noneEntered && !this.state.value;

        return (
            <div
                style={classStyles(optionsProps, this.state.value).container}
                ref={(node) => {
                    this.node = node;
                }}
            >
                {this.renderLabel()}

                {displayNoneEntered && (
                    <Input
                        placeholder="None Entered"
                        disabled={true}
                        margin="0"
                        width="100%"
                    />
                )}

                {!displayNoneEntered && (
                    <React.Fragment>
                        {disabled && this.renderDisabledInput(options)}

                        {!disabled && (
                            <input
                                {...options}
                                ref={(ref) => (this.inputRef = ref)}
                                data-toggle={this.dataToggle}
                            />
                        )}
                    </React.Fragment>
                )}
            </div>
        );
    }
}
