import _ from "lodash";
import SendRequest from "../../components/SendRequest";

const HandleFetchOptionsRequst = (props) => {
    const {
        state = {},
        onSuccess = () => {},
        onError = () => {},
        testingData = {},
    } = props;

    const { set = () => {}, cmsData = {} } = state;

    const { apiUrls = {} } = cmsData;

    let options = {
        url: apiUrls.optionsUrl || "",
        method: "GET",
    };

    if (!_.isEmpty(testingData)) {
        options.testingData = testingData;
    }

    const handleError = (error) => {
        set({
            notification: {
                title: "Technical Error",
                description: "Failed to fetch options",
                status: "alert",
                onClick: () => {
                    set({
                        notification: {},
                    });
                },
            },
        });

        onError(error);

        console.log(error);
    };

    try {
        SendRequest(
            options,
            (response) => {
                const { data, message, status } = response;

                if (status == 200 && data) {
                    set({
                        options: data,
                    });

                    onSuccess(data);

                    return;
                }

                handleError(message);
            },
            (error) => {
                handleError(error);
            },
        );
    } catch (e) {
        handleError(e);
    }
};

export default HandleFetchOptionsRequst;
