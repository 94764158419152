import React from "react";
import BubbleList from "../../../BubbleList/simpleOnEnter";
import DataFetch from "./DataFetch";
import BubleListSearchDataList from "../../../AddTitleToCollection/src/BubleListSearchDataList";

export default class LinkSimpleMultiple extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchValue: "",
            searchList: [],
            searchListLinks: [],
            readySearchSelection: false,
        };

        this.updateSearchList = this.updateSearchList.bind(this);
    }

    onChange(data) {
        this.setState({ resultData: data });
        this.props.onChange(data);
    }

    updateSearchList(data) {
        const list = BubleListSearchDataList({ searchDataList: data });
        let prevSearchValueLinks = this.state.searchListLinks;
        let searchValueLinks = prevSearchValueLinks.concat(data);
        this.setState({ searchList: list, searchListLinks: searchValueLinks });
    }

    handleSearchSelection(value) {
        value = value.map((item) => {
            if (!item.meta_id) item.meta_id = item.value;
            return item;
        });

        this.setState({ searchValue: value, readySearchSelection: true });
        let records = DataFetch.recordsFromArray({
            records: this.state.searchListLinks,
            searchable: value,
        });

        this.onChange(records);
    }

    render() {
        return (
            <div className="new-linkage-simple-multiple-search">
                <BubbleList
                    label="Search"
                    background="rgb(28, 101, 77)"
                    color="white"
                    deleteWhite={true}
                    emptyPlaceholder="Start Typing Name"
                    handleFormChange={(item) =>
                        this.handleSearchSelection(item)
                    }
                    filterStaticDataList={false}
                    useEnterButtonOnSelect={false}
                    readOnly={false}
                    data={this.state.searchValue}
                    single={true}
                    datalist={this.state.searchList}
                    handleChange={(search) => {
                        DataFetch.links({
                            searchQuery: search,
                            searchUrl: this.props.searchUrl,
                            recordType: this.props.linkCategory,
                            onResult: this.updateSearchList,
                        });
                        this.setState({ readySearchSelection: false });
                    }}
                    openOnClick={false}
                    wrapperStyles={{
                        marginTop: "1.8rem",
                    }}
                />
            </div>
        );
    }
}
