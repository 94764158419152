import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../store/rootStore";
import { useStore as globalStore } from "../../../../../components/Global/store/store";
import ClientManagedScripts from "../../../../../components/ClientManagedOptions/scripts";
import { AWARDS } from "../../../../../components/ClientManagedOptions/options";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconComponent from "./IconComponent";
import {
    ScriptSelectProps,
    LanguageObjectInterface,
} from "../../awards.interface";

const ScriptSelect: React.FC<ScriptSelectProps> = observer((props) => {
    const { wrapperStyles } = props;

    const rootStore = useStores();
    const [globalState] = globalStore() || [{}];

    const { cmsData = {}, options = {} } = globalState;

    const { clientFeatures = {} } = cmsData;

    //const defaultScript: string = 'eng'
    const scriptValue: string = rootStore.scriptValue;

    const [selectedOption, setSelectedOption] =
        useState<LanguageObjectInterface>({
            text: "",
            value: "",
        });

    useEffect(() => {
        let targetScriptIndex: number = scriptOptions.findIndex(
            (option) => option.value === scriptValue,
        );
        if (targetScriptIndex !== -1)
            setSelectedOption({
                text: scriptOptions[targetScriptIndex].text,
                value: scriptOptions[targetScriptIndex].value,
            });
    }, [scriptValue]);

    const scriptOptions: LanguageObjectInterface[] = ClientManagedScripts({
        options,
        fallback: options.scripts || [],
        clientFeatures,
        listKey: AWARDS,
    });

    const MenuProps: object = {
        PaperProps: {
            style: {
                background: "#E9E6EB",
                borderRadius: "0.375rem",
                border: "0.063rem solid #B8B8B8",
                marginTop: "0.4375rem",
                padding: "0 0.5rem",
                maxHeight: "23.75rem",
            },
        },
    };

    const handleSelect = (event: SelectChangeEvent) => {
        const scriptIndex: number = scriptOptions.findIndex(
            (option) => option.value === event.target.value,
        );

        if (scriptIndex !== -1) {
            rootStore.setScriptValue(event.target.value);
            setSelectedOption(scriptOptions[scriptIndex]);
        }
    };

    return (
        <React.Fragment>
            <div className="meta-select-mui" style={wrapperStyles}>
                <Select
                    value={selectedOption.value}
                    renderValue={() => (
                        <div className="meta-select-row">
                            <div className="meta-select-tag">
                                <span>{selectedOption.value}</span>
                            </div>
                            <div className="meta-select-value">
                                {selectedOption.text}
                            </div>
                        </div>
                    )}
                    onChange={(event: SelectChangeEvent) => handleSelect(event)}
                    IconComponent={IconComponent}
                    MenuProps={MenuProps}
                    style={wrapperStyles}
                    className="meta-select-with-tag"
                >
                    {scriptOptions.map((option) => {
                        return (
                            <MenuItem
                                key={option.value}
                                value={option.value}
                                className="meta-select-options--item"
                            >
                                <div className="value">
                                    <div className="text">{option.text}</div>
                                    <div className="meta-select-tag">
                                        <span>{option.value}</span>
                                    </div>
                                </div>
                            </MenuItem>
                        );
                    })}
                </Select>
            </div>
        </React.Fragment>
    );
});

export default ScriptSelect;
