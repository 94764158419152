import React from "react";
import _ from "lodash";
import PlaceholderText from "../PlaceholderText";
import BorderRadius from "../BorderRadius";
import MetaRows from "../MetaRows";
import RenderTag from "./src/RenderTag";
import CyclesTransformer from "./src/CyclesTransformer";
import ConvertTextToMultiple from "../ConvertTextToMultiple";
import "./styles/_styles.scss";

const Cycles = (props) => {
    const { data = {}, styles = {} } = props;

    const cyclesData = data.data || [];
    const transformedData = CyclesTransformer(data);

    return (
        <div style={styles} className="meta-cycles">
            {_.isEmpty(cyclesData) && <PlaceholderText />}

            {!_.isEmpty(cyclesData) && (
                <BorderRadius
                    styles={{
                        width: "100%",
                    }}
                >
                    <MetaRows>
                        {Object.keys(transformedData).map((month, index) => {
                            const item = transformedData[month];
                            let categories = [];

                            const moviesText = ConvertTextToMultiple({
                                text: "Movie",
                                items: item.movies,
                            });

                            const episodesText = ConvertTextToMultiple({
                                text: "Episode",
                                items: item.episodes,
                            });

                            if (item.movies)
                                categories.push(`${item.movies} ${moviesText}`);
                            if (item.episodes)
                                categories.push(
                                    `${item.episodes} ${episodesText}`,
                                );

                            return (
                                <div key={index} className="meta-cycle">
                                    <span className="text title">{month}</span>
                                    <span className="text categories">
                                        {categories.join(", ")}
                                    </span>

                                    <RenderTag
                                        text="Overall Margin"
                                        centerText={item.total_margin}
                                        rightText={item.margin}
                                        styles={{
                                            marginLeft: "0.75rem",
                                        }}
                                    />
                                </div>
                            );
                        })}
                    </MetaRows>
                </BorderRadius>
            )}
        </div>
    );
};

export default Cycles;
