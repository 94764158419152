import { Box, Stack, Typography } from "@mui/material";
import Settings from "../../components/Settings";

// TODO: add types
export const AutocompleteGroupComponent = ({
    params,
    headerText,
    disableEmptyCard,
    hideSuggestions,
}) => {
    return (
        <Box key={params.key}>
            <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                    margin: "0 0 0.625rem 0",
                }}
            >
                <Typography
                    sx={{
                        color: "rgba(47, 47, 47, 0.57)",
                        fontSize: "0.75rem",
                        fontWeight: "500",
                    }}
                >
                    {headerText}
                </Typography>
                <Box
                    sx={{
                        borderRadius: "0.25rem",
                        cursor: disableEmptyCard ? "auto" : "pointer",
                        display: "flex",
                        height: "1.25rem",
                        padding: "0.0625rem",
                        width: "1.25rem",
                        transition: "all .2s ease",
                        "& img": {
                            opacity: "0.7",
                        },
                        "&:hover": {
                            background: "#E0E0E0",
                            "& img": {
                                opacity: 1,
                            },
                        },
                    }}
                    onClick={() => {
                        hideSuggestions();
                    }}
                >
                    <img src={`${Settings.images.path}/svg/close-icon.svg`} />
                </Box>
            </Stack>

            {params.children}
        </Box>
    );
};
