import React from "react";
import { SectionInterface } from "./interface";
import RegionalProperties from "../../views/RegionalProperties";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";

const SectionRegionalProperties = (props: SectionInterface) => {
    const { data = {} } = props;

    return (
        <RegionalProperties
            {...getSectionCommonProps({
                ...props,
                field: "regional_properties",
            })}
            name="RegionalProperties"
            className="meta-record-regional-properties"
            title="Regional Properties"
            data={data.regional_properties || {}}
            noBorder={true}
        />
    );
};

export default SectionRegionalProperties;
