import { envTest } from "../../../components/Environment";

export const SET_CREATE_NEW: string = "SET_CREATE_NEW";
export const SET_EDIT: string = "SET_EDIT";
export const SET_CREATE_NEW_INITIAL: string = "SET_CREATE_NEW_INITIAL";
export const SET_EDIT_INITIAL: string = "SET_EDIT_INITIAL";
export const SET_OPEN_DIALOG: string = "SET_OPEN_DIALOG";
export const SET_ACCESS_POLICY: string = "SET_ACCESS_POLICY";
export const SET_ALL_DISCOVERY_CATEGORIES: string =
    "SET_ALL_DISCOVERY_CATEGORIES";
export const SET_SAVE_POLICY_CHANGES: string = "SET_SAVE_POLICY_CHANGES";
export const SET_BASIC_NOTIFICATION: string = "SET_BASIC_NOTIFICATION";
export const SET_WORKING: string = "SET_WORKING";
export const SET_ACCESS_CATALOGS: string = "SET_ACCESS_CATALOGS";
export const SET_RECORD_VALUE: string = "SET_RECORD_VALUE";
export const SET_DEFAULT_STATE: string = "SET_DEFAULT_STATE";
export const SET_POLICIES: string = "SET_POLICIES";
export const SET_FETCHING_POLICIES: string = "SET_FETCHING_POLICIES";
export const SET_FETCHING_ROLES: string = "SET_FETCHING_ROLES";
export const SET_DELETING_ID: string = "SET_DELETING_ID";
export const SET_ROLES: string = "SET_ROLES";
export const SET_NOTIFICATION_OPTIONS: string = "SET_NOTIFICATION_OPTIONS";
export const USER_ROLE_MANAGEMENT_STATE = "USER_ROLE_MANAGEMENT_STATE";
export const SET_SEARCH_TERM = "SET_SEARCH_TERM";

import { InitialStateType, initialState } from "./context";
import {
    handleSetAccessPolicy,
    handleSetAllDiscoveryCategories,
    handleSetRecordValue,
    setValidationMessage,
} from "./actions";
import _ from "lodash";
import deepcopy from "deepcopy";

export const userRoleManagementReducer = (
    state: InitialStateType,
    action: any,
) => {
    let result: any = {
        ...state,
    };

    const actionProps: any = {
        state,
        action,
    };

    if (action.type == SET_DEFAULT_STATE) {
        result = {
            ...result,
            ...initialState,
            accessCatalogs: state.accessCatalogs,
        };
    }

    if (action.type == SET_SEARCH_TERM) {
        result.searchTerm = action.searchTerm;
    }

    if (action.type == SET_CREATE_NEW) {
        result.createNew = action.createNew;
        result.validationMessage = setValidationMessage(action.createNew);

        if (_.isEmpty(action.createNew)) {
            result.createNewInitial = {};
        }
    }

    if (action.type == SET_EDIT) {
        result.edit = action.edit;
        result.validationMessage = setValidationMessage(action.edit);

        if (_.isEmpty(action.edit)) {
            result.editInitial = {};
        }
    }

    if (action.type == SET_CREATE_NEW_INITIAL) {
        result.createNewInitial = deepcopy(action.createNewInitial);
    }

    if (action.type == SET_EDIT_INITIAL) {
        result.editInitial = deepcopy(action.editInitial);
    }

    if (action.type == SET_OPEN_DIALOG) {
        result.dialogIsShown = action.dialogIsShown;
    }

    if (action.type == SET_BASIC_NOTIFICATION) {
        result.basicNotification = action.basicNotification;
    }

    if (action.type == SET_WORKING) {
        result.working = action.working;
    }

    if (action.type == SET_ACCESS_CATALOGS) {
        result.accessCatalogs = action.accessCatalogs;
    }

    if (action.type == SET_POLICIES) {
        result.policies = action.policies;
    }

    if (action.type == SET_FETCHING_POLICIES) {
        result.fetchingPolicies = action.fetchingPolicies;
    }

    if (action.type == SET_FETCHING_ROLES) {
        result.fetchingRoles = action.fetchingRoles;
    }

    if (action.type == SET_DELETING_ID) {
        result.deletingId = action.deletingId;
    }

    if (action.type == SET_ROLES) {
        result.roles = action.roles;
    }

    if (action.type == SET_NOTIFICATION_OPTIONS) {
        result.notificationOptions = action.notificationOptions;
    }

    if (action.type == SET_ACCESS_POLICY) {
        result = handleSetAccessPolicy(actionProps);
    }

    if (action.type == SET_ALL_DISCOVERY_CATEGORIES) {
        result = handleSetAllDiscoveryCategories(actionProps);
    }

    if (action.type == SET_RECORD_VALUE) {
        result = handleSetRecordValue(actionProps);
    }

    if (envTest) {
        process[USER_ROLE_MANAGEMENT_STATE] = result;
    }

    return result;
};
