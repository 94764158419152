import _ from "lodash";
import CategoryTags from "../../../components/CategoryTags";

const HandleCollectionTitleQueryListSelection = (props) => {
    const { querySelection = {}, state } = props;

    const { collectionTitle = {} } = state;

    if (_.isEmpty(querySelection)) {
        state.setCollectionTitleKey("selectedTitle", {});
        return;
    }

    const {
        original_title,
        category_name,
        category,
        first_release_year,
        meta_id,
        synopsis180,
        titles,
        linkage,
    } = querySelection;

    const title = {
        original_title,
        category: category || category_name,
        first_release_year,
        meta_id,
        synopsis180,
        titles,
        linkage,
    };

    state.setCollectionTitleKey("selectedTitle", title);
    state.setCollectionTitleKey("episodes", []);
    state.setCollectionTitleKey("specials", []);

    if (collectionTitle.category == CategoryTags.Special.short) {
        const { linkage = [] } = title;

        const specials =
            linkage.filter(
                (item) => item.category == CategoryTags.Special.short,
            ) || [];
        state.setCollectionTitleKey("specials", specials);
    }
};

export default HandleCollectionTitleQueryListSelection;
