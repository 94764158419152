import { GetFromLocalStorage } from "../Storage/localStorage";

class Clients {
    isClient(client) {
        if (window.hasOwnProperty("localStorage")) {
            let clientCMSData = GetFromLocalStorage("meta-client-cms-data");

            if (
                clientCMSData &&
                clientCMSData.hasOwnProperty("clientData") &&
                clientCMSData.clientData.hasOwnProperty("clientConfig") &&
                typeof clientCMSData.clientData.clientConfig === "string"
            ) {
                return clientCMSData.clientData.clientConfig == client;
            }
        }

        return process.env.CLIENT == client;
    }

    isClientWarnerMedia() {
        return this.isClient("WarnerMedia");
    }

    isClientGlobalEagle() {
        return this.isClient("GlobalEagle");
    }

    isClientMGM() {
        return this.isClient("MGM");
    }

    isClientFox() {
        return this.isClient("Fox");
    }

    isClientNBCU() {
        return this.isClient("NBCU");
    }

    isClientEpix() {
        return this.isClient("EPIX");
    }

    isWarnerMediaDemo() {
        return this.isClient("Demo");
    }

    isFunimation() {
        return this.isClient("Funimation");
    }

    isViacom() {
        return this.isClient("Viacom");
    }

    isPokemon() {
        return this.isClient("Pokemon");
    }

    isAENetworks() {
        return this.isClient("AENetworks");
    }

    isBBCStudios() {
        return this.isClient("BBCStudios")
    }

    isSinclair() {
        return this.isClient("Sinclair")
    }
}

const instance = new Clients();

export { instance as default };
