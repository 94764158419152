import { action, extendObservable, makeObservable } from "mobx";
import { TicketHistoryEntry } from "../containers/ticket/history/types";
import TaskManagerStoreInterface from "./TaskManagerStoreInterface";
import { RootStore } from "./rootStore";

export type InitialStateType = {
    entries: TicketHistoryEntry[];
};

const initialState: InitialStateType = {
    entries: [],
};

class TicketHistoryStore implements TaskManagerStoreInterface {
    rootStore: RootStore;

    entries: InitialStateType["entries"];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            setEntries: action,
        });

        extendObservable(this, initialState);
    }

    async initialize(...args: any[]) {
        if (this.rootStore.storesInit["ticketHistory"]) {
            throw new Error(
                `Task Manager ticketHistory Store has already been initialized.`,
            );
        }

        this.rootStore.finishLoading("ticketHistory");
    }

    setEntries(entries: TicketHistoryEntry[]) {
        this.entries = Array.from(entries);
    }

    async getEntries(ticketId: number) {
        if (!ticketId) {
            return;
        }

        const response = await this.rootStore.taskManagerApi.getHistory(
            ticketId,
        );

        if (response.isError) {
            this.rootStore.handleApiError(response);
        } else {
            this.setEntries(response.data);
        }
    }
}

export default TicketHistoryStore;
