import { z } from "zod";
import FieldInterface from '../../interfaces/Field.interface'

export const SelectInputRule = (field: FieldInterface) => {
    const errorMessage = "Please select an option";
    
    if (field.validation?.required?.enabled) {
        return z.object({
            [field.key]: z.union([
                z.string().min(1, errorMessage),
                z.object({}).refine((obj) => Object.keys(obj).length > 0, { message: errorMessage}),
                z.array(z.string()).min(1, "Please select an option")
            ])
        })
    }

    return z.object({});
}