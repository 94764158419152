import React from "react";
import "./_styles.scss";

interface TagContainerInterface {
    children: JSX.Element[];
}

const TagContainer = ({ children }: TagContainerInterface) => {
    return <div className="tag-container">{children}</div>;
};

export default TagContainer;
