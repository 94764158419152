import deepcopy from "deepcopy";

export default function ValidateGenres(result = {}, options = {}) {
    let response = deepcopy(result);

    const { data = {}, copy = {}, status, clientFeatures = {} } = options;

    const { descriptors = {} } = data;

    const { genreLimit } = clientFeatures;

    let limit = 5;
    if (Number.isInteger(genreLimit)) limit = genreLimit;
    if (genreLimit === null) limit = false;

    if (Array.isArray(descriptors.genres) && Number.isInteger(limit)) {
        const genres = descriptors.genres.filter((item) => !item.deleted);

        if (genres.length > 5) {
            response.result = false;
            response.notification = {
                title: copy.pleaseCheckGenres || "Please check genres",
                description:
                    copy.maximum5GenresPerRecord ||
                    "Maximum 5 genres is allowed per record",
                status,
            };

            response.validationKeys = result.validationKeys.concat(["genres"]);
        }
    }

    return response;
}
