import React from 'react'
import { makeObservable, action, extendObservable } from 'mobx'
import { RootStore } from '../rootStore'
import { creditListStoreInitialState } from "../state"
import { CreditListInterface } from "../interfaces"
import Field from '../../../../../models/RecordBuilder/Field.interface'
import CoreFormFieldInterface from '../../../../../core/Form/dynamic/interfaces/Field.interface'
import CoreDynamicFormFieldsMapper from '../../../../../components/RecordBuilder/map/dynamicCoreForm/coreDynamicForm'

export class CreditListStore {
    rootStore: RootStore;
    configuration: Field[];
    coreFormFields: CoreFormFieldInterface[];
    creditListOriginal: CreditListInterface;
    creditList: CreditListInterface;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            setCreditListOriginal: action,
            setCreditList: action,
            setCoreFormFields: action
        });

        extendObservable(this, creditListStoreInitialState);
    }

    reset() {
        Object.keys(creditListStoreInitialState).forEach(
            (key) => (this[key] = creditListStoreInitialState[key]),
        );

        this.rootStore.creditListValidationStore.reset()
    }

    setConfiguration(configuration: any): void {
        this.configuration = configuration
    }

    setCreditListOriginal(creditListOriginal: CreditListInterface): void {
        this.creditListOriginal = creditListOriginal
    }

    setCoreFormFields(coreFormFields: CoreFormFieldInterface[]): void {
        this.coreFormFields = coreFormFields
    }

    setCreditList(creditList: CreditListInterface): void {
        this.creditList = {
            ...this.creditList,
            ...creditList
        }
    }

    handleSetCoreFormFields(): void {
        const coreFormFields: CoreFormFieldInterface[] = CoreDynamicFormFieldsMapper({ 
            category: '', 
            configuration: this.configuration?.fields || [],
            path: this.configuration?.code
        })

        this.setCoreFormFields(coreFormFields)
    }
}
