import React, { useState, useEffect, useMemo } from "react";
import isClientManagedCheck from "../ClientManagedOptions/isClientManaged";
import fetchClientManagedOptions from "../ClientManagedOptions/fetchClientManagedOptions";
import { getListItems } from '../../services/ApiClients/CML'
import { mapListItems } from '../../models/ClientManaged/dynamicCML'

const ClientManagedComponent = (props) => {
    const {
        data,
        Component,
        dataKey = "data",
        searchOnChange,
        onSearchKey = "",
        transformListHook = (item) => item,
        className,
        id,
        clientManagedType,
        isClientManaged,
        isDynamicList = false,
        apiUrls = {}
    } = props;

    const [options, setOptions] = useState([]);
    const [fetchingOptions, setFetchingOptions] = useState(false);
    const identifier = useMemo(() => {
        let result = "";
        if (id || className) result = `${id || className}-client-managed`;
        return result;
    });

    function fetchOptions(searchTerm = "") {
        setFetchingOptions(true);

        if (isDynamicList) {
            getListItems({ apiUrls }, clientManagedType).then((data) => {
                setOptions(mapListItems(data));
                setFetchingOptions(false);
            })
            
            return
        }

        fetchClientManagedOptions({
            ...props,
            searchTerm,
            onResponse: (response) => {
                setOptions(response);
                setFetchingOptions(false);
            },
        });
    }

    useEffect(() => {
        if (isClientManagedCheck(props) || isClientManaged) {
            fetchOptions();
        } else {
            setOptions(data);
        }
    }, []);

    let itemProps = {
        loading: fetchingOptions,
        [dataKey]: transformListHook(options),
    };

    if (searchOnChange && onSearchKey) {
        itemProps[onSearchKey] = (value) => fetchOptions(value);
    }

    return (
        <Component
            {...props}
            {...itemProps}
            className={`${props.className || ""} client-managed ${identifier}`}
        />
    );
};

export default ClientManagedComponent;
