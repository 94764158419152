const TrafficLightTransformer = (props) => {
    const { locales = [], data = {}, statusColors = {} } = props;

    const { languages = [] } = data;

    const { incomplete } = statusColors;

    let result = [];

    if (Array.isArray(locales)) {
        locales.forEach((locale) => {
            let status = "disabled";
            const language = languages.find(
                (language) => language.locale == locale.value,
            );

            if (language) {
                status = incomplete;

                if (statusColors[language.status]) {
                    status = statusColors[language.status];
                }
            }

            result.push({
                title: locale.text,
                color: status,
            });
        });
    }

    return result;
};

export default TrafficLightTransformer;
