import _ from "lodash";
import SendRequest from "../../components/SendRequest";

const HandleFetchCMSDataRequest = (props) => {
    const { state = {}, callBack = () => {} } = props;

    const { set } = state;

    let url = window.location.origin + "/cms";

    const options = {
        url,
        method: "GET",
    };

    const handleError = (error) => {
        set({
            notification: {
                title: "Technical Error",
                description: "Failed to fetch cms data",
                status: "alert",
                onClick: () => {
                    set({
                        notification: {},
                    });
                },
            },
        });

        console.log(error);
    };

    try {
        SendRequest(
            options,
            (response) => {
                const { data, message } = response;

                if (data) {
                    if (window.hasOwnProperty("localStorage")) {
                        localStorage.setItem(
                            "meta-client-cms-data",
                            JSON.stringify(data),
                        );
                    }

                    set(
                        {
                            cmsData: data,
                        },
                        () => {
                            callBack();
                        },
                    );

                    return;
                }

                handleError(message);
            },
            (error) => {
                handleError(error);
            },
        );
    } catch (e) {
        handleError(e);
    }
};

export default HandleFetchCMSDataRequest;
