import React from "react";
import CheckboxTag from "./index";

const CheckboxTagLocale = (props) => {
    const {
        leftText,
        centeredContent,
        selected,
        className,
        handleSelection = () => {},
    } = props;

    return (
        <CheckboxTag
            className={className}
            leftText={leftText}
            centeredContent={centeredContent}
            background="#2896a3"
            leftTextStyle={{
                marginRight: "0.5rem",
            }}
            centeredContentStyle={{
                marginRight: "0.1875rem",
                borderRadius: "0.3rem",
                padding: "0.2rem 0.3125rem",
                fontSize: "0.78125rem",
                textAlign: "center",
                minWidth: "2.1875rem",
                color: selected ? "#2896a3" : "#ffffff",
                background: selected ? "#ffffff" : "#787878",
            }}
            selected={selected}
            handleSelection={handleSelection}
        />
    );
};

export default CheckboxTagLocale;
