import _ from "lodash";
import SendRequest from "../../components/SendRequest";
import GetApiUrls from "./GetApiUrls";

const HandleFetchAllPlatformAdmins = (props) => {
    const { options, state } = props;

    const { handleError, handleSuccess } = options;

    const apiUrls = GetApiUrls(state);

    let url = apiUrls.platformAdminEmails;

    const requestOptions = {
        url,
        method: "GET",
    };

    try {
        SendRequest(
            requestOptions,
            (response) => {
                const { data } = response;

                if (data) {
                    handleSuccess(data);
                    return;
                }

                handleError();
            },
            (error) => {
                console.log(error);
                handleError();
            },
        );
    } catch (e) {
        console.log(e);
        handleError(e);
    }
};

export default HandleFetchAllPlatformAdmins;
