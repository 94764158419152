import React from "react";
import _ from "lodash";
import "video.js";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "./styles/_styles.scss";

export default class VideoJs extends React.Component {
    componentDidMount() {
        this.player = videojs(
            this.videoNode,
            this.props,
            function onPlayerReady() {},
        );
        const errorDisplay = this.player.getChild("errorDisplay");
        errorDisplay.off(this.player, "error", errorDisplay.open);
    }

    componentWillUnmount() {
        if (this.player) {
            this.player.dispose();
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.sources, this.props.sources)) {
            this.player.pause();
            this.player.src(this.props.sources);
            this.player.load();
            this.player.play();
        }
    }

    render() {
        const { subtitles = [] } = this.props;

        return (
            <div
                data-vjs-player
                style={{
                    margin: "0.6875rem 0",
                }}
            >
                <video
                    ref={(node) => (this.videoNode = node)}
                    className="video-js vjs-big-play-centered"
                >
                    {subtitles.map((subtitle, index) => (
                        <track
                            kind="captions"
                            src={subtitle.source}
                            srcLang={subtitle.code}
                            label={subtitle.name}
                            key={index}
                        />
                    ))}
                </video>
            </div>
        );
    }
}
