import _ from "lodash";
import { credits } from "../../data/cast-crew";
import { TableCreditRow } from "../types/CastCrew.type";

export function sortAndRank(rows: TableCreditRow[]) {
    const newRows: typeof credits = [];

    const groups = _.groupBy(rows, "role");
    const roles = Object.keys(groups);
    roles.forEach((role) => {
        const sorted = _.sortBy(groups[role], "ranking").map((row) => {
            return {
                ...row,
            };
        });
        newRows.push(...sorted);
    });

    return newRows;
}
