import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";
import thunk from "redux-thunk";
import breakpoint from "./reducers/breakpoint";
import unsavedChanges from "./reducers/unsavedChanges";
import newRecord from "./reducers/newRecord";
import originalNewRecord from "./reducers/originalNewRecord";
import originalRecord from "./reducers/originalRecord";
import activeRecord from "./reducers/activeRecord";
import searchData from "./reducers/searchData";
import recordData from "./reducers/recordData";
import savingRecord from "./reducers/savingRecord";
import recordChanges from "./reducers/recordChanges";
import recordChangesV2 from "./reducers/recordChangesV2";
import newRecordEdit from "./reducers/newRecordEdit";
import hoverRecord from "./reducers/hoverRecord";
import badResponse from "./reducers/badResponse";
import error from "./reducers/error";
import eidrSearch from "./reducers/eidrSearch";
import urlComponents from "./reducers/urlComponents";
import backgroundProcess from "./reducers/backgroundProcess";
import inheritanceFilters from "./reducers/inheritanceFilters";
import inheritanceData from "./reducers/inheritanceData";
import convertingData from "./reducers/convertingData";
import recordOptions from "./reducers/recordOptions";
import recordChangesUuid from "./reducers/recordChangesUuid";
import initialState from "./reducers/initialState";

const reducers = combineReducers({
    breakpoint,
    unsavedChanges,
    newRecord,
    originalNewRecord,
    activeRecord,
    searchData,
    recordData,
    savingRecord,
    originalRecord,
    recordChanges,
    recordChangesV2,
    newRecordEdit,
    hoverRecord,
    badResponse,
    error,
    urlComponents,
    backgroundProcess,
    eidrSearch,
    inheritanceFilters,
    inheritanceData,
    convertingData,
    recordOptions,
    recordChangesUuid,
    initialState,
});

const middlewares = [thunk];

const composeEnhancers = composeWithDevTools({
    // options like actionSanitizer, stateSanitizer
});

const enhancer = composeEnhancers(applyMiddleware(...middlewares));

export default function configureStore(state) {
    return createStore(reducers, state, enhancer);
}
