import React from "react";
import { Box } from "@mui/material";
import { GridFooter, GridFooterContainer } from "@mui/x-data-grid-pro";

export const Footer = ({
    message,
    showTotal,
}: {
    message: string;
    showTotal?: boolean;
}) => {
    return (
        <GridFooterContainer>
            <Box
                sx={{
                    p: 2,
                    mr: 2,
                    fontWeight: 500,
                    color: "#555555",
                    fontSize: "0.875rem",
                    alignSelf: "flex-start",
                }}
            >
                {message}
            </Box>
            {showTotal && <GridFooter />}
            <div />
        </GridFooterContainer>
    );
};
