import React, { Component } from "react";
import _ from "lodash";
import styles from "./styles";
import Select from "../Select";
import SelectMaterial from "../Select/materialBasic";
import Index from "./index";
import update from "react-addons-update";
import deepcopy from "deepcopy";

export default class Channels extends Index {
    constructor(props) {
        super(props);

        /**
         * @var object
         */
        this.defaultActiveRowData = {
            name: false,
            code: false,
            logo_branding: [],
        };

        /**
         * @var object
         */
        this.state = {
            data: [],
            editRow: false,
            hoverRow: false,
            newRow: true,
            activeRow: { ...this.defaultActiveRowData },
            notification: {},
        };

        /**
         * @var object
         */
        this.styles = styles(this.props);

        /**
         * @var object
         */
        this.copy = this.props.copy || {};
    }

    async componentDidMount() {
        this._isMounted = true;
        await this.setDataFromProps();
    }

    setActiveRowDefault() {
        this.setActiveRow(this.defaultActiveRowData);
    }

    getChannelBackground(name, index) {
        let result = "#fff38e";
        let oddIndex = index % 2;

        if (oddIndex == 1) {
            result = "#ffe38e";
        }

        return result;
    }

    getCodeByName(name) {
        const options = this.props.options || {};
        const channels = options.channels || {};
        const channel = channels.find((item) => item.name == name);

        let result = false;
        if (typeof channel == "object") {
            result = channel.code;
        }

        return result;
    }

    checkEmptyDataKeys() {
        let result = true;

        if (Array.isArray(this.state.data)) {
            this.state.data.map((item) => {
                if (item.name == false || item.code == false) {
                    result = false;
                }
            });
        }

        return result;
    }

    isActiveRowValidated() {
        let result = false;

        if (
            this.state.activeRow.name &&
            this.state.activeRow.code &&
            !_.isEmpty(this.state.activeRow.logo_branding)
        ) {
            result = true;
        }

        return result;
    }

    handleChange(key, index, value) {
        if (key) {
            let activeRow = {
                name: this.state.activeRow.name,
                code: this.state.activeRow.code,
                logo_branding: this.state.activeRow.logo_branding,
            };

            activeRow[key] = value;

            if (key == "name") {
                activeRow.code = this.getCodeByName(activeRow.name);
            }

            const updatedState = update(this.state.data, {
                [index]: { $set: activeRow },
            });

            this.setData(updatedState);
            this.setActiveRow(activeRow);
            this.setNewRow(true);
        }
    }

    renderDataRow(item, index, count) {
        if (typeof item == "object") {
            let disabled = true;
            let oddIndex = count % 2;
            const options = this.props.options || {};
            const channels = options.channels || {};

            let names = [];
            if (Array.isArray(channels)) {
                names = channels.map((item) => item.name) || [];
            }

            const logoBrands = options.logo_brands || [];

            if (this.state.editRow === index) {
                disabled = false;
            }

            return (
                <div
                    style={Object.assign(
                        {},
                        this.styles.tableContainer,
                        disabled && this.styles.tableContainerDisabled,
                    )}
                    key={index}
                >
                    <div style={this.styles.tableContent}>
                        <table
                            style={Object.assign(
                                {},
                                this.styles.table,
                                disabled && this.styles.tableDisabled,
                            )}
                            cellPadding="0"
                            cellSpacing="0"
                        >
                            <tbody style={this.styles.tbody}>
                                <tr
                                    style={Object.assign(
                                        {},
                                        this.styles.trow,
                                        oddIndex == 1 && this.styles.trEven,
                                    )}
                                >
                                    <td
                                        style={Object.assign(
                                            {},
                                            this.styles.td,
                                            { width: "18.84375rem" },
                                        )}
                                    >
                                        <Select
                                            name="name"
                                            id="ChannelName"
                                            value={item.name}
                                            disabledText={
                                                this.copy.selectChannel ||
                                                "Select Channel"
                                            }
                                            noneSelectedText={
                                                this.copy.noneSelected ||
                                                "None Selected"
                                            }
                                            disabled={disabled}
                                            readOnly={disabled}
                                            data={names}
                                            clear={true}
                                            noMargin={true}
                                            readOnlyPadding={true}
                                            handleChange={this.handleChange.bind(
                                                this,
                                                "name",
                                                index,
                                            )}
                                        />
                                    </td>

                                    <td
                                        style={Object.assign(
                                            {},
                                            this.styles.tdExtraPadding,
                                        )}
                                    >
                                        <SelectMaterial
                                            name="logo_branding"
                                            id="logo_branding"
                                            value={item.logo_branding || []}
                                            disabledText={
                                                this.copy.selectBranding ||
                                                "Select Branding"
                                            }
                                            noneSelectedText={
                                                this.copy.noneSelected ||
                                                "None Selected"
                                            }
                                            disabled={disabled}
                                            data={logoBrands}
                                            multiple={true}
                                            handleChange={this.handleChange.bind(
                                                this,
                                                "logo_branding",
                                                index,
                                            )}
                                        />
                                    </td>

                                    {disabled && (
                                        <td
                                            style={Object.assign(
                                                {},
                                                this.styles.tdExtraPadding,
                                                { width: "3rem" },
                                            )}
                                        >
                                            {!this.props.disabled &&
                                                this.renderEditIconGrey(
                                                    item,
                                                    index,
                                                )}
                                        </td>
                                    )}

                                    {disabled && item.code && (
                                        <td
                                            style={Object.assign(
                                                {},
                                                this.styles.tdExtraPadding,
                                                { width: "10.875rem" },
                                                disabled && {
                                                    background:
                                                        this.getChannelBackground(
                                                            item.name,
                                                            index,
                                                        ),
                                                    color: "rgba(163, 146, 37, 0.87)",
                                                    fontWeight: 500,
                                                },
                                            )}
                                        >
                                            {item.code}
                                        </td>
                                    )}

                                    {!disabled && (
                                        <td
                                            style={Object.assign(
                                                {},
                                                this.styles.tdExtraPadding,
                                                this.styles.tdAlign,
                                                { width: "5rem" },
                                            )}
                                        >
                                            <div
                                                style={
                                                    this.styles.buttonContainer
                                                }
                                            >
                                                {this.renderDeleteIcon(
                                                    item,
                                                    index,
                                                )}
                                                {this.renderSaveIcon(
                                                    "save-credits",
                                                )}
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        }

        return false;
    }
}
