import React from "react";
import _ from "lodash";
import Descriptors from "./Descriptors";
import BubbleList from "../../BubbleList";
import isIndexInArray from "../../Helpers/isIndexInArray";
import ClientManagedDescriptors, {
    IsClientManagedDescriptors,
} from "../../ClientManagedOptions/descriptors";
import Trivia from "./Trivia";
import OptionsSearchApi from "../../Options/OptionsSearchApi";
import AuditTooltip from "../../AuditTooltip";
import { LockedDescriptorField } from "../../LockedField";
import { CLIENT_MANAGED_DESCRIPTORS } from "../../ClientManagedOptions/options";

export default class DescriptorsV2 extends Descriptors {
    handleOnInputChange(props) {
        const { item = {}, value, clientManaged } = props;

        const { list = "" } = item;

        const { apiUrls = {} } = this.props;

        const { searchClientManaged = "" } = apiUrls;

        this.setState({ searchableDataList: [] });

        if (_.isEmpty(list) && !_.isEmpty(item)) {
            this.updateDataListByOptionsSearch([item]);
            return;
        }

        if ((!_.isEmpty(item) && item.apiUrl) || clientManaged) {
            const itemProps = {
                apiUrls,
                optionUrl: item.apiUrl,
                searchTerm: value,
                updateList: (value) =>
                    this.updateDataListByOptionsSearch(value),
            };

            if (clientManaged) {
                itemProps.url = searchClientManaged.replace(
                    "{table}",
                    CLIENT_MANAGED_DESCRIPTORS,
                );
                itemProps.type = item.key;
            }

            if (clientManaged || (item && item.apiUrl)) {
                OptionsSearchApi(itemProps);
            }
        }
    }

    updateDataListByOptionsSearch(data) {
        this.setState({ searchableDataList: Array.isArray(data) ? data : [] });
    }

    renderBubbleList(props) {
        const { item = {}, index } = props;

        const {
            title,
            color = "#333333",
            background = "#6a6a6a",
            placeholder = `Start typing ${item.title}`,
            deleteWhite = true,
            datalistId = "",
            list = "",
            growing_list = false,
            single = false,
            apiUrl = null,
        } = item;

        const {
            validationKeys = [],
            options = {},
            clientFeatures = {},
            managed_lists = [],
            category,
            clientDataStructure = [],
            recordData = {},
            apiUrls = {},
        } = this.props;

        const disabled = item.disabled || this.props.disabled;

        const { searchableDataList = [] } = this.state;

        const data = _.cloneDeep(this.state.data || {});
        const itemKey = item.key;
        const dataList = this.getOptions(list, options);
        const clientManaged = IsClientManagedDescriptors(clientFeatures);

        const descriptorOptions = ClientManagedDescriptors({
            options,
            fallback: dataList,
            clientFeatures,
            itemKey,
            managed_lists,
            category,
        });

        const lockedField = LockedDescriptorField({
            item,
            clientDataStructure,
            recordData,
        });

        const readOnly = lockedField || disabled;

        return (
            <div style={this.styles.marginItem} key={index}>
                <AuditTooltip fieldName={itemKey} dataSection="descriptors">
                    {!readOnly && (
                        <BubbleList
                            label={title}
                            className={itemKey}
                            name={itemKey}
                            id={itemKey}
                            inputId={`${itemKey}_inputId`}
                            background={background}
                            color={color}
                            // deleteWhite={deleteWhite}
                            readOnly={readOnly}
                            emptyPlaceholder={placeholder}
                            data={data[itemKey] || []}
                            handleFormChange={(formItem) =>
                                this.handleChange(
                                    itemKey,
                                    single ? [_.last(formItem)] : formItem,
                                )
                            }
                            handleChange={(value) =>
                                this.handleOnInputChange({
                                    item,
                                    value,
                                    clientManaged,
                                })
                            }
                            searchOnClick
                            datalistId={datalistId}
                            datalist={
                                apiUrl || clientManaged
                                    ? searchableDataList
                                    : descriptorOptions
                            }
                            ranking
                            useEnterButtonOnSelect
                            validateEnterButton
                            dataListUseEnterButton
                            error={isIndexInArray(validationKeys, itemKey)}
                            filterStaticDataList={false}
                            limitResults={50}
                            handleNoMatchText={growing_list}
                            noMatchText={
                                growing_list &&
                                clientFeatures?.noMatchTextCreateNew
                                    ? "No match for {value}. Create New?"
                                    : null
                            }
                            showAddNewButton={false}
                            chevronBackground={false}
                        />
                    )}

                    {readOnly && (
                        <BubbleList
                            label={title}
                            disabled={disabled}
                            readOnly={readOnly}
                            data={data[itemKey] || []}
                            background={background}
                            color={color}
                            showAddNewButton={false}
                            id={itemKey}
                        />
                    )}
                </AuditTooltip>
            </div>
        );
    }

    getOrderedItems(items) {
        if (Array.isArray(items)) {
            const trivia = items.find((it) => it.key == "trivia");

            if (trivia) {
                const results = items.filter((it) => it.key !== "trivia");
                results.push(trivia);

                return results;
            }
        }

        return items;
    }

    renderFullWidthOptions() {
        const {
            clientDataStructure = {},
            category,
            clientFeatures = {},
            audits,
            canCreateNew,
        } = this.props;

        const { productPlacement = {} } = clientFeatures;

        const { descriptors = {} } = clientDataStructure;

        const { full_width = [] } = descriptors;

        const isCategoryAvailable = (props) => {
            const { category = "", categories = [] } = props;

            return Array.isArray(categories) && !_.isEmpty(categories)
                ? categories.includes(category)
                : true;
        };

        const { data = {} } = this.state;

        const productPlacementInOptions = _.find(full_width, {
            key: "product_placement",
        });

        const items = this.getOrderedItems(full_width);

        return (
            <>
                {items.map((item, index) => {
                    if (
                        isCategoryAvailable({
                            category,
                            categories: item.categories || [],
                        })
                    ) {
                        switch (item.key) {
                            case "trivia":
                                return (
                                    <Trivia
                                        data={this.props.triviaData || []}
                                        handleFormChange={(data) =>
                                            this.props.handleTriviaFormChange(
                                                data,
                                            )
                                        }
                                        disabled={this.props.disabled}
                                        key={index}
                                        clientFeatures={clientFeatures}
                                        audits={audits}
                                        canCreateNew={canCreateNew}
                                    />
                                );
                            default:
                                return this.renderBubbleList({
                                    item,
                                    index,
                                });
                        }
                    }
                })}

                {!productPlacementInOptions &&
                    !_.isEmpty(productPlacement) &&
                    !productPlacement.boolean &&
                    this.renderBubbleListProductPlacement({ data })}
                {productPlacement.boolean &&
                    this.renderBooleanProductPlacement({ data })}
            </>
        );
    }
}
