import _ from "lodash";
import SendRequest from "../../../components/SendRequest";
import GetApiUrls from "../GetApiUrls";

const HandleNewUsergroupSaveRequest = (props) => {
    const { state = {} } = props;

    const { userGroupCreate = "" } = GetApiUrls(state);

    const { set, newRecord, handleUserGroupSearch } = state;

    const { name } = newRecord;

    const requestOptions = {
        url: userGroupCreate,
        method: "POST",
        basic: true,
        body: newRecord,
    };

    const handleError = (error) => {
        console.log(error);

        set({
            savingRecord: false,
            notification: {
                title: `Failed to create user group ${name || ""}`,
                description: error
                    ? error
                    : "Technical error whilst creating user group",
                status: "error",
                onClick: () =>
                    set({
                        notification: {},
                    }),
            },
        });
    };

    set({
        savingRecord: true,
    });

    try {
        SendRequest(
            requestOptions,
            (response) => {
                const { message, status } = response;

                if (status == 200) {
                    set(
                        {
                            savingRecord: false,
                            newRecord: {},
                            editRecord: false,
                        },
                        () => {
                            handleUserGroupSearch("");
                        },
                    );

                    return;
                }

                handleError(message);
            },
            (error) => {
                handleError(error);
            },
        );
    } catch (e) {
        handleError(e);
    }
};

export default HandleNewUsergroupSaveRequest;
