import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import { CoreTableCell } from "../../components/CoreTableCell";
import { CoreTableGridColDef } from "../../types";

const RenderCell = (params: GridRenderCellParams<string>) => {
    const elem = useMemo(
        () => (
            <CoreTableCell enableCopyToClipboard>{params?.value || ''}</CoreTableCell>
        ),
        [params?.value],
    );
    return elem;
};

export const CreditIDCol: CoreTableGridColDef = {
    headerName: "Credit ID",
    field: "creditID",
    flex: 1,
    minWidth: 120,
    maxWidth: 120,
    renderCell: (params: GridRenderCellParams<string>) => {
        return <RenderCell {...params} />;
    },
    // renderCell: (params: GridRenderCellParams<string>) => {
    //     const { value } = params;
    //     return <CoreTableCell enableCopyToClipboard>{value}</CoreTableCell>;
    // },
};
