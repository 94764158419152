import React from "react";
import Component from "./index";
import ClientManagedComponent from "../ClientManagedOptions/Component";

const ClientManagedSelectWithTag = (props) => {
    const { isClientManaged } = props;

    if (isClientManaged === false || isClientManaged == undefined) {
        return <Component {...props} />;
    }

    return <ClientManagedComponent {...props} Component={Component} />;
};

export default ClientManagedSelectWithTag;
