import deepcopy from "deepcopy";
import _ from "lodash";
import { RecordInterface } from "../../models/Record/model";
import { LinkageInterface } from "../../models/Record/linkage";

export default function recordLinkageTransformer(
    data: RecordInterface,
): RecordInterface {
    let result = deepcopy(data);

    if (!_.isEmpty(data.linkage)) {
        result.linkage = result.linkage.map((linkage: LinkageInterface) => {
            const newLinkage: LinkageInterface = {
                meta_id: linkage.meta_id,
                category: linkage.category
            };

            if (linkage.deleted) {
                newLinkage.deleted = linkage.deleted
            }

            return newLinkage
        });
    }

    return result;
}
