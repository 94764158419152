import { useEffect, useMemo, useState } from "react";

export function useBodyFontSize() {
    const [bodyFontSize, setBodyFontSize] = useState(null);

    useEffect(() => {
        // Function to get the body font size
        const getBodyFontSize = () => {
            const bodyElement = document.body;
            const computedStyle = window.getComputedStyle(bodyElement);
            const fontSize = computedStyle.getPropertyValue("font-size");
            return fontSize;
        };

        // Set the initial body font size
        setBodyFontSize(getBodyFontSize());

        // Update the body font size when the window is resized
        const handleResize = () => {
            setBodyFontSize(getBodyFontSize());
        };

        window.addEventListener("resize", handleResize);

        // Clean up the event listener on unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // Memoize the body font size value
    const memoizedBodyFontSize = useMemo(() => bodyFontSize, [bodyFontSize]);

    const isNormalFontSize = memoizedBodyFontSize === "16px";

    return {
        memoizedBodyFontSize,
        isNormalFontSize,
    };
}
