import React, { Fragment } from "react";
import "./styles/_styles.scss";
import _ from "lodash";
import LabelCounter from "../Input/label";
import CopyTo from "./CopyTo";
import Settings from "../Settings";
import StarComment from "../StarComment";
import NoneAddedLabel from "./NoneAddedLabel";
import Label from "./Label";

const GracenoteRegionalItem = (props) => {
    const {
        item = {},
        label = "",
        data = [],
        disabled,
        attribute,
        handleCopyToClick = () => {},
    } = props;

    const { allowedLocales = [] } = item;

    let filteredData = _.isEmpty(allowedLocales)
        ? data
        : data.filter((i) => {
              return allowedLocales.includes(i.locale);
          });

    const itemStyles = {
        marginBottom: "1.5rem",
    };

    const getCounter = (regionalItem) => {
        let counter = "";
        if (
            regionalItem &&
            attribute &&
            typeof regionalItem[attribute] == "string"
        ) {
            counter = regionalItem[attribute].length;
        }

        return counter;
    };

    return (
        <Fragment>
            {_.isEmpty(filteredData) && (
                <div style={itemStyles}>
                    <Label text={label} />
                    <NoneAddedLabel />
                </div>
            )}
            {filteredData &&
                filteredData.map((regionalItem, index) => (
                    <div key={index} style={itemStyles}>
                        <div style={{ display: "flex" }}>
                            <LabelCounter
                                label={label}
                                counter={true}
                                state={{
                                    counter: getCounter(regionalItem),
                                    counterOverLimit: false,
                                }}
                                counterStyles={{ display: "inline-block" }}
                            />

                            {regionalItem.locale && (
                                <span
                                    style={{
                                        marginLeft: "0.46875rem",
                                        ...Settings.components
                                            .smallTransparentBubble,
                                    }}
                                >
                                    {regionalItem.locale}
                                </span>
                            )}
                        </div>

                        <span
                            style={{
                                ...Settings.components.regional,
                                marginTop: "0.6875rem",
                            }}
                        >
                            {regionalItem[attribute]}
                        </span>

                        <StarComment
                            text={`${label} from TMS ID ${regionalItem.from_tms_id}`}
                            disabled={true}
                        />

                        {!disabled && (
                            <CopyTo
                                margin="0"
                                handleClick={() =>
                                    handleCopyToClick(regionalItem)
                                }
                            />
                        )}
                    </div>
                ))}
        </Fragment>
    );
};

export default GracenoteRegionalItem;
