export default function getYears(year = 2019) {
    let result = [];

    const updatedYear = parseFloat(year) + 5;

    for (let i = updatedYear; i > 1886; i--) {
        result.push({
            value: i,
            text: i,
        });
    }

    return result;
}
