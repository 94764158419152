import { action, makeObservable, observable } from "mobx";
import { SeasonVT, TvShowVT } from "../../../../config/types";

class NuvoTvSeriesStore {
    remoteTvShows: TvShowVT[] = [];
    remoteSeasons: SeasonVT[] = [];

    localTvShows: TvShowVT[] = [];
    localSeasons: SeasonVT[] = [];

    loadingTvShows = false;
    loadingSeasons = false;

    constructor() {
        makeObservable(this, {
            loadingTvShows: observable,
            loadingSeasons: observable,

            setLoadingTvShows: action,
            setLoadingSeasons: action,

            remoteTvShows: observable,
            remoteSeasons: observable,

            localTvShows: observable,
            localSeasons: observable,

            setRemoteTvShows: action,
            setRemoteSeasons: action,

            setLocalTvShows: action,
            setLocalSeasons: action,
        });
    }

    setLoadingTvShows = (loading: boolean) => {
        this.loadingTvShows = loading;
    };

    setLoadingSeasons = (loading: boolean) => {
        this.loadingSeasons = loading;
    };

    setRemoteTvShows = (tvShows: TvShowVT[]) => {
        this.remoteTvShows = tvShows;
    };

    setRemoteSeasons = (seasons: SeasonVT[]) => {
        this.remoteSeasons = seasons;
    };

    setLocalTvShows = (tvShows: TvShowVT[]) => {
        this.localTvShows = tvShows;
    };

    setLocalSeasons = (seasons: SeasonVT[]) => {
        this.localSeasons = seasons;
    };

    reset() {
        this.setLocalTvShows([]);
        this.setRemoteTvShows([]);

        this.setLocalSeasons([]);
        this.setRemoteSeasons([]);

        console.log("NuvoTvSeriesStore has been reset");
    }
}

const nuvoTvSeriesStore = new NuvoTvSeriesStore();
export default nuvoTvSeriesStore;
