import React from "react";
import Settings from "../Settings";
import KeywordLabel from "../KeywordLabel";

const GracenoteCopyTo = (props) => {
    const { margin = "0.6875rem 0 0", handleClick = () => {} } = props;

    const spanStyles = {
        display: "flex",
        alignItems: "center",
    };

    const imageStyles = {
        width: "0.625rem",
        marginLeft: "0.625rem",
    };

    const Text = () => {
        return (
            <span style={spanStyles}>
                Copy to{" "}
                <img
                    src={`${Settings.images.path}/svg/chevron-bottom-black.svg`}
                    style={imageStyles}
                />
            </span>
        );
    };

    return (
        <KeywordLabel
            title={<Text />}
            background={Settings.colors.grey.light}
            color="#5C5C5C"
            margin={margin}
            onClick={() => handleClick()}
            className="gracenote-copyto"
        />
    );
};

export default GracenoteCopyTo;
