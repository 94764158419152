import React from 'react'
import SortableTable from '../../../../../components/SortableTable'
import { useStore } from '../context'
import TableItem from './TableItem'
import { setCredits } from '../reducer'
import _ from 'lodash'
import ArrayMove from 'array-move'
import ProvideRanking from './ProvideRanking'
import sortByRanking from './helpers/sortByRanking'

const SortContainer = props => {
    const {
        credits: containerCredits = [],
        role,
        indexStartFrom
    } = props
    
    const [store, dispatch] = useStore()

    const {
        disabled = true,
        credits = []
    } = store

    const SortOrder = ({oldIndex, newIndex}) => {
        let groups = _.groupBy(sortByRanking(credits), 'role')

        if(_.has(groups, role)) {
            let getUpdatedIndex = ArrayMove(_.sortBy(groups[role], 'ranking').filter(i => !i.deleted), oldIndex, newIndex)
            groups[role] = getUpdatedIndex.concat(groups[role].filter(i => i.deleted))
            dispatch(setCredits(ProvideRanking(groups)))
        }
    }

    return(
        <SortableTable
            items={
                _.sortBy(containerCredits, 'ranking').map((credit, index) => 
                    <TableItem 
                        credit={credit} 
                        key={index}
                    />
                )
            }
            disabled={disabled}
            key={role}
            borderRadiusTop={0}
            borderRadiusBottom={0}
            useCommonBorderRadius={false}
            handleSort={value => SortOrder(value)}
            itemStyles={{padding: "0", borderBottom: "0", background: "#f6f6f6", borderRadius: "inherit"}}
            indexStartFrom={indexStartFrom + 1}
        />
    )
}

export default SortContainer