import React from "react";
import Descriptions from "./Descriptions";
import styles from "../styles";
import Clients from "../../Clients";
import moment from "moment";
import Notification from "../../Notification";
import FullScreenLoadingSpinner from "../../FullScreenLoadingSpinner";
import { transformType } from "../utils/helpers/descriptions/transformType";

export default class DescriptionsV2 extends Descriptions {
    renderContent() {
        const { data = [], notification, fetchingTranslations } = this.state;

        const { languageIso, clientDataStructure = {} } = this.props;

        const { synopses = [], synopsisTransformer = [] } = clientDataStructure;

        const localeSynopses =
            data.filter((item) => item.locale == languageIso) || [];
        const classStyles = styles(this.props);

        return (
            <React.Fragment>
                {this.renderUpdatedAt()}

                {synopses.map((synopsesItem, index) => {
                    const { title = "", length, type, hide } = synopsesItem;

                    const synopsisData =
                        localeSynopses.find(
                            (item) =>
                                transformType({ item, synopsisTransformer }) ==
                                type,
                        ) || {};

                    return (
                        <div
                            style={Object.assign(
                                {},
                                classStyles.marginItem,
                                hide && {
                                    ...this.getDisplayStyles(synopsisData),
                                },
                            )}
                            key={index}
                        >
                            {this.renderSynopsis({
                                key: type,
                                label: title,
                                maxCharacter: length,
                                data: synopsisData,
                            })}
                        </div>
                    );
                })}

                <Notification {...notification} />

                {fetchingTranslations && <FullScreenLoadingSpinner />}
            </React.Fragment>
        );
    }

    changeSynopsesUpdatedAt(date) {
        if (Clients.isClientEpix() && date) {
            date = moment(date).format("M/D/YYYY");
        }
        return date;
    }
}
