import { SET_UNSAVED_CHANGES } from "../actions/action_types";
import { defaultUnsavedChanges } from "../initialState";

export default function (
    state = { unsavedChanges: defaultUnsavedChanges },
    action,
) {
    switch (action.type) {
        case SET_UNSAVED_CHANGES:
            return Object.assign({}, state, {
                unsavedChanges: action.unsavedChanges,
            });
    }

    return state;
}
