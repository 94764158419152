import { CreditRowInterface } from "../../../../v4/store/interfaces";
import { CreditInterface } from "../../../credits.interface";

export default function groupCreditsByRole(credits: CreditInterface[] & CreditRowInterface[], roleKey: string = 'role_name'): { [key: string]: CreditInterface[] | CreditRowInterface[]} {
    return credits.reduce((acc, credit) => {
        const role = credit[roleKey];

        if (!acc[role as string]) {
            acc[role as string] = [];
        }

        acc[role as string].push(credit);

        return acc;
    }, {} as { [key: string]: CreditInterface[] & CreditRowInterface[]});
}