import React from "react";
import { SectionInterface } from "./interface";
import SeasonNumbering from "../../views/SeasonNumbering";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";
import IsActiveModel from "../../../RecordBuilder/IsActive";
import { NUMBERING } from "../../../../models/RecordBuilder";
import SectionSeasonNumberingCore from "./SeasonNumberingCore";

const SectionSeasonNumbering = (props: SectionInterface) => {
    const { data = {}, cmsData = {} } = props;

    const { clientDataStructure = {}, clientFeatures = {} } = cmsData;

    if (IsActiveModel({ page: NUMBERING, clientFeatures })) {
        return <SectionSeasonNumberingCore {...props} />
    }

    return (
        <SeasonNumbering
            {...getSectionCommonProps({ ...props, field: "season_numbering" })}
            clientDataStructure={clientDataStructure}
            category={data.category}
            name="season-numbering"
            className="meta-record-season-numbering"
            title="Season Numbering"
            data={data.season_numbering || {}}
        />
    );
};

export default SectionSeasonNumbering;
