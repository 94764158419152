import moment from "moment";

const GetDefaultNewRowData = (props) => {
    const { date } = props;

    let result = {
        licensor: "",
        license_start: null,
        license_end: null,
        business_models: [],
        platforms: "",
        countries: [],
    };

    let momentDate = moment(date);
    if (momentDate.isValid()) {
        momentDate = moment(momentDate).add(1, "days");
        result.license_start = momentDate.format("YYYY-MM-DD HH:mm");

        momentDate = moment(momentDate).add(23, "hours");
        momentDate = moment(momentDate).add(59, "minutes");
        result.license_end = momentDate.format("YYYY-MM-DD HH:mm");
    }

    return result;
};

export default GetDefaultNewRowData;
