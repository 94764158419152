import React from "react";
import _ from "lodash"
import SchedulingPlatformsBuilder from './builder'
import IsActive from '../../../RecordBuilder/IsActive'
import { SCHEDULING_PLATFORMS } from '../../../../models/RecordBuilder'
import { useStore as useGlobalStore } from "../../../Global/store/store"
import PlaceholderText from "../../../PlaceholderText";
import { useStore } from "../../state/context";
import SchedulingPlatformsTemplate from './template'

const PlatformScheduling: React.FC = () => {
    const [store] = useStore()
    const [globalStore] = useGlobalStore()

    const { platformScheduling = [], loadingBuilderConfig = true } = store

    const activeBuilder = IsActive({
        page: SCHEDULING_PLATFORMS,
        clientFeatures: _.get(globalStore, 'cmsData.clientFeatures', {})
    })

    return(
        <div className="platform-scheduling">
            <h2 className="section-title">Platform Scheduling</h2>

            {_.isEmpty(platformScheduling) && !loadingBuilderConfig && (
                <div className="none-added-wrapper">
                    <PlaceholderText
                        text={"Platform Scheduling None Added"}
                    />
                </div>
            )}

            {activeBuilder && <SchedulingPlatformsBuilder />}
            {!activeBuilder && <SchedulingPlatformsTemplate /> }
        </div>
    )
}

export default PlatformScheduling