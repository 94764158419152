import React, { Fragment } from "react";
import "./styles/_styles.scss";
import _ from "lodash";
import Label from "./Label";
import KeywordLabel from "../KeywordLabel";
import CopyTo from "./CopyTo";
import NoneAddedLabel from "./NoneAddedLabel";

const GracenoteLabelItem = (props) => {
    const {
        label = "",
        data,
        background,
        color,
        handleCopyToClick = () => {},
        dataTransformed,
        disabled,
        name,
        readOnly = false,
    } = props;

    const itemStyles = {
        marginBottom: "1.5rem",
    };

    const RenderLabel = ({ title = "", key }) => {
        return (
            <Fragment>
                {!_.isEmpty(title) && (
                    <KeywordLabel
                        title={title}
                        background={background}
                        color={color}
                        margin="0.6875rem 0.625rem 0 0"
                        className={`${name}_label`}
                        cursor="default"
                    />
                )}
                {_.isEmpty(title) && <NoneAddedLabel />}
            </Fragment>
        );
    };

    const dataToUse = !_.isEmpty(dataTransformed) ? dataTransformed : data;
    let allowCopy = true;

    if (
        typeof dataToUse == "object" &&
        !_.isEmpty(dataToUse) &&
        _.has(dataToUse, "isValid")
    ) {
        allowCopy = dataToUse.isValid;
    }

    return (
        <div style={itemStyles} className={name}>
            <Label text={label} />

            {!dataToUse && <NoneAddedLabel />}

            {Array.isArray(dataToUse) &&
                dataToUse.map((item, index) => (
                    <RenderLabel
                        title={
                            typeof item === "string"
                                ? item
                                : item.text || item.value || ""
                        }
                        key={index}
                    />
                ))}

            {typeof dataToUse == "object" &&
                !Array.isArray(dataToUse) &&
                !_.isEmpty(dataToUse) && <RenderLabel title={dataToUse.text} />}

            {(typeof dataToUse == "string" || Number.isInteger(dataToUse)) && (
                <RenderLabel title={dataToUse} />
            )}

            {!disabled && !readOnly && allowCopy && (
                <CopyTo handleClick={handleCopyToClick} />
            )}
        </div>
    );
};

export default GracenoteLabelItem;
