import React from "react";
import { Stack } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Controller, useFormContext } from "react-hook-form";
import { CoreFormType } from "../../../types";
import { CoreFormLabel, CoreFormLabelProps } from "../../styled/CoreForm.label";
import { StyledTextField } from "../../styled/CoreFormText.field";
import { useStore } from "../../../../../components/Global/store/store";
import { formatDateTimeToPayload } from "../../../utils/formatDateTimeToPayload";
import { CoreFormMenuItem } from "../../styled/CoreFormDate.menuItem";
import { CoreFormFieldError } from "../../styled/CoreFormFieldError";
import { DateTimeDisabled } from "./DateTimeDisabled";
import system from "../../../../../config/system";
import { get, isEmpty } from "lodash";
import moment from "moment";
import dayjs from 'dayjs'

export function CoreInputTimePicker<T>({
    path,
    labelProps,
    format,
    disabled,
    payloadFormat,
    customStyles = {},
    ...muiTimePickerProps
}: {
    path: CoreFormType<T>;
    format?: string;
    payloadFormat?: string
    disabled?: boolean;
    customStyles?: React.CSSProperties,
} & { labelProps?: CoreFormLabelProps }) {
    const [store] = useStore() || [{}]
    const formMethods = useFormContext<T>();
    const value = formMethods.watch(path);
    const { setValue } = formMethods
    
    let timeFormat: string = format || store?.cmsData?.clientDataStructure?.time_format || system.datetime.payload.format.time
    let payloadTimeFormat: string = payloadFormat || system.datetime.payload.format.time
    const errorMessage = get(formMethods?.formState.errors, path)?.message;

    let views = ['hours', 'minutes']
    if (timeFormat.toLowerCase().includes('ss')) {
        views.push('seconds')
    }

    return (
        <Stack width="100%">
            {labelProps && <CoreFormLabel {...labelProps} />}
            <Controller
                name={path}
                render={({
                    field: { ref, onBlur, name, ...field },
                    fieldState,
                }) => {

                    if (disabled) {
                        const formattedValue = value ? moment(value, payloadTimeFormat).format(format) : null
                        return (<DateTimeDisabled value={formattedValue} />)
                    }

                    return (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                {...field}
                                {...muiTimePickerProps}
                                value={dayjs(value, payloadTimeFormat)}
                                format={timeFormat}
                                views={views}
                                inputRef={ref}
                                sx={customStyles}
                                error={!isEmpty(errorMessage)}
                                onChange={(value: any) => setValue(
                                    path,
                                    formatDateTimeToPayload(value, payloadTimeFormat)
                                )}
                                slots={{
                                    textField: StyledTextField,
                                    digitalClockSectionItem: CoreFormMenuItem
                                }}
                                slotProps={{
                                    textField: {
                                        path,
                                        onBlur,
                                        name,
                                        error: !!fieldState.error,
                                        helperText: fieldState.error?.message
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    )
                }}
            />
            {errorMessage && (
                <CoreFormFieldError path={path} message={errorMessage} />
            )}
        </Stack>
    );
}

CoreInputTimePicker.defaultProps = {
    labelProps: null,
};
