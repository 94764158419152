export default `validation {
    readOnly {
        enabled
        validation
    }
    required {
        enabled
        validation
    }
    unique {
        enabled
        withinCatalog
        validation
    }
    characterLimit {
        enabled
        limit
        minLimit
        validation
    }
    rangeLimit {
        enabled
        min
        max
        validation
    }
}`;
