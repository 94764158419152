import React from "react";
import _ from "lodash";
import update from "react-addons-update";
import deepcopy from "deepcopy";
import { diff } from "deep-object-diff";
import Index from "./Index";
import Notification from "../../Notification";
import Select from "../../Select";
import MaterialDatePicker from "../../Input/MaterialDatePicker";
import SendRequest from "../../SendRequest";
import Input from "../../Input/basic";
import Financials from "../../Financials";
import PlaceholderText from "../../PlaceholderText";
import AutocompleteInput from "../../AutocompleteInput";
import BubbleList from "../../BubbleList/simpleOnEnter";
import DoubleContent from "../../DoubleContent"
import "../styles/_financials.scss";

export default class FinancialsView extends Index {
    constructor(props) {
        super(props);

        /**
         * @var object
         */
        this.copy = this.props.copy || {};

        /**
         * @var object
         */
        this.state = {
            data: this.props.data || [],
            notification: {},
            financialInformation: {},
            fetchingUserGroups: true,
            userGroups: [],
            userGroupsError: false,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchUserGroups();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!_.isEmpty(diff(nextProps.data, this.props.data))) {
            return true;
        }

        if (!_.isEqual(nextProps.disabled, this.props.disabled)) {
            return true;
        }

        if (!_.isEqual(nextState, this.state)) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps) {
        if (!_.isEmpty(diff(this.props.data, prevProps.data))) {
            this.setStateValue("data", this.props.data);
        }
    }

    getUserGroupData() {
        const { data = [], userGroup = {} } = this.state;

        let updatedData = data
            .filter((item) => !item.deleted)
            .map((item, index) => {
                return {
                    ...item,
                    original_index: index,
                };
            });

        return (
            updatedData.filter((item) => item.user_group == userGroup.value) ||
            []
        );
    }

    fetchUserGroups() {
        const { cmsData = {} } = this.props;

        const { apiUrls = {}, user = {} } = cmsData;

        const options = {
            url: apiUrls.userGroupGet + "?api_token=" + user.api_token,
            method: "GET",
        };

        const handleError = (error) => {
            console.error(error);
            this.setState({
                userGroupsError: true,
                fetchingUserGroups: false,
            });
        };

        try {
            SendRequest(
                options,
                (response) => {
                    const { data = {}, status } = response;

                    if (status == 200) {
                        let userGroup = {};

                        if (!_.isEmpty(data) && Array.isArray(data)) {
                            userGroup = {
                                text: data[0].name,
                                value: data[0].meta_id,
                                platforms: data[0].platforms,
                            };
                        }

                        let userGroups = [];
                        if (Array.isArray(data)) {
                            userGroups = data.map((item) => {
                                return {
                                    text: item.name,
                                    value: item.meta_id,
                                    platforms: item.platforms,
                                };
                            });
                        }

                        this.setState({
                            fetchingUserGroups: false,
                            userGroups,
                            userGroup,
                        });

                        return;
                    }

                    handleError(data);
                },
                (e) => {
                    handleError(e);
                },
            );
        } catch (e) {
            handleError(e);
        }
    }

    handleFinancialInformationChange(key, value) {
        let financialInformation = deepcopy(this.state.financialInformation);
        if (key == "licensor" && typeof value === "object") {
            financialInformation.licensor = value.value || value.text;
            financialInformation.version = value.version || "";
            financialInformation.rightsline_id = value.rightsline_id || "";
        } else {
            financialInformation[key] = value;
        }

        this.setState(
            {
                financialInformation,
            },
            () => {
                this.handleFinancialInformationUpdate();
            },
        );
    }

    handleFinancialInformationUpdate() {
        let notification = deepcopy(this.state.notification);
        notification.disabled = !this.isFinancialInformationValid();

        this.setState({
            notification,
        });
        this.forceUpdate();
    }

    handleAddNew() {
        const RenderFinancialInformation = () =>
            this.renderFinancialInformation();

        if (!this.state.userGroupsError) {
            this.setState({
                notification: {
                    title: "Financial Information",
                    html: <RenderFinancialInformation />,
                    disabled: true,
                    confirm: () => this.handleFinancialInformationConfirm(),
                },
            });
        }

        if (this.state.userGroupsError) {
            this.setState({
                notification: {
                    title: "Financial Information",
                    description:
                        "There was a problem with user groups, please refresh the page and try again",
                },
            });
        }
    }

    handleEdit(original_index) {
        const RenderFinancialInformation = () =>
            this.renderFinancialInformation();

        this.setState({
            financialInformation: this.state.data[original_index],
            notification: {
                title: "Financial Information",
                html: <RenderFinancialInformation />,
                confirm: () =>
                    this.handleFinancialInformationEditUpdate(original_index),
            },
        });
    }

    handleDelete(original_index) {
        this.setState({
            notification: {
                title: "Delete Financial Information",
                description:
                    "Are you sure you want to delete this Financial Information?",
                okText: "Delete",
                confirm: () =>
                    this.handleFinancialInformationDelete(original_index),
            },
        });
    }

    handleFinancialInformationDelete(original_index) {
        const { data } = this.state;

        const newItem = data[original_index];
        newItem.deleted = true;

        const updatedState = update(data, {
            $splice: [[original_index, 1, newItem]],
        });

        this.handleUpdatedState(updatedState);
    }

    handleFinancialInformationEditUpdate(original_index) {
        const { financialInformation, data } = this.state;

        const updatedState = update(data, {
            [original_index]: {
                $set: financialInformation,
            },
        });

        this.handleUpdatedState(updatedState);
    }

    handleUpdatedState(updatedState) {
        this.setState(
            {
                notification: {},
                financialInformation: {},
            },
            () => {
                this.handleChange(updatedState);
            },
        );
    }

    handleFinancialInformationConfirm() {
        const { financialInformation = {}, userGroup = {} } = this.state;

        const updatedFinancialInformation = deepcopy(financialInformation);
        updatedFinancialInformation.user_group = userGroup.value;

        const updatedState = update(this.state.data, {
            $push: [updatedFinancialInformation],
        });

        this.handleUpdatedState(updatedState);
    }

    handleUserGroupChange(meta_id) {
        const { userGroups = [] } = this.state;

        const userGroup = userGroups.find((item) => item.value == meta_id);

        if (userGroup) {
            this.setState({
                userGroup,
            });
        }
    }

    handleChange(data) {
        const { handleFormChange } = this.props;

        this.setState(
            {
                data,
            },
            () => {
                if (typeof handleFormChange == "function") {
                    handleFormChange(data);
                }
            },
        );
    }

    isFinancialInformationValid() {
        const { financialInformation = {} } = this.state;

        const {
            platform,
            onboarding_date,
            expected_buy_runs,
            expected_sell_runs,
            buy_rate,
            sell_rate,
            offboarding_date,
            licensor,
        } = financialInformation;

        const { isLicensorMandatory = true } = this.props;

        let result = false;

        if (
            platform &&
            !_.isEmpty(platform) &&
            onboarding_date &&
            offboarding_date &&
            expected_buy_runs &&
            expected_sell_runs &&
            buy_rate &&
            sell_rate
        ) {
            result = !(isLicensorMandatory && !licensor);
        }

        return result;
    }

    renderFinancialInformation() {
        const {
            disabled,
            rights = [],
            isLicensorMandatory = true,
        } = this.props;

        const { financialInformation = {}, userGroup } = this.state;

        const { platforms = [] } = userGroup;

        const {
            onboarding_date,
            expected_buy_runs,
            expected_sell_runs,
            notes,
            buy_rate,
            sell_rate,
            offboarding_date,
            licensor,
        } = financialInformation;

        let { platform } = financialInformation;

        const licensors =
            rights.map((item) => {
                return {
                    text: item.licensor,
                    value: item.licensor,
                    postfix: item.version ? ` • ${item.version}` : "",
                    version: item.version || "",
                    rightsline_id: item.rightsline_id || "",
                };
            }) || [];

        const marginTop = "1.5rem";

        const RenderTitle = (props) => {
            return (
                <h2
                    style={{
                        margin: "2.34375rem 0 0",
                        fontSize: "1.05rem",
                        fontWeight: 500,
                    }}
                >
                    {props.title}
                </h2>
            );
        };

        if (typeof platform == "string") {
            platform = [platform];
        }

        platform =
            platform && platform.map((item) => ({ text: item, value: item }));

        return (
            <div className="meta-finance-edit-form">
                <BubbleList
                    emptyPlaceholder={"Enter Rights Platform"}
                    callBackOnDelete={() =>
                        this.handleFinancialInformationChange("platform", "")
                    }
                    bubbleOnSelect={true}
                    openOnClick={true}
                    filterStaticDataList={true}
                    financials={true}
                    data={platform}
                    clear={true}
                    id="rights_platforms"
                    label="Rights Platform"
                    disabledText="Enter Rights Platform"
                    noneSelectedText={true}
                    readOnly={disabled}
                    disabled={disabled}
                    datalist={platforms.map((item) => ({
                        text: item,
                        value: item,
                    }))}
                    background="grey"
                    color="white"
                    deleteWhite={true}
                    handleFormChange={(items) =>
                        this.handleFinancialInformationChange(
                            "platform",
                            items.map(({ value }) => value),
                        )
                    }
                    wrapperStyles={{
                        marginTop: marginTop,
                        color: "white",
                    }}
                    selectOnlyItem={true}
                />

                {isLicensorMandatory && (
                    <AutocompleteInput
                        className="meta-financial-edit-form-licensor"
                        placeholder={"Enter Licensor"}
                        callBackOnDelete={() =>
                            this.handleFinancialInformationChange(
                                "licensor",
                                "",
                            )
                        }
                        bubbleOnSelect={true}
                        openOnClick={true}
                        filterStaticDataList={true}
                        financials={true}
                        value={licensor}
                        clear={true}
                        id="licensors"
                        label="Licensor"
                        disabledText="Enter Licensor"
                        noneSelectedText={true}
                        readOnly={disabled}
                        disabled={disabled}
                        staticDatalist={licensors}
                        handleListSelection={(item) =>
                            this.handleFinancialInformationChange(
                                "licensor",
                                item,
                            )
                        }
                        styles={{
                            marginTop,
                        }}
                        selectOnlyItem={true}
                        selectOnlyItemObject={true}
                    />
                )}

                <div
                    className="container-flex"
                    style={{
                        marginTop,
                    }}>

                    <DoubleContent
                        widths={["50%", "50%"]}
                        childMargin="0.75rem">

                        <MaterialDatePicker
                            id="onboarding_date"
                            labelLegacy="Onboarding Date"
                            placeholder="Select a Date"
                            name="onboarding_date"
                            defaultValue={onboarding_date}
                            maxDate={offboarding_date}
                            handleChange={(date) =>
                                this.handleFinancialInformationChange(
                                    "onboarding_date",
                                    date,
                                )
                            }
                        />

                        <MaterialDatePicker
                            id="offboarding_date"
                            labelLegacy="Off-boarding Date"
                            placeholder="Select a Date"
                            name="offboarding_date"
                            defaultValue={offboarding_date}
                            dataToggle="offboarding_date"
                            minDate={onboarding_date}
                            handleChange={(date) =>
                                this.handleFinancialInformationChange(
                                    "offboarding_date",
                                    date,
                                )
                            }
                        />
                    </DoubleContent>
                </div>

                <div
                    className="container-flex"
                    style={{
                        marginTop,
                    }}
                >
                    <Input
                        id="expected_buy_runs"
                        type="number"
                        label="Expected Buy Runs"
                        placeholder="Enter buy runs"
                        value={expected_buy_runs}
                        recordBasic={true}
                        margin="0 0.75rem 0 0"
                        handleChange={(item) =>
                            this.handleFinancialInformationChange(
                                "expected_buy_runs",
                                item,
                            )
                        }
                    />

                    <Input
                        id="expected_sell_runs"
                        type="number"
                        label="Expected Sell Runs"
                        placeholder="Enter Sell Runs"
                        value={expected_sell_runs}
                        recordBasic={true}
                        margin="0 0 0 0.75rem"
                        handleChange={(item) =>
                            this.handleFinancialInformationChange(
                                "expected_sell_runs",
                                item,
                            )
                        }
                    />
                </div>

                <RenderTitle title="Rates" />

                <div
                    className="container-flex"
                    style={{
                        marginTop,
                    }}
                >
                    <Input
                        id="buy_rate"
                        type="number"
                        label="Buy Rate"
                        placeholder="Enter buy rate"
                        value={buy_rate}
                        recordBasic={true}
                        margin="0 0.75rem 0 0"
                        placeholderPrefixIcon="dollar-symbol.svg"
                        handleChange={(item) =>
                            this.handleFinancialInformationChange(
                                "buy_rate",
                                item,
                            )
                        }
                    />

                    <Input
                        id="sell_rate"
                        type="number"
                        label="Sell Rate"
                        placeholder="Enter Sell Rate"
                        value={sell_rate}
                        recordBasic={true}
                        margin="0 0 0 0.75rem"
                        placeholderPrefixIcon="dollar-symbol.svg"
                        handleChange={(item) =>
                            this.handleFinancialInformationChange(
                                "sell_rate",
                                item,
                            )
                        }
                    />
                </div>

                <RenderTitle title="Notes" />

                <Input
                    id="notes"
                    placeholder="Enter Notes Here"
                    value={notes}
                    recordBasic={true}
                    margin="0"
                    handleChange={(item) =>
                        this.handleFinancialInformationChange("notes", item)
                    }
                />
            </div>
        );
    }

    renderNoneAdded() {
        return false;
    }

    renderContent() {
        const { disabled, canCreateNew = true } = this.props;

        const {
            notification,
            fetchingUserGroups,
            userGroups = [],
            userGroup = {},
        } = this.state;

        const userGroupdData = this.getUserGroupData();

        if (!disabled && !fetchingUserGroups && _.isEmpty(userGroup)) {
            return (
                <PlaceholderText
                    text="User group is missing, please select or add a user group"
                    styles={{
                        margin: "1rem 0 0",
                    }}
                />
            );
        }

        return (
            <div>
                <Select
                    id="user_group"
                    value={userGroup.value}
                    clear={true}
                    disabledText="Select User Group"
                    noneSelectedText={true}
                    readOnly={fetchingUserGroups}
                    disabled={fetchingUserGroups}
                    loading={fetchingUserGroups}
                    data={userGroups}
                    handleChange={(item) => this.handleUserGroupChange(item)}
                    wrapperStyles={{
                        width: "18.4375rem",
                        padding: "0 0 1rem 0",
                        position: "absolute",
                        right: "0",
                        top: "1.375rem",
                    }}
                />

                {(!disabled || !_.isEmpty(userGroupdData)) && (
                    <Financials
                        {...this.props}
                        data={userGroupdData}
                        disabled={disabled || fetchingUserGroups}
                        handleAddNew={this.handleAddNew.bind(this)}
                        handleEdit={this.handleEdit.bind(this)}
                        handleDelete={this.handleDelete.bind(this)}
                    />
                )}

                {disabled && _.isEmpty(userGroupdData) && <PlaceholderText />}

                <Notification
                    title={notification.title}
                    html={notification.html}
                    description={notification.description}
                    okText={notification.okText || "Save"}
                    confirmOnEnter={true}
                    closeOnWrapperClick={false}
                    disabled={notification.disabled}
                    intercationStatus="alert"
                    background="white"
                    confirm={notification.confirm}
                    onClick={() => {
                        this.setState({
                            notification: {},
                            financialInformation: {},
                        });
                    }}
                />
            </div>
        );
    }
}
