import { GridRenderCellParams } from "@mui/x-data-grid";
import { CoreTableCell } from "../../components/CoreTableCell";
import { CoreTableGridColDef } from "../../types";

export const AwardsBodyCol: CoreTableGridColDef = {
    field: "awardsBody",
    headerName: "Awards Body",
    flex: 1,
    renderCell: (params: GridRenderCellParams<string>) => {
        const { value } = params;

        return <CoreTableCell boldText>{value}</CoreTableCell>;
    },
};
