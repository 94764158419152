import React from "react";
import { SectionInterface } from "./interface";
import VideoLandscape from "../../views/VideoLandscape";
import CategoryTags from "../../../CategoryTags";
import ai_movie from "../../ai/ai_movie";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";

const SectionVideoLandscape = (props: SectionInterface) => {
    const { data = {}, disabled } = props;

    const { category, original_title } = data;

    const displayDataLandscape =
        category == CategoryTags.Short.short &&
        original_title == "Star Wars: The Rise of Skywalker • Official Trailer";

    return (
        <VideoLandscape
            name="video_landscape"
            className="meta-record-video-landscape"
            data={displayDataLandscape ? ai_movie : []}
            disabled={disabled}
            noBorder={true}
            lastSection={true}
        />
    );
};

export default SectionVideoLandscape;
