import Prefixer from "inline-style-prefixer";
import Settings from "../../../Settings";

export default function styles(props = {}) {
    let defaultColor = "rgba(0, 0, 0, 0.87)";
    if (props.blue) defaultColor = "white";

    let color = props.color || defaultColor;

    const defaultValues = {
        fontSize: props.fontSize || ".875rem",
        padding: props.padding || ".5rem 1rem",
        margin: props.margin || "0.65625rem 0 0 0",
        width: props.width || "auto",
        maxWidth: "none",
        minWidth: "auto",
        background: props.background || Settings.colors.blue.lighter,
        color: props.color || "white",
        boxShadow: props.boxShadow || "none",
        display: props.display || "block",
        letterSpacing: props.letterSpacing || "normal",
        fontWeight: props.fontWeight || 400,
        lineHeight: "1",
        textTransform: props.textTransform || "none",
        transition: props.transition || "0.35s",
        border: "0",
        textAlign: props.textAlign || "center",
        borderRadius: "0.21875rem",
        appearance: "none",
        outline: "none",
        cursor: "pointer",
        visibility: props.visibility || "visible",
    };

    let styles = {
        default: defaultValues,
        primary: {
            background: Settings.colors.blue.lighter,
            color: "white",
            height: "2.375rem",
            margin: "0.65625rem 0 0 0",
        },
        secondary: {
            background: Settings.colors.grey.lighter,
            color: "white",
            height: "2.375rem",
            margin: "0.65625rem 0 0 0",
        },
        warning: {
            background: Settings.colors.amber.default,
            height: "2.375rem",
            margin: "0.65625rem 0 0 0",
        },
        buttonEdit: {
            background: Settings.colors.blue.lighter,
            color: "white",
            height: "2.375rem",
            margin: "0.65625rem 0 0 0",
        },
        buttonDelete: {
            background: Settings.colors.red.medium,
            color: "white",
            height: "2.375rem",
            margin: "0.65625rem 0 0 0",
        },
        buttonCancel: {
            background: Settings.colors.red.medium,
            color: "white",
            height: "2.375rem",
            margin: "0.65625rem 0 0 0",
        },
        actionButtonDelete: {
            background: Settings.colors.red.medium,
            padding: "0.3125rem",
            height: "auto",
            margin: "0 0 0 0.3125rem",
        },
        actionButtonPrimary: {
            background: Settings.colors.blue.lighter,
            padding: "0.3125rem",
            height: "auto",
            margin: "0 0 0 0.3125rem",
        },
        enrichmentButton: {
            background: Settings.colors.enrichment.background,
            height: "2.375rem",
        },
        fluid: {
            margin: "0",
            height: "2.375rem",
            width: "100%",
        },
        buttonText: {
            background: "transparent",
            boxShadow: "none",
            color: "white",
            display: "inline-block",
            margin: "0",
        },
        buttonWelcome: {
            fontSize: "1.05rem",
            fontWeight: 500,
            padding: "0.90625rem",
            textTransform: "uppercase",
        },
        small: {
            fontSize: "0.75rem",
            height: "1.25rem",
            margin: "0 0 0 0.3125rem",
            padding: "0 0.3125rem",
            textTransform: "uppercase",
        },
        imagesButtonPrimary: {
            background: Settings.colors.blue.lighter,
            color: "white",
            height: "2.375rem",
            width: "100%",
        },
        imagesButtonDelete: {
            background: Settings.colors.red.medium,
            color: "white",
            height: "2.375rem",
            width: "100%",
        },
        buttonSection: {
            fontSize: "0.875rem",
            height: "auto",
            lineHeight: "normal",
            margin: "0 0 0 0.5rem",
            padding: "0.5rem 1rem",
        },
        red: {
            background: Settings.colors.red.medium,
            color: "white",
        },
        grey: {
            background: Settings.colors.grey.lighter,
        },
        amber: {
            background: Settings.colors.amber.default,
        },
        blue: {
            background: Settings.colors.blue.medium,
        },
        orange: {
            background: Settings.colors.orange.medium,
            color: "white",
        },
        recordAction: {
            background: Settings.colors.blue.lighter,
            color: "white",
            height: "1.96875rem",
            margin: "0.65625rem 0 0 0",
        },
        error: {
            background: Settings.colors.error.default,
            margin: "0",
        },
        hover: {
            background: Settings.colors.blue.darker,
            color: "white",
            border: props.hoverBorder || defaultValues.border,
        },
        hoverPrimary: {
            background: Settings.colors.blue.darker,
            color: "white",
        },
        hoverSecondary: {
            background: Settings.colors.grey.lighter3,
        },
        hoverWarning: {
            background: Settings.colors.hover.amber,
        },
        hoverButtonEdit: {
            background: Settings.colors.blue.darker,
            color: "white",
        },
        hoverButtonDelete: {
            background: Settings.colors.hover.red,
        },
        hoverButtonCancel: {
            background: Settings.colors.hover.red,
        },
        hoverEnrichmentButton: {
            background: Settings.colors.enrichment.backgroundHover,
        },
        hoverActionButtonPrimary: {
            background: Settings.colors.blue.darker,
        },
        hoverActionButtonDelete: {
            background: Settings.colors.hover.red,
        },
        hoverButtonText: {
            background: "transparent",
            boxShadow: "none",
            color: "white",
            display: "inline-block",
            margin: "0",
        },
        hoverImagesButtonPrimary: {
            background: Settings.colors.blue.darker,
            color: "white",
        },
        hoverImagesButtonDelete: {
            background: Settings.colors.hover.red,
            color: "white",
        },
        hoverRed: {
            background: Settings.colors.hover.red,
        },
        hoverGrey: {
            background: Settings.colors.grey.lighter3,
        },
        hoverAmber: {
            background: Settings.colors.hover.amber,
        },
        hoverBlue: {
            background: Settings.colors.blue.dark,
        },
        hoverOrange: {
            background: Settings.colors.orange.dark,
            color: "white",
        },
        hoverRecordAction: {
            background: Settings.colors.blue.darker,
            color: "white",
        },
        hoverError: {
            background: Settings.colors.hover.error
        },
        disabled: {
            background: props.disabledBackground || Settings.colors.grey.lighter4,
            color: props.disabledColor || Settings.colors.grey.dark2,
            cursor: "default",
        },
        increaseOnHover: {
            transform: "scale(1.01)",
        },
        disabledOpacity: {
            background: props.background || Settings.colors.green.light,
            color: props.color || "rgba(0, 0, 0, 0.87)",
            opacity: 0.4,
        },
        content: {
            position: "relative",
            display: "flex",
            alignItems: "center",
            textAlign: props.textAlign || "center",
            width: "100%",
        },
        text: {
            width: "100%",
            display: "block",
            textAlign: props.textAlign || "center",
            fontWeight: props.fontWeight || 400,
        },
        textTransparent: {
            opacity: 0,
            position: "relative",
            textAlign: props.textAlign || "center",
        },
        loading: {
            maxHeight: props.fontSize || ".5rem",
            display: "block",
            margin: "auto",
            position: "absolute",
            left: "50%",
            WebkitTransform: "translateX(-50%)",
            transform: "translateX(-50%)",
            width: props.loadingIconWidth || "auto",
        },
        leftIcon: {
            width: "1.09375rem",
            marginRight: "0.40625rem",
        },
        rightIcon: {
            width: "1.09375rem",
            marginLeft: "0.40625rem",
        },
        marginLeftTop: {
            marginLeft: "0.65625rem",
            marginTop: "0rem",
        },
        tableControl: {
            marginLeft: "0.65625rem",
            marginTop: "0",
            padding: "0.375rem 0.625rem",
        },
    };

    let prefixer = new Prefixer();

    styles = prefixer.prefix(styles);

    return styles;
}
