import React from "react";
import { SectionInterface } from "./interface";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";
import { CUSTOM_FIELDS } from "../../../../models/RecordBuilder";
import IsActiveRecordBuilder from "../../../RecordBuilder/IsActive";
import OriginationCustomFields from "../../views/OriginationCustomFields";

const SectionOriginationCustomFields = (props: SectionInterface) => {
    const { cmsData = {}, data: recordData = {} } = props;

    const { clientFeatures = {} } = cmsData;
    const { recordBuilder } = clientFeatures
    const { customFieldsPayloadV2 } = recordBuilder

    const payloadKey: string = customFieldsPayloadV2
        ? "origination_v2"
        : "origination"

    const data = recordData[payloadKey] || {}
    const handleFormChange = (editData) => {
        if (props.handleFormChange) {
            props.handleFormChange(payloadKey, {...data, ...editData})
        }
    }

    const originationProps = {
        ...getSectionCommonProps({ ...props, field: payloadKey }),
        data,
        handleFormChange,
        name: "origination",
        className: "meta-record-origination",
        title: "Origination",
        category: recordData.category,
    };

    if (IsActiveRecordBuilder({ page: CUSTOM_FIELDS, clientFeatures })) {
        return <OriginationCustomFields {...originationProps} />;
    }

    return (<></>);
};

export default SectionOriginationCustomFields;
