import { Chip, Link } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { CoreCellText } from "../../../../../core/Table/components/core-cell-v2/CoreCellText";

export const RenderCellWithChipAndLink = (
    params: GridRenderCellParams<string | number> & { linkUrl?: string },
) => {
    const { value, linkUrl } = params;

    return useMemo(() => {
        let finalValue = value;

        if (value == null) {
            finalValue = "-";
        }

        if (finalValue === "-") {
            // No link, no value, no chip
            return <CoreCellText>{finalValue}</CoreCellText>;
        }

        let Label = null;
        let labelCss = {};
        if (!isEmpty(linkUrl)) {
            // Link and Chip
            Label = (
                <Link target="_blank" underline="hover" href={linkUrl}>
                    {finalValue}
                </Link>
            );
            labelCss = {
                fontSize: "0.85714rem",
                letterSpacing: "normal",
                lineHeight: "130%",
                color: "#2F6DCB",
                fontWeight: "400",
            };
        } else {
            // No link, just a chip
            Label = <CoreCellText color="#13572D">{finalValue}</CoreCellText>;
            labelCss = {
                fontSize: "0.85714rem",
                letterSpacing: "normal",
                lineHeight: "130%",
                fontWeight: "400",
            };
        }

        return (
            <Chip
                label={Label}
                sx={{
                    padding: "0.1875rem 0.3125rem",
                    background: !isEmpty(linkUrl) ? "#2F6DCB1A" : "#EBF7EF",
                    margin: "0",

                    "& .MuiChip-label": labelCss,
                }}
            />
        );
    }, [value]);
};
