import React from "react";
import _ from "lodash";
import Index from "./Index";
import "../styles/_gracenote.scss";
import GracenoteLabelItem from "../../Gracenote/LabelItem";
import GracenoteRegionalItem from "../../Gracenote/RegionalItem";
import GracenoteCertifications from "../../Gracenote/Certifications";
import GracenoteVersions from "../../Gracenote/Versions";
import GracenoteContributorItem from "../../Gracenote/ContributorItem";
import DataTransformer from "../../Gracenote/DataTransformer";
import CopyAttributesToMeta from "../../Gracenote/CopyAttributesToMeta";
import CopyAssetsAttributesToMeta from "../../Gracenote/CopyAssetsAttributesToMeta";
import PlaceholderText from "../../PlaceholderText";
import EmptyRecordDataTypeTransformer from "../../Transformers/EmptyRecordDataTypeTransformer";

export default class GracenoteMetaIntegration extends Index {
    constructor(props) {
        super(props);

        const {
            data = {},
            options = {},
            clientFeatures = {},
            apiUrls = {},
        } = this.props;

        this.state = {
            data: DataTransformer({
                data: data.gracenote || {},
                options,
                clientFeatures,
                apiUrls,
            }),
        };
    }

    shouldRenderNoneadded() {
        return _.isEmpty(this.state.data);
    }

    handleCopyToClick(props) {
        this.setState({
            copyTo: props,
        });
    }

    renderNoneAdded() {
        if (!this.shouldRenderNoneadded()) {
            return false;
        }

        const data = this.getData();
        let active = false;

        if (Array.isArray(data)) {
            data.forEach((item) => {
                if (!item.deleted) {
                    active = true;
                }
            });
        }

        if (active) {
            return false;
        }

        if ((this.props.disabled && !active) || _.isEmpty(data)) {
            const { clientFeatures = {} } = this.props;

            const { gracenote_meta_migration = {} } = clientFeatures;

            const { noneAddedText = "None Added" } = gracenote_meta_migration;

            return (
                <div style={this.styles.marginItem}>
                    <PlaceholderText copy={{ noneAdded: noneAddedText }} />
                </div>
            );
        }

        return false;
    }

    handleChange(props) {
        const { handleFormChange = () => {} } = this.props;

        const { item, result } = props;

        handleFormChange({
            section: item.parent_attribute || item.structureKey,
            result,
        });
    }

    renderTagsSection(structure, className) {
        const { single = [], multiple = [] } = structure;

        const { data = {} } = this.state;

        const { disabled } = this.props;

        if (_.isEmpty(structure)) {
            return false;
        }

        if (_.isEmpty(single) && _.isEmpty(multiple)) {
            return false;
        }

        const getProps = (item) => {
            let itemData = _.get(
                data,
                item.path ? item.path : item.name,
                null,
            );

            if(item.mapping && itemData){
                itemData = itemData.map((tag)=>{
                    const mappedItemData = {};

                    Object.keys(item.mapping).forEach(
                        (key) => {mappedItemData[key] = tag[item.mapping[key]]}
                    );

                    return mappedItemData;
                })
            }
    
            return {
                ...item,
                data: itemData,
                dataTransformed: data[item.transformed_name],
                handleCopyToClick: () =>
                    this.handleCopyToClick({
                        item,
                        data: itemData,
                    }),
                disabled,
            };
        };

        return (
            <div className={`gracenote-section ${className}`}>
                {single.map((item, index) => (
                    <GracenoteLabelItem {...getProps(item)} key={index} />
                ))}

                <div className="gracenote-three-columns">
                    {multiple.map((item, index) => (
                        <GracenoteLabelItem {...getProps(item)} key={index} />
                    ))}
                </div>
            </div>
        );
    }

    renderRegionalSection(structure) {
        const { data = {} } = this.state;

        const { disabled, options = {}, clientFeatures = {} } = this.props;

        if (_.isEmpty(structure)) {
            return false;
        }

        return (
            <div className="gracenote-section gracenote-regional">
                {structure.map((item, index) => {
                    return (
                        <div
                            className={
                                item.double ? "gracenote-two-columns" : ""
                            }
                            key={index}
                        >
                            <GracenoteRegionalItem
                                item={item}
                                data={data[item.name]}
                                label={item.label}
                                disabled={disabled}
                                attribute={item.attribute}
                                handleCopyToClick={(regionalItem) =>
                                    this.handleCopyToClick({
                                        item,
                                        data: regionalItem,
                                    })
                                }
                                options={options}
                                clientFeatures={clientFeatures}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }

    renderVersionsSection(structure) {
        const { data = {} } = this.state;

        const { disabled, options = {}, clientFeatures = {} } = this.props;

        if (_.isEmpty(structure)) {
            return false;
        }

        return (
            <div className="gracenote-section gracenote-versions">
                {structure.map((item, index) => {
                    return (
                        <div
                            className={
                                item.double ? "gracenote-two-columns" : ""
                            }
                            key={index}
                        >
                            <GracenoteVersions
                                data={data[item.name]}
                                label={item.label}
                                disabled={disabled}
                                attribute={item.attribute}
                                handleCopyToClick={(regionalItem) =>
                                    this.handleCopyToClick({
                                        item,
                                        data: regionalItem,
                                    })
                                }
                                options={options}
                                clientFeatures={clientFeatures}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }

    renderContributorSection(structure, key) {
        const { data = {} } = this.state;

        if (!structure) {
            return false;
        }

        const { disabled } = this.props;

        return (
            <div className="gracenote-section gracenote-contributors">
                <GracenoteContributorItem
                    {...structure}
                    data={data[key]}
                    disabled={disabled}
                    handleCopyToClick={(contributor) =>
                        this.handleCopyToClick({
                            item: structure,
                            data: contributor,
                        })
                    }
                />
            </div>
        );
    }

    renderCertificationsSection(structure) {
        const { data = {} } = this.state;

        if (!structure) {
            return false;
        }

        const { disabled } = this.props;

        return (
            <div className="gracenote-section gracenote-certifications">
                <GracenoteCertifications
                    {...structure}
                    data={data.certifications}
                    disabled={disabled}
                    handleCopyToClick={(certificate) =>
                        this.handleCopyToClick({
                            item: structure,
                            data: certificate,
                        })
                    }
                />
            </div>
        );
    }

    renderContent() {
        const {
            disabled,
            clientDataStructure = {},
            options = {},
            clientFeatures = {},
        } = this.props;

        const { data = {}, copyTo = {} } = this.state;

        const { gracenote_meta_integration = {} } = clientDataStructure;

        if (disabled && _.isEmpty(data)) {
            return false;
        }

        return (
            <div>
                {!_.isEmpty(data) && (
                    <div>
                        {this.renderTagsSection(
                            gracenote_meta_integration.origination || {},
                            "gracenote-origination",
                        )}
                        {this.renderTagsSection(
                            gracenote_meta_integration.assets || {},
                            "gracenote-assets",
                        )}
                        {this.renderTagsSection(
                            gracenote_meta_integration.release_dates || {},
                            "gracenote-release_dates",
                        )}
                        {this.renderRegionalSection(
                            gracenote_meta_integration.regional,
                        )}
                        {this.renderCertificationsSection(
                            gracenote_meta_integration.certifications,
                        )}
                        {this.renderVersionsSection(
                            gracenote_meta_integration.versions,
                        )}
                        {this.renderContributorSection(
                            gracenote_meta_integration.contributors,
                            "contributors",
                        )}
                        <CopyAttributesToMeta
                            {...copyTo}
                            metadata={this.props.data}
                            handleCancel={() => this.setState({ copyTo: {} })}
                            handleChange={(options) =>
                                this.handleChange(options)
                            }
                            clientDataStructure={clientDataStructure}
                            options={options}
                            clientFeatures={clientFeatures}
                        />

                        <CopyAssetsAttributesToMeta
                            {...copyTo}
                            metadata={EmptyRecordDataTypeTransformer(
                                this.props.data,
                            )}
                            handleCancel={() => this.setState({ copyTo: {} })}
                            handleChange={(options) =>
                                this.handleChange(options)
                            }
                            clientDataStructure={clientDataStructure}
                            options={options}
                        />
                    </div>
                )}
            </div>
        );
    }
}
