import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import { ContributorInterface, ContributorImageInterface } from "../../../features/Preferences/Contributors/interfaces"
import { getAvatarImage, getContributorName } from "../../../models/Contributor/model"

type AvatarProps = {
    imgSrc: string;
};

const CustomAvatar = ({ imgSrc }: AvatarProps) => {
    return (
        <Avatar src={imgSrc}>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10.0002 3.33331C10.8842 3.33331 11.7321 3.6845 12.3572 4.30962C12.9823 4.93474 13.3335 5.78259 13.3335 6.66665C13.3335 7.5507 12.9823 8.39855 12.3572 9.02367C11.7321 9.64879 10.8842 9.99998 10.0002 9.99998C9.11611 9.99998 8.26826 9.64879 7.64314 9.02367C7.01802 8.39855 6.66683 7.5507 6.66683 6.66665C6.66683 5.78259 7.01802 4.93474 7.64314 4.30962C8.26826 3.6845 9.11611 3.33331 10.0002 3.33331ZM10.0002 11.6666C13.6835 11.6666 16.6668 13.1583 16.6668 15V16.6666H3.3335V15C3.3335 13.1583 6.31683 11.6666 10.0002 11.6666Z"
                    fill="#DEDEDE"
                />
            </svg>
        </Avatar>
    );
};

const StyledListItemAvatar = styled(ListItemAvatar)(({ theme }) => ({
    "& .MuiAvatar-root": {
        background: "#F5F5F5",
    },
}));

export const ContributorListItem = (contributor: ContributorInterface) => {
    const avatar: ContributorImageInterface | null = getAvatarImage(contributor)
    const name: string = getContributorName(contributor)

    return (
        <ListItem>
            <StyledListItemAvatar>
                <CustomAvatar imgSrc={avatar?.url} />
            </StyledListItemAvatar>

            <ListItemText 
                primary={name} 
                secondary={contributor?.id} 
            />
        </ListItem>
    );
};
