import React, { Component } from "react";
import Tag from "../Tag";

export default class DoubleTransparentTag extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            textLeft,
            textRight,
            fontSize,
            leftBorderColor,
            leftBackgroundColor,
            leftColor,
            rightBackgroundColor,
            rightColor,
            rightBorderColor,
            margin,
            fontWeight,
            className,
            padding,
        } = this.props;

        return (
            <div style={{ margin }} className={className}>
                <Tag
                    text={textLeft}
                    background="transparent"
                    styles={{
                        display: "inline-block",
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        fontSize,
                        color: leftColor,
                        border: `0.065rem solid ${leftBorderColor}`,
                        backgroundColor: leftBackgroundColor || "transparent",
                        fontWeight,
                    }}
                />

                <Tag
                    text={textRight}
                    styles={{
                        display: "inline-block",
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        border: `0.065rem solid ${
                            rightBorderColor || rightBackgroundColor
                        }`,
                        borderLeft: 0,
                        color: rightColor,
                        fontSize,
                        backgroundColor: rightBackgroundColor,
                        fontWeight,
                    }}
                />
            </div>
        );
    }
}
