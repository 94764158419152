/**
 * All of these interfaces are the same on frontend and backend
 */

import { StandaloneTicket } from "../containers/ticket/shared/validationSchema";

export type TicketType = {
    label: string;
    value: number;
};

export type TicketRequestType = "recordRequest" | "recordUpdate";

export enum TicketCommentScope {
    Internal = 'internal',
    External = 'external'
};

export type TaskManagerUser = {
    id: number;
    name: string;
    email?: string;
};

export type TicketPriority = {
    label: string;
    value: number;
};

export type TicketStatus = {
    label: string;
    value: number;
};

export type ResponsePaginationObject = {
    total: number;
    size: number;
    page: number;
    limit: number;
};

export type RequestPaginationObject = {
    page: number;
};

export type GetTicketResponse = {
    data: Array<StandaloneTicket>;
    pagination: ResponsePaginationObject;
};

export type StandaloneRecord = {
    id: number;
    title: string;
    category: string;
    releaseYear: number;
    brand: string;
    provenance: string;
    originalLanguage: string;
    countryOfOrigin: string;
    collection: string;
};
