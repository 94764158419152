import React from "react";
import Titles from "./Titles";
import DoesTitleReadOnly from "../../RecordHeader/src/DoesTitleReadOnly";
import FullScreenLoadingSpinner from "../../FullScreenLoadingSpinner";
import Notification from "../../Notification";
import transformTitlesToDataStructure from "./components/builder/transformTitlesToDataStructure";
import _ from "lodash";
import RegionalItem from "./RegionalItem";
import FadeWithSkeleton from "../../SkeletonLoader/FadeWithSkeleton";
import BuilderError from "../../RecordBuilder/FetchError";
import GlobalPageContext from "../../Global/store/context";
import { TITLES } from '.././../../models/RecordBuilder'
import { getRegionalStatusesOptions, getSelectedDefaultRegionalStatus } from '../../../models/ClientManaged/regionalStatusModel'

class TitlesBuilder extends Titles {
    constructor(props, context) {
        super(props, context);

        let globalState = [];

        if (context) {
            [globalState] = context;
        }

        this.recordBuilder = globalState.recordBuilder || {};
        this.recordBuilderError = globalState.recordBuilderError || false;
    }

    componentDidMount() {
        if (!this.state.structureFetched) {
            this.setState({
                builderStructure: transformTitlesToDataStructure({
                    data: this.recordBuilder[TITLES],
                    category: this.props.category,
                }),
                structureFetched: true,
            });
        }

        if (!Array.isArray(this.state.regionalStatuses)) {
            getRegionalStatusesOptions(this.props.clientFeatures, this.props.cmsData).then((regionalStatuses) => {
                this.setState({
                    regionalStatuses,
                });
            });
        }
    }

    renderContent() {
        const {
            fetchingTranslations,
            notification,
            builderStructure = [],
            data = [],
        } = this.state;

        const {
            languageIso,
            newRecordEdit,
            supply_chain = {},
            title = "",
        } = this.props;

        const { digital_readiness_status = "draft" } = supply_chain;
        const marginTop = { marginTop: "1.5rem" };

        return (
            <>
                <h2 className="section-title">{title}</h2>

                {this.recordBuilderError && <BuilderError />}

                {!this.recordBuilderError && (
                    <>
                        {this.renderUpdatedAt()}

                        <div style={this.styles.flexMarginContainer}>
                            {builderStructure.map((item, index) => (
                                <div
                                    style={Object.assign(
                                        {},
                                        this.styles.flexMarginItemDouble,
                                        marginTop,
                                    )}
                                    key={index}
                                >
                                    {this.renderTitle({
                                        ...item,
                                        placeholder: item.placeholder,
                                        data: this.getTitle(item.key),
                                        counter: true,
                                        readOnly:
                                            DoesTitleReadOnly({
                                                title: item,
                                                languageIso: languageIso || "",
                                                digital_readiness_status,
                                            }) && !newRecordEdit,
                                    })}
                                </div>
                            ))}
                        </div>
                    </>
                )}

                <Notification {...notification} />

                {fetchingTranslations && <FullScreenLoadingSpinner />}
            </>
        );
    }

    renderTitle(props) {
        const {
            label,
            placeholder,
            key,
            data = {},
            maxCharacter,
            counter = false,
            readOnly = false,
            presentation_title = false,
            googleTranslate = false,
            status: displayStatusSelect,
            release,
            display,
            comment,
            defaultComment,
            defaultCommentValue,
            characterLimit,
            disableValidation,
        } = props;

        const {
            validationKeys = [],
            handleOnBlur,
            disabled,
            handleRegionalTitleSwitchChange,
            clientFeatures = {},
            cmsData = {}
        } = this.props;

        return (
            <div className={`meta-regional-title-${key}`}>
                <RegionalItem
                    inputKey="title"
                    dataSource="titles"
                    label={label}
                    placeholder={placeholder}
                    name={`${key}_title`}
                    data={data}
                    disabled={disabled}
                    readOnly={readOnly}
                    maxCharacter={disableValidation ? 999999 : maxCharacter}
                    counter={counter}
                    validationKeys={validationKeys}
                    type={key}
                    presentation_title={presentation_title}
                    handleRegionalTitleSwitchChange={
                        handleRegionalTitleSwitchChange
                    }
                    googleTranslateItems={this.getGoogleTranslateItems(key)}
                    googleTranslate={googleTranslate}
                    handleOnBlur={handleOnBlur.bind(this, key)}
                    handleChange={this.handleChange.bind(this)}
                    handleGooleTranslateClick={this.handleGooleTranslateClick.bind(
                        this,
                    )}
                    languageIso={this.props.languageIso || null}
                    displayStatusSelect={displayStatusSelect}
                    switchOptions={{
                        hideDisplay: !display,
                        displayRelease: release,
                    }}
                    commentsOptions={{
                        comment,
                        defaultComment,
                        defaultCommentValue,
                    }}
                    statusOptions={this.state.regionalStatuses}
                />
            </div>
        );
    }
}

TitlesBuilder.contextType = GlobalPageContext;

export default TitlesBuilder;
