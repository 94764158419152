const colors = {
    actor: {
        color: "#AD4261",
        background: "#FAC6D5"
    },
    producer: {
        color: "#275F63",
        background: "#C9F2E4"
    },
    soundtrack: {
        color: "#523617",
        background: "#FFCC93"
    },
    additional_crew: {
        color: "#1E3E6F",
        background: "#C4D8F6"
    },
    director: {
        color: "#2E2787",
        background: "#DFD9FD"
    },
    writer: {
        color: "#671C0B",
        background: "#F0B9AD"
    },
    music_artist: {
        color: "#663D79",
        background: "#E6C9F3"
    },
    composer: {
        color: "#515E23",
        background: "#D1E096"
    },
    sales: {
        color: "#1F5C16",
        background: "#C3F3BC"
    },
    marketing: {
        color: "#663D79",
        background: "#E6C9F3"
    },
}

export default colors