import { isEmpty, isEqual, isNumber, isString } from "lodash"
import { DynamicList } from "../interfaces/DynamicList.type"
import FieldInterface, { DataType, InputType } from '../interfaces/Field.interface'
import { CoreSelectOptionType } from "../../components/inputs/Select/types/CoreSelectOptionType"
import { transformOutgoingOption, transformOutgoingOptions } from "./OptionTransformer"
import validString from "../utils/validString"

type Props = {
    fields: FieldInterface[]
    data: any,
    originalData?: any,
    defaultData?: any,
    useNullValues?: boolean,
    useInitialValues?: boolean,
    lists: {
        [key: string]: DynamicList
    }
}

export const TransformOutgoingData = ({
    data = {},
    originalData = {},
    defaultData = {},
    fields = [],
    lists = {},
    useNullValues,
    useInitialValues
}: Props) => {
    let result = {}

    const selectSingleInputs: InputType[] = [InputType.Select]
    const selectMultipleInputs: InputType[] = [InputType.Autocomplete, InputType.SelectMultiple, InputType.SelectMultipleChips]
    const selectInputs: InputType[] = selectSingleInputs.concat(selectMultipleInputs)   
    
    Object.keys(data).forEach((dataKey: string) => {
        let field = fields.find((item: FieldInterface) => item.key == dataKey)

        if (field) {
            const { type, key } = field
            const { input } = type

            result[key] = data[key]

            // Map SELECT inputs 
            if (selectInputs.includes(input)) {
                const list: CoreSelectOptionType[] = lists[field.type.select?.listKey]?.options || []

                // Map structure for Select SINGLE Inputs where option should be an object in payload structure
                if (selectSingleInputs.includes(input)) {
                    result[key] = transformOutgoingOption({ field, list, option: data[key] })
                }

                // Map structure for Select MULTIPLE Inputs where option should be an object in payload structure
                if (selectMultipleInputs.includes(input)) {
                    result[key] = transformOutgoingOptions({field, list, options: data[key], prevOptions: originalData[key] })
                }
            }

            // Map TEXT inputs
            if (field.type.dataType === DataType.String 
                && isString(result[key]) 
                && !result[key]) {

                result[key] = useNullValues 
                    ? null 
                    : ""
            }

            // Map Number inputs
            if (field.type.dataType === DataType.Number && !isNumber(result[key])) {
                const numValue = result[key] ? Number(validString(result[key])) : null;
                result[key] = !isNaN(numValue) ? numValue : null;
            }
        }
    })

    const builtData = buildData({
        originalData,
        defaultData,
        data: result
    })

    const output = useInitialValues 
        ? {...defaultData, ...builtData} // pls dont change
        : builtData
    
    return output
}

export const buildData = ({ data, originalData, defaultData }) => {
    let payload = {}

    const originalDataKeys: string[] = Object.keys(originalData)

    Object.keys(data).forEach((key: string) => {
        if (originalDataKeys.includes(key)) {
            payload[key] = data[key]
        }

        const isDirty = !isEqual(data[key], defaultData[key])

        if (isDirty) {
            payload[key] = data[key]
        }
    })

    return payload
}