import { gql } from "@apollo/client";
import { FieldMapInterface } from "../../interfaces";
import validationOption from "./options/validation"
import iconOption from "./options/icon";
import permissions from "./options/permissions";
import messages from "./options/messages";
import taskManager from "./options/taskManager";

const properties = `
    id
    displayName
    key
    order
    categories
    archived
    options {
        ${validationOption}
        ${iconOption}
        ${messages}
        recordHeaderDisplay {
            enabled
        }
        status {
            enabled
        }
        googleTranslate {
            enabled
        }
        comment {
            enabled
            default {
                enabled
                value
            }
        }
        ${taskManager}
    }
`;

export const schemaGetAll = `
    getSynopsisConfigs {
        label
        ${properties}
        ${permissions}
    }
`

export const queryGetAll = gql`
    query {
        ${schemaGetAll}
    }
`;

export const queryGetOne = gql`
    query {
        getSynopsisConfig {
            ${properties}
        }
    }
`;

export const mutationCreate = gql`
    mutation ($input: CreateSynopsisConfigInput!) {
        createSynopsisConfig(input: $input) {
            ${properties}
        }
    }
`;

export const mutationUpdate = gql`
    mutation ($id: ID!, $input: UpdateSynopsisConfigInput!) {
        updateSynopsisConfig(id: $id, input: $input) {
            ${properties}
        }
    }
`;

const map: FieldMapInterface = {
    createMutation: mutationCreate,
    updateMutation: mutationUpdate,
    queryAll: queryGetAll,
    dataPath: "getSynopsisConfigs",
}

export default map