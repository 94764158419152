import _ from "lodash";

import { ClientFeaturesInterface } from "../Global/interfaces";

interface IsClientManagedRecordTypesInterface {
    clientFeatures: ClientFeaturesInterface;
}

export function isClientManagedRecordTypes({
    clientFeatures,
}: IsClientManagedRecordTypesInterface) {
    const { managedLists = [] } = clientFeatures;

    return managedLists.includes("recordTypes");
}
