import _ from "lodash"
import BuilderFilterByCategory from '../../../../RecordBuilder/map/filterCategory'
import { ValidationLayer } from "../../../../../models/RecordBuilder/Field.interface";

const transformTitlesToDataStructure = ({
    data = [],
    fallback = [],
    category = "",
}) => {
    try {
        return BuilderFilterByCategory({ category, models: data })
            .filter(item => !item.archived)
            .sort((prev, next) => prev.order - next.order)
            .map((titleItem, index) => {
                const {
                    displayName: label = "",
                    key,
                    options = {},
                } = titleItem;

                return Object.assign({}, options, {
                    label,
                    key,
                    presentation_title: options.presentation.enabled,
                    maxCharacter: options.validation.characterLimit.limit,
                    disableValidation: !options.validation.characterLimit.enabled,
                    characterLimitEnforce: options.validation.characterLimit.enabled && options.validation.characterLimit.limit && options.validation.characterLimit.validation !== ValidationLayer.None,
                    characterLimitLength: options.validation.characterLimit.limit,
                    display: options.display.enabled,
                    googleTranslate: options.googleTranslate.enabled,
                    release: options.release.enabled,
                    status: options.status.enabled,
                    comment: options.comment.enabled,
                    defaultComment: options.comment.default.enabled,
                    defaultCommentValue: options.comment.default.value,
                    readOnly: options.validation.readOnly.enabled,
                });
            });
    } catch (error) {
        console.log(error);
        return fallback;
    }
};

export default transformTitlesToDataStructure;
