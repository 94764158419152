import React from 'react'
import { isEmpty } from 'lodash'
import { makeObservable, action, extendObservable } from 'mobx'
import { RootStore } from '../rootStore'
import { creditFormValidationStoreInitialState } from "../state"
import { CreditInterface, CreditLocalisedData } from "../interfaces"

interface PropsInterface {
    configuration?: any, 
    credit: CreditInterface, 
    creditOriginal?: CreditInterface
}

export class CreditFormValidationStore {
    rootStore: RootStore;
    errors: any | null;
    errorAlerts: string[];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            initialize: action,

            setErrorAlerts: action,
            setErrors: action,
            handleValidationCreate: action,
            handleValidationUpdate: action
        });

        extendObservable(this, creditFormValidationStoreInitialState);
    }

    initialize() {
        this.reset()
    }

    reset() {
        Object.keys(creditFormValidationStoreInitialState).forEach(
            (key) => (this[key] = creditFormValidationStoreInitialState[key]),
        );
    }

    getMandatoryFieldsForSection(fields: Array<any>): Array<string> {
        return fields
            ?.filter((item: any) => item?.options?.validation?.required?.enabled)
            .map((item: any) => item.key);
    }

    setErrorAlerts(errorAlerts: string[]): void {
        this.errorAlerts = errorAlerts
    }

    setErrors(errors: any): void {
        this.errors = errors
    }

    handleValidationCreate(configuration: any, credit: CreditInterface, creditOriginal: CreditInterface): void {
        const props: PropsInterface = {
            configuration,
            credit,
            creditOriginal
        }

        const messagesMandatoryFields: string[] = this.handleMandatoryFieldsValidation(props)
        const messagesExistingCredits: string[] = this.handleExistingCreditsValidation(props)

        const errorAlerts: string[] = [
            ...messagesMandatoryFields,
            ...messagesExistingCredits
        ]

        const result = isEmpty(errorAlerts) 
        this.setErrorAlerts(result ? [] : errorAlerts)
    }

    handleValidationUpdate(configuration: any, credit: CreditInterface, creditOriginal: CreditInterface): void {
        const props: PropsInterface = {
            configuration,
            credit,
            creditOriginal
        }

        const messagesMandatoryFields: string[] = this.handleMandatoryFieldsValidation(props)

        const errorAlerts: string[] = [
            ...messagesMandatoryFields
        ]

        const result = isEmpty(errorAlerts) 
        this.setErrorAlerts(result ? [] : errorAlerts)
    }

    handleMandatoryFieldsValidation(props: PropsInterface): string[] {
        let messages: string[] = []

        const mandatoryFields: Array<string> = this.getMandatoryFieldsForSection(props.configuration?.fields);

        mandatoryFields.forEach((field: string) => {
            if (!props.credit[field] || isEmpty(props.credit[field])) {
                const missingField = props.configuration?.fields.find((item: any) => item.key === field) || {}
                messages.push(`${missingField.displayName} is required`)
            }
        })

        return messages
    }

    handleExistingCreditsValidation(props: PropsInterface): string[] {
        let result: boolean = true

        const credits: CreditInterface[] = this.rootStore.creditsStore.getCredits()
        const creditCharacterNames: string[] = this.rootStore.creditsStore.getCreditCharacterNames(props.credit)
        const existingCredit: CreditInterface = this.rootStore.creditsStore.getCreditByRoleIdAndContributorId(credits, props.credit)
        const existingCredits: CreditInterface[] = this.rootStore.creditsStore.getCreditsByRoleIdAndContributorId(credits, props.credit)

        if (!existingCredit) {
            return []
        }

        if (this.rootStore.globalState.cmsData?.clientFeatures?.creditSingleRoleContributorAllowedOnly && !isEmpty(existingCredit)) {
            return ['The Role selected against this Contributor already exists']
        }

        // check if only one existing credits exists 
        if (existingCredits.length > 1) {
            return ['Maximum of two credits can be created for the same Role and Contributor. Try updating character names of existing credit.']
        }

        const existingCreditCharacterNames: string[] = this.rootStore.creditsStore.getCreditCharacterNames(existingCredit)

        if (isEmpty(creditCharacterNames)) {
            return ['The Role selected against this Contributor already exists']
        }

        creditCharacterNames?.forEach((characterName: string) => {
            if (existingCreditCharacterNames.includes(characterName)) {
                result = false
            }
        })

        return result 
            ? [] 
            : ['The Character Name entered against this Role and Contributor already exists']
    }
}
