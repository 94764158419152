import { debounce, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { AutocompleteRecord } from "../index";
import { getContributors } from "../../../../services/Contributors";
import { useStore } from "../../../../components/Global/store/store";

export const useFetchContributors = (
    value: string
) => {
    const [globalState] = useStore() || [{}];
    const [suggestions, setSuggestions] = useState<AutocompleteRecord[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const DELAY = 500;

    useEffect(() => {
        const fetchSuggestions = async () => {
            setLoading(true);
            setSuggestions([]);

            const response = await getContributors(globalState?.cmsData, false, value)

            const suggestionsAux = (response.status === 200 && value)
                ? response.data?.contributors
                : []

            setSuggestions(suggestionsAux);
            setLoading(false);
        };

        const debouncedFetchSuggestions = debounce(fetchSuggestions, DELAY);

        if (!isEmpty(value)) {
            debouncedFetchSuggestions();
        }

        return () => {
            debouncedFetchSuggestions.cancel();
            setLoading(false);
        };
    }, [value]);

    return {
        suggestions,
        loading
    };
};
