import React from "react";
import SectionContentBoilerplate from "../../../Boilerplates/SectionContentWrapper";
import { useStore } from "../../state/context";
import ScheduleContainer from "./ScheduleContainer";
import "../styles/_styles.scss";
import _ from "lodash";
import { HeaderTags } from "../config";

const PlatformSchedulingTemplate: React.FC = () => {
    const [store] = useStore();

    const { platformScheduling = [], loadingBuilderConfig = true } = store;

    const groupsBySchedulingSystem: any[] = _.groupBy(
        platformScheduling,
        "scheduling_system",
    );

    return (
        <div className="platform-scheduling-template">
            {!_.isEmpty(platformScheduling) &&
                Object.keys(groupsBySchedulingSystem).map(
                    (schedulingSystem: string, index: number) => {
                        return (
                            <SectionContentBoilerplate
                                header={HeaderTags(schedulingSystem)}
                                containers={[
                                    <ScheduleContainer
                                        data={
                                            groupsBySchedulingSystem[
                                                schedulingSystem
                                            ]
                                        }
                                    />,
                                ]}
                                key={index}
                            />
                        );
                    },
                )}
        </div>
    )
}

export default PlatformSchedulingTemplate