import { gql } from "@apollo/client";
import { FieldMapInterface } from "../../interfaces";
import editableOption from './options/editable'

const properties = `
    id
    code
    displayName
    order
    archived
    options {
        viewKey
        dataKey
        defaultValues {
            key
            value
        }
    }
    fields {
        key
        path
        displayName
        order
        dataType
        options {
            ${editableOption}
            hidden {
                enabled
            }
            bulkImport {
                enabled
            }
            layer {
                level
                columns
            }
            mandatory {
                enabled
            }
            validation {
                required {
                    enabled
                }
                url {
                    enabled
                }
                email {
                    enabled
                }
            }
        }
    }   
`;

export const schemaGetAll = `
    getContributorSectionConfigs {
        ${properties}       
    }
`
export const queryGetAll = gql`
    query {
        ${schemaGetAll}
    }
`;

export const name: string = 'contributorSections'

export const map: FieldMapInterface = {
    queryAll: queryGetAll,
    dataPath: "getContributorSectionConfigs",
}

export default map