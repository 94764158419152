import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import React from "react";

const Text = styled(Typography)(() => ({
    border: 0,
    padding: 0,
    marginLeft: 0,
    fontSize: "0.875rem",
    lineHeight: "1.138",
}));

export type CoreCellTextProps = React.PropsWithChildren & {
    color?: string;
};

export const CoreCellText = ({
    children,
    color = "rgba(149, 149, 149, 1)",
}: CoreCellTextProps) => {
    let result: any = "-";

    if (!_.isEmpty(children)) {
        result = children;
    }

    return <Text sx={{ color }}>{result}</Text>;
};

CoreCellText.defaultProps = {
    color: "rgba(149, 149, 149, 1)",
};
