export const mimeTypeFromExtension = (extension: string): string => {
    const allowedFileMimeTypes = {
        pdf: "application/pdf",
        csv: "text/csv",
        doc: "application/msword",
        docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        xls: "application/vnd.ms-excel",
        xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        msg: "application/vnd.ms-outlook",
        eml: "message/rfc822",
    };

    return allowedFileMimeTypes[extension];
};
