import { first, isEmpty } from "lodash";
import { action, extendObservable, makeObservable } from "mobx";
import {
    SubmitHandler,
    UseFieldArrayReplace,
    UseFormReset,
    UseFormReturn,
} from "react-hook-form";
import { AllFieldsVT } from "../config/types";
import { adaptFromBackend } from "../containers/record/standalone/adapters/adaptFromBackend";
import { adaptToBackendCreate } from "../containers/record/standalone/adapters/adaptToBackendCreate";
import { adaptToBackendUpdate } from "../containers/record/standalone/adapters/adaptToBackendUpdate";
import nuvoTvSeriesStore from "../containers/record/tv-series/store/nuvoTvSeriesStore";
import { default as tvSeriesStore } from "../containers/record/tv-series/store/store";
import { ChangeCollectionRecordVT } from "../containers/record/types";
import { StandaloneTicket } from "../containers/ticket/shared/validationSchema";
import { TMNuvoSerializer } from "../helpers/TaskManagerSerDes";
import {
    PriorityNoPriority,
    StatusApproved,
    StatusDraft,
    TicketTypeStandalone,
    TicketTypeUpdate,
} from "../types/constants";
import { SearchRecord } from "../types/interfaces";
import { StandaloneRecord, TicketType } from "../types/interfaces.shared";
import getTicketType from "../utils/getTicketType";
import unassignedUser from "../utils/unassignedUser";
import { RootStore } from "./rootStore";
import TaskManagerStoreInterface from "./TaskManagerStoreInterface";

export type InitialStateType = {
    ticketDefaultValues: StandaloneTicket;
    recordSearchResults: SearchRecord[];
    activityTabIndex: number;
    checkedTableRowIds: number[];
    recordIdsOfDuplicatedSP4MpmIds: AllFieldsVT["id"][];
    isApproving: boolean;
    inBulkEditMode: boolean;
};

const initialState: InitialStateType = {
    ticketDefaultValues: {
        id: undefined,
        ticketUuid: "",
        name: "",
        type: TicketTypeStandalone,
        status: StatusDraft,
        priority: PriorityNoPriority,
        assignee: unassignedUser,
        author: {
            id: -1,
            name: "",
        },
        createdAt: undefined,
        updatedAt: undefined,
        records: [],
        description: null,
    },
    recordSearchResults: [],
    activityTabIndex: 0,
    checkedTableRowIds: [],
    recordIdsOfDuplicatedSP4MpmIds: [],
    isApproving: false,
    inBulkEditMode: false,
};

class TicketDetailsStore implements TaskManagerStoreInterface {
    rootStore: RootStore;

    ticketDefaultValues: StandaloneTicket;

    recordSearchResults: InitialStateType["recordSearchResults"];

    checkedTableRowIds: InitialStateType["checkedTableRowIds"];

    activityTabIndex: InitialStateType["activityTabIndex"] =
        initialState.activityTabIndex;

    recordIdsOfDuplicatedSP4MpmIds: InitialStateType["recordIdsOfDuplicatedSP4MpmIds"] =
        [];

    isApproving: InitialStateType["isApproving"] = false;
    nuvoTransformer: TMNuvoSerializer;

    inBulkEditMode: InitialStateType["inBulkEditMode"];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.nuvoTransformer = new TMNuvoSerializer();

        makeObservable(this, {
            setTicket: action,
            approveTicket: action,
            setRecordSearchResults: action,
            setCheckedTableRowIds: action,
            create: action,
            update: action,
            changeActivityTab: action,
            initialize: action,
            validateRecords: action,
            isReadyToApprove: action,
            addSP4MpmIdToDuplicatedList: action,
            clearSP4MpmIdDuplicatedList: action,
            setInBulkEditMode: action,
        });

        extendObservable(this, initialState);
    }

    async initialize(...args: any[]) {
        if (this.rootStore.storesInit["ticketDetails"]) {
            throw new Error(
                `Task Manager ticketDetails Store has already been initialized.`,
            );
        }

        this.rootStore.finishLoading("ticketDetails");
    }

    setTicket(data: StandaloneTicket) {
        Object.keys(this.ticketDefaultValues).forEach((field) => {
            if (data[field] !== undefined) {
                this.ticketDefaultValues[field] = data[field];
            } else {
                this.ticketDefaultValues[field] =
                    initialState.ticketDefaultValues[field];
            }
        });
        this.checkedTableRowIds = [];
        this.clearSP4MpmIdDuplicatedList();
    }

    setNewTicket(type: TicketType) {
        nuvoTvSeriesStore.reset();
        tvSeriesStore.reset();

        this.setTicket({
            author: {
                name: "",
                email: "",
                id: this.rootStore.userId,
            },
            assignee: first(this.rootStore.ticketsStore.filterOptions.assignee),
            type,
        });
    }

    setCheckedTableRowIds(ids: number[]) {
        this.checkedTableRowIds = Array.from(ids);
    }

    setInBulkEditMode(inBulkEditMode: boolean) {
        this.inBulkEditMode = inBulkEditMode;
    }

    async approveTicket(formValues: StandaloneTicket, onSuccess: () => void) {
        const response = await this.rootStore.taskManagerApi.approve(
            formValues.id,
        );

        if (response.isError) {
            this.rootStore.handleApiError(response);
            return;
        }

        // Update ticket data in the tickets store
        // If we don't do this then navigating to a ticket will
        // show the old data
        const tickets = this.rootStore.ticketsStore.tickets;
        const ticketIndex = tickets.findIndex(
            (ticket) => ticket.id === formValues.id,
        );

        const getTicketsResponse =
            await this.rootStore.taskManagerApi.getTicketsByIds([
                formValues.id,
            ]);
        const [ticket] = adaptFromBackend(getTicketsResponse.data);
        this.rootStore.ticketsStore.setTicket(ticketIndex, ticket);

        this.rootStore.pushSnackbar(response.data.message);

        onSuccess();
    }

    setRecordSearchResults(results: SearchRecord[]) {
        this.recordSearchResults = Array.from(results);
    }

    hasTicketBeenSaved(ticket: StandaloneTicket) {
        return ticket.createdAt?.length > 0;
    }

    async create(ticketFormData: StandaloneTicket) {
        const response = await this.rootStore.taskManagerApi.create(
            adaptToBackendCreate(ticketFormData),
        );

        if (response.isError) {
            this.rootStore.handleApiError(response);
            return null;
        } else {
            const [ticket] = adaptFromBackend([response.data]);

            this.setTicket(ticket);

            return ticket;
        }
    }

    async update(
        ticketFormData: StandaloneTicket,
        resetFn: UseFormReset<StandaloneTicket>,
        displaySnackbar: boolean = true,
    ) {
        const response = await this.rootStore.taskManagerApi.update(
            ticketFormData.id,
            adaptToBackendUpdate(ticketFormData.id, ticketFormData),
        );

        if (response.isError) {
            this.rootStore.handleApiError(response);
        } else {
            // Update ticket data in the tickets store
            // If we don't do this then navigating to a ticket will
            // show the old data
            const tickets = this.rootStore.ticketsStore.tickets;
            const ticketIndex = tickets.findIndex(
                (ticket) => ticket.id === ticketFormData.id,
            );

            const getTicketsResponse =
                await this.rootStore.taskManagerApi.getTicketsByIds([
                    ticketFormData.id,
                ]);
            const [ticket] = adaptFromBackend(getTicketsResponse.data);
            this.rootStore.ticketsStore.setTicket(ticketIndex, ticket);
            this.setTicket(ticket);

            // Reset the form
            resetFn(ticketFormData);

            if (displaySnackbar) {
                this.rootStore.pushSnackbar(response.message);
            }
        }
    }

    /**
     * Returns an UUID-like string based on a ticket's data
     * This is not guaranteed to be unique but it does rely on
     * the ticket's ID which is a monotonically growing INT.
     *
     * This should be used only for display purposes.
     *
     * @param ticket Ticket data structure
     * @returns Generated UUID
     */
    getTicketUuid(ticket: StandaloneTicket): string {
        return ticket.id ? `${String(ticket.id)}` : "-";
    }

    async isReadyToApprove(formValues) {
        this.clearSP4MpmIdDuplicatedList();
        let validationPassed = true;

        if (getTicketType(formValues) !== TicketTypeUpdate) {
            validationPassed = await this.validateRecords(
                formValues?.records || [],
            );
        }

        console.log("Validation result: ", validationPassed);

        return validationPassed;
    }

    async validateRecords(records: StandaloneTicket["records"]) {
        this.isApproving = true;
        const validationPromises = records?.map(async (row) => {
            if (!isEmpty(row.metaId)) {
                return true;
            }

            const validationResult =
                this.rootStore.validatorStore.validateMandatoryFields(row);

            if (!isEmpty(validationResult)) {
                return false;
            }

            const isUnique =
                await this.rootStore.validatorStore.isS4MPMIdUnique({
                    value: row.ids.s4_mpm_id,
                });

            if (!isUnique) {
                this.rootStore.ticketDetailsStore.addSP4MpmIdToDuplicatedList(
                    row.id,
                );
            }

            return isUnique;
        });

        const results = await Promise.all(validationPromises);
        const isValid = results.every((result) => result);

        if (isValid) {
            this.rootStore.validatorStore.invalidFields = [];
        }

        this.isApproving = false;
        return !!(isValid && !isEmpty(records));
    }

    /**
     * Switch between activity tabs
     */
    changeActivityTab(activityTabIndex: number) {
        this.activityTabIndex = activityTabIndex;
    }

    addRecordsToCollection(
        collectionData: ChangeCollectionRecordVT,
        form: UseFormReturn<StandaloneTicket>,
    ) {
        this.changeCollectionForRecords(form, collectionData);
    }

    removeCollectionFromRecords(form: UseFormReturn<StandaloneTicket>) {
        this.changeCollectionForRecords(form, {
            collection: null,
        });
    }

    getCollectionCount(records: StandaloneRecord[]) {
        const checkedTableRowIds = this.checkedTableRowIds;
        let count = 0;

        for (let i = 0; i < checkedTableRowIds.length; i++) {
            const recordId = checkedTableRowIds[i];
            const index = records.findIndex((record) => record.id === recordId);

            if (index !== -1 && records[index].collection !== null) {
                count++;
            }
        }

        return count;
    }

    removeRecordsFromTicket(
        form: UseFormReturn<StandaloneTicket>,
        replace: UseFieldArrayReplace<StandaloneTicket>,
    ) {
        const checkedTableRowIds = this.checkedTableRowIds;
        const newRecords = form
            .getValues("records")
            .filter((record) => !checkedTableRowIds.includes(record.id));

        replace(newRecords);

        this.checkedTableRowIds = [];
    }

    async moveRecordsToNewTicket({
        form,
        onSubmit,
        removeRecords,
        snackbarAction,
    }: {
        form: UseFormReturn<StandaloneTicket>;
        onSubmit: SubmitHandler<StandaloneTicket>;
        removeRecords: () => void;
        snackbarAction: (ticketId: number) => JSX.Element;
    }) {
        // Delete records from the current ticket
        // Save the current ticket
        // Redirect to new ticket page and use URLParams to populate the new ticket
        // with the records from the current ticket

        const records = form.getValues("records");
        const checkedTableRowIds = Array.from(this.checkedTableRowIds);

        this.rootStore.pushSnackbar(
            `⏳ Creating a new ticket with the selected records. Please wait...`,
        );

        // Remove the selected records from the current ticket
        removeRecords();

        // Submit the form to save the current ticket
        form.handleSubmit(onSubmit)();

        const formData = form.getValues();
        const selectedRecords = records.filter((record) => {
            return checkedTableRowIds.includes(record.id);
        });

        const newTicket = await this.copyTicketWithRecords(
            formData,
            selectedRecords,
            snackbarAction,
        );

        this.setTicket(newTicket);
    }

    async copyTicketWithRecords(
        data: StandaloneTicket,
        records: StandaloneTicket["records"],
        snackbarAction: (ticketId: number) => JSX.Element,
    ) {
        const response = await this.rootStore.taskManagerApi.create(
            adaptToBackendCreate(
                {
                    ...data,
                    records,
                    name: data.name + " - COPY",
                    status: initialState.ticketDefaultValues.status,
                    priority: initialState.ticketDefaultValues.priority,
                    assignee: first(
                        this.rootStore.ticketsStore.filterOptions.assignee,
                    ),
                },
                { sourceTicketId: data.id },
            ),
        );

        if (response.isError) {
            this.rootStore.handleApiError(response);
            return null;
        }

        if (response.data) {
            const [newTicket] = adaptFromBackend([response.data]);

            this.rootStore.pushSnackbar(
                `Your records have been moved a new ticket.`,
                10000,
                snackbarAction(newTicket.id),
            );

            return newTicket;
        } else {
            return null;
        }
    }

    isMoveRecordsToNewTicketDisabled(isFormDirty: boolean) {
        // If there are no records selected, disable the button
        if (this.checkedTableRowIds.length === 0) {
            return true;
        }

        // Permissions check
        if (
            !this.rootStore.permissionsService.isAllowedToMoveRecordsToNewTicket(
                this.ticketDefaultValues,
            )
        ) {
            return true;
        }

        // If the current ticket has not been saved yet, disable the button
        if (isFormDirty) {
            return true;
        }

        // If this is a brand new ticket, disable the button
        if (this.ticketDefaultValues.id === null) {
            return true;
        }

        return false;
    }

    private changeCollectionForRecords(
        form: UseFormReturn<StandaloneTicket>,
        collectionData: ChangeCollectionRecordVT,
    ) {
        const checkedTableRowIds = this.checkedTableRowIds;

        const newRecords: StandaloneTicket["records"] = Array.from(
            form.getValues("records"),
        );

        for (let i = 0; i < checkedTableRowIds.length; i++) {
            const recordId = checkedTableRowIds[i];
            const index = newRecords.findIndex(
                (record) => record.id === recordId,
            );

            if (index !== -1) {
                newRecords[index].collection = collectionData.collection;
            }
        }

        form.setValue("records", newRecords, {
            shouldValidate: false,
            shouldDirty: true,
        });
    }

    addSP4MpmIdToDuplicatedList(id: AllFieldsVT["id"]) {
        this.recordIdsOfDuplicatedSP4MpmIds.push(id);
    }

    clearSP4MpmIdDuplicatedList() {
        this.recordIdsOfDuplicatedSP4MpmIds = [];
    }
}

export default TicketDetailsStore;
