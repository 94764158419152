import React from "react";
import { SectionInterface } from "./interface";
import HomeScreenLineupManager from "../../src/HomeScreenLineupManager";
import { getSectionCommonProps } from "../../actions";
import { useStore } from "../../../Global/store/store";

const SectionHomeScreenLineupManager = (props: SectionInterface) => {
    const [globalState] = useStore();

    return (
        <HomeScreenLineupManager
            {...getSectionCommonProps({ ...props, state: globalState })}
            {...props}
        />
    );
};

export default SectionHomeScreenLineupManager;
