import { CreditInterface, CreditScriptedCharacterNameInterface } from "../../../credits.interface";

interface SetCharaceterNameScriptsProps {
    credit: CreditInterface
    value: string|object
    script: string
}

const setCharaceterNameScripts = (props: SetCharaceterNameScriptsProps) => {
    const {
        credit,
        value,
        script
    } = props

    let creditCopy: CreditInterface
    let characterNameScriptedValues: CreditScriptedCharacterNameInterface[] = credit["script_character_names"] || []

    let updateTagetIndex = characterNameScriptedValues.findIndex(
        (scriptItem) =>
            scriptItem.code === script
    )

    if (updateTagetIndex !== -1) {
        let targetScript = characterNameScriptedValues[updateTagetIndex]
        targetScript.character_name = value as string
    } else {
        characterNameScriptedValues.push({
            code: script,
            character_name: value as string
        })
    }


    creditCopy = {
        ...credit,
        script_character_names: characterNameScriptedValues
    }

    return creditCopy
}

export default setCharaceterNameScripts