import React from "react";
import { SectionInterface } from "./interface";
import CollectionTitles from "../../views/CollectionTitles";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";
import Context from "../../../../context/Collections/Context";

const SectionCollectionTitles = (props: SectionInterface) => {
    const { cmsData = {}, data = {}, disabled, canViewFinancials } = props;

    return (
        <Context.Consumer>
            {(state) => (
                <CollectionTitles
                    {...getSectionCommonProps({
                        ...props,
                        field: "classification",
                    })}
                    name="collection-titles"
                    className="meta-record-collection-titles"
                    title="Collection Records"
                    data={data.linkage || []}
                    disabled={disabled}
                    lastSection={true}
                    state={state}
                    cmsData={cmsData}
                    meta_id={data.meta_id}
                    canViewFinancials={canViewFinancials}
                    displayTitle={false}
                />
            )}
        </Context.Consumer>
    );
};

export default SectionCollectionTitles;
