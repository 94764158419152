export const levelOne: number = 1
export const levelTwo: number = 2
export const levelThree: number = 3

export const expandableProps: any = {
    bodyStyles: { 
        padding: 0, 
        borderRadius: "0.375rem" 
    },
    borderRadius: true,
    className: "record-builder--multi-level-expandable",
    headerClassName: "record-builder--multi-level-expandable-header",
    defaultOpen: false,
    arrowIconType: "large"
}

export const labelColumnStyles: any = {
    minWidth: '10rem'
}