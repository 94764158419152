import { useEffect, useState } from "react";
import fetchClientManagedOptions from "../../../components/ClientManagedOptions/fetchClientManagedOptions";
import { CLIENT_MANAGED_CHANNEL_ORIGIN } from "../../../components/ClientManagedOptions/options";
import { CoreSelectOptionType } from "../../../core/Form/components/inputs/Select/types/CoreSelectOptionType";

export function useChannelOfOriginOptions(): CoreSelectOptionType[] {
    const [channelOfOriginOptions, setChannelOfOriginOptions] = useState<
        string[]
    >([]);

    const onResponse = (options: string[]) => {
        setChannelOfOriginOptions(options);
    };

    useEffect(() => {
        fetchClientManagedOptions({
            clientManagedType: CLIENT_MANAGED_CHANNEL_ORIGIN,
            onResponse,
        });
    }, []);

    return (
        channelOfOriginOptions?.map((p) => ({
            label: p,
            value: p,
        })) || []
    );
}
