import CastAndCrewOptions from '../../../../components/ClientManagedOptions/cast_and_crew'
import { envTest } from '../../../../components/Environment'
import { testRoles } from '../test/v2/testRoles'

const GetRolesOptions = props => {
    const {
        options = {},
        clientFeatures
    } = props

    let fallback = []

    if(Array.isArray(options.contributor_roles)) {
        fallback = options.contributor_roles
    }

    if (envTest) {
        return testRoles
    }

    return CastAndCrewOptions({
        options,
        fallback,
        clientFeatures
    })
}

export default GetRolesOptions