import { Components, ComponentsType } from "./Components";

export type CoreCreateDataTestIdType = {
    namespace: string;
    comonentType: keyof typeof Components;
    details: string;
};

export class CoreCreateDataTestId {
    protected namespace: string;

    constructor(namespace: string) {
        this.namespace = namespace;
    }

    generateTestId(componentType: ComponentsType, details: string): string {
        return `${this.namespace}-${componentType}-${details}`;
    }
}
