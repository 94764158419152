import React from "react";
import _ from "lodash";
import Ids from "./Ids";
import IDsFilterOnConfig from "../src/IDsFilterOnConfig";
import transformIDsToDataStructure from "./components/builder/transformIDstoDataStructure";
import FadeWithSkeleton from "../../SkeletonLoader/FadeWithSkeleton";
import BuilderError from "../../RecordBuilder/FetchError";
import GlobalPageContext from "../../Global/store/context";
import { CLIENT_IDS, ALTERNATE_IDS, SUPPLY_CHAIN_IDS } from '.././../../models/RecordBuilder'

class IDsBuilder extends Ids {
    constructor(props, context) {
        super(props, context);

        let globalState = [];

        if (context) {
            [globalState] = context;
        }

        this.recordBuilder = globalState.recordBuilder || {};
        this.recordBuilderError = globalState.recordBuilderError || false;
    }

    componentDidMount() {
        super.componentDidMount;

        this.setState({
            structureFetched: true,
        });
    }

    getDataStructure() {
        const { category } = this.props

        return {
            client: transformIDsToDataStructure({ data: this.recordBuilder[CLIENT_IDS], category }),
            supply_chain: transformIDsToDataStructure({ data: this.recordBuilder[SUPPLY_CHAIN_IDS], category }),
            alternate: transformIDsToDataStructure({ data: this.recordBuilder[ALTERNATE_IDS], category }),
        };
    }

    renderInputStructure(item) {
        const { verifiedFields = {}, handleVerifiedFieldsChange = () => {} } =
            this.props;

        let verifiedField = { name: item.key };

        if (!_.isEmpty(verifiedFields[item.key]))
            verifiedField = { ...verifiedFields[item.key] };

        verifiedField.selectedStatus = _.isEmpty(verifiedFields.fields)
            ? null
            : verifiedFields.fields[item.key];

        return this.renderInput({
            label: item.title,
            placeholder: `Enter ${item.title}${
                item.title.toUpperCase().endsWith(" ID") ? "" : " ID"
            }`,
            name: item.key,
            readOnly: item.readOnly || false,
            enableVerificationStatus: item.enableVerificationStatus || false,
            verifiedField: verifiedField,
            handleVerifiedFieldsChange: handleVerifiedFieldsChange,
            url: item.url,
            maxCharacter: item.maxCharacter || null,
            required: item.required || false,
        });
    }

    renderContent() {
        const {
            client = [],
            supply_chain = [],
            alternate = [],
        } = this.getDataStructure();

        const loading =
            _.isEmpty(client) &&
            _.isEmpty(supply_chain) &&
            _.isEmpty(alternate);
        
        if (loading && !this.recordBuilderError) {
            return <FadeWithSkeleton loading={loading} />;
        }

        return (
            <>
                {this.recordBuilderError && <BuilderError />}
                {!this.recordBuilderError && (
                    <>
                        {this.renderClientIds()}
                        {this.renderSupplyChainIds()}
                        {this.renderAltenateIds()}
                    </>
                )}
            </>
        );
    }

    renderClientIds() {
        const { client = [] } = this.getDataStructure();

        const { category = false } = this.props;

        return (
            <div className="meta-client-ids">
                {this.renderTitle("Client IDs")}

                <div
                    style={this.styles.flexMarginContainerNarrow}
                    className="meta-client-ids--container"
                >
                    {client.map((item, index) => {
                        let showItem = IDsFilterOnConfig({
                            category,
                            destination: "client",
                            key: item.key,
                        });

                        return (
                            <div
                                key={index}
                                style={this.styles.flexMarginItemQuoterNarrow}
                            >
                                {showItem && this.renderInputStructure(item)}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    renderSupplyChainIds() {
        const {
            client = [],
            supply_chain = [],
            alternate = [],
        } = this.getDataStructure();

        const { category = false } = this.props;

        return (
            <div
                style={Object.assign(
                    {},
                    !_.isEmpty(alternate) && this.styles.section.default,
                    !_.isEmpty(client) && this.styles.section.paddingTop,
                )}
                className="meta-supply-chain-ids"
            >
                {this.renderTitle("Supply Chain IDs")}

                <div
                    style={this.styles.flexMarginContainerNarrow}
                    className="meta-client-ids--container"
                >
                    {supply_chain.map((item, index) => {
                        let showItem = IDsFilterOnConfig({
                            category,
                            destination: "supply_chain",
                            key: item.key,
                        });

                        return (
                            <div
                                key={index}
                                style={this.styles.flexMarginItemQuoterNarrow}
                            >
                                {showItem && this.renderInputStructure(item)}
                                {showItem &&
                                    !_.isEmpty(item.logo) &&
                                    this.renderLogo(item.logo)}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    renderAltenateIds() {
        const { alternate = [], client = [] } = this.getDataStructure();

        return (
            <div
                style={Object.assign(
                    {},
                    !_.isEmpty(client) && this.styles.section.paddingTop,
                )}
                className="meta-alternate-ids"
            >
                {this.renderTitle("Alternate IDs")}

                <div
                    style={this.styles.flexMarginContainerNarrow}
                    className="meta-client-ids--container"
                >
                    {alternate.map((item, index) => {
                        return (
                            <div
                                key={index}
                                style={this.styles.flexMarginItemQuoterNarrow}
                            >
                                {this.renderInputStructure(item)}
                                {!_.isEmpty(item.logo) &&
                                    this.renderLogo(item.logo)}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

IDsBuilder.contextType = GlobalPageContext;

export default IDsBuilder;
