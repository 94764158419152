import _ from "lodash";

export function GetFromLocalStorage(name) {
    try {
        if (localStorage) {
            const itemStr = localStorage.getItem(name);

            if (_.isEmpty(itemStr)) {
                return null;
            }

            const item = itemStr ? JSON.parse(itemStr) : "";

            if (!item || !item.value) {
                return itemStr;
            }

            const now = new Date();
            if (item && item.expiry && now.getTime() > item.expiry) {
                RemoveItemFromLocalStorage(name);
                return null;
            }

            return item.value;
        }

        return "{}";
    } catch (error) {
        console.log(error);
        return "{}";
    }
}

export function SetLocalStorage(props) {
    if (localStorage) {
        const now = new Date();
        const TTL = process.env.STORAGE_TTL || 7200000; // 120 minutes

        const item = {
            value: props.value,
            expiry: now.getTime() + TTL,
        };

        localStorage.setItem(props.name, JSON.stringify(item));
    }
}

export function RemoveItemFromLocalStorage(keyName) {
    if (localStorage) {
        localStorage.removeItem(keyName);
    }
}
