import NumberPad from "../../Helpers/NumberPad";
import CategoryTags from "../../CategoryTags";
import externalLinks from "../../../config/external_links"
import _ from 'lodash'

export default function getIdHref(
    name = false,
    value = false,
    category = false,
    additionalData = {},
    url = ''
) {
    let result = false;

    if (name && value) {
        switch (name) {
            case "common_sense_id":
                result = `https://www.commonsensemedia.org/contentlink/${value}`;
                break;
            case "imdb_id":
                result = externalLinks.imdb_url.replace("{id}", value)
                break;
            case "box_office_mojo_id":
                result = `https://www.boxofficemojo.com/movies/?id=${value}.htm`;
                break;
            case "rotten_tomatoes_id":
                if (!category) {
                    break;
                }
                switch (category) {
                    case CategoryTags.Feature.short:
                        result = `https://www.rottentomatoes.com/m/${value}`;
                        break;
                    case CategoryTags.Show.short:
                        result = `https://www.rottentomatoes.com/tv/${value}`;
                        break;
                    case CategoryTags.Season.short:
                        if (additionalData.season_number !== "undefined") {
                            result = `https://www.rottentomatoes.com/tv/${value}/s${NumberPad(
                                additionalData.season_number,
                                2,
                            )}`;
                        }

                        break;
                    case CategoryTags.Episode.short:
                    case CategoryTags.ConstructedEpisode.short:
                        if (
                            additionalData.season_number !== "undefined" &&
                            additionalData.episode_number !== "undefined"
                        ) {
                            result = `https://www.rottentomatoes.com/tv/${value}/s${NumberPad(
                                additionalData.season_number,
                                2,
                            )}/e${NumberPad(additionalData.episode_number, 2)}`;
                        }
                        break;
                }

                break;
            case "isan_id":
                result = `http://web.isan.org/template/1.2/publicSearch.do?code=${value}`;
                break;
            case "metacritic_id":
                if (category && category == "Feature") {
                    result = `https://www.metacritic.com/movie/${value}`;
                }

                if (
                    category &&
                    (category == "Show" ||
                        category == "Season" ||
                        category == "Episode")
                ) {
                    result = `https://www.metacritic.com/tv/${value}`;
                }

                break;
            default:
                if (!_.isEmpty(url)) {
                    result = url.replace("{id}", value)
                }
        }
    }

    return result;
}
