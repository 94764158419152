/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import _ from "lodash";
import { CoreTableGridColDef } from "../../types";
import { observer } from "mobx-react";
import ExpandableTags from "../../../Tags/expandable";
import { useVisibleNames } from "../../hooks/useVisibleNames";

// FIXME: get the color value from theme
const StyledTextWrapper = styled(Box)(() => ({
    color: "#959595",
    width: "100%",
}));

const RenderCell = (params: GridRenderCellParams<string[]>) => {
    const disabled = params?.colDef?.disabled;

        const contributors = params?.value;

        const { visibleNames, hiddenNames, contributorWrapperRef } =
            useVisibleNames(contributors);

        const { colDef = {} } = params;

        const { awardsStore = () => {} } = colDef;

        if (_.isEmpty(contributors)) {
            return <StyledTextWrapper>-</StyledTextWrapper>; // TODO: extract a styled text wrapper
        }

        if (contributors) {
            return (
                <StyledTextWrapper>
                    <div
                        ref={contributorWrapperRef}
                        className="grid-cell--contributor"
                    >
                        <div className="contributor-wrapper">
                            {visibleNames.map((name, index) => (
                                <>
                                    <span
                                        key={name.id}
                                        className={
                                            disabled
                                                ? "item-contributor"
                                                : "item-contributor active"
                                        }
                                        onClick={
                                            disabled
                                                ? () => {}
                                                : () => {
                                                      /*awardsStore.setAwardEditContributorFormIsActive(true)*/
                                                  }
                                        }
                                    >
                                        {name}
                                    </span>
                                    {index !== visibleNames.length - 1 && (
                                        <span>, </span>
                                    )}
                                </>
                            ))}
                            {hiddenNames.length >= 1 && (
                                <ExpandableTags
                                    dataList={hiddenNames}
                                    disabled={disabled}
                                    text={`+${hiddenNames.length}`}
                                    awardsStore={awardsStore}
                                />
                            )}
                        </div>
                    </div>
                </StyledTextWrapper>
            );
        }
}

export const ContributorCol: CoreTableGridColDef = observer({
    headerName: "Contributors",
    field: "contributor",
    flex: 1,
    awardsStore: () => {},
    renderCell: (params: GridRenderCellParams<string[]>) => {
        return <RenderCell {...params} />;
    },
});
