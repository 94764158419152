import deepcopy from "deepcopy";
import _ from "lodash";
import { ValidationResult } from "../../models/Validation/interfaces";

export default function ValidateSubGenres(
    result: ValidationResult,
    options: any,
): ValidationResult {
    let response: ValidationResult = deepcopy(result);

    const { data = {}, copy = {}, status, clientDataStructure = {} } = options;

    const { descriptors = {} } = data;

    const dataStructureSubGenresValidation = _.get(
        clientDataStructure,
        `fieldValidation.descriptors.sub_genres`,
        null,
    );

    const subGenresLength = Number.isInteger(
        dataStructureSubGenresValidation?.length,
    )
        ? dataStructureSubGenresValidation.length
        : 20;

    if (Array.isArray(descriptors.sub_genres)) {
        const sub_genres = descriptors.sub_genres.filter(
            (item) => !item.deleted,
        );

        if (sub_genres.length > subGenresLength) {
            response.result = false;
            response.notification = {
                title: copy.pleaseCheckSubGenres || "Please check sub genres",
                description:
                    copy.maximum10SubGenresPerRecord ||
                    `Maximum ${subGenresLength} sub genres is allowed per record`,
                status,
            };

            response.validationKeys = result.validationKeys.concat([
                "sub_genres",
            ]);
        }
    }

    return response;
}
