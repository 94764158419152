import React, { Fragment } from "react";
import _ from "lodash";
import Index from "./Index";
import styles from "../styles";
import PeriodDropdownWithRefresh from "../../PeriodDropdownWithRefresh";
import SentimentAnalysis from "../../SentimentAnalysis";
import SentimentPopularity from "../../SentimentPopularity";
import PlaceholderText from "../../PlaceholderText";

export default class DailySeriesLandscape extends Index {
    constructor(props) {
        super(props);
    }

    renderNoneAdded() {
        return false;
    }

    renderTitle() {
        return false;
    }

    renderContent() {
        const { data = {} } = this.props;

        const classStyles = styles();

        if (_.isEmpty(data)) {
            return (
                <div>
                    <h2 style={classStyles.section.title}>
                        Daily Series Landscape
                    </h2>

                    <PlaceholderText styles={{ marginTop: "1.5rem" }} />
                </div>
            );
        }

        return (
            <div className="meta-daily-series-landscape">
                <div className="container-flex">
                    <h2 style={classStyles.section.title}>
                        Daily Series Landscape
                    </h2>

                    <PeriodDropdownWithRefresh
                        value="12/12/2019"
                        data={[
                            {
                                text: "12 December",
                                tag: "2019",
                                value: "12/12/2019",
                            },
                            {
                                text: "13 December",
                                tag: "2019",
                                value: "13/12/2019",
                            },
                        ]}
                        styles={{
                            marginLeft: "auto",
                        }}
                    />
                </div>

                <Fragment>
                    <label style={{ margin: "0.84375rem 0" }}>
                        Sentiment Analysis
                    </label>

                    <SentimentAnalysis
                        options={[
                            { key: "channel", title: "Channel" },
                            { key: "network_type", title: "Network Type" },
                            { key: "series_id", title: "Series ID" },
                            { key: "daypart", title: "Daypart" },
                            {
                                key: "reactions",
                                title: "Reactions",
                                format: "integer",
                            },
                            { key: "reaction_rate", title: "Reaction Rate" },
                            {
                                key: "unique_reaction_authors",
                                title: "Unique Reacting Authors",
                                format: "integer",
                            },
                            {
                                key: "avarage_followers",
                                title: "Average Followers",
                                format: "integer",
                            },
                        ]}
                        data={data}
                        styles={{
                            maxWidth: "85%",
                        }}
                    />

                    <label>Sentiment Popularity</label>

                    <SentimentPopularity data={data.keywords || {}} />
                </Fragment>
            </div>
        );
    }
}
