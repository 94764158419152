import { observer } from "mobx-react";
import { useFormContext } from "react-hook-form";
import { CoreInputText } from "../../../../../../core/Form/components/inputs/CoreInput.text";
import { AllFieldsVT, PrimaryVT } from "../../../../config/types";
import { HideFormConditionally } from "./HideFormConditionally";

export const ReleaseYear = observer(() => {
    const form = useFormContext<AllFieldsVT>();
    const category = form.getValues("category");

    return (
        <HideFormConditionally>
            <CoreInputText<PrimaryVT>
                path="releaseYear"
                labelProps={{
                    text: "Release Year",
                    isRequired: category === "Feature",
                }}
                type="number"
                placeholder="Start typing the release year..."
            />
        </HideFormConditionally>
    );
});
