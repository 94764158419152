import { SET_HOVER_RECORD } from "../actions/action_types";
import { defaultHoverRecord } from "../initialState";

export default function (state = { hoverRecord: defaultHoverRecord }, action) {
    switch (action.type) {
        case SET_HOVER_RECORD:
            return Object.assign({}, state, {
                hoverRecord: action.hoverRecord,
            });
    }

    return state;
}
