const bbcLondonEV = [
    {
        meta_id: "SF00001544",
        asset_type: "Original Master",
        system_type: "Asset Management System",
        system_name: "London EV",
        system_environment: "Development",
        compliance_code: "ORIG",
        status: "placeholder created",
        eidr: "10.5240/5C30-6149-D184-B9BC-3E75-3",
        audio: "ENG",
        video: "ENG",
        duration: "00.01.01.00",
        created_at: "03/08/2020 12:12:00",
        asset_id: "SF00001544",
        emea_id: "SF_970726133",
        latam_id: "002136555A/848",
        apac_id: "TRA956544A/387762",
        filename: "Jumanji_2017_Trailer_EN.mov",
        meta_users: [],
        image_width: "1920",
        image_height: "1080",
        color_encoding: "4:4:4",
        frame_rate: "23.976",
        stereoscopy: "Monoscopic",
        jpeg_200_profile: "2k IMF Single ML4 SL2 400 Mbit/s",
        colorimetry: "P3 D65 / SMPTE ST 2084 (PQ)",
        pixel_bit_depth: "12-bit",
        mdcv_metadata: "SMPTE ST-8206",
        dynamic_metadata_for_color_volume_transformation: "Dolby VisionTM",
        resolution: "1920x1080",
        fps: "25",
        audio_format: "stereo",
        bit_depth: "24-bit",
        sample_rate: "48 KHz",
        email_addresses: ["theo.holland@turner.com", "gabby.wong@turner.com"],
        compliances: [
            {
                meta_id: "SF00001544",
                asset_type: "Normalised",
                system_type: "Asset Management System",
                system_name: "London EV",
                system_environment: "Development",
                compliance_code: "NORM",
                status: "placeholder created",
                emea_id: null,
                apac_id: null,
                latam_id: null,
                eidr: null,
                video: "ENG",
                audio: "ENG",
                duration: "00.01.01.00",
                created_at: "03/08/2020 12:12:00",
                asset_id: "SF00001544",
                meta_users: [],
                email_addresses: [],
            },
        ],
    },
];

export default bbcLondonEV;
