import React from "react";
import _ from "lodash";
import "./styles/_styles.scss";
import moment from "moment";

const UpdatedAt = (props) => {
    const {
        updatingLogs = {},
        styles = {},
        clientDataStructure = {},
        sectionKey,
    } = props;

    const updatedData = updatingLogs[sectionKey] || {};

    const { update_logs = {} } = clientDataStructure;

    const { format } = update_logs;

    if (_.isEmpty(updatedData)) {
        return false;
    }

    const { updated_at, updated_by_name } = updatedData;

    let momentFormat = format || "HH:mm on Do MMMM YYYY";

    const date = moment(updated_at, "YYYY-MM-DD HH:mm:ss");
    const formatedDate = date.format(momentFormat);
    const validDate = date.isValid();

    if (validDate || !_.isEmpty(updated_by_name)) {
        return (
            <div className="updated-at" style={styles}>
                Last updated{validDate ? " at " + formatedDate : ""}
                {!_.isEmpty(updated_by_name) ? ` by ${updated_by_name}` : ""}
            </div>
        );
    }

    return false;
};

export default UpdatedAt;
