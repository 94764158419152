import React from "react";
import { SectionInterface } from "./interface";
import Certifications from "../../views/Certifications";
import CategoryTags from "../../../CategoryTags";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";

const SectionCertifications = (props: SectionInterface) => {
    const { cmsData = {}, data = {} } = props;

    const { clientFeatures = {} } = cmsData;

    const { showCertificationsRecordShow: certForShow = false } =
        clientFeatures;

    let title =
        (CategoryTags.Show.short == data.category &&
            certForShow &&
            certForShow.label) ||
        "Certifications";

    return (
        <Certifications
            {...getSectionCommonProps({ ...props, field: "certifications" })}
            name="certifications"
            className="meta-record-certifications"
            id="meta-record-certifications"
            title={title}
            data={data.certifications}
            clientFeatures={clientFeatures}
        />
    );
};

export default SectionCertifications;
