const TableHeader = disabled => {
    let headers = [
        {
            label: "#", 
            className: "credit-header ranking-number",
        }, 
        {
            label: "Contributor", 
            className: "credit-header name",
        }, 
        {
            label: "Role", 
            className: "credit-header role",
        },
        {
            label: "Role Type",
            className: "credit-header role-type",
        },
        {
            label: "Character", 
            className: "credit-header character-name",
        }, 
        {
            label: "Contributor ID", 
            className: "credit-header contributor-id",
        }, 
        {
            label: "Contributor IMDB", 
            className: "credit-header contributor-imdb",
        }, 
        {
            label: "Credit ID", 
            className: "credit-header credit-id",
        }
    ]

    if (!disabled) {
        headers.push({
            label: "Control", 
            className: "credit-header control",
        })
    }

    return headers
}

export default TableHeader