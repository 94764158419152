import _ from "lodash";

const HandleSetHover = (props) => {
    const { hoverRecord, state = {} } = props;

    const { set, hoverRecordData } = state;

    let hoverRecordDataState = hoverRecordData;

    if (!_.isEmpty(hoverRecord)) {
        hoverRecordDataState = hoverRecord;
    }

    set({
        hoverRecord,
        hoverRecordData: hoverRecordDataState,
    });
};

export default HandleSetHover;
