import React from "react";
import _ from "lodash";
import styles from "../styles";
import Input from "../../Input/basicCounter";
import Settings from "../../Settings";
import getIdHref from "../../Links/utils/getIdHref";
import isIndexInArray from "../../Helpers/isIndexInArray";
import Index from "./Index";
import deepcopy from "deepcopy";
import { diff } from "deep-object-diff";
import AuditTooltip from "../../AuditTooltip";
import VerificationStatus from "./components/VerificationStatus";
import AuthService from "../../../services/AuthService";
import Label from "../../Input/label";
import CopyTooltip from "../../CopyTooltip";
import Tooltip from "@mui/material/Tooltip";
import { characterLimit } from "../src/IDsCharacterLimit";

export default class Ids extends Index {
    constructor(props) {
        super(props);

        const data = this.props.data;
        const verifiedFields = this.props.verifiedFields;
        const overRide = AuthService.hasPermission("verifiedFields");

        let sectionOptions = [];
        if (Array.isArray(this.props.sectionOptions)) {
            sectionOptions = this.props.sectionOptions;
        }

        /**
         * @var object
         */
        this.copy = this.props.copy || {};

        /**
         * @var object
         */
        this.styles = styles(this.props);

        /**
         * @var bool
         */
        this.shouldDisplayClientIds = true;

        /**
         * @var bool
         */
        this.shouldDisplaySupplyChainIds = true;

        /**
         * @var bool
         */
        this.shouldDisplayAlternateIds = true;

        /**
         * @var object
         */
        this.state = {
            data,
            sectionOptions,
            verifiedFields,
            overRide,
            notification: {},
        };

        this.shouldSectionsRender();
    }

    componentDidMount() {
        this._isMounted = true;
    }

    shouldComponentUpdate(nextProps, nextState) {
        const nextPropsData = nextProps.data || {};
        const thisPropsData = this.props.data || {};
        const difference = diff(nextPropsData, thisPropsData);

        if (!_.isEmpty(difference)) {
            return true;
        }

        if (!_.isEqual(nextProps.disabled, this.props.disabled)) {
            return true;
        }

        if (!_.isEqual(nextProps.sectionOptions, this.props.sectionOptions)) {
            return true;
        }

        if (!_.isEqual(nextProps.audits, this.props.audits)) {
            return true;
        }

        if (!_.isEqual(nextProps.validationKeys, this.props.validationKeys)) {
            return true;
        }

        if (
            !_.isEqual(nextState.builderStructure, this.state.builderStructure)
        ) {
            return true;
        }

        // if(!_.isEqual(nextState, this.state)) {
        // 	return true
        // }

        return false;
    }

    componentDidUpdate(prevProps, prevState) {
        // sectionOptions
        if (!_.isEqual(this.props.sectionOptions, prevProps.sectionOptions)) {
            if (Array.isArray(this.props.sectionOptions)) {
                this.setStateValue("sectionOptions", this.props.sectionOptions);
            }
        }

        // data
        if (!_.isEqual(this.props.data, this.state.data)) {
            this.setStateValue("data", this.props.data);
        }
    }

    shouldSectionsRender() {
        this.shouldDisplayClientIds = this.shouldDisplaySection([
            "turner_emea_id",
            "turner_scarlett_id",
            "turner_apac_id",
            "turner_latam_id",
            "game_international_id",
            "house_id",
        ]);

        this.shouldDisplaySupplyChainIds = this.shouldDisplaySection([
            "rightsline_id",
            "whatson_id",
            "international_id",
        ]);

        this.shouldDisplayAlternateIds = this.shouldDisplaySection([
            "imdb_id",
            "common_sense_id",
            "rotten_tomatoes_id",
            "box_office_mojo_id",
            "metacritic_id",
            "tivo_id",
            "isan_id",
        ]);
    }

    handleChange(name, value) {
        const data = this.state.data;
        let stateData = deepcopy(data || {});
        let result = { ...stateData };

        if (typeof name == "string") {
            result[name] = value;

            this.setState(
                {
                    data: result,
                },
                () => {
                    if (typeof this.props.handleFormChange == "function") {
                        this.props.handleFormChange(result);
                    }
                },
            );
        }
    }

    handleStatusChange(name, value, selectedStatus) {
        const { overRide = false, allowIdEditting = false } = this.state;

        this.props.handleVerifiedFieldsChange({
            name: name,
            value: value,
        });

        if (overRide && _.isEmpty(selectedStatus)) {
            this.forceUpdate();
        }
    }

    shouldDisplaySection(options) {
        const { sectionOptions } = this.state;

        let result = false;

        if (Array.isArray(sectionOptions)) {
            options.forEach((item) => {
                if (isIndexInArray(sectionOptions, item)) {
                    result = true;
                }
            });
        }

        return result;
    }

    renderNoneAdded() {
        return false;
    }

    renderInput(options = {}) {
        const {
            label,
            placeholder,
            name,
            readOnly,
            enableVerificationStatus = false,
            verifiedField = {},
            url = false,
            maxCharacter,
        } = options;

        const { verified = false, selectedStatus = null } = verifiedField;

        const { data } = this.state;

        const {
            validationKeys,
            disabled,
            category,
            clientFeatures = {},
            episode_number,
            season_number
        } = this.props;

        let borderHiddenPadding = false;
        let color = false;
        let cursor = false;
        let href = false;

        const disableEdit =
            enableVerificationStatus &&
            ((!this.state.overRide && verified) ||
                (this.state.overRide && verified && _.isEmpty(selectedStatus)));

        if (disabled) {
            const additionalData = {
                episode_number: episode_number,
                season_number: season_number,
            };

            href = getIdHref(name, data[name], category, additionalData, url);
        }

        if (href) {
            borderHiddenPadding = true;
            color = "rgba(55, 117, 211, 0.87)";
            cursor = "pointer";
        }

        return (
            <AuditTooltip
                fieldKey={name}
                fieldName={"key"}
                value={name}
                dataSection={"identifiers"}
            >
                <div style={this.styles.section.inputGroup}>
                    {disableEdit || disabled || readOnly ? (
                        <div>
                            <Label label={label} fieldKey={name} />
                            {data[name] ? (
                                <CopyTooltip text={data[name] || ""}>
                                    <Tooltip
                                        title={data[name]}
                                        placement="bottom-start"
                                        arrow
                                        PopperProps={{
                                            disablePortal: true,
                                            datashow:
                                                data[name] &&
                                                data[name].length >=
                                                    characterLimit
                                                    ? "show"
                                                    : "hide",
                                        }}
                                    >
                                        {href ? (
                                            <a
                                                href={href}
                                                target="_blank"
                                                className="id-name"
                                            >
                                                {data[name]}
                                            </a>
                                        ) : (
                                            <span className="id-name">
                                                {data[name]}
                                            </span>
                                        )}
                                    </Tooltip>
                                </CopyTooltip>
                            ) : (
                                <span className="none-entered">
                                    None Entered
                                </span>
                            )}
                        </div>
                    ) : (
                        <Input
                            label={label}
                            placeholder={placeholder}
                            id={name}
                            value={data[name]}
                            name={name}
                            recordBasic={true}
                            color={color}
                            cursor={cursor}
                            href={href}
                            borderHiddenPadding={borderHiddenPadding}
                            error={isIndexInArray(validationKeys, name)}
                            handleChange={(item) =>
                                this.handleChange(name, item)
                            }
                            maxCharacter={maxCharacter}
                            counter={!!maxCharacter}
                        />
                    )}
                    {enableVerificationStatus && (
                        <VerificationStatus
                            name={name}
                            item={verifiedField}
                            disabled={disabled}
                            handleChange={(item) =>
                                this.handleStatusChange(name, item)
                            }
                            overRide={this.state.overRide}
                            forceUnverified={_.isEmpty(data[name])}
                        />
                    )}
                </div>
            </AuditTooltip>
        );
    }

    renderLogo(name) {
        if (this.props.disabled && name) {
            return (
                <img
                    src={`${Settings.images.path}/ids/${name}`}
                    style={this.styles.idLogo}
                />
            );
        }

        return false;
    }

    renderClientIds() {
        const { sectionOptions } = this.state;

        if (this.shouldDisplayClientIds) {
            return (
                <div className="meta-client-ids">
                    {this.renderTitle(this.copy.clientIds || "Client IDs")}

                    <div
                        style={this.styles.flexMarginContainerNarrow}
                        className="meta-client-ids--container"
                    >
                        {isIndexInArray(sectionOptions, "turner_emea_id") && (
                            <div style={this.styles.flexMarginItemQuoterNarrow}>
                                {this.renderInput({
                                    label:
                                        this.copy.turnerEmeaId ||
                                        "Turner EMEA ID",
                                    placeholder:
                                        this.copy.enterTurnerId ||
                                        "Enter EMEA ID",
                                    name: "turner_emea_id",
                                })}
                            </div>
                        )}

                        {isIndexInArray(
                            sectionOptions,
                            "turner_emea_game_id",
                        ) && (
                            <div style={this.styles.flexMarginItemQuoterNarrow}>
                                {this.renderInput({
                                    label:
                                        this.copy.turnerEmeaGameId ||
                                        "Turner EMEA Game ID",
                                    placeholder:
                                        this.copy.enterTurnerId ||
                                        "Enter EMEA ID",
                                    name: "turner_emea_id",
                                })}
                            </div>
                        )}

                        {isIndexInArray(
                            sectionOptions,
                            "turner_scarlett_id",
                        ) && (
                            <div style={this.styles.flexMarginItemQuoterNarrow}>
                                {this.renderInput({
                                    label:
                                        this.copy.scarelettId ||
                                        "Turner Scarlett ID",
                                    placeholder:
                                        this.copy.enterScarelettId ||
                                        "Enter Scarlett ID",
                                    name: "turner_scarlett_id",
                                })}
                            </div>
                        )}

                        {isIndexInArray(sectionOptions, "turner_apac_id") && (
                            <div style={this.styles.flexMarginItemQuoterNarrow}>
                                {this.renderInput({
                                    label: this.copy.apacId || "Turner APAC ID",
                                    placeholder:
                                        this.copy.enterApacId ||
                                        "Enter APAC ID",
                                    name: "turner_apac_id",
                                })}
                            </div>
                        )}

                        {isIndexInArray(
                            sectionOptions,
                            "turner_apac_game_id",
                        ) && (
                            <div style={this.styles.flexMarginItemQuoterNarrow}>
                                {this.renderInput({
                                    label:
                                        this.copy.apacGameId ||
                                        "Turner APAC Game ID",
                                    placeholder:
                                        this.copy.enterApacId ||
                                        "Enter APAC ID",
                                    name: "turner_apac_id",
                                })}
                            </div>
                        )}

                        {isIndexInArray(sectionOptions, "turner_latam_id") && (
                            <div style={this.styles.flexMarginItemQuoterNarrow}>
                                {this.renderInput({
                                    label:
                                        this.copy.latamId || "Turner LATAM ID",
                                    placeholder:
                                        this.copy.enterLatamId ||
                                        "Enter LATAM ID",
                                    name: "turner_latam_id",
                                })}
                            </div>
                        )}

                        {isIndexInArray(
                            sectionOptions,
                            "turner_latam_game_id",
                        ) && (
                            <div style={this.styles.flexMarginItemQuoterNarrow}>
                                {this.renderInput({
                                    label:
                                        this.copy.latamGameId ||
                                        "Turner LATAM Game ID",
                                    placeholder:
                                        this.copy.enterLatamId ||
                                        "Enter LATAM ID",
                                    name: "turner_latam_id",
                                })}
                            </div>
                        )}

                        {isIndexInArray(
                            sectionOptions,
                            "game_international_id",
                        ) && (
                            <div style={this.styles.flexMarginItemQuoterNarrow}>
                                {this.renderInput({
                                    label:
                                        this.copy.internationalGameId ||
                                        "International Game ID",
                                    placeholder:
                                        this.copy.enterInternationalGameId ||
                                        "Enter International Game ID",
                                    name: "game_international_id",
                                })}
                            </div>
                        )}

                        {isIndexInArray(sectionOptions, "house_id") && (
                            <div style={this.styles.flexMarginItemQuoterNarrow}>
                                {this.renderInput({
                                    label: this.copy.houseId || "House ID",
                                    placeholder:
                                        this.copy.enterHouseId ||
                                        "Enter House ID",
                                    name: "house_id",
                                })}
                            </div>
                        )}

                        {isIndexInArray(sectionOptions, "hbo_apac_id") && (
                            <div style={this.styles.flexMarginItemQuoterNarrow}>
                                {this.renderInput({
                                    label: "HBO APAC ID",
                                    placeholder: "Enter HBO APAC ID",
                                    name: "hbo_apac_id",
                                })}
                            </div>
                        )}

                        {isIndexInArray(sectionOptions, "mpm_number") && (
                            <div style={this.styles.flexMarginItemQuoterNarrow}>
                                {this.renderInput({
                                    label: "MPM Number",
                                    placeholder: "Enter MPM Number",
                                    name: "mpm_number",
                                })}
                            </div>
                        )}

                        {isIndexInArray(sectionOptions, "ibms_program_id") && (
                            <div style={this.styles.flexMarginItemQuoterNarrow}>
                                {this.renderInput({
                                    label: "IBMS Program ID",
                                    placeholder: "Enter IBMS Program ID",
                                    name: "ibms_program_id",
                                })}
                            </div>
                        )}

                        {isIndexInArray(sectionOptions, "pavi") && (
                            <div style={this.styles.flexMarginItemQuoterNarrow}>
                                {this.renderInput({
                                    label: "PAVI",
                                    placeholder: "Enter PAVI",
                                    name: "pavi",
                                })}
                            </div>
                        )}

                        {isIndexInArray(sectionOptions, "warnermedia_id") && (
                            <div style={this.styles.flexMarginItemQuoterNarrow}>
                                {this.renderInput({
                                    label: "WarnerMedia ID",
                                    placeholder: "Enter WarnerMedia ID",
                                    name: "warnermedia_id",
                                })}
                            </div>
                        )}

                        {isIndexInArray(sectionOptions, "titles_id") && (
                            <div style={this.styles.flexMarginItemQuoterNarrow}>
                                {this.renderInput({
                                    label: "Titles ID",
                                    placeholder: "Enter Titles ID",
                                    name: "titles_id",
                                })}
                            </div>
                        )}

                        {isIndexInArray(sectionOptions, "hbo_eu_clip_id") && (
                            <div style={this.styles.flexMarginItemQuoterNarrow}>
                                {this.renderInput({
                                    label: "HBO EU Clip ID",
                                    placeholder: "Enter HBO EU Clip ID",
                                    name: "hbo_eu_clip_id",
                                })}
                            </div>
                        )}

                        {isIndexInArray(sectionOptions, "hbo_eu_title_id") && (
                            <div style={this.styles.flexMarginItemQuoterNarrow}>
                                {this.renderInput({
                                    label: "HBO EU Title ID",
                                    placeholder: "Enter HBO EU Title ID",
                                    name: "hbo_eu_title_id",
                                })}
                            </div>
                        )}
                    </div>
                </div>
            );
        }

        return false;
    }

    renderSupplyChainIds() {
        const { sectionOptions } = this.state;

        const { clientFeatures = {} } = this.props;

        if (this.shouldDisplaySupplyChainIds) {
            return (
                <div
                    style={Object.assign(
                        {},
                        { paddingTop: 0 },
                        this.shouldDisplayClientIds &&
                            this.shouldDisplayAlternateIds &&
                            this.styles.section.default,
                        this.shouldDisplayClientIds &&
                            !this.shouldDisplayAlternateIds &&
                            this.styles.section.paddingTop,
                    )}
                    className="meta-supply-chain-ids"
                >
                    {this.renderTitle(
                        this.copy.supplyChainIds || "Supply Chain IDs",
                    )}

                    <div
                        style={this.styles.flexMarginContainerNarrow}
                        className="meta-client-ids--container"
                    >
                        {isIndexInArray(sectionOptions, "rightsline_id") && (
                            <div style={this.styles.flexMarginItemQuoterNarrow}>
                                {this.renderInput({
                                    label:
                                        this.copy.rightslineId ||
                                        "Rightsline ID",
                                    placeholder:
                                        this.copy.enterRightslineId ||
                                        "Enter Rightsline ID",
                                    name: "rightsline_id",
                                })}
                            </div>
                        )}

                        {isIndexInArray(sectionOptions, "whatson_id") && (
                            <div style={this.styles.flexMarginItemQuoterNarrow}>
                                {this.renderInput({
                                    label: this.copy.whatsOnId || "Whats’On ID",
                                    placeholder:
                                        this.copy.enterWhatsOnId ||
                                        "Enter Whats’On ID",
                                    name: "whatson_id",
                                })}
                            </div>
                        )}

                        {isIndexInArray(sectionOptions, "international_id") && (
                            <div style={this.styles.flexMarginItemQuoterNarrow}>
                                {this.renderInput({
                                    label:
                                        this.copy.internationalId ||
                                        "International ID",
                                    placeholder:
                                        this.copy.enterInternationalId ||
                                        "International ID",
                                    name: "international_id",
                                })}
                            </div>
                        )}

                        {isIndexInArray(sectionOptions, "media_maestro_id") && (
                            <div style={this.styles.flexMarginItemQuoterNarrow}>
                                {this.renderInput({
                                    label: "Media Maestro",
                                    placeholder:
                                        this.copy.enterInternationalId ||
                                        "Enter Media Maestro ID",
                                    name: "media_maestro_id",
                                })}
                            </div>
                        )}
                    </div>
                </div>
            );
        }

        return false;
    }

    renderAltenateIds() {
        const { sectionOptions } = this.state;

        if (this.shouldDisplayAlternateIds) {
            return (
                <div
                    style={Object.assign(
                        {},
                        (this.shouldDisplaySupplyChainIds ||
                            this.shouldDisplayClientIds) &&
                            this.styles.section.paddingTop,
                    )}
                    className="meta-alternate-ids"
                >
                    {this.renderTitle(
                        this.copy.alternateIds || "Alternate IDs",
                    )}

                    <div
                        style={this.styles.flexMarginContainerNarrow}
                        className="meta-client-ids--container"
                    >
                        {isIndexInArray(sectionOptions, "imdb_id") && (
                            <div style={this.styles.flexMarginItemQuoterNarrow}>
                                {this.renderLogo("imdb.svg")}

                                {this.renderInput({
                                    label: this.copy.imdbId || "IMDB",
                                    placeholder:
                                        this.copy.enterImdbId ||
                                        "Enter IMDB ID",
                                    name: "imdb_id",
                                })}
                            </div>
                        )}

                        {isIndexInArray(sectionOptions, "common_sense_id") && (
                            <div style={this.styles.flexMarginItemQuoterNarrow}>
                                {this.renderLogo("Common_Sense_Media_logo.svg")}

                                {this.renderInput({
                                    label:
                                        this.copy.commonSenseMedia ||
                                        "Common Sense Media",
                                    placeholder:
                                        this.copy.entercommonSenseMediaId ||
                                        "Enter Common Sense Media ID",
                                    name: "common_sense_id",
                                })}
                            </div>
                        )}

                        {isIndexInArray(
                            sectionOptions,
                            "rotten_tomatoes_id",
                        ) && (
                            <div style={this.styles.flexMarginItemQuoterNarrow}>
                                {this.renderLogo("Rotten_Tomatoes_logo.svg")}

                                {this.renderInput({
                                    label:
                                        this.copy.rottenTomatoes ||
                                        "Rotten Tomatoes",
                                    placeholder:
                                        this.copy.enterRottenTomatoesId ||
                                        "Enter Rotten Tomatoes ID",
                                    name: "rotten_tomatoes_id",
                                })}
                            </div>
                        )}

                        {isIndexInArray(
                            sectionOptions,
                            "box_office_mojo_id",
                        ) && (
                            <div style={this.styles.flexMarginItemQuoterNarrow}>
                                {this.renderLogo("BOXOFFICEMOJO.svg")}

                                {this.renderInput({
                                    label:
                                        this.copy.boxOfficeMojo ||
                                        "Box Office Mojo",
                                    placeholder:
                                        this.copy.enterBoxOfficeMojoId ||
                                        "Enter Box Office Mojo ID",
                                    name: "box_office_mojo_id",
                                })}
                            </div>
                        )}

                        {isIndexInArray(sectionOptions, "metacritic_id") && (
                            <div style={this.styles.flexMarginItemQuoterNarrow}>
                                {this.renderLogo("Metacritic_logo.svg")}

                                {this.renderInput({
                                    label: this.copy.metacritic || "Metacritic",
                                    placeholder:
                                        this.copy.enterMetacriticId ||
                                        "Enter Metacritic ID",
                                    name: "metacritic_id",
                                })}
                            </div>
                        )}

                        {isIndexInArray(sectionOptions, "tivo_id") && (
                            <div style={this.styles.flexMarginItemQuoterNarrow}>
                                {this.renderLogo("TiVo_wordmark_BLK.svg")}

                                {this.renderInput({
                                    label: this.copy.tivo || "Tivo",
                                    placeholder:
                                        this.copy.enterTivoId ||
                                        "Enter Tivo ID",
                                    name: "tivo_id",
                                })}
                            </div>
                        )}

                        {isIndexInArray(sectionOptions, "isan_id") && (
                            <div style={this.styles.flexMarginItemQuoterNarrow}>
                                {this.renderLogo("isan.svg")}

                                {this.renderInput({
                                    label: this.copy.isan || "ISAN",
                                    placeholder:
                                        this.copy.enterIsanId ||
                                        "Enter ISAN ID",
                                    name: "isan_id",
                                })}
                            </div>
                        )}

                        {isIndexInArray(sectionOptions, "gracenote_id") && (
                            <div style={this.styles.flexMarginItemQuoterNarrow}>
                                {this.renderLogo("gracenote.svg")}

                                {this.renderInput({
                                    label: "Gracenote",
                                    placeholder:
                                        this.copy.enterIsanId ||
                                        "Enter Gracenote ID",
                                    name: "gracenote_id",
                                })}
                            </div>
                        )}
                    </div>
                </div>
            );
        }

        return false;
    }

    renderContent() {
        return (
            <React.Fragment>
                {this.renderClientIds()}
                {this.renderSupplyChainIds()}
                {this.renderAltenateIds()}
            </React.Fragment>
        );
    }
}
