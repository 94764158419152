import React from "react";
import _ from "lodash";
import SendRequest from "../../components/SendRequest";
import { CmsInterface } from "../../components/Global/interfaces"
import { SendRequestResponseInterface } from "../../components/SendRequest/interface"
import {
    PolicyInterface
} from "../../features/UserRoleManagement/interfaces";

export async function getPolicies(cmsData: CmsInterface): Promise<SendRequestResponseInterface> {
    const { apiUrls = {} } = cmsData;

    const { userManagementPolicies = "" } = apiUrls;

    const options = {
        method: "GET",
        url: userManagementPolicies,
        corsHeaders: true,
    };

    const response = await SendRequest(options)
    
    return response
}

export async function createPolicy(cmsData: CmsInterface, body: PolicyInterface): Promise<SendRequestResponseInterface> {
    const { apiUrls = {} } = cmsData;

    const { userManagementPolicies = "" } = apiUrls;

    const options = {
        method: "POST",
        url: userManagementPolicies,
        body,
        corsHeaders: true,
    };

    const response = await SendRequest(options)
    
    return response
}

export async function updatePolicy(cmsData: CmsInterface, id: number, body: PolicyInterface): Promise<SendRequestResponseInterface> {
    const { apiUrls = {} } = cmsData;

    const { userManagementPolicies = "" } = apiUrls;

    const options = {
        method: "PUT",
        url: `${userManagementPolicies}/${id}`,
        body,
        corsHeaders: true,
    };

    const response = await SendRequest(options)
    
    return response
}

export async function deletePolicy(cmsData: CmsInterface, id: number): Promise<SendRequestResponseInterface> {
    const { apiUrls = {} } = cmsData;

    const { userManagementPolicies = "" } = apiUrls;

    const options = {
        method: "DELETE",
        url: `${userManagementPolicies}/${id}`,
        corsHeaders: true,
    };

    const response = await SendRequest(options)
    
    return response
}