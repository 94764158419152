/* eslint-disable no-shadow */
export enum ArrayOperation {
    HAS_DUPLICATES,
    HAS_VALUE,
}

export type ArrayOperationInput<T> = {
    array: T[];
    value?: T[keyof T]; // Only used for ArrayOperation.Value
    key?: keyof T;
    operation: ArrayOperation;
};

export function arrayOperation<T>(input: ArrayOperationInput<T>) {
    const { array, value, key, operation } = input;

    if (!Array.isArray(array)) {
        return false;
    }

    switch (operation) {
        case ArrayOperation.HAS_DUPLICATES:
            return array?.some(
                (el, i, arr) => arr.findIndex((t) => t[key] === el[key]) !== i,
            );
        case ArrayOperation.HAS_VALUE:
            return array?.filter((arr) => arr[key] === value).length > 1;
        default:
            return false;
    }
}
