import React, { useState, useEffect } from 'react';
import deepcopy from 'deepcopy';
import _ from "lodash";
import { useStore } from '../../context';
import { setActiveContributor } from '../../reducer';
import Settings from '../../../../../../components/Settings';
import MaterialDatePicker from "../../../../../../components/Input/MaterialDatePicker";
import AutocompleteInput from "../../../../../../components/AutocompleteInput";
import Input from "../../../../../../components/Input/basic";
import SelectLanguageTag from './SelectLanguageTag';
import AssignContributorScripts from '../AssignContributorScripts';
import GetContributorFieldValue from "../GetContributorFieldValue";
import GetContributorFullName from '../../../src/GetContributorFullName';
import EnrichContributorByName from '../EnrichContributorByName';
import { parseContributorName } from '../../../../../../models/Record/contributors';

const CreateOrUpdateContributor = (props) => {
    const {
        inputName = '',
        isNewContributor = false
    } = props;

    const [store, dispatch] = useStore();

    const {
        activeContributor = {},
        activeCredit = {},
        options = {},
        defaultScript,
        cmsData = {},
    } = store;

    const {
        clientFeatures = {},
    } = cmsData;

    const nameSplittingEnabled = _.get(clientFeatures, 'contributorFoemNameSplittingEnabled', false);
    const nameFieldExposed = _.get(clientFeatures, 'contributorFullNameExposed', false);

    const {
        title = '',
        date_of_birth = '',
        imdb_id,
        isni_id,
        hand_id,
        iva_person_id
    } = activeContributor;

    const [isScriptSelected, setIsScriptSelected] = useState(false);
    const [script, setScript] = useState(defaultScript);
    const [nameAutoFocus, setNameAutoFocus] = useState(true);

    useEffect(() => {
        if (!activeContributor.first_name) {
            const contributorCopy = deepcopy(activeContributor);
            if (!_.isEmpty(inputName) && _.isEmpty(activeContributor.name)) contributorCopy.name = inputName;
            dispatch(setActiveContributor(EnrichContributorByName(contributorCopy)));
        }
    }, []);

    useEffect(() => {
        let contributorCopy = deepcopy(activeContributor);

        if (!isNewContributor) {
            contributorCopy = {
                ...contributorCopy,
                ...activeCredit,
            };
        } else {
            const nameComponents = parseContributorName(inputName);

            let nameProperties = {
                name: inputName,
            };

            if (nameSplittingEnabled) {
                nameProperties = {
                    ...nameProperties,
                    first_name: nameComponents.first_name,
                    middle_names: nameComponents.middle_name,
                    last_name: nameComponents.last_name,
                };
            }

            contributorCopy = {
                ...contributorCopy,
                ...nameProperties,
                script_names: [{
                    code: defaultScript,
                    ...nameProperties,
                }],
            };
        }

        dispatch(setActiveContributor(contributorCopy));
    }, []);

    document.addEventListener('keydown', (e) => {
        if (e.key === "Tab") {
            setNameAutoFocus(false);
        }
    });

    const UpdateContributor = (field, value) => {
        let contributor = deepcopy(activeContributor);

        if (!isScriptSelected) {
            contributor[field] = value;
        }

        if (!nameFieldExposed) {
            contributor.name = GetContributorFullName({ contributor });
        }

        const scriptFields = [
            'name',
            'first_name',
            'middle_names',
            'last_name',
            'alternative_name',
            'second_alternative_name',
        ];

        if (scriptFields.includes(field)) {
            contributor = AssignContributorScripts({ script, field, value, contributor, nameFieldExposed });
        }

        dispatch(setActiveContributor(contributor));
    };

    const inputProps = {
        margin: "1.5rem 0 0",
        recordBasic: true,
    };

    const textInputProps = {
        margin: "0 0.75rem 0 0",
        recordBasic: true,
        noDelay: true,
    };

    return (
        <div style={Settings.components.credits.wrapper}>
            <MaterialDatePicker
                labelLegacy="Date of Birth"
                placeholder="Select a Date of Birth"
                defaultValue={date_of_birth}
                handleChange={(value) => UpdateContributor('date_of_birth', value)}
                styles={{
                    width: Settings.components.credits.item.double.width,
                    ...inputProps,
                }}
                className="datepicker-full-width datepicker-small"
                {...inputProps}
            />

            <div style={{
                marginTop: "1.5rem",
                width: 'calc(50% - 0.328125rem)'
            }}>
                <Input
                    label="IMDB ID"
                    placeholder="Enter IMDB ID"
                    value={imdb_id}
                    handleChange={(value) => UpdateContributor('imdb_id', value)}
                    {...textInputProps}
                />
            </div>

            <div style={{
                marginTop: "1.5rem",
                width: 'calc(50% - 0.328125rem)',
            }}>
                <Input
                    label="ISNI ID"
                    placeholder="Enter ISNI ID"
                    value={isni_id}
                    handleChange={(value) => UpdateContributor('isni_id', value)}
                    className={"contributor-isni-id"}
                    {...textInputProps}
                />
            </div>

            <div style={{
                marginTop: "1.5rem",
                width: 'calc(50% - 0.328125rem)'
            }}>
                <Input
                    label="HAND ID"
                    placeholder="Enter HAND ID"
                    value={hand_id}
                    handleChange={(value) => UpdateContributor('hand_id', value)}
                    className={"contributor-hand-id"}
                    {...textInputProps}
                />
            </div>

            <div style={{
                marginTop: "1.5rem",
                width: 'calc(50% - 0.328125rem)',
            }}>
                <Input
                    label="IVA Person ID"
                    placeholder="Enter IVA Person ID"
                    value={iva_person_id}
                    handleChange={value => UpdateContributor('iva_person_id', value)}
                    className={"contributor-iva-person-id"}
                    {...textInputProps}
                />
            </div>

            <div style={{
                marginTop: "0.5rem",
                width: "100%"
            }}>
                <SelectLanguageTag
                    script={script}
                    handleChange={(lang) => {
                        setScript(lang);
                        setIsScriptSelected(lang !== 'eng');
                    }}
                />
            </div>

            {nameFieldExposed && (
                <div style={{
                    marginTop: "0.5rem",
                    width: "100%",
                }}>
                    <>
                        <Input
                            label="Full Name"
                            placeholder="Enter Full Name"
                            id="create-new-contributor--full-name"
                            className={"contributor-full-name"}
                            value={GetContributorFieldValue(activeContributor, 'name', script)}
                            handleChange={value => {
                                UpdateContributor('name', value)
                                setNameAutoFocus(false)
                            }}
                            noDelay={true}
                            autoFocus={nameAutoFocus}
                            enterOnFocusOut={true}
                            onFocus={() => setNameAutoFocus(false)}
                            {...inputProps}
                            {...Settings.components.credits.item.single}
                        />

                        {_.isEmpty(activeContributor.name) && (
                            <div
                                className="error-message"
                                style={{
                                    'color': '#D6465E',
                                    'font-size': '0.75rem',
                                    'margin-top': '0.5rem'
                                }}
                            >
                                Please enter Full Name
                            </div>
                        )}
                    </>
                </div>
            )}

            <div style={Settings.components.credits.item.double}>
                <AutocompleteInput
                    label="Title"
                    placeholder="Select Title"
                    value={title}
                    styles={{ marginTop: '1.5rem' }}
                    handleListSelection={(value) => UpdateContributor('title', value)}
                    openOnClick
                    keepSelected
                    staticDatalist={options.person_titles || []}
                    className="contributor-title"
                    {...inputProps}
                />
            </div>

            <Input
                label="First Name"
                placeholder="Enter First Name"
                id="create-new-contributor--first-name"
                className="contributor-first-name"
                value={GetContributorFieldValue(activeContributor, 'first_name', script)}
                handleChange={(value) => UpdateContributor('first_name', value)}
                noDelay
                autoFocus={nameAutoFocus}
                enterOnFocusOut
                onFocus={() => setNameAutoFocus(false)}
                {...inputProps}
                {...Settings.components.credits.item.double}
            />

            <Input
                label="Middle Name"
                placeholder="Enter Middle Name"
                className="contributor-middle-name"
                value={GetContributorFieldValue(activeContributor, 'middle_names', script)}
                handleChange={(value) => UpdateContributor('middle_names', value)}
                noDelay
                {...inputProps}
                {...Settings.components.credits.item.double}
            />

            <Input
                label="Last Name"
                placeholder="Enter Last Name"
                className="contributor-last-name"
                value={GetContributorFieldValue(activeContributor, 'last_name', script)}
                handleChange={value => UpdateContributor('last_name', value)}
                noDelay
                {...inputProps}
                {...Settings.components.credits.item.double}
            />

            <Input
                label="Alternative Name"
                placeholder="Enter Alternative Name"
                className="contributor-alternative-name"
                value={GetContributorFieldValue(activeContributor, 'alternative_name', script)}
                handleChange={(value) => UpdateContributor('alternative_name', value)}
                noDelay
                {...inputProps}
                {...Settings.components.credits.item.double}
            />

            <Input
                label="Alternative Name 2"
                placeholder="Enter Alternative Name 2"
                className="contributor-alternative-name"
                value={GetContributorFieldValue(activeContributor, 'second_alternative_name', script)}
                handleChange={(value) => UpdateContributor('second_alternative_name', value)}
                noDelay
                {...inputProps}
                {...Settings.components.credits.item.double}
            />
        </div>
    );
};

export default CreateOrUpdateContributor
