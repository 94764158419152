import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "rgba(40, 108, 210, 0.1)",
        border: "0",
        color: "#555555",
        padding: "0.625rem",
        fontSize: "0.875rem",
        fontWeight: "400",
        lineHeight: "1rem",
    },
    [`&.${tableCellClasses.body}`]: {
        border: "0",
        color: "#555555",
        padding: "0.375rem 0.625rem",
        fontSize: "0.875rem",
        fontWeight: "400",
        lineHeight: "1rem",
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: "#F5F5F5",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export const StyledTableFooter = styled(TableFooter)(({ theme }) => ({
    color: "rgba(149, 149, 149, 0.37)",
    display: "block",
    fontSize: "0.875rem",
    textAlign: "center",
    padding: "0.625rem",
}));

export const StyledTableHeaderColumn = styled(TableCell)(({ theme }) => ({
    border: "0",
    color: "#555555",
    padding: "0",
    fontSize: "0.875rem",
    fontWeight: "500",
    lineHeight: "1rem",
}));

export const GridSxCommon = {
    ".MuiDataGrid-columnHeaders": {
        backgroundColor: "rgba(40, 108, 210, 0.1)",
    },
    ".MuiDataGrid-columnSeparator": {
        display: "none",
    },
    "&.MuiDataGrid-root": {
        border: "none",
    },
    ".MuiDataGrid-cell:focus": {
        outline: "none",
    },
    ".MuiDataGrid-row.Mui-selected": {
        background: "none",
    },
    ".MuiDataGrid-columnHeader:focus": {
        outline: "none",
    },
    ".scrollArea": {
        height: "fit-content !important",
    },
    ".MuiDataGrid-row:nth-of-type(even)": {
        backgroundColor: "#F5F5F5",
    },
    ".MuiDataGrid-cell": {
        borderBottom: "none",
    },
    ".MuiDataGrid-row--dragging": {
        background: "inherit",
    },
    ".MuiDataGrid-rowReorderCell--draggable": {
        color: "#959595",
    },
};
