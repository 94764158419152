import React from "react";

export default class PlaceholderText extends React.Component {
    /**
     * Renders html dom elements
     *
     * @return DOM elements
     */
    render() {
        const { useMargin } = this.props;

        let text = "None Added";

        return (
            <span
                style={{
                    color: "rgba(188, 188, 188, 0.87)",
                    display: this.props.display || "block",
                    fontSize: "1.05rem",
                    margin: useMargin ? "0.71875rem 0 0" : "",
                    ...this.props.styles,
                }}
                className="meta-placeholder-text"
            >
                {this.props.text || text}
            </span>
        );
    }
}
