import React from "react";
import _ from "lodash";
import "./styles/_styles.scss";
import CircleInput from "../CircleInput";
import isIndexInArray from "../Helpers/isIndexInArray";

const RadioButtonOptions = (props) => {
    const {
        styles = {},
        data = [],
        selected = [],
        handleSelection = () => {},
    } = props;

    if (_.isEmpty(data)) {
        return false;
    }

    return (
        <div className="meta-radio-button-options" style={styles}>
            {data.map((item, index) => {
                const active = isIndexInArray(selected, item.value);

                return (
                    <div key={index}>
                        <span className="text">{item.text}</span>

                        <CircleInput
                            className="meta-radio-button-option"
                            width="1.34375rem"
                            borderWidth="0.25rem"
                            background="#e2e2e2"
                            innerBackground={active ? "#787878" : "#e2e2e2"}
                            onClick={() => handleSelection(item.value)}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default RadioButtonOptions;
