import React from "react";
import { SectionInterface } from "./interface";
import Titles from "../../views/Titles";
import TitlesV2 from "../../views/TitlesV2";
import TitlesBuilder from "../../views/TitlesBuilder";
import { TITLES } from "../../../../models/RecordBuilder";
import IsActiveRecordBuilder from "../../../RecordBuilder/IsActive";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";
import { useStore } from "../../../Global/store/store";

const SectionTitles = (props: SectionInterface) => {
    const {
        cmsData = {},
        data = {},
        handleOnBlur = () => {},
        newRecord,
    } = props;

    const [globalState] = useStore() || [{}];
    const { clientDataStructure = {}, clientFeatures = {} } = cmsData;

    const {
        titles = [],
        updating_logs = [],
        supply_chain = {},
        category,
    } = data;

    const titleProps = {
        ...getSectionCommonProps({ ...props, field: "titles" }),
        name: "titles",
        className: "meta-record-titles",
        title: "Titles",
        data: titles,
        supply_chain,
        updatingLogs: updating_logs,
        category: category,
        noBorder: true,
        clientFeatures,
        handleOnBlur: () => handleOnBlur(),
        clientDataStructure,
        newRecordEdit: !_.isEmpty(newRecord) || false,
        displayTitle: false,
        recordBuilder: globalState.recordBuilder,
    };

    if (IsActiveRecordBuilder({ page: TITLES, clientFeatures })) {
        return <TitlesBuilder {...titleProps} />;
    }

    if (!_.isEmpty(clientDataStructure.titles)) {
        return <TitlesV2 {...titleProps} />;
    }

    return <Titles {...titleProps} />;
};

export default SectionTitles;
