import _ from "lodash";
import CategoryTags from "../../CategoryTags";
import SeasonName from "../../SeasonName";
import IsIndexInArray from "../../Helpers/isIndexInArray";
import { SortCollectionTitlesFull } from "../../SortCollectionTitles";

const Seasons = (props) => {
    const { category, linkage = [], record = {} } = props;

    let result = [];
    let recordLinkage = [];
    if (Array.isArray(record.linkage)) {
        recordLinkage = record.linkage;
    }

    if (category && CategoryTags[category]) {
        const excludedCategories = [CategoryTags.Episode.short];

        const recordLinkageMetaIds = recordLinkage
            .filter((item) => !item.deleted)
            .map((item) => {
                const itemCategory = item.category || item.category_name;
                const shouldNotAddMetaId =
                    IsIndexInArray(excludedCategories, category) &&
                    itemCategory == CategoryTags.Season.short;

                if (!shouldNotAddMetaId) {
                    return item.meta_id;
                }
            });

        let seasons = linkage.filter((item) => item.category == CategoryTags.Season.short)

        if (!props.allowSeasons) {
            seasons = linkage.filter(
                (item) =>
                    item.category == CategoryTags.Season.short &&
                    !IsIndexInArray(recordLinkageMetaIds, item.meta_id),
            );
        }

        if (seasons && seasons.length > 0) {
            result = seasons.map((item) => {
                const {
                    category,
                    original_title,
                    meta_id,
                    number_of_episodes,
                    season_number,
                } = item;

                let text = SeasonName(item);

                if (category == CategoryTags.Season.short) {
                    if (number_of_episodes)
                        text = `${text} • ${number_of_episodes} Episodes`;
                }

                return {
                    text,
                    value: meta_id,
                    category,
                    meta_id,
                    number_of_episodes,
                    season_number,
                };
            });
        }
    }

    return SortCollectionTitlesFull(result);
};

export default Seasons;
