import React from "react";
import DoubleTags from "../DoubleTags";
import Tag from "../Tag";
import CategoryTags from "../CategoryTags";
import _ from "lodash";
import { isEpisode } from "../CategoryTags/EpisodeCategories";

const SeasonAndEpisodeNumberV2 = (props) => {
    const {
        season_number,
        category,
        episode_number = "",
        storyline_number = "",
        production_number = "",
        position = "",
        className = "",
        structure = {},
    } = props;

    const { episodeStructure = [], seasonStructure = [] } = structure;

    let episodeNumbers = [
        "production_number",
        "season_number",
        "episode_number",
    ];
    let seasonNumbers = ["production_number", "season_number"];

    return (
        <React.Fragment>
            <div
                className="meta-linkage--table--episode-tags-block"
                style={{ display: "flex" }}
            >
                {isEpisode(category) &&
                    episodeStructure.map((tagSettings) => {
                        if (episodeNumbers.includes(tagSettings.key)) {
                            return RenderItemTag({
                                itemSettings: tagSettings,
                                number: parseInt(props[tagSettings.key]),
                                className: className,
                            });
                        }
                    })}
            </div>

            <div
                className="meta-linkage--table--season-tags-block"
                style={{ display: "flex" }}
            >
                {category === CategoryTags.Season.short &&
                    seasonStructure.map((tagSettings) => {
                        if (seasonNumbers.includes(tagSettings.key)) {
                            return RenderItemTag({
                                itemSettings: tagSettings,
                                number: parseInt(props[tagSettings.key]),
                                className: className,
                            });
                        }
                    })}
            </div>
        </React.Fragment>
    );
};

const RenderItemTag = (props) => {
    const { itemSettings = {}, number, className } = props;

    const { showLabel = true, active = true, label = "" } = itemSettings;

    if (active) {
        if (showLabel && !_.isEmpty(label) && Number.isInteger(number)) {
            return (
                <DoubleTags
                    leftText={label}
                    rightText={parseInt(number)}
                    rightTextTransparent={true}
                    className={className}
                    key={itemSettings.key}
                />
            );
        }

        if (!showLabel && Number.isInteger(number)) {
            return (
                <Tag
                    text={parseInt(number)}
                    className={className}
                    background="transparent"
                    color="#9a9a9a"
                    styles={{
                        padding: "0.1369rem 0.3rem",
                        border: "0.0625rem solid #9a9a9a",
                    }}
                    key={itemSettings.key}
                />
            );
        }
    }
};

export default SeasonAndEpisodeNumberV2;
