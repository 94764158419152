import { SET_RECORD_DATA } from "../actions/action_types";
import { defaultRecordData } from "../initialState";

export default function (state = { recordData: defaultRecordData }, action) {
    switch (action.type) {
        case SET_RECORD_DATA:
            return Object.assign({}, state, {
                recordData: action.recordData,
            });
    }

    return state;
}
