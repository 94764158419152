import { isEmpty } from "lodash";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { CoreFormPath } from "../../../../../core/Form/types";
import { ParentShowDisabled } from "../standalone/fields/ParentShowDisabled";
import { SeasonVT } from "../../../config/types";
import { useStores } from "../../../store/rootStore";
import { AutocompleteRecord } from "./components/AutocompleteRecordCard";
import { PrimaryFieldsSkeleton } from "./components/PrimaryFieldsSkeleton";
import { useFetchRecordByMetaId } from "./hooks/useFetchRecordByMetaId";
import { GridXS12 } from "./layout/grid";
import { AutocompleteRecords } from "./smart/AutocompleteRecords";
import store from "./store/store";
import { Categories } from "./types/types";
import { createParentShow } from "./createParentShow";

export const ParentShow = observer(() => {
    const rootStore = useStores();
    const form = useFormContext<SeasonVT>();
    const pathParentShowTitle: CoreFormPath<SeasonVT>["path"] =
        "parentShow.title";
    const pathParentShow: CoreFormPath<SeasonVT>["path"] = "parentShow";
    const [parentShowState, setParentShowState] =
        useState<SeasonVT["parentShow"]>(null);
    const { record, loading } = useFetchRecordByMetaId(parentShowState?.metaId);
    const [disabled, setDisabled] = useState<boolean>(false);

    useEffect(() => {
        if (!isEmpty(record?.meta_id)) {
            store.setLoading(false);
            form?.setValue(pathParentShow, parentShowState);
        }
    }, [record?.meta_id]);

    useEffect(() => {
        if (!isEmpty(record?.meta_id)) {
            const tvShow =
                rootStore.taskManagerAdapters.adaptRecordToTvShow(record);
            store.setParentShow(tvShow);

            // form.setValue("category", tvShow.category); // ! DO NOT SET CATEGORY
            form.setValue("brand", tvShow.brand);
            form.setValue("releaseYear", tvShow.releaseYear);
            form.setValue("provenance", tvShow.provenance);
            form.setValue("metaId", tvShow.metaId);
            form.setValue("originalLanguage", tvShow.originalLanguage);
            form.setValue("countryOfOrigin", tvShow.countryOfOrigin);
            form.setValue("titles", tvShow.titles); // TODO: if not working, check with: "titles.aka"
            form.setValue("descriptions", tvShow.descriptions);
            form.setValue("ids", tvShow.ids);
            form.setValue("other", tvShow.other);
            // TODO: add all fields
        }
    }, [record?.meta_id]);

    if (loading) {
        store.setLoading(true);
        return <PrimaryFieldsSkeleton />;
    }

    if (!isEmpty(record?.meta_id)) {
        return <ParentShowDisabled />;
    }

    return (
        <GridXS12>
            <AutocompleteRecords
                category={Categories.Show}
                disabled={disabled}
                labelProps={{
                    text: "Parent Show",
                    isRequired: true,
                    isDisabled: disabled,
                }}
                path={pathParentShowTitle}
                headerText="Existing TV Shows"
                onClick={(option: AutocompleteRecord) => {
                    // This show may already exist in the store
                    const existingShow = store.findByMetaId(
                        option?.category,
                        option?.metaId,
                    );

                    let parentShow = null;
                    if (existingShow) {
                        parentShow = {
                            id: existingShow.id,
                            metaId: existingShow.metaId,
                            title: existingShow.title,
                        };
                    } else {
                        parentShow = createParentShow(option);
                    }

                    // form?.setValue(pathParentShow, parentShow);
                    setParentShowState(parentShow);
                    setDisabled(true);
                }}
            />
        </GridXS12>
    );
});
