import { TextValueInterface, BasicInterface } from "./interfaces";

export function transformBasicOptions(items: BasicInterface[]): string[] {
    if (!Array.isArray(items)) {
        return [];
    }

    return items
        .filter((item) => !item.deleted_at)
        .map((item: BasicInterface) => item.name || item.text);
}

export function transformTextValueOptions(
    items: BasicInterface[],
): TextValueInterface[] {
    if (!Array.isArray(items)) {
        return [];
    }

    return items
        .filter((item) => !item.deleted_at)
        .map((item: BasicInterface) => {
            return {
                text: item.name || item.text,
                value: item.value,
            };
        });
}
