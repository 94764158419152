import { CreditInterface } from "../../../credits.interface";
import { isEmpty } from "../../../utils/helpers";

export default function(
    script: string,
    credit: CreditInterface
): string {

    let result: string = ''

    let targetName: string | null | undefined = credit?.script_names?.find(scriptName => scriptName?.code.toLowerCase() === script.toLowerCase())?.name
    result = !isEmpty(targetName) ? targetName as string : `<span style="color: rgba(188, 188, 188, 0.87); line-height: 1rem;">${!isEmpty(credit.name) ? credit.name : ""}</span>`

    return result
}