import { SET_NOTIFICATION } from "./index";

const setNotification = (notification) => {
    return {
        type: SET_NOTIFICATION,
        state: { notification },
    };
};

export const setErrorNotification = (message: string) => {
    const notification = {
        message,
    };

    return {
        type: SET_NOTIFICATION,
        state: { notification },
    };
};

export default setNotification;
