import { Contributor, CreditInterface } from "../../credits.interface";
import { isEmpty } from "../../utils/helpers"

export default function assignCreditNames(
    credit: CreditInterface,
    sourceContributor: Contributor
): CreditInterface {
    credit.title = sourceContributor.title || null
    credit.first_name = sourceContributor.first_name || null
    credit.last_name = sourceContributor.last_name || null
    credit.middle_names = sourceContributor.middle_names || null
    credit.alternative_name = sourceContributor.alternative_name || null
    credit.second_alternative_name = sourceContributor.second_alternative_name || null
    credit.character_name = sourceContributor.character_name || null

    if (sourceContributor.hasOwnProperty("text") && !isEmpty(sourceContributor.text)) {
        credit.name = sourceContributor.text as string
    }

    if (isEmpty(credit.name) && (!isEmpty(credit.first_name))) {
        credit.name = `${credit.first_name} ${credit.middle_names || ""} ${credit.last_name || ""}`
    }

    if (!credit.hasOwnProperty("script_names")) credit.script_names = []
    if (!credit.hasOwnProperty("script_character_names")) credit.script_character_names = []

    return credit;
}
