import { CreditInterface } from "../../credits.interface";

export default function matchCreditsOnContributorCreate(
    credits: CreditInterface[],
    contributor: CreditInterface
): CreditInterface {

    const updatedContributor = contributor

    const contributorInstances = credits.filter(
        (credit) =>
            credit.local_id === contributor.local_id
    )

    if (!contributorInstances.length) return contributor;

    const updateSource = contributorInstances[0]

    updatedContributor.date_of_birth = updateSource.date_of_birth;
    updatedContributor.imdb_id = updateSource.imdb_id;
    updatedContributor.isni_id = updateSource.isni_id;
    updatedContributor.hand_id = updateSource.hand_id;
    updatedContributor.iva_person_id = updateSource.iva_person_id;
    updatedContributor.title = updateSource.title;
    updatedContributor.name = updateSource.name;
    updatedContributor.first_name = updateSource.first_name;
    updatedContributor.last_name = updateSource.last_name;
    updatedContributor.middle_names = updateSource.middle_names;
    updatedContributor.alternative_name = updateSource.alternative_name;
    updatedContributor.second_alternative_name = updateSource.second_alternative_name;
    updatedContributor.script_names = updateSource.script_names

    return updatedContributor;
}