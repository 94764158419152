import React, { useEffect, useState } from "react";
import DoubleTags from "../../DoubleTags";
import { useStore } from "../../Global/store/store";
import { CreatedByTagsInterface, ContentInterface } from "./interface";
import { getTagContent } from "./model";

const CreatedByTags = (props: CreatedByTagsInterface) => {
    const {
        created_at,
        created_by,
        created_by_name,
        styles = {},
        leftText,
        color = "#9a9a9a",
        fontSize,
        newRecord,
    } = props;

    const [state] = useStore() || [{}];

    const { cmsData = {} } = state;

    const { clientDataStructure = {} } = cmsData;

    const [content, setContent] = useState<ContentInterface | {}>({});

    useEffect(() => {
        setContent(
            getTagContent({
                clientDataStructure,
                leftText,
                created_at,
                created_by_name,
                newRecord,
                created_by,
            }),
        );
    }, []);

    return (
        <DoubleTags
            leftText={content.leftText}
            centerText={content.created_at}
            rightText={content.created_by_name}
            leftTagStyles={{
                background: color,
                color: "white",
                borderColor: color,
            }}
            centerTextTransparent={true}
            rightTextTransparent={true}
            styles={styles}
            fontSize={fontSize}
        />
    );
};

export default CreatedByTags;
