import React from "react";
import _ from "lodash";
import styles from "../styles";
import Select from "../../Select";
import ClientManagedSelectWithTag from "../../SelectWithTag/ClientManaged";
import PlaceholderText from "../../PlaceholderText";
import MaterialDatePicker from "../../Input/MaterialDatePicker";
import RecordTable from "../../RecordTable/ReleaseDates";
import AddNewRowButton from "../../AddNewRowButton";
import Button from "../../Button";
import BorderRadius from "../../BorderRadius";
import Notification from "../../Notification";
import DoubleContent from "../../DoubleContent";
import CategoryTags from "../../CategoryTags";
import update from "react-addons-update";
import Index from "./Index";
import deepcopy from "deepcopy";
import { diff } from "deep-object-diff";
import ClientManagedCountries from "../../ClientManagedOptions/countries";
import ReleaseDateTypesOptions from "../../ClientManagedOptions/release_date_types";
import {
    CLIENT_MANAGED_COUNTRIES,
    CLIENT_MANAGED_RELEASE_DATE_TYPES,
} from "../../ClientManagedOptions/options";
import { isClientManagedReleaseDateTypes } from "../../ClientManagedOptions/release_date_types";
import "../styles/_release_dates.scss";

export default class ReleaseDates extends Index {
    constructor(props) {
        super(props);

        const data = this.getDataFromProps(this.props.data);

        /**
         * @var object
         */
        this.copy = this.props.copy || {};

        /**
         * @var object
         */
        this.styles = styles(this.props);

        /**
         * @var object
         */
        this.state = {
            data,
            notification: {},
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    shouldComponentUpdate(nextProps, nextState) {
        const nextPropsData = nextProps.data || {};
        const thisPropsData = this.props.data || {};
        const difference = diff(nextPropsData, thisPropsData);

        if (!_.isEmpty(difference)) {
            return true;
        }

        if (!_.isEqual(nextProps.disabled, this.props.disabled)) {
            return true;
        }

        if (!_.isEqual(nextProps.audits, this.props.audits)) {
            return true;
        }

        if (!_.isEqual(nextState, this.state)) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props.data, prevProps.data)) {
            const data = this.getDataFromProps(this.props.data);
            this.setStateValue("data", data);
        }
    }

    getDataFromProps(data = {}) {
        let result = [];

        if (Array.isArray(data)) {
            result = data;
        }

        return result;
    }

    handleNotificationCancel() {
        this.setState({
            notification: {},
            item: {},
        });
    }

    handleSaveItem() {
        let data = deepcopy(this.state.data);
        data.push(this.state.item);

        this.setState(
            {
                data,
                item: {},
                notification: {},
            },
            () => {
                this.handleCallBack();
            },
        );
    }

    handleCallBack() {
        if (typeof this.props.handleFormChange == "function") {
            this.props.handleFormChange(this.state.data);
        }
    }

    handleAddNewRowClick() {
        this.setState(
            {
                item: {},
            },
            () => {
                this.setState({
                    notification: {
                        title:
                            this.copy.addNewReleaseDate ||
                            "Add new release date",
                        html: this.renderNotificationHtml(),
                        confirm: this.handleSaveItem.bind(this),
                        disabled: true,
                    },
                });
            },
        );
    }

    handleItemChange(key, value) {
        let item = deepcopy(this.state.item);
        item[key] = value;

        if (key == "type") {
            item.country = undefined;
        }

        this.setState(
            {
                item,
            },
            () => {
                this.handleNotificationUpdate();
            },
        );
    }

    handleNotificationUpdate() {
        let notification = deepcopy(this.state.notification);
        notification.html = this.renderNotificationHtml();
        notification.disabled = this.validateItem();

        this.setState({
            notification,
        });
    }

    handledelete(index) {
        const data = this.sortData();


        const updatedState = update(data, {
            [index]: {
                deleted: {
                    $set: true
                }
            }
        })

        this.setState(
            {
                data: updatedState,
            },
            () => {
                this.handleCallBack();
            },
        );
    }

    sortData() {
        return _.sortBy(this.state.data || [], (item) => item.type);
    }

    validateItem() {
        const { item = {} } = this.state;

        const { type, date, country } = item;

        let disabled = true;

        if (type && date && country) disabled = false;

        return disabled;
    }

    renderNotificationHtml() {
        const {
            options = {},
            category,
            clientFeatures = {},
            cmsData = {},
        } = this.props;

        const { apiUrls = {} } = cmsData;

        let { countries = [], release_dates_types = [] } = options;

        const { item = {}, data = [] } = this.state;

        const { country, date, type } = item;

        const countriesList = ClientManagedCountries({
            options,
            clientFeatures,
            fallback: countries,
        });

        let releaseDateTypesList = ReleaseDateTypesOptions({
            options,
            clientFeatures,
        });

        const filterArray = data
            .filter((item) => item.type == type)
            .map((item) => item.country);

        if (
            category !== CategoryTags.Feature.short &&
            Array.isArray(releaseDateTypesList)
        ) {
            releaseDateTypesList = releaseDateTypesList.filter(
                (type) => type !== "Theatrical",
            );
        }

        return (
            <div>
                <DoubleContent
                    styles={{
                        marginTop: "1rem",
                    }}
                >
                    <ClientManagedSelectWithTag
                        label={this.copy.type || "Type"}
                        id="meta-release-dates-type"
                        value={type}
                        clear={true}
                        noneSelectedText={
                            this.copy.noneSelected || "None Selected"
                        }
                        disabledText={this.copy.selectType || "Select Type"}
                        data={releaseDateTypesList}
                        handleChange={(value) =>
                            this.handleItemChange("type", value)
                        }
                        wrapperStyles={{
                            width: "14.09375rem",
                        }}
                        clientManagedType={CLIENT_MANAGED_RELEASE_DATE_TYPES}
                        clientFeatures={clientFeatures}
                        apiUrls={apiUrls}
                        isClientManaged={isClientManagedReleaseDateTypes({
                            clientFeatures,
                        })}
                        transformListHook={(list) =>
                            list.map((item) => item.name)
                        }
                    />

                    <MaterialDatePicker
                        label={this.copy.date || "Date"}
                        placeholder={this.copy.selectDate || "Select a Date"}
                        id="meta-release-dates-date"
                        defaultValue={date}
                        handleChange={(value) =>
                            this.handleItemChange("date", value)
                        }
                    />
                </DoubleContent>

                <ClientManagedSelectWithTag
                    label={this.copy.country || "Country"}
                    id="meta-release-dates-country"
                    value={country}
                    clear={true}
                    noneSelectedText={this.copy.noneSelected || "None Selected"}
                    disabledText={this.copy.selectCountry || "Select Country"}
                    data={countriesList}
                    handleChange={(value) =>
                        this.handleItemChange("country", value)
                    }
                    wrapperStyles={{
                        marginTop: "1.5rem",
                    }}
                    disabled={!type}
                    filterArray={filterArray}
                    clientManagedType={CLIENT_MANAGED_COUNTRIES}
                    clientFeatures={clientFeatures}
                    apiUrls={apiUrls}
                />
            </div>
        );
    }

    renderNoneAdded() {
        return false;
    }

    renderCountryDateLabels() {
        const {
            disabled,
            options,
            clientFeatures = {},
            audits = [],
            cmsData,
            canCreateNew = true,
            canDelete = true
        } = this.props;

        const sortedData = this.sortData();

        return (
            <React.Fragment>
                <div className="table--top-bar">
                    <Button
                        className={
                            !canCreateNew || disabled
                                ? "meta-release-dates disabled"
                                : "meta-release-dates"
                        }
                        type="button"
                        tableControl={true}
                        onClick={this.handleAddNewRowClick.bind(this)}
                        data={sortedData}
                        disabled={!canCreateNew || disabled}
                        value="New Release Date"
                    />
                </div>

                <div className="release-dates--table">
                    <RecordTable
                        data={sortedData}
                        disabled={disabled}
                        options={options}
                        clientFeatures={clientFeatures}
                        audits={audits}
                        handleDelete={this.handledelete.bind(this)}
                        canDelete={canDelete}
                        cmsData={cmsData}
                    />
                </div>
            </React.Fragment>
        );
    }

    renderContent() {
        const { notification = {} } = this.state;

        return (
            <div className="meta-release-dates--content">
                <div style={this.styles.marginItem}>
                    {this.renderCountryDateLabels()}
                </div>

                <Notification
                    title={notification.title}
                    intercationStatus="alert"
                    html={notification.html}
                    confirm={notification.confirm}
                    background="white"
                    disabled={notification.disabled}
                    onClick={this.handleNotificationCancel.bind(this)}
                    okText={this.copy.addPlusSave || "Add + Save"}
                    closeOnWrapperClick={false}
                    confirmOnEnter={true}
                    keyPressEnterDisabled={true}
                />
            </div>
        );
    }
}
