import React, { useMemo } from "react";
import styles from "../styles";
import { useStore } from "../../../components/Global/store/store";

export default (props) => {
    const [globalState] = useStore() || [{}];

    const {
        cmsData = {}
    } = globalState

    const platform = process.env.APP_PLATFORM || "Fabric"
    const appVersion = cmsData.appVersion || process.env.APP_VERSION

    const classStyles = useMemo(() => styles(props), [])

    const envText = useMemo(() => {
        let result = []

        if (process.env.APP_ENV) result.push (`${process.env.APP_ENV} ${props.environmentText}`)
        if (appVersion) result.push(`v${appVersion}`)

        return result
    }, [cmsData])

    return (
        <div
            style={classStyles.versionContainer}
            className="meta-version-container"
        >
            {platform &&
                <span
                    style={Object.assign(
                        {},
                        classStyles.trademark.default,
                        props.platform && classStyles.trademark[props.platform],
                    )}
                    className="meta-trade-mark"
                >
                    {platform}&trade;
                </span>
            }

            <span
                style={Object.assign(
                    {},
                    classStyles.version.default,
                    props.platform && classStyles.version[props.platform],
                )}
                className="meta-version"
            >
                {envText.join(" | ")}
            </span>
        </div>
    );
};
