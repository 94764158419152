type RoleColors = {
    background: string,
    color: string
}

export default function roleColors(role: string): RoleColors {
    let result = {
        background: "gray",
        color: "black"
    }

    switch (role?.toLowerCase()) {
        case "actor": {
            result.background = "#FAC6D5";
            result.color = "#AD4261";
            break;
        }
        case "director": {
            result.background = "#E6C9F3";
            result.color = "#663D79";
            break;
        }
        case "editor": {
            result.background = "#DFD9FD";
            result.color = "#2E2787";
            break;
        }
        case "executive producer": {
            result.background = "#C4D8F6";
            result.color = "#1E3E6F";
            break;
        }
        case "producer": {
            result.background = "#C9F2E4";
            result.color = "#275F63";
            break;
        }
        case "screenplay": {
            result.background = "#D1E096";
            result.color = "#515E23";
            break;
        }
        case "writer": {
            result.background = "#D1E096";
            result.color = "#515E23";
            break;
        }
        case "cinematography": {
            result.background = "#FFCC93";
            result.color = "#523617";
            break;
        }
        case "conceptualized by": {
            result.background = "#FFD493";
            result.color = "#523617";
            break;
        }
        case "voice over": {
            result.background = "#F28783";
            result.color = "#6F1D1C";
            break;
        }
        case "music": {
            result.background = "#BDEBF6";
            result.color = "#26606F";
            break;
        }
        case "creator": {
            result.background = "#F0C6FA";
            result.color = "#793D5A";
            break;
        }
        case "narrator": {
            result.background = "#F9A893";
            result.color = "#592315";
            break;
        }
        case "performer": {
            result.background = "#F8DD9B";
            result.color = "#654B0C";
            break;
        }
        case "presenter": {
            result.background = "#F0B9AD";
            result.color = "#671C0B";
            break;
        }
        case "production manager": {
            result.background = "#BCF3ED";
            result.color = "#335E76";
            break;
        }
        case "series editor": {
            result.background = "#D7C5FC";
            result.color = "#6235AA";
            break;
        }
         case "series producer": {
            result.background = "#B6E5D5";
            result.color = "#136762";
            break;
        }
        case "voice": {
            result.background = "#FFA1A7";
            result.color = "#4C070A";
            break;
        }

        default:
            result.background = "gray";
            result.color = "black";
            break;
    }

    return result
}