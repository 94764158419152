import React from "react";
import IPlatformScheduling from "./PlatformSchedulingInterface";
import ScheduleRow from "./ScheduleRow";

type Props = {
    data: Array<IPlatformScheduling>;
};

const ScheduleContainer: React.FC<Props> = ({ data = [] }) => {
    return (
        <div className="platform-scheduling-container">
            {data.map((item: IPlatformScheduling, index: number) => {
                return <ScheduleRow item={item} key={index} />;
            })}
        </div>
    );
};

export default ScheduleContainer;
