const data = [
    {
        text: "Afghanistan",
        value: "AFG",
        country_code: "004",
    },
    {
        text: "Åland Islands",
        value: "ALA",
        country_code: "248",
    },
    {
        text: "Albania",
        value: "ALB",
        country_code: "008",
    },
    {
        text: "Algeria",
        value: "DZA",
        country_code: "012",
    },
    {
        text: "American Samoa",
        value: "ASM",
        country_code: "016",
    },
    {
        text: "Andorra",
        value: "AND",
        country_code: "020",
    },
    {
        text: "Angola",
        value: "AGO",
        country_code: "024",
    },
    {
        text: "Anguilla",
        value: "AIA",
        country_code: "660",
    },
    {
        text: "Antarctica",
        value: "ATA",
        country_code: "010",
    },
    {
        text: "Antigua and Barbuda",
        value: "ATG",
        country_code: "028",
    },
    {
        text: "Argentina",
        value: "ARG",
        country_code: "032",
    },
    {
        text: "Armenia",
        value: "ARM",
        country_code: "051",
    },
    {
        text: "Aruba",
        value: "ABW",
        country_code: "533",
    },
    {
        text: "Australia",
        value: "AUS",
        country_code: "036",
    },
    {
        text: "Austria",
        value: "AUT",
        country_code: "040",
    },
    {
        text: "Azerbaijan",
        value: "AZE",
        country_code: "031",
    },
    {
        text: "Bahamas",
        value: "BHS",
        country_code: "044",
    },
    {
        text: "Bahrain",
        value: "BHR",
        country_code: "048",
    },
    {
        text: "Bangladesh",
        value: "BGD",
        country_code: "050",
    },
    {
        text: "Barbados",
        value: "BRB",
        country_code: "052",
    },
    {
        text: "Belarus",
        value: "BLR",
        country_code: "112",
    },
    {
        text: "Belgium",
        value: "BEL",
        country_code: "056",
    },
    {
        text: "Belize",
        value: "BLZ",
        country_code: "084",
    },
    {
        text: "Benin",
        value: "BEN",
        country_code: "204",
    },
    {
        text: "Bermuda",
        value: "BMU",
        country_code: "060",
    },
    {
        text: "Bhutan",
        value: "BTN",
        country_code: "064",
    },
    {
        text: "Bolivia (Plurinational State of)",
        value: "BOL",
        country_code: "068",
    },
    {
        text: "Bonaire, Sint Eustatius and Saba",
        value: "BES",
        country_code: "535",
    },
    {
        text: "Bosnia and Herzegovina",
        value: "BIH",
        country_code: "070",
    },
    {
        text: "Botswana",
        value: "BWA",
        country_code: "072",
    },
    {
        text: "Bouvet Island",
        value: "BVT",
        country_code: "074",
    },
    {
        text: "Brazil",
        value: "BRA",
        country_code: "076",
    },
    {
        text: "British Indian Ocean Territory",
        value: "IOT",
        country_code: "086",
    },
    {
        text: "Brunei Darussalam",
        value: "BRN",
        country_code: "096",
    },
    {
        text: "Bulgaria",
        value: "BGR",
        country_code: "100",
    },
    {
        text: "Burkina Faso",
        value: "BFA",
        country_code: "854",
    },
    {
        text: "Burundi",
        value: "BDI",
        country_code: "108",
    },
    {
        text: "Cabo Verde",
        value: "CPV",
        country_code: "132",
    },
    {
        text: "Cambodia",
        value: "KHM",
        country_code: "116",
    },
    {
        text: "Cameroon",
        value: "CMR",
        country_code: "120",
    },
    {
        text: "Canada",
        value: "CAN",
        country_code: "124",
    },
    {
        text: "Cayman Islands",
        value: "CYM",
        country_code: "136",
    },
    {
        text: "Central African Republic",
        value: "CAF",
        country_code: "140",
    },
    {
        text: "Chad",
        value: "TCD",
        country_code: "148",
    },
    {
        text: "Chile",
        value: "CHL",
        country_code: "152",
    },
    {
        text: "China",
        value: "CHN",
        country_code: "156",
    },
    {
        text: "Christmas Island",
        value: "CXR",
        country_code: "162",
    },
    {
        text: "Cocos (Keeling) Islands",
        value: "CCK",
        country_code: "166",
    },
    {
        text: "Colombia",
        value: "COL",
        country_code: "170",
    },
    {
        text: "Comoros",
        value: "COM",
        country_code: "174",
    },
    {
        text: "Congo",
        value: "COG",
        country_code: "178",
    },
    {
        text: "Congo (Democratic Republic of the)",
        value: "COD",
        country_code: "180",
    },
    {
        text: "Cook Islands",
        value: "COK",
        country_code: "184",
    },
    {
        text: "Costa Rica",
        value: "CRI",
        country_code: "188",
    },
    {
        text: "Côte d'Ivoire",
        value: "CIV",
        country_code: "384",
    },
    {
        text: "Croatia",
        value: "HRV",
        country_code: "191",
    },
    {
        text: "Cuba",
        value: "CUB",
        country_code: "192",
    },
    {
        text: "Curaçao",
        value: "CUW",
        country_code: "531",
    },
    {
        text: "Cyprus",
        value: "CYP",
        country_code: "196",
    },
    {
        text: "Czechia",
        value: "CZE",
        country_code: "203",
    },
    {
        text: "Denmark",
        value: "DNK",
        country_code: "208",
    },
    {
        text: "Djibouti",
        value: "DJI",
        country_code: "262",
    },
    {
        text: "Dominica",
        value: "DMA",
        country_code: "212",
    },
    {
        text: "Dominican Republic",
        value: "DOM",
        country_code: "214",
    },
    {
        text: "Ecuador",
        value: "ECU",
        country_code: "218",
    },
    {
        text: "Egypt",
        value: "EGY",
        country_code: "818",
    },
    {
        text: "El Salvador",
        value: "SLV",
        country_code: "222",
    },
    {
        text: "Equatorial Guinea",
        value: "GNQ",
        country_code: "226",
    },
    {
        text: "Eritrea",
        value: "ERI",
        country_code: "232",
    },
    {
        text: "Estonia",
        value: "EST",
        country_code: "233",
    },
    {
        text: "Ethiopia",
        value: "ETH",
        country_code: "231",
    },
    {
        text: "Falkland Islands (Malvinas)",
        value: "FLK",
        country_code: "238",
    },
    {
        text: "Faroe Islands",
        value: "FRO",
        country_code: "234",
    },
    {
        text: "Fiji",
        value: "FJI",
        country_code: "242",
    },
    {
        text: "Finland",
        value: "FIN",
        country_code: "246",
    },
    {
        text: "France",
        value: "FRA",
        country_code: "250",
    },
    {
        text: "French Guiana",
        value: "GUF",
        country_code: "254",
    },
    {
        text: "French Polynesia",
        value: "PYF",
        country_code: "258",
    },
    {
        text: "French Southern Territories",
        value: "ATF",
        country_code: "260",
    },
    {
        text: "Gabon",
        value: "GAB",
        country_code: "266",
    },
    {
        text: "Gambia",
        value: "GMB",
        country_code: "270",
    },
    {
        text: "Georgia",
        value: "GEO",
        country_code: "268",
    },
    {
        text: "Germany",
        value: "DEU",
        country_code: "276",
    },
    {
        text: "Ghana",
        value: "GHA",
        country_code: "288",
    },
    {
        text: "Gibraltar",
        value: "GIB",
        country_code: "292",
    },
    {
        text: "Greece",
        value: "GRC",
        country_code: "300",
    },
    {
        text: "Greenland",
        value: "GRL",
        country_code: "304",
    },
    {
        text: "Grenada",
        value: "GRD",
        country_code: "308",
    },
    {
        text: "Guadeloupe",
        value: "GLP",
        country_code: "312",
    },
    {
        text: "Guam",
        value: "GUM",
        country_code: "316",
    },
    {
        text: "Guatemala",
        value: "GTM",
        country_code: "320",
    },
    {
        text: "Guernsey",
        value: "GGY",
        country_code: "831",
    },
    {
        text: "Guinea",
        value: "GIN",
        country_code: "324",
    },
    {
        text: "Guinea-Bissau",
        value: "GNB",
        country_code: "624",
    },
    {
        text: "Guyana",
        value: "GUY",
        country_code: "328",
    },
    {
        text: "Haiti",
        value: "HTI",
        country_code: "332",
    },
    {
        text: "Heard Island and McDonald Islands",
        value: "HMD",
        country_code: "334",
    },
    {
        text: "Holy See",
        value: "VAT",
        country_code: "336",
    },
    {
        text: "Honduras",
        value: "HND",
        country_code: "340",
    },
    {
        text: "Hong Kong",
        value: "HKG",
        country_code: "344",
    },
    {
        text: "Hungary",
        value: "HUN",
        country_code: "348",
    },
    {
        text: "Iceland",
        value: "ISL",
        country_code: "352",
    },
    {
        text: "India",
        value: "IND",
        country_code: "356",
    },
    {
        text: "Indonesia",
        value: "IDN",
        country_code: "360",
    },
    {
        text: "Iran (Islamic Republic of)",
        value: "IRN",
        country_code: "364",
    },
    {
        text: "Iraq",
        value: "IRQ",
        country_code: "368",
    },
    {
        text: "Ireland",
        value: "IRL",
        country_code: "372",
    },
    {
        text: "Isle of Man",
        value: "IMN",
        country_code: "833",
    },
    {
        text: "Israel",
        value: "ISR",
        country_code: "376",
    },
    {
        text: "Italy",
        value: "ITA",
        country_code: "380",
    },
    {
        text: "Jamaica",
        value: "JAM",
        country_code: "388",
    },
    {
        text: "Japan",
        value: "JPN",
        country_code: "392",
    },
    {
        text: "Jersey",
        value: "JEY",
        country_code: "832",
    },
    {
        text: "Jordan",
        value: "JOR",
        country_code: "400",
    },
    {
        text: "Kazakhstan",
        value: "KAZ",
        country_code: "398",
    },
    {
        text: "Kenya",
        value: "KEN",
        country_code: "404",
    },
    {
        text: "Kiribati",
        value: "KIR",
        country_code: "296",
    },
    {
        text: "Korea (Democratic People's Republic of)",
        value: "PRK",
        country_code: "408",
    },
    {
        text: "Korea (Republic of)",
        value: "KOR",
        country_code: "410",
    },
    {
        text: "Kuwait",
        value: "KWT",
        country_code: "414",
    },
    {
        text: "Kyrgyzstan",
        value: "KGZ",
        country_code: "417",
    },
    {
        text: "Lao People's Democratic Republic",
        value: "LAO",
        country_code: "418",
    },
    {
        text: "Latvia",
        value: "LVA",
        country_code: "428",
    },
    {
        text: "Lebanon",
        value: "LBN",
        country_code: "422",
    },
    {
        text: "Lesotho",
        value: "LSO",
        country_code: "426",
    },
    {
        text: "Liberia",
        value: "LBR",
        country_code: "430",
    },
    {
        text: "Libya",
        value: "LBY",
        country_code: "434",
    },
    {
        text: "Liechtenstein",
        value: "LIE",
        country_code: "438",
    },
    {
        text: "Lithuania",
        value: "LTU",
        country_code: "440",
    },
    {
        text: "Luxembourg",
        value: "LUX",
        country_code: "442",
    },
    {
        text: "Macao",
        value: "MAC",
        country_code: "446",
    },
    {
        text: "Macedonia (the former Yugoslav Republic of)",
        value: "MKD",
        country_code: "807",
    },
    {
        text: "Madagascar",
        value: "MDG",
        country_code: "450",
    },
    {
        text: "Malawi",
        value: "MWI",
        country_code: "454",
    },
    {
        text: "Malaysia",
        value: "MYS",
        country_code: "458",
    },
    {
        text: "Maldives",
        value: "MDV",
        country_code: "462",
    },
    {
        text: "Mali",
        value: "MLI",
        country_code: "466",
    },
    {
        text: "Malta",
        value: "MLT",
        country_code: "470",
    },
    {
        text: "Marshall Islands",
        value: "MHL",
        country_code: "584",
    },
    {
        text: "Martinique",
        value: "MTQ",
        country_code: "474",
    },
    {
        text: "Mauritania",
        value: "MRT",
        country_code: "478",
    },
    {
        text: "Mauritius",
        value: "MUS",
        country_code: "480",
    },
    {
        text: "Mayotte",
        value: "MYT",
        country_code: "175",
    },
    {
        text: "Mexico",
        value: "MEX",
        country_code: "484",
    },
    {
        text: "Micronesia (Federated States of)",
        value: "FSM",
        country_code: "583",
    },
    {
        text: "Moldova (Republic of)",
        value: "MDA",
        country_code: "498",
    },
    {
        text: "Monaco",
        value: "MCO",
        country_code: "492",
    },
    {
        text: "Mongolia",
        value: "MNG",
        country_code: "496",
    },
    {
        text: "Montenegro",
        value: "MNE",
        country_code: "499",
    },
    {
        text: "Montserrat",
        value: "MSR",
        country_code: "500",
    },
    {
        text: "Morocco",
        value: "MAR",
        country_code: "504",
    },
    {
        text: "Mozambique",
        value: "MOZ",
        country_code: "508",
    },
    {
        text: "Myanmar",
        value: "MMR",
        country_code: "104",
    },
    {
        text: "Namibia",
        value: "NAM",
        country_code: "516",
    },
    {
        text: "Nauru",
        value: "NRU",
        country_code: "520",
    },
    {
        text: "Nepal",
        value: "NPL",
        country_code: "524",
    },
    {
        text: "Netherlands",
        value: "NLD",
        country_code: "528",
    },
    {
        text: "New Caledonia",
        value: "NCL",
        country_code: "540",
    },
    {
        text: "New Zealand",
        value: "NZL",
        country_code: "554",
    },
    {
        text: "Nicaragua",
        value: "NIC",
        country_code: "558",
    },
    {
        text: "Niger",
        value: "NER",
        country_code: "562",
    },
    {
        text: "Nigeria",
        value: "NGA",
        country_code: "566",
    },
    {
        text: "Niue",
        value: "NIU",
        country_code: "570",
    },
    {
        text: "Norfolk Island",
        value: "NFK",
        country_code: "574",
    },
    {
        text: "Northern Mariana Islands",
        value: "MNP",
        country_code: "580",
    },
    {
        text: "Norway",
        value: "NOR",
        country_code: "578",
    },
    {
        text: "Oman",
        value: "OMN",
        country_code: "512",
    },
    {
        text: "Pakistan",
        value: "PAK",
        country_code: "586",
    },
    {
        text: "Palau",
        value: "PLW",
        country_code: "585",
    },
    {
        text: "Palestine, State of",
        value: "PSE",
        country_code: "275",
    },
    {
        text: "Panama",
        value: "PAN",
        country_code: "591",
    },
    {
        text: "Papua New Guinea",
        value: "PNG",
        country_code: "598",
    },
    {
        text: "Paraguay",
        value: "PRY",
        country_code: "600",
    },
    {
        text: "Peru",
        value: "PER",
        country_code: "604",
    },
    {
        text: "Philippines",
        value: "PHL",
        country_code: "608",
    },
    {
        text: "Pitcairn",
        value: "PCN",
        country_code: "612",
    },
    {
        text: "Poland",
        value: "POL",
        country_code: "616",
    },
    {
        text: "Portugal",
        value: "PRT",
        country_code: "620",
    },
    {
        text: "Puerto Rico",
        value: "PRI",
        country_code: "630",
    },
    {
        text: "Qatar",
        value: "QAT",
        country_code: "634",
    },
    {
        text: "Réunion",
        value: "REU",
        country_code: "638",
    },
    {
        text: "Romania",
        value: "ROU",
        country_code: "642",
    },
    {
        text: "Russian Federation",
        value: "RUS",
        country_code: "643",
    },
    {
        text: "Rwanda",
        value: "RWA",
        country_code: "646",
    },
    {
        text: "Saint Barthélemy",
        value: "BLM",
        country_code: "652",
    },
    {
        text: "Saint Helena, Ascension and Tristan da Cunha",
        value: "SHN",
        country_code: "654",
    },
    {
        text: "Saint Kitts and Nevis",
        value: "KNA",
        country_code: "659",
    },
    {
        text: "Saint Lucia",
        value: "LCA",
        country_code: "662",
    },
    {
        text: "Saint Martin (French part)",
        value: "MAF",
        country_code: "663",
    },
    {
        text: "Saint Pierre and Miquelon",
        value: "SPM",
        country_code: "666",
    },
    {
        text: "Saint Vincent and the Grenadines",
        value: "VCT",
        country_code: "670",
    },
    {
        text: "Samoa",
        value: "WSM",
        country_code: "882",
    },
    {
        text: "San Marino",
        value: "SMR",
        country_code: "674",
    },
    {
        text: "Sao Tome and Principe",
        value: "STP",
        country_code: "678",
    },
    {
        text: "Saudi Arabia",
        value: "SAU",
        country_code: "682",
    },
    {
        text: "Senegal",
        value: "SEN",
        country_code: "686",
    },
    {
        text: "Serbia",
        value: "SRB",
        country_code: "688",
    },
    {
        text: "Seychelles",
        value: "SYC",
        country_code: "690",
    },
    {
        text: "Sierra Leone",
        value: "SLE",
        country_code: "694",
    },
    {
        text: "Singapore",
        value: "SGP",
        country_code: "702",
    },
    {
        text: "Sint Maarten (Dutch part)",
        value: "SXM",
        country_code: "534",
    },
    {
        text: "Slovakia",
        value: "SVK",
        country_code: "703",
    },
    {
        text: "Slovenia",
        value: "SVN",
        country_code: "705",
    },
    {
        text: "Solomon Islands",
        value: "SLB",
        country_code: "090",
    },
    {
        text: "Somalia",
        value: "SOM",
        country_code: "706",
    },
    {
        text: "South Africa",
        value: "ZAF",
        country_code: "710",
    },
    {
        text: "South Georgia and the South Sandwich Islands",
        value: "SGS",
        country_code: "239",
    },
    {
        text: "South Sudan",
        value: "SSD",
        country_code: "728",
    },
    {
        text: "Spain",
        value: "ESP",
        country_code: "724",
    },
    {
        text: "Sri Lanka",
        value: "LKA",
        country_code: "144",
    },
    {
        text: "Sudan",
        value: "SDN",
        country_code: "729",
    },
    {
        text: "Suritext",
        value: "SUR",
        country_code: "740",
    },
    {
        text: "Svalbard and Jan Mayen",
        value: "SJM",
        country_code: "744",
    },
    {
        text: "Swaziland",
        value: "SWZ",
        country_code: "748",
    },
    {
        text: "Sweden",
        value: "SWE",
        country_code: "752",
    },
    {
        text: "Switzerland",
        value: "CHE",
        country_code: "756",
    },
    {
        text: "Syrian Arab Republic",
        value: "SYR",
        country_code: "760",
    },
    {
        text: "Taiwan, Province of China",
        value: "TWN",
        country_code: "158",
    },
    {
        text: "Tajikistan",
        value: "TJK",
        country_code: "762",
    },
    {
        text: "Tanzania, United Republic of",
        value: "TZA",
        country_code: "834",
    },
    {
        text: "Thailand",
        value: "THA",
        country_code: "764",
    },
    {
        text: "Timor-Leste",
        value: "TLS",
        country_code: "626",
    },
    {
        text: "Togo",
        value: "TGO",
        country_code: "768",
    },
    {
        text: "Tokelau",
        value: "TKL",
        country_code: "772",
    },
    {
        text: "Tonga",
        value: "TON",
        country_code: "776",
    },
    {
        text: "Trinidad and Tobago",
        value: "TTO",
        country_code: "780",
    },
    {
        text: "Tunisia",
        value: "TUN",
        country_code: "788",
    },
    {
        text: "Turkey",
        value: "TUR",
        country_code: "792",
    },
    {
        text: "Turkmenistan",
        value: "TKM",
        country_code: "795",
    },
    {
        text: "Turks and Caicos Islands",
        value: "TCA",
        country_code: "796",
    },
    {
        text: "Tuvalu",
        value: "TUV",
        country_code: "798",
    },
    {
        text: "Uganda",
        value: "UGA",
        country_code: "800",
    },
    {
        text: "Ukraine",
        value: "UKR",
        country_code: "804",
    },
    {
        text: "United Arab Emirates",
        value: "ARE",
        country_code: "784",
    },
    {
        text: "United Kingdom of Great Britain and Northern Ireland",
        value: "GBR",
        country_code: "826",
    },
    {
        text: "United States of America",
        value: "USA",
        country_code: "840",
    },
    {
        text: "United States Minor Outlying Islands",
        value: "UMI",
        country_code: "581",
    },
    {
        text: "Uruguay",
        value: "URY",
        country_code: "858",
    },
    {
        text: "Uzbekistan",
        value: "UZB",
        country_code: "860",
    },
    {
        text: "Vanuatu",
        value: "VUT",
        country_code: "548",
    },
    {
        text: "Venezuela (Bolivarian Republic of)",
        value: "VEN",
        country_code: "862",
    },
    {
        text: "Viet Nam",
        value: "VNM",
        country_code: "704",
    },
    {
        text: "Virgin Islands (British)",
        value: "VGB",
        country_code: "092",
    },
    {
        text: "Virgin Islands (U.S.)",
        value: "VIR",
        country_code: "850",
    },
    {
        text: "Wallis and Futuna",
        value: "WLF",
        country_code: "876",
    },
    {
        text: "Western Sahara",
        value: "ESH",
        country_code: "732",
    },
    {
        text: "Yemen",
        value: "YEM",
        country_code: "887",
    },
    {
        text: "Zambia",
        value: "ZMB",
        country_code: "894",
    },
    {
        text: "Zimbabwe",
        value: "ZWE",
        country_code: "716",
    },
];

export default data;
