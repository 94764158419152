import { Box, Stack } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Controller, useFormContext } from "react-hook-form";
import { CoreFormType } from "../../../types";
import { CoreFormLabel, CoreFormLabelProps } from "../../styled/CoreForm.label";
import { StyledTextField } from "../../styled/CoreFormText.field";
import { useStore } from "../../../../../components/Global/store/store";
import { formatDateTimeToPayload } from "../../../utils/formatDateTimeToPayload";
import { CoreFormFieldError } from "../../styled/CoreFormFieldError";
import { DateTimeDisabled } from "./DateTimeDisabled";
import system from "../../../../../config/system";
import moment from "moment";
import dayjs from 'dayjs'
import { get, isEmpty } from "lodash";

export function CoreInputDatePicker<T>({
    path,
    labelProps,
    disabled,
    payloadFormat,
    format,
    noneElemnt = false,
    ...muiDatePickerProps
}: {
    path: CoreFormType<T>;
    disabled?: boolean;
    format?: string,
    noneElemnt?: boolean,
    payloadFormat?: string
} & { labelProps?: CoreFormLabelProps }) {
    const [store] = useStore() || [{}]
    const formMethods = useFormContext<T>();
    const value = formMethods.watch(path);
    const { setValue, setError, clearErrors } = formMethods

    const errorMessage = get(formMethods?.formState.errors, path)?.message;
    const dateFormat: string = format || store?.cmsData?.clientDataStructure?.date_format || system.datetime.payload.format.date
    const payloadDateFormat = format === 'YYYY' ? 'YYYY' : (payloadFormat || system.datetime.payload.format.date)

    return (
        <div 
            style={{ width: '100%' }}
            data-testid={`core-datepicker-${path}`}>

            <Stack width="100%">
                {labelProps && <CoreFormLabel {...labelProps} />}
                <Controller
                    name={path}
                    render={({
                        field: { ref, onBlur, name, ...field },
                        fieldState,
                    }) => {
                        if (disabled && noneElemnt) {
                            const formattedValue = value ? moment(value, payloadDateFormat).format(format) : null
                            return (<DateTimeDisabled value={formattedValue} />)
                        }

                        return (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                    {...field}
                                    {...muiDatePickerProps}
                                    format={dateFormat}
                                    disabled={disabled}
                                    views={format === 'YYYY' ? ['year'] : ['year', 'day']}
                                    inputRef={ref}
                                    value={dayjs(value)}
                                    onChange={(value: any) => {
                                        if (dayjs(value).isValid() || !value) {
                                            clearErrors(path)
                                            setValue(
                                                path,
                                                formatDateTimeToPayload(value, payloadDateFormat)
                                            )
                                        } else {
                                            setError(path, {
                                                type: 'manual',
                                                message: 'Invalid date'
                                            })
                                        }
                                    }}
                                    slots={{
                                        textField: StyledTextField
                                    }}
                                    slotProps={{
                                        textField: {
                                            path,
                                            onBlur,
                                            name,
                                            error: !!fieldState.error,
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        )
                    }
                }
                />
                {errorMessage && (
                    <CoreFormFieldError path={path} message={errorMessage} />
                )}
            </Stack>
        </div>
    );
}

CoreInputDatePicker.defaultProps = {
    labelProps: null,
};
