import _ from "lodash";
import SendRequest from "../../components/SendRequest";
import { getSession, connect, buildTopic } from "../../components/IoT";
import update from "react-addons-update";
import { getTransformedRecordToApi } from "../../models/Record/model"

const HandleUpdateRecordRequest = (props) => {
    const {
        saveAndContinue,
        state = {},
        copy = {},
        category,
        IoTData,
        callBack = () => {},
    } = props;

    const {
        set,
        searchData = [],
        cmsData = {},
        recordData,
        recordChanges,
        handleRecentActivitySearch = () => {},
        handleRecordUpdateAfterIoT = () => {},
    } = state;

    const { apiUrls = {} } = cmsData;

    const { clientData = {}, clientFeatures = {} } = cmsData;

    const { iot } = clientFeatures;

    const requestData = getTransformedRecordToApi(recordChanges, cmsData);

    const options = {
        url: apiUrls.updateRecordUrl.replace("{meta_id}", recordData.meta_id),
        method: "PUT",
        body: requestData,
    };

    const handleError = (error) => {
        set(
            {
                savingRecord: false,
                loadingId: false,
                notification: {
                    title: "Technical Error",
                    description:
                        typeof error == "string"
                            ? error
                            : "Record could not be updated.",
                    status: "alert",
                    onClick: () => {
                        set({
                            notification: {},
                        });
                    },
                },
            },
            callBack,
        );

        console.log(error);
    };

    const handleSuccess = (description) => {
        set({
            notification: {
                title: `${category} has been updated`,
                description: description,
                status: "alert",
                onClick: () => {
                    handleRecentActivitySearch(category);
                    set({
                        notification: {},
                    });
                },
            },
        });
    };

    SendRequest(
        options,
        async (response) => {
            const { status, data, message } = response;

            if (!_.isEmpty(data) && status == 200) {
                let sharedKeys = {
                    loadingId: false,
                    validationKeys: [],
                    unsavedChanges: false,
                    savingRecord: false,
                    recordChanges: {},
                    collectionTitle: {},
                };

                if (iot) {
                    const IoT = await getSession();
                    const topic = buildTopic({
                        client: clientData.clientRsName || process.env.CLIENT,
                        env: process.env.APP_ENV,
                        meta_id: data.meta_id,
                        topic: "updated",
                    });

                    await connect({
                        ...IoT,
                        topic,
                        onMessage: () => handleRecordUpdateAfterIoT(),
                    });

                    sharedKeys.IoTConnection = IoTData;

                    const searchDataIndex = _.findIndex(
                        searchData,
                        function (item) {
                            return item.meta_id == data.meta_id;
                        },
                    );

                    const searchDataState = update(searchData, {
                        [searchDataIndex]: {
                            $set: IoTData,
                        },
                    });

                    if (
                        searchDataIndex !== -1 &&
                        Array.isArray(searchDataState)
                    ) {
                        sharedKeys.searchData = searchDataState;
                    }
                }

                if (saveAndContinue) {
                    set(
                        {
                            ...sharedKeys,
                            recordData: data,
                            originalRecordData: data,
                            originalRecord: data,
                        },
                        callBack,
                    );
                }

                if (!saveAndContinue) {
                    set(
                        {
                            ...sharedKeys,
                            editRecord: false,
                            activeRecord: {},
                            recordData: {},
                            originalRecordData: {},
                            originalRecord: {},
                            urlComponents: {},
                            searchQuery: "",
                        },
                        callBack,
                    );
                }

                const description = `${data.meta_id} (${data.original_title}) update success`;
                handleSuccess(description);
                return;
            }

            // On Error
            handleError(message);
        },
        (e) => {
            handleError(e);
            console.log(e);
        },
    );
};

export default HandleUpdateRecordRequest;
