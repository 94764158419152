import React, { Fragment } from "react";
import TitleValue from "../../../TitleValue";
import moment from "moment";
import { GetNotifications } from "../Notifications";
import Clients from "../../../Clients";
import _ from "lodash";
import ConvertExtraInfoToString from "./ConvertExtraInfoToString";

const RenderBasicInfo = (props) => {
    const { compliance = {}, Notifications } = props;

    const { meta_id, created_at } = compliance;

    return (
        <div className="double-inline-30-70 asset-info">
            <div>
                <TitleValue
                    title="Fabric Asset ID"
                    value={meta_id}
                    className="compliance--meta-id"
                />

                <ExtraFields {...props} />

                <TitleValue
                    title="Created"
                    value={
                        created_at
                            ? moment(created_at).format("HH:mm, Do MMMM YYYY")
                            : false
                    }
                    className="compliance--created"
                />
            </div>

            <div>
                <TitleValue
                    title="Workflow Notification"
                    value={Notifications()}
                    valueStyle={{ lineHeight: 1.3 }}
                    className="compliance--workflow-notifications"
                />
            </div>
        </div>
    );
};

const RenderSectionBasicInfo = (props) => {
    const {
        compliance = {},
        Notifications,
        dataStructure = {},
        asset_id = {},
        workflow_notifications = {},
    } = props;

    const { basicInfoRow = false, created = {} } = dataStructure;

    const { meta_id, created_at } = compliance;

    const titleValueStyle = {
        width: "auto",
    };

    let createdAtFormat = created_at
        ? moment(created_at).format("HH:mm, Do MMMM YYYY")
        : false;
    if (createdAtFormat && Clients.isClientEpix() && !_.isEmpty(created_at)) {
        createdAtFormat = moment(created_at).format("M/D/YYYY HH:mm");
    }

    return (
        <Fragment>
            <div className="asset-info">
                <TitleValue
                    title="Fabric Asset ID"
                    value={meta_id}
                    style={asset_id.style ? asset_id.style : titleValueStyle}
                    className="compliance--meta-id"
                />
                {!basicInfoRow && (
                    <ExtraFields {...props} style={titleValueStyle} />
                )}

                <TitleValue
                    title="Created"
                    value={createdAtFormat}
                    style={created.style ? created.style : titleValueStyle}
                    className="compliance--created"
                />

                <TitleValue
                    title="Workflow Notification"
                    value={Notifications()}
                    valueStyle={{ lineHeight: 1.3 }}
                    style={
                        workflow_notifications.style
                            ? workflow_notifications.style
                            : titleValueStyle
                    }
                    className="compliance--workflow-notifications"
                />
            </div>
            {basicInfoRow && (
                <div className="asset-info row">
                    <ExtraFields {...props} style={titleValueStyle} />
                </div>
            )}
        </Fragment>
    );
};

const ExtraFields = (props) => {
    const { compliance = {}, extraFields = [], style } = props;

    return (
        <Fragment>
            {extraFields.map((field, index) => {
                let value;

                if (compliance[field.key]) {
                    value = compliance[field.key];
                } else if (field.otherKeys && Array.isArray(field.otherKeys)) {
                    for (const key of field.otherKeys) {
                        if (compliance[key]) {
                            value = compliance[key];
                            break;
                        }
                    }
                }

                return (
                    <TitleValue
                        title={field.title}
                        value={ConvertExtraInfoToString(value)}
                        style={field.style ? field.style : style}
                        className={`compliance--${field.key}`}
                        key={index}
                    />
                );
            })}
        </Fragment>
    );
};

const BasicInfo = (props) => {
    const { compliance = {}, clientFeatures = {}, dataStructure = {} } = props;

    const { assets = {} } = clientFeatures;

    const { meta_users, email_addresses } = compliance;

    const { sectionedUi } = assets;

    const {
        basic_info = [],
        basic_info_options = {},
        asset_id,
        workflow_notifications,
        created,
    } = dataStructure;

    const Notifications = () =>
        GetNotifications({
            meta_users,
            email_addresses,
        });

    const renderProps = {
        ...props,
        extraFields: basic_info,
        Notifications,
        asset_id,
        workflow_notifications,
        created,
        basic_info_options,
    };

    return sectionedUi ? (
        <RenderSectionBasicInfo {...renderProps} />
    ) : (
        <RenderBasicInfo {...renderProps} />
    );
};

export default BasicInfo;
