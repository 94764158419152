import React from 'react'
import { Chip } from "@mui/material";

export default function(
    value: string, 
    checked: boolean
) {
    return (
            <Chip
                label={value}
                sx={{
                    bgcolor: checked ? "#EBF7EF" : "#FBEDEF",
                    "& .MuiChip-label": {
                    color: checked ? "#13572D" : "#761424",
                    fontWeight: checked ? 600 : 400,
                    fontSize: "0.875rem",
                    },
                    padding: "0.25rem 0.375rem",
                    borderRadius: "0.125rem",
                    margin: "0 0 0 0.75rem",
                    fontSize: "0.875rem",
                }}
            />
        );
}