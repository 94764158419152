import { IconButton, SvgIcon } from "@material-ui/core";
import React from "react";

type Props = {
    onClick: () => void;
};

const ExpandImageButton: React.FC = ({ onClick }: Props) => (
    <IconButton
        style={{
            position: "absolute",
            top: 4,
            left: 8,
            zIndex: 1,
            borderRadius: "0.25rem",
            marginLeft: "auto",
            padding: "0.25rem",
            transform: "rotate(90deg)",
        }}
        aria-label="expand"
        onClick={onClick}
    >
        <SvgIcon 
            viewBox="0 0 16 16"
            style={{
                fontSize: "1rem"
            }}>
            <path
                d="M6.22222 16V14.2222H3.03111L7.03111 10.2222L5.77778 8.96889L1.77778 12.9689V9.77778H0V16H6.22222ZM10.2222 7.03111L14.2222 3.03111V6.22222H16V0H9.77778V1.77778H12.9689L8.96889 5.77778L10.2222 7.03111Z"
                fill="#fff"
                fillOpacity="1"
            />
        </SvgIcon>
    </IconButton>
);

export default ExpandImageButton;
