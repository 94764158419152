import React from "react";
import CategoryTags from "../../CategoryTags";

const RenderCategoryPosition = (props) => {
    const { index, item = {} } = props;

    const { category } = item;

    const categoryItem = CategoryTags[category] || {};
    const background = categoryItem.color;

    let style = {};
    if (background) style.background = background;

    return (
        <div className="meta-numbered-record-table--position" style={style}>
            {index + 1}
        </div>
    );
};

export default RenderCategoryPosition;
