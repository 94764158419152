import Margins from "./Margins";
import _ from "lodash";

const PlatformTransformer = (item) => {
    const {
        platform,
        user_group,
        created_by,
        created_at,
        buy_rate,
        expected_buy_runs,
        expected_sell_runs,
        sell_rate,
        notes,
        last_edited_on,
        onboarding_date,
        last_edited_by,
        offboarding_date,
        licensor,
    } = item;

    const { total_buy_rate, total_sell_rate, total_margin, margin } =
        Margins(item);

    let platformData = platform;
    if (Array.isArray(platformData) && !_.isEmpty(platformData)) {
        let platformText = "";
        platformData.map((item, key) => {
            if (typeof item === "string") {
                platformText += item;
                if (key !== platformData.length - 1) platformText += " | ";
            }
        });

        if (!_.isEmpty(platformText)) platformData = platformText;
    }

    let licensorText = licensor;
    if (
        typeof licensor === "object" &&
        (licensor.hasOwnProperty("value") || licensor.hasOwnProperty("text"))
    ) {
        licensorText = licensor.text || licensor.value;
    }

    const result = {
        platform: platformData,
        user_group,
        created_by,
        created_at,
        buy_rate,
        total_buy_rate,
        total_sell_rate,
        total_margin,
        margin,
        notes,
        last_edited_on,
        onboarding_date,
        last_edited_by,
        sell_rate,
        expected_buy_runs,
        expected_sell_runs,
        offboarding_date,
        licensor: licensorText,
    };

    return result;
};

export default PlatformTransformer;
