import Field from '../../../../models/RecordBuilder/Field.interface'

export const fieldName: Field = {
    dataType: 'String',
    displayName: 'Contributor Name',
    key: 'contributor_id',
    options: {
        validation: {
            required: {
                enabled: true
            }
        },
        layer: {
            columns: 2,
            level: 1
        },
        editable: {
            inputType: 'AutocompleteContributors'
        }
    }
}

export const fieldNotes: Field = {
    dataType: 'String',
    displayName: 'Credit Notes',
    key: 'notes',
    options: {
        layer: {
            columns: 2,
            level: 1
        }
    }
}

export const fieldNotesUpdate: Field = {
    ...fieldNotes,
    options: {
        layer: {
            columns: 1,
            level: 3
        }
    }
}

export const fieldRole: Field = {
    dataType: 'String',
    displayName: 'Role',
    key: 'role_id',
    options: {
        validation: {
            required: {
                enabled: true
            }
        },
        layer: {
            columns: 1,
            level: 2
        },
        editable: {
            inputType: 'Select',
            optionsManagedList: {
                enabled: true,
                key: 'client_managed_cast_and_crew',
                option: {
                    dataType: 'Object',
                    textField: 'name',
                    valueField: 'id',
                }
            }
        }
    }
}

export const fieldRoleUpdate: Field = {
    ...fieldRole,
    options: {
        ...fieldRole.options,
        editable: {
            ...fieldRole.options.editable,
            disabledOnEdit: true,
            inputType: 'SelectFramed',
        },
        tooltip: "To change the role, please delete the existing Credit and add a new one with the desired role."
    },
}

export const fieldCharacterNameLanguage: Field = {
    dataType: 'String',
    displayName: 'Character Name Language',
    key: 'locale',
    options: {
        layer: {
            columns: 1,
            level: 2
        },
        editable: {
            inputType: 'Select',
            optionsManagedList: {
                adornmentKey: "locale",
                list: [],
                enabled: true,
                key: 'cast_and_crew',
                textField: 'text',
                valueField: 'value',
                option: {
                    dataType: 'Object'
                }
            }
        }
    }
}

export const fieldCharacterName: Field = {
    dataType: 'Array',
    displayName: 'Character Name',
    key: 'character_name',
    options: {
        layer: {
            columns: 1,
            level: 2
        },
        editable: {
            inputType: 'Autocomplete',
            optionsManagedList: {
                enabled: true,
                growing: true,
                color: "#DEDEDE",
                textColor: "#494949",
                textField: 'name',
                valueField: 'value',
                option: {
                    dataType: 'Object',
                    textField: 'name',
                    valueField: 'value'
                }
            }
        }
    }
}

export const configurationCreate = {
    code: 'credit',
    fields: [
        fieldName,
        fieldNotes,
        fieldRole,
        fieldCharacterNameLanguage,
        fieldCharacterName
    ]
}

export const configurationUpdate = {
    code: 'credit',
    fields: [
        fieldRoleUpdate,
        fieldCharacterNameLanguage,
        fieldCharacterName,
        fieldNotesUpdate
    ]
}

export const apiCreateFields: string[] = [
    fieldName.key,
    fieldNotes.key,
    fieldRole.key,
    'localised_data'
]

export const apiUpdateFields: string[] = [
    'localised_data',
    fieldNotes.key,
]