import React from "react";
import { SectionInterface } from "./interface";
import RelatedTitles from "../../views/RelatedTitles";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";
import { useStore } from "../../../Global/store/store";

const SectionRelatedTitles = (props: SectionInterface) => {
    const {
        cmsData = {},
        data = {},
        handleInheritanceChange = () => {},
        handleConvertingChange = () => {},
        attributes = {},
        linkageDatalist,
    } = props;

    const [state] = useStore() || [{}];

    const { userPermissions = {} } = state;

    const { clientDataStructure = {}, clientFeatures = {} } = cmsData;

    const { linkage = {} } = clientFeatures;

    const { customIds = {} } = linkage;

    return (
        <RelatedTitles
            {...getSectionCommonProps({ ...props, field: "linkage" })}
            name="related-titles"
            className="meta-record-related-titles"
            title="Related Titles"
            meta_id={data.meta_id}
            data={data.linkage || []}
            customIds={customIds}
            category={data.category}
            recordType={data.origination ? data.origination.type : ""}
            lastSection={true}
            searchUrl={attributes.searchLinkageUrl}
            searchUrlFull={attributes.searchLinkageFullUrl}
            handleInheritanceChange={handleInheritanceChange.bind(this)}
            handleConvertingChange={handleConvertingChange.bind(this)}
            originRecordData={data || {}}
            datalist={linkageDatalist}
            clientDataStructure={clientDataStructure}
            clientFeatures={clientFeatures}
            cmsData={cmsData}
            userPermissions={userPermissions}
        />
    );
};

export default SectionRelatedTitles;
