import HandleCatalogAvailabilityRequest from "./HandleCatalogAvailabilityRequest";

const HandleFetchCatalogAvailability = (props) => {
    const { state = {}, options = {} } = props;

    const { handleError, handleSuccess } = options;

    HandleCatalogAvailabilityRequest({
        state,
        method: "GET",
        handleError,
        handleSuccess,
    });
};

export default HandleFetchCatalogAvailability;
