import React from "react";
import FadeMaterial from "../TransitionGroup/FadeMaterial";
import SkeletonLoader from "../SkeletonLoader";

type Props = {
    loading?: boolean;
    skeletonLoading?: boolean;
    recordBody?: boolean;
};

const FadeWithSkeleton: React.FC<Props> = (props) => {
    const {
        loading = false,
        skeletonLoading = false,
        recordBody = false,
    } = props;

    if (!loading) {
        return <></>;
    }

    return (
        <FadeMaterial loading={loading}>
            <SkeletonLoader loading={skeletonLoading} recordBody={recordBody} />
        </FadeMaterial>
    );
};

export default FadeWithSkeleton;
