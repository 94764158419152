import _ from "lodash";

const ResolveCondition = (props) => {
    const {
        fieldName = "",
        dataSection = false,
        metaId = false,
        auditRow = {},
        value = false,
        scriptLanguage = false,
        locale = false,
    } = props;

    let resolverConditions = {};
    let values = auditRow.new_values || {};

    resolverConditions.isDataSection =
        dataSection &&
        !_.isEmpty(dataSection) &&
        dataSection === auditRow.field_type;
    resolverConditions.isTypeFieldCheck =
        values.type && values.type === fieldName;
    resolverConditions.isMetaIdCheck =
        metaId && values.meta_id && values.meta_id === metaId;
    resolverConditions.checkValue = value && !_.isEmpty(value);
    resolverConditions.fieldIn = Object.keys(values).includes(fieldName);

    if (scriptLanguage && values[scriptLanguage]) {
        let data = values[scriptLanguage];
        resolverConditions.fieldIn = Object.keys(data).includes(fieldName);
    }

    if (
        locale &&
        values.locale &&
        typeof locale === "string" &&
        typeof values.locale === "string"
    ) {
        resolverConditions.fieldIn = values.locale == locale;
    }

    return resolverConditions;
};

export default ResolveCondition;
