import SendRequest from "../../components/SendRequest";
import { CmsInterface } from "../../components/Global/interfaces"
import { CatalogInterface, PolicyInterface } from "../../features/UserRoleManagement/interfaces"
import { SendRequestResponseInterface } from "../../components/SendRequest/interface"
import { testingCatalogData } from "./test/data"

export async function getCatalogs(cmsData: CmsInterface): Promise<SendRequestResponseInterface> {
    const { apiUrls = {} } = cmsData;

    const { userManagementCatalogs = "" } = apiUrls;

    const options = {
        method: "GET",
        url: userManagementCatalogs,
        corsHeaders: true,
    };

    const response = await SendRequest(options)
    
    return response
}

export async function getPolicyCatalogs(cmsData: CmsInterface, policy: PolicyInterface): Promise<SendRequestResponseInterface> {
    const { apiUrls = {} } = cmsData;

    const { userManagementCatalogs = "" } = apiUrls;

    const options = {
        method: "GET",
        url: userManagementCatalogs,
        corsHeaders: true,
        testingData: testingCatalogData
    };

    const response = await SendRequest(options)
    const responseData = response.data || []
    const policyCatalogKeys: Array<string> = Object.keys(policy?.catalog || {})

    return responseData.filter((catalog: CatalogInterface) => policyCatalogKeys.includes(catalog?.id))
}

export async function createCatalog(cmsData: CmsInterface, body: CatalogInterface): Promise<SendRequestResponseInterface> {
    const { apiUrls = {} } = cmsData;

    const { userManagementCatalogs = "" } = apiUrls;

    const options = {
        method: "POST",
        url: `${userManagementCatalogs}`,
        corsHeaders: true,
        body
    };

    const response = await SendRequest(options)
    
    return response
}

export async function updateCatalog(cmsData: CmsInterface, body: CatalogInterface): Promise<SendRequestResponseInterface> {
    const { apiUrls = {} } = cmsData;

    const { userManagementCatalogs = "" } = apiUrls;

    const options = {
        method: "PUT",
        url: `${userManagementCatalogs}/${body.id}`,
        corsHeaders: true,
        body: {
            name: body.name,
            is_default: body.is_default
        }
    };

    const response = await SendRequest(options)
    
    return response
}

export async function deleteCatalog(cmsData: CmsInterface, body: CatalogInterface): Promise<SendRequestResponseInterface> {
    const { apiUrls = {} } = cmsData;

    const { userManagementCatalogs = "" } = apiUrls;

    const options = {
        method: "DELETE",
        url: `${userManagementCatalogs}/${body.id || body}`,
        corsHeaders: true,
    };

    const response = await SendRequest(options)
    
    return response
}