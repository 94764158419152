import "whatwg-fetch";
import getRequestOptions from "./requestOptions";

export default async function SendRequest(
    options,
    onOk = (response) => {},
    onError = (response) => {}
) {
    if (process.env.NODE_ENV == "test") {
        let data = options.testingData || {
            someKey: true,
        };

        let response = {
            data,
            status: 200,
        };

        if (options.testingIncludedData) {
            response.included = options.testingIncludedData;
        }

        if (options.testHandleSuccess) {
            options.testHandleSuccess(options.testingIncludedData || {});

            return;
        }

        onOk(response);
        return response
    }

    try {
        const requestOptions = getRequestOptions(options);

        const response = await fetch(options.url, requestOptions)
            .then((response) => {
                if (options.responseType == "blob") {
                    return response.blob();
                }

                return response.json();
            })
            .then((json) => {
                if (json.errors) {
                    onError(json.errors)
                    return json.errors;
                } else {
                    let result = json;

                    if (typeof result == "object" && options.stamp) {
                        result.stamp = options.stamp;
                    }

                    onOk(result)
                    return result
                }
            })
            .catch((e) => {
                console.log(e);
                onError(e);
                return e;
            });

        return response
    } catch (e) {
        onError(e)
        return e;
    }

    return false;
};
