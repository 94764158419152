import React from "react";
import { SectionInterface } from "./interface";
import EpisodeNumbering from "../../views/EpisodeNumbering";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";
import IsActiveModel from "../../../RecordBuilder/IsActive";
import { NUMBERING } from "../../../../models/RecordBuilder";
import SectionEpisodeNumberingCore from "./EpisodeNumberingCore";

const SectionStorylineNumbering = (props: SectionInterface) => {
    const { data = {}, cmsData = {} } = props;

    const { clientDataStructure = {}, clientFeatures = {} } = cmsData;

    if (IsActiveModel({ page: NUMBERING, clientFeatures })) {
        return <SectionEpisodeNumberingCore {...props} section={"storyline_numbering"} />
    }

    return (
        <EpisodeNumbering
            {...getSectionCommonProps({
                ...props,
                field: "storyline_numbering",
            })}
            name="storyline-numbering"
            className="meta-record-storyline-numbering"
            title="Storyline Numbering"
            data={data.storyline_numbering || {}}
            category="Storyline"
            clientDataStructure={clientDataStructure}
        />
    );
};

export default SectionStorylineNumbering;
