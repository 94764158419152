import React from "react";
import { extendObservable, makeObservable, action } from 'mobx'
import _ from 'lodash'
import { RootStore } from './rootStore'
import { navigationStoreInitialState } from "./state"
import { TabInterface } from "../../../../../core/Tabs/interfaces";
import { ContributorSectionInterface } from '../../../../../services/ApiClients/RecordBuilder/graphql/interfaces/contributorSectionsInterface'
import { map as mapContributorSections } from '../../../../../services/ApiClients/RecordBuilder/graphql/schemas/contributorSections'
import { envTest } from "../../../../../components/Environment"
import { recordBuilderResponse } from "../../test-react/testData"

export class NavigationStore {
    rootStore: RootStore
    formMenu: TabInterface[]
    activeMenuIndex: number

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            getSectionByActiveIndex: action,
            getTabKeyByActiveIndex: action,
            setFormMenu: action,
            setActiveMenuIndex: action,

            handleSetMenu: action
        })

        extendObservable(this, navigationStoreInitialState);
    }

    initialize(): void {
        this.handleSetMenu()
    }

    reset() {
        Object.keys(navigationStoreInitialState).forEach(
            (key) => (this[key] = navigationStoreInitialState[key]),
        );
    }

    getRecordBuilderConfig(): ContributorSectionInterface[] {
        const config = envTest
            ? recordBuilderResponse.data[mapContributorSections.dataPath]
            : this.rootStore.optionsStore.recordBuilderConfig

        return config
    }

    getSectionByActiveIndex(): any {
        return this.formMenu[this.activeMenuIndex] || {}
    }

    getTabKeyByActiveIndex(): string {
        return this.formMenu[this.activeMenuIndex]?.key
    }

    setFormMenu(formMenu: TabInterface[]): void {
        this.formMenu = formMenu
    }

    setActiveMenuIndex(activeMenuIndex: number): void {
        this.activeMenuIndex = activeMenuIndex
        this.rootStore.optionsStore.handleSetActiveSectionConfig()

        const activeContributorStore = this.rootStore.getActiveContributorStore()
        activeContributorStore?.handleSetContributorSectionData()
    }

    handleActiveMenuReset(): void {
        this.setActiveMenuIndex(0)
    }

    handleSetMenu(): void {
        const recordBuilderConifig = this.getRecordBuilderConfig()

        if (!_.isEmpty(recordBuilderConifig)) {
            const formMenu = recordBuilderConifig.map((item: ContributorSectionInterface) => {
                return {
                    key: item.code,
                    label: item.displayName,
                }
            })

            this.setFormMenu(formMenu)
        }
    }
}