import _ from "lodash";
import CategoryTags from "../CategoryTags/";

export interface DescriptorGenresInterface {
    value?: string;
    text?: string;
    is_game?: boolean;
}

export function filterGenresViaCategory(
    genres: DescriptorGenresInterface[],
    category: string,
): DescriptorGenresInterface[] {
    if (_.isEmpty(genres)) {
        return [];
    }
    return genres.filter((genre) => {
        return (
            (category === CategoryTags.Game.short && genre.is_game) ||
            (category !== CategoryTags.Game.short && !genre.is_game)
        );
    });
}
