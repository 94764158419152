import React from "react";
import ActionButton from "../ActionButton";
import "./styles/_styles.scss";

export default class UpdateDeleteActionButtons extends React.Component {
    render() {
        const {
            copy = {},
            onClickDelete,
            onClickUpdate,
            onClickEdit,
            style = {},
        } = this.props;

        const margin = `0 0 0 0.59375rem`;

        return (
            <div style={style} className="meta-delete-update-buttons">
                {onClickEdit && (
                    <ActionButton
                        text={copy.edit || "Edit"}
                        background="#8f8f8f"
                        hoverBackground="#8f8f8f"
                        color="#ececec"
                        margin={margin}
                        onClick={onClickEdit}
                    />
                )}

                <ActionButton
                    text={copy.delete || "Delete"}
                    background="#ff0000"
                    hoverBackground="#ff3232"
                    margin={margin}
                    onClick={onClickDelete}
                />

                {onClickUpdate && (
                    <ActionButton
                        text={copy.updateAsset || "Update Asset"}
                        background="#2a8618"
                        hoverBackground="#2a8618"
                        margin={margin}
                        onClick={onClickUpdate}
                    />
                )}
            </div>
        );
    }
}
