const config = [
    {
        id: "1",
        key: "righstdomain",
        displayName: "Righstdomain",
        archived: false,
        order: 1,
        categories: [],
        sections: [
            {
                key: "rights_information",
                displayName: "Rights Information",
                order: 1,
                archived: false,
                options: {
                    hidden: {
                        enabled: false,
                        __typename: "BasicFeatureFlag",
                    },
                    __typename: "RightsSectionOptions",
                },
                fields: [
                    {
                        key: "catalog_id",
                        displayName: "Catalog ID",
                        order: 1,
                        archived: false,
                        options: {
                            editable: {
                                enabled: false,
                                inputType: "Text",
                                __typename: "EditableOption",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                value: null,
                                __typename: "DefaultOption",
                            },
                            hiddenForCategories: [],
                            __typename: "CoreFieldOptions",
                        },
                        __typename: "CoreField",
                    },
                    {
                        key: "licence_start",
                        displayName: "Licence Start",
                        order: 2,
                        archived: false,
                        options: {
                            editable: {
                                enabled: false,
                                inputType: "DateTime",
                                __typename: "EditableOption",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                value: null,
                                __typename: "DefaultOption",
                            },
                            hiddenForCategories: [],
                            __typename: "CoreFieldOptions",
                        },
                        __typename: "CoreField",
                    },
                    {
                        key: "licence_end",
                        displayName: "Licence End",
                        order: 3,
                        archived: false,
                        options: {
                            editable: {
                                enabled: false,
                                inputType: "DateTime",
                                __typename: "EditableOption",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                value: null,
                                __typename: "DefaultOption",
                            },
                            hiddenForCategories: [],
                            __typename: "CoreFieldOptions",
                        },
                        __typename: "CoreField",
                    },
                    {
                        key: "exclusivity",
                        displayName: "Exclusivity",
                        order: 4,
                        archived: false,
                        options: {
                            editable: {
                                enabled: false,
                                inputType: "Select",
                                __typename: "EditableOption",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                value: null,
                                __typename: "DefaultOption",
                            },
                            hiddenForCategories: [],
                            __typename: "CoreFieldOptions",
                        },
                        __typename: "CoreField",
                    },
                    {
                        key: "media",
                        displayName: "Media",
                        order: 5,
                        archived: false,
                        options: {
                            editable: {
                                enabled: true,
                                inputType: "SelectMultiple",
                                __typename: "EditableOption",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                value: null,
                                __typename: "DefaultOption",
                            },
                            hiddenForCategories: [],
                            __typename: "CoreFieldOptions",
                        },
                        __typename: "CoreField",
                    },
                    {
                        key: "platform",
                        displayName: "Platform",
                        order: 6,
                        archived: false,
                        options: {
                            editable: {
                                enabled: false,
                                inputType: "Select",
                                __typename: "EditableOption",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                value: null,
                                __typename: "DefaultOption",
                            },
                            hiddenForCategories: [],
                            __typename: "CoreFieldOptions",
                        },
                        __typename: "CoreField",
                    },
                ],
                __typename: "RightsSection",
            },
            {
                key: "rights_conditions",
                displayName: "Rights Conditions",
                order: 2,
                archived: false,
                options: {
                    hidden: {
                        enabled: false,
                        __typename: "BasicFeatureFlag",
                    },
                    __typename: "RightsSectionOptions",
                },
                fields: [
                    {
                        key: "territory",
                        displayName: "Territory",
                        order: 1,
                        archived: false,
                        options: {
                            editable: {
                                enabled: false,
                                inputType: "SelectMultiple",
                                __typename: "EditableOption",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                value: null,
                                __typename: "DefaultOption",
                            },
                            hiddenForCategories: [],
                            __typename: "CoreFieldOptions",
                        },
                        __typename: "CoreField",
                    },
                    {
                        key: "language",
                        displayName: "Language",
                        order: 2,
                        archived: false,
                        options: {
                            editable: {
                                enabled: false,
                                inputType: "SelectMultiple",
                                __typename: "EditableOption",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                value: null,
                                __typename: "DefaultOption",
                            },
                            hiddenForCategories: [],
                            __typename: "CoreFieldOptions",
                        },
                        __typename: "CoreField",
                    },
                    {
                        key: "simulcast",
                        displayName: "Simulcast",
                        order: 3,
                        archived: false,
                        options: {
                            editable: {
                                enabled: false,
                                inputType: "SelectMultiple",
                                __typename: "EditableOption",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                value: null,
                                __typename: "DefaultOption",
                            },
                            hiddenForCategories: [],
                            __typename: "CoreFieldOptions",
                        },
                        __typename: "CoreField",
                    },
                    {
                        key: "simulcast_expectations",
                        displayName: "Simulcast Expectations",
                        order: 4,
                        archived: false,
                        options: {
                            editable: {
                                enabled: false,
                                inputType: "Text",
                                __typename: "EditableOption",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                value: null,
                                __typename: "DefaultOption",
                            },
                            hiddenForCategories: [],
                            __typename: "CoreFieldOptions",
                        },
                        __typename: "CoreField",
                    },
                    {
                        key: "ad_requirements",
                        displayName: "Ad Requirements",
                        order: 5,
                        archived: false,
                        options: {
                            editable: {
                                enabled: false,
                                inputType: "Text",
                                __typename: "EditableOption",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                value: null,
                                __typename: "DefaultOption",
                            },
                            hiddenForCategories: [],
                            __typename: "CoreFieldOptions",
                        },
                        __typename: "CoreField",
                    },
                    {
                        key: "number_of_ad_breaks_required",
                        displayName: "# Ad Breaks Required",
                        order: 6,
                        archived: false,
                        options: {
                            editable: {
                                enabled: false,
                                inputType: "Text",
                                __typename: "EditableOption",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                value: null,
                                __typename: "DefaultOption",
                            },
                            hiddenForCategories: [],
                            __typename: "CoreFieldOptions",
                        },
                        __typename: "CoreField",
                    },
                    {
                        key: "exhibition_per_episode",
                        displayName: "Exhibition per Episode",
                        order: 7,
                        archived: false,
                        options: {
                            editable: {
                                enabled: false,
                                inputType: "Select",
                                __typename: "EditableOption",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                value: null,
                                __typename: "DefaultOption",
                            },
                            hiddenForCategories: [],
                            __typename: "CoreFieldOptions",
                        },
                        __typename: "CoreField",
                    },
                    {
                        key: "exhibitions_per_episode_limit",
                        displayName: "Exhibitions per Episode Limit",
                        order: 8,
                        archived: false,
                        options: {
                            editable: {
                                enabled: false,
                                inputType: "Text",
                                __typename: "EditableOption",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                value: null,
                                __typename: "DefaultOption",
                            },
                            hiddenForCategories: [],
                            __typename: "CoreFieldOptions",
                        },
                        __typename: "CoreField",
                    },
                    {
                        key: "exhibition_per_episode_notes",
                        displayName: "Exhibition per Episode Notes",
                        order: 9,
                        archived: false,
                        options: {
                            editable: {
                                enabled: false,
                                inputType: "Select",
                                __typename: "EditableOption",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                value: null,
                                __typename: "DefaultOption",
                            },
                            hiddenForCategories: [],
                            __typename: "CoreFieldOptions",
                        },
                        __typename: "CoreField",
                    },
                    {
                        key: "avod_rights",
                        displayName: "AVOD Rights",
                        order: 10,
                        archived: false,
                        options: {
                            editable: {
                                enabled: false,
                                inputType: "Select",
                                __typename: "EditableOption",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                value: null,
                                __typename: "DefaultOption",
                            },
                            hiddenForCategories: [],
                            __typename: "CoreFieldOptions",
                        },
                        __typename: "CoreField",
                    },
                    {
                        key: "svod_rights",
                        displayName: "SVOD Rights",
                        order: 11,
                        archived: false,
                        options: {
                            editable: {
                                enabled: false,
                                inputType: "Select",
                                __typename: "EditableOption",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                value: null,
                                __typename: "DefaultOption",
                            },
                            hiddenForCategories: [],
                            __typename: "CoreFieldOptions",
                        },
                        __typename: "CoreField",
                    },
                    {
                        key: "limited_number_of_svod_platforms",
                        displayName: "Limited # SVOD Platforms",
                        order: 12,
                        archived: false,
                        options: {
                            editable: {
                                enabled: false,
                                inputType: "Text",
                                __typename: "EditableOption",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                value: null,
                                __typename: "DefaultOption",
                            },
                            hiddenForCategories: [],
                            __typename: "CoreFieldOptions",
                        },
                        __typename: "CoreField",
                    },
                    {
                        key: "approved_svod_platforms",
                        displayName: "Approved SVOD Platforms",
                        order: 13,
                        archived: false,
                        options: {
                            editable: {
                                enabled: false,
                                inputType: "Select",
                                __typename: "EditableOption",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                value: null,
                                __typename: "DefaultOption",
                            },
                            hiddenForCategories: [],
                            __typename: "CoreFieldOptions",
                        },
                        __typename: "CoreField",
                    },
                    {
                        key: "merchandising _restrictions",
                        displayName: "Merchandising Restrictions",
                        order: 14,
                        archived: false,
                        options: {
                            editable: {
                                enabled: false,
                                inputType: "Text",
                                __typename: "EditableOption",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                value: null,
                                __typename: "DefaultOption",
                            },
                            hiddenForCategories: [],
                            __typename: "CoreFieldOptions",
                        },
                        __typename: "CoreField",
                    },
                    {
                        key: "ancillary_restrictions_notes",
                        displayName: "Ancillary Restrictions Notes",
                        order: 15,
                        archived: false,
                        options: {
                            editable: {
                                enabled: false,
                                inputType: "Text",
                                __typename: "EditableOption",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                value: null,
                                __typename: "DefaultOption",
                            },
                            hiddenForCategories: [],
                            __typename: "CoreFieldOptions",
                        },
                        __typename: "CoreField",
                    },
                ],
                __typename: "RightsSection",
            },
        ],
        __typename: "RightsConfig",
    },
    {
        id: "1",
        key: "righstdomainTwo",
        displayName: "RighstdomainTwo",
        archived: false,
        order: 1,
        categories: [],
        sections: [
            {
                key: "rights_information",
                displayName: "Rights Information",
                order: 1,
                categories: [],
                archived: false,
                options: {
                    hidden: {
                        enabled: false,
                        __typename: "BasicFeatureFlag",
                    },
                    __typename: "RightsSectionOptions",
                },
                fields: [
                    {
                        key: "rights_information",
                        displayName: "Rights Information",
                        key: "catalog_id",
                        displayName: "Catalog ID",
                        order: 1,
                        archived: false,
                        options: {
                            editable: {
                                enabled: false,
                                inputType: "Text",
                                __typename: "EditableOption",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                value: null,
                                __typename: "DefaultOption",
                            },
                            hiddenForCategories: [],
                            __typename: "CoreFieldOptions",
                        },
                        __typename: "CoreField",
                    },
                    {
                        key: "license_start",
                        displayName: "License Start",
                        order: 2,
                        archived: false,
                        options: {
                            editable: {
                                enabled: false,
                                inputType: "DateTime",
                                __typename: "EditableOption",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            __typename: "RightsSectionOptions",
                            default: {
                                enabled: false,
                                value: null,
                                __typename: "DefaultOption",
                            },
                            hiddenForCategories: [],
                            __typename: "CoreFieldOptions",
                        },
                        __typename: "CoreField",
                    },
                    {
                        key: "license_end",
                        displayName: "License End",
                        order: 3,
                        archived: false,
                        options: {
                            editable: {
                                enabled: false,
                                inputType: "DateTime",
                                __typename: "EditableOption",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                value: null,
                                __typename: "DefaultOption",
                            },
                            hiddenForCategories: [],
                            __typename: "CoreFieldOptions",
                        },
                        __typename: "CoreField",
                    },
                    {
                        key: "exclusivity",
                        displayName: "Exclusivity",
                        order: 4,
                        archived: false,
                        options: {
                            editable: {
                                enabled: false,
                                inputType: "Select",
                                __typename: "EditableOption",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                value: null,
                                __typename: "DefaultOption",
                            },
                            hiddenForCategories: [],
                            __typename: "CoreFieldOptions",
                        },
                        __typename: "CoreField",
                    },
                    {
                        key: "media",
                        displayName: "Media",
                        order: 5,
                        archived: false,
                        options: {
                            editable: {
                                enabled: true,
                                inputType: "SelectMultiple",
                                __typename: "EditableOption",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                value: null,
                                __typename: "DefaultOption",
                            },
                            hiddenForCategories: [],
                            __typename: "CoreFieldOptions",
                        },
                        __typename: "CoreField",
                    },
                    {
                        key: "platform",
                        displayName: "Platform",
                        order: 6,
                        archived: false,
                        options: {
                            editable: {
                                enabled: false,
                                inputType: "Select",
                                __typename: "EditableOption",
                            },
                            hidden: {
                                enabled: false,
                                __typename: "BasicFeatureFlag",
                            },
                            default: {
                                enabled: false,
                                value: null,
                                __typename: "DefaultOption",
                            },
                            hiddenForCategories: [],
                            __typename: "CoreFieldOptions",
                        },
                        fields: [
                            {
                                key: "catalog_id",
                                displayName: "Catalog ID",
                                order: 1,
                                archived: false,
                                options: {
                                    editable: {
                                        enabled: false,
                                        inputType: "Text",
                                        __typename: "EditableOption",
                                    },
                                    hidden: {
                                        enabled: false,
                                        __typename: "BasicFeatureFlag",
                                    },
                                    default: {
                                        enabled: false,
                                        value: null,
                                        __typename: "DefaultOption",
                                    },
                                    hiddenForCategories: [],
                                    __typename: "CoreFieldOptions",
                                },
                                __typename: "CoreField",
                            },
                            {
                                key: "license_start",
                                displayName: "License Start",
                                order: 2,
                                archived: false,
                                options: {
                                    editable: {
                                        enabled: false,
                                        inputType: "DateTime",
                                        __typename: "EditableOption",
                                    },
                                    hidden: {
                                        enabled: false,
                                        __typename: "BasicFeatureFlag",
                                    },
                                    default: {
                                        enabled: false,
                                        value: null,
                                        __typename: "DefaultOption",
                                    },
                                    hiddenForCategories: [],
                                    __typename: "CoreFieldOptions",
                                },
                                __typename: "CoreField",
                            },
                            {
                                key: "license_end",
                                displayName: "License End",
                                order: 3,
                                archived: false,
                                options: {
                                    editable: {
                                        enabled: false,
                                        inputType: "DateTime",
                                        __typename: "EditableOption",
                                    },
                                    hidden: {
                                        enabled: false,
                                        __typename: "BasicFeatureFlag",
                                    },
                                    default: {
                                        enabled: false,
                                        value: null,
                                        __typename: "DefaultOption",
                                    },
                                    hiddenForCategories: [],
                                    __typename: "CoreFieldOptions",
                                },
                                __typename: "CoreField",
                            },
                            {
                                key: "exclusivity",
                                displayName: "Exclusivity",
                                order: 4,
                                archived: false,
                                options: {
                                    editable: {
                                        enabled: false,
                                        inputType: "Select",
                                        __typename: "EditableOption",
                                    },
                                    hidden: {
                                        enabled: false,
                                        __typename: "BasicFeatureFlag",
                                    },
                                    default: {
                                        enabled: false,
                                        value: null,
                                        __typename: "DefaultOption",
                                    },
                                    hiddenForCategories: [],
                                    __typename: "CoreFieldOptions",
                                },
                                __typename: "CoreField",
                            },
                            {
                                key: "media",
                                displayName: "Media",
                                order: 5,
                                archived: false,
                                options: {
                                    editable: {
                                        enabled: true,
                                        inputType: "SelectMultiple",
                                        __typename: "EditableOption",
                                    },
                                    hidden: {
                                        enabled: false,
                                        __typename: "BasicFeatureFlag",
                                    },
                                    default: {
                                        enabled: false,
                                        value: null,
                                        __typename: "DefaultOption",
                                    },
                                    hiddenForCategories: [],
                                    __typename: "CoreFieldOptions",
                                },
                                __typename: "CoreField",
                            },
                            {
                                key: "platform",
                                displayName: "Platform",
                                order: 6,
                                archived: false,
                                options: {
                                    editable: {
                                        enabled: false,
                                        inputType: "Select",
                                        __typename: "EditableOption",
                                    },
                                    hidden: {
                                        enabled: false,
                                        __typename: "BasicFeatureFlag",
                                    },
                                    default: {
                                        enabled: false,
                                        value: null,
                                        __typename: "DefaultOption",
                                    },
                                    hiddenForCategories: [],
                                    __typename: "CoreFieldOptions",
                                },
                                __typename: "CoreField",
                            },
                        ],
                        __typename: "RightsSection",
                        __typename: "CoreField",
                    },
                ],
                __typename: "RightsConfig",
                __typename: "RightsSection",
            },
        ],
        __typename: "RightsConfig",
    },
]

export default config;
