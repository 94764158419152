import { gql } from "@apollo/client";
import { FieldMapInterface } from "../../interfaces";

const properties = `
    id
    key
    displayName
    archived
    order
    categories
    sections {
        key
        displayName
        order
        archived
        options {
            hidden {
                enabled
            }
        }
        fields {
            key
            displayName
            order
            archived
            options {
                editable {
                    enabled
                    inputType
                }
                hidden {
                    enabled
                }
                default {
                    enabled
                    value
                }
                hiddenForCategories
            }
        }
    }
`;

export const schemaGetAll = `
    getRightsConfigs {
        ${properties}
    }
`

export const queryGetAll = gql`
    query {
        ${schemaGetAll}
    }
`;

const map: FieldMapInterface = {
    queryAll: queryGetAll,
    dataPath: "getRightsConfigs",
}

export default map
