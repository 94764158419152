import { ApolloClient, InMemoryCache } from "@apollo/client";

export const RecordBuilderApolloClient = new ApolloClient({
    uri: process.env.RECORD_BUILDER_URL,
    cache: new InMemoryCache({
        addTypename: false
    }),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'network-only',
        },
    },
});