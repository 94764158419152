import { gql } from "@apollo/client";
import { FieldMapInterface } from "../../interfaces";

export const properties = `
    id
    key
    system
    displayName
    order
    archived
    sections {
        key
        displayName
        order
        archived
        options {
            hidden {
                enabled
            }
        }
        fields {
            key
            displayName
            order
            dataType
            options {
                editable {
                    enabled
                    inputType
                    minLength
                    maxLength
                    booleanDisplay {
                        type
                        trueLabel
                        falseLabel
                    }
                    optionsManagedList {
                        enabled
                        key
                        textField
                        valueField
                        minimumSelectLimit
                        maximumSelectLimit
                        dynamic
                    }
                }
                hidden {
                    enabled
                }
                default {
                    enabled
                    value
                }
                hiddenForCategories
            }
        }
    }
`;

export const schemaGetAll = `
    getSchedulingPlatformConfigs {
        ${properties}   
    }
`

export const queryGetAll = gql`
    query {
        ${schemaGetAll}
    }
`;

const map: FieldMapInterface = {
    queryAll: queryGetAll,
    dataPath: "getSchedulingPlatformConfigs",
}

export default map