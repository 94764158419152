import React, { useEffect, useState } from "react";
import { useStore } from "../../../components/Global/store/store";
import { CmsInterface, UserInterface } from "../../../components/Global/interfaces";
import SendRequest from "../../../components/SendRequest";
import { SendRequestResponseInterface } from "../../../components/SendRequest/interface";
import { envTest } from "../../../components/Environment";
import Alert from "@mui/material/Alert";
import "./styles/_styles.scss";
import { getInterval, message, messageLogOutTimer } from "./config";
import FadeIn from "../../../components/TransitionGroup/FadeIn";

interface PropsInterface {
    interval?: number;
}

let workerInterval: ReturnType<typeof setInterval>;

export default function RedirectLoggedOutUser(
    props: PropsInterface,
): false | JSX.Element {
    const interval: number = Number.isInteger(props.interval)
        ? props.interval
        : parseInt(process.env.LOGOUT_OTHER_DEVICES_INTERVAL);
    const intervalTimer: number = getInterval(interval);

    const [state] = useStore();
    const cmsData: CmsInterface = state.cmsData || {};
    const clientFeatures: any = cmsData.clientFeatures || {};
    const logOutUserFromMultipleDevices: boolean =
        clientFeatures.logOutUserFromMultipleDevices;

    const [displayMessage, setDisplayMessage] = useState<boolean>(false);

    function work(): void {
        const apiUrls: any = cmsData.apiUrls || {};
        const user: UserInterface = cmsData.user;
        const logoutOtherDevices: string = apiUrls.logoutOtherDevices || "";
        const logout: string = apiUrls.logout || "";

        if ((user && user.api_token) || envTest) {
            const options = {
                api_token: user.api_token,
                url: logoutOtherDevices,
                method: "post",
                noXApiKey: true,
                testingData: {
                    status: 200,
                    data: true,
                },
            };

            SendRequest(
                options,
                (response: SendRequestResponseInterface) => {
                    const { status, data } = response;

                    if (envTest) {
                        setDisplayMessage(true);
                        process.REDIRECTED = true;
                        clearInterval(workerInterval);

                        return;
                    }

                    if (status == 200 && data === true && logout) {
                        clearInterval(workerInterval);
                        setDisplayMessage(true);

                        setTimeout(() => {
                            window.location.href = logout;
                        }, messageLogOutTimer);
                    }
                },
                (e: SendRequestResponseInterface) => {
                    console.log(e);
                },
            );
        }
    }

    if (logOutUserFromMultipleDevices) {
        useEffect(() => {
            workerInterval = setInterval(() => {
                work();
            }, intervalTimer);

            return () => clearInterval(workerInterval);
        }, []);
    }

    if (!displayMessage) {
        return false;
    }

    return (
        <FadeIn>
            <div className="redirect-logged-out-user">
                <Alert severity="error">{message}</Alert>
            </div>
        </FadeIn>
    );
}
