import FieldInterface from '../interfaces/Field.interface'
import { CoreSelectOptionType } from "../../components/inputs/Select/types/CoreSelectOptionType"
import { isEmpty } from 'lodash'
import { listOptionText, optionValue } from './OptionTransformer'

export const transformList = ({ options, field }: { options: { [key: string]: string }[], field: FieldInterface }): CoreSelectOptionType[] => {
    if (!isEmpty(options)) {
        let res =  options.map((option) => {
            return {
                ...option,
                label: listOptionText({ field, option }),
                value: optionValue({ field, option, useList: true })
            }
        })

        return res
    }

    return []
}