import React from "react";
import { IconButton, SvgIcon } from "@material-ui/core";
import downloadFile from "../File/downloadFile"
import { styled } from "@mui/material/styles"

type Props = {
    href: string;
    fileName: string
};

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    "&.download-button": {
        borderRadius: "0.25rem",
        marginLeft: "auto",
        padding: "0.25rem",
        "&:hover": {
            "& path": {
                fillOpacity: 1,
            },
        },
    }
}));

const DownloadImageButton: React.FC = ({ href, fileName }: Props) => (
    <StyledIconButton
        aria-label="download"
        onClick={(event: React.SyntheticEvent) => downloadFile(event, href, fileName)}
        download
        className="download-button">
        <SvgIcon 
            viewBox="0 0 14 17"
            style={{
                fontSize: "1rem"
            }}>
            <path
                d="M11.59 6.5H10V1.5C10 0.95 9.55 0.5 9 0.5H5C4.45 0.5 4 0.95 4 1.5V6.5H2.41C1.52 6.5 1.07 7.58 1.7 8.21L6.29 12.8C6.68 13.19 7.31 13.19 7.7 12.8L12.29 8.21C12.92 7.58 12.48 6.5 11.59 6.5ZM0 16.5C0 17.05 0.45 17.5 1 17.5H13C13.55 17.5 14 17.05 14 16.5C14 15.95 13.55 15.5 13 15.5H1C0.45 15.5 0 15.95 0 16.5Z"
                fill="#4D4D4D"
                fillOpacity="0.7"
            />
        </SvgIcon>
    </StyledIconButton>
);

export default DownloadImageButton;
