import React from "react";
import CubeShape from "../../../../../CubeShape";
import { SUBTITLE_SECTION, AUDIO_SECTION } from "../section/AdditionalSection";
import { useStore } from "../../../state/context";
import AssetTypeInterface from "../interfaces/AssetTypeInterface";
import { ASSET_TYPES_CONFIG } from "../../../configuration";
import GetAssetTypeConfiguration from "../../../helpers/GetAssetTypeConfig";
import _ from "lodash";

const HeaderCube: React.FC = (props) => {
    const { hierarchyLevel = 0, isChildExists = false, asset } = props;

    const [store] = useStore();

    const { type } = asset;

    const { configuration } = store;

    const assetTypeConfig: AssetTypeInterface | undefined =
        GetAssetTypeConfiguration(configuration, asset);
    const colorOption = _.get(assetTypeConfig, "options.color") || {};

    const { enabled = false, code = "" } = colorOption;

    let cubeColorProperty = hierarchyLevel > 0 ? "#006ACC" : "#4DA9FF";

    if (type == SUBTITLE_SECTION) {
        cubeColorProperty = "#1FBC8A";
    }

    if (type == AUDIO_SECTION) {
        cubeColorProperty = "#7B61FF";
    }

    if (enabled && code) {
        cubeColorProperty = code;
    }

    return (
        <div className="header-cube">
            <CubeShape color={cubeColorProperty} />
            {isChildExists && (
                <div className="vertical-line-container">
                    <div className="vertical-line"></div>
                </div>
            )}
        </div>
    );
};

export default HeaderCube;
