import _ from 'lodash'
import deepcopy from "deepcopy";
import { ValidationResult } from "./interfaces/Validation";
import DisabledRuleActive from "./rules/disabled";

export default function ValidateProvenance(
    result: ValidationResult,
    options: any,
): ValidationResult {
    let response: ValidationResult = deepcopy(result);

    const { data = {}, status, clientDataStructure = {} } = options;

    const provenanceValidation =
        clientDataStructure?.fieldValidation?.origination?.provenance;

    const {
        origination = {},
        category = false,
        status: recordStatus = ''
    } = data;

    const { type } = origination;

    if (
        DisabledRuleActive({
            recordStatus,
            category,
            type,
            dataStructure: clientDataStructure,
            fieldPath: "origination.provenance",
        })
    ) {
        return response;
    }

    if ((!origination || _.isEmpty(origination.provenance)) && provenanceValidation) {
        response.result = false;
        response.notification = {
            title: "Please check provenance",
            description: "Provenance is missing",
            status,
        };

        response.validationKeys = result.validationKeys.concat(["provenance"]);
    }

    return response;
}
