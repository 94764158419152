import React from "react";
import _ from "lodash";
import styles from "../../components/RecordBody/styles";
import Index from "../../components/RecordBody/views/Index";
import { diff } from "deep-object-diff";
import { useStore as globalStore } from "../../components/Global/store/store";
import AwardsComponent from "../Awards/v1";
import AwardsV2 from "../Awards/v2";
import { getDefaultLanguage, getDefaultWrittenLanguageCodeFromCache } from "../../models/ClientManaged/languagesModel";

export default class Awards extends Index {
    constructor(props) {
        super(props);

        let data = [];
        if (Array.isArray(this.props.data)) {
            data = this.props.data;
        }

        /**
         * @var object
         */
        this.styles = styles(this.props);

        /**
         * @var object
         */
        this.state = {
            data,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        const nextPropsData = nextProps.data || {};
        const thisPropsData = this.props.data || {};
        const difference = diff(nextPropsData, thisPropsData);

        if (!_.isEmpty(difference)) {
            return true;
        }

        if (!_.isEqual(nextProps.disabled, this.props.disabled)) {
            return true;
        }

        if (!_.isEqual(nextProps.options, this.props.options)) {
            return true;
        }

        if (!_.isEqual(nextProps.script, this.props.script)) {
            return true;
        }

        if (!_.isEqual(nextState, this.state)) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (Array.isArray(this.props.data)) {
            this.setData(this.props.data);
        }
    }

    handleChange(value) {
        if (typeof this.props.handleFormChange == "function") {
            this.props.handleFormChange(value);
        }
    }

    renderNoneAdded() {
        return false;
    }

    renderContent() {
        const { cmsData = {}, options = {} } = this.props;

        const { disabled, allowCreateNew } = this.props;

        const version = cmsData?.clientFeatures?.awardsVersion;

        const data = Object.assign([], this.state.data);

        const defaultScript = getDefaultWrittenLanguageCodeFromCache()

        return (
            <div style={this.styles.marginItem}>
                {version === "v2" ? (
                    <AwardsV2
                        {...this.props}
                        data={data}
                        allowCreateNew={allowCreateNew}
                        handleChange={this.handleChange.bind(this)}
                        defaultScript={defaultScript}
                    />
                ) : (
                    <AwardsComponent
                        {...this.props}
                        data={data}
                        allowCreateNew={allowCreateNew}
                        handleChange={this.handleChange.bind(this)}
                        defaultScriptCode={defaultScript}
                    />
                )}
            </div>
        );
    }
}
