import _ from "lodash";
import NumberPad from "../../../components/Helpers/NumberPad";
import CategoryTags from "../../../components/CategoryTags";

const EpisodeLeftText = (props) => {
    const { episode = {} } = props;

    let { season_number, episode_number, storyline_number, production_number } =
        episode;

    let result = "";
    let prefix = "EP";

    if (episode.category == CategoryTags.Storyline.short) {
        prefix = "SL";
    }

    if (episode && season_number) {
        result = `S${NumberPad(season_number, 3)}`;
    }

    if (result !== "") {
        result = result + " • ";
    }

    if (episode && (episode_number || storyline_number)) {
        const episodeNumber = episode_number || storyline_number;

        result = result + `${prefix}${NumberPad(episodeNumber, 3)}`;

        if (production_number) {
            result = result + " • ";
        }
    }

    if (production_number) {
        result = result + "PN" + production_number;
    }

    return result;
};

export default EpisodeLeftText;
