/* eslint-disable @typescript-eslint/no-unused-vars */
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import React, { useMemo } from "react";
import { isEmpty } from "lodash"
import { CoreTableCell } from "../../../../../core/Table/components/CoreTableCell";
import { Chip } from "@mui/material";
import colors from "../utils/RoleColors"
import { useStore } from "../../../../../components/Global/store/store";

const RenderCell = (params: GridRenderCellParams<string>) => {
    const [globalState] = useStore() || [{}];

    const elem = useMemo(
        () => {
            const {
                value: roles = []
            } = params;

            if (!globalState.cmsData?.clientFeatures?.contributorsPreferencesRole || isEmpty(roles)) {
                return (
                    <CoreTableCell renderChildren={true}>
                        <Chip 
                            label="None Added"
                            sx={{
                                color: 'rgba(73, 73, 73, 1)',
                                backgroundColor: '#DEDEDE',
                            }}
                        />
                    </CoreTableCell>
                )
            }

            return (
                <CoreTableCell renderChildren={true}>
                    {roles.map((role) => {
                        const { color = "#000", background = "#ccc" } = colors[role.replace(/ /g, '_').toLowerCase()] || {};
                        return (
                            <Chip 
                                label={role} 
                                sx={{
                                    color: color,
                                    backgroundColor: background,
                                }}
                            />
                        )
                    })}
                </CoreTableCell>
            )
        },
        [params?.value],
    );
    return elem;
};

export const RoleCol = (minWidth = 160) => ({
    headerName: "Role",
    field: "roles",
    flex: 1,
    minWidth: minWidth,
    maxWidth: minWidth,
    renderCell: (params: GridRenderCellParams<string>) => {
        return <RenderCell {...params} />;
    },
});

