import React from "react";
import { DataGridProps } from "@mui/x-data-grid";
import { CoreTable } from "../../../../core/Table/CoreTable";
import { CoreTableConfig, CoreTableGridColDef } from "../../../../core/Table/types";
import { FilmographyTableRow } from "./types/Contributor.type";
import _ from "lodash";
import { FilmographyRecordCol } from "./columns/FilmographyRecord";
import { RoleCol } from "./columns/Role.column";

export type FilmographyTableProps = {
    filmography: FilmographyTableRow[]
} & Pick<DataGridProps, "loading">;

export const FilmographyTable = ({
    filmography,
    loading
}: FilmographyTableProps) => {
    const rows: FilmographyTableRow[] = filmography

    const columns: CoreTableGridColDef[] = [
        FilmographyRecordCol,
        RoleCol(250),
    ]

    //@ts-ignore
    const config: CoreTableConfig = {
        variant: "default",
        state: "default"
    };

    return (
        <CoreTable<FilmographyTableRow>
            sortingOrder={["asc", "desc"]}
            config={config}
            columns={columns}
            rows={rows}
            hideFooterRowCount
            hideFooterSelectedRowCount
            loading={loading}         
        />
    );
};
