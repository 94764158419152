import { GridValueFormatterParams } from "@mui/x-data-grid-pro";
import _ from "lodash";
import { CoreTableGridColDef } from "../../types";
import { CoreTableCell } from "../../components/CoreTableCell";

export const FirstReleaseYearCol: CoreTableGridColDef = {
    field: "first_release_year",
    headerName: "First Release Year",
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams<number>) => {
        <CoreTableCell>{params?.value || ' - '}</CoreTableCell>
    },
};
