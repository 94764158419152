export const coreSelectDisabledStyles: React.CSSProperties = {
    fontSize: "0.90625rem",
    fontWeight: 500,
    textTransform: "capitalize",
    color: "rgba(110, 110, 110, 0.87)",
    display: "block",
    marginTop: "0.8rem",
    opacity: 0.6
}

export const coreSelectActiveStyles: React.CSSProperties = {
    fontSize: "0.90625rem",
    fontWeight: 500,
    textTransform: "capitalize",
    color: "rgba(110, 110, 110, 0.87)",
    display: "block",
    marginTop: "0.8rem",
}

export const menuCustomStyles = {
    'disabled': {
        opacity: 0.9
    },
    'active': {
        ".MuiOutlinedInput-notchedOutline": {
            borderColor:
                "rgba(77, 77, 77, 0.2) !important",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor:
                    "rgb(40, 108, 210, .75) !important",
        }
    }
}

export const menuCustomDisabledStyles: React.CSSProperties = {
    opacity: 0.9
}

export const menuCustomActiveStyles = {
    ".MuiOutlinedInput-notchedOutline": {
        borderColor:
            "rgba(77, 77, 77, 0.2) !important",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor:
                "rgb(40, 108, 210, .75) !important",
    }
}