import Prefixer from "inline-style-prefixer";

export default function styles(props = {}) {
    let styles = {
        checkbox: {
            margin: props.margin || "0 0 0 auto",
        },
    };

    let prefixer = new Prefixer();
    styles = prefixer.prefix(styles);

    return styles;
}
