import { action, extendObservable, makeObservable } from "mobx";
import { TicketAttachment } from "../containers/ticket/attachments/types";
import TaskManagerStoreInterface from "./TaskManagerStoreInterface";
import { RootStore } from "./rootStore";
import { StandaloneTicket } from "../containers/ticket/shared/validationSchema";
import { downloadBlob } from "../utils/downloadHelper";

export type InitialStateType = {
    attachments: TicketAttachment[];
    loading: boolean;
    selectedAttachmentIds?: TicketAttachment["id"][];
};

const initialState: InitialStateType = {
    attachments: [],
    loading: false,
    selectedAttachmentIds: [],
};

class TicketAttachmentsStore implements TaskManagerStoreInterface {
    rootStore: RootStore;

    attachments: InitialStateType["attachments"];

    loading: InitialStateType["loading"];

    selectedAttachmentIds: InitialStateType["selectedAttachmentIds"];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            setAttachments: action,
            setLoading: action,
            setSelectedAttachmentIds: action,
        });

        extendObservable(this, initialState);
    }

    async initialize(...args: any[]) {}

    setAttachments(attachments: TicketAttachment[]) {
        this.attachments = Array.from(attachments);
    }

    setLoading(loading: boolean) {
        this.loading = loading;
    }

    setSelectedAttachmentIds(selectedAttachmentIds: TicketAttachment["id"][]) {
        this.selectedAttachmentIds = Array.from(selectedAttachmentIds);
    }

    async getByTicketId(ticketId: number) {
        if (!ticketId) {
            return;
        }

        const response = await this.rootStore.taskManagerApi.getAttachments(
            ticketId,
        );

        if (response.isError) {
            this.rootStore.handleApiError(response);
        } else {
            this.setAttachments(response.data);
        }
    }

    getById(id: TicketAttachment["id"]) {
        return this.attachments.find((attachment) => attachment.id === id);
    }

    async delete(
        ticketId: StandaloneTicket["id"],
        attachmentIds: TicketAttachment["id"][],
    ) {
        if (!ticketId || !attachmentIds) {
            return;
        }

        const response = await this.rootStore.taskManagerApi.deleteAttachments(
            ticketId,
            attachmentIds,
        );

        if (response.isError) {
            this.rootStore.handleApiError(response);
        } else {
            this.rootStore.pushSnackbar(response.message);

            await this.getByTicketId(ticketId);
        }
    }

    async upload(ticketId: StandaloneTicket["id"], files: FileList) {
        if (!ticketId) {
            return;
        }

        const response = await this.rootStore.taskManagerApi.uploadAttachments(
            ticketId,
            files,
        );

        if (response.isError) {
            this.rootStore.handleApiError(response);
        } else {
            this.rootStore.pushSnackbar(response.message);
        }
    }

    async download(
        ticketId: StandaloneTicket["id"],
        attachmentIds: TicketAttachment["id"][],
    ) {
        if (!ticketId || !attachmentIds) {
            return;
        }

        const blobResponse =
            await this.rootStore.taskManagerApi.downloadAttachments(
                ticketId,
                attachmentIds,
            );

        downloadBlob(
            blobResponse.data,
            blobResponse.mimeType,
            blobResponse.name,
        );
    }
}

export default TicketAttachmentsStore;
