import Clients from "../index";
import EpixIdentifiersViewConfig from "./identifiersViewConfigs/EpixConfig";
import FoxIdentifiersViewConfig from "./identifiersViewConfigs/FoxConfig";
import EpixCreateRecordConfig from "./createRecordConfig/EpixConfig";
import MGMCreateRecordConfig from "./createRecordConfig/MGMConfig";
import EpixTemplateTabsConfig from "./templateTabsConfigs/EpixConfig";

class Config {
    getTemplateTabsConfig() {
        let result = {};

        if (Clients.isClientMGM() || Clients.isClientEpix()) {
            result = EpixTemplateTabsConfig;
        }

        return result;
    }

    getIdentifiersViewConfig() {
        let result = {};

        switch (this.getClient()) {
            case "EPIX":
                result = EpixIdentifiersViewConfig;
                break;
            case "Fox":
                result = FoxIdentifiersViewConfig;
                break;
        }

        return result;
    }

    getCreateRecordConfig() {
        let result = {};

        switch (this.getClient()) {
            case "EPIX":
                result = EpixCreateRecordConfig;
                break;
            case "MGM":
                result = MGMCreateRecordConfig;
                break;
        }

        return result;
    }

    getClient() {
        let clientsList = [
            "EPIX",
            "MGM",
            "Demo",
            "GlobalEagle",
            "WarnerMedia",
            "Fox",
        ];
        let client = null;
        clientsList.map((value) => {
            if (Clients.isClient(value)) {
                client = value;
            }
        });

        return client;
    }
}

const instance = new Config();

export { instance as default };
