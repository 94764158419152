const PhilomenaSony = [
    {
        meta_id: "AS00012564",
        asset_type: "Original Master",
        compliance_code: "ORIG",
        system_type: "Asset Management System",
        system_name: "Sony Media Backbone",
        system_environment: "Development",
        status: "placeholder created",
        eidr: "10.5240/63B8-F4DD-D11B-B584-3E2D-S",
        audio: "ENG",
        video: "ENG",
        duration: "00.01.38.08",
        created_at: "05/07/2020 10:12:00",
        asset_id: "FF00029837",
        emea_id: "FE_883726133",
        latam_id: "002136544A/3883",
        apac_id: "MOV136544A/3883001",
        filename: "Philomena_INT-Linear-Edit_EN.mov",
        meta_users: [],
        image_width: "1920",
        image_height: "1080",
        color_encoding: "4:4:4",
        frame_rate: "23.976",
        stereoscopy: "Monoscopic",
        jpeg_200_profile: "2k IMF Single ML4 SL2 400 Mbit/s",
        colorimetry: "P3 D65 / SMPTE ST 2084 (PQ)",
        pixel_bit_depth: "12-bit",
        mdcv_metadata: "SMPTE ST-2086",
        dynamic_metadata_for_color_volume_transformation: "Dolby VisionTM",
        resolution: "1920x1080",
        fps: "25",
        audio_format: "stereo",
        bit_depth: "24-bit",
        sample_rate: "48 KHz",
        email_addresses: [
            "patrick.mccarthy@turner.com",
            "robert.cranfield@turner.com",
        ],
        certifications: [
            {
                country_code: "POL",
                rating: "Intern TAP 6",
                system: "Munich Internal",
                advisory: ["Disturbing Content", "Crude Humour"],
            },
            {
                advisory: [],
                country_code: "MYS",
                rating: "18 S",
                system: "Warner Media",
            },
        ],
        alternate_language_audio_tracks: [
            {
                filename: "Philomena_Theatrical-Edit_Alt-Audio_French-FRE.mxf",
                format: "STEREO",
                bit_depth: "24-bit",
                sample_rate: "48 KHz",
                language: "French",
                language_code: "FRE",
            },
            {
                filename:
                    "Philomena_Theatrical-Edit_Alt-Audio_German-de-DE.mxf",
                format: "5:1 SURROUND",
                bit_depth: "24-bit",
                sample_rate: "48 KHz",
                language: "German",
                language_code: "DEU",
            },
            {
                filename:
                    "Philomena_Theatrical-Edit_Alt_Latin-Spanish-es-419.mxf",
                format: "STEREO",
                bit_depth: "24-bit",
                sample_rate: "48 KHz",
                language: "Spanish",
                language_code: "SPA",
            },
        ],
        subtitle_tracks: [
            {
                filename: "Philomena_INT-Linear-EN-SDH.xml",
                format: "SDH",
                standard: "WebVTT",
                language: "English",
                language_code: "ENG",
            },
            {
                filename:
                    "Philomena_International-Linear-Subtitles-EN-US-CC.xml",
                format: "CLOSED CAPTIONS",
                standard: "TTML",
                language: "English • US",
                language_code: "ENG-US",
            },
        ],
        compliances: [
            {
                meta_id: "AS00012565",
                asset_type: "Normalised",
                system_type: "Asset Management System",
                system_name: "Sony DADC",
                system_environment: "Development",
                compliance_code: "NORM",
                status: "placeholder created",
                emea_id: null,
                apac_id: null,
                latam_id: null,
                eidr: null,
                video: "ENG",
                audio: "ENG",
                duration: "00.01.38.08",
                created_at: "05/07/2020 10:12:00",
                asset_id: "FF00029837",
                meta_users: [],
                email_addresses: [],
            },
        ],
    },
    {
        meta_id: "AS00012512",
        asset_type: "Alternate Language Master",
        system_type: "Asset Management System",
        system_name: "Sony DADC",
        system_environment: "Development",
        compliance_code: "ORIG_FRE",
        status: "placeholder created",
        eidr: "10.5240/63B8-F4DD-D11B-B584-3E2D-A",
        audio: "FRE",
        video: "FRE",
        duration: "00.01.35.04",
        created_at: "05/07/2020 11:12:00",
        asset_id: "FF00029837",
        emea_id: "FE_883726134",
        latam_id: "002136544A/3884",
        apac_id: "MOV136544A/3883004",
        filename: "Philomena_VOD-Edit_FRE.mov",
        meta_users: [],
        image_width: "1920",
        image_height: "1080",
        color_encoding: "4:4:4",
        frame_rate: "23.976",
        stereoscopy: "Monoscopic",
        jpeg_200_profile: "2k IMF Single ML4 SL2 400 Mbit/s",
        colorimetry: "P3 D65 / SMPTE ST 2084 (PQ)",
        mdcv_metadata: "SMPTE ST-2086",
        dynamic_metadata_for_color_volume_transformation: "Dolby VisionTM",
        resolution: "1920x1080",
        fps: "25",
        audio_format: "stereo",
        bit_depth: "24-bit",
        sample_rate: "48 KHz",
        pixel_bit_depth: "12-bit",

        email_addresses: [
            "patrick.mccarthy@turner.com",
            "robert.cranfield@turner.com",
        ],
        alternate_language_audio_tracks: [
            {
                filename: "Philomena_Theatrical-Edit_Alt-Audio_French-FRE.mxf",
                format: "STEREO",
                bit_depth: "24-bit",
                sample_rate: "48 KHz",
                language: "French",
                language_code: "FRE",
            },
        ],
        compliances: [
            {
                meta_id: "AS00012561",
                asset_type: "Normalised",
                system_type: "Asset Management System",
                system_name: "Sony DADC",
                system_environment: "Development",
                compliance_code: "NORM",
                status: "placeholder created",
                emea_id: null,
                apac_id: null,
                latam_id: null,
                eidr: null,
                video: "ENG",
                audio: "ENG",
                duration: "00.01.42.04",
                created_at: "05/07/2020 11:12:00",
                asset_id: "FF00029837",
                meta_users: [],
                email_addresses: [],
            },
        ],
    },
];

export default PhilomenaSony;
