import React from "react";
import { observable, makeObservable, extendObservable, action } from "mobx";
import CreditsStore from "./creditsStore";
import CreditListsStore from "./creditListsStore";
import { CreditsInitialRootState } from "../credits.interface";
import { getRoles } from "../../../../services/Record/CastAndCrew/getRoles";

const storeInitialState: CreditsInitialRootState = {
    handleFormChange: () => {},
    scriptValue: "",
    defaultScriptValue: "",
    tableSortActive: false,
    roles: [],
    cmsData: {
        clientFeatures: {},
        clientDataStructure: {},
    },
};

export class RootStore {
    creditListsStore: CreditListsStore;
    creditsStore: CreditsStore;

    handleFormChange: (data: any) => void = () => {};
    editIsDisabled: boolean = true;
    scriptValue: string = "";
    defaultScriptValue: string = "";
    tableSortActive: boolean = false;
    roles: any = [];
    cmsData: any = {};

    tableSortStaus = {
        active: false,
        onreset: (reset: boolean): boolean => false,
    };

    constructor() {
        this.creditsStore = new CreditsStore(this);
        this.creditListsStore = new CreditListsStore(this);

        makeObservable(this, {
            editIsDisabled: observable,
            tableSortStaus: observable,
            setEditStatus: action,
            setScriptValue: action,
            setDefaultScriptValue: action,
            handleDataChange: action,
            resetTableSort: action,
            setRoles: action,
            handleSetRoles: action
        });

        extendObservable(this, storeInitialState);
    }

    initialize({
        handleFormChange,
        scriptValue,
        defaultScriptValue,
        cmsData
    }: CreditsInitialRootState): void {
        this.handleFormChange = handleFormChange;
        this.setScriptValue(scriptValue);
        this.setDefaultScriptValue(defaultScriptValue);
        this.setCmsData(cmsData);
        this.handleSetRoles()
    }

    handleSetRoles(): void {
        getRoles(this.cmsData).then((res: any) => this.setRoles(res.data))
    }

    handleDataChange() {
        this.handleFormChange({
            credits: this.creditsStore.credits,
            creditLists: this.creditListsStore.creditLists,
        });
    }

    setEditStatus(status: boolean) {
        this.tableSortActive = false;
        this.editIsDisabled = status;
    }

    
    setScriptValue(value: string) {
        this.scriptValue = value;
    }

    setDefaultScriptValue(value: string) {
        this.defaultScriptValue = value;
    }

    setCmsData(cmsData: any) {
        this.cmsData = cmsData;
    }

    setTableSortActive(status: boolean) {
        this.tableSortActive = status;
    }

    setTableSortStatus(status: boolean, onreset: (reset: boolean) => false) {
        this.tableSortStaus.active = status;
        this.tableSortStaus.onreset = onreset;
    }

    setRoles(roles: any) {
        this.roles = roles;
    }

    resetTableSort() {
        this.tableSortStaus.active = false;
        this.tableSortStaus.onreset(true);
    }

    get getEditStatus() {
        return this.editIsDisabled;
    }
    
    get getScriptValue() {
        return this.scriptValue;
    }

    get getDefaultScriptValue() {
        return this.defaultScriptValue;
    }

    get getTableSortActive() {
        return this.tableSortActive;
    }

    get getTableSortStatus() {
        return this.tableSortStaus;
    }

    get getCmsData() {
        return this.cmsData;
    }
    
}

export const StoresContext = React.createContext(new RootStore());
export const useStores = () => React.useContext(StoresContext);
