export default function GetLanguagesFromRegions(props) {
    const { languages } = props;

    let result = [];

    if (typeof languages == "object") {
        for (var k in languages) {
            if (languages.hasOwnProperty(k)) {
                result.push(...languages[k]);
            }
        }
    }

    return result;
}
