const InputStyled = ({
    children,
    columns,
    sortIndex,
    field
}) => {
    const itemWidth = () => {
        if (!columns || columns === 1) {
            return '100%'
        }

        return `calc(${100/columns}% - ${((columns - 1) * 24) / columns}px)`
    }

    const marginRight = (index) => {
        return ((index+1) % columns) == 0 ? '0' : '1.5rem'
    }

    return (
        <div 
            className={`core-dynamic-form-input-cell-wrapper core-dynamic-form-input-cell-wrapper--${field.key}`} 
            style={{
                width: itemWidth(), 
                marginRight: marginRight(sortIndex),
                marginTop: "1rem",
            }}>
            { children }
        </div>
    )
}

export default InputStyled