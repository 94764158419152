import _ from "lodash";
import { CountriesInterface } from "../model";
import isoCountries from "../../Options/isoCountries";
import { CLIENT_MANAGED_COUNTRIES } from "../../ClientManagedOptions/options";
import { GetFromLocalStorage } from "../../Storage/localStorage";
import transformCountriesToInterface from "./transformCountriesToInterface";

export default function getCountriesFromAllSources(
    options: any = {},
    transform: boolean = true,
): CountriesInterface[] | [] {
    const optionsCountries: CountriesInterface[] = options.countries || [];
    const clientManagedCountriesStorage: string = GetFromLocalStorage(
        CLIENT_MANAGED_COUNTRIES,
    );
    const clientManagedCountries: CountriesInterface[] = JSON.parse(
        clientManagedCountriesStorage,
    );

    let result: CountriesInterface[] = isoCountries;

    if (!_.isEmpty(optionsCountries)) {
        result = optionsCountries;
    }

    if (!_.isEmpty(clientManagedCountries)) {
        result = clientManagedCountries;
    }

    return transform ? transformCountriesToInterface(result) : result;
}
