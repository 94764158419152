import AuthService from "../../../services/AuthService";
import { TITLE, REPORT, FRANCHISE } from "./CollectionType";
import GetPageTypeByCategory from "./GetPageTypeByCategory";
import { CmsInterface } from "../../../components/Global/interfaces"
import { PolicyInterface } from "../../../features/UserRoleManagement/interfaces"
import { accessTypeCreate, policyTypeFranchiseCollections, policyTypeCollections } from "../../../features/UserRoleManagement/utils/accessOptions"
import { featureUserRoleManagement, userHasPermission } from "../../../services/PermissionService"

const typeCreateAccess = (
    type: string, 
    cmsData: CmsInterface, 
    userPermissions: PolicyInterface[] = []): boolean => {

    const userRoleManagementEnabled: boolean = featureUserRoleManagement({ cmsData })

    let isAvailable = false;

    switch (type) {
        case TITLE:
        case REPORT:
        case FRANCHISE:
            isAvailable = AuthService.hasPermission(
                `collections.${type}.create`,
            );

            if (userRoleManagementEnabled) {
                isAvailable = userHasPermission({
                    cmsData,
                    userPermissions,
                    pathField: policyTypeFranchiseCollections,
                    access: accessTypeCreate,
                })
            }

            break;
        default:
            isAvailable = AuthService.hasPermission("collections.create");

            if (userRoleManagementEnabled) {
                isAvailable = userHasPermission({
                    cmsData,
                    userPermissions,
                    pathField: policyTypeCollections,
                    access: accessTypeCreate,
                })
            }
    }

    return isAvailable;
};

const GetCollectionsAvailableList = (
    type: string,
    categoriesList: Array<string> = [],
    cmsData: CmsInterface | {} = {},
    userPermissions: PolicyInterface[] = []
): Array<string> => {

    if (categoriesList.length > 0) {
        let availableToCreate: Array<string> = [];

        categoriesList.forEach((categoryItem: string) => {
            let active = typeCreateAccess(
                GetPageTypeByCategory({ 
                    category: categoryItem 
                }),
                cmsData,
                userPermissions
            );

            if (active) {
                availableToCreate.push(categoryItem);
            }
        });

        return availableToCreate;
    }

    return typeCreateAccess(type) ? [type] : [];
};

export default GetCollectionsAvailableList;
