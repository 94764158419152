import _ from "lodash";
import CategoryTags from "../../../components/CategoryTags";

const MultipleSearchType = (category) => {
    let result = false;

    switch (category) {
        case CategoryTags.Special.short:
            result = true;
            break;
    }

    return result;
};

export default MultipleSearchType;
