import React, { useEffect, useState } from "react";
import { useStore } from "../store/context";
import RegionalAgeGuidanceRow from "../interfaces/RegionalAgeGuidanceRow.interface";
import Section from "./Section";
import Button from "../../Button";
import "../styles/_styles.scss";
import Boilerplate from "../../RecordBody/views/Boilerplate";
import { addNewRegional, editClassification } from "../store/reducer";
import { GetFromLocalStorage } from "../../Storage/localStorage";
import { CLIENT_MANAGED_TERRITORIES } from "../../ClientManagedOptions/options";
import fetchClientManagedOptions from "../../ClientManagedOptions/fetchClientManagedOptions";
import { ORIGINAL_AGE_GUIDANCE, REGIONAL_AGE_GUIDANCE } from "../configuration";
import _ from "lodash";

const Main: React.FC = (): JSX.Element => {
    const [store, dispatch] = useStore();

    const { classification = {}, age_guidance = [], disabled, cmsData } = store;

    const { minimum_age: originalMinAge, maximum_age: originalMaxAge } =
        classification;

    const [showClassification, setShowClassification] = useState(
        !!(originalMinAge || originalMaxAge),
    );

    useEffect(() => {
        const territories = GetFromLocalStorage(CLIENT_MANAGED_TERRITORIES);
        if (!territories) {
            fetchClientManagedOptions({
                clientManagedType: CLIENT_MANAGED_TERRITORIES,
                apiUrls: cmsData.apiUrls || {},
                clientFeatures: cmsData.clientFeatures || {},
            });
        }
    }, [age_guidance]);

    useEffect(() => {
        setShowClassification(!!(originalMinAge || originalMaxAge));
    }, [classification]);

    const regional = age_guidance.filter(
        (item: RegionalAgeGuidanceRow) => !item.deleted,
    );

    return (
        <Boilerplate
            title={"Classification"}
            className={"age-guidance-main-component"}
        >
            {/* ORIGNAL AGE GUIDANCE */}
            <div className="age-guidance-container">
                <span className="section-title">Age Guidance</span>
                <div className="management-block">
                    <div className="buttons">
                        <Button
                            value="New Age Guidance"
                            margin="0"
                            blue={true}
                            disabled={
                                disabled || originalMinAge || originalMaxAge
                            }
                            className="add-new"
                            onClick={() => setShowClassification(true)}
                            disabledBackground={"#E4E4E4"}
                        />
                    </div>
                </div>

                {showClassification && (
                    <Section
                        data={[classification]}
                        type={ORIGINAL_AGE_GUIDANCE}
                    />
                )}
            </div>

            {/* REGIONAL AGE GUIDANCE */}
            <div className="age-guidance-container">
                <span className="section-title">Regional Age Guidance</span>
                <div className="management-block">
                    <div className="buttons">
                        <Button
                            value="New Regional Age Guidance"
                            margin="0"
                            blue={true}
                            disabled={disabled}
                            className="add-new"
                            onClick={() => {
                                dispatch(addNewRegional());
                                return;
                            }}
                            disabledBackground={"#E4E4E4"}
                        />
                    </div>
                </div>
                {!_.isEmpty(regional) && (
                    <Section data={regional} type={REGIONAL_AGE_GUIDANCE} />
                )}
            </div>
        </Boilerplate>
    );
};

export default Main;
