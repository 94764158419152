import React, { useState, useEffect } from "react";
import Select from "../Select";
import Categories from "../CategoryTags/Categories";
import Input from "../Input/datalist";
import BubbleList from "../BubbleList";
import CategoryTags from "../CategoryTags";
import DoubleGridList from "../CheckboxTag/DoubleGridList";
import Effects from "./src/Effects";
import RenderEpisodeSelect from "./src/RenderEpisodeSelect";
import MultipleTitleSearch from "./src/MultipleTitleSearch";
import MultipleSearchType from "./src/MultipleSearchType";
import HandleSearchTermAndCategory from "./src/HandleSearchTermAndCategory";
import KeywordLabelProps from "./src/KeywordLabelProps";
import BubleListSearchDataList from "./src/BubleListSearchDataList";
import Seasons from "./src/Seasons";
import DisplayEpisodeSelect from "./src/DisplayEpisodeSelect";
import DisplaySpecialSelect from "./src/DisplaySpecialSelect";
import { SortCollectionTitlesFull } from "../SortCollectionTitles";
import "./styles/_styles.scss";
import _ from "lodash";
import FetchIncludingList from "../RecordBody/views/createLinkage/helpers/FetchIncludingList";
import DefaultIncludingListShow from "../RecordBody/views/createLinkage/src/data/DefaultIncludingListShow";
import DefaultIncludingListSeason from "../RecordBody/views/createLinkage/src/data/DefaultIncludingListSeason";
import BubbleListSimpleOnEnterSingle from "../BubbleList/simpleOnEnterSingle";

const AddTitleToCollection = (props) => {
    const {
        state = {},
        handleCategoryChange,
        copy = {},
        handleQueryChange,
        handleQueryListSelection,
        handleCollectionTitleSeasonSelect,
        handleCollectionTitleSelectAll,
        handleCollectionTitleSelection,
        handleCollectionTitleSelectAllShowSpecials,
        handleCollectionTitleSelectionShowSpecial,
        handleSelectedTitlesChange,
    } = props;

    const {
        collectionTitle = {},
        cmsData = {},
        options = {},
        setCollectionTitleKey,
        fetchingEpisodes,
        getRecordData = () => {},
    } = state;

    const { clientFeatures = {} } = cmsData;

    const {
        category,
        searchDataList = [],
        selectedTitle = {},
        selectedTitles = [],
        selectedSeason,
        episodes = [],
        specials = [],
        selectedAll = false,
        selectedAllShowSpecials = false,
        selectedEpisodes = [],
        selectedShowSpecials = [],
        children = "show_only",
        parent = "season_only",
    } = collectionTitle;

    const record = getRecordData();

    const { linkage = [] } = selectedTitle;

    const seasons = Seasons({
        category,
        linkage,
        record,
    });

    const multipleTitleSearch = MultipleTitleSearch(category);
    const multipleSearchType = MultipleSearchType(category);

    const keywordLabelProps = KeywordLabelProps({
        category,
        seasons,
    });

    const bubleListSearchDataList = BubleListSearchDataList({
        searchDataList,
        clientFeatures,
    });

    const displaySpecialSelect = DisplaySpecialSelect({
        category,
    });

    const displayEpisodeSelect = DisplayEpisodeSelect({
        category,
    });

    useEffect(() => {
        Effects({
            collectionTitle,
        });
    });

    const filteredSelectedTitles = selectedTitles.filter(
        (item) => !item.deleted,
    );

    const { clientDataStructure = {} } = cmsData;

    const { linkages = [] } = clientDataStructure;

    const includingTypesList = FetchIncludingList({
        category: category,
        configs: linkages,
        fallback:
            category == CategoryTags.Show.short
                ? DefaultIncludingListShow
                : DefaultIncludingListSeason,
    });

    return (
        <div className="meta-add-title-to-collection">
            <Select
                label={copy.recordType || "Record Type"}
                name="category"
                id="collectionTitleCategory"
                value={category || ""}
                handleChange={handleCategoryChange}
                disabledText={copy.selectCategory || "Select Category"}
                wrapperStyles={{
                    marginTop: "1.8rem",
                }}
                data={Categories({
                    cmsData,
                    options,
                })}
                showNullOption={false}
            />

            {!_.isEmpty(category) && (
                <div id="meta-collection-titles--search">
                    {(multipleTitleSearch || multipleSearchType) &&
                        _.isEmpty(selectedTitle) && (
                            <React.Fragment>
                                <BubbleList
                                    label="Search by Title"
                                    inputId="meta-add-title-to-collection--search"
                                    background={
                                        keywordLabelProps.background ||
                                        "#949494"
                                    }
                                    color={keywordLabelProps.color || "white"}
                                    deleteWhite={true}
                                    emptyPlaceholder={`Start typing ${category} name`}
                                    data={selectedTitles}
                                    datalistId="meta-add-title-to-collection--datalist"
                                    datalist={bubleListSearchDataList}
                                    dataListUseEnterButton={true}
                                    handleFormChange={(selectedTitles) =>
                                        handleSelectedTitlesChange(
                                            selectedTitles,
                                        )
                                    }
                                    handleChange={(searchQuery) =>
                                        HandleSearchTermAndCategory({
                                            category,
                                            searchQuery,
                                            categorySearch: true,
                                            handleQueryChange,
                                        })
                                    }
                                    wrapperStyles={{
                                        marginTop: "1.8rem",
                                    }}
                                    key={category}
                                    deleteObject={true}
                                />

                                {category == CategoryTags.Show.short && (
                                    <DoubleGridList
                                        label={
                                            copy.childInclussion ||
                                            "Include Linked Seasons and Episodes "
                                        }
                                        items={includingTypesList}
                                        styles={{
                                            margin: "1.8rem 0 0",
                                        }}
                                        selected={[children]}
                                        handleSelection={(value) =>
                                            setCollectionTitleKey(
                                                "children",
                                                value,
                                            )
                                        }
                                    />
                                )}
                            </React.Fragment>
                        )}

                    {(!multipleTitleSearch || multipleSearchType) &&
                        _.isEmpty(filteredSelectedTitles) && (
                            <React.Fragment>
                                <BubbleListSimpleOnEnterSingle
                                    label="Search by Parent Show"
                                    inputId="meta-add-title-to-collection--search-show"
                                    background={
                                        keywordLabelProps.background ||
                                        "#949494"
                                    }
                                    color={keywordLabelProps.color || "white"}
                                    deleteWhite={true}
                                    emptyPlaceholder={`Start typing show name`}
                                    datalistId="meta-add-title-to-collection--datalist"
                                    datalist={bubleListSearchDataList}
                                    dataListUseEnterButton={true}
                                    handleFormChange={(querySelection) => 
                                        handleQueryListSelection(querySelection[0])
                                    }
                                    handleChange={(searchQuery) =>
                                        HandleSearchTermAndCategory({
                                            category,
                                            searchQuery,
                                            handleQueryChange,
                                        })
                                    }
                                    wrapperStyles={{
                                        marginTop: "1.8rem",
                                    }}
                                    styles={{
                                        margin: "0.71875rem 0.71875rem 0 0",
                                    }}
                                    key={category}
                                    deleteObject={true}
                                />

                                {category == CategoryTags.Season.short && (
                                    <DoubleGridList
                                        label={
                                            copy.childInclussion ||
                                            "Include Parent Show"
                                        }
                                        items={includingTypesList}
                                        styles={{
                                            margin: "1.8rem 0 0",
                                        }}
                                        selected={[parent]}
                                        handleSelection={(value) =>
                                            setCollectionTitleKey(
                                                "parent",
                                                value,
                                            )
                                        }
                                    />
                                )}
                            </React.Fragment>
                        )}
                </div>
            )}

            {/** Show specials select */}
            {!_.isEmpty(selectedTitle) && displaySpecialSelect && (
                <div id="meta-collection-titles--show-specials">
                    <RenderEpisodeSelect
                        episodes={specials}
                        selectedAll={selectedAllShowSpecials}
                        category={category}
                        selectedEpisodes={selectedShowSpecials}
                        handleSelectAllClick={
                            handleCollectionTitleSelectAllShowSpecials
                        }
                        handleSelection={
                            handleCollectionTitleSelectionShowSpecial
                        }
                        selectAllLabel={copy.showSpecials || "Show Specials"}
                        parent="show"
                    />
                </div>
            )}

            {!_.isEmpty(selectedTitle) && (
                <div id="meta-collection-titles--season-select">
                    <Select
                        label={copy.season || "Season"}
                        name="seasons"
                        id="collectionTitleSeasons"
                        value={selectedSeason || ""}
                        disabledText={copy.selectSeason || "Select Season"}
                        data={seasons}
                        loading={fetchingEpisodes}
                        wrapperStyles={{
                            marginTop: "1.8rem",
                        }}
                        handleChange={(item) =>
                            handleCollectionTitleSeasonSelect(item)
                        }
                        showNullOption={false}
                    />
                </div>
            )}

            {!_.isEmpty(selectedSeason) &&
                !_.isEmpty(selectedTitle) &&
                displayEpisodeSelect &&
                !fetchingEpisodes && (
                    <div id="meta-collection-titles--episode-select">
                        <RenderEpisodeSelect
                            episodes={SortCollectionTitlesFull(episodes)}
                            selectedAll={selectedAll}
                            category={category}
                            selectedEpisodes={selectedEpisodes}
                            handleSelectAllClick={
                                handleCollectionTitleSelectAll
                            }
                            handleSelection={handleCollectionTitleSelection}
                            parent="season"
                        />
                    </div>
                )}
        </div>
    );
};

export default AddTitleToCollection;
