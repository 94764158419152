import { isString } from "lodash"

export default function getFileExtension(filename: string): string {
    if (isString(filename)) {
        return filename.split('.').pop()
    }

    return ""
}

export function getFileNameWithoutExtension(filename: string): string {
    if (isString(filename)) {
        return filename.split('.')[0]
    }

    return ""
}