import React from "react";
import { SectionInterface } from "./interface";
import AdditionalInformation from "../../views/AdditionalInformation";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";

const SectionAdditionalInformation = (props: SectionInterface) => {
    const { data = {}, cmsData = {} } = props;

    const { clientDataStructure = {} } = cmsData;

    const { additional_information = {} } = data;

    return (
        <AdditionalInformation
            {...getSectionCommonProps({
                ...props,
                field: "additional_information",
            })}
            name="additional-information"
            title="Additional Information"
            className="meta-record-additional-information"
            data={additional_information}
            category={data.category}
            noBorder={true}
            lastSection={true}
            dataStructure={clientDataStructure.additional_information}
        />
    );
};

export default SectionAdditionalInformation;
