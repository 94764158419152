import CategoryTags from "../../../CategoryTags/index";

const config = [
    {
        category: CategoryTags.Short.short,
        disabledTabs: ["measurement", "awards", "rights"],
    },
    {
        category: CategoryTags.Extras.short,
        disabledTabs: ["measurement", "awards", "rights"],
    },
];

export default config;
