import React from "react";
import "./styles/_styles.scss";
import InputLabel from "../Input/inputLabel";
import NumberFormat from "react-number-format";

const SentimentAnalysis = (props) => {
    const { data = {}, options = [], styles = {} } = props;

    const RenderFormattedNumber = (value) => {
        return (
            <NumberFormat
                value={value}
                displayType={"text"}
                thousandSeparator={true}
            />
        );
    };

    return (
        <div className="sentimental-analysis" style={styles}>
            {options.map((option, index) => {
                const { key, title, format } = option;

                const value = data[key];

                if (!value) return;

                let text = value;
                if (format == "integer" && value) {
                    text = RenderFormattedNumber(text);
                }

                return (
                    <InputLabel
                        label={title}
                        text={text}
                        styles={{
                            minWidth: "13rem",
                            marginRight: "1rem",
                            marginBottom: "1.25rem",
                        }}
                        key={index}
                        margin="0.5625rem 0 0"
                    />
                );
            })}
        </div>
    );
};

export default SentimentAnalysis;
