import React from "react";
import Tag from "../Tag";
import DoubleTags from "../DoubleTags";
import "./styles/_styles.scss";
import Settings from "../Settings";
import StatusTags from "../StatusTags";

const TitleStatusDisplayReleaseTags = (props) => {
    const { data = {}, displayStatusTags = true, statusOptions } = props;

    const {
        title,
        display,
        is_display,
        release,
        is_release,
        presentation,
        locale,
        api_name,
    } = data;

    let displayDisplayTag = false;
    if (display || display == 1 || is_display || is_display == 1)
        displayDisplayTag = true;

    let releaseDisplayTag = false;
    if (release || release == 1 || is_release || is_release == 1)
        releaseDisplayTag = true;

    let presentationTag = false;
    if (presentation || presentation == 1) presentationTag = true;

    const commonProps = {
        margin: "0 0.3125rem 0 0",
        color: "white",
    };

    const RenderTag = (props) => (
        <Tag
            text={props.text}
            className={props.className}
            styles={{
                ...commonProps,
                background: props.background || "#87918e",
            }}
        />
    );

    return (
        <div className="meta-title-status-display-release-tags">
            {displayStatusTags && (
                <StatusTags
                    data={data}
                    statusOptions={statusOptions}
                    style={{
                        marginRight: "0.3125rem",
                    }}
                />
            )}

            {locale && (
                <RenderTag
                    text={locale}
                    className="meta-tag-locale"
                    background="#4d4d4d"
                />
            )}

            {api_name && !displayStatusTags && (
                <DoubleTags
                    leftText={api_name}
                    rightText="api"
                    rightTextTransparent={true}
                    styles={{
                        marginRight: "0.3125rem",
                    }}
                />
            )}

            {displayDisplayTag && (
                <RenderTag text="Display" className="meta-tag-display" />
            )}

            {releaseDisplayTag && (
                <RenderTag text="Release" className="meta-tag-release" />
            )}

            {presentationTag && (
                <RenderTag
                    text="Presentation"
                    className="meta-tag-presentation"
                />
            )}
        </div>
    );
};

export default TitleStatusDisplayReleaseTags;
