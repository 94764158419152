import _ from "lodash";
import { CmsInterface } from "../../../components/Global/interfaces";
import SendRequest from "../../../components/SendRequest";
import {
    SendRequestOptionsInterface,
    SendRequestResponseInterface,
} from "../../../components/SendRequest/interface";
import { testRoles } from "../../../features/Credits/v2/test/v2/testRoles";

export const getRoles = async (
    cmsData: CmsInterface,
): Promise<SendRequestResponseInterface> => {
    const options: SendRequestOptionsInterface = {
        method: "GET",
        url:
            _.get(cmsData, "apiUrls.clientManagedCastAndCrew", "") +
            "?s=&l=999",
        testingData: testRoles
    };

    const response: SendRequestResponseInterface = await SendRequest(options);

    if (response.data) {
        response.data = response.data.sort((a: any, b: any) => a.order - b.order)
    }

    return response;
};
