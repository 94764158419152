import { CreditInterface } from "../../credits.interface";
export default function matchCreditsOnContributorUpdate(
    credits: CreditInterface[],
    contributor: CreditInterface
): CreditInterface[] {
    return credits.map(credit => {
        if (credit.local_id === contributor.local_id) {
            credit.date_of_birth = contributor.date_of_birth;
            credit.imdb_id = contributor.imdb_id;
            credit.isni_id = contributor.isni_id;
            credit.hand_id = contributor.hand_id;
            credit.iva_person_id = contributor.iva_person_id;
            credit.title = contributor.title;
            credit.name = contributor.name;
            credit.first_name = contributor.first_name;
            credit.last_name = contributor.last_name;
            credit.middle_names = contributor.middle_names;
            credit.alternative_name = contributor.alternative_name;
            credit.second_alternative_name = contributor.second_alternative_name;
            credit.script_names = contributor.script_names
        }

        return credit
    })
}