import React from "react";
import _ from "lodash";
import "./styles/_styles.scss";

const DoubleContent = (props) => {
    const {
        children = [],
        styles = {},
        itemStyles = {},
        widths = [],
        childMargin = "0.34375rem",
    } = props;

    if (!Array.isArray(children)) {
        return <></>
    }

    return (
        <div className="meta-double-content" style={styles}>
            {children.map((child, index) => {
                let margin = `0 ${childMargin}`;
                if (index == children.length - 1)
                    margin = `0 0 0 ${childMargin}`;
                if (index == 0) margin = `0 ${childMargin} 0 0`;

                let width = "";
                if (Array.isArray(widths) && widths[index]) {
                    width = widths[index];
                }

                return (
                    <div
                        className="meta-double-content--child"
                        style={{ width, margin, ...itemStyles }}
                        key={index}
                    >
                        {child}
                    </div>
                );
            })}
        </div>
    );
};

export default DoubleContent;
