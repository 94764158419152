import React from "react";
import { SectionInterface } from "./interface";
import AiringOrder from "../../views/AiringOrder";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";

const SectionAiringOrder = (props: SectionInterface) => {
    return (
        <AiringOrder
            {...getSectionCommonProps({ ...props, field: "airing_reorder" })}
        />
    );
};

export default SectionAiringOrder;
