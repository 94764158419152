import _ from "lodash";
import CategoryTags from "../../../components/CategoryTags";

const DisplaySpecialSelect = (props) => {
    const { category } = props;

    let result = false;

    if (category == CategoryTags.Special.short) result = true;

    return result;
};

export default DisplaySpecialSelect;
