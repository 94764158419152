import CategoryTag from "../../CategoryTags";
import _ from "lodash"

type Props = {
    models: any[]
    category: string
}

const filterCategory = (props: Props) => {
    const {
        category,
        models = []
    } = props

    if (!category) {
        return models
    }

    return models.filter(
        item => {
            let categoryModel = {}
            Object.keys(CategoryTag).map(key => {
                if (CategoryTag[key].short == category) {
                    categoryModel = CategoryTag[key]
                }
            })

            return !_.isEmpty(categoryModel) && Array.isArray(item.categories) && item.categories.includes(categoryModel.short)
        }
    )
}

export default filterCategory