import { extendObservable, makeObservable, action } from "mobx";
import _ from "lodash";
import {
    TaxonomyOptionsTransformedInterface,
    TaxonomyOptionsInterface,
} from "../../../models/ClientManaged/taxonomyInterface";
import { getTransformedOptions } from "../../../models/ClientManaged/taxonomyInterface.model";
import { TaxonomyStructure, TaxonomyType } from "../taxonomy.interface";
import {
    GlobalTaxonomyInterface,
    TaxonomyInterface,
} from "../../../models/Record/taxonomy";
import { CLIENT_MANAGED_TAXONOMY } from "../../../components/ClientManagedOptions/options";
import fetchClientManagedOptions from "../../../components/ClientManagedOptions/fetchClientManagedOptions";
import update from "react-addons-update";
import deepcopy from "deepcopy";
import { CmsInterface } from "../../../components/Global/interfaces";
import clientManagedOptionsData from "../test/clientManagedOptionsData";

interface TaxonomyStoreInitialStateInterface {
    data: GlobalTaxonomyInterface | {};
    loadingOptions: boolean;
    options: TaxonomyOptionsInterface[];
    transformedOptions: TaxonomyOptionsTransformedInterface[];
    structure: TaxonomyStructure | {};
    globalKey: string;
    cmsData: CmsInterface | {};
}

interface InitializeInterface {
    structure: TaxonomyStructure | {};
    data: GlobalTaxonomyInterface | {};
    handleFormChange: (data: TaxonomyInterface) => void;
    cmsData: CmsInterface;
}

const taxonomyStoreInitialState: TaxonomyStoreInitialStateInterface = {
    data: {},
    loadingOptions: true,
    options: [],
    transformedOptions: [],
    structure: {},
    globalKey: "",
    cmsData: {},
};

export class TaxonomyStore {
    data;
    loadingOptions;
    options;
    transformedOptions;
    structure;
    globalKey;
    handleFormChange;
    cmsData;

    constructor() {
        makeObservable(this, {
            initialize: action,
            setGlobalKey: action,
            setStructure: action,
            setCmsData: action,
            setData: action,
            setInitialData: action,
            setLoadingOptions: action,
            setOptions: action,
            setTransformedOptions: action,
            getItemTitle: action,
            reset: action,
            handleDeleteItem: action,
            handleAddToData: action,
        });

        extendObservable(this, taxonomyStoreInitialState);
    }

    reset() {
        Object.keys(taxonomyStoreInitialState).forEach(
            (key) => (this[key] = taxonomyStoreInitialState[key]),
        );
    }

    initialize({
        structure,
        data,
        handleFormChange,
        cmsData,
    }: InitializeInterface): void {
        this.setCmsData(cmsData);
        this.setStructure(structure);
        this.setGlobalKey();
        this.setInitialData(data);
        this.fetchOptions();

        this.handleFormChange = handleFormChange;
    }

    setCmsData(cmsData: CmsInterface | {}): void {
        this.cmsData = cmsData;
    }

    setGlobalKey(): void {
        this.globalKey = this.structure?.globalKey;
    }

    setStructure(structure: TaxonomyStructure | {}): void {
        this.structure = structure;
    }

    setInitialData(data: GlobalTaxonomyInterface | {}): void {
        let updatedState: GlobalTaxonomyInterface = deepcopy(data);

        // sets global key object if empty
        if (!updatedState[this.globalKey]) {
            updatedState = update(updatedState, {
                [this.globalKey]: {
                    $set: {},
                },
            });
        }

        // sets secondary keys if empty
        if (this.structure && !_.isEmpty(this.structure.levels)) {
            this.structure.types.forEach((type: TaxonomyType) => {
                if (!updatedState[this.globalKey][type.name]) {
                    updatedState = update(updatedState, {
                        [this.globalKey]: {
                            [type.name]: {
                                $set: [],
                            },
                        },
                    });
                }
            });
        }

        this.setData(updatedState);
    }

    setData(data: GlobalTaxonomyInterface | {}): void {
        this.data = data;
    }

    setOptions(options: TaxonomyOptionsInterface[]): void {
        this.options = options;
    }

    setTransformedOptions(): void {
        this.transformedOptions = getTransformedOptions(
            this.options,
            this.data,
            this.structure,
        );
    }

    setLoadingOptions(loadingOptions: boolean): void {
        this.loadingOptions = loadingOptions;
    }

    getItemTitle(item: TaxonomyInterface): string {
        const { levels = {} } = item;

        let result = "";

        if (!_.isEmpty(levels)) {
            let sortedObject = _.sortBy(levels, [
                function (o: any) {
                    return o.depth;
                },
            ]);

            const array = Object.keys(sortedObject).map(
                (key) => sortedObject[key].value,
            );

            return array.join(" • ");
        }

        return result;
    }

    handleDeleteItem(item: TaxonomyInterface, type: string): void {
        const index = _.findIndex(
            this.data?.[this.globalKey]?.[type],
            function (it: TaxonomyInterface) {
                return it.id == item.id;
            },
        );

        const data = update(this.data, {
            [this.globalKey]: {
                [type]: {
                    $splice: [[index, 1]],
                },
            },
        });

        this.setData(data);
        this.setTransformedOptions();
        this.handleFormChange(data);
    }

    handleAddToData(
        item: TaxonomyOptionsTransformedInterface,
        type: string,
    ): void {
        let newValue: TaxonomyInterface = {
            id: item.value?.value,
            value: item.value?.text,
            levels: {},
        };

        if (!_.isEmpty(this.structure.levels)) {
            newValue.levels = {
                [this.structure.levels[0]]: {
                    value: item.value?.parent,
                    depth: 1,
                },
                [this.structure.levels[1]]: {
                    value: item.value?.subParent,
                    depth: 2,
                },
                [this.structure.levels[2]]: {
                    value: item.parent?.text,
                    depth: 3,
                },
                [this.structure.levels[3]]: {
                    id: item.value?.value,
                    value: item.value?.text,
                    depth: 4,
                },
            };
        }

        let updatedState: TaxonomyInterface = deepcopy(this.data);

        updatedState = update(updatedState, {
            [this.globalKey]: {
                [type]: {
                    $push: [newValue],
                },
            },
        });

        this.setData(updatedState);
        this.setTransformedOptions();
        this.handleFormChange(updatedState);
    }

    fetchOptions(): void {
        fetchClientManagedOptions({
            clientManagedType: CLIENT_MANAGED_TAXONOMY,
            apiUrls: this.cmsData?.apiUrls,
            type: this.globalKey,
            testingData: clientManagedOptionsData,
            onResponse: (options: any) => {
                this.setLoadingOptions(false);
                this.setOptions(options);
                this.setTransformedOptions();
            },
        });
    }
}
