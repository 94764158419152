import React from "react";
import "./styles/_multiple.scss";

export interface ITag {
    text: string;
    color?: string;
    background?: string;
    uppercase?: boolean;
    styleType?: "normal" | "thin";
    onClick?: Function;
}

type MultipleTagProps = {
    tags: Array<ITag>;
};

const MultipleTag: React.FC<MultipleTagProps> = ({ tags = [] }) => {
    return (
        <div className="multiple-tag">
            {tags.map((tag: ITag) => {
                const { styleType = "normal" } = tag;

                return (
                    <span
                        className="single-tag"
                        style={{
                            color: tag.color || "#626261",
                            background: tag.background || "#DADBDB",
                            textTransform: tag.uppercase ? "uppercase" : "none",
                            padding:
                                styleType == "normal"
                                    ? "0.375rem 0.5rem"
                                    : "0.375rem 0.375rem",
                        }}
                    >
                        {tag.text}
                    </span>
                );
            })}
        </div>
    );
};

export default MultipleTag;
