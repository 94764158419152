import React from "react";
import "./styles/_styles.scss";
import SelectWithTag from "../SelectWithTag";
import Button from "../Button";
import Settings from "../Settings";

const PeriodDropdownWithRefresh = (props) => {
    const {
        value,
        data = [],
        disabled,
        loading = false,
        width = "14.15625rem",
        handleChange = () => {},
        handleRefresh = () => {},
        styles = {},
        refresh = false,
    } = props;

    return (
        <div
            className="period-dropdown-with-refresh container-flex"
            style={styles}
        >
            {refresh && (
                <Button
                    value="Refresh"
                    type="button"
                    margin="0"
                    padding="0.59375rem"
                    background="#dbdbdb"
                    hoverBackground="#dbdbdb"
                    color="rgba(101, 101, 101, 0.87)"
                    fontSize="1.0625rem"
                    loading={loading}
                    leftIcon={`${Settings.images.path}/svg/refresh-grey.svg`}
                    leftIconStyles={{
                        width: "0.90625rem",
                    }}
                    onClick={() => handleRefresh()}
                />
            )}

            <SelectWithTag
                value={value}
                clear={true}
                data={data}
                displayTagOnDisabled={true}
                disabled={disabled}
                loading={loading}
                width={width}
                noMargin={true}
                wrapperStyles={{
                    margin: "0 0 0 0.625rem",
                }}
                handleChange={(value) => handleChange(value)}
            />
        </div>
    );
};

export default PeriodDropdownWithRefresh;
