import _ from "lodash";
import { CAST_AND_CREW, CLIENT_MANAGED_CAST_AND_CREW } from "./options";
import { GetFromLocalStorage } from "../Storage/localStorage";
import clientManagedController from "./clientManagedController";

export function isClientManagedCastAndCrew(props) {
    const { clientFeatures = {} } = props;

    const { managedLists = [] } = clientFeatures;

    return Array.isArray(managedLists) && managedLists.includes(CAST_AND_CREW);
}

export default function CastAndCrewOptions(props) {
    const { options = {}, fallback = [] } = props;

    const local = GetFromLocalStorage(CLIENT_MANAGED_CAST_AND_CREW);
    const castAndCrewOptions = clientManagedController({
        clientManagedOptions: options.client_managed_cast_and_crew || [],
        fallback,
        localStorage: JSON.parse(local),
        isClientManaged: isClientManagedCastAndCrew(props),
    });

    return castAndCrewOptions;
}

export function CastAndCrewOptionsTransformer(client_managed_cast_and_crew) {
    return client_managed_cast_and_crew
        .filter((item) => !item.api_only)
        .map((item) => {
            return {
                ...item,
                text: item.name,
                value: item.id,
            };
        });
}
