import Prefixer from "inline-style-prefixer";

export default function styles(props = {}) {
    let styles = {
        default: {
            background: props.background || "#87918e",
            color: props.color || "white",
            padding: "0.1875rem 0.3125rem",
            textTransform: props.textTransform || "uppercase",
            textAlign: props.textAlign || "center",
            fontSize: props.fontSize || "0.75rem",
            borderRadius: "0.25rem",
            lineHeight: props.lineHeight || "1rem",
        },
        active: {
            background: "#557c6f",
        },
        proposed: {
            background: "rgb(210, 121, 62)",
        },
        disabled: {
            background: "#87918e",
        },
        background: {
            background: props.background,
        },
        margin: {
            margin: props.margin,
        },
        href: {
            cursor: "pointer",
        },
        icon: {
            height: "0.875rem",
        },
        iconContainer: {
            display: "flex",
            alignItems: "center",
        },
    };

    let prefixer = new Prefixer();
    styles = prefixer.prefix(styles);

    return styles;
}
