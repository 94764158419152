import RegionalAgeGuidanceRowInterface from "../../interfaces/RegionalAgeGuidanceRow.interface";
import _ from "lodash";
import deepcopy from "deepcopy";

const EditRegionalRatingHelper = (
    newRating: RegionalAgeGuidanceRowInterface,
    oldRating: RegionalAgeGuidanceRowInterface,
    age_guidance: RegionalAgeGuidanceRowInterface[],
) => {
    let territoriesToDelete: string[] = [];
    const oldData = deepcopy(age_guidance);

    let editedData = oldData.map((item: RegionalAgeGuidanceRowInterface) => {
        if (
            item.maximum_age == oldRating.maximum_age &&
            item.minimum_age == oldRating.minimum_age
        ) {
            item.maximum_age = newRating.maximum_age;
            item.minimum_age = newRating.minimum_age;
            item.territories = newRating.territories;

            oldRating.territories.map((territory: string) => {
                if (!newRating.territories.includes(territory)) {
                    territoriesToDelete.push(territory);
                }
            });
        }

        return item;
    });

    if (!_.isEmpty(territoriesToDelete)) {
        editedData.push({
            maximum_age: oldRating.maximum_age,
            minimum_age: oldRating.minimum_age,
            territories: territoriesToDelete,
            deleted: true,
        });
    }

    return editedData;
};

export default EditRegionalRatingHelper;
