import { GridValueFormatterParams } from "@mui/x-data-grid-pro";
import { isEmpty, isNaN } from "lodash";

export const valueFormatterString = (
    params: GridValueFormatterParams<string>,
) => {
    if (isEmpty(params.value)) {
        return "-";
    }
    return params.value;
};
export const valueFormatterNumber = (
    params: GridValueFormatterParams<number>,
) => {
    if (
        Number(params.value) <= -1 ||
        params.value == null ||
        isNaN(params.value)
    ) {
        return "-";
    }
    return `${Number(params.value).toString()}`;
};
