import SendRequest from "../../SendRequest";
import Stamp from "../../SendRequest/Stamp";
import _ from "lodash";

const HandleSearch = (props) => {
    const {
        value,
        setFetchingDatalist,
        setStamp,
        setDatalist,
        searchKey,
        apiUrls,
        setDisplayDataList,
        searchUrl,
        limit = "",
    } = props;

    setFetchingDatalist(value ? true : false);
    setStamp(Stamp);

    const limitParam = `&l=${limit}`;

    let url = `${apiUrls.searchAwards}?s=${value}&type=${searchKey}`;

    if (searchUrl) {
        url = `${searchUrl}?s=${value}${limit && limitParam}`;
    }

    const options = {
        url,
        method: "GET",
        testingData: [
            {
                text: value,
                value: value,
            },
        ],
    };

    SendRequest(options, (response) => {
        const { status, data } = response;

        if (status == 200 || !_.isEmpty(data)) {
            setDatalist(data);
            setDisplayDataList(true);
        }

        setFetchingDatalist(false);
    });
};

export default HandleSearch;
