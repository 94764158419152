import React from "react";
import _ from "lodash";
import deepcopy from "deepcopy";
import SelectWithTag from "../../SelectWithTag";
import TitleBarWithPager from "../../TitleBarWithPager";
import AddNewRowButton from "../../AddNewRowButton";
import Notification from "../../Notification";
import SortableTable from "../../SortableTable";
import Paragraph from "../../Paragraph";
import Input from "../../Input/basic";
import InputDatalist from "../../Input/datalist";
import PlaceholderText from "../../PlaceholderText";
import HomeScreenLineupManagerRows from "./HomeScreenLineupManagerRows";
import DoubleGridList from "../../CheckboxTag/DoubleGridList";
import {
    MonthYearDates,
    MonthYearDatesWithOptions,
} from "../../Helpers/MonthYearDates";
import GetMonthYearFromDate from "../../Helpers/GetMonthYearFromDate";
import SendRequest from "../../SendRequest";
import ContentLoading from "../../ContentLoading";
import DownloadFileWithFetch from "../../File/DownloadFileWithFetch";
import AddNewButtonWithLineMultiple from "../../AddNewButtonWithLine/Multiple";
import Button from "../../Button";
import Settings from "../../Settings";
import { arrayMove } from "react-sortable-hoc";
import AuthService from "../../../services/AuthService";
import { disabledSection } from "../config";
import FadeWithSkeleton from "../../SkeletonLoader/FadeWithSkeleton";

export default class HomeScreenLineupManager extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            included: {},
            notification: {},
            swimlane: {},
            collections: [],
            sorting: false,
        };

        this.copy = {};
        this.dates = MonthYearDates();
    }

    componentDidMount() {
        this.handleLineupManagerRequest();
    }

    handleLineupManagerRequest() {
        const { state = {} } = this.props;

        const { handleLineupManagerRequest = () => {} } = state;

        this.setState({
            fetchingMetaData: true,
        });

        handleLineupManagerRequest({
            handleError: () => {
                this.setErrorOnFetch();
            },
            handleSuccess: (response) => {
                this.setDataFromResponse(response);
            },
        });
    }

    setDataFromResponse(response) {
        const { data = {}, included = {} } = response;

        this.setState({
            data,
            included,
            fetchingMetaData: false,
            sorting: false,
        });
    }

    setErrorOnFetch(description) {
        this.setState({
            notification: {
                title: this.copy.technicalError || "Techincal Error",
                description:
                    description || "Failed to lineups, please refresh to retry",
            },
            fetchingMetaData: false,
            sorting: false,
        });
    }

    getSwimlanes() {
        const { data = {}, included = {} } = this.state;

        const { swimlanes = [] } = included;

        return swimlanes;
    }

    apiUrls() {
        const { state = {} } = this.props;

        const { cmsData = {} } = state;

        const { apiUrls = {} } = cmsData;

        return apiUrls;
    }

    clientFeatures() {
        const { state = {} } = this.props;

        const { cmsData = {} } = state;

        const { clientFeatures = {} } = cmsData;

        return clientFeatures;
    }

    setSwimlaneKey(key, value) {
        let updatedState = deepcopy(this.state.swimlane);
        updatedState[key] = value;

        this.setState(
            {
                swimlane: updatedState,
            },
            () => {
                this.updateNotificationHtml();
            },
        );
    }

    isSwimlaneValid() {
        const { swimlane = {} } = this.state;

        const {
            collection,
            collection_type,
            title,
            duration_type,
            window_start,
            window_finish,
        } = swimlane;

        let result = false;

        if (collection && collection_type && title && duration_type) {
            result = true;
        }

        if (duration_type == "window" && (!window_start || !window_finish)) {
            result = false;
        }

        return result;
    }

    handleCollectionsSearch(props) {
        const { searchQuery, collection_type } = props;

        const apiUrls = this.apiUrls();

        let categpry = "collection";
        if (collection_type == "connect") categpry = "connect collection";

        const options = {
            url: `${apiUrls.searchWithCategory}?category=${categpry}&s=${searchQuery}`,
            method: "GET",
        };

        if (!searchQuery) {
            this.setSwimlaneKey("collection", "");
        }

        const handleError = (error) => {
            let notification = deepcopy(this.state.notification);
            notification.errorMessage = "Could not fetch collections";

            this.setState({
                notification,
            });

            if (error) {
                console.log(error);
            }
        };

        SendRequest(
            options,
            (response) => {
                const { status, data, message } = response;

                if (status == 200) {
                    this.setState(
                        {
                            collections: deepcopy(data),
                        },
                        () => {
                            this.updateNotificationHtml();
                        },
                    );

                    return;
                }

                // On Error
                handleError(message);
            },
            (e) => {
                handleError(e);
            },
        );
    }

    updateNotificationHtml() {
        let updatedState = deepcopy(this.state.notification);
        updatedState.html = this.getNotificationHtml();
        updatedState.disabled = !this.isSwimlaneValid();

        this.setState({
            notification: updatedState,
        });
    }

    getNotificationHtml() {
        const { swimlane = {}, collections = [] } = this.state;

        const {
            collection_type,
            title,
            duration_type,
            window_start,
            window_finish,
            collection,
        } = swimlane;

        const { connect } = this.clientFeatures();

        let collectionTypes = [
            {
                text: "Title Collection",
                value: "title",
            },
        ];

        if (connect) {
            collectionTypes.push({
                text: "Connect Collection",
                value: "connect",
            });
        }

        let startDates = this.dates;
        if (window_finish) {
            startDates = MonthYearDatesWithOptions({
                limitToDate: window_finish,
            });
        }

        return (
            <div>
                <DoubleGridList
                    label={this.copy.collectionType || "Collection Type"}
                    items={collectionTypes}
                    styles={{
                        margin: "1.625rem 0 0",
                    }}
                    selected={collection_type ? [collection_type] : []}
                    handleSelection={(value) => {
                        this.setState({
                            collections: [],
                        });
                        this.setSwimlaneKey("collection_type", value);
                    }}
                />

                <InputDatalist
                    placeholder={
                        this.copy.startTypingCollectionName ||
                        "Start typing collection name"
                    }
                    label={
                        this.copy.searchForCollection || "Search for Collection"
                    }
                    id="swimlane-search-for-collection"
                    datalist={collections}
                    value={collection}
                    noFilter={true}
                    recordBasic={true}
                    keepSelected={true}
                    bubbleOnSelect={true}
                    margin="1.65625rem 0 0"
                    selectedItemKeep={true}
                    selectedItemKey="meta_id"
                    handleChange={(searchQuery) =>
                        this.handleCollectionsSearch({
                            searchQuery,
                            collection_type,
                        })
                    }
                    handleListSelection={(value) =>
                        this.handleCollectionSelection({
                            value,
                        })
                    }
                    keywordLabel={{
                        background: "#1890bd",
                    }}
                />

                <Paragraph
                    color="#5a5a5a"
                    items={[
                        this.copy.configureswimlaneDescription ||
                            "Title Collections are only presented above that are available in the country of the Rights Holder for the Window chosen. Smart Collections are always available.",
                    ]}
                    styles={{
                        margin: "1.25rem 0 0",
                    }}
                    fontWeight={400}
                    fontSize="0.78125rem"
                />

                <Input
                    label={this.copy.swimlaneTitle || "Swimlane Title"}
                    placeholder={this.copy.enterTitle || "Enter title"}
                    id="swimlane_title"
                    value={title}
                    recordBasic={true}
                    margin="1.65625rem 0 0"
                    handleChange={(value) =>
                        this.setSwimlaneKey("title", value)
                    }
                />

                <DoubleGridList
                    label={this.copy.swimlaneDuration || "Swimlane Duration"}
                    items={[
                        {
                            text: this.copy.titleCollection || "Single month",
                            value: "single",
                        },
                        {
                            text: this.copy.smartCollection || "Window",
                            value: "window",
                        },
                    ]}
                    styles={{
                        margin: "1.625rem 0 0",
                    }}
                    selected={duration_type ? [duration_type] : []}
                    handleSelection={(value) =>
                        this.setSwimlaneKey("duration_type", value)
                    }
                />

                {duration_type == "window" && (
                    <div className="container-flex">
                        <SelectWithTag
                            id="window_start"
                            value={window_start}
                            clear={true}
                            label={this.copy.windowStart || "Window Start"}
                            disabledText={
                                this.copy.originalTitle || "Select Date"
                            }
                            noneSelectedText={
                                this.copy.noneSelected || "None Selected"
                            }
                            data={startDates}
                            group={true}
                            wrapperStyles={{
                                width: "50%",
                                margin: "1.625rem 0.328125rem 0 0",
                            }}
                            handleChange={(value) =>
                                this.setSwimlaneKey("window_start", value)
                            }
                        />

                        <SelectWithTag
                            id="window_finish"
                            value={window_finish}
                            clear={true}
                            label={this.copy.windowFinish || "Window Finish"}
                            disabledText={
                                this.copy.originalTitle || "Select Date"
                            }
                            noneSelectedText={
                                this.copy.noneSelected || "None Selected"
                            }
                            data={this.dates}
                            group={true}
                            wrapperStyles={{
                                width: "50%",
                                margin: "1.625rem 0 0 0.328125rem",
                            }}
                            handleChange={(value) =>
                                this.setSwimlaneKey("window_finish", value)
                            }
                        />
                    </div>
                )}
            </div>
        );
    }

    handleCollectionSelection(props) {
        const { value = {} } = props;

        const { swimlane = {} } = this.state;

        const { title } = swimlane;

        const { collections = [] } = this.state;

        const meta_id = value.meta_id;
        const collection = collections.find((item) => item.meta_id == meta_id);

        let updatedState = deepcopy(swimlane);
        updatedState.collection = meta_id;

        // Adda title from athe selected collection
        if (collection && collection.original_title && _.isEmpty(title)) {
            updatedState.title = collection.original_title;
        }

        this.setState(
            {
                swimlane: updatedState,
            },
            () => {
                this.updateNotificationHtml();
            },
        );
    }

    handleAddNewSwimlane() {
        const { connect } = this.clientFeatures();

        this.setState(
            {
                swimlane: {
                    collection_type: connect ? "connect" : "title",
                },
            },
            () => {
                this.setState({
                    notification: {
                        title:
                            this.copy.configureSwimlane || "Configure Swimlane",
                        html: this.getNotificationHtml(),
                        okText: this.copy.savePlusClose || "save + close",
                        confirm: this.handleNewSwimlane.bind(this),
                        disabled: true,
                    },
                });
            },
        );
    }

    handleNewSwimlane() {
        const { state } = this.props;

        const { data = {}, swimlane } = this.state;

        const {
            handleSwimlaneCreateRequest,
            lineupPeriod,
            activeRecord = {},
        } = state;

        const { meta_id } = data;

        let requestData = {
            ...swimlane,
            lineup_meta_id: meta_id,
            usergroup_meta_id: activeRecord.meta_id,
        };

        if (swimlane.duration_type == "single") {
            requestData.duration_period = lineupPeriod;
        }

        handleSwimlaneCreateRequest({
            data: requestData,
            handleError: () =>
                this.setErrorOnFetch(
                    "Failed to create a swimlane, please retry again.",
                ),
            handleSuccess: () => this.handleLineupManagerRequest(),
        });

        this.setState({
            swimlane: {},
            notification: {},
        });
    }

    handleNotificationReset() {
        this.setState({
            notification: {},
        });
    }

    handleSwimlaneEditRecordClick(meta_id) {
        const swimlanes = this.getSwimlanes();

        if (Array.isArray(swimlanes)) {
            const swimlane = swimlanes.find((item) => item.meta_id == meta_id);

            this.setState(
                {
                    swimlane,
                },
                () => {
                    this.setState({
                        notification: {
                            title:
                                this.copy.configureSwimlane ||
                                "Configure Swimlane",
                            html: this.getNotificationHtml(),
                            okText: this.copy.save || "Save",
                            confirm: () => {
                                this.handleSwimlaneUpdate();
                                this.handleNotificationReset();
                            },
                            disabled: true,
                        },
                    });
                },
            );
        }
    }

    handleSwimlaneUpdate() {
        const { state = {} } = this.props;
        const { data = {}, swimlane } = this.state;
        const { handleSwimlaneUpdate, activeRecord = {} } = state;
        const { meta_id } = data;
        //ensure null duration period
        if (swimlane.duration_type === "window") {
            swimlane.duration_period = null;
        }
        let requestData = {
            ...swimlane,
            lineup_meta_id: meta_id,
            usergroup_meta_id: activeRecord.meta_id,
        };
        handleSwimlaneUpdate({
            swimlane: requestData,
            handleError: () =>
                this.setErrorOnFetch(
                    "Failed to update a swimlane, please retry again",
                ),
            handleSuccess: () => this.handleLineupManagerRequest(),
        });
    }
    handleDeleteSwimLane(swimlane) {
        const { state = {} } = this.props;

        const { data = {} } = this.state;

        const { handleSwimlaneDelete } = state;

        handleSwimlaneDelete({
            meta_id: data.meta_id,
            swimlane,
            handleError: () =>
                this.setErrorOnFetch(
                    "Failed to delete a swimlane, please retry again",
                ),
            handleSuccess: () => this.handleLineupManagerRequest(),
        });

        this.handleNotificationReset();
    }

    handleSwimlaneDeleteRecordClick(meta_id) {
        const swimlanes = this.getSwimlanes();

        if (Array.isArray(swimlanes)) {
            const swimlane = swimlanes.find((item) => item.meta_id == meta_id);

            this.setState({
                notification: {
                    title: this.copy.swimlane || "Delete swimlane",
                    description:
                        this.copy.areYouSureWantToDelete ||
                        "Are you sure you want to delete " + swimlane.title,
                    html: false,
                    okText: this.copy.delete || "Delete",
                    disabled: false,
                    confirm: () => this.handleDeleteSwimLane(swimlane),
                },
            });
        }
    }

    handleStatusUpdate(status) {
        let key = "";
        let payload = {
            status,
        };

        if (status == "published") {
            key = "publishing";
        }

        if (status == "submitted") {
            key = "submitting";
        }

        if (status == "pending") {
            key = "takingDown";
        }

        this.handleLineupManagerUpdate(payload);

        this.setState({
            [key]: true,
        });
    }

    async handleLineupManagerUpdate(updatedData) {
        const { data = {} } = this.state;

        const { state = {} } = this.props;

        const {
            handleLineupManagerUpdate,
            handleFetchAllPlatformAdmins,
            activeRecord = {},
        } = state;

        const { name, meta_id } = activeRecord;

        const setLoadingKeys = () => {
            this.setState({
                takingDown: false,
                submitting: false,
                publishing: false,
            });
        };

        if (updatedData.status == "submitted") {
            let promise = new Promise((resolve, reject) => {
                handleFetchAllPlatformAdmins({
                    handleError: () => reject([]),
                    handleSuccess: (data) => resolve(data),
                });
            });

            updatedData.emails = await promise;
        }

        updatedData.userGroup = {
            name,
            meta_id,
        };

        handleLineupManagerUpdate({
            data: updatedData,
            meta_id: data.meta_id,
            handleError: () => {
                this.setErrorOnFetch();
                setLoadingKeys();
            },
            handleSuccess: () => {
                this.handleLineupManagerRequest();
                setLoadingKeys();
            },
        });
    }

    handleSwimlaneSort(movedData) {
        const { data = {} } = this.state;

        const swimlanes = deepcopy(data.swimlanes);
        let orderedData = arrayMove(
            swimlanes,
            movedData.oldIndex,
            movedData.newIndex,
        );

        if (Array.isArray(orderedData) && !_.isEqual(swimlanes, orderedData)) {
            this.setState({
                sorting: true,
            });

            this.handleLineupManagerUpdate({
                swimlanes: orderedData,
            });
        }
    }

    handleLineupPeriodNextPreviousEvent(event) {
        const { state = {} } = this.props;

        const { handleLineupPeriodNextPreviousEvent } = state;

        handleLineupPeriodNextPreviousEvent({
            event,
            handleSuccess: () => this.handleLineupManagerRequest(),
        });
    }

    handleStartDownload(item) {
        this.setState(
            {
                downloading: true,
            },
            () => {
                DownloadFileWithFetch({
                    ...item,
                    handleSuccess: () => {
                        this.setState({
                            downloading: false,
                            notification: {},
                        });
                    },
                    handleError: () => {
                        this.setState({
                            downloading: false,
                            notification: {
                                title: "Technical error",
                                description:
                                    "Failed to download file, please try again",
                            },
                        });
                    },
                });
            },
        );
    }

    render() {
        const { state = {}, disabled, canCreateNew = true } = this.props;

        const {
            data = {},
            included = {},
            notification = {},
            fetchingMetaData,
            sorting,
            publishing,
            submitting,
            takingDown,
            downloading,
        } = this.state;

        const {
            menuActive,
            lineupPeriod,
            handleLineupPeriodChange,
            activeRecord = {},
        } = state;

        const { meta_id, name } = activeRecord;

        const { status, swimlanes = [] } = data;

        const { lineupFinancialsCSVExport = "" } = this.apiUrls();

        const { exports = {} } = this.clientFeatures();

        const { exportButtonLineupsFinancials } = exports;

        const statusColor = status == "published" ? "#158a47" : "#efa02f";
        const period = GetMonthYearFromDate(lineupPeriod);
        let buttons = [];

        if (exportButtonLineupsFinancials) {
            const filename = `${name}-${lineupPeriod}-LineupFinancialData.csv`;

            buttons.push({
                id: "meta-csv-lineup-export-button",
                title: "Export Lineup Financial Data",
                loading: downloading,
                onClick: () =>
                    this.handleStartDownload({
                        url:
                            lineupFinancialsCSVExport
                                .replace("{usergroup_meta_id}", meta_id)
                                .replace("{period}", lineupPeriod) +
                            "?user_group_name=" +
                            name,
                        method: "GET",
                        filename,
                    }),
            });
        }

        let submitButton = AuthService.hasPermission(
            "lineupPublishSubmitButton",
        );

        const RenderButton = (props) => {
            return (
                <Button
                    value={props.value}
                    type="button"
                    margin="0"
                    padding="0.59375rem 1.96875rem"
                    id={props.id}
                    red={props.red}
                    grey={props.grey}
                    loading={props.loading}
                    onClick={props.onClick ? props.onClick : () => {}}
                    loadingSrc={Settings.images.loadingDotsWhite}
                    loadingIconWidth="1.5rem"
                    marginLeftTop={true}
                />
            );
        };

        return (
            <div
                className={`meta-home-screen-linup-manager ${
                    disabled ? disabledSection : ""
                }`}
            >
                <FadeWithSkeleton
                    loading={fetchingMetaData && _.isEmpty(data) && disabled}
                />

                <div className="meta-record-body--title-content">
                    <h2>
                        {this.copy.metaHomeScreenLinupManager ||
                            "Fabric Connect Homescreen Lineup Manager"}
                    </h2>

                    <div className="container-flex container-align-center container-margin-left-auto">
                        {!disabled && (
                            <React.Fragment>
                                {(status === "pending" ||
                                    status === "submitted") &&
                                    !submitButton && (
                                        <RenderButton
                                            value={
                                                this.copy.publish || "Publish"
                                            }
                                            id="meta-publish-lineup"
                                            loading={publishing}
                                            onClick={this.handleStatusUpdate.bind(
                                                this,
                                                "published",
                                            )}
                                        />
                                    )}

                                {status === "pending" && submitButton && (
                                    <RenderButton
                                        value="Submit"
                                        id="meta-publish-lineup"
                                        loading={submitting}
                                        onClick={this.handleStatusUpdate.bind(
                                            this,
                                            "submitted",
                                        )}
                                    />
                                )}

                                {status === "published" && (
                                    <RenderButton
                                        value={
                                            this.copy.takeDown || "Take Down"
                                        }
                                        red={true}
                                        id="meta-takedown-lineup"
                                        loading={takingDown}
                                        onClick={this.handleStatusUpdate.bind(
                                            this,
                                            "pending",
                                        )}
                                    />
                                )}

                                {status === "submitted" && submitButton && (
                                    <RenderButton
                                        value="Submitted"
                                        grey={true}
                                    />
                                )}
                            </React.Fragment>
                        )}

                        {disabled && (
                            <AddNewButtonWithLineMultiple
                                buttons={buttons}
                                leftIcon={
                                    Settings.images.path +
                                    "/svg/down-arrow-white.svg"
                                }
                            />
                        )}

                        <SelectWithTag
                            id="month_year_date"
                            value={lineupPeriod}
                            clear={true}
                            data={this.dates}
                            group={true}
                            displayTagOnDisabled={true}
                            disabled={fetchingMetaData}
                            loading={fetchingMetaData}
                            width="14.15625rem"
                            noMargin={true}
                            wrapperStyles={{
                                margin: "0 0 0 0.6875rem",
                            }}
                            handleChange={(value) =>
                                handleLineupPeriodChange({
                                    lineupPeriod: value,
                                    handleSuccess: () =>
                                        this.handleLineupManagerRequest(),
                                })
                            }
                        />
                    </div>
                </div>

                <TitleBarWithPager
                    statusColor={statusColor}
                    title={period.month}
                    tag={period.year}
                    disabled={fetchingMetaData}
                    onPreviousClick={this.handleLineupPeriodNextPreviousEvent.bind(
                        this,
                        "previous",
                    )}
                    onNextClick={this.handleLineupPeriodNextPreviousEvent.bind(
                        this,
                        "next",
                    )}
                    styles={{
                        marginTop: "1.21875rem",
                    }}
                />

                {!_.isEmpty(swimlanes) && (
                    <SortableTable
                        items={HomeScreenLineupManagerRows({
                            data,
                            included,
                            disabled,
                            handleEditClick:
                                this.handleSwimlaneEditRecordClick.bind(this),
                            handleDeleteClick:
                                this.handleSwimlaneDeleteRecordClick.bind(this),
                        })}
                        disabled={disabled}
                        styles={{
                            marginTop: "1.96875rem",
                        }}
                        loading={sorting}
                        handleSort={this.handleSwimlaneSort.bind(this)}
                        borderRadiusTop={!_.isEmpty(swimlanes) && !disabled}
                    />
                )}

                {_.isEmpty(swimlanes) && !fetchingMetaData && disabled && (
                    <PlaceholderText
                        styles={{
                            marginTop: "1rem",
                            textAlign: "center",
                        }}
                    />
                )}

                {!disabled && (
                    <AddNewRowButton
                        text="Add new swim lane"
                        onClick={this.handleAddNewSwimlane.bind(this)}
                        data={swimlanes}
                        styles={{
                            marginTop: _.isEmpty(swimlanes) ? "1rem" : "0",
                        }}
                        disabled={!canCreateNew}
                    />
                )}

                <Notification
                    title={notification.title}
                    description={notification.description}
                    intercationStatus="alert"
                    html={notification.html}
                    confirm={notification.confirm}
                    background="white"
                    okText={notification.okText}
                    disabled={notification.disabled}
                    loading={notification.loading}
                    errorMessage={notification.errorMessage}
                    closeOnWrapperClick={false}
                    confirmOnEnter={false}
                    onClick={this.handleNotificationReset.bind(this)}
                />
            </div>
        );

        return false;
    }
}
