import RegionalAgeGuidancePayloadItemInterface from "../../interfaces/RegionalAgeGuidancePayloadItem.interface";
import RegionalAgeGuidanceRowInterface from "../../interfaces/RegionalAgeGuidanceRow.interface";

const TransformRegionalDataIncome = (
    data: Array<RegionalAgeGuidancePayloadItemInterface>,
): Array<RegionalAgeGuidanceRowInterface> => {
    let rows: Array<RegionalAgeGuidanceRowInterface> = [];

    data.forEach((rating: RegionalAgeGuidancePayloadItemInterface) => {
        let makeNewRow = true;

        rows = rows.map((row: RegionalAgeGuidanceRowInterface) => {
            if (
                row.maximum_age == rating.maximum_age &&
                row.minimum_age == rating.minimum_age &&
                !rating.deleted
            ) {
                row.territories.push(rating.territory);
                makeNewRow = false;
            }

            return row;
        });

        if (makeNewRow) {
            let newRow: RegionalAgeGuidanceRowInterface = {
                minimum_age: rating.minimum_age,
                maximum_age: rating.maximum_age,
                territories: [rating.territory],
            };

            if (rating.deleted) {
                newRow.deleted = true;
            }

            rows.push(newRow);
        }
    });

    return rows;
};

export default TransformRegionalDataIncome;
