import { isEmpty } from "lodash"
import { CreditInterface, CreditLocalisedData } from "../store/interfaces"

export default function(credit: CreditInterface, apiCredit: CreditInterface, locale: string): CreditInterface {
    let result = {...credit}

    if (!credit.localised_data) {
        result.localised_data = []
    }

    if (!isEmpty(apiCredit.character_names)) {
        if (!result.localised_data?.find((ld: CreditLocalisedData) => ld.locale === locale)) {
            result.localised_data.push({
                locale,
                character_names: apiCredit.character_names
            })
        }
    }

    return result
}