import React, { useReducer, useContext, createContext, useEffect } from "react";
import reducer, { setState } from "./reducer";
import ProviderPropertiesInterface from "../interfaces/ProviderProperties.interface";
import { useStore as useGlobalStore } from "../../Global/store/store";
import TransformRegionalDataIncome from "../src/transformers/TransformRegionalDataIncome";

const AgeGuidanceContext = createContext({
    store: {},
    dispatch: () => {},
});

export const useStore = () => useContext(AgeGuidanceContext);

export const AgeGuidanceProvider: React.FC<ProviderPropertiesInterface> = (
    props,
) => {
    const {
        handleFormChange = () => {},
        handleFormChangeClassification = () => {},
        disabled = true,
        age_guidance = [],
        classification = {},
        children,
    } = props;

    const [globalStore] = useGlobalStore();

    const { cmsData = {}, options = {} } = globalStore;

    const [store, dispatch] = useReducer(reducer, {
        classification,
        cmsData,
        options,
        handleFormChange,
        handleFormChangeClassification,
        disabled,
        age_guidance,
        deleted: [],
    });
    
    useEffect(() => {
        dispatch(setState({ disabled, age_guidance, classification }));
    }, [disabled]);

    return (
        <AgeGuidanceContext.Provider value={[store, dispatch]}>
            {children}
        </AgeGuidanceContext.Provider>
    );
};
