import _ from "lodash";
import CategoryTags from "../CategoryTags";

export default function getValidationMessages(props) {
    const { status = "", recordData = {}, clientDataStructure = {} } = props;

    const {
        synopses = [],
        descriptors = [],
        certifications = [],
        category = "",
    } = recordData;

    const { digitalReadinessStatus = {} } = clientDataStructure;

    const { validation = {} } = digitalReadinessStatus;

    const validationStatus = validation[status] || {};

    let result = [];

    function excludeCategories(item) {
        return (
            Array.isArray(item.exclude_categories) &&
            item.exclude_categories.includes(category)
        );
    }

    // synopses
    if (!_.isEmpty(validationStatus.synopses)) {
        validationStatus.synopses.forEach((vItem) => {
            if (excludeCategories(vItem)) {
                return;
            }

            const synopsesItem =
                synopses.find((item) => item.type == vItem.type) || {};

            if (_.isEmpty(synopsesItem) || synopsesItem.deleted) {
                result.push(vItem.message);
            }
        });
    }

    // descriptors
    if (!_.isEmpty(validationStatus.descriptors)) {
        validationStatus.descriptors.forEach((vItem) => {
            if (excludeCategories(vItem)) {
                return;
            }

            const descriptorItem = descriptors[vItem.type] || [];

            if (_.isEmpty(descriptorItem.filter((item) => !item.deleted))) {
                result.push(vItem.message);
            }
        });
    }

    if (!_.isEmpty(validationStatus.certifications)) {
        validationStatus.certifications.forEach((vItem) => {
            if (excludeCategories(vItem)) {
                return;
            }

            const certificationItem =
                certifications.find(
                    (item) =>
                        item.certification_type == vItem.certification_type &&
                        item.region == vItem.region &&
                        item.system == vItem.system,
                ) || {};

            if (_.isEmpty(certificationItem) || certificationItem.deleted) {
                result.push(vItem.message);
            }
        });
    }

    return result;
}
