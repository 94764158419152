import _ from "lodash";
import SendRequest from "../../SendRequest";
import {
    GetFromLocalStorage,
    SetLocalStorage,
} from "../../Storage/localStorage";
import { Contributor } from "../types/Contributor/Contributor";
import { ContributorHandlerProps } from "../types/Contributor/ContributorHandlerProps";
import { ContributorRoles } from "../types/Contributor/ContributorRoles";
import { MetaContributor } from "../types/Contributor/MetaContributor";
import { SendRequestOptionsInterface } from "../../SendRequest/interface";

function ContributorHandler(props: ContributorHandlerProps): Contributor {
    const { contributor_roles, item, searchContributorsTerm } = props;

    let role: number | null = null;
    const storeKey: string = `CONTRIBUTOR_${item.contributor}_${item.role}`;
    const roleInOptions: ContributorRoles | undefined = contributor_roles.find(
        (c) => {
            return c.text == item.role;
        },
    );
    const characterNames: string[] = (item && item.character_names) || [];

    if (roleInOptions && _.has(roleInOptions, "value")) {
        role = roleInOptions.value;
    }

    const defaultContributor: Contributor = {
        name: item.contributor,
        character_name: characterNames.join(","),
        script_character_names: characterNames,
        first_name: "",
        last_name: "",
        middle_names: "",
        alternative_name: item.contributor,
        title: null,
        role_name: item.role,
        role: role,
        isValid: !_.isEmpty(roleInOptions),
        storeKey,
    };

    function handleError(
        defaultContributor: Contributor,
        storeKey: string,
    ): void {
        SetLocalStorage({
            name: storeKey,
            value: defaultContributor,
        });
    }

    function onResponse(
        data: MetaContributor,
        defaultContributor: Contributor,
        storeKey: string,
    ): void {
        let result: Contributor;
        if (_.isEmpty(data)) {
            result = defaultContributor;
        } else {
            result = {
                isValid: defaultContributor.isValid,
                name: defaultContributor.name,
                character_name: defaultContributor.character_name,
                script_character_names:
                    defaultContributor.script_character_names,
                role: defaultContributor.role,
                role_name: defaultContributor.role_name,
                storeKey,
                first_name: data.first_name,
                middle_names: data.middle_names,
                alternative_name: data.alternative_name,
                title: data.title,
                last_name: data.last_name,
            };
        }
        SetLocalStorage({
            name: storeKey,
            value: result,
        });
    }

    function request(
        options: SendRequestOptionsInterface,
        defaultContributor: Contributor,
        storeKey: string,
    ): void {
        try {
            SendRequest(
                options,
                (data: { status: number; data: MetaContributor[] }) => {
                    if (data.status == 200) {
                        onResponse(data.data[0], defaultContributor, storeKey);
                    } else {
                        handleError(defaultContributor, storeKey);
                    }
                },
                () => {
                    handleError(defaultContributor, storeKey);
                },
            );
        } catch (e) {
            handleError(defaultContributor, storeKey);
        }
    }

    let localPrimary = GetFromLocalStorage(storeKey);

    if (localPrimary) {
        return localPrimary;
    } else {
        const ops: SendRequestOptionsInterface = {
            url: searchContributorsTerm.replace(
                "{searchTerm}",
                item.contributor,
            ),
            method: "GET",
            testingData: [defaultContributor],
        };
        request(ops, defaultContributor, storeKey);
    }
    return defaultContributor;
}

export default ContributorHandler;
