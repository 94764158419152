import React, { Component } from "react";
import styles from "./styles";
import Settings from "../Settings";
import Input from "../Input/basic";

export default class StarComment extends Component {
    constructor(props) {
        super(props);

        /**
         * @var object
         */
        this.state = {
            text: this.props.text || "",
        };

        this.classStyles = styles(this.props);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.text !== this.props.text) {
            this.setState({
                text: this.props.text,
            });
        }
    }

    getImage() {
        let result = `${Settings.images.path}/svg/star-grey.svg`;

        if (this.state.text) {
            result = `${Settings.images.path}/svg/comment-star-blue.svg`;
        }

        return result;
    }

    handleChange(text) {
        if (text !== this.state.text) {
            this.setState(
                {
                    text,
                },
                () => {
                    if (typeof this.props.handleChange == "function") {
                        this.props.handleChange(text);
                    }
                },
            );
        }
    }

    render() {
        return (
            <div
                style={Object.assign(
                    {},
                    this.classStyles.container,
                    !this.state.text && this.classStyles.noComments,
                    this.props.styles && this.props.styles,
                )}
            >
                <img
                    src={this.getImage()}
                    alt="comment"
                    style={this.classStyles.image}
                />

                <Input
                    placeholder={this.props.noCommentsText || "No comments."}
                    id={this.props.id}
                    value={this.state.text}
                    name={this.props.name}
                    disabled={this.props.disabled}
                    inputShallow={this.props.inputShallow}
                    color={
                        !this.noComments
                            ? "rgba(0, 122, 255, 0.87)"
                            : "rgba(205, 205, 205, 0.87)"
                    }
                    focusColor={
                        !this.noComments
                            ? "rgba(0, 122, 255, 0.87)"
                            : "rgba(205, 205, 205, 0.87)"
                    }
                    width="100%"
                    fontSize="0.78125rem"
                    borderHiddenPadding={true}
                    recordBasic={true}
                    noBorder={this.props.noBorder}
                    inputPadding={this.props.inputPadding}
                    //onBlur={this.handleChange.bind(this)}
                    handleChange={this.handleChange.bind(this)}
                />
            </div>
        );
    }
}
