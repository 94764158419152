import Settings from "../../Settings"

export default function getImage(data: any, icon: boolean, meta_id: string): string {
    if (data.source === "open") {
        let iconPath = icon ? 'icon/' : ''
        return `${Settings.recordImages.path}/${meta_id}/${iconPath}${data.filename}`;
    }

    if (data.source == "bulk upload" && icon) {
        return `${data.filename}?icon`
    }

    

    return data.filename || "";
}