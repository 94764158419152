import _ from "lodash";
import deepcopy from "deepcopy";
import GetCountryCodeByCountryName from "../../Helpers/GetCountryCodeByCountryName";

const CertificationEnrichmentHandler = (props) => {
    const { options = {}, certificate = {} } = props;

    const { rating = "" } = certificate;

    const { certifications = {} } = options;

    const { internal_systems = {} } = certifications;

    const { regions = {} } = internal_systems;

    let certificateData = deepcopy(certificate);

    if (!_.isEmpty(regions)) {
        Object.keys(regions).map((region) => {
            let regionData = regions[region];
            Object.keys(regionData).map((system) => {
                let systemData = regionData[system];
                if (
                    systemData.ratings &&
                    Array.isArray(systemData.ratings) &&
                    !_.isEmpty(systemData.ratings)
                ) {
                    if (systemData.ratings.includes(rating)) {
                        certificateData.system = system;
                        certificateData.region = region;
                        certificateData.country_code =
                            GetCountryCodeByCountryName({
                                options,
                                countryName: region,
                            });
                    }
                }
            });
        });
    }

    return certificateData;
};

export default CertificationEnrichmentHandler;
