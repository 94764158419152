const GetCountryCodeByCountryName = (props) => {
    const { options = {}, countryName = "" } = props;

    const { countries = [] } = options;
    let result = "";
    countries.map((row) => {
        if (row.text && row.value && row.text == countryName) {
            result = row.value;
        }
    });

    return result;
};

export default GetCountryCodeByCountryName;
