import DefaultClientPermissions from "../DefaultClientPermissions";
import FilterPermissionsByRoles from "../FilterPermissionsByRoles";
import _ from "lodash";

export default function ClientPermissions(roles) {
    const {
        Role_SuperAdmin,
        Role_PlatformAdmin,
        Role_Admin,
        Role_Viewer,
        Role_LocalisationManager,
        Role_LanguageManager,
        Role_Okta_User,
        Role_RegionalEditor,
        Role_DataManager,
        Role_SensitiveViewer,
        Role_CollectionsManager,
        Role_FinanceManager,
    } = roles;

    const defaultRoles = [
        Role_SuperAdmin,
        Role_PlatformAdmin,
        Role_Admin,
        Role_Viewer,
        Role_LocalisationManager,
        Role_LanguageManager,
        Role_Okta_User,
        Role_RegionalEditor,
        Role_DataManager,
        Role_SensitiveViewer,
        Role_CollectionsManager,
        Role_FinanceManager,
    ];

    let permissions = DefaultClientPermissions(roles);

    // custom
    permissions.lineupPublishSubmitButton = [Role_LanguageManager, Role_Admin];

    permissions.connect.userGroupManagement.view = [
        Role_SuperAdmin,
        Role_PlatformAdmin,
    ];

    permissions.connect.userGroupManagement.create = [Role_SuperAdmin];

    permissions.connect.manager.information.view = [Role_SuperAdmin];

    permissions.connect.manager.users.view = [Role_SuperAdmin];

    permissions.platformManagement = [Role_SuperAdmin, Role_PlatformAdmin];

    permissions.discovery.edit = [
        Role_SuperAdmin,
        Role_PlatformAdmin,
        Role_Admin,
        Role_LocalisationManager,
        Role_LanguageManager,
        Role_RegionalEditor,
        Role_DataManager,
        Role_FinanceManager,
    ];

    permissions.userAccount = defaultRoles;
    permissions.collections.view = defaultRoles;
    permissions.collections.edit = defaultRoles;
    permissions.financials.view = defaultRoles;
    permissions.discovery.view = defaultRoles;
    permissions.lineupManager.edit = defaultRoles;
    permissions.lineupManager.view = defaultRoles;
    permissions.connect.swimlanes.view = defaultRoles;
    permissions.connect.swimlanes.edit = defaultRoles;

    // additions
    permissions.discovery.create = _.concat(permissions.discovery.create, [
        Role_DataManager,
    ]);

    permissions.collections.create = _.concat(permissions.collections.create, [
        Role_CollectionsManager,
    ]);

    permissions.collections.title.create = _.concat(
        permissions.collections.title.create,
        [Role_CollectionsManager],
    );

    permissions.collections.report.create = _.concat(
        permissions.collections.report.create,
        [Role_CollectionsManager],
    );

    permissions.collections.edit = _.concat(permissions.collections.edit, [
        Role_CollectionsManager,
    ]);

    // filtering roles
    permissions.lineupManager.edit = FilterPermissionsByRoles(
        permissions.lineupManager.edit,
        [
            Role_Viewer,
            Role_SensitiveViewer,
            Role_CollectionsManager,
            Role_FinanceManager,
        ],
    );

    permissions.lineupManager.view = FilterPermissionsByRoles(
        permissions.lineupManager.view,
        [Role_DataManager],
    );

    permissions.collections.edit = FilterPermissionsByRoles(
        permissions.collections.edit,
        [Role_DataManager, Role_SensitiveViewer],
    );

    permissions.collections.view = FilterPermissionsByRoles(
        permissions.collections.view,
        [Role_FinanceManager, Role_DataManager],
    );

    permissions.financials.view = FilterPermissionsByRoles(
        permissions.financials.view,
        [Role_DataManager, Role_SensitiveViewer],
    );

    return permissions;
}
