import { gql } from "@apollo/client";
import { FieldMapInterface } from "../../interfaces";

export const properties = `
    id
    code
    destinationPlatform
    displayName
    order
    archived
    options {
        alternativeKeys
        canBeRoot {
            enabled
        }
        childAssetTypes
        color {
            enabled
            code
        }
        icon {
            enabled
            code
        }
    }
    sections {
        key
        displayName
        order
        archived
        options {
            hidden {
                enabled
            }
            dataPath {
                path
            }
            multiple {
                enabled
            }
        }
        sections {
            key
            displayName
            order
            archived
            options {
                hidden {
                    enabled
                }
                dataPath {
                    path
                }
                multiple {
                    enabled
                }
            }
            fields {
                key
                displayName
                order
                dataType
                options {
                    editable {
                        enabled
                        inputType
                        minLength
                        maxLength
                        booleanDisplay {
                            type
                            trueLabel
                            falseLabel
                        }
                        optionsManagedList {
                            enabled
                            key
                            textField
                            valueField
                            minimumSelectLimit
                            maximumSelectLimit
                            dynamic
                        }
                    }
                    unique {
                        enabled
                    }
                    hidden {
                        enabled
                    }
                    default {
                        enabled
                    }
                    hiddenForCategories
                }
            }
        }
        fields {
            key
            displayName
            order
            dataType
            options {
                editable {
                    enabled
                    inputType
                    minLength
                    maxLength
                    optionsManagedList {
                        enabled
                        key
                        textField
                        valueField
                        minimumSelectLimit
                        maximumSelectLimit
                        dynamic
                    }
                    booleanDisplay {
                        type
                        trueLabel
                        falseLabel
                    }
                }
                unique {
                    enabled
                }
                hidden {
                    enabled
                }
                default {
                    enabled
                }
                hiddenForCategories
            }
        }
    }
`;

export const schemaGetAll = `
    getAssetTypeConfigs {
        ${properties}   
    }
`

export const queryGetAll = gql`
    query {
        ${schemaGetAll}
    }
`;

const map: FieldMapInterface = {
    queryAll: queryGetAll,
    dataPath: "getAssetTypeConfigs",
}

export default map