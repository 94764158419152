import deepcopy from "deepcopy";
import _ from "lodash";

export default function EmptyRecordDataTypeTransformer(data) {
    let result = deepcopy(data);

    if (typeof result == "object" && result) {
        if (result.descriptors) {
            if (
                Array.isArray(result.descriptors) &&
                _.isEmpty(result.descriptors)
            ) {
                result.descriptors = {};
            }
        }
    }

    return result;
}
