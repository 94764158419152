import { Box } from "@mui/material";
import { observer } from "mobx-react";
import { CoreCellText } from "../../../core/Table/components/core-cell-v2/CoreCellText";
import { CoreTableErrorWrapper } from "../../../core/Table/components/CoreTableErrorWrapper";
import { useStores } from "../store/rootStore";
import { AllFieldsVT } from "./types";

export type SP4MPMIDCellProps = {
    value: AllFieldsVT["ids"]["s4_mpm_id"];
    rowId: AllFieldsVT["id"];
};

export const SP4MPMIDCell = observer(({ value, rowId }: SP4MPMIDCellProps) => {
    const stores = useStores();
    const ids = stores.ticketDetailsStore.recordIdsOfDuplicatedSP4MpmIds;
    const component = (
        <CoreCellText color="rgba(149, 149, 149, 1)">
            <span> {value}</span>
        </CoreCellText>
    );

    if (ids.includes(rowId)) {
        return (
            <CoreTableErrorWrapper>
                <Box
                    sx={{
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <CoreCellText color="#D6465E">
                        <span style={{ fontWeight: "bold" }}> {value}</span>
                    </CoreCellText>
                </Box>
            </CoreTableErrorWrapper>
        );
    }
    return component;
});
