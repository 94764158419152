import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./styles";
import Index from "./index";
import _ from "lodash";
import TextareaAutosize from "react-autosize-textarea";

export default class TextArea extends Index {
    componentDidMount() {
        this.isComponentMounted = true;

        this.setCounter();
        this.setCounterOverLimit();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!_.isEqual(this.props.value, nextProps.value)) {
            return true;
        }

        if (!_.isEqual(this.props.disabled, nextProps.disabled)) {
            return true;
        }

        if (!_.isEqual(this.props.display, nextProps.display)) {
            return true;
        }

        if (!_.isEqual(this.props.counter, nextProps.counter)) {
            return true;
        }

        if (!_.isEqual(this.props.className, nextProps.className)) {
            return true;
        }

        if (!_.isEqual(this.props.error, nextProps.error)) {
            return true;
        }

        if (!_.isEqual(this.state, nextState)) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.value !== this.props.value) {
            this.setState(
                {
                    value: this.props.value,
                },
                () => {
                    this.setCounter();
                    this.setCounterOverLimit();
                },
            );
        }
    }

    handleChange(event) {
        if (event) {
            if (event.target) {
                let value = event.target.value;
                let counter = this.state.counter;

                if (this.props.counter) {
                    counter = value.length;
                }

                this.setState(
                    {
                        value,
                        counter,
                    },
                    () => {
                        this.setCounterOverLimit();
                        this.handleChangeCallback();
                    },
                );
            }
        }
    }

    render() {
        const recordBasicOptions = this.props.recordBasic
            ? this.getRecordBasicProps()
            : {};
        const optionsProps = {
            ...this.props,
            ...recordBasicOptions,
        };

        let options = this.getOptions(optionsProps) || {};
        options.style = {
            ...options.style,
            whiteSpace: "no-wrap",
            overflow: "initial",
            textOverflow: "unset",
        };

        return (
            <div
                style={styles(optionsProps, this.state.value).container}
                ref={(node) => {
                    this.node = node;
                }}
            >
                {this.renderLabel()}

                <TextareaAutosize
                    {...options}
                    data-testid={`textArea_autosize_${this.props.name}`}
                    className={`placeholder-light-grey ${
                        this.props.className || ""
                    }`}
                    onResize={(e) => {}}
                />
            </div>
        );
    }
}
