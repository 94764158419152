import _ from 'lodash'
import { makeObservable, action } from 'mobx'
import moment from 'moment'
import update from "react-addons-update";
import { RootStore } from './rootStore'
import config from "../config"
import { ContributorInterface, ContributorImageInterface } from "../../interfaces"
import { envTest } from "../../../../../components/Environment"
import externalLinks from "../../../../../config/external_links"

export class ContributorStore {
    rootStore: RootStore;
    contributorOriginal: ContributorInterface | null
    contributor: ContributorInterface | null
    contributorSectionData: any
    dataKey: string | null
    open: boolean
    working: boolean
    userChanges: ContributorInterface
    ratings: any
    showValidationErrors: boolean

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            getCheckedKeys: action,
            getFabricOriginPersonUrl: action,
            getContributorSectionOriginalData: action,
            setContributorOriginal: action,
            setContributor: action,
            setDataKey: action,
            setContributorSectionData: action,
            setOpen: action,
            setWorking: action,
            setUserChanges: action,
            setRatings: action,
            setShowValidationErrors: action,

            handleSetDataKey: action,
            handleFormChange: action
        });
    }

    getFabricOriginPersonUrl(): string {
        const originId: string | null = this.contributor?.ids?.origin_id

        if (originId) {
            return externalLinks.origin_person_url.replace("{id}", originId)
        }

        return ""
    }

    getAvatarUrl(): string {
        const avatar = this.contributor?.images?.find((item: ContributorImageInterface) => item.avatar)

        if (avatar?.url) {
            return avatar.url
        }

        return ""
    }

    getCheckedKeys(): any {
        const sectionDataKey: string | null = this.rootStore.optionsStore.getActiveSectionDataKey()

        if (!sectionDataKey) {
            return {};
        }

        const {
            code,
            fields = []
        } = this.rootStore.optionsStore.activeSectionConfig || {}

        const mandatoryFields: Array<string> = this.rootStore.optionsStore.getMandatoryFieldsForSection(fields)

        const items: Array<any> = this.contributor[code] || [];

        const filteredItems = items.filter((item: any) =>
            mandatoryFields.every((field: string) => !!item[field])
        );

        const mappedLocales = filteredItems.map((item: any) => item.locale);

        return { 
            [sectionDataKey]: mappedLocales 
        };
    }

    getTranformedContributor() {
        const keysWithDataKey = this.rootStore.optionsStore.getSectionKeysWithDataKey();
        let result: ContributorInterface = {};
        
        Object.keys(this.userChanges).forEach((key) => {
            const section = this.rootStore.optionsStore.getSectionByKey(key);
            
            const mandatoryFields = Array.isArray(section?.fields)
                ? section.fields.filter((field) => field?.options?.mandatory?.enabled).map((field) => field.key)
                : [];

            let items = keysWithDataKey.includes(key) && Array.isArray(this.userChanges[key])
                ? this.userChanges[key].filter((item: any) => mandatoryFields.every((field) => !!item[field]))
                : this.userChanges[key];

            items = Array.isArray(items)
                ? items.map((item: any) => this.transformObject(item, section))
                : this.transformObject(items, section)

            result[key] = items;
        });

        result = _.omit(result, config.transformedFieldsToOmit)

        return result;
    }

    getContributorSectionOriginalData(): any {
        const section = this.rootStore.optionsStore.activeSectionConfig?.code
        return this.contributorOriginal?.[section]
    }

    setWorking(working: boolean): void {
        this.working = working
    }

    setOpen(open: boolean): void {
        this.open = open
    }

    setContributorSectionData(contributorSectionData: any): void {
        this.contributorSectionData = contributorSectionData
    }

    setDataKey(dataKey: string | null): void {
        this.dataKey = dataKey
    }

    setContributorOriginal(contributorOriginal: ContributorInterface | null): void {
        this.contributorOriginal = contributorOriginal
    }

    setContributor(contributor: ContributorInterface | null): void {
        this.contributor = contributor
    }

    setUserChanges(userChanges: ContributorInterface): void {
        this.userChanges = {
            ...this.userChanges,
            ...userChanges
        }
    }

    setRatings(ratings: any): void {
        this.ratings = ratings
    }

    setShowValidationErrors(showValidationErrors: boolean): void {
        this.showValidationErrors = showValidationErrors
    }

    transformObject(items: any, section: any) {
        let result = {}

        Object.keys(items).forEach((key: string) => { 
            const field: any = section?.fields?.find((item: any) => item.key === key)

            if (field?.options?.editable?.inputType === "Date") {
                const itemsKeyDate = _.isObject(items[key])
                    ? items[key].toDate() 
                    : items[key]

                const dateString: Date | string = items[key] ? moment(itemsKeyDate).toDate() : ""
                const date: string = moment(dateString).format(this.rootStore.getDateFormat())

                if (moment(items[key]).isValid()) {
                    result[key] = date
                    return 
                }
            }

            result[key] = items[key]
        })

        return result
    }

    handleSetContributorSectionData(): any {
        const {
            code
        } = this.rootStore.optionsStore.activeSectionConfig || {}

        const dataKey: string | null = this.rootStore.optionsStore.getActiveSectionDataKey()

        if (!this.contributor) {
            return this.setContributorSectionData({});
        }

        if (!this.contributor[code]) {
            return this.setContributorSectionData({});
        }

        const result = dataKey && Array.isArray(this.contributor[code]) && this.dataKey
            ? this.contributor[code].find((item: any) => item[dataKey] === this.dataKey) || {}
            : this.contributor[code];

        return this.setContributorSectionData(result || {});
    }

    handleSetDataKey(): void {
        if (this.rootStore.optionsStore.activeSectionConfig?.options?.dataKey === 'locale') {
            this.setDataKey(this.rootStore.optionsStore.defaultLanguageCode)
        }
    }

    handleFormChange(values: any, field: any): void {
        const {
            code
        } = this.rootStore.optionsStore.activeSectionConfig

        this.setShowValidationErrors(false)

        const sectionCode: string = this.rootStore.optionsStore.getActiveSectionCode()
        const dataKey: string | null = this.rootStore.optionsStore.getActiveSectionDataKey()

        if (values[dataKey]) {
            this.setDataKey(values[dataKey]);
        }

        if (dataKey) {
            let sectionData = Array.isArray(this.contributor[code]) 
                ? this.contributor[code] 
                : [];

            const itemIndex = sectionData.findIndex((it: any) => it[dataKey] == values[dataKey]);

            if (itemIndex === -1) {
                sectionData = update(sectionData, { 
                    $push: [{
                        locale: values[dataKey],
                        [field?.name]: values[field?.name]
                    }] 
                });

            } else if (field?.name !== dataKey) {
                sectionData = update(sectionData, { 
                    [itemIndex]: { 
                        $set: values 
                    } 
                });
            }

            const updatedState = update(this.contributor, { [code]: { $set: sectionData } });
            this.setContributor(updatedState)

            this.setUserChanges({
                [sectionCode]: updatedState[sectionCode]
            })
        } else {
            const updatedState = update(this.contributor, { [code]: { $set: values } });
            this.setContributor(updatedState)

            this.setUserChanges({
                [sectionCode]: updatedState[sectionCode]
            })
        }

        this.handleSetContributorSectionData();
        this.rootStore.validationStore.handleContributorValidation()

        if (!envTest) {
            this.rootStore.integrationsStore.handleContributorChange()
        }
    }

    handleSetImages(images: Array<any>): void {
        this.setContributor({
            ...this.contributor,
            images
        })

        this.setUserChanges({
            images
        })
    }

    handleSetOriginImage(image: ContributorImageInterface): void {
        this.setContributor({
            ...this.contributor,
            images: [image]
        })

        this.setUserChanges({
            images: [image]
        })
    }
}
