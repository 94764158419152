import _ from "lodash";
import SendRequest from "../../../components/SendRequest";
import GetApiUrls from "../GetApiUrls";
import HandleRequestError from "../HandleRequestError";

const HandleSwimlaneUpdateRequest = (props) => {
    const { state = {}, options = {} } = props;

    const { swimlane = {}, handleError, handleSuccess } = options;

    const apiUrls = GetApiUrls(state);

    const requestOptions = {
        url: apiUrls.swimlane.replace("{meta_id}", swimlane.meta_id),
        method: "PUT",
        body: swimlane,
    };

    try {
        SendRequest(
            requestOptions,
            (response) => {
                const { message, status } = response;

                if (status == 200) {
                    handleSuccess();
                    return;
                }

                handleError(message);
            },
            (error) => {
                handleError(error);
            },
        );
    } catch (e) {
        handleError(e);
    }
};

export default HandleSwimlaneUpdateRequest;
