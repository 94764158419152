import IPlatformScheduling from "../platforms/PlatformSchedulingInterface";
import moment from "moment";
import _ from "lodash";

const IsActiveWindow = (schedule: IPlatformScheduling): boolean => {
    const { put_up_date = "", take_down_date = "" } = schedule;

    if (_.isEmpty(put_up_date) || _.isEmpty(take_down_date)) {
        return false;
    }

    const currentTimeInt = moment().unix();

    return (
        moment(put_up_date).unix() < currentTimeInt &&
        currentTimeInt < moment(take_down_date).unix()
    );
};

export default IsActiveWindow;
