import React, { Component, Fragment } from "react";
import _ from "lodash";
import DOMPurify from "isomorphic-dompurify";
import classstyles from "./styles";

export default class Paragraph extends Component {
    render() {
        const {
            styles = {},
            items = [],
            label,
            customLabelStyles = {},
            lineClamp,
            maxCharacters,
            moreButtonBackground = "#e6b91e",
            className = "",
            testId = "description"
        } = this.props;

        const lineClampStyles = {
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: lineClamp,
            WebkitBoxOrient: "vertical",
        };

        const moreButtonStyles = {
            background: moreButtonBackground,
            fontSize: "0.78125rem",
            fontWeight: 500,
            letterSpacing: "0.015625rem",
            color: "#131515",
            padding: "0.21875rem",
            textTransform: "uppercase",
            borderRadius: "0.15625rem",
            marginLeft: "auto",
        };

        const paragraphStyles = Object.assign(
            {},
            classstyles(this.props).paragraph,
            lineClamp && lineClampStyles,
        );

        const labelStyles = Object.assign(
            {},
            classstyles(this.props).label,
            customLabelStyles && customLabelStyles,
        );

        if (Array.isArray(items)) {
            return (
                <div style={styles}>
                    {label && <span style={labelStyles}>{label}</span>}

                    {items.map((item, index) => {
                        if (typeof item == "string" || Number.isInteger(item)) {
                            let displayEclipse = false;
                            let itemStyles = paragraphStyles;

                            if (maxCharacters && item.length > maxCharacters) {
                                displayEclipse = true;
                                itemStyles = {
                                    ...paragraphStyles,
                                    ...lineClampStyles,
                                };
                            }

                            return (
                                <Fragment key={index}>
                                    <p
                                        style={itemStyles}
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(item),
                                        }}
                                        data-testid={`${testId}-${index + 1}`}
                                        className={`meta-paragraph ${className}`}
                                    ></p>

                                    {displayEclipse && (
                                        <span
                                            className="read-more"
                                            style={moreButtonStyles}
                                        >
                                            more
                                        </span>
                                    )}
                                </Fragment>
                            );
                        }

                        if (typeof item == "object") {
                            return (
                                <span style={paragraphStyles} key={index}>
                                    {item}
                                </span>
                            );
                        }
                    })}
                </div>
            );
        }

        return false;
    }
}
