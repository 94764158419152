const OriginalTitleDotYearText = (props) => {
    const { first_release_year = false, original_title = "" } = props;

    let text = original_title;

    if (first_release_year) {
        text += " • " + first_release_year;
    }

    return text;
};

export default OriginalTitleDotYearText;
