import React from "react";
import "./styles/_styles.scss";

const SelectAllToggle = (props) => {
    const { copy = {}, selected = false, onClick = () => {} } = props;

    return (
        <div className="meta-select-all-toggle">
            <span className="lable">{copy.selectAll || "Select All"}</span>
            <div
                className="toggle"
                data-selected={selected}
                onClick={() => onClick()}
            ></div>
        </div>
    );
};

export default SelectAllToggle;
