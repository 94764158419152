import React, { useState, useContext, useEffect, useRef } from "react";
import styles from "./styles";
import CounterLabel from "./counterLabel";
import LabelAudit from "../AuditTooltip/LabelAudit";
import AuditContext from "../AuditTooltip/context/Context";
import { active, inactive, mouseIn } from "../AuditTooltip/src/LabelStates";

const Label = (props) => {
    const {
        label,
        required,
        state = {},
        error,
        counterStyles = {},
        labelStyles = {},
        fieldKey = null,
        name = null,
    } = props;

    const classStyles = styles(props);
    const dataKey = fieldKey || name;

    const auditContext = useContext(AuditContext);
    const [auditState, setAuditState] = useState(inactive);
    const countRef = useRef(auditState);
    countRef.current = auditState;

    useEffect(() => {
        setTimeout(function () {
            if (countRef.current.changeColor) setAuditState(active);
        }, 500);
    }, [auditState]);

    const handleShowAuditSet = (show = false) => {
        if (!auditContext.state.activeAudits) return;
        setAuditState(show ? mouseIn : inactive);
    };

    if (label) {
        const { counter, counterOverLimit } = state;

        return (
            <label
                style={Object.assign(
                    {},
                    classStyles.label,
                    labelStyles,
                    error && classStyles.labelError,
                    auditState.changeColor && classStyles.labelAudit,
                )}
                onMouseEnter={(e) => handleShowAuditSet(true)}
                onMouseLeave={(e) => handleShowAuditSet()}
            >
                {auditState.showAudit && <LabelAudit labelKey={dataKey} />}

                {label}

                {required && (
                    <span
                        style={Object.assign(
                            {},
                            classStyles.required,
                            error && classStyles.labelError,
                        )}
                    >
                        {" "}
                        - Required
                    </span>
                )}

                {props.counter && (
                    <CounterLabel
                        counter={counter}
                        counterOverLimit={counterOverLimit}
                        counterStyles={counterStyles}
                    />
                )}
            </label>
        );
    }

    return false;
};

export default Label;
