import React from "react";
import { Skeleton, Stack } from "@mui/material";
import "./styles/_styles.scss";

const TabSectionSkeleton = () => {
    return (
        <div className="skeleton--tab-section">
            <Stack spacing={2} className="fabric--stack">
                <Skeleton width="10.625rem" height="1.625rem" />

                <Skeleton width="5rem" height="0.9375rem" />

                <Skeleton height="2.5rem" />

                <Skeleton />
            </Stack>
        </div>
    );
};

export default TabSectionSkeleton;
