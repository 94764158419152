import React from "react";
import _ from "lodash";
import Index from "./Index";
import deepcopy from "deepcopy";
import { diff } from "deep-object-diff";
import BubbleList from "../../BubbleList";

export default class ContentAdvisories extends Index {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const nextPropsData = nextProps.data || {};
        const thisPropsData = this.props.data || {};
        const difference = diff(nextPropsData, thisPropsData);

        if (!_.isEmpty(difference)) {
            return true;
        }

        if (!_.isEqual(nextProps.disabled, this.props.disabled)) {
            return true;
        }

        if (!_.isEqual(nextState, this.state)) {
            return true;
        }

        return false;
    }

    handleChange(value) {
        if (typeof this.props.handleFormChange == "function") {
            value = value.filter((row) => !row.deleted).map((row) => row.text);
            this.props.handleFormChange(value);
        }
    }

    renderContent() {
        const data = deepcopy(this.props.data || []);

        const { disabled, options = {} } = this.props;

        const { content_advisories = [] } = options;

        const PrepareData = (data) => {
            data = data.map((item) => {
                return {
                    text: item,
                    ranking: 0,
                };
            });
            return data;
        };

        return (
            <div style={this.styles.marginTopSmall}>
                <BubbleList
                    name="meta-content-advisories"
                    id="meta-content-advisories"
                    inputId={"meta-content-advisories" + "_inputId"}
                    background="#e55151"
                    color="white"
                    deleteWhite={true}
                    readOnly={disabled}
                    emptyPlaceholder="Start typing Content Advisory"
                    data={PrepareData(data || [])}
                    handleFormChange={(item) => this.handleChange(item)}
                    datalistId={"meta-content-advisories-data_list"}
                    datalist={content_advisories}
                    ranking={true}
                    dataListUseEnterButton={true}
                    filterStaticDataList={true}
                    limitResults={50}
                    handleNoMatchText={true}
                    noMatchText={"No match for {value}. Create New?"}
                    showNoneEntered={false}
                />
            </div>
        );
    }
}
