export const routeBackdoor =
    process.env.BACKDOOR_PATH || "/login/admin/backdoor/access";
export const routeLogin = "/login";
export const routeWelcome = "/welcome";
export const routePasswordResetWithToken = "/password/reset/:token";
export const routeClientSelect = "/client-select";
export const routeRecordsSearch = "/records-search";
export const routeDiscovery = "/discovery";
export const routeCollections = "/collections";
export const routeLocalisations = "/localisations";
export const routeConnectCollections = "/connect-collections";
export const routeReporting = "/reporting";
export const routeAdvancedReporting = "/advanced-reporting";
export const routeAdvancedReportingExplore = "/advanced-reporting-explore";
export const routeAdvancedReportingExplore1 = "/advanced-reporting-explore-1";
export const routeAdvancedReportingExplore2 = "/advanced-reporting-explore-2";
export const routeAdvancedReportingExplore3 = "/advanced-reporting-explore-3";
export const routeAdvancedReportingExplore4 = "/advanced-reporting-explore-4";
export const routeAdvancedReportingExplore5 = "/advanced-reporting-explore-5";
export const routeConnectManager = "/connect-manager";
export const routeLineupManager = "/lineup-manager";
export const routeAccount = "/account";
export const routePlatformManagement = "/platform-management";
export const routeUserRoleManagement = "/user-role-management";
export const routeRecordBuilder = "/record-builder";
export const routePreferences = "/preferences";
export const routeTaskManager = "/task-manager";
export const routeTaskManagerTicket = "/task-manager/ticket/:id";
export const routeTaskManagerNewTicket = "/task-manager/new/:type";
export const routeLogout = "/logout";
export const routeHelp =
    "https://metahive.atlassian.net/servicedesk/customer/portal/2";
export const routeConnectManagement = "/connect-management";

export const preLoginRoutes = [
    routeLogin,
    routeWelcome,
    routePasswordResetWithToken,
    routeClientSelect,
    routeBackdoor,
];

export const generalRoutes = [
    routeRecordsSearch,
    routeDiscovery,
    routeCollections,
    routeLocalisations,
    routeConnectCollections,
    routeReporting,
    routeAdvancedReporting,
    routeAdvancedReportingExplore,
    routeAdvancedReportingExplore1,
    routeAdvancedReportingExplore2,
    routeAdvancedReportingExplore3,
    routeAdvancedReportingExplore4,
    routeAdvancedReportingExplore5,
    routeConnectManager,
    routeLineupManager,
    routeAccount,
];

export const managementRoutes = [
    routePlatformManagement,
    routePreferences,
    routeUserRoleManagement,
    routeRecordBuilder,
    routeTaskManager,
    routeTaskManagerTicket,
    routeTaskManagerNewTicket,
];

export const taskManagerRoutes = [
    routeTaskManager,
    routeTaskManagerTicket,
    routeTaskManagerNewTicket,
];
