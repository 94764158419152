import deepcopy from "deepcopy";
import _ from "lodash";

export default function ValidateAssets(result = {}, options = {}) {
    let response = deepcopy(result);

    const { data = {}, copy = {}, status, clientFeatures = {} } = options;

    const { assets, origination = {} } = data;

    let validate = false;
    if (
        clientFeatures.assets &&
        typeof clientFeatures.assets === "object" &&
        "tmcAssetValidation" in clientFeatures.assets &&
        clientFeatures.assets.tmcAssetValidation == true
    )
        validate = true;

    if (
        Array.isArray(assets) &&
        !_.isEmpty(assets) &&
        !origination.release_duration &&
        validate
    ) {
        response.result = false;
        response.notification = {
            title: copy.recordCouldNotBeSaved || `Record could not be Saved`,
            description:
                copy.tmcValidationDescription ||
                "Assets could not be created, as the Record has failed TMC Validation. Please check record for Mandatory TMC Data Attributes and retry.",
            status,
        };

        response.validationKeys = result.validationKeys.concat([
            "release_duration",
        ]);
    }

    return response;
}
