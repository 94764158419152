import React from "react";
import { SectionInterface } from "./interface";
import ProgrammingDates from "../../views/ProgrammingDates";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";

const SectionProgrammingDates = (props: SectionInterface) => {
    const { cmsData = {}, data = {} } = props;

    const { clientFeatures = {} } = cmsData;

    const { programming_dates = [] } = data;

    return (
        <ProgrammingDates
            {...getSectionCommonProps({ ...props, field: "programming_dates" })}
            name="programming"
            data={programming_dates}
            title="Programming"
            clientFeatures={clientFeatures}
            dataFromProps={true}
            category={data.category || null}
        />
    );
};

export default SectionProgrammingDates;
