import { GridRenderCellParams } from "@mui/x-data-grid";
import { isEmpty } from 'lodash'
import { CoreTableGridColDef } from "../../types";
import SeasonAndEpisodeNumber from "../../../../components/Tags/SeasonAndEpisodeNumber";
import SeasonAndEpisodeNumberV2 from "../../../../components/Tags/SeasonAndEpisodeNumberV2";
import { useStore as globalStore } from "../../../../components/Global/store/store";

const RenderNumbering = ({ row }) => {
    const [globalState] = globalStore() || [{}];

    const { 
        linkage_numbering_tags = [] 
    } = globalState?.cmsData?.clientDataStructure || {};

    const { 
        linkageNumberingV2 = false 
    } = globalState?.cmsData?.clientFeatures || {};

    if (!isEmpty(linkage_numbering_tags) && linkageNumberingV2) {
        return SeasonAndEpisodeNumberV2({
            ...row,
            className: "meta-linkage--table-episode-tag",
            structure: linkage_numbering_tags,
        });
    }

    return SeasonAndEpisodeNumber({
        ...row,
        episodeClassName: "meta-linkage--table-episode-tag",
    });
}

export const NumberingCol: CoreTableGridColDef = {
    field: "episode_number",
    headerName: "Episode Number",
    flex: 1,
    renderCell: (params: GridRenderCellParams<string>) => {
        const { row } = params;

        return <RenderNumbering row={row} />
    }
};
