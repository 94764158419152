import {
    StatusApproved,
    StatusAwaitingApproval,
    StatusCancelled,
    StatusChangeInProgress,
    StatusDraft,
    StatusRejected,
    StatusSubmissionInProgress,
    StatusSubmitted,
    StatusSubmissionRejected,
} from "../../types/constants";
import { TaskManagerPermissionsSchema } from "../../types/interfaces";
import PermissionPathsInterface from "./PermissionPathsInterface";

class RecordUpdatePermissionPaths implements PermissionPathsInterface {
    private statusesToPermissions: {
        [
            k: string
        ]: keyof TaskManagerPermissionsSchema["ticketTypes"]["recordRequest"]["statuses"];
    } = {
        [StatusDraft.label]: "draft",
        [StatusAwaitingApproval.label]: "awaitingApproval",
        [StatusRejected.label]: "rejected",
        [StatusApproved.label]: "approved",
        [StatusChangeInProgress.label]: "changeInProgress",
        [StatusCancelled.label]: "cancelled",
        [StatusSubmissionInProgress.label]: "submissionInProgress",
        [StatusSubmitted.label]: "submitted",
        [StatusSubmissionRejected.label]: "submissionRejected",
    };

    getApprovalPaths() {
        return {
            own: "ticketTypes.recordUpdate.approvals.own",
            other: "ticketTypes.recordUpdate.approvals.other",
        };
    }

    getCRUDPath() {
        return "ticketTypes.recordUpdate.type";
    }

    getTicketCommentPath() {
        return "ticketTypes.recordUpdate.comments";
    }

    getStatusesPaths() {
        return [
            StatusDraft,
            StatusAwaitingApproval,
            StatusApproved,
            StatusRejected,
            StatusChangeInProgress,
            StatusCancelled,
            StatusSubmissionInProgress,
            StatusSubmitted,
            StatusSubmissionRejected,
        ].map((status) => ({
            status: status,
            path: `ticketTypes.recordUpdate.statuses.${
                this.statusesToPermissions[status.label]
            }`,
        }));
    }
}

export default RecordUpdatePermissionPaths;
