import AssetInterface from "../../components/asset/interfaces/AssetInterface";
import { SET_NEW_ASSET, CONFIRM_ASSET_EDIT } from "./index";

export const setEditAsset = (asset: AssetInterface) => {
    return {
        type: SET_NEW_ASSET,
        state: { editAsset: asset },
    };
};

export const confirmAssetEdit = (
    asset: AssetInterface,
    assets: Array<AssetInterface>,
) => {
    const { uuid = null } = asset;

    const EditAssetByUUID = (assetsStore): Array<AssetInterface> => {
        return assetsStore.map((item: AssetInterface) => {
            if (item.uuid == uuid) {
                return Object.assign({}, { ...item }, { ...asset });
            }

            if (item.child_assets) {
                item.child_assets = EditAssetByUUID(item.child_assets);
            }

            return item;
        });
    };

    return {
        type: CONFIRM_ASSET_EDIT,
        state: {
            assets: EditAssetByUUID(assets),
            editAsset: {},
            notification: {},
        },
    };
};
