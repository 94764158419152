import { SET_RECORD_CHANGES_UUID } from "../actions/action_types";
import { defaultRecordChangesUuid } from "../initialState";

export default function (
    state = { recordChangesUuid: defaultRecordChangesUuid },
    action,
) {
    switch (action.type) {
        case SET_RECORD_CHANGES_UUID:
            return Object.assign({}, state, {
                recordChangesUuid: action.recordChangesUuid,
            });
    }

    return state;
}
