import deepcopy from "deepcopy";
import ConfigModel, { DataType, ValidationLayer } from '../../../models/RecordBuilder/Field.interface'
import _ from "lodash"
import BuilderFilterByCategory from '../RecordBuilder/map/filterCategory'
import { userHasPermission } from "../../../services/PermissionService";
import { originationCustomFieldsSection } from "../../../features/UserRoleManagement/utils/sectionOptions/recordSections";
import { accessTypeUpdate, accessTypeView } from "../../../features/UserRoleManagement/utils/accessOptions";
import ValidateOrigination from './Origination'
import OriginationField, { Section } from "../../../models/RecordBuilder/OriginationField.interface"

export default function ValidateCustomFields(
    result: any,
    options: any,
) {
    const {
        userPermissions = {},
        cmsData = {},
        data = {},
        builderConfig = {},
    } = options;

    const hasAccess = (accessType) => {
        return userHasPermission({
            cmsData,
            userPermissions,
            pathField: originationCustomFieldsSection.path,
            access: accessType,
            prefix: data.category,
        })
    }

    if(!hasAccess(accessTypeView) || !hasAccess(accessTypeUpdate)) {
        return result
    }
    
    let configuration = _.get(builderConfig, 'origination', [])
        .filter((config: OriginationField) => config.section == Section.CustomFields)

    const { clientFeatures = {} } = cmsData;
    const { recordBuilder = {} } = clientFeatures
    const { customFieldsPayloadV2 = false } = recordBuilder

    const payloadKey: string = customFieldsPayloadV2
        ? "origination_v2"
        : "origination"
    

    return ValidateOrigination(
        result,
        options,
        configuration,
        data[payloadKey] || {}
    );
}
