import React from "react";
import { isEmpty } from "lodash"
import Settings from "../../../../Settings"
import "../../../styles/_multi_level_expandable.scss"

interface HeaderInterface {
    className?: string,
    level: number,
    tags: Array<string>,
    name: string,
    expandAll?: boolean
}

interface RenderCubeInterface {
    level: number
}

interface RenderTagInterface {
    text: string
}

const cubeColors: any = {
    1: "expandable-icon-one",
    2: "expandable-icon-two",
    3: "expandable-icon-three"
}

const RenderCube = ({ level }: RenderCubeInterface) => {
    return (
        <img 
            src={`${Settings.images.path}/svg/${cubeColors[level] || cubeColors['1']}.svg`}
            className="header-right--cube"
        />
    )
}

const RenderTag = ({ text }: RenderTagInterface) => {
    return (
        <div className="header-right--tag">
            {text}
        </div>
    )
}

const RenderExpandAll = ({ expandAll }) => {
    return (
        <div
            className="header-right--expand-all"
            onClick={(e) => {}}>

            <img src={`${Settings.images.path}/svg/${expandAll ? "arrows-expand-all" : "arrows-collapse-all"}.svg`}/>
        </div>
    )
}

const Header = (props: HeaderInterface) => {
    const {
        level,
        tags,
        name,
        expandAll = true
    } = props

    return (
        <div className="record-builder-expandable--header">
            <div className="record-builder-expandable--header-left">
                <RenderCube level={level}/>

                <span className="header-left--title">{name}</span>
            </div>

            <div className="record-builder-expandable--header-right">
                {!isEmpty(tags) &&
                    <>
                        {tags.map((tag, tagIndex) => {
                            return (
                                <RenderTag 
                                    text={tag}
                                    key={`level-${level}-${tagIndex}`}
                                />
                            )
                        })}
                    </>
                }   

                {expandAll &&
                    <RenderExpandAll expandAll={false}/>
                } 
            </div>
        </div>
    )
}

export default Header;
