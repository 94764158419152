import { DataInterface, ContentInterface } from "./interface";
import moment from "moment";
import _ from "lodash";
import momentTz from "moment-timezone";

const tz: string = momentTz.tz.guess();

function getLeftText(leftText: string, newRecord: boolean): string {
    let result: string = "Created";

    if (leftText) {
        result = leftText;
    }

    if (newRecord) {
        result = "";
    }

    return result;
}

function getCreatedAt(created_at: string, clientDataStructure: any): string {
    const { date_format = "YYYY.MM.DD" } = clientDataStructure;

    const defaultDate: any = momentTz.tz(created_at, "UTC");

    let result: string = tz
        ? defaultDate.clone().tz(tz).format(`${date_format} • HH:mm`)
        : moment(created_at).format(`${date_format} • HH:mm`);

    return result;
}

function getCreatedByName(
    created_by_name: string,
    created_by: string,
    newRecord: boolean,
): string {
    let result: string = created_by_name || "";

    if (!created_by) {
        result = "API";

        if (created_by_name) {
            result = `API • ${created_by_name}`;
        }

        if (newRecord) {
            result = "";
        }
    }

    return result;
}

export function getTagContent(props: DataInterface): ContentInterface {
    const {
        clientDataStructure,
        leftText = "",
        created_at,
        created_by_name = "",
        newRecord = false,
        created_by = "",
    } = props;

    return {
        leftText: getLeftText(leftText, newRecord),
        created_at: getCreatedAt(created_at, clientDataStructure),
        created_by_name: getCreatedByName(
            created_by_name,
            created_by,
            newRecord,
        ),
    };
}
