import React, { useEffect } from "react";
import "./styles/_styles.scss";
import CircleInput from "../CircleInput";
import Settings from "../Settings";
import $ from "jquery";

const TrafficLightMenuSelector = (props) => {
    const {
        options = [],
        selectedOption = 0,
        styles = {},
        handleClick = () => {},
    } = props;

    useEffect(() => {
        const id = `#meta-traffic-light-menu--item-${selectedOption}`;
        const position = $(id).position();

        if (position) {
            const positionInRem = position.left / 16;
            const iconMarginInRem = 0.35;

            $("#meta-traffic-light-menu--selector").animate(
                {
                    left: `${positionInRem + iconMarginInRem}rem`,
                },
                150,
            );
        }
    });

    return (
        <div className="meta-traffic-light-menu" style={styles}>
            <div className="meta-traffic-light-menu--items">
                {options.map((option, index) => {
                    const { color, title } = option;

                    const innerBackground =
                        typeof color == "string" ? color : false;
                    const disabled = color == "disabled";

                    return (
                        <CircleInput
                            key={index}
                            className="meta-traffic-light-menu--item"
                            id={`meta-traffic-light-menu--item-${index}`}
                            background="#dfdfdf"
                            innerBackground={innerBackground}
                            width="0.85rem"
                            borderWidth="0.2rem"
                            disabled={disabled}
                            title={title}
                            onClick={handleClick.bind(this, index)}
                        />
                    );
                })}
            </div>

            <img
                src={`${Settings.images.path}/svg/arrow-up-grey.svg`}
                id="meta-traffic-light-menu--selector"
            />
        </div>
    );
};

export default TrafficLightMenuSelector;
