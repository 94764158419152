import { GridRenderCellParams } from "@mui/x-data-grid";
import { CoreTableGridColDef } from "../../types";
import { useStore as globalStore } from "../../../../components/Global/store/store";
import Tag from "../../../../components/Tag";

export const CustomIdsCol: CoreTableGridColDef = {
    field: "custom_ids",
    headerName: "Custom IDs",
    flex: 1,
    renderCell: (params: GridRenderCellParams<string>) => {
        const { row } = params;

        const [globalState] = globalStore() || [{}];

        const { 
            linkage = {} 
        } = globalState?.cmsData?.clientFeatures || {};

        const { 
            customIds
        } = linkage;

        return (
            <div>
                {customIds.map((it: string, index: number) => {
                    if (!row[it]) {
                        return <></>
                    }

                    return (
                         <Tag
                            text={row[it]}
                            key={index}
                            styles={{
                                fontSize: "0.78125rem",
                                fontWeight: 500,
                                padding: "0.25rem 0.3125rem",
                            }}
                        />
                    )
                })}
            </div>
        )
    }
};
