import React from "react";
import { SectionInterface } from "./interface";
import SeasonRunningOrders from "../../views/SeasonRunningOrders";
import CategoryTags from "../../../CategoryTags";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";

const SectionSeasonRunningOrders = (props: SectionInterface) => {
    const { cmsData = {}, data = {} } = props;

    const { clientFeatures = {} } = cmsData;

    const linkage = data.linkage || [];

    let episodes = [];
    let storylines = [];
    let constructedEpisodes = [];

    if (Array.isArray(linkage)) {
        episodes = linkage.filter(
            (l) => l.category == CategoryTags.Episode.short,
        );
        storylines = linkage.filter(
            (l) => l.category == CategoryTags.Storyline.short,
        );
        constructedEpisodes = linkage.filter(
            (l) => l.category == CategoryTags.ConstructedEpisode.short,
        );
    }

    return (
        <SeasonRunningOrders
            {...getSectionCommonProps({ ...props, field: "running_order" })}
            name="season-running-orders"
            className="meta-record-season-running-orders"
            title="Season Running Orders"
            data={data.running_order || []}
            episodes={episodes || []}
            storylines={storylines || []}
            constructedEpisodes={constructedEpisodes || []}
            lastSection={true}
            clientFeatures={clientFeatures}
        />
    );
};

export default SectionSeasonRunningOrders;
