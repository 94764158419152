import _ from "lodash";
import deepcopy from "deepcopy";
import SendRequest from "../../components/SendRequest";
import { getTransformedRecordFromApi } from "../../models/Record/model"

const HandleRecordMetaDataRequest = (props) => {
    const { state = {}, callBack = () => {} } = props;

    const { set, cmsData = {}, activeRecord = {} } = state;

    const { apiUrls = {} } = cmsData;

    set({
        fetchingMetaData: true,
    });

    const options = {
        url: apiUrls.metaDataUrl.replace("{meta_id}", activeRecord.meta_id),
        method: "GET",
    };

    const handleError = (error) => {
        callBack();

        set({
            fetchingMetaData: false,
            notification: {
                title: "Technical Error",
                description: "Could not fetch record meta data",
                status: "alert",
                onClick: () => {
                    set({
                        notification: {},
                    });
                },
            },
        });

        if (error) {
            console.log(error);
        }
    };

    SendRequest(
        options,
        (response) => {
            const { status, data, message } = response;

            if (status == 200) {
                const record = getTransformedRecordFromApi(data, cmsData)

                set({
                    fetchingMetaData: false,
                    recordData: deepcopy(record),
                    originalRecord: deepcopy(record),
                });

                callBack(record);

                return;
            }

            // On Error
            handleError(message);
        },
        (e) => {
            handleError(e);
        },
    );
};

export default HandleRecordMetaDataRequest;
