import React from "react";
import Settings from "../Settings";

const ActionPlayButton = (props) => {
    const { disabled, styles = {}, onClick } = props;

    let background = "#626262";
    let image = "chevron-right-green";

    if (disabled) {
        background = "#737373";
        image = "chevron-right-grey";
    }

    let containerStyles = {
        ...Settings.components.actionButton.container,
        ...styles,
        background,
        padding: 0,
        width: "1.6875rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
    };

    return (
        <div
            style={containerStyles}
            onClick={onClick}
            className="meta-asset-action-play-button"
        >
            <img
                src={`${Settings.images.path}/svg/${image}.svg`}
                style={{ width: "0.40625rem" }}
            />
        </div>
    );
};

export default ActionPlayButton;
