import React from 'react'
import { makeObservable, action, extendObservable } from 'mobx'
import { findIndex, isEmpty, isObject } from "lodash"
import update from "react-addons-update";
import { RootStore } from './rootStore'
import { creditListsStoreInitialState } from "./state"
import { CreditListInterface, CreditInterface, CreditRowInterface } from "./interfaces"
import { getRecordCreditLists, getCreditListCredits } from "../../../../services/Record/CreditsService"
import { creditLists, creditsResponse } from "../test-react/testData"
import { apiExistingCreditFields, apiNewCreditFields } from "../config/creditListForm.config"
import { apiCreditListFields, apiCreditListCreateFields, apiCreditListUpdateFields, defaultCreditLists, originalCreditListCode } from "../config/creditList.config"
import { envTest } from "../../../../components/Environment"

export class CreditListsStore {
    rootStore: RootStore;
    fetching: boolean;
    error: boolean | string;
    creditLists: CreditListInterface[];
    originalCreditLists: CreditListInterface[];
    selectedCreditList: CreditListInterface | {}
    defaultCreditListCode: string

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            initialize: action,

            setFetching: action,
            setError: action,
            setCreditLists: action,
            setOriginalCreditLists: action,
            setSelectedCreditList: action,
            handleDeleteCreditList: action,
            handleUpdateCreditsInCreditList: action,
            handleSetSelectedCreditListByIdOrCode: action,
            handleAddCreditToCreditList: action,
            handleDeleteCreditFromCreditLists: action,
            handleDeleteCreditFromActiveCreditList: action,
            handleAddCreditsToCreditList: action,
            handleFetchCredits: action
        });

        extendObservable(this, creditListsStoreInitialState);
    }

    initialize() {
        this.reset()
        this.setFetching(true)
    }

    reset() {
        Object.keys(creditListsStoreInitialState).forEach(
            (key) => (this[key] = creditListsStoreInitialState[key]),
        );
    }

    getCreditListFooterText(): string {
        if (!this.selectedCreditList?.name) {
            return "No Credit List Found"
        }

        const result: string[] = [`${this.selectedCreditList?.name} • ${this.selectedCreditList?.code}`]

        if (this.selectedCreditList?.id) {
            result.push(`Credit List ID: ${this.selectedCreditList?.id}`)
        }

        return result.join(" • ")
    }

    getFilteredCreditLists(): CreditListInterface[] {
        return this.creditLists?.filter((creditList: CreditListInterface) => !creditList.deleted)
    }

    getActiveCreditListIndex(): number {
        return findIndex(this.creditLists, (creditList: CreditListInterface) => creditList.code === this.selectedCreditList.code)
    }

    getCreditListIndexById(id: string): number {
        return findIndex(this.creditLists, (creditList: CreditListInterface) => creditList.id === id)
    }

    getActiveCreditList(): CreditListInterface {
        return this.creditLists.find((creditList: CreditListInterface) => creditList.code === this.selectedCreditList.code)
    }

    getActiveCreditListCredits(): CreditInterface[] {
        return this.getActiveCreditList()?.credits?.filter((credit: CreditInterface) => !credit.deleted)
    }

    getCreditIndexInCreditList(creditList: CreditListInterface, credit: CreditInterface): number {
        return credit?.id
            ? creditList?.credits?.findIndex((c: CreditInterface) => c.id === credit?.id)
            : creditList?.credits?.findIndex((c: CreditInterface) => c.role_id === credit?.role_id && c.contributor_id === credit?.contributor_id)
    }

    getTransformCredits(credits: CreditInterface[]): CreditInterface[] {
        let updatedState: CreditInterface[] = []

        credits.forEach((credit: CreditInterface) => {
            const result = credit.id 
                ? this.rootStore.handleTransformItem(apiExistingCreditFields, credit)
                : this.rootStore.handleTransformItem(apiNewCreditFields, credit)

            updatedState.push(result)
        })

        return updatedState
    }

    getActiveCreditListCreditIndex(credit: CreditInterface): number {
        const credits: CreditInterface[] = this.getActiveCreditList()?.credits

        return credit?.id
            ? credits?.findIndex((c: CreditInterface) => c.id === credit?.id)
            : credits?.findIndex((c: CreditInterface) => c.role_id === credit?.role_id && c.contributor_id === credit?.contributor_id)
    }

    getCreditRanking(credit: CreditInterface): number | undefined {
        const creditListCredits: CreditInterface[] = this.getActiveCreditListCredits();
        
        const creditListCredit: CreditInterface | undefined = creditListCredits?.find((c: CreditInterface) => 
            c.id 
                ? c.id === credit.id 
                : c.role_id === credit.role_id && c.contributor_id === credit.contributor_id
        );

        return creditListCredit?.ranking ?? undefined;
    }

    setFetching(fetching: boolean): void {
        this.fetching = fetching
    }

    setError(error: boolean | string): void {
        this.error = error
    }

    setCreditLists(creditLists: CreditListInterface[]): void {
        this.creditLists = creditLists
    }

    setOriginalCreditLists(originalCreditLists: CreditListInterface[]): void {
        this.originalCreditLists = originalCreditLists
    }

    setSelectedCreditList(selectedCreditList: CreditListInterface | {}): void {
        this.selectedCreditList = selectedCreditList || {}
    }

    handleAddCreditList(creditList: CreditListInterface): void {
        this.setCreditLists([...this.creditLists, creditList])
        this.rootStore.handleChange()
    }

    handleUpdateCreditList(creditList: CreditListInterface): void {
        const index: number = !creditList.id 
            ? this.getActiveCreditListIndex()
            : this.getCreditListIndexById(creditList.id)

        const result = update(this.creditLists, {
            [index]: {
                $set: creditList
            }
        });

        this.setCreditLists(result)
        this.rootStore.handleChange()
    }

    handleRemoveCreditList(creditList: CreditListInterface): void {
        const index: number = findIndex(this.creditLists, function (item: CreditListInterface) {
            return (
                item.code == creditList.code &&
                !item.deleted 
            );
        });

        let result: CreditListInterface[] = [...this.creditLists]

        if (index == -1) {
            return this.rootStore.handleDispatchError("Failed to delete credit list")
        } 

        if (!creditList.id) {
            result = update(this.creditLists, {
                $splice: [[index, 1]]
            })
        } else {
            result = update(this.creditLists, {
                [index]: {
                    deleted: { 
                        $set: true 
                    }
                }
            });
        }

        this.setCreditLists(result)
        this.handleSetDefaultCreditList(this.creditLists)

        this.rootStore.handleChange()
    }

     handleSetDefaultCreditList(options: CreditListInterface[]): void {
        if (isEmpty(options)) {
            return 
        }
        
        this.setSelectedCreditList(
            options?.find((cl: CreditListInterface) => cl.code == originalCreditListCode) || options[0]
        )
    }

    handleDeleteCreditList(creditList: CreditListInterface) {
        this.rootStore.setCoreDialog({
            open: true,
            title: `Delete Credit List`,
            paragraph: `Are you sure you want to delete this credit list?`,
            buttons: {
                discard: {
                    label: "Cancel",
                    handleOnDiscard: () => this.rootStore.setCoreDialog({})
                },
                submit: {
                    handleOnSubmit: () => {
                        this.handleRemoveCreditList(creditList)
                        this.rootStore.setCoreDialog({})

                        this.rootStore.activeCreditsStore.handleSetCredits()
                        this.rootStore.inactiveCreditsStore.handleSetCredits()
                    },
                    label: "Delete Credit List",
                    disabled: false
                }
            }
        })
    }

    handleSetSelectedCreditListByIdOrCode(value: string | CreditListInterface) {
        if (isObject(value) && value?.code) {
            return this.setSelectedCreditList(
                this.getFilteredCreditLists()?.find((cl: CreditListInterface) => cl.code == value.code)
            )
        }
        
        this.setSelectedCreditList(
            this.getFilteredCreditLists()?.find((cl: CreditListInterface) => cl.id == value)
        )
    }

    async handleFetchCreditLists() {
        this.setFetching(true)

         if (envTest) {
            return new Promise((resolve) => {
                this.setCreditLists(creditLists)
                this.setFetching(false)
                this.handleSetDefaultCreditList(creditLists)

                resolve(creditLists)
            })
        }

        // new record
        if (!this.rootStore.meta_id) {
            this.setFetching(false)

            this.setCreditLists(defaultCreditLists)
            this.setOriginalCreditLists(defaultCreditLists)
            this.handleSetDefaultCreditList(defaultCreditLists)
            
            return
        }

        const response = await getRecordCreditLists(this.rootStore.globalState?.cmsData, this.rootStore.meta_id)
        this.setFetching(false)

        if (response.status === 200) {
            const creditLists: CreditListInterface[] = !isEmpty(response.data)
                ? this.rootStore.handleTransformItems(apiCreditListFields, this.handleOrderCreditLists(response.data || []))
                : defaultCreditLists

            this.setCreditLists(creditLists)
            this.setOriginalCreditLists(creditLists)
            this.handleSetDefaultCreditList(creditLists)
        } else {
            this.rootStore.handleDispatchError("Failed to fetch credit lists")
        }
    }

    async handleFetchCredits() {
        return envTest
            ? this.handleFetchCreditsTest()
            : this.handleFetchCreditsApi()
    }

    handleFetchCreditsTest() {
        for (const creditList of this.creditLists) {
            this.handleUpdateCreditsInCreditList(creditsResponse[creditList?.id], creditList)
        }
    }

    async handleFetchCreditsApi() {
        for await (const creditList of this.creditLists) {
            const selectedCreditListId: string = creditList?.id

            if (selectedCreditListId) {
                 const response = await getCreditListCredits(this.rootStore.globalState?.cmsData, selectedCreditListId)

                if (response.status === 200 && !isEmpty(response.data)) {
                    this.handleUpdateCreditsInCreditList(response.data, creditList)
                } else {
                    this.handleUpdateCreditsInCreditList([], creditList)
                    this.rootStore.handleDispatchError(`Failed to fetch credits, for ${creditList.name}`)
                }
            } else {
                this.handleUpdateCreditsInCreditList([], creditList)
            }
        }
    }

    handleOrderCreditLists(creditLists: CreditListInterface[]): CreditListInterface[] {
        return creditLists.sort((a: CreditListInterface, b: CreditListInterface) => {
            if (a.code === originalCreditListCode) {
                return -1
            }

            return 0
        })
    }

    handleUpdateCreditsInCreditList(credits: CreditInterface[], creditList: CreditListInterface): void {
        const index: number = this.getCreditListIndexById(creditList?.id)

        if (index !== -1) {
            const result: CreditListInterface[] = update(this.creditLists, {
                [index]: {
                    credits: { 
                        $set: this.getTransformCredits(credits)
                    }
                }
            });

            this.setCreditLists(result)
            this.setOriginalCreditLists(result)
        } else {
            this.rootStore.handleDispatchError("Failed to fetch credits to credit list")
        }
    }

    handleAddCreditsToCreditList(credits: CreditInterface[]): void {
        const index: number = this.getActiveCreditListIndex()

        if (index !== -1) {
            const result: CreditListInterface[] = update(this.creditLists, {
                [index]: {
                    credits: { 
                        $set: this.getTransformCredits(credits)
                    }
                }
            });

            this.setCreditLists(result)
        } else {
            this.rootStore.handleDispatchError("Failed to fetch credits to credit list")
        }
    }

    handleAddCreditToCreditList(credit: CreditInterface): void {
        const index: number = this.getActiveCreditListIndex()
        const creditWithRanking: CreditInterface = this.transformCreditWithSequentialRanking(credit)
        const creditIndex: number = this.getActiveCreditListCreditIndex(credit)

        if (index !== -1) {
            if (!this.creditLists[index]?.credits) {
                const updatedCreditLists = update(this.creditLists, {
                    [index]: {
                        credits: {
                            $set: []
                        }
                    }
                });

                this.setCreditLists(updatedCreditLists)
            }

            let result: CreditListInterface[] = this.creditLists

            if (creditIndex !== -1) {
                // update
                result = update(this.creditLists, {
                    [index]: {
                        credits: { 
                            [creditIndex]: {
                                $set: {
                                    ...this.rootStore.handleTransformItem(apiExistingCreditFields, creditWithRanking),
                                    deleted: false
                                }
                            }
                        }
                    }
                })
            } else {
                // add
                result = update(this.creditLists, {
                    [index]: {
                        credits: { 
                            $push: [
                                creditWithRanking.id 
                                    ? this.rootStore.handleTransformItem(apiExistingCreditFields, creditWithRanking)
                                    : this.rootStore.handleTransformItem(apiNewCreditFields, creditWithRanking)
                            ]
                        }
                    }
                });
            }

            this.setCreditLists(result)
        } else {
            this.rootStore.handleDispatchError("Failed to fetch credits to credit list")
        }
    }

    handleDeleteCreditFromCreditLists(credit: CreditInterface): void {
        this.creditLists.forEach((creditList: CreditListInterface, creditListIndex) => {
            const creditIndex: number = this.getCreditIndexInCreditList(creditList, credit)

            if (creditIndex !== -1) {
                const creditListCredits: CreditInterface[] = this.creditLists[creditListIndex]?.credits || []

                const creditListCredit: CreditInterface = {
                    ...creditListCredits[creditIndex] || {},
                    ...credit
                }

                let updatedCreditLists: CreditListInterface[] = [...this.creditLists]

                if (!credit.id) {
                    // Hard delete
                    updatedCreditLists = update(this.creditLists, {
                        [creditListIndex]: {
                            credits: { 
                                $splice: [[creditIndex, 1]]
                            }
                        }
                    })
                } else {
                    // Soft delete
                    updatedCreditLists = update(this.creditLists, {
                        [creditListIndex]: {
                            credits: { 
                                [creditIndex]: {
                                    deleted: {
                                        $set: true
                                    }
                                }
                            }
                        }
                    })

                    delete updatedCreditLists[creditListIndex]?.credits[creditIndex].ranking
                }

                // Re rank credits
                updatedCreditLists = this.handleReRankCreditsInCreditList(
                    updatedCreditLists,
                    creditListIndex,
                    updatedCreditLists[creditListIndex]?.credits,
                    creditListCredit
                )

                this.setCreditLists(updatedCreditLists)
            }
        })
    }

    handleReRankCreditsInCreditList(creditLists: CreditListInterface[], creditListIndex: number, credits: CreditInterface[], credit: CreditInterface): CreditListInterface[] {
        const reRankedCredits: CreditInterface[] = this.handleCreditsReRankingWithinRole(credits, credit)

        return update(creditLists, {
            [creditListIndex]: {
                credits: { 
                    $set: reRankedCredits
                }
            }
        })
    }

    handleDeleteCreditFromActiveCreditList(tableCredit: CreditRowInterface): void {
        const credit: CreditInterface = this.rootStore.creditsStore.getCredit(
            this.rootStore.creditsStore.getTransformedTableCredit(tableCredit)
        )

        if (!credit) {
            return this.rootStore.handleDispatchError("Failed to move credit. Credit not found.")
        }

        const creditList: CreditListInterface = this.getActiveCreditList()
        const creditListIndex: number = this.getActiveCreditListIndex()
        const creditIndex: number = this.getCreditIndexInCreditList(this.creditLists[creditListIndex], credit)

        if (creditListIndex !== -1 && creditIndex !== -1) {
            let updatedCreditLists: CreditListInterface[] = [...this.creditLists]

            const creditListCredit: CreditInterface = {
                ...credit,
                ...this.creditLists[creditListIndex]?.credits[creditIndex]
            }

            if (!creditList.id) {
                // Hard delete
                updatedCreditLists = update(this.creditLists, {
                    [creditListIndex]: {
                        credits: { 
                            $splice: [[creditIndex, 1]]
                        }
                    }
                })
            } else {
                // Soft delete
                updatedCreditLists = update(this.creditLists, {
                    [creditListIndex]: {
                        credits: { 
                            [creditIndex]: {
                                deleted: {
                                    $set: true
                                }
                            }
                        }
                    }
                })

                delete updatedCreditLists[creditListIndex]?.credits[creditIndex].ranking
            }

            // Re rank credits
            updatedCreditLists = this.handleReRankCreditsInCreditList(
                updatedCreditLists,
                creditListIndex,
                updatedCreditLists[creditListIndex]?.credits,
                creditListCredit
            )

            this.setCreditLists(updatedCreditLists)
            this.rootStore.handleChange()
        }
    }

    handleCreditsReRankingWithinRole(credits: CreditInterface[], credit: CreditInterface): CreditInterface[] {
        return credits?.map((cr: CreditInterface) => {
            if (cr.role_id === credit.role_id 
                && cr.ranking > credit.ranking 
                && credit.ranking !== null) {

                return {
                    ...cr,
                    ranking: cr.ranking - 1
                }
            }

            return cr
        })
    }

    handleTransformCreditListsForApi(creditLists: CreditListInterface[]): CreditListInterface[] {
        const items = [...creditLists]

        return items.map((creditList: CreditListInterface) =>
            creditList.id 
                ? this.rootStore.handleTransformItem(apiCreditListUpdateFields, creditList)
                : this.rootStore.handleTransformItem(apiCreditListCreateFields, creditList)
        );
    }

    transformCreditWithSequentialRanking(credit: CreditInterface): CreditInterface {
        const credits: CreditInterface[] = this.getActiveCreditListCredits()

        const groupRankings: number[] = credits?.filter((cr: CreditInterface) => cr.role_id == credit?.role_id)
            ?.map((cr: CreditInterface) => cr.ranking)

        const topRanking: number = isEmpty(groupRankings) 
            ? 0
            : Math.max(...groupRankings)

        return {
            ...credit,
            ranking: topRanking + 1
        }
    }
}
