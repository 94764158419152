import react from "react";
import CategoryTags from "../../CategoryTags";

const HandleSearchTermAndCategory = (props) => {
    const { searchQuery, categorySearch, handleQueryChange, category } = props;

    let searchCategory = category;

    switch (category) {
        case CategoryTags.Season.short:
            searchCategory = CategoryTags.Show.short;
            break;

        case CategoryTags.Episode.short:
            searchCategory = CategoryTags.Show.short;
            break;

        case CategoryTags.Storyline.short:
            searchCategory = CategoryTags.Show.short;
            break;

        case CategoryTags.Special.short:
            searchCategory = CategoryTags.Show.short;
            break;
    }

    if (categorySearch) {
        searchCategory = category;
    }

    handleQueryChange({
        searchQuery,
        searchCategory,
    });
};

export default HandleSearchTermAndCategory;
