import { CircularProgress, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { CoreFormLabel } from "../../../../../../core/Form/components/styled/CoreForm.label";
import { GridContainer, GridXS12, GridXS6 } from "../layout/grid";
import { SequencingTitle } from "../sequencing/SequencingTitle";

const SelectSkeleton = () => <Skeleton height="1.4375em" />;
export function PrimaryFieldsSkeleton() {
    return (
        <GridXS12>
            <Stack direction="row" spacing={2}>
                <CircularProgress size={20} />
                <Box
                    sx={{
                        color: "#5F5F5F",
                        fontSize: "1rem",
                    }}
                >
                    Fetching data...
                </Box>
            </Stack>
        </GridXS12>
    );

    return (
        <Box sx={{ width: "100%" }}>
            <GridContainer>
                <GridXS6>
                    <CoreFormLabel text="Release Year" isDisabled />
                    <SelectSkeleton />
                </GridXS6>
                <GridXS6>
                    <CoreFormLabel text="Brand" isDisabled />
                    <SelectSkeleton />
                </GridXS6>

                <GridXS6>
                    <CoreFormLabel text="Provenance" isDisabled />
                    <SelectSkeleton />
                </GridXS6>
                <GridXS6>
                    <CoreFormLabel text="Primary Language" isDisabled />
                    <SelectSkeleton />
                </GridXS6>

                <GridXS12>
                    <CoreFormLabel text="Country of Origin" isDisabled />
                    <SelectSkeleton />
                </GridXS12>

                <GridXS12>
                    <SequencingTitle />
                </GridXS12>

                <GridXS12>
                    <Skeleton height="15em" />
                </GridXS12>
            </GridContainer>
        </Box>
    );
}
