import { Box, CircularProgress } from "@mui/material";
import { observer } from "mobx-react";
import React, { PropsWithChildren } from "react";
import { useBackgroundProcessingStore } from "../store/useBackgroundProcessingStore";
import { palette } from "../theme/background-processing.theme";
import { WidgetState } from "../types";
import { Overview } from "./Overview";

const GREEN = palette?.common?.green;
const WHITE = palette?.common?.white;

const Text = ({ color, children }: { color: string } & PropsWithChildren) => {
    return (
        <div
            className="icon-text visible"
            style={{
                color,
                fontWeight: 400,
                fontSize: "0.875rem",
                marginLeft: "0.28rem",
            }}
        >
            {children}
        </div>
    );
};

const NeutralText = ({ children }: PropsWithChildren) => {
    const color = WHITE;
    return <Text color={color}>{children}</Text>;
};

const ErrorText = ({ children }: PropsWithChildren) => {
    const color = palette?.secondary?.main;
    return <Text color={color}>{children}</Text>;
};

const SuccessText = ({ children }: PropsWithChildren) => {
    const color = GREEN;
    return <Text color={color}>{children}</Text>;
};

export const BackgroundProcessingMenuItem = observer(() => {
    const store = useBackgroundProcessingStore();

    React.useEffect(() => {
        store.listProcesses();
        // store.setProcesses(fakeProcesses());
    }, []);

    let icon = null;
    let textComponent = null;
    const widgetState = store.getWidgetState();
    switch (widgetState) {
        case WidgetState.HIDDEN: {
            return null;
        }

        case WidgetState.LOADING: {
            const startedProcessesCount = store.getStartedProcessesCount();
            icon = (
                <Box sx={{ color: WHITE }}>
                    <CircularProgress
                        disableShrink
                        color="inherit"
                        size="1.5625rem"
                    />
                </Box>
            );

            textComponent = (
                <NeutralText>
                    {startedProcessesCount}{" "}
                    {startedProcessesCount === 1 ? "Process" : "Processes"}{" "}
                    Active
                </NeutralText>
            );

            break;
        }

        case WidgetState.ERROR: {
            icon = (
                <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M3.41179 26.25H26.5743C27.5368 26.25 28.1368 25.2125 27.6618 24.375L16.0743 4.37501C15.5868 3.53751 14.3868 3.53751 13.9118 4.37501L2.32429 24.375C1.84929 25.2125 2.44929 26.25 3.41179 26.25ZM16.2493 22.5H13.7493V20H16.2493V22.5ZM14.9993 17.5C14.3118 17.5 13.7493 16.9375 13.7493 16.25V13.75C13.7493 13.0625 14.3118 12.5 14.9993 12.5C15.6868 12.5 16.2493 13.0625 16.2493 13.75V16.25C16.2493 16.9375 15.6868 17.5 14.9993 17.5Z"
                        fill="#E92F4E"
                    />
                </svg>
            );

            textComponent = (
                <ErrorText>
                    {store.getFailedProcessesCount() === 1
                        ? `${store.getFailedProcessesCount()} Error`
                        : `${store.getFailedProcessesCount()} Errors`}
                </ErrorText>
            );
            break;
        }

        case WidgetState.SUCCESS: {
            icon = (
                <svg
                    width="42"
                    height="42"
                    viewBox="0 0 42 42"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M22.25 8.53748V8.56248V11.0625C27.7375 11.7375 31.625 16.725 30.95 22.2125C30.375 26.7625 26.8 30.375 22.25 30.9125V33.4125C29.125 32.725 34.125 26.625 33.4375 19.75C32.875 13.8125 28.1625 9.12498 22.25 8.53748ZM19.75 8.57498C17.3125 8.81248 14.9875 9.74998 13.0875 11.325L14.875 13.175C16.275 12.05 17.9625 11.325 19.75 11.075V8.57498ZM11.325 13.0875C9.75 14.9875 8.8125 17.3 8.5625 19.75H11.0625C11.3 17.975 12 16.2875 13.1125 14.875L11.325 13.0875ZM25.375 16.625L19.275 22.725L16.625 20.075L15.3 21.4L19.275 25.375L26.7 17.95L25.375 16.625ZM8.575 22.25C8.825 24.7 9.7875 27.0125 11.3375 28.9125L13.1125 27.125C12.0125 25.7125 11.3 24.025 11.075 22.25H8.575ZM14.875 28.9625L13.0875 30.675C14.975 32.25 17.3 33.2375 19.75 33.5V31C17.975 30.775 16.2875 30.0625 14.875 28.9625Z"
                        fill={GREEN}
                    />
                </svg>
            );

            textComponent = <SuccessText>Processing Complete</SuccessText>;

            break;
        }

        default: {
            return null;
        }
    }

    return (
        <Overview
            triggerComponent={
                <div
                    data-testid="background-processing-menu-item"
                    className="icon-text-container visible "
                >
                    <div className="svg-container">
                        <div
                            data-testid="background-processing-menu-item-icon"
                            className="icon-container"
                        >
                            {icon}
                        </div>
                    </div>
                    <div data-testid="background-processing-menu-item-text">
                        {textComponent}
                    </div>
                </div>
            }
        />
    );
});
