export const chipColors: {label: string, value: string}[] = [
    {
        label: "#FAC6D5",
        value: "#FAC6D5",
    },
    {
        label: "#E6C9F3",
        value: "#E6C9F3",
    },
    {
        label: "#C4D8F6",
        value: "#C4D8F6",
    },
    {
        label: "#D1E096",
        value: "#D1E096",
    },
    {
        label: "#F9A893",
        value: "#F9A893",
    },
    {
        label: "#F8DD9B",
        value: "#F8DD9B",
    },
    {
        label: "#F28783",
        value: "#F28783",
    },
    {
        label: "#B6E5D5",
        value: "#B6E5D5",
    },
    {
        label: "#C3F3BC",
        value: "#C3F3BC",
    },
    {
        label: "#BDEBF6",
        value: "#BDEBF6",
    },
    {
        label: "#FFA1A7",
        value: "#FFA1A7",
    }
]