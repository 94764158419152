import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { EpisodeVT, PrimaryVT, SeasonVT } from "../../../../config/types";
import { useStores } from "../../../../store/rootStore";
import { Linkage } from "../../../../types/interfaces";

export const useFetchLinkage = ({
    metaId,
    category,
}: Required<Pick<PrimaryVT, "metaId" | "category">>) => {
    const rootStore = useStores();
    const [linkedItems, setLinkedItems] = useState<(SeasonVT | EpisodeVT)[]>(
        [],
    );
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchSeasons = async () => {
            setLoading(true);
            // don't need to use try catch because rootStore.recordServiceApi.search already handles it
            const response = await rootStore.recordServiceApi.searchByMetaIds([
                metaId,
            ]);

            if (isEmpty(response) || !Array.isArray(response)) {
                setLoading(false);
                return;
            }

            const linkages: Linkage[] = response[0]?.data?.linkage || [];
            const seasonsAux: SeasonVT[] = linkages
                .filter((item) => item?.category === category)
                .map(rootStore.taskManagerAdapters.adaptLinkageRecordToTM);

            setLoading(false);
            setLinkedItems(seasonsAux);
        };

        if (!isEmpty(metaId)) {
            fetchSeasons();
        }
    }, [metaId, category]);

    // setLoading to false on unmount
    useEffect(() => {
        return () => {
            setLoading(false);
        };
    }, []);

    return { linkedItems, loading };
};
