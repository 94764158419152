import { v4 as uuidv4 } from 'uuid'

export interface InitialCreditInterface {
    language?: string | null;
    title: string | null;
    name: string | null;
    script_names: CreditScriptedNameInterface[];
    first_name: string | null;
    last_name: string | null;
    middle_names: string | null;
    alternative_name: string | null;
    second_alternative_name: string | null;
    date_of_birth: string | null;
    credit_meta_id: string | null;
    contributor_meta_id?: string | null;
    character_name?: string | null;
    script_character_names: CreditScriptedCharacterNameInterface[];
    role?: number;
    role_name?: string | null;
    ranking?: number | boolean;
    source?: string | null;
    imdb_id: string | null;
    isni_id: string | null;
    hand_id: string | null;
    iva_person_id: string | null;
    external_id?: string | null;
    value: number | null;
    credit_lists: CreditRankMap[];
    deleted?: boolean;
    text?: string | null;
    updated?: boolean;
    new?: boolean | null;
}

export interface CreditInterface extends InitialCreditInterface {
    local_id: string | null;
}

export interface CreditScriptedNameInterface {
    code: string;
    name?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    middle_names?: string | null;
    alternative_name?: string | null;
    second_alternative_name?: string | null;
}

export interface CreditScriptedCharacterNameInterface {
    code: string;
    character_name: string;
}

export interface CreditRankMap {
    name?: string;
    code?: string;
    meta_id?: string;
    ranking?: number;
    deleted?: boolean;
}

export const transformCreditsPayload = (
    credits: InitialCreditInterface[]
): CreditInterface[] => {
    return credits?.map((credit: CreditInterface) => {
        credit.local_id = uuidv4();
        return credit;
    })
}

export const addLocalIdToCredit = (
    credit: CreditInterface
): CreditInterface => {
    if (!credit.local_id) {
        credit.local_id = uuidv4();
    }
    
    return credit;
}