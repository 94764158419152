const MarginsV2 = (item) => {
    const { buy_rate, sell_rate } = item;

    const total_buy_rate = parseFloat(buy_rate);
    const total_sell_rate = parseFloat(sell_rate);
    const total_margin =
        parseFloat(total_sell_rate) - parseFloat(total_buy_rate);
    const margin =
        (parseFloat(total_margin) / parseFloat(total_sell_rate)) * 100;

    let result = {
        total_buy_rate,
        total_sell_rate,
        total_margin,
        margin,
    };

    Object.keys(result).map((key) => {
        result[key] = parseFloat(result[key].toFixed(2));
    });

    return result;
};

export default MarginsV2;
