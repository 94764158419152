import { Box, Grid, Stack } from "@mui/material";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { CoreFormPath } from "../../../types";
import { CoreFormLabel, CoreFormLabelProps } from "../../styled/CoreForm.label";
import { CoreFormCaption } from "../../styled/CoreFormCaption";
import { CoreFormFieldError } from "../../styled/CoreFormFieldError";
import { CoreSelectOptionType } from "../Select/types/CoreSelectOptionType";
import { CoreRadio, CoreRadioVariant } from "./CoreRadio";

export type CoreRadioStackProps<T> = CoreFormPath<T> & {
    options: CoreSelectOptionType[];
    variant?: CoreRadioVariant;
    labelProps?: CoreFormLabelProps;
    customRadioStyles?: React.CSSProperties;
    hideSelector?: boolean;
    disabled?: boolean,
    gridItemProps?: {
        xs: number;
    };
};

export function CoreRadioStack<T>({
    path,
    options,
    variant,
    disabled,
    labelProps,
    gridItemProps,
}: CoreRadioStackProps<T>) {
    const formMethods = useFormContext();
    const errorMessage = formMethods?.getFieldState(path).error?.message;
    const error = !!errorMessage;
    const [onHoverText, setOnHoverText] = useState("");

    let MainComponent = (
        <Stack
            direction="row"
            flexWrap="wrap"
            rowGap="0.625rem"
            columnGap="0.625rem"
            // onMouseLeave={() => setOnHoverText(null)}
        >
            {options?.map((option) => {
                return (
                    <CoreRadio<T>
                        key={option.value}
                        option={option}
                        path={path}
                        variant={variant}
                        error={error}
                        disabled={disabled}
                        setOnHoverText={setOnHoverText}
                    />
                );
            })}
        </Stack>
    );

    if (!isEmpty(gridItemProps)) {
        MainComponent = (
            <Grid container width="100%" spacing={2.5}>
                {options?.map((option) => {
                    return (
                        <Grid item xs={gridItemProps?.xs} width="100%">
                            <CoreRadio<T>
                                key={option.value}
                                option={option}
                                path={path}
                                variant={variant}
                                error={error}
                                setOnHoverText={setOnHoverText}
                                disabled={disabled}
                                disableMaxWidth
                                data-testid={`radio-${option.value}`}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        );
    }

    return (
        <Box>
            {labelProps && <CoreFormLabel {...labelProps} />}

            {MainComponent}

            {error && <CoreFormFieldError path={path} message={errorMessage} />}
            {!isEmpty(onHoverText) && <CoreFormCaption>{onHoverText}</CoreFormCaption>}
        </Box>
    );
}

CoreRadioStack.defaultProps = {
    variant: "tile",
    labelProps: null,
    gridItemProps: null,
};
