
import _ from "lodash";
import { z } from "zod";
import FieldInterface, { InputType } from '../interfaces/Field.interface'
import { TextInputRule } from "./rules/text"
import { DefaultInputRule } from "./rules/default"
import { BooleanInputRule } from "./rules/boolean";
import { SelectMultipleInputRule } from './rules/selectMultiple'
import { SelectInputRule } from './rules/select'
import { AlphaNumericInputRule } from "./rules/alphaNumeric";
import { NumericInputRule } from "./rules/numeric";

type Props = {
    fields: FieldInterface[],
    extraRefine?: Function
}

const ValidationSchemaBuilder = ({ fields = [], extraRefine = () => {} }: Props) => {
    let schema = z.object({})

    const rule = (field: FieldInterface) => {
        switch(field.type.input) {
            case InputType.Text:
            case InputType.TextArea:
            case InputType.TextFramed:
            case InputType.Date:
            case InputType.DateTime:
            case InputType.Time:
            case InputType.Year:
                return TextInputRule(field)
            case InputType.BooleanRadio:
            case InputType.BooleanToggle:
                return BooleanInputRule(field)
            case InputType.SelectMultiple:
            case InputType.SelectMultipleChips:
            case InputType.Autocomplete:
                return SelectMultipleInputRule(field)
            case InputType.Select:
                return SelectInputRule(field)
            case InputType.Alphanumeric:
                return AlphaNumericInputRule(field)
            case InputType.Duration:
            case InputType.Numeric:
                return NumericInputRule(field)
            default:
                return DefaultInputRule(field)
        }
    }

    fields.forEach((field: FieldInterface) => {
        schema = schema.merge(
            rule(field)
        )
    })

    return schema.superRefine(extraRefine)
}

export default ValidationSchemaBuilder
