import React from "react";
import _ from "lodash";
import PlaceholderText from "../PlaceholderText";
import BorderRadius from "../BorderRadius";
import "./styles/_styles.scss";
import CycleRow from "../RecordTable/CycleRow";
import CycleRowMonth from "../RecordTable/CycleRowMonth";

const CyclesV2 = (props) => {
    const { styles = {}, data = [], monthStats = {} } = props;

    return (
        <div style={styles} className="meta-cycles-v2">
            {_.isEmpty(data) && <PlaceholderText />}

            {!_.isEmpty(data) && (
                <BorderRadius
                    styles={{
                        width: "100%",
                    }}
                >
                    <CycleRowMonth monthStats={monthStats} />

                    {data.map((item, index) => {
                        const odd = index % 2 == 0;

                        return (
                            <CycleRow
                                item={item}
                                key={index}
                                index={index}
                                even={!odd}
                            />
                        );
                    })}
                </BorderRadius>
            )}
        </div>
    );
};

export default CyclesV2;
