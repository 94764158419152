export default function(value: any): boolean {
    if (value == null || value == undefined) {
        return true;
    }

    if (Array.isArray(value) || typeof value === "string") {
        return !value.length;
    }

    for (const key in value) {
        if (value.hasOwnProperty(key)) {
            return false;
        }
    }
    
    return true;
}