import { useEffect } from "react";

const OutsideClick = (ref, callback, options = {}) => {
    const { excludeClasses } = options;

    useEffect(() => {
        function handleClickOutside(event) {
            let stop = false;

            if (Array.isArray(excludeClasses)) {
                excludeClasses.forEach((item) => {
                    if (event.target.classList.contains(item)) {
                        stop = true;
                    }

                    if (hasParent(event.target, item)) {
                        stop = true;
                    }
                });
            }

            if (stop) return;

            if (ref.current && !ref.current.contains(event.target)) {
                callback(event);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    const hasParent = (el, parentName) => {
        while (el && el.parentNode) {
            el = el.parentNode;
            if (el.classList && el.classList.contains(parentName)) {
                return el;
            }
        }
    
        return null;
    }
};

export default OutsideClick;
