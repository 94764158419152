import React, { useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import {
    CoreForm,
    CoreFormProvider,
    CoreFormThemeProvider,
} from "../../../../../core/Form/components/CoreForm";
import { CoreSelect } from "../../../../../core/Form/components/inputs/Select/components/CoreSelect";
import { CoreFormStack } from "../../../../../core/Form/components/layout/CoreForm.stack";
import { useEffect } from "react";
import _ from "lodash";
import { Chip } from "@mui/material";
import { APIRoleOptionInterface } from "../../credits.interface";
import { 
    coreSelectDisabledStyles,
    coreSelectActiveStyles,
    menuCustomDisabledStyles,
    menuCustomActiveStyles 
} from '../styles'

export type RoleCoreSelectProps = {
    options: APIRoleOptionInterface[];
    onChange: (role: APIRoleOptionInterface) => void;
    value: string;
    formRef: any;
    disabled?: boolean;
};

export const RoleCoreSelect = React.forwardRef(
    ({ options, onChange, value, formRef, disabled = false }: RoleCoreSelectProps) => {
        const form = useForm({
            defaultValues: {
                role: value,
            },
            mode: "onSubmit",
        });

        useImperativeHandle(formRef, () => ({
            resetForm() {
                form.setValue("role", "");
            },
        }));

        useEffect(() => {
            disabled ? form.reset() : () => {}
        }, [disabled]);

        useEffect(() => {
            if (
                value !== form?.watch("role") &&
                !_.isEmpty(form?.watch("role"))
            ) {
                onChange(
                    options?.find((role) => role.id == form?.watch("role")),
                );
            } 
        }, [form?.watch("role")]);

        return (
            <CoreFormThemeProvider>
                <CoreFormProvider {...form}>
                    <CoreForm>
                        <CoreFormStack direction="row">
                            <CoreSelect
                                config={{
                                    path: "role",
                                    options: options?.map((role) => {
                                        let result = {
                                            label: role.name,
                                            value: role.id.toString()
                                        }

                                        if (role.type) {
                                            result.rightAdornment = (
                                                <Chip
                                                    label={role.type}
                                                    sx={{
                                                        bgcolor: "#e1e1e1",
                                                        padding: "0.25rem 0.375rem",
                                                        borderRadius: "0.125rem",
                                                        margin: "0 0 0 0.75rem !important",
                                                    }}
                                                />
                                            )
                                        }

                                        return result
                                    }),
                                    enableSearchbox: true,
                                    multiple: false,
                                    enableScrollToSelected: true,
                                    labelProps: {
                                        text: "Role",
                                        customStyles: disabled ? coreSelectDisabledStyles : coreSelectActiveStyles,
                                    },
                                    disabled: disabled,
                                }}
                                key={options?.join("_")}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            boxShadow:
                                                "0.125rem 0.1875rem 0.3125rem rgba(79, 94, 116, 0.15)",
                                            border: "0.0625rem solid rgba(77, 77, 77, 0.2)",
                                            padding: "0.3125rem",
                                            overflow: "hidden",
                                        },
                                    },
                                    MenuListProps: {
                                        sx: {
                                            maxHeight: 315,
                                            paddingY: 0,
                                            "& .MuiPaper-root + div": {
                                                overflow: "scroll",
                                                maxHeight: "17.5rem",
                                            },
                                        },
                                    },
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                    },
                                    sx: { transform: "translateY(0.3125rem)" },
                                }}
                                customStyles={disabled ? menuCustomDisabledStyles : menuCustomActiveStyles}
                            />
                        </CoreFormStack>
                    </CoreForm>
                </CoreFormProvider>
            </CoreFormThemeProvider>
        );
    },
);
