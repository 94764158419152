import { isIdEntity } from '../../../../models/RecordBuilder'
import { transformOutgoingCategories } from "./transformCategories"

export const handleItemUpdate = (item, model?: string) => {
    if (item.id) delete item.id
    if (Object.keys(item).includes('key')) delete item.key

    if (isIdEntity(model)) {
        item = transformIds(item)
    }

    return item
}

const transformIds = (item) => {
    if (item.type) {
        delete item.type
    }

    return item
}

const handleConfigurations = (data) => {
    return data.map(item => {
        if (item.uuid) delete item.uuid
        if (item.label) delete item.label
        if (item.permissions) delete item.permissions

        if (Array.isArray(item.categories)) {
            item.categories = transformOutgoingCategories(item.categories)
        }

        return item
    })
}

export default handleConfigurations