/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { GridRowParams, GridSelectionModel } from "@mui/x-data-grid";
import { RenderActionsCol } from "../components/RenderActions.column";
import { CoreTable } from "../CoreTable";
import { CoreTableConfig, CoreTableGridColDef, CoreTableRow } from "../types";
import { ImageNameCol } from "./columns/ImageName.column";
import { ImageIdCol } from "./columns/ImageId.column";
import { ImageTypeCol } from "./columns/ImageType.column";
import { ResolutionCol } from "./columns/Resolution.column";
import { FormatCol } from "./columns/Format.column";
import { ImageInterface } from "../../../models/Record/images"
import { ImagesType } from "./types/Images.type";

export type ImagesTypeProps = {
    images: ImageInterface[];
    config: CoreTableConfig,
    editPermission: boolean,
    deletePermission: boolean,
    handleEdit: (params: GridRowParams) => void,
    handleDelete: (params: GridRowParams) => void,
    handleDownload: (event: React.SyntheticEvent, params: GridRowParams) => void,
    handlePreview: (params: GridRowParams) => void,
    hasNoPreview: (params: GridRowParams) => boolean,
};

export const ImagesTable = ({ images, config, handleEdit, handleDelete, handleDownload, handlePreview, editPermission, deletePermission, hasNoPreview }: ImagesTypeProps) => {
    let actionCols = {
        download: {
            handleOnClick: (event: React.SyntheticEvent, params: GridRowParams) => handleDownload(event, params)    
        },
        preview: {
            disabled: (params: GridRowParams) => hasNoPreview(params),
            handleOnClick: (params: GridRowParams) => handlePreview(params)
        },
        onlyShowInDefaultState: {
            download: true,
            preview: true,
            width: 80
        },
        state: config.state,
        width: 140
    }

    if (editPermission) {
        actionCols.edit = {
            handleOnClick: (params: GridRowParams) => handleEdit(params)            
        }
    }

    if (deletePermission) {
        actionCols.delete = {
            handleOnClick: (params: GridRowParams) => handleDelete(params)    
        }
    }

    const actions = RenderActionsCol(actionCols);

    const columns: CoreTableGridColDef[] = [
        ImageNameCol,
        ImageIdCol,
        ImageTypeCol,
        ResolutionCol,
        FormatCol,
        ...actions,
    ];

    const rows: CoreTableRow<ImagesType> = images;

    const tableConfig: CoreTableConfig = {
        ...config || {},
        tableName: "Images",
        emptyFallback: "No Images"
    };

    return (
        <CoreTable<ImagesType>
            config={tableConfig}
            columns={columns}
            rows={rows}
            hideFooter={true}
            onSelectionModelChange={(ids: GridSelectionModel) => {}}
        />
    );
};
