import React, { Component } from "react";
import styles from "../styles/triviaStyles";
import _ from "lodash";
import InputTextArea from "../../Input/textarea";
import DynamicTable from "../../DynamicTable";
import "../../RecordTable/styles/_styles.scss";
import RenderPosition from "../../RecordTable/src/RenderPosition";
import update from "react-addons-update";
import Notification from "../../Notification";
import BorderRadius from "../../BorderRadius";
import KeywordLabel from "../../KeywordLabel";
import AuditTooltip from "../../AuditTooltip";

export default class Trivia extends DynamicTable {
    constructor(props) {
        super(props);

        let { data = [] } = this.props;

        this.state = {
            data,
            editStates: [],
            editRow: false,
            hoverRow: false,
            newRow: true,
            activeRow: {},
        };

        this.defaultActiveRowData = {
            text: "",
        };

        this.styles = styles(this.props);
    }

    checkEmptyDataKeys() {
        return true;
    }

    handleChange(key, index, value) {
        if (key) {
            let activeRow = { ...this.state.activeRow };
            activeRow[key] = value;

            const updatedState = update(this.state.data, {
                [index]: { $set: activeRow },
            });

            this.setChangeData(updatedState);
            this.setActiveRow(activeRow);
            this.setNewRow(true);
        }
    }

    renderRow(row) {
        const { text = "", number = 0 } = row;

        const { audits = [], clientFeatures = {} } = this.props;

        let disabled = true;

        if (this.state.editRow === number) {
            disabled = false;
        }

        return (
            <div
                className={`meta-numbered-record-table--row meta-trivia-row ${
                    (number + 1) % 2 ? "even" : ""
                }`}
                key={number}
            >
                {RenderPosition(number)}

                <div
                    className="text"
                    style={Object.assign({}, this.styles.tdText)}
                >
                    <AuditTooltip
                        audits={audits}
                        clientFeatures={clientFeatures}
                        fieldName={"text"}
                        dataSection="trivia"
                        value={text}
                    >
                        <InputTextArea
                            placeholder="Start Typing Trivia"
                            id="meta-charachter-name"
                            value={text}
                            name="character_name"
                            disabled={disabled}
                            margin="0"
                            recordBasic={true}
                            borderHiddenPadding={true}
                            updateState={!disabled}
                            handleChange={this.handleChange.bind(
                                this,
                                "text",
                                number,
                            )}
                            styles={this.styles.tableTextStyle}
                        />
                    </AuditTooltip>
                </div>

                {disabled && !this.props.disabled && (
                    <div style={{ margin: "0.75rem", display: "flex" }}>
                        {this.renderEditIconGrey(
                            { text },
                            number,
                            "meta-trivia-edit-icon",
                        )}
                    </div>
                )}

                {!disabled && (
                    <div
                        style={{
                            display: "flex",
                            margin: "0.75rem",
                            justifyContent: "flex-end",
                        }}
                    >
                        {this.renderDeleteIcon(
                            { text },
                            number,
                            "meta-trivia-delete-icon",
                        )}
                        {this.renderSaveIcon(number)}
                    </div>
                )}
            </div>
        );
    }

    setChangeData(data) {
        this.setData(data);

        if (typeof this.props.handleFormChange == "function") {
            this.props.handleFormChange(data);
        }
    }

    deleteRow(item, index) {
        const updatedState = update(this.state.data, { $splice: [[index, 1]] });

        this.setChangeData(updatedState);
        this.setActiveRowDefault();
        this.handleChangeCallBack();
        this.setEditRow(false);
        this.setNewRow(true);
        this.setNotification({});
    }

    isActiveRowValidated() {
        let result = false;

        if (this.state.activeRow && this.state.activeRow.text) {
            result = true;
        }

        return result;
    }

    disabledEditState(number) {
        return this.state.editRow !== number;
    }

    render() {
        const { data = [], notification = {} } = this.state;

        const { canCreateNew = true } = this.props;

        return (
            <div
                id="meta-data-trivia"
                style={Object.assign({}, this.styles.mainContainer)}
            >
                <label style={this.styles.label}>Trivia</label>
                <BorderRadius styles={{ margin: "0.71875rem 0 0" }}>
                    {data.map((row, index) =>
                        this.renderRow({ text: row.text || "", number: index }),
                    )}

                    {this.props.disabled && _.isEmpty(data) && (
                        <KeywordLabel
                            title={"None Entered"}
                            weighting={false}
                            background="#F2F2F2"
                            color="#6D6D6D"
                            cursor="default"
                        />
                    )}

                    {this.renderAddNewButton({ disabled: !canCreateNew })}
                </BorderRadius>

                <Notification
                    title={notification.title || false}
                    description={notification.description || false}
                    intercationStatus={notification.status || false}
                    confirm={notification.confirm || false}
                    onClick={this.handleNotificationCancel.bind(this)}
                    okText={notification.okText || undefined}
                />
            </div>
        );
    }
}
