import _ from "lodash";

const SelectedLanguageIndex = (props) => {
    const { locales = [], currentLang = {} } = props;

    let result = _.findIndex(locales, function (item) {
        return item.value == currentLang.value;
    });

    // Defaults to first language if not found
    if (result == -1) {
        result = 0;
    }

    return result;
};

export default SelectedLanguageIndex;
