import _ from "lodash";
import ClientFeatures from "../../../../CategoryTags/ClientFeatures";

const GetCategoryMatrix = (props) => {
    const { 
        options = {},
        clientDataStructure = {},
        clientFeatures = {},
        categoryName = "",
    } = props;

    const { category_matrix = {} } = options;
    const { categories = {} } = clientDataStructure;
    const { hidden = [] } = categories

    let result = [];

    if (
        Object.keys(category_matrix).includes(categoryName) &&
        !_.isEmpty(category_matrix[categoryName]) &&
        Array.isArray(category_matrix[categoryName])
    ) {
        let allowedCategories = category_matrix[categoryName];
        let clientFeaturesCategories = ClientFeatures({ clientFeatures, clientDataStructure })
        allowedCategories.map((category) => {
            category = category.replace(" ", "");
            if (clientFeaturesCategories[category]) {
                let categoryTagObj = clientFeaturesCategories[category];
                result.push({
                    text: categoryTagObj.long,
                    value: categoryTagObj.short,
                });
            }
        });
    }

    return result;
};

export default GetCategoryMatrix;
