import React from "react";
import { Checkbox, Box, Stack, Divider } from "@mui/material";
import { CoreSelectOptionType } from "../types/CoreSelectOptionType";

export type CoreSelectSelectAllCheckboxProps = {
    options: CoreSelectOptionType[];
    isSingleSelect?: boolean;
    showSelectedItemsCount?: number;
    selectedItems: string[];
    onChange: (...event: any[]) => void;
    onBlur: any;
    ref: any;
};
export function CoreSelectSelectAllCheckbox({
    selectedItems = [],
    options,
    isSingleSelect,
    onChange,
    onBlur,
    ref,
}: CoreSelectSelectAllCheckboxProps) {
    const allSelected: boolean = Array.isArray(selectedItems) 
        ? options.every(r => selectedItems?.includes(r.value)) 
        : false;

    const handleOnChange = (): void => {
        if (allSelected) {
            onChange([])
        } else {
            onChange(options.map((o) => o.value))
        }
    }

    return (
        <>
            <Box
                key="select-all"
                component="li"
                role="menuitem"
                aria-label={`menuitem-select-all`}
                onClick={handleOnChange}
                ref={ref}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    sx={{
                        borderRadius: "0.1875rem",
                        backgroundColor:
                            allSelected && "rgba(40, 108, 210, 0.1)",
                        "&:hover": {
                            backgroundColor: "rgba(240, 240, 240, 1)",
                            cursor: "pointer",
                        },
                        color: "rgba(47, 47, 47, 0.87)",
                        height: "2.25rem",
                        padding: "0 0.5rem",
                    }}
                    paddingY={isSingleSelect && 4}
                    paddingX={isSingleSelect && 2}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            flex: 1,
                        }}
                    >
                        <Checkbox
                            disableRipple
                            edge="start"
                            onBlur={onBlur} 
                            onChange={handleOnChange}
                            checked={allSelected}
                            inputRef={ref}
                            size="small"
                            sx={{
                                margin: 0,
                                padding: "0.5rem 0.625rem 0.5rem 0",
                            }}
                        />
                        <Box flex={1} sx={{ fontStyle: "italic" }}>
                            Select All
                        </Box>
                    </Box>
                </Stack>
            </Box>
        </>
    );
}
