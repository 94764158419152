import { useFormContext } from "react-hook-form";
import { CoreRadioStack } from "../../../../../../core/Form/components/inputs/Radio/CoreRadioStack";
import { CoreSelectOptionType } from "../../../../../../core/Form/components/inputs/Select/types/CoreSelectOptionType";
import { GridXS12 } from "../layout/grid";
import { Categories, ChildCategory } from "../types/types";

export const ChildRecordCategory = () => {
    const form = useFormContext();
    const category = form.getValues("category");

    if (
        category === Categories.Episode ||
        category === Categories.Storyline ||
        category === Categories.ModularEpisode
    ) {
        return null;
    }

    let gridItemProps = {};
    const childRecordCategories: CoreSelectOptionType[] = [
        {
            label: Categories.Episode,
            value: Categories.Episode,
        },
        {
            label: Categories.Storyline,
            value: Categories.Storyline,
        },
    ];

    if (category === Categories.Show || category === Categories.Season) {
        childRecordCategories.push({
            label: Categories.ModularEpisode,
            value: Categories.ModularEpisode,
        });
        gridItemProps = {
            xs: 4,
        };
    }

    return (
        <GridXS12>
            <CoreRadioStack<ChildCategory>
                options={childRecordCategories}
                path="childCategory"
                labelProps={{
                    text: "Child Record Category",
                    isRequired: false,
                }}
                variant="condensed"
                gridItemProps={gridItemProps}
            />
        </GridXS12>
    );
};
