import React from "react";
import { SectionInterface } from "./interface";
import ReleaseDates from "../../views/ReleaseDates";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";

const SectionReleaseDates = (props: SectionInterface) => {
    const { cmsData = {}, data = {}, disabled } = props;

    const { clientFeatures = {} } = cmsData;

    const { release_dates = {}, category } = data;

    function readOnlyReleaseDates() {
        const { measurement = {} } = clientFeatures;

        const { readOnly = {} } = measurement;

        return readOnly["release_dates"] || false;
    }

    return (
        <ReleaseDates
            {...getSectionCommonProps({ ...props, field: "release_dates" })}
            name="release-dates"
            className="meta-record-release-dates"
            title="Release Dates"
            data={release_dates}
            disabled={disabled || readOnlyReleaseDates()}
            noBorder={true}
            category={category}
            clientFeatures={clientFeatures}
            cmsData={cmsData}
        />
    );
};

export default SectionReleaseDates;
