import React, { useState, useEffect } from "react";
import Select from "../Select";
import FetchCategoryList from "./helpers/FetchCategoryList";
import _ from "lodash";
import deepcopy from "deepcopy";
import CheckboxTag from "../CheckboxTag";
import Label from "../Input/label";
import "./styles/toLinkedRecords.scss";
import GetCategoryObject from "../CategoryTags/GetCategoryObject";

const InheritToLinkedRecords = (props) => {
    const {
        linkages = {},
        clientFeatures = {},
        clientDataStructure = {},
        recordCategory = "",
        handleChange = () => {},
        options = {},
    } = props;

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedRecords, setSelectedRecords] = useState([]);

    useEffect(() => {
        handleChange(selectedRecords);
    }, [selectedRecords]);

    let recordsList = [];
    if (selectedCategory) {
        recordsList = linkages.filter(
            (item) => item.category === selectedCategory,
        );
    }

    const IsSelected = (metaId) =>
        selectedRecords.find((item) => item.meta_id === metaId);

    return (
        <div className={"meta-inheritance-to-linkages"}>
            <Select
                label="Record Type"
                name="inheritRecordType"
                id="linkage_record_type"
                disabledText="Select Record Type"
                value={selectedCategory || ""}
                handleChange={(value) => setSelectedCategory(value)}
                data={FetchCategoryList({
                    options,
                    clientFeatures,
                    clientDataStructure,
                    linkages,
                    sourceCategory: recordCategory,
                })}
            />
            {selectedCategory && !_.isEmpty(recordsList) && (
                <div className={"meta-inheritance-records-list"}>
                    <Label
                        label={`Select ${
                            GetCategoryObject(selectedCategory).multiple
                        }`}
                    />
                    {recordsList.map((record) => {
                        return (
                            <CheckboxTag
                                selected={IsSelected(record.meta_id)}
                                leftText={record.meta_id}
                                centeredContent={record.original_title}
                                background="#458e6b"
                                styles={{
                                    marginTop: "0.40625rem",
                                }}
                                centeredContentStyle={{
                                    color: IsSelected(record.meta_id)
                                        ? "white"
                                        : "#787878",
                                }}
                                leftTextStyle={{
                                    color: IsSelected(record.meta_id)
                                        ? "white"
                                        : "#787878",
                                }}
                                handleSelection={() => {
                                    let selectedList =
                                        deepcopy(selectedRecords);
                                    if (IsSelected(record.meta_id)) {
                                        selectedList = selectedList.filter(
                                            (item) =>
                                                item.meta_id !== record.meta_id,
                                        );
                                    } else {
                                        selectedList.push(record);
                                    }
                                    setSelectedRecords(selectedList);
                                }}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default InheritToLinkedRecords;
