/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { GridRowParams, GridSelectionModel } from "@mui/x-data-grid";
import { CheckboxCol } from "../components/Checkbox.column";
import { RenderActionsCol } from "../components/RenderActions.column";
import { ReorderCol } from "../components/Reorder.column";
import { CoreTable } from "../CoreTable";
import { CoreTableConfig, CoreTableGridColDef, CoreTableRow } from "../types";
import { AwardsBodyCol } from "./columns/AwardsBody.column";
import { CategoryCol } from "./columns/Category.column";
import { SourceCol } from "./columns/Source.column";
import { ContributorCol } from "./columns/Contributor.column";
import { ResultsCol } from "./columns/Results.column";
import { YearCol } from "./columns/Year.column";
import { AwardsType } from "./types/Awards.type";
import { observer } from "mobx-react";
import { useStores } from "../../../features/Awards/v2/store/rootStore";
import { MovedDataInterface } from "../../../features/Awards/interfaces/awards.interfaces";
import { GridRowOrderChangeParams } from "@mui/x-data-grid-pro";

export type AwardsTypeProps = {
    awards: AwardsType[];
    disabled: boolean;
};

export const AwardsTable = observer(
    ({ awards, disabled = true }: AwardsTypeProps) => {
        const { awardsStore } = useStores() || {};

        const actions = RenderActionsCol({
            edit: {
                handleOnClick: (params: GridRowParams) => {
                    awardsStore.handleEditItemClick(params.row);
                },
            },
            delete: {
                handleOnClick: (params: GridRowParams) => {
                    awardsStore.setSelectedRows([params.row]);
                    
                    awardsStore.setAwardDeleteFormIsActive(true);
                },
            },
        });

        const columns: CoreTableGridColDef[] = [
            CheckboxCol,
            AwardsBodyCol,
            ResultsCol,
            YearCol,
            CategoryCol,
            { ...ContributorCol, disabled: disabled, awardsStore },
            SourceCol,
            ReorderCol,
            ...actions,
        ];
        const rows: CoreTableRow<AwardsType> = awards;

        const config: CoreTableConfig = {
            state: disabled ? "default" : "edit",
            emptyFallback: "No Awards Added",
            // searchBar: {},
            // exportCSV: {},
        };

        return (
            <CoreTable<AwardsType>
                config={config}
                columns={columns}
                rows={rows}
                disabled={disabled}
                hideFooter={true}
                onSelectionModelChange={(ids: GridSelectionModel) => {
                    const selectedIDs = new Set(ids);
                    const selectedRows = rows.filter((row) =>
                        selectedIDs.has(row.id),
                    );
                    awardsStore.setSelectedRows(selectedRows);
                }}
                selectionModel={awardsStore.selectedRows.map((row) => row.id)}
                onRowOrderChange={(params: GridRowOrderChangeParams) => {
                    const { oldIndex, targetIndex } = params;
                    const movedData: MovedDataInterface = {
                        oldIndex,
                        newIndex: targetIndex,
                    };
                    awardsStore.handleOrderItems(movedData);
                }}
            />
        );
    },
);
