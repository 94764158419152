import React, { ReactElement, useState } from "react";
import MUIMenuItem from "@mui/material/MenuItem";
import { Checkbox, Tooltip } from "@mui/material";
import Settings from "../../../Settings";

interface MenuItemV2Props {
    item: any;
    value: any;
    index: number;
    disableClick: boolean;
    selectType: string;
    disabled: boolean;
    data: any;
    enableTooltip: boolean;
    actions: boolean;
    handleEdit?: Function;
    handleDelete?: Function;
    rightItem?: any;
    renderRightItem?: boolean;
    enableDelete?: boolean;
    enableUpdate?: boolean;
}

interface TooltipPropsInterface {
    text: string;
    children: ReactElement;
}

const MenuItem: React.FC<MenuItemV2Props> = (props) => {
    const {
        item,
        disableClick = false,
        index,
        selectType = "single",
        disabled = true,
        data,
        enableTooltip = false,
        actions = false,
        handleEdit = () => {},
        handleDelete = () => {},
        rightItem = false,
        renderRightItem = false,
        enableDelete = true,
        enableUpdate = true,
    } = props;

    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    const RenderTooltip: React.FC<TooltipPropsInterface> = (props) => {
        const { text, children } = props;

        return (
            <Tooltip
                title={enableTooltip && text.length > 30 ? text : ""}
                placement="bottom-start"
                open={showTooltip}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                arrow
                PopperProps={{
                    disablePortal: true,
                }}
                componentsProps={{
                    tooltip: {
                        sx: {
                            bgcolor: "#3B4462",
                            color: "common.white",
                            "& .MuiTooltip-arrow": {
                                color: "#3B4462",
                            },
                        },
                    },
                }}
            >
                {children}
            </Tooltip>
        );
    };

    return (
        <MUIMenuItem
            {...props}
            className="meta-select-options--item"
            data-value={item.value || item}
            disabled={disableClick}
            key={index}
        >
            {selectType === "checkbox" && !disabled && (
                <Checkbox
                    className="meta-select-options--checkbox"
                    checked={data.includes(item.value || item.id || item)}
                />
            )}

            <RenderTooltip text={item.text || item.name || item || ""}>
                <div
                    className={
                        selectType === "checkbox"
                            ? "checkbox-value"
                            : "single-value"
                    }
                >
                    <div className="text">
                        {item.text || item.name || item || ""}
                    </div>

                    {renderRightItem && (
                        <div className="right-item">
                            {rightItem}
                        </div>
                    )}
                </div>
            </RenderTooltip>

            {!disabled && actions && (
                <div className="actions">
                    {index ? (
                        <>
                            {enableUpdate &&
                                <div
                                    className="edit"
                                    onClick={() => handleEdit(item)}
                                >
                                    <img
                                        src={`${Settings.images.path}/svg/pencil-white.svg`}
                                        alt="pencil-white-icon"
                                    />
                                </div>
                            }

                            {enableDelete &&
                                <div
                                    className="delete"
                                    onClick={() => handleDelete(item)}
                                >
                                    <img
                                        src={`${Settings.images.path}/svg/close-white.svg`}
                                        alt="close-white-icon"
                                    />
                                </div>
                            }
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            )}
        </MUIMenuItem>
    );
};

export default MenuItem;
