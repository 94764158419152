import React from "react";
import { SectionInterface } from "./interface";
import Channels from "../../views/Channels";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";

const SectionVideos = (props: SectionInterface) => {
    const { data = {} } = props;

    return (
        <Channels
            {...getSectionCommonProps({ ...props, field: "channels" })}
            name="channels"
            className="meta-record-channels"
            title="Channels"
            data={data.channels || []}
            lastSection={true}
        />
    );
};

export default SectionVideos;
