/* eslint-disable class-methods-use-this */
import { isEmpty } from "lodash";
import {
    KeyValueType,
    RecordResponse,
    RecordResponseIdentifier,
    SearchResponse,
} from "../types/interfaces";
import { fetchApi } from "../utils/fetchApi";
import { log } from "../utils/log";
import {
    API_ERROR_UNEXPECTED,
    getUrl,
    RSBaseUrl,
    RSEndpoints,
    TaskManagerApiReturnType,
} from "./api.config";

export type ApiResponseRS<T> = {
    status: number;
    data: T;
};

class RecordServiceApi {
    userToken: string = "";

    xApiKey: string = "";

    constructor(userToken?: string, xApiKey?: string) {
        this.userToken = userToken;
        this.xApiKey = xApiKey || process.env.RECORD_SERVICE_URL_X_API_KEY;
    }

    async search(
        searchTerm: string,
        category?: string,
        releaseYear?: number,
    ): Promise<TaskManagerApiReturnType<SearchResponse>> {
        const filters = {
            s: searchTerm,
            category,
            first_release_year: releaseYear,
        };
        log(
            "[API.RecordService] Obtaining records using these filters",
            filters,
        );

        try {
            const url = getUrl(RSBaseUrl, RSEndpoints.SEARCH);
            const params = filters;
            const response = await fetchApi<ApiResponseRS<SearchResponse>>(
                url,
                {
                    method: "GET",
                    params,
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": this.xApiKey,
                        token: this.userToken,
                    },
                },
            );
            console.log(
                `[API] Records from record-service using this searchTerm: ${searchTerm}`,
                response.data.active_records,
            );
            return response.data;
        } catch (err) {
            log("[API.RecordService] Failed call to search", err);
            return {
                message: API_ERROR_UNEXPECTED,
                data: {
                    active_records: [],
                },
                isError: true,
            };
        }
    }

    async searchByMetaIds(metaIds: string[] = []) {
        log(
            "[API] Obtaining records from record-service using these metaIds",
            metaIds,
        );

        if (isEmpty(metaIds)) {
            return [];
        }

        const promises = metaIds.map(async (metaId) => {
            const url = getUrl(
                RSBaseUrl,
                `${RSEndpoints.SEARCH_BY_META_ID}/${metaId}`,
            );
            const response = await fetchApi<ApiResponseRS<RecordResponse>>(
                url,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": this.xApiKey,
                        token: this.userToken,
                    },
                },
            );
            return response.data;
        });

        return Promise.all(promises);
    }

    async searchByIdentifier({ key, value }: KeyValueType) {
        if (isEmpty(key) || isEmpty(value)) {
            return null;
        }

        try {
            const url = `${getUrl(
                RSBaseUrl,
                RSEndpoints.SEARCH_BY_IDENTIFIER,
            )}/${key}/${value}`;

            const response = await fetchApi<
                ApiResponseRS<RecordResponseIdentifier>
            >(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "x-api-key": this.xApiKey,
                    token: this.userToken,
                },
            });

            return response.data;
        } catch (error) {
            return null;
        }
    }
}

export default RecordServiceApi;
