const HandleCollectionTitleSelectAllShowSpecials = (props) => {
    const { state = {} } = props;

    const { collectionTitle = {}, setCollectionTitleKey = () => {} } = state;

    const { selectedAllShowSpecials, specials = [] } = collectionTitle;

    let result = !selectedAllShowSpecials;

    setCollectionTitleKey("selectedAllShowSpecials", result);

    // Add all selected specials
    if (result && Array.isArray(specials)) {
        setCollectionTitleKey("selectedShowSpecials", specials);
    }

    // Remove all selected specials
    if (!result) {
        setCollectionTitleKey("selectedShowSpecials", []);
    }
};

export default HandleCollectionTitleSelectAllShowSpecials;
