import { CreditInterface, CreditScriptedNameInterface } from "../../../credits.interface";

interface GetNameScriptValueProps {
    field: string
    credit: CreditInterface
    script: string
}
const getNameScriptValue = (props: GetNameScriptValueProps) => {
    const {
        field,
        credit,
        script
    } = props

    /*
    if (script === defaultScript) {
        return credit[field as keyof CreditInterface];
    }*/

    const scriptedValues = [...credit.script_names]
    let targetNameIndex: number = scriptedValues.findIndex(value => value?.code.toLowerCase() === script.toLowerCase())
    let targetScriptValue: CreditScriptedNameInterface|undefined = scriptedValues[targetNameIndex]

    return targetScriptValue ? targetScriptValue?.[field as keyof CreditScriptedNameInterface] : "";
}

export default getNameScriptValue