import _ from "lodash";
import CategoryTags from "../../../components/CategoryTags";

const DisplayEpisodeSelect = (props) => {
    const { category } = props;

    let result = false;

    if (
        category == CategoryTags.Episode.short ||
        category == CategoryTags.Storyline.short ||
        category == CategoryTags.Special.short
    ) {
        result = true;
    }

    return result;
};

export default DisplayEpisodeSelect;
