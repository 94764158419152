import { CoreSelect } from "../../../../../../core/Form/components/inputs/Select/components/CoreSelect";
import { fieldOptions } from "../../../../config/clients/viacom/generators/inheritFields";
import { GridXS12 } from "../layout/grid";
import { SeasonFormData, TvShowFormData } from "../types/types";

// TODO: remove duplicated code

type InheritanceProps = {
    variant:
        | keyof Pick<SeasonFormData, "seasonInheritance">
        | keyof Pick<SeasonFormData, "episodeInheritance">
        | keyof Pick<TvShowFormData, "seasonEpisodeInheritance">;
};

export const Inheritance = ({ variant }: InheritanceProps) => {
    if (variant === "seasonInheritance") {
        return (
            <GridXS12>
                <CoreSelect<SeasonFormData>
                    config={{
                        path: "seasonInheritance",
                        labelProps: {
                            text: "Season Inheritance",
                            isRequired: false,
                        },
                        options: fieldOptions,
                        showChipArray: true,
                        multiple: true,
                    }}
                />
            </GridXS12>
        );
    }

    if (variant === "episodeInheritance") {
        return (
            <GridXS12>
                <CoreSelect<SeasonFormData>
                    config={{
                        path: "episodeInheritance",
                        labelProps: {
                            text: "Children Inheritance", // "Episode Inheritance",
                            isRequired: false,
                        },
                        options: fieldOptions,
                        showChipArray: true,
                        enableSearchbox: true,
                        multiple: true,
                    }}
                />
            </GridXS12>
        );
    }

    if (variant === "seasonEpisodeInheritance") {
        return (
            <GridXS12>
                <CoreSelect<TvShowFormData>
                    config={{
                        path: "seasonEpisodeInheritance",
                        labelProps: {
                            text: "Season & Episode Inheritance",
                            isRequired: false,
                        },
                        options: fieldOptions,
                        showChipArray: true,
                        multiple: true,
                    }}
                />
            </GridXS12>
        );
    }

    return <div>Invalid variant passed to Inheritance component</div>;
};
