export const classPrefix: string = 'credits-v4';

export const coreDialogProps: any = {
    dialogMaxWidth: "37.5rem",
    dialogContentWidth: "57.5rem"
}

export const dialogTitleProps: any = {
    fontSize: "1rem",
    letterSpacing: "unset",
    lineHeight: "1.4",
    color: "rgba(47, 47, 47, 0.87)",
}

export const roleNameActor: string = 'Actor';