import React, { useMemo } from "react"
import { observer } from "mobx-react";
import { useStores } from "../../../store/rootStore";
import { CreditInterface, DataTablePayload } from "../../../credits.interface";
import Notification from "../../../../../../components/Notification";
import Settings from "../../../../../../components/Settings";

const DeleteForm: React.FC = observer(() => {
    const rootStore = useStores()
    const { creditsStore } = rootStore || {}
    const { source } = creditsStore.getCreditDeleteFormProperty
    const allCredits: CreditInterface[] = creditsStore.credits;
    const selectedCreditIds: DataTablePayload[] = creditsStore.selectedCredits[source as "active" | "inactive"];
    const creditsToDelete = allCredits.filter(credit => selectedCreditIds.some(id =>
        credit.role_name === id.role && credit.local_id === id.localID
    ));

    const renderFormElements = (): JSX.Element => {
        const activeLists = useMemo(() => {
            if (creditsToDelete.length === 1) {
                const credit = creditsToDelete[0];
                return credit.credit_lists
                    .filter(list => !list.deleted)
                    .map(list => list.name);
            }
            return [];
        }, [creditsToDelete]);

        const deleteText = useMemo(() => {
            if (creditsToDelete.length === 1) {
                if (activeLists.length > 0) {
                    return `This Credit is active in ${activeLists.join(", ")}. If you delete this credit it will delete from ${activeLists.length > 1 ? "these credit lists." : "this credit list."
                        } Are you sure you want to delete?`;
                } else {
                    return "Are you sure you want to delete this credit?";
                }
            } else if (creditsToDelete.length >= 2) {
                return `These ${creditsToDelete.length} credits may be active in other Credit Lists. Deleting them will result in permanent deletion from all Credit Lists. Are you sure you want to delete?`;
            }
            return "";
        }, [creditsToDelete, activeLists]);

        return (
            <div className="update-create-credit-form">
                <p style={{ lineHeight: "1.375rem" }}>{deleteText}</p>
            </div>
        );
    }

    const handleConfirm = () => {
        creditsStore.deleteCredits(creditsStore.creditDeleteFormProperties.source as "active" | "inactive")
        creditsStore.setCreditDeleteFormStatus(false)
    }

    const handleCancel = () => {
        creditsStore.setCreditDeleteFormStatus(false)
    }

    return (
        <React.Fragment>
            <Notification
                intercationStatus="error"
                background={"white"}
                icon={`${Settings.images.path}/svg/alert.svg}`}
                disabled={false}
                title={"Delete Credit"}
                okText={"Delete"}
                html={renderFormElements()}
                confirm={handleConfirm}
                cancelClick={handleCancel}
            />
        </React.Fragment>
    )
})

export default DeleteForm