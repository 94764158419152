const HandleDeleteRow = (props) => {
    const {
        index,
        setItems,
        items = [],
        setEditRowIndex,
        setActiveRow,
        handleChange = () => {},
    } = props;

    let updatedState = [...items];

    if (Number.isInteger(index)) {
        updatedState = updatedState.filter(
            (item, itemIndex) => index !== itemIndex,
        );

        setItems(updatedState);
        setActiveRow({});
        setEditRowIndex(false);
        handleChange(updatedState);
    }
};

export default HandleDeleteRow;
