import React, { Fragment } from "react";
import { useStore } from "../state/context";
import AssetContainer from "./asset";
import { ASSET_PLATFORMS_CONFIG } from "../configuration";
import AssetPlatformInterface from "./asset/interfaces/AssetPlatformInterface";
import { getSystemEnvironment } from "../../src/GenerateNewAsset";
import _ from "lodash";
import AssetInterface from "./asset/interfaces/AssetInterface";
import "./styles/_styles.scss";
import SectionContentWrapper from "../../../Boilerplates/SectionContentWrapper";

type PlatformIterationData = {
    key: string;
    name: string;
    assets: Array<AssetInterface>;
};

const PlatformContainer: React.FC = () => {
    const [store] = useStore();

    const { assets = [], configuration = {} } = store;

    const GetPlatforms: Function = (): Array<PlatformIterationData> => {
        const platformConfigs: Array<AssetPlatformInterface> =
            configuration[ASSET_PLATFORMS_CONFIG] || [];

        return platformConfigs
            .filter((item) => !item.archived)
            .reduce((result, platform) => {
                let platformAssets = assets
                    .filter((asset: AssetInterface) => !asset.deleted)
                    .filter(
                        (asset: AssetInterface) =>
                            asset.destination_platform?.toLowerCase() ==
                            platform.key?.toLowerCase(),
                    );

                if (!_.isEmpty(platformAssets)) {
                    result.push({
                        key: platform.key,
                        name: platform.displayName,
                        assets: platformAssets,
                    });
                }

                return result;
            }, []);
    };

    return (
        <Fragment>
            {GetPlatforms().map(
                (platform: PlatformIterationData, key: number) => {
                    return (
                        <SectionContentWrapper
                            header={{
                                tags: [
                                    [
                                        {
                                            text: "ASSET MANAGEMENT SYSTEM",
                                            color: "#626261",
                                            background: "#DADBDB",
                                            uppercase: true,
                                        },
                                        {
                                            text: platform.name.toUpperCase(),
                                            color: "#878787",
                                            background: "#EDECED",
                                            uppercase: true,
                                        },
                                    ],
                                    [
                                        {
                                            text: `${getSystemEnvironment()} ENVIRONMENT`,
                                            color: "#DA8F33",
                                            background: "#F7E9CF",
                                            uppercase: true,
                                        },
                                    ],
                                ],
                            }}
                            containers={platform.assets.map((asset, index) => {
                                return (
                                    <AssetContainer
                                        asset={asset}
                                        key={index}
                                        rowIndex={index}
                                    />
                                );
                            })}
                        />
                    );
                },
            )}
        </Fragment>
    );
};

export default PlatformContainer;
