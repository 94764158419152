import Attribute from "../Attribute";
import { envDev } from "../Environment";
import { GetFromLocalStorage } from "../Storage/localStorage";

export default function getRequestOptions(options) {
    const element = "#tokens";
    let apiToken = Attribute(element, "data-api-token") || "";
    let xApiKey = process.env.RECORD_SERVICE_URL_X_API_KEY || "";

    let userToken = process.env.USER_TOKEN_OVERRIDE 
        || Attribute(element, "data-user-token") 
        || "";

    if (typeof options == "object") {
        if (options.user_token) {
            userToken = options.user_token;
        }

        if (options.api_token) {
            apiToken = options.api_token;
        }

        if (options.x_api_key) {
            xApiKey = options.x_api_key;
        }

        if (options.url && options.method) {
            let request = {
                method: options.method,
            };

            let body = options.body || false;

            if (body && userToken) {
                body.token = userToken;
            }

            if (options.noToken === true) {
                delete body.token;
            }

            request.headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
                "x-api-key": xApiKey,
            };

            if (options.noXApiKey) {
                request.headers["Authorization"] = `Bearer ${apiToken}`;
            }

            if (options.basicXApiKey) {
                request.headers = {
                    "x-api-key": xApiKey,
                };
            }

            if (options.basic) {
                request.headers = {
                    Authorization: `Bearer ${apiToken}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                };

                if (options.noJson === true) {
                    delete request.headers.Accept;
                    delete request.headers["Content-Type"];
                }
            }

            if (options.basicUsernamePassword) {
                request.headers = {
                    Authorization: `Basic ${btoa(
                        `${options.username}:${options.password}`,
                    )}`,
                };
            }

            if (body) {
                request.body = JSON.stringify(body);
            }

            if (options.formData) {
                request.body = options.formData;
            }

            if (userToken) {
                request.headers.token = userToken;
            }

            if (options.headers) {
                request.headers = {
                    ...request.headers,
                    ...options.headers,
                };
            }

            if (options.corsHeaders) {
                request.headers = {
                    ...request.headers,
                    "Access-Control-Allow-Headers": "*",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*",
                    "Content-Type": "application/json",
                };
            }

            if (options.overrideHeaders) {
                request.headers = options.overrideHeaders;
            }

            if (options.mode) {
                request.mode = options.mode;
            }

            if (options.credentials) {
                request.credentials = options.credentials;
            }

            if (options.force_preflight) {
                request.force_preflight = options.force_preflight;
            }

            if (options.redirect) {
                request.redirect = options.redirect;
            }

            if (options.referrerPolicy) {
                request.referrerPolicy = options.referrerPolicy;
            }

            if (options.signal) {
                request.signal = options.signal;
            }

            return request;
        }
    }

    return {};
}
