import { isEmpty } from "lodash";
import { EpisodeVT, SeasonVT, TvShowVT } from "../config/types";
import store from "../containers/record/tv-series/store/store";
import { NuvoRecord } from "../containers/record/tv-series/types/nuvo.types";
import { Categories } from "../containers/record/tv-series/types/types";
import randomNumber from "../utils/randomNumber";

interface TvSeriesRelationshipsInterface {
    findParentShow(nuvoRecord: NuvoRecord): TvShowVT;
    findParentSeason(nuvoRecord: NuvoRecord): SeasonVT;
    findParentEpisode(nuvoRecord: NuvoRecord): EpisodeVT;
}

class TvSeriesRelationships implements TvSeriesRelationshipsInterface {
    findParentShow(nuvoRecord: NuvoRecord): TvShowVT {
        if (nuvoRecord?.category === Categories.Show) {
            return null; // Shows can't have parentShow
        }

        let show: TvShowVT;
        if (isEmpty(nuvoRecord?.manualLink_parent_show_meta_id)) {
            // search by title because it should be a local tv show
            show = store.findByKey({
                category: Categories.Show,
                key: "title",
                value: nuvoRecord.manualLink_parent_show_title,
            });
        } else {
            // search by metaId because it should be a remote tv show
            show = store.findByKey({
                category: Categories.Show,
                key: "metaId",
                value: nuvoRecord.manualLink_parent_show_meta_id,
            });
        }

        // If we still don't have a show, create a dummy one
        if (isEmpty(show)) {
            const randomId = randomNumber();
            return {
                id: randomId,
                category: Categories.Show,
                title: `_PARENT_SHOW_TITLE_MISSING_${randomId}`,
            };
        }

        return show;
    }

    findParentSeason(nuvoRecord: NuvoRecord): SeasonVT {
        if (isEmpty(nuvoRecord)) {
            return null;
        }

        if (
            nuvoRecord.category !== Categories.Episode &&
            nuvoRecord.category !== Categories.ModularEpisode &&
            nuvoRecord.category !== Categories.Storyline
        ) {
            return null; // Only certain categories of records can have parentSeason
        }

        let season: SeasonVT;
        if (isEmpty(nuvoRecord.manualLink_parent_season_meta_id)) {
            season = store.findByKeys({
                category: Categories.Season,
                keyValuePairs: [
                    {
                        key: "seasonNo",
                        value: nuvoRecord?.manualLink_parent_season_number,
                    },
                    {
                        key: "parentShow.title",
                        value: nuvoRecord?.manualLink_parent_show_title,
                    },
                ],
            });

            // Could be remote show
            if (isEmpty(season)) {
                season = store.findByKeys({
                    category: Categories.Season,
                    keyValuePairs: [
                        {
                            key: "seasonNo",
                            value: nuvoRecord.manualLink_parent_season_number,
                        },
                        {
                            key: "parentShow.metaId",
                            value: nuvoRecord.manualLink_parent_show_meta_id,
                        },
                    ],
                });
            }
        } else {
            season = store.findByKeys({
                category: Categories.Season,
                keyValuePairs: [
                    {
                        key: "metaId",
                        value: nuvoRecord.manualLink_parent_season_meta_id,
                    },
                    {
                        key: "parentShow.metaId",
                        value: nuvoRecord.manualLink_parent_show_meta_id,
                    },
                ],
            });
        }

        if (isEmpty(season)) {
            // If we still don't have a season, create a dummy one
            console.log(
                "Due to missing parent season, creating a dummy one",
                nuvoRecord,
            );

            return {
                title: `_PARENT_SEASON_TITLE_MISSING_${randomNumber()}`,
                seasonNo: nuvoRecord.manualLink_parent_season_number,
            };
        }

        return season;
    }

    findParentEpisode(nuvoRecord: NuvoRecord): EpisodeVT {
        if (isEmpty(nuvoRecord)) {
            return null;
        }

        if (nuvoRecord.category !== Categories.Storyline) {
            return null; // Only Storylines can have parent episodes for now
        }

        if (Number(nuvoRecord.manualLink_parent_episode_number) <= 0) {
            console.log("Parent episode number has to be > 0", nuvoRecord);
            return null;
        }

        // search by title because it should be a local tv episode
        let episode: EpisodeVT = store.findByKeys({
            category: Categories.ModularEpisode,
            keyValuePairs: [
                {
                    key: "episodeNo",
                    value: nuvoRecord.manualLink_parent_episode_number,
                },
                {
                    key: "parentSeason.seasonNo",
                    value: nuvoRecord.manualLink_parent_season_number,
                },
                {
                    key: "parentShow.title",
                    value: nuvoRecord.manualLink_parent_show_title,
                },
            ],
        });

        if (isEmpty(episode)) {
            // The show may be remote, not local
            episode = store.findByKeys({
                category: Categories.ModularEpisode,
                keyValuePairs: [
                    {
                        key: "episodeNo",
                        value: nuvoRecord.manualLink_parent_episode_number,
                    },
                    {
                        key: "parentSeason.seasonNo",
                        value: nuvoRecord.manualLink_parent_season_number,
                    },
                    {
                        key: "parentShow.metaId",
                        value: nuvoRecord.manualLink_parent_show_meta_id,
                    },
                ],
            });

            // Both show and seasons may be remote, not local
            if (isEmpty(episode)) {
                episode = store.findByKeys({
                    category: Categories.ModularEpisode,
                    keyValuePairs: [
                        {
                            key: "episodeNo",
                            value: nuvoRecord.manualLink_parent_episode_number,
                        },
                        {
                            key: "parentSeason.metaId",
                            value: nuvoRecord.manualLink_parent_season_meta_id,
                        },
                        {
                            key: "parentShow.metaId",
                            value: nuvoRecord.manualLink_parent_show_meta_id,
                        },
                    ],
                });
            }
        }

        return episode;
    }
}

export default new TvSeriesRelationships();
