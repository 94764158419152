import { SectionInterface } from "./interface";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";
import FabricStudioNumbering from "../../views/FabricStudioNumbering";
import CoreFormFieldInterface, { DataType, InputType } from '../../../../core/Form/dynamic/interfaces/Field.interface'

const SectionEpisodeNumberingCore = (props: SectionInterface & { section?: string }) => {
    const { data = {}, section = 'episode_numbering' } = props;
    
    const episodeCoreField: CoreFormFieldInterface = {
        type: {
            dataType: DataType.Number,
            input: InputType.Numeric,
        },
        label: "Original Running Order Position Number",
        key: "position",
        disabled: true,
        order: 1,
        layer: { level: 1, columns: 3 },
        validation: {
            readOnly: { enabled: true }
        }
    }

    return (
        <FabricStudioNumbering 
            {...getSectionCommonProps({ ...props, field: section })}
            category={data.category}
            data={data[section] || {}}
            coreDynamicFormFields={[episodeCoreField]}
            disabled={true}
        />
    )
}

export default SectionEpisodeNumberingCore