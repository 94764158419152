import { SET_STATE } from "./index";

const setState = (data) => {
    return {
        type: SET_STATE,
        state: { ...data },
    };
};

export default setState;
