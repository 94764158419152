import React from "react";
import NumberFormat from "react-number-format";
import TrippleLeftColorTag from "../../DoubleTags/TrippleLeftColor";

const RenderTag = (props) => {
    const { text, centerText, rightText, styles = {} } = props;

    const RenderFormattedNumber = (props) => {
        if (!Number.isInteger(centerText)) {
            return "-";
        }

        return (
            <NumberFormat
                value={props.value}
                displayType={"text"}
                thousandSeparator={true}
                prefix={props.prefix ? "$" : ""}
                decimalScale={2}
            />
        );
    };

    const RenderRightText = (props) => {
        if (!Number.isInteger(props.value)) {
            return "-";
        }

        return `${props.value.toFixed(2)}%`;
    };

    return (
        <TrippleLeftColorTag
            leftText={text}
            centerText={
                <RenderFormattedNumber value={centerText} prefix={true} />
            }
            rightText={<RenderRightText value={rightText} />}
            styles={styles}
        />
    );
};

export default RenderTag;
