import React from "react";
import Settings from "../Settings";
import "./_styles.scss";

interface ActionIconInterface {
    className?: string;
    handleOnClick?: (e: any) => void;
    variant?: string;
}

interface ActionIconContainerInterface {
    children: JSX.Element;
    className?: string;
    handleOnClick?: (e: any) => void;
}

export const ActionIconContainer = ({
    children,
    handleOnClick,
    className,
}: ActionIconContainerInterface) => {
    return (
        <div
            className={`action-icon-container ${className}`}
            onClick={
                handleOnClick
                    ? (e) => {
                          e.stopPropagation();
                          handleOnClick(e);
                      }
                    : () => {}
            }
        >
            {children}
        </div>
    );
};

export const ActionIconDoubleArrow = ({
    className,
    handleOnClick,
}: ActionIconInterface) => {
    return (
        <ActionIconContainer
            handleOnClick={handleOnClick}
            className="double-arrows"
        >
            <div className="double-arrows-container">
                <img
                    className={`double-arrow--icon ${className}`}
                    src={`${Settings.images.path}/svg/arrow-grey-up.svg`}
                />

                <img
                    className={`double-arrow--icon ${className}`}
                    src={`${Settings.images.path}/svg/arrow-grey.svg`}
                />
            </div>
        </ActionIconContainer>
    );
};

export const ActionIconSingleArrow = ({
    className,
    variant,
}: ActionIconInterface) => {
    const GetArrowIconSrc = () => {
        let src = "";

        switch (variant) {
            case "small":
                src = "/svg/arrow-expandable-small.svg";
                break;
            case "large":
                src = "/svg/arrow-expandable-large.svg";
                break;
            default:
                src = "/svg/arrow-expandable-small.svg";
        }

        return src;
    };

    return (
        <ActionIconContainer>
            <img
                className={`meta-expandable-row--icon ${className}`}
                src={`${Settings.images.path}${GetArrowIconSrc()}`}
            />
        </ActionIconContainer>
    );
};
