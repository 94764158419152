import React, { useRef } from "react";
import "./styles/_styles.scss";
import $ from "jquery";
import Settings from "../Settings";
import { envTest } from "../Environment";
import WhiteBox from "../Containers/WhiteBox";
import Checkbox from "@mui/material/Checkbox";

const TOGGLE_OPEN: string = "Open";
const TOGGLE_COLLAPSE: string = "Collapse";
const CONTENT_CLASS: string = "collapsable-content";
const TOGGLE_ICON_CLASS: string = "toggle-icon";
const SLIDE_SPEED: number = 150;
const TOGGLE_CONTAINER_CLASS: string = "toggle-container";

interface ComponentProps {
    title?: string;
    checkbox: boolean;
    handleChange?: (value: boolean) => void;
    shouldShowChildren?: boolean;
    children?: JSX.Element;
    checked?: boolean;
    className?: string;
    disabled?: boolean;
    titleElement?: JSX.Element;
}

const CollapsableCheckboxSection = ({
    disabled,
    title,
    checkbox,
    handleChange,
    shouldShowChildren,
    checked,
    children,
    className,
    titleElement
}: ComponentProps): JSX.Element => {
    const containerRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const toggleTextRef = useRef<HTMLDivElement>(null);

    function handleClick(): void {
        if (!$(containerRef.current).hasClass(TOGGLE_OPEN.toLowerCase())) {
            $(contentRef.current).slideDown(SLIDE_SPEED);
            $(containerRef.current).addClass(TOGGLE_OPEN.toLowerCase());
            $(toggleTextRef.current).text(TOGGLE_COLLAPSE);
        } else {
            $(contentRef.current).slideUp(SLIDE_SPEED);
            $(containerRef.current).removeClass(TOGGLE_OPEN.toLowerCase());
            $(toggleTextRef.current).text(TOGGLE_OPEN);
        }
    }

    return (
        <WhiteBox>
            <div
                className={`collapsable-checkbox-section ${
                    className ? className : ""
                }`}
                onClick={() => handleClick()}
            >
                <div className="header">
                    {checkbox && (
                        <Checkbox
                            checked={checked}
                            disabled={disabled}
                            style={{ marginRight: "0.5625rem", padding: 0 }}
                            onChange={(
                                e: React.SyntheticEvent,
                                value: boolean,
                            ) => handleChange(value)}
                            size="small"
                        />
                    )}

                    {(title && !titleElement) &&
                        <span className="title">
                            {title}
                        </span>
                    }

                    {(!title && titleElement) &&
                        <div className="title">
                            {titleElement}
                        </div>
                    }

                    {shouldShowChildren && (
                        <div
                            className={
                                envTest ? TOGGLE_OPEN : TOGGLE_CONTAINER_CLASS
                            }
                            ref={containerRef}
                        >
                            <img
                                src={`${Settings.images.path}/svg/arrow-light-grey.svg`}
                                className={TOGGLE_ICON_CLASS}
                            />
                        </div>
                    )}
                </div>

                {children && shouldShowChildren && (
                    <div 
                        className={CONTENT_CLASS} 
                        ref={contentRef}
                        onClick={(e) => e.stopPropagation()}>

                        {children}
                    </div>
                )}
            </div>
        </WhiteBox>
    );
};

export default CollapsableCheckboxSection;
