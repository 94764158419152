import { Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

type NoMatchProps<TOption> = {
    pathValue: string;
    onClick: (option: TOption) => void;
    inputPath: string;
    suggestionsExist: boolean;
};

const StyledTypography = styled(Typography)(({ theme }) => ({
    color: "rgba(47, 47, 47, 0.87)",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "145%",
    letterSpacing: "0.01rem",
}));

const BoldStyledTypography = styled(StyledTypography)(({ theme }) => ({
    fontWeight: 500,
    cursor: "pointer",
}));

const StyledStack = styled(Stack)(({ theme }) => ({
    margin: "0"
}));
StyledStack.defaultProps = {
    direction: "row",
    alignItems: "center",
};

export const NoMatchCreateNew = <TOption extends unknown>({
    pathValue,
    onClick,
    inputPath,
    suggestionsExist
}: NoMatchProps<TOption>) => (
    <StyledStack sx={{
        margin: suggestionsExist && "0 0 0.625rem 0"
    }}>
        <StyledTypography>No match for {`“${pathValue}“.`}</StyledTypography>
        &nbsp;
        <BoldStyledTypography
            onClick={() => {
                return (
                    typeof onClick === "function" &&
                    onClick({ [inputPath]: pathValue } as TOption)
                );
            }}
        >
            Create New?
        </BoldStyledTypography>
    </StyledStack>
);

export const NoMatch = <TOption extends unknown>({
    pathValue,
    suggestionsExist
}: Pick<NoMatchProps<TOption>, "pathValue" | "suggestionsExist">) => (
    <StyledStack sx={{
        margin: suggestionsExist && "0 0 0.625rem 0"
    }}>
        <StyledTypography>No match for {`“${pathValue}“.`}</StyledTypography>
    </StyledStack>
);
