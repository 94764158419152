import SendRequest from "../SendRequest";
import _ from "lodash";
import { SetLocalStorage } from "../Storage/localStorage";

const OptionsSearchApi = (props) => {
    const {
        apiUrls = {},
        optionUrl = null,
        searchTerm = "",
        updateList = () => {},
        signal,
        onResponse = () => {},
        onError = () => {},
        url,
        type,
        storeKey,
        testingData,
    } = props;

    const { recordService = "" } = apiUrls;

    if ((!_.isEmpty(optionUrl) && !_.isEmpty(recordService)) || url) {
        let optionsUrl = `${recordService}${optionUrl}?s=${searchTerm}`;
        if (url) optionsUrl = `${url}?s=${searchTerm}`;
        if (url && type) optionsUrl = `${url}?s=${searchTerm}&type=${type}`;

        try {
            const options = {
                url: optionsUrl,
                method: "GET",
                signal,
                testingData,
            };

            const handleError = (error) => {
                onError(error)
                console.log(`Error fetching ${searchTerm}`);
            };

            SendRequest(
                options,
                (data) => {
                    onResponse();

                    if (data.status == 200) {
                        const dataState = data.data || [];
                        updateList(dataState);

                        if (storeKey) {
                            SetLocalStorage({
                                name: storeKey,
                                value: JSON.stringify(dataState),
                            });
                        }
                    } else {
                        handleError(data);
                    }
                },
                (error) => {
                    onResponse();
                    handleError(error);
                },
            );
        } catch (e) {
            onResponse();
            handleError();
        }
    }
};

export default OptionsSearchApi;
