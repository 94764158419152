import _ from "lodash"
import { FileInterface } from "./interfaces"
import getFileExtension from "../Helpers/fileExtension"

export default function filterFilesByExtension(event: React.ChangeEvent<HTMLInputElement>, extensions: string): React.ChangeEvent<HTMLInputElement> {
    const {
        target
    } = event

    let filteredFiles: FileInterface[] = []
    const files: FileInterface[] = target?.files

    if (_.isEmpty(extensions) || !files.length) {
        return event
    }

    for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const extension = getFileExtension(file.name)

        if (extensions?.includes(extension)) {
            filteredFiles.push(file)
        }
    }

    return {
        ...event,
        target: {
            files: filteredFiles
        }
    }
}