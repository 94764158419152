import React from "react";
import styles from "./styles";
import Select from "../Select";
import Index from "./index";
import _ from "lodash";
import update from "react-addons-update";
import deepcopy from "deepcopy";

export default class Certifications extends Index {
    constructor(props) {
        super(props);

        /**
         * @var object
         */
        this.defaultActiveRowData = {
            certification_type: null,
            region: null,
            system: null,
            type: null,
            environment: null,
            rating: null,
        };

        /**
         * @var object
         */
        this.state = {
            data: props.data || [],
            editRow: false,
            hoverRow: false,
            newRow: true,
            activeRow: { ...this.defaultActiveRowData },
            notification: {},
            movedItemIndex: false,
            regionDisabled: false,
            systemDisabled: false,
            typeDisabled: false,
            environmentDisabled: false,
        };

        /**
         * @var object
         */
        this.styles = styles(this.props);

        /**
         * @var object
         */
        this.copy = this.props.copy || {};

        this.officalBody = "Official Body";
        this.externalResource = "External Resource";
        this.internal = "Internal";
    }

    setActiveRowDefault() {
        this.setActiveRow(this.defaultActiveRowData);
    }

    getRatingBackgroundColor(certification_type, index) {
        let oddIndex = index % 2;
        let result = "#8eb9ff";

        if (oddIndex == 1) {
            result = "#72a1f0";
        }

        if (certification_type) {
            if (certification_type == "Internal") {
                result = "#f17878";

                if (oddIndex == 1) {
                    result = "#e06969";
                }
            }

            if (certification_type == "External Resource") {
                result = "#6ecc94";

                if (oddIndex == 1) {
                    result = "#42bd92";
                }
            }
        }

        return result;
    }

    checkEmptyDataKeys() {
        let result = true;
        let validationKeys = [
            "certification_type",
            "region",
            "system",
            "type",
            "environment",
            "rating",
        ];

        const { data = {} } = this.state;

        validationKeys.forEach((item) => {
            if (!_.isEmpty(data[item])) result = false;
        });

        return result;
    }

    isActiveRowValidated() {
        const { activeRow = {} } = this.state;

        const { rating } = activeRow;

        return !_.isEmpty(rating);
    }

    getRowByIndex(rowIndex) {
        const { data = [] } = this.state;

        return data.find((value, key) => key === rowIndex);
    }

    handleNewRow() {
        if (this.state.editRow === false && Array.isArray(this.state.data)) {
            const data = update(this.state.data, {
                $push: [this.defaultActiveRowData],
            });
            this.setData(data);
            this.setEditRow(data.length - 1);
        }
    }

    handleChange(key, index, value) {
        if (key) {
            let activeRow = deepcopy(this.getRowByIndex(index));
            activeRow[key] = value;

            if (key == "certification_type") {
                activeRow.region = null;
                activeRow.system = null;
                activeRow.type = null;
                activeRow.environment = null;
                activeRow.rating = null;
            }

            if (key == "region") {
                activeRow.system = null;
                activeRow.type = null;
                activeRow.environment = null;
                activeRow.rating = null;
            }

            if (key == "system") {
                activeRow.type = null;
                activeRow.environment = null;
                activeRow.rating = null;
            }

            if (key == "type") {
                activeRow.environment = null;
                activeRow.rating = null;
            }

            if (key == "environment") {
                activeRow.rating = null;
            }

            let stateData = [...this.state.data];
            stateData[index] = activeRow;

            this.setData(stateData);
            this.setEditRow(index);
            this.setState({ activeRow }, () => this.handleStateSave());
        }
    }

    getDisabledFieldsByType(activeRow) {
        let result = {
            systemDisabled: false,
            typeDisabled: false,
            environmentDisabled: false,
        };

        // Disables columns
        const certification_type = activeRow.certification_type;

        if (
            certification_type == this.externalResource ||
            activeRow.region == "Worldwide"
        ) {
            result.systemDisabled = true;
            result.typeDisabled = true;
            result.environmentDisabled = true;
        }

        if (certification_type == this.internal) {
            result.typeDisabled = true;
            result.environmentDisabled = true;
        }

        return result;
    }

    renderDataRow(item, index, count) {
        if (typeof item == "object") {
            let disabled = this.props.disabled || false;
            const oddIndex = count % 2;

            const {
                certification_type,
                region,
                system,
                type,
                environment,
                rating,
            } = item;

            const {
                systemDisabled = false,
                typeDisabled = false,
                environmentDisabled = false,
                regionDisabled = false,
            } = this.getDisabledFieldsByType(item);

            const { options = {}, clientFeatures = {} } = this.props;

            const {
                external_ratings = [],
                internal_ratings = [],
                region_systems = {},
                external_resource = [],
            } = options;

            let internal_systems = deepcopy(options.internal_systems || {});

            const background = this.getRatingBackgroundColor(
                certification_type,
                index,
            );

            if (this.state.editRow === index) {
                disabled = false;
            }

            let regions = [];
            if (certification_type) regions = Object.keys(region_systems) || [];
            if (certification_type == this.officalBody)
                regions = regions.filter((item) => item !== "Worldwide");
            if (certification_type == this.externalResource)
                regions = external_resource;

            let systems = [];
            let types = [];
            let environments = [];
            let ratings = [];

            // Selects options
            if (region_systems[region]) {
                systems = Object.keys(region_systems[region]) || [];

                if (system && certification_type !== this.internal) {
                    const systemData = region_systems[region][system];

                    if (!_.isEmpty(systemData)) {
                        types = systemData.types || [];
                        environments = systemData.environments || [];
                        ratings = systemData.ratings || [];
                    }
                }
            }

            // Overwrites
            if (certification_type == this.externalResource)
                ratings = external_ratings;

            // Internal
            if (certification_type == this.internal) {
                if (clientFeatures.useCertificationsInternalSystemsLocal)
                    internal_systems = deepcopy(
                        options.internal_systems_local || {},
                    );
                if (
                    !_.isEmpty(internal_systems) &&
                    internal_systems.regions &&
                    internal_systems.regions[region]
                ) {
                    systems =
                        Object.keys(internal_systems.regions[region]) || [];

                    if (
                        region &&
                        system &&
                        internal_systems.regions[region][system]
                    ) {
                        ratings =
                            internal_systems.regions[region][system].ratings;
                    }
                } else {
                    systems = internal_systems.default;
                    ratings = internal_ratings;
                }
            }

            let certificationTypes = options.types || [];
            // Removes internal if no internal systems found
            if (_.isEmpty(internal_systems)) {
                certificationTypes = certificationTypes.filter(
                    (item) => item !== "Internal",
                );
            }

            if (_.isEmpty(ratings) && rating) {
                ratings.push(rating);
            }

            return (
                <div style={this.styles.tableContainer} key={index}>
                    <div style={this.styles.tableContent}>
                        <table
                            style={this.styles.table}
                            cellPadding="0"
                            cellSpacing="0"
                        >
                            <tbody style={this.styles.tbody}>
                                <tr
                                    style={Object.assign(
                                        {},
                                        this.styles.trow,
                                        oddIndex == 1 && this.styles.trEven,
                                    )}
                                >
                                    <td
                                        style={Object.assign(
                                            {},
                                            this.styles.td,
                                            { width: "12.59375rem" },
                                        )}
                                    >
                                        <Select
                                            name="certification_type"
                                            id="certificate_certification_type"
                                            value={certification_type}
                                            disabledText={
                                                this.copy.selectType ||
                                                "Select Type"
                                            }
                                            handleChange={(value) =>
                                                this.handleChange(
                                                    "certification_type",
                                                    index,
                                                    value,
                                                )
                                            }
                                            clear={true}
                                            noMargin={true}
                                            readOnlyPadding={true}
                                            readOnly={disabled}
                                            disabled={disabled}
                                            noneSelectedText={
                                                certification_type
                                                    ? certification_type
                                                    : "-"
                                            }
                                            data={certificationTypes}
                                        />
                                    </td>

                                    <td
                                        style={Object.assign(
                                            {},
                                            this.styles.td,
                                            { width: "15rem" },
                                        )}
                                    >
                                        <Select
                                            name="region"
                                            id="certificate_region"
                                            value={region}
                                            disabledText={
                                                this.copy.selectRegion ||
                                                "Select Region"
                                            }
                                            handleChange={(value) =>
                                                this.handleChange(
                                                    "region",
                                                    index,
                                                    value,
                                                )
                                            }
                                            clear={true}
                                            noMargin={true}
                                            readOnlyPadding={true}
                                            readOnly={
                                                regionDisabled ? true : disabled
                                            }
                                            disabled={
                                                regionDisabled ? true : disabled
                                            }
                                            noneSelectedText={
                                                region ? region : "-"
                                            }
                                            data={regions}
                                        />
                                    </td>

                                    <td
                                        style={Object.assign(
                                            {},
                                            this.styles.td,
                                            { width: "10rem" },
                                        )}
                                    >
                                        <Select
                                            name="system"
                                            id="certificate_system"
                                            value={system}
                                            disabledText={
                                                this.copy.selectSystem ||
                                                "Select System"
                                            }
                                            handleChange={(value) =>
                                                this.handleChange(
                                                    "system",
                                                    index,
                                                    value,
                                                )
                                            }
                                            clear={true}
                                            noMargin={true}
                                            readOnlyPadding={true}
                                            readOnly={
                                                systemDisabled ? true : disabled
                                            }
                                            disabled={
                                                systemDisabled ? true : disabled
                                            }
                                            noneSelectedText={
                                                system ? system : "-"
                                            }
                                            data={systems}
                                        />
                                    </td>

                                    <td
                                        style={Object.assign(
                                            {},
                                            this.styles.td,
                                            { width: "10rem" },
                                        )}
                                    >
                                        <Select
                                            name="record_type"
                                            id="record_type"
                                            value={type}
                                            disabledText={
                                                this.copy.selectSystem ||
                                                "Select Type"
                                            }
                                            handleChange={(value) =>
                                                this.handleChange(
                                                    "type",
                                                    index,
                                                    value,
                                                )
                                            }
                                            clear={true}
                                            noMargin={true}
                                            readOnlyPadding={true}
                                            readOnly={
                                                typeDisabled ? true : disabled
                                            }
                                            disabled={
                                                typeDisabled ? true : disabled
                                            }
                                            noneSelectedText={type ? type : "-"}
                                            data={types}
                                        />
                                    </td>

                                    <td
                                        style={Object.assign(
                                            {},
                                            this.styles.td,
                                            { width: "10rem" },
                                        )}
                                    >
                                        <Select
                                            name="environment"
                                            id="certificate_environment"
                                            value={environment}
                                            disabledText={
                                                this.copy.selectEnvironment ||
                                                "Select Environment"
                                            }
                                            handleChange={(value) =>
                                                this.handleChange(
                                                    "environment",
                                                    index,
                                                    value,
                                                )
                                            }
                                            clear={true}
                                            noMargin={true}
                                            readOnlyPadding={true}
                                            readOnly={
                                                environmentDisabled
                                                    ? true
                                                    : disabled
                                            }
                                            disabled={
                                                environmentDisabled
                                                    ? true
                                                    : disabled
                                            }
                                            noneSelectedText={
                                                environment ? environment : "-"
                                            }
                                            data={environments}
                                        />
                                    </td>

                                    <td
                                        style={Object.assign(
                                            {},
                                            this.styles.td,
                                            { width: "10.90625rem" },
                                            disabled && { background },
                                        )}
                                    >
                                        <Select
                                            name="rating"
                                            id="certificate_rating"
                                            value={rating}
                                            disabledText={
                                                this.copy.selectRating ||
                                                "Select Rating"
                                            }
                                            handleChange={(value) =>
                                                this.handleChange(
                                                    "rating",
                                                    index,
                                                    value,
                                                )
                                            }
                                            clear={true}
                                            noMargin={true}
                                            readOnlyPadding={true}
                                            readOnly={disabled}
                                            disabled={disabled}
                                            noneSelectedText={
                                                rating ? rating : "-"
                                            }
                                            readOnlyColor="white"
                                            data={ratings}
                                            readOnlyFontWeight="500"
                                        />
                                    </td>

                                    {!disabled && (
                                        <td
                                            style={Object.assign(
                                                {},
                                                {
                                                    width: "1rem",
                                                    paddingRight: "0.39375rem",
                                                },
                                            )}
                                        >
                                            <div
                                                style={
                                                    this.styles.buttonContainer
                                                }
                                            >
                                                {this.renderDeleteIcon(
                                                    item,
                                                    index,
                                                    "meta-certifications-delete-icon",
                                                )}
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        }

        return false;
    }
}
