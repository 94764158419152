import React from "react";
import EpisodeLeftText from "../src/EpisodeLeftText";
import RenderSelectAll from "./RenderSelectAll";
import RenderCheckboxTag from "./RenderCheckboxTag";

const RenderEpisodeSelect = (props) => {
    const {
        episodes,
        selectedEpisodes,
        handleSelection = () => {},
        handleSelectAllClick,
        selectedAll,
        category,
        selectAllLabel,
        parent,
        displaySelectAll = true
    } = props;

    return (
        <React.Fragment>
            {displaySelectAll &&
                RenderSelectAll({
                    handleSelectAllClick,
                    selectedAll,
                    category,
                    selectAllLabel,
                    episodes,
                    parent,
                })
            }

            {episodes.map((episode, index) => {
                const selected = selectedEpisodes.find(
                    (item) => item.meta_id == episode.meta_id,
                );
                const leftText = EpisodeLeftText({
                    episode,
                });

                return (
                    <RenderCheckboxTag
                        key={index}
                        selected={selected}
                        leftText={leftText}
                        episode={episode}
                        handleSelection={handleSelection}
                    />
                );
            })}
        </React.Fragment>
    );
};

export default RenderEpisodeSelect;
