import { CreditInterface, CreditScriptedCharacterNameInterface } from "../../../credits.interface";
import { defaultLanguage } from "../../../config";
import { isEmpty } from "../../../utils/helpers";

interface getCharacterNameScriptValueProps {
   credit: CreditInterface
   script: string
}
const getCharacterNameScriptValue = (props: getCharacterNameScriptValueProps) => {
    const {
        credit,
        script
    } = props

    const defaultScript: string = defaultLanguage.value
    let scriptValues: CreditScriptedCharacterNameInterface[] = credit.hasOwnProperty("script_character_names") ? credit['script_character_names'] : []

    let characterName: string|null = null
    const defaultPlaceHolder: string = "Enter Character Name"
    let placeHolder: string = defaultPlaceHolder

    if (script === defaultScript) {
        characterName = credit.character_name as string || null
    }

    if (script !== defaultScript && isEmpty(scriptValues)) {
        placeHolder = credit.character_name as string || defaultPlaceHolder
    }

    if (script !== defaultScript) {
        let targetIndex: number = scriptValues.findIndex(
            (scriptValue: CreditScriptedCharacterNameInterface) => scriptValue.code.toLowerCase() === script.toLowerCase()
        )

        if (targetIndex === -1) {
            placeHolder = credit.character_name as string || defaultPlaceHolder
        } else {
            characterName = scriptValues[targetIndex].character_name
            placeHolder = credit.character_name as string
        }
    }

    return {
        characterName,
        placeHolder
    }
}

export default getCharacterNameScriptValue