import React, { useEffect, useState } from "react";
import { LinearProgress, Snackbar, Alert, Portal } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import "./styles/_styles.scss";

const Linear = () => {
    const [progress, setProgress] = useState<number>(0);
    const [status, setStatus] = useState<string>("primary");

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) return setStatus("error");

                // initial loading speed
                let result = Math.min(oldProgress + 5, 100);

                // When the progress reached half, slows down the speed
                if (oldProgress > 50 && oldProgress < 100) {
                    result = Math.min(oldProgress + 0.5, 100);
                }

                return result;
            });
        }, 200);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <>
            <LinearProgress
                variant="determinate"
                value={progress}
                color={status}
                className="fabric--linear-progress"
            />

            {status === "error" && (
                <Portal disablePortal={true}>
                    <Snackbar
                        open={true}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                    >
                        <Alert variant="filled" icon={<ErrorIcon />}>
                            Sorry, loading is taking longer than usual
                        </Alert>
                    </Snackbar>
                </Portal>
            )}
        </>
    );
};

export default Linear;
