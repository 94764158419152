// TODO: move these constants to theme
export const COLOR_CELL_CONTENT = "#959595";
export const COLOR_CELL_CONTENT_BOLD = "#706f6f";
export const COLOR_BLUE = "#286CD2";
export const COLOR_COLUMN_HEADER = "#555555";
export const COLOR_ROW_BORDER = "#F5F5F5";
export const COLOR_TABLE_BORDER = "#E6E6E6";

export const COLOR_COLUMN_HEADER_INACTIVE = "#E7E7E7";
export const COLOR_COLUMN_HEADER_ACTIVE = "#EBF0F9";

export const LOADING_ROW_COUNT = 15;
