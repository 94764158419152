import React from "react";
import Settings from "../../Settings";
import DoubleTransparentTag from "../../DoubleTransparentTag";

const RenderDoubleAssetDetailTag = (props) => {
    const { textLeft, textRight, margin, className, media_id } = props;

    return (
        <DoubleTransparentTag
            textLeft={textLeft}
            textRight={textRight || media_id}
            fontSize={Settings.components.actionButton.text.fontSize}
            leftBorderColor="#eaeaea"
            leftBackgroundColor="transparent"
            leftColor="#eaeaea"
            rightBackgroundColor="#eaeaea"
            rightColor="#797979"
            fontWeight={500}
            padding="0.1rem 0.3125rem"
            margin={margin}
            className={className}
        />
    );
};

export default RenderDoubleAssetDetailTag;
