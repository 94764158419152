import React from "react";
import { CoreSelectOptionType } from "../../../../../../core/Form/components/inputs/Select/types/CoreSelectOptionType";
import { Categories } from "../types/types";

const episodeSVG = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2 4H0V18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H16V18H2V4ZM18 0H6C5.46957 0 4.96086 0.210714 4.58579 0.585786C4.21071 0.960859 4 1.46957 4 2V14C4 14.5304 4.21071 15.0391 4.58579 15.4142C4.96086 15.7893 5.46957 16 6 16H18C18.5304 16 19.0391 15.7893 19.4142 15.4142C19.7893 15.0391 20 14.5304 20 14V2C20 1.46957 19.7893 0.960859 19.4142 0.585786C19.0391 0.210714 18.5304 0 18 0ZM10 12.5V3.5L16 8L10 12.5Z"
            fill="#4D4D4D"
            fillOpacity="0.7"
        />
    </svg>
);
export const tvSeriesCategoryOptions: CoreSelectOptionType[] = [
    {
        label: Categories.Show,
        value: Categories.Show,
        topAdornment: (
            <svg
                width="20"
                height="18"
                viewBox="0 0 20 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6.16 0L4.75 1.41L7.34 4H2C0.89 4 0 4.89 0 6V16C0 17.11 0.89 18 2 18H18C19.11 18 20 17.11 20 16V6C20 4.89 19.11 4 18 4H12.66L15.25 1.41L13.84 0L10 3.84L6.16 0ZM2 6H15V16H2V6ZM17.5 6C17.7652 6 18.0196 6.10536 18.2071 6.29289C18.3946 6.48043 18.5 6.73478 18.5 7C18.5 7.26522 18.3946 7.51957 18.2071 7.70711C18.0196 7.89464 17.7652 8 17.5 8C17.2348 8 16.9804 7.89464 16.7929 7.70711C16.6054 7.51957 16.5 7.26522 16.5 7C16.5 6.73478 16.6054 6.48043 16.7929 6.29289C16.9804 6.10536 17.2348 6 17.5 6ZM17.5 9C17.7652 9 18.0196 9.10536 18.2071 9.29289C18.3946 9.48043 18.5 9.73478 18.5 10C18.5 10.2652 18.3946 10.5196 18.2071 10.7071C18.0196 10.8946 17.7652 11 17.5 11C17.2348 11 16.9804 10.8946 16.7929 10.7071C16.6054 10.5196 16.5 10.2652 16.5 10C16.5 9.73478 16.6054 9.48043 16.7929 9.29289C16.9804 9.10536 17.2348 9 17.5 9Z"
                    fill="#4D4D4D"
                    fillOpacity="0.7"
                />
            </svg>
        ),
    },
    {
        label: Categories.Season,
        value: Categories.Season,
        topAdornment: (
            <svg
                width="18"
                height="20"
                viewBox="0 0 18 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M16 18H2V7H16M13 0V2H5V0H3V2H2C0.9 2 0 2.9 0 4V18C0 19.11 0.9 20 2 20H16C17.11 20 18 19.11 18 18V4C18 2.9 17.11 2 16 2H15V0M7.88 11H4.27L7.19 13.11L6.08 16.56L9 14.43L11.92 16.56L10.8 13.12L13.72 11H10.12L9 7.56L7.88 11Z"
                    fill="#4D4D4D"
                    fillOpacity="0.7"
                />
            </svg>
        ),
    },
    {
        label: Categories.Episode,
        value: Categories.Episode,
        topAdornment: episodeSVG,
    },
    {
        label: Categories.Storyline,
        value: Categories.Storyline,
        topAdornment: (
            <svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M16.5 10.33C17.29 10.33 18.13 10.41 19 10.57V12.07C18.38 11.91 17.54 11.83 16.5 11.83C14.6 11.83 13.11 12.16 12 12.82V11.13C13.17 10.6 14.67 10.33 16.5 10.33ZM12 8.46C13.29 7.93 14.79 7.67 16.5 7.67C17.29 7.67 18.13 7.74 19 7.9V9.4C18.38 9.24 17.54 9.16 16.5 9.16C14.6 9.16 13.11 9.5 12 10.15M16.5 6.5C14.6 6.5 13.11 6.82 12 7.5V5.84C13.23 5.28 14.73 5 16.5 5C17.29 5 18.13 5.08 19 5.23V6.78C18.26 6.59 17.41 6.5 16.5 6.5ZM20 14.5V3C18.96 2.67 17.79 2.5 16.5 2.5C14.45 2.5 12.62 3 11 4V15.5C12.62 14.5 14.45 14 16.5 14C17.69 14 18.86 14.16 20 14.5ZM16.5 0.5C18.85 0.5 20.69 1 22 2V16.56C22 16.68 21.95 16.8 21.84 16.91C21.73 17 21.61 17.08 21.5 17.08C21.39 17.08 21.31 17.06 21.25 17.03C19.97 16.34 18.38 16 16.5 16C14.45 16 12.62 16.5 11 17.5C9.66 16.5 7.83 16 5.5 16C3.84 16 2.25 16.36 0.75 17.07C0.72 17.08 0.68 17.08 0.63 17.1C0.59 17.11 0.55 17.12 0.5 17.12C0.39 17.12 0.27 17.08 0.16 17C0.05 16.89 0 16.78 0 16.65V2C1.34 1 3.18 0.5 5.5 0.5C7.83 0.5 9.66 1 11 2C12.34 1 14.17 0.5 16.5 0.5Z"
                    fill="#4D4D4D"
                    fillOpacity="0.7"
                />
            </svg>
        ),
    },
    {
        label: Categories.ModularEpisode,
        value: Categories.ModularEpisode,
        topAdornment: episodeSVG,
    },
];
