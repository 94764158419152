import colors from "./colors"

const common = {
    width: "100%",
    padding: "0.75rem 0.8125rem",
};

const table = {
    header: {
        background: colors.grey.light,
        ...common,
    },
    body: {
        background: "#ececec",
        ...common,
    },
};

export default table;
