import { isEmpty } from "lodash";
import { observer } from "mobx-react";
import { PropsWithChildren } from "react";
import { useFormContext } from "react-hook-form";
import { AllFieldsVT } from "../../../../config/types";
import store from "../../tv-series/store/store";
import { Categories } from "../../tv-series/types/types";

export const HideFormConditionally = observer(
    ({ children }: PropsWithChildren) => {
        const form = useFormContext<AllFieldsVT>();
        const values = form.getValues();
        const { category } = values;

        return children; // TODO: remove this line. It's just for development

        if (store.editMode) {
            return children;
        }

        if (
            category !== Categories.Show &&
            isEmpty(values?.parentShow?.metaId)
        ) {
            return null;
        }

        if (
            (category === Categories.Episode ||
                category === Categories.Storyline) &&
            isEmpty(form.watch("parentSeason.title"))
        ) {
            return null;
        }

        return children;
    },
);
