const HandleNewRow = (props) => {
    const {
        items = [],
        setItems = () => {},
        setEditRowIndex = () => {},
    } = props;

    const editRowIndex = Array.isArray(items) ? items.length : 0;
    const scriptedValues = {
        value: "",
        scripts: [],
    };

    const newRow = {
        award_body: { ...scriptedValues },
        result: { ...scriptedValues },
        year: null,
        category: { ...scriptedValues },
        contributor: null,
        order: Array.isArray(items) ? items.length + 1 : 1,
    };

    const updatedState = [...items, { ...newRow }];

    setItems(updatedState);
    setEditRowIndex(editRowIndex);
};

export default HandleNewRow;
