import React, { createContext, useReducer } from "react";
import { userRoleManagementReducer } from "./reducer";
import { RoleInterface, CatalogInterface } from "../interfaces";
import { NOTIFICATION_INTERFACE } from "../../../components/Notification/interface";

export type InitialStateType = {
    createNew: RoleInterface | {};
    edit: RoleInterface | {};
    createNewInitial: RoleInterface | {};
    editInitial: RoleInterface | {};
    dialogIsShown: boolean;
    basicNotification?: NOTIFICATION_INTERFACE | {};
    working: boolean | string;
    accessCatalogs: CatalogInterface[];
    deletingId: string;
    fetchingRoles: boolean;
    roles: Array<RoleInterface>;
    notificationOptions: any;
    validationMessage: string;
    searchTerm: string;
};

export const initialState = {
    createNew: {},
    edit: {},
    createNewInitial: {},
    editInitial: {},
    dialogIsShown: false,
    basicNotification: {},
    working: false,
    accessCatalogs: [],
    deletingId: "",
    fetchingRoles: true,
    roles: [],
    notificationOptions: {},
    policies: [],
    fetchingPolicies: true,
    validationMessage: "",
    searchTerm: "",
};

const AppContext = createContext<{
    state: InitialStateType;
    dispatch: React.Dispatch<any>;
}>({
    state: initialState,
    dispatch: () => null,
});

const AppProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(
        userRoleManagementReducer,
        initialState,
    );

    return (
        <AppContext.Provider value={{ state, dispatch }}>
            {children}
        </AppContext.Provider>
    );
};

export { AppContext, AppProvider };
