import React from "react";
import "./styles/_styles.scss";
import RenderCategoryPosition from "./src/RenderCategoryPosition";
import RenderTitle from "./src/RenderTitle";
import RenderCategoryTag from "./src/RenderCategoryTag";
import RenderDoubleTagAmount from "../Cycles/src/RenderDoubleTagAmount";
import RenderDoubleTagPercentage from "../Cycles/src/RenderDoubleTagPercentage";

const CollectionRow = (props) => {
    const { item = [], index, even } = props;

    const {
        total_sell_rate,
        total_buy_rate,
        total_margin,
        margin,
        category = "",
    } = item;

    const titleStyles = {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "18rem",
    };

    const tagStyles = {
        marginLeft: "0.75rem",
    };

    const GetCategoryKey = (category) => {
        let result = category;
        if (category.includes(" ")) {
            result = result.replace(" ", "");
        }

        return result;
    };

    return (
        <div
            className={`meta-numbered-record-table--row ${even ? "even" : ""}`}
            key={index}
        >
            {RenderCategoryPosition({
                index,
                item,
            })}

            <div className="meta-numbered-record-table--content">
                {RenderTitle(item, titleStyles)}
            </div>

            <div className="meta-numbered-record-table--content-right">
                {RenderCategoryTag(GetCategoryKey(category))}

                <RenderDoubleTagAmount
                    text="Total sell rate"
                    rightText={total_sell_rate}
                    styles={tagStyles}
                />

                <RenderDoubleTagAmount
                    text="Total buy rate"
                    rightText={total_buy_rate}
                    styles={tagStyles}
                />

                <RenderDoubleTagPercentage
                    text="Overall margin"
                    rightText={margin}
                    styles={tagStyles}
                />
            </div>
        </div>
    );
};

export default CollectionRow;
