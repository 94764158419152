import _ from "lodash";
import { diff } from "deep-object-diff";

const filteredArrayKeys = [
    "contributors", // mobx UI
    "credit_lists", // mobx UI
    "rights", // deletes
    "trivia", //deletes
    "certifications", 
    "links", 
    "release_dates"
]

// rights - add id from payload
// release_dates - add deleted tag

export default function DeepObjectDifference(object, base, cmsData = {}, deepArrayChange = false) {
    const deepArrayChangeEnabled = deepArrayChange || cmsData?.clientFeatures?.deepArrayChange
    let result = {};

    if (typeof object == "object" && typeof base == "object") {
        const difference = diff(object, base);

        _.forIn(difference, function (value, key) {
            const difference = diff(object[key], base[key]);

            if (base[key] == undefined) {
                result[key] = object[key];
            }

            if (!_.isEmpty(difference)) {
                result[key] = object[key];
            } else if (Number.isInteger(difference)) {
                result[key] = object[key];
            }

            // Only return partial array items
            if (deepArrayChangeEnabled && 
                Array.isArray(object[key]) && 
                !filteredArrayKeys.includes(key)) {

                let arrayDifference = []
                let objectArray = object[key]
                let baseArray = base[key]

                arrayDifference = objectArray?.filter(object1 => {
                    const object1Json = JSON.stringify(object1)

                    return !baseArray?.some(object2 => {
                        const object2Json = JSON.stringify(object2)
                        return object1Json === object2Json;
                    });
                });

                if (!_.isEmpty(arrayDifference)) {
                    result[key] = arrayDifference
                }
            }
        });
    }

    return result;
}
