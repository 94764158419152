/* eslint-disable camelcase */
import deepcopy from "deepcopy";
import _ from "lodash";
import DisabledRuleActive from "./rules/disabled";
import CategoryTags from "../CategoryTags";

export default function ValidateProductionNumber(
    result = {},
    optionsData = {},
) {
    const response = deepcopy(result);

    const {
        data = {},
        status,
        clientFeatures = {},
        clientDataStructure = {},
    } = optionsData;

    const {
        episode_numbering = {},
        category = false,
        status: recordStatus = "",
        category_name = "",
    } = data;

    const recordCategory = category_name || category;

    const { type } = episode_numbering;

    const { productionNumberValidation = true } = clientFeatures;

    let validationPath = "season_numbering.production_number";
    let record_numbering = data.season_numbering || {};

    if (recordCategory === CategoryTags.Episode.short) {
        record_numbering = data.episode_numbering || {};
        validationPath = "episode_numbering.production_number";
    }

    if (recordCategory === CategoryTags.Storyline.short) {
        record_numbering = data.storyline_numbering || {};
        validationPath = "storyline_numbering.production_number";
    }

    const dataStructureProductionNumberValidation = _.get(
        clientDataStructure,
        `fieldValidation.${validationPath}`,
        null,
    );

    const productionNumberLength = Number.isInteger(
        dataStructureProductionNumberValidation?.length,
    )
        ? dataStructureProductionNumberValidation.length
        : 4;

    const { production_number } = record_numbering;

    if (!productionNumberValidation) {
        return response;
    }

    if (
        DisabledRuleActive({
            recordStatus,
            category,
            type,
            dataStructure: clientDataStructure,
            fieldPath: validationPath,
        })
    ) {
        return response;
    }

    if (dataStructureProductionNumberValidation && !production_number) {
        if (dataStructureProductionNumberValidation.recordStatus?.includes(recordStatus)) {
            response.notification = {
                title: "Please check production number",
                description: "Production number should not be empty",
                status,
            };

            response.validationKeys = result.validationKeys.concat([
                "production_number",
            ]);

            response.result = false;
        }
    }

    if (
        Number.isInteger(production_number) ||
        typeof production_number === "string"
    ) {
        if (
            production_number &&
            production_number.length > productionNumberLength
        ) {
            response.notification = {
                title: "Please check production number",
                description: `Production number should be up to ${productionNumberLength} characters.`,
                status,
            };

            response.validationKeys = result.validationKeys.concat([
                "production_number",
            ]);
            response.result = false;
        }
    }

    return response;
}
