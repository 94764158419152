import Prefixer from "inline-style-prefixer";

export default function styles(props = {}) {
    let navWidth = "4.375rem";

    let styles = {
        navContainer: {
            position: "fixed",
            top: 0,
            left: 0,
            width: navWidth,
            background: "#292929",
            minHeight: "100vh",
            display: "inline-block",
            verticalAlign: "top",
            zIndex: 1,
            textAlign: "center",
        },
        safari: {
            overflow: "visible",
        },
        nav: {
            width: "100%",
            height: "100%",
            position: "absolute",
            textAlign: "center",
        },
        content: {
            display: "inline-block",
            verticalAlign: "top",
            marginLeft: navWidth,
            width: `calc(100% - ${navWidth})`,
            minHeight: "100vh",
            background: "#292929",
        },
        navContent: {
            position: "relative",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            WebkitFlexDirection: "column",
            textAlign: "center",
        },
        iconContainer: {
            width: "100%",
            margin: props.iconContainerMargin || "0 0 1.21875rem 0",
            flexShrink: 0,
            WebkitSlexShrink: 0,
            textAlign: "center",
            position: "relative",
            height: "2.125rem",
        },
        icon: {
            width: "1.875rem",
            maxWidth: "100%",
            cursor: "pointer",
        },
        iconPlaceholder: {
            width: "2rem",
            height: "2rem",
            background: "#383838",
            borderRadius: "50%",
            margin: "auto",
        },
        iconLarge: {
            width: "2.625rem",
        },
        logo: {
            width: "2.625rem",
            maxWidth: "100%",
            margin: "1rem 0",
        },
        environment: {
            marginTop: "auto",
            flexShrink: 0,
            WebkitSlexShrink: 0,
        },
        environmentItem: {
            display: "block",
            fontSize: "1.175rem",
            color: "rgba(255, 255, 255, 0.2)",
            cursor: "pointer",
        },
        notification: {
            width: "0.8125rem",
            height: "0.8125rem",
            background: "#ff0000",
            position: "absolute",
            zIndex: 2,
            top: "-0.1rem",
            right: "1rem",
            borderRadius: "50%",
            cursor: "pointer",
        },
    };

    let prefixer = new Prefixer();
    styles = prefixer.prefix(styles);

    return styles;
}
