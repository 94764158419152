import CategoryTags from "../../../components/CategoryTags";

const KeywordLabelProps = (props) => {
    const { category, seasons } = props;

    let result = {};

    if (category && CategoryTags[category]) {
        result = {
            background: CategoryTags[category].color,
            color: "white",
        };

        if (seasons && seasons.length > 0) {
            let seasonText = "Season";

            if (seasons.length > 1) {
                seasonText = "Seasons";
            }

            result.postfix = `${seasons.length} ${seasonText}`;
        }
    }

    return result;
};

export default KeywordLabelProps;
