import React from "react";
import Button from "../Button";
import Settings from "../Settings";

const ActionButton = (props) => {
    let {
        text,
        disabledColor = "#D0D0D0",
        onClick,
        disabled,
        fontSize = "0.78125rem",
        padding = "0.3125rem",
        color = "white",
        fontWeight = 500,
        letterSpacing = "-0.01875rem",
        id,
    } = props;

    if (disabled) {
        onClick = false;
    }

    return (
        <Button
            {...props}
            id={id}
            value={text}
            type="button"
            padding={padding}
            disabledColor={disabledColor}
            color={color}
            fontWeight={fontWeight}
            letterSpacing={letterSpacing}
            fontSize={fontSize}
            onClick={onClick}
            loadingSrc={Settings.images.loadingWhite}
        />
    );
};

export default ActionButton;
