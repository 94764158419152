import React from "react";
import { SectionInterface } from "./interface";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";
import Ancillaries from "../../../../features/Ancillaries"

const SectionAncillaries = (props: SectionInterface) => {
    const { data = {} } = props;
    const { ancillaries = [] } = data;

    return (
        <Ancillaries
            {...getSectionCommonProps({ ...props, field: "ancillaries" })}
            data={ancillaries}
        />
    );
};

export default SectionAncillaries;
