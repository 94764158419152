import {
    StandaloneTicket,
    StandaloneTicketForUpdate,
} from "../../../ticket/shared/validationSchema";

export const adaptToBackendCreate = (
    ticket: StandaloneTicket,
    options?: { sourceTicketId?: StandaloneTicket["id"] },
): StandaloneTicketForUpdate => {
    const {
        author: { id: authorId },
        assignee: { id: assigneeId },
        name,
        priority: { label: priorityLabel },
        records,
        status: { label: statusLabel },
        description,
    } = ticket;

    const payload = {
        author: authorId,
        assignee: assigneeId,
        name,
        priority: priorityLabel,
        records,
        status: statusLabel,
        type: ticket.type.label,
        description,
    };

    if (options?.sourceTicketId) {
        return {
            ...payload,
            sourceTicketId: options.sourceTicketId,
        };
    }

    return payload;
};
