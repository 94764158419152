import React from "react";
import { SectionInterface } from "./interface";
import Images from "../../../../features/Images";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";

const SectionImages = (props: SectionInterface) => {
    const {
        cmsData = {},
        data = {},
        attributes = {},
        state = {},
        disabled
    } = props;

    const {
        clientDataStructure = {},
        clientFeatures = {},
        apiUrls = {},
    } = cmsData;

    return (
        <Images
            {...getSectionCommonProps({ ...props, field: "images" })}
            name="images"
            className="meta-record-images"
            displayTitle={false}
            data={data.images || {}}
            lastSection={true}
            recordImageUrl={attributes.recordImageUrl}
            meta_id={data.meta_id}
            meta_id_temp={data.meta_id_temp}
            dataStructure={clientDataStructure.images}
            clientFeatures={clientFeatures}
            apiUrls={apiUrls}
            userPermissions={state.userPermissions}
            disabled={disabled}
        />
    );
};

export default SectionImages;
