import React, { useState } from "react";
import _ from "lodash";
import "./styles/_styles.scss";
import AddNewRowButton from "../AddNewRowButton";
import FadeIn from "../TransitionGroup/FadeIn";
import RenderItem from "./src/RenderItem";
import GetDefaultNewRowData from "./src/GetDefaultNewRowData";
import Notification from "../Notification";
import GetLanguagesFromRegions from "../Languages/GetLanguagesFromRegions";
import update from "react-addons-update";
import deepcopy from "deepcopy";

const RightsComponent = (
    {
        options = {},
        disabled,
        date,
        styles = {},
        cmsData = {},
        dataStructure,
        righsV3DataStructure = {},
        handleChange = () => {},
        data: rightsData = [],
        canCreateNew = true,
    },
    props,
) => {
    const { clientFeatures = {}, clientData = {} } = cmsData;

    const { clientRightsSystemName = "IBMS" } = clientData;

    const {
        rightsSubsDubs,
        oemSystems,
        licensorText,
        rights = {},
    } = clientFeatures;

    const { multipleVersions } = rights;

    const { all_written_languages } = options;

    const [newRow, setNewRow] = useState({});
    const [notification, setNotification] = useState({});
    const [editRowIndex, setEditRowIndex] = useState(null);
    const [data, setData] = useState(rightsData);

    const handleNewRowChange = (type, value) => {
        let updatedState = deepcopy(newRow);
        updatedState[type] = value;

        setNewRow(updatedState);
    };

    const handleRowChange = (type, value) => {
        const updatedState = update(data, {
            [editRowIndex]: {
                [type]: {
                    $set: value,
                },
            },
        });

        setData(updatedState);
    };

    const handleNewRowSave = () => {
        let updatedState = deepcopy(data);
        updatedState.push(newRow);

        setData(updatedState);
        setNewRow({});
        setEditRowIndex(null);

        // callback
        handleChange(updatedState);
    };

    const handleDelete = () => {
        if (Number.isInteger(editRowIndex)) {
            const updatedState = update(data, { $splice: [[editRowIndex, 1]] });

            setNotification({
                title: "Delete",
                description: "Are you sure you want to delete this right?",
                confirm: () => {
                    // callback
                    handleChange(updatedState);
                    setData(updatedState);
                    setNotification({});
                    setEditRowIndex(null);
                },
            });
        }
    };

    const handleRowUpdate = () => {
        if (Number.isInteger(editRowIndex)) {
            setEditRowIndex(null);

            // callback
            handleChange(data);
        }
    };

    const isRowValid = (data) => {
        let result = true;

        let validationKeys = [
            "licensor",
            "platforms",
            "countries",
            "business_models",
            "license_start",
            "license_end",
        ];
        if (dataStructure) {
            let dataStructureFieldsKeys = dataStructure.map(function (item) {
                if (item.hasOwnProperty("key")) {
                    return item.key;
                }
            });

            validationKeys = validationKeys.filter((item) =>
                dataStructureFieldsKeys.includes(item),
            );
        }

        if (oemSystems) {
            validationKeys = validationKeys.filter(
                (item) => item !== "platforms",
            );
        }

        // find required keys if other keys are not empty
        if (Array.isArray(dataStructure)) {
            dataStructure
                .filter((item) => item.required_if_not_empty)
                .forEach((item) => {
                    if (data[item.required_if_not_empty]) {
                        validationKeys.push(item.key);
                    }
                });
        }

        validationKeys.forEach((item) => {
            if (_.isEmpty(data[item])) {
                result = false;
            }
        });

        return result;
    };

    const renderRightsTabs = () => {
        return (
            <div className="rights-v3-tabs">
                <span className="rights-v3-tab-label">
                    RIGHTS MANAGEMENT SYSTEM
                </span>
                {clientRightsSystemName && (
                    <span className="rights-v3-tab-system-name">
                        {clientRightsSystemName}
                    </span>
                )}
                <span className="rights-v3-env">{process.env.APP_ENV}</span>
            </div>
        );
    };

    const written_languages = GetLanguagesFromRegions({
        languages: all_written_languages,
    });

    const defaultProps = {
        oemSystems,
        rightsSubsDubs,
        options,
        licensorText,
        multipleVersions,
        dataStructure,
        righsV3DataStructure,
        clientFeatures,
        written_languages,
        cmsData,
    };

    const { rightsV3 = false } = clientFeatures;

    return (
        <FadeIn>
            {rightsV3 && renderRightsTabs()}
            <div
                className={`meta-rights-${rightsV3 ? "v3" : "v2"}`}
                style={styles}
            >
                {data.map((item, index) => {
                    let edit = editRowIndex === index;
                    if (!_.isEmpty(newRow)) {
                        edit = false;
                    }

                    return (
                        <RenderItem
                            {...defaultProps}
                            data={item}
                            key={index}
                            disabled={disabled}
                            edit={edit}
                            editDisabled={Number.isInteger(editRowIndex)}
                            isValidated={isRowValid(item)}
                            handleEdit={() => setEditRowIndex(index)}
                            handleStateSave={() => handleRowUpdate()}
                            handleDelete={() => handleDelete()}
                            handleChange={(key, value) =>
                                handleRowChange(key, value)
                            }
                        />
                    );
                })}

                {!_.isEmpty(newRow) && !disabled && (
                    <RenderItem
                        {...defaultProps}
                        data={newRow}
                        edit={true}
                        isValidated={isRowValid(newRow)}
                        handleStateSave={() => handleNewRowSave()}
                        handleDelete={() => setNewRow({})}
                        handleChange={(key, value) =>
                            handleNewRowChange(key, value)
                        }
                    />
                )}

                {!disabled && (
                    <AddNewRowButton
                        onClick={() => {
                            if (_.isEmpty(newRow))
                                setNewRow(GetDefaultNewRowData({ date }));
                        }}
                        disabled={
                            !canCreateNew ||
                            disabled ||
                            Number.isInteger(editRowIndex) ||
                            !_.isEmpty(newRow)
                        }
                        data={data}
                    />
                )}
            </div>

            <Notification
                title={notification.title}
                description={notification.description}
                intercationStatus="alert"
                confirm={notification.confirm}
                onClick={() => setNotification({})}
                okText="Ok"
            />
        </FadeIn>
    );
};

export default RightsComponent;
