import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button } from "@mui/material";
import React from "react";
import { CoreMenuButtonContent } from "./CoreMenuButtonContent";

export type CoreMenuButtonProps = {
    MENU_BUTTON_ID: string;
    open: boolean;
    MENU_ID: string;
    name: string;
    handleClick: (event: React.MouseEvent<HTMLElement>) => void;
    selected: string[];
    disabled?: boolean;
    customStyles?: any;
    error?: boolean;
};

export function CoreMenuButton({
    MENU_BUTTON_ID,
    open,
    MENU_ID,
    name,
    handleClick,
    selected,
    disabled = false,
    error = false,
    customStyles = {}
}: CoreMenuButtonProps) {
    return (
        <Button
            type="button"
            disableElevation
            disableRipple
            disabled={disabled}
            error={error}
            endIcon={
                <KeyboardArrowDownIcon
                    sx={{
                        color: "rgba(77, 77, 77, 0.7)",
                    }}
                />
            }
            variant="outlined"
            size="small"
            id={MENU_BUTTON_ID}
            aria-controls={open ? MENU_ID : undefined}
            name={name}
            aria-haspopup="true"
            role="button"
            data-testid={`core-menu-button-${name}`}
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{
                backgroundColor:
                    selected?.length >= 1 ? "rgba(40, 108, 210, 0.2)" : "#FFF",

                color: "rgba(47, 47, 47, 0.87)",
                border: error ? "0.0625rem solid #eaa4af" : "0.0625rem solid rgba(77, 77, 77, 0.1)",
                textTransform: "none",
                padding: "0.375rem 0.625rem",
                display: 'flex',
                justifyContent: 'flex-start',
                "&:hover": {
                    backgroundColor:
                        selected?.length >= 1
                            ? "rgba(40, 108, 210, 0.2)"
                            : "#FFF",
                    border: "0.0625rem solid rgba(40, 108, 210, 0.75)",
                },
                "& .MuiTypography-root": {
                    lineHeight: "1.5",
                },
                "& .MuiChip-root": {
                    margin: 0,
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                    color: "rgba(0, 0, 0, 1)",
                    padding: "2px 0.25rem",
                    "& .MuiChip-label": {
                        fontSize: "12px",
                    },
                },
                "& .MuiMenu-list": {
                    paddingTop: 0,
                },
                ...customStyles,
            }}
        >
            <CoreMenuButtonContent selected={selected} name={name} />
        </Button>
    );
}
