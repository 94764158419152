import React from "react";
import CategoryTags from "../../CategoryTags";
import _ from "lodash";

const RenderNoEpisodesText = (props) => {
    const { category, parent } = props;

    let text = "No episodes for selected " + parent;

    switch (category) {
        case CategoryTags.Storyline.short:
            text = "No storylines for selected " + parent;
            break;

        case CategoryTags.Special.short:
            text = "No specials for selected " + parent;
            break;
    }

    return (
        <div className="meta-collection-titles--no-episodes-found">{text}</div>
    );
};

export default RenderNoEpisodesText;
