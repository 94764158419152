import React from "react";
import _ from "lodash";
import Settings from "../../Settings";
import ActionButton from "../../CustomSelect/ActionButton";
import TagSimple from "../../Tag/simple";
import ExpandableRow from "../../ExpandableRow";
import RenderIdDoubleTag from "./RenderIdDoubleTag";
import RenderComplianceBody from "./RenderComplianceBody";
import AssetTrafficLightText from "../../AssetTrafficLight/text";
import RenderCertificateRatingIso from "./RenderCertificateRatingIso";
import SingleDimensionAsset from "./SingleDimensionAsset";
import QuickView from "./QuickView";

export default class ExpandableRowBody extends React.Component {
    constructor(props) {
        super(props);
    }

    renderMasterHeader(props) {
        const { title, locale, actionOptions = [] } = props;

        const { disabled, options = {} } = this.props;

        const { spoken_languages = [] } = options;

        const language =
            spoken_languages.find((item) => item.value == locale) || {};
        let languageText = locale;

        if (language && language.text && language.value) {
            languageText = `${language.text} • ${language.value}`;
        }

        return (
            <div className="master-header meta-asset-header">
                <img
                    className="header-icon"
                    src={`${Settings.images.path}/svg/assets-master-grey.svg`}
                />

                <span className="title">{title}</span>

                <div className="right-content">
                    <TagSimple
                        background="#6785f4"
                        color="white"
                        text={languageText}
                    />

                    <ActionButton items={actionOptions} disabled={disabled} />
                </div>
            </div>
        );
    }

    canDeleteItem(status) {
        const { placeholderCreatedStatus, pendingStatus, canDeleteAllAssets } =
            this.props;

        let canDelete =
            status == placeholderCreatedStatus || status == pendingStatus;
        if (canDeleteAllAssets) canDelete = true;

        return canDelete;
    }

    renderComplianceHeader(props) {
        const { compliance = {}, title, master } = props;

        const {
            media_id,
            disabled,
            handleResendWorkflowNotifications,
            handleEditWorkflowNotifications,
            handleEditSPHX,
            handleEditCertifications,
            handleEditIds,
            handleDeletePlaceholder,
            dataStructure = {},
            clientFeatures = {},
        } = this.props;

        const {
            asset_type,
            compliance_code,
            code,
            status,
            meta_users = [],
            email_addresses = [],
            certifications = [],
            tmc_media_id,
        } = compliance;

        const { codeKey } = dataStructure;

        const { name: media_id_name } = dataStructure.media_id || {};

        let displayCode = compliance_code || code;
        if (!displayCode && codeKey && compliance[codeKey]) {
            displayCode = compliance[codeKey];
        }

        let system_media_id = media_id || "-";
        if (media_id && displayCode)
            system_media_id = media_id + "_" + displayCode;

        return (
            <div className="meta-asset-header">
                <img
                    className="header-icon"
                    src={`${Settings.images.path}/svg/square-play-grey.svg`}
                />

                <span className="title">{title || asset_type}</span>

                <div className="right-content">
                    <div className="certifications">
                        {certifications
                            .filter((item) => !item.deleted)
                            .map((certificate, index) => {
                                return (
                                    <RenderCertificateRatingIso
                                        certificate={certificate}
                                        clientFeatures={clientFeatures}
                                        key={index}
                                    />
                                );
                            })}
                    </div>

                    <AssetTrafficLightText status={status} text={displayCode} />

                    <RenderIdDoubleTag
                        textLeft={media_id_name}
                        textRight={tmc_media_id || system_media_id}
                    />

                    <ActionButton
                        disabled={disabled}
                        items={[
                            {
                                text: "Resend Workflow Notifications",
                                onClick: () =>
                                    handleResendWorkflowNotifications(
                                        compliance,
                                    ),
                                disabled:
                                    _.isEmpty(meta_users) &&
                                    _.isEmpty(email_addresses),
                            },
                            {
                                text: "Edit Workflow Notifications",
                                onClick: () =>
                                    handleEditWorkflowNotifications({
                                        compliance,
                                        master,
                                    }),
                            },
                            {
                                text: `Edit ${displayCode} Asset Information`,
                                onClick: () => handleEditSPHX(),
                                disabled: true,
                            },
                            {
                                text: _.get(
                                    clientFeatures,
                                    "assets.territory",
                                    false,
                                )
                                    ? "Edit IDs & Territories"
                                    : "Edit IDs",
                                onClick: () =>
                                    handleEditIds({
                                        compliance,
                                        master,
                                    }),
                            },
                            {
                                text: "Edit Certifications & Advisory",
                                onClick: () =>
                                    handleEditCertifications({
                                        compliance,
                                        master,
                                    }),
                            },
                            {
                                text: `Delete Compliance`,
                                onClick: () =>
                                    handleDeletePlaceholder({
                                        compliance,
                                        master,
                                    }),
                                disabled: !this.canDeleteItem(
                                    compliance.status,
                                ),
                            },
                        ]}
                    />
                </div>
            </div>
        );
    }

    renderMasterWithCompliances(props) {
        const { master = {}, locale } = props;

        const { compliances = [], status } = master;

        const {
            handleEditMaster,
            alternativeMasterAssetType,
            handleDeletePlaceholder,
            options = {},
            send_to_ai,
            clientFeatures,
            dataStructure = {},
            dispatchNotification = () => {},
        } = this.props;

        const canDeleteMaster = this.canDeleteItem(status);

        let actionOptions = [
            {
                text: `Create New Compliance Edit Placeholder in ${
                    master.asset_type || "Master"
                }`,
                onClick: () => handleEditMaster(master),
            },
            {
                text: `Delete Master`,
                onClick: () =>
                    handleDeletePlaceholder({
                        master,
                    }),
                disabled: !canDeleteMaster,
            },
        ];

        if (master.asset_type == alternativeMasterAssetType) {
            actionOptions = [
                {
                    text: `Delete Master`,
                    onClick: () =>
                        handleDeletePlaceholder({
                            master,
                        }),
                    disabled: !canDeleteMaster,
                },
            ];
        }

        return (
            <React.Fragment>
                {this.renderMasterHeader({
                    title: master.asset_type,
                    locale,
                    actionOptions,
                })}

                <ExpandableRow
                    header={this.renderComplianceHeader({
                        compliance: master,
                        master,
                        title: "Original",
                    })}
                    body={
                        <RenderComplianceBody
                            compliance={master}
                            options={options}
                            send_to_ai={send_to_ai}
                            clientFeatures={clientFeatures}
                            dataStructure={dataStructure}
                            dispatchNotification={dispatchNotification}
                        />
                    }
                    bodyStyles={{ padding: 0 }}
                    className="meta-expandable-rows--compliance"
                    arrowIconColorType="medium"
                />

                {compliances.map((compliance, index) => {
                    if (!compliance.deleted) {
                        return (
                            <ExpandableRow
                                header={this.renderComplianceHeader({
                                    compliance,
                                    master,
                                })}
                                className="meta-expandable-rows--compliance"
                                body={
                                    <RenderComplianceBody
                                        compliance={compliance}
                                        send_to_ai={send_to_ai}
                                        clientFeatures={clientFeatures}
                                        dataStructure={dataStructure}
                                        dispatchNotification={
                                            dispatchNotification
                                        }
                                    />
                                }
                                noHeaderBackground={true}
                                bodyStyles={{ padding: 0 }}
                                key={index}
                                arrowIconColorType="medium"
                            />
                        );
                    }
                })}
            </React.Fragment>
        );
    }

    renderSingleDimensionAssets(props) {
        const { data = [], locale } = props;

        const {
            media_id,
            disabled,
            options = {},
            clientFeatures,
            dataStructure = {},
            editableSectionOriginalMaster,
            handleChange,
            dispatchNotification,
            canDeleteCompliance,
            category,
            cmsData = {},
        } = this.props;

        const { entry_name } = dataStructure;

        const actionOptions = [];
        if (editableSectionOriginalMaster) {
            actionOptions.push({
                text: `Create New ${entry_name || "Asset"}`,
                onClick: () =>
                    dispatchNotification({
                        state: "create new asset",
                        props: {
                            data,
                            handleChange,
                            clientFeatures,
                            dataStructure: [dataStructure],
                            options,
                            filterOnSystemName: false,
                        },
                        dispatchNotification,
                    }),
                disabled: !editableSectionOriginalMaster,
            });
        }

        return (
            <React.Fragment>
                {this.renderMasterHeader({
                    title: "Original Master",
                    locale,
                    actionOptions,
                })}

                {data
                    .map((item, index) => (
                        <SingleDimensionAsset
                            data={data}
                            asset={item}
                            disabled={disabled}
                            media_id={media_id}
                            clientFeatures={clientFeatures}
                            dataStructure={dataStructure}
                            options={options}
                            handleChange={handleChange}
                            dispatchNotification={dispatchNotification}
                            key={`SingleDimension_${index}`}
                            index={index}
                            audits={this.props.audits || []}
                            canDeleteCompliance={canDeleteCompliance}
                            category={category}
                            cmsData={cmsData}
                        />
                    ))
                    .filter((item) => !item.props.asset.deleted)}
            </React.Fragment>
        );
    }

    render() {
        const {
            data,
            orignalMaster = {},
            alternativeMasters = [],
            masterLocale,
            quickView,
            dataStructure = {},
        } = this.props;

        const { singleDimension } = dataStructure;

        if (quickView) {
            return (
                <div>
                    <QuickView
                        {...this.props}
                        styles={{
                            borderBottomLeftRadius: "0.3rem",
                            borderBottomRightRadius: "0.3rem",
                        }}
                    />
                </div>
            );
        }

        return (
            <div className="meta-asset-body">
                {singleDimension &&
                    this.renderSingleDimensionAssets({
                        data,
                        locale: masterLocale,
                    })}

                {!singleDimension && (
                    <React.Fragment>
                        {!orignalMaster.deleted && 
                            this.renderMasterWithCompliances({
                                master: orignalMaster,
                                locale: masterLocale,
                            })
                        }

                        {!_.isEmpty(alternativeMasters) &&
                            alternativeMasters.map((alternateMaster, index) => {
                                if (!alternateMaster.deleted) {
                                    return this.renderMasterWithCompliances({
                                        master: alternateMaster,
                                        locale: alternateMaster.locale,
                                        key: index,
                                    });
                                }
                            })}
                    </React.Fragment>
                )}
            </div>
        );
    }
}
