import { Box, Stack } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CoreFormPath } from "../../../types";
import { CoreSelectOptionType } from "../Select/types/CoreSelectOptionType";

export type CoreRadioVariant = "tile" | "condensed";
export type CoreRadioProps<T> = CoreFormPath<T> & {
    option: CoreSelectOptionType;
    variant: CoreRadioVariant;
    error?: boolean;
    disabled?: boolean;
    setOnHoverText?: React.Dispatch<React.SetStateAction<string>>;
    disableMaxWidth?: boolean;
    customRadioStyles?: React.CSSProperties;
    hideSelector?: boolean;
    hideLabel?: boolean;
};

export function CoreRadio<T>({
    path,
    option,
    variant,
    error,
    disabled,
    setOnHoverText,
    disableMaxWidth,
    customRadioStyles = {},
    hideSelector = false,
    hideLabel = false,
}: CoreRadioProps<T>) {
    const formMethods = useFormContext();

    return (
        <Controller
            name={path}
            render={({ field: { onBlur, onChange, value, ref } }) => {
                const selected = option?.value;
                const label = option?.label || selected;
                const isSelectedInGroup =
                    selected === formMethods?.getValues(path);

                if (isSelectedInGroup && setOnHoverText) {
                    setOnHoverText(option?.onHoverText);
                }

                let border = "";

                if (error) {
                    border = "0.0625rem solid rgba(214, 70, 94, 0.75)";
                } else if (isSelectedInGroup) {
                    border = "0.1rem solid rgba(40, 108, 210, 0.75)";
                } else {
                    border = "0.0625rem solid #E6E6E6";
                }

                const tile = (
                    <Stack
                        // onMouseEnter={() => {
                        //     if (setOnHoverText) {
                        //         setOnHoverText(option?.onHoverText);
                        //     }
                        // }}
                        // onMouseLeave={() => {
                        //     if (isSelectedInGroup && setOnHoverText) {
                        //         setOnHoverText(option?.onHoverText);
                        //     }
                        // }}
                        spacing={4}
                        direction="column"
                        sx={{
                            width: "6.5rem",
                            height: "6.5rem",
                            padding: "1.125rem",
                            border,
                            borderRadius: "0.375rem",
                            background: "#FFFFFF",
                            "&:hover": {
                                cursor: "pointer",
                                boxShadow:
                                    !isSelectedInGroup &&
                                    "0rem 0.25rem 0.625rem rgba(74, 90, 113, 0.15)",
                            },
                            "& path": {
                                fillOpacity: isSelectedInGroup ? "0.87" : "0.7",
                            },
                        }}
                        component="div"
                        onClick={() => {
                            if (isSelectedInGroup && setOnHoverText) {
                                setOnHoverText(option?.onHoverText);
                            }
                            onChange(selected);
                        }}
                        onBlur={onBlur}
                        ref={ref}
                        alignItems="center"
                        justifyContent="center"
                        data-testid={`radio-${option.value}`}
                    >
                        {option?.topAdornment}

                        <Box
                            sx={{
                                color: "rgba(47, 47, 47, 0.87)",
                                fontWeight: isSelectedInGroup ? 500 : 400,
                                fontSize: "0.75rem",
                                lineHeight: "135%",
                                textAlign: "center",
                                margin: "0.625rem 0 0 !important",
                                ...option.sx,
                            }}
                        >
                            {option?.label ? option.label : selected}
                        </Box>
                    </Stack>
                );

                const condensed = (
                    <Stack
                        direction="row"
                        sx={{
                            width: "100%",
                            maxWidth: disableMaxWidth ? "100%" : "48.7%",
                            padding: "0.3125rem 0.625rem 0.3125rem 0.375rem",
                            border,
                            borderRadius: "0.375rem",
                            background: isSelectedInGroup
                                ? "#F2F6FE"
                                : "#FFFFFF",
                            "&:hover": {
                                cursor: "pointer",
                            },
                            height: "2.5rem",
                            ...customRadioStyles
                        }}
                        component="div"
                        onClick={() => {
                            onChange(selected);
                        }}
                        onBlur={onBlur}
                        ref={ref}
                        alignItems="center"
                        justifyContent="center"
                        data-testid={`radio-${option.value}`}
                    >
                        <Box>
                            <input
                                type="radio"
                                title="Yes"
                                checked={isSelectedInGroup}
                                hidden={hideSelector}
                            />
                        </Box>

                        <Box
                            sx={{
                                color: "rgba(47, 47, 47, 0.87)",
                                fontWeight: isSelectedInGroup ? 500 : 400,
                                fontSize: "0.875rem",
                                lineHeight: "150%",
                                marginLeft: "0.5rem",
                                flex: 1,
                                ...option.sx,
                            }}
                        >   
                            {hideLabel ? '' : label}
                        </Box>
                    </Stack>
                );
                
                const disabledStack = (
                    <Stack
                        direction="row"
                        sx={{
                            width: "100%",
                            maxWidth: disableMaxWidth ? "100%" : "48.7%",
                            padding: "0.3125rem 0.625rem 0.3125rem 0",
                            border: 'none',
                            background: 'none',
                            height: "2.5rem",
                        }}
                        component="div"
                        onBlur={onBlur}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Box sx={{ opacity: '0.37' }}>
                            <input
                                type="radio"
                                title="Yes"
                                checked={isSelectedInGroup}
                                style={{ marginLeft: 0 }}
                            />
                        </Box>

                        <Box
                            sx={{
                                color: "rgba(47, 47, 47, 0.87)",
                                fontWeight: isSelectedInGroup ? 500 : 400,
                                fontSize: "0.875rem",
                                lineHeight: "150%",
                                marginLeft: "0.5rem",
                                flex: 1,
                                ...option.sx
                            }}
                        >
                            {option?.label ? option.label : selected}
                        </Box>
                    </Stack>
                );

                if (disabled) {
                    return disabledStack
                }

                if (variant === "condensed") {
                    return condensed;
                }

                return tile;
            }}
        />
    );
}

CoreRadio.defaultProps = {
    error: false,
    setOnHoverText: null,
    disableMaxWidth: false,
};
