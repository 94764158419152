export default function ContentDeliveryTransformer(props) {
    const { data = [], content_delivery_preview_url } = props;

    const types = {
        dash: "application/dash+xml",
        mp4: "video/mp4",
        hls: "application/x-mpegURL",
    };

    const transformedData = data.map((item) => {
        const { subtitles = [], format, path, identifier, images = [] } = item;

        const basePath = `${content_delivery_preview_url}/${identifier}`;
        const poster = images.find((image) => image.type == "poster") || {};

        const transformedsSubtitles = subtitles.map((subtitle) => {
            return {
                ...subtitle,
                source: `${basePath}/${subtitle.path}`,
            };
        });

        return {
            ...item,
            source: {
                path: `${basePath}/${path}`,
                type: types[format],
            },
            subtitles: transformedsSubtitles,
            poster: `${basePath}/${poster.path}`,
        };
    });

    return {
        data: transformedData,
        selectedFormat: transformedData[0],
    };
}
