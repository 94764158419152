import React from "react";
import NumberFormat from "react-number-format";
import RightTransparentLeftColor from "../../DoubleTags/RightTransparentLeftColor";

const RenderDoubleTagPercentage = (props) => {
    const { text, rightText, styles = {}, className = "" } = props;

    const RenderRightText = (props) => {
        if (!Number.isFinite(props.value)) {
            return "-";
        }

        return `${props.value.toFixed(2)}%`;
    };

    return (
        <div className={`meta-lineup--finance-month--tag--${className}`}>
            <RightTransparentLeftColor
                leftText={text}
                rightText={<RenderRightText value={rightText} />}
                styles={styles}
            />
        </div>
    );
};

export default RenderDoubleTagPercentage;
