const input = {
    disabled: {
        fontSize: "1.05rem",
        fontWeight: 400,
        color: "#656565",
        letterSpacing: "0.0275rem",
    },
};

export default input;
