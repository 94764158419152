export default function validateString(string, maxLength, minLength = 1) {
    let result = true;

    if (typeof string == "string") {
        if (string.length < minLength) {
            result = false;
        }

        if (string.length > maxLength) {
            result = false;
        }
    }

    if (typeof string !== "string") {
        result = false;
    }

    if (!string) {
        result = false;
    }

    return result;
}
