import React from "react";
import Component from "./index";
import ClientManagedComponent from "../ClientManagedOptions/Component";

const ClientManagedAutocomplete = (props) => {
    return (
        <ClientManagedComponent
            {...props}
            Component={Component}
            onSearchKey="handleChange"
            dataKey="staticDatalist"
        />
    );
};

export default ClientManagedAutocomplete;
