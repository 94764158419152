import { LanguageList } from "../Gracenote/types/OriginalAudioLanguage/LanguageList";

const LanguageListData: LanguageList[] = [
    {
        name: "English",
        meta_code: "ENG",
    },
    {
        name: "German",
        meta_code: "GER",
    },
];

export default LanguageListData;
