const HandleRequestError = (props) => {
    const { state, description, error } = props;

    const { set } = state;

    set({
        notification: {
            title: "Technical Error",
            description: error ? error : description,
            status: "alert",
            onClick: () => {
                set({
                    notification: {},
                });
            },
        },
        fetchingMetaData: false,
        sorting: false,
    });

    console.log(error);
};

export default HandleRequestError;
