import React from "react";
import "./styles/_copyattributes.scss";
import CircleInputWithTitle from "../CircleInputWithTitle";
import CopyAttributesCancelBar from "./CopyAttributesCancelBar";
import CheckboxTagLocale from "../CheckboxTag/Locale";
import _ from "lodash";

const CopyAttributesLanguages = (props) => {
    const {
        selectAll,
        copy = {},
        locales = [],
        selectedLanguagesToCopy = [],
        handleCancelClick = () => {},
        handleCopyAttributes = () => {},
        handleSelection = () => {},
        handleSelectAll = () => {},
    } = props;

    let rowCount = 8;
    if (Array.isArray(locales)) {
        rowCount = Math.ceil(locales.length / 4);
    }

    return (
        <div className="localisation-language-manager-copy-atrributes-languages">
            <div className="content">
                <CircleInputWithTitle
                    title={copy.selectLanguages || "Select Languages"}
                    selectAll={selectAll}
                    styles={{
                        marginBottom: "1.09375rem",
                    }}
                    handleClick={handleSelectAll}
                />

                <div
                    className="languages"
                    style={{
                        gridTemplateRows: `repeat(${rowCount}, 1fr)`,
                    }}
                >
                    {locales.map((locale, index) => {
                        const selected = selectedLanguagesToCopy.find(
                            (item) => item.value == locale.value,
                        );

                        return (
                            <CheckboxTagLocale
                                leftText={locale.text}
                                className={`localisation-language-manager-copy-attributes-locales--${locale.value}`}
                                centeredContent={locale.value}
                                selected={selected ? true : false}
                                key={index}
                                handleSelection={handleSelection.bind(
                                    this,
                                    locale,
                                )}
                            />
                        );
                    })}
                </div>
            </div>

            <CopyAttributesCancelBar
                handleCancelClick={handleCancelClick}
                handleCopyAttributes={handleCopyAttributes}
                disabled={_.isEmpty(selectedLanguagesToCopy)}
            />
        </div>
    );
};

export default CopyAttributesLanguages;
