import CategoryTags from "../CategoryTags";
import SeasonName from "../SeasonName";
import EpisodeTitle from "../EpisodeTitle";
import _ from "lodash";

const RecordTitle = (data) => {
    const {
        original_title = "",
        search_title = "",
        category,
        titles = [],
    } = data;

    let result = original_title || search_title;

    // if(!_.isEmpty(titles)) {
    // 	const originalTitle = titles.find(item => item.display == true)

    // 	if(originalTitle && originalTitle.title) {
    // 		result = originalTitle.title
    // 	}
    // }

    // Season
    if (category == CategoryTags.Season.short) {
        result = SeasonName(data);
    }

    // Episode or Storyline
    if (
        category == CategoryTags.Episode.short ||
        category == CategoryTags.Storyline.short
    ) {
        result = EpisodeTitle(data);
    }

    return result;
};

export default RecordTitle;
