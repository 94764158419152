import React from "react";

const CircleInput = (props) => {
    const {
        className,
        background = "#dfdfdf",
        innerBackground = "green",
        width = "1rem",
        borderWidth,
        id,
        disabled,
        title,
        onClick = () => {},
        styles = {},
    } = props;

    let outerStyles = {
        width,
        height: width,
        borderRadius: "50%",
        background: innerBackground,
        borderWidth: borderWidth,
        borderStyle: "solid",
        borderColor: background,
        cursor: "pointer",
        ...styles,
    };

    if (disabled) {
        (outerStyles.opacity = "0.15"), (outerStyles.background = "#838383");
    }

    return (
        <div
            className={`meta-circle-input ${className}`}
            id={id}
            style={outerStyles}
            title={title}
            onClick={onClick}
        ></div>
    );
};

export default CircleInput;
