import { SET_BACKGROUND_PROCESS } from "../actions/action_types";
import { defaultBackgroundProcess } from "../initialState";

export default function (
    state = { backgroundProcess: defaultBackgroundProcess },
    action,
) {
    switch (action.type) {
        case SET_BACKGROUND_PROCESS:
            return Object.assign({}, state, {
                backgroundProcess: action.backgroundProcess,
            });
    }

    return state;
}
