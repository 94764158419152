/**
 * @param maxValue Maximum expected value
 * @param value Current value
 * @returns Normalised value used for progress bar
 */
export function normaliseValue(value: number, maxValue: number) {
    const MIN = 0;
    const MAX = maxValue;

    const normalisezValue = ((value - MIN) * 100) / (MAX - MIN);
    return normalisezValue;
}
