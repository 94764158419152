import React from "react";
import { SectionInterface } from "./interface";
import DailySeriesLandscape from "../../views/DailySeriesLandscape";
import CategoryTags from "../../../CategoryTags";
import ai_episode from "../../ai/ai_episode";
import _ from "lodash";

const SectionDailySeriesLandscape = (props: SectionInterface) => {
    const { data = {}, disabled } = props;

    const { category, original_title } = data;

    const displayDataDaily =
        category == CategoryTags.Short.short &&
        original_title == "Chapter Sixty-Three: “Hereditary”";

    return (
        <DailySeriesLandscape
            name="daily_series_landscape"
            className="meta-record-daily-series-landscape"
            data={displayDataDaily ? ai_episode : {}}
            disabled={disabled}
            noBorder={true}
        />
    );
};

export default SectionDailySeriesLandscape;
