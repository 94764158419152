import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../store/rootStore";
import Notification from "../../../../../components/Notification";
import { RenderEditAwardForm } from "../components/RenderEditAwardForm";

interface Props {
    cmsData: any;
    contributors: any[];
}

const EditAwardForm: React.FC<Props> = observer((props: Props) => {
    const rootStore = useStores();
    const { awardsStore } = rootStore || {};

    const { cmsData = {}, contributors = [] } = props;

    return (
        <Notification
            title="Edit Award"
            background="white"
            intercationStatus="alert"
            confirm={() => awardsStore.handleSaveEditItem()}
            okText="Save"
            cancelClick={() => awardsStore.setAwardEditFormIsActive(false)}
            html={
                <RenderEditAwardForm
                    cmsData={cmsData}
                    contributors={contributors}
                />
            }
            maxWidth="42rem"
        />
    );
});

export default EditAwardForm;
