import AssetInterface from "../../components/asset/interfaces/AssetInterface";
import { CONFIRM_ASSET_DELETE } from "./index";

export const confirmAssetDelete = (
    asset: AssetInterface,
    assets: Array<AssetInterface>,
) => {
    const { uuid = null } = asset;

    const DeleteAssetByUUID = (assetsStore): Array<AssetInterface> => {
        return assetsStore.map((item: AssetInterface) => {
            if (item.uuid == uuid) {
                return Object.assign({}, item, { deleted: true });
            }

            if (item.child_assets) {
                item.child_assets = DeleteAssetByUUID(item.child_assets);
            }

            return item;
        });
    };

    return {
        type: CONFIRM_ASSET_DELETE,
        state: {
            assets: DeleteAssetByUUID(assets),
            editAsset: {},
            notification: {},
        },
    };
};
