import { SET_CONVERTING_DATA } from "../actions/action_types";
import { defaultConvertingData } from "../initialState";

export default function (
    state = { convertingData: defaultConvertingData },
    action,
) {
    switch (action.type) {
        case SET_CONVERTING_DATA:
            return Object.assign({}, state, {
                convertingData: action.convertingData,
            });
    }

    return state;
}
