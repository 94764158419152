import React from "react";
import _ from "lodash";
import styles from "../styles";
import RightsComponent from "../../Rights";
import Index from "./Index";
import deepcopy from "deepcopy";
import { diff } from "deep-object-diff";
import moment from "moment";
import NoneEntered from "../../KeywordLabel/noneEntered";
import { sortRights } from "../utils/helpers/rights/sortRights";

export default class Rights extends Index {
    constructor(props) {
        super(props);

        let data = [];

        if (Array.isArray(this.props.data)) {
            data = this.props.data;
        }

        /**
         * @var object
         */
        this.styles = styles(this.props);

        /**
         * @var object
         */
        this.state = {
            data,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        const nextPropsData = nextProps.data || {};
        const thisPropsData = this.props.data || {};
        const difference = diff(nextPropsData, thisPropsData);

        if (!_.isEmpty(difference)) {
            return true;
        }

        if (!_.isEqual(nextProps.disabled, this.props.disabled)) {
            return true;
        }

        if (!_.isEqual(nextProps.options, this.props.options)) {
            return true;
        }

        if (!_.isEqual(nextProps.fetchRightsUrl, this.props.fetchRightsUrl)) {
            return true;
        }

        if (!_.isEqual(nextProps.rights, this.props.rights)) {
            return true;
        }

        if (!_.isEqual(nextState, this.state)) {
            return true;
        }

        return false;
    }

    handleChange(value) {
        if (typeof this.props.handleFormChange == "function") {
            this.props.handleFormChange(value);
        }

        this.setData(value);
    }

    renderContent() {
        let data = deepcopy(this.state.data || []);

        if (this.props.disabled && _.isEmpty(data)) {
            return false;
        }

        const sortedData = sortRights(data);

        const { clientDataStructure } = this.props;

        const { rightsV3 = {}, rights = null } = clientDataStructure;

        return (
            <RightsComponent
                {...this.props}
                data={sortedData}
                styles={this.styles.marginItem}
                dataStructure={rights}
                righsV3DataStructure={rightsV3}
                handleChange={(item) => this.handleChange(item)}
            />
        );
    }
}
