import SendRequest from "../../../components/SendRequest";
import GetApiUrls from "../GetApiUrls";

const HandleDeleteUserGroupRequest = (props) => {
    const {
        state = {},
        meta_id,
        handleSuccess = () => {},
        handleError = () => {},
    } = props;

    const { userGroupDelete = "" } = GetApiUrls(state);

    const options = {
        url: userGroupDelete.replace("%meta_id%", meta_id),
        method: "DELETE",
        basic: true,
    };

    SendRequest(
        options,
        (response) => {
            const { status } = response;

            if (status == 200) {
                handleSuccess(response);
                return;
            }

            handleError();
        },
        (error) => {
            handleError(error);
        },
    );
};

export default HandleDeleteUserGroupRequest;
