import { getDefaultWrittenLanguageCodeFromCache } from "../../../models/ClientManaged/languagesModel"

const SET_CMS_DATA = "SET_CMS_DATA";
const SET_OPTIONS = "SET_OPTIONS";
const SET_NOTIFICATION = "SET_NOTIFICATION";
const SET_CLIENT_MANAGED_OPTIONS_VERSIONS =
    "SET_CLIENT_MANAGED_OPTIONS_VERSIONS";
const SET_USER_PERMISSIONS = "SET_USER_PERMISSIONS";
const SET_USER_PERMISSIONS_ERROR = "SET_USER_PERMISSIONS_ERROR";
const SET_ROLES = "SET_ROLES";
const SET_ROLES_TRANSFORMED = "SET_ROLES_TRANSFORMED";
const SET_ACCESS_CATALOGS = "SET_ACCESS_CATALOGS";
const SET_ACCESS_CATALOGS_ERROR = "SET_ACCESS_CATALOGS_ERROR";
const SET_CLIENT_MANAGED_OPTIONS = "SET_CLIENT_MANAGED_OPTIONS";
const SET_FALLBACK_WRITTEN_LANGUAGES = "SET_FALLBACK_WRITTEN_LANGUAGES";
const SET_DEFAULT_LANGUAGE_CODE = "SET_DEFAULT_LANGUAGE_CODE";
const SET_RECORD_BUILDER = "SET_RECORD_BUILDER";
const SET_RECORD_BUILDER_ERROR = "SET_RECORD_BUILDER_ERROR";
const SET_CLIENT_MANAGED_LISTS = "SET_CLIENT_MANAGED_LISTS"
const SET_SNACKBAR = "SET_SNACKBAR";
const SET_SNACKBAR_REMOVE = "SET_SNACKBAR_REMOVE";

export const initialState = {
    cmsData: {},
    options: {},
    notification: {},
    clientManagedOptionsVersions: {},
    clientManagedOptions: {},
    userPermissions: {},
    roles: [],
    rolesTransformed: [],
    defaultLocale: getDefaultWrittenLanguageCodeFromCache(),
    fallBackWrittenLanguages: [],
    defaultLanguageCode: getDefaultWrittenLanguageCodeFromCache(),
    snackbars: []
};

export const setCMSData = (cmsData) => ({
    type: SET_CMS_DATA,
    cmsData,
});

export const setOptions = (options) => ({
    type: SET_OPTIONS,
    options,
});

export const setNotification = (notification) => ({
    type: SET_NOTIFICATION,
    notification,
});

export const setClientManagedOptionsVersions = (
    clientManagedOptionsVersions,
) => ({
    type: SET_CLIENT_MANAGED_OPTIONS_VERSIONS,
    clientManagedOptionsVersions,
});

export const setClientManagedOptions = (clientManagedOptionsItem) => ({
    type: SET_CLIENT_MANAGED_OPTIONS,
    clientManagedOptionsItem,
});

export const setUserPermissions = (userPermissions) => ({
    type: SET_USER_PERMISSIONS,
    userPermissions,
});

export const setPermissionsError = (permissionsError) => ({
    type: SET_USER_PERMISSIONS_ERROR,
    permissionsError,
});

export const setRoles = (roles) => ({
    type: SET_ROLES,
    roles,
});

export const setRolesTransformed = (rolesTransformed) => ({
    type: SET_ROLES_TRANSFORMED,
    rolesTransformed,
});

export const setAccessCatalogs = (catalogs) => ({
    type: SET_ACCESS_CATALOGS,
    catalogs,
});

export const setAccessCatalogsError = (catalogsError) => ({
    type: SET_ACCESS_CATALOGS_ERROR,
    catalogsError,
});

export const setFallBackWrittenLanguages = (fallBackWrittenLanguages) => ({
    type: SET_FALLBACK_WRITTEN_LANGUAGES,
    fallBackWrittenLanguages,
});

export const setDefaultLanguageCode = (defaultLanguageCode) => ({
    type: SET_DEFAULT_LANGUAGE_CODE,
    defaultLanguageCode,
})

export const setRecordBuilder = (recordBuilder) => ({
    type: SET_RECORD_BUILDER,
    recordBuilder,
});

export const setRecordBuilderError = (recordBuilderError) => ({
    type: SET_RECORD_BUILDER_ERROR,
    recordBuilderError,
});

export const setSnackbar = (snackbar) => ({
    type: SET_SNACKBAR,
    snackbar,
});

export const setSnackbarRemove = (index) => ({
    type: SET_SNACKBAR_REMOVE,
    index
});

export const setClientManagedLists = (clientManagedLists) => ({
    type: SET_CLIENT_MANAGED_LISTS,
    clientManagedLists,
});

export const globalPageReducer = (state = initialState, action) => {
    if (action.type === SET_CMS_DATA) {
        return {
            ...state,
            cmsData: action.cmsData,
        };
    }

    if (action.type === SET_FALLBACK_WRITTEN_LANGUAGES) {
        return {
            ...state,
            fallBackWrittenLanguages: action.fallBackWrittenLanguages,
        };
    }

    if (action.type === SET_DEFAULT_LANGUAGE_CODE) {
        return {
            ...state,
            defaultLanguageCode: action.defaultLanguageCode,
        };
    }

    if (action.type === SET_OPTIONS) {
        return {
            ...state,
            options: action.options,
        };
    }

    if (action.type === SET_NOTIFICATION) {
        return {
            ...state,
            notification: action.notification,
        };
    }

    if (action.type === SET_CLIENT_MANAGED_OPTIONS_VERSIONS) {
        return {
            ...state,
            clientManagedOptionsVersions: action.clientManagedOptionsVersions,
        };
    }

    if (action.type === SET_CLIENT_MANAGED_OPTIONS) {
        return {
            ...state,
            clientManagedOptions: {
                ...state.clientManagedOptions,
                ...action.clientManagedOptionsItem
            },
        };
    }

    if (action.type === SET_USER_PERMISSIONS) {
        return {
            ...state,
            userPermissions: action.userPermissions,
        };
    }

    if (action.type === SET_USER_PERMISSIONS_ERROR) {
        return {
            ...state,
            permissionsError: action.permissionsError,
        };
    }

    if (action.type === SET_ROLES) {
        return {
            ...state,
            roles: action.roles,
        };
    }

    if (action.type === SET_ROLES_TRANSFORMED) {
        return {
            ...state,
            rolesTransformed: action.rolesTransformed,
        };
    }

    if (action.type === SET_ACCESS_CATALOGS) {
        return {
            ...state,
            accessCatalogs: action.catalogs,
        };
    }

    if (action.type === SET_ACCESS_CATALOGS_ERROR) {
        return {
            ...state,
            accessCatalogs: [],
            accessCatalogsError: action.catalogsError,
        };
    }

    if (action.type === SET_RECORD_BUILDER) {
        let transformedConfig = {};
        
        return {
            ...state,
            recordBuilder: action.recordBuilder,
        };
    }

    if (action.type === SET_RECORD_BUILDER_ERROR) {
        return {
            ...state,
            recordBuilder: [],
            recordBuilderError: action.recordBuilderError,
        };
    }

    if (action.type === SET_SNACKBAR) {
        let snackbars = [...state.snackbars]
        snackbars.push(action.snackbar)

        return {
            ...state,
            snackbars,
        };
    }

    if (action.type === SET_SNACKBAR_REMOVE) {
        let snackbars = [...state.snackbars]
        snackbars.splice(action.index, 1)

        return {
            ...state,
            snackbars,
        };
    }

    if (action.type === SET_CLIENT_MANAGED_LISTS) {
        return {
            ...state,
            clientManagedLists: action.clientManagedLists,
        };
    }
};
