import { styled } from "@mui/material/styles"
import Menu from '@mui/material/Menu';

export const StyledMenu = styled(Menu)({
	".MuiPaper-root": {
		border: "0.0625rem solid rgba(77, 77, 77, 0.1)",
		boxShadow: "0.125rem 0.1875rem 0.3125rem rgba(79, 94, 116, 0.15)",
	},
	".MuiMenuItem-root": {
		color: "rgba(47, 47, 47, 0.87)",
		cursor: "pointer",
		fontSize: "0.875rem",
		margin: 0,
		padding: "0.5rem 0.75rem",
	}
})