import $ from "jquery"
import { isEmpty } from "lodash"
import { FieldJqueryInterface } from '../interfaces/Form.interface'

export function runJqueryFields(fieldJquery: FieldJqueryInterface): void {
    if (!isEmpty(fieldJquery?.hide)) {
        fieldJquery?.hide.forEach((field) => {
            $(`${field}`).css({
                display: "none",
            });
        })
    }

    if (!isEmpty(fieldJquery?.slideDown)) {
        fieldJquery?.slideDown.forEach((field) => {
            $(`${field}`).slideDown(100);
        });
    }
}