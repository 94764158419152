import styled from "@emotion/styled";
import { Box } from "@mui/material";

interface Props {
    linkurl?: string;
}

export const StyledOriginBadge = styled(Box)<Props>(({ linkurl = '' }) => ({
    position: 'absolute',
    right: '0.5rem',
    top: '0.5rem',
    ".origin-badge__container": {
        display: 'inline-flex',
        alignItems: 'center',
        background: linkurl ? '#000' : '#ECECEC',
        border: linkurl ? '0.0625rem solid #A6A6A6' : '0.0625rem solid #D7D7D7',
        borderRadius: '0.4375rem',
        padding: '0.5rem 0.5625rem 0.375rem 0.5625rem',
        cursor: linkurl ? 'pointer' : 'default',
        
    },
    ".origin-badge__image": {
        display: 'flex',
        alignItems: 'center',
        "img": {
            width: '1.3125rem'
        }
    },
    ".origin-badge__details": {
        display: 'flex',
        flexFlow: 'column',
        padding: '0 0 0 0.375rem',
        "span": {
            color: linkurl ? '#fff' : '#C8C8C8',
            fontSize: '0.625rem',
            lineHeight: '0.5625rem',
            "&.title": {
                fontSize: '1rem',
                lineHeight: '1.1875rem'
            }
        },
    }
}));

export const StyledMeasurement = styled(Box)(() => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0px, 1fr))',
    columnGap: "1.125rem",
    rowGap: "1.125rem",
    margin: "1.125rem 0 0"
}));

export const StyledMeasurementCard = styled(Box)(() => ({
    border: '0.0625rem solid #E6E6E6',
    borderRadius: '0.375rem',
    background: '#fff',
    padding: '0.9375rem',
    ".measurement-card__logo": {
        height: "1.75rem",
        "img": {
            maxHeight: "100%"
        }
    },
    ".measurement-card__title": {
        color: "rgba(47, 47, 47, 0.87)",
        fontSize: "0.875rem",
        fontWeight: "500",
        lineHeight: "1.3125rem",
        margin: "0.625rem 0 0",
    },
    ".measurement-card__rating": {
        display: "flex",
        margin: "0.3125rem 0 0",
        ".rating-icon": {
            margin: "0 0.3125rem 0 0"
        },
        ".rating-value": {
            color: 'rgba(47, 47, 47, 0.87)',
            fontWeight: "500",
        },
        ".rating-secondary-value": {
            color: 'rgba(47, 47, 47, 0.37)',
            fontWeight: "400",
        }
    }
}));