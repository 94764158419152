import UseSingleAdvisoryGroup from "./UseSingleAdvisoryGroup";

const FilterAdvisoriesByRegionSystem = (props) => {
    const {
        advisories = [],
        options = {},
        rating = {},
        clientDataStructure = {},
    } = props;

    const { certifications, countries = [] } = options;

    const { internal_systems = {} } = certifications;

    const { regions = {}, advisory_groups = [] } = internal_systems;

    const CountryNameByCode = (props) => {
        const { countries = [], code = "" } = props;
        let result = null;

        countries.map((row) => {
            if (row.value === code) {
                result = row.text;
            }
        });

        return result;
    };

    let result = [];
    if (UseSingleAdvisoryGroup({ clientDataStructure })) {
        advisory_groups.map((group) => {
            group.advisory.map((advisoryItem) => {
                if (advisories.includes(advisoryItem.name)) {
                    result.push(advisoryItem.name);
                }
            });
        });
    } else {
        if (
            regions.hasOwnProperty(
                CountryNameByCode({ countries, code: rating.country_code }),
            )
        ) {
            let availableAdvisoriesSystems =
                regions[
                    CountryNameByCode({ countries, code: rating.country_code })
                ] || [];
            let systemData = availableAdvisoriesSystems[rating.system] || {};
            if (systemData.advisories && Array.isArray(systemData.advisories)) {
                advisories.map((item) => {
                    if (systemData.advisories.includes(item)) {
                        result.push(item);
                    }
                });
            }
        }
    }

    return result;
};

export default FilterAdvisoriesByRegionSystem;
