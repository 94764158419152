import { AwardInterface } from '../../interfaces/awards.interfaces'

export const adaptContributorData = (
	awardObject: AwardInterface
): string[] | [] => {

    if (Array.isArray(awardObject.contributors)) {
		return awardObject.contributors.map(
			(contributor) => 
				contributor.value	
		)
	}

	return awardObject.contributor ? [awardObject.contributor?.value] : []
}