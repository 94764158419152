import ClientManagedLanguages from "../../ClientManagedOptions/languages";
import _ from "lodash";

const UpdateRegionalLocaleValue = (props) => {
    const { options = {}, clientFeatures, regionalItem = {} } = props;

    let languagesList = ClientManagedLanguages({
        options,
        clientFeatures,
        spoken: true,
        languageKey: "primary_language",
    });

    let result = { ...regionalItem };

    if (result.locale && !_.isEmpty(languagesList)) {
        const regionalItemLanguage = languagesList.find((language) =>
            !_.isEmpty(language.name)
                ? language.name.toLowerCase() == result.locale.toLowerCase()
                : false || !_.isEmpty(language.text)
                ? language.text.toLowerCase() == result.locale.toLowerCase()
                : false || !_.isEmpty(language.iso_639_1)
                ? language.iso_639_1.toLowerCase() ==
                  result.locale.toLowerCase()
                : false || !_.isEmpty(language.iso_639_2)
                ? language.iso_639_2.toLowerCase() ==
                  result.locale.toLowerCase()
                : false,
        );

        result.locale =
            regionalItemLanguage &&
            regionalItemLanguage.meta_code &&
            !_.isEmpty(regionalItemLanguage.meta_code)
                ? regionalItemLanguage.meta_code.toUpperCase()
                : result.locale;
    }

    return result;
};

export default UpdateRegionalLocaleValue;
