import React from "react";
import Select from "../../../Select";
import EpisodeLeftText from "../../../AddTitleToCollection/src/EpisodeLeftText";
import RenderCheckboxTag from "../../../AddTitleToCollection/src/RenderCheckboxTag";
import DataFetch from "./DataFetch";
import CategoryTags from "../../../CategoryTags";
import AutocompleteInput from "../../../AutocompleteInput";
import _ from "lodash";
import OriginalTitleDotYearText from "./src/OriginalTitleDotYearText";
import SeasonLabel from "./src/SeasonLabel";
import ShowLabelOfSeasonsEpisodesCounts from "./src/ShowLabelOfSeasonsEpisodesCounts";

export default class LinkEpisodes extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchValue: "",
            searchList: [],
            searchListLinks: [],
            readySearchSelection: false,
            seasonsList: [],
            selectedSeason: null,
            episodesList: [],
            selectedEpisodes: [],
        };

        this.updateSearchList = this.updateSearchList.bind(this);
        this.updateEpisodesList = this.updateEpisodesList.bind(this);
    }

    updateSearchList(data) {
        const list = data.map((item) => {
            return {
                value: item.meta_id,
                text: OriginalTitleDotYearText({ ...item }),
            };
        });
        this.setState({ searchList: list, searchListLinks: data });
    }

    handleSearchSelection(value) {
        value.meta_id = value.value;

        let seasons =
            DataFetch.seasonsFromLinkage({
                meta_id: value.meta_id,
                records: this.state.searchListLinks,
            }) || [];

        value.postfix = ShowLabelOfSeasonsEpisodesCounts({
            parentShowLinkages: seasons,
        }).seasonsLabel;
        seasons.sort((a, b) => (a.season_number > b.season_number ? 1 : -1));

        let seasonsList = seasons.map((row) => {
            return {
                text: SeasonLabel({ ...row }),
                value: row.meta_id,
            };
        });

        this.setState({
            searchValue: value,
            readySearchSelection: true,
            seasonsList: seasonsList,
        });
    }

    handleSeasonSelection(value) {
        this.setState({ selectedSeason: value });

        DataFetch.linksByMetaId({
            searchUrl: this.props.searchFullUrl,
            meta_id: value,
            filterCategories: this.props.categories,
            onResult: this.updateEpisodesList,
        });
    }

    updateEpisodesList(rows) {
        this.setState({ episodesList: rows });
    }

    handleEpisodesSelection(value) {
        let tmpSelectedEpisodes = this.state.selectedEpisodes;

        let presentEpisode = this.state.selectedEpisodes.find(
            (el) => el.meta_id === value.record.meta_id,
        );

        if (presentEpisode) {
            tmpSelectedEpisodes = tmpSelectedEpisodes.filter((el) => {
                return el.meta_id != value.record.meta_id;
            });
        } else {
            tmpSelectedEpisodes.push(value.record);
        }

        this.setState({ selectedEpisodes: tmpSelectedEpisodes });
        this.props.onChange(tmpSelectedEpisodes);
    }

    render() {
        return (
            <div className="new-linkage-episode-search">
                <AutocompleteInput
                    label="Search By Parent Show"
                    id="new-linkage-episode-search-input"
                    placeholder="Start Typing Show Name"
                    noneEntered={true}
                    value={this.state.searchValue}
                    staticDatalist={this.state.searchList}
                    single={true}
                    recordBasic={true}
                    keepSelected={true}
                    bubbleOnSelect={true}
                    callBackOnDelete={() =>
                        this.setState({ searchValue: null })
                    }
                    handleListSelection={(item) =>
                        this.handleSearchSelection(item)
                    }
                    filterStaticDataList={true}
                    handleChange={(search) => {
                        DataFetch.links({
                            searchQuery: search,
                            searchUrl: this.props.searchUrl,
                            recordType: CategoryTags.Episode.short,
                            onResult: this.updateSearchList,
                        });
                        this.setState({ readySearchSelection: false });
                    }}
                    styles={{
                        margin: "1.5rem 0 0 0",
                    }}
                    inputStyles={{
                        margin: "0.28125rem 0 0 0",
                    }}
                    keywordLabel={{
                        background: "rgb(28, 101, 77)",
                        postfix: this.state.searchValue
                            ? this.state.searchValue.postfix
                            : "",
                    }}
                />

                {this.state.searchValue && this.state.readySearchSelection && (
                    <div style={{ paddingTop: "1.85rem" }}>
                        <Select
                            label="Season"
                            id="new-linkage-episode-select-season"
                            disabledText="Select Season"
                            value={this.state.selectedSeason || ""}
                            handleChange={(seasonMetaId) =>
                                this.handleSeasonSelection(seasonMetaId)
                            }
                            data={this.state.seasonsList}
                            showNullOption={false}
                        />
                    </div>
                )}

                {!_.isEmpty(this.state.selectedSeason) && (
                    <div className="new-linkage-episode-select-episodes">
                        <span
                            style={{
                                display: "block",
                                fontSize: "0.90625rem",
                                fontWeight: 500,
                                color: "rgba(110, 110, 110, 0.87)",
                                paddingTop: "1.85rem",
                            }}
                        >
                            Select Episode
                        </span>
                        {this.state.episodesList.map((episode, index) => {
                            const selected = this.state.selectedEpisodes.find(
                                (item) => item.meta_id == episode.meta_id,
                            );
                            const leftText = EpisodeLeftText({
                                episode,
                            });
                            return (
                                <RenderCheckboxTag
                                    id="new-linkage-episode-item-checkbox-episode"
                                    key={index}
                                    selected={selected}
                                    leftText={leftText}
                                    episode={episode}
                                    handleSelection={(value) =>
                                        this.handleEpisodesSelection(value)
                                    }
                                />
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }
}
