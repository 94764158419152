import { SET_BREAKPOINT } from "../actions/action_types";
import { defaultBreakpoint } from "../initialState";

export default function (state = { breakpoint: defaultBreakpoint }, action) {
    switch (action.type) {
        case SET_BREAKPOINT:
            return Object.assign({}, state, {
                breakpoint: action.breakpoint,
            });
    }

    return state;
}
