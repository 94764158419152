import SendRequest from "../../../components/SendRequest";
import _ from "lodash";
import CategoryTags from "../../../components/CategoryTags";
import GetRecordData from "../GetRecordData";

const HandleCollectionTitleEpisodesSearch = (props) => {
    const { selectedSeason, state = {} } = props;

    const { cmsData = {}, collectionTitle = {}, set } = state;

    const { category, selectedTitle = {} } = collectionTitle;

    const { linkage = [] } = selectedTitle;

    const record = GetRecordData({
        state,
    });

    let collectionTitlesLinkage = [];
    if (Array.isArray(record.linkage)) {
        collectionTitlesLinkage = record.linkage;
    }

    const linkageMetaIds = collectionTitlesLinkage
        .filter((item) => !item.deleted)
        .map((item) => item.meta_id);

    set({
        fetchingEpisodes: true,
    });

    if (selectedSeason && cmsData) {
        const { apiUrls } = cmsData;

        const season = linkage.find(
            (item) =>
                item.meta_id == selectedSeason &&
                item.category == CategoryTags.Season.short,
        );

        if (season && season.meta_id) {
            const options = {
                url: apiUrls.recordLinkage,
                method: "POST",
                body: {
                    meta_id: season.meta_id,
                },
                testingData: [
                    {
                        category: "Show",
                    },
                ],
            };

            const HandleError = (error) => {
                if (error) {
                    console.log(error);
                }

                set({
                    fetchingEpisodes: false,
                });

                state.setCollectionTitleKey(
                    "error",
                    "Could not fetch episodes",
                );
            };

            SendRequest(
                options,
                (response) => {
                    const { data = [], status, message } = response;

                    if (status == 200) {
                        const episodes = data.filter(
                            (item) =>
                                item.category == CategoryTags.Episode.short &&
                                !linkageMetaIds.includes(item.meta_id),
                        );
                        const storylines = data.filter(
                            (item) =>
                                item.category == CategoryTags.Storyline.short &&
                                !linkageMetaIds.includes(item.meta_id),
                        );
                        const specials = data.filter(
                            (item) =>
                                item.category == CategoryTags.Special.short &&
                                !linkageMetaIds.includes(item.meta_id),
                        );

                        if (category == CategoryTags.Episode.short) {
                            state.setCollectionTitleKey("episodes", episodes);
                        }

                        if (category == CategoryTags.Storyline.short) {
                            state.setCollectionTitleKey("episodes", storylines);
                        }

                        if (category == CategoryTags.Special.short) {
                            state.setCollectionTitleKey("episodes", specials);
                        }

                        if (category == CategoryTags.Season.short) {
                            state.setCollectionTitleKey(
                                "episodes",
                                _.concat(episodes, storylines, specials),
                            );
                        }

                        set({
                            fetchingEpisodes: false,
                        });

                        return;
                    }

                    HandleError(message);
                },
                (e) => {
                    HandleError(e);
                },
            );
        }
    }
};

export default HandleCollectionTitleEpisodesSearch;
