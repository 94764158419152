import React from "react";
import { SectionInterface } from "./interface";
import Ids from "../../views/Ids";
import IDsV2 from "../../views/IDsV2";
import IsActiveRecordBuilder from "../../../RecordBuilder/IsActive";
import { IDS } from "../../../../models/RecordBuilder";
import IDsBuilder from "../../views/IDsBuilder";
import { getSectionCommonProps } from "../../actions";
import _ from "lodash";

const SectionIds = (props: SectionInterface) => {
    const {
        handleFormChange,
        cmsData = {},
        data = {}
    } = props;

    const { clientDataStructure = {}, clientFeatures = {} } = cmsData;

    const { verified_fields: verifiedFields = {} } = data;

    const idsProps = {
        ...getSectionCommonProps({ ...props, field: "ids" }),
        className: "meta-record-ids",
        data: data.ids || {},
        firstSection: true,
        lastSection: true,
        category: data.category,
        clientFeatures: clientFeatures,
        dataStructure: clientDataStructure.identifiers || {},
        verifiedFields: verifiedFields,
        season_number: data?.season_numbering?.season_number || null,
        episode_number: data?.episode_numbering?.position || null,
        handleVerifiedFieldsChange: (data: any) =>
            handleFormChange("verified_fields", data),
    };

    if (IsActiveRecordBuilder({ page: IDS, clientFeatures })) {
        return <IDsBuilder {...idsProps} />;
    }

    if (!_.isEmpty(clientDataStructure.identifiers)) {
        return <IDsV2 {...idsProps} />;
    }

    return <Ids {...idsProps} />;
};

export default SectionIds;
