import React from "react";
import SectionContentWrapper from "../../../Boilerplates/SectionContentWrapper"
import CubeShape from "../../../CubeShape"
import "../../styles/_empty_state.scss"

interface EmptyStateInterface {
    className?: string,
    title: string
}

const EmptyState = (props: EmptyStateInterface) => {
    const {
        className = '',
        title = ""
    } = props

    return (
        <SectionContentWrapper className={className}>
            <div className="asset-empty-state">
                <CubeShape color="#CECECE" />
                
                <span className="asset-empty-state--title">{title}</span>
            </div>
        </SectionContentWrapper>
    )
}

export default EmptyState;
