import { isEmpty } from "lodash";
import { AllFieldsVT, SeasonVT, TvShowVT } from "../../config/types";
import store from "../../containers/record/tv-series/store/store";
import {
    NuvoParentFields,
    NuvoRecord,
} from "../../containers/record/tv-series/types/nuvo.types";
import { Categories } from "../../containers/record/tv-series/types/types";
import CollectionHelpers from "../CollectionHelpers";
import TvSeriesRelationships from "../TvSeriesRelationships";

const serdesFns = {
    brand: {
        deserializeFn: (nuvoRecord) => {
            return nuvoRecord?.origination?.brand;
        },
        serializeFn: (brand) => {
            return brand;
        },
    },

    collection: {
        deserializeFn: (nuvoRecord: NuvoRecord) => {
            return CollectionHelpers.fromNuvoRecord(nuvoRecord);
        },
        serializeFn: (collection: AllFieldsVT["collection"]) => {
            return CollectionHelpers.toNuvoRecord(collection);
        },
    },

    countryOfOrigin: {
        deserializeFn(nuvoRecord) {
            return nuvoRecord?.origination?.country_iso;
        },
        serializeFn(countryOfOrigin) {
            return countryOfOrigin;
        },
    },

    descriptions: {
        genre: {
            deserializeFn: (nuvoRecord: NuvoRecord) => {
                const genres = nuvoRecord.descriptors?.genres;
                if (isEmpty(genres)) {
                    return [];
                }

                return genres.map((genre) => genre.value);
            },
            serializeFn: (genres: string[]) => {
                return genres.join(", ");
            },
        },
        subGenre: {
            deserializeFn: (nuvoRecord: NuvoRecord) => {
                const subGenres = nuvoRecord.descriptors?.sub_genres;
                if (isEmpty(subGenres)) {
                    return [];
                }

                return subGenres.map((subGenre) => subGenre.value);
            },
            serializeFn: (subGenres: string[]) => {
                return subGenres.join(", ");
            },
        },
    },

    originalLanguage: {
        deserializeFn: (nuvoRecord) => {
            return nuvoRecord?.origination?.locale;
        },
        serializeFn: (locale) => {
            return locale;
        },
    },

    parentModularEpisode: {
        deserializeFn: (nuvoRecord: NuvoRecord) => {
            return TvSeriesRelationships.findParentEpisode(nuvoRecord);
        },
        serializeFn: (
            parentModularEpisode: AllFieldsVT["parentModularEpisode"],
        ): NuvoParentFields => {
            if (isEmpty(parentModularEpisode)) {
                return {
                    manualLink_parent_episode_number: null,
                };
            }

            const parentEpisode = store.findByKey({
                category: Categories.ModularEpisode,
                key: "id",
                value: parentModularEpisode.id,
            });

            return {
                manualLink_parent_episode_number: parentEpisode?.episodeNo,
            };
        },
    },

    parentSeason: {
        deserializeFn: (nuvoRecord: NuvoRecord) => {
            return TvSeriesRelationships.findParentSeason(nuvoRecord);
        },
        serializeFn: (
            parentSeason: AllFieldsVT["parentSeason"],
        ): NuvoParentFields => {
            if (isEmpty(parentSeason)) {
                return {
                    manualLink_parent_season_meta_id: "",
                    manualLink_parent_season_number: null,
                };
            }

            let season: SeasonVT;
            if (isEmpty(parentSeason?.metaId)) {
                // search by title because it should be a local tv show
                season = store.findByKey({
                    category: Categories.Season,
                    key: "title",
                    value: parentSeason?.title,
                });
            }

            if (!isEmpty(parentSeason?.metaId)) {
                // search by metaId because it should be a remote tv show
                season = store.findByKey({
                    category: Categories.Season,
                    key: "metaId",
                    value: parentSeason?.metaId,
                });
            }

            return {
                manualLink_parent_season_meta_id: season?.metaId,
                manualLink_parent_season_number: season?.seasonNo,
            };
        },
    },

    parentShow: {
        deserializeFn: (nuvoRecord: NuvoRecord) => {
            return TvSeriesRelationships.findParentShow(nuvoRecord);
        },
        serializeFn: (parentShow: AllFieldsVT["parentShow"]) => {
            if (isEmpty(parentShow)) {
                return {
                    manualLink_parent_show_meta_id: "",
                    manualLink_parent_show_title: "",
                };
            }

            let show: TvShowVT;
            if (isEmpty(parentShow?.metaId)) {
                // search by title because it should be a local tv show
                show = store.findByKey({
                    category: Categories.Show,
                    key: "title",
                    value: parentShow?.title,
                });
            } else {
                // search by metaId because it should be a remote tv show
                show = store.findByKey({
                    category: Categories.Show,
                    key: "metaId",
                    value: parentShow?.metaId,
                });
            }

            return {
                manualLink_parent_show_meta_id: show?.metaId,
                manualLink_parent_show_title: show?.title,
            };
        },
    },

    provenance: {
        deserializeFn: (nuvoRecord) => {
            return nuvoRecord?.origination?.provenance;
        },
        serializeFn: (provenance) => {
            return provenance;
        },
    },

    seasonNo: {
        deserializeFn: (nuvoRecord: NuvoRecord) => {
            if (nuvoRecord?.manualLink_parent_season_number > 0) {
                return nuvoRecord.manualLink_parent_season_number;
            } else {
                if (!isEmpty(nuvoRecord?.manualLink_parent_season_meta_id)) {
                    // Get from store
                    const season = store.findByKey({
                        category: Categories.Season,
                        key: "metaId",
                        value: nuvoRecord?.manualLink_parent_season_meta_id,
                    });

                    return season?.seasonNo;
                } else {
                    return nuvoRecord?.season_numbering?.season_number;
                }
            }
        },
        serializeFn: (seasonNumber: AllFieldsVT["seasonNo"]) => {
            return seasonNumber;
        },
    },
};

export { serdesFns };
