import {
    StandaloneTicket,
    StandaloneTicketForUpdate,
} from "../../../ticket/shared/validationSchema";

export const adaptToBackendUpdate = (
    ticketId: StandaloneTicket["id"],
    ticket: StandaloneTicket,
): StandaloneTicketForUpdate[] => {
    const {
        assignee: { id: assigneeId },
        name,
        priority: { label: priorityLabel },
        records,
        status: { label: statusLabel },
        description,
    } = ticket;

    const result = [
        {
            assignee: assigneeId,
            name,
            priority: priorityLabel,
            records,
            status: statusLabel,
            id: ticketId,
            description,
        },
    ];

    return result;
};
