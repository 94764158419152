import React from "react";
import Settings from "../../Settings";
import DoubleTransparentTag from "../../DoubleTransparentTag";

const RenderIdDoubleTag = (props) => {
    const { textLeft = "Media ID", textRight, media_id } = props;

    return (
        <DoubleTransparentTag
            textLeft={textLeft}
            textRight={textRight || media_id}
            fontSize={Settings.components.actionButton.text.fontSize}
            leftBorderColor="#737373"
            leftBackgroundColor="transparent"
            leftColor="#737373"
            rightBackgroundColor="#737373"
            rightColor="#d9d9d9"
            fontWeight={500}
            padding="0.1rem 0.3125rem"
        />
    );
};

export default RenderIdDoubleTag;
