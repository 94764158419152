import { CoreTableState } from "../../types";

export function adaptStatusToCoreTableState(status: string) {
    let coreTableState: CoreTableState = "default";
    if (status === "view") {
        coreTableState = "default";
    }
    if (status === "edit") {
        coreTableState = "edit";
    }
    return coreTableState;
}
