const FetchConfig = (props) => {
    const {
        sourceCategory = "",
        targetCategory = "Show",
        dataStructure = {},
    } = props;

    const { recordConverter = [] } = dataStructure;

    return recordConverter.find(
        (item) =>
            (item.targetCategory === targetCategory) &
            (item.sourceCategory === sourceCategory),
    );
};

export default FetchConfig;
