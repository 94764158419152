import { action, extendObservable, makeObservable } from "mobx";
import { FieldValues, UseFormReset } from "react-hook-form";
import { BulkImportConfigInterface } from "../../NuvoFileUploader/nuvo.interface";
import { adaptFromBackend } from "../containers/record/standalone/adapters/adaptFromBackend";
import {
    ChangeAssigneeTicketVT,
    InputFiltersType,
    OutputFiltersType,
} from "../containers/ticket/filters-form/validation/ticketFiltersVS";
import {
    StandaloneTicket,
    StandaloneTicketForUpdate,
} from "../containers/ticket/shared/validationSchema";
import {
    AccountStatus,
    MESSAGE_TICKET_ASSIGNED,
    MESSAGE_TICKET_DELETED,
    MESSAGE_TICKET_PRIORITY_UPDATED,
    MESSAGE_TICKET_STATUS_UPDATED,
    PriorityHigh,
    PriorityLow,
    PriorityMedium,
    PriorityNoPriority,
    StatusApproved,
    StatusAwaitingApproval,
    StatusCancelled,
    StatusChangeInProgress,
    StatusDraft,
    StatusRejected,
    StatusSubmissionInProgress,
    StatusSubmitted,
    StatusSubmissionRejected,
    SupportedTicketTypes,
    TabAllTickets,
    TabArchivedTickets,
    TabMyTickets,
    TicketTypeLabels,
} from "../types/constants";
import { FabricUserType, TaskManagerTab } from "../types/interfaces";
import { TicketStatus } from "../types/interfaces.shared";
import findTicketsByIds from "../utils/findTicketsByIds";
import findUserByName from "../utils/findUserByName";
import { getBulkImportConfig2 } from "../utils/getBulkImportConfig";
import { log } from "../utils/log";
import unassignedUser from "../utils/unassignedUser";
import TaskManagerStoreInterface from "./TaskManagerStoreInterface";
import { RootStore } from "./rootStore";
import findUserByEmail from "../utils/findUserByEmail";

export type InitialStateType = {
    activeTabIndex: number;
    availableTabs: TaskManagerTab[];
    defaultFilterValues: OutputFiltersType;
    filterOptions: InputFiltersType;
    tickets: StandaloneTicket[];
    checkedTableRowIds: number[];
    predefinedFiltersByTabs: {
        [k: string]: OutputFiltersType;
    };
    bulkImportConfig: {
        standalone: BulkImportConfigInterface;
        tvShows: BulkImportConfigInterface;
    };
    isDataLoading: boolean;
    paginationByTabs: {
        [k: string]: { page: number; total: number; limit: number };
    };
};

export const initialState: Readonly<InitialStateType> = {
    tickets: [],
    activeTabIndex: 0,
    defaultFilterValues: {
        assignee: [],
        author: [],
        name: "",
        priority: [],
        status: [],
        type: [],
    },
    availableTabs: [TabMyTickets],
    filterOptions: {
        type: [],
        assignee: [],
        status: [],
        priority: [],
        author: [],
    },
    predefinedFiltersByTabs: {
        [TabAllTickets.label]: {
            author: [],
            name: "",
            assignee: [],
            type: [],
            status: [
                StatusDraft.label,
                StatusAwaitingApproval.label,
                StatusRejected.label,
                StatusChangeInProgress.label,
                StatusSubmissionInProgress.label,
                StatusSubmitted.label,
                StatusSubmissionRejected.label,
            ],
            priority: [],
        },
        [TabMyTickets.label]: {
            status: [
                StatusDraft.label,
                StatusAwaitingApproval.label,
                StatusRejected.label,
                StatusChangeInProgress.label,
                StatusSubmissionInProgress.label,
                StatusSubmitted.label,
                StatusSubmissionRejected.label,
            ],
            author: [],
            type: [],
            priority: [],
            name: "",
            assignee: [],
        },
        [TabArchivedTickets.label]: {
            status: [StatusApproved.label, StatusCancelled.label],
            author: [],
            type: [],
            priority: [],
            name: "",
            assignee: [],
        },
    },
    checkedTableRowIds: [],
    bulkImportConfig: {
        standalone: {
            headers: {
                static: [],
                dynamic: {},
            },
            mandatoryFields: [],
        },
        tvShows: {
            headers: {
                static: [],
                dynamic: {},
            },
            mandatoryFields: [],
        },
    },
    isDataLoading: false,
    paginationByTabs: {
        [TabAllTickets.label]: {
            page: 1,
            total: 0,
            limit: 100,
        },
        [TabMyTickets.label]: {
            page: 1,
            total: 0,
            limit: 100,
        },
        [TabArchivedTickets.label]: {
            page: 1,
            total: 0,
            limit: 100,
        },
    },
};

class TicketsStore implements TaskManagerStoreInterface {
    readonly rootStore: RootStore;

    private activeTab: TaskManagerTab = TabMyTickets;

    private activeTabIndex: number = 0;

    private availableTabs: TaskManagerTab[] = [TabMyTickets];

    filterOptions: InitialStateType["filterOptions"];

    resetFiltersFn: UseFormReset<FieldValues>;

    tickets: StandaloneTicket[];

    checkedTableRowIds: number[];

    bulkImportConfig: InitialStateType["bulkImportConfig"];

    predefinedFiltersByTabs: InitialStateType["predefinedFiltersByTabs"];

    isDataLoading: boolean;

    paginationByTabs: InitialStateType["paginationByTabs"];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            initialize: action,

            changeActiveTab: action,
            deleteTickets: action,
            updateTickets: action,
            changeSelectedTicketsPriority: action,
            changeSelectedTicketsStatus: action,
            deleteSelectedTickets: action,

            setAvailableTabs: action,
            setActiveTabIndex: action,
            setResetFilters: action,
            setTickets: action,
            setTicket: action,
            setFilterOptions: action,
            setCheckedTableRowIds: action,
            setIsDataLoading: action,
            setCurrentPage: action,
        });

        extendObservable(this, initialState);
    }

    async initialize() {
        if (this.rootStore.storesInit["tickets"]) {
            throw new Error(
                `Task Manager tickets Store has already been initialized.`,
            );
        }

        // Nuvo upload config
        this.bulkImportConfig = getBulkImportConfig2();

        const options: InputFiltersType = {
            type: this.getAllowedTypes(),
            assignee: [unassignedUser],
            author: [],
            priority: [
                PriorityHigh,
                PriorityMedium,
                PriorityLow,
                PriorityNoPriority,
            ],
            status: [
                StatusDraft,
                StatusAwaitingApproval,
                StatusApproved,
                StatusRejected,
                StatusChangeInProgress,
                StatusCancelled,
                StatusSubmissionInProgress,
                StatusSubmitted,
                StatusSubmissionRejected,
            ],
        };

        // Get users with TM access

        const allUsersResponse = await this.rootStore.userServiceApi.getUsers({
            type: "taskManager",
        });

        if (allUsersResponse.isError) {
            this.rootStore.handleApiError(allUsersResponse);

            const dummyList = [];
            options.assignee.push(...dummyList);
        } else {
            const allUsers = allUsersResponse.data;

            // Assignees have to be verified users
            const userMapper = (user: FabricUserType) => {
                return {
                    id: user.id,
                    name: user.full_name,
                    email: user.email,
                    accountStatus: user.account_status,
                };
            };
            const verifiedMappedUsers = allUsers.map(userMapper);
            options.assignee.push(...verifiedMappedUsers);

            // Authors can be any user so that we can view tickets created by inactive users
            const allMappedUsers = allUsers.map(userMapper);
            options.author.push(...allMappedUsers);
        }
        this.setFilterOptions(options);

        // Which tabs can the user see
        const allowedTabs = this.rootStore.permissionsService.getAllowedTabs();
        this.availableTabs = allowedTabs;

        // If the user does not have access to My Tickets tab, find the first tab
        if (
            !allowedTabs.map((t) => t.label).includes(TabMyTickets.label) &&
            allowedTabs.length
        ) {
            this.activeTab = allowedTabs[0];
        }

        // Which tab is opened by default
        // Find the index based on the value of activeTab
        this.activeTabIndex = this.availableTabs.findIndex(
            (t) => t.label === this.activeTab.label,
        );

        this.rootStore.finishLoading("tickets");
    }

    // Getters

    getAvailableTabs() {
        return this.availableTabs;
    }

    getActiveTabIndex() {
        return this.activeTabIndex;
    }

    getActiveTab() {
        return this.activeTab;
    }

    getIsDataLoading() {
        return this.isDataLoading;
    }

    getCurrentPage() {
        return this.paginationByTabs[this.activeTab.label].page;
    }

    getCurrentPageLimit() {
        return this.paginationByTabs[this.activeTab.label].limit;
    }

    getCurrentPageTotal() {
        return this.paginationByTabs[this.activeTab.label].total;
    }

    // Setters

    setAvailableTabs(tabs: TaskManagerTab[]) {
        this.availableTabs = Array.from(tabs);
    }

    setActiveTabIndex(tabIndex: number) {
        if (tabIndex < 0 || tabIndex > this.getAvailableTabs().length - 1) {
            log("Invalid tab index called on setActiveTab", tabIndex);
            return;
        }

        this.activeTabIndex = tabIndex;
    }

    setResetFilters(fn: UseFormReset<FieldValues>) {
        this.resetFiltersFn = fn;
    }

    setTickets(tickets: Array<StandaloneTicket>) {
        this.tickets = tickets;
    }

    setTicket(index: number, data: StandaloneTicket) {
        this.tickets[index] = data;
    }

    setFilterOptions(filterOptions: InputFiltersType) {
        this.filterOptions = { ...filterOptions };
    }

    setCheckedTableRowIds(ids: number[]) {
        this.checkedTableRowIds = Array.from(ids);
    }

    setIsDataLoading(loading: boolean) {
        this.isDataLoading = loading;
    }

    // Public API

    setCurrentPage(page: number) {
        this.paginationByTabs[this.activeTab.label].page = page;
    }

    changeActiveTab(newTabIndex: number) {
        if (newTabIndex < 0 || newTabIndex > this.availableTabs.length - 1) {
            log("Invalid tab index called on changeActiveTab", newTabIndex);
            return;
        }

        // Change tab
        this.setActiveTabIndex(newTabIndex);
        this.activeTab = this.getAvailableTabs()[newTabIndex];

        // Reset filters to default state (empty)
        this.resetFilters();
    }

    async deleteTickets(tickets: StandaloneTicket[]) {
        return this.rootStore.taskManagerApi.deleteMulti(
            tickets.map((t) => t.id),
        );
    }

    async updateTickets<K extends keyof StandaloneTicketForUpdate>(
        ticketIds: StandaloneTicket["id"][],
        field: K,
        data: StandaloneTicketForUpdate[K],
    ) {
        return this.rootStore.taskManagerApi.updateFieldMulti<K>(
            ticketIds,
            field,
            data,
        );
    }

    /**
     * Loads a list of tickets from API using filters applied in the UI and (optionally)
     * the filters predefined per tab (such as for Archive tab, only Approved tickets are to be loaded).
     * This is achieved by a custom merging logic between UI filters and predefined tab filters.
     *
     *
     * @param filters
     * @param options options.mergeFilters Whether to merge filters with predefined tab filters
     */
    async getTicketsUsingFilters(
        filters: OutputFiltersType,
        { mergeFilters }: { mergeFilters: boolean },
    ) {
        const isFromMyTicketsTab =
            this.getActiveTab().label === TabMyTickets.label;

        if (mergeFilters) {
            const tabFilters =
                initialState.predefinedFiltersByTabs[this.activeTab.label];
            const f: OutputFiltersType = {
                assignee: filters.assignee ?? [],
                author: filters.author?.length
                    ? filters.author
                    : tabFilters.author,
                priority: filters.priority ?? [],
                status: filters.status?.length
                    ? filters.status
                    : tabFilters.status,
                type: filters.type?.length
                    ? filters.type
                    : this.getAllowedTypes().map((t) => t.label),
                name: filters.name ?? "",
            };

            if (isFromMyTicketsTab) {
                // When not using author and assignee filters we use
                // a more complex query to select tickets
                // "created by" OR "assigned to" the current user
                if (!filters.author.length && !filters.assignee.length) {
                    f.OR = [
                        {
                            author: [this.rootStore.userId],
                        },
                        {
                            assignee: [this.rootStore.userId],
                        },
                    ];
                    delete f.author;
                    delete f.assignee;
                } else if (!filters.author.length) {
                    // In the My Tickets tab the author filter should always include the current user
                    // as long as the user is not using the "Created by" filter
                    f.author = [this.rootStore.userId];
                }
            }

            return this.getAndSaveTicketsWithFilters(f);
        } else {
            return this.getAndSaveTicketsWithFilters(filters);
        }
    }

    async getAndSaveTicketById(id: number) {
        const response = await this.rootStore.taskManagerApi.getTicketById(id);

        if (response.isError) {
            this.rootStore.handleApiError(response);
            return null;
        }

        const [ticket] = adaptFromBackend([response.data]);
        this.setTickets([ticket]);

        return ticket;
    }

    /**
     * Changes priority of the currently selected tickets (used by bulk functionality)
     * by making a backend request, followed by a request to retrieve the new details
     * (basically a refresh) of the selected tickets.
     *
     * Note there is no permission check yet.
     *
     * @param newPriorityLabel New priority label to apply
     */
    async changeSelectedTicketsPriority(
        newPriorityLabel: string,
    ): Promise<void> {
        const response = await this.updateTickets(
            this.checkedTableRowIds,
            "priority",
            newPriorityLabel,
        );

        if (!response.isError) {
            this.rootStore.pushSnackbar(MESSAGE_TICKET_PRIORITY_UPDATED);

            await this.getTicketsByIds(this.checkedTableRowIds);
        } else {
            this.rootStore.handleApiError(response);
        }
    }

    resetFilters = () => {
        // Reset filters
        this.resetFiltersFn(initialState.defaultFilterValues);

        this.setCurrentPage(1);
    };

    /**
     * Attempts to delete currently selected tickets. If user does not have
     * permissions, tickets are not deleted. Otherwise, we delete the tickets,
     * push a notification to the user, and refresh list of tickets of current tab.
     * @param filters Current applied filters for ticket list
     */
    async deleteSelectedTickets(filters: OutputFiltersType): Promise<void> {
        const listOfIds = this.checkedTableRowIds;
        const tickets = findTicketsByIds(this.tickets, listOfIds);
        const isAllowed =
            this.rootStore.permissionsService.isAllowedToDelete(tickets);

        if (!isAllowed) {
            alert("You do not have permission to delete these tickets!");
            return;
        }

        if (confirm("Are you sure?") === true) {
            const response = await this.deleteTickets(tickets);

            if (!response.isError) {
                this.rootStore.pushSnackbar(MESSAGE_TICKET_DELETED);

                await this.getTicketsUsingFilters(filters, {
                    mergeFilters: true,
                });
            } else {
                this.rootStore.handleApiError(response);
            }
        }
    }

    /**
     * Changes status of currently selected tickets if user has permission to do so.
     * After the backend request we reload data by making a new request
     * only for the selected tickets.
     * @param newStatusLabel New status label to apply
     */
    async changeSelectedTicketsStatus(newStatusLabel: string): Promise<void> {
        const listOfIds = this.checkedTableRowIds;
        const tickets = findTicketsByIds(this.tickets, listOfIds);

        const isAllowed =
            this.rootStore.permissionsService.isAllowedToEdit(tickets);

        if (!isAllowed) {
            alert("You do not have permission to edit these tickets!");
            return;
        }

        const response = await this.updateTickets(
            tickets.map((t) => t.id),
            "status",
            newStatusLabel,
        );

        if (!response.isError) {
            this.rootStore.pushSnackbar(MESSAGE_TICKET_STATUS_UPDATED);

            await this.getTicketsByIds(this.checkedTableRowIds);
        } else {
            this.rootStore.handleApiError(response);
        }
    }

    /**
     * Changes assignee of currently selected tickets.
     * After the backend request we reload data by making a new request
     * only for the selected tickets.
     *
     * @param data Data from ChangeAssignee Form
     */
    async changeSelectedTicketsAssignee(data: ChangeAssigneeTicketVT) {
        const user = findUserByEmail(
            this.filterOptions.assignee,
            data.assignee,
        );

        if (!user) {
            return;
        }

        const response = await this.updateTickets(
            this.checkedTableRowIds,
            "assignee",
            user.id,
        );
        if (!response.isError) {
            this.rootStore.pushSnackbar(MESSAGE_TICKET_ASSIGNED);

            await this.getTicketsByIds(this.checkedTableRowIds);
        } else {
            this.rootStore.handleApiError(response);
        }
    }

    /**
     * Returns whether the user is an "admin-like" user
     * which means they have access to features such as the
     * "all tickets" tab.
     *
     * This does not do an exhaustive permissions check
     * but attempts to check whether a user seems to be an
     * Admin (since we don't have an explicit  Admin permission to check).
     */
    isAdminLikeUser() {
        return this.rootStore.permissionsService
            .getAllowedTabs()
            .map((t) => t.label)
            .includes(TabAllTickets.label);
    }

    /**
     * Returns whether the user does not have enough permissions
     * to create any kind of ticket.
     */
    isReadonlyUser() {
        return TicketTypeLabels.reduce((accumulator, label: string) => {
            return (
                accumulator &&
                !this.rootStore.permissionsService.isAllowedToCreate(label)
            );
        }, true);
    }

    isOnArchiveTab() {
        return this.getActiveTab().label === TabArchivedTickets.label;
    }

    getBulkAllowedStatuses(ticketIds: number[]) {
        const tickets = this.findTicketsByIds(ticketIds);

        // We need to find the intersection of allowed statuses
        // Example: if we have 2 tickets, one of type "RecordUpdateRequest" and one of type "RecordCreateRequest",
        // we may have different permissions set for the statuses belonging to each type.
        // So we need to find the intersection of allowed statuses for each ticket.
        // In addition, the Approved status is never allowed because we have a special
        // flow for Ticket Approval. So we just exclude it at the end.
        // NOTE: This only works as long as we have the same status values for each type.

        return tickets
            .reduce((accumulator, ticket) => {
                const allowedStatusesForTicket =
                    this.rootStore.permissionsService.getAllowedStatuses(
                        ticket.type,
                    );

                if (accumulator.length === 0) {
                    return allowedStatusesForTicket;
                }

                return accumulator.filter((status) =>
                    allowedStatusesForTicket.includes(status),
                );
            }, [] as TicketStatus[])
            .filter((status) => status.label !== StatusApproved.label);
    }

    // Private API

    private async getAndSaveTicketsWithFilters(
        filters: OutputFiltersType,
    ): Promise<void> {
        try {
            this.setIsDataLoading(true);

            const response =
                await this.rootStore.taskManagerApi.getTicketsByFilters(
                    filters,
                    this.paginationByTabs[this.activeTab.label],
                );

            if (!response.isError) {
                const data = adaptFromBackend(response.data);

                this.setTickets(data);
                this.paginationByTabs[this.activeTab.label] =
                    response.pagination;

                this.setIsDataLoading(false);
            } else {
                if (this.rootStore.isErrorRecoverable(response)) {
                    this.rootStore.handleRequestCancelled();
                } else {
                    this.rootStore.handleApiError(response);
                    this.setIsDataLoading(false);
                }
            }
        } catch (err) {
            this.setIsDataLoading(false);
        }
    }

    private getAllowedTypes() {
        return SupportedTicketTypes.filter((type) => {
            return this.rootStore.permissionsService.isAllowedToView({
                type,
            });
        });
    }

    private findTicketsByIds(ids: number[]) {
        return this.tickets.filter((t) => ids.includes(t.id));
    }

    private async getTicketsByIds(
        ids: StandaloneTicket["id"][],
    ): Promise<StandaloneTicket[]> {
        return new Promise((resolve) => {
            setTimeout(async () => {
                const response =
                    await this.rootStore.taskManagerApi.getTicketsByIds(ids);

                if (response.isError) {
                    this.rootStore.handleApiError(response);
                    resolve(response.data);
                }

                const data = adaptFromBackend(response.data);
                // eslint-disable-next-line no-restricted-syntax
                for (const t of data) {
                    // eslint-disable-next-line no-plusplus
                    for (let i = 0; i < this.tickets.length; i++) {
                        if (this.tickets[i].id === t.id) {
                            this.setTicket(i, t);
                        }
                    }
                }
                resolve(response.data);
            }, 1000);
        });
    }
}

export default TicketsStore;
