import { isEmpty, isNumber, isString } from "lodash"

const validString = (option: any, defaultReturn: any = null) => {
    const isValidStringType = isString(option) || isNumber(option)
    const stringOption = String(option)

    if (isValidStringType && !isEmpty(stringOption)) {
        return stringOption
    }

    return defaultReturn
}

export default validString 