import Prefixer from "inline-style-prefixer";

export default function styles(props = {}) {
    let styles = {
        plusSign: {
            width: "1.46875rem",
            marginTop: "1rem",
            cursor: "pointer",
            display: "block",
        },
        iconDisabled: {
            opacity: 0.5,
        },
        tableContainer: {
            width: "100%",
        },
        tableContainerDisabled: {
            overflowX: "hidden",
        },
        tableContent: {
            width: "100%",
            transition: "ease-in-out 0.3s",
            transitionDelay: "0.15s",
            position: "relative",
        },
        tableContentMargin: {
            marginLeft: "-5rem",
        },
        tableHiddenColumn: {
            width: "5rem",
            height: "100%",
            position: "absolute",
            top: 0,
            right: 0,
            background: "grey",
            display: "flex",
            alignItems: "center",
        },
        table: {
            width: "100%",
            margin: props.margin || 0,
            display: "block",
        },
        tableDisabled: {
            overflowX: "scroll",
        },
        tableBorderRadius: {
            borderCollapse: "collapse",
            borderRadius: "0.15625rem",
            borderStyle: "hidden",
        },
        tableBorder: {
            border: "0.065rem solid rgb(215, 215, 215)",
        },
        tbody: {
            display: "table",
            width: "100%",
        },
        td: {
            fontSize: "0.90625rem",
            fontWeight: "500",
            color: "rgba(78, 78, 78, 0.87)",
            padding: "0.96875rem 0.59375rem",
        },
        tdExtraPadding: {
            padding: "0.96875rem 1.3125rem",
        },
        tdPaddingRightNone: {
            paddingRight: 0,
        },
        tdNoPadding: {
            padding: "0",
        },
        tdlinkage: {
            width: "3rem",
            background: "white",
            padding: "0",
            height: "3.5rem",
        },
        divLinkage: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            height: "100%",
            width: "100%",
        },
        divLinkageLast: {
            alignItems: "flex-start",
        },
        tdCenter: {
            textAlign: "center",
        },
        tdRight: {
            textAlign: "right",
        },
        tdRightFlex: {
            justifyContent: "flex-end",
        },
        tdColor: {
            color: "white",
        },
        tdLight: {
            fontWeight: 300,
            color: "rgba(78, 78, 78, 0.87)",
        },
        tdNormal: {
            fontWeight: 300,
            color: "rgba(78, 78, 78, 0.87)",
        },
        tdStatus: {
            color: "white",
            textTransform: "uppercase",
            fontSize: "0.90625rem",
            textAlign: "center",
        },
        tableIcon: {
            width: "1.25rem",
            margin: "0 0 0 auto",
            display: "block",
            cursor: "pointer",
        },
        tableInlineIcon: {
            width: "1.25rem",
            display: "block",
            cursor: "pointer",
            margin: "auto",
            transition: "0.3s",
        },
        tableIconMargin: {
            marginLeft: "0.5rem",
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            height: "100%",
        },
        icon: {
            width: "1.09375rem",
            cursor: "pointer",
        },
        flexTableRow: {
            display: "flex",
            width: "100%",
        },
        flexTableRowMargin: {
            marginTop: "1.6875rem",
        },
        flexTableColumn: {
            width: "25%",
            paddingRight: "1.53125rem",
        },
        flexTableColumnLarge: {
            width: "33.3333%",
            paddingRight: "1.53125rem",
        },
        flexTableColumnXLarge: {
            width: "40%",
            paddingRight: "1.53125rem",
        },
        flexSingleRow: {
            width: "100%",
            paddingRight: "1.53125rem",
        },
        label: {
            display: "block",
            padding: 0,
            fontSize: "0.90625rem",
            fontWeight: 500,
            lineHeight: 1,
            color: "rgba(110, 110, 110, 0.87)",
        },
        newRowContainer: {
            width: "100%",
            background: "#f6f6f6",
            padding: "0.71875rem",
            borderBottomLeftRadius: "0.3rem",
            borderBottomRightRadius: "0.3rem",
        },
        categoryIcon: {
            width: "2.25rem",
            marginRight: "0.65625rem",
        },
        originalTitle: {
            color: "#696969",
        },
        verticalLine: {
            background: "#acacac",
            height: "100%",
            width: "0.125rem",
        },
        verticalLineLast: {
            height: "50%",
        },
        verticalLineLastreverted: {
            height: "100%",
        },
        horizontalLine: {
            background: "#acacac",
            height: "0.125rem",
            width: "1.46875rem",
        },
        horizontalLineLast: {
            marginTop: "1.63rem",
        },
        flexContent: {
            display: "flex",
            alignItems: "center",
            width: "100%",
        },
        link: {
            cursor: "pointer",
        },
        originalTitleLinkage: {
            cursor: "pointer",
            width: "100%",
            maxWidth: "25rem",
            overflow: "hidden",
            display: "inline-block",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
        logoBrandingContainer: {
            display: "flex",
            alignItems: "center",
            marginRight: "5.0625rem",
        },
        logoBrandingText: {
            marginRight: "0.71875rem",
            fontSize: "0.78125rem",
            color: "rgba(0, 0, 0, 0.87)",
        },
        linkageTitle: {
            margin: "0 0.65625rem 0 0",
            lineHeight: 1,
            display: "inherit",
            color: "rgba(47, 47, 47, 0.87)",
        },
    };

    let prefixer = new Prefixer();
    styles = prefixer.prefix(styles);

    return styles;
}
