import { LanguageInterface } from "../../../../models/ClientManaged/languagesInterface"
import { ContributorInterface } from "../../../Preferences/Contributors/interfaces"
import { CreditInterface } from "../store/interfaces"
import { CreditListInterface } from "../store/interfaces"
import { CmsInterface } from "../../../../components/Global/interfaces"
import { globalStateInterface } from "../../../../components/Global/interfaces"
import { RecordInterface } from "../../../../models/Record/model"

export const castAndCrewLanguages: LanguageInterface[] = [
    {
        id: 1,
        name: 'English Latin',
        meta_code: 'ENG',
        value: 'ENG',
        text: 'English Latin',
    },
    {
        id: 2,
        name: 'Japanese',
        meta_code: 'JPN',
        value: 'JPN',
        text: 'Japanese',
    }
]

export const creditLists: CreditListInterface[] = [
    {
        id: 'CRE0001',
        code: 'ORG',
        name: 'Original Credit List'
    },
    {
        id: 'CRE0002',
        code: 'ALT',
        name: 'Alternative Credit List'
    }
]

export const creditsByRecord: CreditInterface[] = [
    {
        id: "1",
        contributor_id: "CON00001",
        role_id: "1",
        notes: "a note",
        localised_data: [
            {
                locale: "ENG",
                character_names: [
                    {
                        name: "Davy Jones"
                    }
                ]
            },
            {
                locale: "ARB",
                character_names: [
                    {
                        name: "Davy Jones ARB"
                    }
                ]
            }
        ]
    },
    {
        id: "2",
        contributor_id: "CON00003",
        role_id: "1",
        notes: "a note",
        localised_data: [
            {
                locale: "ENG",
                character_names: [
                    {
                        name: "Fake Davy Jones"
                    }
                ]
            }
        ]
    },
    {
        id: "3",
        contributor_id: "CON00004",
        role_id: "1",
        notes: "a note",
        localised_data: [
            {
                locale: "ENG",
                character_names: [
                    {
                        name: "Fake Davy Jones"
                    }
                ]
            }
        ]
    },
    {
        id: "4",
        contributor_id: "CON00002",
        role_id: "2",
        notes: "a note",
        localised_data: [
            {
                locale: "ENG",
                character_names: [
                    {
                        name: "Fake Davy Jones"
                    }
                ]
            }
        ]
    },
    {
        id: "5",
        contributor_id: "CON00005",
        role_id: "1",
        notes: "a note",
        localised_data: [
            {
                locale: "ENG",
                character_names: [
                    {
                        name: "Inactive Davy Jones (Deleted)",
                        deleted: true
                    }
                ]
            }
        ]
    },
    {
        id: "6",
        contributor_id: "CON00006",
        role_id: "2",
        notes: "a note",
        localised_data: [
            {
                locale: "ENG",
                character_names: [
                    {
                        name: "Inactive Jack Sparrow"
                    }
                ]
            }
        ]
    },
    {
        id: "7",
        contributor_id: "CON00007",
        role_id: "3",
        notes: "a note",
        localised_data: [
            {
                locale: "ENG",
                character_names: [
                    {
                        name: "Inactive Davy Jones"
                    }
                ]
            }
        ]
    }
]

export const creditsResponse: any = {
    'CRE0001': [
        {
            ...creditsByRecord[0],
            ranking: 1
        },
        {
            ...creditsByRecord[1],
            ranking: 2
        },
        {
            ...creditsByRecord[2],
            ranking: 3
        },
        {
            ...creditsByRecord[3],
            ranking: 1
        
        }
    ],
    'CRE0002': [
        {
            ...creditsByRecord[0],
            ranking: 1
        },
        {
            ...creditsByRecord[1],
            ranking: 2
        },
        {
            ...creditsByRecord[2],
            ranking: 3
        },
        {
            ...creditsByRecord[4],
            ranking: 4
        },
        {
            ...creditsByRecord[5],
            ranking: 2
        },
        {
            ...creditsByRecord[6],
            ranking: 1
        }
    ]
}

export const creditsTable: {credits: CreditInterface[], contributors: ContributorInterface[]} = {
    credits: [
        {
            id: "1",
            contributor_id: "1",
            role_id: "1",
            notes: "a note",
            localised_data: [
                {
                    locale: "ENG",
                    character_names: [
                        {
                            name: "Davy Jones"
                        }
                    ]
                },
                {
                    locale: "ARB",
                    character_names: [
                        {
                            name: "Davy Jones ARB"
                        }
                    ]
                }
            ],
            created_at: "2024-04-09T12:17:32.870Z",
            updated_at: "2024-04-09T12:17:32.870Z",
            created_by: "Test User",
            updated_by: "Test User",
            ranking: 1
        },
        {
            id: "4",
            contributor_id: "4",
            role_id: "1",
            notes: "a note",
            localised_data: [
                {
                    locale: "ENG",
                    character_names: [
                        {
                            name: "Fake Davy Jones"
                        }
                    ]
                }
            ],
            created_at: "2024-04-09T12:17:32.870Z",
            updated_at: "2024-04-09T12:17:32.870Z",
            created_by: "Test User",
            updated_by: "Test User",
            ranking: 2
        },
        {
            id: "2",
            contributor_id: "2",
            role_id: "2",
            notes: "a note",
            localised_data: [
                {
                    locale: "ENG",
                    character_names: [
                        {
                            name: "Fake Davy Jones"
                        }
                    ]
                }
            ],
            created_at: "2024-04-09T12:17:32.870Z",
            updated_at: "2024-04-09T12:17:32.870Z",
            created_by: "Test User",
            updated_by: "Test User",
            ranking: 1
        },
        {
            id: "3",
            contributor_id: "3",
            role_id: "3",
            notes: "a note",
            localised_data: [
                {
                    locale: "ENG",
                    character_names: [
                        {
                            name: "Will Turner"
                        }
                    ]
                }
            ],
            created_at: "2024-04-09T12:17:32.870Z",
            updated_at: "2024-04-09T12:17:32.870Z",
            created_by: "Test User",
            updated_by: "Test User",
            ranking: 1
        },
        {
            id: "5",
            contributor_id: "5",
            role_id: "1",
            notes: "a note",
            localised_data: [
                {
                    locale: "ENG",
                    character_names: [
                        {
                            name: "Inactive Davy Jones (Deleted)",
                            deleted: true
                        }
                    ]
                }
            ],
            created_at: "2024-04-09T12:17:32.870Z",
            updated_at: "2024-04-09T12:17:32.870Z",
            created_by: "Test User",
            updated_by: "Test User",
            ranking: null,
            inactive: true
        },
        {
            id: "6",
            contributor_id: "6",
            role_id: "2",
            notes: "a note",
            localised_data: [
                {
                    locale: "ENG",
                    character_names: [
                        {
                            name: "Inactive Jack Sparrow"
                        }
                    ]
                }
            ],
            created_at: "2024-04-09T12:17:32.870Z",
            updated_at: "2024-04-09T12:17:32.870Z",
            created_by: "Test User",
            updated_by: "Test User",
            ranking: null,
            inactive: true
        },
        {
            id: "7",
            contributor_id: "7",
            role_id: "3",
            notes: "a note",
            localised_data: [
                {
                    locale: "ENG",
                    character_names: [
                        {
                            name: "Inactive Davy Jones"
                        }
                    ]
                }
            ],
            created_at: "2024-04-09T12:17:32.870Z",
            updated_at: "2024-04-09T12:17:32.870Z",
            created_by: "Test User",
            updated_by: "Test User",
            ranking: null,
            inactive: true
        }
    ],
    contributors: [
        {
            id: "CON00001",
            localised_metadata: [
                {
                    locale: "ENG",
                    full_name: "Bill Nighy",
                }
            ],
            roles: [
                "Director",
                "Writer",
                "Marketing",
                "Actor"
            ],
            types: [
                "Cast"
            ],
            ids: {
                imdb_id: "nm0461136",
            }
        },
        {
            id: "CON00002",
            localised_metadata: [
                {
                    locale: "ENG",
                    full_name: "Fake Bill Nighy",
                }
            ],
            roles: [
                "Director",
                "Writer",
                "Marketing",
                "Actor"
            ],
            types: [
                "Cast"
            ],
            ids: {
                imdb_id: "nm0461137",
            }
        },
        {
            id: "CON00005",
            localised_metadata: [
                {
                    locale: "ENG",
                    full_name: "Inactive Bill Nighy",
                }
            ],
            roles: [
                "Director",
                "Writer",
                "Marketing",
                "Actor"
            ],
            types: [
                "Cast"
            ],
            ids: {
                imdb_id: "nm0461123",
            }
        },
        {
            id: "CON00007",
            localised_metadata: [
                {
                    locale: "ENG",
                    full_name: "Inactive Bill Nighy 2",
                }
            ],
            roles: [
                "Director",
                "Writer",
                "Marketing",
                "Actor",
                "Editor"
            ],
            types: [
                "Cast"
            ],
            ids: {
                imdb_id: "nm0461103",
            }
        },
        {
            id: "CON00004",
            localised_metadata: [
                {
                    locale: "ENG",
                    full_name: "Johnny Depp",
                }
            ],
            roles: [
                "Director",
                "Writer",
                "Marketing",
                "Actor"
            ],
            types: [
                "Cast"
            ],
            ids: {
                imdb_id: "nm0562112",
            }
        },
        {
            id: "CON00006",
            localised_metadata: [
                {
                    locale: "ENG",
                    full_name: "Inactive Johnny Depp",
                }
            ],
            roles: [
                "Director",
                "Writer",
                "Marketing",
                "Actor"
            ],
            types: [
                "Cast"
            ],
            ids: {
                imdb_id: "nm0562185",
            }
        },
        {
            id: "CON00003",
            localised_metadata: [
                {
                    locale: "ENG",
                    full_name: "Orlando Bloom",
                }
            ],
            roles: [
                "Director",
                "Writer",
                "Marketing",
                "Actor",
                "Editor"
            ],
            types: [
                "Cast"
            ],
            ids: {
                imdb_id: "nm0089217",
            }
        }
    ]
}

export const cmsData: CmsInterface = {
    clientFeatures: {
        userRoleManagement: true
    }
}

export const superAdminGlobalState: globalStateInterface = {
    cmsData,
    userPermissions: [
        {
            catalog: {
                epix: {
                    record: {
                        category: {
                            Feature: {
                                _p: 15,
                                tab: {
                                    credits: {
                                        _p: 15,
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    ]
}

export const roles = [
    {
        api_only: 0,
        character_name: 1,
        colour_code: "#d60c0c",
        created_at: "2021-10-19 19:49:44",
        deleted_at: null,
        id: 1,
        name: "Actor",
        order: 1,
        type: "Cast",
        updated_at: "2023-09-19 13:25:44",
    },
    {
        api_only: 0,
        character_name: 1,
        colour_code: "#d60c0c",
        created_at: "2021-10-19 19:49:44",
        deleted_at: null,
        id: 2,
        name: "Director",
        order: 2,
        type: "Cast",
        updated_at: "2023-09-19 13:25:44",
    },
    {
        api_only: 0,
        character_name: 1,
        colour_code: "#d60c0c",
        created_at: "2021-10-19 19:49:44",
        deleted_at: null,
        id: 3,
        name: "Editor",
        order: 3,
        type: "Cast",
        updated_at: "2023-09-19 13:25:44",
    },
]

export const createOnlyGlobalState: globalStateInterface = {
    cmsData,
    userPermissions: [
        {
            catalog: {
                epix: {
                    record: {
                        category: {
                            Feature: {
                                _p: 8,
                                tab: {
                                    credits: {
                                        _p: 8,
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    ]
}

export const deleteOnlyGlobalState: globalStateInterface = {
    cmsData,
    userPermissions: [
        {
            catalog: {
                epix: {
                    record: {
                        category: {
                            Feature: {
                                _p: 2,
                                tab: {
                                    credits: {
                                        _p: 2,
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    ]
}

export const updateOnlyGlobalState: globalStateInterface = {
    cmsData,
    userPermissions: [
        {
            catalog: {
                epix: {
                    record: {
                        category: {
                            Feature: {
                                _p: 4,
                                tab: {
                                    credits: {
                                        _p: 4,
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    ]
}

export const viewOnlyGlobalState: globalStateInterface = {
    cmsData,
    userPermissions: [
        {
            catalog: {
                epix: {
                    record: {
                        category: {
                            Feature: {
                                _p: 1,
                                tab: {
                                    credits: {
                                        _p: 1,
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    ]
}

export const recordData: RecordInterface = {
    meta_id: 'FE00001'
}