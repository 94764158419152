import update from "react-addons-update";

const HandleCollectionTitleSelectionShowSpecial = (props) => {
    const { item = {}, state = {} } = props;

    const { collectionTitle = {} } = state;

    const { selectedShowSpecials = [] } = collectionTitle;

    const { selected, record } = item;

    let updatedState = selectedShowSpecials;

    // Add
    if (selected && record) {
        updatedState = update(selectedShowSpecials, { $push: [record] });
    }

    // Remove
    if (!selected && record) {
        const recordIndex = _.findIndex(selectedShowSpecials, function (rc) {
            return rc.meta_id == record.meta_id;
        });

        updatedState = update(selectedShowSpecials, {
            $splice: [[recordIndex, 1]],
        });
    }

    state.setCollectionTitleKey("selectedShowSpecials", updatedState);
    state.setCollectionTitleKey("selectedAllShowSpecials", false);
};

export default HandleCollectionTitleSelectionShowSpecial;
