import React from "react";
import PreFrameworkBoilerplateView from "./views/PreFrameworkBoilerplateView";
import ErrorHandler from "../../components/ErrorHandler";

export default class AbstractPreFrameworkBoilerplate extends React.Component {
    /**
     * AbstractPreFrameworkBoilerplate
     *
     * @param object props
     * @return false
     */
    constructor(props) {
        super(props);

        /**
         * @var object
         */
        this.attributes = {};
    }

    /**
     * Runs methods on component mount
     *
     * @return false
     */
    componentWillMount() {
        this.setAttributes();
    }

    /**
     * Catches errors
     *
     * @param object error
     * @param string info
     * @return false
     */
    componentDidCatch(error, info) {
        ErrorHandler(error, info);
    }

    /**
     * Renders DOM elements
     *
     * @return DOM elements
     */
    render() {
        return (
            <PreFrameworkBoilerplateView
                children={this.props.children}
                environmentText={
                    this.attributes.environmentText ||
                    this.props.environmentText
                }
                width={this.props.width || false}
                background={this.props.background || false}
                contentBackground={this.props.contentBackground || false}
                animate={this.props.animate}
            />
        );
    }
}
