export const testRoles = [
    {
        id: 1,
        name: "Actor",
        type: "Cast",
        colour_code: null,
        character_name: 1,
        api_only: 0,
        order: 99999,
        created_at: "2020-12-04 17:41:45",
        updated_at: "2020-12-04 17:41:45",
        order: 3
    },
    {
        id: 2,
        name: "Director",
        type: null,
        colour_code: null,
        character_name: 0,
        api_only: 0,
        order: 99999,
        created_at: "2020-12-04 17:41:45",
        updated_at: "2020-12-04 17:41:45",
        order: 1
    },
    {
        id: 3,
        name: "Voice",
        type: null,
        colour_code: null,
        character_name: 1,
        api_only: 0,
        order: 99999,
        created_at: "2020-12-04 17:41:45",
        updated_at: "2020-12-04 17:41:45",
        order: 2,
    },
    {
        id: 4,
        name: "Voice Over",
        type: null,
        colour_code: null,
        character_name: 1,
        api_only: 0,
        order: 99999,
        created_at: "2020-12-04 17:41:45",
        updated_at: "2020-12-04 17:41:45",
        order: 4
    }
]