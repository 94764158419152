import { GridRowOrderChangeParams } from "@mui/x-data-grid-pro";
import _ from "lodash";
import { TableCreditRow } from "../types/CastCrew.type";
import { createIndexes as createIndexesFromRoles } from "./createIndexes";
import { recreateRankings } from "./recreateRankings";

export function changeRowOrder(
    rows: TableCreditRow[],
    updateRowPosition: (
        oldIndex: number,
        targetIndex: number,
        rowsArray: TableCreditRow[],
    ) => TableCreditRow[],
    onReorder: (data: TableCreditRow[]) => void,
    setRows,
) {
    return (params: GridRowOrderChangeParams) => {
        const { row, oldIndex, targetIndex } = params;

        const roles = rows.map((rowParam) => rowParam?.role);
        const indexes = createIndexesFromRoles(roles);
        const rowRole = row?.role;
        const rowRoleIndexes = indexes[rowRole]?.map((elem) =>
            parseInt(elem, 10),
        );
        let newTargetIndex = -1;

        const isValidIndex = () => rowRoleIndexes?.includes(targetIndex);

        if (isValidIndex()) {
            newTargetIndex = targetIndex;
        } else {
            const maxRoleIndex = _.max(rowRoleIndexes) || 0;
            const minRoleIndex = _.min(rowRoleIndexes) || 0;
            newTargetIndex =
                targetIndex > maxRoleIndex ? maxRoleIndex : minRoleIndex;

            if (rowRoleIndexes == null || rowRoleIndexes?.length === 1) {
                newTargetIndex = oldIndex;
            }
        }

        const updatedRows = updateRowPosition(oldIndex, newTargetIndex, rows);
        const newRows = recreateRankings(updatedRows);
        onReorder(newRows);
        setRows(newRows);
    };
}
