import React from "react";
import "./styles/_styles.scss";
import KeywordLabel from "../KeywordLabel";
import _ from "lodash";
import NumberFormat from "react-number-format";
import GetColorsFromSpectrum from "./src/GetColorsFromSpectrum";

const SentimentPopularity = (props) => {
    const { data = {}, styles = {} } = props;

    const RenderFormattedNumber = (value) => {
        return (
            <NumberFormat
                value={value}
                displayType={"text"}
                thousandSeparator={true}
            />
        );
    };

    if (!_.isEmpty(data) && typeof data == "object") {
        const dataArray = Object.keys(data).map((key) => ({
            title: key,
            value: data[key],
        }));
        const sortedArray = dataArray.sort(
            (a, b) => parseFloat(b.value) - parseFloat(a.value),
        );
        const first = sortedArray[0];

        return (
            <div className="sentimental-popularity" style={styles}>
                {sortedArray.map((item, index) => {
                    const value = item.value;
                    const colors = GetColorsFromSpectrum({
                        value,
                        first: first.value,
                    });

                    return (
                        <KeywordLabel
                            title={item.title}
                            weighting={RenderFormattedNumber(value)}
                            margin="0.84375rem 0.84375rem 0 0"
                            background={colors.background}
                            color={colors.color}
                            weightingBackground={colors.weightBackground}
                            weightColor={colors.weightColor}
                            textTransform="capitalize"
                            key={index}
                        />
                    );
                })}
            </div>
        );
    }
};

export default SentimentPopularity;
