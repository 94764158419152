import React from "react";
import DoubleTags from "../../DoubleTags";

const RenderShowTags = (show) => {
    if (show) {
        return (
            <DoubleTags
                leftText="Show"
                rightText={show}
                rightTextTransparent={true}
                className="meta-numbered-record-table--tags"
            />
        );
    }

    return false;
};

export default RenderShowTags;
