import UpdateLocalisations from "./UpdateLocalisations";
import AddNewLocalisation from "./AddNewLocalisation";

function Localisation() {
    return {
        updateLocalisations: (data, callback) =>
            UpdateLocalisations({ data, callback, state: this.state }),
        addNewLocalisation: (data, callback) =>
            AddNewLocalisation({ data, callback, state: this.state }),
    };
}

export default Localisation;
