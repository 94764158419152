import _ from 'lodash'
import deepcopy from 'deepcopy'
import update from 'react-addons-update'

const UpdateScripts = options => {
    const {
        key,
        value,
        script,
        item = {}
    } = options

    const scriptedKey = key == 'name' ? 'script_names' : 'script_character_names'
    let scriptedValues = [...item[scriptedKey] || []]

    const scriptIndex = _.findIndex(scriptedValues, function(item) {
        return item.code == script
    })

    const scriptText = value.text || value

    // update state
    const getUpdatedItem = updatedScripts => {
        let itemCopy = deepcopy(item)

        if(!itemCopy[scriptedKey]) {
            itemCopy[scriptedKey] = []
        }
        itemCopy[scriptedKey] = updatedScripts
        return itemCopy
    }

    // update
    let updatedScripts = []
    if(scriptIndex !== -1) {
        let scriptItem = deepcopy(scriptedValues[scriptIndex])
        scriptItem[key] = scriptText

        updatedScripts = update(scriptedValues, {[scriptIndex]: {
                $set: scriptItem
            }})
    } else {
        // create script
        const scriptItem = {
            [key]: scriptText,
            code: script
        }

        updatedScripts = update(scriptedValues, {$push: [scriptItem]})
    }

    return getUpdatedItem(updatedScripts)
}

export default UpdateScripts