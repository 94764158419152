import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import styles from "./styles";
import Settings from "../Settings";
import DOMPurify from "isomorphic-dompurify";

export default class Notification extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingIcon: {},
            displayRequirement: false,
        };

        this.componentIsMounted = false;
    }

    componentWillMount() {
        window.addEventListener("keypress", this.handleKeyPress.bind(this));
    }

    componentDidMount() {
        this.componentIsMounted = true;
        this.loadImage("loadingIcon", "loading-white.svg");
    }

    componentWillUnmount() {
        this.componentIsMounted = false;

        window.removeEventListener("keypress", this.handleKeyPress.bind(this));
    }

    loadImage(stateKey, name) {
        let image = new Image();
        image.src = Settings.images.path + "/svg/" + name;

        image.onload = () => {
            if (this.componentIsMounted) {
                this.setState({
                    [stateKey]: image,
                });
            }
        };
    }

    handleClick(e) {
        const {
            loading,
            disabled,
            confirm,
            cancelClick,
            closeOnWrapperClick = true,
        } = this.props;

        let canCancel = true;
        if (disabled && loading) {
            canCancel = false;
        }

        if (e && e.target) {
            if (
                e.target.id == "meta-notification-wrapper" &&
                closeOnWrapperClick
            ) {
                this.handleClose();
            }

            if (typeof cancelClick == "function") {
                if (
                    e.target.id == "meta-notification-interaction-cancel" &&
                    !disabled
                ) {
                    cancelClick();
                    this.setState({ displayRequirement: false });

                    return;
                }
            }

            if (typeof confirm == "function") {
                if (
                    e.target.id == "meta-notification-interaction" &&
                    !disabled
                ) {
                    confirm();
                    this.setState({ displayRequirement: false });
                }

                if (
                    e.target.id == "meta-notification-interaction" &&
                    disabled &&
                    !this.props.errorMessage
                ) {
                    this.setState({ displayRequirement: true });
                }

                if (
                    e.target.id == "meta-notification-interaction-cancel" &&
                    canCancel
                ) {
                    this.handleClose();
                }
            }

            if (!confirm) {
                if (
                    e.target.id == "meta-notification-interaction" &&
                    canCancel
                ) {
                    this.handleClose();
                }
            }
        }
    }

    handleKeyPress(e) {
        const { confirmOnEnter, disabled, confirm } = this.props;

        if (typeof e == "object" && typeof confirm == "function") {
            if (
                (e.code == "Enter" || e.which == 13) &&
                confirmOnEnter &&
                !disabled
            ) {
                confirm();
            }
        }
    }

    handleClose() {
        if (typeof this.props.onClick === "function") {
            this.props.onClick();
        }
        this.setState({ displayRequirement: false });
    }

    getActiveClassName() {
        let result = "";

        if (this.props.title && (this.props.description || this.props.html)) {
            result = "active";
        }

        return result;
    }

    shouldDisplay() {
        let result = false;

        if (this.props.title && (this.props.description || this.props.html)) {
            result = true;
        }

        if (this.props.html) {
            result = true;
        }

        return result;
    }

    renderRequirement(classStyles) {
        const { requirementText } = this.props;

        return (
            <div
                style={Object.assign(
                    {},
                    classStyles.errorContainerDefault,
                    (requirementText || this.props.errorMessageHtml) &&
                        classStyles.errorContainer,
                )}
                id="meta-notification-error-message"
            >
                {requirementText || ""}

                {this.props.errorMessageHtml && this.props.errorMessageHtml}
            </div>
        );
    }

    renderCustomMiddleButton(classStyles) {
        const { customMiddleButton = {} } = this.props;

        if (Array.isArray(customMiddleButton)) {
            return customMiddleButton
                .map((buttonData, index) =>
                    this.renderCustomButton(buttonData, classStyles, index),
                )
                .filter((button) => !!button)
                .map((button, index) => {
                    return (
                        <React.Fragment
                            key={`meta-notification-custom-middle-button-${index}`}
                        >
                            {button}
                        </React.Fragment>
                    );
                });
        }

        return this.renderCustomButton(customMiddleButton, classStyles);
    }

    renderCustomButton(customButtonObj, classStyles, index = 0) {
        const {
            id,
            text = null,
            disabled = false,
            className = "",
            onClick = () => {},
        } = customButtonObj;

        const handleCustomeClick = () => {
            if (!disabled) {
                onClick();
            }
        };

        if (text) {
            return (
                <a
                    style={Object.assign(
                        {},
                        classStyles.action,
                        classStyles.actionConfirm,
                        disabled && classStyles.disabled,
                    )}
                    className={`meta-notification-interaction-custom ${className}`}
                    id={id}
                    key={index}
                    onClick={handleCustomeClick.bind(this)}
                >
                    {text}
                </a>
            );
        }
    }

    renderOkTextLoadingIcon(classStyles) {
        const {
            loading,
            disabled,
            interactionButtonClassName,
            okText,
            intercationStatus,
        } = this.props;

        const { loadingIcon } = this.state;

        if (loading && loadingIcon.src) {
            return (
                <img
                    src={loadingIcon.src}
                    alt="loading.."
                    style={classStyles.loading}
                />
            );
        }

        return (
            <a
                style={Object.assign(
                    {},
                    classStyles.action,
                    disabled && classStyles.disabled,
                    intercationStatus == "imdb" &&
                        classStyles.actionConfirmImdb,
                    intercationStatus == "enrichment" &&
                        classStyles.actionConfirmEnrichment,
                )}
                className={interactionButtonClassName}
                id="meta-notification-interaction"
                data-testid={`${this.props.title}-meta-notification-interaction-ok`}
            >
                {okText || "Ok"}
            </a>
        );
    }

    render() {
        const classStyles = styles({
            ...this.props,
            display: this.shouldDisplay(),
        });

        const activeClassName = this.getActiveClassName();

        return (
            <Scrollbars
                style={classStyles.scrollbars}
                className={this.props.wrapperClassName}
                id="meta-notification"
            >
                <div
                    className={`${activeClassName} ${this.props.className}`}
                    onClick={this.handleClick.bind(this)}
                    id="meta-notification-wrapper"
                    style={classStyles.container}
                >
                    <div
                        style={Object.assign(
                            {},
                            classStyles.wrapper,
                            this.props.wrapperStyle && this.props.wrapperStyle,
                        )}
                        className="react-fade-in"
                    >
                        <div style={classStyles.contentWrapper}>
                            <div
                                style={Object.assign(
                                    {},
                                    classStyles.content,
                                    this.props.background && {
                                        background: this.props.background,
                                    },
                                    this.props.contentNoPadding &&
                                        classStyles.contentNoPadding,
                                    this.props.centralised &&
                                        classStyles.contentCentralised,
                                )}
                            >
                                {this.props.image && (
                                    <img
                                        src={this.props.image}
                                        alt={this.props.title}
                                        style={classStyles.image}
                                    />
                                )}

                                <span
                                    id="meta-notification--title"
                                    style={Object.assign(
                                        {},
                                        classStyles.title,
                                        this.props.titlePadding &&
                                            classStyles.titlePadding,
                                    )}
                                >
                                    {this.props.title}
                                </span>

                                {this.props.description && (
                                    <div
                                        id="meta-notification--description"
                                        style={classStyles.description}
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                                this.props.description || "",
                                            ),
                                        }}
                                    ></div>
                                )}

                                {this.props.html && this.props.html}
                            </div>

                            <div
                                style={Object.assign(
                                    {},
                                    classStyles.errorContainerDefault,
                                    (this.props.errorMessage ||
                                        this.props.errorMessageHtml) &&
                                        classStyles.errorContainer,
                                )}
                                id="meta-notification-error-message"
                            >
                                {this.props.errorMessage || ""}

                                {this.props.errorMessageHtml &&
                                    this.props.errorMessageHtml}
                            </div>

                            {this.state.displayRequirement &&
                                this.renderRequirement(classStyles)}

                            {this.props.intercationStatus && (
                                <div
                                    style={Object.assign(
                                        {},
                                        classStyles.interaction,
                                        this.props.intercationStatus ==
                                            "error" &&
                                            classStyles.interactionError,
                                        this.props.intercationStatus ==
                                            "success" &&
                                            classStyles.interactionSuccess,
                                        this.props.intercationStatus ==
                                            "eidr" &&
                                            classStyles.interactionEidr,
                                        this.props.intercationStatus ==
                                            "imdb" &&
                                            classStyles.interactionImdb,
                                        this.props.intercationStatus ==
                                            "enrichment" &&
                                            classStyles.interactionEnrichment,
                                    )}
                                    id="meta-notification-interaction-bar"
                                >
                                    {this.props.confirm && (
                                        <a
                                            style={Object.assign(
                                                {},
                                                classStyles.action,
                                                classStyles.actionConfirm,
                                                this.props.disabled &&
                                                    this.props.loading &&
                                                    classStyles.disabled,
                                                this.props.intercationStatus ==
                                                    "imdb" &&
                                                    classStyles.actionConfirmImdb,
                                                this.props.intercationStatus ==
                                                    "enrichment" &&
                                                    classStyles.actionConfirmEnrichment,
                                            )}
                                            id="meta-notification-interaction-cancel"
                                            data-testid={`${this.props.title}-meta-notification-interaction-cancel`}
                                        >
                                            {this.props.cancelText || "Cancel"}
                                        </a>
                                    )}

                                    {this.renderCustomMiddleButton(classStyles)}
                                    {this.renderOkTextLoadingIcon(classStyles)}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Scrollbars>
        );
    }
}
