import React, { useState, useRef } from "react";
import Settings from "../Settings";
import "./styles/_styles.scss";

const QuickView = (props) => {
    const {
        disabled,
        onChange = () => {},
        lockedBackground = "#0c4d43",
    } = props;

    const image = disabled ? "glasses-light-grey" : "glasses-white";
    const [lock, setLock] = useState(false);

    let textStyles = {
        ...Settings.components.actionButton.text,
    };

    if (disabled) {
        textStyles = {
            ...Settings.components.actionButton.text,
            ...Settings.components.actionButton.textDisabled,
        };
    }

    const handleClick = (e) => {
        e.stopPropagation();
        setLock(!lock);
        onChange(!lock);
    };

    return (
        <div
            className={`
				quick-view 
				${disabled ? "disabled" : ""}
			`}
            style={{
                ...Settings.components.actionButton.container,
                background: lock ? lockedBackground : "#626262",
            }}
            onClick={handleClick.bind(this)}
        >
            <span style={textStyles}>Quick View</span>

            <img src={`${Settings.images.path}/svg/${image}.svg`} />
        </div>
    );
};

export default QuickView;
