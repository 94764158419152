import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { BooleanPreviewSVG } from "./BooleanPreview.svg";
import { FreeTextPreviewSVG } from "./FreeTextPreview.svg";
import { CoreSelectOptionType } from "../../../../../../../../core/Form/components/inputs/Select/types/CoreSelectOptionType";

export type CoreSelectListItemPreviewProps = {
    image: React.ReactNode;
    text: string;
};
export const CoreSelectListItemPreview = ({
    image,
    text,
}: CoreSelectListItemPreviewProps) => {
    return (
        <Stack
            direction="column"
            rowGap="0.625rem"
            sx={{
                padding: "0.5rem",
                backgroundColor: "rgba(28, 44, 96, 1)",
                borderRadius: "0.1875rem",
                maxWidth: "9.75rem",
            }}
        >
            <Box
                justifyContent="center"
                sx={{
                    background: "white",
                    borderRadius: "0.1875rem",
                    width: "100%",
                    paddingY: "0.6875rem",
                    paddingX: "1.575rem",
                }}
            >
                {image}
            </Box>

            <Typography>{text}</Typography>
        </Stack>
    );
};

// ! DO NOT CHANGE THE ORDER IN ARRAY
export const booleanOptions: CoreSelectOptionType[] = [
    {
        label: "Toggle Switch",
        value: "ToggleSwitch",
        tooltip: (
            <CoreSelectListItemPreview
                image={<BooleanPreviewSVG />}
                text="Turn on/off mutually exclusive options."
            />
        ),
    },
    {
        label: "Radio Button",
        value: "RadioButton",
        tooltip: (
            <CoreSelectListItemPreview
                image={<FreeTextPreviewSVG />}
                text="Select a single radio button by default."
            />
        ),
    },
];
