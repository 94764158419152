import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    AccordionDetails,
    AccordionProps,
    AccordionSummary,
    Box,
    Accordion as MuiAccordion,
    Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { CoreFormSectionLabel } from "../Form/components/layout/CoreFormSectionLabel";

export interface CoreAccordionItem {
    title: string | React.ReactNode;
    content: string | React.ReactNode;
    rightAdornment?: string | React.ReactNode;
}

export interface CoreAccordionProps {
    items: CoreAccordionItem[];
    defaultExpanded?: boolean;
    fontSize?: string;
    readOnly?: boolean;
    expandDisabled?: boolean;
    customStyles?: React.CSSProperties;
    expandIcon?: React.ReactNode;
}

const StyledAccordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `0.0625rem solid #E5E5E5`,
    borderRadius: "0.375rem",
    boxShadow: "0 0.25rem 0.625rem 0 rgba(74, 90, 113, 0.05)",
    "&:not(:last-child)": {
        borderBottom: 0,
    },
    "&:before": {
        display: "none",
    },
    "& .MuiAccordionSummary-root": {
        background: "#FFF",
        borderRadius: "0.375rem",
        minHeight: "unset",
        padding: "0.9375rem",
        "& .MuiAccordionSummary-expandIconWrapper": {
            "& .MuiSvgIcon-root": {
                borderRadius: "0.25rem",
                transition: "all 0.2s ease",
                "&:hover": {
                    background: "#E0E0E0",
                },
            },
        },
    },
    "& .MuiAccordionSummary-content": {
        margin: 0,
    },
}));

export const CoreAccordion: React.FC<CoreAccordionProps> = ({
    items = [],
    defaultExpanded = false,
    fontSize = "1rem",
    readOnly = false,
    expandDisabled = false,
    customStyles = {},
    expandIcon = <ExpandMoreIcon />,
}) => {
    const [expanded, setExpanded] = useState<boolean>(defaultExpanded);

    const handleChange = () => {
        if (!expandDisabled) {
            setExpanded((prev) => !prev);
        }
    };

    return (
        <div>
            {items.map((item, index) => {
                const notEmptyContent = !isEmpty(item?.content);
                const titleComponent =
                    typeof item?.title === "string" ? (
                        <CoreFormSectionLabel>
                            {item?.title}
                        </CoreFormSectionLabel>
                    ) : (
                        item?.title
                    );

                return (
                    <StyledAccordion
                        key={`accordion-index-${index}`}
                        expanded={expanded}
                        onChange={() => handleChange(index)}
                        variant="outlined"
                        // elevation={1}
                        sx={{
                            boxShadow: readOnly
                                ? "none"
                                : "0 0.25rem 0.625rem 0 rgba(74, 90, 113, 0.05)",
                            "& .MuiAccordionSummary-root": {
                                cursor: expandDisabled
                                    ? "default !important"
                                    : "pointer",
                                padding: readOnly ? "0.625rem" : "0.9375rem",
                            },
                            ...customStyles,
                        }}
                    >
                        <AccordionSummary
                            sx={{
                                padding: "0 0.9375rem 0.9375rem 0.9375rem",
                            }}
                            expandIcon={
                                !expandDisabled &&
                                notEmptyContent && expandIcon
                            }
                        >
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                width="100%"
                            >
                                <Box
                                    sx={{
                                        "& .MuiTypography-root": {
                                            fontSize,
                                            color: "rgba(47, 47, 47, 0.87)",
                                            lineHeight: "normal",
                                        },
                                    }}
                                >
                                    {titleComponent}
                                </Box>
                                {!isEmpty(item.rightAdornment) && (
                                    <Box
                                        sx={{
                                            "& .MuiChip-root": {
                                                padding: "0.25rem 0.5rem",
                                                marginTop: "0",
                                                cursor: expandDisabled
                                                    ? "default !important"
                                                    : "",
                                                "& .MuiChip-label": {
                                                    textTransform: "uppercase",
                                                    fontWeight: "400",
                                                    fontSize: "0.75rem",
                                                    lineHeight: "1rem",
                                                },
                                            },
                                        }}
                                    >
                                        {item.rightAdornment}
                                    </Box>
                                )}
                            </Stack>
                        </AccordionSummary>
                        {notEmptyContent && (
                            <AccordionDetails
                                sx={{
                                    padding: "0 0.9375rem 0.9375rem 0.9375rem",
                                }}
                            >
                                {item.content}
                            </AccordionDetails>
                        )}
                    </StyledAccordion>
                );
            })}
        </div>
    );
};

CoreAccordion.defaultProps = {
    defaultExpanded: false,
};

// Usage example
export const CoreAccordionDemo: React.FC = () => {
    const accordionItems: CoreAccordionItem[] = [
        {
            title: "Accordion Item 1",
            content: "Content for Accordion Item 1",
        },
        {
            title: "Accordion Item 2",
            content: "Content for Accordion Item 2",
        },
        {
            title: "Accordion Item 3",
            content: "Content for Accordion Item 3",
        },
    ];

    return (
        <div>
            <h1>My Accordion</h1>
            <CoreAccordion items={accordionItems} />
        </div>
    );
};
