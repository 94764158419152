import _ from "lodash";
import SendRequest from "../../components/SendRequest";
import GetApiUrls from "./GetApiUrls";

const HandleRecordDuplicationRequest = (props) => {
    const { state, meta_id, category, handleSuccess, handleError } = props;

    const apiUrls = GetApiUrls(state);

    const options = {
        url: apiUrls.duplicateRecord,
        method: "POST",
        body: {
            meta_id,
            category,
        },
    };

    const errorHandle = (data) => {
        console.log(data);
        handleError();
    };

    SendRequest(
        options,
        (response) => {
            const { status, message } = response;

            if (status == 200) {
                return handleSuccess();
            }

            // On Error
            errorHandle(message);
        },
        (e) => {
            errorHandle(e);
            console.log(e);
        },
    );
};

export default HandleRecordDuplicationRequest;
