import ProvideRanking from "./ProvideRanking";

const UpdateCreditAction = (state, action) => {
    const sortedCredits = [];

    state.credits.forEach((item) => {
        if (item.local_id === action.state.local_id && !item.deleted) {
            if (
                item.role != state.activeCredit.role ||
                item.name != state.activeCredit.name
            ) {
                sortedCredits.push({
                    ...item,
                    deleted: true,
                });
                sortedCredits.push(state.activeCredit);
                return;
            }

            sortedCredits.push({
                ...state.activeCredit,
            });
            return;
        }
        sortedCredits.push(item);
    });

    return ProvideRanking(sortedCredits);
};

export default UpdateCreditAction;
