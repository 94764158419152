import React from "react";
import Settings from "../../Settings";
import ExpandableRow from "../../ExpandableRow";
import RenderCertificateRatingIso from "./RenderCertificateRatingIso";
import AssetTrafficLightText from "../../AssetTrafficLight/text";
import RenderIdDoubleTag from "./RenderIdDoubleTag";
import ActionButton from "../../CustomSelect/ActionButton";
import RenderComplianceBody from "./RenderComplianceBody";
import AuditTooltip from "../../AuditTooltip";
import _ from "lodash";

const Header = (props) => {
    const {
        data,
        asset,
        disabled,
        media_id,
        dataStructure,
        handleChange,
        dispatchNotification,
        options,
        clientFeatures,
        index = null,
        canDeleteCompliance,
        category,
        cmsData = {},
    } = props;

    const {
        asset_type,
        compliance_code,
        status,
        version_name,
        code,
        version_code,
        certifications = [],
        territory,
    } = asset;

    const { name: media_id_name, key: media_id_key } =
        dataStructure.media_id || {};

    const actualCode = compliance_code || version_code || code;
    const system_media_id =
        media_id && actualCode
            ? `${media_id}_${actualCode}`
            : asset[media_id_key] || "-";

    const updateAssetChange = (updatedAsset) => {
        const newData = [...data];
        if (index >= 0) {
            newData.splice(index, 1, updatedAsset);
            handleChange(newData);
        }
    };

    const handleEditIds = (asset, newIds) => {
        const newAsset = {
            ...asset,
            ...newIds,
        };
        updateAssetChange(newAsset);
    };

    const handleEditCertifications = (asset, data) => {
        const newAsset = {
            ...asset,
            ...data,
        };
        updateAssetChange(newAsset);
    };

    const handleEditDuration = (asset, duration) => {
        const newAsset = {
            ...asset,
            duration,
        };
        updateAssetChange(newAsset);
    };

    const deleteAsset = () => {
        const newAsset = { ...asset };
        newAsset.deleted = true;

        updateAssetChange(newAsset);
    };

    const ActionButtons = () => {
        const { assets = {} } = clientFeatures;

        let actionBtns = [
            {
                text: "Update IDs & Technical Metadata",
                onClick: () =>
                    dispatchNotification({
                        state: "edit asset ids",
                        props: {
                            asset,
                            handleChange: handleEditIds,
                            dataStructure,
                            clientFeatures,
                            category,
                        },
                        dispatchNotification,
                    }),
            },
            {
                text: "Edit Certifications & Advisory",
                onClick: () =>
                    dispatchNotification({
                        state: "edit asset certifications",
                        props: {
                            asset,
                            handleChange: handleEditCertifications,
                            options,
                            dataStructure,
                            clientFeatures,
                            cmsData,
                            territory,
                        },
                        dispatchNotification,
                    }),
            },
            {
                text: "Edit Duration",
                onClick: () =>
                    dispatchNotification({
                        state: "edit asset duration",
                        props: {
                            asset,
                            handleChange: handleEditDuration,
                            options,
                            dataStructure,
                            clientFeatures,
                        },
                        dispatchNotification,
                    }),
            },
        ];

        if (!assets.deletingAssetVersionDenied && canDeleteCompliance) {
            actionBtns.push({
                text: "Delete Compliance",
                onClick: () =>
                    dispatchNotification({
                        state: "delete metadata only asset",
                        props: {
                            data,
                            asset,
                            handleChange: deleteAsset,
                            options,
                            dataStructure,
                            clientFeatures,
                        },
                        dispatchNotification,
                    }),
            });
        }

        return actionBtns;
    };

    return (
        <div className="meta-asset-header">
            <img
                className="header-icon"
                src={`${Settings.images.path}/svg/square-play-grey.svg`}
            />
            <span className="title">{asset_type || version_name}</span>

            <div className="right-content">
                <div className="certifications">
                    {certifications
                        .filter((item) => !item.deleted)
                        .map((certificate, index) => {
                            return (
                                <RenderCertificateRatingIso
                                    certificate={certificate}
                                    clientFeatures={clientFeatures}
                                    key={index}
                                />
                            );
                        })}
                </div>

                <AssetTrafficLightText status={status} text={actualCode} />

                <RenderIdDoubleTag
                    textLeft={media_id_name}
                    textRight={system_media_id}
                />

                <ActionButton disabled={disabled} items={ActionButtons()} />
            </div>
        </div>
    );
};

const Body = (props) => {
    const {
        asset,
        send_to_ai,
        clientFeatures,
        dataStructure,
        dispatchNotification = () => {},
        cmsData = {},
    } = props;

    return (
        <RenderComplianceBody
            compliance={asset}
            send_to_ai={send_to_ai}
            clientFeatures={clientFeatures}
            dataStructure={dataStructure}
            dispatchNotification={dispatchNotification}
            cmsData={cmsData}
        />
    );
};

const SingleDimensionAsset = (props) => {
    return (
        <ExpandableRow
            header={
                <AuditTooltip
                    audits={props.audits || []}
                    value={props.asset ? props.asset.meta_id : false}
                    fieldName="meta_id"
                    clientFeatures={props.clientFeatures || {}}
                    dataSection="assets"
                >
                    <Header {...props} />
                </AuditTooltip>
            }
            body={<Body {...props} />}
            bodyStyles={{ padding: 0 }}
            className="meta-expandable-rows--compliance"
            arrowIconColorType="medium"
            cmsData={props.cmsData}
        />
    );
};

export default SingleDimensionAsset;
