import Config from "../../Clients/Config";

const IDsFilterOnConfig = (props) => {
    let result = true;

    const { category = false, destination = "", key = "" } = props;

    if (category) {
        let config = Config.getIdentifiersViewConfig();
        if (typeof config === "object" && config.hasOwnProperty(destination)) {
            if (
                config[destination].fields &&
                Array.isArray(config[destination].fields)
            ) {
                let itemConfig = config[destination].fields.find(
                    (value) => value.key === key,
                );
                if (itemConfig && itemConfig.disabledForCategories) {
                    if (itemConfig.disabledForCategories.includes(category)) {
                        result = false;
                    }
                }
            }
        }
    }

    return result;
};

export default IDsFilterOnConfig;
