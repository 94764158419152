import React from "react";
import MultipleTag, { ITag } from "../Tag/multiple";
import _ from "lodash";
import "./styles/_sectionContentStyles.scss";

interface IHeader {
    tags: Array<Array<ITag>>;
}

type Props = {
    header?: IHeader;
    containers?: Array<JSX.Element>;
    className?: string;
    headerClassName?: string;
    contentClassName?: string;
    containerClassName?: string;
    children?: JSX.Element
};

const SectionContentBoilerplate: React.FC<Props> = (props) => {
    const {
        header = {},
        containers = [],
        className = "",
        headerClassName = "",
        contentClassName = "",
        containerClassName = "",
        children
    } = props;

    const { tags = [] } = header;

    return (
        <div className={`section-content-boilerplate ${className}`}>
            {!_.isEmpty(tags) && (
                <div className={`section-content-header ${headerClassName}`}>
                    {tags.map((multipleTags, index) => {
                        return <MultipleTag tags={multipleTags} key={index} />;
                    })}
                </div>
            )}

            <div className={`section-content-containers ${contentClassName}`}>
                {containers?.map((element, index) => {
                    return (
                        <div
                            className={`section-content-container ${containerClassName}`}
                            style={{ marginTop: index > 0 ? "1.25rem" : 0 }}
                        >
                            {element}
                        </div>
                    );
                })}

                {children &&
                    <div className={`section-content-container ${containerClassName}`}>
                        {children}
                    </div>
                }
            </div>
        </div>
    );
};

export default SectionContentBoilerplate;
