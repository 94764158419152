import _ from "lodash";

export default function GetRecordData(state) {
    const { newRecord, activeRecord, recordData } = state;

    if (!_.isEmpty(newRecord)) return newRecord;
    if (!_.isEmpty(recordData)) return recordData;
    if (!_.isEmpty(activeRecord)) return activeRecord;

    return {};
}
