import { RegionalStatusInterface } from "./regionalStatusInterface";
import { ClientFeaturesInterface, CmsInterface } from "../../components/Global/interfaces";
import {
    TITLES_AND_SYNOPSES_STATUS,
} from "../../components/ClientManagedOptions/options";
import { getDynamicList } from "../../services/ClientManagedListService";
import { isEmpty } from "lodash";

export const defaultStatusList: RegionalStatusInterface[] = [
    {
        text: "Approved",
        value: "approved",
    },
    {
        text: "Proposed",
        value: "proposed",
    },
];

export async function getRegionalStatusesOptions(clientFeatures: ClientFeaturesInterface = {}, cmsData: CmsInterface): Promise<RegionalStatusInterface[]> {
    const {
        clientManagedTitlesAndSynopsesStatus = false,
    } = clientFeatures;

    let statusList = defaultStatusList;

    if (clientManagedTitlesAndSynopsesStatus) {
        const response = await getDynamicList(cmsData, TITLES_AND_SYNOPSES_STATUS);

        if (response) {
            statusList = response;
        }
    }

    return statusList;
}

export function getSelectedDefaultRegionalStatus(clientFeatures: ClientFeaturesInterface = {}, regionalStatuses: RegionalStatusInterface[]): string | null {
    const {
        clientManagedTitlesAndSynopsesStatus = false,
    } = clientFeatures;

    if (!clientManagedTitlesAndSynopsesStatus || isEmpty(regionalStatuses)) {
        return "approved";
    }

    const defaultStatusOption = regionalStatuses.find((item) => item.default) || regionalStatuses[0];
    return defaultStatusOption ? defaultStatusOption.value : null;
}
