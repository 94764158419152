import React from "react";
import { SectionInterface } from "./interface";
import Classification from "../../views/Classification";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";

const SectionClassification = (props: SectionInterface) => {
    const { sectionOptions = {}, data = {}, cmsData = {} } = props;

    return (
        <Classification
            {...getSectionCommonProps({ ...props, field: "classification" })}
            name="classification"
            className="meta-record-classification"
            title="Classification"
            data={data.classification}
            category={data.category}
            sectionOptions={sectionOptions.classification || []}
            clientDataStructure={cmsData.clientDataStructure}
        />
    );
};

export default SectionClassification;
