import FilmographyTableView from "./views/FilmographyTable.view"
import MeasurementView from "./views/Measurement.view"

export const measurementSouceIMDb: string = 'imdb'
export const measurementSouceOrigin: string = 'origin'
export const ivaRatingKey: string = 'IvaRating'

export default {
    iva: {
        imagesTypes: {
            headshot: 'Headshot'
        },
        ratingKey: ivaRatingKey
    },
    customSectionViews: {
        filmographyTable: {
            Component: FilmographyTableView
        },
        measurement: {
            Component: MeasurementView
        }
    },
    transformedFieldsToOmit: [
        'id', 
        'updated_at', 
        'created_at'
    ],
    measurementSouces: [
        {
            logo: 'origin.svg',
            title: 'Origin Power Rating',
            ratingIcon: 'star.svg',
            source: measurementSouceOrigin,
            id: {
                text: 'Origin ID',
                path: 'ids.origin_id'
            },
            ratingName: ivaRatingKey
        },
        // {
        //     logo: 'imdb-yellow-black.svg',
        //     title: 'IMDb STARmeter',
        //     ratingIcon: 'trend-down-red.svg',
        //     source: measurementSouceIMDb,
        //     id: {
        //         text: 'IMDb ID',
        //         path: 'ids.imdb_id'
        //     }
        // },
    ]
}