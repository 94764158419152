import React, { useState } from "react";
import { SectionInterface } from "./interface";
import BoxOfficeAndPerformance from "../../views/BoxOfficeAndPerformance";
import DividerLine from "../../../DividerLine";
import LocaleSelect from "../../views/LocaleSelect";
import { CLIENT_MANAGED_COUNTRIES } from "../../../ClientManagedOptions/options";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";

const SectionBoxOfficeAndPerformance = (props: SectionInterface) => {
    const {
        sectionOptions = {},
        handleFormChange,
        options = {},
        cmsData = {},
        data = {},
        lastSection,
        disabled,
        audits = [],
    } = props;

    const { clientFeatures = {} } = cmsData;

    const { box_office_and_performance = {} } = data;

    const [boxOfficeLocale, setBoxOfficeLocale] = useState<string>("USA");

    let checkedKeys: Array<string> = [];
    Object.keys(box_office_and_performance).forEach((key) =>
        checkedKeys.push(key),
    );

    return (
        <React.Fragment>
            <LocaleSelect
                options={options}
                value={boxOfficeLocale}
                optionsKey={CLIENT_MANAGED_COUNTRIES}
                group={false}
                handleLanguageChange={(value: string) =>
                    setBoxOfficeLocale(value)
                }
                checkedKeys={checkedKeys}
                clientFeatures={clientFeatures}
                cmsData={cmsData}
                disabledText="Select Country"
            />

            <BoxOfficeAndPerformance
                {...getSectionCommonProps({
                    ...props,
                    field: "box_office_and_performance",
                })}
                name="ratings"
                className="meta-record-box-office-and-performance"
                title="Box Office & Performance"
                data={box_office_and_performance}
                noBorder={true}
                options={options}
                country_iso={boxOfficeLocale}
                clientFeatures={clientFeatures}
            />

            <DividerLine />
        </React.Fragment>
    );
};

export default SectionBoxOfficeAndPerformance;
