import React from "react";
import CustomSelect from "../../CustomSelect";
import MenuIconBoilerPlate from "../../Boilerplates/MenuIconBoilerplate";
import { useStore } from "../store/context";
import { deleteRegionalRating, deleteClassification } from "../store/reducer";
import AgeGuidance from "../interfaces/RegionalAgeGuidanceRow.interface";
import { REGIONAL_AGE_GUIDANCE } from "../configuration";

type Props = {
    index: number;
    ageGuidance: AgeGuidance;
    type: string;
};

const Actions: React.FC<Props> = (props): JSX.Element => {
    const [store, dispatch] = useStore();

    const { ageGuidance: rowData, index, type } = props;

    const { disabled, age_guidance } = store;

    const actionsList = [
        {
            onClick: () => {
                type == REGIONAL_AGE_GUIDANCE
                    ? dispatch(deleteRegionalRating(rowData, age_guidance))
                    : dispatch(deleteClassification());
            },
            text: "Delete",
        },
    ];

    return (
        <div className="actions">
            <CustomSelect
                disabled={disabled}
                chevronImage={false}
                background={"none"}
                items={actionsList}
                customImageRight={<MenuIconBoilerPlate />}
            />
        </div>
    );
};

export default Actions;
