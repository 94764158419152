import _ from "lodash";
import {
    CLIENT_MANAGED_RECORD_TYPES,
    CLIENT_MANAGED_RECORD_TYPES_WITH_CATEGORIES,
    CLIENT_MANAGED_VERSIONS,
} from "./options";
import {
    GetFromLocalStorage,
    SetLocalStorage,
    RemoveItemFromLocalStorage,
} from "../Storage/localStorage";

interface ClientManagedControllerProps {
    clientManagedOptions: any;
    fallback: any;
    localStorage: any;
    isClientManaged: boolean;
}

export interface VersionsInLocalStorageInterface {
    client_managed_asset_compliances: number;
    client_managed_brands: number;
    client_managed_cast_and_crew: number;
    client_managed_catalogs: number;
    client_managed_certifications: number;
    client_managed_countries: number;
    client_managed_descriptors: number;
    client_managed_languages: number;
    client_managed_localisation_compliances: number;
    client_managed_localisation_studios: number;
    client_managed_production_quotas: number;
    client_managed_provenances: number;
    client_managed_record_types: number;
    client_managed_rights_licensors: number;
    client_managed_rights_platforms: number;
    client_managed_rights_versions: number;
    client_managed_release_date_types: number;
    client_managed_record_statuses: number;
    client_managed_territories: number;
    client_managed_suppliers: number;
    client_managed_content_types: number;
    client_managed_purchase_types: number;
    client_managed_enhanced_firstlook_series: number;
    client_managed_branding_type_names: number;
    client_managed_target_demos: number;
    client_managed_viewerships: number;
    client_managed_median_ages: number;
    client_managed_series_networks: number;
    client_managed_sp_review_names: number;
    client_managed_sp_review_causes: number;
    client_managed_program_usages: number;
    client_managed_primary_program_usages: number;
    client_managed_program_subtypes: number;
    client_managed_library_prefix: number;
    client_managed_release_types: number;
    client_managed_platforms: number;
    client_managed_titles_and_synopses_status: number;
}

export default function clientManagedController({
    clientManagedOptions,
    fallback,
    localStorage,
    isClientManaged,
}: ClientManagedControllerProps): any {
    if (isClientManaged) {
        if (!_.isEmpty(clientManagedOptions)) {
            return clientManagedOptions;
        }

        if (!_.isEmpty(localStorage)) {
            return localStorage;
        }
    }

    return fallback;
}

export function manageVersionsInLocalStorage(
    versions: VersionsInLocalStorageInterface,
): void {
    if (!_.isEmpty(versions)) {
        const localVersions =
            GetFromLocalStorage(CLIENT_MANAGED_VERSIONS) || {};
        Object.keys(versions).forEach((key: string) => {
            const item: number = versions[key];

            if (localVersions[key] && localVersions[key] !== item) {
                RemoveItemFromLocalStorage(key);
                if (key === CLIENT_MANAGED_RECORD_TYPES) {
                    RemoveItemFromLocalStorage(CLIENT_MANAGED_RECORD_TYPES_WITH_CATEGORIES);
                }
                console.log("Setting new client managed list", key);
            }
        });

        SetLocalStorage({
            name: CLIENT_MANAGED_VERSIONS,
            value: versions,
        });
    }
}
