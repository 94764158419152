import deepcopy from "deepcopy";
import _ from "lodash";
import validateString from "../Helpers/validateString";
import GetLocaleNameFromOptions from "../GetLocaleNameFromOptions";

export default function ValidateOriginalTitle(result = {}, optionsData = {}) {
    let response = deepcopy(result);

    const {
        data = {},
        status,
    } = optionsData;

    const { titles = [] } = data;

    const displayTitle = titles.find(
        (item) => item.display && !item.deleted && item.title,
    );

    if (_.isEmpty(displayTitle)) {
        response.result = false;

        response.notification = {
            title: "Please check display title",
            description: "Display title is required",
            status,
        };

        response.validationKeys = result.validationKeys.concat([
            `title_title_display`,
        ]);
    }

    return response;
}
