import { CreditInterface, CreditScriptedCharacterNameInterface } from "../../../credits.interface";
import { isEmpty } from "../../../utils/helpers";

export default function (
    script: string,
    defaultScript: string,
    credit: CreditInterface
): string  {
    let result: string = credit.character_name as string

    if (script !== defaultScript) {
        let targetName: string | undefined = (credit as CreditInterface).script_character_names.find(
            (scriptName: CreditScriptedCharacterNameInterface) =>
                scriptName.code.toLowerCase() === script.toLowerCase()
        )?.character_name

        result = targetName ? targetName : `<span style="color: rgba(188, 188, 188, 0.87); line-height: 1rem;">${!isEmpty(credit.character_name) ? credit.character_name : ""}</span>`
    }

    return result
}