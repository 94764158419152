import { RecordInterface } from "./model";
import _, { isEmpty, first } from "lodash";

export const ROLE_NAME_CREATOR = "Creator";
export const ROLE_NAME_DIRECTOR = "Director";

export const EXTERNAL_ID = "external_id";

interface ScriptCharacterNameInterface {
    character_name: string;
    code: string;
}

export interface ContributorInterface {
    name: string;
    role_name: string;
    script_character_names?: ScriptCharacterNameInterface[]
}

export type NameComponents = {
    name: string
    first_name: string
    last_name: string
    middle_name: string
}

export function getContributors(
    record: RecordInterface,
): ContributorInterface[] {
    return record.contributors || [];
}

export function getCreators(
    record: RecordInterface,
    count: number,
): string | false {
    const contributors: ContributorInterface[] = getContributors(record);
    const creators: ContributorInterface[] = contributors.filter(
        (item: ContributorInterface) => item.role_name == ROLE_NAME_CREATOR,
    );
    const takenCreators: string[] = creators
        .slice(0, count)
        .map((item: ContributorInterface) => item.name);

    return _.isEmpty(takenCreators) ? false : takenCreators.join(" • ");
}

export function getDirector(record: RecordInterface): string | false {
    const contributors: ContributorInterface[] = getContributors(record);
    const director: ContributorInterface | any =
        contributors.find(
            (item: ContributorInterface) =>
                item.role_name == ROLE_NAME_DIRECTOR,
        ) || {};

    return director.name || false;
}

export function getListOfContributors(record: RecordInterface): string {
    const contributors: ContributorInterface[] = getContributors(record);
    const orderedByContributors: ContributorInterface[] = _.orderBy(contributors, 'ranking')
    const items: Array<string> = orderedByContributors.map((item: ContributorInterface) => item.name) 
    
    return items.join(', ')
}

export function getContributorCustomIdStatus(
    constributorCustomFields: string[],
    customFieldsEnabled: boolean,
): boolean {

    if (constributorCustomFields.includes(EXTERNAL_ID) && customFieldsEnabled) {
        return true
    }

    return false
}

export function parseContributorName(name: string): NameComponents | Error {
    const nameComponents: NameComponents = {
        name: name,
        first_name: "",
        last_name: "",
        middle_name: ""
    };

    const words = name.trim().split(/\s+/);

    if (!words[0].length) return new Error(
        "Credits parseName function Error: Name must not be empty or only whitespace", 
        { 
            cause: nameComponents
        }
    );

    const numWords = words.length;
    if (numWords === 1) {
        nameComponents.first_name = words[0];
    } else if (numWords === 2) {
        nameComponents.first_name = words[0];
        nameComponents.last_name = words[1];
    } else {
        nameComponents.first_name = words[0];
        nameComponents.last_name = words[numWords - 1];
        nameComponents.middle_name = words.slice(1, numWords - 1).join(" ");
    }

    return nameComponents;
}

export function getFirstCharacterName(contributor: ContributorInterface): string | null {
    if (isEmpty(contributor?.script_character_names)) {
        return null
    }

    const englishScriptCharacterName = contributor?.script_character_names?.find((item: any) => item.code == "ENG")
    const firstScriptCharacterName = first(contributor?.script_character_names)

    return englishScriptCharacterName
        ? englishScriptCharacterName.character_name
        : firstScriptCharacterName.character_name
}