import React from "react";
import _ from "lodash";
import styles from "../styles";
import Synopsis from "./components/Synopsis";
import isIndexInArray from "../../Helpers/isIndexInArray";
import update from "react-addons-update";
import Index from "./Index";
import deepcopy from "deepcopy";
import { diff } from "deep-object-diff";
import GetPopulatedLanguages from "../../GoogleTranslateApi/GetPopulatedLanguages";
import GetMetaGoogleTranslateLanguages from "../../Languages/GetMetaGoogleTranslateLanguages";
import GoogleTranslateApi from "../../GoogleTranslateApi";
import GoogleTranslateMultipleApi from "../../GoogleTranslateApi/Multiple";
import Notification from "../../Notification";
import FullScreenLoadingSpinner from "../../FullScreenLoadingSpinner";
import RegionalItem from "./RegionalItem";
import UpdatedAt from "../../UpdatedAt";
import { LockedSynopsisField } from "../../LockedField";
import { getRegionalStatusesOptions, getSelectedDefaultRegionalStatus } from '../../../models/ClientManaged/regionalStatusModel'

export default class Descriptions extends Index {
    constructor(props) {
        super(props);

        const { data, sectionOptions = [] } = this.props;

        let dataFromProps = [];
        if (Array.isArray(data)) {
            dataFromProps = data;
        }

        /**
         * @var object
         */
        this.state = {
            data: dataFromProps,
            sectionOptions,
        };

        /**
         * @var object
         */
        this.styles = styles(this.props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const nextPropsData = nextProps.data || [];
        const thisPropsData = this.props.data || [];
        const difference = diff(nextPropsData, thisPropsData);

        if (!_.isEmpty(difference)) {
            return true;
        }

        if (!_.isEqual(nextProps.stateKey, this.props.stateKey)) {
            return true;
        }

        if (!_.isEqual(nextProps.validationKeys, this.props.validationKeys)) {
            return true;
        }

        if (!_.isEqual(nextProps.disabled, this.props.disabled)) {
            return true;
        }

        if (!_.isEqual(nextProps.options, this.props.options)) {
            return true;
        }

        if (!_.isEqual(nextProps.sectionOptions, this.props.sectionOptions)) {
            return true;
        }

        if (!_.isEqual(nextProps.languageIso, this.props.languageIso)) {
            return true;
        }

        if (!_.isEqual(nextState, this.state)) {
            return true;
        }

        return false;
    }

    componentDidMount() {
        if (!Array.isArray(this.state.regionalStatuses)) {
            getRegionalStatusesOptions(this.props.clientFeatures, this.props.cmsData).then((regionalStatuses) => {
                this.setState({
                    regionalStatuses,
                });
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { data, sectionOptions } = this.props;

        if (
            !_.isEqual(data, prevProps.data) &&
            !_.isEqual(data, prevState.data)
        ) {
            if (Array.isArray(data)) {
                this.setStateValue("data", data);
            }
        }

        // sectionOptions
        if (!_.isEqual(sectionOptions, prevProps.sectionOptions)) {
            if (Array.isArray(sectionOptions)) {
                this.setStateValue("sectionOptions", sectionOptions);
            }
        }
    }

    getDisplayStyles(data) {
        const { disabled } = this.props;

        let result = {};

        if (_.isEmpty(data) && disabled) {
            result = {
                display: "none",
            };
        }

        return result;
    }

    handleChange(props) {
        const {
            type,
            key,
            value,
            locale = this.props.languageIso,
            api_name = null,
            source,
            status,
        } = props;

        const { data } = this.state;

        const {
            clientFeatures = {},
        } = this.props;

        const defaultStatus = getSelectedDefaultRegionalStatus(clientFeatures, this.state.regionalStatuses)

        // Find index
        const stateIndex = _.findIndex(data, function (t) {
            return t.type == type && t.locale == locale;
        });

        let updatedState = deepcopy(data);

        // Update
        if (stateIndex !== -1) {
            updatedState[stateIndex][key] = value;

            const item = deepcopy(updatedState[stateIndex]);

            if (item && typeof item.api_name == "string") {
                delete updatedState[stateIndex].api_name;
            }

            // Remove deleted key if any value exists
            if (item && item.deleted && (item.synopsis || item.comment)) {
                delete updatedState[stateIndex].deleted;
            }

            // Delete
            if (item && !item.synopsis && !item.comment) {
                updatedState[stateIndex].deleted = true;
            }
        }

        // Create
        if (stateIndex === -1) {
            let item = {
                status: status || defaultStatus,
                source: source || "open",
                synopsis: null,
                comment: null,
                locale,
                type,
            };

            if (typeof api_name == "string") {
                item.api_name = api_name;
            }

            item[key] = value;
            updatedState = update(data, { $push: [item] });
        }

        this.setState(
            {
                data: updatedState,
            },
            () => {
                if (typeof this.props.handleFormChange == "function") {
                    this.props.handleFormChange(updatedState);
                }
            },
        );
    }

    async handleGooleTranslateClick(props) {
        const { locale, label } = props;

        const { data = [] } = this.state;

        const { options = {} } = this.props;

        const { spoken_languages = [], top_written_languages = [] } = options;

        const setFetching = () => {
            this.setState({
                fetchingTranslations: true,
            });
        };

        const setNotification = (props) => {
            this.setState({
                fetchingTranslations: false,
                notification: {
                    title: props.title,
                    description: props.description,
                    intercationStatus: props.intercationStatus || "alert",
                    onClick: () => {
                        this.setState({
                            notification: {},
                        });
                    },
                },
            });
        };

        const setTechnicalError = () => {
            setNotification({
                title: "Technical Error",
                description: "Failed to translate language, please try again.",
            });
        };

        // translate all empty languages
        if (locale == "empty_values") {
            // get all empty locales
            const metaGoogleTranslateLanguages =
                GetMetaGoogleTranslateLanguages({
                    top_written_languages,
                    languages: spoken_languages,
                });

            const populatedLocales = data
                .filter((item) => item.synopsis && item.type == props.key)
                .map((item) => item.locale);

            const emptyLocales = metaGoogleTranslateLanguages
                .filter((item) => !isIndexInArray(populatedLocales, item.value))
                .map((item) => item.iso_639_1);

            if (!_.isEmpty(emptyLocales)) {
                setFetching();

                this.handleGoogleTranslateApi({
                    ...props,
                    locales: emptyLocales,
                    multiple: true,
                    handleSuccess: (response) => {
                        // set all empty languages
                        if (!_.isEmpty(response.success)) {
                            this.applyTranslatedLanguages({
                                key: props.key,
                                items: response.success,
                            });

                            this.setState({
                                fetchingTranslations: false,
                            });
                        }

                        // if there were some errors, then display an error message
                        if (!_.isEmpty(response.error)) {
                            return setNotification({
                                title: "Finished translation with some errors",
                                description:
                                    "Some languages failed to be translated, please try again.",
                            });
                        }

                        setNotification({
                            title: "Google Translate",
                            description:
                                "Translation complete on all empty languages",
                            intercationStatus: "success",
                        });
                    },
                    handleError: () => setTechnicalError(),
                });
            }

            return;
        }

        setFetching();

        const language = this.getInfoFromTranslatedLocale({
            locale,
        });

        // translate single language
        this.handleGoogleTranslateApi({
            ...props,
            handleSuccess: (value) => {
                this.applyTranslatedLanguage({
                    ...props,
                    value,
                });

                setNotification({
                    title: "Google Translate",
                    description: `Translation complete to ${
                        language.text || ""
                    } ${label}`,
                    intercationStatus: "success",
                });
            },
            handleError: () => setTechnicalError(),
        });
    }

    handleGoogleTranslateApi(props) {
        if (props.multiple) {
            return GoogleTranslateMultipleApi({
                ...props,
            });
        }

        GoogleTranslateApi({
            ...props,
        });
    }

    getInfoFromTranslatedLocale(props) {
        const { locale = "" } = props;

        const { options = {} } = this.props;

        const { spoken_languages = [] } = options;

        const getLanguageCode = (item) => {
            let languageCode = "";
            if (typeof item.iso_639_1 == "string") {
                languageCode = item.iso_639_1.toLowerCase();
            }

            return languageCode;
        };

        return (
            spoken_languages.find(
                (item) => getLanguageCode(item) == locale.toLowerCase(),
            ) || {}
        );
    }

    applyTranslatedLanguage(props) {
        const { value } = props;

        const metaLocale = this.getInfoFromTranslatedLocale(props);

        if (metaLocale.value) {
            this.handleChange({
                type: props.key,
                key: "synopsis",
                value,
                locale: metaLocale.value,
                api_name: "Google Translate",
                source: "api",
                status: "proposed",
            });
        }
    }

    applyTranslatedLanguages(props) {
        const { items = [] } = props;

        const { data = [] } = this.state;

        let result = [];

        items.forEach((item) => {
            const metaLocale = this.getInfoFromTranslatedLocale({
                locale: item.locale,
            });

            if (metaLocale.value) {
                result.push({
                    type: props.key,
                    synopsis: item.text,
                    api_name: "Google Translate",
                    source: "api",
                    status: "proposed",
                    locale: metaLocale.value,
                    comment: null,
                });
            }
        });

        const updatedData = update(data, { $push: [...result] });
        this.updataDataWithChange(updatedData);
    }

    updataDataWithChange(updatedData) {
        const { handleFormChange } = this.props;

        this.setState(
            {
                data: updatedData,
            },
            () => {
                if (typeof handleFormChange == "function") {
                    handleFormChange(updatedData);
                }
            },
        );
    }

    renderNoneAdded() {
        return false;
    }

    renderSynopsis(props) {
        const { key, label, maxCharacter, data = {} } = props;

        const {
            validationKeys = [],
            disabled,
            handleOnBlur = () => {},
            clientFeatures = {},
            clientDataStructure = {},
            recordData = {},
            stateKey,
            options = {},
        } = this.props;

        const { 
            googleTranslate,
        } = clientFeatures;

        let lockedField = LockedSynopsisField({
            item: props,
            clientDataStructure,
            recordData,
        });

        return (
            <RegionalItem
                inputKey="synopsis"
                className="meta-record-synopsis"
                commentWidth="50%"
                label={label}
                placeholder={disabled ? "None Entered" : `Enter ${label}`}
                name={key}
                data={data}
                disabled={lockedField || props.disabled || disabled}
                maxCharacter={maxCharacter}
                counter={true}
                validationKeys={validationKeys}
                type={key}
                displaySwitches={false}
                googleTranslateItems={this.getGoogleTranslateItems(key)}
                googleTranslate={googleTranslate}
                handleOnBlur={handleOnBlur.bind(this, key)}
                handleChange={this.handleChange.bind(this)}
                inlineCommentsAndButtons={true}
                textArea={true}
                stateKey={stateKey}
                clientFeatures={clientFeatures}
                dataSource={"synopses"}
                handleGooleTranslateClick={(locale) =>
                    this.handleGooleTranslateClick({
                        key,
                        locale,
                        text: data.synopsis,
                        label,
                    })
                }
                languageIso={this.props.languageIso || null}
                statusOptions={this.state.regionalStatuses}
            />
        );
    }

    renderUpdatedAt() {
        const { updatingLogs = {}, clientDataStructure } = this.props;

        return (
            <UpdatedAt
                updatingLogs={updatingLogs}
                clientDataStructure={clientDataStructure}
                sectionKey="synopses"
            />
        );
    }

    renderContent() {
        const { data, sectionOptions, notification, fetchingTranslations } =
            this.state;

        const { languageIso, copy = {}, category } = this.props;

        let synopsis250Label = copy.synopsis250 || "Synopsis (250)";
        if (category == "Game") {
            synopsis250Label = copy.synopsis || "Synopsis";
        }

        let synopsis400Label = copy.synopsis400 || "Synopsis (400)";
        if (category == "Game") {
            synopsis400Label = copy.seoSynopsis || "SEO Synopsis";
        }

        const localeSynopses =
            data.filter((item) => item.locale == languageIso) || [];
        const synopsis60 =
            localeSynopses.find((item) => item.type == "synopsis60") || {};
        const synopsis80 =
            localeSynopses.find((item) => item.type == "synopsis80") || {};
        const synopsis180 =
            localeSynopses.find((item) => item.type == "synopsis180") || {};
        const synopsis240 =
            localeSynopses.find((item) => item.type == "synopsis240") || {};
        const synopsis250 =
            localeSynopses.find((item) => item.type == "synopsis250") || {};
        const synopsis400 =
            localeSynopses.find((item) => item.type == "synopsis400") || {};
        const synopsis640 =
            localeSynopses.find((item) => item.type == "synopsis640") || {};
        const synopsis800 =
            localeSynopses.find((item) => item.type == "synopsis800") || {};
        const synopsis1000 =
            localeSynopses.find((item) => item.type == "synopsis1000") || {};
        const synopsis2000 =
            localeSynopses.find((item) => item.type == "synopsis2000") || {};
        const synopsis4000 =
            localeSynopses.find((item) => item.type == "synopsis4000") || {};
        const synopsis10000 =
            localeSynopses.find((item) => item.type == "synopsis10000") || {};
        const filmstruck320 =
            localeSynopses.find((item) => item.type == "filmstruck320") || {};
        const copyright =
            localeSynopses.find((item) => item.type == "copyright") || {};

        return (
            <React.Fragment>
                {this.renderUpdatedAt()}

                {isIndexInArray(sectionOptions, "Synopsis60") && (
                    <div
                        style={Object.assign({}, this.styles.marginItem, {
                            ...this.getDisplayStyles(synopsis60),
                        })}
                    >
                        {this.renderSynopsis({
                            key: "synopsis60",
                            label: "Synopsis (60)",
                            maxCharacter: 60,
                            data: synopsis60,
                        })}
                    </div>
                )}

                {isIndexInArray(sectionOptions, "Synopsis80") && (
                    <div style={Object.assign({}, this.styles.marginItem)}>
                        {this.renderSynopsis({
                            key: "synopsis80",
                            label: "Synopsis (80)",
                            maxCharacter: 80,
                            data: synopsis80,
                        })}
                    </div>
                )}

                {isIndexInArray(sectionOptions, "Synopsis180") && (
                    <div style={Object.assign({}, this.styles.marginItem)}>
                        {this.renderSynopsis({
                            key: "synopsis180",
                            label: "Synopsis (180)",
                            maxCharacter: 180,
                            data: synopsis180,
                        })}
                    </div>
                )}

                {isIndexInArray(sectionOptions, "240HboxMax") && (
                    <div
                        style={Object.assign({}, this.styles.marginItem, {
                            ...this.getDisplayStyles(synopsis240),
                        })}
                    >
                        {this.renderSynopsis({
                            key: "synopsis240",
                            label: "Synopsis (240) HBO Max",
                            maxCharacter: 240,
                            data: synopsis240,
                            disabled: true,
                        })}
                    </div>
                )}

                {isIndexInArray(sectionOptions, "Synopsis250") && (
                    <div
                        style={Object.assign({}, this.styles.marginItem, {
                            ...this.getDisplayStyles(synopsis250),
                        })}
                    >
                        {this.renderSynopsis({
                            key: "synopsis250",
                            label: "Synopsis (250)",
                            maxCharacter: 255,
                            data: synopsis250,
                        })}
                    </div>
                )}

                {isIndexInArray(sectionOptions, "Synopsis400") && (
                    <div
                        style={Object.assign({}, this.styles.marginItem, {
                            ...this.getDisplayStyles(synopsis400),
                        })}
                    >
                        {this.renderSynopsis({
                            key: "synopsis400",
                            label: "Synopsis (400)",
                            maxCharacter: 400,
                            data: synopsis400,
                        })}
                    </div>
                )}

                {isIndexInArray(sectionOptions, "Synopsis640") && (
                    <div
                        style={Object.assign({}, this.styles.marginItem, {
                            ...this.getDisplayStyles(synopsis640),
                        })}
                    >
                        {this.renderSynopsis({
                            key: "synopsis640",
                            label: "Synopsis (640)",
                            maxCharacter: 640,
                            data: synopsis640,
                        })}
                    </div>
                )}

                {isIndexInArray(sectionOptions, "800HboxMax") && (
                    <div
                        style={Object.assign({}, this.styles.marginItem, {
                            ...this.getDisplayStyles(synopsis800),
                        })}
                    >
                        {this.renderSynopsis({
                            key: "synopsis800",
                            label: "Synopsis (800) HBO Max",
                            maxCharacter: 800,
                            data: synopsis800,
                            disabled: true,
                        })}
                    </div>
                )}

                {isIndexInArray(sectionOptions, "Synopsis1000") && (
                    <div
                        style={Object.assign({}, this.styles.marginItem, {
                            ...this.getDisplayStyles(synopsis1000),
                        })}
                    >
                        {this.renderSynopsis({
                            key: "synopsis1000",
                            label: "Synopsis (1000)",
                            maxCharacter: 1000,
                            data: synopsis1000,
                        })}
                    </div>
                )}

                {isIndexInArray(sectionOptions, "Synopsis2000") && (
                    <div
                        style={Object.assign({}, this.styles.marginItem, {
                            ...this.getDisplayStyles(synopsis2000),
                        })}
                    >
                        {this.renderSynopsis({
                            key: "synopsis2000",
                            label: "Synopsis (2000)",
                            maxCharacter: 2000,
                            data: synopsis2000,
                        })}
                    </div>
                )}

                {isIndexInArray(sectionOptions, "Synopsis4000") && (
                    <div
                        style={Object.assign({}, this.styles.marginItem, {
                            ...this.getDisplayStyles(synopsis4000),
                        })}
                    >
                        {this.renderSynopsis({
                            key: "synopsis4000",
                            label: "Synopsis (4000)",
                            maxCharacter: 4000,
                            data: synopsis4000,
                        })}
                    </div>
                )}

                {(isIndexInArray(sectionOptions, "SynopsisDistributor10000") ||
                    isIndexInArray(sectionOptions, "Synopsis10000")) && (
                    <div
                        style={Object.assign({}, this.styles.marginItem, {
                            ...this.getDisplayStyles(synopsis10000),
                        })}
                    >
                        {this.renderSynopsis({
                            key: "synopsis10000",
                            label: isIndexInArray(
                                sectionOptions,
                                "Synopsis10000",
                            )
                                ? "Synopsis (10000)"
                                : "Synopsis Distributor (10000)",
                            maxCharacter: 10000,
                            data: synopsis10000,
                        })}
                    </div>
                )}

                {isIndexInArray(sectionOptions, "Filmstruck320") && (
                    <div
                        style={Object.assign({}, this.styles.marginItem, {
                            ...this.getDisplayStyles(filmstruck320),
                        })}
                    >
                        {this.renderSynopsis({
                            key: "filmstruck320",
                            label: "FilmStruck (320)",
                            maxCharacter: 320,
                            data: filmstruck320,
                        })}
                    </div>
                )}

                {isIndexInArray(sectionOptions, "Copyright") && (
                    <div
                        style={Object.assign({}, this.styles.marginItem, {
                            ...this.getDisplayStyles(copyright),
                        })}
                    >
                        {this.renderSynopsis({
                            key: "copyright",
                            label: "Copyright",
                            data: copyright,
                        })}
                    </div>
                )}

                <Notification {...notification} />

                {fetchingTranslations && <FullScreenLoadingSpinner />}
            </React.Fragment>
        );
    }
}
