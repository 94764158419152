import React, { useReducer, useContext, useEffect } from "react";
import reducer, { setState } from "./reducer";

type ProviderProps = {
    children: JSX.Element;
    handleFormChange: Function;
    handleConditionsFormChange: Function;
    disabled: boolean;
    platformScheduling: Array<any>;
    schedulingConditions: any;
    recordCategory: string;
};

export const PlatformSchedulingContext = React.createContext();

export const useStore = () => useContext(PlatformSchedulingContext);

const SchedulingContextProvider: React.FC<ProviderProps> = ({
    children,
    handleFormChange,
    disabled,
    platformScheduling,
    schedulingConditions,
    handleConditionsFormChange,
    recordCategory,
}) => {
    let defaultState = {
        platformScheduling,
        schedulingConditions,
        disabled,
        handleFormChange,
        handleConditionsFormChange,
        recordCategory,
        loadingBuilderConfig: false,
    };

    const [state, dispatch] = useReducer(reducer, defaultState);

    useEffect(() => {
        dispatch(
            setState({ disabled, platformScheduling, schedulingConditions }),
        );
    }, [schedulingConditions, platformScheduling, disabled]);

    return (
        <PlatformSchedulingContext.Provider value={[state, dispatch]}>
            {children}
        </PlatformSchedulingContext.Provider>
    );
};

export default SchedulingContextProvider;
