import React from "react";
import _ from "lodash";
import "./styles/_styles.scss";

const DottedContentArray = (props) => {
    const { children = [], styles = {} } = props;

    if (Array.isArray(children)) {
        return (
            <div className="meta-dotted-content-array" style={styles}>
                {children.map((child, index) => {
                    if (!_.isEmpty(child)) {
                        return (
                            <div
                                className="meta-dotted-content-array--child"
                                key={index}
                            >
                                {child}
                            </div>
                        );
                    }
                })}
            </div>
        );
    }

    return false;
};

export default DottedContentArray;
