/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
import { isEmpty, uniq } from "lodash";
import {
    CORE_FORM_ARRAY_DEFAULT_VALUE,
    CORE_FORM_NUMBER_DEFAULT_VALUE,
    CORE_FORM_STRING_DEFAULT_VALUE,
} from "../../../core/Form/constants";
import {
    AllFieldsVT,
    EpisodeVT,
    PrimaryVT,
    SeasonVT,
    TvShowVT,
} from "../config/types";
import store from "../containers/record/tv-series/store/store";
import { NuvoRecord } from "../containers/record/tv-series/types/nuvo.types";
import { Categories } from "../containers/record/tv-series/types/types";
import { Linkage, RecordResponse } from "../types/interfaces";
import randomNumber from "../utils/randomNumber";

export class TaskManagerAdapters {
    private uniqueStringArray(array: string[]): string[] {
        return uniq(array).filter((item) => item != null);
    }

    adaptLinkageRecordToTM = (item: Linkage) => {
        const _item: AllFieldsVT = {
            id: randomNumber(),
            metaId: item?.meta_id,
            title: item?.original_title,
            category: item?.category,
            seasonNo: item?.season_number,
            numberOfEpisodes:
                item?.number_of_episodes || CORE_FORM_NUMBER_DEFAULT_VALUE,
            episodeNo: item?.episode_number || CORE_FORM_NUMBER_DEFAULT_VALUE,
            originalProductionNo:
                String(item?.production_number) ||
                CORE_FORM_STRING_DEFAULT_VALUE,
            parentShow: null,
        };

        return _item;
    };

    adaptRecordToSeason(
        record: RecordResponse,
        parentShowTitle: TvShowVT["title"],
        parentShowMetaId: TvShowVT["metaId"],
    ): Required<SeasonVT> {
        let parentShowFindRes: TvShowVT;

        if (!isEmpty(parentShowTitle)) {
            parentShowFindRes = store.findByKey({
                category: Categories.Show,
                key: "title",
                value: parentShowTitle,
            });
        } else if (!isEmpty(parentShowMetaId)) {
            parentShowFindRes = store.findByKey({
                category: Categories.Show,
                key: "metaId",
                value: parentShowMetaId,
            });
        }

        const season: Required<SeasonVT> = {
            id: randomNumber(),
            title: this.extractTitleRecord(record),
            metaId: this.extractMetaId(record),
            category: this.extractCategory(record),
            brand: this.extractBrand(record),
            provenance: this.extractProvenance(record),
            originalLanguage: this.extractOriginalLanguage(record),
            countryOfOrigin: this.extractCountryOfOrigin(record),
            releaseYear: this.extractReleaseYear(record),
            seasonNo: this.extractSeasonNo(record),
            parentShow: this.convertShowToParentShow(parentShowFindRes),
            numberOfEpisodes: this.extractNumberOfEpisodes(record),
        };

        return season;
    }

    adaptNuvoRecordsToUniqueTvShowMetaIds(
        nuvoRecords: NuvoRecord[],
    ): PrimaryVT["metaId"][] {
        const tvShowMetaIds: TvShowVT["metaId"][] = nuvoRecords?.map(
            (nuvoRecord) => {
                return nuvoRecord.manualLink_parent_show_meta_id;
            },
        );

        return this.uniqueStringArray(tvShowMetaIds);
    }

    adaptNuvoRecordsToUniqueSeasonMetaIds(
        nuvoRecords: NuvoRecord[],
    ): PrimaryVT["metaId"][] {
        const seasonMetaIds: TvShowVT["metaId"][] = nuvoRecords?.map(
            (nuvoRecord) => {
                if (!isEmpty(nuvoRecord?.manualLink_parent_season_meta_id)) {
                    return nuvoRecord.manualLink_parent_season_meta_id;
                }

                return null;
            },
        );

        return this.uniqueStringArray(seasonMetaIds);
    }

    adaptRecordToTvShow(record: RecordResponse) {
        const alternative = record?.titles?.find((title) => {
            title?.type === "alternative" && title?.locale === "ENG"; // TODO: extract constant
        });

        const tvShow: Required<TvShowVT> = {
            id: record?.id || randomNumber(),
            title: this.extractTitleRecord(record),
            metaId: this.extractMetaId(record),
            category: this.extractCategory(record),
            brand: this.extractBrand(record),
            provenance: this.extractProvenance(record),
            originalLanguage: this.extractOriginalLanguage(record),
            countryOfOrigin: this.extractCountryOfOrigin(record),
            releaseYear: this.extractReleaseYear(record),
            // TODO: displayTitle
            titles: {
                aka: CORE_FORM_ARRAY_DEFAULT_VALUE, // CORE_FORM_STRING_DEFAULT_VALUE, // TODO: create extra field for this
                fka: CORE_FORM_ARRAY_DEFAULT_VALUE, // CORE_FORM_STRING_DEFAULT_VALUE, // TODO: create extra field for this
                short: CORE_FORM_STRING_DEFAULT_VALUE, // TODO: create extra field for this
                sort: CORE_FORM_STRING_DEFAULT_VALUE, // TODO: create extra field for this
                alternative:
                    alternative?.title || CORE_FORM_STRING_DEFAULT_VALUE, // TODO: create extra field for this
                release: CORE_FORM_STRING_DEFAULT_VALUE,
            },
            "titles.alternative":
                alternative?.title || CORE_FORM_STRING_DEFAULT_VALUE,
            descriptions: {
                genre:
                    // record?.descriptors?.genres?.map((d) => {
                    //     const option: CoreSelectOptionType = {
                    //         label: d?.text,
                    //         value: d?.text,
                    //     };
                    //     return option;
                    // }) || CORE_FORM_ARRAY_DEFAULT_VALUE,
                    record?.descriptors?.genres?.map((d) => d?.text) ||
                    CORE_FORM_ARRAY_DEFAULT_VALUE,
                subGenre:
                    record?.descriptors?.sub_genres?.map((d) => d?.text) ||
                    CORE_FORM_ARRAY_DEFAULT_VALUE,
                // record?.descriptors?.sub_genres?.map((d) => {
                //     const option: CoreSelectOptionType = {
                //         label: d?.text,
                //         value: d?.text,
                //     };
                //     return option;
                // }) || CORE_FORM_ARRAY_DEFAULT_VALUE,

                description100: CORE_FORM_STRING_DEFAULT_VALUE,
                description250: CORE_FORM_STRING_DEFAULT_VALUE,
                longDescription1024: CORE_FORM_STRING_DEFAULT_VALUE,
            },
            ids: {
                rms: CORE_FORM_STRING_DEFAULT_VALUE,
                legacySap: CORE_FORM_STRING_DEFAULT_VALUE,
                s4_mpm_id: CORE_FORM_STRING_DEFAULT_VALUE,
                change_record_id: CORE_FORM_STRING_DEFAULT_VALUE,
            },
            other: {
                channelOfOrigin:
                    record?.origination?.channel_origin ||
                    CORE_FORM_STRING_DEFAULT_VALUE,
            },
        };

        if (record?.category === Categories.Season) {
            return {
                ...tvShow,
                seasonNo: this.extractSeasonNo(record),
            };
        }

        return tvShow;
    }

    private extracOriginalProductionNo(
        record: NuvoRecord,
    ): EpisodeVT["originalProductionNo"] | undefined {
        if (isEmpty(record?.original_production_number)) {
            return CORE_FORM_STRING_DEFAULT_VALUE;
        }
        return String(record.original_production_number);
    }

    private extractEpisodeNo(
        record: NuvoRecord,
    ): EpisodeVT["episodeNo"] | undefined {
        if (record?.episode_number == null || record?.episode_number <= 0) {
            return CORE_FORM_NUMBER_DEFAULT_VALUE;
        }
        return record.episode_number;
    }

    private convertShowToParentShow(
        show: TvShowVT,
    ): SeasonVT["parentShow"] | null {
        return {
            id: show?.id,
            title:
                show?.title || `_PARENT_SHOW_TITLE_MISSING_${randomNumber()}`,
            metaId: show?.metaId,
        };
    }

    private extractSeasonNo(
        record: RecordResponse | NuvoRecord,
    ): SeasonVT["seasonNo"] | undefined {
        if (
            record?.season_numbering?.season_number == null || // ! IMPORTANT: Do not use isEmpty because it's a number
            record?.season_numbering?.season_number <= 0
        ) {
            return CORE_FORM_NUMBER_DEFAULT_VALUE;
        }
        return record.season_numbering.season_number;
    }

    private extractNumberOfEpisodes(
        record: RecordResponse,
    ): SeasonVT["numberOfEpisodes"] {
        const episodes: Linkage[] = [];
        record?.linkage?.forEach((linkage) => {
            if (linkage?.category === Categories.Episode) {
                episodes.push(linkage);
            }
        });

        return episodes.length;
    }

    private extractTitleRecord(
        record: RecordResponse | NuvoRecord,
    ): PrimaryVT["title"] {
        return !isEmpty(record?.original_title)
            ? record.original_title
            : `_MISSING_TITLE_${randomNumber()}`;
    }

    private extractMetaId(record: RecordResponse): PrimaryVT["metaId"] {
        return !isEmpty(record?.meta_id)
            ? record.meta_id
            : CORE_FORM_STRING_DEFAULT_VALUE;
    }

    private extractCategory(
        record: RecordResponse | NuvoRecord,
    ): PrimaryVT["category"] {
        return !isEmpty(record?.category)
            ? record.category
            : CORE_FORM_STRING_DEFAULT_VALUE;
    }

    private extractBrand(
        record: RecordResponse | NuvoRecord,
    ): PrimaryVT["brand"] {
        return !isEmpty(record?.origination?.brand)
            ? record.origination.brand
            : CORE_FORM_STRING_DEFAULT_VALUE;
    }

    private extractProvenance(
        record: RecordResponse | NuvoRecord,
    ): PrimaryVT["provenance"] {
        return !isEmpty(record?.origination?.provenance)
            ? record.origination.provenance
            : CORE_FORM_STRING_DEFAULT_VALUE;
    }

    private extractOriginalLanguage(
        record: RecordResponse,
    ): PrimaryVT["originalLanguage"] {
        return !isEmpty(record?.locale)
            ? record.locale
            : CORE_FORM_STRING_DEFAULT_VALUE;
    }

    private extractCountryOfOrigin(
        record: RecordResponse,
    ): PrimaryVT["countryOfOrigin"] {
        return !isEmpty(record?.country_iso)
            ? record.country_iso
            : CORE_FORM_STRING_DEFAULT_VALUE;
    }

    private extractReleaseYear(
        record: RecordResponse | NuvoRecord,
    ): PrimaryVT["releaseYear"] {
        return record?.first_release_year &&
            Number(record?.first_release_year) > 0
            ? record.first_release_year
            : CORE_FORM_NUMBER_DEFAULT_VALUE;
    }

    adaptRecordToMandatoryAndOptionalFields = (
        record: Partial<AllFieldsVT> = {},
    ) => {
        // console.log("adaptRecordToMandatoryAndOptionalFields: ", record);
        // TODO: this SHOULD be generated automatically
        // TODO: create extractor functions like above
        const result: AllFieldsVT = {
            ...record,
            id: record?.id || randomNumber(),
            category: record?.category || CORE_FORM_STRING_DEFAULT_VALUE,
            countryOfOrigin:
                record?.countryOfOrigin || CORE_FORM_STRING_DEFAULT_VALUE,
            originalLanguage:
                record?.originalLanguage || CORE_FORM_STRING_DEFAULT_VALUE,
            provenance: record?.provenance || CORE_FORM_STRING_DEFAULT_VALUE,
            displayTitle:
                record?.displayTitle || CORE_FORM_STRING_DEFAULT_VALUE,
            releaseYear: record?.releaseYear || CORE_FORM_NUMBER_DEFAULT_VALUE,
            brand: record?.brand || CORE_FORM_STRING_DEFAULT_VALUE,
            title: record?.title || CORE_FORM_STRING_DEFAULT_VALUE,
            "titles.aka": record?.titles?.aka || CORE_FORM_ARRAY_DEFAULT_VALUE,
            "titles.fka": record?.titles?.fka || CORE_FORM_ARRAY_DEFAULT_VALUE,
            "titles.short":
                record?.titles?.short || CORE_FORM_STRING_DEFAULT_VALUE,
            "titles.sort":
                record?.titles?.sort || CORE_FORM_STRING_DEFAULT_VALUE,
            "titles.alternative":
                record?.titles?.alternative || CORE_FORM_STRING_DEFAULT_VALUE,
            "titles.release":
                record?.titles?.release || CORE_FORM_STRING_DEFAULT_VALUE,
            "descriptions.genre":
                record?.descriptions?.genre || CORE_FORM_STRING_DEFAULT_VALUE,
            "descriptions.subGenre":
                record?.descriptions?.subGenre ||
                CORE_FORM_STRING_DEFAULT_VALUE,
            "descriptions.description100":
                record?.descriptions?.description100 ||
                CORE_FORM_STRING_DEFAULT_VALUE,
            "descriptions.description250":
                record?.descriptions?.description250 ||
                CORE_FORM_STRING_DEFAULT_VALUE,
            "descriptions.longDescription1024":
                record?.descriptions?.longDescription1024 ||
                CORE_FORM_STRING_DEFAULT_VALUE,

            "ids.rms": record?.ids?.rms || CORE_FORM_STRING_DEFAULT_VALUE,
            "ids.legacySap":
                record?.ids?.legacySap || CORE_FORM_STRING_DEFAULT_VALUE,
            "ids.s4_mpm_id":
                record?.ids?.s4_mpm_id || CORE_FORM_STRING_DEFAULT_VALUE,
            "ids.change_record_id":
                record?.ids?.change_record_id || CORE_FORM_STRING_DEFAULT_VALUE,

            "other.channelOfOrigin":
                record?.other?.channelOfOrigin ||
                CORE_FORM_STRING_DEFAULT_VALUE,

            collection: record?.collection || null,
        };

        return result;
    };

    adaptTvSeriesRecordToCoreTable = (record: AllFieldsVT) => {
        const result = {
            ...this.adaptRecordToMandatoryAndOptionalFields(record),
            childCategory:
                record?.childCategory || CORE_FORM_STRING_DEFAULT_VALUE,
            sequences: record?.sequences || CORE_FORM_ARRAY_DEFAULT_VALUE,
            seasonEpisodeInheritance:
                record?.seasonEpisodeInheritance ||
                CORE_FORM_ARRAY_DEFAULT_VALUE,
            parentShow: record?.parentShow || null,
            parentSeason: record?.parentSeason || null,
            parentModularEpisode: record?.parentModularEpisode || null,
            seasonNo: record?.seasonNo || CORE_FORM_NUMBER_DEFAULT_VALUE,
            episodeNo: record?.episodeNo || CORE_FORM_NUMBER_DEFAULT_VALUE,
            originalProductionNo:
                record?.originalProductionNo || CORE_FORM_STRING_DEFAULT_VALUE,
        };

        return result;
    };
}
export default TaskManagerAdapters;

export const adapters = new TaskManagerAdapters();
