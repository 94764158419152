import React from "react";
import "./styles/_copyattributes.scss";

const CopyAttributesCancelBar = (props) => {
    const {
        disabled,
        copy = {},
        handleCancelClick = () => {},
        handleCopyAttributes = () => {},
    } = props;

    return (
        <div className="localisation-language-manager-copy-atrributes-cancel-bar">
            <div>
                <a data-cancel onClick={handleCancelClick.bind(this)}>
                    {copy.cancel || "Cancel"}
                </a>

                <a
                    data-copy-attributes-to-selected-languages
                    onClick={
                        !disabled ? handleCopyAttributes.bind(this) : () => {}
                    }
                    data-disabled={disabled ? "true" : "false"}
                    id="localisation-language-manager-copy-to-selected-language"
                >
                    {copy.copyAttributesToSelectedLanguages ||
                        "Copy attributes to selected languages "}
                </a>
            </div>
        </div>
    );
};

export default CopyAttributesCancelBar;
