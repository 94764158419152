import React from "react";
import _ from "lodash";
import styles from "../styles";
import isIndexInArray from "../../Helpers/isIndexInArray";
import GetMetaGoogleTranslateLanguages from "../../Languages/GetMetaGoogleTranslateLanguages";
import GoogleTranslateApi from "../../GoogleTranslateApi";
import GoogleTranslateMultipleApi from "../../GoogleTranslateApi/Multiple";
import update from "react-addons-update";
import Index from "./Index";
import deepcopy from "deepcopy";
import Notification from "../../Notification";
import FullScreenLoadingSpinner from "../../FullScreenLoadingSpinner";
import RegionalItem from "./RegionalItem";
import UpdatedAt from "../../UpdatedAt";
import { getRegionalStatusesOptions, getSelectedDefaultRegionalStatus } from '../../../models/ClientManaged/regionalStatusModel'

export default class Titles extends Index {
    constructor(props) {
        super(props);

        let data = [];
        if (Array.isArray(this.props.data)) {
            data = this.props.data;
        }

        let sectionOptions = [];
        if (Array.isArray(this.props.sectionOptions)) {
            sectionOptions = this.props.sectionOptions;
        }

        /**
         * @var object
         */
        this.copy = this.props.copy || {};

        /**
         * @var object
         */
        this.styles = styles(this.props);

        /**
         * @var object
         */
        this.state = {
            data,
            sectionOptions,
            notification: {},
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!_.isEqual(this.props.data, nextProps.data)) {
            return true;
        }

        if (!_.isEqual(nextProps.validationKeys, this.props.validationKeys)) {
            return true;
        }

        if (!_.isEqual(nextProps.disabled, this.props.disabled)) {
            return true;
        }

        if (!_.isEqual(nextProps.options, this.props.options)) {
            return true;
        }

        if (!_.isEqual(nextProps.category, this.props.category)) {
            return true;
        }

        if (!_.isEqual(nextProps.sectionOptions, this.props.sectionOptions)) {
            return true;
        }

        if (!_.isEqual(nextProps.languageIso, this.props.languageIso)) {
            return true;
        }

        if (!_.isEqual(nextState, this.state)) {
            return true;
        }

        return false;
    }

    componentDidMount() {
        if (!Array.isArray(this.state.regionalStatuses)) {
            getRegionalStatusesOptions(this.props.clientFeatures, this.props.cmsData).then((regionalStatuses) => {
                this.setState({
                    regionalStatuses,
                });
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            !_.isEqual(this.props.data, prevProps.data) &&
            !_.isEqual(this.props.data, prevState.data)
        ) {
            if (Array.isArray(this.props.data)) {
                this.setStateValue("data", this.props.data);
            }
        }

        // sectionOptions
        if (!_.isEqual(this.props.sectionOptions, prevProps.sectionOptions)) {
            if (Array.isArray(this.props.sectionOptions)) {
                this.setStateValue("sectionOptions", this.props.sectionOptions);
            }
        }
    }

    getTitle(type) {
        const { data } = this.state;

        const { languageIso } = this.props;

        let result = {};

        if (Array.isArray(data)) {
            const title = data.find(
                (item) => item.locale == languageIso && item.type == type,
            );

            if (typeof title == "object" && title) {
                result = title;
            }
        }

        return result;
    }

    getDisplayStyles(data) {
        const { disabled } = this.props;

        let result = {};

        if (_.isEmpty(data) && disabled) {
            result = {
                display: "none",
            };
        }

        return result;
    }

    applyTranslatedLanguage(props) {
        const { value } = props;

        const metaLocale = this.getInfoFromTranslatedLocale(props);

        if (metaLocale.value) {
            this.handleChange({
                type: props.key,
                key: "title",
                value,
                locale: metaLocale.value,
                api_name: "Google Translate",
                source: "api",
                status: "proposed",
            });
        }
    }

    applyTranslatedLanguages(props) {
        const { items = [] } = props;

        const { data = [] } = this.state;

        let result = [];

        items.forEach((item) => {
            const metaLocale = this.getInfoFromTranslatedLocale({
                locale: item.locale,
            });

            if (metaLocale.value) {
                result.push({
                    type: props.key,
                    title: item.text,
                    api_name: "Google Translate",
                    source: "api",
                    status: "proposed",
                    locale: metaLocale.value,
                    comment: null,
                    release: false,
                    display: false,
                });
            }
        });

        const updatedData = update(data, { $push: [...result] });
        this.updataDataWithChange(updatedData);
    }

    getInfoFromTranslatedLocale(props) {
        const { locale = "" } = props;

        const { options = {} } = this.props;

        const { spoken_languages = [] } = options;

        const getLanguageCode = (item) => {
            let languageCode = "";
            if (typeof item.iso_639_1 == "string") {
                languageCode = item.iso_639_1.toLowerCase();
            }

            return languageCode;
        };

        return (
            spoken_languages.find(
                (item) => getLanguageCode(item) == locale.toLowerCase(),
            ) || {}
        );
    }

    async handleGooleTranslateClick(props) {
        const { locale, label } = props;

        const { data = [] } = this.state;

        const { options = {} } = this.props;

        const { spoken_languages = [], top_written_languages = [] } = options;

        const setFetching = () => {
            this.setState({
                fetchingTranslations: true,
            });
        };

        const setNotification = (props) => {
            this.setState({
                fetchingTranslations: false,
                notification: {
                    title: props.title,
                    description: props.description,
                    intercationStatus: props.intercationStatus || "alert",
                    onClick: () => {
                        this.setState({
                            notification: {},
                        });
                    },
                },
            });
        };

        const setTechnicalError = () => {
            setNotification({
                title: "Technical Error",
                description: "Failed to translate language, please try again.",
            });
        };

        // translate all empty languages
        if (locale == "empty_values") {
            // get all empty locales
            const metaGoogleTranslateLanguages =
                GetMetaGoogleTranslateLanguages({
                    top_written_languages,
                    languages: spoken_languages,
                });

            const populatedLocales = data
                .filter((item) => item.title && item.type == props.key)
                .map((item) => item.locale);

            const emptyLocales = metaGoogleTranslateLanguages
                .filter((item) => !isIndexInArray(populatedLocales, item.value))
                .map((item) => item.iso_639_1);

            if (!_.isEmpty(emptyLocales)) {
                setFetching();

                this.handleGoogleTranslateApi({
                    ...props,
                    locales: emptyLocales,
                    multiple: true,
                    handleSuccess: (response) => {
                        // set all empty languages
                        if (!_.isEmpty(response.success)) {
                            this.applyTranslatedLanguages({
                                key: props.key,
                                items: response.success,
                            });

                            this.setState({
                                fetchingTranslations: false,
                            });
                        }

                        // if there were some errors, then display an error message
                        if (!_.isEmpty(response.error)) {
                            return setNotification({
                                title: "Google Translate",
                                description:
                                    "Some languages failed to be translated, please try again.",
                            });
                        }

                        setNotification({
                            title: "Google Translate",
                            description:
                                "Translation complete on all empty languages",
                            intercationStatus: "success",
                        });
                    },
                    handleError: () => setTechnicalError(),
                });
            }

            return;
        }

        setFetching();

        const language = this.getInfoFromTranslatedLocale({
            locale,
        });

        // translate single language
        this.handleGoogleTranslateApi({
            ...props,
            handleSuccess: (value) => {
                this.applyTranslatedLanguage({
                    ...props,
                    value,
                });

                setNotification({
                    title: "Google Translate",
                    description: `Translation complete to ${
                        language.text || ""
                    } ${label}`,
                    intercationStatus: "success",
                });
            },
            handleError: () => setTechnicalError(),
        });
    }

    handleGoogleTranslateApi(props) {
        if (props.multiple) {
            return GoogleTranslateMultipleApi({
                ...props,
            });
        }

        GoogleTranslateApi({
            ...props,
        });
    }

    handleChange(props) {
        const {
            type,
            key,
            value,
            locale = this.props.languageIso,
            api_name = null,
            source,
            status,
        } = props;

        const {
            clientFeatures = {},
        } = this.props;

        const defaultStatus = getSelectedDefaultRegionalStatus(clientFeatures, this.state.regionalStatuses)
        const { data = [] } = this.state;

        if (Array.isArray(data)) {
            const titleIndex = _.findIndex(data, function (t) {
                return t.type == type && t.locale == locale;
            });

            let updatedData = deepcopy(data);

            // Update
            if (titleIndex !== -1) {
                let title = deepcopy(data[titleIndex]);

                title[key] = value;

                if (key !== "comment" && !value) {
                    title.deleted = true;
                }

                if (value) {
                    delete title.deleted;
                }

                updatedData = update(updatedData, {
                    [titleIndex]: {
                        $set: title,
                    },
                });

                if (key !== "comment" && !value) {
                    updatedData = update(updatedData, {
                        [titleIndex]: {
                            ["deleted"]: {
                                $set: true,
                            },
                        },
                    });
                }
            }

            // create
            if (titleIndex === -1) {
                let title = {
                    type,
                    title: null,
                    comment: null,
                    locale,
                    status: status || defaultStatus,
                    source: source || "open",
                };

                if (typeof api_name == "string") {
                    title.api_name = api_name;
                }

                title[key] = value;

                updatedData = update(data, {
                    $push: [title],
                });
            }

            this.updataDataWithChange(updatedData);
        }
    }

    updataDataWithChange(updatedData) {
        const { handleFormChange } = this.props;

        this.setState(
            {
                data: updatedData,
            },
            () => {
                if (typeof handleFormChange == "function") {
                    handleFormChange(updatedData);
                }
            },
        );
    }

    renderNoneAdded() {
        return false;
    }

    renderTitle(props) {
        const {
            label,
            placeholder,
            key,
            data = {},
            maxCharacter,
            counter = false,
            readOnly = false,
        } = props;

        const {
            validationKeys = [],
            handleOnBlur,
            disabled,
            handleRegionalTitleSwitchChange,
            clientFeatures = {},
        } = this.props;

        const { googleTranslate, titles = {} } = clientFeatures;

        const { presentation_title } = titles;

        return (
            <div className={`meta-regional-title-${key}`}>
                <RegionalItem
                    inputKey="title"
                    dataSource="titles"
                    label={label}
                    placeholder={placeholder}
                    name={`${key}_title`}
                    data={data}
                    disabled={disabled}
                    readOnly={readOnly}
                    maxCharacter={maxCharacter}
                    counter={counter}
                    validationKeys={validationKeys}
                    type={key}
                    presentation_title={presentation_title}
                    handleRegionalTitleSwitchChange={
                        handleRegionalTitleSwitchChange
                    }
                    googleTranslateItems={this.getGoogleTranslateItems(key)}
                    googleTranslate={googleTranslate}
                    handleOnBlur={handleOnBlur.bind(this, key)}
                    handleChange={this.handleChange.bind(this)}
                    handleGooleTranslateClick={this.handleGooleTranslateClick.bind(
                        this,
                    )}
                    languageIso={this.props.languageIso || null}
                    statusOptions={this.state.regionalStatuses}
                />
            </div>
        );
    }

    renderUpdatedAt() {
        const { updatingLogs = {}, clientDataStructure } = this.props;

        return (
            <UpdatedAt
                updatingLogs={updatingLogs}
                clientDataStructure={clientDataStructure}
                sectionKey="titles"
            />
        );
    }

    renderContent() {
        const { sectionOptions, notification, fetchingTranslations } =
            this.state;

        const { copy = {}, category } = this.props;

        let title = this.getTitle("title");
        let alternative = this.getTitle("alternative");
        let short = this.getTitle("short");
        let seo = this.getTitle("seo");
        let title19 = this.getTitle("19");
        let title35 = this.getTitle("35");
        let title40 = this.getTitle("40");
        let title50 = this.getTitle("50");

        let titleLabel = copy.title || "Title";
        let titlePlaceholder = copy.enterTitle || "Enter Title";

        if (category == "Game") {
            titleLabel = copy.gameName || "Game Name";
            titlePlaceholder = copy.enterGameName || "Enter Game Name";
        }

        const marginTop = { marginTop: "1.5rem" };

        return (
            <React.Fragment>
                {this.renderUpdatedAt()}

                <div style={this.styles.flexMarginContainer}>
                    {isIndexInArray(sectionOptions, "Title") && (
                        <div
                            style={Object.assign(
                                {},
                                this.styles.flexMarginItemDouble,
                                marginTop,
                            )}
                        >
                            {this.renderTitle({
                                label: titleLabel,
                                placeholder: titlePlaceholder,
                                key: "title",
                                data: title,
                                maxCharacter: 255,
                                counter: true,
                            })}
                        </div>
                    )}

                    {isIndexInArray(sectionOptions, "AlternativeTitle") && (
                        <div
                            style={Object.assign(
                                {},
                                this.styles.flexMarginItemDouble,
                                marginTop,
                            )}
                        >
                            {this.renderTitle({
                                label:
                                    copy.alternativeTitle ||
                                    "Alternative Title",
                                placeholder:
                                    copy.enterAlternativeTitle ||
                                    "Enter Alternative Title",
                                key: "alternative",
                                data: alternative,
                                maxCharacter: 255,
                                counter: true,
                            })}
                        </div>
                    )}

                    {isIndexInArray(sectionOptions, "ShortTitle") && (
                        <div
                            style={Object.assign(
                                {},
                                this.styles.flexMarginItemDouble,
                                marginTop,
                                { ...this.getDisplayStyles(short) },
                            )}
                        >
                            {this.renderTitle({
                                label: copy.shortTitle || "Short Title",
                                placeholder:
                                    copy.enterAlternativeTitle ||
                                    "Enter Alternative Title",
                                key: "short",
                                data: short,
                                maxCharacter: 255,
                                counter: true,
                            })}
                        </div>
                    )}

                    {isIndexInArray(sectionOptions, "Title19") && (
                        <div
                            style={Object.assign(
                                {},
                                this.styles.flexMarginItemDouble,
                                { ...this.getDisplayStyles(title19) },
                                marginTop,
                            )}
                        >
                            {this.renderTitle({
                                label: copy.title19 || "Title (19)",
                                placeholder: copy.enterTitle || "Enter Title",
                                key: "19",
                                data: title19,
                                maxCharacter: 19,
                                counter: true,
                            })}
                        </div>
                    )}

                    {isIndexInArray(sectionOptions, "Title35") && (
                        <div
                            style={Object.assign(
                                {},
                                this.styles.flexMarginItemDouble,
                                { ...this.getDisplayStyles(title35) },
                                marginTop,
                            )}
                        >
                            {this.renderTitle({
                                label: copy.title35 || "Title (35)",
                                placeholder: copy.enterTitle || "Enter Title",
                                key: "35",
                                data: title35,
                                maxCharacter: 35,
                                counter: true,
                            })}
                        </div>
                    )}

                    {isIndexInArray(sectionOptions, "Title40") && (
                        <div
                            style={Object.assign(
                                {},
                                this.styles.flexMarginItemDouble,
                                { ...this.getDisplayStyles(title40) },
                                marginTop,
                            )}
                        >
                            {this.renderTitle({
                                label: copy.title40 || "Title (40)",
                                placeholder: copy.enterTitle || "Enter Title",
                                key: "40",
                                data: title40,
                                maxCharacter: 40,
                                counter: true,
                            })}
                        </div>
                    )}

                    {isIndexInArray(sectionOptions, "Title50") && (
                        <div
                            style={Object.assign(
                                {},
                                this.styles.flexMarginItemDouble,
                                { ...this.getDisplayStyles(title50) },
                                marginTop,
                            )}
                        >
                            {this.renderTitle({
                                label: copy.title50 || "Title (50)",
                                placeholder: copy.enterTitle || "Enter Title",
                                key: "50",
                                data: title50,
                                maxCharacter: 50,
                                counter: true,
                            })}
                        </div>
                    )}
                </div>

                {isIndexInArray(sectionOptions, "SeoTitle") && (
                    <div
                        style={Object.assign(
                            {},
                            this.styles.marginItem,
                            marginTop,
                            { ...this.getDisplayStyles(seo) },
                        )}
                    >
                        {this.renderTitle({
                            label: copy.seoTitle || "SEO Title",
                            placeholder:
                                copy.enterShortTitle || "Enter SEO Title",
                            key: "seo",
                            data: seo,
                            maxCharacter: 190,
                            counter: true,
                        })}
                    </div>
                )}

                <Notification {...notification} />

                {fetchingTranslations && <FullScreenLoadingSpinner />}
            </React.Fragment>
        );
    }
}
