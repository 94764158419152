import _ from "lodash";
import {
    CLIENT_MANAGED_LANGUAGES,
    DUBBING_TRACKS,
    SUBTITLES,
    ADDITIONAL_SPOKEN_LANGUAGES,
    IMAGES_LANGUAGES,
    LANGUAGE_KEY_PRIMARY_LANGUAGE,
    AWARDS,
    CAST_AND_CREW,
    TITLES_AND_SYNOPSES,
} from "./options";
import { GetFromLocalStorage } from "../Storage/localStorage";
import clientManagedController from "./clientManagedController";

export const SPOKEN_LANGUAGES = [
    DUBBING_TRACKS,
    ADDITIONAL_SPOKEN_LANGUAGES,
    LANGUAGE_KEY_PRIMARY_LANGUAGE,
];

export const WRITTEN_LANGUAGES = [
    SUBTITLES,
    IMAGES_LANGUAGES,
    AWARDS,
    CAST_AND_CREW,
    TITLES_AND_SYNOPSES,
];

export const GROUPED_BY_REGION = [TITLES_AND_SYNOPSES];

export const SCRIPT_LANGUAGES = [AWARDS, CAST_AND_CREW];

export function isSpokenLanguage(languageKey) {
    return SPOKEN_LANGUAGES.includes(languageKey);
}

export function shouldGroupByRegion(languageKey) {
    return GROUPED_BY_REGION.includes(languageKey);
}

export function isScriptLanguage(languageKey) {
    return SCRIPT_LANGUAGES.includes(languageKey);
}

export function isClientManagedLanguages(props) {
    const { clientFeatures = {}, languageKey } = props;

    const { managedLists = [] } = clientFeatures;

    return managedLists.includes(languageKey);
}

function getLanguages(props) {
    const { options = {}, type, option, clientFeatures = {} } = props;

    const { managedLists } = clientFeatures;

    const { client_managed_languages = {} } = options;

    const clientManagedLanguages = client_managed_languages[type] || {};
    const languagesList = clientManagedLanguages[option] || [];

    return {
        languagesList,
        managedLists,
    };
}

export function ReplaceWithAKAlanguage(props) {
    const { languages = [], option } = props;

    const { languagesList, managedLists } = getLanguages(props);

    let result = [];

    if (Array.isArray(managedLists) && managedLists.includes(option)) {
        languages.forEach((language) => {
            if (typeof language == "string") {
                let akaLanguage = languagesList.find(
                    (item) => item.name == language && Array.isArray(item.aka),
                );

                if (akaLanguage && !_.isEmpty(akaLanguage.aka)) {
                    return result.push(...akaLanguage.aka);
                }
            }

            result.push(language);
        });
    }

    return result;
}

export function ConcatAKALanguages(props) {
    const { languages = [], option } = props;

    const { languagesList, managedLists } = getLanguages(props);

    let result = [];

    if (Array.isArray(managedLists) && managedLists.includes(option)) {
        languages.forEach((language) => {
            if (typeof language == "string") {
                let akaLanguage = languagesList.find(
                    (item) => item.name == language && Array.isArray(item.aka),
                );

                if (akaLanguage && !_.isEmpty(akaLanguage.aka)) {
                    result.push(...akaLanguage.aka);
                }
            }
        });
    }

    return [...languages, ...result];
}

export function ApplyAKALanguages(props) {
    const { languages = [], option } = props;

    const { languagesList, managedLists } = getLanguages(props);

    let result = [];

    if (Array.isArray(managedLists) && managedLists.includes(option)) {
        languages.forEach((language) => {
            if (typeof language == "string") {
                let akaLanguage = languagesList.find(
                    (item) =>
                        item.aka &&
                        Array.isArray(item.aka) &&
                        item.aka.includes(language),
                );

                if (akaLanguage && akaLanguage.name) {
                    result.push(akaLanguage.name);
                }
            }
        });
    }

    return [...languages, ...result];
}

export default function ClientManagedLanguages(props) {
    const {
        options = {},
        spoken,
        fallback = [],
        languageKey,
        groupByRegion,
    } = props;

    // local languages
    const local = GetFromLocalStorage(CLIENT_MANAGED_LANGUAGES);
    const isClientManaged = isClientManagedLanguages(props);

    let result = clientManagedController({
        clientManagedOptions: options.client_managed_languages || [],
        fallback,
        localStorage: !_.isEmpty(local) ? JSON.parse(local) : [],
        isClientManaged,
    });

    if (isClientManaged) {
        result = tranformClientManagedLanguages({
            client_managed_languages: result,
            languageKey,
            groupByRegion,
            spoken,
        });
    }

    return result;
}

export function tranformClientManagedLanguages(props) {
    const { client_managed_languages = {}, languageKey, groupByRegion } = props;

    const {
        spoken_languages = [],
        written_languages = [],
        localisation_order_languages = [],
    } = client_managed_languages;

    let result = [];
    let spoken = props.spoken || isSpokenLanguage(languageKey);

    if (languageKey === "localisation_orders") {
        result = localisation_order_languages;
    } else {
        const languages = spoken ? spoken_languages : written_languages;
        result = languages[languageKey] || [];
    }

    if (groupByRegion && !_.isEmpty(result)) {
        const filtered = result.filter((item) => item.region);
        result = _.groupBy(filtered, "region");
    }

    return result;
}
