import { v4 as uuidv4 } from "uuid";

export const defaultActiveRecord = false;
export const defaultBackgroundProcess = false;
export const defaultBadResponse = false;
export const defaultBreakpoint = "large";
export const defaultConvertingData = {};
export const defaultEidrSearch = 0;
export const defaultError = false;
export const defaultHoverRecord = false;
export const defaultInheritanceData = {};
export const defaultInheritanceFilters = [];
export const defaultNewRecord = false;
export const defaultNewRecordEdit = false;
export const defaultOriginalNewRecord = false;
export const defaultOriginalRecord = false;
export const defaultRecordChanges = {};
export const defaultRecordChangesV2 = {};
export const defaultRecordChangesUuid = uuidv4();
export const defaultRecordData = {};
export const defaultRecordOptions = {};
export const defaultSavingRecord = false;
export const defaultSearchData = {};
export const defaultUnsavedChanges = false;
export const defaultUrlComponents = {};

export default {
    activeRecord: defaultActiveRecord,
    backgroundProcess: defaultBackgroundProcess,
    badResponse: defaultBadResponse,
    breakpoint: defaultBreakpoint,
    convertingData: defaultConvertingData,
    eidrSearch: defaultEidrSearch,
    error: defaultError,
    hoverRecord: defaultHoverRecord,
    inheritanceData: defaultInheritanceData,
    inheritanceFilters: defaultInheritanceFilters,
    newRecord: defaultNewRecord,
    newRecordEdit: defaultNewRecordEdit,
    originalNewRecord: defaultOriginalNewRecord,
    originalRecord: defaultOriginalRecord,
    recordChanges: defaultRecordChanges,
    recordChangesV2: defaultRecordChangesV2,
    recordChangesUuid: defaultRecordChangesUuid,
    recordData: defaultRecordData,
    recordOptions: defaultRecordOptions,
    savingRecord: defaultSavingRecord,
    searchData: defaultSearchData,
    snsavedChanges: defaultUnsavedChanges,
    urlComponents: defaultUrlComponents,
};
