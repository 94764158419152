import React from "react";
import BubbleList from "../../../BubbleList";
import DoubleGridList from "../../../CheckboxTag/DoubleGridList";
import DataFetch from "./DataFetch";
import CategoryTags from "../../../CategoryTags";
import ShowLabelOfSeasonsEpisodesCounts from "./src/ShowLabelOfSeasonsEpisodesCounts";
import OriginalTitleDotYearText from "./src/OriginalTitleDotYearText";
import BubleListSearchDataList from "../../../AddTitleToCollection/src/BubleListSearchDataList";
import FetchIncludingList from "./helpers/FetchIncludingList";
import DefaultIncludingListShow from "./src/data/DefaultIncludingListShow";
import CheckIncludingTypesListOnSingle from "./helpers/CheckIncludingTypesListOnSingle";

export default class LinkShow extends React.Component {
    constructor(props) {
        super(props);

        const { clientDataStructure = {} } = props;

        const { linkages = [] } = clientDataStructure;

        this.includingTypesList = FetchIncludingList({
            category: CategoryTags.Show.short,
            configs: linkages,
            fallback: DefaultIncludingListShow,
        });

        this.state = {
            resultData: [],
            searchValue: [],
            searchList: [],
            searchListLinks: [],
            readySearchSelection: false,
            showIncludingType:
                CheckIncludingTypesListOnSingle(this.includingTypesList) ||
                "show_only",
        };

        this.updateSearchList = this.updateSearchList.bind(this);
        this.handleIncludeType = this.handleIncludeType.bind(this);
    }

    onChange(data) {
        this.setState({ resultData: data });
        this.props.onChange(data);
    }

    updateSearchList(data) {
        const list = BubleListSearchDataList({ searchDataList: data });

        let prevSearchValueLinks = this.state.searchListLinks;
        let searchValueLinks = prevSearchValueLinks.concat(data);

        this.setState({ searchList: list, searchListLinks: searchValueLinks });
    }

    handleSearchSelection(value) {
        value = value.filter((item) => !item.deleted);
        value = value.map((item) => {
            if (!item.meta_id) item.meta_id = item.value;
            return item;
        });

        let records = DataFetch.recordsFromArray({
            records: this.state.searchListLinks,
            searchable: value,
        });

        let completeValues = records.map((item) => {
            let seasonEpisodesLabels = ShowLabelOfSeasonsEpisodesCounts({
                parentShowLinkages: item.linkage,
            });

            return {
                text: OriginalTitleDotYearText({ ...item }),
                value: item.meta_id,
                suffix: seasonEpisodesLabels.scopeLabel,
                meta_id: item.meta_id,
            };
        });

        this.setState({
            searchValue: completeValues,
            readySearchSelection: completeValues.length > 0,
        });
        this.handleResultData(value, this.state.showIncludingType);
    }

    handleIncludeType(type) {
        this.setState({ showIncludingType: type });
        this.handleResultData(this.state.searchValue, type);
    }

    handleResultData(searchValue, showIncludingType) {
        let shows = DataFetch.recordsFromArray({
            records: this.state.searchListLinks,
            searchable: searchValue,
        });

        if (showIncludingType === "show_only") {
            this.onChange(shows);
        } else if (showIncludingType === "show_season_episodes") {
            let records = [];
            shows.map((item) => {
                records = records.concat(
                    DataFetch.episodesFormLinkage({
                        linkage: item.linkage,
                    }),
                );

                records = records.concat(
                    DataFetch.seasonsFromLinkage({
                        meta_id: item.meta_id || null,
                        records: shows,
                    }),
                );
            });

            records = records.concat(shows);

            this.onChange(records);
        }
    }

    render() {
        return (
            <div className="new-linkage-show-search">
                <BubbleList
                    id="new-linkage-show-search-input"
                    label="Search by Title"
                    background="rgb(28, 101, 77)"
                    color="white"
                    deleteWhite={true}
                    emptyPlaceholder="Start typing Show name"
                    handleFormChange={(items) =>
                        this.handleSearchSelection(items)
                    }
                    filterStaticDataList={true}
                    useEnterButtonOnSelect={false}
                    readOnly={false}
                    data={this.state.searchValue}
                    datalist={this.state.searchList}
                    handleChange={(search) => {
                        DataFetch.links({
                            searchQuery: search,
                            searchUrl: this.props.searchUrl,
                            recordType: CategoryTags.Show.short,
                            onResult: this.updateSearchList,
                        });
                        this.setState({ readySearchSelection: false });
                    }}
                    openOnClick={false}
                    wrapperStyles={{
                        marginTop: "1.8rem",
                    }}
                />

                {this.state.readySearchSelection &&
                    this.includingTypesList.length > 1 && (
                        <DoubleGridList
                            id="new-linkage-show-grid-list-options"
                            label="Include Linked Seasons and Episodes "
                            items={this.includingTypesList}
                            styles={{
                                margin: "1.8rem 0 0",
                            }}
                            selected={this.state.showIncludingType}
                            handleSelection={this.handleIncludeType}
                        />
                    )}
            </div>
        );
    }
}
