import "./styles/style.scss";
import React from "react";

const DividerLine = (props) => {
    const { styles = {}, text = '' } = props;

    return ( 
        <div className="meta-divider-line" style={styles}>
            {text && <span>{text}</span>}
        </div>
    )
};

export default DividerLine;
