import ValidateFirstReleaseYear from "./ValidateFirstReleaseYear";
import ValidateCountryOfOrigin from "./ValidateCountryOfOrigin";
import ValidateGenres from "./ValidateGenres";
import ValidateSubGenres from "./ValidateSubGenres";
import ValidateProperties from "./ValidateProperties";
import ValidateEidr from "./ValidateEidr";
import ValidateAssets from "./ValidateAssets";
import ValidateAgeGuidance from "./ValidateAgeGuidance";
import ValidateOrigination from "./ValidateOrigination";
import ValidateProvenance from "./ValidateProvenance";
import ValidatePrimaryLanguage from "./ValidatePrimaryLanguage";
import ValidateBrand from "./ValidateBrand";
import ValidateRecordType from "./ValidateRecordType";
import ValidateChannelOfOrigin from "./ValidateChannelOfOrigin";
import ValidateLinkedFranchise from "./ValidateLinkedFranchise";
import { validateTitles } from "../../models/Validation/regional/validateTitles";
import { validateSynopses } from "../../models/Validation/regional/validateSynopses";
import { validateIdentifiers } from "../../models/Validation/Identifiers";
import ValidateOriginationBase from "./builder/OriginationBase";
import ValidateCustomFields from "./builder/CustomFields";
import ValidateNumbering from "./builder/AlternativeNumbering"

export default function General(options = {}) {
    let result = {
        result: true,
        notification: {},
        validationKeys: [],
    };

    //Linked Franchise Collection
    result = ValidateLinkedFranchise(result, options);

    //Channel of Origin
    result = ValidateChannelOfOrigin(result, options);

    //Brand
    result = ValidateBrand(result, options);

    //Primary Language
    result = ValidatePrimaryLanguage(result, options);

    //Provenance
    result = ValidateProvenance(result, options);

    // First release year
    result = ValidateFirstReleaseYear(result, options);

    // Country of Origin
    result = ValidateCountryOfOrigin(result, options);

    // Identifiers
    result = validateIdentifiers({ result, options });

    // Regional
    result = validateTitles({ result, options });
    result = validateSynopses({ result, options });

    // Genrres
    result = ValidateGenres(result, options);

    // Sub Genrres
    result = ValidateSubGenres(result, options);

    // EIDR
    result = ValidateEidr(result, options);

    // Assets
    result = ValidateAssets(result, options);

    // Properties
    result = ValidateProperties(result, options);

    result = ValidateAgeGuidance(result, options);

    //Record Type
    result = ValidateRecordType(result, options);

    //Origination
    result = ValidateOrigination({ result, options });
    result = ValidateOriginationBase(result, options);
    result = ValidateCustomFields(result, options)
    result = ValidateNumbering(result, options)

    return result;
}
