import _ from "lodash";

const FilterAssetTypesBySystem = (props) => {
    const { asset_types = {}, system_name = "" } = props;

    let result = {};

    Object.keys(asset_types).map((group) => {
        let types = asset_types[group];
        result[group] = [];
        types.map((type) => {
            if (
                type.systems &&
                Array.isArray(type.systems) &&
                !_.isEmpty(type.systems) &&
                type.systems.includes(system_name)
            ) {
                result[group].push(type);
            }
        });
    });

    return result;
};

export default FilterAssetTypesBySystem;
