import React from "react";
import _ from "lodash";
import Settings from "../../../components/Settings";
import AutocompleteInput from "../../../components/AutocompleteInput";
import {
    ScriptedValuesTransformer,
    GetEnglishValues,
} from "./ScriptedValuesTransformer";
import getYears from "../../../components/Options/years";
import Select from "../../../components/Select";

const RenderRows = (props) => {
    const {
        items = [],
        editRowIndex,
        year,
        handleChange = () => {},
        handleDeleteRow,
        activeRow,
        isScript,
        script,
        cmsData = {},
        createNew,
        focusKey,
        disabled,
    } = props;

    let result = [];

    const Item = (props) => {
        const { item = {}, index } = props;

        const scriptedValues = ScriptedValuesTransformer({
            item,
            index,
            activeRow,
            editRowIndex,
            isScript,
            script,
        });

        const englishValues = GetEnglishValues({
            item,
            index,
            activeRow,
            editRowIndex,
        });

        const marginStyles = {
            margin: "0 0.5rem",
            width: "100%",
        };

        const marginStylesSmall = {
            ...marginStyles,
            width: "70%",
        };

        const apiUrls = cmsData.apiUrls || {};

        const disableField = (key) => {
            let result = disabled;

            if (
                isScript &&
                (!activeRow || !activeRow[key] || !activeRow[key].value)
            ) {
                result = true;
            }

            return result;
        };

        const RenderAutoCompleteInput = (props) => {
            const {
                placeholder,
                value,
                styles,
                itemKey,
                className,
                searchKey,
                searchUrl,
                searchOnClick,
            } = props;

            let placeholderText = placeholder;
            if (isScript && englishValues[itemKey]) {
                placeholderText = englishValues[itemKey];
            }

            return (
                <AutocompleteInput
                    placeholder={placeholderText}
                    value={value}
                    styles={styles}
                    handleListSelection={(value) => {
                        handleChange({ value, key: itemKey, index });
                    }}
                    disabled={disabled}
                    apiUrls={apiUrls}
                    className={className}
                    searchKey={searchKey}
                    simpleInput={isScript}
                    searchUrl={searchUrl}
                    searchOnClick={searchOnClick}
                    createNew={createNew}
                    id={itemKey + index}
                    setFocusOnMount={focusKey == itemKey ? true : false}
                />
            );
        };

        return (
            <div className="meta-awards--item">
                <RenderAutoCompleteInput
                    placeholder="Enter awards body"
                    value={scriptedValues.award_body}
                    styles={marginStyles}
                    itemKey="award_body"
                    className="meta-awards--item--awards-body"
                    searchKey="award_bodies"
                    searchOnClick={true}
                />

                <RenderAutoCompleteInput
                    placeholder="Enter result"
                    value={scriptedValues.result}
                    styles={marginStylesSmall}
                    itemKey="result"
                    className="meta-awards--item--result"
                    searchKey="results"
                    searchOnClick={true}
                />

                <Select
                    value={scriptedValues.year}
                    clear={true}
                    disabledText="Select Year"
                    noneSelectedText="None Selected"
                    className="meta-awards--item--year"
                    readOnly={disabled}
                    disabled={disabled}
                    data={getYears(year)}
                    selectStyles={{
                        margin: 0,
                    }}
                    wrapperStyles={marginStylesSmall}
                    handleChange={(value) =>
                        handleChange({
                            value,
                            key: "year",
                            index,
                        })
                    }
                />

                <RenderAutoCompleteInput
                    placeholder="Enter category"
                    value={scriptedValues.category}
                    styles={marginStyles}
                    itemKey="category"
                    className="meta-awards--item--category"
                    searchKey="categories"
                    searchOnClick={true}
                />

                <RenderAutoCompleteInput
                    placeholder={!disabled ? "Enter contributor" : ""}
                    value={scriptedValues.contributor}
                    styles={{
                        width: "100%",
                    }}
                    itemKey="contributor"
                    className="meta-awards--item--contributor"
                    searchKey="contributors"
                    searchUrl={apiUrls.searchContributors}
                />

                <div className="buttons">
                    {!disabled && !(editRowIndex === index) && (
                        <React.Fragment>
                            <div
                                className="meta-awards--item--sort"
                                style={{
                                    backgroundImage: `url('${Settings.images.path}/svg/menu-light-grey.svg')`,
                                }}
                            ></div>
                        </React.Fragment>
                    )}

                    {!disabled && (
                        <React.Fragment>
                            <img
                                className="meta-awards--item--delete"
                                src={`${Settings.images.path}/svg/clear-light-grey.svg`}
                                onClick={() =>
                                    handleDeleteRow({
                                        index,
                                    })
                                }
                            />
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    };

    if (Array.isArray(items)) {
        result = items.map((item, index) => {
            return <Item item={item} index={index} />;
        });
    }

    return result;
};

export default RenderRows;
