import React from "react"
import { Typography } from "@mui/material"

type Props = {
    text?: string
    customStyles?: {
        [key: string]: string
    }
}

const NoneElement = ({ text = "None Entered", customStyles = {} }: Props) => {
    return (
        <Typography 
            variant="body2"
            sx={{
                fontSize: "1rem;",
                color: "rgba(47, 47, 47, 0.37);",
                fontFamily: "'Roboto';",
                marginTop: "0.5rem !important",
                ...customStyles
            }}>
            {text}
        </Typography>
    )
}

export default NoneElement