import React from "react";
import _ from "lodash";
import Input from "../../interfaces/Input.interface";
import { CoreDurationInput } from "../../../components/inputs/CoreDurationInput";
import { observer } from "mobx-react";
import { useStores } from '../../store/rootStore'

export const Duration = observer(({ field }: Input) => {
    const { formStore } = useStores()

    let customStyles = {}

    if (field.validation?.readOnly?.enabled || formStore.disabled) {
        customStyles = {
            '.MuiInputBase-input': {
                paddingBottom: '0.5rem !important',
            },
            '.MuiOutlinedInput-notchedOutline': {
                border: '0'
            },
            '.Mui-disabled': {
                paddingLeft: '0 !important',
                color: "rgba(47, 47, 47, 0.87) !important;",
                "-webkit-text-fill-color": "rgba(47, 47, 47, 0.87) !important;",
            }
        }
    }

    return (
        <CoreDurationInput
            path={field.key}
            labelProps={{
                text: field.label,
                isRequired: field.validation?.required?.enabled
            }}
            disabled={field.validation?.readOnly?.enabled || formStore.disabled}
            customStyles={customStyles}
        />
    )
})