import React from "react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ExpandMore";
import { CoreTable } from "../../../../../core/Table/CoreTable";
import { observer } from "mobx-react";
import { useStores } from "../../store/rootStore";
import { CreditRowInterface } from "../../store/interfaces";
import { GridRowOrderChangeParams } from "@mui/x-data-grid-pro";
import { CoreAccordion } from "../../../../../core/Accordion/CoreAccordion";
import { classPrefix } from "../../utils/constants";
import { defaultCreditsColumns } from "../../config/creditsTable.config";
import InactiveCreditListsBar from "../credit-table/InactiveCreditListsBar.view";
import { CoreTableGridColDef } from "../../../../../core/Table/types";
import { CheckboxCol } from "../../../../../core/Table/components/Checkbox.column";
import { RenderActionsCol } from "../../../../../core/Table/components/RenderActions.column";

const InactiveCreditsTable = observer(() => {
    const { 
        rootStore,
        inactiveCreditsStore,
        permissionsStore
    } = useStores();

    const editModeInactiveCreditsColumns: CoreTableGridColDef[] = [
        CheckboxCol,
        ...defaultCreditsColumns,
        ...RenderActionsCol({
            add: {
                disabled: !permissionsStore.userHasUpdatePermission,
                handleOnClick: (params: GridRowParams) => {
                    inactiveCreditsStore.handleAddCreditToCreditList(params)
                }
            },
            delete: {
                disabled: !permissionsStore.userHasDeletePermission,
                handleOnClick: (params: GridRowParams) => inactiveCreditsStore.handleDeleteCredit(params),
            },
            width: 80
        })
    ]

    return (
        <div className={`${classPrefix}-inactive-credits-accordion`} data-testid={`${classPrefix}-inactive-credits-accordion`}>
            <CoreAccordion 
                data-testid="inactive-credits-accordion"
                defaultExpanded={false}
                items={[
                    {
                        title: 'Inactive Credits',
                        content: (
                            <>
                                <InactiveCreditListsBar />

                                <div data-testid={`${classPrefix}-inactive-credits-table`}>
                                    <CoreTable<CreditRowInterface> 
                                        sortingOrder={["asc", "desc"]}
                                        columns={rootStore.disabled ? defaultCreditsColumns : editModeInactiveCreditsColumns}
                                        config={inactiveCreditsStore.tableConfig}
                                        rows={inactiveCreditsStore.inactiveTableCredits}
                                        onSelectionModelChange={(ids: string[]) => inactiveCreditsStore.setSelected(ids)}
                                        hideFooterRowCount
                                        hideFooterSelectedRowCount
                                        onRowOrderChange={(params: GridRowOrderChangeParams) => {}}
                                        loading={false}
                                        onSortModelChange={(params) => {}}    
                                    />
                                </div>
                            </>
                        ),
                    },
                ]}
                customStyles={{
                    "& .MuiAccordionSummary-root": {
                        flexDirection: "row-reverse",
                        padding: 0,
                        marginTop: '1rem',
                        "& .MuiAccordionSummary-content": {
                            margin: "0 0 0 0.3125rem",
                        },
                    },
                    "& .MuiAccordionDetails-root ": {
                        padding: 0,
                        marginTop: '0.62rem',
                    },
                    boxShadow: 'none',
                    border: 0
                }}
                expandIcon={<ArrowForwardIosSharpIcon />}
            />
        </div>
    )
})

export default InactiveCreditsTable;