import React, { useState } from "react";
import { useStore } from "../../../state/context";
import AssetInterface from "../interfaces/AssetInterface";
import {
    AssetTypeSectionFieldInterface,
} from "../interfaces/AssetTypeInterface";
import _ from "lodash";
import "./styles/_styles.scss";
import Input from "../../../../../Input/basic";
import Select from "../../../../../Select";
import { setEditAsset } from "../../../state/actions/assetEdit";
import ClientManagedSelect from "../../../../../Select/ClientManaged";
import { useStore as useGlobalStore } from "../../../../../Global/store/store";
import InputDatePicker from "../../../../../Input/datepicker";
import MaskInput from "../../../../../Input/maskInput";
import GetEditableFields from "../../../helpers/GetEditableFields";

type Props = {
    asset: AssetInterface;
};

const AssetUpdateForm: React.FC<Props> = ({ asset }) => {
    const [store, dispatch] = useStore()
    const [globalState] = useGlobalStore() || [{}]

    const { configuration } = store;

    const [formData, setFormData] = useState<AssetInterface>(asset);

    const handleChange = (
        key: string,
        value: string | number | { value: string; text: string },
        dataType: string,
    ): void => {
        let data: AssetInterface = Object.assign({}, { ...formData });

        if (dataType == "Boolean") {
            data[key] = value == 1 ? true : false;
        } else {
            data[key] = value;
        }

        setFormData(data);
        dispatch(setEditAsset(data));
    };

    const editableFields: Array<AssetTypeSectionFieldInterface> = GetEditableFields(configuration, asset)

    const booleanFieldValues = [
        {
            text: "False",
            value: 0,
        },
        {
            text: "True",
            value: 1,
        },
    ];

    return (
        <div className="asset-update-form">
            {editableFields.map((field) => {
                const isDate: boolean = field?.options?.editable?.inputType == "Date" 
                const isDuration: boolean = field?.options?.editable?.inputType == "Duration" 

                return (
                    <div className="form-field-wrapper">
                        {field.dataType == "Boolean" && (
                            <Select
                                label={field.displayName}
                                name={field.key}
                                value={asset[field.key] == true ? 1 : 0}
                                handleChange={(value) =>
                                    handleChange(
                                        field.key,
                                        value,
                                        field.dataType,
                                    )
                                }
                                data={booleanFieldValues}
                            />
                        )}

                        {(field.dataType == "Object" && field?.options?.editable?.inputType == "Select") &&
                            <ClientManagedSelect
                                value={asset[field.key]}
                                label={field.displayName}
                                placeholder={`Enter ${field.displayName}`}
                                noneSelectedText="None Selected"
                                disabledText={`Select ${field.displayName}`}
                                handleChangeTextValue={(value: {value: string, text: string}) => 
                                    handleChange(
                                        field.key,
                                        value,
                                        field.dataType,
                                    )
                                }
                                clientManagedType={field?.options?.editable?.optionsManagedList?.key}
                                clientFeatures={globalState?.cmsData?.clientFeatures}
                                apiUrls={globalState?.cmsData?.apiUrls}
                                isClientManaged={field?.options?.editable?.optionsManagedList?.key ? true : false}
                                isDynamicList={field?.options?.editable?.optionsManagedList?.dynamic}
                            />
                        }

                        {isDate &&
                            <InputDatePicker
                                label={field.displayName}
                                placeholder={`Enter ${field.displayName}`}
                                noneEntered={true}
                                recordBasic={true}
                                dataToggle={asset[field.key]}
                                format={field?.options?.editable?.format}
                                value={asset[field.key]}
                                handleChange={(value: string) => {
                                    handleChange(
                                        field.key,
                                        value,
                                        field.dataType,
                                    )
                                }}
                            />
                        }

                        {isDuration &&
                            <MaskInput
                                label={field.displayName}
                                placeholder={`Enter ${field.displayName}`}
                                noneEntered={true}
                                recordBasic={true}
                                borderHiddenPadding={true}
                                value={asset[field.key] || ""}
                                handleChange={(value) => {
                                    handleChange(
                                        field.key,
                                        value?.toString(),
                                        field.dataType,
                                    )
                                }}
                                maskChar="0"
                                mask="99:99:99"
                            />
                        }

                        {((field.dataType == "String" || field.dataType == "Number") && !isDate && !isDuration) && (
                            <Input
                                value={asset[field.key]}
                                placeholder={`Enter ${field.displayName}`}
                                label={field.displayName}
                                color="#000000"
                                handleChange={(value) =>
                                    handleChange(
                                        field.key,
                                        value,
                                        field.dataType,
                                    )
                                }
                                styles={{
                                    boxShadow:
                                        "rgb(207, 206, 206) 0px 0.0625rem 0px 0px",
                                }}
                            />
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default AssetUpdateForm;
