export const TITLES = 'titles'
export const DESCRIPTIONS = 'descriptions'
export const RIGHTS = 'rights'
export const ORIGINATION = 'origination'
export const CUSTOM_FIELDS = 'customFields'
export const SCHEDULING = 'scheduling'
export const SCHEDULING_SYSTEMS = 'schedulingSystems'
export const SCHEDULING_PLATFORMS = 'schedulingPlatforms'
export const CONTRIBUTOR_SECTIONS = 'contributorSections'
export const NUMBERING = 'numbering'

export const ASSETS = 'assets'
export const ASSET_TYPES = 'assetTypes'
export const ASSET_DESTINATION_PLATFORMS = 'assetDestinationPlatforms'
export const ASSET_CODES = 'assetCodes'

export const IDS = 'ids'
export const CLIENT_IDS = 'clientIds'
export const SUPPLY_CHAIN_IDS = 'supplyChainIds'
export const ALTERNATE_IDS = 'alternateIds'

export enum ID_TYPES {
    client = 'Client',
    alternate = 'Alternate',
    supplychain = 'SupplyChain',
}

export enum Label {
    default = 'Default',
    custom = 'Custom'
}

export const isIdEntity = (entity: string) => {
    return [CLIENT_IDS, ALTERNATE_IDS, SUPPLY_CHAIN_IDS].includes(entity)
}

export const idsMap = {
    [CLIENT_IDS]: ID_TYPES.client,
    [ALTERNATE_IDS]: ID_TYPES.alternate,
    [SUPPLY_CHAIN_IDS]: ID_TYPES.supplychain,
}
