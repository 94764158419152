import React from "react";
import _ from "lodash";
import styles from "./styles";
import Index from "./index";
import validateAlphaNumeric from "../Helpers/validateAlphaNumeric";
import validateNumeric from "../Helpers/validateNumeric";
import PlaceholderText from "../PlaceholderText";

export default class Input extends Index {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.isComponentMounted = true;

        if (this.props.setFocusOnMount) {
            this.handleFocus.bind(this, true);

            if (this.props.id && this.props.setFocusWithId) {
                const element = document.getElementById(this.props.id);

                if (element) {
                    element.focus();
                }
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!_.isEqual(nextProps.value, this.state.value)) {
            return true;
        }

        if (!_.isEqual(this.props.disabled, nextProps.disabled)) {
            return true;
        }

        if (!_.isEqual(this.props.display, nextProps.display)) {
            return true;
        }

        if (!_.isEqual(this.props.className, nextProps.className)) {
            return true;
        }

        if (!_.isEqual(this.props.error, nextProps.error)) {
            return true;
        }

        if (!_.isEqual(this.props.loading, nextProps.loading)) {
            return true;
        }

        if (!_.isEqual(this.props.autoFocus, nextProps.autoFocus)) {
            return true;
        }

        if (!_.isEqual(this.state, nextState)) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps, prevState) {
        const { updateState = true } = this.props;

        if (prevProps.value !== this.props.value && updateState) {
            this.setState({
                value: this.props.value,
            });
        }

        if (this.props.autoFocus) {
            this.inputRef.focus();
        }
    }

    handleKeyPress(event) {
        const { useEnterButtonOnSelect, handleEnter, handleListSelection } =
            this.props;

        const { value } = this.state;

        if (typeof event == "object") {
            if (
                event.key === "Enter" &&
                !_.isEmpty(value) &&
                useEnterButtonOnSelect
            ) {
                event.preventDefault();

                this.handleFocus(false);

                if (typeof handleEnter == "function") {
                    handleEnter(value);
                }

                if (typeof handleListSelection == "function") {
                    handleListSelection(value);
                }
            }
        }
    }

    handleChange(event) {
        const { alphaNumeric, numeric, type, max, min } = this.props;

        if (event) {
            if (event.target) {
                let value = event.target.value;

                if (alphaNumeric && !validateAlphaNumeric(value)) {
                    return;
                }

                if (numeric && !validateNumeric(value)) {
                    return;
                }

                // Validate number
                if (type == "number") {
                    if (max !== undefined) {
                        if (value > max) {
                            value = max;
                        }
                    }

                    if (min !== undefined) {
                        if (value < min) {
                            value = min;
                        }
                    }
                } else {
                    if (
                        max !== undefined &&
                        min == undefined &&
                        _.isString(value)
                    ) {
                        if (value.length > max) {
                            value = event.target.value.substring(0, max);
                        }
                    }
                }

                this.setState(
                    {
                        value,
                    },
                    () => {
                        this.handleChangeCallback();
                    },
                );
            }
        }
    }

    renderInput(options) {
        const theme = process.THEME || {};
        const inputTheme = theme.input || {};

        const { placeholderClass = "placeholder-light-grey" } = inputTheme;

        if (this.props.autoFillFaker) {
            return (
                <React.Fragment>
                    <input
                        id={this.props.fakerId}
                        style={{ display: "none" }}
                        type="text"
                        name={this.props.fakerName}
                        data-testid={options.id}
                    />

                    {this.props.disabled && this.renderDisabledInput(options)}

                    {!this.props.disabled && (
                        <input
                            {...options}
                            data-testid={options.id}
                            className={`${placeholderClass} ${
                                this.props.className || ""
                            }`}
                            ref={(ref) => (this.inputRef = ref)}
                        />
                    )}
                </React.Fragment>
            );
        }

        return (
            <input
                {...options}
                className={`${placeholderClass} ${this.props.className || ""}`}
                ref={(ref) => (this.inputRef = ref)}
                data-testid={options.id}
                onInput={(event) => {
                    //this prevents non-numeric values from being entered in number inputs on Safari and Firefox
                    if (
                        !event.target.validity.valid &&
                        event.target.type === "number"
                    )
                        event.target.value = this.state.value;
                }}
            />
        );
    }

    render() {
        const { disabled, useNoneAdded, value } = this.props;

        const recordBasicOptions = this.props.recordBasic
            ? this.getRecordBasicProps()
            : {};
        const optionsProps = {
            ...this.props,
            ...recordBasicOptions,
            onKeyPress: this.handleKeyPress.bind(this),
        };

        const options = this.getOptions(optionsProps) || {};

        const displayNoneAdded = disabled && useNoneAdded && !value;

        return (
            <div
                style={styles(optionsProps, this.state.value).container}
                ref={(node) => {
                    this.node = node;
                }}
            >
                {this.renderLabel()}

                {displayNoneAdded && (
                    <PlaceholderText useMargin={true} text="None Selected" />
                )}

                {!displayNoneAdded && this.renderInput(options)}
            </div>
        );
    }
}
