import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { DataGridProps } from "@mui/x-data-grid";
import { GridRowParams } from "@mui/x-data-grid-pro";
import _ from "lodash";
import { RootStore, useStores } from "../../../features/Credits/v3/store/rootStore";
import { CheckboxCol } from "../components/Checkbox.column";
import { RenderActionsCol } from "../components/RenderActions.column";
import { ReorderCol } from "../components/Reorder.column";
import { CoreTable } from "../CoreTable";
import { CoreTableConfig, CoreTableGridColDef, CoreTableState } from "../types";
import { CharacterNameCol } from "./columns/CharacterName.column";
import { ContributorIDCol } from "./columns/ContributorID.column";
import { ContributorNameCol } from "./columns/ContributorName.column";
import { CreditIDCol } from "./columns/CreditID.column";
import { ImdbIDCol } from "./columns/ImdbID.column";
import { RenderAddAction } from "./columns/RenderAddAction";
import { RoleCol } from "./columns/Role.column";
import { onSelectionModelChange } from "./onSelectionModelChange";
import { TableCreditRow } from "./types/CastCrew.type";
import {
    adaptStatusToCoreTableState,
    changeRowOrder,
    handleEdit,
    recreateRankings,
    sortAndRank,
    updateRowPosition,
} from "./utils";



export type CastCrewTableVariant = "active" | "inactive";

export type CastCrewTableProps = {
    variant: CastCrewTableVariant;
    credits: TableCreditRow[];
    footerData: string;
    onCreditEdit: (data: TableCreditRow) => void;
    onSelect: (data: TableCreditRow[]) => void;
    onReorder: (data: TableCreditRow[]) => void;
    //onContributorClick: (data: TableCreditRow) => void;
    onRowsChanged: (rows: TableCreditRow[]) => void;
    onAdd: (data: TableCreditRow) => void;
} & Pick<DataGridProps, "loading">;

export const CastCrewTable = ({
    variant,
    credits,
    footerData,
    onCreditEdit: onEdit,
    onSelect,
    onReorder,
    onRowsChanged,
    onAdd,
    loading,
}: CastCrewTableProps) => {
    const rootStore: RootStore | {} = useStores() || {}
    const status = (rootStore as RootStore).getEditStatus ? "view" : "edit"
    
    const [rows, setRows] = useState<TableCreditRow[]>(credits);
    const [resetCounter, setResetCounter] = useState(0);
    const coreTableState: CoreTableState = adaptStatusToCoreTableState(status);
    const isInactive = variant === "inactive";
    const isActive = variant === "active";

    const tableSortStatus = (rootStore as RootStore).getTableSortStatus

    const resetTable = () => {
        const aux = resetCounter + 1;
        setResetCounter(aux);
    }

    useEffect(() => {
        tableSortStatus.active ?  null : resetTable()
    }, [tableSortStatus.active]);

    // TODO: rethink this
    useEffect(() => {
        if (!_.isEmpty(credits)) {
            let creditsRows = credits;
            if (isInactive) {
                creditsRows = credits?.map((credit) => {
                    return {
                        ...credit,
                        ranking: -1,
                    };
                });
            }

            let newRows = sortAndRank(creditsRows);

            if (isActive) {
                newRows = recreateRankings(newRows);
            }

            setRows(newRows);
        } else {
            setRows(credits);
        }
    }, [credits]);

    useEffect(() => {
        onRowsChanged(rows);
    }, [rows]);

    // TODO: move columns and actions outside of this component
    const actionsActive = RenderActionsCol({
        edit: {
            handleOnClick: (params: GridRowParams) => {
                handleEdit(params, rows, onEdit);
            },
        },
    });

    const actionsInactive = RenderAddAction({
        add: {
            handleOnClick: (params: GridRowParams) => {
                const { id, row } = params;
                onAdd(row)

                /*
                const foundRow = rows?.find((row) => row?.id === id);
                if (foundRow) {
                    onAdd(foundRow);
                }*/
            },
        },
    });

    const [columns, setColumns] = useState<CoreTableGridColDef[]>([
        CheckboxCol,
        ContributorNameCol,
        RoleCol,
        CharacterNameCol,
        ContributorIDCol,
        ImdbIDCol,
        CreditIDCol,
    ]);

    useEffect(() => {
        const cols = columns;
        if (isInactive) {
            cols?.push(...actionsInactive);
        } else if (isActive) {
            cols?.push(ReorderCol, ...actionsActive);
        }
        setColumns(cols);
    }, []);

    useEffect(() => {
        const cols = columns?.map((col) => {
            return {
                ...col,
                sortable: status === "view",
            };
        });
        setColumns(cols);
    }, [status]);

    const handleRowOrderChange = changeRowOrder(
        rows,
        updateRowPosition,
        onReorder,
        setRows,
    );

    const config: CoreTableConfig = {
        variant: variant === "inactive" ? "inactive" : "default",
        state: coreTableState,
        emptyFallback: isInactive ? "No Inactive Credits" : "No Active Credits",
        tableName: "CastCrew",
        leftFooterText: footerData,
        resetCounter,
    };

    return (
        <>
            {
                <Button
                    sx={{ marginX: 1, display: "none" }}
                    variant="outlined"
                    onClick={() => resetTable()}
                >
                    Set Initial State
                </Button>
            }

            <CoreTable<TableCreditRow>
                sortingOrder={["asc", "desc"]}
                config={config}
                columns={columns}
                rows={rows}
                onSelectionModelChange={onSelectionModelChange(rows, onSelect)}
                hideFooterRowCount
                hideFooterSelectedRowCount
                onRowOrderChange={handleRowOrderChange}
                loading={loading}
                onSortModelChange={(params) => {
                    !params.length ? null : (rootStore as RootStore).setTableSortStatus(true, (data) => data)
                }}              
            />
        </>
    );
};
