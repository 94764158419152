import React from "react";
import Component from "./simple";

const TagSimpleDouble = (props) => {
    const {
        leftBackground,
        rightBackground,
        color,
        leftText,
        rightText,
        styles = {},
        leftStyles = {},
        rightStyles = {},
    } = props;

    let containerStyles = {
        display: "flex",
        alignItems: "center",
        ...styles,
    };

    return (
        <div style={containerStyles}>
            <Component
                text={leftText}
                background={leftBackground}
                color={color}
                styles={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    ...leftStyles,
                }}
            />

            <Component
                text={rightText}
                background={rightBackground}
                color={color}
                styles={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    ...rightStyles,
                }}
            />
        </div>
    );
};

export default TagSimpleDouble;
