const actionButton = {
    container: {
        height: "1.465rem",
        padding: "0 0.3125rem",
        borderRadius: "0.15625rem",
        textTransform: "uppercase",
    },
    text: {
        fontSize: "0.78125rem",
        fontWeight: 500,
        letterSpacing: "0.015625rem",
        color: "#ffffff",
    },
    textDisabled: {
        color: "#a5a5a5",
    },
};

export default actionButton;
