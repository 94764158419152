import CategoryTags from "../../../components/CategoryTags";
import GetRecordData from "../GetRecordData";
import deepcopy from "deepcopy";
import _ from "lodash";
import HandleChange from "../HandleChange";
import {
    SortCollectionByShowSeason,
    SortCollectionTitlesFull,
} from "../../../components/SortCollectionTitles";

const HandleCollectionTitlesAdd = (props) => {
    const { state = {}, handleChange } = props;

    const { collectionTitle = {} } = state;

    const {
        category,
        selectedTitles = [],
        selectedTitle = {},
        selectedSeason,
        selectedEpisodes = [],
        selectedShowSpecials = [],
        episodes = [],
        children,
        parent,
    } = collectionTitle;

    const record = GetRecordData({
        state,
    });

    const { linkage = [] } = record;

    const getSeason = () => {
        let result = {};

        if (Array.isArray(selectedTitle.linkage)) {
            result = selectedTitle.linkage.find(
                (item) => item.meta_id == selectedSeason,
            );
        }

        return result;
    };

    if (Array.isArray(linkage)) {
        let titlesToSave = [];

        switch (category) {
            case CategoryTags.Season.short:
                if (selectedSeason && Array.isArray(selectedTitle.linkage)) {
                    titlesToSave = [getSeason()];

                    if (parent && parent == "show_season") {
                        titlesToSave.push(selectedTitle);
                    }

                    if (parent && parent == "show_season_episodes") {
                        titlesToSave.push(selectedTitle);
                        if (!_.isEmpty(episodes)) {
                            titlesToSave.push(
                                ...SortCollectionTitlesFull(episodes),
                            );
                        }
                    }
                }

                break;

            case CategoryTags.Episode.short:
                if (!_.isEmpty(selectedEpisodes))
                    titlesToSave = selectedEpisodes;
                titlesToSave.push(getSeason());
                titlesToSave.push(selectedTitle);

                break;

            case CategoryTags.ConstructedEpisode.short:
                if (!_.isEmpty(selectedEpisodes))
                    titlesToSave = selectedEpisodes;
                titlesToSave.push(getSeason());
                titlesToSave.push(selectedTitle);

                break;

            case CategoryTags.Storyline.short:
                if (!_.isEmpty(selectedEpisodes))
                    titlesToSave = selectedEpisodes;
                titlesToSave.push(getSeason());
                titlesToSave.push(selectedTitle);

                break;

            case CategoryTags.Special.short:
                if (!_.isEmpty(selectedShowSpecials))
                    titlesToSave.push(...selectedShowSpecials);
                if (!_.isEmpty(selectedEpisodes))
                    titlesToSave.push(...selectedEpisodes);

                // singles
                if (!_.isEmpty(selectedTitles)) {
                    titlesToSave = selectedTitles;
                }

                if (!_.isEmpty(selectedEpisodes)) {
                    titlesToSave = selectedEpisodes;
                }

                break;

            case CategoryTags.Show.short:
                if (!_.isEmpty(selectedTitles)) {
                    titlesToSave.push(...selectedTitles);

                    if (children && children == "show_season_episodes") {
                        selectedTitles.forEach((item) => {
                            const seasons = item.linkage.filter(
                                (item) =>
                                    item.category == CategoryTags.Season.short,
                            );
                            const episodes = item.linkage.filter(
                                (item) =>
                                    item.category == CategoryTags.Episode.short,
                            );
                            const storylines = item.linkage.filter(
                                (item) =>
                                    item.category ==
                                    CategoryTags.Storyline.short,
                            );
                            const specials = item.linkage.filter(
                                (item) =>
                                    item.category == CategoryTags.Special.short,
                            );

                            if (!_.isEmpty(seasons)) {
                                titlesToSave.push(...seasons);
                            }

                            if (!_.isEmpty(episodes)) {
                                let items = SortCollectionTitlesFull(episodes);
                                titlesToSave.push(...items);
                            }

                            if (!_.isEmpty(storylines)) {
                                let items =
                                    SortCollectionTitlesFull(storylines);
                                titlesToSave.push(...items);
                            }

                            if (!_.isEmpty(specials)) {
                                titlesToSave.push(...specials);
                            }
                        });
                    }
                }

                break;

            default:
                titlesToSave = selectedTitles;
        }

        if (!_.isEmpty(titlesToSave)) {
            let updatedState = deepcopy(linkage);
            titlesToSave = SortCollectionByShowSeason(titlesToSave);

            const activeTitles = updatedState
                .filter((item) => !item.deleted)
                .map((item) => item.meta_id);

            titlesToSave = titlesToSave.filter(
                (item) => !activeTitles.includes(item.meta_id),
            );

            updatedState.push(...titlesToSave);

            if (typeof handleChange == "function") {
                handleChange("linkage", updatedState, state);
                return;
            }

            HandleChange({
                key: "linkage",
                data: updatedState,
                state,
            });
        }
    }
};

export default HandleCollectionTitlesAdd;
