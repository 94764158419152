import React, { useState, useEffect } from "react";
import Button from "../../Button";
import _, { isObject } from "lodash";
import Notification from "../../Notification";
import SendRequest from "../../SendRequest";
import Settings from "../../Settings";
import CategoryTags from "../../CategoryTags";

const AiringOrder = (props) => {
    const {
        handleReordering = () => {},
        data = {},
        disabled = true,
    } = props;

    const recordOptions =  isObject(props.recordOptions) 
        ? props.recordOptions 
        : {};

    const { 
        airing_reorder = {} 
    } = recordOptions;

    const [notification, setNotification] = useState({});
    const [reorderRunning, setReorderRunning] = useState(true);

    useEffect(() => {
        if (reorderRunning && data.category == CategoryTags.Show.short) {
            runProgressRequest();
        }
    }, [reorderRunning]);

    useEffect(() => {
        setReorderRunning(true);
    }, [props.recordOptions]);

    const runProgressRequest = () => {
        setTimeout(() => {
            const apiUrl =
                _.get(props, "cmsData.apiUrls.taskProgressStatus") || "";

            SendRequest(
                {
                    url: apiUrl,
                    method: "POST",
                    body: {
                        meta_id: data.meta_id || null,
                        type: "Airing Reorder",
                    },
                },
                async (response) => {
                    if (typeof response == "object") {
                        if (response.status == 200 && response.data) {
                            const status = _.get(response, "data.status");
                            if (status == "completed" || status == "error")
                                setReorderRunning(false);
                            else runProgressRequest();
                        } else {
                            setReorderRunning(false);
                        }
                    }
                },
            );
        }, 2000);
    };

    const handleClick = (enable) => {
        if (enable) {
            handleReordering({
                show_meta_id: data.meta_id || null,
            });
            setNotification({
                description:
                    "Episode Airing order will be recalculated on saving the Show Record",
            });
        } else {
            handleReordering({});
            setNotification({
                description:
                    "Airing Order Re-Calculation Process has been Cancelled",
            });
        }
    };

    return (
        <div id="airing-order">
            <div className="top-btn-section">
                {_.isEmpty(airing_reorder) && !disabled && (
                    <Button
                        loading={reorderRunning}
                        loadingSrc={Settings.images.loadingDotsWhite}
                        id={"airing-reorder-btn"}
                        value={"Regenerate Airing Order"}
                        type="button"
                        background={"rgb(40, 108, 210)"}
                        onClick={() => handleClick(true)}
                        color={"white"}
                        margin="0 0 0 auto"
                        padding="0.5625rem 0.6875rem"
                        hoverBackground="rgb(87 129 191)"
                        fontSize="0.78125rem"
                        fontWeight="500"
                        border={"0.0625rem solid rgb(40, 108, 210)"}
                        borderRadius="0.25rem"
                        textTransform={"none"}
                    />
                )}

                {!_.isEmpty(airing_reorder) && !disabled && (
                    <Button
                        loading={false}
                        id={"airing-reorder-btn"}
                        value={"Cancel Regenerating Airing Order"}
                        type="button"
                        background={"rgb(232, 55, 92)"}
                        onClick={() => handleClick(false)}
                        color={"white"}
                        margin="0 0 0 auto"
                        padding="0.5625rem 0.6875rem"
                        hoverBackground="rgb(234 99 127)"
                        fontSize="0.78125rem"
                        fontWeight="500"
                        border={"0.0625rem solid rgb(232, 55, 92)"}
                        borderRadius="0.25rem"
                        textTransform={"none"}
                    />
                )}
            </div>

            {!_.isEmpty(notification) && (
                <Notification
                    title={"Regenerate Airing Order"}
                    description={notification.description}
                    intercationStatus="alert"
                    onClick={setNotification.bind(this, {})}
                    okText={"Ok"}
                />
            )}
        </div>
    );
};

export default AiringOrder;
