import React from "react";
import { isEmpty } from "lodash"
import { observer } from "mobx-react";
import { useStores } from "../store/rootStore";
import MultiLevelExpandable from "../../../components/RecordBuilder/views/MultiLevelExpandable";

const EmptyState = observer(() => {
    const { ancillariesStore } = useStores() || {};

    if (isEmpty(ancillariesStore.multiLevelViewData)) {
        return <></>
    }

    return (
        <MultiLevelExpandable 
            data={ancillariesStore.multiLevelViewData}
            expandAll={false}
        />
    )
});

export default EmptyState;
