import React, { useState, useEffect } from "react";
import deepcopy from "deepcopy";
import { useStore } from "../../context";
import InputDataList from "../../../../../../components/Input/datalist";
import Settings from "../../../../../../components/Settings";
import OptionsSearchApi from "../../../../../../components/Options/OptionsSearchApi";
import AssignName from "../../../src/AssignName";
import AssignRole from "../../../src/AssignRole";
import UpdateScripts from "../../../src/UpdateScripts";
import Input from "../../../../../../components/Input/basic";
import GetScriptedValues from "../../../src/GetScriptedValues";
import CreateOrUpdateContributor from "./CreateOrUpdateContributor";
import {
    setActiveCredit,
    setNotification,
    updateContributor,
    updateCredit,
    setActiveContributor,
    setNewCredit,
} from "../../reducer";
import { CAST_AND_CREW } from "../../../../../../components/ClientManagedOptions/options";
import { useStore as globalStore } from "../../../../../../components/Global/store/store";
import { RoleCoreSelect } from "../../../../v3/view/components/RoleCoreSelect";
import { ScriptCoreSelect } from "../../../../../../components/Select/ScriptSelect/ScriptCoreSelect";
import { getRoles } from "../../../../../../services/Record/CastAndCrew/getRoles";
import { addLocalIdToCredit } from "../../../../../../models/Record/credits";
import { getDefaultWrittenLanguageCodeFromCache } from "../../../../../../models/ClientManaged/languagesModel";

const CreateOrUpdateCredit = ({
    credit = {},
    isNewCredit = false,
    activeRole = {},
}) => {
    const [store, dispatch] = useStore();
    const [globalState] = globalStore() || [{}];

    const [roleSelectOptions, setRoleSelectOptions] = useState([]);

    const { activeCredit = {}, cmsData = {}, options = {} } = store;

    const {
        clientFeatures = {},
    } = cmsData;

    const fallBackScript = getDefaultWrittenLanguageCodeFromCache();

    const [contributors, setContributors] = useState([]);
    const [isScriptSelected, setIsScriptSelected] = useState(false);
    const [script, setScript] = useState(globalState.defaultLocale);
    const [contributorSearch, setContributorSearch] = useState(isNewCredit);
    const [activeCharacterName, setActiveCharacterName] = useState(
        activeRole.character_name || false,
    );
    const [activeRoleDisabled, setActiveRoleDisabled] = useState(true);
    const [focusOn, setFocusOn] = useState(false);

    useEffect(() => {
        getRoles(cmsData).then((roles) => {
            setRoleSelectOptions(roles.data);
        });
    }, []);

    useEffect(() => {
        if (focusOn) {
            setFocusOn("role");
        }
    }, [script]);

    useEffect(() => {
        if (activeCredit.name) {
            setActiveRoleDisabled(false);
        }
    }, [activeCredit]);

    const HandleSearchContributor = (searchTerm) => {
        if (!!searchTerm.trim()) {
            OptionsSearchApi({
                apiUrls: cmsData.apiUrls,
                optionUrl: "/options/contributors",
                searchTerm,
                updateList: (data) => setContributors(data),
            });
        }
    };

    const DeleteContributor = () => {
        setContributorSearch(true);
        dispatch(setActiveCredit({}));
        setActiveRoleDisabled(true);
        setActiveCharacterName(false);
    };

    const generateFullName = (credit) => {
        let generatedName = "";
        if (credit.first_name) {
            generatedName += credit.first_name + ' ';
        }
        if (credit.middle_names) {
            generatedName += credit.middle_names + ' ';
        }
        if (credit.last_name) {
            generatedName += credit.last_name;
        }
        return generatedName.trim();
    };

    const SelectContributor = (value) => {
        setContributorSearch(false);
        dispatch(setActiveCredit(AssignName({ item: activeCredit, value })));
        setActiveRoleDisabled(false);
        setFocusOn("role");
    };

    const SelectRole = (value) => {
        const updatedCredit = AssignRole({ item: activeCredit, value });
        if (updatedCredit.role_name === "Actor") {
             updatedCredit.character_name = null;
             updatedCredit.script_character_names = [];
        }
        setActiveCharacterName(value?.character_name ? true : false);
        dispatch(setActiveCredit(updatedCredit));
        setFocusOn("character");
    };

    const SetScriptedValue = (field, value) => {
        let creditCopy = deepcopy(activeCredit);
        if (!isScriptSelected && field === "character_name")
            creditCopy.character_name = value;

            creditCopy = UpdateScripts({
                key: field,
                value,
                script,
                item: creditCopy,
            });

        if (
            isScriptSelected &&
            (field === "name" || field === "character_name")
        ) {
            creditCopy = UpdateScripts({
                key: field,
                value,
                script,
                item: creditCopy,
            });
        }

        dispatch(setActiveCredit(creditCopy));
        setFocusOn(!creditCopy.role ? "role" : "character");
    };

    const setCreditLocalId = (credit) => {
        if (!credit.local_id) {
            return addLocalIdToCredit(credit);
        }
        return credit;
    };

    const CreateContributor = (value) => {
        setContributorSearch(false);
        dispatch(setActiveContributor({}));
        dispatch(
            setNotification({
                html: <CreateOrUpdateContributor inputName={value} isNewContributor={true} />,
                confirm: () => {
                    dispatch(updateContributor());
                    dispatch(
                        setNotification({
                            html: <CreateOrUpdateCredit />,
                            title: "Add New Credit",
                            confirm: () => {
                                dispatch(
                                    isNewCredit
                                        ? setNewCredit()
                                        : updateCredit(
                                              credit.role,
                                              credit.ranking,
                                          ),
                                );
                                dispatch(setActiveContributor({}));
                                dispatch(setNotification({}));
                            },
                            disabled: true,
                            requirementText:
                                "Credit Name and Role is required.",
                        }),
                    );
                },
                title: "Add New Contributor",
            }),
        );
    };

    const scriptedValues = GetScriptedValues({
        item: activeCredit,
        disabled: false,
        isScript: isScriptSelected,
        script,
    });
    const tempFullName = generateFullName(activeCredit);

    return (
        <div className={"update-create-credit-form"}>
            <div className={"credit-contributor"}>
                {!contributorSearch /*!isScriptSelected &&*/ && (
                    <div className="contributor-wrapper">
                        <span className="contributor-name">
                            {activeCredit.name || tempFullName || ""}
                        </span>
                        <span className="contributor-id">
                            {activeCredit.contributor_id || ""}
                        </span>
                        <img
                            src={`${Settings.images.path}/svg/clear-white.svg`}
                            onClick={() => DeleteContributor()}
                            alt="x"
                            className="clear-icon"
                        />
                    </div>
                )}
                {contributorSearch /*!isScriptSelected &&*/ && (
                    <InputDataList
                        placeholder="Enter Name"
                        value=""
                        className="meta-credits-name"
                        padding="0.4375rem 0"
                        margin="0"
                        boxShadow="none"
                        single
                        noBorder
                        useEnterButton
                        dataListUseEnterButton
                        recordBasic
                        borderHiddenPadding
                        handleNoMatchText={(value) => CreateContributor(value)}
                        datalist={contributors || []}
                        keepValue
                        handleChange={(value) => HandleSearchContributor(value)}
                        handleListSelection={(contributor) =>
                            SelectContributor(contributor)
                        }
                        noMatchText={"No match for {value}. Create New?"}
                        styles={{ boxShadow: "none", padding: "0.25rem 0" }}
                        autoFocus
                    />
                )}
            </div>
            
            <div className="credit-script">
                <ScriptCoreSelect
                    options={options}
                    onChange={(value) => {
                        setScript(value);
                        setIsScriptSelected(value != fallBackScript);
                    }}
                    value={script}
                    listKey={CAST_AND_CREW}
                />
            </div>

            <did className="credit-role">
                <RoleCoreSelect
                    options={roleSelectOptions}
                    value={activeCredit.role?.toString() || ''}
                    onChange={(role) => SelectRole(role)}
                    disabled={activeRoleDisabled}
                />
            </did>

            {activeCharacterName &&
                <div className="credit-character-name">
                    <Input
                        recordBasic
                        label="Character Name"
                        inputDelay={200}
                        placeholder={scriptedValues.characterPlaceholder}
                        value={scriptedValues.character_name || ""}
                        handleChange={(value) =>
                            SetScriptedValue("character_name", value)
                        }
                        margin="1rem 0 0"
                        disabled={!activeCharacterName}
                        autoFocus={focusOn === "character"}
                    />
                </div>
            }
        </div>
    );
};

export default CreateOrUpdateCredit;
