import _ from "lodash";

function shouldLockField(props) {
    const { recordData = {}, item = {} } = props;

    const { field = "", value = "" } = item;

    return _.get(recordData, field) == value;
}

export function LockedDescriptorField(props) {
    const { item = {}, clientDataStructure = [], recordData = {} } = props;

    const { key = "" } = item;

    const { lockedFileds = {} } = clientDataStructure;

    if (lockedFileds.descriptors && lockedFileds.descriptors[key]) {
        return shouldLockField({
            item: lockedFileds.descriptors[key],
            recordData,
        });
    }

    return false;
}

export function LockedSynopsisField(props) {
    const { item = {}, clientDataStructure = [], recordData = {} } = props;

    const { key = "" } = item;

    const { lockedFileds = {} } = clientDataStructure;

    if (lockedFileds.synopses && lockedFileds.synopses[key]) {
        return shouldLockField({
            item: lockedFileds.synopses[key],
            recordData,
        });
    }

    return false;
}
