import React from "react";
import _ from "lodash";
import styles from "./styles";
import "./styles/_styles.scss";
import Input from "../Input/datalist";
import SendRequest from "../SendRequest";
import GetStamp from "../SendRequest/Stamp";
import CategoryTags from "../CategoryTags";
import Tag from "../Tag";
import LinkageSearchTransformer from "../Transformers/LinkageSearchTransformer";
import Index from "./index";
import update from "react-addons-update";
import deepcopy from "deepcopy";
import SeasonName from "../SeasonName";
import SeasonAndEpisodeNumber from "../Tags/SeasonAndEpisodeNumber";
import HandleSelectedRecord from "../Selected/handleSelectedRecord";
import SeasonAndEpisodeNumberV2 from "../Tags/SeasonAndEpisodeNumberV2";
import AuditTooltip from "../AuditTooltip";
import AuditContext from "../AuditTooltip/context/Context";
import LabelAudit from "../AuditTooltip/LabelAudit";

class Linkage extends Index {
    constructor(props) {
        super(props);

        let data = {};
        if (typeof this.props.data == "object") {
            data = this.props.data;
        }

        /**
         * @var object
         */
        this.defaultActiveRowData = {
            id: null,
            link_record_id: null,
            original_title: false,
            first_release_year: false,
            meta_id: false,
            category: false,
            type: false,
        };

        if (this.props.logoBranding) {
            this.defaultActiveRowData.logo_branding = false;
        }

        /**
         * @var object
         */
        this.state = {
            data,
            editRow: false,
            hoverRow: false,
            newRow: true,
            activeRow: { ...this.defaultActiveRowData },
            notification: {},
            datalist: this.props.datalist || [],
            showAudit: {},
        };

        /**
         * @var object
         */
        this.styles = styles(this.props);

        /**
         * @var object
         */
        this.copy = this.props.copy || {};

        /**
         * @var string
         */
        this.interactionButtonClassName = "meta-notification-linkage-save";

        /**
         * @var bool
         */
        this.keyPressEnterDisabled = true;

        /**
         * @var string|bool
         */
        this.stamp = false;

        /**
         * @var object
         */
        this.timer = null;

        /**
         * @var string
         */
        this.classPrefix = this.props.classPrefix || "meta-linkage";

        /**
         * @var string
         */
        this.notificationClass = this.props.notificationClass || "";
    }

    componentDidUpdate(prevProps, prevState) {
        // Edit record
        if (!_.isEqual(this.props.data, prevProps.data)) {
            this.setDataFromProps();
        }
    }

    setDataFromProps() {
        let result = {};

        if (typeof this.props.data == "object") {
            result = this.props.data;
        }

        if (Array.isArray(this.props.data)) {
            result = this.props.data;
        }

        this.setData(result);
    }

    setNotification(notification) {
        if (!_.isEqual(this.state.notification, notification)) {
            this.setState({
                notification,
            });
        }
    }

    setDatalist(datalist) {
        if (!_.isEqual(this.state.datalist, datalist) && this._isMounted) {
            this.setState({
                datalist,
            });
        }
    }

    handleRowDelete(item, index) {
        this.setNotification({
            title: "Delete Linkage",
            description: "Are you sure you want to delete this linkage?",
            status: "alert",
            okText: this.copy.okText || "Delete",
            confirm: this.deleteRow.bind(this, item, index),
        });
    }

    checkEmptyDataKeys() {
        let result = true;

        if (typeof this.state.data == "object") {
            this.state.data.map((item) => {
                if (
                    item.original_title == false ||
                    item.first_release_year == false ||
                    item.meta_id == false ||
                    item.link_record_id == false ||
                    item.category == false ||
                    item.type == false
                ) {
                    result = false;
                }
            });
        }

        return result;
    }

    isActiveRowValidated() {
        let result = false;

        if (
            this.state.activeRow.original_title ||
            this.state.activeRow.first_release_year ||
            this.state.activeRow.meta_id ||
            this.state.activeRow.link_record_id
        ) {
            result = true;
        }

        return result;
    }

    handleSearch(selected) {
        HandleSelectedRecord({
            handleLinkSearch: this.props.handleLinkSearch,
            selected
        });
    }

    async handleInactiveStateChange(key, index, value) {
        const row = deepcopy(this.state.data[index]);

        if (typeof row == "object") {
            row[key] = value;
        }

        const data = update(this.state.data, {
            [index]: {
                $set: row,
            },
        });

        await this.setData(data);
        this.handleChangeCallBack();
    }

    async handleChange(key, value) {
        let activeRow = {
            id: this.state.activeRow.id,
            link_record_id: this.state.link_record_id,
            original_title: this.state.activeRow.original_title,
            first_release_year: this.state.activeRow.first_release_year,
            meta_id: this.state.activeRow.meta_id,
            category: this.state.activeRow.category,
            type: this.state.activeRow.type,
        };

        let notification = {
            title: this.state.notification.title,
            okText: this.state.notification.okText,
            html: this.state.notification.html,
            confirm: this.state.notification.confirm,
            status: this.state.notification.status,
            disabled: true,
            background: this.state.notification.background,
        };

        if (key == "record_title") {
            activeRow.link_record_id = false;
            activeRow.original_title = false;
            activeRow.first_release_year = false;
            activeRow.meta_id = false;
            activeRow.category = false;
            activeRow.type = false;

            this.setDatalist([]);

            if (typeof value == "string" && value && this.props.searchUrl) {
                try {
                    clearTimeout(this.timer);
                    this.timer = setTimeout(
                        this.sendRequest.bind(this, notification, value),
                        350,
                    );
                } catch (e) {
                    console.log(e);
                }
            }

            // API faker
            if (process.env.NODE_ENV == "test" && value == "Jumanji") {
                activeRow.id = 1234;
                activeRow.link_record_id = 12345;
                activeRow.original_title = "Jumanji";
                activeRow.first_release_year = 2018;
                activeRow.meta_id = "METAID";
                activeRow.category = "Feature";
                activeRow.type = "Live Action";
            }

            if (typeof value == "object") {
                activeRow.id = value.id;
                activeRow.link_record_id = value.id;
                activeRow.original_title = value.original_title;
                activeRow.first_release_year = value.first_release_year;
                activeRow.meta_id = value.meta_id;
                activeRow.category = value.category;
                activeRow.type = value.type;
            }
        }

        this.setState(
            {
                activeRow,
            },
            () => {
                if (this.isActiveRowValidated()) {
                    notification.disabled = false;
                }

                this.setNotification(notification);
            },
        );
    }

    sendRequest(notification = {}, value = {}) {
        const stamp = GetStamp();
        const options = {
            url: this.props.searchUrl.replace("{searchTerm}", value),
            method: "GET",
            stamp,
        };

        this.stamp = stamp;

        SendRequest(
            options,
            async (data) => {
                if (this.stamp == data.stamp) {
                    let transformedData = LinkageSearchTransformer(data.data);
                    await this.setDatalist(transformedData);

                    // Filter records already selected
                    if (Array.isArray(this.state.data)) {
                        this.state.data.forEach((stateItem) => {
                            transformedData = transformedData.filter(
                                (item) => item.meta_id !== stateItem.meta_id,
                            );
                        });
                    }

                    notification.html = this.renderNotificationHtml(
                        {},
                        false,
                        transformedData,
                    );
                    this.setNotification(notification);
                }
            },
            (e) => {
                console.log(e);
            },
        );

        clearTimeout(this.timer);
    }

    handleNewRow() {
        if (
            this.state.newRow &&
            this.state.editRow === false &&
            typeof this.state.data == "object"
        ) {
            let title = this.copy.relationshipLink || "Linked Record";

            if (this.props.linkTitle) {
                title = this.props.linkTitle;
            }

            const notification = {
                title,
                okText: this.copy.save || "Save",
                html: this.renderNotificationHtml(),
                confirm: this.handleSave.bind(this),
                status: "alert",
                disabled: !this.isActiveRowValidated(),
                background: "white",
            };

            this.setNotification(notification);
        }
    }

    handleSave() {
        if (this.isActiveRowValidated()) {
            if (typeof this.state.activeRow == "object") {
                const data = update(this.state.data, {
                    $push: [this.state.activeRow],
                });

                this.setState(
                    {
                        data,
                    },
                    () => {
                        this.setNotification({});
                        this.setActiveRowDefault();
                        this.setEditRow(false);
                        this.handleChangeCallBack();
                    },
                );
            }
        }
    }

    handleNotificationCancel() {
        this.setNotification({});
        this.setEditRow(false);
    }

    renderNotificationHtml(
        item = {},
        index = false,
        datalist = this.state.datalist,
    ) {
        let placeholder =
            this.copy.startTypingTitleName || "Start Typing Title Name";
        if (this.props.linkPlaceholder) {
            placeholder = this.props.linkPlaceholder;
        }

        return (
            <div>
                <Input
                    placeholder={placeholder}
                    className={`${this.classPrefix}-record-title`}
                    id={`${this.classPrefix}-record-title`}
                    label="Target Title"
                    datalist={datalist}
                    single={true}
                    noFilter={true}
                    autoFocus={true}
                    recordBasic={true}
                    keepSelected={true}
                    value={item.original_title}
                    margin="1rem 0 0"
                    handleChange={this.handleChange.bind(this, "record_title")}
                    handleListSelection={this.handleChange.bind(
                        this,
                        "record_title",
                    )}
                    styles={{
                        paddingTop: "0.5625rem",
                    }}
                />
            </div>
        );
    }

    renderNumbering(item) {
        let result = SeasonAndEpisodeNumber({
            ...item,
            episodeClassName: "meta-linkage--table-episode-tag",
        });

        const { numberingStructure, linkageNumberingV2 } = this.props;

        if (!_.isEmpty(numberingStructure) && linkageNumberingV2) {
            result = SeasonAndEpisodeNumberV2({
                ...item,
                className: "meta-linkage--table-episode-tag",
                structure: numberingStructure,
            });
        }

        return result;
    }

    renderRecordType(type) {
        if (!type) {
            return <></>
        }
        
        return (
            <Tag
                text={type}
                styles={{
                    fontSize: "0.78125rem",
                    fontWeight: 500,
                    padding: "0.25rem 0.3125rem",
                }}
            />
        )
    }

    handleShowAuditSet(key, show) {
        if (!this.context.state.activeAudits) return;

        let showAudit = this.state.showAudit || {};
        showAudit[key] = show;
        showAudit.changeColor = show;
        if (!show) showAudit.showTooltip = show;

        this.setState({ showAudit }, () => {
            if (show) {
                setTimeout(
                    function () {
                        let showAuditCopy = this.state.showAudit || {};
                        if (showAuditCopy.changeColor) {
                            showAuditCopy.showTooltip = true;
                            this.setState({ showAuditCopy });
                        }
                    }.bind(this),
                    500,
                );
            }
        });
    }

    renderDataRow(item, index, count) {
        if (typeof item == "object") {
            const {
                original_title,
                category,
                category_name,
                season_number,
                meta_id,
                first_release_year,
                type,
                link_record_id = null,
            } = item;

            const { customIds, disabled } = this.props;

            let oddIndex = count % 2;
            let title = original_title;
            const tag = CategoryTags[category || category_name] || {};

            if (category == "Season" && !title) {
                if (Number.isInteger(season_number)) {
                    title = SeasonName(item);
                }
            }

            // Defaults to meta id
            if (!title && meta_id) {
                title = meta_id;
            }

            const TitleTag = (itemProps) => {
                return (
                    <Tag
                        text={itemProps.text}
                        styles={{
                            fontSize: "0.8125rem",
                            fontWeight: "bold",
                            background: "none",
                            color: "rgba(110, 110, 110, 0.87)",
                            padding: "0.21875rem",
                            marginLeft: "0.46875rem",
                            border: "0.03125rem solid rgba(110, 110, 110, 0.87)",
                        }}
                    />
                );
            };

            const auditKey = `linkages-${link_record_id}`;

            return (
                <AuditTooltip
                    dataSection="linkages"
                    fieldName="link_record_id"
                    value={link_record_id}
                    fieldKey={auditKey}
                >
                    <div
                        style={Object.assign({}, this.styles.tableContainer)}
                        key={index}
                        onMouseEnter={this.handleRowHover.bind(this, index)}
                        onMouseLeave={this.handleRowHover.bind(this, false)}
                    >
                        <div style={this.styles.tableContent}>
                            <table
                                style={Object.assign(
                                    {},
                                    this.styles.table,
                                    disabled && this.styles.tableDisabled,
                                )}
                                cellPadding="0"
                                cellSpacing="0"
                                className="meta-linkage--table"
                            >
                                <tbody style={this.styles.tbody}>
                                    <tr
                                        style={Object.assign(
                                            {},
                                            this.styles.trow,
                                            oddIndex == 1 && this.styles.trEven,
                                        )}
                                    >
                                        <td>
                                            <div
                                                style={this.styles.flexContent}
                                            >
                                                {this.state.showAudit[
                                                    auditKey
                                                ] &&
                                                    this.state.showAudit
                                                        .showTooltip && (
                                                        <LabelAudit
                                                            labelKey={auditKey}
                                                            styles={{
                                                                marginTop:
                                                                    "-3.438rem",
                                                            }}
                                                        />
                                                    )}

                                                {this.props.links && (
                                                    <a
                                                        className="meta-linkage--table-title"
                                                        onClick={this.handleSearch.bind(
                                                            this,
                                                            meta_id,
                                                        )}
                                                        onMouseEnter={(e) =>
                                                            this.handleShowAuditSet(
                                                                auditKey,
                                                                true,
                                                            )
                                                        }
                                                        onMouseLeave={(e) =>
                                                            this.handleShowAuditSet(
                                                                auditKey,
                                                                false,
                                                            )
                                                        }
                                                        style={Object.assign(
                                                            {},
                                                            this.state
                                                                .showAudit[
                                                                auditKey
                                                            ] &&
                                                                this.state
                                                                    .showAudit
                                                                    .changeColor && {
                                                                    color: "#696969",
                                                                },
                                                        )}
                                                    >
                                                        {title}
                                                    </a>
                                                )}

                                                {!this.props.links && (
                                                    <span className="meta-linkage--table-title">
                                                        {title}
                                                    </span>
                                                )}

                                                <span className="meta-linkage--table-release-year">
                                                    {first_release_year}
                                                </span>
                                            </div>
                                        </td>

                                        <td>
                                            <div
                                                style={Object.assign(
                                                    {},
                                                    this.styles.flexContent,
                                                    {
                                                        justifyContent:
                                                            "flex-end",
                                                    },
                                                )}
                                            >
                                                <span className="meta-linkage--table-meta-id id">
                                                    {this.props.links && (
                                                        <a
                                                            style={
                                                                this.styles.link
                                                            }
                                                            onClick={this.handleSearch.bind(
                                                                this,
                                                                meta_id,
                                                            )}
                                                        >
                                                            {meta_id}
                                                        </a>
                                                    )}

                                                    {!this.props.links &&
                                                        meta_id}
                                                </span>

                                                {!_.isEmpty(customIds) &&
                                                    Array.isArray(customIds) &&
                                                    customIds.map(
                                                        (item, index) => {
                                                            return (
                                                                <span
                                                                    className="meta-linkage--table-custom-id id"
                                                                    key={`custom-id-linkage-${index}`}
                                                                >
                                                                    {item[item]}
                                                                </span>
                                                            );
                                                        },
                                                    )}

                                                <div className="meta-linkage--table-episode-tags">
                                                    {this.renderNumbering(item)}
                                                </div>

                                                <div className="meta-linkage--table-record-type-tag">
                                                    {this.renderRecordType(type)}
                                                </div>

                                                {!disabled &&
                                                    this.renderDeleteIconLinkage(
                                                        item,
                                                        index,
                                                        "meta-linkage-delete-icon",
                                                    )}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </AuditTooltip>
            );
        }

        return false;
    }

    renderAddNewButton() {
        return false;
    }
}

Linkage.contextType = AuditContext;

export default Linkage;
